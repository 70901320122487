var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"conversionSelectCheckbox",class:{
    'conversionSelectCheckbox--checked': _vm.checked
  }},[_c('input',{staticClass:"conversionSelectCheckbox__input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.checked},on:{"change":function($event){return _vm.onInput($event.target.checked)}}}),_c('div',{staticClass:"conversionSelectCheckbox__iconContainer",class:{
      'conversionSelectCheckbox__iconContainer--checked': _vm.checked
    }},[(_vm.checked)?_c('Icon',{staticClass:"conversionSelectCheckbox__icon",attrs:{"icon":_vm.icon,"size":11,"color":_vm.color}}):_vm._e()],1),_c('div',{staticClass:"conversionSelectCheckbox__text",class:{
      'conversionSelectCheckbox__text--checked': _vm.checked
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }