export type GroupingResult<T> = { key: string; values: T[] }[];

export function groupBy<T>(
  arr: T[],
  groupFunc: (ele: T) => string
): GroupingResult<T> {
  const results: GroupingResult<T> = [];

  arr.forEach(ele => {
    const key = groupFunc(ele);

    const result: { key: string; values: T[] } | undefined = results.find(
      r => r.key === key
    );
    if (result) {
      result.values.push(ele);
    } else {
      results.push({ key, values: [ele] });
    }
  });

  return results;
}

/**
 * Using generics to flatten an array of up to 3 depths
 * reference: https://www.reddit.com/r/typescript/comments/apfkxh/how_should_i_correctly_type_an_array_flatten/eg82tcu
 */
export function flatten<T>(arr: T[][][]): T[];
export function flatten<T>(arr: T[][]): T[];
export function flatten<T>(arr: T[]): T[];
export function flatten<T>(arr: T[]): T[] {
  return arr.reduce(
    (acc: T[], val) =>
      Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
    []
  );
}

export function isLastItem<T>(array: T[], index: number): boolean {
  return array.length - 1 === index;
}
