<template>
  <div class="progress-bar" :style="{ width: `${width}px` }">
    <div class="progress-bar__text">
      <slot></slot>
      <span class="dots">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
    <div class="progress" :style="progressStyle" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  // slotで文字列を受け取るが、今後、再利用性が低い場合は
  // 後ろのドットもslotとして受け取るなどが必要

  @Prop({
    type: Number,
    required: true,
    validator: function(progress: number) {
      return progress >= 0.0 && progress <= 100.0;
    }
  })
  progress!: number;

  @Prop({ type: Number, default: 250 })
  width!: number;

  get progressStyle() {
    return {
      width: String(this.progress) + "%"
    };
  }
}
</script>
<style scoped lang="scss">
$progress-bar-height: 30px;
$dot-size: 3px;
$dot-margin: 6px;

.progress-bar {
  position: relative;
  display: inline-block;
  height: $progress-bar-height;
  border-radius: 5px;
  background-color: $colorBase300;
}

.progress-bar__text {
  position: absolute;
  width: 100%;
  height: $progress-bar-height;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 16px;
  line-height: $progress-bar-height;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
  background-color: $colorBlue750;
}

.dots {
  position: relative;
  display: inline-block;
  margin-left: $dot-margin;
  width: (2 * $dot-margin + 3 * $dot-size);
}

.dots > span {
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: $dot-size;
  height: $dot-size;
  border-radius: 1.5px;
  background: $colorDark;
  content: "";
  opacity: 0;
  animation-name: appear;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;

  &:nth-child(1) {
    left: 0;
    animation-delay: -0.4s;
  }

  &:nth-child(2) {
    left: $dot-margin;
    animation-delay: -0.2s;
  }

  &:nth-child(3) {
    left: 2 * $dot-margin;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
