import Axios, { AxiosResponse, AxiosRequestConfig } from "axios";
Axios.defaults.withCredentials = true;
Axios.defaults.baseURL = process.env.API_BASE_URL;

const API_VERSION = "2.0";

export default class HttpClient {
  private csrfToken?: string;

  get config(): AxiosRequestConfig {
    const config: AxiosRequestConfig = {
      headers: {
        "api-version": API_VERSION,
        accept: "application/json, text/plain, */*",
        "If-Modified-Since": "Thu, 01 Jun 1970 00:00:00 GMT"
      }
    };
    if (config.headers && this.csrfToken) {
      config.headers["x-csrftoken"] = this.csrfToken;
    }

    return config;
  }

  updateCsrfToken(csrfToken: string) {
    this.csrfToken = csrfToken;
  }

  async get<T>(url: string, params?: {}): Promise<T> {
    const config: AxiosRequestConfig = {};
    if (params) {
      config.params = params;
    }

    const res: AxiosResponse<T> = await Axios.get<T>(url, config);
    return res.data;
  }

  async post<T>(url: string, params: {}): Promise<T> {
    const res: AxiosResponse<T> = await Axios.post<T>(url, params, this.config);
    return res.data;
  }

  async put<T>(url: string, params: {}): Promise<T> {
    const res: AxiosResponse<T> = await Axios.put<T>(url, params, this.config);
    return res.data;
  }

  async patch<T>(url: string, params: {}): Promise<T> {
    const res: AxiosResponse<T> = await Axios.patch<T>(
      url,
      params,
      this.config
    );
    return res.data;
  }
}
