<template>
  <div class="balloon" :class="[placementClass]">
    <div
      v-if="notArrow"
      class="balloon__arrow balloon__arrow-front"
      :style="arrowStyle"
    ></div>
    <div class="balloon__wrapper">
      <slot></slot>
    </div>
    <div
      v-if="notArrow"
      class="balloon__arrow balloon__arrow-back"
      :style="arrowStyle"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BalloonDirection } from "@/const/balloon";

@Component
export default class Balloon extends Vue {
  @Prop({ type: String, default: BalloonDirection.Top })
  direction!: BalloonDirection;

  @Prop({ type: Number, default: 0 })
  adjust!: number;

  @Prop({ type: Boolean, default: true })
  notArrow!: boolean;

  get placementClass(): string {
    return "balloon__direction--" + this.direction;
  }

  // arrow position adjust style
  get arrowStyle() {
    if (typeof this.adjust === "number" && this.adjust !== 0) {
      if (
        this.direction.indexOf("top") !== -1 ||
        this.direction.indexOf("bottom") !== -1
      ) {
        return {
          marginLeft: this.adjust + "px"
        };
      }

      if (
        this.direction.indexOf("left") !== -1 ||
        this.direction.indexOf("right") !== -1
      ) {
        return {
          marginTop: this.adjust + "px"
        };
      }
    }

    return {};
  }
}
</script>

<style scoped lang="scss">
.balloon {
  position: relative;
  display: inline-block;
  border-radius: $sizeRadius;
  box-shadow: 5px 5px 5px $colorShadow100;
  cursor: default;
}

.balloon__wrapper {
  position: relative;
  height: 100%;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.balloon__arrow {
  position: absolute;
  width: $balloonArrowSize;
  height: $balloonArrowSize;
  transform: rotate(45deg);
}

.balloon__arrow-front {
  background-color: $colorWhite;
}

.balloon__arrow-back {
  background-color: $colorBase600;
}

.balloon__direction--top,
.balloon__direction--top-start,
.balloon__direction--top-end {
  .balloon__arrow-front {
    top: -4px;
  }

  .balloon__arrow-back {
    top: -5px;
  }
}

.balloon__direction--bottom,
.balloon__direction--bottom-start,
.balloon__direction--bottom-end {
  .balloon__arrow-front {
    bottom: -4px;
    border-top-color: $colorWhite;
  }

  .balloon__arrow-back {
    bottom: -5px;
    border-top-color: $colorBase600;
  }
}

.balloon__direction--top,
.balloon__direction--bottom {
  .balloon__arrow {
    left: 50%;
    margin-left: calc(-1 * $balloonArrowSize / 2);
  }
}
.balloon__direction--top-start,
.balloon__direction--bottom-start {
  .balloon__arrow {
    left: $balloonArrowTopBottomPosition;
  }
}
.balloon__direction--top-end,
.balloon__direction--bottom-end {
  .balloon__arrow {
    left: calc(100% - #{$balloonArrowTopBottomPosition} - #{$balloonArrowSize});
  }
}

.balloon__direction--right,
.balloon__direction--right-start,
.balloon__direction--right-end {
  .balloon__arrow-front {
    right: -4px;
  }

  .balloon__arrow-back {
    right: -5px;
  }
}

.balloon__direction--left,
.balloon__direction--left-start,
.balloon__direction--left-end {
  .balloon__arrow-front {
    left: -4px;
  }

  .balloon__arrow-back {
    left: -5px;
  }
}

.balloon__direction--right,
.balloon__direction--left {
  .balloon__arrow {
    top: 50%;
    margin-top: -8px;
  }
}
.balloon__direction--right-start,
.balloon__direction--left-start {
  .balloon__arrow {
    top: $balloonArrowTopBottomPosition;
  }
}
.balloon__direction--right-end,
.balloon__direction--left-end {
  .balloon__arrow {
    top: calc(100% - #{$balloonArrowTopBottomPosition} - #{$balloonArrowSize});
  }
}
</style>
