<i18n src="@/i18n/components/filter/filter-device.json"></i18n>
<template>
  <span class="filterDeviceLabel">
    <span v-t="'devices'" class="filterDeviceLabel__title"></span>
    <span>{{ deviceList }}</span>
    <span v-if="hasEdgeIcon" class="filterDeviceLabel__edge">
      <Icon :icon="iconRight" :size="12" :color="iconColor" />
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DeviceType } from "@/const/filter";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";

@Component({
  components: {
    Icon
  }
})
export default class FilterDeviceLabel extends Vue {
  @Prop({ type: Array, required: true })
  filteredDevices!: DeviceType[];

  @Prop({ type: Boolean, default: false })
  hasEdgeIcon!: boolean;

  iconRight = Icons.CaretRight;
  iconColor = Colors.Base600;

  get deviceList(): string {
    return this.translateDeviceTypesToDeviceNames(this.filteredDevices).join(
      ", "
    );
  }

  translateDeviceTypesToDeviceNames(deviceTypes: DeviceType[]): string[] {
    return deviceTypes.map(deviceIndex => {
      switch (deviceIndex) {
        case DeviceType.IPHONE:
          return "iPhone";
        case DeviceType.IPAD:
          return "iPad";
        case DeviceType.ANDROID:
          return "Android";
        case DeviceType.PC:
          return "PC";
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.filterDeviceLabel {
  color: $colorBase700;
  font-size: 14px;
}

.filterDeviceLabel__title {
  color: $colorBase900;
  font-weight: 700;

  &::after {
    content: ": ";
  }
}

.filterDeviceLabel__edge {
  display: inline-block;
  margin: 0 3px;
}
</style>
