import {
  AdditionalSelectConditionInterface,
  ValueInputMethod,
  numberValue
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import {
  UserAttributeConditionParams,
  AttributeConditionParamType,
  AdditionalConditionParams,
  AttributeType
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * ユーザ属性(数字)による条件
 *
 * OMO版限定の検索条件
 * 通常の検索条件に加えて、OMOでインポートしたユーザ属性に対する選定条件を付与できる
 *
 * 範囲を指定して、指定したユーザ属性(数字)がその範囲内にあるユーザのみを選定する
 */
export class UserAttributeNumberCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    // ユーザ属性定義のID
    public readonly id: number,
    // 指定範囲の開始
    public readonly from: number,
    // 指定範囲の終了
    public readonly to: number,
    // 値指定 or レンジ指定
    public readonly rangeType: ValueInputMethod
  ) {}

  /**
   * 表示用のタイトル
   * @param definitions
   */
  displayTitle(definitions: UserAttributeDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? ":" + def.name : "";

    return (i18n.t("models.search.userAttributes") as string) + defName;
  }

  /**
   * 設定された値を表示用に加工して返す。
   */
  displayValue(): string {
    return numberValue(this.from, this.to, this.rangeType);
  }

  /**
   * 指定のIDがuserAttributeDefinitionIdと同じか返す
   * Conversionごとにid名が違うので共通の関数もたせる
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertUserAttributeNumberConditionToJson(
  condition: UserAttributeNumberCondition
): UserAttributeConditionParams {
  return {
    type: AttributeConditionParamType.UserAttribute,
    id: condition.id,
    formatType: AttributeType.NUMBER,
    strValues: [],
    word_match_method: -1,
    valRangeType: condition.rangeType,
    valFrom: condition.from,
    valTo: condition.to
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToUserAttributeNumberCondition(
  json: UserAttributeConditionParams,
  activeUserAttributeDefinitions: UserAttributeDefinition[]
): UserAttributeNumberCondition | null {
  // 利用可能なidリストになければnull
  if (!activeUserAttributeDefinitions.some(def => def.id === json.id)) {
    return null;
  }
  return new UserAttributeNumberCondition(
    json.id,
    json.valFrom,
    json.valTo,
    json.valRangeType
  );
}

/**
 * 追加条件のJSONがアンケートの条件かどうかを判定する
 */
export function isUserAttributeNumberConditionParams(
  params: AdditionalConditionParams
): params is UserAttributeConditionParams {
  return (
    params.type === AttributeConditionParamType.UserAttribute &&
    params.formatType === AttributeType.NUMBER
  );
}
