import { render, staticRenderFns } from "./UserTrend.vue?vue&type=template&id=2389511a&scoped=true"
import script from "./UserTrend.vue?vue&type=script&lang=ts"
export * from "./UserTrend.vue?vue&type=script&lang=ts"
import style0 from "./UserTrend.vue?vue&type=style&index=0&id=2389511a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2389511a",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/user-trend.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2FUserTrend.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports