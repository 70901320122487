import { render, staticRenderFns } from "./CoordinationConversionSettingsCreate.vue?vue&type=template&id=286a08cf&scoped=true"
import script from "./CoordinationConversionSettingsCreate.vue?vue&type=script&lang=ts"
export * from "./CoordinationConversionSettingsCreate.vue?vue&type=script&lang=ts"
import style0 from "./CoordinationConversionSettingsCreate.vue?vue&type=style&index=0&id=286a08cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286a08cf",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/client-settings/coordination/conversion/coordination-conversion-settings-create.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2Fclient-settings%2Fcoordination%2Fconversion%2FCoordinationConversionSettingsCreate.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports