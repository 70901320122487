<i18n src="@/i18n/views/home.json"></i18n>
<template>
  <div class="search-navigation-onboarding">
    <div class="search-navigation-onboarding__frame"></div>
    <div v-t="'startObserving'" class="search-navigation-onboarding__balloon" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SearchNavigationOnboarding extends Vue {}
</script>

<style lang="scss" scoped>
.search-navigation-onboarding {
  pointer-events: none;
}

.search-navigation-onboarding__frame {
  width: 100%;
  height: 40px;
  border: 2px solid $colorOnboarding;
  border-radius: $sizeRadius;
  cursor: pointer;
  animation: frameAnimation 0.8s infinite ease-out alternate;
}

@keyframes frameAnimation {
  0% {
    border-color: $colorWhite;
  }
  100% {
    border-color: $colorOnboarding;
  }
}

.search-navigation-onboarding__balloon,
.search-navigation-onboarding__balloon-shadow {
  position: absolute;
  left: 50%;
  display: inline-block;
  display: flex;
  padding: 0 46px;
  height: 40px;
  border-radius: $sizeRadius;
  background: $colorOnboarding;
  box-shadow: 0 4px $colorBase500;
  color: $colorWhite;
  white-space: nowrap;
  font-size: 16px;
  line-height: 40px;
  transform: translate(-50%, 0);
  animation: balloonAnimation 0.8s infinite ease-out alternate;
}

.search-navigation-onboarding__balloon:before {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -8px;
  border: 8px solid transparent;
  border-bottom: 16px solid $colorOnboarding;
  content: "";
}

@keyframes balloonAnimation {
  0% {
    top: 65px;
  }
  100% {
    top: 60px;
  }
}
</style>
