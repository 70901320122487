<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="search-navigation">
    <div v-if="isDisabled" class="search-navigation-searchbox-disabled">
      <div class="search-wrapper-empty">
        <Icon
          :icon="iconSearch"
          :size="20"
          :color="disabledIconColor"
          class="search-icon"
        />
        <div class="empty-text-disabled">
          {{ $t("searchUsers") }}
          <Icon
            :icon="selectArrow"
            :size="8"
            :color="disabledIconColor"
            class="empty-text-icon"
          />
        </div>
      </div>
    </div>
    <div v-else class="search-navigation-searchbox">
      <!-- 検索後 --->
      <div v-if="showSelectCondition" class="search-wrapper">
        <div class="search-condition-wrapper">
          <div class="condition-detail-wrapper" @click="onClickSearch">
            <Tooltip
              class="condition-detail-tooltip"
              :color="tooltipColor"
              :placement="tooltipHeaderPlacement"
              :active="showTooltip"
              :wrap="true"
            >
              <div class="condition-detail-wrapper-text">
                <div class="search-icon">
                  <Icon :icon="iconSearch" :size="20" />
                </div>
                <div class="condition-detail">
                  <slot name="selectConditionTag"></slot>
                </div>
              </div>

              <template #content>
                <div class="condition-detail-tag-tooltip">
                  <slot name="selectConditionTagToolTip"></slot>
                </div>
              </template>
            </Tooltip>
          </div>
        </div>
        <!-- 絞り込み後 --->
        <div v-if="showFilterCondition" class="filter-node-wrapper">
          <div
            class="condition-detail-wrapper"
            data-cy="filter-node-wrapper"
            @click="onClickFilter"
          >
            <Tooltip
              class="condition-detail-tooltip"
              :placement="tooltipHeaderPlacement"
              :color="tooltipColor"
              :active="showTooltip"
              :wrap="true"
            >
              <div
                class="condition-detail-wrapper-text condition-detail-wrapper-filter"
              >
                <div class="condition-detail">
                  <slot name="filterConditionTag"></slot>
                </div>
              </div>
              <template #content>
                <div class="condition-detail-tag-tooltip">
                  <slot name="filterConditionTagTooltip"></slot>
                </div>
              </template>
            </Tooltip>
          </div>
        </div>
        <!-- 絞り込み前 --->
        <div
          v-else
          class="filter-node-wrapper-empty"
          data-cy="filter-node-wrapper-empty"
          @click="onClickFilter"
        >
          <div class="empty-text">
            {{ $t("filterUsers") }}
            <Icon
              :icon="selectArrow"
              :size="8"
              :color="arrowColor"
              class="empty-text-icon"
            />
          </div>
        </div>
      </div>
      <!-- 検索前 --->
      <div
        v-else
        class="search-wrapper-empty"
        data-cy="search-wrapper-empty"
        @click="onClickSearch"
      >
        <Icon :icon="iconSearch" :size="20" class="search-icon" />
        <div class="empty-text">
          {{ $t("searchUsers") }}
          <Icon
            :icon="selectArrow"
            :size="8"
            :color="arrowColor"
            class="empty-text-icon"
          />
        </div>
      </div>
    </div>
    <SearchNavigationOnboarding
      v-if="showOnboarding"
      class="search-navigation__onboarding"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import SearchNavigationOnboarding from "@/components/onboarding/SearchNavigationOnboarding.vue";
import { Colors } from "@/const/Colors";
import { TooltipPlacement, TooltipColor } from "@/const/tooltip";
@Component({
  components: {
    Icon,
    Tooltip,
    SearchNavigationOnboarding
  }
})
export default class SearchNavigation extends Vue {
  iconSearch = Icons.Search;
  selectArrow = Icons.ArrowBottom;
  tooltipHeaderPlacement = TooltipPlacement.Bottom;
  tooltipColor = TooltipColor.Light;
  arrowColor = Colors.Base700;
  disabledIconColor = Colors.ElmentUIGray300;
  @Prop({ type: Boolean, default: false })
  showSelectCondition!: boolean;
  @Prop({ type: Boolean, default: false })
  showFilterCondition!: boolean;
  @Prop({ type: Boolean, default: true })
  showTooltip!: boolean;
  @Prop({ type: Boolean, default: false })
  showOnboarding!: boolean;
  @Prop({ type: Boolean, default: false })
  isDisabled!: boolean;
  @Emit("click-search")
  onClickSearch() {}
  @Emit("click-filter")
  onClickFilter() {}
}
</script>
<style lang="scss" scoped>
.search-navigation {
  position: relative;
}
.search-navigation-searchbox {
  width: 100%;
  height: 40px;
  border: 1px solid $colorBase500;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  line-height: 1;
}
.search-navigation-searchbox-disabled {
  width: 100%;
  height: 40px;
  border: 1px solid $colorGray200;
  border-radius: $sizeRadius;
  background-color: $colorGray200;
  line-height: 1;
  pointer-events: none;
}
.search-icon {
  padding-right: 10px;
  padding-left: 10px;
  width: 40px;
}
.search-wrapper-empty,
.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 1;
}
.search-wrapper-empty {
  border-radius: calc(#{$sizeRadius} - 1px);
  cursor: pointer;
  transition: background-color 0.5s ease;
  &:hover {
    background: $colorHoverLightForWhite;
  }
}

.search-condition-wrapper {
  border-radius: calc(#{$sizeRadius} - 1px) 0 0 calc(#{$sizeRadius} - 1px);
}
.filter-node-wrapper,
.filter-node-wrapper-empty {
  border-left: 1px solid $colorBase500;
  border-radius: 0 calc(#{$sizeRadius} - 1px) calc(#{$sizeRadius} - 1px) 0;
}
.filter-node-wrapper-empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-condition-wrapper,
.filter-node-wrapper,
.filter-node-wrapper-empty {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: $colorWhite;
  cursor: pointer;
  transition: background-color 0.5s ease;
  &:hover {
    background: $colorHoverLightForWhite;
  }
}

.condition-detail-tooltip {
  width: 100%;
  height: 100%;
}
.condition-detail-wrapper {
  height: 100%;
  font-size: 16px;
}
.condition-detail-wrapper-filter {
  padding-left: 10px;
}
.condition-detail-wrapper-text {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.condition-detail-tag-tooltip {
  overflow: hidden;
  min-width: 390px;
  width: calc(55vw / 2);
}
.condition-detail {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.remove {
  position: absolute;
  top: 10px;
  right: 10px;
}
.clear {
  background: $colorWhite;
}
.empty-text {
  display: inline-flex;
  align-items: center;
  padding-top: 2px;
  color: $colorBase700;
  font-size: 16px;
  line-height: 1;
}
.empty-text-disabled {
  display: inline-flex;
  align-items: center;
  padding-top: 2px;
  color: $colorBase600;
  font-size: 16px;
  line-height: 1;
}
.empty-text-icon {
  margin-left: 5px;
}
.search-navigation__onboarding {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
