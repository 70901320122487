import { render, staticRenderFns } from "./SegmentedTrendsTabs.vue?vue&type=template&id=de2ad13a&scoped=true"
import script from "./SegmentedTrendsTabs.vue?vue&type=script&lang=ts"
export * from "./SegmentedTrendsTabs.vue?vue&type=script&lang=ts"
import style0 from "./SegmentedTrendsTabs.vue?vue&type=style&index=0&id=de2ad13a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2ad13a",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/segmented-trends/segmented-trends-tabs.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fsegmented-trends%2FSegmentedTrendsTabs.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports