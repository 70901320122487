import { GetterTree, MutationTree, ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store/";
import { Favorite } from "@/models/userdata/Favorite";
import { ApiParam } from "@/api/api-param";
import { ApiRes } from "@/api/api-res";

export class FavoriteState {
  favorites: Favorite[] = [];
}

const mutations: MutationTree<FavoriteState> = {
  setFavorites(state: FavoriteState, favorites: Favorite[]) {
    state.favorites = favorites;
  },
  addFavorite(state: FavoriteState, favorite: Favorite) {
    for (let i = 0; i < state.favorites.length; i++) {
      const fav = state.favorites[i];
      // 既存のリストにあれば追加ではなく更新する
      if (fav.userId === favorite.userId) {
        state.favorites[i] = favorite;
        return;
      }
    }
    state.favorites.unshift(favorite);
  },
  removeFavorites(state: FavoriteState, userIds: string[]) {
    state.favorites = state.favorites.filter(fav => {
      return userIds.indexOf(fav.userId) === -1;
    });
  },
  initialize(state: FavoriteState) {
    state.favorites = [];
  }
};

const getters: GetterTree<FavoriteState, RootState> = {
  userIds(state) {
    return state.favorites.map(f => f.userId);
  }
};

const actions: ActionTree<FavoriteState, RootState> = {
  async fetchFavorite({ commit, rootState }) {
    const body = await rootState.api.userdata.getFavoriteList();
    const favorites: Favorite[] = body.favorites.map(Favorite.fromJson);
    commit("setFavorites", favorites);
  },
  async addFavorite(
    store: ActionContext<FavoriteState, any>,
    favorite: Favorite
  ) {
    const updatedTimeMsec: number = favorite.updatedDate.getTime();
    const param: ApiParam.SaveFavorite = {
      id: 0,
      user_id: favorite.userId,
      description: favorite.description,
      updated_time_msec: updatedTimeMsec
    };
    await store.rootState.api.userdata.saveFavorite(param);
    store.commit("addFavorite", favorite);
    store.dispatch("userInfo/fetchUserInfo", null, { root: true });
  },
  async removeFavorites(
    store: ActionContext<FavoriteState, any>,
    userIds: string[]
  ) {
    const param: ApiParam.DeleteFavorites = {
      user_ids: userIds
    };
    const result: ApiRes.DeleteFavorites = await store.rootState.api.userdata.deleteFavorites(
      param
    );
    if (result.success) {
      store.commit("removeFavorites", userIds);

      store.dispatch("userInfo/fetchUserInfo", null, { root: true });
    } else {
      // TODO エラー
    }
  }
};

export const favorite = {
  namespaced: true,
  state: new FavoriteState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
