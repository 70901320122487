<i18n src="@/i18n/views/memo-button.json"></i18n>
<template>
  <div class="memo-button" data-cy="memo-button">
    <Tooltip :text="$t('tooltip')">
      <IconButton
        class="memo-button__icon"
        :icon="icons.Memo"
        :size="30"
        :icon-scale="0.53"
        :selected="hasMemo"
        :background="true"
        @click="openForm"
      />
    </Tooltip>
    <Overlay v-if="showForm" class="memo-button__overlay" @click="closeForm" />
    <Balloon
      v-if="showForm"
      class="memo-button__balloon"
      data-cy="memo-button__balloon"
      :direction="direction"
      :class="placementClass"
    >
      <div class="memo-button__form">
        <div class="memo-button__title">{{ $t("title") }}</div>
        <div
          class="memo-button__characterLength"
          :class="{ 'memo-button__characterLength--error': isTooLong }"
          >{{ memoRemainingCharacterCountMessage }}</div
        >
        <TextArea
          ref="memo-button__textarea"
          v-model="memo"
          :has-error="isTooLong"
          class="memo-button__textarea"
          data-cy="memo-button__textarea"
          width="340px"
          height="120px"
        />
        <div class="memo-button__button-area">
          <img
            v-if="isUpdating"
            src="@/assets/img/loading_circle.gif"
            class="memo-button__updating"
          />
          <Button
            v-else
            class="memo-button__button"
            data-cy="memo-button__button"
            width="200px"
            :disabled="isTooLong"
            @click="updateMemo"
            >{{ $t("save") }}</Button
          >
        </div>
        <Transition name="complete">
          <div v-if="showUploadedMessage" class="memo-button__comlate">{{
            $t("complete")
          }}</div>
        </Transition>
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import IconButton from "@/components/IconButton.vue";
import Overlay from "@/components/Overlay.vue";
import Balloon from "@/components/Balloon.vue";
import TextArea from "@/components/form/TextArea.vue";
import Button from "@/components/Button.vue";

import { BalloonDirection } from "@/const/balloon";
import { Icons } from "@/const/Icons";

import { User } from "@/models/User";
import { Memo } from "@/models/userdata/Memo";
import { showAlert } from "@/util/modal-util";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import * as Sentry from "@sentry/browser";

@Component({
  components: {
    Tooltip,
    IconButton,
    Overlay,
    Balloon,
    TextArea,
    Button
  }
})
export default class MemoButton extends Vue {
  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: String, default: BalloonDirection.TopStart })
  direction!: BalloonDirection;

  get placementClass(): string {
    return "memo-button__form__direction--" + this.direction;
  }

  icons = Icons;
  maxMemoLength = 500;

  memo: string = "";
  completeTimerId: number = 0;
  showForm = false;
  showUploadedMessage = false;
  isUpdating = false;

  created() {
    if (this.hasMemo) {
      this.memo = this.user.memo.content;
    }
  }

  mounted() {
    if (this.historyMemos.length == 0) {
      this.$store.dispatch("history/fetchMemos").catch(error => {
        throw new Error(error);
      });
    }
  }

  destroyed() {
    clearTimeout(this.completeTimerId);
  }

  get memoRemainingCharacterCount(): number {
    return this.maxMemoLength - this.memo.length;
  }

  get isTooLong(): boolean {
    return this.memo.length > this.maxMemoLength;
  }

  get memoRemainingCharacterCountMessage(): string {
    if (this.isTooLong) {
      return this.$i18n.t("memoCharactersRemaining", {
        num: -this.memoRemainingCharacterCount
      }) as string;
    }

    if (this.memo.length > 0) {
      return this.$i18n.t("exceededByMemoCharacters", {
        num: this.memoRemainingCharacterCount
      }) as string;
    }

    return this.$i18n.t("memoCharacterWithin", {
      num: this.memoRemainingCharacterCount
    }) as string;
  }

  get hasMemo(): boolean {
    return this.user.memo.content !== "";
  }

  get isMemoUsers(): boolean {
    return this.$route.name === "memo-users";
  }

  get historyMemos(): Memo[] {
    return this.$store.state.history.memos;
  }

  openForm() {
    this.showForm = true;
    this.$nextTick(() =>
      (this.$refs["memo-button__textarea"] as HTMLElement).focus()
    );
  }
  closeForm() {
    clearTimeout(this.completeTimerId);
    this.showForm = false;
    this.showUploadedMessage = false;
  }

  async updateMemo() {
    this.isUpdating = true;
    try {
      await this.$store.dispatch("memo/updateMemo", {
        userId: this.user.id,
        memoContent: this.memo,
        updatedTimeMsec: this.user.memo.updatedDate.getTime(),
        isMemoPage: this.isMemoUsers
      });
      if (!this.showForm) {
        this.isUpdating = false;
        return;
      }
      this.showUploadedMessage = true;
      this.completeTimerId = window.setTimeout(() => {
        this.closeForm();
      }, 1000);

      UgTag.pushEvent(UgEventTag.UpdateMemo);
    } catch (e) {
      // TOOD 翻訳
      showAlert("メモの更新に失敗しました");
      Sentry.captureException(e);
    } finally {
      if (this.isMemoUsers && this.memo === "") {
        this.$store.commit("search/removeUser", this.user.id);
      }

      this.isUpdating = false;
    }
  }
}
</script>

<style scoped lang="scss">
.complete-enter-active {
  transition: opacity 0.3s;
}
.complete-enter {
  opacity: 0;
}

.memo-button {
  position: relative;
}

.memo-button__balloon {
  position: absolute;
  top: 40px;
  left: -10px;
}

.memo-button__form {
  padding: 20px;
}

.memo-button__title {
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.memo-button__characterLength {
  font-size: 12px;
  text-align: left;
}

.memo-button__characterLength--error {
  color: $colorError;
}

.memo-button__textarea {
  margin-top: 10px;
}

.memo-button__button-area {
  margin-top: 20px;
  text-align: center;
}

.memo-button__updating {
  width: 38px;
  height: 38px;
}

.memo-button__comlate {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: $sizeRadius;
  background-color: $colorDark;
  color: $colorWhite;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

$topY: translateY(0);
$bottomY: translateY(calc(-100% - 46px));

$topBottomEndX: translateX(calc(-100% + 52px));
$topBottomCenterX: translateX(calc(-50% + 20px));

.memo-button__form__direction--top {
  transform: $topBottomCenterX $topY;
}
.memo-button__form__direction--top-start {
  transform: $topY;
}
.memo-button__form__direction--top-end {
  transform: $topBottomEndX $topY;
}

.memo-button__form__direction--bottom {
  transform: $topBottomCenterX $bottomY;
}
.memo-button__form__direction--bottom-start {
  transform: $bottomY;
}
.memo-button__form__direction--bottom-end {
  transform: $topBottomEndX $bottomY;
}

$leftX: translateX(46px);
$rightX: translateX(-100%);

$leftRightStartY: translateY(-52px);
$leftRightEndY: translateY(-100%);
$leftRightCenterY: translateY(calc(-50% - 30px));

.memo-button__form__direction--right {
  transform: $rightX $leftRightCenterY;
}
.memo-button__form__direction--right-start {
  transform: $rightX $leftRightStartY;
}
.memo-button__form__direction--right-end {
  transform: $rightX $leftRightEndY;
}

.memo-button__form__direction--left {
  transform: $leftX $leftRightCenterY;
}
.memo-button__form__direction--left-start {
  transform: $leftX $leftRightStartY;
}
.memo-button__form__direction--left-end {
  transform: $leftX $leftRightEndY;
}
</style>
