export enum SegmentedTrendsTab {
  webView = "webView",
  landing = "landing",
  inflow = "inflow",
  appView = "appView",
  event = "event",
  conversion = "conversion"
}

export enum SegmentedTrendsSelectType {
  row20 = 20,
  row50 = 50,
  row100 = 100,
  row500 = 500
}
