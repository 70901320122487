<i18n src="@/i18n/views/user-trend.json"></i18n>
<template>
  <div class="userTrend">
    <div class="userTrend__header">
      <SearchResultHeader
        class="userTrend__SearchResultHeader"
        :icon-title="ICONS.Analysis"
        :title="userTrendTitle"
      />

      <UserTrendSearchForm
        v-if="isConditionFetched"
        class="userTrend__searchForm"
        data-cy="userTrend__searchForm"
        :condition="condition"
        :client-cvs="clientCvs"
        @click="submit"
      />
      <Loading v-else height="250px" class="userTrend__loading" />
      <div ref="userTrendTabs">
        <UserTrendTabs
          :can-use-webdata-features="canUseWebdataFeatures"
          :is-contract-app="isContractApp"
          :selected-tab="currentTab"
          @changeTab="onChangeTab"
        />
      </div>

      <div
        v-if="condition.validate.isValid"
        class="userTrend__filterFormContainer"
      >
        <div v-t="'displayMode'" class="userTrend__filterFormLabel" />
        <UserTrendViewSwitch
          class="userTrend__viewSwitch"
          :selected-view="showType"
          @click="switchViewType"
        />
        <div v-t="'filterCondition'" class="userTrend__filterFormLabel" />
        <UserTrendFilterDefaultForm
          :condition="filterCondition"
          :label="defaultFormLabel"
          :show-deselect-filter="showDeselectFilter && !isLoading"
          :is-disabled="isLoading"
          @filter="fetchFilterAnalysis"
          @deselect-filter="fetchFilterAnalysis"
        />
      </div>
    </div>

    <div
      v-if="isScatter"
      v-t="'scatterChartDescription'"
      class="userTrend__scatterChartDescription"
    />

    <div class="userTrend__controller">
      <div v-if="!isLoading && !isEmptyData" class="userTrend__componentsForm">
        <UserTrendScatterForm
          v-if="isScatter"
          data-cy="user-trend-scatter-form"
          class="userTrend__scatterForm"
          :value="scatterType"
          :page-type="currentTab"
          :time-span-options="timeSpanOptions"
          :is-compared="isCompared"
          @input="inputScatterForm"
        />

        <div v-else data-cy="user-trend-pager" class="userTrend__pager">
          <AnalysisToolsPagerSelect
            :value="numInRow"
            :select-options="selectOptions"
            @input="updatePagerSelect"
          />
          <AnalysisToolsPagerNav
            class="userTrend__pagerNav"
            :current-page="currentPage"
            :last-page="lastPage"
            @click="updatePagerNav"
          />
        </div>
      </div>

      <div class="userTrend__buttonContainer">
        <LinkCopyButton
          class="userTrend__shareButton"
          :link-text="shareLinkText"
          :icon-size="22"
        />

        <CsvDownloadButton
          class="userTrend__csvDownloadButton"
          :disabled="disabledCsvDownload"
          :is-downloading="csvDownloading"
          :icon-size="22"
          @click="onCsvDownloadClick"
        />
      </div>
    </div>

    <Loading v-if="isLoading" height="100px" class="userTrend__loading" />

    <div
      v-else-if="isEmptyData"
      v-t="'noCorrespondingData'"
      class="userTrend__emptyText"
    />

    <div
      v-else
      ref="userTrendViews"
      data-cy="userTrend__views"
      class="userTrend__views"
    >
      <AnalysisToolsBackButton
        v-if="isDetailed"
        class="userTrend__backButton"
        :page-type="detailedPageType"
        :content="detailedURL"
        @click="fromDetailPage"
      />

      <UserTrendScatter
        v-if="isScatter"
        data-cy="user-trend-scatter"
        :chart-points="chartPoints"
        :page-type="currentTab"
        :scatter-type="scatterType"
        @click-user-icon="userIconClicked"
      />

      <div
        v-else
        data-cy="user-trend-table-container"
        class="userTrend__tableContainer"
      >
        <component
          :is="tableComponent"
          sticky-top="70px"
          :row-data="rowData"
          :is-compared="isCompared"
          :sort-column="sortColumnData"
          :is-detailed="isDetailed"
          @click-user-icon="userIconClicked"
          @sort="sortData"
          @click-url="toDetailPage"
        />

        <div class="userTrend__pager userTrend__pager--bottom">
          <AnalysisToolsPagerSelect
            :value="numInRow"
            :select-options="selectOptions"
            @input="updatePagerSelect"
          />
          <AnalysisToolsPagerNav
            class="userTrend__pagerNav"
            :current-page="currentPage"
            :last-page="lastPage"
            @click="updatePagerNav"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import { handleError, TimeoutError } from "@/util/error-util";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { UserTrendSearchCondition } from "@/models/user-trend/UserTrendSearchCondition";
import {
  UserTrendViewType,
  AnalysisType,
  UserTrendSelectType,
  UserTrendDataType,
  UserTrendColumnType,
  UserTrendScatterXAxisType
} from "@/const/user-trend";
import UserTrendSearchForm from "@/components/user-trend/form/UserTrendSearchForm.vue";
import UserTrendTabs from "@/components/user-trend/UserTrendTabs.vue";
import UserTrendFilterDefaultForm from "@/components/user-trend/form/UserTrendFilterDefaultForm.vue";
import Loading from "@/components/Loading.vue";
import { Icons } from "@/const/Icons";
import { ValidationResult } from "@/models/search/ValidationResult";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { UserTrendRowData } from "@/models/user-trend/UserTrendRowData";
import UserTrendWebViewTable from "@/components/user-trend/table/UserTrendWebViewTable.vue";
import UserTrendLandingTable from "@/components/user-trend/table/UserTrendLandingTable.vue";
import UserTrendInflowTable from "@/components/user-trend/table/UserTrendInflowTable.vue";
import UserTrendAppViewTable from "@/components/user-trend/table/UserTrendAppViewTable.vue";
import AnalysisToolsPagerSelect from "@/components/analysis-tools/AnalysisToolsPagerSelect.vue";
import AnalysisToolsPagerNav from "@/components/analysis-tools/AnalysisToolsPagerNav.vue";
import SelectOption from "@/components/form/SelectOption";
import CsvDownloadButton from "@/components/CsvDownloadButton.vue";
import LinkCopyButton from "@/components/LinkCopyButton.vue";
import UserTrendScatter from "@/components/user-trend/scatter/UserTrendScatter.vue";
import UserTrendScatterForm from "@/components/user-trend/scatter/UserTrendScatterForm.vue";
import { sortColumnData } from "@/components/user-trend/table/table-util";
import { UserTrendScatterType } from "@/models/user-trend/UserTrendScatterType";
import { UserTrendChartPoint } from "@/models/user-trend/UserTrendChartPoint";
import { DateFormat, formatDate } from "@/util/date-util";
import AnalysisToolsBackButton from "@/components/analysis-tools/AnalysisToolsBackButton.vue";
import { AnalysisToolsDetailType } from "@/const/analysis-tools";
import { showAlert } from "@/util/modal-util";
import { UserTrendFilterCondition } from "@/models/user-trend/UserTrendFilterCondition";
import { addHidToUserTrendUrl } from "@/router";
import * as Sentry from "@sentry/browser";
import UserTrendViewSwitch from "@/components/user-trend/UserTrendViewSwitch.vue";
import SearchResultHeader from "@/components/search/SearchResultHeader.vue";

@Component({
  components: {
    UserTrendSearchForm,
    UserTrendTabs,
    UserTrendFilterDefaultForm,
    Loading,
    AnalysisToolsPagerSelect,
    AnalysisToolsPagerNav,
    CsvDownloadButton,
    LinkCopyButton,
    UserTrendScatter,
    UserTrendScatterForm,
    AnalysisToolsBackButton,
    UserTrendViewSwitch,
    SearchResultHeader
  }
})
export default class UserTrend extends Vue {
  showDeselectFilter: boolean = false;
  isConditionFetched: boolean = false;
  isCopiedShareLink: boolean = false;
  isSubmitting: boolean = false;
  ICONS = Icons;
  async created() {
    await this.$store
      .dispatch("userTrends/fetchLatestCondition")
      .catch(error => {
        Sentry.captureException(error);
      });

    // URLにhidがあればhidから条件を復活
    if (this.$route.query.hid) {
      await this.$store
        .dispatch(
          "userTrends/fetchSearchConditionById",
          this.$route.query.hid as string
        )
        .catch(e => {
          // 条件がない、エラーの場合は新しいhidを付けられるように、hidをURLから削除
          const { hid, ...query } = this.$route.query;
          this.$router.replace({ query });

          if (e.response.status === 404) {
            showAlert(this.$i18n.t("invalidUrl") as string);
          } else {
            showAlert(this.$i18n.t("common-error-message") as string);
          }
        });

      // urlがinflow-detailsでhidがinflow-detailsのものでない場合、inflowsにリダイレクト
      if (this.$route.name === AnalysisType.InflowDetail && !this.detailedURL) {
        const path = `/user-trend/${AnalysisType.Inflow}`;
        await this.$router.replace({ path });
      }
    }
    this.isConditionFetched = true;
    this.executeAnalysis();
  }

  // Tab ではなく GlobalNavi で user-ternd page を移動したとき query が引き継がないため
  // コンポーネント内ガードを使用
  // https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
  beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (to?: string | false | ((vm: Vue) => any) | void) => void
  ) {
    if (
      !to.query.hid &&
      !this.isSubmitting &&
      to.name !== AnalysisType.InflowDetail
    ) {
      if (!!from.query.hid) {
        this.$router.replace({
          path: to.path,
          query: from.query
        });
      }

      this.executeAnalysis();
      this.showDeselectFilter = this.filterCondition.value.length > 0;
      return;
    }
    next();
  }

  // 別ページに移動後、再び戻ってきた時、移動前のデータが残り hid が付与されないため
  // 別ページに移動時に store を reset
  destroyed() {
    this.$store.dispatch("userTrends/resetUserTrendsPerPage");
    this.$store.dispatch("userTrends/resetUserTrendsData");
  }

  get isCompared(): Boolean {
    return this.condition.hasComparison;
  }

  get showType(): UserTrendViewType {
    return this.$route.query.view === UserTrendViewType.Scatter
      ? (this.$route.query.view as UserTrendViewType)
      : UserTrendViewType.Table;
  }

  get scatterType(): UserTrendScatterType {
    let xAxisType = this.$store.state.preference.userTrendXAxisDataType;
    if (
      xAxisType === UserTrendScatterXAxisType.count &&
      ![AnalysisType.WebView, AnalysisType.AppView].includes(this.currentTab)
    ) {
      xAxisType = UserTrendScatterXAxisType.visit;
    }
    return new UserTrendScatterType(
      this.$store.getters[this.currentStateQuery].dataType,
      xAxisType,
      this.$store.state.preference.userTrendYAxisDataType
    );
  }

  get chartPoints(): UserTrendChartPoint[] {
    return this.$store.getters[this.query("chartPoints")];
  }

  get timeSpanOptions(): SelectOption[] {
    const baseLabel =
      formatDate(DateFormat.yyyysMsd, this.condition.base.startDate) +
      " - " +
      formatDate(DateFormat.yyyysMsd, this.condition.base.endDate);
    const compLabel =
      formatDate(DateFormat.yyyysMsd, this.condition.comparison!.startDate) +
      " - " +
      formatDate(DateFormat.yyyysMsd, this.condition.comparison!.endDate);
    return [
      { value: "base", label: baseLabel, disabled: false },
      { value: "comparison", label: compLabel, disabled: false }
    ];
  }

  get userTrendTitle() {
    const topTitle = this.$t("topTitle") as string;

    if (this.$route.name === AnalysisType.WebView) {
      return (topTitle + (this.$t("webView") as string)) as string;
    }
    if (this.$route.name === AnalysisType.Landing) {
      return (topTitle + (this.$t("landing") as string)) as string;
    }
    if (this.$route.name === AnalysisType.Inflow) {
      return (topTitle + (this.$t("inflow") as string)) as string;
    }
    if (this.$route.name === AnalysisType.InflowDetail) {
      return (topTitle + (this.$t("inflowDetail") as string)) as string;
    }
    if (this.$route.name === AnalysisType.AppView) {
      return (topTitle + (this.$t("appView") as string)) as string;
    }
  }

  get clientCvs(): ConversionDefinition[] {
    return this.$store.getters["clientSettings/allActiveConversionDefinitions"];
  }

  get isLoading(): boolean {
    return this.$store.getters[this.currentStateQuery].isLoading;
  }

  get canUseWebdataFeatures(): boolean {
    return this.$store.state.app.canUseWebdataFeatures;
  }
  //App契約してるか
  get isContractApp(): boolean {
    return this.$store.state.client.client.isContractApp;
  }
  // Tabの分類(detailを区別しない)
  get currentTab(): AnalysisType {
    return this.$route.name as AnalysisType;
  }
  get condition(): UserTrendSearchCondition {
    return this.$store.state.userTrends.condition;
  }
  get filterCondition(): UserTrendFilterCondition {
    return this.$store.getters[this.currentStateQuery].filter;
  }
  get defaultFormLabel(): String {
    if (this.currentTab === AnalysisType.AppView) {
      return this.$t("screen") as string;
    }
    return this.$t("url") as string;
  }

  get rowData(): UserTrendRowData[] {
    return this.$store.getters[this.query("results")];
  }

  get currentStateQuery(): string {
    return this.query("baseState");
  }

  get sortColumnData(): sortColumnData {
    return {
      type: this.sortType,
      key: this.sortKey,
      isDesc: this.isDesc
    };
  }
  get sortType(): UserTrendColumnType {
    return this.$store.getters[this.currentStateQuery].sortColumn;
  }
  get sortKey(): UserTrendDataType {
    return this.$store.getters[this.currentStateQuery].sortKey;
  }
  get isDesc(): boolean {
    return this.$store.getters[this.currentStateQuery].order === "desc";
  }
  get isEmptyData(): boolean {
    if (this.isScatter) {
      return this.chartPoints.length === 0;
    }

    return this.rowData.length === 0;
  }
  get currentPage(): number {
    return this.$store.getters[this.currentStateQuery].currentPage;
  }
  get lastPage(): number {
    return Math.ceil(
      this.$store.getters[this.currentStateQuery].totalRows! / this.numInRow
    );
  }
  get numInRow(): number {
    return this.$store.getters[this.currentStateQuery].perPage;
  }
  get selectOptions(): SelectOption[] {
    return Object.values(UserTrendSelectType)
      .filter(value => {
        if (typeof value === "number") {
          return value;
        }
      })
      .map(value => {
        return {
          value: value,
          label: String(value),
          disabled: false
        };
      });
  }
  get tableComponent(): Vue.Component {
    switch (this.currentTab) {
      case AnalysisType.Landing:
        return UserTrendLandingTable;
      case AnalysisType.Inflow:
      case AnalysisType.InflowDetail:
        return UserTrendInflowTable;
      case AnalysisType.AppView:
        return UserTrendAppViewTable;
      default:
        return UserTrendWebViewTable;
    }
  }

  get csvDownloading(): boolean {
    return this.$store.getters[this.currentStateQuery].csvDownloading;
  }

  get disabledCsvDownload(): boolean {
    return this.isLoading || this.csvDownloading || this.isEmptyData;
  }

  get isScatter(): boolean {
    return this.showType == UserTrendViewType.Scatter;
  }

  // 詳細検索情報を取得
  get detailedPageType(): AnalysisToolsDetailType | null {
    switch (this.currentTab) {
      case AnalysisType.InflowDetail:
        return AnalysisToolsDetailType.Inflow;
      default:
        return null;
    }
  }

  get detailedURL(): String | null {
    return this.$store.getters[this.currentStateQuery].domain;
  }

  get isDetailed(): boolean {
    return this.currentTab === AnalysisType.InflowDetail;
  }

  get userTrendTabs(): HTMLElement {
    return this.$refs.userTrendTabs as HTMLElement;
  }

  get shareLinkText(): string {
    return location.protocol + "//" + location.host + this.$route.fullPath;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  executeAnalysis() {
    const updateUrlFunction = this.$route.query.hid
      ? undefined
      : addHidToUserTrendUrl(this.$router);

    this.$store
      .dispatch(
        this.query(this.isScatter ? "executeScatter" : "executeTable"),
        updateUrlFunction
      )
      .catch(error => {
        error instanceof TimeoutError
          ? showAlert(error.message)
          : handleError(error);
        Sentry.captureException(error);
      });
  }

  async onChangeTab(tab: AnalysisType) {
    const query = { ...this.$route.query, view: this.showType };

    await this.$router.push({
      path: `/user-trend/${tab}`,
      query
    });

    this.executeAnalysis();
    this.showDeselectFilter = this.filterCondition.value.length > 0;
  }

  query(query: string): string {
    return "userTrends/" + this.currentTab + "/" + query;
  }

  async submit(condition: UserTrendSearchCondition) {
    this.isSubmitting = true;
    const validation: ValidationResult =
      condition !== null ? condition.validate : this.condition.validate;

    // 検索条件の日付およびCVをチェック
    if (validation.isValid) {
      UgTag.pushEvent(UgEventTag.UserTrendCondition);
      // 条件変更時はstoreのデータをリセット
      this.$store.dispatch("userTrends/resetUserTrendsData");

      // 検索条件をstoreに反映させるのはボタンクリック時のみ
      this.$store.commit("userTrends/setCondition", condition);
      const query = { ...this.$route.query, hid: undefined };

      // 通常検索時は、hidを削除するのみ。
      // detailページでの検索時は、元のページに戻る。
      if (this.isDetailed) {
        let path: string = "";
        if (this.currentTab === AnalysisType.InflowDetail) {
          path = `/user-trend/${AnalysisType.Inflow}`;
        }

        await this.$router.push({ path, query });
        this.$store.commit(this.query("setDomain"), null);
      } else {
        await this.$router.replace({ query });
      }
      this.executeAnalysis();
      this.showDeselectFilter = false;
    } else {
      showAlert(validation.errorMessage);
    }
    this.isSubmitting = false;
  }

  fetchFilterAnalysis(filter: UserTrendFilterCondition) {
    // 文字列が空でない場合はUGのイベントを発火
    if (filter.value !== "") {
      UgTag.pushEvent(UgEventTag.UserTrendFilter);
    }
    const detailUrl = this.detailedURL;

    // ページャーなど初期化するため検索済みデータをリセット
    this.$store.commit(this.query("resetData"));

    // リセット後にセットする
    this.$store.commit(this.query("setFilter"), filter);

    if (this.isDetailed) {
      this.$store.commit(this.query("setDomain"), detailUrl);
    }

    if (this.isScatter) {
      this.$store.dispatch(this.query("fetchScatter")).catch(error => {
        handleError(error);
        Sentry.captureException(error);
      });
    } else {
      this.$store.dispatch(this.query("fetchResults")).catch(error => {
        handleError(error);
        Sentry.captureException(error);
      });
    }

    this.showDeselectFilter = filter.value.length > 0;
  }

  async switchViewType(view: UserTrendViewType) {
    const query = { ...this.$route.query, view };
    await this.$router.replace({ query });

    UgTag.pushPv();
    this.executeAnalysis();
  }

  userIconClicked(userIds: string[]) {
    UgTag.pushEvent(UgEventTag.UserTrendToUserList);
    this.$store.dispatch("search/goToUserList", userIds).catch(error => {
      Sentry.captureException(error);
    });
    const query: { [key: string]: string } = {
      view: this.$store.state.preference.searchResultView
    };
    this.$router.push({ name: "users", query });
  }

  sortData(sortColumnData: sortColumnData) {
    // storeの変数の更新
    this.$store.commit(
      this.query("setSortOrder"),
      sortColumnData.isDesc ? "desc" : "asc"
    );
    // sortカラムの更新
    this.$store.commit(this.query("setSortColumn"), sortColumnData.type);

    // sortKeyの更新
    this.$store.commit(this.query("setSortKey"), sortColumnData.key);

    // dataの取得
    this.$store.dispatch(this.query("fetchResults")).catch(error => {
      handleError(error);
      Sentry.captureException(error);
    });
  }

  updatePagerSelect(numInRow: number) {
    // TODO:preferenceにsetする
    this.$store.commit(this.query("setPerPage"), numInRow);
    this.$store.commit(this.query("setCurrentPage"), 1);
    this.$store
      .dispatch(this.query("fetchResults"))
      .then(() => {
        this.onScrollToUserTrendController();
      })
      .catch(error => {
        handleError(error);
        Sentry.captureException(error);
      });
  }

  updatePagerNav(direction: number) {
    this.$store.commit(
      this.query("setCurrentPage"),
      this.currentPage + direction
    );
    this.$store
      .dispatch(this.query("fetchResults"))
      .then(() => {
        this.onScrollToUserTrendController();
      })
      .catch(error => {
        handleError(error);
        Sentry.captureException(error);
      });
  }

  onCsvDownloadClick() {
    UgTag.pushEvent(UgEventTag.UserTrendCsv);
    this.$store.dispatch(this.query("downloadCsv")).catch(error => {
      handleError(error);
      Sentry.captureException(error);
    });
  }

  inputScatterForm(scatterType: UserTrendScatterType) {
    const toFetch: boolean =
      this.scatterType.xAxisType === scatterType.xAxisType &&
      this.scatterType.yAxisType === scatterType.yAxisType;

    // store変数の更新
    this.$store.commit(this.query("setDataType"), scatterType.type);
    this.$store.commit(
      "preference/setUserTrendXAxisDataType",
      scatterType.xAxisType
    );
    this.$store.commit(
      "preference/setUserTrendYAxisDataType",
      scatterType.yAxisType
    );

    if (toFetch) {
      // storeからapiを叩きデータ取得
      this.$store.dispatch(this.query("fetchScatter")).catch(error => {
        handleError(error);
        Sentry.captureException(error);
      });
    }
  }

  // 詳細ページに移動する
  async toDetailPage(url: String) {
    let path: string = "";
    if (this.currentTab === AnalysisType.Inflow) {
      path = `/user-trend/${AnalysisType.InflowDetail}`;
      this.$store.commit("userTrends/inflow-details/resetData");
    }

    const { hid, ...query } = this.$route.query;
    query.view = this.showType;

    await this.$router.push({ path, query });
    this.$store.commit(this.query("setDomain"), url);
    this.executeAnalysis();
    this.showDeselectFilter = false;

    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  }

  // 詳細ページから戻ってくる
  async fromDetailPage() {
    let path: string = "";
    if (this.currentTab === AnalysisType.InflowDetail) {
      path = `/user-trend/${AnalysisType.Inflow}`;
    }

    const query = {
      ...this.$route.query,
      view: this.showType
    };

    await this.$router.push({ path, query });
    this.executeAnalysis();
    this.showDeselectFilter = this.filterCondition.value.length > 0;
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  }

  onScrollToUserTrendController() {
    window.scrollTo(0, this.userTrendTabs.offsetTop - 70);
  }
}
</script>

<style scoped lang="scss">
.userTrend {
  padding: 20px 20px 95px;
  min-height: 1200px;
}
.userTrend__searchForm {
  margin: 20px 0;
  padding: 25px 20px;
  border: 1px solid $colorBase500;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.userTrend__controller {
  position: relative;
  margin-top: 30px;
  padding: 0 15px;
  width: 100%;
  height: $formPartsHeight;
  background-color: $colorWhite;
}

.userTrend__buttonContainer {
  position: absolute;
  top: 4px;
  right: 15px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.userTrend__shareButton {
  position: relative;
  margin-right: 10px;
  margin-left: 15px;
  padding-left: 15px;
  width: 37px;
}

.userTrend__backButton {
  margin-bottom: 15px;
}

.userTrend__pager {
  display: flex;
  align-items: center;
}

.userTrend__pager--bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.userTrend__pagerNav {
  margin-left: 15px;
}

.userTrend__emptyText {
  padding-top: 30px;
  height: 200px;
  text-align: center;
}

.userTrend__views {
  margin-top: 15px;
}
.userTrend__filterFormContainer {
  align-items: center;
  background-color: $colorWhite;
  border: 1px solid $colorBase500;
  border-radius: $sizeRadius;
  column-gap: 30px;
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 20px;
  padding: 25px 20px;
  row-gap: 20px;
}
.userTrend__filterFormLabel {
  font-weight: bold;
}
.userTrend__SearchResultHeader {
  padding: 0 0 4px;
  color: $colorGray1000;
}
.userTrend__scatterChartDescription {
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-size: 12px;
  color: $colorBase700;
}
</style>
