<i18n src="@/i18n/components/mfa/otp-form"></i18n>

<template>
  <div class="otpForm">
    <InputText
      v-model="otpCode"
      data-cy="otp-code-input"
      class="otpForm__otpInputText"
      :placeholder="$t('placeholder')"
      :has-error="shouldShowMessageForInvalidOtpCode"
    />
    <p
      v-if="shouldShowMessageForInvalidOtpCode"
      data-cy="otp-error-message"
      class="otpForm__errorMessage"
    >
      {{ $t("validationError") }}
    </p>
    <Button
      data-cy="otp-submit-button"
      :style="buttonMarginTop"
      :width="submitButtonWidth"
      :height="submitButtonHeight"
      :disabled="!hasValidFormatOtpCode"
      @click="onClick(value)"
      >{{ $t("submitText") }}
    </Button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import InputText from "@/components/form/InputText.vue";
import Button from "@/components/Button.vue";
import { isNumberText } from "@/util/string-util";
import { Properties } from "csstype";

@Component({
  components: {
    InputText,
    Button
  }
})
export default class OtpForm extends Vue {
  submitButtonWidth = "354px";
  submitButtonHeight = "35px";

  @Prop({ type: String, default: "" })
  value!: string;

  @Prop({ type: String, default: "20px" })
  spacing!: string;

  get otpCode(): string {
    return this.value;
  }

  set otpCode(newCode: string) {
    this.$emit("input", newCode);
  }

  get hasValidFormatOtpCode(): boolean {
    return this.value.length === 6 && isNumberText(this.value);
  }
  get shouldShowMessageForInvalidOtpCode(): boolean {
    return this.value.length > 0 && !this.hasValidFormatOtpCode;
  }

  get buttonMarginTop() {
    let properties: Properties = {
      marginTop: this.spacing
    };
    return properties;
  }

  onClick(otpCode: string) {
    this.$emit("submit", otpCode);
  }
}
</script>

<style lang="scss" scoped>
.otpForm {
  width: 354px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.otpForm__otpInputText {
  width: 100%;
}

.otpForm__errorMessage {
  position: absolute;
  left: 0;
  top: 38px;
  color: $colorError;
}
</style>
