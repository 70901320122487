import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import {
  FilterNodeParamForLaunchApp,
  FilterNodeParamForConversion
} from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { getHourlyInterval } from "@/util/date-util";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import {
  FilterNodeType,
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  FilterLaunchAppAdditionalCondition,
  conditionName,
  globalConversionAttributeIdToFilterNodeConditionType
} from "@/models/search/filter-node-condition/FilterLaunchAppAdditionalCondition";
import SelectOption from "@/components/form/SelectOption";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

/**
 * アプリ起動タイプ
 */
export enum AppLaunchType {
  PushNotification = 1,
  Link = 2,
  FirstOpen = 3,
  AppRemove = 4
}

/**
 * アプリ起動による絞り込み条件
 *
 * 選択した種類のアプリ起動（プッシュ通知orリンク）があるユーザを絞り込む
 *
 * 初回、期間、日付と時間帯 を条件として追加できる
 */
export class FilterNodeForLaunchApp {
  public readonly nodeType = FilterNodeType.LaunchApp;

  constructor(
    // アプリ起動タイプ
    public readonly launchType: AppLaunchType,
    /**
     * 追加の検索条件
     *
     * アプリ起動追加条件(string)初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
    )[],
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion()
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate(): ValidationResult {
    return validateConditions(this.additionalConditions);
  }

  isConditionAddable(isFirstNode: boolean): boolean {
    const numConditions = this.additionalConditions.length;

    if (isConversionType(this.launchType)) {
      // OR-node case
      if (this.depth !== 0) return false;

      return isFirstNode ? numConditions < 2 : numConditions < 1;
    }

    if (
      this.depth !== 0 &&
      this.launchType === AppLaunchType.PushNotification
    ) {
      return numConditions < 1;
    }

    return numConditions < 2;
  }

  insertEdge(): FilterNodeForLaunchApp {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForLaunchApp(
      this.launchType,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion
    );
  }

  removeEdge(): FilterNodeForLaunchApp {
    return new FilterNodeForLaunchApp(
      this.launchType,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion
    );
  }

  removeFirstTimeCondition(): FilterNodeForLaunchApp {
    return new FilterNodeForLaunchApp(
      this.launchType,
      this.additionalConditions.filter(
        cnd => !(cnd instanceof FilterFirstTimeCondition)
      ),
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion
    );
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    let launchAppOptions: SelectOption[] = [
      {
        value: FilterNodeConditionType.None,
        label: i18n.t("models.search.activationType") as string,
        disabled: true
      }
    ];
    if (this.launchType === AppLaunchType.PushNotification) {
      launchAppOptions = this.pushOptions(this.additionalConditions);
    }
    if (this.launchType === AppLaunchType.Link) {
      launchAppOptions = this.linkOptions(this.additionalConditions);
    }

    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: launchAppOptions
      }
    ];

    if (this.depth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }

  private pushOptions(
    additionalConditions: (
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
    )[]
  ): SelectOption[] {
    const hasPushLabel = additionalConditions.some(
      cnd => cnd.conditionType === FilterNodeConditionType.PushLabel
    );
    return [
      {
        value: FilterNodeConditionType.PushLabel,
        label: conditionName(FilterNodeConditionType.PushLabel),
        disabled: hasPushLabel // 既にPushLabelが選択されていたら、disabled
      }
    ];
  }

  private linkOptions(
    additionalConditions: (
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
    )[]
  ): SelectOption[] {
    return [
      {
        value: FilterNodeConditionType.LinkCampaign,
        label: conditionName(FilterNodeConditionType.LinkCampaign),
        disabled: false
      },
      {
        value: FilterNodeConditionType.LinkSource,
        label: conditionName(FilterNodeConditionType.LinkSource),
        disabled: false
      },
      {
        value: FilterNodeConditionType.LinkMedium,
        label: conditionName(FilterNodeConditionType.LinkMedium),
        disabled: false
      },
      {
        value: FilterNodeConditionType.LinkLinkName,
        label: conditionName(FilterNodeConditionType.LinkLinkName),
        disabled: false
      },
      {
        value: FilterNodeConditionType.LinkLinkValue,
        label: conditionName(FilterNodeConditionType.LinkLinkValue),
        disabled: false
      }
    ].map(option => {
      // 既に選択されている物はdisabled
      option.disabled = additionalConditions.some(
        cnd => cnd.conditionType === option.value
      );
      return option;
    });
  }
}

export function convertFilterNodeForLaunchAppToJson(
  node: FilterNodeForLaunchApp,
  globalConversionDefinitions: ConversionDefinition[]
): FilterNodeParamForLaunchApp | FilterNodeParamForConversion {
  if (isConversionType(node.launchType)) {
    return toConversionJson(node, globalConversionDefinitions);
  }
  return toLaunchAppJson(node);
}

function toLaunchAppJson(
  node: FilterNodeForLaunchApp
): FilterNodeParamForLaunchApp {
  const result: FilterNodeParamForLaunchApp = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.LaunchApp,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    launch_type: node.launchType,
    launch_attributes: []
  };

  for (const condition of node.additionalConditions) {
    if (condition instanceof FilterLaunchAppAdditionalCondition) {
      result.launch_attributes.push({
        id: condition.conversionAttributeId,
        value: condition.value,
        word_match_method: condition.matchMethod
      });
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}
function toConversionJson(
  node: FilterNodeForLaunchApp,
  globalConversionDefinitions: ConversionDefinition[]
): FilterNodeParamForConversion {
  let cvId = 0;
  // globalConversionDefinitionsの1つ目に初回起動、2つ目にアプリ削除が入っている前提
  // 順番はsystem.tsのfetchConversionDefinitionsでセットしている
  if (
    globalConversionDefinitions.length > 0 &&
    node.launchType === AppLaunchType.FirstOpen
  ) {
    cvId = globalConversionDefinitions[0].id;
  }
  if (
    globalConversionDefinitions.length > 1 &&
    node.launchType === AppLaunchType.AppRemove
  ) {
    cvId = globalConversionDefinitions[1].id;
  }

  const result: FilterNodeParamForConversion = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.Conversion,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    cv: {
      id: cvId,
      attributes: []
    }
  };

  for (const condition of node.additionalConditions) {
    if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForLaunchApp(
  json: FilterNodeParamForLaunchApp,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterNodeForLaunchApp {
  const additionalConditions: (
    | FilterAdditionalTimingCondition
    | FilterLaunchAppAdditionalCondition
  )[] = getAdditionalTimingConditions(json);

  json.launch_attributes.forEach(attr => {
    const conditionType = globalConversionAttributeIdToFilterNodeConditionType(
      attr.id,
      globalConversionAttributeDefinitions
    );
    additionalConditions.push(
      new FilterLaunchAppAdditionalCondition(
        conditionType,
        attr.id,
        attr.value,
        attr.word_match_method
      )
    );
  });

  return new FilterNodeForLaunchApp(
    json.launch_type,
    additionalConditions,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!)
  );
}

// 初回起動、アプリ削除の場合はConversion扱いなので、別関数でJsonからモデルに戻す
export function convertConversionJsonToFilterNodeForLaunchApp(
  json: FilterNodeParamForConversion,
  globalConversionDefinitions: ConversionDefinition[]
): FilterNodeForLaunchApp {
  const additionalConditions: (
    | FilterAdditionalTimingCondition
    | FilterLaunchAppAdditionalCondition
  )[] = getAdditionalTimingConditions(json);

  let launchType = AppLaunchType.FirstOpen;
  // globalConversionDefinitionsの1つ目に初回起動、2つ目にアプリ削除が入っている前提
  // 順番はsystem.tsのfetchConversionDefinitionsでセットしている
  if (
    globalConversionDefinitions.length > 0 &&
    json.cv.id === globalConversionDefinitions[0].id
  ) {
    launchType = AppLaunchType.FirstOpen;
  }
  if (
    globalConversionDefinitions.length > 1 &&
    json.cv.id === globalConversionDefinitions[1].id
  ) {
    launchType = AppLaunchType.AppRemove;
  }

  return new FilterNodeForLaunchApp(
    launchType,
    additionalConditions,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!)
  );
}

function isConversionType(launchType: AppLaunchType): boolean {
  return [AppLaunchType.FirstOpen, AppLaunchType.AppRemove].includes(
    launchType
  );
}
