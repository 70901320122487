<template>
  <g
    class="chartGridLine"
    :height="gridLineGroupHeight"
    :transform="gridLineGroupTransform"
  >
    <template v-for="(date, i) in dateRange">
      <text
        v-if="showGridLineText(i)"
        :key="'gridLineText' + i"
        :dy="gridLineTextY"
        :dx="getGridLineTextX(i)"
        font-size="10"
        class="chartGridLine__text"
      >
        {{ date }}
      </text>
    </template>

    <path :d="gridLinePath" class="chartGridLine__path" />
  </g>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ALL_PLOT_PERIOD,
  START_LINE_X,
  BASELINE_EDGE_LENGTH,
  SIX_MONTH_BASELINE_EDGE_LENGTH,
  GRIDLINE_HEIGHT,
  GRIDLINE_GROUP_HEIGHT,
  getDiffDays,
  getMonthlyDateStrings,
  getOneMonthDateStrings,
  getMonthlyDates
} from "@/components/chart/chart-util";

const WEEK = 7;

@Component
export default class ChartGridLine extends Vue {
  @Prop({ type: Number, required: true })
  y!: number;

  @Prop({ type: Number, required: true })
  baselineLength!: number;

  @Prop({ type: Date, required: true })
  firstScaleDate!: Date;

  @Prop({ type: Date, required: true })
  lastScaleDate!: Date;

  @Prop({ type: Boolean, required: true })
  isOneMonth!: boolean;

  @Prop({ type: Boolean, required: true })
  isSixMonth!: boolean;

  gridLineGroupHeight = GRIDLINE_GROUP_HEIGHT;

  get gridLineTextY(): number {
    return GRIDLINE_HEIGHT * 2 + 4;
  }

  get gridLineGroupTransform(): string {
    return "translate(0, " + (this.y - GRIDLINE_HEIGHT) + ")";
  }

  get dateRange(): string[] {
    if (this.isOneMonth) {
      return getOneMonthDateStrings(this.firstScaleDate, this.lastScaleDate);
    }

    const dates: Date[] = getMonthlyDates(
      new Date(this.firstScaleDate),
      ALL_PLOT_PERIOD
    );

    return getMonthlyDateStrings(dates);
  }

  get gridLinePath(): string {
    let path: string = "";

    this.dateRange.forEach((date, i) => {
      const x: number = this.getGridLineTextX(i);
      path += `M ${x} ${0} V ${GRIDLINE_HEIGHT} `;
    });

    return path;
  }

  showGridLineText(index: number): boolean {
    return !this.isOneMonth || index % WEEK === 0;
  }

  getGridLineTextX(index: number): number {
    const start = this.isSixMonth
      ? START_LINE_X + SIX_MONTH_BASELINE_EDGE_LENGTH
      : START_LINE_X + BASELINE_EDGE_LENGTH;
    const gradations: number = this.isOneMonth
      ? getDiffDays(this.firstScaleDate, this.lastScaleDate)
      : ALL_PLOT_PERIOD;
    const unitWidth = this.baselineLength / gradations;

    return start + unitWidth * index;
  }
}
</script>

<style scoped lang="scss">
.chartGridLine__path {
  stroke: $colorBase700;
}

.chartGridLine__text {
  cursor: text;

  text-anchor: middle;
  fill: $colorBase700;
}
</style>
