import { ApiRes } from "@/api/api-res";
import { PLATFORM_CATEGORY, PLATFORM_DEVICE_CATEGORY } from "@/const/gram";
import { i18n } from "@/i18n";

/**
 * 利用端末
 *
 * ユーザが使用している端末名を配列で持つ
 * 端末名は以下の5種類
 * iPhane、iPad、Android、PC、その他のモバイル端末
 *
 * WEB計測とアプリ計測の両方契約しているクライアントの場合は、
 * 端末名の後ろに(Web) か (App) がついて、とどちらからのアクセスかも表示される
 *
 */
export class Device {
  constructor(
    // 端末名
    private readonly devices: string[]
  ) {}

  // 表示する時は配列をカンマ区切りしたものを使う
  public get toString(): string {
    return this.devices.join(", ");
  }

  public static build(
    json: ApiRes.Platform[],
    canUseWebdataFeaturesAndIsContractApp: boolean
  ): Device {
    const devices: string[] = [];

    for (const platform of json) {
      const platformCategory = platform.platform_category;
      for (const platformSubCategory of platform.platform_sub_categories) {
        const device = this.getDeviceFromCategory(
          platformCategory,
          platformSubCategory,
          canUseWebdataFeaturesAndIsContractApp
        );
        if (device !== null) {
          devices.push(device);
        }
      }
    }

    return new Device(devices);
  }

  public static getDeviceFromCategory(
    platformCategory: number,
    platformSubCategory: number,
    canUseWebdataFeaturesAndIsContractApp: boolean
  ): string | null {
    const isWeb = platformCategory === PLATFORM_CATEGORY.WEB.ID;
    const isApp = platformCategory === PLATFORM_CATEGORY.APP.ID;

    // Webかアプリ以外なら空を返す
    if (!isWeb && !isApp) {
      return null;
    }

    let device = null;
    switch (platformSubCategory) {
      case PLATFORM_DEVICE_CATEGORY.IPHONE:
        device = i18n.t("models.device.iPhone") as string;
        break;
      case PLATFORM_DEVICE_CATEGORY.ANDROID:
        device = i18n.t("models.device.android") as string;
        break;
      case PLATFORM_DEVICE_CATEGORY.IPAD:
        device = i18n.t("models.device.iPad") as string;
        break;
      case PLATFORM_DEVICE_CATEGORY.OTHERS:
        device = isWeb
          ? (i18n.t("models.device.pc") as string)
          : (i18n.t("models.device.otherMobileDevice") as string);
        break;
    }

    // Webもアプリも契約している場合は、どちらからのアクセスかを表示
    if (canUseWebdataFeaturesAndIsContractApp && device !== null) {
      const contractWebAndAppText = (isWeb
        ? i18n.t("models.device.web")
        : i18n.t("models.device.app")) as string;
      device += `(${contractWebAndAppText})`;
    }

    return device;
  }
}
