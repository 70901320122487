import { TdWebImportConfigDetailResponse } from "@/api/apis/client-settings/ApiTdWebImportConfig";
export enum TimestampFormatType {
  UnixTimeSecond = 1,
  UnixTimeMiliSecond = 2,
  DateAndTime = 3,
  NotAvailable = 4,
  DateTimeWithTimezone = 5
}
export class TdWebImportConfigDefinition {
  constructor(
    public readonly timestampColumn: string,
    public readonly timestampFormatType: TimestampFormatType,
    public readonly serviceIdColumn: string,
    public readonly platformIdColumn: string,
    public readonly locationUriColumn: string,
    public readonly locationNameColumn: string,
    public readonly referrerUriColumn: string,
    public readonly userAgentColumn: string,
    public readonly screenResolutionColumn: string,
    public readonly ipAddressColumn: string,
    public readonly navigationTypeColumn: string
  ) {}

  public static fromJson(
    json: TdWebImportConfigDetailResponse
  ): TdWebImportConfigDefinition {
    return new TdWebImportConfigDefinition(
      json.timestamp_column,
      json.timestamp_format_type,
      json.service_id_column,
      json.platform_id_column,
      json.location_uri_column,
      json.location_name_column,
      json.referrer_uri_column,
      json.user_agent_column,
      setEmptyIfNull(json.screen_resolution_column),
      setEmptyIfNull(json.ip_address_column),
      setEmptyIfNull(json.navigation_type_column)
    );
  }

  public toJson(): TdWebImportConfigDetailResponse {
    return {
      timestamp_column: this.timestampColumn,
      timestamp_format_type: this.timestampFormatType,
      service_id_column: this.serviceIdColumn,
      platform_id_column: this.platformIdColumn,
      location_uri_column: this.locationUriColumn,
      location_name_column: this.locationNameColumn,
      referrer_uri_column: this.referrerUriColumn,
      user_agent_column: this.userAgentColumn,
      screen_resolution_column: setNullIfEmpty(this.screenResolutionColumn),
      ip_address_column: setNullIfEmpty(this.ipAddressColumn),
      navigation_type_column: setNullIfEmpty(this.navigationTypeColumn)
    };
  }

  public isDefined(): boolean {
    return this.timestampColumn !== "";
  }

  public update<K extends keyof TdWebImportConfigDefinition>(
    key: K,
    value: TdWebImportConfigDefinition[K]
  ): TdWebImportConfigDefinition {
    const tdWebImportConfig = new TdWebImportConfigDefinition(
      this.timestampColumn,
      this.timestampFormatType,
      this.serviceIdColumn,
      this.platformIdColumn,
      this.locationUriColumn,
      this.locationNameColumn,
      this.referrerUriColumn,
      this.userAgentColumn,
      this.screenResolutionColumn,
      this.ipAddressColumn,
      this.navigationTypeColumn
    );

    tdWebImportConfig[key] = value;

    return tdWebImportConfig;
  }

  public copy(): TdWebImportConfigDefinition {
    return new TdWebImportConfigDefinition(
      this.timestampColumn,
      this.timestampFormatType,
      this.serviceIdColumn,
      this.platformIdColumn,
      this.locationUriColumn,
      this.locationNameColumn,
      this.referrerUriColumn,
      this.userAgentColumn,
      this.screenResolutionColumn,
      this.ipAddressColumn,
      this.navigationTypeColumn
    );
  }
}

function setNullIfEmpty(fieldValue: string): string | null {
  if (fieldValue === "") {
    return null;
  }
  return fieldValue;
}

function setEmptyIfNull(fieldValue: string | null): string {
  if (fieldValue === null) {
    return "";
  }
  return fieldValue;
}
