var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"select-by-nps-change-form"},[_c('div',{staticClass:"select-by-nps-change-form__title"},[_c('TitleTextWithIcon',{attrs:{"title":_vm.$t('scoreChange'),"icon":_vm.iconConversion}})],1),_c('div',{staticClass:"select-by-nps-change-form__label-type-wrapper"},[_c('div',{staticClass:"select-by-nps-change-form__label-type-items",attrs:{"data-cy":"nps-previous-items"}},[_c('div',{staticClass:"select-by-nps-change-form__label-type-title"},[_vm._v(" "+_vm._s(_vm.$t("previous"))+" "),_c('div',{directives:[{name:"t",rawName:"v-t",value:('multipleSelect'),expression:"'multipleSelect'"}],staticClass:"select-by-nps-change-form__label-type-note"})]),_c('div',{staticClass:"select-by-nps-change-form__label-type-item",class:{
          'select-by-nps-change-form__label-type-item__selected':
            _vm.condition.previousNpsLabels.indexOf(_vm.npsLabel.Detractor) >= 0
        },on:{"click":function($event){return _vm.onClickPreviousLabel(_vm.npsLabel.Detractor)}}},[_vm._v(" "+_vm._s(_vm.$t("low"))+" "),_c('div',{staticClass:"select-by-nps-change-form__score"},[_vm._v(_vm._s(_vm.loyaltyLowScore))])]),_c('div',{staticClass:"select-by-nps-change-form__label-type-item",class:{
          'select-by-nps-change-form__label-type-item__selected':
            _vm.condition.previousNpsLabels.indexOf(_vm.npsLabel.Passive) >= 0
        },attrs:{"data-cy":"nps-previous-item"},on:{"click":function($event){return _vm.onClickPreviousLabel(_vm.npsLabel.Passive)}}},[_vm._v(" "+_vm._s(_vm.$t("middle"))+" "),_c('div',{staticClass:"select-by-nps-change-form__score"},[_vm._v(_vm._s(_vm.loyaltyMediumScore))])]),_c('div',{staticClass:"select-by-nps-change-form__label-type-item",class:{
          'select-by-nps-change-form__label-type-item__selected':
            _vm.condition.previousNpsLabels.indexOf(_vm.npsLabel.Promoter) >= 0
        },on:{"click":function($event){return _vm.onClickPreviousLabel(_vm.npsLabel.Promoter)}}},[_vm._v(" "+_vm._s(_vm.$t("high"))+" "),_c('div',{staticClass:"select-by-nps-change-form__score"},[_vm._v(_vm._s(_vm.loyaltyHighScore))])])])]),_c('div',{staticClass:"select-by-nps-change-form__down-arrow"},[_c('Icon',{attrs:{"icon":_vm.iconDownArrow,"size":30,"color":_vm.downArrowColor}})],1),_c('div',{staticClass:"select-by-nps-change-form__label-type-wrapper"},[_c('div',{staticClass:"select-by-nps-change-form__label-type-items",attrs:{"data-cy":"nps-recent-items"}},[_c('div',{directives:[{name:"t",rawName:"v-t",value:('recent'),expression:"'recent'"}],staticClass:"select-by-nps-change-form__label-type-title"}),_c('div',{staticClass:"select-by-nps-change-form__label-type-item",class:{
          'select-by-nps-change-form__label-type-item__selected':
            _vm.condition.npsLabel === _vm.npsLabel.Detractor
        },attrs:{"data-cy":"nps-recent-item"},on:{"click":function($event){return _vm.onClickLabel(_vm.npsLabel.Detractor)}}},[_vm._v(" "+_vm._s(_vm.$t("low"))+" "),_c('div',{staticClass:"select-by-nps-change-form__score"},[_vm._v(_vm._s(_vm.loyaltyLowScore))])]),_c('div',{staticClass:"select-by-nps-change-form__label-type-item",class:{
          'select-by-nps-change-form__label-type-item__selected':
            _vm.condition.npsLabel === _vm.npsLabel.Passive
        },on:{"click":function($event){return _vm.onClickLabel(_vm.npsLabel.Passive)}}},[_vm._v(" "+_vm._s(_vm.$t("middle"))+" "),_c('div',{staticClass:"select-by-nps-change-form__score"},[_vm._v(_vm._s(_vm.loyaltyMediumScore))])]),_c('div',{staticClass:"select-by-nps-change-form__label-type-item",class:{
          'select-by-nps-change-form__label-type-item__selected':
            _vm.condition.npsLabel === _vm.npsLabel.Promoter
        },on:{"click":function($event){return _vm.onClickLabel(_vm.npsLabel.Promoter)}}},[_vm._v(" "+_vm._s(_vm.$t("high"))+" "),_c('div',{staticClass:"select-by-nps-change-form__score"},[_vm._v(_vm._s(_vm.loyaltyHighScore))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }