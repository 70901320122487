<i18n
  src="@/i18n/components/observation/user-header/company-activity-display-setting.json"
></i18n>
<template>
  <div class="company-activity-display-setting">
    <Tooltip :placement="tooltipPlacement.BottomEnd" :text="tooltipText">
      <IconButton
        class="company-activity-display-setting__icon-button"
        :icon="iconDots"
        :size="28"
        :icon-scale="0.6"
        :background="true"
        :button-type="IconButtonType.CompanyActivity"
        @click="onClickIconButton"
      />
    </Tooltip>
    <Overlay v-if="isShowBalloon" @click="onClickOverlay" />
    <div class="company-activity-display-setting__balloon">
      <Balloon
        v-if="isShowBalloon"
        class="company-activity-display-setting__balloon-wrapper"
        :direction="balloonDirection.TopEnd"
      >
        <div class="company-activity-display-setting__balloon-inner">
          <CheckBoxList
            v-model="selectActionSettings"
            :column="1"
            :options="actionSettingOptions"
          />

          <Button width="100%" @click="onClickSubmitButton">OK</Button>
        </div>
      </Balloon>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyActivity } from "@/models/CompanyActivity";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { TooltipPlacement } from "@/const/tooltip";
import { BalloonDirection } from "@/const/balloon";
import Tooltip from "@/components/Tooltip.vue";
import IconButton from "@/components/IconButton.vue";
import Balloon from "@/components/Balloon.vue";
import Overlay from "@/components/Overlay.vue";
import Button from "@/components/Button.vue";
import CheckBoxList from "@/components/form/CheckBoxList.vue";
import SelectOption from "@/components/form/SelectOption";

@Component({
  components: {
    Tooltip,
    IconButton,
    Balloon,
    Button,
    Overlay,
    CheckBoxList
  }
})
export default class CompanyActivityDisplaySetting extends Vue {
  isShowBalloon = false;
  selectActionSettings: number[] = [];
  iconDots = Icons.Dots;
  tooltipPlacement = TooltipPlacement;
  balloonDirection = BalloonDirection;
  IconButtonType = IconButtonType;

  @Prop({ type: Array, required: true })
  companyActivityDisplaySettings!: CompanyActivity[];

  updateSettings(companyActivities: CompanyActivity[]) {
    this.$emit("update", companyActivities);
  }

  get tooltipText(): string {
    return this.$i18n.t("tooltip") as string;
  }

  get actionSettingOptions(): SelectOption[] {
    return this.companyActivityDisplaySettings.map(c => {
      const a = new CompanyActivity(c.contactDefinition);
      const select: SelectOption = {
        value: a.contactDefinition.id,
        label: a.contactDefinition.name,
        disabled: false
      };

      return select;
    });
  }

  onClickIconButton() {
    const settings = this.companyActivityDisplaySettings.filter(
      c => c.isDisplay
    );
    this.selectActionSettings = settings.map(setting => {
      const c = new CompanyActivity(setting.contactDefinition);
      return c.contactDefinition.id;
    });
    this.isShowBalloon = true;
  }

  onClickOverlay() {
    this.isShowBalloon = false;
  }

  onClickSubmitButton() {
    const currentActionSettings: CompanyActivity[] = this.companyActivityDisplaySettings.map(
      companyActivityDisplaySetting => {
        const companyActivity = new CompanyActivity(
          companyActivityDisplaySetting.contactDefinition
        );

        if (
          !this.selectActionSettings.some(
            select => select === companyActivity.contactDefinition.id
          )
        ) {
          companyActivity.isDisplay = false;
        }

        return companyActivity;
      }
    );
    this.isShowBalloon = false;
    this.updateSettings(currentActionSettings);
  }
}
</script>

<style scoped lang="scss">
.company-activity-display-setting {
  position: relative;
  display: inline-block;

  &__icon-button {
    border: 2px solid $colorBase600;
    border-radius: 50%;

    &:hover {
      border-color: $colorBase900;
    }
  }

  &__balloon {
    position: absolute;
    top: 40px;
    right: -12px;
    width: 220px;

    &-wrapper {
      width: 100%;
    }

    &-inner {
      padding: 20px;
    }
  }
}
</style>
