import { Colors } from "@/const/Colors";

export type IconButtonColorType = {
  unhoverIconColor: Colors;
  hoveredIconColor: Colors;
  selectedIconColor: Colors;
  disabledIconColor: Colors;
  unhoverBackgroundColor: Colors;
  hoveredBackgroundColor: Colors;
  disabledBackgroundColor: Colors;
};

type IconButtonType =
  | "Weak"
  | "Strong"
  | "Default"
  | "UserSearchArrow"
  | "UserSearchEvent"
  | "Favorite"
  | "CompanyActivity"
  | "Tour"
  | "UserTrend";

export const IconButtonType: Record<IconButtonType, IconButtonColorType> = {
  //iconSize <= 16px
  Strong: {
    unhoverIconColor: Colors.Base700,
    hoveredIconColor: Colors.Base900,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base500,
    unhoverBackgroundColor: Colors.Clear,
    hoveredBackgroundColor: Colors.Blue600,
    disabledBackgroundColor: Colors.Clear
  },
  // iconSize > 16px
  Weak: {
    unhoverIconColor: Colors.Base600,
    hoveredIconColor: Colors.Base900,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base400,
    unhoverBackgroundColor: Colors.Clear,
    hoveredBackgroundColor: Colors.Blue600,
    disabledBackgroundColor: Colors.Clear
  },
  Default: {
    unhoverIconColor: Colors.Base900,
    hoveredIconColor: Colors.Base900,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base400,
    unhoverBackgroundColor: Colors.White,
    hoveredBackgroundColor: Colors.Blue600,
    disabledBackgroundColor: Colors.Clear
  },
  UserSearchArrow: {
    unhoverIconColor: Colors.White,
    hoveredIconColor: Colors.White,
    selectedIconColor: Colors.White,
    disabledIconColor: Colors.White,
    unhoverBackgroundColor: Colors.Base600,
    hoveredBackgroundColor: Colors.Base900,
    disabledBackgroundColor: Colors.Base400
  },
  UserSearchEvent: {
    unhoverIconColor: Colors.Base700,
    hoveredIconColor: Colors.Base900,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base600,
    unhoverBackgroundColor: Colors.Clear,
    hoveredBackgroundColor: Colors.Clear,
    disabledBackgroundColor: Colors.Clear
  },
  Favorite: {
    unhoverIconColor: Colors.RedForFavorite,
    hoveredIconColor: Colors.RedForFavorite,
    selectedIconColor: Colors.RedForFavorite,
    disabledIconColor: Colors.RedForFavorite,
    unhoverBackgroundColor: Colors.Clear,
    hoveredBackgroundColor: Colors.Blue600,
    disabledBackgroundColor: Colors.Clear
  },
  CompanyActivity: {
    unhoverIconColor: Colors.Base600,
    hoveredIconColor: Colors.Base900,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base400,
    unhoverBackgroundColor: Colors.White,
    hoveredBackgroundColor: Colors.White,
    disabledBackgroundColor: Colors.White
  },
  Tour: {
    unhoverIconColor: Colors.Base900,
    hoveredIconColor: Colors.Base900,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base500,
    unhoverBackgroundColor: Colors.Clear,
    hoveredBackgroundColor: Colors.TourHoverBgColor,
    disabledBackgroundColor: Colors.Clear
  },
  UserTrend: {
    unhoverIconColor: Colors.Base700,
    hoveredIconColor: Colors.Base700,
    selectedIconColor: Colors.Base900,
    disabledIconColor: Colors.Base400,
    unhoverBackgroundColor: Colors.Clear,
    hoveredBackgroundColor: Colors.Blue600,
    disabledBackgroundColor: Colors.Clear
  }
};
