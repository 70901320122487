<i18n
  src="@/i18n/views/client-settings/coordination/conversion-attribute/coordination-conversion-attribute-settings-create.json"
></i18n>
<template>
  <div class="coordinationConversionAttributeSettingsCreate">
    <h1 class="coordinationConversionAttributeSettingsCreate__title">
      {{ coordinationName }}
    </h1>
    <div
      v-t="'title'"
      class="coordinationConversionAttributeSettingsCreate__title coordinationConversionAttributeSettingsCreate__subTitle"
    />
    <div class="conversionSettingsCreate_tabContent">
      <div
        v-if="isConfirm"
        v-t="'message'"
        class="coordinationConversionAttributeSettingsCreate__message"
      />
      <CoordinationConversionAttributeConfirmTable
        v-if="isConfirm"
        :coordination-conversion-attribute="formData"
      />
      <CoordinationConversionAttributeEditTable
        v-else
        :coordination-conversion-attribute="formData"
        :conversion-attribute-limit-reached="conversionAttributeLimitReached"
        :is-contract-web="isContractWeb"
        @update="onUpdate"
      />
      <div
        class="coordinationConversionAttributeSettingsCreate__buttonContainer"
      >
        <Button
          v-t="isConfirm ? 'register' : 'confirm'"
          class="coordinationConversionAttributeSettingsCreate__confirmButton"
          :disabled="isSubmitButtonDisabled"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickConfirm"
        />
        <Button
          v-t="'cancel'"
          class="coordinationConversionAttributeSettingsCreate__cancelButton"
          type="light"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickCancel"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import { ButtonTextSize } from "@/const/button";
import {
  ConversionAttributeDefinition,
  CoordinationConversionAttributeEditableData,
  isValidCoordinationConversionAttributeData
} from "@/models/client-settings/ConversionAttributeDefinition";

import { MapCvAttributeDefinitionListWithCvAttribute } from "@/models/client-settings/MapConversionAttributeDefinition";
import { handleError } from "@/util/error-util";
import {
  Coordination,
  CreationMethod
} from "@/models/client-settings/Coordination";
import CoordinationConversionAttributeConfirmTable from "@/components/client-settings/coordination/conversion-attribute/CoordinationConversionAttributeConfirmTable.vue";
import CoordinationConversionAttributeEditTable from "@/components/client-settings/coordination/conversion-attribute/CoordinationConversionAttributeEditTable.vue";

@Component({
  components: {
    Button,
    CoordinationConversionAttributeConfirmTable,
    CoordinationConversionAttributeEditTable
  }
})
export default class CoordinationConversionAttributeSettingsCreate extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isConfirm: boolean = false;
  isSubmitting: boolean = false;

  formData: CoordinationConversionAttributeEditableData = {
    targetData: this.coordinationName,
    creationMethod: CreationMethod.NEW,
    conversionAttributeType: "TEXT",
    conversionAttributeId: 0,
    conversionAttributeName: "",
    path: [""]
  };

  get coordinationName(): string {
    return (
      this.coordinations.find(
        coordination => coordination.id === this.coordinationId
      )?.name || ""
    );
  }

  get isContractWeb(): boolean {
    return this.$store.state.client.client.isContractWeb;
  }

  get coordinations(): Coordination[] {
    return this.$store.state.clientSettings.coordinations;
  }

  get coordinationId(): number {
    return Number(this.$route.params.coordinationId);
  }

  onUpdate(data: CoordinationConversionAttributeEditableData) {
    this.formData = data;
  }

  async onClickConfirm() {
    if (this.isConfirm) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      await this.addMapConversionAttribute().then(
        () =>
          (window.location.href = `/client-settings/coordination/${this.coordinationId}/cvattribute/index/?created_new=true`)
      );
    } else {
      this.scrollTop();
      this.isConfirm = true;
    }
  }

  async addMapConversionAttribute() {
    const mapConversionAttributeDef = new MapCvAttributeDefinitionListWithCvAttribute(
      this.formData.creationMethod,
      this.coordinationId,
      this.formData.conversionAttributeName,
      this.formData.conversionAttributeType,
      this.formData.conversionAttributeId,
      this.formData.path
    );
    await this.$store
      .dispatch(
        "mapConversionAttribute/registerNewCvAttributeAndMapCvAttribute",
        mapConversionAttributeDef
      )
      .catch(handleError);
  }

  onClickCancel() {
    if (this.isConfirm) {
      this.scrollTop();
      this.isConfirm = false;
    } else {
      window.location.href = `/client-settings/coordination/${this.coordinationId}/cvattribute/index/`;
    }
  }

  get isConversionAttributesFetched(): boolean {
    return (
      this.$store.state.clientSettings.activeConversionAttributeDefinitions
        .length > 0
    );
  }

  get activeConversionAttributes(): ConversionAttributeDefinition[] {
    return this.$store.state.clientSettings
      .activeConversionAttributeDefinitions;
  }

  get resourceLimitation(): number {
    return this.$store.state.client.client.resourceLimitation
      .conversion_attribute;
  }

  get conversionAttributeCreationLimit(): number {
    return this.resourceLimitation - this.activeConversionAttributes.length;
  }

  get isSubmitButtonDisabled(): boolean {
    if (this.isSubmitting) return true;

    return !isValidCoordinationConversionAttributeData(this.formData);
  }

  get conversionAttributeLimitReached(): boolean {
    return this.conversionAttributeCreationLimit <= 0;
  }

  async beforeMount() {
    if (!this.isConversionAttributesFetched) {
      await this.$store
        .dispatch("clientSettings/fetchConversionAttributeDefinitions")
        .catch(handleError);
    }

    if (this.conversionAttributeLimitReached) {
      this.formData.creationMethod = CreationMethod.EXISTING;
    }
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
</script>
<style lang="scss" scoped>
.coordinationConversionAttributeSettingsCreate {
  margin-right: 20px;
  min-width: 704px;
}
.coordinationConversionAttributeSettingsCreate__title {
  font-size: 20px;
  font-weight: 700;
}

.coordinationConversionAttributeSettingsCreate__subTitle {
  padding: 20px 0 46px 0;
}
.coordinationConversionAttributeSettingsCreate__message {
  margin-bottom: 30px;
  font-size: 14px;
}
.coordinationConversionAttributeSettingsCreate__buttonContainer {
  margin-top: 16px;
}
.coordinationConversionAttributeSettingsCreate__confirmButton {
  margin-right: 20px;
}
</style>
