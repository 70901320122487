<template>
  <div
    ref="selectCheckBox"
    class="select-check-box"
    :class="`select-check-box--${selectSize}`"
  >
    <div :class="selectClasses" @click="onOpen">
      {{ title }}
      <Icon
        :icon="selectArrow"
        :size="8"
        :color="iconColor"
        class="select-check-box__arrow"
      />
    </div>

    <Transition name="fade">
      <div v-show="showOptions" :class="pulldownClasses">
        <div
          class="select-check-box__pulldown-inner"
          :style="{ maxHeight: pulldownHiehgt }"
        >
          <div
            v-for="selectCheckBoxItem in selectCheckBoxItems"
            :key="selectCheckBoxItem.parent.id"
            class="select-check-box__pulldown-parent"
          >
            <CheckBox
              :checked="
                isChekced(selectedParentIds, selectCheckBoxItem.parent.id)
              "
              @input="
                updateSelectedParent($event, selectCheckBoxItem.parent.id)
              "
            >
              {{ selectCheckBoxItem.parent.name }}
            </CheckBox>
            <div
              v-for="child in selectCheckBoxItem.children"
              :key="child.id"
              class="select-check-box__pulldown-parent__child"
            >
              <CheckBox
                :checked="isChekced(selectedChildIds, child.id)"
                @input="updateSelectedChild($event, child.id)"
              >
                {{ child.name }}
              </CheckBox>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <div
      v-show="showOptions"
      class="select-check-box__overlay"
      @click="showOptions = !showOptions"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

/* components */
import CheckBox from "@/components/form/CheckBox.vue";
import Icon from "@/components/Icon.vue";
import {
  SelectCheckBoxItems,
  FormPartsSize,
  PulldownAlign
} from "@/components/form/SelectCheckBoxItems";

/* const */
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";

@Component({
  components: { Icon, CheckBox }
})
export default class SelectCheckBox extends Vue {
  @Prop({ type: Array, required: true })
  selectCheckBoxItems!: SelectCheckBoxItems[];

  @Prop({ type: String, default: FormPartsSize.Medium })
  selectSize!: FormPartsSize;

  @Prop({ type: String, default: PulldownAlign.Left })
  pulldownAlign!: PulldownAlign;

  @Prop({ type: String, default: "" })
  title!: string;

  @Prop({ type: Array, default: () => [] })
  selectedParentIds!: number[];

  @Prop({ type: Array, default: () => [] })
  selectedChildIds!: number[];

  @Prop({ type: String, default: "100vh" })
  pulldownStandardHeight!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  updateParent(selectedParentIds: number[]) {
    this.$emit("updateParent", selectedParentIds);
  }

  updateChild(selectedChildIds: number[]) {
    this.$emit("updateChild", selectedChildIds);
  }

  selectArrow: string = Icons.ArrowBottom;
  showOptions: boolean = false;
  pulldownHiehgt: string = "auto";

  get iconColor(): Colors {
    return this.disabled ? Colors.ElmentUIGray300 : Colors.Base900;
  }

  get selectCheckBox(): HTMLElement {
    return this.$refs.selectCheckBox as HTMLElement;
  }

  get pulldownClasses(): string {
    const defaultClass = "select-check-box__pulldown";
    const classes = [
      defaultClass,
      `${defaultClass}--${this.selectSize}`,
      `${defaultClass}--${this.pulldownAlign}`
    ];

    return classes.join(" ");
  }

  get selectClasses(): string {
    const classes = [
      "select-check-box__wrapper",
      `select-check-box__wrapper--${this.selectSize}`
    ];
    if (this.showOptions) {
      classes.push("select-check-box__wrapper--selected");
    }
    if (this.disabled) {
      classes.push("select-check-box__wrapper--disabled");
    }
    return classes.join(" ");
  }

  isChekced(ids: number[], id: number) {
    return ids.indexOf(id) >= 0;
  }

  updateSelectedParent(checked: boolean, selectedId: number) {
    if (checked) {
      this.updateParent([...this.selectedParentIds, selectedId]);
    } else {
      const updatedIds = this.selectedParentIds.filter(id => id !== selectedId);
      this.updateParent(updatedIds);
    }
  }

  updateSelectedChild(checked: boolean, selectedId: number) {
    if (checked) {
      this.updateChild([...this.selectedChildIds, selectedId]);
    } else {
      const updatedIds = this.selectedChildIds.filter(id => id !== selectedId);
      this.updateChild(updatedIds);
    }
  }

  onOpen() {
    if (!this.disabled) {
      this.showOptions = !this.showOptions;

      const rect = this.selectCheckBox.getBoundingClientRect();
      // 45px = .select-check-box__wrapper の height & top
      // 50px = 調整の値
      this.pulldownHiehgt =
        "calc(" +
        this.pulldownStandardHeight +
        " - 45px - 50px - " +
        rect.top +
        "px)";
    }
  }
}
</script>

<style scoped lang="scss">
.select-check-box {
  position: relative;
  display: inline-block;
}

.select-check-box--medium {
  width: 250px;
}

.select-check-box--small {
  max-width: 250px;
}

.select-check-box__wrapper {
  position: relative;
  overflow: hidden;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorBase900;
  text-align-last: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:not(.select-check-box__wrapper--disabled):hover {
    cursor: pointer;
    background-color: $colorHoverLightForWhite;
  }
}

.select-check-box__wrapper--medium {
  padding-right: 30px;
  padding-left: 15px;
  height: $formPartsHeight;
  line-height: $formPartsHeight;
}

.select-check-box__wrapper--small {
  padding-right: 25px;
  padding-left: 10px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
}

.select-check-box__wrapper--selected {
  background-color: $colorHoverLightForWhite;
}

.select-check-box__wrapper--disabled {
  border-color: $colorGray200;
  background-color: $colorGray200;
  color: $colorBase600;
}

.select-check-box__arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: 1px;
  transform: translateY(-50%);
}

.select-check-box__pulldown {
  position: absolute;
  z-index: 10;
  padding: 10px;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.select-check-box__pulldown--medium {
  top: $formPartsHeight + 10px;
  width: 250px;
}

.select-check-box__pulldown--small {
  top: 30px;
  width: 270px;
}

.select-check-box__pulldown--left {
  left: 0;
}

.select-check-box__pulldown--right {
  right: 0;
}

.select-check-box__pulldown-inner {
  overflow-x: hidden;
  overflow-y: auto;
  text-align-last: left;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $colorClear;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $colorBase500;
    cursor: pointer;
  }
}
.select-check-box__pulldown-parent {
  position: relative;
  text-align: left;
  &:not(:first-child) {
    margin-top: 5px;
  }
}

.select-check-box__pulldown-parent__child {
  padding-left: 20px;
  &:not(:first-child) {
    margin-top: 5px;
  }
}

.select-check-box__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
