<template>
  <td
    class="analysisTableCell"
    :style="style"
    v-bind="colspanRowspan"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="onClick"
  >
    <slot></slot>
  </td>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

type colspanRowspan = {
  colspan?: number;
  rowspan?: number;
};

@Component
export default class AnalysisTableCell extends Vue {
  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: Number, default: 0 })
  colspan!: number;

  @Prop({ type: Number, default: 0 })
  rowspan!: number;

  onMouseEnter(event: MouseEvent): void {
    this.$emit("mouse-enter", event);
  }

  onMouseLeave(event: MouseEvent): void {
    this.$emit("mouse-leave", event);
  }

  onClick(event: MouseEvent) {
    this.$emit("click", event);
  }

  get style() {
    return {
      width: this.width
    };
  }

  get colspanRowspan(): colspanRowspan {
    const span: colspanRowspan = {};

    if (this.colspan !== 0) {
      span["colspan"] = this.colspan;
    }
    if (this.rowspan !== 0) {
      span["rowspan"] = this.rowspan;
    }
    return span;
  }
}
</script>

<style lang="scss" scoped>
.analysisTableCell {
  padding: 10px 10px 8px;
  border-bottom: 1px solid $colorBase400;
  border-left: 1px solid $colorBase400;
  vertical-align: middle;
  text-align: center;

  @include all-break-word();

  &:last-child {
    border-right: 1px solid $colorBase400;
  }
}
</style>
