import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import { MatchMethod } from "@/models/search/MatchMethod";
import { AttributeType } from "@/api/apis/ApiSearch";
import { FunnelAttributeJson } from "@/api/apis/ApiFunnel";

export type FunnelConditionAttributeType =
  | FunnelConditionNumberAttribute
  | FunnelConditionTextAttribute;

interface FunnelConditionAttribute {
  attributeId: number;
}

export class FunnelConditionNumberAttribute
  implements FunnelConditionAttribute {
  constructor(
    public readonly attributeId: number,
    public readonly minValue: number | null,
    public readonly maxValue: number | null
  ) {}

  get displayValue(): string {
    if (this.maxValue === null) {
      return localI18n("greaterThanOrEqualTo", { num: this.minValue });
    }

    if (this.minValue === null) {
      return localI18n("lessThanOrEqualTo", { num: this.maxValue });
    }

    return `${this.minValue} - ${this.maxValue}`;
  }

  toJson(): FunnelAttributeJson {
    const params: FunnelAttributeJson = {
      id: this.attributeId,
      type: AttributeType.NUMBER
    };
    if (this.minValue !== null) {
      params["min"] = this.minValue;
    }
    if (this.maxValue !== null) {
      params["max"] = this.maxValue;
    }
    return params;
  }

  static fromJson(json: FunnelAttributeJson): FunnelConditionNumberAttribute {
    return new FunnelConditionNumberAttribute(
      json.id,
      json.min !== undefined ? json.min : null,
      json.max !== undefined ? json.max : null
    );
  }

  get validate(): ValidationResult {
    const errorMessages: string[] = [];
    if (this.minValue === null && this.maxValue === null) {
      errorMessages.push(localI18n("validateValue"));
    }
    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

export class FunnelConditionTextAttribute implements FunnelConditionAttribute {
  constructor(
    public readonly attributeId: number,
    public readonly value: string,
    public readonly wordMatchMethod: MatchMethod
  ) {}

  get wordMatchString(): string {
    switch (this.wordMatchMethod) {
      case MatchMethod.Exact:
        return localI18n("perfectMatch");
      case MatchMethod.Prefix:
        return localI18n("prefixMatch");
      case MatchMethod.Postfix:
        return localI18n("postfixMatch");
      default:
        return localI18n("partialMatch");
    }
  }

  get displayValue(): string {
    return `${this.value} (${this.wordMatchString})`;
  }

  toJson(): FunnelAttributeJson {
    return {
      id: this.attributeId,
      type: AttributeType.TEXT,
      value: this.value,
      word_match_method: this.wordMatchMethod
    };
  }

  static fromJson(json: FunnelAttributeJson): FunnelConditionTextAttribute {
    return new FunnelConditionTextAttribute(
      json.id,
      json.value !== undefined ? json.value : "",
      json.word_match_method !== undefined
        ? json.word_match_method
        : MatchMethod.Partial
    );
  }

  get validate(): ValidationResult {
    const errorMessages: string[] = [];
    if (this.value === "") {
      errorMessages.push(localI18n("validateValue"));
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

function localI18n(key: string, param = {}): string {
  return i18n.t(
    `models.funnel.funnelConditionAttribute.${key}`,
    param
  ) as string;
}
