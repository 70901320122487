<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div
    data-cy="additional-condition-editor"
    class="additional-condition-editor"
  >
    <div class="additional-condition-list-title">
      <TitleTextWithIcon :title="$t('filterConditions')" :icon="iconFilter" />
      <span class="additional-condition-list-notes">{{
        $t("notes", { num: maxAdditionalConditionSize })
      }}</span>
    </div>
    <AdditionalConditionList
      v-if="additionalConditions.length > 0"
      class="additional-condition-list"
      :conditions="additionalConditions"
      :conversion-attribute-definitions="conversionAttributeDefinitions"
      :nps-definitions="npsDefinitions"
      :enquete-definitions="enqueteDefinitions"
      :business-index-definitions="businessIndexDefinitions"
      :user-attribute-definitions="userAttributeDefinitions"
      :business-event-definitions="businessEventDefinitions"
      :contact-definitions="contactDefinitions"
      @click-delete="onClickDelete"
      @click-edit="onClickEdit"
    />
    <AdditionalConditionButtons
      class="additional-condition-editor__buttons"
      :condition="condition"
      :conversion-attribute-definitions="conversionAttributeDefinitions"
      :nps-definitions="npsDefinitions"
      :enquete-definitions="enqueteDefinitions"
      :business-index-definitions="businessIndexDefinitions"
      :user-attribute-definitions="userAttributeDefinitions"
      :business-event-definitions="businessEventDefinitions"
      :contact-definitions="contactDefinitions"
      @click="onClickAdd"
    />
    <AdditionalConditionDialog
      data-cy="additional-condition-form"
      :visible="showForm"
      :condition-type="fromConditionType"
      :current-conditions="additionalConditions"
      :edit-condition="editCondition"
      :conversion-attribute-definitions="conversionAttributeDefinitions"
      :nps-definitions="npsDefinitions"
      :enquete-definitions="enqueteDefinitions"
      :business-index-definitions="businessIndexDefinitions"
      :user-attribute-definitions="userAttributeDefinitions"
      :business-event-definitions="businessEventDefinitions"
      :contact-definitions="contactDefinitions"
      :is-repeat-tab="isRepeatTab"
      @close="closeForm"
      @submit="onSubmitForm"
      @cancel="closeForm"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import AdditionalConditionButtons from "@/components/search/AdditionalConditionButtons.vue";
import AdditionalConditionList from "@/components/search/AdditionalConditionList.vue";
import Overlay from "@/components/Overlay.vue";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import AdditionalConditionDialog from "@/components/search/AdditionalConditionDialog.vue";

import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import { SelectByNpsChangeCondition } from "@/models/search/select-condition/SelectByNpsChangeCondition";
import { BusinessEventAttributeCondition } from "@/models/search/additional-condition/BusinessEventAttributeCondition";
import { ContactAttributeCondition } from "@/models/search/additional-condition/ContactAttributeCondition";

import {
  AdditionalSelectCondition,
  AdditionalConditionType
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { UserAttributeStringCondition } from "@/models/search/additional-condition/UserAttributeStringCondition";
import { UserAttributeNumberCondition } from "@/models/search/additional-condition/UserAttributeNumberCondition";
import { NpsCondition } from "@/models/search/additional-condition/NpsCondition";
import { EnqueteCondition } from "@/models/search/additional-condition/EnqueteCondition";
import { BusinessIndexCondition } from "@/models/search/additional-condition/BusinessIndexCondition";
import { Icons } from "@/const/Icons";
import { MAX_HOLD_ADDITIONAL_CONDITION_SIZE } from "@/models/search/select-condition/SelectCondition";
import { SelectByEngagementCondition } from "@/models/search/select-condition/SelectByEngagementCondition";
import { ConversionAttributeCondition } from "@/models/search/additional-condition/ConversionAttributeCondition";

const NO_EDIT_INDEX = -1;
const ADDITIONAL_CONDITION_FORM: string = "AdditionalConditionDialog";

@Component({
  components: {
    AdditionalConditionButtons,
    AdditionalConditionList,
    Overlay,
    TitleTextWithIcon,
    AdditionalConditionDialog
  }
})
export default class AdditionalConditionEditor extends Vue {
  @Model("input", {
    type: [
      SelectByConversionCondition,
      SelectByAppCondition,
      SelectByBusinessEventCondition,
      SelectByContactCondition,
      SelectByNpsCondition,
      SelectByNpsChangeCondition,
      SelectByEngagementCondition
    ],
    required: true
  })
  condition!:
    | SelectByConversionCondition
    | SelectByAppCondition
    | SelectByBusinessEventCondition
    | SelectByContactCondition
    | SelectByNpsCondition
    | SelectByNpsChangeCondition
    | SelectByEngagementCondition;

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  npsDefinitions!: NpsDefinition[];

  @Prop({ type: Array, required: true })
  enqueteDefinitions!: EnqueteDefinition[];

  @Prop({ type: Array, required: true })
  businessIndexDefinitions!: BusinessIndexDefinition[];

  @Prop({ type: Array, required: true })
  userAttributeDefinitions!: UserAttributeDefinition[];

  @Prop({ type: Array, required: true })
  businessEventDefinitions!: BusinessEventDefinition[];

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  @Prop({ type: Boolean, required: false })
  isRepeatTab!: Boolean | false;

  onInput(
    condition:
      | SelectByConversionCondition
      | SelectByAppCondition
      | SelectByBusinessEventCondition
      | SelectByContactCondition
      | SelectByNpsCondition
      | SelectByNpsChangeCondition
      | SelectByEngagementCondition
  ) {
    this.$emit("input", condition);
  }

  iconFilter = Icons.Filter;
  maxAdditionalConditionSize = MAX_HOLD_ADDITIONAL_CONDITION_SIZE;

  /**
   * Flag to display the form
   */
  showForm = false;

  /**
   * form modal name
   */
  additionalConditionFormModalName = ADDITIONAL_CONDITION_FORM;

  /**
   * 追加・編集するAdditionalConditionタイプ
   * フォームは渡されたこのタイプによってフォームを出し分ける
   */
  fromConditionType: AdditionalConditionType =
    AdditionalConditionType.CONVERSION_ATTRIBUTE;

  /**
   * 編集するAdditionalCondition
   * フォームは渡されたこのConditionによって編集か新規追加を判断する
   */
  editCondition: null | AdditionalSelectCondition = null;

  /**
   * 編集するAdditionalConditionが、親SelectConditionにあるadditionalConditionsのどのindexに入っているか
   * 編集後は別のAdditionalConditionになるので、このindexを頼りに差し替える
   */
  editConditionIndex: number = NO_EDIT_INDEX;

  /**
   * 親SelectConditionにあるadditionalConditions
   */
  get additionalConditions(): AdditionalSelectCondition[] {
    return this.condition.additionalConditions;
  }

  /**
   * AdditionalConditionの追加ボタンを押した
   * 追加するタイプを指定してフォームのポップアップ表示
   */
  onClickAdd(type: AdditionalConditionType) {
    this.fromConditionType = type;
    this.showForm = true;
  }

  /**
   * Close form modal
   */
  closeForm() {
    // 次も編集状態にならないように編集情報をクリア
    this.editCondition = null;
    this.editConditionIndex = NO_EDIT_INDEX;

    this.showForm = false;
  }

  /**
   * Add / edit button in form was clicked
   */
  onSubmitForm(additionalCondition: AdditionalSelectCondition) {
    let additionalConditions: AdditionalSelectCondition[] = [];

    // リスト表示に反映させるため、編集の場合は配列作り直す
    if (this.editConditionIndex !== NO_EDIT_INDEX) {
      this.additionalConditions.forEach((cnd, index) => {
        additionalConditions.push(
          this.editConditionIndex === index ? additionalCondition : cnd
        );
      });
    } else {
      additionalConditions = this.condition.additionalConditions;
      additionalConditions.push(additionalCondition);
    }
    this.updateCondition(additionalConditions);
    this.closeForm();
  }

  /**
   * delete button is clicked
   */
  onClickDelete(additionalSelectCondition: AdditionalSelectCondition) {
    const additionalConditions = this.condition.additionalConditions.filter(
      condition => {
        return condition !== additionalSelectCondition;
      }
    );
    this.updateCondition(additionalConditions);
  }

  /**
   * edit button is clicked
   */
  onClickEdit(additionalSelectCondition: AdditionalSelectCondition) {
    this.condition.additionalConditions.forEach((cnd, index) => {
      if (cnd === additionalSelectCondition) {
        const type = this.getAdditionalConditionType(cnd);
        if (type !== null) {
          this.editConditionIndex = index;
          this.editCondition = cnd;
          this.fromConditionType = type;
          this.showForm = true;
        }
      }
    });
  }

  /**
   * 渡されたadditionalConditionsを使って、SelectCondtionを作り直してemit
   */
  updateCondition(additionalConditions: AdditionalSelectCondition[]) {
    if (this.condition instanceof SelectByConversionCondition) {
      this.onInput(
        new SelectByConversionCondition(
          this.condition.conversionDefinitionIds,
          this.condition.startDate,
          this.condition.endDate,
          this.condition.hour,
          additionalConditions
        )
      );
    }
    if (this.condition instanceof SelectByAppCondition) {
      this.onInput(
        new SelectByAppCondition(
          this.condition.conversionDefinitionIds,
          this.condition.startDate,
          this.condition.endDate,
          this.condition.hour,
          additionalConditions
        )
      );
    }
    if (this.condition instanceof SelectByBusinessEventCondition) {
      this.onInput(
        new SelectByBusinessEventCondition(
          this.condition.businessEventDefinitionIds,
          this.condition.startDate,
          this.condition.endDate,
          this.condition.hour,
          additionalConditions
        )
      );
    }
    if (this.condition instanceof SelectByContactCondition) {
      this.onInput(
        new SelectByContactCondition(
          this.condition.contactDefinitionIds,
          this.condition.startDate,
          this.condition.endDate,
          this.condition.hour,
          additionalConditions
        )
      );
    }
    if (this.condition instanceof SelectByNpsCondition) {
      this.onInput(
        new SelectByNpsCondition(
          this.condition.from,
          this.condition.to,
          this.condition.date,
          additionalConditions
        )
      );
    }
    if (this.condition instanceof SelectByNpsChangeCondition) {
      this.onInput(
        new SelectByNpsChangeCondition(
          this.condition.previousNpsLabels,
          this.condition.npsLabel,
          additionalConditions
        )
      );
    }
    if (this.condition instanceof SelectByEngagementCondition) {
      this.onInput(
        this.condition.updateAdditionalConditions(additionalConditions)
      );
    }
  }

  /**
   * AdditionalSelectConditionからtypeを探って返す
   */
  getAdditionalConditionType(
    condition: AdditionalSelectCondition
  ): AdditionalConditionType | null {
    if (condition instanceof ConversionAttributeCondition) {
      return AdditionalConditionType.CONVERSION_ATTRIBUTE;
    }
    if (
      condition instanceof UserAttributeStringCondition ||
      condition instanceof UserAttributeNumberCondition
    ) {
      return AdditionalConditionType.USER_ATTRIBUTE;
    }
    if (condition instanceof BusinessIndexCondition) {
      return AdditionalConditionType.BUSINESS_INDEX;
    }
    if (condition instanceof NpsCondition) {
      return AdditionalConditionType.NPS;
    }
    if (condition instanceof EnqueteCondition) {
      return AdditionalConditionType.ENQUETE;
    }
    if (condition instanceof BusinessEventAttributeCondition) {
      return AdditionalConditionType.BUSINESS_EVENT_ATTRIBUTE;
    }
    if (condition instanceof ContactAttributeCondition) {
      return AdditionalConditionType.CONTACT_ATTRIBUTE;
    }

    return null;
  }
}
</script>
<style lang="scss" scoped>
.additional-condition-list-title {
  margin-top: 20px;
}

.additional-condition-list-notes {
  margin-left: 10px;
}

.additional-condition-list {
  margin: 20px 0 0 0;
}
.additional-condition-editor__buttons {
  padding-top: 10px;
}
</style>
