import { ApiRes } from "@/api/api-res";
import {
  VisitConversion,
  VisitOverview
} from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { UserPvStats } from "@/models/UserPvStats";
import { Device } from "@/models/Device";

/**
 * 5種類あるOverviewをまとめるクラス
 */
export class GramOverviews {
  constructor(
    public readonly visit: VisitOverview[],
    public readonly nps: NpsOverview[],
    public readonly business: BusinessEventOverview[],
    public readonly enquete: EnqueteOverview[],
    public readonly contact: ContactOverview[],
    public readonly filterMatchedIds: string[]
  ) {}

  /**
   * returns the specified number of future visit date from the specified date
   * @param baseDate
   * @param futureCount
   * @returns Date | null if visit is exist return date if not return null
   */
  public getFutureSpecificVisitDate(
    baseDate: Date,
    futureCount: number
  ): Date | null {
    let count = 0;
    for (const v of this.visit) {
      if (v.date.getTime() > baseDate.getTime()) {
        count++;
      }
      if (count >= futureCount) {
        return v.date;
      }
    }
    return null;
  }

  /**
   * returns the specified number of past visit date from the specified date
   * @param baseDate
   * @param pastCount
   * @returns Date | null if visit is exist return date if not return null
   */
  public getPastSpecificVisitDate(
    baseDate: Date,
    pastCount: number
  ): Date | null {
    let count = 0;
    const reverseVisits = this.visit.slice().reverse();
    for (const v of reverseVisits) {
      if (v.date.getTime() < baseDate.getTime()) {
        count++;
      }
      if (count >= pastCount) {
        return v.date;
      }
    }
    return null;
  }

  /**
   * APIのユーザ検索結果と、NPS定義、ビジネスイベント定義、アンケート定義からGramOverviewsクラスを作る
   *
   * @param json APIから渡されるユーザのデータ
   * @param npsDefinitions NPS定義
   * @param businessEventDefinitions ビジネスイベント定義
   * @param enqueteDefinitions アンケート定義
   */
  public static build(
    json: ApiRes.User,
    conversionDefinitions: ConversionDefinition[],
    npsDefinitions: NpsDefinition[],
    businessEventDefinitions: BusinessEventDefinition[],
    enqueteDefinitions: EnqueteDefinition[]
  ): GramOverviews {
    return new GramOverviews(
      GramOverviews.buildVisitOverviews(
        json.gma_visit_overview,
        conversionDefinitions
      ),
      GramOverviews.buildNpsOverviews(json.gma_nps_overview, npsDefinitions),
      GramOverviews.buildBusinessEventOverviews(
        json.gma_be_overview,
        businessEventDefinitions
      ),
      GramOverviews.buildEnqueteOverviews(
        json.gma_enqt_overview,
        enqueteDefinitions
      ),
      json.gma_contact_overview.map(ContactOverview.fromJson),
      json.filter_matched_gram_ids
    );
  }

  /**
   * APIのデータとCVの定義から、訪問のOverviewを作る
   *
   * @param json APIから返ってくるVisitOverviewのデータ
   * @param conversionDefinitions コンバージョン定義
   */
  private static buildVisitOverviews(
    json: ApiRes.VisitOverview[],
    conversionDefinitions: ConversionDefinition[]
  ): VisitOverview[] {
    return json
      .filter(
        visitJson =>
          visitJson.pv_length > 0 || visitJson.conversion_ids.length > 0
      )
      .map(visitJson => {
        const conversions: VisitConversion[] = visitJson.conversion_ids.map(
          id => {
            const cv = conversionDefinitions.find(def => def.id === id);
            if (cv) {
              return { id, name: cv.name };
            }

            // This cannot be happened in normal use.
            return { id: 0, name: "" };
          }
        );
        return new VisitOverview(
          visitJson.gram_id,
          new Date(visitJson.start_time_sec * 1000),
          conversions,
          new UserPvStats(
            visitJson.pv_pc_web,
            visitJson.pv_sp_web,
            visitJson.pv_pc_app,
            visitJson.pv_sp_app
          ),
          Device.getDeviceFromCategory(
            visitJson.platform_category,
            visitJson.platform_sub_category,
            false
          ),
          visitJson.time_of_day
        );
      });
  }

  /**
   * APIのデータとNPS定義から、NPSOverviewを作る
   * 対応するNPS定義が見つからない場合はそのデータを破棄する
   *
   * @param json APIから返ってくるビジネスイベントOverviewのデータ
   * @param npsDefinitions NPS定義
   */
  private static buildNpsOverviews(
    json: ApiRes.NpsOverview[],
    npsDefinitions: NpsDefinition[]
  ): NpsOverview[] {
    if (npsDefinitions.length > 0) {
      const definition: NpsDefinition = npsDefinitions[0];

      return json.map(
        (npsOverviewJson: ApiRes.NpsOverview) =>
          new NpsOverview(
            npsOverviewJson.gram_id,
            new Date(npsOverviewJson.time_usec / 1000),
            npsOverviewJson.score,
            npsOverviewJson.comment,
            definition
          )
      );
    }

    return [];
  }

  /**
   * APIのデータとビジネスイベント定義から、ビジネスイベントOverviewを作る
   * 対応するビジネスイベント定義が見つからない場合はそのデータを破棄する
   *
   * @param json APIから返ってくるビジネスイベントOverviewのデータ
   * @param businessEventDefinitions ビジネスイベント定義
   */
  private static buildBusinessEventOverviews(
    json: ApiRes.BusinessEventOverview[],
    businessEventDefinitions: BusinessEventDefinition[]
  ): BusinessEventOverview[] {
    return json.reduce(
      (
        acc: BusinessEventOverview[],
        businessEventJson: ApiRes.BusinessEventOverview
      ) => {
        // 対応するビジネスイベント定義を取得
        const businessEventDefinition:
          | BusinessEventDefinition
          | undefined = businessEventDefinitions.find(
          e => e.id === businessEventJson.id
        );

        // 対応するものがなければ、そのデータをスキップする
        if (businessEventDefinition === undefined) {
          return acc;
        }

        // Overviewを作って結果の配列にpushする
        acc.push(
          new BusinessEventOverview(
            businessEventJson.gram_id,
            new Date(businessEventJson.time_usec / 1000),
            businessEventJson.content,
            businessEventDefinition
          )
        );
        return acc;
      },
      []
    );
  }

  /**
   * APIのデータとアンケート定義から、アンケートOverviewを作る
   * 対応するアンケート定義が見つからない場合はそのデータを破棄する
   *
   * @param json APIから返ってくるアンケートOverviewのデータ
   * @param enqueteDefinitions アンケート定義
   */
  private static buildEnqueteOverviews(
    json: ApiRes.EnqueteOverview[],
    enqueteDefinitions: EnqueteDefinition[]
  ): EnqueteOverview[] {
    return json.reduce(
      (acc: EnqueteOverview[], enqueteJson: ApiRes.EnqueteOverview) => {
        // 対応するアンケート定義を取得
        const enqueteDefinition:
          | EnqueteDefinition
          | undefined = enqueteDefinitions.find(e => e.id === enqueteJson.id);

        // 対応するものがなければ、そのデータをスキップする
        if (enqueteDefinition === undefined) {
          return acc;
        }

        // Overviewを作って結果の配列にpushする
        acc.push(
          new EnqueteOverview(
            enqueteJson.gram_id,
            new Date(enqueteJson.time_usec / 1000),
            enqueteJson.score,
            enqueteJson.comment,
            enqueteDefinition
          )
        );
        return acc;
      },
      []
    );
  }
}
