<i18n src="@/i18n/components/filter/filter-period.json"></i18n>
<template>
  <div class="filterPeriod">
    <div class="filterPeriod__title">
      {{ $t("title") }}
      <Tooltip :placement="tooltipPlacement" :wrap="true">
        <Icon :icon="Icons.HelpInvert" :color="Colors.Base700" :size="10" />
        <template #content>
          <div class="filterPeriod__help">
            <p v-t="'tooltip'" class="filterPeriod__helpText" />
            <ul class="filterPeriod__helpList">
              <li v-t="'tooltipDetail'" />
            </ul>
          </div>
        </template>
      </Tooltip>
      :
    </div>
    <div
      class="filterPeriod__form"
      :class="{ filterPeriod__form__leftSpace: isEnglish }"
    >
      <span
        v-for="option in periodOptions"
        :key="option.value"
        :class="{ filterPeriod__radioButton: !isEnglish }"
      >
        <RadioButton
          :label="option.value"
          :value="periodDays"
          @change="onInput"
          >{{ option.label }}</RadioButton
        >
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { FilterPeriodDays } from "@/const/filter";
import { TooltipPlacement } from "@/const/tooltip";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectOption from "@/components/form/SelectOption";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    RadioButton,
    Tooltip,
    Icon
  }
})
export default class FilterPeriod extends Vue {
  Icons = Icons;
  Colors = Colors;
  tooltipPlacement = TooltipPlacement.BottomStart;

  @Prop({ type: Number, required: true })
  periodDays!: FilterPeriodDays;

  onInput(periodDays: FilterPeriodDays) {
    this.$emit("input", periodDays);
  }

  get periodOptions(): SelectOption[] {
    return [
      {
        label: this.$i18n.t("wholePeriod") as string,
        value: FilterPeriodDays.TWO_YEARS,
        disabled: false
      },
      {
        label: this.$i18n.t("oneYear") as string,
        value: FilterPeriodDays.ONE_YEAR,
        disabled: false
      },
      {
        label: this.$i18n.t("sixMonths") as string,
        value: FilterPeriodDays.SIX_MONTHS,
        disabled: false
      },
      {
        label: this.$i18n.t("threeMonths") as string,
        value: FilterPeriodDays.THREE_MONTHS,
        disabled: false
      },
      {
        label: this.$i18n.t("oneMonth") as string,
        value: FilterPeriodDays.ONE_MONTH,
        disabled: false
      }
    ];
  }

  get isEnglish(): boolean {
    // To remove the margin to display 1 line
    return this.$i18n.locale === "en";
  }
}
</script>

<style lang="scss" scoped>
.filterPeriod {
  display: flex;
  align-items: center;
}

.filterPeriod__title {
  margin-right: 6px;
}

.filterPeriod__form__leftSpace {
  padding-left: 7px;
}

.filterPeriod__radioButton {
  margin-left: 8px;
}

.filterPeriod__help {
  width: 500px;
  text-align: left;
  text-indent: 0;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.6;
}

.filterPeriod__helpText {
  padding: 0 0 6px 0;
}

.filterPeriod__helpList {
  padding-left: 20px;
  list-style: disc;
}
</style>
