import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";

/**
 * ビジネスイベントOverview
 *
 * ビジネスイベントとしてOMOインポートしたグラムを表す。
 * ビジネスイベントとは。グラムのうちビジネス上の重要な行動となるもの。例えば解約や、返品など。
 */
export class BusinessEventOverview {
  public constructor(
    // グラムのID
    public readonly gramId: string,
    // ビジネスイベントが起こった時間
    public readonly date: Date,
    // ビジネスイベントの説明
    public readonly content: string,
    // ビジネスイベントの定義
    public readonly definition: BusinessEventDefinition
  ) {}
}
