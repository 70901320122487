import { render, staticRenderFns } from "./TourDetailTitle.vue?vue&type=template&id=43d98b34&scoped=true"
import script from "./TourDetailTitle.vue?vue&type=script&lang=ts"
export * from "./TourDetailTitle.vue?vue&type=script&lang=ts"
import style0 from "./TourDetailTitle.vue?vue&type=style&index=0&id=43d98b34&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d98b34",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/tours/tour-detail-title.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ftours%2FTourDetailTitle.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports