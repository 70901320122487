import { ApiRes } from "@/api/api-res";

/* Multi-factor Authentication */
export class Mfa {
  constructor(
    public readonly required: boolean,
    public readonly enabled: boolean
  ) {}

  public static fromJson(json: ApiRes.Mfa): Mfa {
    if (!json) return new Mfa(false, false);
    return new Mfa(json.required, json.enabled);
  }
}
