import { MatchMethod } from "@/models/search/MatchMethod";
import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * リファラURLによる条件
 *
 * リファラ絞り込みに、リファラURLを条件として付与できる
 *
 */
export class FilterReferrerUrlCondition {
  public readonly conditionType = FilterNodeConditionType.ReferrerUrl;

  constructor(
    public readonly referrerUrl: string,
    // 文字列のマッチ条件（部分一致 or 完全一致）
    public readonly matchMethod: MatchMethod
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.referrerUrl,
      i18n.t("models.search.referrerUrl") as string
    );
  }
}
