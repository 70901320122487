import { ApiRes } from "@/api/api-res";
import { AlmostCvUser } from "@/models/almost-cv/AlmostCvUser";
import { Usec } from "@/util/date-util";

export class AlmostCvUserList {
  public constructor(public readonly users: AlmostCvUser[]) {}

  get userIds(): string[] {
    return this.users.map(user => user.userId);
  }

  public getGramIdsByUserId(userId: string): string[] {
    const almostCvUser = this.users.find(user => user.userId === userId);

    return almostCvUser?.gramIds || [];
  }

  public getUsecOfFirstGramIdByUserId(userId: string): Usec {
    const firstGramId = this.getFirstGramIdByUserId(userId);
    return this.getUsecFromGramId(firstGramId);
  }

  private getFirstGramIdByUserId(userId: string): string {
    const gramIds: string[] = this.getGramIdsByUserId(userId);
    if (gramIds.length === 0) {
      return "";
    }
    return gramIds[0];
  }

  private getUsecFromGramId(gramId: string): Usec {
    return Number(gramId.split(":")[0]);
  }

  public static fromJson(json: ApiRes.AlmostCvUser[]): AlmostCvUserList {
    const almostCvUsers = json.map(AlmostCvUser.fromJson);
    return new AlmostCvUserList(almostCvUsers);
  }
}
