import { UserPvStats } from "@/models/UserPvStats";
import { VisitTimeFrame } from "@/api/apis/ApiVisits";

export interface VisitConversion {
  id: number;
  name: string;
}

/**
 * 訪問Overview
 *
 * 1訪問あたりのグラムの集計情報を表す。
 * 1訪問は30分以内の連続したアクセスの集合のことを表す。
 * （ただし6時間を越えると、2つの訪問に分ける）
 */
export class VisitOverview {
  public constructor(
    // 訪問の中の最初のグラムのID
    public readonly gramId: string,
    // 訪問の中の最初のグラムが発生した時間
    public readonly date: Date,
    // 訪問内に起きたすべてのコンバージョンの配列
    public readonly conversions: VisitConversion[],
    // その訪問内のPVの集計情報
    public readonly pvStats: UserPvStats,
    public readonly device: string | null,
    public readonly timeOfDay: VisitTimeFrame
  ) {}

  get hasConversion(): boolean {
    return this.conversions.length > 0;
  }
}
