import HttpClient from "@/api/HttpClient";
import { ApiRes } from "@/api/api-res";
import { ApiUrl } from "@/api/api-url";

export default class ApiMfa {
  constructor(private readonly httpClient: HttpClient) {}

  public configure = (): Promise<ApiRes.MfaConfig> => {
    return this.httpClient.post<ApiRes.MfaConfig>(ApiUrl.MFA_CONFIGURE, {});
  };

  public enable = (
    secretKey: string,
    otpCode: string
  ): Promise<ApiRes.MfaEnableSuccess | ApiRes.MfaConfig> => {
    return this.httpClient.post<ApiRes.MfaEnableSuccess | ApiRes.MfaConfig>(
      ApiUrl.MFA_ENABLE,
      {
        secret_key: secretKey,
        otp_code: otpCode
      }
    );
  };

  public verify = (otpCode: string): Promise<ApiRes.MfaVerification> => {
    return this.httpClient.post<ApiRes.MfaVerification>(ApiUrl.MFA_VERIFY, {
      otp_code: otpCode
    });
  };
}
