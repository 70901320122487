import IconArrowDown from "@/components/v3-icons/IconArrowDown.vue";
import IconArrowUp from "@/components/v3-icons/IconArrowUp.vue";
import IconHome from "@/components/v3-icons/IconHome.vue";
import IconShop from "@/components/v3-icons/IconShop.vue";
import IconAnalysis from "@/components/v3-icons/IconAnalysis.vue";
import IconHorizontalBars from "@/components/v3-icons/IconHorizontalBars.vue";
import IconBranches from "@/components/v3-icons/IconBranches.vue";
import IconBinocular from "@/components/v3-icons/IconBinocular.vue";
import IconHeart from "@/components/v3-icons/IconHeart.vue";
import IconPin from "@/components/v3-icons/IconPin.vue";
import IconHistory from "@/components/v3-icons/IconHistory.vue";
import IconMemo from "@/components/v3-icons/IconMemo.vue";
import IconMessage from "@/components/v3-icons/IconMessage.vue";
import IconSideBarArrowDown from "@/components/v3-icons/IconSideBarArrowDown.vue";
import IconSideBarArrowUp from "@/components/v3-icons/IconSideBarArrowUp.vue";
import IconThematicAnalysis from "@/components/v3-icons/IconThematicAnalysis.vue";

export const iconList: { [key: string]: typeof IconArrowUp } = {
  "arrow-up": IconArrowUp,
  "arrow-down": IconArrowDown,
  home: IconHome,
  shop: IconShop,
  analysis: IconAnalysis,
  "horizontal-bars": IconHorizontalBars,
  branches: IconBranches,
  binocular: IconBinocular,
  heart: IconHeart,
  pin: IconPin,
  history: IconHistory,
  memo: IconMemo,
  message: IconMessage,
  "side-bar-arrow-down": IconSideBarArrowDown,
  "side-bar-arrow-up": IconSideBarArrowUp,
  "thematic-analysis": IconThematicAnalysis
};
