<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-condition-balloon">
    <Overlay class="header_filter-panel-overlay" @click="onClickClose" />
    <Balloon
      ref="filterFormBalloon"
      class="header_filter-panel"
      :adjust="arrowPosition"
    >
      <div
        class="header_filter-panel-container"
        :class="{
          'header_filter-panel-container__has-button': isSelectedTabFilterConditions
        }"
        data-cy="filter-panel-container"
      >
        <div
          ref="filterPanelInner"
          class="header_filter-panel-inner customScrollbar"
          @scroll="onScroll"
        >
          <FilterConditionForm
            v-if="isSelectedTabFilterConditions"
            v-model="filterCondition"
            class="header_filter-form"
          />
          <template v-else-if="isSelectedTabFilterHistory">
            <FilterHistoryEntry
              v-for="filterHistory in filterHistories"
              :key="filterHistory.id"
              :history="filterHistory"
              @click="onClickHistory"
            >
              <FilterConditionLabel
                :filter-condition="filterHistory.filterCondition"
              />
            </FilterHistoryEntry>
          </template>
          <div class="header_filter-panel-header">
            <SearchHeaderTab
              v-model="selectedFilterTab"
              class="header_filter-tab"
              :options="tabFilterOptions"
            />
            <Button
              v-if="isFilterMode"
              class="button-clear-filter"
              data-cy="button-clear-filter"
              @click="clearFilter"
            >
              <div v-t="'clearFilter'" class="button-clear-filter__text" />
              <Icon
                class="button-clear-filter__icon"
                :icon="iconButtonClose"
                :size="18"
                :color="iconButtonCloseColor"
              />
            </Button>
          </div>
        </div>
      </div>
      <div
        v-show="isSelectedTabFilterConditions"
        class="header_filter-panel-buttons"
      >
        <Button
          v-t="'filter'"
          class="button-filter"
          data-cy="button-filter"
          :disabled="shouldDisableSubmitButton"
          @click="filter"
        />
        <Button
          v-t="'cancel'"
          class="button-cancel"
          data-cy="button-filter-cancel"
          type="light"
          @click="onClickClose"
        />
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import Icon from "@/components/Icon.vue";
import Balloon from "@/components/Balloon.vue";
import Overlay from "@/components/Overlay.vue";
import SearchHeaderTab from "@/components/search/SearchHeaderTab.vue";
import { TabOption } from "@/components/search/TabOption";
import FilterConditionForm from "@/components/filter/FilterConditionForm.vue";
import FilterHistoryEntry from "@/components/filter/FilterHistoryEntry.vue";
import { FilterHistory } from "@/models/search/FilterHistory";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import {
  FilterCondition,
  deepCopyFilterCondition
} from "@/models/search/filter-condition/FilterCondition";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { handleNoQueryCacheError } from "@/util/error-util";
import { showAlert } from "@/util/modal-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import FilterConditionLabel from "@/views/FilterConditionLabel.vue";
import { UgTag, UgEventTag, UgAttributeTag } from "@/store/modules/ugTag";
import { addHidToUsersUrl } from "@/router";
import { SearchResultViews } from "@/const/SearchResultViews";

enum TabFlterId {
  Conditions,
  History
}

@Component({
  components: {
    Button,
    Icon,
    Balloon,
    Overlay,
    SearchHeaderTab,
    FilterConditionForm,
    FilterHistoryEntry,
    FilterConditionLabel
  }
})
export default class FilterConditionBalloon extends Vue {
  @Emit("click-close")
  onClickClose() {}

  @Prop({ type: Number, default: false })
  searchNavigationWidth!: number;

  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[] = this
    .$store.state.system.globalConversionAttributeDefinitions;
  iconButtonClose = Icons.ButtonClose;
  iconButtonCloseColor = Colors.Base500;

  // タブの種類
  tabFilterOptions!: TabOption[];

  // 絞り込みの選択中のタブ
  selectedFilterTab: TabOption = { id: 0, label: "", isNew: false };

  ro: ResizeObserver | null = null;

  isScrollPositionBottom = false;

  created() {
    this.tabFilterOptions = [
      {
        id: TabFlterId.Conditions,
        label: this.$t("filterCondition") as string,
        isNew: false
      },
      {
        id: TabFlterId.History,
        label: this.$t("filterHistory") as string,
        isNew: false
      }
    ];
    this.selectedFilterTab = this.tabFilterOptions[0];
  }

  setResizeObserver() {
    // This is a workaround for the issue that the scroll position is not adjusted when the height of the filter form is changed.
    const elementsWrapper = document.querySelector(".header_filter-form");
    const scrollWrapper = this.$refs.filterPanelInner as HTMLElement | null;
    let elementsWrapperHeight = (elementsWrapper as HTMLElement).offsetHeight;

    if (!elementsWrapper || !scrollWrapper) {
      return;
    }

    if (this.ro) {
      this.ro.disconnect();
      this.ro = null;
    }

    this.ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        const prevHeight = elementsWrapperHeight;
        elementsWrapperHeight = cr.height;
        if (
          this.isScrollPositionBottom &&
          elementsWrapperHeight - prevHeight > 0
        ) {
          (scrollWrapper as HTMLElement).scrollTop =
            (scrollWrapper as HTMLElement).scrollTop +
            elementsWrapperHeight -
            prevHeight;
        }
      }
    });

    if (elementsWrapper) {
      this.ro.observe(elementsWrapper);
    }
  }
  // Since header_filter-form is destroyed when the tab is switched to history
  // Therefore, init the resize observer when the tab is switched
  @Watch("selectedFilterTab.id")
  onChangeSelectedTab(val: TabFlterId) {
    if (val === TabFlterId.Conditions) {
      this.$nextTick(() => {
        this.setResizeObserver();
        this.onScroll();
      });
    }
  }

  mounted() {
    this.setResizeObserver();
  }

  beforeDestroy() {
    if (this.ro) {
      this.ro.disconnect();
      this.ro = null;
    }
  }

  // Adjust return result with `-5` because the arrow is not exactly centered
  get arrowPosition(): number {
    return this.searchNavigationWidth / 4 - 5;
  }

  /**
   * 絞り込み情報・関数
   */
  get isFilterMode(): boolean {
    return this.$store.state.filter.isFilterMode;
  }

  get filterCondition(): FilterCondition {
    return this.$store.state.filter.filterConditionForForm;
  }
  set filterCondition(condition: FilterCondition) {
    this.$store.commit("filter/setFilterConditionForForm", condition);
  }

  get canUseWebdataFeatures(): boolean {
    return this.$store.state.app.canUseWebdataFeatures;
  }

  get isContractApp(): boolean {
    return this.$store.state.client.client.isContractApp;
  }

  get isSelectedTabFilterConditions(): boolean {
    return this.selectedFilterTab.id === TabFlterId.Conditions;
  }
  get isSelectedTabFilterHistory(): boolean {
    return this.selectedFilterTab.id === TabFlterId.History;
  }

  get isFilterConditionEmpty(): boolean {
    return (
      this.filterCondition.filterNodes.length === 0 &&
      this.filterCondition.deviceTypes.length === 0
    );
  }

  get shouldClear(): boolean {
    // 絞り込み中かつ、条件がなにも選択されていない
    return this.isFilterMode && this.isFilterConditionEmpty;
  }

  get indexOfEnableExcludeCondition(): number | null {
    return this.$store.state.filter.indexOfEnableExcludeCondition;
  }

  get isMiddleExclusionEnabledButConditionNotSet() {
    return (
      this.indexOfEnableExcludeCondition !== null &&
      !this.filterCondition.filterNodes.some(node => node.isExcluded)
    );
  }

  get shouldDisableSubmitButton(): boolean {
    if (!this.$store.state.search.historyId) return true;

    if (this.isMiddleExclusionEnabledButConditionNotSet) return true;

    return !this.filterCondition.validate.isValid;
  }

  get filterHistories(): FilterHistory[] {
    return this.$store.state.searchHistory.filterHistories;
  }

  get isFunnelResult(): boolean {
    return this.$route.name === "funnel-analysis-detail";
  }

  onClickHistory(condition: FilterCondition) {
    const activeDefinitions: ActiveDefinitions = this.$store.getters[
      "clientSettings/activeDefinitions"
    ];
    const conversionDefinitions: ConversionDefinition[] = this.$store.getters[
      "system/activeGlobalConversionDefinitions"
    ];
    const deepCopyCondition = deepCopyFilterCondition(
      condition,
      this.canUseWebdataFeatures,
      this.isContractApp,
      activeDefinitions,
      conversionDefinitions,
      this.globalConversionAttributeDefinitions
    );

    this.$store.commit("filter/setFilterConditionForForm", deepCopyCondition);
    // Check if any node is excluded and if so set the index of the excluded item
    const indexOfExcludedNode = condition.filterNodes.findIndex(
      node => node.isExcluded
    );
    const isLastNodeExcluded =
      indexOfExcludedNode === condition.filterNodes.length - 1;
    const enableExcludeConditionIndex =
      indexOfExcludedNode > 0 && !isLastNodeExcluded
        ? indexOfExcludedNode - 1
        : null;

    this.$store.commit(
      "filter/setIndexOfEnableExcludeCondition",
      enableExcludeConditionIndex
    );

    this.selectedFilterTab = this.tabFilterOptions[0];
    const el = document.querySelector(".header_filter-panel-inner");
    if (el) {
      el.scrollTop = 0;
    }
  }

  clearFilter() {
    this.$store.dispatch("filter/clearFilter");
    if (this.isFunnelResult) {
      const query: { [key: string]: string } = {
        s: this.$route.query.s as string,
        m: this.$route.query.m as string
      };
      this.$router.replace({ query });
    } else {
      const updateUrlFunction = addHidToUsersUrl(this.$router);
      updateUrlFunction(this.$store.state.search.historyId);
    }
    this.onClickClose();
  }

  filter() {
    const validationResult: ValidationResult = this.$store.state.filter
      .filterConditionForForm.validate;
    if (!validationResult.isValid) {
      if (validationResult.errorMessage) {
        showAlert(validationResult.errorMessage);
      }
      return;
    }
    // お気に入り条件をクリア
    this.$store.commit("search/setFavoriteSearchLabel", "");

    this.$store
      .dispatch("filter/executeFilter", {
        setHistoryIdToUrl: addHidToUsersUrl(this.$router)
      })
      .catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });

    this.$store.commit(
      "preference/setSearchResultView",
      SearchResultViews.Overview
    );

    if (!this.isFunnelResult) {
      const query: { [key: string]: string } = {
        view: this.$store.state.preference.searchResultView
      };

      this.$router.push({
        name: this.$router.currentRoute.name as string,
        query
      });
    }
    this.onClickClose();

    const numberOfNodes = this.filterCondition.filterNodes.length;
    UgTag.pushEvent(UgEventTag.Filter, {
      [UgAttributeTag.NumberOfNodes]: numberOfNodes,
      [UgAttributeTag.Condition]: this.getLabelStringFromFilterConditionLabel(
        this.filterCondition
      )
    });
  }

  getLabelStringFromFilterConditionLabel(condition: FilterCondition) {
    const FilterConditionLabelComponentClass = Vue.extend(FilterConditionLabel);
    const instance = new FilterConditionLabelComponentClass({
      propsData: { filterCondition: condition },
      parent: this
    });
    instance.$mount();
    return (instance as FilterConditionLabel).filterLabelTextAsSingleString;
  }

  onScroll() {
    const elementsWrapper = document.querySelector(".header_filter-form");
    const scrollWrapper = this.$refs.filterPanelInner as HTMLElement | null;

    if (!elementsWrapper || !scrollWrapper) {
      return;
    }
    const numberOfNodes = elementsWrapper.querySelectorAll(
      ".filterConditionForm__dragAreaNode"
    ).length;

    const scrollPositionThreshold = this.getScrollPositionThreshold(
      scrollWrapper.offsetHeight,
      (elementsWrapper as HTMLElement).offsetHeight,
      numberOfNodes
    );

    if (scrollWrapper.scrollTop > scrollPositionThreshold) {
      this.isScrollPositionBottom = true;
    } else {
      this.isScrollPositionBottom = false;
    }
  }

  getScrollPositionThreshold(
    scrollWrapperHeight: number,
    elementsWrapperHeight: number,
    numberOfNodes: number
  ): number {
    const heightDiff = 15 * numberOfNodes;
    return elementsWrapperHeight - scrollWrapperHeight - heightDiff;
  }
}
</script>

<style lang="scss" scoped>
/* transformを使用し中央にするとFirefoxでDrag中の要素の表示がおかしくなる */
.header_filter-panel {
  position: fixed;
  top: calc(#{$headerHeight} + 15px);
  right: 0;
  left: 0;
  margin: 0 auto;
  max-height: $headerFilterPanelHeight;
  width: $headerFilterPanelWidth;
  height: 100%;
}

.header_filter-panel-container {
  padding: 85px 15px 15px;
  height: 100%;

  &.header_filter-panel-container__has-button {
    padding-bottom: $headerFilterPanelButtonsHeight;
  }
}

.header_filter-panel-inner {
  overflow-y: auto;
  padding: 10px 15px 0;
  height: 100%;
}

.header_filter-panel-header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 15px 15px 0 30px;
  width: 100%;
}

.header_filter-tab {
  flex: 1;
}

.button-clear-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-clear-filter__text {
  margin: 0 8px 0 6px;
}

.button-clear-filter__icon {
  margin: 0 4px 0 0;
}

.header_filter-panel-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 30px;
  width: 100%;
  height: $headerFilterPanelButtonsHeight;
  border-radius: 0 0 $sizeRadius $sizeRadius;
  background-color: $colorWhite;
}

.button-filter,
.button-cancel {
  flex: 1;
}
.button-filter {
  margin-right: 15px;
}
.button-cancel {
  margin-left: 15px;
}

.header_filter-form {
  padding-bottom: 40px;
}
</style>
