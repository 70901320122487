<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div
    class="filter-add-condition-button"
    data-cy="filter-add-condition-button"
    @click="onClick"
  >
    <IconButton
      class="filter-add-condition-button__icon"
      :icon="icons.PlusCircle"
      :button-type="IconButtonType.Weak"
      :size="18"
    />
    <span class="filter-add-condition-button__text">{{
      $t("addCondition")
    }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import IconButton from "@/components/IconButton.vue";

@Component({ components: { IconButton } })
export default class FilterAddConditionButton extends Vue {
  @Emit("click")
  onClick() {}

  icons = Icons;
  IconButtonType = IconButtonType;
}
</script>
<style lang="scss" scoped>
.filter-add-condition-button__text {
  padding-top: 3px;
  color: $colorBase900;
  font-size: 14px;
}

.filter-add-condition-button {
  display: inline-flex;
  margin-top: 20px;
  color: $colorBase500;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $colorBase600;
    .filter-add-condition-button__text {
      text-decoration: underline;
    }
  }
}

.filter-add-condition-button__icon {
  margin-right: 7px;
}
</style>
