import { GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { Memo } from "@/models/User";
import { Msec } from "@/util/date-util";

interface UpdateUserMemoParams {
  userId: string;
  memoContent: string;
  updatedTimeMsec: Msec;
  isMemoPage: boolean;
}

export class MemoState {}

const mutations: MutationTree<MemoState> = {};

const getters: GetterTree<MemoState, RootState> = {};

const actions: ActionTree<MemoState, RootState> = {
  async updateMemo(
    { commit, rootState },
    { userId, memoContent, updatedTimeMsec, isMemoPage }: UpdateUserMemoParams
  ) {
    const historyId = rootState.search.historyId;

    if (historyId === null) {
      // TODO エラー
      return;
    }

    try {
      const result = await rootState.api.memo.updateMemo({
        history_id: historyId,
        memo: {
          user_id: userId,
          content: memoContent,
          updated_time_msec: updatedTimeMsec
        }
      });
      const updatedMemo = {
        userId,
        memo: new Memo(memoContent, new Date(result.updated_time_msec)),
        isMemoPage
      };
      await commit("search/updateMemo", updatedMemo, { root: true });
      await commit("filter/updateMemo", updatedMemo, { root: true });
      await commit("clustering/updateMemo", updatedMemo, { root: true });
      await commit("user/updateMemo", updatedMemo, { root: true });
      await commit("history/addMemo", updatedMemo, { root: true });
    } catch (e) {
      throw e;
    }
  }
};

export const memo = {
  namespaced: true,
  state: new MemoState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
