import { ApiRes } from "@/api/api-res";

export class Coordination {
  constructor(public readonly id: number, public readonly name: string) {}

  public static fromJson(json: ApiRes.Coordination): Coordination {
    return new Coordination(json.id, json.name);
  }
}

export enum CreationMethod {
  NEW,
  EXISTING
}
