import {
  UNSPECIFIED_FLUCTUATION_VALUE,
  DIRECT_FLUCTUATION_VALUE,
  DIRECT_FLUCTUATION_MAX_VALUE,
  FluctuationValue
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { DateRange } from "@/components/date-picker/DateRange";
import { getCurrentDate } from "@/util/date-util";

export function getInitialValue(
  minValue: FluctuationValue,
  maxValue: FluctuationValue,
  includedValues: number[]
): { min: number; max: number | null } {
  let min = 0;
  let max: number | null = null;

  // select value in includedValues
  if (
    includedValues.includes(minValue.select) &&
    maxValue.select === UNSPECIFIED_FLUCTUATION_VALUE
  ) {
    min = minValue.select;
  }
  // 直接指定 - 値指定設定済みの時
  if (
    maxValue.select === UNSPECIFIED_FLUCTUATION_VALUE &&
    minValue.select === DIRECT_FLUCTUATION_VALUE
  ) {
    min = minValue.value;
  }
  // 直接指定 - 範囲指定設定済みの時
  if (
    maxValue.select === DIRECT_FLUCTUATION_VALUE &&
    minValue.select === DIRECT_FLUCTUATION_VALUE
  ) {
    min = minValue.value;
    max = maxValue.value;
  }
  // 旧リピート検索復元 - 下限x% & 上限なし
  if (
    DIRECT_FLUCTUATION_VALUE > minValue.select &&
    minValue.select > UNSPECIFIED_FLUCTUATION_VALUE &&
    maxValue.select === UNSPECIFIED_FLUCTUATION_VALUE
  ) {
    min = minValue.select;
  }
  // 旧リピート検索復元 - 下限なし & 上限x%
  if (
    minValue.select === UNSPECIFIED_FLUCTUATION_VALUE &&
    DIRECT_FLUCTUATION_VALUE > maxValue.select &&
    maxValue.select > UNSPECIFIED_FLUCTUATION_VALUE
  ) {
    max = maxValue.select;
  }
  // 旧リピート検索復元 - 下限x% & 上限x%
  if (
    DIRECT_FLUCTUATION_VALUE > minValue.select &&
    minValue.select > UNSPECIFIED_FLUCTUATION_VALUE &&
    DIRECT_FLUCTUATION_VALUE > maxValue.select &&
    maxValue.select > UNSPECIFIED_FLUCTUATION_VALUE
  ) {
    min = minValue.select;
    max = maxValue.select;
  }
  // 旧リピート検索復元 - 下限x% & 上限直接指定
  if (
    DIRECT_FLUCTUATION_VALUE > minValue.select &&
    minValue.select > UNSPECIFIED_FLUCTUATION_VALUE &&
    maxValue.select === DIRECT_FLUCTUATION_VALUE
  ) {
    min = minValue.select;
    max = maxValue.value;
  }
  // 旧リピート検索復元 - 下限直接指定 & 上限x%
  if (
    minValue.select === DIRECT_FLUCTUATION_VALUE &&
    DIRECT_FLUCTUATION_VALUE > maxValue.select &&
    maxValue.select > UNSPECIFIED_FLUCTUATION_VALUE
  ) {
    min = minValue.value;
    max = maxValue.select;
  }

  return { min, max };
}

export function getConvertPercentageToTimes(value: number) {
  const result =
    (value + DIRECT_FLUCTUATION_MAX_VALUE) / DIRECT_FLUCTUATION_MAX_VALUE;

  return value === 0 ? 1 : result;
}

export function getConvertTimesToPercentage(value: number) {
  const result =
    value * DIRECT_FLUCTUATION_MAX_VALUE - DIRECT_FLUCTUATION_MAX_VALUE;

  return value === 1 ? 0 : Math.floor(result);
}

export function getPercentOfDecrease(value: number) {
  return DIRECT_FLUCTUATION_MAX_VALUE - value;
}

export function getDaysDiff(value: DateRange): number {
  const min = new Date(value.min);
  const max = new Date(value.max);

  return Math.floor(1 + (max.getTime() - min.getTime()) / 86400000);
}

export function getPeriodDateRanges(
  value: number
): { pastPeriod: DateRange; targetPeriod: DateRange } {
  const baseDate = getCurrentDate();
  baseDate.setDate(baseDate.getDate() - 2);
  baseDate.setHours(23, 59, 59, 999);
  const diff = value - 1;

  const targetMinDate = new Date(baseDate);
  targetMinDate.setDate(targetMinDate.getDate() - diff);
  targetMinDate.setHours(0, 0, 0, 0);

  const pastMaxDate = new Date(targetMinDate);
  pastMaxDate.setDate(pastMaxDate.getDate() - 1);
  pastMaxDate.setHours(23, 59, 59, 999);

  const pastMinDate = new Date(pastMaxDate);
  pastMinDate.setDate(pastMinDate.getDate() - diff);
  pastMinDate.setHours(0, 0, 0, 0);

  const pastPeriod: DateRange = {
    min: pastMinDate,
    max: pastMaxDate
  };

  const targetPeriod: DateRange = {
    min: targetMinDate,
    max: baseDate
  };

  return {
    pastPeriod,
    targetPeriod
  };
}
