<template>
  <div class="loading" :style="style">
    <img src="../assets/img/loading_circle.gif" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Loading extends Vue {
  @Prop({ type: String, default: "100vh" })
  height!: string;

  get style() {
    return {
      height: this.height
    };
  }
}
</script>

<style scoped lang="scss">
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & img {
    width: 58px;
    height: 58px;
  }
}
</style>
