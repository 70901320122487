<i18n src="@/i18n/views/help.json"></i18n>
<template>
  <div class="help-nav">
    <Balloon
      v-show="true"
      class="help-nav_wrapper"
      data-cy="help-nav_wrapper"
      :direction="balloonDirection"
    >
      <div class="help-nav_inner" data-cy="help-nav_links">
        <a
          class="help-nav_item"
          href="https://blog.usergram.info/"
          target="_blank"
          data-cy="help-nav-online-support"
          rel="noopener"
        >
          <NavigationItem :icon="iconMortarboard">
            <span class="help-nav_text">{{ $t("onlineSupport") }}</span>
            <Icon
              :icon="iconExternalLink"
              :size="iconSize"
              :color="iconColor"
              class="help-nav_icon"
            />
          </NavigationItem>
        </a>
        <a
          class="help-nav_item"
          href="https://help.usergram.info/"
          target="_blank"
          data-cy="help-nav-help-site"
          rel="noopener"
        >
          <NavigationItem :icon="iconHelp">
            <span class="help-nav_text">{{ $t("helpSite") }}</span>
            <Icon
              :icon="iconExternalLink"
              :size="iconSize"
              :color="iconColor"
              class="help-nav_icon"
            />
          </NavigationItem>
        </a>
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { BalloonDirection } from "@/const/balloon";
import Balloon from "@/components/Balloon.vue";
import Icon from "@/components/Icon.vue";
import NavigationItem from "@/components/NavigationItem.vue";
const ICON_COLOR: string = "inherit";
const ICON_SIZE: number = 11;
@Component({
  components: {
    Balloon,
    Icon,
    NavigationItem
  }
})
export default class Help extends Vue {
  iconHelp = Icons.Help;
  iconExternalLink = Icons.ExternalLink;
  iconMortarboard = Icons.Mortarboard;
  iconColor = ICON_COLOR;
  iconSize = ICON_SIZE;
  balloonDirection = BalloonDirection.TopEnd;
}
</script>

<style lang="scss" scoped>
.help-nav_wrapper {
  width: 200px;
}
.help-nav_inner {
  padding: 10px 0;
}
.help-nav_links {
  padding: 10px 0;
}
.help-nav_item {
  display: block;
  &:hover {
    text-decoration: none;
  }
}
.help-nav_text {
  color: $colorBase900;
}
.help-nav_icon {
  margin-left: 10px;
}
</style>
