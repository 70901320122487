<template>
  <div class="userTrendAppViewTable">
    <AnalysisTable :sticky-top="stickyTop">
      <template #header>
        <UserTrendTableHeader
          page-column-width="20%"
          :page-type="pageType"
          :sort-column="sortColumn"
          :is-compared="isCompared"
          @sort="fetchData"
        />
      </template>
      <!-- normal row -->
      <AnalysisTableRow
        v-for="(row, id) in rowData"
        :key="row.aggregateKeys[0].key"
      >
        <UserTrendCell
          :label="row.aggregateKeys[0].keyForDisplay"
          :active-tooltip="false"
          width="20%"
          @mouse-enter="onMouseEnter(id)"
          @mouse-leave="onMouseLeave"
        >
          <UserTrendUserIcon
            v-show="row.userIds.length > 0"
            @click="onClickUserIcon(row.userIds)"
          />
        </UserTrendCell>

        <template v-for="column in columns">
          <AnalysisTableCell
            :key="column"
            class="userTrendAppViewTable__cell"
            @mouse-enter="onMouseEnter(id)"
            @mouse-leave="onMouseLeave"
          >
            {{ row[userTrendDataType.base][column].toLocaleString() }}
          </AnalysisTableCell>
          <AnalysisTableCell
            v-if="isCompared"
            :key="column + 1"
            class="userTrendAppViewTable__cell"
            :class="{ 'userTrendAppViewTable__cell--target': !isHoverRow(id) }"
            @mouse-enter="onMouseEnter(id)"
            @mouse-leave="onMouseLeave"
          >
            {{ row[userTrendDataType.comparison][column].toLocaleString() }}
          </AnalysisTableCell>
        </template>
      </AnalysisTableRow>
    </AnalysisTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserTrendRowData } from "@/models/user-trend/UserTrendRowData";
import {
  UserTrendColumnType,
  UserTrendDataType,
  AnalysisType
} from "@/const/user-trend";
import {
  sortColumnData,
  pageColumnTypes
} from "@/components/user-trend/table/table-util";

import AnalysisTable from "@/components/table/AnalysisTable.vue";
import AnalysisTableRow from "@/components/table/AnalysisTableRow.vue";
import AnalysisTableCell from "@/components/table/AnalysisTableCell.vue";
import AnalysisTableHeaderRow from "@/components/table/AnalysisTableHeaderRow.vue";
import AnalysisTableHeaderCell from "@/components/table/AnalysisTableHeaderCell.vue";
import UserTrendTableHeader from "@/components/user-trend/table/UserTrendTableHeader.vue";
import UserTrendCell from "@/components/user-trend/table/UserTrendCell.vue";
import UserTrendUserIcon from "@/components/user-trend/table/UserTrendUserIcon.vue";

@Component({
  components: {
    AnalysisTable,
    AnalysisTableRow,
    AnalysisTableCell,
    AnalysisTableHeaderRow,
    AnalysisTableHeaderCell,
    UserTrendTableHeader,
    UserTrendCell,
    UserTrendUserIcon
  }
})
export default class UserTrendAppViewTable extends Vue {
  userTrendDataType: typeof UserTrendDataType = UserTrendDataType;
  pageType = AnalysisType.AppView;

  hoveredCellIndex: number = -1;

  @Prop({ type: Array, required: true })
  rowData!: UserTrendRowData[];

  @Prop({ type: Boolean, required: true })
  isCompared!: boolean;

  @Prop({ type: Object, required: true })
  sortColumn!: sortColumnData;

  @Prop({ type: String, default: "0" })
  stickyTop!: string;

  fetchData(sortColumn: sortColumnData) {
    this.$emit("sort", sortColumn);
  }

  // 表からユーザ一覧への導線
  onClickUserIcon(userIds: string[]) {
    this.$emit("click-user-icon", userIds);
  }

  get columns(): UserTrendColumnType[] {
    return pageColumnTypes(this.pageType);
  }

  isHoverRow(id: number): boolean {
    return this.hoveredCellIndex === id;
  }

  onMouseEnter(id: number) {
    this.hoveredCellIndex = id;
  }

  onMouseLeave() {
    this.hoveredCellIndex = -1;
  }
}
</script>

<style scoped lang="scss">
.userTrendAppViewTable__cell {
  text-align: right;
}

.userTrendAppViewTable__cell--target {
  background-color: $colorGray100;
}
</style>
