import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import {
  InputFormSuggestionStatus,
  InputFormSuggestionType
} from "@/const/input-form-suggestion";
import { SuggestionResultResponse } from "@/api/apis/ApiInputFormSuggestion";

export class InputFormSuggestionState {
  status: InputFormSuggestionStatus = InputFormSuggestionStatus.RUNNING;
  result: string[] = [];
}

const mutations = <MutationTree<InputFormSuggestionState>>{
  setStatus(
    state: InputFormSuggestionState,
    status: InputFormSuggestionStatus
  ) {
    state.status = status;
  },
  setResult(state: InputFormSuggestionState, result: string[]) {
    state.result = result;
  }
};

const actions = <ActionTree<InputFormSuggestionState, RootState>>{
  resetResult({ state, commit }) {
    if (state.status !== InputFormSuggestionStatus.FAILED) {
      commit("setStatus", InputFormSuggestionStatus.RUNNING);
    }
    commit("setResult", []);
  },
  async create({ commit, rootState }) {
    try {
      await rootState.api.inputFormSuggestion.prepare();
    } catch {
      commit("setStatus", InputFormSuggestionStatus.FAILED);
    }
  },
  async fetchResult(
    { state, commit, rootState },
    params: { type: InputFormSuggestionType; input_str: string }
  ) {
    if (state.status === InputFormSuggestionStatus.FAILED) {
      return;
    }

    if (params.input_str.length === 0) {
      return;
    }

    try {
      const response: SuggestionResultResponse = await rootState.api.inputFormSuggestion.getResult(
        params.type,
        params.input_str
      );

      commit("setStatus", response.status);
      commit("setResult", response.result || []);
    } catch {
      commit("setStatus", InputFormSuggestionStatus.FAILED);
    }
  }
};

export const inputFormSuggestion = {
  namespaced: true,
  state: new InputFormSuggestionState(),
  mutations,
  actions
};
