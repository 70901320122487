<i18n src="@/i18n/views/client-settings/client-settings-top.json"></i18n>
<template>
  <div class="clientSettingsTop" data-cy="client-settings-top">
    <div
      v-if="client.hasWebOrGA4Contract"
      class="clientSettingsTop__measurementWebSettings"
    >
      <div v-t="'measurementWebSettings'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div v-if="client.hasWebOrGA4Contract" class="clientSettingsTop__link">
          <a
            v-t="'siteUrlTitle'"
            href="/client-settings/site-url/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div v-t="'siteUrlDesc'" class="clientSettingsTop__linkDescription" />
        </div>

        <div v-if="client.isContractWeb" class="clientSettingsTop__link">
          <a
            v-t="'measurementMethodTitle'"
            href="/client-settings/branded-domain-tracking/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'measurementMethodDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div v-if="client.isContractWeb" class="clientSettingsTop__link">
          <RouterLink
            v-t="'conversionTitle'"
            :to="{ name: 'conversion-settings-index' }"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'conversionDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div v-if="client.isContractWeb" class="clientSettingsTop__link">
          <a
            v-t="'eventTitle'"
            href="/client-settings/event/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div v-t="'eventDesc'" class="clientSettingsTop__linkDescription" />
        </div>

        <div v-if="client.isContractWeb" class="clientSettingsTop__link">
          <a
            v-t="'conversionAndEventAttributeTitle'"
            href="/client-settings/conversion-attribute/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'conversionAndEventAttributeDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div v-if="client.isContractWeb" class="clientSettingsTop__link">
          <a
            v-t="'measurementTagTitle'"
            href="/client-settings/measurement-tag/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'measurementTagDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div v-if="client.isContractWeb" class="clientSettingsTop__link">
          <a
            v-t="'exclusionIpTitle'"
            href="/client-settings/exclusion-ip/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'exclusionIpDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
      </div>
    </div>

    <div
      v-if="client.hasAppOrGA4Contract"
      class="clientSettingsTop__measurementAppGA4Settings"
    >
      <div v-t="'measurementAppGA4Settings'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div class="clientSettingsTop__link">
          <a
            v-t="'targetAppGA4Title'"
            href="/client-settings/coordination/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'targetAppGA4Desc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div
          v-for="coordination in coordinations.slice(0, 5)"
          :key="coordination.id"
          class="clientSettingsTop__link clientSettingsTop__targetApp"
        >
          <a
            :href="
              `/client-settings/coordination/${coordination.id}/detail/index/`
            "
            class="clientSettingsTop__linkTitle"
            >{{ coordination.name }}</a
          >
          <div class="clientSettingsTop__linkDescription">
            <div class="clientSettingsTop__subLink">
              <a
                v-t="'conversionSettings'"
                :href="
                  `/client-settings/coordination/${coordination.id}/conversion/index/`
                "
              />
            </div>
            <div class="clientSettingsTop__subLink">
              <a
                v-t="'eventSettings'"
                :href="
                  `/client-settings/coordination/${coordination.id}/event/index/`
                "
              />
            </div>
            <div class="clientSettingsTop__subLink">
              <a
                v-t="'attributeSettings'"
                :href="
                  `/client-settings/coordination/${coordination.id}/cvattribute/index/`
                "
              />
            </div>
          </div>
        </div>

        <div
          v-if="hasMoreThanFiveCoordinations"
          class="clientSettingsTop__link"
        >
          <a
            v-t="'seeAllCoordinationSettings'"
            href="/client-settings/coordination/index/"
            class="clientSettingsTop__linkTitle"
          />
        </div>
      </div>
    </div>

    <div class="clientSettingsTop__accountManagement">
      <div v-t="'accountManagement'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div class="clientSettingsTop__link">
          <a
            v-t="'idPasswordManagementTitle'"
            href="/client-settings/id-management/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'idPasswordManagementDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div class="clientSettingsTop__link">
          <a
            v-t="'usageOfIdTitle'"
            href="/client-settings/user-usage/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'usageOfIdDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div class="clientSettingsTop__link">
          <a
            v-t="'loginRestrictionTitle'"
            href="/client-settings/login-restriction/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'loginRestrictionDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
      </div>
    </div>

    <div
      v-if="client.hasAnyOfImportContract"
      class="clientSettingsTop__importDataSettings"
    >
      <div v-t="'importDataSettings'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div v-if="client.isContractNps" class="clientSettingsTop__link">
          <a
            v-t="'customerLoyaltyTitle'"
            href="/client-settings/loyalty/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'customerLoyaltyDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div
          v-if="client.isContractBusinessIndex"
          class="clientSettingsTop__link"
        >
          <a
            v-t="'businessIndexTitle'"
            href="/client-settings/biz-index/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'businessIndexDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div
          v-if="client.isContractBusinessEvent"
          class="clientSettingsTop__link"
        >
          <a
            v-t="'businessEventTitle'"
            href="/client-settings/be/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'businessEventDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div
          v-if="client.isContractUserAttribute"
          class="clientSettingsTop__link"
        >
          <a
            v-t="'userAttributeTitle'"
            href="/client-settings/user_attr/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'userAttributeDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div v-if="client.isContractContact" class="clientSettingsTop__link">
          <a
            v-t="'contactHistoryTitle'"
            href="/client-settings/contact/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'contactHistoryDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div class="clientSettingsTop__link">
          <a
            v-t="'csvTemplateTitle'"
            href="/client-settings/csv_format/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'csvTemplateDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
        <div v-if="isImportValidationEnabled" class="clientSettingsTop__link">
          <a
            v-t="'importHistoryTitle'"
            href="/client-settings/import_history/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'importHistoryDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
      </div>
    </div>

    <div v-if="client.isContractTdImport" class="clientSettingsTop__tdImport">
      <div v-t="'tdImportTitle'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div class="clientSettingsTop__link">
          <RouterLink
            v-t="'tdWebImportConfigSetting'"
            to="/client-settings/treasure-data/td-web-import-config"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'tdWebImportConfigSettingDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div class="clientSettingsTop__link">
          <RouterLink
            v-t="'tdConversion'"
            to="/client-settings/treasure-data/td-conversion"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'tdConversionDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>

        <div class="clientSettingsTop__link">
          <RouterLink
            v-t="'tdConversionAttribute'"
            to="/client-settings/treasure-data/td-conversion-attr"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'tdConversionAttributeDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
      </div>
    </div>

    <div class="clientSettingsTop__usageStatus">
      <div v-t="'usageStatus'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div class="clientSettingsTop__link">
          <a
            v-t="'historyOfMeasurementTitle'"
            href="/client-settings/use-status/index/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'historyOfMeasurementDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
      </div>
    </div>

    <div
      v-if="client.isContractWeb"
      class="clientSettingsTop__measurementDataOutput"
    >
      <div v-t="'measurementDataOutput'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div class="clientSettingsTop__link">
          <a
            v-t="'activityDataFullDownloadTitle'"
            href="/client-settings/csv-takeout/"
            class="clientSettingsTop__linkTitle"
          />
          <div
            v-t="'activityDataFullDownloadDesc'"
            class="clientSettingsTop__linkDescription"
          />
        </div>
      </div>
    </div>

    <div class="clientSettingsTop__help">
      <div v-t="'help'" class="clientSettingsTop__title" />
      <div class="clientSettingsTop__links">
        <div class="clientSettingsTop__link">
          <a
            v-t="'helpSiteTitle'"
            href="https://help.usergram.info/"
            target="_blank"
            rel="noopener"
            class="clientSettingsTop__linkTitle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { Client } from "@/models/Client";
import { Coordination } from "@/models/client-settings/Coordination";

@Component({
  components: {}
})
export default class ClientSettingsTop extends Vue {
  get client(): Client {
    return this.$store.state.client.client;
  }

  get coordinations(): Coordination[] {
    return this.$store.state.clientSettings.coordinations;
  }

  get hasMoreThanFiveCoordinations(): boolean {
    return this.coordinations.length > 5;
  }

  get isImportValidationEnabled(): boolean {
    return this.$store.state.app.isImportValidationEnabled;
  }
}
</script>

<style lang="scss" scoped>
.clientSettingsTop {
  margin-right: 20px;
  min-width: 704px;
}

.clientSettingsTop__links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  border-bottom: 1px solid $colorBase500;
}

.clientSettingsTop__link {
  padding: 0 90px 38px 0;
  width: 50%;
}

.clientSettingsTop__title {
  padding: 0 0 46px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: inherit;
  cursor: pointer;
}

.clientSettingsTop__linkTitle {
  color: $colorBase700;
  text-decoration: underline;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;

  &:hover {
    color: $colorBase800;
  }
}

.clientSettingsTop__linkDescription {
  margin: 12px 0;
  white-space: pre-line;
  font-size: 12px;
  line-height: 1.5;
}

.clientSettingsTop__subLink {
  padding: 3px 5px 3px 10px;
  color: $colorBase700;

  &::before {
    content: "- ";
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-size: 12px;
    line-height: 1.5;
    cursor: pointer;

    &:hover {
      color: $colorBase800;
    }
  }
}
</style>
