<i18n
  src="@/i18n/components/client-settings/conversion/conversion-list-table.json"
></i18n>
<template>
  <div class="conversionListTable">
    <SettingsTable min-width="680px">
      <template slot="header">
        <SettingsTableRow>
          <SettingsTableHeaderCell
            v-t="'measurementState'"
            text-align="center"
          />
          <SettingsTableHeaderCell v-t="'conversionName'" text-align="center" />
          <SettingsTableHeaderCell
            text-align="center"
            class="conversionListTable__cvParamHeaderCell"
          >
            <span v-t="'conversionParameter'" />
            <Tooltip :placement="tooltipPlacement.Bottom">
              <Icon
                :icon="iconHelp"
                :color="iconColor"
                style="margin-left: 4px"
              />
              <div
                slot="content"
                v-t="'conversionParameterTooltipText'"
                class="conversionListTable__tooltipContent"
              />
            </Tooltip>
          </SettingsTableHeaderCell>
          <SettingsTableHeaderCell v-t="'edit'" text-align="center" />
          <SettingsTableHeaderCell
            text-align="center"
            class="conversionListTable__measurementStateHeaderCell"
          >
            <span v-t="'changeMeasurementState'" />
            <Tooltip :placement="tooltipPlacement.BottomEnd">
              <Icon
                :icon="iconHelp"
                :color="iconColor"
                style="margin-left: 4px"
              />
              <div
                slot="content"
                v-t="'measuringStateTooltipText'"
                class="conversionListTable__tooltipContent"
              />
            </Tooltip>
          </SettingsTableHeaderCell>
        </SettingsTableRow>
      </template>
      <SettingsTableRow v-if="isEmpty">
        <SettingsTableCell v-t="'notSet'" :colspan="5" text-align="center" />
      </SettingsTableRow>
      <template v-else>
        <SettingsTableRow
          v-for="(val, key) in activeConversions"
          :key="`active-${key}`"
        >
          <SettingsTableCell v-t="'measuring'" text-align="center" />
          <SettingsTableCell>{{ val.name }}</SettingsTableCell>
          <SettingsTableCell>{{ val.path.join(" → ") }}</SettingsTableCell>
          <SettingsTableCell text-align="center">
            <span
              v-t="'edit'"
              class="conversionListTable__link"
              @click="onClickUpdate(val.id)"
            />
          </SettingsTableCell>
          <SettingsTableCell text-align="center">
            <span
              v-t="'stop'"
              class="conversionListTable__link"
              @click="onClickUpdateState(val.id, 'disable')"
            />
          </SettingsTableCell>
        </SettingsTableRow>

        <SettingsTableRow
          v-for="(val, key) in disabledConversions"
          :key="`disabled-${key}`"
        >
          <SettingsTableCell
            v-t="'stopped'"
            text-align="center"
            :disabled="true"
          />
          <SettingsTableCell :disabled="true">
            {{ val.name }}</SettingsTableCell
          >
          <SettingsTableCell :disabled="true">
            {{ val.path.join(" → ") }}</SettingsTableCell
          >
          <SettingsTableCell text-align="center" :disabled="true">
            <span
              v-t="'edit'"
              class="conversionListTable__link"
              @click="onClickUpdate(val.id)"
            />
          </SettingsTableCell>
          <SettingsTableCell text-align="center" :disabled="true">
            <span
              v-if="canEnable"
              v-t="'start'"
              class="conversionListTable__link"
              @click="onClickUpdateState(val.id, 'enable')"
            />
            <span
              v-else
              v-t="'start'"
              class="conversionListTable__link__disabled"
            />
          </SettingsTableCell>
        </SettingsTableRow>
      </template>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableHeaderCell from "@/components/table/SettingsTableHeaderCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";

@Component({
  components: {
    Icon,
    SettingsTable,
    SettingsTableHeaderCell,
    SettingsTableRow,
    SettingsTableCell,
    Tooltip
  }
})
export default class ConversionListTable extends Vue {
  iconColor = Colors.Base600;
  iconHelp = Icons.Help;
  tooltipPlacement = TooltipPlacement;

  @Prop({ type: Array, required: true })
  activeConversions!: ConversionDefinition[];

  @Prop({ type: Array, required: true })
  disabledConversions!: ConversionDefinition[];

  @Prop({ type: Boolean, required: true })
  canEnable!: boolean;

  @Emit("click-edit")
  onClickUpdate(id: number) {
    return id;
  }

  get isEmpty(): boolean {
    const numActive = this.activeConversions.length;
    const numDisabled = this.disabledConversions.length;
    return numActive + numDisabled === 0;
  }

  onClickUpdateState(id: number, measurementStatus: "enable" | "disable") {
    this.$emit("click-update-state", id, measurementStatus);
  }
}
</script>

<style lang="scss" scoped>
.conversionListTable {
  margin-bottom: 16px;
}
.conversionListTable__cvParamHeaderCell,
.conversionListTable__measurementStateHeaderCell {
  & > * {
    vertical-align: middle;
  }
}
.conversionListTable__tooltipContent {
  width: 226px;
  white-space: pre-line;
  word-wrap: break-word;
  line-height: 1.5;
}
.conversionListTable__link {
  color: $colorBase700;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $colorBase800;
  }
}
.conversionListTable__link__disabled {
  color: $colorBase700;
}
</style>
