<template>
  <div
    v-if="isVisible"
    class="chart-business-event"
    data-cy="chart-business-event"
    :style="style"
    @click="onClick(businessEventOverview)"
    @mouseover="onMouseOver(businessEventOverview)"
    @mouseleave="onMouseLeave"
    v-text="businessEventName"
  />
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import {
  BUSINESS_EVENT_WIDTH,
  BUSINESS_EVENT_HEIGHT,
  BUSINESS_EVENT_Y,
  BUSINESS_EVENT_BACK_COLOR,
  BUSINESS_EVENT_TEXT_COLOR_MATCHED,
  BUSINESS_EVENT_STROKE_COLOR,
  COLOR_MATCHED,
  convertTimeToX,
  getXAdjustWidth,
  isInList
} from "@/components/chart/chart-util";

const stylePatterns = {
  default: {
    color: Colors.Base900,
    background: BUSINESS_EVENT_BACK_COLOR,
    "border-color": BUSINESS_EVENT_STROKE_COLOR,
    "font-weight": 400 //normal
  },
  filtered: {
    color: BUSINESS_EVENT_TEXT_COLOR_MATCHED,
    background: COLOR_MATCHED,
    "border-color": Colors.White,
    "font-weight": 700 //bold
  },
  funnelMatched: {
    color: Colors.SemiHighlightedBar,
    background: BUSINESS_EVENT_BACK_COLOR,
    "border-color": Colors.SemiHighlightedBar,
    "font-weight": 600
  },
  funnelMatchedSelected: {
    color: Colors.White,
    background: Colors.HighlightedBar,
    "border-color": Colors.HighlightedBar,
    "font-weight": 600
  }
};
@Component
export default class ChartBusinessEvent extends Vue {
  @Prop({ type: Date, required: true })
  lastScaleDate!: Date;

  @Prop({ type: Number, required: true })
  lastScaleX!: number;

  @Prop({ type: Date, required: true })
  firstScaleDate!: Date;

  @Prop({ type: Number, required: true })
  baselineLength!: number;

  @Prop({ type: BusinessEventOverview, required: true })
  businessEventOverview!: BusinessEventOverview;

  @Prop({ type: Array, required: true })
  filterMatchedIds!: string[];

  @Prop({ type: Number, required: false, default: 0 })
  scrollX!: number;

  @Prop({ type: Number, required: false })
  scrollWidth!: number;

  @Prop({ type: Boolean, required: false, default: true })
  isChartScrollable!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  isInUserDetail!: boolean;

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  onClick(businessEventOverview: BusinessEventOverview) {
    this.$emit("click", businessEventOverview);
  }

  onMouseOver(businessEventOverview: BusinessEventOverview) {
    this.$emit("mouse-over", businessEventOverview);
  }

  @Emit("mouse-leave")
  onMouseLeave() {}

  left: number | null = null;

  mounted() {
    this.updateLeftValue();
  }

  get style() {
    let matchedStyle = stylePatterns["default"];
    if (isInList(this.businessEventOverview, this.filterMatchedIds)) {
      matchedStyle = stylePatterns["filtered"];
    } else if (
      isInList(this.businessEventOverview, this.funnelMatchedSelectedGramIds)
    ) {
      matchedStyle = stylePatterns["funnelMatchedSelected"];
    } else if (
      isInList(this.businessEventOverview, this.funnelMatchedGramIds)
    ) {
      matchedStyle = stylePatterns["funnelMatched"];
    }

    this.updateLeftValue();

    return {
      top: BUSINESS_EVENT_Y + "px",
      left: this.left + "px",
      width: BUSINESS_EVENT_WIDTH + "px",
      height: BUSINESS_EVENT_HEIGHT + "px",
      ...matchedStyle
    };
  }

  get businessEventName(): string {
    // 一行は3文字までなので、3文字で改行
    const nameArray = this.businessEventOverview.definition.name.match(
      /(.{1,3})$|.{3}/g
    );
    if (nameArray === null) {
      return "";
    }
    return nameArray.join("\n");
  }

  get isVisible(): boolean {
    if (!this.isChartScrollable || this.isInUserDetail) return true;
    return (
      this.scrollWidth !== null &&
      this.left !== null &&
      this.left > this.scrollX - BUSINESS_EVENT_WIDTH &&
      this.left < this.scrollX + this.scrollWidth
    );
  }

  updateLeftValue() {
    this.left = getXAdjustWidth(
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        this.businessEventOverview.date.getTime()
      ),
      BUSINESS_EVENT_WIDTH
    );
  }
}
</script>

<style scoped lang="scss">
.chart-business-event {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $colorBase500;
  border-radius: 4px;
  text-align: center;
  white-space: pre-wrap;
  font-size: 10px;
  cursor: pointer;
  transition: transform 0.05s ease-out;

  &:hover {
    transform: scale(1.2);
  }
}
</style>
