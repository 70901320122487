import { ApiRes } from "@/api/api-res";

export class Memo {
  constructor(
    public readonly userId: string,
    public readonly content: string,
    public readonly updatedDate: Date
  ) {}

  public static fromJson(json: ApiRes.Memo): Memo {
    return new Memo(
      json.user_id,
      json.content,
      new Date(json.updated_time_msec)
    );
  }
}
