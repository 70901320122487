<i18n src="@/i18n/views/client-settings/td-conversion.json"></i18n>
<template>
  <div class="tdConversionSetting">
    <div v-t="'tdConversionSetting'" class="tdConversionSetting__title" />
    <div class="tdConversionSetting__description">
      <div v-t="'description1'" />
      <br />
      <div v-html="$t('description2')" />
    </div>
    <div v-if="!isLoading">
      <SettingsAlert
        v-if="!tdWebImportConfig.isDefined()"
        class="tdConversionSetting__configErrorMessage tdConversionSetting__errorMessage"
      >
        <span v-html="$t('notConfiguredImportConfigError')"></span>
      </SettingsAlert>

      <SettingsAlert
        v-if="conversionDefinitions.length === 0"
        class="tdConversionSetting__errorMessage"
      >
        <span v-html="$t('noConversionCreatedError')"></span>
      </SettingsAlert>
      <SettingsAlert
        v-if="
          conversionDefinitions.length > 0 && !hasEnabledConversionDefinitions
        "
        class="tdConversionSetting__errorMessage"
      >
        <span v-html="$t('noEnabledConversionError')"></span>
      </SettingsAlert>
    </div>
    <div v-if="successMessage" class="setting-success-message">
      {{ successMessage }}
    </div>
    <div class="tdConversionSetting__buttonContainer">
      <template v-if="isReadyToCreateTdConversion">
        <RouterLink
          :to="{ name: 'td-conversion-create' }"
          class="tdConversionSetting__button"
        >
          <Button
            v-t="'registerNewConversionCondition'"
            data-cy="td-create-cv-condition"
            padding="0 20px"
            :text-size="buttonTextSize"
          />
        </RouterLink>
      </template>
      <template v-else>
        <Button
          v-t="'registerNewConversionCondition'"
          data-cy="td-create-cv-condition"
          padding="0 20px"
          :disabled="true"
          :text-size="buttonTextSize"
        />
      </template>
    </div>
    <div v-if="isLoading" class="tdConversionSetting__loading">
      <Loading height="80px" />
    </div>
    <div v-else class="tdConversionSetting__table">
      <SettingsTable :min-width="'680px'">
        <template #header>
          <SettingsTableRow>
            <SettingsTableHeaderCell
              v-t="'conversionName'"
              text-align="center"
            />
            <SettingsTableHeaderCell
              v-t="'cvParamColumn'"
              text-align="center"
            />
            <SettingsTableHeaderCell
              v-t="'cvCondition'"
              :colspan="2"
              text-align="center"
            />
            <SettingsTableHeaderCell v-t="'edit'" text-align="center" />
            <SettingsTableHeaderCell
              v-t="'updateMeasurementStatus'"
              text-align="center"
            />
          </SettingsTableRow>
        </template>

        <template v-if="hasTdConversion">
          <SettingsTableRow
            v-for="tdConversion in sortedTdConversions"
            :key="tdConversion.id"
            class="tdConversionSetting__row"
          >
            <SettingsTableCell
              :disabled="tdConversion.isDisabled"
              class="tdConversionSettings__cvNameCell"
              text-align="center"
            >
              {{ getConversionNameFromConversionId(tdConversion.cvId) }}
            </SettingsTableCell>
            <SettingsTableCell
              :disabled="tdConversion.isDisabled"
              class="tdConversionSetting__cvParamCell"
              text-align="center"
            >
              {{ getCvParamColumnText(tdConversion) }}
            </SettingsTableCell>
            <SettingsTableCell
              :disabled="tdConversion.isDisabled"
              class="tdConversionSettings__cvCondCell"
              text-align="center"
            >
              {{ tdConversion.cvCond }}
            </SettingsTableCell>
            <SettingsTableCell
              :disabled="tdConversion.isDisabled"
              text-align="center"
            >
              {{ getTextMatchMethod(tdConversion.matchMethod) }}
            </SettingsTableCell>
            <SettingsTableCell
              :disabled="tdConversion.isDisabled"
              text-align="center"
            >
              <span v-if="tdConversion.isDisabled" v-t="'edit'" />
              <RouterLink
                v-else
                v-t="'edit'"
                class="tdConversionSettings__link"
                :to="{
                  name: 'td-conversion-update',
                  params: { id: tdConversion.id }
                }"
              />
            </SettingsTableCell>
            <SettingsTableCell
              :disabled="tdConversion.isDisabled"
              text-align="center"
            >
              <RouterLink
                class="tdConversionSettings__link"
                data-cy="td-cv-toggle-measurement-status"
                :to="{
                  name: 'td-conversion-update-measurement-state',
                  params: { id: tdConversion.id }
                }"
                >{{ getTextMeasurementStatusAction(tdConversion.isDisabled) }}
              </RouterLink>
            </SettingsTableCell>
          </SettingsTableRow>
        </template>
        <template v-else>
          <SettingsTableRow class="tdConversionSetting__emptyRow">
            <SettingsTableCell
              v-t="'notSet'"
              text-align="center"
              :colspan="6"
            />
          </SettingsTableRow>
        </template>
      </SettingsTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import SettingsAlert from "@/components/client-settings/SettingsAlert.vue";
import Loading from "@/components/Loading.vue";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableHeaderCell from "@/components/table/SettingsTableHeaderCell.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import { ButtonTextSize } from "@/const/button";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import TdConversionDefinition from "@/models/client-settings/TdConversionDefinition";
import { TdWebImportConfigDefinition } from "@/models/client-settings/TdWebImportConfigDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";
import { handleError } from "@/util/error-util";

@Component({
  components: {
    Button,
    SettingsAlert,
    SettingsTable,
    SettingsTableRow,
    SettingsTableHeaderCell,
    SettingsTableCell,
    Loading
  }
})
export default class TdConversionSettings extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isLoading = true;

  get hasTdConversion(): boolean {
    return this.tdConversions.length > 0;
  }
  get conversionDefinitions(): ConversionDefinition[] {
    return this.$store.state.clientSettings.conversionDefinitions;
  }
  get hasEnabledConversionDefinitions(): boolean {
    return this.conversionDefinitions.filter(cv => !cv.isDisabled).length > 0;
  }
  get tdConversions(): TdConversionDefinition[] {
    return this.$store.state.tdConversion.tdConversionDefinitions;
  }
  get tdWebImportConfig(): TdWebImportConfigDefinition {
    return this.$store.state.tdWebImportConfig.tdWebImportConfigDetails;
  }
  get successMessage(): string {
    return this.$store.state.tdConversion.successMessage;
  }
  get sortedTdConversions(): TdConversionDefinition[] {
    return [...this.tdConversions].sort((a, b) => {
      if (a.isDisabled === b.isDisabled) {
        return this.getConversionNameFromConversionId(a.cvId).localeCompare(
          this.getConversionNameFromConversionId(b.cvId)
        );
      }
      return b.isDisabled ? -1 : 1;
    });
  }
  get isReadyToCreateTdConversion(): boolean {
    return (
      !!this.tdWebImportConfig &&
      this.tdWebImportConfig.isDefined() &&
      this.hasEnabledConversionDefinitions
    );
  }
  getTextMeasurementStatusAction(isDisabled: boolean): string {
    const translationKey = isDisabled ? "startMeasuring" : "stopMeasuring";
    return this.$i18n.t(translationKey) as string;
  }
  getTextMatchMethod(matchMethod: MatchMethod): string {
    const translationKey = "matchMethod." + matchMethod;
    return this.$i18n.t(translationKey) as string;
  }
  getConversionNameFromConversionId(id: number): string {
    if (this.conversionDefinitions.length === 0) return "";

    return this.conversionDefinitions.filter(
      conversionDefinition => conversionDefinition.id == id
    )[0].name;
  }
  getCvParamColumnText(tdConversion: TdConversionDefinition): string {
    const column = tdConversion.cvParamColumn;

    if (column === this.tdWebImportConfig.locationUriColumn) {
      return this.$t("pageURLLabel").toString();
    }

    if (column === this.tdWebImportConfig.locationNameColumn) {
      return this.$t("pageTitleLabel").toString();
    }

    return tdConversion.cvParamColumn;
  }
  async created() {
    const requests = [
      this.$store.dispatch("clientSettings/fetchConversionDefinitions"),
      this.$store.dispatch("tdWebImportConfig/fetchTdWebImportConfigDetail"),
      this.$store.dispatch("tdConversion/fetchTdConversions")
    ];

    Promise.all(requests)
      .then(() => {
        this.isLoading = false;
      })
      .catch(handleError);
  }
  destroyed() {
    this.$store.commit("tdConversion/setSuccessMessage", "");
  }
}
</script>

<style lang="scss" scoped>
.tdConversionSetting {
  margin-right: 20px;
  min-width: 704px;
}
::v-deep .tdConversionSettings__link {
  color: $colorBase700;
  text-decoration: underline;
}
.tdConversionSetting__title {
  padding: 0 0 46px 0;
  font-size: 20px;
  font-weight: 700;
}
.tdConversionSetting__description {
  padding-bottom: 40px;
  white-space: pre-line;
  font-size: 12px;
  line-height: 24px;
}
.tdConversionSetting__errorMessage {
  margin-bottom: 10px;
}
.tdConversionSetting__errorMessage:last-of-type {
  margin-bottom: 30px;
}
.setting-success-message {
  color: $colorBlue900;
  margin-bottom: 30px;
  font-size: 14px;
}
.tdConversionSetting__buttonContainer {
  margin-bottom: 30px;
}
.tdConversionSetting__button {
  display: block;
  outline: none;
}
.tdConversionSetting__table {
  width: auto;
}
.tdConversionSetting__noContent {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  outline: 1px solid #cad1e0;
}
</style>
