<i18n
  src="@/i18n/views/client-settings/coordination/conversion/coordination-conversion-settings-create.json"
></i18n>
<template>
  <div class="coordinationConversionSettingsCreate">
    <h1 class="coordinationConversionSettingsCreate__title">
      {{ coordinationName }}
    </h1>
    <div
      v-t="'title'"
      class="coordinationConversionSettingsCreate__title coordinationConversionSettingsCreate__subTitle"
    />
    <div class="conversionSettingsCreate_tabContent">
      <div
        v-if="isConfirm"
        v-t="'message'"
        class="coordinationConversionSettingsCreate__message"
      />
      <CoordinationConversionConfirmTable
        v-if="isConfirm"
        :coordination-conversion="formData"
      />
      <CoordinationConversionEditTable
        v-else
        :coordination-conversion="formData"
        :conversion-limit-reached="conversionLimitReached"
        :is-contract-web="isContractWeb"
        @update="onUpdate"
      />
      <div class="coordinationConversionSettingsCreate__buttonContainer">
        <Button
          v-t="isConfirm ? 'register' : 'confirm'"
          class="coordinationConversionSettingsCreate__confirmButton"
          :disabled="isSubmitButtonDisabled"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickConfirm"
        />
        <Button
          v-t="'cancel'"
          class="coordinationConversionSettingsCreate__cancelButton"
          type="light"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickCancel"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import CoordinationConversionConfirmTable from "@/components/client-settings/coordination/conversion/CoordinationConversionConfirmTable.vue";
import CoordinationConversionEditTable from "@/components/client-settings/coordination/conversion/CoordinationConversionEditTable.vue";
import { ButtonTextSize } from "@/const/button";
import {
  ConversionDefinition,
  CoordinationConversionEditableData,
  isValidCoordinationConversionData
} from "@/models/client-settings/ConversionDefinition";
import { handleError } from "@/util/error-util";
import {
  Coordination,
  CreationMethod
} from "@/models/client-settings/Coordination";
import { MapConversionDefinitionListWithCv } from "@/models/client-settings/AppConversionDefinition";

@Component({
  components: {
    Button,
    CoordinationConversionConfirmTable,
    CoordinationConversionEditTable
  }
})
export default class CoordinationConversionSettingsCreate extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isConfirm: boolean = false;
  isSubmitting: boolean = false;

  formData: CoordinationConversionEditableData = {
    targetData: this.coordinationName,
    creationMethod: CreationMethod.NEW,
    conversionId: 0,
    conversionName: "",
    path: [""]
  };

  get coordinationName(): string {
    return (
      this.coordinations.find(
        coordination => coordination.id === this.coordinationId
      )?.name || ""
    );
  }

  get isContractWeb(): boolean {
    return this.$store.state.client.client.isContractWeb;
  }

  get coordinations(): Coordination[] {
    return this.$store.state.clientSettings.coordinations;
  }

  get coordinationId(): number {
    return Number(this.$route.params.coordinationId);
  }

  onUpdate(data: CoordinationConversionEditableData) {
    this.formData = data;
  }

  async onClickConfirm() {
    if (this.isConfirm) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      await this.addMapConversion().then(
        () =>
          (window.location.href = `/client-settings/coordination/${this.coordinationId}/conversion/index/?created_new=true`)
      );
    } else {
      this.scrollTop();
      this.isConfirm = true;
    }
  }

  async addMapConversion() {
    const mapConversionDef = new MapConversionDefinitionListWithCv(
      this.formData.creationMethod,
      this.coordinationId,
      this.formData.conversionName,
      this.formData.conversionId,
      this.formData.path
    );

    await this.$store
      .dispatch("appConversion/registerNewCvAndMapConversion", mapConversionDef)
      .catch(handleError);
  }

  onClickCancel() {
    if (this.isConfirm) {
      this.scrollTop();
      this.isConfirm = false;
    } else {
      window.location.href = `/client-settings/coordination/${this.coordinationId}/conversion/index/`;
    }
  }

  get isConversionsFetched(): boolean {
    return this.$store.state.conversion.isConversionsFetched;
  }

  get activeConversions(): ConversionDefinition[] {
    return this.$store.getters["conversion/activeConversions"];
  }

  get resourceLimitation(): number {
    return this.$store.state.client.client.resourceLimitation.conversion;
  }

  get conversionCreationLimit(): number {
    return this.resourceLimitation - this.activeConversions.length;
  }

  get isSubmitButtonDisabled(): boolean {
    if (this.isSubmitting) return true;

    return !isValidCoordinationConversionData(this.formData);
  }

  get conversionLimitReached(): boolean {
    return this.conversionCreationLimit <= 0;
  }

  async beforeMount() {
    if (!this.isConversionsFetched) {
      await this.$store
        .dispatch("conversion/fetchConversionList")
        .catch(handleError);
    }

    if (this.conversionLimitReached) {
      this.formData.creationMethod = CreationMethod.EXISTING;
    }
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
</script>
<style lang="scss" scoped>
.coordinationConversionSettingsCreate {
  margin-right: 20px;
  min-width: 704px;
}
.coordinationConversionSettingsCreate__title {
  font-size: 20px;
  font-weight: 700;
}

.coordinationConversionSettingsCreate__subTitle {
  padding: 20px 0 46px 0;
}
.coordinationConversionSettingsCreate__message {
  margin-bottom: 30px;
  font-size: 14px;
}
.coordinationConversionSettingsCreate__buttonContainer {
  margin-top: 16px;
}
.coordinationConversionSettingsCreate__confirmButton {
  margin-right: 20px;
}
</style>
