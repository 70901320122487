<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filterMiddleExclusionNode">
    <CheckBox
      :checked="isExcludeConditionEnabled"
      class="filterMiddleExclusionNode__excludeConditionCheckbox"
      :style="{ left: left }"
      :disabled="isExcludeCheckBoxDisabled"
      @input="handleCheckboxChange"
    >
      <p v-t="'addExcludeCondition'" />
    </CheckBox>

    <div
      v-if="isExcludeConditionEnabled && hideDropAreaIfHaveIsExclude(index)"
      class="filterMiddleExclusionNode__node"
    >
      <slot name="middleExclusionNode" />
    </div>

    <div
      v-if="isExcludeConditionEnabled && !hideDropAreaIfHaveIsExclude(index)"
      ref="excludeConditionDropArea"
      class="filterMiddleExclusionNode__dropArea"
    >
      <FilterConditionFormDropArea
        class="filterMiddleExclusionNode__DropAreaContainer"
        height="50px"
        @drop="handleDrop"
      >
        <span
          class="filterMiddleExclusionNode__DropAreaText"
          v-html="$t('dragHereExcludeCondition')"
        />
      </FilterConditionFormDropArea>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  FilterEdge,
  FilterEdgeType,
  SameVisitTransitionType
} from "@/models/search/filter-node/FilterEdge";
import CheckBox from "@/components/form/CheckBox.vue";
import FilterConditionFormDropArea from "@/components/filter/FilterConditionFormDropArea.vue";
import { FilterNode } from "@/models/search/filter-node/FilterNode";

@Component({
  name: "FilterMiddleExclusionNode",
  components: {
    CheckBox,
    FilterConditionFormDropArea
  }
})
export default class FilterMiddleExclusionNode extends Vue {
  @Prop({ type: FilterEdge, default: null })
  edge!: FilterEdge | null;

  @Prop({ type: Number, required: true })
  index!: number;

  @Prop({ type: String, default: "0" })
  left!: string;

  get draggingNodeType() {
    return this.$store.state.filter.draggingNodeType;
  }

  get filterConditionNodes(): FilterNode[] {
    return this.$store.state.filter.filterConditionForForm.filterNodes;
  }

  get indexOfEnableExcludeCondition(): number | null {
    return this.$store.state.filter.indexOfEnableExcludeCondition;
  }

  get isExcludeConditionEnabled() {
    return this.indexOfEnableExcludeCondition === this.index;
  }

  handleDrop() {
    this.$emit("add-exclusion-node", this.draggingNodeType);
  }

  // Hide if this index have isExcluded
  hideDropAreaIfHaveIsExclude(index: number): boolean {
    return !!this.filterConditionNodes[index + 1]?.isExcluded;
  }

  handleCheckboxChange(checkboxValue: boolean) {
    if (checkboxValue) {
      this.$store.commit("filter/setIndexOfEnableExcludeCondition", this.index);
    } else {
      this.$store.commit("filter/setIndexOfEnableExcludeCondition", null);
      if (this.filterConditionNodes[this.index + 1].isExcluded) {
        this.$emit("remove-exclusion-node");
      }
    }
  }

  get isExcludeCheckBoxDisabled() {
    if (this.indexOfEnableExcludeCondition !== null) {
      return this.index !== this.indexOfEnableExcludeCondition;
    } else {
      return (
        this.excludeConditionDisableBaseOnEdgeType ||
        this.isFirstOrLastNodeExcluded ||
        this.filterConditionNodes.length === 5
      );
    }
  }

  get isFirstOrLastNodeExcluded() {
    return (
      this.filterConditionNodes[0].isExcluded ||
      this.filterConditionNodes[this.filterConditionNodes.length - 1].isExcluded
    );
  }

  get excludeConditionDisableBaseOnEdgeType() {
    return (
      this.edge?.edgeType == FilterEdgeType.moreThanXDays ||
      this.edge?.edgeType == FilterEdgeType.withinXDays ||
      (this.edge?.edgeType == FilterEdgeType.sameVisit &&
        this.edge?.transitionType ==
          SameVisitTransitionType.withInTransitionStep) ||
      this.edge?.transitionType == SameVisitTransitionType.immediatelyAfter
    );
  }
}
</script>

<style lang="scss" scoped>
.filterMiddleExclusionNode {
  margin-top: -35px;
}

.filterMiddleExclusionNode__excludeConditionCheckbox {
  align-items: center;
  height: 35px;
  position: relative;

  &::before {
    background-color: $colorBase500;
    content: "";
    display: block;
    height: 100%;
    margin: 0 20px;
    width: 1px;
  }
}

.filterMiddleExclusionNode__node,
.filterMiddleExclusionNode__dropArea {
  margin-top: 20px;
}
</style>
