import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { MapEventDefinition } from "@/models/client-settings/MapEventDefinition";

export interface MapEventWithEventRequest {
  event_name: string;
  parameters: string[];
}
export interface MapEventResponse {
  id: number;
  coordination_id: number;
  event_id: number;
  parameter: string;
  is_disabled: boolean;
}
export interface MapEventRequest {
  parameters: string[];
}

export default class ApiMapEvent {
  constructor(private readonly httpClient: HttpClient) {}

  public registerMapEventWithEvent = async (
    params: MapEventWithEventRequest,
    coordinationId: number
  ): Promise<MapEventDefinition[]> => {
    const url = ApiUrl.MAP_EVENT_AND_EVENT_REGISTER(coordinationId);

    const response = await this.httpClient.post<MapEventResponse[]>(
      url,
      params
    );

    return response.map(this.toModel);
  };

  public registerMapEvent = async (
    params: MapEventRequest,
    coordinationId: number,
    eventId: number
  ): Promise<MapEventDefinition[]> => {
    const url = ApiUrl.MAP_EVENT_REGISTER(coordinationId, eventId);

    const response = await this.httpClient.post<MapEventResponse[]>(
      url,
      params
    );

    return response.map(this.toModel);
  };

  private toModel(json: MapEventResponse) {
    return new MapEventDefinition(
      json.id,
      json.coordination_id,
      json.event_id,
      json.parameter,
      json.is_disabled
    );
  }
}
