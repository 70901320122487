import { render, staticRenderFns } from "./TdConversionCreateAndUpdate.vue?vue&type=template&id=447b740e&scoped=true"
import script from "./TdConversionCreateAndUpdate.vue?vue&type=script&lang=ts"
export * from "./TdConversionCreateAndUpdate.vue?vue&type=script&lang=ts"
import style0 from "./TdConversionCreateAndUpdate.vue?vue&type=style&index=0&id=447b740e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447b740e",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/client-settings/td-conversion-create-and-update.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2Fclient-settings%2FTdConversionCreateAndUpdate.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports