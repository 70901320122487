<template>
  <div class="csvDownloadProgress">
    <div class="csvDownloadProgress__fileName">
      {{ fileName }}
    </div>

    <div class="csvDownloadProgress__progressBar">
      <div class="csvDownloadProgress__progressBar--line"></div>
      <div class="csvDownloadProgress__progressBar--subline inc"></div>
      <div class="csvDownloadProgress__progressBar--subline dec"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class CsvDownloadProgress extends Vue {
  @Prop({ type: String, default: "" })
  fileName!: string;
}
</script>

<style lang="scss" scoped>
.csvDownloadProgress__progressBar {
  position: relative;
  display: inline-block;
  background-color: #f5f5f7;
  overflow-x: hidden;
  width: 80px;
  height: 8px;
  border-radius: 0;
}
.csvDownloadProgress {
  display: flex;
  align-items: center;
  background: $colorDark;
  color: $colorWhite;
  padding: 12px 20px;
  border-radius: 5px;
}

.csvDownloadProgress__fileName {
  display: block;
  margin-right: 20px;
}

.csvDownloadProgress__progressBar--line,
.csvDownloadProgress__progressBar--subline {
  position: absolute;
  background: #7ebae6;
  height: 8px;
}

.csvDownloadProgress__progressBar .inc {
  animation: increase 2s infinite;
}
.csvDownloadProgress__progressBar .dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>
