<template>
  <li
    ref="suggestionItem"
    class="suggestionItem"
    :class="{ 'suggestionItem--selected': isSelected }"
    @click="onClick"
  >
    <span
      v-for="(h, index) in highlight(wholeText)"
      :key="index"
      :class="{ 'suggestionItem--highlight': h.isHighlight }"
      >{{ h.text }}</span
    >
  </li>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";

interface HighlightWord {
  text: string;
  isHighlight: boolean;
}
@Component
export default class SuggestionItem extends Vue {
  @Prop({ type: String, required: true })
  wholeText!: string;

  @Prop({ type: String, required: true })
  highlightText!: string;

  @Prop({ type: Boolean, default: false })
  isSelected!: boolean;

  @Emit("click")
  onClick() {}

  @Watch("isSelected")
  scrollIntoView(value: boolean) {
    if (value) {
      const suggestionItem = this.$refs.suggestionItem as HTMLElement;
      suggestionItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }

  highlight(plainText: string): HighlightWord[] {
    const escapedText = this.highlightText.replace(
      /[.+*?^${}()|[\]\\]/g,
      "\\$&"
    );
    const regExp = new RegExp("(" + escapedText + ")", "ig");
    return plainText
      .split(regExp)
      .filter(t => t !== "")
      .map(t => {
        return {
          text: t,
          isHighlight: t.toLowerCase() === this.highlightText.toLowerCase()
        };
      });
  }
}
</script>

<style lang="scss" scoped>
.suggestionItem {
  list-style: none;
  padding: 9px 11px;
  cursor: pointer;
  color: $colorBase900;
  font-size: 12px;
  line-height: 1.5;
  background-color: $colorWhite;
  word-break: break-all;

  &:hover {
    background-color: $colorBlue600;
  }
  &--selected {
    background-color: $colorBlue600;
  }
  &--highlight {
    background-color: rgba($colorOrange300, 0.6);
  }
}
</style>
