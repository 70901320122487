<i18n
  src="@/i18n/components/segmented-trends/segmented-trends-inflow-table.json"
></i18n>
<template>
  <AnalysisTable
    class="segmented-trends-inflow-table"
    data-cy="segmented-trends-inflow-table"
  >
    <template #header>
      <AnalysisTableHeaderRow>
        <AnalysisTableHeaderCell width="50%">{{
          $t("url")
        }}</AnalysisTableHeaderCell>
        <AnalysisTableHeaderCell>
          {{ $t("pvVisit") }}
          <Tooltip :placement="tooltipPlacement.Bottom" :wrap="true">
            <Icon
              class="help-icon"
              :icon="Icons.HelpInvertWhite"
              :color="Colors.Base700"
              :size="10"
            />
            <template #content>
              <div v-t="'inflowPvVisitHelp'" class="help-text" />
            </template>
          </Tooltip>
        </AnalysisTableHeaderCell>
        <AnalysisTableHeaderCell>
          {{ $t("uniqueUsers") }}
        </AnalysisTableHeaderCell>
      </AnalysisTableHeaderRow>
    </template>
    <AnalysisTableRow v-for="(row, index) in data" :key="index">
      <AnalysisTableCell class="url-cell" width="50%">
        <div class="url-cell__wrapper">
          <a
            class="url-cell__external-link"
            :href="row.key.key"
            target="_blank"
            rel="noopener"
          >
            <IconButton
              :icon="Icons.ExternalLink"
              :size="24"
              :icon-scale="0.5"
            />
          </a>
          <Tooltip
            class="url-cell__link-text"
            :text="row.title.forDisplay()"
            :placement="tooltipPlacement.TopStart"
          >
            <div
              v-if="isInflow"
              class="url-cell__to-detail"
              data-cy="url-cell__to-detail"
              @click="onClickDomain(row.key.forDisplay())"
              >{{ row.key.forDisplay() }}</div
            >
            <div v-else>{{ row.key.forDisplay() }}</div>
          </Tooltip>
        </div>
      </AnalysisTableCell>
      <AnalysisTableCell class="number-cell">{{
        row.pvVisit
      }}</AnalysisTableCell>
      <AnalysisTableCell class="number-cell">{{
        row.uniqueUsers
      }}</AnalysisTableCell>
    </AnalysisTableRow>
  </AnalysisTable>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import AnalysisTable from "@/components/table/AnalysisTable.vue";
import AnalysisTableRow from "@/components/table/AnalysisTableRow.vue";
import AnalysisTableCell from "@/components/table/AnalysisTableCell.vue";
import AnalysisTableHeaderRow from "@/components/table/AnalysisTableHeaderRow.vue";
import AnalysisTableHeaderCell from "@/components/table/AnalysisTableHeaderCell.vue";
import { SegmentedTrendResult } from "@/models/segmented-trends/SegmentedTrendResult";

@Component({
  components: {
    Icon,
    IconButton,
    Tooltip,
    AnalysisTable,
    AnalysisTableRow,
    AnalysisTableCell,
    AnalysisTableHeaderRow,
    AnalysisTableHeaderCell
  }
})
export default class SegmentedTrendsInflowTable extends Vue {
  Icons = Icons;
  Colors = Colors;
  tooltipPlacement = TooltipPlacement;

  @Prop({ type: Array, required: true })
  data!: SegmentedTrendResult[];
  @Prop({ type: Boolean, required: true })
  isInflow!: boolean;

  onClickDomain(domain: string) {
    this.$emit("urlClicked", domain);
  }
}
</script>

<style scoped lang="scss">
.url-cell {
  padding-right: 0;
  padding-left: 0;
  text-align: left;

  @include all-break-word();
}
.url-cell__wrapper {
  display: table;
  min-width: 100px;
  width: 100%;
}
.url-cell__external-link {
  display: table-cell;
  padding-right: 5px;
  padding-left: 10px;
  width: 40px;
  vertical-align: middle;
  &:hover {
    text-decoration: none;
  }
}
.url-cell__link-text {
  display: table-cell;
  vertical-align: middle;
}
.number-cell {
  text-align: right;
}
.url-cell__to-detail {
  color: $colorBase600;
  cursor: pointer;
}
.url-cell__to-detail:hover {
  color: $colorBase900;
  text-decoration: underline;
}
.help-text {
  width: 200px;
  white-space: normal;
}
</style>
