<template>
  <div class="ug-table-row">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UgTableRow extends Vue {}
</script>

<style lang="scss" scoped>
.ug-table-row {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  &:nth-child(even) {
    background-color: $colorGray100;
  }

  &:nth-child(odd) {
    background-color: $colorWhite;
  }
}
</style>
