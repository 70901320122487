import {
  AdditionalSelectConditionInterface,
  stringListValue
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  AttributeConditionParamType,
  ContactAttributeConditionParams,
  AdditionalConditionParams
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * Conditions by contact attribute
 * In addition to normal search conditions, selection conditions for Activity history can be added
 */
export enum ContactFieldType {
  Content = 1,
  Title = 2,
  Purpose = 3,
  StoreName = 4
}

export class ContactAttributeCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    // Contact definition ID
    public readonly id: number,
    // Contact field type
    public readonly fieldType: ContactFieldType,
    // Search character string
    public readonly values: string[],
    // Exact match or partial match
    public readonly matchPattern: MatchMethod
  ) {}

  /**
   * Display title
   * @param definitions
   */
  displayTitle(definitions: ContactDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? ":" + def.translatedName : "";

    let contactShopFieldName = "";

    if (this.fieldType === ContactFieldType.Purpose) {
      contactShopFieldName =
        ((" (" + i18n.t("models.search.storeVisitPurpose")) as string) + ")";
    } else if (this.fieldType === ContactFieldType.StoreName) {
      contactShopFieldName =
        ((" (" + i18n.t("models.search.nameOfStore")) as string) + ")";
    }

    return (
      (i18n.t("models.search.activityHistory") as string) +
      defName +
      contactShopFieldName
    );
  }

  /**
   * Returns the set value for display.
   */
  displayValue(): string {
    return stringListValue(this.values, this.matchPattern);
  }

  /**
   * Returns whether the specified ID is the same as contactAttributeDefinitionId
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * Search condition -> JSON
 */
export function convertContactAttributeConditionToJson(
  condition: ContactAttributeCondition
): ContactAttributeConditionParams {
  return {
    type: AttributeConditionParamType.ContactAttribute,
    id: condition.id,
    field_id: condition.fieldType,
    values: condition.values,
    word_match_method: condition.matchPattern
  };
}

/**
 * JSON-> search condition
 */
export function convertJsonToContactAttributeCondition(
  json: ContactAttributeConditionParams,
  activeContactAttributeDefinitions: ContactDefinition[]
): ContactAttributeCondition | null {
  // null if not in the available id list
  if (!activeContactAttributeDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  return new ContactAttributeCondition(
    json.id,
    json.field_id,
    json.values,
    json.word_match_method
  );
}

/**
 * Determine if the additional condition JSON is a Contact Attribute Condition
 */
export function isContactAttributeConditionParams(
  params: AdditionalConditionParams
): params is ContactAttributeConditionParams {
  return params.type === AttributeConditionParamType.ContactAttribute;
}
