var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    once: false
  }),expression:"{\n    callback: visibilityChanged,\n    once: false\n  }"}],ref:"chart",staticClass:"chartBase",style:(_vm.style)},[_c('ScrollWrapper',{staticClass:"chartBase__scrollWrapper",attrs:{"overflow":_vm.overflow,"arrow-show":true,"destination-x":_vm.scrollDestinationX},on:{"update:scroll-x":_vm.updateScrollX,"update:scroll-width":_vm.updateScrollWidth,"update:is-scrollable":_vm.updateIsScrollable}},[_c('div',{staticClass:"chartBase__overview",style:(_vm.style)},[_vm._t("default",null,{"props":{
          scrollX: _vm.scrollX,
          scrollWidth: _vm.scrollWidth,
          isVisible: _vm.isVisible,
          isScrollable: _vm.isScrollable
        }}),_c('div',{attrs:{"id":"canvas"}},[_c('svg',{attrs:{"id":"paper","width":_vm.paperWidth,"height":_vm.paperHeight,"xmlns":"http://www.w3.org/2000/svg","version":"1.1","xmlns:xlink":"http://www.w3.org/1999/xlink","xmlns:svgjs":"http://svgjs.com/svgjs"}},[(_vm.isUserDetail)?_c('ChartHighlight',{attrs:{"width":_vm.barWidth,"height":_vm.paperHeight,"x":_vm.highlightX}}):_vm._e(),(_vm.coloredPeriods.length > 0)?_c('g',{attrs:{"id":"coloredPeriods"}},_vm._l((_vm.coloredPeriods),function(coloredPeriod,i){return _c('ChartColoredPeriod',{key:'coloredPeriod' + i,attrs:{"width":_vm.getChartColoredPeriodWidth(coloredPeriod),"height":_vm.baselineY,"x":_vm.getChartUnitX(coloredPeriod.startDate),"fill":coloredPeriod.color,"stroke":coloredPeriod.strokeColor,"data-cy":[
                coloredPeriod.color === _vm.colors.Blue730
                  ? 'colored-period-base'
                  : 'colored-period-comp'
              ]},on:{"mouseenter":function($event){_vm.onMouseEnterColoredPeriod(
                  _vm.getChartColoredPeriodMousePositionX($event),
                  coloredPeriod
                )},"mouseleave":_vm.onMouseLeaveColoredPeriod}})}),1):_vm._e(),_c('ChartGridLine',{attrs:{"y":_vm.baselineY,"baseline-length":_vm.baselineLength,"first-scale-date":_vm.firstScaleDate,"last-scale-date":_vm.lastScaleDate,"is-one-month":_vm.isOneMonth,"is-six-month":_vm.isSixMonth}}),(_vm.isVisible)?_c('ChartVisitBars',{attrs:{"visit-overviews":_vm.visitOverviews,"filter-matched-visit-ids":_vm.filterMatchedVisitIds,"almost-matched-visit-ids":_vm.almostMatchedVisitIds,"funnel-matched-gram-ids":_vm.funnelMatchedGramIds,"funnel-matched-selected-gram-ids":_vm.funnelMatchedSelectedGramIds,"baseline-y":_vm.baselineY,"baseline-length":_vm.baselineLength,"first-scale-date":_vm.firstScaleDate,"last-scale-date":_vm.lastScaleDate,"last-scale-x":_vm.lastScaleX,"width":_vm.barWidth,"show-stroke":!_vm.isTwoYear,"selected-conversion-ids":_vm.selectedConversionIds,"is-toggle-time-of-day-enabled":_vm.isToggleTimeOfDayEnabled,"scroll-x":_vm.scrollX,"scroll-width":_vm.scrollWidth,"is-chart-scrollable":_vm.isScrollable,"is-in-user-detail":_vm.isUserDetail},on:{"click":_vm.onClick,"mouseenter":_vm.onMouseEnterOverview,"mouseleave":_vm.onMouseLeaveOverview}}):_vm._e(),(_vm.isOmo)?_c('ChartOmoIcons',{attrs:{"nps-overviews":_vm.npsOverviews,"enquete-overviews":_vm.enqueteOverviews,"baseline-length":_vm.baselineLength,"first-scale-date":_vm.firstScaleDate,"last-scale-date":_vm.lastScaleDate,"last-scale-x":_vm.lastScaleX,"is-year":_vm.isYear},on:{"click":_vm.onClick,"mouseenter":_vm.onMouseEnterOverview,"mouseleave":_vm.onMouseLeaveOverview}}):_vm._e(),_c('path',{staticClass:"chartBase__baseline",attrs:{"d":_vm.baselinePath}}),(_vm.showPeriodLinePath)?_c('path',{staticClass:"chartBase__periodLine",attrs:{"d":_vm.periodLinePath}}):_vm._e()],1)])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }