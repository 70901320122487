<i18n src="@/i18n/components/chart.json"></i18n>
<template>
  <div>
    <!-- :value is needed to avoid elementui-lts bug -->
    <ChartBalloonBase
      v-if="visitOverview"
      :value="showBalloon"
      :placement="placement"
      :style="{
        position: 'absolute',
        left: balloonX + 'px',
        top: visitBarBalloonY + 'px'
      }"
    >
      <div class="chartBalloonBase__empty" />

      <template #content>
        <div class="chartBalloonBase__content">
          {{ dateString }}
          <ul class="list">
            <li v-if="visitOverview.device">
              {{ device }}: {{ visitOverview.device }}
            </li>
            <li>{{ pageView }}</li>
            <li v-if="visitOverview.conversions.length > 0">{{
              conversionNames
            }}</li>
          </ul>
        </div>
      </template>
    </ChartBalloonBase>

    <ChartBalloonBase
      v-if="businessEventOverview"
      :value="showBalloon"
      :placement="placement"
      :style="{
        position: 'absolute',
        left: balloonX + 'px',
        top: businessEventBalloonY + 'px'
      }"
    >
      <div class="chartBalloonBase__empty" />

      <template #content>
        <div class="chartBalloonBase__content">
          {{ dateString }}
          <ul class="list">
            <li>{{ businessEventOverview.definition.name }}</li>
            <li v-if="businessEventOverview.content.length > 0">{{
              businessEventOverview.content
            }}</li>
          </ul>
        </div>
      </template>
    </ChartBalloonBase>

    <ChartBalloonBase
      v-if="contactOverview"
      :value="showBalloon"
      :placement="placement"
      :style="{
        position: 'absolute',
        left: balloonX + 'px',
        top: contactBalloonY + 'px'
      }"
    >
      <div class="chartBalloonBase__empty" />

      <template #content>
        <div class="chartBalloonBase__content">
          {{ dateString }}
          <ul class="list">
            <li v-if="contactOverview.talkTime > 0"
              >{{ call }} {{ min }}{{ sec }}</li
            >
            <li v-if="contactOverview.content">{{
              contactOverview.content
            }}</li>
          </ul>
        </div>
      </template>
    </ChartBalloonBase>

    <ChartBalloonBase
      v-if="npsOverview"
      :value="showBalloon"
      :placement="placement"
      :style="{
        position: 'absolute',
        left: balloonX + 'px',
        top: scoreBoxBalloonY + 'px'
      }"
    >
      <div class="chartBalloonBase__empty" />

      <template #content>
        <div class="chartBalloonBase__content">
          {{ dateString }}
          <ul class="list">
            <li>{{ npsOverview.definition.name }} : {{ npsOverview.score }}</li>
            <li v-if="npsOverview.comment.length > 0">{{
              npsOverview.comment
            }}</li>
          </ul>
        </div>
      </template>
    </ChartBalloonBase>

    <ChartBalloonBase
      v-if="enqueteOverview"
      :value="showBalloon"
      :placement="placement"
      :style="{
        position: 'absolute',
        left: balloonX + 'px',
        top: scoreBoxBalloonY + 'px'
      }"
    >
      <div class="chartBalloonBase__empty" />

      <template #content>
        <div class="chartBalloonBase__content">
          {{ dateString }}
          <ul class="list">
            <li
              >{{ enqueteOverview.definition.name }} :
              {{ enqueteOverview.score }}</li
            >
            <li v-if="enqueteOverview.comment.length > 0">{{
              enqueteOverview.comment
            }}</li>
          </ul>
        </div>
      </template>
    </ChartBalloonBase>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { DateFormat, formatDate } from "@/util/date-util";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import ChartBalloonBase from "@/components/chart/ChartBalloonBase.vue";
import {
  CHART_BALLOON_OFFSET,
  CHART_BALLOON_ADJUST_X,
  CONTACT_ICON_SIZE,
  CONTACT_ICON_Y,
  BUSINESS_EVENT_HEIGHT,
  BUSINESS_EVENT_Y,
  SCOREBOX_HEIGHT,
  SCOREBOX_Y
} from "@/components/chart/chart-util";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";

@Component({
  components: {
    ChartBalloonBase
  }
})
export default class ChartBalloon extends Vue {
  @Prop({ type: Object, required: true })
  balloonContentOverview!:
    | VisitOverview
    | NpsOverview
    | BusinessEventOverview
    | EnqueteOverview
    | ContactOverview;

  @Prop({ type: Boolean, required: true })
  showBalloon!: boolean;

  @Prop({ type: Boolean, required: true })
  isUserDetail!: boolean;

  @Prop({ type: Number, required: true })
  x!: number;

  @Prop({ type: Number })
  visitBarY!: number;

  @Prop({ type: Number, required: true })
  baselineY!: number;

  get visitOverview(): VisitOverview | undefined {
    if (this.balloonContentOverview instanceof VisitOverview) {
      return this.balloonContentOverview;
    }
  }

  get contactOverview(): ContactOverview | undefined {
    if (this.balloonContentOverview instanceof ContactOverview) {
      return this.balloonContentOverview;
    }
  }

  get npsOverview(): NpsOverview | undefined {
    if (this.balloonContentOverview instanceof NpsOverview) {
      return this.balloonContentOverview;
    }
  }

  get enqueteOverview(): EnqueteOverview | undefined {
    if (this.balloonContentOverview instanceof EnqueteOverview) {
      return this.balloonContentOverview;
    }
  }

  get businessEventOverview(): BusinessEventOverview | undefined {
    if (this.balloonContentOverview instanceof BusinessEventOverview) {
      return this.balloonContentOverview;
    }
  }

  get balloonX() {
    return this.x - CHART_BALLOON_ADJUST_X;
  }

  get placement(): string {
    return this.isUserDetail ? "bottom" : "top";
  }

  get visitBarBalloonY(): number {
    return this.isUserDetail
      ? this.baselineY
      : this.visitBarY - CHART_BALLOON_OFFSET;
  }

  get businessEventBalloonY(): number {
    return this.isUserDetail
      ? BUSINESS_EVENT_HEIGHT + BUSINESS_EVENT_Y
      : BUSINESS_EVENT_Y - CHART_BALLOON_OFFSET;
  }

  get contactBalloonY(): number {
    return this.isUserDetail
      ? CONTACT_ICON_SIZE + CONTACT_ICON_Y + CHART_BALLOON_OFFSET
      : CONTACT_ICON_Y - CHART_BALLOON_OFFSET;
  }

  get scoreBoxBalloonY(): number {
    return this.isUserDetail ? SCOREBOX_HEIGHT + SCOREBOX_Y : SCOREBOX_Y;
  }

  get dateString(): string {
    const containTime: string = formatDate(
      DateFormat.M月d日_E_HH時mm分,
      this.balloonContentOverview.date
    );
    if (this.balloonContentOverview instanceof VisitOverview) {
      return containTime;
    } else if (this.balloonContentOverview instanceof ContactOverview) {
      if (this.balloonContentOverview.type == ContactDefinitionType.TEL) {
        return containTime + "〜";
      }
      return containTime;
    } else {
      return formatDate(
        DateFormat.yyyy年M月d日E,
        this.balloonContentOverview.date
      );
    }
  }

  get talkSec(): number {
    if (this.balloonContentOverview instanceof ContactOverview) {
      return this.balloonContentOverview.talkTime % 60;
    }
    return 0;
  }

  get talkMin(): number {
    if (this.balloonContentOverview instanceof ContactOverview) {
      return Math.floor(this.balloonContentOverview.talkTime / 60);
    }
    return 0;
  }

  get conversionNames(): string {
    if (this.balloonContentOverview instanceof VisitOverview) {
      const conversionNames: string[] = this.balloonContentOverview.conversions.map(
        cv => cv.name
      );
      return conversionNames.join(",");
    }
    return "";
  }

  get device(): string {
    return this.$i18n.t("device") as string;
  }

  get pageView(): string {
    if (this.balloonContentOverview instanceof VisitOverview) {
      return this.$i18n.t("pageView", {
        pv: this.balloonContentOverview.pvStats.allPv
      }) as string;
    }
    return "";
  }

  get call(): string {
    return this.$i18n.t("call") as string;
  }

  get min(): string {
    if (this.balloonContentOverview instanceof ContactOverview) {
      return this.$i18n.t("min", {
        time: this.talkMin
      }) as string;
    }
    return "";
  }

  get sec(): string {
    if (this.balloonContentOverview instanceof ContactOverview) {
      return this.$i18n.t("sec", {
        time: this.talkSec
      }) as string;
    }
    return "";
  }
}
</script>

<style scoped lang="scss">
.list {
  margin-left: 5px;
  list-style-type: disc;
}

.list > li {
  overflow: hidden;
  max-width: 150px;
  list-style-position: inside;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
