import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";

import {
  SelectCondition,
  convertSelectConditionToJson
} from "@/models/search/select-condition/SelectCondition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { SelectUserApiRequestParams } from "@/api/apis/ApiSearch";
import { ApiRes } from "@/api/api-res";
import { TOUR_DETAIL_MAX_USER_COUNT } from "@/store/modules/tour";

export default class ApiTour {
  constructor(private readonly httpClient: HttpClient) {}

  async selectUser(
    selectCondition: SelectCondition,
    npsDefinition: NpsDefinition | null
  ): Promise<number | null> {
    const params: SelectUserApiRequestParams | null = convertSelectConditionToJson(
      selectCondition,
      npsDefinition
    );
    if (params === null) {
      return null;
    }

    const res = await this.httpClient.post<{ history_id: number }>(
      ApiUrl.TOUR_SELECT_USER,
      params
    );

    return res.history_id;
  }

  async *getFilterUserResult(
    historyId: number,
    chunkSize: number = TOUR_DETAIL_MAX_USER_COUNT
  ): AsyncIterableIterator<ApiRes.FilterSearchResult> {
    const getResponse = (offset: number) =>
      this.httpClient.post<ApiRes.FilterSearchResult>(ApiUrl.TOUR_USER, {
        offset_limit: {
          user_number_offset: offset,
          user_number_limit: chunkSize
        },
        history_id: historyId
      });

    let offset = 0;
    let res = await getResponse(offset);
    offset += res.users.length;
    yield res;

    while (
      !res.search_finished ||
      res.num_filtered_users < TOUR_DETAIL_MAX_USER_COUNT
    ) {
      res = await getResponse(offset);
      offset += res.users.length;
      yield res;
    }
  }
}
