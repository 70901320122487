<i18n src="@/i18n/components/tours/tour-detail-complete-modal.json"></i18n>
<template>
  <Dialog
    class="tourDetailCompleteModal"
    visible
    top="220px"
    width="820px"
    :close-button-type="tourCloseButtonType"
    @close="close"
  >
    <template #title>
      <header v-t="'title'" class="tourDetailCompleteModal__title" />
    </template>
    <div class="tourDetailCompleteModal__body">
      <UserIcon class="tourDetailCompleteModal__icon" />

      <div class="tourDetailCompleteModal__descriptionContainer">
        <h3
          v-t="'description_1'"
          class="tourDetailCompleteModal__description tourDetailCompleteModal__description--bold"
        />
        <h4
          v-t="'description_2'"
          class="tourDetailCompleteModal__description"
        />
      </div>

      <div class="tourDetailCompleteModal__buttonContainer">
        <RouterLink data-unit-test="dark-button" to="/tours">
          <Button
            v-t="'dark_button'"
            class="tourDetailCompleteModal__button tourDetailCompleteModal__button--first"
            :height="buttonHeight"
            width="100%"
          />
        </RouterLink>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import UserIcon from "@/assets/svg/user/face_bluegreen.svg";
import Button from "@/components/Button.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import { IconButtonType } from "@/const/IconButtons";

@Component({
  components: { UserIcon, Button, Dialog }
})
export default class TourDetailCompleteModal extends Vue {
  buttonHeight = "35px";
  tourCloseButtonType = IconButtonType.Tour;

  @Emit("close")
  close() {}
}
</script>

<style lang="scss" scoped>
.tourDetailCompleteModal {
  ::v-deep .el-dialog {
    padding: 4px 4px 40px;
  }

  ::v-deep .dialog__closeButton {
    top: 15px;
    right: 15px;
  }
}

.tourDetailCompleteModal__title {
  position: relative;
  padding: 28px;
  width: 100%;
  height: 74px;
  border-radius: 2px 2px 0px 0px;
  background: $colorTourBase;
  color: $colorWhite;
  font-weight: 700;
  font-size: 18px;

  // Arrow down
  &::before {
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 15px 10px;
    border-style: solid;
    border-color: transparent;
    border-top-color: $colorTourBase;
    content: "";
    transform: translateX(-50%);
  }
}

.tourDetailCompleteModal__body {
  text-align: center;
}

.tourDetailCompleteModal__icon {
  margin-top: 35px;
  width: 45px;
  height: 37px;
}

.tourDetailCompleteModal__descriptionContainer {
  margin-top: 8px;
}

.tourDetailCompleteModal__description {
  font-size: 16px;
  line-height: 1.5;

  &--bold {
    font-weight: 700;
  }
}

.tourDetailCompleteModal__buttonContainer {
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;
  width: 410px;
}

.tourDetailCompleteModal__button {
  font-size: 13px;
}
</style>
