import { VisitSummaryJsonData, VisitSummaryType } from "@/api/apis/ApiVisits";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { VisitSummaryRowData } from "@/models/visit-summary/VisitSummaryRowData";

export class VisitSummary {
  constructor(private summaryDataList: VisitSummaryRowData[]) {}

  public get hasData(): boolean {
    return this.summaryDataList.length > 0;
  }

  public get getCvList(): VisitSummaryRowData[] {
    return this.filterByType(VisitSummaryType.CV);
  }

  public get getEventList(): VisitSummaryRowData[] {
    return this.filterByType(VisitSummaryType.EVENT);
  }

  public get getInflowList(): VisitSummaryRowData[] {
    return this.filterByType(VisitSummaryType.INFLOW);
  }

  public get getWithdrawalList(): VisitSummaryRowData[] {
    return this.filterByType(VisitSummaryType.WITHDRAWAL);
  }

  public get getPvList(): VisitSummaryRowData[] {
    return this.filterByType(VisitSummaryType.PV);
  }

  private filterByType(type: VisitSummaryType): VisitSummaryRowData[] {
    return this.summaryDataList.filter(data => data.type === type);
  }

  public static build(
    json: VisitSummaryJsonData[],
    conversionDefinitions: ConversionDefinition[],
    globalConversionDefinitions: ConversionDefinition[]
  ): VisitSummary {
    const summaryDataList: VisitSummaryRowData[] = json.map(
      (data: VisitSummaryJsonData) => {
        return VisitSummaryRowData.build(
          data,
          conversionDefinitions,
          globalConversionDefinitions
        );
      }
    );
    return new VisitSummary(summaryDataList);
  }
}
