<template>
  <div>
    <h1 class="pageTitle">
      {{ title }}
    </h1>
    <p class="forgetPassword__description" v-html="formattedDescriptions"></p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ForgetPasswordHeader extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: Array, required: true })
  descriptions!: string[];

  get formattedDescriptions() {
    return this.descriptions.join("<br />");
  }
}
</script>

<style lang="scss" scoped>
.pageTitle {
  font-size: 32px;
  margin-bottom: 40px;
  color: $colorBase900;
  font-weight: bold;
  text-align: center;
}

.forgetPassword__description {
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  color: $colorBase900;
}
</style>
