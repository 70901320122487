<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div class="clusteringMenu">
    <div
      class="clusteringMenu__inner"
      :class="{ 'clusteringMenu__inner--active': isClusteringMode }"
    >
      <div
        v-click-outside="closeClusteringResults"
        :class="selectClasses"
        data-cy="clustering-menu-select"
        @click="openClusteringResults"
      >
        <span v-if="selectedPattern" class="clusteringMenu__selectWrapper">
          <span class="clusteringMenu__selectTitle">{{
            selectedPattern.title
          }}</span>
          <span
            v-if="showCharacteristic(selectedPattern)"
            class="clusteringMenu__selectCharacteristicName"
            >{{ selectedPattern.label.characteristics[0].name }}</span
          >
          <span
            v-if="showCharacteristic(selectedPattern)"
            class="clusteringMenu__selectCharacteristicValue"
            >: {{ selectedPattern.label.characteristics[0].value }}</span
          >
          <span
            v-if="showCharacteristic(selectedPattern)"
            class="clusteringMenu__selectCharacteristicName"
            >{{ selectedPattern.label.characteristics[1].name }}</span
          >
          <span
            v-if="showCharacteristic(selectedPattern)"
            class="clusteringMenu__selectCharacteristicValue"
            >: {{ selectedPattern.label.characteristics[1].value }}</span
          >
        </span>
        <span v-else v-t="'selectPattern'" />
        <Icon
          class="clusteringMenu__selectIcon"
          :icon="Icons.ArrowBottom"
          :size="8"
        />
      </div>
      <div v-if="isClusteringMode" class="clusteringMenu__selectedMenuCount">
        {{ $t("userCount", { count: matchCount }) }} ({{ matchRatio }}%)
      </div>
      <div v-show="isClusteringMode" class="clusteringMenu__clear">
        <IconButton
          :icon="Icons.ButtonClose"
          :size="32"
          :icon-scale="0.5"
          @click="clearClustering"
        />
      </div>
      <div v-if="showClusteringResults" class="clusteringMenu__resultContainer">
        <template v-if="clusteringList.length > 0">
          <table
            class="clusteringMenu__result"
            data-cy="clustering-menu-option"
          >
            <tr
              v-for="cl in clusteringList"
              :key="cl.label.title"
              class="clusteringMenu__item"
              :class="{
                'clusteringMenu__item--selected': isSelectedPattern(cl)
              }"
              @click="selectClusteringPattern(cl)"
            >
              <th
                :colspan="showCharacteristic(cl) ? 0 : 5"
                class="clusteringMenu__title"
                >{{ cl.title }}</th
              >
              <td
                v-if="showCharacteristic(cl)"
                class="clusteringMenu__characteristicName"
                >{{ cl.label.characteristics[0].name }}</td
              >
              <td
                v-if="showCharacteristic(cl)"
                class="clusteringMenu__characteristicValue"
                >: {{ cl.label.characteristics[0].value }}</td
              >
              <td
                v-if="showCharacteristic(cl)"
                class="clusteringMenu__characteristicName"
                >{{ cl.label.characteristics[1].name }}</td
              >
              <td
                v-if="showCharacteristic(cl)"
                class="clusteringMenu__characteristicValue"
                >: {{ cl.label.characteristics[1].value }}</td
              >
              <td class="clusteringMenu__userCount">{{
                $t("userCount", { count: cl.userCount })
              }}</td>
            </tr>
          </table>
        </template>
        <div v-else-if="!isFetched">
          <Loading height="200px" />
        </div>
        <div v-else class="clusteringMenu__failureMessage">
          {{ $t("clusteringFailed") }}
        </div>
      </div>
    </div>

    <div class="clusteringMenu__helpIcon">
      <Tooltip :placement="TooltipPlacement.BottomStart">
        <Icon :icon="iconHelp" :color="iconHelpColor" :size="12" />
        <template #content>
          <div class="clusteringMenu__content">
            {{ $t("clusteringNoteHeader") }}
            <ul>
              <li>{{ $t("clusteringNote1") }}</li>
              <li>{{ $t("clusteringNote2") }}</li>
              <li
                >{{ $t("clusteringNote3-1") }}<br />{{
                  $t("clusteringNote3-2")
                }}</li
              >
              <li>{{ $t("clusteringNote4") }}</li>
              <li>{{ $t("clusteringNote5") }}</li>
            </ul>
          </div>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { ClusteringPattern } from "@/models/search/ClusteringPattern";
import { handleNoQueryCacheError } from "@/util/error-util";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { TooltipPlacement } from "@/const/tooltip";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { SearchResultViews } from "@/const/SearchResultViews";

import Loading from "@/components/Loading.vue";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";

import vClickOutside from "v-click-outside";

@Component({
  components: {
    Icon,
    IconButton,
    Loading,
    Tooltip
  },
  directives: { clickOutside: vClickOutside.directive }
})
export default class ClusteringMenu extends Vue {
  showClusteringResults = false;

  Icons = Icons;
  iconHelp = Icons.HelpInvert;
  iconHelpColor = Colors.Base700;
  TooltipPlacement = TooltipPlacement;

  get isClusteringMode(): boolean {
    return this.$store.state.clustering.isClusteringMode;
  }

  get isFetched(): boolean {
    return this.$store.state.clustering.isPatternFetched;
  }

  get clusteringList(): ClusteringPattern[] {
    return this.$store.state.clustering.patternList;
  }

  get matchRatio(): number {
    return this.$store.state.clustering.ratio;
  }

  get matchCount(): number {
    return this.selectedPattern ? this.selectedPattern.userCount : 0;
  }

  get selectedPattern(): ClusteringPattern | null {
    return this.$store.state.clustering.selectedPattern;
  }

  get selectClasses(): string {
    const classes = ["clusteringMenu__select"];
    if (this.showClusteringResults) {
      classes.push("clusteringMenu__select--selected");
    }

    return classes.join(" ");
  }

  openClusteringResults() {
    this.showClusteringResults = true;
  }

  closeClusteringResults() {
    this.showClusteringResults = false;
  }

  selectClusteringPattern(pattern: ClusteringPattern) {
    if (
      this.selectedPattern === null ||
      this.selectedPattern.label !== pattern.label
    ) {
      UgTag.pushEvent(UgEventTag.SelectClustering);
      this.$store.commit("clustering/setClusteringPattern", pattern);
      this.$store.dispatch("clustering/executeClustering").catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });

      this.$store.commit(
        "preference/setSearchResultView",
        SearchResultViews.Overview
      );

      this.closeClusteringResults();
    }
  }

  clearClustering() {
    this.showClusteringResults = false;
    this.$store.dispatch("clustering/clearClustering");
  }

  isSelectedPattern(pattern: ClusteringPattern): boolean {
    if (!this.selectedPattern) {
      return false;
    }
    return this.selectedPattern.title === pattern.title;
  }
  showCharacteristic(pattern: ClusteringPattern): boolean {
    if (!pattern.isCalculated) {
      return false;
    }
    if (pattern.label.characteristics.length < 2) {
      return false;
    }
    return true;
  }
}
</script>

<style lang="scss" scoped>
.clusteringMenu {
  display: flex;
}

.clusteringMenu__inner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 3px 6px 8px;
  border-radius: 5px;
}

.clusteringMenu__inner--active {
  padding: 6px 32px 6px 8px;
  background-color: $colorBlue600;
}

.clusteringMenu__select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 26px 8px 8px;
  min-width: 160px;
  height: 28px;
  border: 1px solid $colorBase600;
  border-radius: 5px;
  background-color: $colorWhite;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: $colorHoverLightForWhite;
  }
}

.clusteringMenu__select--selected {
  background-color: $colorHoverLightForWhite;
}

.clusteringMenu__selectWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 172px;
  font-size: 12px;
}
.clusteringMenu__selectTitle {
  padding: 0 2px 0 3px;
}
.clusteringMenu__selectCharacteristicName {
  padding-left: 8px;
}

.clusteringMenu__selectCharacteristicValue {
  padding: 0 0 0 2px;
}

.clusteringMenu__selectIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -4px;
}

.clusteringMenu__selectedMenuCount {
  margin-left: 12px;
  font-weight: bold;
  font-size: 16px;
}

.clusteringMenu__clear {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.clusteringMenu__resultContainer {
  position: absolute;
  top: 36px;
  left: 8px;
  overflow: hidden;
  min-width: 320px;
  border: 1px solid $colorBase600;
  border-radius: 5px;
  background-color: $colorWhite;
}

.clusteringMenu__result {
  width: 100%;
}

.clusteringMenu__item {
  width: 100%;
  color: $colorBase700;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:not(.clusteringMenu__item--selected):hover {
    background-color: $colorBlue600;
    color: $colorBase900;
  }

  &.clusteringMenu__item--selected {
    background-color: $colorHoverLightForWhite;
    color: $colorBase900;
    cursor: default;
  }

  & td {
    padding-top: 15px;
    padding-bottom: 15px;
    white-space: nowrap;
  }
  & th {
    padding-left: 15px;
    white-space: nowrap;
  }
  & td:last-child {
    padding-right: 15px;
  }
}

.clusteringMenu__result--selected {
  background-color: $colorBlue700;
  color: $colorBase900;
  cursor: default;
}

.clusteringMenu__title {
  padding-right: 16px;
  width: 1%;
  text-align: left;
}

.clusteringMenu__characteristicName {
  width: 1%;
}

.clusteringMenu__characteristicValue {
  padding-right: 20px;
  padding-left: 4px;
  width: 1%;
}

.clusteringMenu__userCount {
  margin-left: 15px;
  color: $colorBase800;
  text-align: right;
  white-space: nowrap;
  font-weight: bold;
  font-size: 16px;
}

.clusteringMenu__failureMessage {
  padding: 20px;
  font-weight: bold;
}

.clusteringMenu__helpIcon {
  margin: auto 6px;
}

.clusteringMenu__content {
  width: 600px;
  white-space: normal;
  line-height: 1.8;

  & li {
    position: relative;
    padding: 0 0 4px 20px;
    &::before {
      position: absolute;
      left: 11px;
      content: "- ";
      line-height: 1.8;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>
