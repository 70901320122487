import HttpClient from "@/api/HttpClient";
import ApiConfig from "@/api/apis/ApiConfig";
import ApiSystem from "@/api/apis/ApiSystem";
import ApiAuth from "@/api/apis/ApiAuth";
import ApiClients from "@/api/apis/ApiClients";
import ApiClientSettings from "@/api/apis/ApiClientSettings";
import ApiUserdata from "@/api/apis/ApiUserdata";
import ApiClientPv from "@/api/apis/ApiClientPv";
import ApiSearch from "@/api/apis/ApiSearch";
import ApiVisits from "@/api/apis/ApiVisits";
import ApiMemo from "@/api/apis/ApiMemo";
import ApiWordCloud from "@/api/apis/ApiWordcloud";
import ApiUserHistory from "@/api/apis/ApiUserHistory";
import ApiSearchHistory from "@/api/apis/ApiSearchHistory";
import ApiEngagementSearch from "@/api/apis/ApiEngagementSearch";
import ApiUserTrends from "@/api/apis/ApiUserTrend";
import ApiSegmentedTrends from "@/api/apis/ApiSegmentedTrends";
import ApiLagCv from "@/api/apis/ApiLagCv";
import ApiAlmostCv from "@/api/apis/ApiAlmostCv";
import ApiForgetPassword from "@/api/apis/ApiForgetPassword";
import ApiConversionTrend from "@/api/apis/ApiConversionTrend";
import ApiFunnel from "@/api/apis/ApiFunnel";
import ApiTour from "@/api/apis/ApiTour";
import ApiConversion from "@/api/apis/client-settings/ApiConversion";
import ApiAppConversion from "@/api/apis/client-settings/ApiAppConversion";
import ApiTdWebImportConfig from "@/api/apis/client-settings/ApiTdWebImportConfig";
import ApiTdConversion from "@/api/apis/client-settings/ApiTdConversion";
import ApiTdConversionAttribute from "@/api/apis/client-settings/ApiTdConversionAttribute";
import ApiMfa from "@/api/apis/ApiMfa";
import ApiInputFormSuggestion from "@/api/apis/ApiInputFormSuggestion";
import ApiMapConversionAttribute from "@/api/apis/client-settings/ApiMapConversionAttribute";
import ApiMapEvent from "@/api/apis/client-settings/ApiMapEvent";

export class ApiState {
  httpClient = new HttpClient();
  config = new ApiConfig(this.httpClient);
  system = new ApiSystem(this.httpClient);
  auth = new ApiAuth(this.httpClient);
  clients = new ApiClients(this.httpClient);
  clientSettings = new ApiClientSettings(this.httpClient);
  userdata = new ApiUserdata(this.httpClient);
  clientPv = new ApiClientPv(this.httpClient);
  search = new ApiSearch(this.httpClient);
  searchHistory = new ApiSearchHistory(this.httpClient);
  visits = new ApiVisits(this.httpClient);
  memo = new ApiMemo(this.httpClient);
  wordcloud = new ApiWordCloud(this.httpClient);
  userHistory = new ApiUserHistory(this.httpClient);
  engagementSearch = new ApiEngagementSearch(this.httpClient);
  userTrends = new ApiUserTrends(this.httpClient);
  segmentedTrends = new ApiSegmentedTrends(this.httpClient);
  lagCv = new ApiLagCv(this.httpClient);
  almostCv = new ApiAlmostCv(this.httpClient);
  forgetPassword = new ApiForgetPassword(this.httpClient);
  conversionTrend = new ApiConversionTrend(this.httpClient);
  funnel = new ApiFunnel(this.httpClient);
  tour = new ApiTour(this.httpClient);
  conversion = new ApiConversion(this.httpClient);
  appConversion = new ApiAppConversion(this.httpClient);
  mapConversionAttribute = new ApiMapConversionAttribute(this.httpClient);
  mapEvent = new ApiMapEvent(this.httpClient);
  tdWebImportConfig = new ApiTdWebImportConfig(this.httpClient);
  tdConversion = new ApiTdConversion(this.httpClient);
  tdConversionAttribute = new ApiTdConversionAttribute(this.httpClient);
  mfa = new ApiMfa(this.httpClient);
  inputFormSuggestion = new ApiInputFormSuggestion(this.httpClient);
}

export const api = {
  namespaced: true,
  state: new ApiState()
};
