<template>
  <div class="tourDetailHeaderTip">
    <Icon
      class="tourDetailHeaderTip__icon"
      :icon="icon"
      :size="iconSize"
      :color="iconColor"
    />
    <p class="tourDetailHeaderTip__text">{{ text }}</p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";

@Component({
  components: { Icon }
})
export default class TourDetailHeaderPanelTip extends Vue {
  @Prop({ type: String, required: true })
  text!: string;

  icon = Icons.User;
  iconSize = 14;
  iconColor = Colors.TourBase;
}
</script>

<style lang="scss" scope>
.tourDetailHeaderTip {
  display: flex;
  align-items: flex-start;
}

.tourDetailHeaderTip__icon {
  position: relative;
  top: 2px;
}

.tourDetailHeaderTip__text {
  margin-left: 8px;
  width: 100%;
  color: $colorBase700;
  font-size: 14px;
  line-height: 1.5;
}

.tourDetailHeaderTip ~ .tourDetailHeaderTip {
  margin-top: 12px;
  padding-top: 13px;
  border-top: 1px dashed $colorBase400;
}
</style>
