import { UserTrendRowJsonData } from "@/api/apis/ApiUserTrend";
import { UserTrendNumberData } from "@/models/user-trend/UserTrendNumberData";

/**
 * ユーザートレンドデータ
 *
 * APIから得られたUserTrendの集計結果を保持し、
 * 表や散布図を作成するためのデータクラス
 */

export interface aggregateKey {
  key: string; // url, screen name, event name
  keyForDisplay: string; // url for display
  subKey: string | null; // page title, event attribute
}

export class UserTrendRowData {
  constructor(
    // あるページを見たユーザのID
    public readonly userIds: string[],
    // あるページのタイトルやURL, event名などが入っている
    public readonly aggregateKeys: aggregateKey[], // for cv-paths

    // メインのデータ期間
    public readonly base: UserTrendNumberData,
    // 比較用のデータ期間
    public readonly comparison: UserTrendNumberData | null = null
  ) {}

  public static fromJson(json: UserTrendRowJsonData): UserTrendRowData {
    const aggregateKeys: aggregateKey[] =
      json.aggregate_keys !== undefined
        ? json.aggregate_keys.map(aggregate_key => {
            return {
              key: aggregate_key.key,
              keyForDisplay: aggregate_key.key.replace(/^http(s?):\/\//i, ""),
              subKey:
                aggregate_key.sub_key !== undefined
                  ? aggregate_key.sub_key
                  : null
            };
          })
        : [];
    return new UserTrendRowData(
      json.cv_user_ids !== undefined ? json.cv_user_ids : [],
      aggregateKeys,
      UserTrendNumberData.fromJson(json.base),
      json.comparison !== undefined
        ? UserTrendNumberData.fromJson(json.comparison)
        : null
    );
  }
}
