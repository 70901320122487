import { render, staticRenderFns } from "./InputFormSuggestionTooltip.vue?vue&type=template&id=8bd44c1a&scoped=true"
import script from "./InputFormSuggestionTooltip.vue?vue&type=script&lang=ts"
export * from "./InputFormSuggestionTooltip.vue?vue&type=script&lang=ts"
import style0 from "./InputFormSuggestionTooltip.vue?vue&type=style&index=0&id=8bd44c1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bd44c1a",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/input-form-suggestion/input-form-suggetion-tooltip.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Finput-form-suggestion%2FInputFormSuggestionTooltip.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports