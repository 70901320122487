<i18n src="@/i18n/components/user-trend/scatter/user-trend-tooltip.json"></i18n>
<template>
  <div class="userTrendTooltip" :style="style">
    <Balloon :not-arrow="false">
      <div class="userTrendTooltip__container">
        <div
          v-for="(aggregateKey, index) in filterAggregateKeys"
          :key="index"
          class="userTrendTooltip__title"
          :class="addDetailTitleClass(index)"
        >
          <a
            v-if="showLink"
            :href="aggregateKey.key"
            class="userTrendTooltip__link"
            target="_blank"
            rel="noopener"
          >
            <Icon
              class="userTrendTooltip__externalLinkIcon"
              :icon="iconExternalLink"
              :size="10"
            />
            <span>{{ aggregateKey.keyForDisplay }}</span>
          </a>
          <span v-else class="userTrendTooltip__text">{{
            aggregateKey.key
          }}</span>
        </div>

        <div class="userTrendTooltip__body">
          <div class="userTrendTooltip__column">
            <table class="userTrendTooltip__table">
              <tr
                v-if="showCount"
                class="userTrendTooltip__row userTrendTooltip__countRow"
                :class="addSelectedTypeClass(xAxisType.count)"
              >
                <td class="userTrendTooltip__cell">{{ countLabel }}</td>
                <td class="userTrendTooltip__divider">:</td>
                <td
                  class="userTrendTooltip__cell userTrendTooltip__countCell"
                  >{{ point.data.count.toLocaleString() }}</td
                >
              </tr>
              <tr
                class="userTrendTooltip__row userTrendTooltip__visitRow"
                :class="addSelectedTypeClass(xAxisType.visit)"
              >
                <td v-t="'visit'" class="userTrendTooltip__cell" />
                <td class="userTrendTooltip__divider">:</td>
                <td
                  class="userTrendTooltip__cell userTrendTooltip__visitsCell"
                  >{{ point.data.visit.toLocaleString() }}</td
                >
              </tr>
              <tr
                class="userTrendTooltip__row userTrendTooltip__uuRow"
                :class="addSelectedTypeClass(xAxisType.uu)"
              >
                <td v-t="'uu'" class="userTrendTooltip__cell" />
                <td class="userTrendTooltip__divider">:</td>
                <td class="userTrendTooltip__cell userTrendTooltip__uuCell">{{
                  point.data.uniqueUsers.toLocaleString()
                }}</td>
              </tr>
            </table>
          </div>

          <div class="userTrendTooltip__column">
            <table class="userTrendTooltip__table">
              <tr
                class="userTrendTooltip__row"
                :class="addSelectedTypeClass(yAxisType.cvInVisit)"
              >
                <td v-t="'cvInVisit'" class="userTrendTooltip__cell" />
                <td class="userTrendTooltip__divider">:</td>
                <td
                  class="userTrendTooltip__cell userTrendTooltip__cvInVisitCell"
                  >{{ point.data.cvInVisit.toLocaleString() }}</td
                >
              </tr>
              <tr
                class="userTrendTooltip__row"
                :class="addSelectedTypeClass(yAxisType.cvRatioInVisit)"
              >
                <td v-t="'cvRatioInVisit'" class="userTrendTooltip__cell" />
                <td class="userTrendTooltip__divider">:</td>
                <td
                  class="userTrendTooltip__cell userTrendTooltip__cvRatioInVisitCell"
                  >{{ point.data.cvRatioInVisit }}%</td
                >
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="point.userIds.length > 0"
          class="userTrendTooltip__user"
          data-cy="user-trend-tooltip-user"
          @click="onClick(point.userIds)"
        >
          <UserIconDefault class="userTrendTooltip__userIcon" />
          {{ $t("cvUserHelp") }}
        </div>
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserTrendChartPoint } from "@/models/user-trend/UserTrendChartPoint";
import { aggregateKey } from "@/models/user-trend/UserTrendRowData";
import { UserTrendScatterType } from "@/models/user-trend/UserTrendScatterType";
import {
  AnalysisType,
  UserTrendScatterXAxisType,
  UserTrendScatterYAxisType
} from "@/const/user-trend";
import { TOOLTIP_WIDTH, TOOLTIP_HEIGHT } from "@/const/user-trend";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import Balloon from "@/components/Balloon.vue";
import UserIconDefault from "@/assets/svg/user/face.svg";

@Component({
  components: {
    Icon,
    Balloon,
    UserIconDefault
  }
})
export default class UserTrendTooltip extends Vue {
  @Prop({ type: UserTrendScatterType, required: true })
  type!: UserTrendScatterType;

  @Prop({ type: Object, required: true })
  point!: UserTrendChartPoint;

  @Prop({ type: String, required: true })
  pageType!: AnalysisType;

  onClick(userIds: string[]) {
    this.$emit("click", userIds);
  }

  iconExternalLink = Icons.ExternalLink;
  xAxisType = UserTrendScatterXAxisType;
  yAxisType = UserTrendScatterYAxisType;

  get filterAggregateKeys(): aggregateKey[] {
    const aggregateKeys = this.point.aggregateKeys;

    if (this.pageType === AnalysisType.InflowDetail) {
      return aggregateKeys;
    }

    return this.point.aggregateKeys.filter(
      (aggregateKey: aggregateKey, i: number) => i === 0
    );
  }

  get showLink(): boolean {
    return (
      this.pageType === AnalysisType.WebView ||
      this.pageType === AnalysisType.Landing ||
      this.pageType === AnalysisType.Inflow ||
      this.pageType === AnalysisType.InflowDetail
    );
  }

  get showCount(): boolean {
    return (
      this.pageType === AnalysisType.WebView ||
      this.pageType === AnalysisType.AppView
    );
  }

  get countLabel(): string {
    if (this.pageType === AnalysisType.AppView) {
      return this.$i18n.t("viewCount") as string;
    }

    return this.$i18n.t("pv") as string;
  }

  get style() {
    return {
      width: TOOLTIP_WIDTH + "px",
      height: TOOLTIP_HEIGHT + "px"
    };
  }

  addSelectedTypeClass(
    type: UserTrendScatterXAxisType | UserTrendScatterYAxisType
  ): string {
    return this.type.xAxisType === type || this.type.yAxisType === type
      ? "userTrendTooltip__row--selected"
      : "";
  }

  addDetailTitleClass(index: number): string {
    if (this.pageType === AnalysisType.InflowDetail && index > 0) {
      return "userTrendTooltip__title--detail";
    }

    return "";
  }
}
</script>

<style scoped lang="scss">
.userTrendTooltip__container {
  padding: 10px;
  font-size: 12px;
}

.userTrendTooltip__title {
  position: relative;
}

.userTrendTooltip__title--detail {
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 3px;
    left: 5px;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid $colorBase900;
    border-left: 1px solid $colorBase900;
    content: "";
  }
}

.userTrendTooltip__body {
  display: table;
}

.userTrendTooltip__column {
  display: table-cell;
  &:first-child {
    padding-right: 15px;
  }
  &:last-child {
    padding-left: 15px;
  }
}

.userTrendTooltip__table {
  border-collapse: collapse;
}

.userTrendTooltip__row {
  border-bottom: 1px solid $colorBase500;
}

.userTrendTooltip__row--selected {
  border-bottom-color: $colorBase900;
  font-weight: bold;
}

.userTrendTooltip__link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 2px 0 4px 1px;
  color: $colorBase700;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;

  &::before {
    padding-right: 4px;
    font-size: 10px;
  }

  &:hover {
    color: $colorBase900;
    text-decoration: underline;
  }
}

.userTrendTooltip__externalLinkIcon {
  margin-right: 5px;
}

.userTrendTooltip__text {
  display: inline-block;
  overflow: hidden;
  padding: 2px 0 4px 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.userTrendTooltip__cell {
  padding: 10px 0 4px 0;
  height: 28px;
  white-space: nowrap;
  font-size: 13px;
}

.userTrendTooltip__divider {
  width: 14px;
  text-align: center;
}

.userTrendTooltip__user {
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
  color: $colorBase700;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;

  &:hover {
    color: $colorBase900;
    text-decoration: underline;
  }
}

.userTrendTooltip__userIcon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
</style>
