<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-contact-form">
    <div v-t="'contactPrefix'" class="prefix" />
    <div class="filter-contact-form-element">
      <div class="filter-contact-select-form">
        <SelectBox
          v-model="contactDefinitionId"
          :options="options"
          width="160px"
          class="select-box"
        />
        <SelectBox
          v-if="condition.canSelectSubjectType(selectedContactDefinitionType)"
          v-model="subjectTypeValue"
          :options="
            condition.getOptionsForContactSubjectType(
              selectedContactDefinitionType
            )
          "
          width="100%"
          class="contact-subject-type-select-box"
        />
      </div>
      <div class="filter-contact-talktime-form">
        <CheckBox
          v-if="showTalkTime"
          v-model="isCheckTalkTime"
          :checked="isCheckTalkTime"
          class="check-box"
        />
        <FilterBaseInputNumber
          v-if="showTalkTime"
          v-model="talkMinValue"
          :prefix="
            $te('contactTalkTimePrefix') ? $t('contactTalkTimePrefix') : ''
          "
          :suffix="$t('contactTalkTimeSuffix')"
          width="60px"
          class="input-text"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import CheckBox from "@/components/form/CheckBox.vue";
import FilterBaseInputNumber from "@/components/filter/form/FilterBaseInputNumber.vue";
import SelectBox from "@/components/form/SelectBox.vue";

import { FilterContactCondition } from "@/models/search/filter-node-condition/FilterContactCondition";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";
import SelectOption from "@/components/form/SelectOption";

@Component({
  components: {
    CheckBox,
    FilterBaseInputNumber,
    SelectBox
  }
})
export default class FilterContactField extends Vue {
  @Model("input", { type: FilterContactCondition, required: true })
  condition!: FilterContactCondition;

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  onInput(conditoin: FilterContactCondition) {
    this.$emit("input", conditoin);
  }

  get options(): SelectOption[] {
    return this.contactDefinitions.map(def => def.generateSelectOption());
  }

  get showTalkTime(): boolean {
    return this.selectedContactDefinitionType === ContactDefinitionType.TEL;
  }

  get contactDefinitionId(): number {
    return this.condition.contactDefinitionId;
  }
  set contactDefinitionId(contactDefinitionId: number) {
    // バリデーションに引っかかるので電話以外を選んだらチェックを外しておく
    const isCheck =
      contactDefinitionId === ContactDefinitionType.TEL
        ? this.condition.isCheckTalkTime
        : false;

    this.onInput(
      new FilterContactCondition(
        contactDefinitionId,
        isCheck,
        this.condition.talkMin
      )
    );
  }

  get isCheckTalkTime(): boolean {
    return this.condition.isCheckTalkTime;
  }
  set isCheckTalkTime(isChecked: boolean) {
    this.onInput(
      new FilterContactCondition(
        this.condition.contactDefinitionId,
        isChecked,
        this.condition.talkMin
      )
    );
  }

  get talkMinValue(): number {
    return this.condition.talkMin;
  }
  set talkMinValue(talkMin: number) {
    this.onInput(
      new FilterContactCondition(
        this.condition.contactDefinitionId,
        this.condition.isCheckTalkTime,
        talkMin
      )
    );
  }

  get subjectTypeValue(): number {
    return this.condition.subjectType || 0;
  }
  set subjectTypeValue(subjectType: number) {
    this.onInput(
      new FilterContactCondition(
        this.condition.contactDefinitionId,
        this.condition.isCheckTalkTime,
        this.condition.talkMin,
        subjectType
      )
    );
  }

  get selectedContactDefinitionType(): ContactDefinitionType {
    const contact = this.contactDefinitions.filter(
      def => def.id === this.condition.contactDefinitionId
    );
    if (contact.length > 0) {
      return contact[0].type;
    }

    return 0;
  }
}
</script>
<style lang="scss" scoped>
.filter-contact-form {
  display: flex;
}

.filter-contact-form-element {
  display: inline-block;
  align-items: center;
}

.contact-subject-type-select-box {
  margin-left: 16px;
}

.input-text {
  margin-left: 7px;
}

.filter-contact-talktime-form {
  margin-top: 20px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.prefix {
  display: inline-block;
  color: $colorBase700;
  font-size: 14px;
  margin: 10px 7px 0 0;
}
</style>
