<template>
  <FilterBaseInput
    class="filter-base-input-number"
    :prefix="prefix"
    :suffix="suffix"
  >
    <InputNumber :value="value" :style="style" @input="onInput" />
  </FilterBaseInput>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import FilterBaseInput from "@/components/filter/form/FilterBaseInput.vue";
import InputNumber from "@/components/form/InputNumber.vue";

@Component({
  components: {
    FilterBaseInput,
    InputNumber
  }
})
export default class FilterBaseInputNumber extends Vue {
  @Model("input", { type: Number, required: true })
  value!: Number;

  @Prop({ type: String, default: "" })
  prefix!: string;

  @Prop({ type: String, default: "" })
  suffix!: string;

  @Prop({ type: String, default: "160px" })
  width!: string;

  onInput(value: number) {
    this.$emit("input", value);
  }

  get style() {
    return {
      width: this.width
    };
  }
}
</script>
