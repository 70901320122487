import { MutationTree } from "vuex";
import { ChartPeriod } from "@/const/chart-period";
import { SearchResultViews } from "@/const/SearchResultViews";
import {
  UserTrendScatterXAxisType,
  UserTrendScatterYAxisType
} from "@/const/user-trend";
import { FavoriteSearch } from "@/models/search/FavoriteSearch";

export class PreferenceState {
  userTrendXAxisDataType: UserTrendScatterXAxisType =
    UserTrendScatterXAxisType.visit;
  userTrendYAxisDataType: UserTrendScatterYAxisType =
    UserTrendScatterYAxisType.cvInVisit;
  chartPeriod: ChartPeriod = ChartPeriod.SixMonths;
  searchResultView: SearchResultViews = SearchResultViews.Attribute;
  noMoreShowHomeOnboarding: boolean = false;
  noMoreShowSearchOnboarding: boolean = false;
  clickExpandGlobalNav: boolean = false;

  noMoreShowSearchBlogBalloonOnboarding: boolean = false;
  noMoreShowFilterBlogBalloonOnboarding: boolean = false;
  noMoreShowUserIndexSmallBlogBalloonOnboarding: boolean = false;
  noMoreShowUserIndexLargeBlogBalloonOnboarding: boolean = false;
  noMoreShowUserDetailBlogBalloonOnboarding: boolean = false;
  noMoreShowUserTrendBlogBalloonOnboarding: boolean = false;

  favoriteSearchBalloonPositionY: string = "";
  favoriteSearchBalloonArrowAdjust: number = 0;
  hoveredFavoriteSearch: FavoriteSearch | null = null;
  isFavoriteSearchBalloonHovered: boolean = false;
}

const mutations: MutationTree<PreferenceState> = {
  setUserTrendXAxisDataType(
    state: PreferenceState,
    xAxisDataType: UserTrendScatterXAxisType
  ) {
    state.userTrendXAxisDataType = xAxisDataType;
  },
  setUserTrendYAxisDataType(
    state: PreferenceState,
    yAxisDataType: UserTrendScatterYAxisType
  ) {
    state.userTrendYAxisDataType = yAxisDataType;
  },
  setChartPeriod(state: PreferenceState, chartPeriod: ChartPeriod) {
    state.chartPeriod = chartPeriod;
  },
  setSearchResultView(
    state: PreferenceState,
    searchResultView: SearchResultViews
  ) {
    state.searchResultView = searchResultView;
  },
  initialize(state: PreferenceState) {
    state.userTrendXAxisDataType = UserTrendScatterXAxisType.visit;
    state.userTrendYAxisDataType = UserTrendScatterYAxisType.cvInVisit;
    state.searchResultView = SearchResultViews.Attribute;
  },
  setNoMoreShowHomeOnboarding(state: PreferenceState, noMoreShow: boolean) {
    state.noMoreShowHomeOnboarding = noMoreShow;
  },
  setNoMoreShowSearchOnboarding(state: PreferenceState, noMoreShow: boolean) {
    state.noMoreShowSearchOnboarding = noMoreShow;
  },
  setClickExpandGlobalNav(
    state: PreferenceState,
    clickExpandGlobalNav: boolean
  ) {
    state.clickExpandGlobalNav = clickExpandGlobalNav;
  },
  setFavoriteSearchBalloonPositionY(
    state: PreferenceState,
    balloonPositionY: string
  ) {
    state.favoriteSearchBalloonPositionY = balloonPositionY;
  },
  setFavoriteSearchBalloonArrowAdjust(
    state: PreferenceState,
    balloonArrowAdjust: number
  ) {
    state.favoriteSearchBalloonArrowAdjust = balloonArrowAdjust;
  },
  setHoveredFavoriteSearch(
    state: PreferenceState,
    hoveredFavoriteSearch: FavoriteSearch
  ) {
    state.hoveredFavoriteSearch = hoveredFavoriteSearch;
  },
  setIsFavoriteSearchBalloonHovered(
    state: PreferenceState,
    isBalloonHover: boolean
  ) {
    state.isFavoriteSearchBalloonHovered = isBalloonHover;
  },
  setNoMoreShowSearchBlogPageBalloonOnboarding(
    state: PreferenceState,
    noMoreShow: boolean
  ) {
    state.noMoreShowSearchBlogBalloonOnboarding = noMoreShow;
  },
  setNoMoreShowFilterBlogPageBalloonOnboarding(
    state: PreferenceState,
    noMoreShow: boolean
  ) {
    state.noMoreShowFilterBlogBalloonOnboarding = noMoreShow;
  },
  setNoMoreShowUserIndexSmallBlogPageBalloonOnboarding(
    state: PreferenceState,
    noMoreShow: boolean
  ) {
    state.noMoreShowUserIndexSmallBlogBalloonOnboarding = noMoreShow;
  },
  setNoMoreShowUserIndexLargeBlogPageBalloonOnboarding(
    state: PreferenceState,
    noMoreShow: boolean
  ) {
    state.noMoreShowUserIndexLargeBlogBalloonOnboarding = noMoreShow;
  },
  setNoMoreShowUserDetailBlogPageBalloonOnboarding(
    state: PreferenceState,
    noMoreShow: boolean
  ) {
    state.noMoreShowUserDetailBlogBalloonOnboarding = noMoreShow;
  },
  setNoMoreShowUserTrendBlogPageBalloonOnboarding(
    state: PreferenceState,
    noMoreShow: boolean
  ) {
    state.noMoreShowUserTrendBlogBalloonOnboarding = noMoreShow;
  }
};

export const preference = {
  namespaced: true,
  state: new PreferenceState(),
  mutations
};
