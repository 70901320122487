<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div class="search-status">
    <Icon :icon="Icons.Users" :size="24"></Icon>
    <div v-if="isCountFetched && isTourDetail" class="search-status__count">
      <div>{{ $t("userCount", { count: tourUserCount }) }}</div>
    </div>
    <div
      v-else-if="isCountFetched && !isFilterMode"
      class="search-status__count"
      data-cy="search-status__count"
    >
      <div v-if="selectExactUserCount > 2000">{{ $t("moreThan2000") }}</div>
      <div v-else>{{ $t("userCount", { count: selectUserCount }) }}</div>
    </div>
    <div v-else-if="isCountFetched && isFilterMode">
      <div class="search-status__match">{{
        $t("matchRatio", { ratio: matchRatio })
      }}</div>
      <div
        class="search-status__filter-count"
        data-cy="search-status__filter-count"
        >{{
          $t("fraction", { numer: filterUserCount, denom: selectUserCount })
        }}</div
      >
    </div>
    <div v-else-if="isFilterMode">
      <ProgressBar :progress="progress">{{ progressText }}</ProgressBar>
    </div>
    <div v-else>{{ $t("calculating") }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProgressBar from "@/components/ProgressBar.vue";
import Icon from "@/components/Icon.vue";

import { Icons } from "@/const/Icons";
import { TOUR_DETAIL_MAX_USER_COUNT } from "@/store/modules/tour";

@Component({
  components: {
    ProgressBar,
    Icon
  }
})
export default class SearchStatus extends Vue {
  Icons = Icons;

  get isFilterMode(): boolean {
    return this.$store.state.filter.isFilterMode;
  }

  get isCountFetched(): boolean {
    const searchCountFetched: boolean = this.$store.state.search.isCountFetched;
    const filterCountFetched: boolean = this.$store.state.filter.isCountFetched;
    return this.isFilterMode
      ? searchCountFetched && filterCountFetched
      : searchCountFetched;
  }

  get selectExactUserCount(): number {
    return this.$store.state.search.userCount;
  }

  get selectUserCount(): number {
    const count: number = this.selectExactUserCount;
    if (count >= 2000) {
      return 2000;
    }
    return count;
  }

  get filterUserCount(): number {
    const count: number = this.$store.state.filter.userCount;
    if (count >= 2000) {
      return 2000;
    }
    return count;
  }

  get tourUserCount(): number {
    return this.filterUserCount > TOUR_DETAIL_MAX_USER_COUNT
      ? TOUR_DETAIL_MAX_USER_COUNT
      : this.filterUserCount;
  }

  get progress(): number {
    return this.$store.state.filter.progress || 0;
  }

  get matchRatio(): number {
    return this.selectUserCount === 0
      ? 0
      : Math.floor((this.filterUserCount * 1000) / this.selectUserCount) / 10;
  }

  get progressText(): string {
    if (this.isTourDetail) {
      return this.$i18n.t("calculating") as string;
    }
    if (this.$store.state.filter.isProgressUserCountDisplayable) {
      return (
        this.$i18n.t("progressMatchRatio", {
          ratio: this.$store.state.filter.matchedPercentForProgress
        }) +
        "(" +
        this.$i18n.t("progressUserCount", {
          count: this.$store.state.filter.userCountForProgress
        }) +
        ")"
      );
    }

    return this.$i18n.t("calculating") as string;
  }

  get isTourDetail(): boolean {
    return this.$route.name === "tour-detail";
  }
}
</script>

<style lang="scss" scoped>
.search-status {
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  &__count {
    font-weight: bold;
    font-size: 16px;
  }

  &__match {
    font-weight: bold;
  }

  &__filter-count {
    margin-top: 5px;
    font-size: 12px;
  }
}
</style>
