<template>
  <div class="settingsTable" :style="style">
    <div class="settingsTable__head">
      <slot name="header"></slot>
    </div>
    <div class="settingsTable__body">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SettingsTable extends Vue {
  @Prop({ type: String, default: "auto" })
  minWidth!: string;

  get style() {
    return {
      minWidth: this.minWidth
    };
  }
}
</script>

<style lang="scss" scoped>
.settingsTable {
  display: table;
  border-collapse: collapse;
  font-size: 12px;
}

.settingsTable__head {
  display: table-header-group;
  width: 100%;
}

.settingsTable__body {
  display: table-row-group;
}
</style>
