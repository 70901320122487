<template>
  <div
    class="navigationItem"
    :class="[
      hoverClass,
      showText ? 'navigationItem--textExists' : 'navigationItem--iconOnly',
      { 'navigationItem--active': active }
    ]"
  >
    <Icon
      v-if="icon"
      class="navigationItem__icon"
      :icon="icon"
      :color="iconColor"
      :size="size"
    />
    <span
      v-if="hasCustomIconSlotContent"
      class="navigationItem__icon navigationItem__customIcon"
    >
      <slot name="customicon"></slot>
    </span>
    <Transition name="navigationItemText">
      <div v-if="showText" class="navigationItem__text">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";

import { HoverColor, ICON_COLOR, ICON_SIZE } from "@/const/NavigationItem";

@Component({
  components: {
    Icon
  }
})
export default class NavigationItem extends Vue {
  @Prop({ type: String })
  icon!: Icons;

  @Prop({ type: String, default: HoverColor.Blue })
  hoverColor!: HoverColor;

  @Prop({ type: Number, default: ICON_SIZE })
  size!: number;

  @Prop({ type: Boolean, default: true })
  showText!: boolean;

  @Prop({ type: Boolean, default: false })
  active!: boolean;

  iconColor = ICON_COLOR;

  get hoverClass(): string {
    return "navigationItem--" + this.hoverColor;
  }

  get hasCustomIconSlotContent() {
    return this.$slots.customicon !== undefined;
  }
}
</script>

<style lang="scss" scoped>
.navigationItem {
  display: flex;
  align-items: center;
  padding: 0 22px;
  height: 40px;
  color: $colorBase700;
  line-height: 1.3;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.navigationItem__icon {
  transition: color 0.5s ease;
}

.navigationItem__customIcon {
  line-height: 0;
  margin-top: 1px;
}

.navigationItem__text {
  overflow: hidden;
  margin-left: 8px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navigationItem--blue {
  background-color: $colorWhite;
  color: $colorBase700;

  &:hover {
    background-color: $colorBlue600;
    color: $colorBase900;
  }
}

.router-link-active .navigationItem--blue,
.navigationItem--active.navigationItem--blue {
  background-color: $colorBlue600;
  color: $colorBase900;
}

.navigationItem--black {
  background-color: $colorWhite;
  color: $colorBase700;

  &:hover {
    background-color: $colorBase800;
    color: $colorWhite;
  }
}

.router-link-active .navigationItem--black,
.navigationItem--active.navigationItem--black {
  background-color: $colorBase800;
  color: $colorWhite;
}

.navigationItem--transparent {
  background-color: $colorWhite;
  color: $colorBase700;

  &:hover {
    color: $colorBase900;
  }
}

.router-link-active .navigationItem--transparent,
.navigationItem--active.navigationItem--transparent {
  color: $colorBase900;
}

.navigationItemText-enter-active,
.navigationItemText-leave-active {
  opacity: 1;
  transition: opacity $navigationItemTransition ease;
}

.navigationItemText-enter,
.navigationItemText-leave-to {
  opacity: 0;
}
</style>
