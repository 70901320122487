import { ConversionAttributeType } from "@/models/client-settings/ConversionAttributeDefinition";
import { CreationMethod } from "@/models/client-settings/Coordination";

export class MapConversionAttributeDefinition {
  constructor(
    public readonly id: number,
    public readonly coordinationId: number,
    public readonly conversionAttributeId: number,
    public readonly parameter: string,
    public readonly isDisabled: boolean
  ) {}
}

export class MapCvAttributeDefinitionList {
  constructor(
    public readonly coordinationId: number,
    public readonly parameters: string[]
  ) {}
}

export class MapCvAttributeDefinitionListWithCvAttribute {
  constructor(
    public readonly creationMethod: CreationMethod,
    public readonly coordinationId: number,
    public readonly conversionAttributeName: string,
    public readonly conversionAttributeType: ConversionAttributeType,
    public readonly conversionAttributeId: number,
    public readonly parameters: string[]
  ) {}
}
