<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div class="featuredUsers">
    <div class="featuredUsers__tab" data-cy="featuredUsers__tab">
      <RadioButton
        class="featuredUsers__radio"
        :value="selectedTab"
        name="behaviorType"
        :label="lagCvTabIndex"
        @change="changeContentType"
      >
        <span class="featuredUsers__tabLabel">
          {{ $t("lagCv") }}
        </span>
      </RadioButton>
      <RadioButton
        class="featuredUsers__radio"
        :value="selectedTab"
        name="behaviorType"
        :label="almostCvTabIndex"
        @change="changeContentType"
      >
        <span class="featuredUsers__tabLabel">
          {{ $t("almostCv") }}
        </span>
      </RadioButton>
    </div>
    <FeaturedUsersLagCv
      v-if="isLagCvTabSelected"
      :lag-cv-user-counts="lagCvUserCounts"
      :is-error="isLagCvError"
      :is-lag-cv-loading="isLagCvLoading"
      @select="onSelectResult"
      @retry-fetch="fetchLagCvUserCounts"
    />
    <FeaturedUsersAlmostCv
      v-else
      :almost-cv-info="almostCvInfo"
      :is-error="isAlmostCvError"
      :is-almost-cv-loading="isAlmostCvLoading"
      @select="onSelectResult"
      @retry-fetch="fetchAlmostCvUserCounts"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import RadioButton from "@/components/form/RadioButton.vue";
import FeaturedUsersLagCv from "@/components/search/FeaturedUsersLagCv.vue";
import FeaturedUsersAlmostCv from "@/components/search/FeaturedUsersAlmostCv.vue";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";
import LagCvUserCount from "@/models/lag-cv/LagCvUserCount";

enum TabIndex {
  LagCv,
  AlmostCv
}

@Component({
  components: {
    RadioButton,
    FeaturedUsersLagCv,
    FeaturedUsersAlmostCv
  }
})
export default class SearchFeaturedUsers extends Vue {
  almostCvTabIndex = TabIndex.AlmostCv;
  lagCvTabIndex = TabIndex.LagCv;
  selectedTab = this.lagCvTabIndex;

  onSelectResult(url: string) {
    this.$emit("change", url);
  }

  get isLagCvTabSelected(): boolean {
    return this.selectedTab === TabIndex.LagCv;
  }

  get isAlmostCvTabSelected(): boolean {
    return this.selectedTab === TabIndex.AlmostCv;
  }

  get almostCvInfo(): AlmostCvInfo {
    return this.$store.state.almostCv.almostCvInfo;
  }

  get lagCvUserCounts(): LagCvUserCount[] {
    return this.$store.state.lagCv.lagCvUserCounts;
  }

  get isLagCvError(): boolean {
    return this.$store.state.lagCv.isLagCvsFetchError;
  }

  get isLagCvFetched(): boolean {
    return this.$store.state.lagCv.isLagCvsFetched;
  }

  get isLagCvLoading(): boolean {
    return !this.$store.state.lagCv.isLagCvsFetched;
  }

  get isAlmostCvError(): boolean {
    return this.$store.state.almostCv.isAlmostCvFetchError;
  }

  get isAlmostCvUserCountsFetched(): boolean {
    return this.$store.state.almostCv.isAlmostCvInfoFetched;
  }

  get isAlmostCvLoading(): boolean {
    return this.$store.state.almostCv.isLoadingAlmostCvInfo;
  }

  mounted() {
    this.fetchLagCvUserCountsIfNotFetched();
  }

  changeContentType(value: number) {
    this.selectedTab = value;
    this.fetchSelectedUserCountsIfNotFetched();
  }

  fetchSelectedUserCountsIfNotFetched() {
    if (this.isAlmostCvTabSelected) {
      this.fetchAlmostCvUserCountsIfNotFetched();
    }
  }

  fetchLagCvUserCounts() {
    this.$store.dispatch("lagCv/fetchLagCvUserCounts");
  }

  fetchLagCvUserCountsIfNotFetched() {
    if (!this.isLagCvFetched) {
      this.fetchLagCvUserCounts();
    }
  }

  fetchAlmostCvUserCounts() {
    this.$store.dispatch("almostCv/fetchAlmostCvInfo");
  }

  fetchAlmostCvUserCountsIfNotFetched() {
    if (!this.isAlmostCvUserCountsFetched) {
      this.fetchAlmostCvUserCounts();
    }
  }
}
</script>

<style lang="scss" scoped>
.featuredUsers {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.featuredUsers__tab {
  margin-bottom: 36px;
}
.featuredUsers__tabLabel {
  font-size: 14px;
  display: block;
  margin-top: -2px;
}
.featuredUsers__radio {
  margin-right: 120px;
}
</style>
