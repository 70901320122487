<i18n src="@/i18n/views/login.json"></i18n>

<template>
  <div class="login">
    <div class="login__languageLabel">
      <LanguageSelect />
    </div>

    <div class="login__container">
      <div class="login__logo">
        <UsergramLogo />
      </div>

      <div class="login__main">
        <div class="login__form">
          <div class="login__formId">
            <div class="login__formLabel" data-cy="lg-Form_Label">
              {{ $t("loginId") }}
            </div>
            <InputText
              v-model="id"
              class="login__formInput"
              data-cy="lg-Form_input_Id"
              :has-error="idInputError"
              @input="onIdInput"
            />

            <div
              v-if="idInputError"
              v-t="'loginId validation message'"
              data-cy="lg-Form_errorText"
              class="login__formErrorText"
            />
          </div>
          <div class="login__formPassword">
            <div class="login__formLabel" data-cy="lg-Form_Label_Password">
              {{ $t("password") }}
            </div>
            <InputText
              v-model="password"
              :type="isShowPassword ? 'text' : 'password'"
              class="login__formInput"
              data-cy="lg-Form_input_Password"
              @keyup-enter="submitLogin"
            />
            <CheckBox
              v-model="isShowPassword"
              data-cy="show-password"
              class="login__showPassword"
              >{{ $t("showPassword") }}</CheckBox
            >
          </div>

          <Button
            :disabled="isSubmitting || idInputError"
            data-cy="lg-Form_button"
            class="login__button"
            width="100%"
            @click="submitLogin"
            >{{ $t("login") }}</Button
          >
          <div class="login__forgotPasswordContainer">
            <router-link data-cy="forgot-password" to="/forget-password">{{
              $t("forgottenPassword")
            }}</router-link>
          </div>
        </div>
        <div class="login__news">
          <div
            v-for="newsForLoginPage in newsForLoginPages"
            :key="newsForLoginPage.id"
            class="login__newsRow"
            v-html="newsForLoginPage.displayMessage"
          ></div>
        </div>
      </div>

      <div class="login__footer">
        © Copyright beBit, Inc.
        <a href="https://help.usergram.info/" target="_blank" rel="noopener">{{
          $t("help")
        }}</a>
      </div>
    </div>

    <div v-if="isSubmitting" class="login__loading">
      <Loading height="100%" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { LoginUser } from "@/models/auth/UgUser";
import { NewsForLoginPage } from "@/models/NewsForLoginPage";
import { NewsForLoginPage as NewsJson } from "@/api/apis/ApiSystem";
import { handleError } from "@/util/error-util";
import { Icons } from "@/const/Icons";

import UsergramLogo from "@/assets/svg/logo/usergram-logo.svg";
import Button from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import InputText from "@/components/form/InputText.vue";
import CheckBox from "@/components/form/CheckBox.vue";
import Loading from "@/components/Loading.vue";
import LanguageSelect from "@/views/LanguageSelect.vue";
import { UgTag, UgConversionTag } from "@/store/modules/ugTag";

@Component({
  components: {
    Icon,
    Button,
    CheckBox,
    UsergramLogo,
    InputText,
    Loading,
    LanguageSelect
  }
})
export default class Login extends Vue {
  id: string = "";
  password: string = "";
  isShowPassword: boolean = false;
  newsForLoginPages: NewsForLoginPage[] = [];
  isSubmitting: boolean = false;
  selectArrow = Icons.ArrowBottom;

  idInputError: boolean = false;

  get isMfaRequired(): boolean {
    return this.$store.getters["auth/isMfaRequired"];
  }

  get isMfaEnabled(): boolean {
    return this.$store.getters["auth/isMfaEnabled"];
  }

  async created() {
    // お知らせの読み込み
    try {
      (
        await this.$store.state.api.system.getNewsForLoginPageList()
      ).messages.forEach((res: NewsJson) => {
        this.newsForLoginPages.push(new NewsForLoginPage(res));
      });
    } catch (e) {
      // ハンドリング不要
    }
  }

  public async submitLogin() {
    // 2重クリックガード
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    try {
      await this.$store.dispatch("auth/login", {
        id: this.id,
        password: this.password
      });

      UgTag.pushCv(UgConversionTag.Login);

      this.redirectToSuitablePage();
    } catch (e) {
      this.handleError(e);
      this.isSubmitting = false;
    }
  }

  private async redirectToSuitablePage() {
    const loginUser: LoginUser | null = this.$store.state.auth.user;

    const isSuperUser = loginUser && loginUser.isSuperUser;
    if (isSuperUser) {
      if (!this.isMfaRequired) {
        this.goToClientSettingsPage();
        return;
      }

      if (this.isMfaEnabled) {
        this.goToInputTotpPage();
      } else {
        this.goToMfaSetupPage();
      }
      return;
    }

    if (this.isMfaRequired) {
      if (this.isMfaEnabled) {
        this.goToInputTotpPage();
      } else {
        this.goToMfaSetupPage();
      }
      return;
    }

    await this.$store.dispatch("app/initializePostLogin");

    if (this.$route.query.redirect) {
      location.assign(this.$route.query.redirect as string);
      // Client、Definitions等のUGを利用する上で必須データを読み込む
      await this.$store.dispatch("app/initialize");
    } else {
      // Client、Definitions等のUGを利用する上で必須データを読み込む
      await this.$store.dispatch("app/initialize");
      this.goToHome();
      return;
    }
  }

  goToClientSettingsPage() {
    this.$router.push({ name: "client-settings-top" });
  }

  goToMfaSetupPage() {
    this.$router.push({
      path: "/mfa",
      query: this.$route.query
    });
  }

  goToInputTotpPage() {
    this.$router.push({
      path: "/totp",
      query: this.$route.query
    });
  }

  goToHome() {
    // ログイン画面からホームに飛ぶ際に、もう表示しないに
    // チェックしていない場合はホームのオンボーディングを表示
    this.$store.commit(
      "onboarding/setShowHomeOnboarding",
      !this.$store.state.preference.noMoreShowHomeOnboarding
    );
    location.replace("/"); // avoid user being able to navigate back to login
  }

  handleError(error: any) {
    this.isSubmitting = false;
    handleError(error);
  }

  onIdInput() {
    if (!this.id.match(/^[0-9A-Za-z-._]*$/)) {
      this.idInputError = true;
    } else {
      this.idInputError = false;
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100%;
  background-color: $colorWhite;
}

.login__languageLabel {
  position: fixed;
  top: 30px;
  right: 30px;
}

.login__container {
  padding: 30px;
  min-width: 735px;
  text-align: center;
}

.login__logo {
  padding: 56px 0 42px 0;
  width: 100%;
  text-align: center;

  & svg {
    width: 150px;
  }
}

.login__main {
  display: inline-flex;
  margin: 0 auto;
  margin-bottom: 80px;
  height: auto;
  text-align: left;
}

.login__form {
  padding-right: 40px;
  padding-bottom: 20px;
  width: 395px;
  height: 100%;
  border-right: 1px solid $colorLine;
}

.login__formId,
.login__formPassword {
  display: block;
  margin-top: 20px;
}

.login__formId {
  margin-bottom: 17px;
}

.login__showPassword {
  margin-top: 15px;
}

.login__button {
  margin-top: 35px;
}

.login__forgotPasswordContainer {
  margin-top: 20px;
  text-align: center;

  a {
    color: $colorTextLink;
    text-decoration: none;
  }
}

.login__footer {
  padding-bottom: 40px;
  text-align: center;
  font-size: 14px;

  & a {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $colorLine;
    color: $colorTextLinkGray;
    text-decoration: none;
  }
}

::v-deep .login__newsRow a {
  color: $colorTextLinkGray;
}

.login__forgotPasswordContainer a:hover {
  text-decoration: underline;
}

.login__footer a:hover {
  color: $colorText;
  text-decoration: underline;
}

.login__formLabel {
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 16px;
}

.login__formErrorText {
  margin-top: 8px;
  color: $colorError;
  font-size: 11px;
  line-height: 1.5;
}

.login__news {
  padding-left: 35px;
  width: 394px;
}

.login__newsRow {
  padding: 20px 0;
  border-top: 1px solid $colorLine;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.3;

  &:first-child {
    padding-top: 18px;
    border: 0px;
  }
}

.login__loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $colorOverlay;
  text-align: center;
  & img {
    width: 58px;
    height: 58px;
  }
}
</style>
