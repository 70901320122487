<template>
  <div
    ref="wrapper"
    class="select-box"
    :class="{
      placeholder: isPlaceholder,
      disabled: disabled,
      addIcon: hasIcon
    }"
  >
    <select
      class="select-box_pulldown"
      :class="{ 'select-box_pulldown--disabled': disabled }"
      data-cy="select-box_pulldown"
      :disabled="disabled"
      @change="onChange($event.target.value)"
      @focus="onFocus"
    >
      <template v-if="isOptionGroup">
        <optgroup
          v-for="group in options"
          :key="group.label"
          :label="group.label"
        >
          <option
            v-for="option in group.options"
            :key="option.value"
            :value="option.value"
            :selected="isSelected(option.value)"
            :disabled="option.disabled"
            >{{ option.label }}</option
          >
        </optgroup>
      </template>
      <template v-else>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="isSelected(option.value)"
          :disabled="option.disabled"
          >{{ option.label }}</option
        >
      </template>
    </select>
    <div
      class="select-box_wrapper"
      :class="{ 'select-box_wrapper--error': hasError }"
      :style="{ width }"
    >
      <div class="select-box_icon">
        <slot :icon-color="iconColor" />
      </div>
      {{ selectedLabel }}
      <Icon
        :icon="selectArrow"
        :size="8"
        :color="iconColor"
        class="select-box_arrow"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";
import SelectOption from "@/components/form/SelectOption";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";

import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";

@Component({
  components: { Icon }
})
export default class SelectBox extends Vue {
  @Model("input", { type: [Number, String], required: true })
  value!: number | string;

  @Prop({ type: Array, required: true })
  options!: SelectOption[] | SelectOptionGroup[];

  @Prop({ type: String, default: "200px" })
  width!: string;

  /*
  falseなら何もしませんが、値がセットされた場合、
  選択中のvalueとplaceholderValueが同じ値ならplaceholder扱いで、
  枠とテキストが薄いデザインになります。
  */
  @Prop({ type: [Number, String, Boolean], default: false })
  placeholderValue!: number | string | boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  hasIcon!: boolean;

  @Prop({ type: Boolean, default: false })
  hasError!: boolean;

  onInput(value: string | number) {
    this.$emit("input", value);
  }

  @Emit("focus")
  onFocus() {}

  selectArrow = Icons.ArrowBottom;

  get iconColor(): Colors {
    if (this.isPlaceholder) {
      return Colors.Base600;
    }
    if (this.disabled) {
      return Colors.ElmentUIGray300;
    }
    if (this.hasError) {
      return Colors.Error;
    }
    return Colors.Base900;
  }

  get isOptionGroup(): boolean {
    return this.options.length > 0 && "options" in this.options[0];
  }

  get selectedLabel(): string {
    // optionGroupの場合
    if (this.isOptionGroup) {
      const options = this.options as SelectOptionGroup[];
      let selected: SelectOption | undefined = undefined;
      for (let i = 0; i < options.length; i++) {
        selected = options[i].options.find(opt =>
          this.compareValue(opt.value, this.value)
        );
        if (selected !== undefined) {
          return selected.label;
        }
      }
    }
    // 通常のoptionの場合
    else {
      const options = this.options as SelectOption[];
      const selected = options.find(opt =>
        this.compareValue(opt.value, this.value)
      );
      return selected?.label || "";
    }
    return "";
  }

  get isPlaceholder(): boolean {
    if (this.placeholderValue === false) {
      return false;
    }

    return this.compareValue(String(this.placeholderValue), this.value);
  }

  isSelected(value: number | string) {
    return this.compareValue(value, this.value);
  }

  onChange(value: number | string) {
    if (this.disabled) {
      return;
    }
    // optionGroupの場合
    if (this.isOptionGroup) {
      const options = this.options as SelectOptionGroup[];
      let selected: SelectOption | undefined = undefined;
      for (let i = 0; i < options.length; i++) {
        selected = options[i].options.find(opt =>
          this.compareValue(opt.value, value)
        );
        if (selected !== undefined) {
          this.onInput(selected.value);
          return;
        }
      }
    }
    // 通常のoptionの場合
    else {
      const options = this.options as SelectOption[];
      const selected = options.find(opt => this.compareValue(opt.value, value));
      if (selected !== undefined) {
        this.onInput(selected.value);
      }
    }
  }

  compareValue(val1: number | string, val2: number | string): boolean {
    return String(val1) === String(val2);
  }
}
</script>

<style scoped lang="scss">
.select-box {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.select-box_wrapper {
  position: relative;
  overflow: hidden;
  padding-right: 26px;
  padding-left: 10px;
  width: 140px;
  height: $formPartsHeight;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorBase900;
  text-align-last: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: $formPartsHeight;
  pointer-events: none;
}

.select-box_wrapper--error {
  border-color: $colorError;
  color: $colorError;
}

.placeholder {
  & .select-box_wrapper {
    border-color: $colorBase600;
    color: $colorBase800;
  }
}

.select-box_pulldown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: $formPartsHeight;
  opacity: 0;
  cursor: pointer;
}

.disabled {
  & .select-box_pulldown {
    cursor: default;
  }

  & .select-box_wrapper {
    border-color: $colorGray200;
    background-color: $colorGray200;
    color: $colorBase600;
  }
}

.addIcon {
  & .select-box_wrapper {
    padding-left: 30px;
  }
}

.select-box_pulldown:hover + .select-box_wrapper {
  background-color: $colorHoverLightForWhite;
}
.select-box_pulldown--disabled {
  pointer-events: none;
}

.select-box_arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.select-box_icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
}
</style>
