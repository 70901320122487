import store from "@/store";
import router from "@/router";
import { i18n } from "@/i18n";
import { showAlert } from "@/util/modal-util";
import { handleNoQueryCacheError, handleError } from "@/util/error-util";
import { ERROR_CREATE_CONDITION_FROM_HISTORY_ID } from "@/store/modules/search";
import { addHidToUsersUrl } from "@/router";
import { SearchHistory } from "@/models/search/SearchHistory";
import { SearchResultViews } from "@/const/SearchResultViews";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";

export async function executeSearchByHistoryId(historyId: number) {
  try {
    await store.dispatch("search/executeSelectFromHistoryId", historyId);
  } catch (error) {
    // 条件がサーバから取得出来なかった場合はエラーを表示して、一番最後に実行した検索条件で検索
    if (
      (error as Error).message &&
      (error as Error).message === ERROR_CREATE_CONDITION_FROM_HISTORY_ID
    ) {
      showAlert(i18n.t("invalidUrl") as string);
      executeSearchWithLastExecutedCondition();
      return;
    }
    handleNoQueryCacheError(error);
  }
}

export async function executeSearchWithLastExecutedCondition() {
  const searchHistories: SearchHistory[] =
    store.getters["searchHistory/searchHistories"];

  const history = searchHistories.length > 0 ? searchHistories[0] : null;

  // 実行した検索条件が1件もなければホームに飛ばす
  if (history === null) {
    router.replace("/");
    return;
  }

  await store
    .dispatch("search/executeSelectFromHistory", {
      history,
      setHistoryIdToUrl: addHidToUsersUrl(router)
    })
    .catch(handleError);

  const query: { [key: string]: string } = {};

  router.replace({ name: "users", query });
}

export function createQueryWithViewAndHid(
  view: SearchResultViews
): { [key: string]: string } {
  const route = router.currentRoute;
  const query: { [key: string]: string } = { view };
  if (route.query.hid) {
    query["hid"] = route.query.hid as string;
  }
  return query;
}

export async function executeSearchByUserIds(userIds: string[]) {
  const selectCondition = new SelectByUserIdCondition(userIds);
  router.replace({ query: {} });

  await store
    .dispatch("search/executeSelect", { selectCondition })
    .catch(handleNoQueryCacheError);

  if (userIds.length === 0) {
    store.dispatch("search/clearSelectCondition");
  }
}
