import { i18n } from "@/i18n";
import { postDate } from "@/util/date-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  MAX_PERIOD_DAYS,
  SIX_MONTHS_DAYS
} from "@/models/search/select-condition/SelectCondition";
import { AttributeType } from "@/api/apis/ApiSearch";
import {
  FunnelConditionJson,
  FunnelEdgeJson,
  FunnelInflowMatchConditionJson
} from "@/api/apis/ApiFunnel";
import {
  FunnelType,
  SearchField,
  InflowSearchField,
  InflowOrganicSearchField
} from "@/const/funnel";
import {
  FunnelConditionAttributeType,
  FunnelConditionNumberAttribute,
  FunnelConditionTextAttribute
} from "@/models/funnel/FunnelConditionAttribute";
import { getDisplayPeriod } from "@/util/funnel-util";
import { SearchEngine } from "@/models/system/SearchEngine";

export type FunnelCondition =
  | FunnelConditionDefault
  | FunnelConditionPageTitleOrURL
  | FunnelConditionInflow
  | FunnelConditionInflowParam
  | FunnelConditionInflowOrganic;

const MAX_ATTRIBUTE_ITEM = 1;

/**
 * ファネル条件間の連結条件タイプ
 */
export enum FunnelEdgeType {
  allVisit = 1,
  sameVisit = 2
}

export enum SameVisitTransitionType {
  allAfter = 1,
  immediatelyAfter = 2
}

export interface InflowMatchCondition {
  value: string;
  wordMatchMethod: MatchMethod;
  searchField: InflowSearchField;
}

export type InflowOrganicMatchCondition =
  | InflowOrganicLocationCondition
  | InflowOrganicSearchEngineCondition;

export interface InflowOrganicLocationCondition {
  value: string;
  wordMatchMethod: MatchMethod;
  searchField:
    | InflowOrganicSearchField.LOCATION_URI
    | InflowOrganicSearchField.LOCATION_NAME;
}

export interface InflowOrganicSearchEngineCondition {
  searchField: InflowOrganicSearchField.SEARCH_ENGINE;
  searchEngineId: number;
}

/**
 * ファネル条件間の連結条件
 */
export class FunnelEdge {
  constructor(
    public readonly type: FunnelEdgeType,
    public readonly transitionType = SameVisitTransitionType.allAfter
  ) {}

  static defaultCondition() {
    return new FunnelEdge(FunnelEdgeType.allVisit);
  }

  tojson(): FunnelEdgeJson {
    const type = this.type;
    const params: FunnelEdgeJson = { type };

    if (type === FunnelEdgeType.sameVisit) {
      params["transition_type"] = this.transitionType;
    }

    return params;
  }
}

export abstract class BaseFunnelCondition {
  constructor(
    public readonly conditionType: FunnelType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly notCondition: boolean,
    public readonly edge: FunnelEdge
  ) {}

  update<T extends BaseFunnelCondition>(
    params: {
      [P in keyof T]?: T[P];
    }
  ): this {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), {
      ...this,
      ...params
    });
  }

  get numberOfPeriodDays(): number {
    return (
      (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24)
    );
  }

  get displayPeriod(): string {
    return getDisplayPeriod(this.startDate, this.endDate);
  }
}

/**
 * Funnel内の各条件を格納するクラス（CV・イベント・ビジネスイベント・行動履歴）
 */
export class FunnelConditionDefault extends BaseFunnelCondition {
  constructor(
    public readonly conditionType: FunnelType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly value: number,
    public readonly notCondition: boolean,
    public readonly attributes: FunnelConditionAttributeType[],
    public readonly edge: FunnelEdge
  ) {
    super(conditionType, startDate, endDate, notCondition, edge);
  }

  get typeName(): string {
    let result = "";

    if (this.conditionType === FunnelType.CV) {
      result = localI18n("conversion");
    } else if (this.conditionType === FunnelType.Event) {
      result = localI18n("event");
    } else if (this.conditionType === FunnelType.BusinessEvent) {
      result = localI18n("businessEvent");
    } else if (this.conditionType === FunnelType.Contact) {
      result = localI18n("contact");
    }

    return result;
  }

  get title(): string {
    return this.typeName;
  }

  get displayValueForHistory(): number {
    return this.value;
  }

  get displayValueForResult(): number[] {
    return [this.value];
  }

  toJson(useEdge = true): FunnelConditionJson {
    const params: FunnelConditionJson = {
      type: this.conditionType,
      start_date: postDate(this.startDate),
      end_date: postDate(this.endDate),
      value: this.value,
      not_condition: this.notCondition
    };

    if (useEdge) {
      params["edge"] = this.edge.tojson();
    }

    if (this.attributes.length > 0) {
      params["attributes"] = this.attributes.map(a => a.toJson());
    }
    return params;
  }

  static fromJson(json: FunnelConditionJson): FunnelConditionDefault {
    const startDate = new Date(json.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(json.end_date);
    endDate.setHours(23, 59, 59, 999);

    const attributes: FunnelConditionAttributeType[] = [];

    if (json.attributes !== undefined) {
      json.attributes.forEach(a => {
        if (a.type === AttributeType.NUMBER) {
          attributes.push(FunnelConditionNumberAttribute.fromJson(a));
        }
        if (a.type === AttributeType.TEXT) {
          attributes.push(FunnelConditionTextAttribute.fromJson(a));
        }
      });
    }

    return new FunnelConditionDefault(
      json.type,
      startDate,
      endDate,
      Number(json.value),
      json.not_condition,
      attributes,
      json.edge && json.edge.type !== undefined
        ? new FunnelEdge(json.edge.type, json.edge.transition_type)
        : FunnelEdge.defaultCondition()
    );
  }

  validate(canUseLongTerm = false): ValidationResult {
    const errorMessages: string[] = [];
    if (this.attributes.length > MAX_ATTRIBUTE_ITEM) {
      errorMessages.push(localI18n("validateMaxAttr"));
    }

    const periodValidation = validatePeriod(
      canUseLongTerm,
      this.numberOfPeriodDays
    );
    if (!periodValidation.isValid) {
      errorMessages.push(periodValidation.errorMessage);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    const childErrorMessages: string[] = [];
    this.attributes.forEach(a => {
      const result = a.validate;
      if (result.isValid === false) {
        childErrorMessages.push(result.errorMessage);
      }
    });
    if (childErrorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: childErrorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

/**
 * Funnel内の各条件を格納するクラス（ページタイトル・ページURL・スクリーン名）
 */
export class FunnelConditionPageTitleOrURL extends BaseFunnelCondition {
  constructor(
    public readonly conditionType: FunnelType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly value: string,
    public readonly notCondition: boolean,
    public readonly wordMatchMethod: MatchMethod,
    public readonly searchField: SearchField,
    public readonly edge: FunnelEdge
  ) {
    super(conditionType, startDate, endDate, notCondition, edge);
  }

  get typeName(): string {
    let result = "";

    if (this.conditionType === FunnelType.PV) {
      result = localI18n("webView");
    } else if (this.conditionType === FunnelType.Screen) {
      result = localI18n("appView");
    }

    return result;
  }

  get title(): string {
    return this.typeName;
  }

  get wordMatchString(): string {
    switch (this.wordMatchMethod) {
      case MatchMethod.Exact:
        return localI18n("perfectMatch");
      case MatchMethod.Prefix:
        return localI18n("prefixMatch");
      case MatchMethod.Postfix:
        return localI18n("postfixMatch");
      case MatchMethod.Wildcard:
        return localI18n("wildcardMatch");
      default:
        return localI18n("partialMatch");
    }
  }

  get prefix(): string {
    if (this.conditionType === FunnelType.Screen) {
      if (this.searchField === SearchField.URL) {
        return localI18n("screenURL") + ": ";
      } else {
        return localI18n("screenTitle") + ": ";
      }
    } else if (this.conditionType === FunnelType.PV) {
      if (this.searchField === SearchField.URL) {
        return localI18n("pageUrl") + ": ";
      } else {
        return localI18n("pageTitle") + ": ";
      }
    }
    return "";
  }

  get displayValueForHistory(): string {
    return this.value;
  }

  get displayValueForResult(): string[] {
    return [`${this.prefix}${this.value} (${this.wordMatchString})`];
  }

  toJson(useEdge = true): FunnelConditionJson {
    const params: FunnelConditionJson = {
      type: this.conditionType,
      start_date: postDate(this.startDate),
      end_date: postDate(this.endDate),
      value: this.value,
      not_condition: this.notCondition,
      word_match_method: this.wordMatchMethod,
      search_field: this.searchField
    };

    if (useEdge) {
      params["edge"] = this.edge.tojson();
    }

    return params;
  }

  static fromJson(json: FunnelConditionJson): FunnelConditionPageTitleOrURL {
    const startDate = new Date(json.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(json.end_date);
    endDate.setHours(23, 59, 59, 999);

    return new FunnelConditionPageTitleOrURL(
      json.type,
      startDate,
      endDate,
      String(json.value),
      json.not_condition,
      json.word_match_method !== undefined
        ? json.word_match_method
        : MatchMethod.Partial,
      json.search_field !== undefined ? json.search_field : SearchField.URL,
      json.edge && json.edge.type !== undefined
        ? new FunnelEdge(json.edge.type, json.edge.transition_type)
        : FunnelEdge.defaultCondition()
    );
  }

  validate(canUseLongTerm = false, usePrefix = true): ValidationResult {
    const errorMessages: string[] = [];
    const maxLengthValue = 256;

    if (this.value === "") {
      errorMessages.push(localI18n("validateValue"));
    }
    if (
      this.wordMatchMethod === MatchMethod.Wildcard &&
      this.value.match(/^[*]+$/)
    ) {
      errorMessages.push(localI18n("validateValueWildcard"));
    }
    if (this.value.length > maxLengthValue) {
      errorMessages.push(
        localI18n("maxCharacters", {
          maxLength: String(maxLengthValue)
        })
      );
    }

    const periodValidation = validatePeriod(
      canUseLongTerm,
      this.numberOfPeriodDays
    );
    if (!periodValidation.isValid) {
      errorMessages.push(periodValidation.errorMessage);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: usePrefix
          ? errorMessages.map(msg => `${this.prefix}${msg}`).join("\n")
          : errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

export class FunnelConditionInflow extends BaseFunnelCondition {
  constructor(
    public readonly conditionType: FunnelType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly notCondition: boolean,
    public readonly matchConditions: InflowMatchCondition[],
    public readonly edge: FunnelEdge
  ) {
    super(conditionType, startDate, endDate, notCondition, edge);
  }

  get typeName(): string {
    return localI18n("inflow");
  }

  get title(): string {
    return `${localI18n("inflow")} (${localI18n("inflowPrefix")})`;
  }

  get displayValueForHistory(): string {
    const value = this.matchConditions
      .map(condition => condition.value)
      .join(", ");
    return `${localI18n("inflowPrefix")}: ${value}`;
  }

  get displayValueForResult(): string[] {
    return this.matchConditions.map(condition => {
      const { searchField, value, wordMatchMethod } = condition;
      return `${this.getPrefix(searchField)}${value} (${this.getWordMatchString(
        wordMatchMethod
      )})`;
    });
  }

  getPrefix(searchField: InflowSearchField): string {
    switch (searchField) {
      case InflowSearchField.LOCATION_REFERRER:
        return localI18n("locationReferrer") + ": ";
      case InflowSearchField.LOCATION_NAME:
        return localI18n("locationName") + ": ";
      case InflowSearchField.LOCATION_URI:
        return localI18n("locationUri") + ": ";
    }
  }

  getWordMatchString(wordMatchMethod: MatchMethod): string {
    switch (wordMatchMethod) {
      case MatchMethod.Exact:
        return localI18n("perfectMatch");
      case MatchMethod.Wildcard:
        return localI18n("wildcardMatch");
      default:
        return localI18n("partialMatch");
    }
  }

  toJson(useEdge = true): FunnelConditionJson {
    const matchConditions: FunnelInflowMatchConditionJson[] = this.matchConditions.map(
      matchCondition => ({
        value: matchCondition.value,
        word_match_method: matchCondition.wordMatchMethod,
        search_field: matchCondition.searchField
      })
    );

    const params: FunnelConditionJson = {
      type: this.conditionType,
      start_date: postDate(this.startDate),
      end_date: postDate(this.endDate),
      not_condition: this.notCondition,
      match_conditions: matchConditions
    };

    if (useEdge) {
      params["edge"] = this.edge.tojson();
    }

    return params;
  }

  static fromJson(json: FunnelConditionJson): FunnelConditionInflow {
    const startDate = new Date(json.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(json.end_date);
    endDate.setHours(23, 59, 59, 999);

    let matchConditions: InflowMatchCondition[] = [];

    if (json.match_conditions) {
      matchConditions = json.match_conditions.map(matchCondition => ({
        value: matchCondition.value,
        wordMatchMethod: matchCondition.word_match_method,
        searchField: matchCondition.search_field as InflowSearchField
      }));
    }

    return new FunnelConditionInflow(
      json.type,
      startDate,
      endDate,
      json.not_condition,
      matchConditions,
      json.edge && json.edge.type !== undefined
        ? new FunnelEdge(json.edge.type, json.edge.transition_type)
        : FunnelEdge.defaultCondition()
    );
  }

  validate(canUseLongTerm = false): ValidationResult {
    const errorMessages: string[] = [];
    const maxLengthValue = 256;

    this.matchConditions.forEach(condition => {
      if (condition.value === "") {
        errorMessages.push(
          `${this.getPrefix(condition.searchField)}${localI18n(
            "validateValue"
          )}`
        );
      }
      if (
        condition.wordMatchMethod === MatchMethod.Wildcard &&
        condition.value.match(/^[*]+$/)
      ) {
        errorMessages.push(
          `${this.getPrefix(condition.searchField)}${localI18n(
            "validateValueWildcard"
          )}`
        );
      }
      if (condition.value.length > maxLengthValue) {
        errorMessages.push(
          `${this.getPrefix(condition.searchField)}${localI18n(
            "maxCharacters",
            { maxLength: String(maxLengthValue) }
          )}`
        );
      }
    });

    const periodValidation = validatePeriod(
      canUseLongTerm,
      this.numberOfPeriodDays
    );
    if (!periodValidation.isValid) {
      errorMessages.push(periodValidation.errorMessage);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

export class FunnelConditionInflowParam extends BaseFunnelCondition {
  constructor(
    public readonly conditionType: FunnelType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly notCondition: boolean,
    public readonly inflowParam: string,
    public readonly edge: FunnelEdge
  ) {
    super(conditionType, startDate, endDate, notCondition, edge);
  }

  get typeName(): string {
    return localI18n("inflow");
  }

  get title(): string {
    return `${localI18n("inflow")} (${localI18n("inflowParameterPrefix")})`;
  }

  get displayValueForHistory(): string {
    return `${localI18n("inflowParameterPrefix")}: ${this.inflowParam}`;
  }

  get displayValueForResult(): string[] {
    return [`${this.prefix}${this.inflowParam}`];
  }

  get prefix(): string {
    return localI18n("inflowParameter") + ": ";
  }

  toJson(useEdge = true): FunnelConditionJson {
    const params: FunnelConditionJson = {
      type: FunnelType.InflowParam,
      start_date: postDate(this.startDate),
      end_date: postDate(this.endDate),
      not_condition: this.notCondition,
      inflow_param: this.inflowParam
    };

    if (useEdge) {
      params["edge"] = this.edge.tojson();
    }

    return params;
  }

  static fromJson(json: FunnelConditionJson): FunnelConditionInflowParam {
    const startDate = new Date(json.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(json.end_date);
    endDate.setHours(23, 59, 59, 999);

    return new FunnelConditionInflowParam(
      json.type,
      startDate,
      endDate,
      json.not_condition,
      json.inflow_param ?? "",
      json.edge && json.edge.type !== undefined
        ? new FunnelEdge(json.edge.type, json.edge.transition_type)
        : FunnelEdge.defaultCondition()
    );
  }

  validate(canUseLongTerm = false, usePrefix = true): ValidationResult {
    const errorMessages: string[] = [];
    const maxLengthValue = 100;

    if (this.inflowParam === "") {
      errorMessages.push(localI18n("validateValue"));
    }
    if (this.inflowParam.length > maxLengthValue) {
      errorMessages.push(
        `${localI18n("maxCharacters", { maxLength: String(maxLengthValue) })}`
      );
    }
    const periodValidation = validatePeriod(
      canUseLongTerm,
      this.numberOfPeriodDays
    );
    if (!periodValidation.isValid) {
      errorMessages.push(periodValidation.errorMessage);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: usePrefix
          ? errorMessages.map(msg => `${this.prefix}${msg}`).join("\n")
          : errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

export class FunnelConditionInflowOrganic extends BaseFunnelCondition {
  constructor(
    public readonly conditionType: FunnelType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly notCondition: boolean,
    private readonly searchEngines: SearchEngine[],
    public readonly matchConditions: InflowOrganicMatchCondition[],
    public readonly edge: FunnelEdge
  ) {
    super(conditionType, startDate, endDate, notCondition, edge);
  }

  get typeName(): string {
    return localI18n("inflow");
  }

  get title(): string {
    return `${localI18n("inflow")} (${localI18n("inflowOrganicPrefix")})`;
  }

  get searchEngineId(): number | null {
    const searchEngine = this.matchConditions.find(
      condition =>
        condition.searchField === InflowOrganicSearchField.SEARCH_ENGINE
    );
    return searchEngine
      ? (searchEngine as InflowOrganicSearchEngineCondition).searchEngineId
      : null;
  }

  get filteredMatchConditions(): InflowOrganicLocationCondition[] {
    return this.matchConditions.filter(
      condition =>
        condition.searchField !== InflowOrganicSearchField.SEARCH_ENGINE
    ) as InflowOrganicLocationCondition[];
  }

  get displayValueForHistory(): string {
    const searchEngine = this.searchEngines.find(
      e => e.id === this.searchEngineId
    );
    const searchEngineLabel = searchEngine ? [searchEngine.name] : [];

    const values = this.filteredMatchConditions.map(
      condition => condition.value
    );

    const labels = searchEngineLabel.concat(values);

    return labels.length > 0
      ? `${localI18n("inflowOrganicPrefix")}: ${labels.join(", ")}`
      : localI18n("inflowOrganicPrefix");
  }

  get displayValueForResult(): string[] {
    const searchEngine = this.searchEngines.find(
      e => e.id === this.searchEngineId
    );
    const searchEngineLabel = searchEngine
      ? [`${localI18n("searchEngine")}: ${searchEngine.name}`]
      : [];

    const matchConditionLabels = this.filteredMatchConditions.map(condition => {
      const { searchField, value, wordMatchMethod } = condition;
      return `${this.getPrefix(searchField)}${value} (${this.getWordMatchString(
        wordMatchMethod
      )})`;
    });

    return searchEngineLabel.concat(matchConditionLabels);
  }

  getPrefix(searchField: InflowOrganicSearchField): string {
    switch (searchField) {
      case InflowOrganicSearchField.LOCATION_NAME:
        return localI18n("locationName") + ": ";
      case InflowOrganicSearchField.LOCATION_URI:
        return localI18n("locationUri") + ": ";
      case InflowOrganicSearchField.SEARCH_ENGINE:
        return localI18n("searchEngine") + ": ";
    }
  }

  getWordMatchString(wordMatchMethod?: MatchMethod): string {
    switch (wordMatchMethod) {
      case MatchMethod.Exact:
        return localI18n("perfectMatch");
      case MatchMethod.Wildcard:
        return localI18n("wildcardMatch");
      default:
        return localI18n("partialMatch");
    }
  }

  toJson(useEdge = true): FunnelConditionJson {
    const matchConditions: FunnelInflowMatchConditionJson[] = this.filteredMatchConditions.map(
      condition => ({
        value: condition.value,
        word_match_method: condition.wordMatchMethod,
        search_field: condition.searchField
      })
    );

    const params: FunnelConditionJson = {
      type: this.conditionType,
      start_date: postDate(this.startDate),
      end_date: postDate(this.endDate),
      not_condition: this.notCondition,
      match_conditions: matchConditions
    };

    if (this.searchEngineId) {
      params["search_engine_id"] = this.searchEngineId;
    }

    if (useEdge) {
      params["edge"] = this.edge.tojson();
    }

    return params;
  }

  static fromJson(
    json: FunnelConditionJson,
    searchEngines: SearchEngine[]
  ): FunnelConditionInflowOrganic {
    const startDate = new Date(json.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(json.end_date);
    endDate.setHours(23, 59, 59, 999);

    let matchConditions: InflowOrganicMatchCondition[] = [];

    if (json.match_conditions) {
      matchConditions = json.match_conditions.map(matchCondition => ({
        value: matchCondition.value,
        wordMatchMethod: matchCondition.word_match_method,
        searchField: matchCondition.search_field
      })) as InflowOrganicLocationCondition[];
    }
    if (json.search_engine_id) {
      matchConditions = [
        {
          searchEngineId: json.search_engine_id,
          searchField: InflowOrganicSearchField.SEARCH_ENGINE
        },
        ...matchConditions
      ];
    }

    return new FunnelConditionInflowOrganic(
      json.type,
      startDate,
      endDate,
      json.not_condition,
      searchEngines,
      matchConditions,
      json.edge && json.edge.type !== undefined
        ? new FunnelEdge(json.edge.type, json.edge.transition_type)
        : FunnelEdge.defaultCondition()
    );
  }

  validate(canUseLongTerm = false): ValidationResult {
    const errorMessages: string[] = [];
    const maxLengthValue = 256;

    this.filteredMatchConditions.forEach(condition => {
      if (condition.value === "") {
        errorMessages.push(
          `${this.getPrefix(condition.searchField)}${localI18n(
            "validateValue"
          )}`
        );
      }
      if (
        condition.wordMatchMethod === MatchMethod.Wildcard &&
        condition.value.match(/^[*]+$/)
      ) {
        errorMessages.push(
          `${this.getPrefix(condition.searchField)}${localI18n(
            "validateValueWildcard"
          )}`
        );
      }
      if (condition.value.length > maxLengthValue) {
        errorMessages.push(
          `${this.getPrefix(condition.searchField)}${localI18n(
            "maxCharacters",
            { maxLength: String(maxLengthValue) }
          )}`
        );
      }
    });

    const periodValidation = validatePeriod(
      canUseLongTerm,
      this.numberOfPeriodDays
    );
    if (!periodValidation.isValid) {
      errorMessages.push(periodValidation.errorMessage);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

function validatePeriod(
  canUseLongTerm: boolean,
  numberOfPeriodDays: number
): ValidationResult {
  const maxPeriodDays = canUseLongTerm ? SIX_MONTHS_DAYS : MAX_PERIOD_DAYS;
  if (numberOfPeriodDays < maxPeriodDays) return { isValid: true };

  return {
    isValid: false,
    errorMessage: localI18n("validateMaxPeriod", {
      dateRange: Math.floor(maxPeriodDays / 30)
    })
  };
}

function localI18n(key: string, param = {}): string {
  return i18n.t(`models.funnel.funnelCondition.${key}`, param) as string;
}

export function convertFunnelConditionJsonToModel(
  json: FunnelConditionJson,
  searchEngines: SearchEngine[] = []
) {
  switch (json.type) {
    case FunnelType.PV:
    case FunnelType.Screen:
      return FunnelConditionPageTitleOrURL.fromJson(json);
    case FunnelType.CV:
    case FunnelType.Event:
    case FunnelType.BusinessEvent:
    case FunnelType.Contact:
      return FunnelConditionDefault.fromJson(json);
    case FunnelType.Inflow:
      return FunnelConditionInflow.fromJson(json);
    case FunnelType.InflowParam:
      return FunnelConditionInflowParam.fromJson(json);
    case FunnelType.InflowOrganic:
      return FunnelConditionInflowOrganic.fromJson(json, searchEngines);
    default:
      const exhaustiveCheck: never = json.type;
      return exhaustiveCheck;
  }
}
