<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-conversion-attribute-form">
    <SelectBox
      v-model="conversionAttributeDefinitionId"
      :options="filteredOptions"
      style="margin-right: 7px"
      width="220px"
    />
    <FilterBaseInputText
      v-model="value"
      :prefix="
        $te('conversionAttributePrefix') ? $t('conversionAttributePrefix') : ''
      "
      width="200px"
      style="margin-right: 7px"
    />
    <SelectBox
      v-model="matchMethodValue"
      :options="matchMethodOptions"
      width="120px"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import { FilterConversionAttributeCondition } from "@/models/search/filter-node-condition/FilterConversionAttributeCondition";
import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import { matchPatternOptions } from "@/components/search/matchPatternOptions";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    SelectBox,
    FilterBaseInputText
  }
})
export default class FilterConversionAttributeField extends Vue {
  @Model("input", { type: FilterConversionAttributeCondition, required: true })
  condition!: FilterConversionAttributeCondition;

  @Prop({ type: Array, required: true })
  options!: SelectOption[];

  onInput(condition: FilterConversionAttributeCondition) {
    this.$emit("input", condition);
  }

  get filteredOptions(): SelectOption[] {
    return this.options.map(op => {
      const isOptionSelected =
        op.value === this.conversionAttributeDefinitionId;

      return {
        label: op.label,
        value: op.value,
        disabled: isOptionSelected ? false : op.disabled
      };
    });
  }

  get conversionAttributeDefinitionId(): number {
    return this.condition.conversionAttributeDefinitionId;
  }
  set conversionAttributeDefinitionId(conversionAttributeDefinitionId: number) {
    this.onInput(
      new FilterConversionAttributeCondition(
        conversionAttributeDefinitionId,
        this.condition.value,
        this.condition.matchMethod
      )
    );
  }

  get value(): string {
    return this.condition.value;
  }
  set value(value: string) {
    this.onInput(
      new FilterConversionAttributeCondition(
        this.condition.conversionAttributeDefinitionId,
        value,
        this.condition.matchMethod
      )
    );
  }

  get matchMethodOptions(): SelectOption[] {
    return matchPatternOptions();
  }

  get matchMethodValue(): MatchMethod {
    return this.condition.matchMethod;
  }
  set matchMethodValue(matchMethodValue: MatchMethod) {
    this.onInput(
      new FilterConversionAttributeCondition(
        this.condition.conversionAttributeDefinitionId,
        this.condition.value,
        matchMethodValue
      )
    );
  }
}
</script>
<style lang="scss" scoped>
.filter-conversion-attribute-form {
  display: flex;
  align-items: center;
}
</style>
