import { Colors } from "@/const/Colors";

type StyleType = "DotOptions" | "StepStyle" | "RailStyle" | "ProcessStyle";

export const RangeUiStyleType: Record<StyleType, Object> = {
  DotOptions: {
    style: {
      "background-color": Colors.White,
      border: "5px solid" + Colors.Cv,
      height: "15px",
      width: "15px",
      "box-shadow": "none"
    }
  },
  StepStyle: {
    "background-color": Colors.Base700,
    height: "3px",
    width: "1px",
    "margin-top": "9.5px",
    "border-radius": "0"
  },
  RailStyle: {
    "background-color": Colors.White,
    border: "1px solid" + Colors.Base600,
    height: "5px"
  },
  ProcessStyle: {
    "background-color": Colors.Cv,
    "box-shadow": "0 0 0 1px" + Colors.Cv
  }
};
