import { render, staticRenderFns } from "./DateRangePickerInput.vue?vue&type=template&id=24c00520&scoped=true"
import script from "./DateRangePickerInput.vue?vue&type=script&lang=ts"
export * from "./DateRangePickerInput.vue?vue&type=script&lang=ts"
import style0 from "./DateRangePickerInput.vue?vue&type=style&index=0&id=24c00520&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c00520",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/date-picker/date-range-picker-input.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fdate-picker%2FDateRangePickerInput.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports