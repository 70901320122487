<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div class="featuredUsersError">
    <div>
      <NoResultImage />
      <p class="featuredUsersError__text">{{ $t("errorText") }}</p>
      <Button
        v-t="'errorTextButton'"
        class="featuredUsersError__button"
        type="light"
        height="24px"
        width="80px"
        @click="onClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import NoResultImage from "@/assets/svg/lag-cv/lag_cv_error_face.svg";
import Button from "@/components/Button.vue";

@Component({
  components: {
    NoResultImage,
    Button
  }
})
export default class FeaturedUsersError extends Vue {
  @Emit("retry-fetch")
  onClick() {}
}
</script>

<style lang="scss" scoped>
.featuredUsersError {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.featuredUsersError__button {
  border: 1px solid $colorBase500 !important;
  color: $colorBase700 !important;
  font-size: 14px;
  font-weight: normal;
}
.featuredUsersError__text {
  color: $colorBase700;
  font-size: 14px;
  line-height: 1.5;
  padding: 30px 0px;
  white-space: pre-wrap;
}
</style>
