import {
  AdditionalSelectConditionInterface,
  stringListValue
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  AttributeConditionParamType,
  BusinessEventAttributeConditionParams,
  AdditionalConditionParams
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * Conditions by business event attribute
 *
 * In addition to normal search conditions, selection conditions for business event attribute can be added
 *
 * Specify a character string and select the one that the specified business event partially or completely matches.
 * Multiple character strings can be specified
 */

export class BusinessEventAttributeCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    // Business event definition ID
    public readonly id: number,
    // Search character string
    public readonly values: string[],
    // Exact match or partial match
    public readonly matchPattern: MatchMethod
  ) {}

  /**
   * Display title
   * @param definitions
   */
  displayTitle(definitions: BusinessEventDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? ":" + def.name : "";

    return (i18n.t("models.search.businessEvent") as string) + defName;
  }

  /**
   * Returns the set value for display.
   */
  displayValue(): string {
    return stringListValue(this.values, this.matchPattern);
  }

  /**
   * Returns whether the specified ID is the same as businessEventDefinitionId
   * Since the id name is different for each business event, give a common function
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * Search condition -> JSON
 */
export function convertBusinessEventAttributeConditionToJson(
  condition: BusinessEventAttributeCondition
): BusinessEventAttributeConditionParams {
  return {
    type: AttributeConditionParamType.BusinessEventAttribute,
    id: condition.id,
    values: condition.values,
    word_match_method: condition.matchPattern
  };
}

/**
 * JSON-> search condition
 */
export function convertJsonToBusinessEventAttributeCondition(
  json: BusinessEventAttributeConditionParams,
  activeBusinessEventDefinitions: BusinessEventDefinition[]
): BusinessEventAttributeCondition | null {
  // null if not in the available id list
  if (!activeBusinessEventDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  return new BusinessEventAttributeCondition(
    json.id,
    json.values,
    json.word_match_method
  );
}

/**
 * Determine if the additional condition JSON is a business event condition
 */
export function isBusinessEventAttributeConditionParams(
  params: AdditionalConditionParams
): params is BusinessEventAttributeConditionParams {
  return params.type === AttributeConditionParamType.BusinessEventAttribute;
}
