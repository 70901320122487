import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";
import { AppConversionDefinition } from "@/models/client-settings/AppConversionDefinition";

export interface AppConversionResponse {
  id: number;
  coordination_id: number;
  conversion_id: number;
  parameter: string;
  is_disabled: boolean;
}

export interface MapConversionAndCvRequest {
  conversion_name: string;
  parameters: string[];
}

export interface MapConversionRequest {
  parameters: string[];
}

export default class ApiAppConversion {
  constructor(private readonly httpClient: HttpClient) {}

  public getList = async (): Promise<AppConversionDefinition[]> => {
    const url = ApiUrl.APP_CONVERSION;

    const response = await this.httpClient.get<AppConversionResponse[]>(url);

    return response.map(this.toModel);
  };

  public registerCvAndMapConversion = async (
    params: MapConversionAndCvRequest,
    coordinationId: number
  ): Promise<AppConversionDefinition[]> => {
    const url = ApiUrl.MAP_CONVERSION_AND_CV_REGISTER(coordinationId);

    const response = await this.httpClient.post<AppConversionResponse[]>(
      url,
      params
    );

    return response.map(this.toModel);
  };

  public registerMapConversion = async (
    params: MapConversionRequest,
    coordinationId: number,
    conversionId: number
  ): Promise<AppConversionDefinition[]> => {
    const url = ApiUrl.MAP_CONVERSION_REGISTER(coordinationId, conversionId);

    const response = await this.httpClient.post<AppConversionResponse[]>(
      url,
      params
    );

    return response.map(this.toModel);
  };

  private toModel(json: AppConversionResponse) {
    return new AppConversionDefinition(
      json.id,
      json.coordination_id,
      json.conversion_id,
      json.parameter,
      json.is_disabled
    );
  }
}
