<i18n
  src="@/i18n/components/search/engagement-form/rate-of-decrease-dialog.json"
></i18n>
<template>
  <Dialog
    class="dateOfDecreaseDialog"
    :visible="visible"
    :show-close-button="false"
    :title="title"
    width="600px"
    top="15vh"
    @opened="onOpened"
    @close="onClose"
  >
    <div class="dateOfDecreaseDialog__container">
      <div class="dateOfDecreaseDialog__row">
        <div class="dateOfDecreaseDialog__head">
          <RadioButton
            v-model="specificationValue"
            :label="specificationLabels[0]"
            name="specification"
          >
            <span>{{ $t("valueSpecification") }}</span>
          </RadioButton>
        </div>

        <div class="dateOfDecreaseDialog__body">
          <div
            v-if="showText(percentageOrLessItemPrefix)"
            class="dateOfDecreaseDialog__prefix"
          >
            {{ percentageOrLessItemPrefix }}
          </div>
          <InputNumber
            v-model="valueSpecificationValue"
            :min="directMinValue"
            :max="directMaxValue"
            class="dateOfDecreaseDialog__input"
          />
          <div
            v-if="showText(percentageOrLessItemSuffix)"
            class="dateOfDecreaseDialog__suffix"
          >
            {{ percentageOrLessItemSuffix }}
          </div>
        </div>
      </div>

      <div class="dateOfDecreaseDialog__row">
        <div class="dateOfDecreaseDialog__head">
          <RadioButton
            v-model="specificationValue"
            :label="specificationLabels[1]"
            name="specification"
          >
            <span v-t="'rangeSpecification'" />
          </RadioButton>
        </div>

        <div class="dateOfDecreaseDialog__body">
          <div
            v-if="showText(percentageItemPrefix)"
            class="dateOfDecreaseDialog__prefix"
          >
            {{ percentageItemPrefix }}
          </div>
          <InputNumber
            v-model="rangeSpecificationMinValue"
            :min="directMinValue"
            :max="directMaxValue"
            class="dateOfDecreaseDialog__input"
          />
          <div class="dateOfDecreaseDialog__text" v-text="'-'" />
          <InputNumber
            v-model="rangeSpecificationMaxValue"
            :min="directMinValue"
            :max="directMaxValue"
            class="dateOfDecreaseDialog__input"
          />
          <div class="dateOfDecreaseDialog__suffix">
            {{ percentageItemSuffix }}
          </div>

          <Tooltip class="dateOfDecreaseDialog__tooltip">
            <Icon :icon="icon" :color="color" :size="12" />

            <template #content>
              <div class="dateOfDecreaseDialog__tooltipContent">
                {{ $t("tooltipTitle") }}<br />
                {{ $t("tooltipContent1") }}

                <div class="dateOfDecreaseDialog__example">
                  {{ $t("tooltipContent2") }}<br />
                  {{ $t("tooltipContent3") }}
                </div>
              </div>
            </template>
          </Tooltip>
        </div>
      </div>

      <div v-t="'note'" class="dateOfDecreaseDialog__note" />
    </div>

    <div class="dateOfDecreaseDialog__buttonContaineer">
      <Button v-t="'submit'" width="250px" @click="onSubmit" />
      <Button
        v-t="'cancel'"
        width="250px"
        class="dateOfDecreaseDialog__canselButton"
        type="light"
        @click="onClose"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {
  FluctuationType,
  FluctuationValue,
  FluctuationValues,
  FLUCTUATION_MIN_VALUES,
  SPECIFICATION_LABELS,
  UNSPECIFIED_FLUCTUATION_VALUE,
  DIRECT_FLUCTUATION_VALUE,
  DIRECT_FLUCTUATION_MAX_VALUE,
  DIRECT_FLUCTUATION_MIN_VALUE
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import {
  getInitialValue,
  getPercentOfDecrease
} from "@/util/select-by-engagement-util";
import { showAlert } from "@/util/modal-util";
import RadioButton from "@/components/form/RadioButton.vue";
import InputNumber from "@/components/form/InputNumber.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import Button from "@/components/Button.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    RadioButton,
    InputNumber,
    Dialog,
    Button,
    Tooltip,
    Icon
  }
})
export default class RateOfDecreaseDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  visible!: boolean;

  @Prop({ type: Object, required: true })
  value!: FluctuationValues;

  @Emit("opened")
  onOpened() {
    this.updateValue = this.value;

    const type = this.value.type;

    if (type === FluctuationType.Decrease) {
      const maxFluctuationValue = this.value.max;
      const minFluctuationValue = this.value.min;
      const { min, max } = getInitialValue(
        minFluctuationValue,
        maxFluctuationValue,
        FLUCTUATION_MIN_VALUES
      );

      if (max === null) {
        this.specificationValue = this.specificationLabels[0];
        this.valueSpecificationValue = getPercentOfDecrease(min);
      } else {
        this.specificationValue = this.specificationLabels[1];

        const minValue = getPercentOfDecrease(min);
        const maxValue = getPercentOfDecrease(max);

        this.rangeSpecificationMinValue = minValue;
        this.rangeSpecificationMaxValue = maxValue;
      }
    }
  }

  @Emit("close")
  onClose() {}

  onInput(values: FluctuationValues) {
    this.$emit("input", values);
  }

  updateValue = this.value;
  directMaxValue = DIRECT_FLUCTUATION_MAX_VALUE;
  directMinValue = DIRECT_FLUCTUATION_MIN_VALUE;

  valueSpecificationValue = this.directMinValue;
  rangeSpecificationMaxValue = this.directMinValue;
  rangeSpecificationMinValue = this.directMaxValue;

  specificationLabels = SPECIFICATION_LABELS;
  specificationValue = this.specificationLabels[0];

  icon = Icons.HelpInvertWhite;
  color = Colors.Base700;

  get title(): string {
    return this.$t("title") as string;
  }

  get percentageOrLessItemPrefix(): string {
    return this.$te("percentageOrLessItemPrefix")
      ? (this.$t("percentageOrLessItemPrefix") as string)
      : "";
  }

  get percentageOrLessItemSuffix(): string {
    return this.$t("percentageOrLessItemSuffix") as string;
  }

  get percentageItemPrefix(): string {
    return this.$te("percentageItemPrefix")
      ? (this.$t("percentageItemPrefix") as string)
      : "";
  }

  get percentageItemSuffix(): string {
    return this.$t("percentageItemSuffix") as string;
  }

  showText(value: string): boolean {
    return value.length > 0;
  }

  onSubmit() {
    if (this.specificationValue === this.specificationLabels[0]) {
      const min = new FluctuationValue(
        DIRECT_FLUCTUATION_VALUE,
        getPercentOfDecrease(this.valueSpecificationValue)
      );

      const max = new FluctuationValue(UNSPECIFIED_FLUCTUATION_VALUE, 0);
      const result: FluctuationValues = {
        min,
        max,
        type: FluctuationType.Decrease
      };

      this.onInput(result);
    } else {
      const minPercent = getPercentOfDecrease(this.rangeSpecificationMinValue);
      const maxPercent = getPercentOfDecrease(this.rangeSpecificationMaxValue);

      if (minPercent >= maxPercent) {
        showAlert(this.$t("errorHigherLowerUpper") as string);
        return;
      }

      const min = new FluctuationValue(DIRECT_FLUCTUATION_VALUE, minPercent);
      const max = new FluctuationValue(DIRECT_FLUCTUATION_VALUE, maxPercent);

      const result: FluctuationValues = {
        min,
        max,
        type: FluctuationType.Decrease
      };

      this.onInput(result);
    }
  }
}
</script>

<style scoped lang="scss">
.dateOfDecreaseDialog__row {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.dateOfDecreaseDialog__head {
  min-width: 135px;
}

.dateOfDecreaseDialog__body {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 8px;
}

.dateOfDecreaseDialog__input {
  width: 60px;
}

.dateOfDecreaseDialog__prefix {
  margin-right: 8px;
}

.dateOfDecreaseDialog__suffix {
  margin-left: 8px;
}

.dateOfDecreaseDialog__text {
  margin: 0 8px;
}

.dateOfDecreaseDialog__tooltip {
  margin-left: 8px;
}

.dateOfDecreaseDialog__tooltipContent {
  line-height: 1.5;
}

.dateOfDecreaseDialog__example {
  margin-top: 10px;
}

.dateOfDecreaseDialog__note {
  margin-top: 25px;
  text-align: center;
}

.dateOfDecreaseDialog__buttonContaineer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.dateOfDecreaseDialog__canselButton {
  margin-left: 15px;
}
</style>
