import { FilterNodeParam } from "@/api/apis/ApiSearch";

const startPoint = 0;
// This endpoint value consider as unlimited when send to BE
export const endPoint = 999999;

// Data in second
export const stayTimeRangeStepInSec = [
  startPoint,
  1,
  2,
  3,
  4,
  5,
  10,
  20,
  30,
  40,
  50,
  60,
  120,
  180,
  240,
  300,
  600,
  1200,
  1800,
  endPoint
];

export class FilterStayTimeCondition {
  constructor(
    public readonly isStayTimeChecked: boolean = false,
    public readonly stayTimeValueGreaterThanEqual: number = startPoint,
    public readonly stayTimeValueLessThan: number = endPoint
  ) {}
}

export function convertJsonValueToStayTime(
  jsonValue: FilterNodeParam
): FilterStayTimeCondition {
  if (jsonValue.stay_time_in_sec_lt) {
    return new FilterStayTimeCondition(
      true,
      jsonValue.stay_time_in_sec_gte,
      jsonValue.stay_time_in_sec_lt!
    );
  }

  return new FilterStayTimeCondition(false);
}
