import { render, staticRenderFns } from "./UserDetailBalloon.vue?vue&type=template&id=c2be8794&scoped=true"
import script from "./UserDetailBalloon.vue?vue&type=script&lang=ts"
export * from "./UserDetailBalloon.vue?vue&type=script&lang=ts"
import style0 from "./UserDetailBalloon.vue?vue&type=style&index=0&id=c2be8794&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2be8794",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/observation/user-header/user-detail-balloon.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fobservation%2Fuser-header%2FUserDetailBalloon.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports