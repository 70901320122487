import { i18n } from "@/i18n";
import SelectOption from "@/components/form/SelectOption";
import { MatchMethod } from "@/models/search/MatchMethod";

export const matchMethodOptions = (only?: MatchMethod[]): SelectOption[] => {
  const options = [
    {
      value: MatchMethod.Partial,
      label: i18n.t("util.selectUtil.partialMatch").toString(),
      disabled: false
    },
    {
      value: MatchMethod.Exact,
      label: i18n.t("util.selectUtil.perfectMatch").toString(),
      disabled: false
    },
    {
      value: MatchMethod.Prefix,
      label: i18n.t("util.selectUtil.prefixMatch").toString(),
      disabled: false
    },
    {
      value: MatchMethod.Postfix,
      label: i18n.t("util.selectUtil.postfixMatch").toString(),
      disabled: false
    },
    {
      value: MatchMethod.Wildcard,
      label: i18n.t("util.selectUtil.wildcardMatch").toString(),
      disabled: false
    }
  ];

  return only ? options.filter(option => only.includes(option.value)) : options;
};
