<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="select-by-conversion-form">
    <div class="title">
      <TitleTextWithIcon :title="$t('period')" :icon="iconHistoy" />
    </div>
    <div class="period">
      <DateRangePickerInput
        v-model="dateValue"
        :enabled-period="enabledPeriod"
      />
      <SelectBox
        v-model="hourValue"
        data-cy="select-box-hour"
        class="hour"
        :options="hourOptions"
      />
    </div>
    <div class="title">
      <TitleTextWithIcon :title="$t('conversion')" :icon="iconConversion" />
    </div>
    <div class="all-check-on-off">
      <div
        v-t="'selectAll'"
        class="all-check-on-off-item"
        @click="checkAllConversion"
      />
      <div
        v-t="'deselectAll'"
        class="all-check-on-off-item"
        @click="removeAllConversion"
      />
    </div>
    <CheckBoxList
      v-model="conversionValues"
      data-cy="select-by-conversion-checkboxes"
      :column="3"
      :options="conversionOptions"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import {
  hourlyIntervalsSelectOpitions,
  HOURLY_INTERVALS_DEFAULT_VALUE
} from "@/util/date-util";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import { DateRange } from "@/components/date-picker/DateRange";
import { enabledPeriodRange } from "@/util/date-range-picker-util";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import CheckBoxList from "@/components/form/CheckBoxList.vue";
import SelectOption from "@/components/form/SelectOption";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    DateRangePickerInput,
    TitleTextWithIcon,
    SelectBox,
    CheckBoxList
  }
})
export default class SelectByConversionForm extends Vue {
  @Prop({ type: Array, required: true })
  conversionDefinitions!: ConversionDefinition[];

  @Model("input", { type: SelectByConversionCondition, required: true })
  condition!: SelectByConversionCondition;

  onUpdate(condition: SelectByConversionCondition) {
    this.$emit("input", condition);
  }

  iconConversion = Icons.Conversion;
  iconHistoy = Icons.History;

  get enabledPeriod(): DateRange {
    return enabledPeriodRange();
  }

  // 時間帯Selectのオプション
  get hourOptions(): SelectOption[] {
    return hourlyIntervalsSelectOpitions();
  }

  // コンバージョンのcheckboxListのオプション
  get conversionOptions(): SelectOption[] {
    return this.conversionDefinitions.map(cd => {
      return { value: cd.id, label: cd.name, disabled: false };
    });
  }

  // 日付の値
  get dateValue(): DateRange | null {
    if (this.condition.endDate !== null && this.condition.startDate !== null) {
      return {
        min: this.condition.startDate,
        max: this.condition.endDate
      };
    }

    return null;
  }
  set dateValue(dateRange: DateRange | null) {
    if (dateRange !== null) {
      const condition = new SelectByConversionCondition(
        this.condition.conversionDefinitionIds,
        dateRange.min,
        dateRange.max,
        this.condition.hour,
        this.condition.additionalConditions
      );
      this.onUpdate(condition);
    }
  }

  // 時間帯の値
  get hourValue(): number {
    // selectのvalueにnullは渡せないのでnullの場合はHOURLY_INTERVALS_DEFAULT_VALUEを渡す
    return this.condition.hour === null
      ? HOURLY_INTERVALS_DEFAULT_VALUE
      : this.condition.hour;
  }
  set hourValue(hour: number) {
    // HOURLY_INTERVALS_DEFAULT_VALUEだった場合はnullに戻す
    const setHour = hour === HOURLY_INTERVALS_DEFAULT_VALUE ? null : hour;
    const condition = new SelectByConversionCondition(
      this.condition.conversionDefinitionIds,
      this.condition.startDate,
      this.condition.endDate,
      setHour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  // コンバージョンの値
  get conversionValues(): number[] {
    return this.condition.conversionDefinitionIds;
  }
  set conversionValues(conversionDefinitionIds: number[]) {
    const condition = new SelectByConversionCondition(
      conversionDefinitionIds,
      this.condition.startDate,
      this.condition.endDate,
      this.condition.hour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  // SelectByConversionConditionのすべてのConversionにチェックを入れる
  checkAllConversion() {
    const condition = new SelectByConversionCondition(
      this.conversionDefinitions.map(def => def.id),
      this.condition.startDate,
      this.condition.endDate,
      this.condition.hour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  // SelectByConversionConditionのすべてのConversionのチェックを外す
  removeAllConversion() {
    const condition = new SelectByConversionCondition(
      [],
      this.condition.startDate,
      this.condition.endDate,
      this.condition.hour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }
}
</script>

<style scoped lang="scss">
.title {
  margin: 30px 0 10px 0;
  &:first-child {
    margin-top: 0;
  }
}
.period {
  position: relative;
  display: flex;
  height: $formPartsHeight;
}
.hour {
  margin-left: 20px;
}

.all-check-on-off {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.all-check-on-off-item {
  margin-right: 15px;
  color: $colorBase700;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
