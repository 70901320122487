<template>
  <tr class="analysisTableRow">
    <slot></slot>
  </tr>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AnalysisTableRow extends Vue {}
</script>

<style lang="scss" scoped>
.analysisTableRow {
  &:hover {
    background-color: $colorBlue500;
  }
}
</style>
