<i18n src="@/i18n/components/user/user-has-no-activity.json"></i18n>

<template>
  <div class="userHasNoActivity">
    <p
      v-t="'noUserActivity'"
      class="userHasNoActivity__text"
      data-cy="noUserActivity"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
@Component
export default class UserHasNoActivity extends Vue {}
</script>

<style lang="scss" scoped>
.userHasNoActivity {
  text-align: center;
  padding: 60px;
}
.userHasNoActivity__text {
  font-size: 16px;
  font-weight: bold;
  color: $colorBase900;
  margin-bottom: 42px;
}
</style>
