<template>
  <div class="input-number">
    <input
      ref="textForm"
      class="input-number-input"
      :class="{ 'input-number-input--error': hasError }"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :tabindex="tabindex"
      @blur="onInputStringValue($event.target)"
      @focus="onFocus"
      @keyup.enter="onEnter"
      @keypress="allowOnlyNumber($event)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";
import { isNumberText } from "@/util/string-util";
import InputText from "@/components/form/InputText.vue";

@Component({
  components: { InputText }
})
export default class InputNumber extends Vue {
  @Model("input", { type: Number, required: true })
  value!: number;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Number, default: 0 })
  tabindex!: number;

  @Prop({ type: String, default: "text" })
  type!: string;

  @Prop({ type: Boolean, default: false })
  hasError!: boolean;

  @Prop({ type: Number, default: Number.MAX_SAFE_INTEGER })
  max!: number;

  @Prop({ type: Number, default: -Infinity })
  min!: number;

  onInput(value: number) {
    this.$emit("input", value);
  }

  @Emit("focus")
  onFocus() {}

  @Emit("keyup-enter")
  onEnter() {}

  onInputStringValue(target: HTMLInputElement) {
    let num = Number(target.value);
    if (isNaN(num)) {
      num = 0;
      target.value = String(0);
    }

    if (num <= this.min) {
      num = this.min;
      target.value = String(this.min);
    }

    if (num >= this.max) {
      num = this.max;
      target.value = String(this.max);
    }
    this.onInput(num);
  }

  allowOnlyNumber(event: KeyboardEvent) {
    if (!isNumberText(event.key)) {
      event.preventDefault();
    }
  }
}
</script>

<style scoped lang="scss">
.input-number-input {
  padding: 0 8px;
  width: 100%;
  height: $formPartsHeight;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorText;
  font-size: $basicFontSize;
  /* IE11で中央にする */
  line-height: $formPartsHeight;

  &::placeholder {
    color: $colorBase700;
  }
}

.input-number-input:disabled {
  border: 1px solid $colorBase500;
  background-color: $colorBase300;
  color: $colorBase600;
  cursor: default;
}

.input-number-input--error {
  border-color: $colorError;
  color: $colorError;
}
</style>
