<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <ul>
    <li
      v-for="condition in conditions"
      :key="getKey(condition)"
      class="additional-condition-wrapper"
    >
      <div class="additional-condition-contents">
        <div class="additional-condition-title">{{ getTitle(condition) }}</div>
        <div class="additional-condition-value">{{ getValue(condition) }}</div>
      </div>
      <div class="additional-condition-edit">
        <Tooltip :text="$t('edit')">
          <IconButton
            class="additional-condition-edit-button"
            :icon="icons.Memo"
            :size="iconSize"
            :button-type="iconButtonType"
            @click="onClickEdit(condition)"
          />
        </Tooltip>
      </div>
      <div class="additional-condition-delete">
        <Tooltip :text="$t('delete')">
          <IconButton
            class="additional-condition-delete-button"
            :icon="icons.ButtonClose"
            :size="iconSize"
            :button-type="iconButtonType"
            @click="onClickDelete(condition)"
          />
        </Tooltip>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AdditionalSelectCondition } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ConversionAttributeTextCondition } from "@/models/search/additional-condition/ConversionAttributeTextCondition";
import { UserAttributeStringCondition } from "@/models/search/additional-condition/UserAttributeStringCondition";
import { UserAttributeNumberCondition } from "@/models/search/additional-condition/UserAttributeNumberCondition";
import { NpsCondition } from "@/models/search/additional-condition/NpsCondition";
import { EnqueteCondition } from "@/models/search/additional-condition/EnqueteCondition";
import { BusinessIndexCondition } from "@/models/search/additional-condition/BusinessIndexCondition";
import { BusinessEventAttributeCondition } from "@/models/search/additional-condition/BusinessEventAttributeCondition";
import { ContactAttributeCondition } from "@/models/search/additional-condition/ContactAttributeCondition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { ConversionAttributeCondition } from "@/models/search/additional-condition/ConversionAttributeCondition";

const ICON_SIZE: number = 18;

/**
 * 設定済みのAdditionalConditionを表示するコンポーネント
 */
@Component({
  components: {
    Icon,
    IconButton,
    Tooltip
  }
})
export default class AdditionalConditionList extends Vue {
  icons = Icons;
  iconSize = ICON_SIZE;
  iconButtonType = IconButtonType.Weak;

  @Prop({ type: Array, required: true })
  conditions!: AdditionalSelectCondition[];

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  npsDefinitions!: NpsDefinition[];

  @Prop({ type: Array, required: true })
  enqueteDefinitions!: EnqueteDefinition[];

  @Prop({ type: Array, required: true })
  businessIndexDefinitions!: BusinessIndexDefinition[];

  @Prop({ type: Array, required: true })
  userAttributeDefinitions!: UserAttributeDefinition[];

  @Prop({ type: Array, required: true })
  businessEventDefinitions!: BusinessEventDefinition[];

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  onClickDelete(condition: AdditionalSelectCondition) {
    this.$emit("click-delete", condition);
  }

  onClickEdit(condition: AdditionalSelectCondition) {
    this.$emit("click-edit", condition);
  }

  // AdditionalSelectConditionからループのためのキーを作成
  getKey(condition: AdditionalSelectCondition): string {
    if (condition instanceof ConversionAttributeTextCondition) {
      return "cv" + String(condition.id);
    }
    if (condition instanceof NpsCondition) {
      return "nps";
    }
    if (condition instanceof EnqueteCondition) {
      return "en" + String(condition.id);
    }
    if (
      condition instanceof UserAttributeStringCondition ||
      condition instanceof UserAttributeNumberCondition
    ) {
      return "ua" + String(condition.id);
    }
    if (condition instanceof BusinessIndexCondition) {
      return "bz" + String(condition.id);
    }
    if (condition instanceof BusinessEventAttributeCondition) {
      return "bze" + String(condition.id);
    }
    if (condition instanceof ContactAttributeCondition) {
      return "cn" + String(condition.id) + String(condition.fieldType);
    }

    return "";
  }

  getTitle(condition: AdditionalSelectCondition): string {
    if (condition instanceof ConversionAttributeCondition) {
      return condition.displayTitle(this.conversionAttributeDefinitions);
    }
    if (condition instanceof NpsCondition) {
      return condition.displayTitle(this.npsDefinitions);
    }
    if (condition instanceof EnqueteCondition) {
      return condition.displayTitle(this.enqueteDefinitions);
    }
    if (
      condition instanceof UserAttributeStringCondition ||
      condition instanceof UserAttributeNumberCondition
    ) {
      return condition.displayTitle(this.userAttributeDefinitions);
    }
    if (condition instanceof BusinessIndexCondition) {
      return condition.displayTitle(this.businessIndexDefinitions);
    }
    if (condition instanceof BusinessEventAttributeCondition) {
      return condition.displayTitle(this.businessEventDefinitions);
    }
    if (condition instanceof ContactAttributeCondition) {
      return condition.displayTitle(this.contactDefinitions);
    }

    return "";
  }

  getValue(condition: AdditionalSelectCondition): string {
    // ビジネス指標だけヶ月の表示、非表示判断のためにDefinitionsが必要
    if (condition instanceof BusinessIndexCondition) {
      return condition.displayValue(this.businessIndexDefinitions);
    }

    return condition.displayValue();
  }
}
</script>

<style scoped lang="scss">
.additional-condition-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px 20px;
  width: 100%;
  background: $colorBase300;
}

.additional-condition-contents {
  flex: 1;
}

.additional-condition-title {
  margin-bottom: 4px;
  white-space: normal;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
}

.additional-condition-value {
  color: $colorBase700;
  white-space: normal;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 1.5;
}

.additional-condition-delete-button {
  padding: 4px;
  cursor: pointer;
}
.additional-condition-edit {
  padding: 0 20px;
}

.additional-condition-edit-button {
  padding: 4px;
  cursor: pointer;
}
</style>
