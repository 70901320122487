import { ApiRes } from "@/api/api-res";
import { GazeLevel } from "@/const/user-icon";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { getGazeLevelFromPv } from "@/models/getIcons";

export class UserPvStats {
  constructor(
    private readonly pcWeb: number,
    private readonly spWeb: number,
    private readonly pcApp: number,
    private readonly spApp: number
  ) {}

  public get smartphonePv(): number {
    return this.spWeb + this.spApp;
  }

  public get pcPv(): number {
    return this.pcWeb + this.pcApp;
  }

  public get isPc(): boolean {
    return this.pcApp + this.pcWeb > this.spApp + this.spWeb;
  }

  public get isApplication(): boolean {
    return this.pcWeb + this.spWeb < this.pcApp + this.spApp;
  }

  public get allPv() {
    return this.pcWeb + this.spWeb + this.pcApp + this.spApp;
  }

  public gazeLevelFromPv(client: ClientPvStats): GazeLevel {
    return getGazeLevelFromPv(this.allPv, client);
  }

  public merge(pvStats2: UserPvStats): UserPvStats {
    return new UserPvStats(
      this.pcWeb + pvStats2.pcWeb,
      this.spWeb + pvStats2.spWeb,
      this.pcApp + pvStats2.pcApp,
      this.spApp + pvStats2.spApp
    );
  }

  public static fromPv = (pv: ApiRes.IconPv): UserPvStats => {
    return new UserPvStats(pv.pc_web, pv.sp_web, pv.pc_app, pv.sp_app);
  };
}
