<template>
  <button type="button" class="upmb-Menu" @click="onClick">
    <div class="upmb-Menu_Dot"></div>
    <div class="upmb-Menu_Dot"></div>
    <div class="upmb-Menu_Dot"></div>
  </button>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UgTableMenuButton extends Vue {
  onClick(event: MouseEvent): void {
    this.$emit("click", event);
  }
}
</script>

<style scoped lang="scss">
.upmb-Menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colorBlue800;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &_Dot {
    background-color: $colorWhite;
    border-radius: 50%;
    margin-right: 2px;
    margin-left: 2px;
    width: 3px;
    height: 3px;
  }

  &:hover {
    background-color: $colorBlue900;
  }
}
</style>
