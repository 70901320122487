import { render, staticRenderFns } from "./TdConversionUpdateMeasurementState.vue?vue&type=template&id=4f80e01c&scoped=true"
import script from "./TdConversionUpdateMeasurementState.vue?vue&type=script&lang=ts"
export * from "./TdConversionUpdateMeasurementState.vue?vue&type=script&lang=ts"
import style0 from "./TdConversionUpdateMeasurementState.vue?vue&type=style&index=0&id=4f80e01c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f80e01c",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/client-settings/td-conversion-change-measurement-state.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2Fclient-settings%2FTdConversionUpdateMeasurementState.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports