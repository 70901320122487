<i18n src="@/i18n/components/tours/tour-index-item.json"></i18n>
<template>
  <div class="tourIndexItem">
    <figure class="tourIndexItem__header">
      <img
        class="tourIndexItem__image"
        :src="tour.eyecatchImageUrl"
        :alt="tour.title"
      />
    </figure>

    <div class="tourIndexItem__body">
      <div class="tourIndexItem__title">{{ tour.title }}</div>

      <div v-if="false" class="tourIndexItem__targetCvs">
        {{ $t("targetCv") }}: {{ targetConversions }}
      </div>

      <div class="tourIndexItem__reason">{{ tour.reason }}</div>

      <RouterLink
        :to="tourDetailRoute"
        class="tourIndexItem__button"
        :class="{ 'tourIndexItem__button--disabled': disabled }"
      >
        {{ actionLabel }}
      </RouterLink>
      <div
        class="tourIndexItem__note"
        :class="{ 'tourIndexItem__note--disabled': disabled }"
        v-text="note"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { TourCondition } from "@/models/tour/TourCondition";

const estimatedTime = 10; // use constant value for 1st release

@Component
export default class TourIndexItem extends Vue {
  @Prop({ type: Object, required: true })
  tour!: TourCondition;

  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;

  get targetConversions(): string {
    const cvText: string = this.tour.conversions.join(", ");

    // Max 1 line with multi bite character & '...' at the end
    if (cvText.length < 21) {
      return cvText;
    } else {
      return cvText.substr(0, 20) + "...";
    }
  }

  get tourDetailRoute(): string {
    return `tours/${this.tour.id}`;
  }

  get actionLabel(): string {
    return this.disabled
      ? (this.$t("disabledAction") as string)
      : (this.$t("actionLabel") as string);
  }
  get note(): string {
    return this.disabled
      ? (this.$t("disabledNote") as string)
      : (this.$t("note", { minutes: estimatedTime }) as string);
  }
}
</script>

<style scoped lang="scss">
.tourIndexItem {
  display: flex;
  overflow: hidden; // to apply border radius to top image
  flex-direction: column;
  width: 296px;
  height: 423px;
  border-radius: $sizeRadius;
  background: $colorWhite;
}

.tourIndexItem__header {
  display: block;
  height: 148px;
}

.tourIndexItem__image {
  width: 100%;
  height: 100%;
}

.tourIndexItem__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 25px;
  padding-right: $appContentPadding;
  padding-left: $appContentPadding;
  height: 262px;
}

.tourIndexItem__title {
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

.tourIndexItem__targetCvs {
  margin-top: 10px;
  color: $colorBase700;
  font-weight: 400;
  font-size: 11px;
  line-height: 1;
}

.tourIndexItem__reason {
  margin-top: 16px;
  height: 72px;
  color: $colorBase700;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
}

.tourIndexItem__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 22px 30px 12px;
  height: 35px;
  border: 1px solid $colorBase700;
  border-radius: 5px;
  color: $colorBase900;
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;

  &--disabled {
    border-color: $colorGray200;
    background: $colorGray200;
    color: $colorBase600;
    pointer-events: none;
  }

  &:hover {
    background: $colorBlue600;
  }
}

.tourIndexItem__note {
  margin: 0 auto;
  color: $colorBase700;
  font-size: 12px;
  text-align: center;

  &--disabled {
    color: $colorError;
    font-size: 10px;
    line-height: 1.5;
  }
}
</style>
