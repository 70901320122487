import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { Client } from "@/models/Client";

export class ClientState {
  client: Client | null = null;
}

const mutations: MutationTree<ClientState> = {
  setClient(state, client: Client) {
    state.client = client;
  },
  initialize(state: ClientState) {
    state.client = null;
  }
};

const actions: ActionTree<ClientState, RootState> = {
  async fetchClient({ commit, rootState }) {
    const body = await rootState.api.clients.getClient();
    const client: Client = Client.fromJson(body.client);
    commit("setClient", client);
  }
};

export const client = {
  namespaced: true,
  state: new ClientState(),
  mutations: mutations,
  actions: actions
};
