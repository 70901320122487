import {
  ValueInputMethod,
  numberValue,
  AdditionalSelectConditionInterface
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import {
  AttributeConditionParamType,
  LoyaltyType,
  NpsConditionParams,
  AdditionalConditionParams
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * NPSのデータ入力方法
 * 高中低評価から選択する方法と数値を入力する方法がある
 */
export enum NpsInputMethod {
  // 高中低評価から選択
  Select = 1,

  // 数値入力
  Input = 2
}

/**
 * NPSの評価選択種類
 */
export enum NpsScoreCategory {
  // 低評価
  Low = 1,

  // 中評価
  Medium = 2,

  // 高評価
  High = 3
}

/**
 * 顧客ロイヤルティ指標による検索条件
 *
 * 内部的にはNPSと呼ばれており
 * 概念的にはRelational NPSを指している
 * @see https://www.bell24.co.jp/ja/ccwiki/cat1/npsnet-promoter-score.html
 *
 * OMO版限定の検索条件
 * 通常の検索条件に加えて、OMOでインポートしたR-NPSのスコアに対する選定条件を付与できる
 *
 * 範囲を指定して、指定したR-NPSのスコアがその範囲内にあるユーザのみを選定する
 */
export class NpsCondition implements AdditionalSelectConditionInterface {
  constructor(
    // NPS定義のID
    public readonly id: number,
    // 指定範囲の開始
    public readonly from: number,
    // 指定範囲の終了
    public readonly to: number,
    // NPSスコアの入力方法
    public readonly inputMethod: NpsInputMethod,
    // 評価選択で選択した場合の評価
    public readonly scoreType: NpsScoreCategory,
    // 値指定 or レンジ指定
    public readonly rangeType: ValueInputMethod
  ) {}

  /**
   * 表示用のタイトル
   * @param definitions
   */
  displayTitle(definitions: NpsDefinition[]): string {
    const npsName = 0 < definitions.length ? definitions[0].name : "";

    return (
      (((i18n.t("models.search.customerLoyaltyIndex") as string) +
        "：" +
        i18n.t("models.search.customerLoyaltyIndex")) as string) +
      "(" +
      npsName +
      ")"
    );
  }

  /**
   * 設定された値を表示用に加工して返す。
   */
  displayValue(): string {
    // NPSスコアの入力方法が評価選択式の場合
    if (this.inputMethod === NpsInputMethod.Select) {
      let val: string =
        (i18n.t("models.search.classification") as string) + " ";
      if (this.scoreType === NpsScoreCategory.Low) {
        val += i18n.t("models.search.lowEvaluation") as string;
      } else if (this.scoreType === NpsScoreCategory.Medium) {
        val += i18n.t("models.search.intermediateEvaluation") as string;
      } else if (this.scoreType === NpsScoreCategory.High) {
        val += i18n.t("models.search.highEvaluation") as string;
      }
      val += "(" + String(this.from) + "〜" + String(this.to) + ")";
      return val;
    }
    // NPSスコアの入力方法が数値入力式の場合
    return (
      (i18n.t("models.search.classification") as string) +
      " " +
      numberValue(this.from, this.to, this.rangeType)
    );
  }

  /**
   * 指定のIDがuserAttributeDefinitionIdと同じか返す
   * Conversionごとにid名が違うので共通の関数もたせる
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertNpsConditionToJson(
  condition: NpsCondition
): NpsConditionParams {
  return {
    type: AttributeConditionParamType.Loyalty,
    id: condition.id,
    npsType: LoyaltyType.Nps,
    totalCatScoreType: condition.inputMethod,
    totalCat:
      condition.inputMethod === NpsInputMethod.Select
        ? condition.scoreType
        : -1,
    valRangeType:
      condition.inputMethod === NpsInputMethod.Select
        ? -1
        : condition.rangeType,
    valFrom: condition.from,
    valTo: condition.to
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToNpsCondition(
  json: NpsConditionParams
): NpsCondition {
  return new NpsCondition(
    json.id,
    json.valFrom,
    json.valTo,
    json.totalCatScoreType,
    json.totalCat,
    json.valRangeType
  );
}

/**
 * 追加条件のJSONがNPSの条件かどうかを判定する
 */
export function isNpsConditionParams(
  params: AdditionalConditionParams
): params is NpsConditionParams {
  return (
    params.type === AttributeConditionParamType.Loyalty &&
    params.npsType === LoyaltyType.Nps
  );
}
