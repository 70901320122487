import { FunnelConditionActivityJson } from "@/api/apis/ApiFunnel";
import {
  FunnelCondition,
  convertFunnelConditionJsonToModel
} from "@/models/funnel/FunnelCondition";
import { SearchEngine } from "@/models/system/SearchEngine";

export enum ComparisonType {
  equalTo = 1,
  greaterThanOrEqualTo = 2,
  lessThanOrEqualTo = 3
}

export class FunnelConditionActivity {
  constructor(
    public readonly times: number,
    public readonly comparison: ComparisonType,
    public readonly condition: FunnelCondition
  ) {}

  toJson(): FunnelConditionActivityJson {
    return {
      times: this.times,
      comparison: this.comparison,
      condition: this.condition.toJson(false)
    };
  }

  static fromJson(
    json: FunnelConditionActivityJson,
    searchEngines: SearchEngine[]
  ): FunnelConditionActivity {
    const condition = convertFunnelConditionJsonToModel(
      json.condition,
      searchEngines
    );
    return new FunnelConditionActivity(json.times, json.comparison, condition);
  }
}
