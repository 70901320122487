<i18n src="@/i18n/components/search/search-result-helper.json"></i18n>

<template>
  <div class="searchResultHeader">
    <div
      class="searchResultHeader__titleContainer"
      data-cy="result-header-label"
    >
      <Icon :icon="iconTitle" :size="16" />
      <div
        class="searchResultHeader__title"
        :title="favoriteSearchLabel || title"
        >{{ favoriteSearchLabel || title }}</div
      >
    </div>

    <div
      v-if="$slots.selectCondition"
      class="searchResultHeader__selectCondition"
    >
      <div class="searchResultHeader__selectConditionLabelContainer">
        <slot name="selectCondition" />

        <button
          v-if="canSaveCondition"
          class="searchResultHeader__button"
          :class="[{ 'searchResultHeader__button--saved': isConditionSaved }]"
          data-cy="save-favorite-condition-button"
          @click="onClickSaveCondition"
        >
          <div
            v-t="
              isConditionSaved
                ? 'currentConditionIsSaved'
                : 'saveCurrentCondition'
            "
          />
          <Icon :icon="iconSave" :color="iconColor" :size="14" />
        </button>
      </div>

      <slot name="selectDetailedCondition" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";

@Component({
  components: {
    Icon
  }
})
export default class SearchResultHeader extends Vue {
  @Prop({ type: Boolean, default: false })
  canSaveCondition!: boolean;

  @Prop({ type: String, default: "" })
  favoriteSearchLabel!: string;

  @Prop({ type: String, required: true })
  iconTitle!: Icons;

  @Prop({ type: String, default: "" })
  title!: string;

  @Emit("click-save-condition")
  onEmitSaveCondition() {}

  onClickSaveCondition() {
    if (!this.isConditionSaved) {
      this.onEmitSaveCondition();
    }
  }

  iconSave = Icons.Save;

  get isConditionSaved(): boolean {
    return this.favoriteSearchLabel !== "";
  }

  get iconColor(): Colors {
    return this.isConditionSaved ? Colors.Base500 : Colors.Base700;
  }
}
</script>

<style lang="scss" scoped>
.searchResultHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 20px 6px;
  width: 100%;
}

.searchResultHeader__selectCondition {
  margin-top: 5px;
  padding: 0 40px;
}

.searchResultHeader__selectConditionLabelContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchResultHeader__titleContainer {
  display: flex;
  align-items: center;
  max-width: 700px;
  font-weight: bold;
  font-size: 18px;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.searchResultHeader__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: $colorBase700;
  margin-top: 7px;

  & > *:first-child {
    margin-right: 8px;

    &:hover {
      text-decoration: underline;
    }
  }

  &::before {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    height: 14px;
    background-color: $colorBase500;
    content: "";
  }
}

.searchResultHeader__button--saved {
  color: $colorBase500;
  cursor: default;

  & > *:first-child:hover {
    text-decoration: none;
  }
}

.searchResultHeader__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.3;
}

@media print {
  .searchResultHeader__button {
    display: none;
  }
}
</style>
