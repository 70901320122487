import { MutationTree, GetterTree, ActionTree } from "vuex";
import { i18n } from "@/i18n";
import { RootState } from "@/store/";
import { sleep } from "@/util/common-util";
import { TimeoutError } from "@/util/error-util";

import {
  ConversionTrendResultJson,
  ConversionTrendApiResponse,
  ConversionTrendStatus
} from "@/api/apis/ApiConversionTrend";
import { ConversionTrendResult } from "@/models/conversion-trend/ConversionTrendResult";
import {
  ConversionDefinition,
  GlobalConversionDefinition
} from "@/models/client-settings/ConversionDefinition";

// 再度APIを叩くまでの待機時間を2秒とする
const FETCH_INTERVAL_MSEC = 2000;
// 120秒(2秒 x 60回)で結果が返らない場合はタイムアウトとする
const TIMEOUT_COUNT = 60;

export class ConversionTrendState {
  currentId: string = "";
  status: ConversionTrendStatus = ConversionTrendStatus.NOT_STARTED;
  resultJson: ConversionTrendResultJson[] = [];
}

const mutations = <MutationTree<ConversionTrendState>>{
  setCurrentId(state: ConversionTrendState, id: string) {
    state.currentId = id;
  },
  setStatus(state: ConversionTrendState, status: ConversionTrendStatus) {
    state.status = status;
  },
  setResult(
    state: ConversionTrendState,
    resultJson: ConversionTrendResultJson[]
  ) {
    state.resultJson = resultJson;
  }
};

const getters = <GetterTree<ConversionTrendState, RootState>>{
  resultsPerCv(state, {}, {}, rootGetters): ConversionTrendResult[] {
    const normalConversionResult: ConversionTrendResult[] = [];
    const globalConversionResult: ConversionTrendResult[] = [];

    const globalConversionIds = rootGetters[
      "system/activeGlobalConversionDefinitions"
    ].map((c: GlobalConversionDefinition) => c.id);

    const allResult = state.resultJson
      .map(ConversionTrendResult.build)
      .sort((a, b) => a.conversionId - b.conversionId);

    allResult.forEach(r => {
      if (globalConversionIds.includes(r.conversionId)) {
        globalConversionResult.push(r);
      } else {
        normalConversionResult.push(r);
      }
    });

    return [...normalConversionResult, ...globalConversionResult];
  }
};

const actions = <ActionTree<ConversionTrendState, RootState>>{
  async create({ commit, rootState, rootGetters }) {
    commit("setStatus", ConversionTrendStatus.NOT_STARTED);

    const allActiveConversions: ConversionDefinition[] =
      rootGetters["clientSettings/allActiveConversionDefinitions"];

    if (allActiveConversions.length > 0) {
      const response: ConversionTrendApiResponse = await rootState.api.conversionTrend.createNew();

      commit("setCurrentId", response.id);
      commit("setStatus", response.status);
    }
  },
  async fetchResult({ state, commit, rootState }) {
    const currentId = state.currentId;

    if (currentId === "") {
      return;
    }

    let response: ConversionTrendApiResponse = await rootState.api.conversionTrend.getResult(
      currentId
    );

    let count = 0;
    while (response.status === ConversionTrendStatus.RUNNING) {
      // タイムアウト処理
      if (count > TIMEOUT_COUNT) {
        throw new TimeoutError(i18n.t("store.modules.apiTimeout") as string);
      }

      await sleep(FETCH_INTERVAL_MSEC);
      count++;

      response = await rootState.api.conversionTrend.getResult(currentId);
    }

    commit("setStatus", response.status);
    commit("setResult", response.result);
  }
};

export const conversionTrend = {
  namespaced: true,
  state: new ConversionTrendState(),
  mutations,
  getters,
  actions
};
