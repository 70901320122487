import { render, staticRenderFns } from "./SegmentedTrends.vue?vue&type=template&id=575933c0&scoped=true"
import script from "./SegmentedTrends.vue?vue&type=script&lang=ts"
export * from "./SegmentedTrends.vue?vue&type=script&lang=ts"
import style0 from "./SegmentedTrends.vue?vue&type=style&index=0&id=575933c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "575933c0",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/segmented-trends.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2FSegmentedTrends.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports