<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div class="featuredUsersNote">
    {{ description }}
    <HelpSiteLink
      v-if="isJapanese"
      class="featuredUsersNote__help"
      :url="url"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import HelpSiteLink from "@/components/home-menu/HelpSiteLink.vue";

@Component({
  components: {
    HelpSiteLink
  }
})
export default class FeaturedUsersNote extends Vue {
  @Prop({ type: String, required: true })
  description!: string;

  @Prop({ type: String, required: true })
  url!: string;

  get isJapanese(): boolean {
    return this.$i18n.locale === "ja";
  }
}
</script>

<style lang="scss" scoped>
.featuredUsersNote {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 16px;
}
.featuredUsersNote__help {
  display: inline;
}
</style>
