<i18n
  src="@/i18n/components/client-settings/coordination/conversion/coordination-conversion-confirm-table.json"
></i18n>
<template>
  <div class="conversionConfirmTable">
    <SettingsTable>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'targetData'"
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell width="480px">
          {{ coordinationConversion.targetData }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'conversion'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell>
          {{ conversionName }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'coordinationParameter'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell>
          <ul style="list-style-type: disc; margin-left: 15px;">
            <li v-for="param in coordinationConversion.path" :key="param">
              {{ param }}
            </li>
          </ul>
        </SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import {
  ConversionDefinition,
  CoordinationConversionEditableData
} from "@/models/client-settings/ConversionDefinition";
import { CreationMethod } from "@/models/client-settings/Coordination";

@Component({
  components: {
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell
  }
})
export default class CoordinationConversionConfirmTable extends Vue {
  @Prop({ type: Object, required: true })
  coordinationConversion!: CoordinationConversionEditableData;

  get activeConversions(): ConversionDefinition[] {
    return this.$store.getters["conversion/activeConversions"];
  }

  get conversionName() {
    if (this.coordinationConversion.creationMethod === CreationMethod.NEW)
      return this.coordinationConversion.conversionName;

    return this.activeConversions.find(
      conversion =>
        conversion.id === Number(this.coordinationConversion.conversionId)
    )?.name;
  }
}
</script>

<style lang="scss" scoped>
.conversionConfirmTable {
  margin-bottom: 16px;
  min-width: 680px;
}
</style>
