import { render, staticRenderFns } from "./DisplayMenu.vue?vue&type=template&id=2de54bf6&scoped=true"
import script from "./DisplayMenu.vue?vue&type=script&lang=ts"
export * from "./DisplayMenu.vue?vue&type=script&lang=ts"
import style0 from "./DisplayMenu.vue?vue&type=style&index=0&id=2de54bf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de54bf6",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/user-search-result.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2FDisplayMenu.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports