<i18n
  src="@/i18n/views/client-settings/conversion/conversion-settings-edit.json"
></i18n>
<template>
  <div class="conversionSettingsEdit">
    <div v-t="'title'" class="conversionSettingsEdit__title" />
    <div
      v-if="isConfirm"
      v-t="'message'"
      class="conversionSettingsEdit__message"
    />

    <template v-if="isLoading">
      <Loading height="80px" />
    </template>
    <template v-else>
      <ConversionConfirmTable
        v-if="isConfirm"
        :name="formData.name"
        :path="formData.path"
      />
      <ConversionEditTable
        v-else
        :name="formData.name"
        :path="formData.path"
        @update="onUpdate"
      />

      <div class="conversionSettingsEdit__buttonContainer">
        <Button
          v-t="isConfirm ? 'update' : 'confirm'"
          :class="
            isConfirm
              ? 'conversionSettingsEdit__submitButton'
              : 'conversionSettingsEdit__confirmButton'
          "
          :disabled="isSubmitButtonDisabled"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickConfirm"
        />

        <Button
          v-t="'cancel'"
          class="conversionSettingsEdit__cancelButton"
          type="light"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickCancel"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import ConversionConfirmTable from "@/components/client-settings/conversion/ConversionConfirmTable.vue";
import ConversionEditTable from "@/components/client-settings/conversion/ConversionEditTable.vue";
import Loading from "@/components/Loading.vue";
import { ButtonTextSize } from "@/const/button";
import {
  ConversionDefinition,
  ConversionEditableData,
  isValidConversionData
} from "@/models/client-settings/ConversionDefinition";
import { handleError, handleNotFoundError } from "@/util/error-util";

@Component({
  components: {
    Button,
    ConversionConfirmTable,
    ConversionEditTable,
    Loading
  }
})
export default class ConversionSettingsEdit extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isConfirm = false;
  isLoading = true;
  isSubmitting = false;

  formData: ConversionEditableData = { name: "", path: [""] };

  onUpdate(data: ConversionEditableData) {
    this.formData = data;
  }

  async onClickConfirm() {
    if (this.isConfirm) {
      if (!this.currentConversion) return;
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      const { id, isDisabled, ...param } = this.currentConversion.update(
        this.formData
      );
      await this.$store
        .dispatch("conversion/updateConversion", { id, param })
        .then(() => {
          this.$router.push({ name: "conversion-settings-index" });
        })
        .catch(handleError)
        .finally(() => (this.isSubmitting = false));
    } else {
      this.scrollTop();
      this.isConfirm = true;
    }
  }

  onClickCancel() {
    if (this.isConfirm) {
      this.scrollTop();
      this.isConfirm = false;
    } else {
      this.$router.push({ name: "conversion-settings-index" });
    }
  }

  get currentConversion(): ConversionDefinition | null {
    return this.$store.state.conversion.currentConversion;
  }

  get isSubmitButtonDisabled(): boolean {
    if (this.isSubmitting) return true;

    return !isValidConversionData(this.formData);
  }

  async mounted() {
    const conversionId = Number(this.$route.params.id);
    await this.$store
      .dispatch("conversion/fetchConversionById", conversionId)
      .catch(e =>
        handleNotFoundError(
          e,
          "conversion-settings-index",
          this.$t("404error").toString()
        )
      );

    this.formData = {
      name: this.currentConversion?.name || this.formData.name,
      path: this.currentConversion?.path || this.formData.path
    };
    this.isLoading = false;
  }

  destroyed() {
    this.$store.commit("conversion/setCurrentConversion", null);
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss" scoped>
.conversionSettingsEdit {
  margin-right: 20px;
  min-width: 704px;
}
.conversionSettingsEdit__title {
  padding: 0 0 46px 0;
  font-size: 20px;
  font-weight: 700;
}
.conversionSettingsEdit__message {
  margin-bottom: 30px;
  font-size: 14px;
}
.conversionSettingsEdit__buttonContainer {
  margin-top: 16px;
}
.conversionSettingsEdit__confirmButton,
.conversionSettingsEdit__submitButton {
  margin-right: 20px;
}
</style>
