import { render, staticRenderFns } from "./V3SideBarNew.vue?vue&type=template&id=438dc504&scoped=true"
import script from "./V3SideBarNew.vue?vue&type=script&setup=true&lang=ts"
export * from "./V3SideBarNew.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./V3SideBarNew.vue?vue&type=style&index=0&id=438dc504&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438dc504",
  null
  
)

/* custom blocks */
import block0 from "./V3SideBarNew.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports