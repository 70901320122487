<template>
  <div
    :id="gramId"
    class="ud-Visit_Gram ud-Visit_Gram_Pv"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div
      class="ud-Visit_GramIcon"
      :class="{ 'ud-Visit_GramIconTime_LongStay': isLongStay }"
    >
      <div v-if="isApp" class="ud-Visit_GramIconApp">App</div>
      <UserIcon
        :is-pc="isPc"
        :gaze-level="gazeLevel"
        class="ud-Visit_GramIcon_UserIcon"
      />
      <div v-if="stayTime" :class="stayTimeClass">
        {{ stayTime }}
        <span class="ud-Visit_GramIconTime_Unit">{{ stayTimeUnit }}</span>
      </div>
    </div>
    <div
      class="ud-Visit_GramBody"
      :class="{
        showingPreview: previewedGramId === gramId,
        'ud-Visit_GramBody--hasLink': hasLinkableUrl
      }"
      @click="onClick"
    >
      <div class="ud-Visit_GramTime">{{ time }}</div>
      <div class="ud-Visit_GramTitle">
        <span
          v-for="(title, index) in displayTitleArray"
          :key="index"
          :class="{ 'ud-Visit_HighLight': title.isHighlight }"
          >{{ title.text }}</span
        >
        <span class="ud-Visit_GramTitleNavigation">{{ navigationText }}</span>
      </div>
      <a
        v-if="hasLinkableUrl"
        class="ud-Visit_GramUrl"
        :href="url"
        target="_blank"
        rel="noopener"
        @click.stop
      >
        <span
          v-for="(url, index) in displayUrlArray"
          :key="index"
          :class="{ 'ud-Visit_HighLight': url.isHighlight }"
          >{{ url.text }}</span
        >
      </a>
      <div v-else class="ud-Visit_GramUrl_NoLink">
        <span
          v-for="(url, index) in displayUrlArray"
          :key="index"
          :class="{ 'ud-Visit_HighLight': url.isHighlight }"
          >{{ url.text }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import { Gram } from "@/models/Gram";
import UserIcon from "@/components/users/UserIcon.vue";
import { getGazeLevelFromStayTimeSec } from "@/models/getIcons";
import { GazeLevel } from "@/const/user-icon";
import { MatchMethod, WordForHighlight } from "@/models/search/MatchMethod";

interface HighlightWord {
  text: string;
  isHighlight: boolean;
}

@Component({
  components: {
    UserIcon
  }
})
export default class GramPv extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: String, required: true })
  previewedGramId!: string;

  @Prop({ type: Array, required: true })
  filterTitles!: WordForHighlight[];

  @Prop({ type: Array, required: true })
  filterFullUrls!: WordForHighlight[];

  @Prop({ type: Array, required: true })
  filterPartUrls!: WordForHighlight[];

  clickWebPvGram(url: string, isSmartphone: boolean, gramId: string) {
    this.$emit("click-pv", url, isSmartphone, gramId);
  }

  get gramId(): string {
    return this.gram.id;
  }

  get time(): string {
    return this.gram.timeTx;
  }

  get isPc(): boolean {
    return !this.gram.isSmartphone;
  }

  get gazeLevel(): GazeLevel {
    // 離脱前は常に無表情にする
    if (this.gram.hasWithdrawal) {
      return 1;
    }
    return getGazeLevelFromStayTimeSec(this.gram.stayTimeSec);
  }

  get stayTimeClass(): string {
    return this.gram.isSmartphone
      ? "ud-Visit_GramIconTime_Smp"
      : "ud-Visit_GramIconTime_Pc";
  }

  get isApp(): boolean {
    return this.gram.isApp;
  }

  get isLongStay(): boolean {
    return this.gram.isLongStay;
  }

  get stayTime(): string {
    return this.gram.stayTime;
  }

  get stayTimeUnit(): string {
    return this.gram.stayTimeUnitTx;
  }

  get navigationText(): string {
    return this.gram.navigationTx;
  }

  get displayTitleArray(): HighlightWord[] {
    return this.setPartHighlight(
      this.gram.pvLocationName,
      this.filterTitles,
      false
    );
  }

  get url(): string {
    return this.gram.pvLocationUri;
  }

  get hasLinkableUrl(): boolean {
    return this.url.startsWith("http");
  }

  get displayUrlArray(): HighlightWord[] {
    let displayUrlArray = this.setFullHighlight(
      this.gram.pvLocationUri,
      this.filterFullUrls
    );
    if (displayUrlArray[0].isHighlight === false) {
      const nonInflowParamWords = this.filterPartUrls.filter(
        word => !word.isInflowParam
      );
      const hasInflowParams =
        this.filterPartUrls.length != nonInflowParamWords.length;
      if (hasInflowParams) {
        const uriParts = this.gram.pvLocationUri.split("?");
        const uriWithoutQueryParams = uriParts[0];
        const queryParamsWithFragment = uriParts[1];
        const uriArray = this.setPartHighlight(
          uriWithoutQueryParams,
          nonInflowParamWords,
          true
        );
        if (!queryParamsWithFragment) {
          return uriArray;
        }
        const queryParams = "?" + queryParamsWithFragment.split("#")[0];
        const queryParamArray = this.setPartHighlight(
          queryParams,
          this.filterPartUrls,
          true
        );
        displayUrlArray = uriArray.concat(queryParamArray);
        const fragment = queryParamsWithFragment.split("#")[1];
        if (fragment) {
          displayUrlArray.push({ text: "#" + fragment, isHighlight: false });
        }
      } else {
        displayUrlArray = this.setPartHighlight(
          this.gram.pvLocationUri,
          this.filterPartUrls,
          true
        );
      }
    }
    return displayUrlArray;
  }
  /*
   * 対象の文字列が一致していればハイライトをセット（完全一致）
   * なければ false を返す
   */
  setFullHighlight(str: string, words: WordForHighlight[]): HighlightWord[] {
    for (let i = 0; i < words.length; i += 1) {
      const filterWord = words[i].word;
      if (filterWord.toLowerCase() === str.toLowerCase()) {
        return [{ text: str, isHighlight: true }];
      }
    }
    return [{ text: str, isHighlight: false }];
  }

  // 対象の文字列が含まれていればハイライトをセット（部分一致）
  setPartHighlight(
    str: string,
    words: WordForHighlight[],
    isCompareByLowerCase: boolean
  ): HighlightWord[] {
    let matches: HighlightWord[] = [{ text: str, isHighlight: false }];

    for (let i = 0; i < words.length; i += 1) {
      let filterWord = words[i].word;
      // If wildcard search
      if (words[i].matchMethod === MatchMethod.Wildcard) {
        filterWord = this.convertWildCardSearchToRegexSearch(filterWord, str);
      }
      if (filterWord === "") {
        continue;
      }

      const tmpMatches: HighlightWord[] = [];
      matches.forEach(HighlightWord => {
        const option = isCompareByLowerCase ? "ig" : "g";
        // フィルター文言で分割
        const reg = new RegExp(
          filterWord.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
          option
        );
        const divided = HighlightWord.text.split(reg);
        // 2個以上ある場合フィルター文言が含まれている
        if (1 < divided.length) {
          let textPos = 0;
          for (let x = 0; x < divided.length; x += 1) {
            textPos += divided[x].length;
            if (divided[x] !== "") {
              tmpMatches.push({ text: divided[x], isHighlight: false });
            }
            if (x !== divided.length - 1) {
              const targetWord = HighlightWord.text.substr(
                textPos,
                filterWord.length
              );
              tmpMatches.push({ text: targetWord, isHighlight: true });
              textPos += targetWord.length;
            }
          }
        } else {
          tmpMatches.push(HighlightWord);
        }
      });
      matches = tmpMatches;
    }

    return matches;
  }

  onClick() {
    if (!this.hasLinkableUrl) {
      return;
    }
    this.clickWebPvGram(this.url, this.gram.isSmartphone, this.gramId);
  }

  convertWildCardSearchToRegexSearch(
    filterWord: string,
    originalString: string
  ): string {
    const escapedFilterWord = this.escapeWildcardFilterWord(filterWord);
    const pattern = this.convertToRegex(escapedFilterWord);
    const arrayMatchedWildcardFilterWord = originalString.match(pattern);
    return arrayMatchedWildcardFilterWord != null
      ? arrayMatchedWildcardFilterWord[0]
      : "";
  }

  escapeWildcardFilterWord(filterWord: string) {
    const wordEscapedSpecialCharacters = this.escapeSpecialCharacters(
      filterWord
    );
    const wordEscapedWhiteSpaces = this.escapeWhiteSpaces(
      wordEscapedSpecialCharacters
    );
    return wordEscapedWhiteSpaces;
  }

  escapeSpecialCharacters(filterWord: string): string {
    const wildcardSpecialCharacterRegExp = /[.+?^${}()|[\]\\]/g;
    return filterWord.replace(wildcardSpecialCharacterRegExp, "\\$&");
  }

  escapeWhiteSpaces(filterWord: string): string {
    const asteriskWithSpacesRegExp = /\s*([*])\s*/g;
    return filterWord.replace(asteriskWithSpacesRegExp, "*").trim();
  }

  convertToRegex(filterWord: string): RegExp {
    return new RegExp(filterWord.replace(/\*/g, ".*"), "ig");
  }
}
</script>
