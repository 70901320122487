import { ApiRes } from "@/api/api-res";
import SelectOption from "@/components/form/SelectOption";
import { PLATFORM_CATEGORY } from "@/const/gram";
import { i18n } from "@/i18n";

export enum ContactDefinitionType {
  TEL = PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.TEL,
  MAIL = PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.MAIL,
  // this type is only for customer side
  SHOP = PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.SHOP,
  DM = PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.DM,
  // this type is only for the company side
  VISIT = PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.VISIT,
  // this type is only for the company side
  APP = PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.APP
}

export class ContactDefinition {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly isDisabled: boolean,
    public readonly type: ContactDefinitionType
  ) {}

  public static fromJson(json: ApiRes.ContactDef): ContactDefinition {
    return new ContactDefinition(
      json.id,
      json.name,
      json.is_disabled,
      json.data_type
    );
  }

  public generateSelectOption(): SelectOption {
    return {
      label: this.translatedName,
      value: this.id,
      disabled: false
    };
  }

  get translatedName(): string {
    switch (this.type) {
      case ContactDefinitionType.APP:
        return i18n.t("models.gram.appNotification") as string;
      case ContactDefinitionType.DM:
        return i18n.t("models.gram.dm") as string;
      case ContactDefinitionType.MAIL:
        return i18n.t("models.gram.mail") as string;
      case ContactDefinitionType.SHOP:
        return i18n.t("models.gram.shop") as string;
      case ContactDefinitionType.TEL:
        return i18n.t("models.gram.tel") as string;
      case ContactDefinitionType.VISIT:
        return i18n.t("models.gram.visit") as string;
      default:
        return "";
    }
  }
}
