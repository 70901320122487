import createPersistedState from "vuex-persistedstate";
import Vue from "vue";
import Vuex from "vuex";
import { alert, AlertState } from "@/store/modules/alert";
import { api, ApiState } from "@/store/modules/api";
import { app, AppState } from "@/store/modules/app";
import { auth, AuthState } from "@/store/modules/auth";
import { client, ClientState } from "@/store/modules/client";
import {
  clientSettings,
  ClientSettingsState
} from "@/store/modules/clientSettings";
import {
  companyActivityDisplaySetting,
  CompanyActivityDisplaySettingState
} from "@/store/modules/companyActivityDisplaySetting";
import { favorite, FavoriteState } from "@/store/modules/favorite";
import { filter, FilterState } from "@/store/modules/filter";
import { clustering, ClusteringState } from "@/store/modules/clustering";
import { history, HistoryState } from "@/store/modules/history";
import { preference, PreferenceState } from "@/store/modules/preference";
import { search, SearchState } from "@/store/modules/search";
import { searchForm, SearchFormState } from "@/store/modules/searchForm";
import {
  searchHistory,
  SearchHistoryState
} from "@/store/modules/searchHistory";
import { system, SystemState } from "@/store/modules/system";
import { user, UserState } from "@/store/modules/user";
import { visit, VisitState } from "@/store/modules/visit";
import { wordcloud, WordcloudState } from "@/store/modules/wordcloud";
import { memo } from "@/store/modules/memo";
import { onboarding, OnboardingState } from "@/store/modules/onboarding";
import {
  engagementSearch,
  EngagementSearchState
} from "@/store/modules/engagement-search";
import { almostCv, AlmostCvState } from "@/store/modules/almostCv";
import { lagCv, LagCvState } from "@/store/modules/lagCv";

import { Memo } from "@/models/User";
import {
  segmentedTrends,
  SegmentedTrendsState
} from "@/store/segmented-trends/";
import { userTrends, UserTrendsState } from "@/store/user-trends/";
import { notification, NotificationState } from "@/store/modules/notification";
import {
  conversionTrend,
  ConversionTrendState
} from "@/store/modules/conversion-trend";
import { funnel, FunnelState } from "@/store/modules/funnel";
import { tour, TourState } from "@/store/modules/tour";
import {
  conversion,
  ConversionState
} from "@/store/modules/client-settings/conversion";
import {
  appConversion,
  AppConversionState
} from "@/store/modules/client-settings/appConversion";
import {
  tdWebImportConfig,
  TdWebImportConfigState
} from "@/store/modules/client-settings/TdWebImportConfigModule";
import {
  tdConversion,
  TdConversionState
} from "@/store/modules/client-settings/TdConversionModule";
import {
  tdConversionAttribute,
  TdConversionAttributeState
} from "@/store/modules/client-settings/TdConversionAttributeModule";
import { mfa, MfaState } from "@/store/modules/mfa";
import {
  inputFormSuggestion,
  InputFormSuggestionState
} from "@/store/modules/input-form-suggestion";
import {
  mapConversionAttribute,
  MapConversionAttributeState
} from "@/store/modules/client-settings/mapConversionAttribute";
import {
  mapEvent,
  MapEventState
} from "@/store/modules/client-settings/mapEvent";

Vue.use(Vuex);

export interface RootState {
  app: AppState;
  api: ApiState;
  auth: AuthState;
  client: ClientState;
  search: SearchState;
  filter: FilterState;
  clustering: ClusteringState;
  searchHistory: SearchHistoryState;
  alert: AlertState;
  user: UserState;
  favorite: FavoriteState;
  visit: VisitState;
  companyActivityDisplaySetting: CompanyActivityDisplaySettingState;
  clientSettings: ClientSettingsState;
  system: SystemState;
  wordcloud: WordcloudState;
  searchForm: SearchFormState;
  preference: PreferenceState;
  history: HistoryState;
  memo: Memo;
  onboarding: OnboardingState;
  engagementSearch: EngagementSearchState;
  almostCv: AlmostCvState;
  lagCv: LagCvState;
  userTrends: UserTrendsState;
  segmentedTrends: SegmentedTrendsState;
  notification: NotificationState;
  conversionTrend: ConversionTrendState;
  funnel: FunnelState;
  tour: TourState;
  conversion: ConversionState;
  appConversion: AppConversionState;
  mapConversionAttribute: MapConversionAttributeState;
  mapEvent: MapEventState;
  tdWebImportConfig: TdWebImportConfigState;
  tdConversion: TdConversionState;
  tdConversionAttribute: TdConversionAttributeState;
  mfa: MfaState;
  inputFormSuggestion: InputFormSuggestionState;
}

export default new Vuex.Store<RootState>({
  modules: {
    app,
    api,
    auth,
    client,
    search,
    filter,
    clustering,
    searchHistory,
    alert,
    user,
    favorite,
    visit,
    companyActivityDisplaySetting,
    clientSettings,
    system,
    wordcloud,
    searchForm,
    preference,
    history,
    memo,
    onboarding,
    userTrends,
    segmentedTrends,
    engagementSearch,
    lagCv,
    almostCv,
    notification,
    conversionTrend,
    funnel,
    conversion,
    appConversion,
    mapConversionAttribute,
    mapEvent,
    tdWebImportConfig,
    tdConversion,
    tdConversionAttribute,
    tour,
    mfa,
    inputFormSuggestion
  },
  plugins: [
    createPersistedState({
      key: "usergram",
      paths: ["preference", "notification.markAsRead"]
    })
  ]
});
