import { i18n } from "@/i18n";
import {
  ClusteringPatternData,
  ClusteringLabelData,
  ClusteringLabelDataType
} from "@/api/apis/ApiSearch";

// No is for no calculated. it is not displayed
export type LabelTitle = "A" | "B" | "C" | "D" | "E" | "F" | "No";

export interface Characteristic {
  readonly name: string;
  readonly value: string;
}

export interface ClusteringLabel {
  readonly title: LabelTitle;
  readonly characteristics: Characteristic[];
}

/**
 * Clustering
 *
 * label: To display select option title
 * userCount: Number of users for each cluster
 * cachedId: Cache key to get user list for each cluster
 * isCalculated: Whether this cluster could be calculated from the data
 */
export class ClusteringPattern {
  constructor(
    public readonly label: ClusteringLabel,
    public readonly userCount: number,
    public readonly cachedId: string,
    public readonly isCalculated: boolean
  ) {}

  get title(): string {
    if (!this.isCalculated) {
      return i18n.t("models.clustering.noCalculated") as string;
    }
    return this.label.title;
  }

  public static fromJson(
    json: ClusteringPatternData,
    title: LabelTitle
  ): ClusteringPattern {
    const characteristics: Characteristic[] = json.label.map(typeValue => {
      return {
        name: ClusteringPattern.getCharacteristicName(typeValue.type),
        value: ClusteringPattern.getCharacteristicValue(typeValue)
      };
    });
    const label = { title, characteristics };
    return new ClusteringPattern(
      label,
      json.num_user,
      json.cached_cluster_id,
      json.is_calculated
    );
  }

  private static getCharacteristicName(type: ClusteringLabelDataType): string {
    return i18n.t("models.clustering.labelType-" + type) as string;
  }
  private static getCharacteristicValue(data: ClusteringLabelData): string {
    const prefix = data.type === "leadtime" ? "labelValueTime-" : "labelValue-";
    return i18n.t("models.clustering." + prefix + data.value) as string;
  }
}
