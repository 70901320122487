import {
  AdditionalSelectConditionInterface,
  stringListValue
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  AttributeConditionParamType,
  ConversionAttributeTextConditionParams,
  AttributeType,
  AdditionalConditionParams
} from "@/api/apis/ApiSearch";
import { ConversionAttributeCondition } from "@/models/search/additional-condition/ConversionAttributeCondition";

/**
 * コンバージョン・イベント属性による条件
 *
 * 通常の検索条件に加えて、コンバージョン・イベント属性に対する選定条件を付与できる
 *
 * 文字列を指定して、指定したコンバージョン・イベント属性が部分一致、または完全一致するものを選定する
 * 文字列は複数指定可能でor条件になる
 */

export class ConversionAttributeTextCondition
  extends ConversionAttributeCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    id: number,
    public readonly values: string[],
    public readonly matchPattern: MatchMethod
  ) {
    super(id, AttributeType.TEXT);
  }

  /**
   * 表示用の設定された値を返す。
   */
  displayValue(): string {
    return stringListValue(this.values, this.matchPattern);
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertConversionAttributeTextConditionToJson(
  condition: ConversionAttributeTextCondition
): ConversionAttributeTextConditionParams {
  return {
    type: AttributeConditionParamType.ConversionAttribute,
    id: condition.id,
    attributeType: AttributeType.TEXT,
    values: condition.values,
    word_match_method: condition.matchPattern
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToConversionAttributeTextCondition(
  json: ConversionAttributeTextConditionParams,
  activeConversionAttributeDefinitions: ConversionAttributeDefinition[]
): ConversionAttributeTextCondition | null {
  // 利用可能なidリストになければnull
  if (!activeConversionAttributeDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  return new ConversionAttributeTextCondition(
    json.id,
    json.values,
    json.word_match_method
  );
}

export function isConversionAttributeTextConditionParams(
  params: AdditionalConditionParams
): params is ConversionAttributeTextConditionParams {
  return (
    (params as ConversionAttributeTextConditionParams).attributeType ===
      AttributeType.TEXT ||
    //TODO: this is for attributeType not provided (existing implementation)
    //which should be removed in the future. Since, existing selection history
    //does not have attributeType, so we have to keep this for backward compatibility.
    (params as ConversionAttributeTextConditionParams).attributeType == null
  );
}
