import { MatchMethod } from "@/models/search/MatchMethod";

/**
 * UserTrendの絞り込み条件を格納するクラス
 */

export class UserTrendFilterCondition {
  constructor(public value: string, public matchMethod: MatchMethod) {}

  static get defaultFilter() {
    return new UserTrendFilterCondition("", MatchMethod.Partial);
  }

  setValue(value: string) {
    this.value = value;
  }

  setMethod(method: MatchMethod) {
    this.matchMethod = method;
  }
}
