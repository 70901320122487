<i18n src="@/i18n/views/global-nav.json"></i18n>
<template>
  <div
    class="global-nav"
    data-cy="global-nav"
    @mouseenter="onMouseEnterNavi"
    @mouseleave="onMouseLeaveNavi"
  >
    <div class="globalNav__container">
      <div class="global-nav_group">
        <RouterLink
          class="global-nav_item"
          data-cy="global_nav_home"
          to="/"
          exact
        >
          <NavigationItem :icon="Icons.Home" :show-text="expandNav">
            <span v-t="'home'" class="global-nav_text" />
          </NavigationItem>
        </RouterLink>

        <RouterLink
          class="global-nav_item"
          data-cy="global_nav_favorite_users"
          to="/bookmark-users"
        >
          <NavigationItem :icon="Icons.Heart" :show-text="expandNav">
            <span v-t="'favoriteUsers'" class="global-nav_text" />
          </NavigationItem>
        </RouterLink>

        <RouterLink
          class="global-nav_item"
          data-cy="global_nav_memo"
          to="/memo-users"
        >
          <NavigationItem :icon="Icons.Memo" :show-text="expandNav">
            <span v-t="'memoUsers'" class="global-nav_text" />
          </NavigationItem>
        </RouterLink>

        <RouterLink
          class="global-nav_item"
          data-cy="global_nav_watch_history"
          to="/watch-history"
        >
          <NavigationItem :icon="Icons.History" :show-text="expandNav">
            <span v-t="'watchHistory'" class="global-nav_text" />
          </NavigationItem>
        </RouterLink>
      </div>

      <div v-if="isToursAvailable" class="global-nav_group">
        <RouterLink
          class="global-nav_item"
          data-cy="global_nav_tours"
          to="/tours"
        >
          <NavigationItem :icon="Icons.Binocular" :show-text="expandNav">
            <span v-t="'tours'" class="global-nav_text" />
          </NavigationItem>
        </RouterLink>
      </div>

      <div
        v-if="isFunnelAvailable"
        class="global-nav_group global-nav_group--funnel"
      >
        <RouterLink
          class="global-nav_item"
          data-cy="global_nav_funnel_analysis"
          to="/funnel"
        >
          <NavigationItem :icon="Icons.HorizontalBars" :show-text="expandNav">
            <span v-t="'funnelAnalysis'" class="global-nav_text" />
          </NavigationItem>
        </RouterLink>
      </div>

      <div v-if="isAvailableUserTrend" class="global-nav_group">
        <RouterLink
          v-if="canUseWebdataFeatures"
          class="global-nav_item"
          :to="{ name: 'web-views' }"
          data-cy="global_nav_user-trend_web_view"
        >
          <NavigationItem :icon="Icons.Analysis" :show-text="expandNav">
            <span class="global-nav_text">
              {{ $t("pageAnalysis") }}
            </span>
          </NavigationItem>
        </RouterLink>

        <RouterLink
          v-if="canUseWebdataFeatures"
          class="global-nav_item"
          :to="{ name: 'landings' }"
          data-cy="global_nav_user-trend_landing"
        >
          <NavigationItem :icon="Icons.Analysis" :show-text="expandNav">
            <span class="global-nav_text">
              {{ $t("landingPageAnalysis") }}
            </span>
          </NavigationItem>
        </RouterLink>

        <RouterLink
          v-if="canUseWebdataFeatures"
          class="global-nav_item"
          :to="{ name: 'inflows' }"
          data-cy="global_nav_user-trend_inflow"
        >
          <NavigationItem :icon="Icons.Analysis" :show-text="expandNav">
            <span class="global-nav_text">
              {{ $t("inflowSourceAnalysis") }}
            </span>
          </NavigationItem>
        </RouterLink>

        <RouterLink
          v-if="isContractApp"
          class="global-nav_item"
          :to="{ name: 'app-views' }"
          data-cy="global_nav_user-trend_app_view"
        >
          <NavigationItem :icon="Icons.Analysis" :show-text="expandNav">
            <span class="global-nav_text">
              {{ $t("appPageAnalysis") }}
            </span>
          </NavigationItem>
        </RouterLink>
      </div>

      <div v-if="canUseBetaUI" class="global-nav_group">
        <a
          href="/v2/report/cv/"
          class="global-nav_item"
          data-cy="global_nav_gram-kun_journey"
        >
          <NavigationItem :show-text="expandNav">
            <template #customicon>
              <IconGram />
            </template>
            <span class="global-nav_text">
              {{ $t("gramkunJourney") }}
            </span>
          </NavigationItem>
        </a>

        <a
          href="/v2/report"
          class="global-nav_item"
          data-cy="global_nav_gram-kun_stats"
        >
          <NavigationItem :show-text="expandNav">
            <template #customicon>
              <IconGram />
            </template>
            <span class="global-nav_text">
              {{ $t("gramkunStats") }}
            </span>
          </NavigationItem>
        </a>
      </div>

      <div
        v-if="favoriteSearches.length > 0 && isAvailableUserList"
        class="global-nav_group"
      >
        <div
          v-for="favoriteSearch in favoriteSearches"
          :key="favoriteSearch.id"
          class="global-nav_item"
        >
          <div
            class="global-nav_item_inner"
            @mouseenter="onMouseEnterItem($event, favoriteSearch)"
            @mouseleave="onMouseLeaveItem()"
          >
            <NavigationItem
              :icon="Icons.Save"
              :show-text="expandNav"
              :active="activeFavoriteSearchItem(favoriteSearch.id)"
            >
              <span class="global-nav_text">{{ favoriteSearch.label }}</span>
            </NavigationItem>
          </div>
        </div>
      </div>
    </div>

    <div class="globalNav__copyright"
      ><template v-if="expandNav">© COPYRIGHT beBit, Inc.</template></div
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import {
  Container as ElContainer,
  MenuItem as ElMenuItem,
  Menu as ElMenu,
  MenuItemGroup as ElMenuItemGroup
} from "elementui-lts";
import { Icons } from "@/const/Icons";
import { LoginUser } from "@/models/auth/UgUser";
import { Client } from "@/models/Client";
import Icon from "@/components/Icon.vue";
import IconGram from "@/assets/svg/gramkun/icon-gram.svg";
import NavigationItem from "@/components/NavigationItem.vue";
import { FavoriteSearch } from "@/models/search/FavoriteSearch";
import { isGuestUser } from "@/util/common-util";

@Component({
  components: {
    ElContainer,
    ElMenuItem,
    ElMenu,
    ElMenuItemGroup,
    Icon,
    IconGram,
    NavigationItem
  }
})
export default class GlobalNav extends Vue {
  Icons = Icons;

  @Prop({ type: Boolean, required: true })
  expandNav!: boolean;

  @Prop({ type: Number, required: true })
  hoveredFavoriteSearchId!: number;

  @Emit("mouse-leave-item")
  onMouseLeaveItem() {}

  onMouseEnterItem(event: MouseEvent, search: FavoriteSearch) {
    this.$emit("mouse-enter-item", event, search);
  }

  @Emit("mouse-leave-navi")
  onMouseLeaveNavi() {}

  @Emit("mouse-enter-navi")
  onMouseEnterNavi() {}

  get favoriteSearches(): FavoriteSearch[] {
    return this.$store.getters["searchHistory/favoriteSearches"];
  }

  get canUseBetaUI(): boolean {
    return this.$store.state.app.canUseBetaUI;
  }

  get loginUser(): LoginUser {
    return this.$store.state.auth.user;
  }

  get loginClient(): Client {
    return this.$store.state.client.client;
  }

  get isAvailableUserList(): boolean {
    return this.loginUser.permission.isAvailableUserList;
  }

  get isAvailableUserTrend(): boolean {
    return this.loginUser.permission.isAvailableUserTrend;
  }
  get isContractApp(): boolean {
    return this.loginClient.isContractApp;
  }
  get canUseWebdataFeatures(): boolean {
    return this.$store.state.app.canUseWebdataFeatures;
  }

  get isFunnelAvailable(): boolean {
    return (
      this.isAvailableUserList && this.loginClient.hasFunnelAnalysisContract
    );
  }

  get isToursAvailable() {
    return (
      !isGuestUser(this.loginUser) && this.$store.state.app.canUseTourFeatures
    );
  }

  activeFavoriteSearchItem(id: number): boolean {
    return this.hoveredFavoriteSearchId === id;
  }
}
</script>

<style lang="scss" scoped>
.globalNav__container {
  padding-top: 4px;
  border-bottom: 1px solid $colorBase500;
}

.global-nav_group {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $colorBase500;
  &:last-child {
    border-bottom: none;
  }
}

.global-nav_item {
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.global-nav_text {
  color: $colorBase900;
}

.globalNav__copyright {
  padding: 20px 0 40px 22px;
  height: 73px;
  color: $colorBase700;
  white-space: nowrap;
  font-size: 12px;
}
</style>
