import { FilterSiteUrlCondition } from "@/models/search/filter-node-condition/FilterSiteUrlCondition";
import {
  FilterPageTitleCondition,
  convertJsonToFilterPageTitleCondition
} from "@/models/search/filter-node-condition/FilterPageTitleCondition";
import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import { FilterNodeParamForBrowseSite } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import {
  FilterNodeType,
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import { MatchMethod, WordForHighlight } from "@/models/search/MatchMethod";
import {
  convertJsonValueToStayTime,
  FilterStayTimeCondition
} from "@/models/search/filter-node-condition/FilterStayTimeCondition";
/**
 * サイト内のページ閲覧による絞り込み条件
 *
 * サイト内のURL、サイト内のページタイトルいずれかの入力が必須で、条件にあうユーザを絞り込む
 * 条件として2つすべてを追加することもできる
 * サイト内のURLは完全一致or部分一致で、サイト内のページタイトルは部分一致を指定できる
 *
 * 初回、期間、日付と時間帯 を条件として追加できる
 */
export class FilterNodeForBrowseSite {
  public readonly nodeType = FilterNodeType.BrowseSite;

  constructor(
    /**
     * 少なくとも、サイト内のURL、サイト内のページタイトルの条件いずれかの保持が必須
     */
    public readonly condition:
      | FilterPageTitleCondition
      | FilterSiteUrlCondition,
    /**
     * 追加の検索条件
     *
     * サイト内のURL、サイト内のページタイトル、初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterPageTitleCondition
      | FilterSiteUrlCondition
      | FilterAdditionalTimingCondition
    )[],
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion(),
    public readonly filterStayTime: FilterStayTimeCondition = new FilterStayTimeCondition()
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate(): ValidationResult {
    return validateConditions(
      this.additionalConditions,
      this.condition.validate
    );
  }

  get isConditionAddable(): boolean {
    if (this.depth === 0) {
      return this.additionalConditions.length < 2;
    } else {
      return this.additionalConditions.length < 1;
    }
  }

  get hasSiteUrlCondition(): boolean {
    return (
      this.condition instanceof FilterSiteUrlCondition ||
      this.additionalConditions.some(
        cnd => cnd instanceof FilterSiteUrlCondition
      )
    );
  }

  get hasPageTitleCondition(): boolean {
    return (
      this.condition instanceof FilterPageTitleCondition ||
      this.additionalConditions.some(
        cnd => cnd instanceof FilterPageTitleCondition
      )
    );
  }

  get titlesForHighlight(): WordForHighlight[] {
    if (this.filterExclusion.exclusionType === ExclusionType.Exclude) return [];
    const titles: WordForHighlight[] = [];
    if (this.condition instanceof FilterPageTitleCondition) {
      titles.push({
        word: this.condition.pageTitle,
        matchMethod: this.condition.matchMethod
      });
    }
    this.additionalConditions.forEach(additionalCondition => {
      if (additionalCondition instanceof FilterPageTitleCondition) {
        titles.push({
          word: additionalCondition.pageTitle,
          matchMethod: this.condition.matchMethod
        });
      }
    });
    return titles;
  }

  get fullUrlsForHighlight(): WordForHighlight[] {
    if (this.filterExclusion.exclusionType === ExclusionType.Exclude) return [];
    const urls: WordForHighlight[] = [];
    if (
      this.condition instanceof FilterSiteUrlCondition &&
      (this.condition.matchMethod === MatchMethod.Exact ||
        this.condition.matchMethod === MatchMethod.Wildcard)
    ) {
      urls.push({
        word: this.condition.siteUrl,
        matchMethod: this.condition.matchMethod
      });
    }

    this.additionalConditions.forEach(additionalCondition => {
      if (
        additionalCondition instanceof FilterSiteUrlCondition &&
        (additionalCondition.matchMethod === MatchMethod.Exact ||
          additionalCondition.matchMethod === MatchMethod.Wildcard)
      ) {
        urls.push({
          word: additionalCondition.siteUrl,
          matchMethod: this.condition.matchMethod
        });
      }
    });
    return urls;
  }

  get partUrlsForHighlight(): WordForHighlight[] {
    if (this.filterExclusion.exclusionType === ExclusionType.Exclude) return [];
    const urls: WordForHighlight[] = [];
    if (
      this.condition instanceof FilterSiteUrlCondition &&
      (this.condition.matchMethod === MatchMethod.Partial ||
        this.condition.matchMethod === MatchMethod.Wildcard)
    ) {
      urls.push({
        word: this.condition.siteUrl,
        matchMethod: this.condition.matchMethod
      });
    }
    this.additionalConditions.forEach(additionalCondition => {
      if (
        additionalCondition instanceof FilterSiteUrlCondition &&
        (additionalCondition.matchMethod === MatchMethod.Partial ||
          additionalCondition.matchMethod === MatchMethod.Wildcard)
      ) {
        urls.push({
          word: additionalCondition.siteUrl,
          matchMethod: this.condition.matchMethod
        });
      }
    });
    return urls;
  }

  insertEdge(): FilterNodeForBrowseSite {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForBrowseSite(
      this.condition,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion,
      this.filterStayTime
    );
  }

  removeEdge(): FilterNodeForBrowseSite {
    return new FilterNodeForBrowseSite(
      this.condition,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion,
      this.filterStayTime
    );
  }

  removeFirstTimeCondition(): FilterNodeForBrowseSite {
    return new FilterNodeForBrowseSite(
      this.condition,
      this.additionalConditions.filter(
        cnd => !(cnd instanceof FilterFirstTimeCondition)
      ),
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion,
      this.filterStayTime
    );
  }

  /**
   * isConditionがtrueならadditionalConditionsではなく、
   * conditionで表示される方のなので、日付系の選択肢はdisable
   */
  selectOption(
    currentCondition: FilterAdditionalCondition,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.SiteUrl,
            label: i18n.t("models.search.pageUrl") as string,
            disabled:
              this.hasSiteUrlCondition &&
              !(currentCondition instanceof FilterSiteUrlCondition)
          },
          {
            value: FilterNodeConditionType.PageTitle,
            label: i18n.t("models.search.pageTitle") as string,
            disabled:
              this.hasPageTitleCondition &&
              !(currentCondition instanceof FilterPageTitleCondition)
          }
        ]
      }
    ];

    if (this.depth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }
}

export function convertFilterNodeForBrowseSiteToJson(
  node: FilterNodeForBrowseSite
): FilterNodeParamForBrowseSite {
  const result: FilterNodeParamForBrowseSite = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.BrowseSite,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion)
  };

  if (
    node.filterStayTime.isStayTimeChecked &&
    node.filterExclusion.exclusionType === ExclusionType.Include &&
    node.depth === 0
  ) {
    result.stay_time_in_sec_gte =
      node.filterStayTime.stayTimeValueGreaterThanEqual;

    result.stay_time_in_sec_lt = node.filterStayTime.stayTimeValueLessThan;
  }

  const conditions: (
    | FilterPageTitleCondition
    | FilterSiteUrlCondition
    | FilterAdditionalTimingCondition
  )[] = node.additionalConditions.concat(node.condition);

  for (const condition of conditions) {
    if (condition instanceof FilterPageTitleCondition) {
      result.cruise_title = {
        title: condition.pageTitle,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterSiteUrlCondition) {
      result.cruise_url = {
        url: condition.siteUrl,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForBrowseSite(
  json: FilterNodeParamForBrowseSite
): FilterNodeForBrowseSite {
  // ダミー条件（cruise_urlとcruise_titleが両方ない条件はvalidationエラーで除外）
  let condition!: FilterPageTitleCondition | FilterSiteUrlCondition;

  const additionalConditions: (
    | FilterPageTitleCondition
    | FilterSiteUrlCondition
    | FilterAdditionalTimingCondition
  )[] = getAdditionalTimingConditions(json);

  // cruise_url と cruise_title が両方ある場合は、cruise_titleをadditionalとする
  if (json.cruise_url) {
    condition = new FilterSiteUrlCondition(
      json.cruise_url.url,
      json.cruise_url.word_match_method
    );
    if (json.cruise_title) {
      additionalConditions.push(
        convertJsonToFilterPageTitleCondition(json.cruise_title)
      );
    }
  } else if (json.cruise_title) {
    condition = convertJsonToFilterPageTitleCondition(json.cruise_title);
  }

  return new FilterNodeForBrowseSite(
    condition,
    additionalConditions,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!),
    convertJsonValueToStayTime(json)
  );
}
