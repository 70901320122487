<template>
  <div class="filter-date-hour-form">
    <DatePickerInput
      v-model="date"
      :enabled-period="enabledPeriod"
      class="date-picker"
    />

    <SelectBox v-model="hour" width="110px" :options="hourOptions" />
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import { hourlyIntervalsSelectOpitions } from "@/util/date-util";
import { enabledPeriodRange } from "@/util/date-range-picker-util";
import { DateRange } from "@/components/date-picker/DateRange";
import DatePickerInput from "@/components/date-picker/DatePickerInput.vue";

@Component({
  components: {
    DatePickerInput,
    SelectBox
  }
})
export default class FilterDateHourField extends Vue {
  @Model("input", { type: FilterDateHourCondition, required: true })
  condition!: FilterDateHourCondition;

  onInput(condition: FilterDateHourCondition) {
    this.$emit("input", condition);
  }

  get enabledPeriod(): DateRange {
    return enabledPeriodRange();
  }

  // 時間帯Selectのオプション
  get hourOptions(): SelectOption[] {
    return hourlyIntervalsSelectOpitions(false);
  }

  get date(): Date {
    return this.condition.date;
  }
  set date(date: Date) {
    this.onInput(new FilterDateHourCondition(date, this.condition.hour));
  }

  get hour(): number {
    return this.condition.hour;
  }
  set hour(hour: number) {
    this.onInput(new FilterDateHourCondition(this.condition.date, hour));
  }
}
</script>
<style lang="scss" scoped>
.filter-date-hour-form {
  display: flex;
  align-items: center;
}
.date-picker {
  margin-right: 7px;
}
</style>
