<i18n
  src="@/i18n/components/segmented-trends/segmented-trends-event-table.json"
></i18n>
<template>
  <AnalysisTable class="segmented-trends-event-table">
    <template #header>
      <AnalysisTableHeaderRow>
        <AnalysisTableHeaderCell width="50%">{{
          $t("event")
        }}</AnalysisTableHeaderCell>
        <AnalysisTableHeaderCell>
          {{ $t("eventCount") }}
          <Tooltip :placement="tooltipPlacement.Bottom" :wrap="true">
            <Icon
              class="help-icon"
              :icon="Icons.HelpInvertWhite"
              :color="Colors.Base700"
              :size="10"
            />
            <template #content>
              <div v-t="'eventCountHelp'" class="help-text" />
            </template>
          </Tooltip>
        </AnalysisTableHeaderCell>
        <AnalysisTableHeaderCell>
          {{ $t("uniqueUsers") }}
        </AnalysisTableHeaderCell>
      </AnalysisTableHeaderRow>
    </template>
    <AnalysisTableRow v-for="(row, index) in data" :key="index">
      <AnalysisTableCell class="url-cell" width="50%">
        <div class="url-cell__wrapper">
          <div class="url-cell__item">{{ row.key.key }}</div>
        </div>
      </AnalysisTableCell>
      <AnalysisTableCell class="number-cell">{{ row.count }}</AnalysisTableCell>
      <AnalysisTableCell class="number-cell">{{
        row.uniqueUsers
      }}</AnalysisTableCell>
    </AnalysisTableRow>
  </AnalysisTable>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import AnalysisTable from "@/components/table/AnalysisTable.vue";
import AnalysisTableRow from "@/components/table/AnalysisTableRow.vue";
import AnalysisTableCell from "@/components/table/AnalysisTableCell.vue";
import AnalysisTableHeaderRow from "@/components/table/AnalysisTableHeaderRow.vue";
import AnalysisTableHeaderCell from "@/components/table/AnalysisTableHeaderCell.vue";
import { SegmentedTrendResult } from "@/models/segmented-trends/SegmentedTrendResult";

@Component({
  components: {
    Icon,
    Tooltip,
    AnalysisTable,
    AnalysisTableRow,
    AnalysisTableCell,
    AnalysisTableHeaderRow,
    AnalysisTableHeaderCell
  }
})
export default class SegmentedTrendsEventTable extends Vue {
  Icons = Icons;
  Colors = Colors;
  tooltipPlacement = TooltipPlacement;

  @Prop({ type: Array, required: true })
  data!: SegmentedTrendResult[];
}
</script>

<style scoped lang="scss">
.url-cell {
  padding-right: 0;
  padding-left: 0;
  text-align: left;

  @include all-break-word();
}
.url-cell__wrapper {
  display: table;
  min-width: 100px;
  width: 100%;
}
.url-cell__item {
  position: relative;
  left: 20px;
  display: table-cell;
  padding: 10px, auto, 8px, 0px;
  height: 24px;
  vertical-align: middle;
}
.number-cell {
  text-align: right;
}
.help-text {
  width: 200px;
  white-space: normal;
}
</style>
