<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseSelectBox
    :value="appLaunchType"
    :options="options"
    :prefix="prefix"
    :suffix="suffix"
    class="filter-app-launch-form"
    @input="onInput"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import FilterBaseSelectBox from "@/components/filter/form/FilterBaseSelectBox.vue";
import { AppLaunchType } from "@/models/search/filter-node/FilterNodeForLaunchApp";
import { AppLaunchTypeOptions } from "@/components/filter/form/FilterSelectOptions";

@Component({
  components: {
    FilterBaseSelectBox
  }
})
export default class FilterAppLaunchField extends Vue {
  options = AppLaunchTypeOptions();

  @Model("input", { type: Number, required: true })
  appLaunchType!: AppLaunchType;

  onInput(appLaunchType: AppLaunchType) {
    this.$emit("input", appLaunchType);
  }

  get prefix(): string {
    if (this.$i18n.te("appLaunchPrefix") && this.isLaunch) {
      return this.$i18n.t("appLaunchPrefix") as string;
    }
    return "";
  }

  get suffix(): string {
    if (this.$i18n.te("appLaunchSuffix") && this.isLaunch) {
      return this.$i18n.t("appLaunchSuffix") as string;
    }
    return "";
  }

  get isLaunch(): boolean {
    return (
      this.appLaunchType === AppLaunchType.PushNotification ||
      this.appLaunchType === AppLaunchType.Link
    );
  }
}
</script>
