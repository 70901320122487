import axios from "axios";
import { ActionTree, MutationTree } from "vuex";
import { TdConversionRequest } from "@/api/apis/client-settings/ApiTdConversion";
import TdConversionDefinition from "@/models/client-settings/TdConversionDefinition";
import { RootState } from "@/store";

export class TdConversionState {
  tdConversionDefinitions: TdConversionDefinition[] = [];
  selectedTdConversionDefinition: TdConversionDefinition | null = null;
  successMessage: string = "";
}

const mutations: MutationTree<TdConversionState> = {
  setTdConversionDefinitions(
    state: TdConversionState,
    tdConversionDefinitions: TdConversionDefinition[]
  ) {
    state.tdConversionDefinitions = tdConversionDefinitions;
  },

  setSelectedTdConversionDefinition(
    state: TdConversionState,
    tdConversionDefinition: TdConversionDefinition
  ) {
    state.selectedTdConversionDefinition = tdConversionDefinition;
  },

  setSuccessMessage(state: TdConversionState, message: string) {
    state.successMessage = message;
  }
};

const actions: ActionTree<TdConversionState, RootState> = {
  async fetchTdConversions({ commit, rootState }) {
    const client = rootState.client.client;
    const hasTdImportContract = client?.isContractTdImport || false;

    if (hasTdImportContract) {
      const response = await rootState.api.tdConversion.getTdConversionList();
      const tdConversionDefinitions = response.map(
        TdConversionDefinition.fromJson
      );
      commit("setTdConversionDefinitions", tdConversionDefinitions);
    }
  },

  async fetchTdConversionById({ commit, rootState }, id: number) {
    try {
      const res = await rootState.api.tdConversion.getTdConversionById(id);
      const tdConversionDefinition = TdConversionDefinition.fromJson(res);
      commit("setSelectedTdConversionDefinition", tdConversionDefinition);
    } catch (e) {
      if (!axios.isAxiosError(e) || !e.response || e.response.status !== 404) {
        throw e;
      }
    }
  },

  async createTdConversion({ rootState }, params: TdConversionRequest) {
    await rootState.api.tdConversion.createTdConversion(params);
  },

  async updateTdConversion(
    { rootState },
    payload: { id: number; params: TdConversionRequest }
  ) {
    await rootState.api.tdConversion.updateTdConversion(
      payload.id,
      payload.params
    );
  }
};

export const tdConversion = {
  namespaced: true,
  state: new TdConversionState(),
  mutations: mutations,
  actions: actions
};
