<template>
  <FilterBaseInputTextMatchType
    :value="pageTitle"
    :match-method="matchMethod"
    :is-wild-card-option-enable="true"
    :placeholder="placeholder"
    :input-form-suggestion-type="inputFormSuggestionType"
    class="filter-page-title-form"
    data-cy="filter-page-title-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterPageTitleCondition } from "@/models/search/filter-node-condition/FilterPageTitleCondition";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";
import { i18n } from "@/i18n";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterPageTitleField extends Vue {
  @Model("input", { type: FilterPageTitleCondition, required: true })
  condition!: FilterPageTitleCondition;

  inputFormSuggestionType = InputFormSuggestionType.WEB_TITLE;

  onInput(condition: FilterPageTitleCondition) {
    this.$emit("input", condition);
  }

  get pageTitle(): string {
    return this.condition.pageTitle;
  }

  get matchMethod(): MatchMethod {
    return this.condition.matchMethod;
  }

  get placeholder(): string {
    if (this.condition.matchMethod == MatchMethod.Wildcard) {
      return i18n.t(
        "components.search.filter.pageTitleWildcardPlaceholderText"
      ) as string;
    }
    return "";
  }

  onInputText(pageTitle: string) {
    this.onInput(
      new FilterPageTitleCondition(pageTitle, this.condition.matchMethod)
    );
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(
      new FilterPageTitleCondition(this.condition.pageTitle, matchMethod)
    );
  }
}
</script>
