import {
  MapCvAttributeWithCvAttributeRequest,
  MapCvAttributeRequest
} from "@/api/apis/client-settings/ApiMapConversionAttribute";
import {
  MapConversionAttributeDefinition,
  MapCvAttributeDefinitionListWithCvAttribute
} from "@/models/client-settings/MapConversionAttributeDefinition";
import { CreationMethod } from "@/models/client-settings/Coordination";
import { RootState } from "@/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";

export class MapConversionAttributeState {
  mapConversionAttributeList: MapConversionAttributeDefinition[] = [];
  newRegisteredMapConversionAttributes: MapConversionAttributeDefinition[] = [];
  isFetched: boolean = false;
}

const mutations: MutationTree<MapConversionAttributeState> = {
  setMapConversionAttributes(
    state: MapConversionAttributeState,
    mapConversionAttributeList: MapConversionAttributeDefinition[]
  ) {
    state.mapConversionAttributeList = mapConversionAttributeList;
  },

  setIsFetched(state: MapConversionAttributeState, isFetched: boolean) {
    state.isFetched = isFetched;
  },

  setCreatedMapConversionAttribute(
    state: MapConversionAttributeState,
    mapConversionAttributes: MapConversionAttributeDefinition[]
  ) {
    state.newRegisteredMapConversionAttributes = mapConversionAttributes;
  }
};

const getters = <GetterTree<MapConversionAttributeState, RootState>>{
  activeMapConversionAttributes(state): MapConversionAttributeDefinition[] {
    return state.mapConversionAttributeList.filter(
      cvAttribute => !cvAttribute.isDisabled
    );
  },
  disabledMapConversionAttributes(state): MapConversionAttributeDefinition[] {
    return state.mapConversionAttributeList.filter(
      cvAttribute => cvAttribute.isDisabled
    );
  }
};

const actions: ActionTree<MapConversionAttributeState, RootState> = {
  async registerNewCvAttributeAndMapCvAttribute(
    { commit, rootState },
    def: MapCvAttributeDefinitionListWithCvAttribute
  ) {
    let response;
    if (def.creationMethod == CreationMethod.NEW) {
      const params: MapCvAttributeWithCvAttributeRequest = {
        conversion_attribute_name: def.conversionAttributeName,
        conversion_attribute_type: def.conversionAttributeType,
        parameters: def.parameters
      };
      response = await rootState.api.mapConversionAttribute.registerMapCvAttributeWithCvAttribute(
        params,
        def.coordinationId
      );
    } else {
      const params: MapCvAttributeRequest = {
        parameters: def.parameters
      };
      response = await rootState.api.mapConversionAttribute.registerMapConversionAttribute(
        params,
        def.coordinationId,
        def.conversionAttributeId
      );
    }

    commit("setCreatedMapConversionAttribute", response);
  }
};

export const mapConversionAttribute = {
  namespaced: true,
  state: new MapConversionAttributeState(),
  mutations,
  actions,
  getters
};
