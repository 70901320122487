export enum BUTTON_TYPE {
  DARK = "dark",
  LIGHT = "light",
  LIGHT_BLUE = "lightBlue"
}

export enum ButtonTextSize {
  Tiny = "tiny",
  Small = "small",
  Medium = "medium"
}

export const BUTTON_TEXT_TINY_FONT_SIZE = 12;
export const BUTTON_TEXT_SMALL_FONT_SIZE = 14;
export const BUTTON_TEXT_MEDIUM_FONT_SIZE = 16;

export const getFontSize = (size: ButtonTextSize) => {
  if (size === ButtonTextSize.Tiny) {
    return `${BUTTON_TEXT_TINY_FONT_SIZE}px`;
  }

  if (size === ButtonTextSize.Small) {
    return `${BUTTON_TEXT_SMALL_FONT_SIZE}px`;
  }

  return `${BUTTON_TEXT_MEDIUM_FONT_SIZE}px`;
};
