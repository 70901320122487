<i18n src="@/i18n/views/tour-index.json"></i18n>
<template>
  <div class="tourIndex">
    <div class="tourIndex__header">
      <div class="tourIndex__titleContainer">
        <Icon :icon="iconTour" :size="iconSize" />
        <span v-t="'title'" class="tourIndex__title" />
        <span class="tourIndex__beta">BETA</span>
      </div>
      <div v-t="'subTitle'" class="tourIndex__subTitle" />
    </div>

    <div class="tourIndex__content">
      <TourIndexNotification />

      <div class="tourIndex__itemContainer">
        <div
          v-for="(tour, i) in tours"
          :key="`tour${i}`"
          class="tourIndex__item"
        >
          <TourIndexItem :tour="tour" :disabled="!isWhiteListedTour(tour.id)" />
        </div>
        <div class="tourIndex__item">
          <UpcomingTour />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import UpcomingTour from "@/components/tours/UpcomingTour.vue";
import TourIndexItem from "@/components/tours/TourIndexItem.vue";
import TourIndexNotification from "@/components/tours/TourIndexNotification.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import {
  TourCondition,
  INQUIRY_TOUR_ID,
  ERROR_TOUR_ID,
  MULTI_INFLOW_TOUR_ID
} from "@/models/tour/TourCondition";

@Component({
  components: {
    UpcomingTour,
    TourIndexItem,
    TourIndexNotification,
    Icon
  }
})
export default class TourIndex extends Vue {
  iconTour = Icons.Binocular;
  iconSize = 16;

  get whitelist(): { [key: string]: boolean } {
    return {
      [INQUIRY_TOUR_ID]: [3, 32, 44, 220, 1001].includes(this.clientId),
      [ERROR_TOUR_ID]: this.$store.state.app.canUseErrorTourFeatures,
      [MULTI_INFLOW_TOUR_ID]: this.$store.state.app
        .canUseMultiInflowTourFeatures
    };
  }

  get tours(): TourCondition[] {
    return this.$store.getters["tour/tours"];
  }

  isWhiteListedTour(tourId: string): boolean {
    if (!tourId || !(tourId in this.whitelist)) return false;
    return this.whitelist[tourId];
  }

  get clientId(): number {
    return this.$store.state.client.client.id;
  }
}
</script>

<style lang="scss" scoped>
$itemWidth: 296px;
$itemPaddingInRow: 16px;
$itemsWidthIncludeMargin: calc(3 * #{$itemWidth} + 2 * #{$itemPaddingInRow});

.tourIndex {
  padding: 38px $appContentPadding;
  min-width: calc(#{$itemsWidthIncludeMargin} + 2 * #{$appContentPadding});
  min-height: 100vh;
  background: $colorGray100;
}

.tourIndex__header {
  text-align: center;
}

.tourIndex__titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tourIndex__title {
  margin-left: 10px;
  font-weight: 700;
  font-size: 18px;
}

.tourIndex__beta {
  margin-left: 6px;
  color: $colorBase600;
  font-weight: 400;
  font-size: 12px;
}

.tourIndex__subTitle {
  margin: 21px auto 0;
  width: 545px;
  color: $colorBase700;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.tourIndex__content {
  margin: 20px auto 0;
  width: $itemsWidthIncludeMargin;
}

.tourIndex__itemContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.tourIndex__item {
  margin-bottom: 40px;
  width: $itemWidth;

  &:nth-child(3n + 2) {
    margin-right: $itemPaddingInRow;
    margin-left: $itemPaddingInRow;
  }
}
</style>
