<i18n src="@/i18n/views/memo-user.json"></i18n>
<template>
  <div class="memo-user">
    <SearchResultContainer
      :view="searchResultView"
      :label="$t('notesList')"
      :icon="Icons.Memo"
      :is-show-empty-description="hasUserId"
      @view-change="onViewChange"
    />
    <div
      v-if="memosFetched && !hasUserId"
      class="memo-user__onboarding"
      data-cy="memo-user__onboarding"
    >
      <OnBoardingJa
        v-if="locale === AvailableLanguages.ja"
        class="memo-user__onboarding-svg"
      />
      <OnBoardingZhHant
        v-else-if="locale === AvailableLanguages['zh-hant']"
        class="memo-user__onboarding-svg"
      />
      <OnBoardingZhHans
        v-else-if="locale === AvailableLanguages['zh-hans']"
        class="memo-user__onboarding-svg"
      />
      <OnBoardingEn v-else class="memo-user__onboarding-svg-en" />

      <LinkText
        class="memo-user__onboarding-link-wrapper"
        url="https://help.usergram.info/article/310-memo"
        :link-text="$t('helpSiteLink')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { handleNoQueryCacheError } from "@/util/error-util";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { Memo } from "@/models/userdata/Memo";
import { Icons } from "@/const/Icons";
import { SearchResultViews } from "@/const/SearchResultViews";
import { AvailableLanguages } from "@/i18n/index";
import Icon from "@/components/Icon.vue";
import LinkText from "@/components/LinkText.vue";

import OnBoardingJa from "@/assets/svg/onboarding/no-memo-ja.svg";
import OnBoardingEn from "@/assets/svg/onboarding/no-memo-en.svg";
import OnBoardingZhHant from "@/assets/svg/onboarding/no-memo-zh-hant.svg";
import OnBoardingZhHans from "@/assets/svg/onboarding/no-memo-zh-hans.svg";
import {
  executeSearchByHistoryId,
  createQueryWithViewAndHid,
  executeSearchByUserIds
} from "@/util/user-search-util";
import * as Sentry from "@sentry/browser";

@Component({
  components: {
    SearchResultContainer,
    Icon,
    LinkText,
    OnBoardingJa,
    OnBoardingEn,
    OnBoardingZhHant,
    OnBoardingZhHans
  }
})
export default class MemoUser extends Vue {
  @Watch("userIds")
  onChangeUserIds(ids: string[], oldIds: string[]) {
    // This comparison is necessary
    // not to call following when initial load
    if (ids.length === oldIds.length) return;
    this.isUserIdsUpdated = true;
    if (!this.hasUserId) {
      this.memosFetched = true;
    }
  }

  @Watch("isUserIdsUpdated")
  @Watch("showUserDetail")
  async reRenderUserResult() {
    await this.$nextTick();

    if (this.showUserDetail || !this.isUserIdsUpdated) return;

    this.isUserIdsUpdated = false;
    executeSearchByUserIds(this.userIds);
  }

  Icons = Icons;
  AvailableLanguages = AvailableLanguages;
  searchResultView = SearchResultViews.Memo;
  memosFetched: boolean = false;
  isUserIdsUpdated = false;

  async created() {
    const view = this.$route.query.view;
    switch (view) {
      case SearchResultViews.Attribute:
        this.searchResultView = SearchResultViews.Attribute;
        break;
      case SearchResultViews.Overview:
        this.searchResultView = SearchResultViews.Overview;
        break;
      default:
        this.searchResultView = SearchResultViews.Memo;
    }

    if (this.historyMemos.length === 0) {
      await this.fetchHistoryMemos();
    }

    if (!this.hasUserId) {
      this.$store.dispatch("search/clearForEmptyState");
    } else {
      if (this.$route.query.hid) {
        await executeSearchByHistoryId(Number(this.$route.query.hid));
      } else {
        const selectCondition = new SelectByUserIdCondition(this.userIds);
        this.$store
          .dispatch("search/executeSelect", { selectCondition })
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
      }
    }
  }

  async fetchHistoryMemos() {
    try {
      // 検索結果画面からメモ履歴へ移動してくると検索結果が表示されてしまうので
      // メモ履歴の表示前先に初期化するのが必要
      this.$store.dispatch("search/initializeBeforeSearch");
      await this.$store.dispatch("history/fetchMemos");
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.memosFetched = true;
    }
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
    const query = createQueryWithViewAndHid(view);
    this.$router.replace({ query });
  }

  get locale(): string {
    return this.$i18n.locale;
  }

  get userIds(): string[] {
    return this.historyMemos.map(m => m.userId);
  }

  get historyMemos(): Memo[] {
    return this.$store.state.history.memos.filter(
      (m: Memo) => m.content !== ""
    );
  }

  get hasUserId(): boolean {
    return this.userIds.length > 0;
  }

  get showUserDetail(): boolean {
    return this.$store.state.user.showUserDetail;
  }
}
</script>

<style lang="scss" scoped>
.memo-user__onboarding {
  position: relative;
  padding: 40px 20px 0 20px;
  text-align: center;
}
.memo-user__onboarding-svg {
  width: 960px;
  height: 361px;
}

.memo-user__onboarding-svg-en {
  width: 960px;
  height: 358px;
}

.memo-user__onboarding-link-wrapper {
  position: absolute;
  bottom: 46px;
  left: calc(50% - 40px);
  transform: translate(-100%, 0);
}
</style>
