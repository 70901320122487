import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";
import SelectOption from "@/components/form/SelectOption";
import { ContactSubjectType } from "@/models/search/filter-node/FilterNodeForContact";

/**
 * 入口ページタイトルによる条件
 *
 * 絞り込み条件に追加で、入口ページタイトル（部分一致）を条件として付与できる
 */
export class FilterContactCondition {
  public readonly conditionType = FilterNodeConditionType.Contact;

  constructor(
    // 行動履歴定義を指定
    public readonly contactDefinitionId: number,
    // 通話時間もチェックするか
    public readonly isCheckTalkTime: boolean,
    // 指定した数値分以上の通話に絞る
    public readonly talkMin: number,

    public subjectType?: number
  ) {}

  get validate(): ValidationResult {
    // 通話時間をチェックしないのであればチェック不要
    if (!this.isCheckTalkTime) {
      return { isValid: true };
    }

    // 通話時間のチェックありなら通話時間をチェックする
    if (this.talkMin <= 0) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.enterCallDuration") as string
      };
    }
    return { isValid: true };
  }

  canSelectSubjectType(data_type: ContactDefinitionType): boolean {
    return (
      data_type === ContactDefinitionType.TEL ||
      data_type === ContactDefinitionType.MAIL ||
      data_type === ContactDefinitionType.DM
    );
  }

  getOptionsForContactSubjectType(
    data_type: ContactDefinitionType
  ): SelectOption[] {
    if (data_type === ContactDefinitionType.TEL) {
      return [
        {
          label: i18n.t("components.search.filter.allCall") as string,
          value: ContactSubjectType.All,
          disabled: false
        },
        {
          label: i18n.t("components.search.filter.customerCall") as string,
          value: ContactSubjectType.InBound,
          disabled: false
        },
        {
          label: i18n.t("components.search.filter.companyCall") as string,
          value: ContactSubjectType.OutBound,
          disabled: false
        }
      ];
    }
    if (data_type === ContactDefinitionType.MAIL) {
      return [
        {
          label: i18n.t("components.search.filter.allMail") as string,
          value: ContactSubjectType.All,
          disabled: false
        },
        {
          label: i18n.t("components.search.filter.customerMail") as string,
          value: ContactSubjectType.InBound,
          disabled: false
        },
        {
          label: i18n.t("components.search.filter.companyMail") as string,
          value: ContactSubjectType.OutBound,
          disabled: false
        }
      ];
    }
    if (data_type === ContactDefinitionType.DM) {
      return [
        {
          label: i18n.t("components.search.filter.allDocument") as string,
          value: ContactSubjectType.All,
          disabled: false
        },
        {
          label: i18n.t("components.search.filter.customerDocument") as string,
          value: ContactSubjectType.InBound,
          disabled: false
        },
        {
          label: i18n.t("components.search.filter.companyDocument") as string,
          value: ContactSubjectType.OutBound,
          disabled: false
        }
      ];
    }

    return [];
  }
}
