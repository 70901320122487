<template>
  <div class="filter-node-item" data-cy="filter-node-item">
    <SelectBox
      class="condition-type"
      width="180px"
      :value="conditionType"
      :options="options"
      @input="onInput"
    />
    <div>
      <slot />
    </div>
    <IconButton
      v-if="isDeletable"
      class="remove"
      :icon="icons.ButtonClose"
      :size="20"
      :button-type="IconButtonType.Weak"
      @click="onClickRemove(conditionType)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";

@Component({
  name: "FilterNodeItem",
  components: {
    IconButton,
    SelectBox
  }
})
export default class FilterNodeItem extends Vue {
  icons = Icons;
  IconButtonType = IconButtonType;

  @Model("input", { type: Number, required: true })
  conditionType!: FilterNodeConditionType;

  @Prop({ type: Array, required: true })
  options!: SelectOptionGroup[];

  @Prop({ type: Boolean, default: true })
  isDeletable!: boolean;

  onClickRemove(conditionType: FilterNodeConditionType) {
    this.$emit("remove", conditionType);
  }

  onInput(conditionType: FilterNodeConditionType) {
    this.$emit("input", conditionType);
  }
}
</script>
<style lang="scss" scoped>
.filter-node-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.condition-type {
  margin-right: 10px;
}
.remove {
  margin-left: 10px;
}
</style>
