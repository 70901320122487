var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"filterConditionForm"},[_c('div',{staticClass:"filterConditionForm__dragArea"},[_c('FilterPeriod',{staticClass:"filterConditionForm__filterPeriod",attrs:{"period-days":_vm.filterCondition.periodDays},on:{"input":_vm.onFilterPeriod}}),_c('FilterDevice',{staticClass:"filterConditionForm__filterDevice",attrs:{"values":_vm.filterCondition.deviceTypes},on:{"input":_vm.onFilterDevice}}),(_vm.filterCondition.filterNodes.length === 0)?_c('FilterConditionFormDropArea',{staticClass:"filterConditionForm__emptyDragArea",attrs:{"height":"400px"},on:{"drop":function($event){return _vm.handleDrop(0, _vm.draggingNodeType)}}},[_vm._v(" "+_vm._s(_vm.$t("userActivity"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$te("dragHere") ? _vm.$t("dragHere") : "")+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$te("dragHere") ? _vm.$t("upToFive") : "")+" "),_c('img',{staticClass:"tips-drag-node",attrs:{"src":require("@/assets/img/home/tips-drag-nodes.gif")}})]):_vm._e(),_c('div',{staticClass:"filterConditionForm__activeDragArea"},[(
          _vm.getNodeDragAreaVisibility(
            _vm.filterCondition.filterNodes.length,
            0,
            _vm.isDragging,
            true
          )
        )?_c('FilterConditionFormDropArea',{staticClass:"filterConditionForm__topDragArea",on:{"drop":function($event){return _vm.handleDrop(0, _vm.draggingNodeType)}}},[_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('userActivity_html'))}}),_vm._v(_vm._s(_vm.$te("dragHere") ? _vm.$t("dragHere") : "")+" ")])]):_vm._e(),_vm._l((_vm.filterCondition.filterNodes),function(filterNode,index){return _c('div',{key:index,staticClass:"filterConditionForm__dragAreaNode"},[(
            !_vm.isNodeExcluded(index) ||
              index === 0 ||
              index === _vm.filterCondition.filterNodes.length - 1
          )?_c('FilterNodeFactory',{attrs:{"filter-node":filterNode,"index":index,"input-handler":node => _vm.onUpdateNode(node, index),"is-dragging":_vm.isDragging,"exclusion":_vm.getExclusionProperties(_vm.filterCondition.filterNodes, index)},on:{"add-or-node":function($event){return _vm.handleDrop(index, $event, 'orNode')},"remove-node":function($event){return _vm.handleNodeRemoval(index, 0, 0)},"remove-child-node":function($event){return _vm.handleNodeRemoval(index, $event, 1)}}}):_vm._e(),(filterNode.edge)?_c('FilterNodeInterElement',{attrs:{"edge":filterNode.edge,"index":index,"is-exclusion-checkbox-enabled":_vm.indexOfEnableExcludeCondition === index},on:{"update-edge":function($event){return _vm.onUpdateEdge(index, $event)},"add-exclusion-node":function($event){return _vm.handleDrop(index + 1, $event, 'excludeNode')},"remove-exclusion-node":function($event){return _vm.handleNodeRemoval(index + 1, 0, 0)}},scopedSlots:_vm._u([(_vm.isNodeExcluded(index + 1))?{key:"middleExclusionNode",fn:function(){return [_c('FilterNodeFactory',{attrs:{"filter-node":_vm.filterCondition.filterNodes[index + 1],"index":index + 1,"input-handler":node => _vm.onUpdateNode(node, index + 1),"is-dragging":_vm.isDragging,"exclusion":_vm.getExclusionProperties(_vm.filterCondition.filterNodes, index + 1)},on:{"add-or-node":function($event){return _vm.handleDrop(index + 1, $event, 'orNode')},"remove-node":function($event){return _vm.handleNodeRemoval(index + 1, 0, 0)},"remove-child-node":function($event){return _vm.handleNodeRemoval(index + 1, $event, 1)}}})]},proxy:true}:null],null,true)}):_vm._e(),(
            _vm.getNodeDragAreaVisibility(
              _vm.filterCondition.filterNodes.length,
              index,
              _vm.isDragging
            ) && !_vm.isNodeExcluded(index + 1)
          )?_c('FilterConditionFormDropArea',{staticClass:"filterConditionForm__bottomDragArea",class:{
            'filterConditionForm__bottomDragArea--afterMiddleExclusion': _vm.isAfterMiddleExclusion(
              index
            ),
            'filterConditionForm__bottomDragArea--last':
              _vm.filterCondition.filterNodes.length - 1 === index
          },on:{"drop":function($event){return _vm.handleDrop(index + 1, _vm.draggingNodeType)}}},[_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('userActivity_html'))}}),_vm._v(_vm._s(_vm.$te("dragHere") ? _vm.$t("dragHere") : "")+" ")])]):_vm._e()],1)})],2)],1),_c('div',{staticClass:"filterConditionForm__typeContainer customScrollbar",attrs:{"data-cy":"filter-condition-form__type-list"}},[_vm._l((_vm.filterList),function(filter,index){return [(_vm.isTitle(filter.type))?_c('TitleTextWithIcon',{key:index,staticClass:"filterConditionForm__name",attrs:{"title":filter.title,"icon":_vm.iconConversion}}):_c('div',{key:index,staticClass:"filterConditionForm__type",class:{
          'filterConditionForm__type--dragging':
            _vm.draggingNodeType === filter.type
        },attrs:{"draggable":"true","data-cy":"filter-condition-form__type-list-item"},on:{"dragstart":function($event){return _vm.onDragStart($event.dataTransfer, filter.type)},"dragend":_vm.onDragEnd}},[_vm._v(" "+_vm._s(filter.title)+" ")])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }