<i18n src="@/i18n/views/segmented-trends.json"></i18n>
<template>
  <div class="segmented-trends">
    <SegmentedTrendsButton :is-disabled="isDisabled" @click="onClickButton" />
    <Dialog
      :visible="showSegmentedTrends"
      data-cy="segmented-trends__modal"
      top="5vh"
      width="80vw"
      class="segmented-trends__modal"
      @open="fetchAllResults"
      @close="onCloseModal"
    >
      <template #title>
        <div class="segmented-trends__description">{{
          $t("segmentedTrendsDescription")
        }}</div>
      </template>
      <SegmentedTrendsTabs
        :can-use-webdata-features="canUseWebdataFeatures"
        :is-contract-app="isContractApp"
        :has-event-definitions="hasEventDefinitions"
        :selected-tab="currentTab"
        @changeTab="onChangeTab"
      />
      <div v-if="!isEmptyData" class="segmented-trends__header">
        <div class="segmented-trends__pager-wrapper">
          <AnalysisToolsPagerSelect
            :value="resultsPerPage"
            :select-options="selectOptions"
            @input="onSelectPerPage"
          />
          <AnalysisToolsPagerNav
            class="segmented-trends__pager-nav"
            :current-page="currentPage"
            :last-page="lastPage"
            @click="onClickPaging"
          />
        </div>
        <div class="segmented-trends__label-and-csv">
          <SegmentedTrendsDateLabel
            class="segmented-trends__date-label"
            :select-condition="selectCondition"
          />
          <CsvDownloadButton
            class="segmented-trends__csv-download"
            :disabled="false"
            :is-downloading="csvDownloading"
            @click="downloadCsv"
          />
        </div>
      </div>
      <Loading v-if="isLoading" height="200px" />
      <AnalysisToolsBackButton
        v-else-if="!isInflow"
        class="segmented-trends__inflow-back-button"
        :page-type="AnalysisToolsDetailType.Inflow"
        :content="domain"
        @click="toInflow"
      />
      <div
        v-if="!isLoading && isEmptyData"
        v-t="'noCorrespondingData'"
        class="empty-text"
      />
      <SegmentedTrendsWebViewTable
        v-if="currentTab === SegmentedTrendsTab.webView && !isEmptyData"
        :data="webViewData"
      />
      <SegmentedTrendsLandingTable
        v-if="currentTab === SegmentedTrendsTab.landing && !isEmptyData"
        :data="landingData"
      />
      <SegmentedTrendsInflowTable
        v-if="currentTab === SegmentedTrendsTab.inflow && !isEmptyData"
        :is-inflow="isInflow"
        :data="inflowData"
        @urlClicked="toInflowDetail"
      />
      <SegmentedTrendsAppViewTable
        v-if="currentTab === SegmentedTrendsTab.appView && !isEmptyData"
        :data="appViewData"
      />
      <SegmentedTrendsEventTable
        v-if="currentTab === SegmentedTrendsTab.event && !isEmptyData"
        :data="eventData"
      />
      <SegmentedTrendsConversionTable
        v-if="currentTab === SegmentedTrendsTab.conversion && !isEmptyData"
        :data="conversionData"
      />
      <div v-if="!isEmptyData" class="segmented-trends__footer">
        <AnalysisToolsPagerSelect
          :value="resultsPerPage"
          :select-options="selectOptions"
          @input="onSelectPerPage"
        />
        <AnalysisToolsPagerNav
          class="segmented-trends__pager-nav"
          :current-page="currentPage"
          :last-page="lastPage"
          @click="onClickPaging"
        />
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import SegmentedTrendsButton from "@/components/segmented-trends/SegmentedTrendsButton.vue";
import SegmentedTrendsTabs from "@/components/segmented-trends/SegmentedTrendsTabs.vue";
import SegmentedTrendsDateLabel from "@/components/segmented-trends/SegmentedTrendsDateLabel.vue";
import { SegmentedTrendResult } from "@/models/segmented-trends/SegmentedTrendResult";
import SegmentedTrendsWebViewTable from "@/components/segmented-trends/SegmentedTrendsWebViewTable.vue";
import SegmentedTrendsLandingTable from "@/components/segmented-trends/SegmentedTrendsLandingTable.vue";
import SegmentedTrendsInflowTable from "@/components/segmented-trends/SegmentedTrendsInflowTable.vue";
import SegmentedTrendsAppViewTable from "@/components/segmented-trends/SegmentedTrendsAppViewTable.vue";
import SegmentedTrendsEventTable from "@/components/segmented-trends/SegmentedTrendsEventTable.vue";
import SegmentedTrendsConversionTable from "@/components/segmented-trends/SegmentedTrendsConversionTable.vue";
import AnalysisToolsBackButton from "@/components/analysis-tools/AnalysisToolsBackButton.vue";
import AnalysisToolsPagerSelect from "@/components/analysis-tools/AnalysisToolsPagerSelect.vue";
import AnalysisToolsPagerNav from "@/components/analysis-tools/AnalysisToolsPagerNav.vue";
import { SegmentedTrendsTab } from "@/const/segmented-trends";
import { SegmentedTrendsSelectType } from "@/const/segmented-trends";
import { AnalysisToolsDetailType } from "@/const/analysis-tools";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { validateDate } from "@/models/segmented-trends/SegmentedTrend";
import Loading from "@/components/Loading.vue";
import { handleNoQueryCacheError } from "@/util/error-util";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import SelectOption from "@/components/form/SelectOption";
import CsvDownloadButton from "@/components/CsvDownloadButton.vue";
import * as Sentry from "@sentry/browser";

const ANALYSIS_NAME_MAP = {
  webView: "pageAnalysis",
  landing: "landingPageAnalysis",
  inflow: "inflowAnalysis",
  appView: "appViewAnalysis",
  event: "eventAnalysis",
  conversion: "conversionAnalysis"
};

@Component({
  components: {
    Tooltip,
    Icon,
    Dialog,
    SegmentedTrendsButton,
    SegmentedTrendsTabs,
    SegmentedTrendsDateLabel,
    SegmentedTrendsWebViewTable,
    SegmentedTrendsLandingTable,
    SegmentedTrendsInflowTable,
    SegmentedTrendsAppViewTable,
    SegmentedTrendsEventTable,
    SegmentedTrendsConversionTable,
    AnalysisToolsBackButton,
    AnalysisToolsPagerSelect,
    AnalysisToolsPagerNav,
    Loading,
    CsvDownloadButton
  }
})
export default class SegmentedTrends extends Vue {
  showSegmentedTrends = false;
  SegmentedTrendsTab = SegmentedTrendsTab;
  AnalysisToolsDetailType = AnalysisToolsDetailType;
  //inflow(流入元)の表示がinflow-detailか否か(inflow-detailの場合はfalse)
  isInflow: boolean = true;
  Colors = Colors;
  Icons = Icons;
  IconSize: number = 20;
  nameMap = ANALYSIS_NAME_MAP;

  //'表示する行数'の選択内容をタブ毎にstoreへ保存
  onSelectPerPage(perPage: number) {
    try {
      switch (this.currentTab) {
        case SegmentedTrendsTab.webView:
          this.$store.commit("segmentedTrends/webView/setPerPage", perPage);
          this.$store.commit("segmentedTrends/webView/setCurrentPage", 1);
          this.$store.dispatch("segmentedTrends/webView/fetchResults");
          break;
        case SegmentedTrendsTab.landing:
          this.$store.commit("segmentedTrends/landing/setPerPage", perPage);
          this.$store.commit("segmentedTrends/landing/setCurrentPage", 1);
          this.$store.dispatch("segmentedTrends/landing/fetchResults");
          break;
        case SegmentedTrendsTab.inflow:
          if (this.isInflow) {
            this.$store.commit("segmentedTrends/inflow/setPerPage", perPage);
            this.$store.commit("segmentedTrends/inflow/setCurrentPage", 1);
            this.$store.dispatch("segmentedTrends/inflow/fetchResults");
          } else {
            this.$store.commit(
              "segmentedTrends/inflowDetail/setPerPage",
              perPage
            );
            this.$store.commit(
              "segmentedTrends/inflowDetail/setCurrentPage",
              1
            );
            this.$store.dispatch("segmentedTrends/inflowDetail/fetchResults");
          }
          break;
        case SegmentedTrendsTab.appView:
          this.$store.commit("segmentedTrends/appView/setPerPage", perPage);
          this.$store.commit("segmentedTrends/appView/setCurrentPage", 1);
          this.$store.dispatch("segmentedTrends/appView/fetchResults");
          break;
        case SegmentedTrendsTab.event:
          this.$store.commit("segmentedTrends/event/setPerPage", perPage);
          this.$store.commit("segmentedTrends/event/setCurrentPage", 1);
          this.$store.dispatch("segmentedTrends/event/fetchResults");
          break;
        case SegmentedTrendsTab.conversion:
          this.$store.commit("segmentedTrends/conversion/setPerPage", perPage);
          this.$store.commit("segmentedTrends/conversion/setCurrentPage", 1);
          this.$store.dispatch("segmentedTrends/conversion/fetchResults");
          break;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  get canUseWebdataFeatures(): boolean {
    return this.$store.state.app.canUseWebdataFeatures;
  }

  //App契約してるか
  get isContractApp(): boolean {
    return this.$store.state.client.client.isContractApp;
  }

  //イベントを定義しているか
  get hasEventDefinitions(): boolean {
    return this.$store.state.clientSettings.activeEventDefinitions.length > 0;
  }

  get selectCondition(): SelectCondition | null {
    return this.$store.state.search.selectCondition;
  }

  get selectOptions(): SelectOption[] {
    return [
      { value: SegmentedTrendsSelectType.row20, label: "20", disabled: false },
      { value: SegmentedTrendsSelectType.row50, label: "50", disabled: false },
      {
        value: SegmentedTrendsSelectType.row100,
        label: "100",
        disabled: false
      },
      { value: SegmentedTrendsSelectType.row500, label: "500", disabled: false }
    ];
  }

  //'表示する行数'の読み込み
  get resultsPerPage(): SegmentedTrendsSelectType {
    switch (this.currentTab) {
      case SegmentedTrendsTab.webView:
        return this.$store.state.segmentedTrends.webView.perPage;
      case SegmentedTrendsTab.landing:
        return this.$store.state.segmentedTrends.landing.perPage;
      case SegmentedTrendsTab.inflow:
        return this.isInflow
          ? this.$store.state.segmentedTrends.inflow.perPage
          : this.$store.state.segmentedTrends.inflowDetail.perPage;
      case SegmentedTrendsTab.appView:
        return this.$store.state.segmentedTrends.appView.perPage;
      case SegmentedTrendsTab.event:
        return this.$store.state.segmentedTrends.event.perPage;
      case SegmentedTrendsTab.conversion:
        return this.$store.state.segmentedTrends.conversion.perPage;
      default:
        return this.$store.state.segmentedTrends.webView.perPage;
    }
  }

  get currentPage(): number {
    switch (this.currentTab) {
      case SegmentedTrendsTab.webView:
        return this.$store.state.segmentedTrends.webView.currentPage;
      case SegmentedTrendsTab.landing:
        return this.$store.state.segmentedTrends.landing.currentPage;
      case SegmentedTrendsTab.inflow:
        return this.isInflow
          ? this.$store.state.segmentedTrends.inflow.currentPage
          : this.$store.state.segmentedTrends.inflowDetail.currentPage;
      case SegmentedTrendsTab.appView:
        return this.$store.state.segmentedTrends.appView.currentPage;
      case SegmentedTrendsTab.event:
        return this.$store.state.segmentedTrends.event.currentPage;
      case SegmentedTrendsTab.conversion:
        return this.$store.state.segmentedTrends.conversion.currentPage;
      default:
        return this.$store.state.segmentedTrends.webView.currentPage;
    }
  }

  get lastPage(): number {
    //currentTabに対応した total_rows を取得
    return Math.ceil(this.totalRows / this.resultsPerPage);
  }

  get totalRows(): number {
    switch (this.currentTab) {
      case SegmentedTrendsTab.webView:
        return this.$store.state.segmentedTrends.webView.totalRows;
      case SegmentedTrendsTab.landing:
        return this.$store.state.segmentedTrends.landing.totalRows;
      case SegmentedTrendsTab.inflow:
        return this.isInflow
          ? this.$store.state.segmentedTrends.inflow.totalRows
          : this.$store.state.segmentedTrends.inflowDetail.totalRows;
      case SegmentedTrendsTab.appView:
        return this.$store.state.segmentedTrends.appView.totalRows;
      case SegmentedTrendsTab.event:
        return this.$store.state.segmentedTrends.event.totalRows;
      case SegmentedTrendsTab.conversion:
        return this.$store.state.segmentedTrends.conversion.totalRows;
      default:
        return this.$store.state.segmentedTrends.conversion.totalRows;
    }
  }

  get pageTitleForUGTag(): string {
    const pageTitleBase = this.$i18n.t("userTrendAnalysis") as string;
    const analysisName = this.$i18n.t(this.nameMap[this.currentTab]) as string;
    return pageTitleBase + " | " + analysisName;
  }

  get currentTab(): SegmentedTrendsTab {
    return this.$store.state.segmentedTrends.currentTab;
  }

  get availableTabs(): SegmentedTrendsTab[] {
    let tabs = [SegmentedTrendsTab.conversion];

    if (this.canUseWebdataFeatures) {
      tabs = tabs.concat([
        SegmentedTrendsTab.webView,
        SegmentedTrendsTab.landing,
        SegmentedTrendsTab.inflow
      ]);
    }

    if (this.isContractApp) {
      tabs = tabs.concat([SegmentedTrendsTab.appView]);
    }

    if (this.hasEventDefinitions) {
      tabs = tabs.concat([SegmentedTrendsTab.event]);
    }

    return tabs;
  }

  created() {
    if (this.canUseWebdataFeatures) {
      this.setDefaultTab(SegmentedTrendsTab.webView);
    } else if (this.isContractApp) {
      this.setDefaultTab(SegmentedTrendsTab.appView);
    }
  }

  onClickButton() {
    this.showSegmentedTrends = true;
    UgTag.pushEvent(UgEventTag.SegmentedTrendDisplay);
    UgTag.pushPv(this.pageTitleForUGTag);
  }

  onChangeTab(tab: SegmentedTrendsTab) {
    this.$store.commit("segmentedTrends/setCurrentTab", tab);
    this.isInflow = true;
    this.fetchResult(tab);
    UgTag.pushPv(this.pageTitleForUGTag);
  }

  onCloseModal() {
    this.showSegmentedTrends = false;
    UgTag.pushPv();
  }

  setDefaultTab(tab: SegmentedTrendsTab) {
    this.$store.commit("segmentedTrends/setCurrentTab", tab);
  }

  fetchAllResults() {
    this.isInflow = true;
    // 全てのタブのセグメント分析結果を並列で取得する
    // 複数のエラーがあった場合は１つ目のみ表示
    const requests = this.availableTabs.map(tab => this.fetchResult(tab));
    Promise.all(requests).catch(errors => {
      handleNoQueryCacheError(errors[0]);
      throw new Error(errors);
    });
  }

  fetchResult(tab: SegmentedTrendsTab) {
    switch (tab) {
      case SegmentedTrendsTab.webView:
        this.$store
          .dispatch("segmentedTrends/webView/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
        break;
      case SegmentedTrendsTab.landing:
        this.$store
          .dispatch("segmentedTrends/landing/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
        break;
      case SegmentedTrendsTab.inflow:
        this.$store
          .dispatch("segmentedTrends/inflow/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
        break;
      case SegmentedTrendsTab.appView:
        this.$store
          .dispatch("segmentedTrends/appView/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
        break;
      case SegmentedTrendsTab.event:
        this.$store
          .dispatch("segmentedTrends/event/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
        break;
      case SegmentedTrendsTab.conversion:
        this.$store
          .dispatch("segmentedTrends/conversion/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
        break;
      default:
        this.$store
          .dispatch("segmentedTrends/conversion/executeAnalysis")
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
    }
  }

  onClickPaging(value: number) {
    const page = this.currentPage + value;

    try {
      switch (this.currentTab) {
        case SegmentedTrendsTab.webView:
          this.$store.commit("segmentedTrends/webView/setCurrentPage", page);
          this.$store.dispatch("segmentedTrends/webView/fetchResults");
          break;
        case SegmentedTrendsTab.landing:
          this.$store.commit("segmentedTrends/landing/setCurrentPage", page);
          this.$store.dispatch("segmentedTrends/landing/fetchResults");
          break;
        case SegmentedTrendsTab.inflow:
          if (this.isInflow) {
            this.$store.commit("segmentedTrends/inflow/setCurrentPage", page);
            this.$store.dispatch("segmentedTrends/inflow/fetchResults");
          } else {
            this.$store.commit(
              "segmentedTrends/inflowDetail/setCurrentPage",
              page
            );
            this.$store.dispatch("segmentedTrends/inflowDetail/fetchResults");
          }
          break;
        case SegmentedTrendsTab.appView:
          this.$store.commit("segmentedTrends/appView/setCurrentPage", page);
          this.$store.dispatch("segmentedTrends/appView/fetchResults");
          break;
        case SegmentedTrendsTab.event:
          this.$store.commit("segmentedTrends/event/setCurrentPage", page);
          this.$store.dispatch("segmentedTrends/event/fetchResults");
          break;
        case SegmentedTrendsTab.conversion:
          this.$store.commit("segmentedTrends/conversion/setCurrentPage", page);
          this.$store.dispatch("segmentedTrends/conversion/fetchResults");
          break;
        default:
          this.$store.commit("segmentedTrends/conversion/setCurrentPage", page);
          this.$store.dispatch("segmentedTrends/conversion/fetchResults");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  toInflowDetail(domain: string) {
    this.isInflow = false;
    this.$store.commit("segmentedTrends/inflowDetail/resetData");
    this.$store.commit("segmentedTrends/inflowDetail/setDomain", domain);
    this.$store
      .dispatch("segmentedTrends/inflowDetail/executeAnalysis")
      .catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });
  }

  toInflow() {
    this.isInflow = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  downloadCsv(selectedTab: SegmentedTrendsTab) {
    try {
      UgTag.pushEvent(UgEventTag.SegmentedTrendCsv);
      switch (this.currentTab) {
        case SegmentedTrendsTab.webView:
          this.$store.dispatch("segmentedTrends/webView/downloadCsv");
          break;
        case SegmentedTrendsTab.landing:
          this.$store.dispatch("segmentedTrends/landing/downloadCsv");
          break;
        case SegmentedTrendsTab.inflow:
          if (this.isInflow) {
            this.$store.dispatch("segmentedTrends/inflow/downloadCsv");
          } else {
            this.$store.dispatch("segmentedTrends/inflowDetail/downloadCsv");
          }
          break;
        case SegmentedTrendsTab.appView:
          this.$store.dispatch("segmentedTrends/appView/downloadCsv");
          break;
        case SegmentedTrendsTab.event:
          this.$store.dispatch("segmentedTrends/event/downloadCsv");
          break;
        case SegmentedTrendsTab.conversion:
          this.$store.dispatch("segmentedTrends/conversion/downloadCsv");
          break;
        default:
          this.$store.dispatch("segmentedTrends/conversion/downloadCsv");
          break;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  get csvDownloading(): boolean {
    switch (this.currentTab) {
      case SegmentedTrendsTab.webView:
        return this.$store.state.segmentedTrends.webView.csvDownloading;
      case SegmentedTrendsTab.landing:
        return this.$store.state.segmentedTrends.landing.csvDownloading;
      case SegmentedTrendsTab.inflow:
        if (this.isInflow) {
          return this.$store.state.segmentedTrends.inflow.csvDownloading;
        } else {
          return this.$store.state.segmentedTrends.inflowDetail.csvDownloading;
        }
      case SegmentedTrendsTab.appView:
        return this.$store.state.segmentedTrends.appView.csvDownloading;
      case SegmentedTrendsTab.event:
        return this.$store.state.segmentedTrends.event.csvDownloading;
      case SegmentedTrendsTab.conversion:
        return this.$store.state.segmentedTrends.conversion.csvDownloading;
      default:
        return false;
    }
  }

  get isLoading(): boolean {
    switch (this.currentTab) {
      case SegmentedTrendsTab.webView:
        return this.$store.state.segmentedTrends.webView.isLoading;
      case SegmentedTrendsTab.landing:
        return this.$store.state.segmentedTrends.landing.isLoading;
      case SegmentedTrendsTab.inflow:
        if (this.isInflow) {
          return this.$store.state.segmentedTrends.inflow.isLoading;
        } else {
          return this.$store.state.segmentedTrends.inflowDetail.isLoading;
        }
      case SegmentedTrendsTab.appView:
        return this.$store.state.segmentedTrends.appView.isLoading;
      case SegmentedTrendsTab.event:
        return this.$store.state.segmentedTrends.event.isLoading;
      case SegmentedTrendsTab.conversion:
        return this.$store.state.segmentedTrends.conversion.isLoading;
      default:
        return this.$store.state.segmentedTrends.conversion.isLoading;
    }
  }

  get webViewData(): SegmentedTrendResult[] {
    return this.$store.getters["segmentedTrends/webView/results"];
  }
  get landingData(): SegmentedTrendResult[] {
    return this.$store.getters["segmentedTrends/landing/results"];
  }
  get inflowData(): SegmentedTrendResult[] {
    if (this.isInflow) {
      return this.$store.getters["segmentedTrends/inflow/results"];
    } else {
      return this.$store.getters["segmentedTrends/inflowDetail/results"];
    }
  }
  get appViewData(): SegmentedTrendResult[] {
    return this.$store.getters["segmentedTrends/appView/results"];
  }
  get eventData(): SegmentedTrendResult[] {
    return this.$store.getters["segmentedTrends/event/results"];
  }
  get conversionData(): SegmentedTrendResult[] {
    return this.$store.getters["segmentedTrends/conversion/results"];
  }
  get domain(): string {
    return this.$store.state.segmentedTrends.inflowDetail.domain;
  }
  get isEmptyData(): boolean {
    switch (this.currentTab) {
      case SegmentedTrendsTab.webView:
        return this.webViewData.length === 0;
      case SegmentedTrendsTab.landing:
        return this.landingData.length === 0;
      case SegmentedTrendsTab.inflow:
        return this.inflowData.length === 0;
      case SegmentedTrendsTab.appView:
        return this.appViewData.length === 0;
      case SegmentedTrendsTab.event:
        return this.eventData.length === 0;
      case SegmentedTrendsTab.conversion:
        return this.conversionData.length === 0;
      default:
        return this.webViewData.length === 0;
    }
  }

  get isDisabled(): boolean {
    return this.isClusteringMode || !this.isValidDate;
  }

  get isClusteringMode(): boolean {
    return this.$store.state.clustering.isClusteringMode;
  }

  get isValidDate(): boolean {
    return validateDate(this.selectCondition);
  }
}
</script>

<style lang="scss" scoped>
.empty-text {
  padding-top: 30px;
  height: 200px;
  text-align: center;
}
.segmented-trends__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
.segmented-trends__description {
  padding: 0 10px;
  white-space: pre-wrap;
  line-height: 1.5;
}
.segmented-trends__pager-wrapper {
  display: flex;
  justify-content: flex-start;
}
.segmented-trends__label-and-csv {
  display: flex;
  justify-content: flex-end;
}
.segmented-trends__inflow-back-button {
  margin-bottom: 10px;
}
.segmented-trends__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;
}
.segmented-trends__pager-nav {
  margin-left: 15px;
}
.segmented-trends-tabs {
  margin: 20px 0;
}
.segmented-trends__date-label {
  margin-right: 12px;
}
.segmented-trends__csv-download {
  display: inline-flex;
  &:before {
    display: inline-block;
    margin-right: 12px;
    width: 1px;
    background-color: $colorBase500;
    content: "";
  }
}
.downloading {
  display: inline-block;
  vertical-align: top;
  animation-name: appear;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
}
.segmented-trends__csv-download-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
}
.segmented-trends__csv-download-icon {
  position: absolute;
}

@keyframes appear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
