<i18n src="@/i18n/components/table/user-summary.json"></i18n>
<template>
  <div class="userSummary">
    <div class="userSummary__headerRow">
      <div class="userSummary__header">{{ title }}</div>
      <div v-t="'count'" class="userSummary__header" />
      <div v-t="'rate'" class="userSummary__header" />
    </div>

    <div v-for="(row, index) in rowData" :key="index" class="userSummary__row">
      <div class="userSummary__cell">
        <Tooltip
          class="userSummary__tooltip"
          :placement="tooltipPlacement"
          fixed-arrow-position
        >
          <template #content>
            {{ row.name }}<br />
            {{ row.count }}
            {{ $tc("time", row.count) }}
            ({{ row.rate }}%)
          </template>

          <div class="userSummary__cellText">
            {{ row.name }}
          </div>
        </Tooltip>
      </div>

      <div class="userSummary__cell">{{ row.count }}</div>
      <div class="userSummary__cell">{{ row.rate }}%</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { UserSummaryItem } from "@/models/user-summary/UserSummaryItem";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement } from "@/const/tooltip";

@Component({
  components: { Tooltip }
})
export default class UserSummary extends Vue {
  @Prop({ type: String, required: false, default: "" })
  title!: string;

  @Prop({ type: Array, required: false, default: () => [] })
  rowData!: UserSummaryItem[];

  tooltipPlacement: TooltipPlacement = TooltipPlacement.TopStart;
}
</script>

<style lang="scss" scoped>
$borderColorHeaderCell: $colorBlue820;
$borderColorNormalCell: $colorBase400;

.userSummary {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.userSummary__headerRow {
  background-color: $colorBlue800;
  border: 1px solid $borderColorHeaderCell;
  border-top-left-radius: $sizeRadius;
  border-top-right-radius: $sizeRadius;
}

.userSummary__headerRow,
.userSummary__row {
  display: flex;
}

.userSummary__header,
.userSummary__cell {
  vertical-align: middle;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.userSummary__header {
  padding: 5px 8px;
  color: $colorWhite;
  text-align: center;
  font-weight: bold;

  &:nth-child(1) {
    flex: 1;
    border-top-left-radius: $sizeRadius;
  }

  &:nth-child(2) {
    width: 60px;
    border-left: 1px solid $borderColorHeaderCell;
  }

  &:nth-child(3) {
    width: 60px;
    border-left: 1px solid $borderColorHeaderCell;
    border-top-right-radius: $sizeRadius;
  }
}

.userSummary__row {
  display: flex;
}

.userSummary__cell {
  padding: 5px 8px;
  border-right: 1px solid $borderColorNormalCell;
  border-bottom: 1px solid $borderColorNormalCell;
  color: $colorBase900;
  text-align: right;
  font-size: 11px;

  &:nth-child(3n + 1) {
    border-left: 1px solid $borderColorNormalCell;
    text-align: left;
  }
}

.userSummary__row .userSummary__cell {
  &:nth-child(1) {
    flex: 1;
    width: 0;
  }

  &:nth-child(2) {
    width: 60px;
  }

  &:nth-child(3) {
    width: 60px;
  }
}

.userSummary__cellText {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.userSummary__tooltip {
  max-width: 100%;
}
</style>
