var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:{
    'side-bar-item': true,
    ellipsis: true,
    'side-bar-item--active': _vm.active,
    'side-bar-item--small': _vm.small,
    'side-bar-item--expandable': _vm.isExpandable,
    'side-bar-item--minimized': _vm.showMinimized
  }},[(_vm.icon)?_c(_setup.V3Icon,{class:{
      'side-bar-item__icon': true,
      'side-bar-item__icon--small': _vm.small,
      'side-bar-item__icon--icon-branches': _vm.isIconBranches
    },attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.text && !_vm.showMinimized)?_c('span',{staticClass:"side-bar-item__text ellipsis"},[_vm._v(_vm._s(_vm.text))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }