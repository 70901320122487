<i18n src="@/i18n/components/filter/form/filter-base-input-text.json"></i18n>
<template>
  <FilterBaseInput
    class="filter-base-input-text"
    :prefix="prefix"
    :suffix="suffix"
    data-cy="filter-base-input-text"
  >
    <InputTextWithSuggestion
      v-if="inputFormSuggestionType !== undefined"
      :value="value"
      :width="width"
      :input-form-suggestion-type="inputFormSuggestionType"
      :event-name="filterSelectSuggestionEventName"
      :placeholder="placeholderLabel"
      @input="onInput"
    />
    <InputText
      v-else
      :value="value"
      :style="style"
      :placeholder="placeholderLabel"
      @input="onInput"
    />
  </FilterBaseInput>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import FilterBaseInput from "@/components/filter/form/FilterBaseInput.vue";
import InputText from "@/components/form/InputText.vue";
import InputTextWithSuggestion from "@/components/input-form-suggestion/InputTextWithSuggestion.vue";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";
import { UgEventTag } from "@/store/modules/ugTag";

@Component({
  components: {
    FilterBaseInput,
    InputText,
    InputTextWithSuggestion
  }
})
export default class FilterBaseInputText extends Vue {
  @Model("input", { type: String, required: true })
  value!: string;

  @Prop({ type: String, default: "" })
  prefix!: string;

  @Prop({ type: String, default: "" })
  suffix!: string;

  @Prop({ type: String, default: "260px" })
  width!: string;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: String, default: undefined })
  inputFormSuggestionType!: InputFormSuggestionType;

  onInput(value: string) {
    this.$emit("input", value);
  }

  filterSelectSuggestionEventName = UgEventTag.FilterSelectSuggestion;

  get style() {
    return {
      width: this.width
    };
  }

  get placeholderLabel(): string {
    return this.placeholder.length > 0
      ? this.placeholder
      : (this.$t("requiredInput") as string);
  }
}
</script>
