import { ApiRes } from "@/api/api-res";
import { Gram } from "@/models/Gram";
import { PLATFORM_CATEGORY, GRAM_TYPE, GRAM_VIEW_TYPE } from "@/const/gram";
import { ConversionDefinition } from "./client-settings/ConversionDefinition";
import { ConversionAttributeDefinition } from "./client-settings/ConversionAttributeDefinition";
import { BusinessEventDefinition } from "./client-settings/BusinessEventDefinition";
import { NpsDefinition } from "./client-settings/NpsDefinition";
import { EnqueteDefinition } from "./client-settings/EnqueteDefinition";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { msecToSec } from "@/util/date-util";
import { VisitTimeFrame } from "@/api/apis/ApiVisits";
import { VisitSummary } from "@/models/visit-summary/VisitSummary";

export class Visit {
  public isLast: boolean = false;
  public elapsedTimeTx: string = "";
  public readonly endTimeSec!: number;
  public readonly startTimeDate: Date;
  public readonly timeZone: number;
  public readonly endTimeIncludingAdditionalTimeSec: number;

  public readonly key: string = ""; // v-forのため
  public readonly isShowableWithdrawal: boolean = false;

  constructor(
    public readonly grams: Gram[],
    public readonly timeOfDay: VisitTimeFrame,
    public readonly summary: VisitSummary
  ) {
    this.key = grams[0].id;

    const startGram = grams[0];
    this.startTimeDate = new Date(startGram.timeSec * 1000);
    this.timeZone = startGram.timeZone;

    const endGram = grams[grams.length - 1];
    this.endTimeSec = endGram.timeSec;

    this.endTimeIncludingAdditionalTimeSec = this.endTimeSec + this.talkTimeSec;

    const nextPvTimes: { [index: string]: number } = {};
    let prevStayGram: Gram | null = null; // A previous gram that can be used for calculation of stay time
    grams.forEach(gram => {
      // Check if the gram should have stay time or not
      if (gram.gramType === GRAM_TYPE.PV && !gram.isImport) {
        if (prevStayGram !== null && !gram.hasInflow) {
          nextPvTimes[prevStayGram.id] = gram.timeSec;
        }
        // 次回算出用にprev情報をセット
        prevStayGram = gram;
      }
    });
    grams.forEach(gram => {
      const nextTime = nextPvTimes[gram.id] ? nextPvTimes[gram.id] : -1;
      gram.setStayTime(nextTime);
    });

    // 次のgramに流入があれば、離脱を表示させるためのフラグをつける
    grams.forEach((gram, index) => {
      if (index < grams.length - 1) {
        const nextGram = grams[index + 1];
        gram.hasWithdrawal = nextGram.hasInflow;
      }
    });

    // 一つでもデジタルデータ(importデータ以外）があれば離脱を表示
    for (const gram of grams) {
      if (!gram.isImport) {
        this.isShowableWithdrawal = true;
        break;
      }
    }
  }

  /**
   * call time of last gram in the visit
   * @return number, timeSec
   */
  get talkTimeSec(): number {
    const endGram: Gram = this.grams[this.grams.length - 1];
    if (
      endGram.platformCategory === PLATFORM_CATEGORY.CONTACT.ID &&
      endGram.platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.TEL
    ) {
      if (endGram.contactTel) {
        return endGram.contactTel.required.talkTimeSec;
      }
    }
    return 0;
  }

  get stayTimeSeconds(): number {
    return Math.floor(
      this.endTimeIncludingAdditionalTimeSec -
        msecToSec(this.startTimeDate.getTime())
    );
  }

  get hasTimeOfDay(): boolean {
    return this.grams[0].hasTimeOfDay;
  }

  get timeFrameIconType(): Icons {
    if (this.timeOfDay == VisitTimeFrame.Morning) {
      return Icons.Morning;
    }

    if (this.timeOfDay == VisitTimeFrame.Day) {
      return Icons.Daytime;
    }

    return Icons.Night;
  }

  /**
   * @return Colors
   */
  get timeFrameIconColor(): Colors {
    if (this.timeOfDay == VisitTimeFrame.Morning) {
      return Colors.RedForTimeFrame;
    }

    if (this.timeOfDay == VisitTimeFrame.Day) {
      return Colors.Orange700;
    }

    return Colors.Purple;
  }

  /**
   * @return number, page view count counted from grams
   */
  get pageViewCount(): number {
    const onlyPvGrams = this.grams.filter(
      gram => gram.viewType === GRAM_VIEW_TYPE.PV
    );
    return onlyPvGrams.length;
  }

  public static build(
    json: ApiRes.Visit,
    conversionDefinitions: ConversionDefinition[],
    globalConversionDefinitions: ConversionDefinition[],
    conversionAttributeDefinitions: ConversionAttributeDefinition[],
    globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[],
    businessEventDefinitions: BusinessEventDefinition[],
    npsDefinitions: NpsDefinition[],
    enqueteDefinitions: EnqueteDefinition[]
  ): Visit {
    const grams: Gram[] = json.grams.map(gram => {
      return Gram.build(
        gram,
        conversionDefinitions,
        globalConversionDefinitions,
        conversionAttributeDefinitions,
        globalConversionAttributeDefinitions,
        businessEventDefinitions,
        npsDefinitions,
        enqueteDefinitions
      );
    });
    const visitSummary = VisitSummary.build(
      json.summary,
      conversionDefinitions,
      globalConversionDefinitions
    );

    return new Visit(grams, json.time_of_day, visitSummary);
  }
}
