import { ClientPvStats } from "@/models/system/ClientPvStats";
import { GazeLevel, GRAM_ICON_TIME_STATS } from "@/const/user-icon";

export function getGazeLevelFromPv(
  pv: number,
  stats: ClientPvStats
): GazeLevel {
  if (!stats.hasStats || pv === 0) {
    return 0;
  }
  if (stats.q3 < pv) {
    return 3;
  }
  if (stats.q1 < pv) {
    return 2;
  }
  return 1;
}

export function getGazeLevelFromStayTimeSec(stayTimeSec: number): GazeLevel {
  if (GRAM_ICON_TIME_STATS.HIGH < stayTimeSec) {
    return 3;
  }
  if (GRAM_ICON_TIME_STATS.MID < stayTimeSec) {
    return 2;
  }
  return 1;
}
