<template>
  <DateRangePickerInput
    v-model="dates"
    :enabled-period="enabledPeriod"
    class="filter-Period-form"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import { DateRange } from "@/components/date-picker/DateRange";
import { enabledPeriodRange } from "@/util/date-range-picker-util";

@Component({
  components: {
    DateRangePickerInput
  }
})
export default class FilterPeriodField extends Vue {
  @Model("input", { type: FilterPeriodCondition, required: true })
  condition!: FilterPeriodCondition;

  onInput(condition: FilterPeriodCondition) {
    this.$emit("input", condition);
  }

  get enabledPeriod(): DateRange {
    return enabledPeriodRange();
  }

  get dates(): DateRange | null {
    if (this.condition.endDate !== null && this.condition.startDate !== null) {
      return { max: this.condition.endDate, min: this.condition.startDate };
    }

    return null;
  }
  set dates(dateRange: DateRange | null) {
    if (dateRange !== null) {
      this.onInput(new FilterPeriodCondition(dateRange.min, dateRange.max));
    }
  }
}
</script>
