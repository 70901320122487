<template>
  <div class="setupStep">
    <div class="setupStep__wrapper">
      <div class="setupStep__header">
        <span class="setupStep__stepText">STEP</span>
        <span class="setupStep__stepNumber">{{ stepNumber }}</span>
        <span class="setupStep__separator"></span>
        <span class="setupStep__title">{{ title }}</span>
      </div>
      <div class="setupStep__body">
        <slot></slot>
      </div>
    </div>
    <div v-if="showArrow" class="setupStep__arrow">
      <StepArrow />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import StepArrow from "@/assets/svg/mfa/step-arrow.svg";

@Component({ components: { StepArrow } })
export default class SetupStep extends Vue {
  @Prop({ type: Number, required: true })
  stepNumber!: number;

  @Prop({ type: String, default: "" })
  title!: string;

  @Prop({ type: Boolean, default: false })
  showArrow!: boolean;
}
</script>

<style scoped lang="scss">
.setupStep__wrapper {
  border: 1px solid $colorBase500;
  border-radius: 5px;
  padding: 21px;
}

.setupStep__header {
  display: flex;
  align-items: center;
}

.setupStep__stepText {
  font-size: 18px;
  font-weight: 700;
  color: $colorBase900;
}

.setupStep__stepNumber {
  font-size: 11px;
  font-weight: 700;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: $colorBase900;
  border-radius: 50%;
  color: $colorWhite;
}

.setupStep__separator {
  background-color: $colorBase500;
  width: 1px;
  height: 20px;
  margin: 0 20px;
}

.setupStep__title {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  color: $colorBase900;
}

.setupStep__body {
  margin-top: 20px;
}

.setupStep__arrow {
  margin: 18px 0px;
  text-align: center;
}
</style>
