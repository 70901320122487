import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  AdditionalConditionParams,
  AttributeConditionParamType,
  AttributeType,
  ConversionAttributeConditionParams
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * コンバージョン・イベント属性による条件
 *
 * 通常の検索条件に加えて、コンバージョン・イベント属性に対する選定条件を付与できる
 *
 * 文字列を指定して、指定したコンバージョン・イベント属性が部分一致、または完全一致するものを選定する
 * 文字列は複数指定可能でor条件になる
 */

export abstract class ConversionAttributeCondition {
  constructor(
    public readonly id: number,
    public readonly attributeType: AttributeType
  ) {}

  /**
   * 表示用のタイトル
   * @param definitions
   */
  displayTitle(definitions: ConversionAttributeDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? ":" + def.name : "";

    return (i18n.t("models.search.cvAttribute") as string) + defName;
  }

  /**
   * 指定のIDがconversionAttributeDefinitionIdと同じか返す
   * Conversionごとにid名が違うので共通の関数もたせる
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * 追加条件のJSONがコンバージョン・イベント属性の条件かどうかを判定する
 */
export function isConversionAttributeConditionParams(
  params: AdditionalConditionParams
): params is ConversionAttributeConditionParams {
  return params.type === AttributeConditionParamType.ConversionAttribute;
}
