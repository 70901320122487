import axios from "axios";
import { TdConversionAttributeRequest } from "@/api/apis/client-settings/ApiTdConversionAttribute";
import { TdConversionAttributeDefinition } from "@/models/client-settings/TdConversionAttributeDefinition";
import { RootState } from "@/store";
import { ActionTree, MutationTree } from "vuex";

export class TdConversionAttributeState {
  tdConversionAttributeDefinitions: TdConversionAttributeDefinition[] = [];
  selectedTdConversionAttributeDefinition: TdConversionAttributeDefinition | null = null;
  successMessage: string = "";
}

const mutations: MutationTree<TdConversionAttributeState> = {
  setTdConversionAttributeDefinitions(
    state: TdConversionAttributeState,
    tdConversionAttributeDefinitions: TdConversionAttributeDefinition[]
  ) {
    state.tdConversionAttributeDefinitions = tdConversionAttributeDefinitions;
  },

  setSelectedTdConversionAttributeDefinition(
    state: TdConversionAttributeState,
    tdConversionAttributeDefinition: TdConversionAttributeDefinition
  ) {
    state.selectedTdConversionAttributeDefinition = tdConversionAttributeDefinition;
  },

  setSuccessMessage(state: TdConversionAttributeState, message: string) {
    state.successMessage = message;
  }
};
const actions: ActionTree<TdConversionAttributeState, RootState> = {
  async fetchTdConversionAttributeDefinitions({ commit, rootState }) {
    const response = await rootState.api.tdConversionAttribute.getTdConversionAttributeList();
    const tdConversionAttributeDefinitions = response.map(
      TdConversionAttributeDefinition.fromJson
    );
    commit(
      "setTdConversionAttributeDefinitions",
      tdConversionAttributeDefinitions
    );
  },
  async fetchTdConversionAttributeById({ commit, rootState }, id: number) {
    try {
      const response = await rootState.api.tdConversionAttribute.getTdConversionAttributeById(
        id
      );
      const tdConversionAttributeDefinition = TdConversionAttributeDefinition.fromJson(
        response
      );
      commit(
        "setSelectedTdConversionAttributeDefinition",
        tdConversionAttributeDefinition
      );
    } catch (e) {
      if (!axios.isAxiosError(e) || !e.response || e.response.status !== 404) {
        throw e;
      }
    }
  },

  async createTdConversionAttribute(
    { rootState },
    payload: TdConversionAttributeRequest
  ) {
    await rootState.api.tdConversionAttribute.createTdConversionAttribute(
      payload
    );
  },

  async updateTdConversionAttribute(
    { rootState },
    params: { id: number; payload: TdConversionAttributeRequest }
  ) {
    await rootState.api.tdConversionAttribute.updateTdConversionAttribute(
      params.id,
      params.payload
    );
  }
};

export const tdConversionAttribute = {
  namespaced: true,
  state: new TdConversionAttributeState(),
  mutations: mutations,
  actions: actions
};
