<template>
  <FilterBaseInputTextMatchType
    :value="value"
    :match-method="matchMethod"
    :input-form-suggestion-type="inputFormSuggestionType"
    class="filter-landing-page-url-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterLandingPageUrlField extends Vue {
  @Model("input", { type: FilterLandingPageUrlCondition, required: true })
  condition!: FilterLandingPageUrlCondition;

  inputFormSuggestionType = InputFormSuggestionType.WEB_URL;

  onInput(condition: FilterLandingPageUrlCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.pageUrl;
  }

  get matchMethod(): MatchMethod {
    return this.condition.matchMethod;
  }

  onInputText(value: string) {
    this.onInput(
      new FilterLandingPageUrlCondition(value, this.condition.matchMethod)
    );
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(
      new FilterLandingPageUrlCondition(this.condition.pageUrl, matchMethod)
    );
  }
}
</script>
