<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <ul
    v-if="isAdditionalSelectConditionAppendable"
    class="additional-condition-buttons"
    data-cy="additional-condition-buttons"
  >
    <li
      v-if="isConversionAttributeConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        :class="{
          'additional-condition-unavailable': !conversionAttributeAvailable
        }"
        data-cy="addition-condition-buttons__button_conversion_attribute"
        @click="onClick(conditionType.CONVERSION_ATTRIBUTE)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
          :disabled="!conversionAttributeAvailable"
        />
        <span
          v-t="'convertionAttribute'"
          class="additional-condition-buttons__text"
          :class="{
            'additional-condition-buttons-disabled': !conversionAttributeAvailable
          }"
        />
      </div>
    </li>
    <li
      v-if="isNpsConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        data-cy="addition-condition-buttons__button_nps"
        @click="onClick(conditionType.NPS)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
        />
        <span
          v-t="'customerLoyaltyIndex'"
          class="additional-condition-buttons__text"
        />
      </div>
    </li>
    <!--
      顧客ロイヤルティ指標(個別スコア)は顧客ロイヤルティ指標が表示されている時は表示しないのでv-else-if
    -->
    <li
      v-else-if="isEnqueteConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        data-cy="addition-condition-buttons__button_conversion_enquete"
        @click="onClick(conditionType.ENQUETE)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
        />
        <span
          v-t="'customerLoyaltyIndex_individual'"
          class="additional-condition-buttons__text"
        />
      </div>
    </li>
    <li
      v-if="isBusinessIndexConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        data-cy="addition-condition-buttons__business_index"
        @click="onClick(conditionType.BUSINESS_INDEX)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
        />
        <span
          v-t="'businessIndex'"
          class="additional-condition-buttons__text"
        />
      </div>
    </li>
    <li
      v-if="isUserAttributeConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        data-cy="addition-condition-buttons__button_user_attribute"
        @click="onClick(conditionType.USER_ATTRIBUTE)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
        />
        <span
          v-t="'userAttribute'"
          class="additional-condition-buttons__text"
        />
      </div>
    </li>
    <li
      v-if="isBusinessEventAttributeConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        data-cy="addition-condition-buttons__business_event_attribute"
        @click="onClick(conditionType.BUSINESS_EVENT_ATTRIBUTE)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
        />
        <span
          v-t="'businessEvent'"
          class="additional-condition-buttons__text"
        />
      </div>
    </li>
    <li
      v-if="isContactAttributeConditionAppendable"
      class="additional-condition-buttons__item"
    >
      <div
        class="additional-condition-buttons__button"
        data-cy="addition-condition-buttons__contact_attribute"
        @click="onClick(conditionType.CONTACT_ATTRIBUTE)"
      >
        <IconButton
          class="additional-condition-buttons__icon"
          :icon="iconPlusCircle"
          :size="iconSize"
          :button-type="iconButtonType"
        />
        <span v-t="'contact'" class="additional-condition-buttons__text" />
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AdditionalConditionType } from "@/models/search/additional-condition/AdditionalSelectCondition";
import {
  SelectCondition,
  isConversionAttributeConditionAppendable,
  isNpsConditionAppendable,
  isEnqueteConditionAppendable,
  isBusinessIndexConditionAppendable,
  isUserAttributeConditionAppendable,
  isBusinessEventAttributeConditionAppendable,
  isContactAttributeConditionAppendable
} from "@/models/search/select-condition/SelectCondition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import { SelectByNpsChangeCondition } from "@/models/search/select-condition/SelectByNpsChangeCondition";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";

import IconButton from "@/components/IconButton.vue";
import Icon from "@/components/Icon.vue";
import { SelectByEngagementCondition } from "@/models/search/select-condition/SelectByEngagementCondition";

const ICON_SIZE: number = 18;

@Component({
  components: {
    Icon,
    IconButton
  }
})
export default class AdditionalConditionButtons extends Vue {
  conditionType = AdditionalConditionType;
  iconPlusCircle = Icons.PlusCircle;
  iconSize = ICON_SIZE;
  iconButtonType = IconButtonType.Weak;

  @Prop({ type: Object, required: true })
  condition!: SelectCondition;

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  npsDefinitions!: NpsDefinition[];

  @Prop({ type: Array, required: true })
  enqueteDefinitions!: EnqueteDefinition[];

  @Prop({ type: Array, required: true })
  businessIndexDefinitions!: BusinessIndexDefinition[];

  @Prop({ type: Array, required: true })
  userAttributeDefinitions!: UserAttributeDefinition[];

  @Prop({ type: Array, required: true })
  businessEventDefinitions!: BusinessEventDefinition[];

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  onClick(type: AdditionalConditionType) {
    this.$emit("click", type);
  }

  /**
   * 渡された条件がNSP(値/変化)かを返す
   * NPSの場合は個別指標のみ追加できるようになる
   */
  get isNpsCondition(): boolean {
    return (
      this.condition instanceof SelectByNpsCondition ||
      this.condition instanceof SelectByNpsChangeCondition
    );
  }

  /**
   * いずれかの追加条件は追加可能か
   */
  get isAdditionalSelectConditionAppendable(): boolean {
    return this.condition.isAdditionalConditionAppendable;
  }

  /**
   * コンバージョン・イベント属性のAdditionalSelectConditionは追加が可能か
   */
  get isConversionAttributeConditionAppendable(): boolean {
    return isConversionAttributeConditionAppendable(
      this.condition,
      this.conversionAttributeDefinitions
    );
  }

  /**
   * 顧客ロイヤルティ指標のAdditionalSelectConditionは追加が可能か
   */
  get isNpsConditionAppendable(): boolean {
    return (
      !this.isNpsCondition &&
      isNpsConditionAppendable(this.condition, this.npsDefinitions)
    );
  }

  /**
   * 顧客ロイヤルティ指標(個別スコア)のAdditionalSelectConditionは追加が可能か
   */
  get isEnqueteConditionAppendable(): boolean {
    return isEnqueteConditionAppendable(
      this.condition,
      this.enqueteDefinitions
    );
  }

  /**
   * ビジネス指標のAdditionalSelectConditionは追加が可能か
   */
  get isBusinessIndexConditionAppendable(): boolean {
    return isBusinessIndexConditionAppendable(
      this.condition,
      this.businessIndexDefinitions
    );
  }

  /**
   * ユーザ属性のAdditionalSelectConditionは追加が可能か
   */
  get isUserAttributeConditionAppendable(): boolean {
    return isUserAttributeConditionAppendable(
      this.condition,
      this.userAttributeDefinitions
    );
  }

  get conversionAttributeAvailable(): boolean {
    if (this.condition instanceof SelectByEngagementCondition) {
      return this.condition.isAdditionalConditionAvailable;
    }
    return true;
  }

  /**
   * Check if business event condition available
   */
  get isBusinessEventAttributeConditionAppendable(): boolean {
    return isBusinessEventAttributeConditionAppendable(
      this.condition,
      this.businessEventDefinitions
    );
  }

  /**
   * Check if contact attribute condition available
   */
  get isContactAttributeConditionAppendable(): boolean {
    return isContactAttributeConditionAppendable(
      this.condition,
      this.contactDefinitions
    );
  }
}
</script>
<style lang="scss" scoped>
.additional-condition-buttons__item {
  display: block;
  margin: 15px 0;
}

.additional-condition-buttons__text {
  padding-top: 3px;
  color: $colorBase900;
  font-size: 14px;
}

.additional-condition-buttons__button {
  display: inline-flex;
  color: $colorBase500;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $colorBase600;

    .additional-condition-buttons__text {
      text-decoration: underline;
    }
  }
}

.additional-condition-buttons__icon {
  margin-right: 7px;
}

.additional-condition-buttons-disabled {
  color: $colorTextLight;
}
.additional-condition-unavailable {
  pointer-events: none;
}
</style>
