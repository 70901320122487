<template>
  <Dialog
    :visible="visible"
    :append-to-body="true"
    :center="false"
    data-cy="confirm-dialog"
    @close="toggleDialog(false)"
  >
    <div>{{ message }}</div>
    <template #footer>
      <div class="confirm-dialog__footer">
        <Button data-cy="dialog-confirm-ok-button" @click="onClick">
          {{ buttonLabel }}
        </Button>
        <Button :type="buttonTypeLight" @click="toggleDialog(false)">
          {{ cancelButtonLabel }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue, Model } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import { BUTTON_TYPE } from "@/const/button";
import Dialog from "@/components/dialog/Dialog.vue";

@Component({
  components: {
    Button,
    Dialog
  }
})
export default class ConfirmDialog extends Vue {
  buttonTypeLight = BUTTON_TYPE.LIGHT;

  @Model("toggle", { type: Boolean, required: true })
  visible!: boolean;

  @Prop({ type: String, required: true })
  message!: string;

  @Prop({ type: String, required: true })
  buttonLabel!: string;

  @Prop({ type: String, required: true })
  cancelButtonLabel!: string;

  @Emit("click")
  onClick() {}

  toggleDialog(open: boolean) {
    this.$emit("toggle", open);
  }
}
</script>

<style lang="scss" scoped>
.confirm-dialog {
  &__footer {
    margin-top: 30px;

    & > *:not(:first-child) {
      margin-left: 12px;
    }
  }
}
</style>
