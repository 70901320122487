import Vue, {
  computed,
  getCurrentInstance,
  WritableComputedRef,
  VueConstructor
} from "vue";
import VueI18n from "vue-i18n";

export interface Composer {
  locale: WritableComputedRef<string>;
  t: typeof VueI18n.prototype.t;
  tc: typeof VueI18n.prototype.tc;
  te: typeof VueI18n.prototype.te;
  d: typeof VueI18n.prototype.d;
  n: typeof VueI18n.prototype.n;
}

export function useI18n(): Composer {
  const instance = getCurrentInstance();
  const vm =
    (instance == null ? void 0 : instance.proxy) ||
    ((instance as unknown) as InstanceType<VueConstructor>) ||
    new Vue({});

  const i18n = vm.$i18n;
  if (!i18n) throw new Error("vue-i18n not initialized");

  const locale = computed({
    get() {
      return i18n.locale;
    },
    set(v: string) {
      i18n.locale = v;
    }
  });

  return {
    locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm)
  };
}
