<i18n src="@/i18n/components/tours/tour-index-notification.json"></i18n>
<template>
  <div class="tourIndexNotification">
    <div v-t="'title'" class="tourIndexNotification__title" />

    <div class="tourIndexNotification__items">
      <TourIndexNotificationItem number="1" :text="$t('item1')" />
      <div class="tourIndexNotification__arrow" />
      <TourIndexNotificationItem number="2" :text="$t('item2')" />
      <div class="tourIndexNotification__arrow" />
      <TourIndexNotificationItem number="3" :text="$t('item3')" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import TourIndexNotificationItem from "./TourIndexNotificationItem.vue";

@Component({
  components: {
    TourIndexNotificationItem
  }
})
export default class TourIndexNotification extends Vue {}
</script>

<style lang="scss" scoped>
.tourIndexNotification {
  max-width: 920px;
}

.tourIndexNotification__title {
  padding: 12px 20px;
  border-radius: $sizeRadius $sizeRadius 0 0;
  background: $colorBlue690;
  color: $colorBlue900;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
}

.tourIndexNotification__items {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 0 0 $sizeRadius $sizeRadius;
  background: $colorWhite;
}

.tourIndexNotification__arrow {
  margin: 0 12px 0 20px;
  border-width: 6px 9px;
  border-style: solid;
  border-color: transparent;
  border-left-color: $colorBlue690;
}
</style>
