import {
  stringListValue,
  AdditionalSelectConditionInterface
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  AttributeConditionParamType,
  UserAttributeConditionParams,
  AdditionalConditionParams,
  AttributeType
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * ユーザ属性(文字列)による条件
 *
 * OMO版限定の検索条件
 * 通常の検索条件に加えて、OMOでインポートしたユーザ属性に対する選定条件を付与できる
 *
 * 文字列を指定して、指定したユーザ属性(文字列)が部分一致、または完全一致するものを選定する
 * 文字列は複数指定可能でor条件になる
 */
export class UserAttributeStringCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    // ユーザ属性定義のID
    public readonly id: number,
    // 検索対象文字列(複数指定可)
    public readonly values: string[],
    // 完全一致 or 部分一致
    public readonly matchPattern: MatchMethod
  ) {}

  /**
   * 表示用のタイトル
   * @param definitions
   */
  displayTitle(definitions: UserAttributeDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? ":" + def.name : "";

    return (i18n.t("models.search.userAttributes") as string) + defName;
  }

  /**
   * 表示用の設定された値を返す。
   */
  displayValue(): string {
    return stringListValue(this.values, this.matchPattern);
  }

  /**
   * 指定のIDがuserAttributeDefinitionIdと同じか返す
   * Conversionごとにid名が違うので共通の関数もたせる
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertUserAttributeStringConditionToJson(
  condition: UserAttributeStringCondition
): UserAttributeConditionParams {
  return {
    type: AttributeConditionParamType.UserAttribute,
    id: condition.id,
    formatType: AttributeType.TEXT,
    strValues: condition.values,
    word_match_method: condition.matchPattern,
    valRangeType: -1,
    valFrom: -1,
    valTo: -1
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToUserAttributeStringCondition(
  json: UserAttributeConditionParams,
  activeUserAttributeDefinitions: UserAttributeDefinition[]
): UserAttributeStringCondition | null {
  // 利用可能なidリストになければnull
  if (!activeUserAttributeDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  let matchMethod: MatchMethod;
  if (json.word_match_method !== undefined) {
    matchMethod = json.word_match_method;
  } else if (json.wordMatchMethod !== undefined) {
    matchMethod = json.wordMatchMethod;
  } else {
    return null;
  }

  return new UserAttributeStringCondition(json.id, json.strValues, matchMethod);
}

/**
 * 追加条件のJSONがアンケートの条件かどうかを判定する
 */
export function isUserAttributeStringConditionParams(
  params: AdditionalConditionParams
): params is UserAttributeConditionParams {
  return (
    params.type === AttributeConditionParamType.UserAttribute &&
    params.formatType === AttributeType.TEXT
  );
}
