import HttpClient from "@/api/HttpClient";
import {
  SelectByEngagementCondition,
  convertSelectByEngagementConditionToJson
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { ApiUrl } from "@/api/api-url";
import { SelectByEngagementQuery } from "@/api/apis/ApiSearch";

export enum EngagementStatus {
  Ok = "OK",
  Failed = "FAILED",
  Running = "RUNNING"
}

export default class ApiEngagementSearch {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * post condition to get id for engagement search
   *
   * @param condition SelectByEngagementCondition
   *
   * @returns Promise<{ id: number }>
   */
  public createSearch(
    condition: SelectByEngagementCondition
  ): Promise<{ id: number }> {
    const params: SelectByEngagementQuery = convertSelectByEngagementConditionToJson(
      condition
    );

    return this.httpClient.post<{ id: number }>(
      ApiUrl.ENGAGEMENT_SEARCH,
      params
    );
  }

  /**
   * get searching status by advanced search id
   *
   * @param advanced_search_id
   *
   * @returns Promise<{ status: EngagementStatus }>
   */
  public getStatus(
    advanced_search_id: number
  ): Promise<{ status: EngagementStatus }> {
    const url: string = ApiUrl.ENGAGEMENT_SEARCH_STATUS(advanced_search_id);
    return this.httpClient.get<{ status: EngagementStatus }>(url);
  }

  /**
   * get history_id by advanced search id
   *
   * @param advanced_search_id
   *
   * @returns Promise<{ history_id: number }>
   */
  public getResult(
    advanced_search_id: number
  ): Promise<{ history_id: number }> {
    const url: string = ApiUrl.ENGAGEMENT_SEARCH_RESULT(advanced_search_id);
    return this.httpClient.get<{ history_id: number }>(url);
  }
}
