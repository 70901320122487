import { ApiRes } from "@/api/api-res";

export class Favorite {
  constructor(
    public readonly userId: string,
    public readonly description: string,
    public readonly updatedDate: Date
  ) {}

  public static fromJson(json: ApiRes.Favorite): Favorite {
    return new Favorite(
      json.user_id,
      json.description,
      new Date(json.updated_time_msec)
    );
  }
}
