<template>
  <div class="lightUgtableHeader">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LightUgTableHeader extends Vue {}
</script>

<style lang="scss" scoped>
.lightUgtableHeader {
  border-top: solid 1px $colorBase400;
  border-right: solid 1px $colorBase400;
  border-bottom: solid 1px $colorBase500;
  border-left: solid 1px $colorBase400;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
