<template>
  <rect
    class="chartColoredPeriod"
    :width="width"
    :height="chartColoredPeriodHeight"
    :x="x"
    :y="strokeWidth"
    :fill="fill"
    :stroke="stroke"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { CHART_COLORED_PERIOD_STROKE_WIDTH } from "@/components/chart/chart-util";

@Component
export default class ChartColoredPeriod extends Vue {
  @Prop({ type: Number, required: true })
  width!: number;

  @Prop({ type: Number, required: true })
  height!: number;

  @Prop({ type: Number, required: true })
  x!: number;

  @Prop({ type: String, required: true })
  fill!: Colors;

  @Prop({ type: String, required: true })
  stroke!: Colors;

  @Emit("mouseenter")
  onMouseEnter() {}

  @Emit("mouseleave")
  onMouseLeave() {}

  strokeWidth = CHART_COLORED_PERIOD_STROKE_WIDTH;

  // Since the height increases by the stroke, subtract the stroke from the height.
  get chartColoredPeriodHeight(): number {
    return this.height - this.strokeWidth;
  }
}
</script>

<style lang="scss" scoped>
.chartColoredPeriod {
  fill-opacity: 0.3;
  stroke-opacity: 0;
  stroke-width: 1;
  mix-blend-mode: multiply;

  &:hover {
    stroke-opacity: 1;
  }
}
</style>
