import { ApiRes } from "@/api/api-res";

/**
 * Model holding AlmostCvConversion data
 */
export class AlmostCvConversion {
  public constructor(
    public readonly conversionId: number,
    public readonly conversionName: string,
    public readonly count: number
  ) {}

  /**
   * Convert data from API into an instance of AlmostCvConversion
   * @param json an element from array of data retrieved from API
   * @returns an instance of this class
   */
  public static fromJson(json: ApiRes.AlmostCvConversion): AlmostCvConversion {
    return new AlmostCvConversion(json.id, json.name, json.num);
  }
}
