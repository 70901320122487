import { GlobalConversionAttribute as GlobalConversionAttributeJson } from "@/api/apis/ApiSystem";
import { i18n } from "@/i18n";

export const enum GlobalConversionAttributeQueryType {
  PushLabel = "__message_name",
  LinkCampaign = "__link_campaign",
  LinkSource = "__link_source",
  LinkMedium = "__link_medium",
  LinkLinkName = "__link_name",
  LinkLinkValue = "__link_value"
}

export type GlobalConversionAttributeQuery =
  | GlobalConversionAttributeQueryType.PushLabel
  | GlobalConversionAttributeQueryType.LinkCampaign
  | GlobalConversionAttributeQueryType.LinkSource
  | GlobalConversionAttributeQueryType.LinkMedium
  | GlobalConversionAttributeQueryType.LinkLinkName
  | GlobalConversionAttributeQueryType.LinkLinkValue;

/**
 * ユーザが指定したわけではない、リンク名などのFirebase由来の設定を保持する
 */
export class GlobalConversionAttributeDefinition {
  constructor(public readonly id: number, public readonly query: string) {}

  public static fromJson(
    json: GlobalConversionAttributeJson
  ): GlobalConversionAttributeDefinition {
    return new GlobalConversionAttributeDefinition(json.id, json.query);
  }
}

export function isAllowGlobalConversionAttributeQuery(query: string): boolean {
  return (
    query === GlobalConversionAttributeQueryType.PushLabel ||
    query === GlobalConversionAttributeQueryType.LinkCampaign ||
    query === GlobalConversionAttributeQueryType.LinkSource ||
    query === GlobalConversionAttributeQueryType.LinkMedium ||
    query === GlobalConversionAttributeQueryType.LinkLinkName ||
    query === GlobalConversionAttributeQueryType.LinkLinkValue
  );
}

export function getGlobalConversionAttributeNameByQuery(query: string): string {
  switch (query) {
    case GlobalConversionAttributeQueryType.PushLabel:
      return i18n.t("models.search.label") as string;
    case GlobalConversionAttributeQueryType.LinkCampaign:
      return "campaign";
    case GlobalConversionAttributeQueryType.LinkSource:
      return "source";
    case GlobalConversionAttributeQueryType.LinkMedium:
      return "medium";
    case GlobalConversionAttributeQueryType.LinkLinkName:
      return "link_name";
    case GlobalConversionAttributeQueryType.LinkLinkValue:
      return "link_value";
  }
  return "";
}
