<template>
  <div class="userTrendFormRow">
    <div
      class="userTrendFormRow__label"
      :style="{ 'min-width': minWidth }"
      v-text="label"
    />

    <div class="userTrendFormRow__body">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UserTrendFormRow extends Vue {
  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: String, default: "auto" })
  minWidth!: string;
}
</script>

<style scoped lang="scss">
.userTrendFormRow {
  display: flex;
  align-items: center;
}

.userTrendFormRow__label {
  margin-right: 10px;
}
</style>
