<i18n src="@/i18n/components/home-menu/help-site-link.json"></i18n>
<template>
  <p>
    <span v-t="'prefixText'" />
    <a
      v-t="'linkTitle'"
      class="helpSiteLink__linkText"
      :href="url"
      target="_blank"
      rel="noopener"
    />
    <span v-t="'suffixText'" />
  </p>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class HelpSiteLink extends Vue {
  @Prop({ type: String, required: true })
  url!: string;
}
</script>
<style lang="scss" scoped>
.helpSiteLink__linkText {
  color: $colorTextLink;
}
</style>
