import { render, staticRenderFns } from "./UpcomingTour.vue?vue&type=template&id=7beddc1e&scoped=true"
import script from "./UpcomingTour.vue?vue&type=script&lang=ts"
export * from "./UpcomingTour.vue?vue&type=script&lang=ts"
import style0 from "./UpcomingTour.vue?vue&type=style&index=0&id=7beddc1e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7beddc1e",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/tours/upcoming-tour.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ftours%2FUpcomingTour.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports