import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import {
  ExclusionProps,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import {
  FilterNodeType,
  FilterNode
} from "@/models/search/filter-node/FilterNode";
import { FilterNodeForConversion } from "@/models/search/filter-node/FilterNodeForConversion";
import { FilterNodeForBrowseSite } from "@/models/search/filter-node/FilterNodeForBrowseSite";
import { FilterNodeForInflow } from "@/models/search/filter-node/FilterNodeForInflow";
import { FilterNodeForEvent } from "@/models/search/filter-node/FilterNodeForEvent";
import {
  FilterNodeForLaunchApp,
  AppLaunchType
} from "@/models/search/filter-node/FilterNodeForLaunchApp";
import { FilterNodeForOrNode } from "@/models/search/filter-node/FilterNodeForOrNode";
import { ExclusionType } from "@/models/search/filter-node/FilterExclusion";
import { FilterNodeForBusinessEvent } from "@/models/search/filter-node/FilterNodeForBusinessEvent";
import { FilterNodeForBrowseApp } from "@/models/search/filter-node/FilterNodeForBrowseApp";
import { FilterNodeForContact } from "@/models/search/filter-node/FilterNodeForContact";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterContactCondition } from "@/models/search/filter-node-condition/FilterContactCondition";
import { FilterSiteUrlCondition } from "@/models/search/filter-node-condition/FilterSiteUrlCondition";
import { FilterAdditionalCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

import { i18n } from "@/i18n";
import store from "@/store/index";
import { showAlert } from "@/util/modal-util";

export type DropType = "addNode" | "orNode" | "excludeNode";

const MAX_NODES = 5;

/**
 * Updates the nodes after one was dragged into a drop area and returns the new array
 * @param nodes filterNodes that will be updated
 * @param index The index for the parent node, needed to create the OR node
 * @param draggingNodeType The node type needed for creating the new node
 * @param dropType Whether this is for adding a new node or creating an OR node, default addNode
 * @returns Updated array of filterNodes
 */
export function updateNodesWithNewDroppedNode(
  nodes: FilterNode[],
  index: number,
  draggingNodeType: FilterNodeType,
  dropType: DropType = "addNode"
): FilterNode[] | undefined {
  if (draggingNodeType === null) {
    return;
  }

  if (MAX_NODES <= nodes.length && dropType === "addNode") {
    showAlert(
      i18n.t("components.search.filter.narrowingDownConditions", {
        num: MAX_NODES
      }) as string
    );
    return undefined;
  }

  if (
    nodes.length === MAX_NODES - 1 &&
    !nodes.some(node => node.isExcluded) &&
    dropType === "addNode"
  ) {
    store.commit("filter/setIndexOfEnableExcludeCondition", null);
  }

  let node: FilterNode;

  if (dropType === "addNode") {
    node = createNewNodeFromType(0, 0, draggingNodeType);
  } else if (dropType === "excludeNode") {
    node = createNewNodeFromType(0, 0, draggingNodeType, true);
  } else {
    // Create OR node and and the dragged and parent nodes as the children
    const firstOrNodeChild = nodes[index];
    firstOrNodeChild.childIndex = 0;
    firstOrNodeChild.depth = 1;
    const newEdge = index + 1 === nodes.length ? null : nodes[index].edge;

    const secondOrNodeChild = createNewNodeFromType(
      1,
      1,
      draggingNodeType,
      firstOrNodeChild.isExcluded
    );

    const filterExclusion = new FilterExclusion(
      firstOrNodeChild.isExcluded
        ? ExclusionType.Exclude
        : ExclusionType.Include
    );
    node = new FilterNodeForOrNode(0, 0, newEdge, filterExclusion, [
      firstOrNodeChild,
      secondOrNodeChild
    ]);

    node.orActivityNodes[0].edge = null;

    // Remove time-based additional conditions
    // It's not pretty but even with null checks TS refuses to infer the types correctly
    // and we don't have optional chaining on this TS version.
    if (
      // @ts-ignore
      node.orActivityNodes[0].condition &&
      // @ts-ignore
      node.orActivityNodes[0].condition.additionalConditions
    ) {
      const newAdditionalConditions = removeTimeAdditionalConditions(
        // @ts-ignore
        node.orActivityNodes[0].condition.additionalConditions
      );
      // @ts-ignore
      node.orActivityNodes[0].condition.additionalConditions = newAdditionalConditions;
      // @ts-ignore
      node.orActivityNodes[0].condition.parentDepth = 1;
      // @ts-ignore
    } else if (node.orActivityNodes[0].additionalConditions) {
      const newAdditionalConditions = removeTimeAdditionalConditions(
        // @ts-ignore
        node.orActivityNodes[0].additionalConditions
      );
      // @ts-ignore
      node.orActivityNodes[0].additionalConditions = newAdditionalConditions;
    }
    // @ts-check
  }

  if (node) {
    if (dropType === "orNode") {
      // Replace the node if creating an OR node
      nodes.splice(index, 1, node);
    } else {
      nodes.splice(index, 0, node);
    }
    // Check if node exclusion should be reset
    // If added node is now first, reset excluded new second node ( but only if it is addNode )
    if (index === 0 && nodes.length > 1 && dropType === "addNode") {
      nodes[1].filterExclusion.exclusionType = ExclusionType.Include;
    }

    // If added node is now last of three or more, reset excluded previously last node ( but only if it is addNode )
    if (
      index === nodes.length - 1 &&
      nodes.length > 2 &&
      dropType === "addNode"
    ) {
      nodes[nodes.length - 2].filterExclusion.exclusionType =
        ExclusionType.Include;
    }

    // Determine and Increase indexOfEnableExcludeCondition because a new node is added before it ( but only if it is addNode )
    const exclusionIndex = store.state.filter.indexOfEnableExcludeCondition;
    if (exclusionIndex !== null && dropType === "addNode") {
      if (index <= exclusionIndex) {
        store.commit(
          "filter/setIndexOfEnableExcludeCondition",
          exclusionIndex + 1
        );
      }
    }
  }

  return nodes;
}

export function createNewNodeFromType(
  childIndex: number,
  depth: number,
  nodeType: number,
  isExcluded: boolean = false
): FilterNode {
  let node!: FilterNode;

  const filterExclusion = new FilterExclusion(
    isExcluded ? ExclusionType.Exclude : ExclusionType.Include
  );

  if (nodeType === FilterNodeType.Conversion) {
    const conversionDefinitions: ConversionDefinition[] =
      store.getters["clientSettings/allActiveConversionDefinitions"];
    const defId = conversionDefinitions[0].id;
    node = new FilterNodeForConversion(
      defId,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.BrowseSite) {
    const urlCondition = new FilterSiteUrlCondition("", MatchMethod.Partial);
    node = new FilterNodeForBrowseSite(
      urlCondition,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.Inflow) {
    node = new FilterNodeForInflow(
      null,
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.Event) {
    const eventDefinitions: EventDefinition[] =
      store.state.clientSettings.activeEventDefinitions;
    const defId = eventDefinitions[0].id;
    node = new FilterNodeForEvent(
      defId,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.LaunchApp) {
    const launchType = AppLaunchType.PushNotification;
    node = new FilterNodeForLaunchApp(
      launchType,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.BrowseApp) {
    node = new FilterNodeForBrowseApp(
      "",
      MatchMethod.Partial,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.Contact) {
    const contactDefinitions: EventDefinition[] =
      store.state.clientSettings.activeContactDefinitions;
    const condition = new FilterContactCondition(
      contactDefinitions[0].id,
      false,
      0
    );
    node = new FilterNodeForContact(
      condition,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  } else if (nodeType === FilterNodeType.BusinessEvent) {
    const businessEventDefinitions: EventDefinition[] =
      store.state.clientSettings.activeBusinessEventDefinitions;
    node = new FilterNodeForBusinessEvent(
      businessEventDefinitions[0].id,
      [],
      childIndex,
      depth,
      null,
      filterExclusion
    );
  }

  return node;
}

/**
 * Regenerates Exclusion-properties for index in provided nodes. Calculates if node
 * at index can be excluded and updates if it's currently excluded depending on provided nodes.
 * @param nodes filterNodes list of currently set filterNodes
 * @param index The index for which exclusion-properties should be generated for
 */
export function createExclusionProperties(
  nodes: FilterNode[],
  index: number
): ExclusionProps {
  const tempProps: ExclusionProps = {
    isEnabled: true,
    isExcluded: false,
    isShown: false
  };

  // Show if the first or last node
  if (index === 0 || nodes.length - 1 === index) {
    tempProps.isShown = true;
  }

  // Check if node is excluded
  if (nodes[index].filterExclusion.exclusionType === ExclusionType.Exclude) {
    tempProps.isExcluded = true;
  }

  // When a node is already excluded, prevent exclusion in other nodes
  const excludedNodes: number[] = [];
  nodes.forEach((node: FilterNode, index: number) => {
    if (node.filterExclusion.exclusionType === ExclusionType.Exclude)
      excludedNodes.push(index);
  });
  if (excludedNodes.length > 0 && !excludedNodes.includes(index)) {
    tempProps.isEnabled = false;
  }

  return tempProps;
}

const removeTimeAdditionalConditions = (
  additionalConditions: FilterAdditionalCondition[]
) => {
  return additionalConditions.filter((condition: FilterAdditionalCondition) => {
    if (
      condition.conditionType === FilterNodeConditionType.FirstTime ||
      condition.conditionType === FilterNodeConditionType.Period ||
      condition.conditionType === FilterNodeConditionType.DateHour
    ) {
      return false;
    }
    return true;
  });
};
