import { render, staticRenderFns } from "./UserTrendScatterForm.vue?vue&type=template&id=1196cbdf&scoped=true"
import script from "./UserTrendScatterForm.vue?vue&type=script&lang=ts"
export * from "./UserTrendScatterForm.vue?vue&type=script&lang=ts"
import style0 from "./UserTrendScatterForm.vue?vue&type=style&index=0&id=1196cbdf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1196cbdf",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/user-trend/scatter/user-trend-scatter-form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fuser-trend%2Fscatter%2FUserTrendScatterForm.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports