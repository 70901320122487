<i18n
  src="@/i18n/components/observation/visit/visit-summary/visit-summary-container.json"
></i18n>
<template>
  <div class="visitSummaryContainer">
    <div
      v-if="summary.hasData"
      class="visitSummaryContainer__hasData"
      data-cy="visitSummaryContainer__hasData"
      @mouseenter="onEnter"
      @mouseleave="onLeave"
    >
      <div class="visitSummaryContainer__link">
        <Icon :icon="icons.Table" :color="iconColor" :size="18" />
        <div class="visitSummaryContainer__linkText"
          >{{ $t("visitSummary")
          }}<span class="visitSummaryContainer__linkTextBeta">(BETA)</span></div
        >
      </div>
      <div
        v-if="showSummaryTooltip"
        data-cy="visitSummaryContainer__summaryTooltipContainer"
        class="visitSummaryContainer__summaryTooltipContainer"
      >
        <div class="visitSummaryContainer__summaryTooltip">
          <VisitSummaryTooltip :data="summary" />
        </div>
      </div>
    </div>
    <Tooltip
      v-if="!summary.hasData"
      :placement="summaryDisableTooltipPlacement"
    >
      <div class="visitSummaryContainer__link">
        <Icon :icon="icons.Table" :color="iconColor" :size="18" />
        <div class="visitSummaryContainer__linkText"
          >{{ $t("visitSummary")
          }}<span class="visitSummaryContainer__linkTextBeta">(BETA)</span></div
        >
      </div>
      <template #content>
        <div v-if="!summary.hasData">
          {{ $t("visitSummaryNote1") }}<br />{{ $t("visitSummaryNote2")
          }}<br />{{ $t("visitSummaryNote3") }}
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script scoped lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import IconButton from "@/components/IconButton.vue";

import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";

import Button from "@/components/Button.vue";
import VisitSummaryTooltip from "@/components/observation/visit/visit-summary/VisitSummaryTooltip.vue";
import { VisitSummary } from "@/models/visit-summary/VisitSummary";
import { Colors } from "@/const/Colors";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";

const PUSH_EVENT_DELAY: number = 1500; // 1.5 sec

@Component({
  components: {
    Icon,
    Tooltip,
    IconButton,
    Button,
    VisitSummaryTooltip
  }
})
export default class VisitSummaryContainer extends Vue {
  icons = Icons;
  colors = Colors;

  pushEventTimer = -1;
  showSummaryTooltip: boolean = false;
  summaryDisableTooltipPlacement = TooltipPlacement.Bottom;

  @Prop({ type: Object, required: true })
  summary!: VisitSummary;

  /*
   emit show summary to VisitHead to update VisitHead z-index.
   Because the next VisitHead overlaps to summary tooltip.
   */
  @Emit("show-summary")
  onShowSummary(showSummary: boolean) {
    this.showSummaryTooltip = showSummary;
  }

  get iconColor(): Colors {
    return this.summary.hasData ? Colors.Base700 : Colors.Base500;
  }

  onEnter(): void {
    if (this.summary.hasData) {
      this.onShowSummary(true);
      this.pushEventTimer = window.setTimeout(() => {
        UgTag.pushEvent(UgEventTag.VisitSummary);
      }, PUSH_EVENT_DELAY);
    }
  }

  onLeave(): void {
    this.onShowSummary(false);
    clearTimeout(this.pushEventTimer);
  }
}
</script>

<style scoped lang="scss">
.visitSummaryContainer {
  padding: 8px 2px 8px 2px;
}

.visitSummaryContainer__summaryTooltipContainer {
  position: relative;
}

.visitSummaryContainer__summaryTooltipContainer::after {
  position: absolute;
  bottom: -22px;
  left: 66px;
  width: $balloonArrowSize;
  height: $balloonArrowSize;
  background: $colorWhite;
  content: "";
  transform: rotate(45deg);
}

.visitSummaryContainer__summaryTooltipContainer::before {
  position: absolute;
  bottom: -21px;
  left: 66px;
  width: $balloonArrowSize;
  height: $balloonArrowSize;
  background: $colorBase600;
  content: "";
  transform: rotate(45deg);
}

.visitSummaryContainer__summaryTooltip {
  position: absolute;
  right: -90px; /* 90px is visitHead__scrollButtonContainer size + 5 */
  padding-top: 12px;
}

@media screen and (min-width: 1750px) {
  .visitSummaryContainer__summaryTooltip {
    right: auto;
    left: 0px;
  }
}

.visitSummaryContainer__link {
  position: relative;
  display: flex;
  align-items: center;
  cursor: default;
  color: $colorBase500;
}

.visitSummaryContainer__linkText {
  margin-left: 6px;
  font-size: 14px;
}

.visitSummaryContainer__hasData {
  &:hover .visitSummaryContainer__linkText {
    text-decoration: underline;
  }
  & .visitSummaryContainer__link {
    color: $colorBase700;
    cursor: pointer;
  }
}

.visitSummaryContainer__linkTextBeta {
  font-size: 11px;
}
</style>
