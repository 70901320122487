import { SiteUrl } from "@/api/apis/ApiClientSettings";
/**
 * 計測対象サイト
 *
 * クライアントが任意に定義できる計測対象サイト
 */
export class MeasurementTargetSite {
  constructor(
    // 設定されたid
    public readonly id: number,
    // 計測対象のhost（pathが設定されている場合はhost + / + path）
    public readonly hostPath: string,
    // サイトの説明
    public readonly description: string,
    // true = 計測停止中, false = 計測中
    public readonly isDisabled: boolean
  ) {}

  public static fromJson(json: SiteUrl): MeasurementTargetSite {
    return new MeasurementTargetSite(
      json.id,
      json.host_path,
      json.description,
      json.is_disabled
    );
  }
}
