import { ApiRes } from "@/api/api-res";
import { CreationMethod } from "@/models/client-settings/Coordination";

/**
 * イベント
 *
 * クライアントが任意に定義できるイベント
 */

export class EventDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    // true = 計測中, false = 計測停止
    public readonly isDisabled: boolean
  ) {}

  public static fromJson(json: ApiRes.Event): EventDefinition {
    return new EventDefinition(json.id, json.name, json.is_disabled);
  }
}

export interface CoordinationEventEditableData {
  targetData: string;
  creationMethod: CreationMethod;
  eventName: string;
  eventId: number;
  path: string[];
}

export const MAX_NAME_LENGTH = 40;
export const MAX_PARAM_LENGTH = 40;

export const isValidCoordinationEventData = (
  data: CoordinationEventEditableData
) => {
  if (data.targetData === "") return false;
  if (data.targetData.length > MAX_NAME_LENGTH) return false;

  if (
    data.creationMethod === CreationMethod.NEW &&
    (data.eventName === "" || data.eventName.length > MAX_NAME_LENGTH)
  )
    return false;
  if (data.creationMethod === CreationMethod.EXISTING && data.eventId <= 0)
    return false;

  if (data.path.length < 1) return false;
  if (data.path.length > 5) return false;
  if (data.path.some(p => p === "")) return false;
  if (data.path.some(p => p.length > MAX_PARAM_LENGTH)) return false;
  if (data.path.some(p => isInValidEventParam(p))) return false;

  return true;
};

export function isInValidEventParam(path: string): boolean {
  return !path.match(/^[0-9A-Za-z-\._]*$/);
}
