import SelectOption from "@/components/form/SelectOption";
import { AppLaunchType } from "@/models/search/filter-node/FilterNodeForLaunchApp";
import { i18n } from "@/i18n";

export const AppLaunchTypeOptions = (): SelectOption[] => {
  return [
    {
      label: i18n.t("components.search.filter.pushNotification") as string,
      value: AppLaunchType.PushNotification,
      disabled: false
    },
    {
      label: i18n.t("components.search.filter.link") as string,
      value: AppLaunchType.Link,
      disabled: false
    },
    {
      label: i18n.t("models.system.initialLaunch") as string,
      value: AppLaunchType.FirstOpen,
      disabled: false
    },
    {
      label: i18n.t("models.system.DeleteApp") as string,
      value: AppLaunchType.AppRemove,
      disabled: false
    }
  ];
};
