<i18n
  src="@/i18n/views/client-settings/td-conversion-change-measurement-state.json"
></i18n>

<template>
  <div class="tdConversionUpdateMeasurementState">
    <div v-t="'title'" class="tdConversionUpdateMeasurementState__title" />
    <div v-if="isLoading" class="tdConversionUpdateMeasurementState__loading">
      <Loading height="80px" />
    </div>

    <div v-else-if="tdConversion.isDisabled && !canEnableMeasurementState">
      <div
        v-t="'cannotEnableMeasurementState'"
        class="tdConversionUpdateMeasurementState__description"
      />

      <div>
        <SettingsTable
          class="tdConversionUpdateMeasurementState__disabledlLinkedConversion"
          min-width="680px"
        >
          <SettingsTableRow>
            <SettingsTableCell>
              <RouterLink :to="{ name: 'conversion-settings-index' }">{{
                conversionName
              }}</RouterLink>
            </SettingsTableCell>
          </SettingsTableRow>
        </SettingsTable>

        <div class="tdConversionUpdateMeasurementState__formButtons">
          <Button
            v-t="'cancel'"
            class="tdConversionUpdateMeasurementState__button  tdConversionUpdateMeasurementState__cancelButton"
            padding="0 20px"
            :text-size="buttonTextSize"
            :type="buttonTypeCancel"
            @click="onClickCancel"
          />
        </div>
      </div>
    </div>

    <div v-else>
      <div
        class="tdConversionUpdateMeasurementState__description"
        v-text="description"
      />

      <div>
        <SettingsTable class="tdConversionUpdateMeasurementState__table">
          <SettingsTableRow class="tdConversionUpdateMeasurementState__row">
            <SettingsTableCell
              v-t="'conversionName'"
              width="200px"
              text-align="center"
            />
            <SettingsTableCell>{{ conversionName }}</SettingsTableCell>
          </SettingsTableRow>
          <SettingsTableRow class="tdConversionUpdateMeasurementState__row">
            <SettingsTableCell v-t="'targetData'" text-align="center" />
            <SettingsTableCell> {{ targetDataText }}</SettingsTableCell>
          </SettingsTableRow>
          <SettingsTableRow class="tdConversionUpdateMeasurementState__row">
            <SettingsTableCell v-t="'matchCondition'" text-align="center" />
            <SettingsTableCell>{{
              `${tdConversion.cvCond} (${matchMethodText})`
            }}</SettingsTableCell>
          </SettingsTableRow>
        </SettingsTable>
        <div class="tdConversionUpdateMeasurementState__formButtons">
          <Button
            class="tdConversionUpdateMeasurementState__button tdConversionUpdateMeasurementState__submitButton"
            padding="0 20px"
            :text-size="buttonTextSize"
            :disabled="isSubmitButtonDisabled"
            @click="onClickSubmit"
            >{{ submitLabel }}</Button
          >
          <Button
            v-t="'cancel'"
            class="tdConversionUpdateMeasurementState__button tdConversionUpdateMeasurementState__cancelButton"
            padding="0 20px"
            :text-size="buttonTextSize"
            :type="buttonTypeCancel"
            @click="onClickCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import TdConversionDefinition from "@/models/client-settings/TdConversionDefinition";
import { Vue, Component } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import Button from "@/components/Button.vue";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { TdWebImportConfigDefinition } from "@/models/client-settings/TdWebImportConfigDefinition";
import { handleError } from "@/util/error-util";
import { ButtonTextSize, BUTTON_TYPE } from "@/const/button";

@Component({
  components: {
    Button,
    Loading,
    SettingsTable,
    SettingsTableCell,
    SettingsTableRow
  }
})
export default class TdConversionUpdateMeasurementState extends Vue {
  isLoading = true;
  buttonTextSize = ButtonTextSize.Small;
  buttonTypeCancel = BUTTON_TYPE.LIGHT;
  isSubmitting = false;

  get tdConversion(): TdConversionDefinition {
    return this.$store.state.tdConversion.selectedTdConversionDefinition;
  }

  get description(): string {
    return this.tdConversion.isDisabled
      ? this.$t("enableDescription").toString()
      : this.$t("disableDescription").toString();
  }

  get conversions(): ConversionDefinition[] {
    return this.$store.state.clientSettings.conversionDefinitions;
  }

  get conversion(): ConversionDefinition {
    const cvId = this.tdConversion.cvId;
    return this.conversions.filter(
      conversionDefinition => conversionDefinition.id === cvId
    )[0];
  }

  get conversionName(): string {
    return this.conversion.name;
  }

  get tdWebImportConfig(): TdWebImportConfigDefinition | null {
    return this.$store.state.tdWebImportConfig.tdWebImportConfigDetails;
  }

  get targetDataText(): string {
    if (this.tdWebImportConfig) {
      return this.tdWebImportConfig.locationUriColumn ===
        this.tdConversion.cvParamColumn
        ? this.$t("pageUrlText").toString()
        : this.$t("pageTitleText").toString();
    }

    return "";
  }

  get canEnableMeasurementState(): boolean {
    return !this.conversion.isDisabled;
  }

  get matchMethodText(): string {
    return this.$t("matchMethod." + this.tdConversion.matchMethod) as string;
  }

  get submitLabel(): string {
    return this.tdConversion.isDisabled
      ? this.$t("beginMeasuring").toString()
      : this.$t("stopMeasuring").toString();
  }

  get isSubmitButtonDisabled(): boolean {
    return this.isSubmitting;
  }

  async onClickSubmit() {
    this.isSubmitting = true;
    try {
      await this.updateTdConversion();
      this.$store.commit(
        "tdConversion/setSuccessMessage",
        this.tdConversion.isDisabled
          ? this.$t("enableSuccessMessage").toString()
          : this.$t("disableSuccessMessage").toString()
      );
      this.$router.push({ name: "td-conversion-settings" });
    } catch (e) {
      handleError(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  async updateTdConversion() {
    if (this.tdConversion) {
      const payload = {
        id: Number(this.$route.params.id),
        params: {
          cv_id: this.tdConversion.cvId,
          cv_param_column: this.tdConversion.cvParamColumn,
          cv_cond: this.tdConversion.cvCond,
          match_method: this.tdConversion.matchMethod,
          is_disabled: !this.tdConversion.isDisabled
        }
      };
      await this.$store.dispatch("tdConversion/updateTdConversion", payload);
    }
  }

  onClickCancel() {
    this.$router.push({ name: "td-conversion-settings" });
  }

  async created() {
    let tdConversion: TdConversionDefinition | null = null;
    try {
      const id: number = parseInt(this.$route.params.id);
      await this.$store.dispatch("tdConversion/fetchTdConversionById", id);

      await this.$store.dispatch(
        "tdWebImportConfig/fetchTdWebImportConfigDetail"
      );

      await this.$store.dispatch("clientSettings/fetchConversionDefinitions");
      tdConversion = this.$store.state.tdConversion
        .selectedTdConversionDefinition;
      if (!!tdConversion && tdConversion.id === id) {
        this.isLoading = false;
      } else {
        this.$router.push({ name: "td-conversion-settings" });
      }
    } catch (e) {
      handleError(e);
    }
  }

  destroyed() {
    this.$store.commit("tdConversion/setSelectedTdConversionDefinition", null);
  }
}
</script>

<style lang="scss" scoped>
.tdConversionUpdateMeasurementState {
  margin-right: 20px;
  min-width: 704px;
}

.tdConversionUpdateMeasurementState__title {
  padding: 0 0 40px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: inherit;
}

.tdConversionUpdateMeasurementState__description {
  line-height: 2;
  margin-bottom: 30px;
  white-space: pre-line;
}

.tdConversionUpdateMeasurementState__table {
  margin-bottom: 16px;
  width: 680px;
}

.tdConversionUpdateMeasurementState__disabledlLinkedConversion {
  margin-bottom: 20px;

  a {
    color: $colorBase700;
    text-decoration: underline;
  }

  a:hover {
    color: $colorBase800;
  }
}

.tdConversionUpdateMeasurementState__button {
  min-width: 120px;
}

.tdConversionUpdateMeasurementState__submitButton {
  margin-right: 15px;
}
</style>
