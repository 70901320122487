<template>
  <label :class="labelClass">
    <input type="radio" :name="name" :checked="checked" @change="onChange()" />
    <div :class="iconClass"></div>

    <div v-if="hasSlot" :class="wrapperClass">
      <slot></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import { RadioButtonSize } from "@/const/RadioButtonSize";

@Component
export default class RadioButton extends Vue {
  @Model("change", { type: [String, Number, Boolean], required: true })
  value!: string | number | boolean;

  @Prop({ type: [String, Number, Boolean], required: true })
  label!: string | number | boolean;

  @Prop({ type: String, default: "" })
  name!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: String, default: RadioButtonSize.Regular })
  size!: RadioButtonSize;

  onInput(value: string | number | boolean) {
    this.$emit("change", value);
  }

  get labelClass(): string {
    const baseClass = "radioButton";
    const classes = [baseClass];

    if (this.size === RadioButtonSize.Small) {
      classes.push(`${baseClass}--${this.size}`);
    }
    if (this.disabled) {
      classes.push(`${baseClass}--disabled`);
    }
    if (this.checked) {
      classes.push(`${baseClass}--checked`);
    }
    return classes.join(" ");
  }

  get iconClass(): string {
    const baseClass = "radioButton__icon";
    const classes = [baseClass];

    if (this.size === RadioButtonSize.Small) {
      classes.push(`${baseClass}--${this.size}`);
    }
    if (this.disabled) {
      classes.push(`${baseClass}--disabled`);
    }
    return classes.join(" ");
  }

  get wrapperClass(): string {
    const baseClass = "radioButton__wrapper";
    const classes = [baseClass];

    if (this.size === RadioButtonSize.Small) {
      classes.push(`${baseClass}--${this.size}`);
    }
    if (this.disabled) {
      classes.push(`${baseClass}--disabled`);
    }
    return classes.join(" ");
  }

  get checked(): boolean {
    return this.value === this.label;
  }

  // ラジオボタン単体仕様の場合、不要な余白を削除
  get hasSlot(): boolean {
    return Object.keys(this.$slots).length > 0;
  }

  onChange() {
    if (!this.disabled) {
      this.onInput(this.label);
    }
  }
}
</script>

<style scoped lang="scss">
.radioButton__icon {
  position: relative;
  width: $selectFormPartsSize;
  height: $selectFormPartsSize;
  border: 1px solid $colorBase600;
  border-radius: 50%;
  background-color: $colorWhite;
}

.radioButton--small {
  align-items: center;
}

.radioButton__icon--small {
  width: 18px;
  height: 18px;
}

.radioButton {
  display: inline-flex;
  padding-right: 10px;
  cursor: pointer;

  & [type="radio"] {
    display: none;
  }

  &--checked {
    .radioButton__icon {
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $colorDark;
        content: "";
        transform: translate(-50%, -50%);
      }
    }
    .radioButton__icon--small {
      &::after {
        width: 10px;
        height: 10px;
      }
    }
  }

  &--disabled {
    cursor: default;
    & .wrapper {
      color: $colorBase600;
    }

    .radioButton__icon {
      border: 1px solid $colorBase500;
      background-color: $colorBase300;
      content: "";
      &::after {
        background-color: $colorBase500;
      }
    }
  }
}

.radioButton__icon--disabled {
  background-color: $colorGray200 !important;
}

.radioButton__wrapper {
  margin-left: 10px;
  padding-top: 5px;
  color: $colorBase800;
  line-height: 1.2;

  @include default-break-word();
}

.radioButton__wrapper--small {
  margin-left: 6px;
  padding-top: 0;
}

.radioButton__wrapper--disabled {
  color: $colorBase600;
}
</style>
