import { TdWebImportConfigDefinition } from "@/models/client-settings/TdWebImportConfigDefinition";
import { RootState } from "@/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";

export class TdWebImportConfigState {
  tdWebImportConfigDetails: TdWebImportConfigDefinition | null = null;
}
const mutations: MutationTree<TdWebImportConfigState> = {
  setTdWebImportConfigDetails(
    state: TdWebImportConfigState,
    tdWebImportConfigDetails: TdWebImportConfigDefinition
  ) {
    state.tdWebImportConfigDetails = tdWebImportConfigDetails;
  }
};

const getters: GetterTree<TdWebImportConfigState, RootState> = {};

const actions: ActionTree<TdWebImportConfigState, RootState> = {
  async fetchTdWebImportConfigDetail({ commit, rootState }) {
    const response = await rootState.api.tdWebImportConfig.getTdWebImportConfigDetails();
    const tdWebImportConfigDetails = TdWebImportConfigDefinition.fromJson(
      response
    );
    commit("setTdWebImportConfigDetails", tdWebImportConfigDetails);
  },

  async updateTdWebImportConfig(
    { rootState },
    params: TdWebImportConfigDefinition
  ) {
    await rootState.api.tdWebImportConfig.updateTdWebImportConfig(
      params.toJson()
    );
  }
};

export const tdWebImportConfig = {
  namespaced: true,
  state: new TdWebImportConfigState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
