import { FavoriteSearch } from "@/models/search/FavoriteSearch";
import store from "@/store";
import { computed, Ref, ref } from "vue";

export function useSearchBalloon() {
  const SHOW_BALLOON_WAIT_DURATION = 300;
  const HIDE_BALLOON_WAIT_DURATION = 100;
  const ADJUST_BALLOON_POSITION = 70;
  const ADJUST_BALLOON_POSITION_WITH_NO_PERIOD = 30;

  // whether to display balloon or not
  const lastHoveredSearchId: Ref<number | null> = ref(null);
  const balloonDisplayTimer: Ref<number | null> = ref(null);
  const balloonHideTimer: Ref<number | null> = ref(null);
  const isBalloonHover = computed(
    () => store.state.preference.isFavoriteSearchBalloonHovered
  );
  const isItemHover = ref(false);

  // where to display balloon
  const defaultBalloonTop = ref(0); // Default position of balloon before screen scrolled.
  const navScroll: number = 0;
  const homeScroll: number = 0;

  function showSearchBalloon(event: MouseEvent, search: FavoriteSearch) {
    isItemHover.value = true;

    if (balloonDisplayTimer.value !== null) {
      clearTimeout(balloonDisplayTimer.value);
    }

    // When hovered favorite search is different with search in Balloon.
    if (search.id !== lastHoveredSearchId.value) {
      resetSearchBalloonStats();
    }

    // Set timer to update balloonPositionY
    balloonDisplayTimer.value = window.setTimeout(() => {
      updateHoveredFavoriteSearch(search);
      lastHoveredSearchId.value = search.id;
      const evTarget = event.target;
      const target: HTMLDivElement = evTarget as HTMLDivElement;
      if ("startDate" in search.history.selectCondition!) {
        updateBalloonArrowAdjust(0);
        defaultBalloonTop.value =
          target.getBoundingClientRect().top +
          navScroll -
          ADJUST_BALLOON_POSITION;
      } else {
        updateBalloonArrowAdjust(-5);
        defaultBalloonTop.value =
          target.getBoundingClientRect().top +
          navScroll -
          ADJUST_BALLOON_POSITION_WITH_NO_PERIOD;
      }
      updateBalloonPosition();
    }, SHOW_BALLOON_WAIT_DURATION);
  }

  function hideSearchBalloon() {
    isItemHover.value = false;

    if (balloonDisplayTimer.value !== null) {
      clearTimeout(balloonDisplayTimer.value);
    }

    // Set timer to update balloonPositionY
    balloonHideTimer.value = window.setTimeout(() => {
      if (!isBalloonHover.value) {
        resetSearchBalloonStats();
      }
      //  delete timer after use
      if (balloonHideTimer.value !== null) {
        clearTimeout(balloonHideTimer.value);
        balloonHideTimer.value = null;
      }
    }, HIDE_BALLOON_WAIT_DURATION);
  }

  function updateBalloonPosition() {
    const balloonPositionY =
      defaultBalloonTop.value - navScroll + homeScroll + "px";
    store.commit(
      "preference/setFavoriteSearchBalloonPositionY",
      balloonPositionY
    );
  }
  function updateBalloonArrowAdjust(balloonArrowAdjust: number) {
    store.commit(
      "preference/setFavoriteSearchBalloonArrowAdjust",
      balloonArrowAdjust
    );
  }
  function updateHoveredFavoriteSearch(favoriteSearch: FavoriteSearch) {
    store.commit("preference/setHoveredFavoriteSearch", favoriteSearch);
  }
  function resetSearchBalloonStats() {
    store.commit("preference/setFavoriteSearchBalloonPositionY", "");
    store.commit("preference/setHoveredFavoriteSearch", null);
  }
  return {
    showSearchBalloon,
    hideSearchBalloon,
    resetSearchBalloonStats
  };
}
