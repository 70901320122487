<template>
  <div class="spreadsheetForm">
    <div ref="spreadsheetForm" class="spreadsheetForm__container"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import jspreadsheet, {
  JSpreadsheetOptions,
  Column,
  JspreadsheetInstanceElement,
  CellValue
} from "jspreadsheet-ce";

interface SpreadsheetDataRow {
  [key: string]: string | number;
}

@Component
export default class SpreadSheetForm extends Vue {
  @Prop({ type: Array, required: true })
  columns!: Column[];

  @Prop({ type: Number, required: true })
  numberOfColumns!: number;

  @Prop({ type: Number, required: true })
  numberOfRows!: number;

  @Prop({ type: Array, required: false, default: () => [] })
  initialData!: CellValue[][] | Record<string, CellValue>[];

  @Emit("data-changed")
  getData(instance: JspreadsheetInstanceElement) {
    let jsonData = instance.jexcel.getJson(false) as SpreadsheetDataRow[];
    jsonData = jsonData.map(row => {
      for (let key in row) {
        if (row[key] === "\u0000") {
          row[key] = "";
        }
      }
      return row;
    });

    return jsonData;
  }

  mounted() {
    const options: JSpreadsheetOptions = {
      data: this.initialData,
      minDimensions: [this.numberOfColumns, this.numberOfRows],
      columns: this.columns,
      selectionCopy: false,
      onchange: this.getData,
      onundo: this.getData,
      onredo: this.getData,
      filters: false,
      columnSorting: false,
      columnResize: false,
      rowResize: false,
      onbeforepaste: (
        instance: JspreadsheetInstanceElement,
        copiedText: string,
        colIndex: string | number,
        rowIndex: string | number
      ) => {
        const parsedData = copiedText
          .split("\n")
          .map(row => row.replace(/\t+$/g, "").split("\t"));

        const allowedHeight = this.numberOfRows - Number(rowIndex);
        const allowedWidth = this.numberOfColumns - Number(colIndex);

        const truncatedData = parsedData
          .slice(0, allowedHeight)
          .map(row => row.slice(0, allowedWidth));

        return truncatedData.map(row => row.join("\t")).join("\n");
      },
      // @ts-ignore
      contextMenu: false,
      allowDeleteRow: false,
      allowDeleteColumn: false,
      allowInsertRow: false,
      allowInsertColumn: false,
      allowExport: false,
      allowManualInsertRow: false,
      allowManualInsertColumn: false,
      allowRenameColumn: false,
      parseFormulas: false
    };

    jspreadsheet(
      this.$refs.spreadsheetForm as JspreadsheetInstanceElement,
      options
    );

    this.setInedexColumnName();
    this.pareseTableHeaderTextAsHtml();
  }

  // Jspreadsheet does not support html content in the header so doing it manually
  pareseTableHeaderTextAsHtml() {
    this.$nextTick().then(() => {
      const headerCells = this.$el.querySelectorAll(
        "thead td:not(.jexcel_selectall)"
      );
      headerCells.forEach((cell, index) => {
        const titleWithBreaks = this.columns[index].title! as string;
        cell.innerHTML = titleWithBreaks;
        const titleWithoutBreaks = titleWithBreaks.replace(/<br>/g, " ");
        (cell as HTMLElement).title = titleWithoutBreaks.replace(
          /<[^>]*>?/gm,
          ""
        );
      });
    });
  }

  // Jspreadsheet does not provude a way to set the index column name so we have to do it manually
  setInedexColumnName() {
    let tdElement = this.$el.querySelector(".jexcel_selectall");
    if (tdElement) {
      tdElement.textContent = "No";
    }
  }

  highlightErrorCell(col: number, row: number) {
    const cellElement = this.$el.querySelector(
      `td[data-x="${col}"][data-y="${row}"]`
    );
    if (cellElement) {
      cellElement.classList.add("error-highlight");
    }
  }

  clearErrorHighlights() {
    const highlightedCells = this.$el.querySelectorAll("td.error-highlight");
    highlightedCells.forEach(cell => {
      cell.classList.remove("error-highlight");
    });
  }
}
</script>

<style lang="scss" scoped>
.spreadsheetForm {
  position: relative;
  display: inline-block;
}

::v-deep .jexcel > thead > tr > td,
::v-deep .jexcel > tbody > tr > td {
  padding: 5px !important;
  background-color: $colorWhite;
  vertical-align: middle;
}

::v-deep .jexcel > thead > tr > td {
  font-size: 12px;
  line-height: 1.4;
  height: 66px;
}

::v-deep .jexcel_row {
  background-color: $colorWhite !important;
}

::v-deep .jexcel_selectall {
  text-align: center;
  vertical-align: middle;
}

::v-deep .error-highlight {
  border: 1px solid $colorError;
}

::v-deep .thead-cell-header-subtitle {
  color: $colorBase700;
  font-size: 10px;
  transform: scale(0.9);
}

::v-deep .jexcel > thead > tr > td:nth-child(3) .thead-cell-header-subtitle {
  display: none;
}
</style>
