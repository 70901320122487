import { ActionTree, MutationTree } from "vuex";
import { ApiRes } from "@/api/api-res";
import { RootState } from "@/store/";
import { User, UserMemo } from "@/models/User";
import {
  ClusteringPattern,
  LabelTitle
} from "@/models/search/ClusteringPattern";
import { getEndDate } from "@/components/chart/chart-util";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { ClusteringListData } from "@/api/apis/ApiSearch";
import { generateUniqueId } from "@/util/common-util";

const INITIAL_USER_FETCH_COUNT = 50;

interface ClusteringSearchResult {
  users: ApiRes.User[];
}

export class ClusteringState {
  // クラスタリング中かどうか
  isClusteringMode: boolean = false;

  // クラスタリング結果
  users: User[] = [];

  // クラスタリングのパターンに該当する件数
  userCount: number = 0;

  // クラスタリングリストの計算中かどうかの状態
  isPatternFetched: boolean = false;

  // 計算されたclusteringパターン一覧
  patternList: ClusteringPattern[] = [];

  // 現在選択されているパターン
  selectedPattern: ClusteringPattern | null = null;

  // 絞り込み結果を返すイテレーター、取得できない場合はnullを返す
  resultIterator: AsyncIterableIterator<ClusteringSearchResult> | null = null;

  // クラスタリングの結果が取得されたかどうか
  isFetched: boolean = false;

  // クラスタリングで使用しているhistory_id
  // select または filter のものをセットする
  historyId: number | null = null;

  // 選択されたパターンでの、検索（絞り込み）結果に対する割合
  ratio: number = 0;

  lastExecuteTimeStamp: string | null = null;
}

const mutations: MutationTree<ClusteringState> = {
  setClusteringFetched(state: ClusteringState, isPatternFetched: boolean) {
    state.isPatternFetched = isPatternFetched;
  },
  setClusteringList(state: ClusteringState, patternList: ClusteringPattern[]) {
    state.patternList = patternList;
  },
  addClusteringResult(state: ClusteringState, users: User[]) {
    state.users.push(...users);
  },
  setIsFetched(state: ClusteringState, isFetched: boolean) {
    state.isFetched = isFetched;
  },
  setUserCount(state: ClusteringState, userCount: number) {
    state.userCount = userCount;
  },
  initializeClusteringResults(state: ClusteringState) {
    state.users = [];
    state.userCount = 0;
    state.historyId = null;
    state.lastExecuteTimeStamp = null;
  },
  setIsClusteringMode(state: ClusteringState, isClusteringMode: boolean) {
    state.isClusteringMode = isClusteringMode;
  },
  setClusteringPattern(state: ClusteringState, pattern: ClusteringPattern) {
    state.selectedPattern = pattern;
  },
  resetClusteringList(state: ClusteringState) {
    state.selectedPattern = null;
    state.isPatternFetched = false;
    state.patternList = [];
  },
  setClusteringResultIterator(
    state: ClusteringState,
    iterator: AsyncIterableIterator<ClusteringSearchResult>
  ) {
    state.resultIterator = iterator;
  },
  setClusteringHistoryId(state: ClusteringState, historyId: number) {
    state.historyId = historyId;
  },
  setRatio(state: ClusteringState, ratio: number) {
    state.ratio = ratio;
  },
  updateMemo(state: ClusteringState, { userId, memo, isMemoPage }: UserMemo) {
    const memoUser = state.users.find(u => u.id === userId);
    if (memoUser !== undefined) {
      let users: User[] = [];
      if (isMemoPage) {
        users = state.users.filter(u => u.id !== userId);
        users.unshift(memoUser.updateMemo(memo));
      } else {
        users = [...state.users];
        const index = users.findIndex(u => u.id === userId);
        users[index] = users[index].updateMemo(memo);
      }
      state.users = users;
    }
  },
  setLastExecuteTimeStamp(state, timeStamp: string) {
    state.lastExecuteTimeStamp = timeStamp;
  }
};

const actions: ActionTree<ClusteringState, RootState> = {
  async fetchClusteringList({ commit, state, rootState }) {
    // 検索後でも絞り込み後でも、履歴IDを使用してクラスタリング実施
    const isFilterMode: boolean = rootState.filter.isFilterMode;
    const historyId: number | null = isFilterMode
      ? rootState.filter.filterHistoryId
      : rootState.search.historyId;

    if (historyId === null) {
      return;
    }
    const executeTimeStamp = generateUniqueId();
    commit("setLastExecuteTimeStamp", executeTimeStamp);
    commit("setClusteringFetched", false);

    // 絞り込み時でも 検索条件の終了日 (getEndDate) を使用する
    const selectCondition: SelectCondition | null =
      rootState.search.selectCondition;

    const response: ClusteringListData = await rootState.api.search.getClusteringList(
      getEndDate(selectCondition),
      historyId
    );

    // 新しい解析が実行された場合はキャンセル
    if (executeTimeStamp != state.lastExecuteTimeStamp) {
      return;
    }

    // A ~ F の6パターンと 未集計 の状態を保持
    const patternTypes: LabelTitle[] = ["A", "B", "C", "D", "E", "F", "No"];
    const clusteringList: ClusteringPattern[] = response.clusters.map(
      (cluster, index) => {
        return ClusteringPattern.fromJson(cluster, patternTypes[index]);
      }
    );

    // クラスタリングリストを保持する
    commit("setClusteringList", clusteringList);
    commit("setClusteringFetched", true);
  },
  async executeClustering({ commit, state, rootState, dispatch }) {
    const selectedPattern: ClusteringPattern | null = state.selectedPattern;
    if (!selectedPattern) {
      return;
    }

    const isFilterMode: boolean = rootState.filter.isFilterMode;
    let historyId: number | null = null;
    let userCountDenom: number = 0;
    if (isFilterMode) {
      historyId = rootState.filter.filterHistoryId;
      userCountDenom = rootState.filter.userCount;
    } else {
      historyId = rootState.search.historyId;
      userCountDenom = rootState.search.userCount;
    }

    if (historyId === null) {
      return;
    }

    commit("initializeClusteringResults");
    commit("setIsClusteringMode", true);

    const clusteringRatio: number =
      Math.floor((selectedPattern.userCount * 1000) / userCountDenom) / 10;
    commit("setUserCount", selectedPattern.userCount);
    commit("setRatio", clusteringRatio);

    // キャンセル処理のためstateに保存する
    commit("setClusteringHistoryId", historyId);

    // クラスタリング結果を返すIteratorを取得
    const clusteringIterator: AsyncIterableIterator<ClusteringSearchResult> = rootState.api.search.getClusteringUserResult(
      historyId,
      selectedPattern
    );

    commit("setClusteringResultIterator", clusteringIterator);

    return await dispatch("fetchClusteringResult", historyId);
  },

  async fetchClusteringResult(
    { state, dispatch, commit },
    clusteringHistoryId: number
  ) {
    commit("setIsFetched", false);
    // INITIAL_USER_FETCH_COUNT 分ユーザを取得するか、ユーザを全取得するまで問い合わせる
    while (
      state.users.length < INITIAL_USER_FETCH_COUNT &&
      state.historyId === clusteringHistoryId &&
      state.users.length < state.userCount
    ) {
      await dispatch("fetchClusteringResultOnce").catch(e => {
        if (state.historyId === clusteringHistoryId) {
          throw e;
        }
      });
    }
    commit("setIsFetched", true);
  },
  async fetchNextClusteringResult({ commit, dispatch }) {
    commit("setIsFetched", false);
    await dispatch("fetchClusteringResultOnce");
    commit("setIsFetched", true);
  },
  /**
   * クラスタリングの結果を一度だけ問い合わせる
   */
  async fetchClusteringResultOnce({ commit, state, rootState, rootGetters }) {
    // クラスタリングの情報がない場合は無視
    if (state.historyId === null || state.resultIterator === null) {
      return;
    }

    // 取得する前に履歴IDを保存する
    const historyId = state.historyId;
    const iteratorResult: IteratorResult<ClusteringSearchResult> = await state.resultIterator.next();

    // 取得後に履歴IDが変わっていないか確認する
    if (!iteratorResult.done && state.historyId === historyId) {
      // レスポンスを取得
      const clusteringResult: ClusteringSearchResult = iteratorResult.value;

      const canUseWebdataFeaturesAndIsContractApp = rootState.client.client
        ? rootState.app.canUseWebdataFeatures &&
          rootState.client.client.isContractApp
        : false;

      // ユーザモデルのビルド
      const users: User[] = clusteringResult.users.map(user =>
        User.build(
          user,
          rootGetters["clientSettings/allConversionDefinitions"],
          rootState.clientSettings.npsDefinitions,
          rootState.clientSettings.businessEventDefinitions,
          rootState.clientSettings.enqueteDefinitions,
          rootState.clientSettings.businessIndexDefinitions,
          canUseWebdataFeaturesAndIsContractApp
        )
      );

      // 取得したユーザ情報を保存
      commit("addClusteringResult", users);
    }

    return;
  },
  /**
   * 検索実行時・絞り込み実行時・絞り込みクリア時にクラスタリングをリセット
   */
  resetClustering({ commit }) {
    commit("setIsClusteringMode", false);
    commit("resetClusteringList");
    commit("initializeClusteringResults");
  },
  /**
   * クラスタリングを解除して、検索（または絞り込み）画面を表示させる
   */
  clearClustering({ commit }) {
    commit("setIsClusteringMode", false);
    commit("setClusteringPattern", null);
    commit("initializeClusteringResults");
  }
};

export const clustering = {
  namespaced: true,
  state: new ClusteringState(),
  mutations,
  actions
};
