import { MatchMethod } from "@/models/search/MatchMethod";
import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * 入口ページURLによる条件
 *
 * 絞り込み条件に追加で、入口ページURLを条件として付与できる
 */
export class FilterLandingPageUrlCondition {
  public readonly conditionType = FilterNodeConditionType.LandingPageUrl;

  constructor(
    public readonly pageUrl: string,
    // 文字列のマッチ条件（部分一致 or 完全一致）
    public readonly matchMethod: MatchMethod
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.pageUrl,
      i18n.t("models.search.entryPageUrl") as string
    );
  }
}
