<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="select-by-contact-form">
    <div class="title">
      <TitleTextWithIcon :title="$t('period')" :icon="iconHistoy" />
    </div>
    <div class="period">
      <DateRangePickerInput
        v-model="dateValue"
        :enabled-period="enabledPeriod"
      />
      <SelectBox v-model="hourValue" class="hour" :options="hourOptions" />
    </div>
    <div class="title">
      <TitleTextWithIcon :title="$t('contact')" :icon="iconConversion" />
    </div>
    <div class="select-by-contact-form__checkbox-list">
      <div
        v-for="(option, index) in contactOptions"
        :key="index"
        class="select-by-contact-form__checkbox-list-item"
      >
        <CheckBox
          :checked="isChecked(option.value)"
          :disabled="option.disabled"
          @input="onInput($event, option.value)"
        >
          <div>
            {{ option.label }}
            <div
              v-if="option.disabled"
              v-t="'importNotSet'"
              class="select-by-contact-form__no-import"
            />
          </div>
        </CheckBox>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import {
  hourlyIntervalsSelectOpitions,
  HOURLY_INTERVALS_DEFAULT_VALUE
} from "@/util/date-util";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import { DateRange } from "@/components/date-picker/DateRange";
import { enabledPeriodRange } from "@/util/date-range-picker-util";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import CheckBox from "@/components/form/CheckBox.vue";

import SelectOption from "@/components/form/SelectOption";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    DateRangePickerInput,
    TitleTextWithIcon,
    SelectBox,
    CheckBox
  }
})
export default class SelectByContactForm extends Vue {
  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  @Model("input", { type: SelectByContactCondition, required: true })
  condition!: SelectByContactCondition;

  onUpdate(condition: SelectByContactCondition) {
    this.$emit("input", condition);
  }

  iconConversion = Icons.Conversion;
  iconHistoy = Icons.History;

  get enabledPeriod(): DateRange {
    return enabledPeriodRange();
  }

  // 時間帯Selectのオプション
  get hourOptions(): SelectOption[] {
    return hourlyIntervalsSelectOpitions();
  }

  /**
   * 行動履歴は、電話あり、メール問い合せあり、来店ありの３つは常にだすが、
   * contactDefinitionsに無いものは、（インポート未設定につき選択できません）
   * のテキストと共にdisableにする
   */
  get contactOptions(): SelectOption[] {
    const tel = this.contactDefinitions.find(
      d => d.type === ContactDefinitionType.TEL
    );
    const mail = this.contactDefinitions.find(
      d => d.type === ContactDefinitionType.MAIL
    );
    const shop = this.contactDefinitions.find(
      d => d.type === ContactDefinitionType.SHOP
    );
    return [
      {
        value: tel?.id || -1,
        label: this.$t("callAccepted") as string,
        disabled: tel === undefined
      },
      {
        value: mail?.id || -1,
        label: this.$t("mailInquiryAccepted") as string,
        disabled: mail === undefined
      },
      {
        value: shop?.id || -1,
        label: this.$t("visitAccepted") as string,
        disabled: shop === undefined
      }
    ];
  }

  // 日付の値
  get dateValue(): DateRange | null {
    if (this.condition.endDate !== null && this.condition.startDate !== null) {
      return {
        min: this.condition.startDate,
        max: this.condition.endDate
      };
    }

    return null;
  }
  set dateValue(dateRange: DateRange | null) {
    if (dateRange !== null) {
      const condition = new SelectByContactCondition(
        this.condition.contactDefinitionIds,
        dateRange.min,
        dateRange.max,
        this.condition.hour,
        this.condition.additionalConditions
      );
      this.onUpdate(condition);
    }
  }

  // 時間帯の値
  get hourValue(): number {
    // selectのvalueにnullは渡せないのでnullの場合はHOURLY_INTERVALS_DEFAULT_VALUEを渡す
    return this.condition.hour === null
      ? HOURLY_INTERVALS_DEFAULT_VALUE
      : this.condition.hour;
  }
  set hourValue(hour: number) {
    // HOURLY_INTERVALS_DEFAULT_VALUEだった場合はnullに戻す
    const setHour = hour === HOURLY_INTERVALS_DEFAULT_VALUE ? null : hour;
    const condition = new SelectByContactCondition(
      this.condition.contactDefinitionIds,
      this.condition.startDate,
      this.condition.endDate,
      setHour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  // 選択された値
  get contactValues(): number[] {
    return this.condition.contactDefinitionIds;
  }
  set contactValues(contactDefinitionIds: number[]) {
    const condition = new SelectByContactCondition(
      contactDefinitionIds,
      this.condition.startDate,
      this.condition.endDate,
      this.condition.hour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  isChecked(value: number) {
    return this.condition.contactDefinitionIds.some(id => id === value);
  }

  onInput(checked: boolean, value: number) {
    let ids: number[] = [];
    if (checked) {
      ids = this.condition.contactDefinitionIds.concat([value]);
    } else {
      ids = this.condition.contactDefinitionIds.filter(id => id !== value);
    }
    const condition = new SelectByContactCondition(
      ids,
      this.condition.startDate,
      this.condition.endDate,
      this.condition.hour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }
}
</script>

<style scoped lang="scss">
.title {
  margin: 30px 0 10px 0;
  &:first-child {
    margin-top: 0;
  }
}
.period {
  position: relative;
  display: flex;
  height: $formPartsHeight;
}
.hour {
  margin-left: 20px;
}
.select-by-contact-form__checkbox-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}

.select-by-contact-form__checkbox-list-item {
  margin-bottom: 10px;
  padding-left: 10px;
  width: 33%;
}

.select-by-contact-form__no-import {
  font-size: 12px;
}
</style>
