<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :title="$t('nodeTitleBrowseApp')"
    class="filter-node-browse-app"
    :show-add-condition="condition.isConditionAddable(isFirstNode)"
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    :select-exclusion-type-value="exclutionTypeValue"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-condition="onClickAddCondition"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <FilterAppBrowseField
      :screen-name="condition.screenName"
      :match-method="condition.matchMethod"
      @input-screen-name="onInputScreenName"
      @input-match-method="onInputMatchMethod"
    />
    <FilterStayTimeField v-if="canUseStayTime" v-model="stayTimeCondition" />
    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :class="index == 0 ? 'filter-first-additional-node-item' : ''"
      :options="condition.selectOption(additionalCondition, false, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import { FilterNodeForBrowseApp } from "@/models/search/filter-node/FilterNodeForBrowseApp";
import {
  ExclusionProps,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterStayTimeCondition } from "@/models/search/filter-node-condition/FilterStayTimeCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

import FilterNode from "@/components/filter/FilterNode.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import FilterAppBrowseField from "@/components/filter/form/FilterAppBrowseField.vue";
import FilterStayTimeField from "@/components/filter/form/FilterStayTimeField.vue";

@Component({
  name: "FilterNodeBrowseApp",
  components: {
    FilterNode,
    FilterNodeItem,
    FilterDateHourField,
    FilterPeriodField,
    FilterAppBrowseField,
    FilterStayTimeField
  }
})
export default class FilterNodeBrowseApp extends Vue {
  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: FilterNodeForBrowseApp, required: true })
  condition!: FilterNodeForBrowseApp;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  onInput(condition: FilterNodeForBrowseApp) {
    this.$emit("input", condition);
  }

  onInputScreenName(name: string) {
    this.onInput(this.createCondition(name, null, null, null));
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(this.createCondition(null, matchMethod, null, null));
  }

  get stayTimeCondition(): FilterStayTimeCondition {
    return this.condition.filterStayTime;
  }
  set stayTimeCondition(condition: FilterStayTimeCondition) {
    this.onInput(this.createCondition(null, null, null, condition));
  }
  // 条件を追加
  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    let addCondition!: FilterAdditionalTimingCondition;
    const hasFirstTime = additionalConditions.some(
      cnd => cnd instanceof FilterFirstTimeCondition
    );

    if (this.isFirstNode && !hasFirstTime) {
      addCondition = FilterFirstTimeCondition.buildDefaultData();
    } else {
      addCondition = FilterPeriodCondition.buildDefaultData();
    }
    additionalConditions.push(addCondition);
    this.onInput(this.createCondition(null, null, additionalConditions, null));
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(this.createCondition(null, null, additionalConditions, null));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let additionalCondition!: FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.FirstTime) {
      additionalCondition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      additionalCondition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      additionalCondition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? additionalCondition : cnd)
    );
    this.onInput(this.createCondition(null, null, additionalConditions, null));
  }

  onInputAdditionalCondition(
    condition: FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(this.createCondition(null, null, additionalConditions, null));
  }

  createCondition(
    screenName: string | null,
    matchMethod: MatchMethod | null,
    conditions: FilterAdditionalTimingCondition[] | null,
    filterStayTime: FilterStayTimeCondition | null
  ): FilterNodeForBrowseApp {
    const setScreenName =
      screenName === null ? this.condition.screenName : screenName;
    const setMatchMethod =
      matchMethod === null ? this.condition.matchMethod : matchMethod;
    const setConditions =
      conditions === null ? this.condition.additionalConditions : conditions;
    const setFilterTime =
      filterStayTime === null ? this.condition.filterStayTime : filterStayTime;
    return new FilterNodeForBrowseApp(
      setScreenName,
      setMatchMethod,
      setConditions,
      this.condition.childIndex,
      this.condition.depth,
      this.condition.edge,
      this.condition.filterExclusion,
      setFilterTime
    );
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForBrowseApp(
        this.condition.screenName,
        this.condition.matchMethod,
        this.condition.additionalConditions,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue),
        this.condition.filterStayTime
      )
    );
  }
  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get canUseStayTime(): boolean {
    return !this.exclusion.isExcluded && this.condition.depth === 0;
  }

  get checkAdditionalConditions(): boolean {
    const conditionsMax = 2;
    const conditions = this.$props.condition;

    return (
      conditions.additionalConditions &&
      conditions.additionalConditions.length === conditionsMax
    );
  }
}
</script>
<style scoped>
.filter-first-additional-node-item {
  margin-top: 15px;
}
</style>
