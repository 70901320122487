<i18n src="@/i18n/components/input-form-suggestion/suggestion-list.json"></i18n>
<template>
  <div class="suggestionList">
    <div
      v-if="isError || isNoResult || isLoading"
      class="suggestionList__message"
    >
      <p v-if="isError">
        {{ $t("isError") }}
      </p>
      <p v-else-if="isLoading">
        {{ $t("isLoading") }}
      </p>
      <p v-else>
        {{ $t("isNoResult") }}
      </p>
    </div>
    <ul v-else class="suggestionList__results customScrollbar">
      <SuggestionItem
        v-for="(text, idx) in suggestionResults"
        :key="`suggestion-${idx}`"
        :whole-text="text"
        :highlight-text="highlightText"
        :is-selected="idx === selectedIndex"
        class="suggestionList__item"
        @click="setResult(text)"
      />
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import SuggestionItem from "@/components/input-form-suggestion/SuggestionItem.vue";

@Component({
  components: {
    SuggestionItem
  }
})
export default class SuggestionList extends Vue {
  @Prop({ type: Array, required: true })
  suggestionResults!: string[];

  @Prop({ type: String, required: true })
  highlightText!: string;

  @Prop({ type: Boolean, default: false })
  isError!: boolean;

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean;

  @Prop({ type: Number, default: 0 })
  selectedIndex!: number;

  get isNoResult(): boolean {
    return this.suggestionResults.length === 0;
  }

  setResult(wholeText: string) {
    this.$emit("click", wholeText);
  }
}
</script>

<style lang="scss" scoped>
.suggestionList {
  margin-top: 6px;
  width: calc(100% - 375px);
  border: 1px solid $colorBase600;
  border-radius: 5px;
  overflow: hidden;
  background-color: $colorWhite;
}

.suggestionList__message {
  height: 100%;
  color: $colorBase700;
  padding: 9px 11px;
  font-size: 12px;
  line-height: 1.5;
}
.suggestionList__results {
  overflow-y: scroll;
  height: auto;
  max-height: 266px;
}

.suggestionList__item {
  border-bottom: 1px solid $colorBase400;
}
.suggestionList__results li:last-child {
  border-bottom: none;
}
</style>
