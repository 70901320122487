import { ApiRes } from "@/api/api-res";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";

/**
 * 行動履歴Overview
 *
 * 行動履歴としてOMOインポートしたグラムを表す。
 * 行動履歴とはユーザとクライアントが接触した履歴のこと
 */
export class ContactOverview {
  public constructor(
    // グラムのID
    public readonly gramId: string,
    // 行動が記録された時間
    public readonly date: Date,
    // 説明
    public readonly content: string,
    // メール or 電話 or 来店
    public readonly type: ContactDefinitionType,
    // 通話時間(電話以外は使わない)
    public readonly talkTime: number = 0
  ) {}

  /**
   * APIのデータからモデルに変換する
   */
  public static fromJson(json: ApiRes.ContactOverview): ContactOverview {
    // メールの場合
    if (isEmailContactOverview(json)) {
      return new ContactOverview(
        json.gram_id,
        new Date(json.time_usec / 1000),
        json.title,
        ContactDefinitionType.MAIL
      );
    }

    // 電話の場合
    if (isPhoneContactOverview(json)) {
      return new ContactOverview(
        json.gram_id,
        new Date(json.time_usec / 1000),
        json.content,
        ContactDefinitionType.TEL,
        json.talk_time_sec
      );
    }

    // 来店の場合
    return new ContactOverview(
      json.gram_id,
      new Date(json.time_usec / 1000),
      json.purpose,
      ContactDefinitionType.SHOP
    );
  }
}

function isEmailContactOverview(
  json: ApiRes.ContactOverview
): json is ApiRes.EmailContactOverview {
  return json.data_type === ContactDefinitionType.MAIL;
}

function isPhoneContactOverview(
  json: ApiRes.ContactOverview
): json is ApiRes.PhoneContactOverview {
  return json.data_type === ContactDefinitionType.TEL;
}
