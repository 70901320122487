<template>
  <div class="datePickerPopupContainer">
    <div class="datePickerPopupContainer__buttonContainer">
      <div class="datePickerPopupContainer__previousButtonContainer">
        <button
          class="datePickerPopupContainer__yearButton datePickerPopupContainer__previousYearButton"
          :title="previousYearCalendar.title"
          @click="onClick(previousYearCalendar)"
        />

        <button
          class="datePickerPopupContainer__monthButton datePickerPopupContainer__previousMonthButton"
          :title="previousMonthCalendar.title"
          @click="onClick(oneMonthAgoCalendar)"
        />
      </div>
      <div class="datePickerPopupContainer__nextButtonContainer">
        <button
          class="datePickerPopupContainer__monthButton datePickerPopupContainer__nextMonthButton"
          :title="nextMonthCalendar.title"
          @click="onClick(nextMonthCalendar)"
        />

        <button
          class="datePickerPopupContainer__yearButton datePickerPopupContainer__nextYearButton"
          :title="nextYearCalendar.title"
          @click="onClick(nextYearCalendar)"
        />
      </div>
    </div>

    <div class="datePickerPopupContainer__body">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DatePicker } from "@/models/date-picker/DatePicker";
import { Calendar } from "@/models/date-picker/Calendar";

@Component
export default class DatePickerPopupContainer extends Vue {
  @Prop({ type: DatePicker, required: true })
  datePicker!: DatePicker;

  onClick(calender: Calendar) {
    this.$emit("move-year-month", calender);
  }

  get calendars(): Calendar[] {
    return this.datePicker.calendars;
  }

  // 移動自体は1ヶ月単位で行いたいため、previousMonthCalendarではなくこちらを使う
  get oneMonthAgoCalendar(): Calendar {
    return this.calendars[1];
  }

  get previousMonthCalendar(): Calendar {
    return this.datePicker.previousMonthCalendar;
  }

  get nextMonthCalendar(): Calendar {
    return this.datePicker.nextMonthCalendar;
  }

  get previousYearCalendar(): Calendar {
    return this.datePicker.previousYearCalendar;
  }

  get nextYearCalendar(): Calendar {
    return this.datePicker.nextYearCalendar;
  }
}
</script>

<style scoped lang="scss">
.datePickerPopupContainer {
  position: relative;
  background-color: $colorWhite;
}

.datePickerPopupContainer__previousButtonContainer {
  position: absolute;
  top: 3px;
  left: 0;
  display: flex;
}

.datePickerPopupContainer__nextButtonContainer {
  position: absolute;
  top: 3px;
  right: 0;
  display: flex;
}

.datePickerPopupContainer__monthButton {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: $colorWhite;
  line-height: 1;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &::before {
    position: absolute;
    top: 9px;
    width: 6px;
    height: 6px;
    border-top: 1px solid $colorElmentUIGray600;
    content: "";
    transition: border-color 0.3s ease;
  }

  &:hover {
    &::before {
      border-color: $colorElmentUIBlue900;
    }
  }
}

.datePickerPopupContainer__previousMonthButton {
  &::before {
    left: 10px;
    border-left: 1px solid $colorElmentUIGray600;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.datePickerPopupContainer__nextMonthButton {
  &::before {
    right: 10px;
    border-right: 1px solid $colorElmentUIGray600;
    transform: translate(50%, -50%) rotate(45deg);
  }
}

.datePickerPopupContainer__yearButton {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: $colorWhite;
  line-height: 1;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &::before,
  &::after {
    position: absolute;
    top: 9px;
    width: 6px;
    height: 6px;
    border-top: 1px solid $colorElmentUIGray600;
    content: "";
    transition: border-color 0.3s ease;
  }

  &:hover {
    &::before,
    &::after {
      border-color: $colorElmentUIBlue900;
    }
  }
}

.datePickerPopupContainer__previousYearButton {
  margin-right: 12px;

  &::before,
  &::after {
    border-left: 1px solid $colorElmentUIGray600;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::before {
    left: 9px;
  }
  &::after {
    left: 12px;
  }
}

.datePickerPopupContainer__nextYearButton {
  margin-left: 12px;

  &::before,
  &::after {
    border-right: 1px solid $colorElmentUIGray600;
    transform: translate(50%, -50%) rotate(45deg);
  }

  &::before {
    right: 9px;
  }
  &::after {
    right: 12px;
  }
}

.datePickerPopupContainer__body {
  display: flex;
  align-items: center;
}
</style>
