<template>
  <div
    :id="gramId"
    class="ud-Visit_Gram ud-Visit_Gram_CompanyActivity"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_CompanyActivity">
        <Icon :icon="iconApp" :size="18" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_GramTime">{{ time }}</div>
      <div class="ud-Visit_GramCompanyActivity">{{ title }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import { Gram } from "@/models/Gram";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";

@Component({
  components: { Icon }
})
export default class GramContactApp extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  iconApp = Icons.App;

  get gramId(): string {
    return this.gram.id;
  }

  get time(): string {
    return this.gram.hasTimeOfDay ? this.gram.timeTx : "";
  }

  get title(): string {
    return this.gram.contactAppTitle;
  }
}
</script>
