import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import { FilterNodeParamForBrowseApp } from "@/api/apis/ApiSearch";
import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { getHourlyInterval } from "@/util/date-util";
import {
  FilterNodeType,
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import {
  convertJsonValueToStayTime,
  FilterStayTimeCondition
} from "@/models/search/filter-node-condition/FilterStayTimeCondition";
/**
 * アプリ閲覧による絞り込み条件
 *
 * 入力したスクリーン名の画面をアプリで開いたユーザを絞り込む
 * スクリーン名は完全一致or部分一致を指定できる
 *
 * 初回、期間、日付と時間帯 を条件として追加できる
 */
export class FilterNodeForBrowseApp {
  public readonly nodeType = FilterNodeType.BrowseApp;

  constructor(
    // スクリーン名
    public readonly screenName: string,
    // 文字列のマッチ条件（部分一致 or 完全一致）
    public readonly matchMethod: MatchMethod,
    /**
     * 追加の検索条件
     *
     * 初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: FilterAdditionalTimingCondition[],
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion(),
    public readonly filterStayTime: FilterStayTimeCondition = new FilterStayTimeCondition()
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate(): ValidationResult {
    const validationResult = filterInputCheck(
      this.screenName,
      i18n.t("models.search.screenName") as string
    );

    return validateConditions(this.additionalConditions, validationResult);
  }

  isConditionAddable(isFirstNode: boolean): boolean {
    if (this.depth === 0 && isFirstNode) {
      return this.additionalConditions.length < 2;
    } else if (this.depth === 0) {
      return this.additionalConditions.length < 1;
    }
    return false;
  }

  insertEdge(): FilterNodeForBrowseApp {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForBrowseApp(
      this.screenName,
      this.matchMethod,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion,
      this.filterStayTime
    );
  }

  removeEdge(): FilterNodeForBrowseApp {
    return new FilterNodeForBrowseApp(
      this.screenName,
      this.matchMethod,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion,
      this.filterStayTime
    );
  }

  removeFirstTimeCondition(): FilterNodeForBrowseApp {
    return new FilterNodeForBrowseApp(
      this.screenName,
      this.matchMethod,
      this.additionalConditions.filter(
        cnd => !(cnd instanceof FilterFirstTimeCondition)
      ),
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion,
      this.filterStayTime
    );
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.None,
            label: i18n.t("models.search.screenName") as string,
            disabled: true
          }
        ]
      }
    ];

    if (this.depth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }
}

export function convertFilterNodeForBrowseAppToJson(
  node: FilterNodeForBrowseApp
): FilterNodeParamForBrowseApp {
  const result: FilterNodeParamForBrowseApp = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.BrowseApp,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    app_cruise_title: {
      value: node.screenName,
      word_match_method: node.matchMethod
    }
  };
  if (
    node.filterStayTime.isStayTimeChecked &&
    node.filterExclusion.exclusionType === ExclusionType.Include &&
    node.depth === 0
  ) {
    result.stay_time_in_sec_gte =
      node.filterStayTime.stayTimeValueGreaterThanEqual;

    result.stay_time_in_sec_lt = node.filterStayTime.stayTimeValueLessThan;
  }
  const conditions: FilterAdditionalTimingCondition[] =
    node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForBrowseApp(
  json: FilterNodeParamForBrowseApp
): FilterNodeForBrowseApp {
  return new FilterNodeForBrowseApp(
    json.app_cruise_title.value,
    json.app_cruise_title.word_match_method,
    getAdditionalTimingConditions(json),
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!),
    convertJsonValueToStayTime(json)
  );
}
