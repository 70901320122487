<i18n src="@/i18n/views/user-trend.json"></i18n>
<template>
  <div class="user-trend-tabs" data-cy="user-trend-tabs">
    <UgTab>
      <UgTabItem
        v-if="canUseWebdataFeatures"
        :class="{ 'user-trend-tabs__wrapper': hasWebAndAppContract }"
        :active="isWebView"
        data-cy="user-trend-web-view-tab"
        @click="onClickTab(analysisType.WebView)"
      >
        {{ $t("webView") }}
        <div v-if="hasWebAndAppContract" class="user-trend-tabs__label">
          (Web)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="canUseWebdataFeatures"
        :class="{ 'user-trend-tabs__wrapper': hasWebAndAppContract }"
        :active="isLanding"
        data-cy="user-trend-landing-tab"
        @click="onClickTab(analysisType.Landing)"
      >
        {{ $t("landing") }}
        <div v-if="hasWebAndAppContract" class="user-trend-tabs__label">
          (Web)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="canUseWebdataFeatures"
        :class="{ 'user-trend-tabs__wrapper': hasWebAndAppContract }"
        :active="isInflow"
        data-cy="user-trend-inflow-tab"
        @click="onClickTab(analysisType.Inflow)"
      >
        {{ $t("inflow") }}
        <div v-if="hasWebAndAppContract" class="user-trend-tabs__label">
          (Web)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="isContractApp"
        :class="{ 'user-trend-tabs__wrapper': hasWebAndAppContract }"
        :active="isAppView"
        data-cy="user-trend-app-view-tab"
        @click="onClickTab(analysisType.AppView)"
      >
        {{ $t("appView") }}
        <div v-if="hasWebAndAppContract" class="user-trend-tabs__label">
          (App)
        </div>
      </UgTabItem>
    </UgTab>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import UgTab from "@/components/tab/UgTab.vue";
import UgTabItem from "@/components/tab/UgTabItem.vue";
import { AnalysisType } from "@/const/user-trend";

@Component({
  components: {
    UgTab,
    UgTabItem
  }
})
export default class UserTrendTabs extends Vue {
  @Prop({ type: Boolean, required: true })
  canUseWebdataFeatures!: boolean;
  @Prop({ type: Boolean, required: true })
  isContractApp!: boolean;
  @Prop({ type: String, required: true })
  selectedTab!: AnalysisType;

  onClickTab(selectedTab: AnalysisType) {
    this.$emit("changeTab", selectedTab);
  }

  analysisType: typeof AnalysisType = AnalysisType;

  get hasWebAndAppContract(): boolean {
    return this.canUseWebdataFeatures && this.isContractApp;
  }

  get isWebView(): boolean {
    return this.selectedTab === AnalysisType.WebView;
  }

  get isLanding(): boolean {
    return this.selectedTab === AnalysisType.Landing;
  }

  get isInflow(): boolean {
    return (
      this.selectedTab === AnalysisType.Inflow ||
      this.selectedTab === AnalysisType.InflowDetail
    );
  }

  get isAppView(): boolean {
    return this.selectedTab === AnalysisType.AppView;
  }
}
</script>

<style scoped lang="scss">
.user-trend-tabs__wrapper {
  display: block;
}
.user-trend-tabs__label {
  display: block;
  padding-top: 2px;
  font-size: 12px;
}
</style>
