import { ApiRes } from "@/api/api-res";
import { ConversionSummary } from "@/models/user-summary/ConversionSummary";
import { EventSummary } from "@/models/user-summary/EventSummary";
import { UserSummaryItem } from "@/models/user-summary/UserSummaryItem";

export class UserSummary {
  constructor(
    public readonly conversions: UserSummaryItem[],
    public readonly events: UserSummaryItem[]
  ) {}

  public static build(
    json: ApiRes.UserSummary | undefined
  ): UserSummary | undefined {
    if (!json) return undefined;
    return new UserSummary(
      buildConversions(json.conversions),
      buildEvents(json.events)
    );
  }
}

function buildConversions(
  json: ApiRes.UserSummaryConversion[]
): ConversionSummary[] {
  if (!json) return [];
  return json.map(conversionJson => {
    return ConversionSummary.build(conversionJson);
  });
}

function buildEvents(json: ApiRes.UserSummaryEvent[]): EventSummary[] {
  if (!json) return [];
  return json.map(eventJson => {
    return EventSummary.build(eventJson);
  });
}
