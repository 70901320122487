<template>
  <div class="analysisTable">
    <div
      ref="analysisTableHead"
      :style="{ top: stickyTop }"
      class="analysisTable__head"
    >
      <table class="analysisTable__headInner">
        <slot name="header" />
      </table>
    </div>
    <div class="analysisTable__body">
      <table class="analysisTable__bodyInner">
        <slot />
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class AnalysisTable extends Vue {
  @Prop({ type: String, default: "0" })
  stickyTop!: string;
}
</script>

<style lang="scss" scoped>
.analysisTable {
  font-size: 12px;
}

.analysisTable__head {
  position: sticky;
  width: 100%;
  border-radius: $sizeRadius $sizeRadius 0 0;
  background-color: $colorWhite;
}

.analysisTable__headInner,
.analysisTable__bodyInner {
  width: 100%;
  table-layout: fixed;
}

.analysisTable__headInner {
  border-collapse: separate;
}
</style>
