<i18n src="@/i18n/components/observation/visit/gram-inflow.json"></i18n>

<template>
  <div
    class="ud-Visit_Gram ud-Visit_Gram_Inflow"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_Circle">
        <Icon :icon="inflowRight" :size="17" :color="iconColor" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_InflowTitle">{{ title }}</div>
      <div class="ud-Visit_InflowAttrs">
        <div v-if="locationReferrer" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("referrer") }}</div>
          <div class="ud-Visit_InflowAttrValue">
            <Tooltip
              v-if="isShowDetail"
              :placement="tooltipPlacement.Bottom"
              :text="locationReferrer"
              :wrap="true"
              class="ud-Visit_InflowReferrerDetailTooltip"
            >
              <a
                class="ud-Visit_InflowAttrValueLink"
                target="_blank"
                :href="locationReferrer"
                rel="noopener"
                >{{ showReferrer }}</a
              >
            </Tooltip>
            <a
              v-else
              class="ud-Visit_InflowAttrValueLink"
              target="_blank"
              rel="noopener"
              :href="locationReferrer"
              >{{ showReferrer }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import { Gram } from "@/models/Gram";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement } from "@/const/tooltip";
import { Colors } from "@/const/Colors";

const MAX_DISPLAY_REFERRER_LEN = 200;

@Component({
  components: { Tooltip, Icon }
})
export default class GramInflowOther extends GramBase {
  tooltipPlacement = TooltipPlacement;
  inflowRight = Icons.InflowRight;
  iconColor = Colors.Blue900;

  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  get title(): string {
    const domain = this.locationReferrer.split("/")[2];
    return this.$i18n.t("inflowFrom_DOMAIN", { domain }) as string;
  }

  get showReferrer(): string {
    if (MAX_DISPLAY_REFERRER_LEN < this.locationReferrer.length) {
      return this.locationReferrer.substr(0, MAX_DISPLAY_REFERRER_LEN) + "...";
    }
    return this.locationReferrer;
  }

  get isShowDetail(): boolean {
    return MAX_DISPLAY_REFERRER_LEN < this.locationReferrer.length;
  }

  get locationReferrer(): string {
    return this.gram.pvLocationReferrer;
  }
}
</script>
