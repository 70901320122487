import "./class-component-hooks";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import { i18n } from "./i18n";
import "elementui-lts/lib/theme-chalk/index.css";
import "vue-slider-component/theme/default.css";
/**
 * we need import reset.scss and index.scss after elementui-lts/lib/theme-chalk/index.css
 * because some elementui-lts css classes had to be overwritten.
 */
import "./assets/scss/reset.scss";
import "./assets/scss/index.scss";
import VueScrollTo from "vue-scrollto";
import VueLazyload from "vue-lazyload";
import vuescroll from "vuescroll";
import VueObserveVisibility from "vue-observe-visibility";
import "intersection-observer";

Vue.config.productionTip = false;

Vue.use(VueScrollTo);

Vue.use(VueObserveVisibility);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: "@/assets/img/loading_circle.gif",
  lazyComponent: true,
  observer: true
});

Vue.use(vuescroll, {
  name: "VueScroll"
});

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
