import { render, staticRenderFns } from "./Help.vue?vue&type=template&id=264051a9&scoped=true"
import script from "./Help.vue?vue&type=script&lang=ts"
export * from "./Help.vue?vue&type=script&lang=ts"
import style0 from "./Help.vue?vue&type=style&index=0&id=264051a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "264051a9",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/help.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2FHelp.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports