<i18n src="@/i18n/components/link-copy-button.json"></i18n>
<template>
  <div class="linkCopyButton">
    <Tooltip
      :text="displayText"
      :placement="tooltipPlacement"
      :active="!disabled"
    >
      <div class="linkCopyButton__container">
        <IconButton
          class="linkCopyButton__icon"
          :icon="icons.Link"
          :size="iconSize"
          :button-type="IconButtonType.Weak"
          :disabled="disabled"
          @click="onClick"
          @mouseleave="onMouseLeave"
        />
      </div>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { TooltipPlacement } from "@/const/tooltip";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import { copyToClipboard } from "@/util/string-util";

@Component({
  components: {
    Tooltip,
    Icon,
    IconButton
  }
})
export default class LinkCopyButton extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: String, default: TooltipPlacement.TopEnd })
  tooltipPlacement!: TooltipPlacement;

  @Prop({ type: String, default: "" })
  tooltipText!: string;

  @Prop({ type: String, required: true })
  linkText!: string;

  @Prop({ type: Number, default: 20 })
  iconSize!: number;

  @Emit("click")
  onClick() {
    if (copyToClipboard(this.linkText)) {
      this.isCopiedShareLink = true;
    }
  }

  icons = Icons;
  IconButtonType = IconButtonType;
  isCopiedShareLink: boolean = false;

  get displayText(): string {
    if (this.isCopiedShareLink) {
      return this.$i18n.t("copied") as string;
    }
    return this.tooltipText !== ""
      ? this.tooltipText
      : (this.$i18n.t("linkCopy") as string);
  }

  onMouseLeave() {
    this.isCopiedShareLink = false;
  }
}
</script>

<style scoped lang="scss">
.linkCopyButton {
  display: inline-block;
}

.linkCopyButton__container {
  position: relative;
  width: 20px;
  height: 20px;
}

.linkCopyButton__icon {
  position: absolute;
}
</style>
