import { UserTrendPostParam } from "@/api/apis/ApiUserTrend";
import { postDate } from "@/util/date-util";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { getCurrentDate } from "@/util/date-util";

/**
 * UserTrend検索条件を格納するクラス
 */

export class UserTrendSearchCondition {
  constructor(
    public readonly conversionIds: number[],
    public readonly cvAttr: {
      id: number;
      values: string[];
      wordMatchMethod: number;
    }[],
    public readonly hasComparison: boolean,
    public readonly base: {
      startDate: Date;
      endDate: Date;
    },
    public readonly comparison: {
      startDate: Date;
      endDate: Date;
    }
  ) {}

  static defaultCondition(activeConversions: ConversionDefinition[] = []) {
    const baseEndDate = getCurrentDate();
    baseEndDate.setHours(23, 59, 59, 999);
    baseEndDate.setDate(baseEndDate.getDate() - 2);

    const baseStartDate = new Date(baseEndDate);
    baseStartDate.setHours(0, 0, 0, 0);
    baseStartDate.setDate(baseStartDate.getDate() - 6);

    const compStartDate = new Date(baseStartDate);
    compStartDate.setDate(compStartDate.getDate() - 7);

    const compEndDate = new Date(baseEndDate);
    compEndDate.setDate(compEndDate.getDate() - 7);

    return new UserTrendSearchCondition(
      activeConversions.map(cv => cv.id),
      [],
      false,
      { startDate: baseStartDate, endDate: baseEndDate },
      { startDate: compStartDate, endDate: compEndDate }
    );
  }

  toJson(): UserTrendPostParam {
    const params: UserTrendPostParam = {
      conversion_ids: this.conversionIds,
      start_date: postDate(this.base.startDate),
      end_date: postDate(this.base.endDate),
      is_compared: this.hasComparison,
      comparison_start_date: postDate(this.comparison.startDate),
      comparison_end_date: postDate(this.comparison.endDate),
      app_user_timezone: getCurrentDate().getTimezoneOffset()
    };

    if (this.cvAttr.length > 0) {
      params["cv_attr"] = this.cvAttr.map(attr => {
        return {
          id: attr.id,
          values: attr.values,
          word_match_method: attr.wordMatchMethod
        };
      });
    }
    return params;
  }

  static fromJson(
    json: UserTrendPostParam,
    activeConversions: ConversionDefinition[]
  ): UserTrendSearchCondition {
    // 利用可能なコンバージョンのみ抽出
    const ids: number[] = json.conversion_ids.filter(id =>
      activeConversions.some(def => def.id === id)
    );

    // 利用可能なコンバージョンがなければdefaultの条件を返す
    if (ids.length === 0) {
      return UserTrendSearchCondition.defaultCondition(activeConversions);
    }

    const baseStartDate = new Date(json.start_date);
    const baseEndDate = new Date(json.end_date);

    const compStartDate = new Date(json.comparison_start_date);
    const compEndDate = new Date(json.comparison_end_date);

    const cvAttributes =
      json.cv_attr === undefined
        ? []
        : json.cv_attr.map(attr => {
            return {
              id: attr.id,
              values: attr.values,
              wordMatchMethod: attr.word_match_method
            };
          });

    return new UserTrendSearchCondition(
      ids,
      cvAttributes,
      json.is_compared,
      {
        startDate: baseStartDate,
        endDate: baseEndDate
      },
      {
        startDate: compStartDate,
        endDate: compEndDate
      }
    );
  }

  get validate(): ValidationResult {
    const day92Msec = 92 * 24 * 60 * 60 * 1000;

    const errorMessages: string[] = [];

    if (this.conversionIds.length === 0) {
      errorMessages.push(localI18n("errorNoConversion"));
    }

    // Error when period range exceeds 3 months(92 days)
    // 対象期間の日付の範囲が3ヶ月(92日)を超えた場合にエラー
    if (
      this.base.endDate.getTime() - this.base.startDate.getTime() >=
      day92Msec
    ) {
      errorMessages.push(localI18n("errorBaseOver92Days"));
    }

    if (this.hasComparison) {
      // Error when period range exceeds 3 months(92 days)
      // 比較期間の日付の範囲が3ヶ月(92日)を超えた場合にエラー
      if (
        this.comparison.endDate.getTime() -
          this.comparison.startDate.getTime() >=
        day92Msec
      ) {
        errorMessages.push(localI18n("errorComparisonOver92Days"));
      }

      // Error if date condition is same
      // 対象期間と比較期間が同じ
      if (
        this.base.startDate.getTime() === this.comparison.startDate.getTime() &&
        this.base.endDate.getTime() === this.comparison.endDate.getTime()
      ) {
        errorMessages.push(localI18n("errorSamePeriod"));
      }
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

function localI18n(key: string): string {
  return i18n.t(`models.userTrend.searchCondition.${key}`) as string;
}
