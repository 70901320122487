<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-contact-employee-field">
    <FilterBaseInputText
      v-model="value"
      :prefix="$t('containsPrefix')"
      :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
    />
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterContactEmployeeCondition } from "@/models/search/filter-node-condition/FilterContactEmployeeCondition";

import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";

@Component({
  components: {
    FilterBaseInputText
  }
})
export default class FilterContactEmployeeField extends Vue {
  @Model("input", {
    type: FilterContactEmployeeCondition,
    required: true
  })
  condition!: FilterContactEmployeeCondition;

  onInput(condition: FilterContactEmployeeCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.employee;
  }
  set value(value: string) {
    this.onInput(new FilterContactEmployeeCondition(value));
  }
}
</script>
<style lang="scss" scoped>
.filter-contact-employee-field {
  display: flex;
  align-items: center;
}

.select-box {
  margin-right: 7px;
}
</style>
