<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <LightUgTableHeader class="featuredUsersAlmostCvTableHeader">
    <LightUgTableHeaderRow>
      <LightUgTableHeaderCell :width="COLUMN_WIDTH">
        {{ $t("cv") }}
      </LightUgTableHeaderCell>
      <LightUgTableHeaderCell no-padding>
        <LightUgTableHeaderRow>
          <LightUgTableHeaderCell text-align="center">
            {{ $t("almostCvTableHeader") }}
          </LightUgTableHeaderCell>
        </LightUgTableHeaderRow>
        <LightUgTableHeaderRow>
          <LightUgTableHeaderCell
            class="featuredUsersAlmostCvTableHeader__periodCell"
            is-thin
          >
            <Tooltip
              class="featuredUsersAlmostCvTableHeader__tooltip"
              :text="periodRange"
            >
              <Icon :icon="Icons.History" />
            </Tooltip>
            <span class="featuredUsersAlmostCvTableHeader__period">{{
              $t("four-weeks")
            }}</span>
          </LightUgTableHeaderCell>
        </LightUgTableHeaderRow>
      </LightUgTableHeaderCell>
    </LightUgTableHeaderRow>
  </LightUgTableHeader>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LightUgTableHeaderRow from "@/components/table/LightUgTableHeaderRow.vue";
import LightUgTableHeaderCell from "@/components/table/LightUgTableHeaderCell.vue";
import LightUgTableHeader from "@/components/table/LightUgTableHeader.vue";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Icons } from "@/const/Icons";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";

@Component({
  components: {
    LightUgTableHeaderRow,
    LightUgTableHeaderCell,
    LightUgTableHeader,
    Icon,
    Tooltip
  }
})
export default class FeaturedUsersAlmostCvTableHeader extends Vue {
  COLUMN_WIDTH = "457px";
  Icons = Icons;

  @Prop({ type: Object, required: true })
  almostCvInfo!: AlmostCvInfo;

  get periodRange(): string {
    return this.almostCvInfo.period.periodRange;
  }
}
</script>
<style lang="scss" scoped>
.featuredUsersAlmostCvTableHeader__periodCell {
  display: inline-flex;
  align-items: center;
}
.featuredUsersAlmostCvTableHeader__tooltip {
  margin-right: 5px;
}
.featuredUsersAlmostCvTableHeader__period {
  padding-bottom: 1px;
}
</style>
