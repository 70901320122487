import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store";
import { SelectByEngagementCondition } from "@/models/search/select-condition/SelectByEngagementCondition";
import { EngagementStatus } from "@/api/apis/ApiEngagementSearch";
import { sleep } from "@/util/common-util";

// 再度APIを叩くまでの待機時間を2秒とする
const FETCH_INTERVAL_MSEC = 2000;

// 120秒(2秒 x 60回)で結果が返らない場合はタイムアウトとする
const TIMEOUT_COUNT = 60;

// 検索開始前のデフォルトのidの値
const ID_VALUE_BEFORE_SEARCH = -1;

export class EngagementSearchState {
  currentId: number = ID_VALUE_BEFORE_SEARCH;
}

const mutations = <MutationTree<EngagementSearchState>>{
  setCurrentId(state: EngagementSearchState, currentId: number) {
    state.currentId = currentId;
  }
};

const actions = <ActionTree<EngagementSearchState, RootState>>{
  /**
   * call API for engagement search with condition to get history id
   * @param condition
   * @returns promise return number of historyId or null
   */
  async fetchHistoryId(
    { commit, dispatch, rootState },
    condition: SelectByEngagementCondition
  ): Promise<number | null> {
    const api = rootState.api.engagementSearch;

    // 検索中のidを初期化 ステータス取得のループを止めるためcurrentIdを更新する
    commit("setCurrentId", ID_VALUE_BEFORE_SEARCH);
    const searchId: number = (await api.createSearch(condition)).id;
    commit("setCurrentId", searchId);

    // resultが取得可能ならAPIからhistoryIdを取得して返す
    if (await dispatch("isPossibleToGetResult", searchId)) {
      return (await api.getResult(searchId)).history_id;
    }
    return null;
  },
  /**
   * ask API until ready to get the result
   * @returns promise return boolean for ready to get result
   */
  async isPossibleToGetResult(
    { state, rootState },
    searchId: number
  ): Promise<boolean> {
    const api = rootState.api.engagementSearch;

    let callCount = 0;
    let status: EngagementStatus = EngagementStatus.Running;
    do {
      callCount++;

      // キャンセル処理 実行できる検索は1つで、searchIdが異なる = 別の検索が実行された時
      if (state.currentId !== searchId) {
        return false;
      }

      // タイムアウト処理
      if (callCount > TIMEOUT_COUNT) {
        throw new Error();
      }

      // ステータス取得
      status = (await api.getStatus(searchId)).status;

      // OKならresultを取れるので true を返す
      if (status == EngagementStatus.Ok) {
        return true;
      }

      // APIからエラーが返ってきた場合
      if (status === EngagementStatus.Failed) {
        throw new Error();
      }

      await sleep(FETCH_INTERVAL_MSEC);
    } while (status === EngagementStatus.Running);

    return false;
  }
};

export const engagementSearch = {
  namespaced: true,
  state: new EngagementSearchState(),
  mutations,
  actions
};
