<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="service-id-form">
    <div class="service-id-title" data-cy="service-id-title">
      {{ $t("EnterTheServiceId", { num: maxServiceIdNumber }) }}
    </div>
    <div class="service-id-head">
      <div
        v-t="'clearInput'"
        class="clear-service-id-button"
        @click="clearServiceIds"
      />

      <div class="serviceIdForm__text">
        <div v-if="isMaxCharactersServiceId" class="serviceIdForm__errorText">
          {{ enterTheServiceIdCharacters }}
        </div>
        <div
          class="service-id-count"
          :class="{
            'service-id-count--error': isServiceIdCountExceeded
          }"
          >{{ serviceIdCount }}</div
        >
      </div>
    </div>
    <TextArea
      ref="serviceIdTextarea"
      data-cy="service-id-textarea"
      height="300px"
      :value="value.serviceIds.join('\n')"
      :disabled="disabled"
      :has-error="isServiceIdCountExceeded || isMaxCharactersServiceId"
      @input="setServiceIds"
    />
    <CheckBox
      class="service-id-checkbox"
      data-cy="service-id-checkbox"
      :disabled="disabled"
      :checked="value.includesNoConversionUsers"
      @input="setIncludesNoConversionUsers"
      >{{ $t("ShowNoConversionUser") }}</CheckBox
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  SelectByServiceIdCondition,
  maxServiceIdNumber,
  MAX_CHARACTERS_SERVICEID
} from "@/models/search/select-condition/SelectByServiceIdCondition";
import TextArea from "@/components/form/TextArea.vue";
import CheckBox from "@/components/form/CheckBox.vue";
import { splitByNewLine } from "@/util/string-util";

@Component({
  components: {
    TextArea,
    CheckBox
  }
})
export default class ServiceIdForm extends Vue {
  maxServiceIdNumber: number = maxServiceIdNumber;
  @Prop({ type: SelectByServiceIdCondition, required: true })
  value!: SelectByServiceIdCondition;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  /**
   * Set service Ids
   */
  setServiceIds(serviceIds: string) {
    const payload: SelectByServiceIdCondition = new SelectByServiceIdCondition(
      splitByNewLine(serviceIds),
      this.value.includesNoConversionUsers
    );
    this.$emit("input", payload);
  }

  /**
   * Opt out of including conversion users into search result
   */
  setIncludesNoConversionUsers(includesNoConversionUsers: boolean) {
    const payload: SelectByServiceIdCondition = new SelectByServiceIdCondition(
      this.value.serviceIds,
      includesNoConversionUsers
    );
    this.$emit("input", payload);
  }

  // Clears Service ID textarea field
  clearServiceIds() {
    const payload: SelectByServiceIdCondition = new SelectByServiceIdCondition(
      [],
      this.value.includesNoConversionUsers
    );
    this.$emit("input", payload);

    (this.$refs.serviceIdTextarea as TextArea).focus();
  }

  get serviceIdCount(): string {
    const count: number = this.value.serviceIds.length;

    if (count > this.maxServiceIdNumber) {
      return this.$i18n.t("overPeople", {
        num: count - this.maxServiceIdNumber
      }) as string;
    }

    return this.$i18n.t("numberPeopleAlreadyEntered", {
      num: count
    }) as string;
  }

  get isServiceIdCountExceeded(): boolean {
    return this.value.serviceIds.length > this.maxServiceIdNumber;
  }

  get isMaxCharactersServiceId(): boolean {
    return this.value.serviceIds.some(
      serviceId => serviceId.length > MAX_CHARACTERS_SERVICEID
    );
  }

  get enterTheServiceIdCharacters(): string {
    return this.$i18n.t("EnterTheServiceIdCharacters", {
      num: MAX_CHARACTERS_SERVICEID
    }) as string;
  }
}
</script>
<style lang="scss" scoped>
.clear-service-id-button {
  display: inline-block;
  margin-bottom: 15px;
  color: $colorBase700;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.service-id-form {
  position: relative;
  display: flex;
  flex-direction: column;
}

.service-id-title {
  margin-bottom: 15px;
  font-size: 14px;
}

.service-id-head {
  display: flex;
  justify-content: space-between;
}

.serviceIdForm__text {
  display: flex;
}

.serviceIdForm__errorText {
  margin-right: 10px;
  color: $colorError;
}

.service-id-count {
  font-size: 13px;
}

.service-id-count--error {
  color: $colorError;
}

.service-id-checkbox {
  margin-top: 20px;
}
</style>
