import { ApiParam } from "@/api/api-param";
import { ApiRes } from "@/api/api-res";
import { MAX_DISPLAY_SPAN_DAYS } from "@/const/app";
import { SUBJECT_TYPE } from "@/const/gram";
import { CompanyActivity } from "@/models/CompanyActivity";
import { Gram } from "@/models/Gram";
import { User } from "@/models/User";
import { Visit } from "@/models/Visit";
import { RootState } from "@/store/";
import { sleep } from "@/util/common-util";
import { getElapsedTimeTx, msecToUsec, usecToMsec } from "@/util/date-util";
import { now } from "@/util/date-util";
import {
  handleNoQueryCacheError,
  isNoQueryCacheError
} from "@/util/error-util";
import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex";

export enum VISIT_DIRECTION {
  PAST = 1,
  FUTURE = 2
}

export interface FirstVisitsParams {
  user: User;
  baseTimeUsec: number;
}

export interface FetchVisitsParams {
  key: string;
  user: User;
  isFirst: boolean;
  gramCount: number;
}

const FETCH_LIMIT = 5; // 一回当たりの取得訪問数
const MAX_FETCH_NUM_GRAMS = 50; // 最大グラム数
const FETCH_INTERVAL_MSEC = 1000; // 再度APIを叩くまでの待機時間
const MAX_FEATURE_USEC = 32503647600000000; // 3000/01/01
interface LoadCondition {
  base_time_usec: number;
  search_direction: number;
  num_visits: number;
  min_searched_time_usec: number;
  max_searched_time_usec: number;
  user_id: string;
}

export class VisitState {
  // 異なるユーザや、離れた日付の訪問を取得する際にリセットされるキー
  // このキーとfetch時に保持しているキーがマッチしてなければ、
  // そのfetch結果は古い条件のfetchなので、fetch結果を捨てるようにする
  currentKey: string = "";

  // 取得したすべての訪問
  visits: Visit[] = [];

  hasNextPast = false;
  isLoadingPast = false;
  baseTimeUsecPast = 0;

  hasNextFuture = false;
  isLoadingFuture = false;
  baseTimeUsecFuture = 0;
}

const mutations: MutationTree<VisitState> = {
  setCurrentKey(state: VisitState, key: string) {
    state.currentKey = key;
  },
  setVisits(state: VisitState, visits: Visit[]) {
    state.visits = visits;
  },
  setIsLoadingPast(state: VisitState, isLoading: boolean) {
    state.isLoadingPast = isLoading;
  },
  setIsLoadingFuture(state: VisitState, isLoading: boolean) {
    state.isLoadingFuture = isLoading;
  },
  setHasNextPast(state: VisitState, hasNext: boolean) {
    state.hasNextPast = hasNext;
  },
  setHasNextFuture(state: VisitState, hasNext: boolean) {
    state.hasNextFuture = hasNext;
  },
  setBaseTimeUsecPast(state: VisitState, timeUsec: number) {
    state.baseTimeUsecPast = timeUsec;
  },
  setBaseTimeUsecFuture(state: VisitState, timeUsec: number) {
    state.baseTimeUsecFuture = timeUsec;
  },
  initialize(state: VisitState) {
    state.currentKey = "";
    state.visits = [];
    state.hasNextPast = false;
    state.isLoadingPast = false;
    state.baseTimeUsecPast = 0;
    state.hasNextFuture = false;
    state.isLoadingFuture = false;
    state.baseTimeUsecFuture = 0;
  }
};

const getters: GetterTree<VisitState, RootState> = {
  firstGram(state: VisitState): Gram | null {
    const visits = state.visits;
    if (visits.length <= 0) {
      return null;
    }
    const grams = visits[0].grams;
    if (grams.length <= 0) {
      return null;
    }
    return grams[0];
  },
  lastGram(state: VisitState): Gram | null {
    const visits = state.visits;
    if (visits.length <= 0) {
      return null;
    }
    const grams = visits[visits.length - 1].grams;
    if (grams.length <= 0) {
      return null;
    }
    return grams[grams.length - 1];
  },
  baseTimeUsec(state: VisitState, getters) {
    return (direction: VISIT_DIRECTION): number => {
      if (direction === VISIT_DIRECTION.PAST) {
        const gram = getters.firstGram;
        if (gram !== null) {
          return Math.floor((gram.timeSec - 1) * 1000 * 1000);
        }
      } else {
        const gram = getters.lastGram;
        if (gram !== null) {
          return Math.floor((gram.timeSec + 1) * 1000 * 1000);
        }
      }
      return -1;
    };
  },
  displayableVisits(state: VisitState, getters, rootState: RootState): Visit[] {
    return filterCompanyActivityDisplay(
      state.visits,
      rootState.companyActivityDisplaySetting.displaySettings
    );
  }
};

const actions: ActionTree<VisitState, RootState> = {
  /**
   * 訪問情報をクリアする
   */
  clearVisit({ commit }) {
    // APIのfetchが終わってデータが更新されないようにキーをクリア
    commit("setCurrentKey", "");
    // ローディングを止める
    commit("setIsLoadingPast", false);
    commit("setIsLoadingFuture", false);
    // 次にfetchまで、過去も未来もデータはなしとしておく（fetch後にチェックされるので）
    commit("setHasNextPast", false);
    commit("setHasNextFuture", false);
    // データクリアする
    commit("setVisits", []);
  },
  /**
   * 最初の訪問を取得する
   */
  async fetchFirst({ commit, dispatch }, params: FirstVisitsParams) {
    const { user, baseTimeUsec } = params;

    // クリア
    dispatch("clearVisit");

    // ユーザやロードする範囲が変わった場合にAPIのリターン値を捨てる判断に使うキーの作成
    const key = user.id + baseTimeUsec + now();
    commit("setCurrentKey", key);

    // 過去向き、未来向きのbaseTimeをセット
    const intBaseTimeUsec = Math.floor(baseTimeUsec);
    commit("setBaseTimeUsecPast", intBaseTimeUsec - 1);
    commit("setBaseTimeUsecFuture", intBaseTimeUsec);

    /**
     * 未来方向をロード
     * ここのawaitを取ると過去方向のロード開始が早くなりますが、一覧の概要のバーをクリックした際に
     * 未来方向より先に過去方向のロードが終わった場合に初期表示日付がずれるのでawaitを付けたままにする
     **/
    await dispatch("fetchFuture", { key, user, isFirst: true, gramCount: 0 });

    // 過去方向をロード
    await dispatch("fetchPast", { key, user, isFirst: false, gramCount: 0 });
  },
  /**
   * 未来方向に次の訪問を取得する
   */
  async fetchNextFuture({ state, dispatch }, user: User) {
    await dispatch("fetchFuture", {
      key: state.currentKey,
      user,
      isFirst: false,
      gramCount: 0
    });
  },
  /**
   * 過去方向に次の訪問を取得する
   */
  async fetchNextPast({ state, dispatch }, user: User) {
    await dispatch("fetchPast", {
      key: state.currentKey,
      user,
      isFirst: false,
      gramCount: 0
    });
  },
  /**
   * 未来方向の訪問を取得する
   */
  async fetchFuture(context, params: FetchVisitsParams) {
    const { key, user, isFirst, gramCount } = params;
    await fetchVisit(
      context,
      VISIT_DIRECTION.FUTURE,
      key,
      user,
      isFirst,
      gramCount
    );
  },
  /**
   * 過去方向の訪問を取得する
   */
  async fetchPast(context, params: FetchVisitsParams) {
    const { key, user, isFirst, gramCount } = params;
    await fetchVisit(
      context,
      VISIT_DIRECTION.PAST,
      key,
      user,
      isFirst,
      gramCount
    );
  }
};

/**
 * 訪問のfetchを行う、過去方向と未来方向で呼ぶ物等が変わる
 *
 * @param ActionContext
 * @param direction
 * @param key
 * @param user
 * @param isFirst
 * @param gramCount
 */
async function fetchVisit(
  {
    commit,
    state,
    dispatch,
    rootState,
    getters,
    rootGetters
  }: ActionContext<VisitState, RootState>,
  direction: VISIT_DIRECTION,
  key: string,
  user: User,
  isFirst: boolean,
  gramCount: number
) {
  const isPast = direction === VISIT_DIRECTION.PAST;

  // ローディング中にする
  commit(isPast ? "setIsLoadingPast" : "setIsLoadingFuture", true);

  // visitを叩くためのペイロード作成
  const payload = getRequestPayload(
    user,
    direction,
    isPast ? state.baseTimeUsecPast : state.baseTimeUsecFuture,
    isFirst,
    rootState.search.historyId
  );

  if (payload === null) {
    // TODO エラー
    return;
  }

  // ロード時キーと現在のキーが違ったら処理を抜ける
  if (key !== state.currentKey) {
    return;
  }

  // APIを呼ぶ、非同期でよばれで、呼び出し側でエラーをキャッチできないのてここて処理をする
  const res = await rootState.api.visits.fetch(payload).catch(error => {
    // NoQueryCacheエラーの場合はホームに遷移するので、観察画面を閉じておく
    if (isNoQueryCacheError(error)) {
      commit("user/setShowUserDetail", false, { root: true });
    }
    handleNoQueryCacheError(error);
    throw new Error(error);
  });
  //  APIがエラーだった場合はnullなので抜ける
  if (res === null) {
    return;
  }

  // ロード時キーと現在のキーが違ったら処理を抜ける
  if (key !== state.currentKey) {
    return;
  }

  const rawVisits: ApiRes.Visit[] = res.visits;

  // 取得したデータを加工する
  const fetchedVisits = rawVisits.map(v =>
    Visit.build(
      v,
      rootState.clientSettings.conversionDefinitions,
      rootGetters["system/activeGlobalConversionDefinitions"],
      rootState.clientSettings.conversionAttributeDefinitions,
      rootState.system.globalConversionAttributeDefinitions,
      rootState.clientSettings.businessEventDefinitions,
      rootState.clientSettings.npsDefinitions,
      rootState.clientSettings.enqueteDefinitions
    )
  );

  // 過去向きなら、取得したvisitを既存のvisitの前に追加
  // 未来向きなら、取得したvisitを既存のvisitの後ろに追加
  const visits = isPast
    ? fetchedVisits.concat(state.visits)
    : state.visits.concat(fetchedVisits);

  // 訪問が追加されているので訪問間の経過時間を再計算
  setElapsedTime(visits);

  // まだ次もあるか
  const hasNext = FETCH_LIMIT <= rawVisits.length;

  commit(isPast ? "setHasNextPast" : "setHasNextFuture", hasNext);
  commit("setVisits", visits);

  // 更新したvisitからbaseTimeを取得する
  const newBaseTimeUsec = getters.baseTimeUsec(direction);
  commit(
    isPast ? "setBaseTimeUsecPast" : "setBaseTimeUsecFuture",
    newBaseTimeUsec
  );

  // 次のfetchが必要か
  let fetchedGramCount = 0;
  rawVisits.forEach(visit => {
    fetchedGramCount += visit.grams.length;
  });
  const totalGramCount = gramCount + fetchedGramCount;
  const loadedEnoughGram = MAX_FETCH_NUM_GRAMS <= totalGramCount;

  // 次がない、もしくは、MAX_FetchNUM_GRAMS分のgramを取得した場合は次をロードしない
  if (!hasNext || loadedEnoughGram) {
    // ローディング中をやめる
    commit(isPast ? "setIsLoadingPast" : "setIsLoadingFuture", false);
    return;
  }

  // FETCH_INTERVAL_MSEC後に次をFetchする
  await sleep(FETCH_INTERVAL_MSEC);

  // ロード時キーと現在のキーが違ったら処理を抜ける
  if (key !== state.currentKey) {
    return;
  }

  // 次を読み込む
  const param: FetchVisitsParams = {
    key: key,
    user: user,
    isFirst: false,
    gramCount: gramCount
  };
  dispatch(isPast ? "fetchPast" : "fetchFuture", param);
}

/**
 * create payload for visit API
 * export is only for test
 * @param user User
 * @param direction VISIT_DIRECTION
 * @param baseTimeUsec number
 * @param isFirst boolean
 * @param historyId number | null
 * @returns null | ApiParam.FetchVisits
 */
export function getRequestPayload(
  user: User,
  direction: VISIT_DIRECTION,
  baseTimeUsec: number,
  isFirst: boolean,
  historyId: number | null
): ApiParam.FetchVisits | null {
  if (historyId === null) {
    // TODO エラー
    return null;
  }

  const baseDate = new Date(usecToMsec(baseTimeUsec));

  // 検索範囲の時間
  let minTimeUsec = 0;
  let maxTimeUsec = 0;
  // fetchする(visit数 + 1)分の先または前のvisitのdateをセットする
  const visitCountForDate = FETCH_LIMIT + 1;
  if (direction === VISIT_DIRECTION.FUTURE) {
    minTimeUsec = baseTimeUsec - 1;
    const maxDate = user.overviews.getFutureSpecificVisitDate(
      baseDate,
      visitCountForDate
    );
    maxTimeUsec =
      maxDate === null ? MAX_FEATURE_USEC : msecToUsec(maxDate.getTime());
  } else {
    const minDate = user.overviews.getPastSpecificVisitDate(
      baseDate,
      visitCountForDate
    );
    if (minDate === null) {
      // 現在から最大表示可能時間前
      minTimeUsec = Math.floor(
        msecToUsec(now() - MAX_DISPLAY_SPAN_DAYS * 24 * 60 * 60 * 1000)
      );
    } else {
      minTimeUsec = msecToUsec(minDate.getTime());
    }
    maxTimeUsec = baseTimeUsec + 1;
  }

  const condition: LoadCondition = {
    search_direction: direction,
    num_visits: FETCH_LIMIT,
    base_time_usec: baseTimeUsec,
    min_searched_time_usec: minTimeUsec,
    max_searched_time_usec: maxTimeUsec,
    user_id: user.id
  };

  return {
    is_first: isFirst,
    load_condition: condition,
    history_id: historyId
  };
}

/**
 * 渡された訪問リストから表示オフになっている企業側アクションを抜いた訪問リストを返す
 *
 * @param visits 訪問リスト
 * @param displaySettings 企業側アクションの表示設定
 *
 * @return 企業側アクションの表示設定抜いた訪問リスト
 */
function filterCompanyActivityDisplay(
  visits: Visit[],
  displaySettings: CompanyActivity[]
): Visit[] {
  // 設定が無い場合はすべての訪問を表示
  if (displaySettings.length === 0) {
    return visits;
  }
  const displayableCompanyActivitys = displaySettings
    .filter(setting => setting.isDisplay)
    .map(setting => setting.contactDefinition.type);

  const filteredVisits: Visit[] = [];
  visits.forEach(visit => {
    const filteredGrams = visit.grams.filter(gram => {
      // 企業側の活動以外は無条件で表示
      if (gram.subjectType !== SUBJECT_TYPE.COMPANY) {
        return true;
      }
      return (
        displayableCompanyActivitys.indexOf(gram.platformSubCategory) !== -1
      );
    });
    if (0 < filteredGrams.length) {
      filteredVisits.push(
        new Visit(filteredGrams, visit.timeOfDay, visit.summary)
      );
    }
  });

  // 訪問が減るとこがあるので、訪問間の経過時間を再計算
  setElapsedTime(filteredVisits);

  return filteredVisits;
}

/**
 * 渡された訪問リストの訪問間の経過時間をセットする
 */
function setElapsedTime(visits: Visit[]) {
  visits.forEach((visit, index) => {
    visit.isLast = index >= visits.length - 1;
    if (visit.isLast) {
      visit.elapsedTimeTx = "";
    } else {
      const nextSec = visits[index + 1].grams[0].timeSec;
      visit.elapsedTimeTx = getElapsedTimeTx(visit.endTimeSec, nextSec);
    }
  });
}

export const visit = {
  namespaced: true,
  state: new VisitState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
