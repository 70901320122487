<template>
  <div class="ug-table">
    <div ref="ugTableHead" :style="{ top: stickyTop }" class="ug-table__head">
      <slot name="header"></slot>
    </div>
    <div class="ug-table__body">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UgTable extends Vue {
  @Prop({ type: String, default: "0" })
  stickyTop!: string;
}
</script>

<style lang="scss" scoped>
.ug-table__head {
  position: sticky;
  width: 100%;
}

@media print {
  .ug-table__head {
    position: static;
  }
}
</style>
