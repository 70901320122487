import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";
import { ApiParam } from "@/api/api-param";

export enum VisitTimeFrame {
  Morning = 1,
  Day = 2,
  Night = 3
}

export const VisitSummaryType = {
  CV: 1,
  PV: 2,
  INFLOW: 3,
  EVENT: 4,
  WITHDRAWAL: 5
} as const;
export type VisitSummaryType = typeof VisitSummaryType[keyof typeof VisitSummaryType];

export const VisitSummaryInflowType = {
  AD: 1,
  SEARCH_ENGINE: 2,
  DOMAIN: 3
} as const;
export type VisitSummaryInflowType = typeof VisitSummaryInflowType[keyof typeof VisitSummaryInflowType];

export const VisitSummaryWithdrawalType = {
  CV: 1,
  PV: 2,
  EVENT: 3
} as const;
export type VisitSummaryWithdrawalType = typeof VisitSummaryWithdrawalType[keyof typeof VisitSummaryWithdrawalType];

export interface VisitSummaryJsonData {
  summary_type: VisitSummaryType;
  count: number;
  name: string | null;
  url: string | null;
  stay_time_sec: number | null;
  cv_id: number | null;
  summary_sub_type: VisitSummaryInflowType | VisitSummaryWithdrawalType | null;
}
export default class ApiVisits {
  constructor(private readonly httpClient: HttpClient) {}

  public fetch = (params: ApiParam.FetchVisits): Promise<ApiRes.Visits> => {
    return this.httpClient.post<ApiRes.Visits>(ApiUrl.VISITS, params);
  };
}
