export enum BlogPage {
  Search = "search",
  Filter = "filter",
  UserIndexSmall = "userIndexSmall",
  UserIndexLarge = "userIndexLarge",
  UserDetail = "userDetail",
  UserTrend = "userTrend",
  Empty = ""
}

export const BLOG_LINK_TO_SEARCH =
  "https://blog.usergram.info/article/faq/extract-user-search-user-2021-0201/";

export const BLOG_LINK_TO_FILTER =
  "https://blog.usergram.info/article/faq/extract-user-select-user-2021-04-30/";

export const BLOG_LINK_TO_USERS_WITH_FEW_USERS =
  "https://blog.usergram.info/article/faq/extract-user-filter-troubleshooting-2019-0801/";

export const BLOG_LINK_TO_USERS_WITH_MANY_USERS =
  "https://blog.usergram.info/article/tips/choice-of-user-2021-04-23/";

export const BLOG_LINK_TO_USER_DETAIL =
  "https://blog.usergram.info/article/faq/data-faq-read-data-2021-0608/";

export const BLOG_LINK_TO_USER_TREND =
  "https://blog.usergram.info/article/faq/usecase-analysis-tool-2019-1211/";

export const BLOG_LINK_PARAMS =
  "?waad=6Zgca2Qz&ugad=6Zgca2Qz&utm_source=usergram&utm_medium=hinticon&utm_campaign=hinticon";

export const BLOG_LINK_SWITCH_DURATION = 250;
