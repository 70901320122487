<template>
  <div
    :data-cy="
      type === 'addNode'
        ? 'condition-form-drop-area-add-node'
        : 'condition-form-drop-area-or-node'
    "
    class="filter-condition-form-drop-area"
    :class="{ 'filter-condition-form-drop-area--hover': isDragOver }"
    :style="{ height: height }"
    @dragover.prevent
    @drop="onDrop"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class FilterConditionFormDropArea extends Vue {
  isDragOver = false;

  @Prop({ type: String, default: "50px" })
  height!: string;

  @Prop({ type: String, default: "addNode" })
  type!: "addNode" | "orNode";

  @Emit("drop")
  onDrop() {
    this.isDragOver = false;
  }

  onDragEnter() {
    this.isDragOver = true;
  }
  onDragLeave() {
    this.isDragOver = false;
  }
}
</script>
<style lang="scss" scoped>
.filter-condition-form-drop-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 2px dashed $colorBase500;
  border-radius: $sizeRadius;
  background: $colorWhite;
  color: $colorBase700;
  text-align: center;
  line-height: 1.8em;
}

.filter-condition-form-drop-area--hover {
  border-color: $colorBase600;
  background: $colorHoverLightForWhite;
}
</style>
