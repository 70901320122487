<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseInputTextMatchType
    :value="screenName"
    :match-method="matchMethod"
    :prefix="$t('appBrowsePrefix')"
    class="filter-app-browse-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { FilterNodeForBrowseApp } from "@/models/search/filter-node/FilterNodeForBrowseApp";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterAppBrowseField extends Vue {
  @Prop({ type: String, required: true })
  screenName!: String;

  @Prop({ type: Number, required: true })
  matchMethod!: MatchMethod;

  onInputText(condition: FilterNodeForBrowseApp) {
    this.$emit("input-screen-name", condition);
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.$emit("input-match-method", matchMethod);
  }
}
</script>
