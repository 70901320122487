<i18n src="@/i18n/views/forget-password"></i18n>

<template>
  <div class="forgetPassword">
    <div v-if="!isValidatingToken" class="forgetPassword__header">
      <div class="forgetPassword__logo">
        <UsergramLogo />
      </div>
      <div class="forgetPassword__language">
        <LanguageSelect />
      </div>
    </div>
    <div
      v-if="!isValidatingToken"
      :class="{
        forgetPassword__main: true,
        'forgetPassword__main--wide':
          isRequestSucceeded && !isPasswordUpdatePage
      }"
    >
      <div v-if="isPasswordUpdatePage">
        <div v-if="!isValidToken">
          <ForgetPasswordHeader
            :title="$t('pageTitleInvalid')"
            :descriptions="[
              $t('invalidDescription1'),
              $t('invalidDescription2')
            ]"
            data-cy="fp-page_header-invalid"
          />
        </div>
        <div v-else-if="isRequestSucceeded">
          <ForgetPasswordHeader
            :title="$t('pageTitleCompleteReset')"
            :descriptions="[$t('descriptionPasswordUpdated')]"
            data-cy="fp-page_header-complete"
          />
        </div>
        <div v-else>
          <ForgetPasswordHeader
            :title="$t('pageTitleResetPassword')"
            :descriptions="[$t('descriptionUpdatePassword')]"
            data-cy="fp-page_header-update"
          />
          <div class="forgetPassword__form">
            <div class="forgetPassword__formContainer">
              <div class="forgetPassword__formItem">
                <div class="forgetPassword__formLabel">
                  {{ $t("password") }}
                </div>
                <InputText
                  v-model="password"
                  :type="'password'"
                  class="forgetPassword__formInput"
                  data-cy="fp-Form_input_Password"
                  @keyup-enter="submitChangePassword"
                />
                <ul class="forgetPassword__passwordRequirements">
                  <li v-for="n in 4" :key="n">
                    {{ $t(`passwordDescription${n}`) }}
                  </li>
                </ul>
              </div>
              <div class="forgetPassword__formItem">
                <div class="forgetPassword__formLabel">
                  {{ $t("passwordConfirmation") }}
                </div>
                <InputText
                  v-model="passwordConfirmation"
                  :type="'password'"
                  class="forgetPassword__formInput"
                  data-cy="fp-Form_input_Password_confirmation"
                  @keyup-enter="submitChangePassword"
                />
              </div>
            </div>
            <Button
              :disabled="isLoading || !password || !passwordConfirmation"
              :width="'100%'"
              data-cy="fp-Form_button"
              class="forgetPassword__submitButton"
              @click="submitChangePassword"
            >
              {{ $t("submitChangePassword") }}
            </Button>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="isRequestSucceeded">
          <ForgetPasswordHeader
            :title="$t('pageTitleSentEmail')"
            :descriptions="[$t('descriptionCheckEmail')]"
            data-cy="fp-page_header-sent"
          />
        </div>
        <div v-else>
          <ForgetPasswordHeader
            :title="$t('pageTitle')"
            :descriptions="[$t('descriptionConfirm'), $t('descriptionSend')]"
            data-cy="fp-page_header-request"
          />
          <div class="forgetPassword__form">
            <div class="forgetPassword__formContainer">
              <div class="forgetPassword__formItem">
                <div class="forgetPassword__formLabel">
                  {{ $t("loginId") }}
                </div>
                <InputText
                  v-model="id"
                  data-cy="fp-Form_input_Id"
                  :has-error="hasIdInputError"
                  class="forgetPassword__formInput"
                  @input="onIdInput"
                  @keyup-enter="submitTokenRequest"
                />
                <div
                  v-if="hasIdInputError"
                  v-t="'loginIdValidationMessage'"
                  data-cy="fp-Form_errorText-input_Id"
                  class="forgetPassword__formErrorText"
                />
              </div>
              <div class="forgetPassword__formItem">
                <div class="forgetPassword__formLabel">
                  {{ $t("email") }}
                </div>
                <InputText
                  v-model="email"
                  data-cy="fp-Form_input_Email"
                  class="forgetPassword__formInput"
                  :has-error="hasEmailInputError"
                  :type="'email'"
                  @input="onEmailInput"
                  @keyup-enter="submitTokenRequest"
                />
                <div
                  v-if="hasEmailInputError"
                  v-t="'emailValidationMessage'"
                  data-cy="fp-Form_errorText-Email"
                  class="forgetPassword__formErrorText"
                />
              </div>
            </div>
            <Button
              :disabled="
                isLoading ||
                  !email ||
                  !id ||
                  hasIdInputError ||
                  hasEmailInputError
              "
              :width="'100%'"
              data-cy="fp-Form_button"
              class="forgetPassword__submitButton"
              @click="submitTokenRequest"
              >{{ $t("submitRequestToken") }}
            </Button>
          </div>
        </div>
      </div>
      <div class="forgetPassword__gobackLink">
        <router-link
          v-if="isPasswordUpdatePage && !isValidToken"
          to="/forget-password"
          data-cy="fp-back_link"
          >{{ $t("forgottenPassword") }}</router-link
        >
        <router-link v-else to="/login" data-cy="fp-back_link">{{
          $t("backToLogin")
        }}</router-link>
      </div>
      <div class="forgetPassword__footer">
        © Copyright beBit, Inc.
        <a href="https://help.usergram.info/" target="_blank" rel="noopener">{{
          $t("help")
        }}</a>
      </div>
    </div>
    <div v-if="isLoading" class="forgetPassword__loading">
      <Loading height="100%" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ForgetPasswordHeader from "@/components/forget-password/ForgetPasswordHeader.vue";
import { handleError } from "@/util/error-util";
import UsergramLogo from "@/assets/svg/logo/usergram-logo.svg";
import Button from "@/components/Button.vue";
import InputText from "@/components/form/InputText.vue";
import Loading from "@/components/Loading.vue";
import LanguageSelect from "@/views/LanguageSelect.vue";

@Component({
  components: {
    Button,
    UsergramLogo,
    InputText,
    Loading,
    LanguageSelect,
    ForgetPasswordHeader
  }
})
export default class ForgetPassword extends Vue {
  isRequestSucceeded: boolean = false;
  isLoading: boolean = false;

  id: string = "";
  email: string = "";

  password: string = "";
  passwordConfirmation: string = "";

  hasIdInputError: boolean = false;
  hasEmailInputError: boolean = false;

  isValidToken: boolean = false;

  get isPasswordUpdatePage() {
    return !!this.token && !!this.encodedLoginName;
  }

  get token(): string {
    return this.$route.params.token;
  }

  get encodedLoginName(): string {
    return this.$route.params.encoded_login_name;
  }

  get isValidatingToken(): boolean {
    return this.isPasswordUpdatePage && !this.isValidToken && this.isLoading;
  }

  async created() {
    if (this.isPasswordUpdatePage) {
      this.isLoading = true;
      try {
        await this.$store.state.api.forgetPassword.validateToken({
          token: this.token,
          encoded_login_name: this.encodedLoginName
        });
        this.isValidToken = true;
      } catch {
        this.isValidToken = false;
      } finally {
        this.isLoading = false;
      }
    }
  }

  public async submitTokenRequest() {
    if (this.isLoading || this.hasIdInputError || this.hasEmailInputError) {
      return;
    }
    this.isLoading = true;
    try {
      await this.$store.state.api.forgetPassword.requestToken({
        email: this.email,
        login_name: this.id
      });
      this.isRequestSucceeded = true;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async submitChangePassword() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      await this.$store.state.api.forgetPassword.updatePassword({
        token: this.token,
        new_password1: this.password,
        new_password2: this.passwordConfirmation,
        encoded_login_name: this.encodedLoginName
      });
      this.isRequestSucceeded = true;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  onIdInput() {
    const idRegex = /^[0-9A-Za-z-._]*$/;
    this.hasIdInputError = !!this.id && !this.id.match(idRegex);
  }

  onEmailInput() {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.hasEmailInputError = !!this.email && !this.email.match(emailRegex);
  }
}
</script>

<style lang="scss" scoped>
.forgetPassword__main {
  max-width: 354px;
  margin: 0 auto;
  padding-top: 60px;
}

.forgetPassword__main--wide {
  max-width: 600px;
}

.forgetPassword__header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 0px 30px;
}

.forgetPassword__logo {
  & svg {
    width: 146.6px;
    height: 30px;
  }
}

.forgetPassword__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgetPassword__formContainer {
  width: 100%;
  margin-top: 40px;
}

.forgetPassword__formItem {
  align-items: center;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.forgetPassword__showPassword {
  margin-top: 15px;
}

.forgetPassword__submitButton {
  margin-top: 40px;
  border: none !important;
}

.forgetPassword__submitButton.disabled {
  background-color: $colorGray200 !important;
  color: $colorBase600 !important;
  border-color: $colorGray200 !important;
  cursor: default;
}

.forgetPassword__footer {
  padding: 80px 0px 40px;
  text-align: center;
  font-size: 14px;

  & a {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $colorLine;
    color: $colorTextLinkGray;
    text-decoration: none;
  }
}

.forgetPassword__gobackLink {
  margin-top: 20px;
  text-align: left;

  a {
    color: $colorTextLink;
    text-decoration: none;
  }
}

.forgetPassword__footer a:hover {
  color: $colorText;
  text-decoration: underline;
}

.forgetPassword__gobackLink a:hover {
  text-decoration: underline;
}

.forgetPassword__formLabel {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 14px;
}

.forgetPassword__formInput {
  min-width: 0;
  flex: 1 0 0;
}

.forgetPassword__formErrorText {
  font-size: 11px;
  margin-top: 8px;
  color: $colorError;
  line-height: 1.5;
  width: 100%;
}

.forgetPassword__passwordRequirements {
  margin: 15px 0 5px;
  line-height: 1.5;
  font-size: 12px;
  color: $colorBase700;
}

.forgetPassword__loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $colorOverlay;
  text-align: center;
  & img {
    width: 58px;
    height: 58px;
  }
}
</style>
