<i18n
  src="@/i18n/views/client-settings/td-conversion-attribute-change-measurement-state.json"
></i18n>

<template>
  <div class="tdConversionAttributeUpdateMeasurementState">
    <div
      v-t="'title'"
      class="tdConversionAttributeUpdateMeasurementState__title"
    />
    <div
      v-if="isLoading"
      class="tdConversionAttributeUpdateMeasurementState__loading"
    >
      <Loading height="80px" />
    </div>
    <div
      v-else-if="
        tdConversionAttribute.isDisabled && conversionAttribute.isDisabled
      "
    >
      <div
        v-t="'cannotEnableMeasurementState'"
        class="tdConversionAttributeUpdateMeasurementState__description"
      />
      <div>
        <SettingsTable
          class="tdConversionAttributeUpdateMeasurementState__disabledlLinkedConversionAttribute"
          min-width="680px"
        >
          <SettingsTableRow>
            <SettingsTableCell>
              <a href="/client-settings/conversion-attribute/index/">{{
                conversionAttributeName
              }}</a>
            </SettingsTableCell>
          </SettingsTableRow>
        </SettingsTable>
        <div class="tdConversionUpdateMeasurementState__formButtons">
          <Button
            v-t="'cancel'"
            class="tdConversionAttributeUpdateMeasurementState__button  tdConversionAttributeUpdateMeasurementState__cancelButton"
            padding="0 20px"
            :text-size="buttonTextSize"
            :type="buttonTypeCancel"
            @click="onClickCancel"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="tdConversionAttributeUpdateMeasurementState__description"
        v-text="description"
      />

      <div>
        <SettingsTable
          class="tdConversionAttributeUpdateMeasurementState__table"
        >
          <SettingsTableRow
            class="tdConversionAttributeUpdateMeasurementState__row"
          >
            <SettingsTableCell
              v-t="'attributeName'"
              width="200px"
              text-align="center"
            />
            <SettingsTableCell>{{ conversionAttributeName }}</SettingsTableCell>
          </SettingsTableRow>
          <SettingsTableRow
            class="tdConversionAttributeUpdateMeasurementState__row"
          >
            <SettingsTableCell v-t="'attributeType'" text-align="center" />
            <SettingsTableCell> {{ attributeType }} </SettingsTableCell>
          </SettingsTableRow>
          <SettingsTableRow
            class="tdConversionAttributeUpdateMeasurementState__row"
          >
            <SettingsTableCell
              v-t="'attributeColumnName'"
              text-align="center"
            />
            <SettingsTableCell>{{
              tdConversionAttribute.cvAttributeColumn
            }}</SettingsTableCell>
          </SettingsTableRow>
        </SettingsTable>
        <div class="tdConversionAttributeUpdateMeasurementState__formButtons">
          <Button
            class="tdConversionAttributeUpdateMeasurementState__submitButton"
            padding="0 20px"
            :text-size="buttonTextSize"
            :disabled="isSubmitButtonDisabled"
            @click="onClickSubmit"
            >{{ submitLabel }}</Button
          >
          <Button
            v-t="'cancel'"
            class="tdConversionAttributeUpdateMeasurementState__cancelButton"
            padding="0 20px"
            :text-size="buttonTextSize"
            :type="buttonTypeCancel"
            @click="onClickCancel"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import Button from "@/components/Button.vue";
import { handleError } from "@/util/error-util";
import { ButtonTextSize, BUTTON_TYPE } from "@/const/button";
import { TdConversionAttributeDefinition } from "@/models/client-settings/TdConversionAttributeDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { AttributeType } from "@/api/apis/ApiSearch";

@Component({
  components: {
    Button,
    Loading,
    SettingsTable,
    SettingsTableCell,
    SettingsTableRow
  }
})
export default class TdConversionAttributeUpdateMeasurementState extends Vue {
  isLoading = true;
  buttonTextSize = ButtonTextSize.Small;
  buttonTypeCancel = BUTTON_TYPE.LIGHT;
  isSubmitting = false;

  get tdConversionAttribute(): TdConversionAttributeDefinition {
    return this.$store.state.tdConversionAttribute
      .selectedTdConversionAttributeDefinition;
  }

  get description(): string {
    return this.tdConversionAttribute.isDisabled
      ? this.$t("enableDescription").toString()
      : this.$t("disableDescription").toString();
  }

  get conversionAttributes(): ConversionAttributeDefinition[] {
    return this.$store.state.clientSettings.conversionAttributeDefinitions;
  }

  get conversionAttribute(): ConversionAttributeDefinition {
    const cvAttributeId = this.tdConversionAttribute.cvAttributeId;
    return this.conversionAttributes.filter(
      conversionAttributeDefinition =>
        conversionAttributeDefinition.id === cvAttributeId
    )[0];
  }

  get attributeType(): string {
    const attributeType: string =
      this.conversionAttribute.type === AttributeType.TEXT ? "text" : "number";
    return this.$t(attributeType) as string;
  }

  get conversionAttributeName(): string {
    return this.conversionAttribute.name;
  }
  get submitLabel(): string {
    return this.tdConversionAttribute.isDisabled
      ? this.$t("beginMeasuring").toString()
      : this.$t("stopMeasuring").toString();
  }

  get isSubmitButtonDisabled(): boolean {
    return this.isSubmitting;
  }

  get successMessage(): string {
    const attrName = this.conversionAttributeName;

    return this.tdConversionAttribute.isDisabled
      ? this.$t("enableSuccessMessage", { attrName }).toString()
      : this.$t("disableSuccessMessage", { attrName }).toString();
  }

  async onClickSubmit() {
    this.isSubmitting = true;
    try {
      await this.updateTdConversionAttribute();
      this.$router.push({ name: "td-conversion-attribute-settings" });
    } catch (e) {
      handleError(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  async updateTdConversionAttribute() {
    if (this.tdConversionAttribute) {
      const params = {
        id: Number(this.$route.params.id),
        payload: {
          cv_attr_id: this.tdConversionAttribute.cvAttributeId,
          cv_attr_column: this.tdConversionAttribute.cvAttributeColumn,
          is_disabled: !this.tdConversionAttribute.isDisabled
        }
      };
      await this.$store.dispatch(
        "tdConversionAttribute/updateTdConversionAttribute",
        params
      );
      this.$store.commit(
        "tdConversionAttribute/setSuccessMessage",
        this.successMessage
      );
    }
  }

  onClickCancel() {
    this.$router.push({ name: "td-conversion-attribute-settings" });
  }

  async created() {
    let tdConversionAttribute: TdConversionAttributeDefinition | null = null;
    try {
      const id: number = parseInt(this.$route.params.id);
      await this.$store.dispatch(
        "tdConversionAttribute/fetchTdConversionAttributeById",
        id
      );

      await this.$store.dispatch(
        "clientSettings/fetchConversionAttributeDefinitions"
      );
      tdConversionAttribute = this.$store.state.tdConversionAttribute
        .selectedTdConversionAttributeDefinition;
      if (!!tdConversionAttribute && tdConversionAttribute.id === id) {
        this.isLoading = false;
      } else {
        this.$router.push({ name: "td-conversion-attribute-settings" });
      }
    } catch (e) {
      handleError(e);
    }
  }

  destroyed() {
    this.$store.commit(
      "tdConversionAttribute/setSelectedTdConversionAttributeDefinition",
      null
    );
  }
}
</script>
<style lang="scss" scoped>
.tdConversionAttributeUpdateMeasurementState {
  margin-right: 20px;
  min-width: 704px;
}

.tdConversionAttributeUpdateMeasurementState__title {
  padding: 0 0 40px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: inherit;
}

.tdConversionAttributeUpdateMeasurementState__description {
  line-height: 2;
  margin-bottom: 30px;
  white-space: pre-line;
}
.tdConversionAttributeUpdateMeasurementState__table {
  margin-bottom: 16px;
  width: 680px;
}
.tdConversionAttributeUpdateMeasurementState__disabledlLinkedConversionAttribute {
  margin-bottom: 20px;
  a {
    color: $colorBase700;
    text-decoration: underline;
  }
  a:hover {
    color: $colorBase800;
  }
}

.tdConversionAttributeUpdateMeasurementState__submitButton {
  margin-right: 15px;
  min-width: 120px;
}
.tdConversionAttributeUpdateMeasurementState__cancelButton {
  min-width: 120px;
}
</style>
