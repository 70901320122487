import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { filterInputCheck } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { i18n } from "@/i18n";

/**
 * 行動履歴による条件
 *
 * 絞り込み条件に追加で、来店、訪問のとき、目的（部分一致）を条件として付与できる
 */
export class FilterContactPurposeCondition {
  public readonly conditionType = FilterNodeConditionType.ContactPurpose;
  constructor(public readonly purpose: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.purpose,
      i18n.t("models.contactAttribute.purpose") as string
    );
  }
}
