import { ApiRes } from "@/api/api-res";
import {
  formatDate,
  DateFormat,
  getTimeDiffTxParams,
  TIME_DIFF_PARAM_TYPE
} from "@/util/date-util";
import { i18n } from "@/i18n";
import {
  PLATFORM_CATEGORY,
  GRAM_TYPE,
  GRAM_VIEW_TYPE,
  SUBJECT_TYPE,
  DATE_FORMAT_TYPE
} from "@/const/gram";
import { ConversionDefinition } from "./client-settings/ConversionDefinition";
import { ConversionAttributeDefinition } from "./client-settings/ConversionAttributeDefinition";
import { BusinessEventDefinition } from "./client-settings/BusinessEventDefinition";
import { NpsDefinition } from "./client-settings/NpsDefinition";
import { EnqueteDefinition } from "./client-settings/EnqueteDefinition";
import {
  GlobalConversionAttributeDefinition,
  getGlobalConversionAttributeNameByQuery,
  isAllowGlobalConversionAttributeQuery
} from "@/models/system/GlobalConversionAttributeDefinition";
import { getConversionNameFromConversionId } from "@/util/definition-util";

// ブラウザバックボタン種別
export enum NAVIGATION_TYPE {
  NORMAL = 0,
  RELOAD = 1, // 再読み込み
  BACK_OR_FORWARD = 2 // 戻るボタンで表示
}

// 行動履歴の電話の反応
export enum CONTACT_TEL_RESPONSE_TYPE {
  NO_RESPONSE = 0,
  REACTION = 1,
  ON_IMPORT = -1
}

export enum CvType {
  Cv = 0,
  FirstOpen = 1
}

export namespace GRAM_SUB_TYPE {
  export const NONE = 0;

  export namespace PV {
    export const NONE = 0;
    export const CRUISE = 1;
    export const AD_INFLOW = 2;
    export const SEARCH_ENGINE_INFLOW = 3;
    export const OTHER_INFLOW = 4;
  }

  export namespace LAUNCH {
    export const NONE = 0;
    export const PUSH_NOTIFICATION = 1;
    export const LINK = 2;
  }
}

interface conversionAttribute {
  id: number;
  value: string;
  name: string;
}

export class Gram {
  public hasWithdrawal: boolean = false;

  public readonly hasInflow: boolean = false;

  public readonly isImport: boolean;
  public readonly isSmartphone: boolean = false;
  public readonly isApp: boolean = false;

  public readonly viewType!: GRAM_VIEW_TYPE;

  public readonly timeTx: string = "";

  public readonly hasNavigation: boolean = false;
  public readonly navigationTx: string = "";

  public isLongStay: boolean = false;
  public stayTime: string = "";
  public stayTimeUnitTx: string = "";
  public stayTimeSec: number = 0;

  constructor(
    public readonly id: string,
    public readonly platformCategory: number,
    public readonly platformSubCategory: number,
    public readonly timeSec: number,
    public readonly timeZone: number,
    public readonly gramType: number,
    public readonly gramSubType: number,
    public readonly subjectType: number,
    public readonly navigationType: number,
    public readonly dateFormatType: DATE_FORMAT_TYPE,
    public readonly cvId: number,
    public readonly pv: GramPv | null,
    public readonly event: GramEvent | null,
    public readonly businessEvent: GramBusinessEvent | null,
    public readonly contactMail: GramContactMail | null,
    public readonly contactShop: GramContactShop | null,
    public readonly contactTel: GramContactTel | null,
    public readonly contactDm: GramContactDm | null,
    public readonly contactVisit: GramContactVisit | null,
    public readonly contactApp: GramContactApp | null,
    public readonly nps: GramNps | null,
    public readonly hasConversion: boolean,
    public readonly conversionName: string,
    public readonly conversionAttributes: conversionAttribute[],
    public readonly businessEventTitle: string,
    public readonly npsEnqtTitle: string,
    public readonly cvType: CvType
  ) {
    this.isImport =
      platformCategory === PLATFORM_CATEGORY.BE.ID ||
      platformCategory === PLATFORM_CATEGORY.BIZ_INDEX.ID ||
      platformCategory === PLATFORM_CATEGORY.CLDB.ID ||
      platformCategory === PLATFORM_CATEGORY.CONTACT.ID;

    this.hasInflow =
      gramType === GRAM_TYPE.PV &&
      (gramSubType === GRAM_SUB_TYPE.PV.AD_INFLOW ||
        gramSubType === GRAM_SUB_TYPE.PV.SEARCH_ENGINE_INFLOW ||
        gramSubType === GRAM_SUB_TYPE.PV.OTHER_INFLOW);

    this.isSmartphone =
      platformSubCategory === PLATFORM_CATEGORY.WEB.SUB_CATEGORY.ANDROID ||
      platformSubCategory === PLATFORM_CATEGORY.WEB.SUB_CATEGORY.IPHONE;

    this.isApp = platformCategory === PLATFORM_CATEGORY.APP.ID;

    // 時刻（文字列）をセット
    this.timeTx = formatDate(
      DateFormat.HHcmm,
      new Date(timeSec * 1000),
      timeZone
    );

    // 戻る進むナビゲーション
    if (navigationType === NAVIGATION_TYPE.NORMAL) {
      this.hasNavigation = false;
      this.navigationTx = "";
    } else if (navigationType === NAVIGATION_TYPE.RELOAD) {
      this.hasNavigation = true;
      this.navigationTx = i18n.t("models.gram.reload") as string;
    } else if (navigationType === NAVIGATION_TYPE.BACK_OR_FORWARD) {
      this.hasNavigation = true;
      this.navigationTx = i18n.t("models.gram.backButton") as string;
    }

    // WEB・APPの場合
    if (
      platformCategory === PLATFORM_CATEGORY.WEB.ID ||
      platformCategory === PLATFORM_CATEGORY.APP.ID
    ) {
      if (gramType === GRAM_TYPE.CV || gramType === GRAM_TYPE.NCV) {
        // CV
        this.viewType = GRAM_VIEW_TYPE.CV;
      } else if (gramType === GRAM_TYPE.PV) {
        // PV
        this.viewType = GRAM_VIEW_TYPE.PV;
      } else if (gramType === GRAM_TYPE.EVENT) {
        // Event
        this.viewType = GRAM_VIEW_TYPE.EVENT;
      } else if (gramType === GRAM_TYPE.LAUNCH) {
        // 起動
        this.viewType = GRAM_VIEW_TYPE.LAUNCH;
      }
    }
    // ビジネスイベントの場合
    if (platformCategory === PLATFORM_CATEGORY.BE.ID) {
      this.viewType = GRAM_VIEW_TYPE.BUSINESS_EVENT;
    }
    // ユーザデータの場合
    if (platformCategory === PLATFORM_CATEGORY.CLDB.ID) {
      if (
        platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.NPS ||
        platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.ENQT
      ) {
        // NPS・ENQT
        this.viewType = GRAM_VIEW_TYPE.NPS_ENQT;
      } else if (
        platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.UDATA
      ) {
        // ユーザ属性
        this.viewType = GRAM_VIEW_TYPE.USER_ATTR;
      }
    }
    // 行動履歴の場合
    if (platformCategory === PLATFORM_CATEGORY.CONTACT.ID) {
      if (platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.TEL) {
        this.viewType = GRAM_VIEW_TYPE.TEL;
      } else if (
        platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.MAIL
      ) {
        this.viewType = GRAM_VIEW_TYPE.MAIL;
      } else if (
        platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.SHOP
      ) {
        this.viewType = GRAM_VIEW_TYPE.SHOP;
      } else if (
        platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.DM
      ) {
        this.viewType = GRAM_VIEW_TYPE.DM;
      } else if (
        platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.VISIT
      ) {
        this.viewType = GRAM_VIEW_TYPE.VISIT;
      } else if (
        platformSubCategory === PLATFORM_CATEGORY.CONTACT.SUB_CATEGORY.APP
      ) {
        this.viewType = GRAM_VIEW_TYPE.APP;
      }
    }

    // if (!this.viewType) {
    //   // TODO エラー
    //   //ErrorManager.handle(new Error('unknown platform_sub_category[' + platformCategory + platformSubCategory + gramType ']'));
    // }
  }

  public setStayTime(nextGramTimeSec: number) {
    if (nextGramTimeSec !== -1) {
      this.stayTimeSec = nextGramTimeSec - this.timeSec;
      const params = getTimeDiffTxParams(
        this.timeSec,
        nextGramTimeSec,
        this.timeZone
      );
      if (params.type === TIME_DIFF_PARAM_TYPE.TIME_UNIT) {
        this.stayTime = params.time + "";
        this.stayTimeUnitTx = params.unitTx;
      } else {
        // TODO error
        // ErrorManager.handle(new Error('invalid paramType[' + params.PARAM_TYPE + ']'));
      }
    }
    // 長時間滞在かセット
    this.isLongStay = 60 <= this.stayTimeSec;
  }

  public get isAdInflow(): boolean {
    return this.hasInflow && this.gramSubType === GRAM_SUB_TYPE.PV.AD_INFLOW;
  }

  public get isSearchInflow(): boolean {
    return (
      this.hasInflow &&
      this.gramSubType === GRAM_SUB_TYPE.PV.SEARCH_ENGINE_INFLOW
    );
  }

  public get isOtherInflow(): boolean {
    return this.hasInflow && this.gramSubType === GRAM_SUB_TYPE.PV.OTHER_INFLOW;
  }

  public get isCompany(): boolean {
    return this.subjectType === SUBJECT_TYPE.COMPANY;
  }

  public get hasTimeOfDay(): boolean {
    return this.dateFormatType !== DATE_FORMAT_TYPE.DATE_ONLY;
  }

  /* ===============================================================
  * PV
  =============================================================== */
  public get pvLocationName(): string {
    if (this.pv !== null) {
      return this.pv.location.name;
    }
    return "";
  }

  public get pvLocationUri(): string {
    if (this.pv !== null) {
      return this.pv.location.uri;
    }
    return "";
  }

  public get pvLocationReferrer(): string {
    if (this.pv !== null) {
      return this.pv.location.referrer;
    }
    return "";
  }

  public get pvSearchEngineName(): string {
    if (this.pv !== null && this.pv.searchEngine !== null) {
      return this.pv.searchEngine.name;
    }
    return "";
  }

  public get pvSearchEngineKeyword(): string {
    if (this.pv !== null && this.pv.searchEngine !== null) {
      return this.pv.searchEngine.keyword;
    }
    return "";
  }

  /* ===============================================================
  * Event
  =============================================================== */
  public get eventName(): string {
    if (this.event !== null) {
      return this.event.name;
    }
    return "";
  }

  /* ===============================================================
  * 起動
  =============================================================== */
  public get launchTitle(): string {
    if (this.gramSubType === GRAM_SUB_TYPE.LAUNCH.PUSH_NOTIFICATION) {
      return i18n.t("models.gram.launchTitle", {
        type: i18n.t("models.gram.pushNotification")
      }) as string;
    }
    if (this.gramSubType === GRAM_SUB_TYPE.LAUNCH.LINK) {
      return i18n.t("models.gram.launchTitle", {
        type: i18n.t("models.gram.link")
      }) as string;
    }

    return "";
  }

  /* ===============================================================
  * ビジネスイベント
  =============================================================== */
  public get businessEventDescription(): string {
    if (this.businessEvent !== null) {
      return this.businessEvent.optional.content;
    }
    return "";
  }

  /* ===============================================================
  * NPS Enqt
  =============================================================== */
  public get npsEnqtDescription(): string {
    if (this.nps !== null && this.nps.optional.comment) {
      return this.nps.optional.comment;
    }
    return "";
  }

  /* ===============================================================
  * 行動履歴 電話
  =============================================================== */
  public get contactTelTitle(): string {
    let title = i18n.t("models.gram.tel") as string;
    const tel = this.contactTel;
    if (tel === null) {
      return title;
    }

    const response = tel.optional.response;
    const talkTimeSec = tel.required.talkTimeSec;

    // 反応無し
    if (response === CONTACT_TEL_RESPONSE_TYPE.NO_RESPONSE) {
      if (this.subjectType === SUBJECT_TYPE.COMPANY) {
        // 企業 → ユーザ
        title += "(" + i18n.t("models.gram.noResponse") + ")";
      } else {
        // ユーザ → 企業
        title += "(" + i18n.t("models.gram.abandoned") + ")";
      }
    }
    // 通話時間あり
    else if (0 < talkTimeSec) {
      const talkSec = talkTimeSec % 60;
      const talkMin = Math.floor(talkTimeSec / 60);
      title += "(" + i18n.t("models.gram.durationOfCall") + " : ";
      if (talkMin > 0) {
        title += talkMin + "" + i18n.t("models.gram.min");
      }
      title += talkSec + "" + i18n.t("models.gram.sec");
      title += "）";
    }

    return title;
  }
  public get contactTelDescription(): string {
    if (this.contactTel !== null) {
      return this.contactTel.optional.content;
    }
    return "";
  }

  /* ===============================================================
  * 行動履歴 メール
  =============================================================== */
  public get contactMailTitle(): string {
    let title = i18n.t("models.gram.mail") as string;
    if (this.contactMail !== null) {
      title += " : " + this.contactMail.required.subject;
    }
    return title;
  }

  /* ===============================================================
  * 行動履歴 来店
  =============================================================== */
  public get contactShopTitle(): string {
    let title = i18n.t("models.gram.shop") as string;
    if (this.contactShop !== null) {
      title += " : " + this.contactShop.required.storeName;
    }
    return title;
  }

  public get contactShopDescription(): string {
    if (this.contactShop !== null) {
      return this.contactShop.optional.purpose;
    }
    return "";
  }

  /* ===============================================================
  * 行動履歴 書類/DM
  =============================================================== */
  public get contactDmTitle(): string {
    let title = "";
    if (this.subjectType === SUBJECT_TYPE.COMPANY) {
      // 企業 → ユーザ
      title = i18n.t("models.gram.dm") as string;
    } else {
      // ユーザ → 企業
      title = i18n.t("models.gram.sendDocuments") as string;
    }
    if (this.contactDm !== null) {
      title += " : (" + this.contactDm.required.content + ")";
    }

    return title;
  }

  /* ===============================================================
  * 行動履歴 営業訪問
  =============================================================== */
  public get contactVisitTitle(): string {
    let title = i18n.t("models.gram.visit") as string;
    if (this.contactVisit !== null && this.contactVisit.optional.employee) {
      title += "(" + this.contactVisit.optional.employee + ")";
    }
    return title;
  }

  public get contactVisitDescription(): string {
    if (this.contactVisit !== null) {
      return this.contactVisit.optional.purpose;
    }
    return "";
  }

  /* ===============================================================
  * 行動履歴 アプリ通知
  =============================================================== */
  public get contactAppTitle(): string {
    let title = i18n.t("models.gram.appNotification") as string;
    if (this.contactApp !== null) {
      title += "(" + this.contactApp.required.content + ")";
    }
    return title;
  }

  public static build(
    json: ApiRes.Gram,
    conversionDefinitions: ConversionDefinition[],
    globalConversionDefinitions: ConversionDefinition[],
    conversionAttributeDefinitions: ConversionAttributeDefinition[],
    globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[],
    businessEventDefinitions: BusinessEventDefinition[],
    npsDefinitions: NpsDefinition[],
    enqueteDefinitions: EnqueteDefinition[]
  ): Gram {
    const nps = GramNps.fromJson(json.nps_attrs);
    const cvAttrs = this.getConversionAttributes(
      json.cv_attrs,
      conversionAttributeDefinitions
    );
    const globalCvAttrs = this.getGlobalConversionAttributes(
      json.cv_attrs,
      globalConversionAttributeDefinitions
    );
    const bothCvAttrs = cvAttrs.concat(globalCvAttrs);
    return new Gram(
      json.gram_id,
      json.platform_category,
      json.platform_sub_category,
      json.time_sec,
      json.timezone,
      json.gram_type,
      json.gram_sub_type,
      json.subject_type,
      json.navigation_type,
      json.ts_format_type,
      json.cv_id,
      GramPv.fromJson(json.pv),
      GramEvent.fromJson(json.event),
      GramBusinessEvent.fromJson(json.be_attrs),
      GramContactMail.fromJson(json.contact_attrs_mail),
      GramContactShop.fromJson(json.contact_attrs_shop),
      GramContactTel.fromJson(json.contact_attrs_tel),
      GramContactDm.fromJson(json.contact_attrs_dm),
      GramContactVisit.fromJson(json.contact_attrs_visit),
      GramContactApp.fromJson(json.contact_attrs_app),
      nps,
      this.getHasConversion(
        json.cv_id,
        conversionDefinitions,
        globalConversionDefinitions
      ),
      getConversionNameFromConversionId(
        json.cv_id,
        conversionDefinitions,
        globalConversionDefinitions
      ),
      bothCvAttrs,
      this.getBusinessEventTitle(
        json.platform_category,
        json.cv_id,
        businessEventDefinitions
      ),
      this.getNpsEnqtTitle(
        json.platform_category,
        json.platform_sub_category,
        nps,
        json.cv_id,
        npsDefinitions,
        enqueteDefinitions
      ),
      this.getCvType(json.cv_id, globalConversionDefinitions)
    );
  }

  /**
   * コンバージョンを持っているかを取得(Definitionが必要なためconstructorに出しておく)
   */
  static getHasConversion(
    cvId: number,
    conversionDefinitions: ConversionDefinition[],
    globalConversionDefinitions: ConversionDefinition[]
  ): boolean {
    const cv = conversionDefinitions.some(cv => cv.id === cvId);
    if (cv) {
      return true;
    }
    // 通常conversionになければ、global conversionをチェック
    return globalConversionDefinitions.some(cv => cv.id === cvId);
  }

  /**
   * コンバージョン・イベント属性取得(Definitionが必要なためconstructorに出しておく)
   */
  static getConversionAttributes(
    cvAttrs: { id: number; value: string }[],
    conversionAttributeDefinitions: ConversionAttributeDefinition[]
  ): conversionAttribute[] {
    // definitionがあるものだけ表示
    const filteredAttr = cvAttrs.filter(attr =>
      conversionAttributeDefinitions.find(at => at.id === attr.id)
    );

    return filteredAttr.map(attr => {
      const at = conversionAttributeDefinitions.find(at => at.id === attr.id);
      const name = at?.name || "";
      return {
        id: attr.id,
        value: attr.value,
        name: name
      };
    });
  }

  /**
   * グローバルコンバージョン属性取得(Definitionが必要なためconstructorに出しておく)
   * 現行はPush/Linkアプリ起動のみにデータが入る
   */
  static getGlobalConversionAttributes(
    cvAttrs: { id: number; value: string }[],
    globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
  ): conversionAttribute[] {
    const filteredAttr: conversionAttribute[] = [];

    cvAttrs.forEach(attr => {
      const def = globalConversionAttributeDefinitions.find(
        at => at.id === attr.id
      );
      // definitionがある、かつ許可されたqueryの属性だけ表示
      if (
        def !== undefined &&
        isAllowGlobalConversionAttributeQuery(def.query)
      ) {
        filteredAttr.push({
          id: attr.id,
          value: attr.value,
          name: getGlobalConversionAttributeNameByQuery(def.query)
        });
      }
    });
    return filteredAttr;
  }

  /**
   * ビジネスイベント名取得(Definitionが必要なためconstructorに出しておく)
   */
  static getBusinessEventTitle(
    platformCategory: number,
    cvId: number,
    businessEventDefinitions: BusinessEventDefinition[]
  ): string {
    if (platformCategory !== PLATFORM_CATEGORY.BE.ID) {
      return "";
    }
    // ビジネスイベントだがconversion idを使って特定する
    const be = businessEventDefinitions.find(d => d.id === cvId);
    return be?.name || "";
  }

  /**
   * NPS、アンケートタイトル取得(Definitionが必要なためconstructorに出しておく)
   */
  static getNpsEnqtTitle(
    platformCategory: number,
    platformSubCategory: number,
    nps: GramNps | null,
    cvId: number,
    npsDefinitions: NpsDefinition[],
    enqueteDefinitions: EnqueteDefinition[]
  ): string {
    // NPSがのデータがない、もしくはcategoryがロイヤルティでない場合は空を返す
    if (
      nps === null ||
      platformCategory !== PLATFORM_CATEGORY.CLDB.ID ||
      !(
        platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.NPS ||
        platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.ENQT
      )
    ) {
      return "";
    }
    const score = nps.required.score;
    if (platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.NPS) {
      if (0 < npsDefinitions.length) {
        return npsDefinitions[0].name + ":" + score;
      }
    } else if (
      platformSubCategory === PLATFORM_CATEGORY.CLDB.SUB_CATEGORY.ENQT
    ) {
      const e = enqueteDefinitions.find(e => e.id === cvId);
      if (e !== undefined) {
        return e.name + ":" + score;
      }
    }
    return "";
  }
  static getCvType(
    cvId: number,
    globalConversionDefinitions: ConversionDefinition[]
  ): CvType {
    if (
      globalConversionDefinitions.length > 0 &&
      globalConversionDefinitions[0].id === cvId
    ) {
      return CvType.FirstOpen;
    }
    return CvType.Cv;
  }
}

export class GramCvAttribute {
  constructor(public readonly id: number, public readonly value: string) {}
}

export class GramPv {
  constructor(
    public readonly location: GramPvLocation,
    public readonly searchEngine: GramPvSearchEngine | null
  ) {}

  public static fromJson(json: ApiRes.PvGram): GramPv | null {
    if (!json || json === null) {
      return null;
    }
    return new GramPv(
      GramPvLocation.fromJson(json.location),
      GramPvSearchEngine.fromJson(json.search_engine)
    );
  }
}
export class GramPvLocation {
  constructor(
    public readonly name: string,
    public readonly uri: string,
    public readonly referrer: string
  ) {}

  public static fromJson(json: ApiRes.PvGramLocation): GramPvLocation {
    return new GramPvLocation(json.name, json.uri, json.referrer);
  }
}

export class GramPvSearchEngine {
  constructor(public readonly name: string, public readonly keyword: string) {}

  public static fromJson(
    json: ApiRes.PvGramSearchEngine
  ): GramPvSearchEngine | null {
    if (!json || json === null) {
      return null;
    }
    return new GramPvSearchEngine(json.name, json.keyword);
  }
}

export class GramEvent {
  constructor(public readonly id: number, public readonly name: string) {}

  public static fromJson(json: ApiRes.EventGram): GramEvent | null {
    if (!json || json === null) {
      return null;
    }
    return new GramEvent(json.id, json.name);
  }
}

export class GramBusinessEvent {
  constructor(
    public readonly optional: {
      readonly content: string;
    }
  ) {}

  public static fromJson(json: ApiRes.GramBeAttrs): GramBusinessEvent | null {
    if (!json || json === null) {
      return null;
    }
    return new GramBusinessEvent({ content: json.optional.event_content });
  }
}

export class GramContactMail {
  constructor(
    public readonly required: {
      readonly subject: string;
    }
  ) {}

  public static fromJson(
    json: ApiRes.GramContactAttrsMail
  ): GramContactMail | null {
    if (!json || json === null) {
      return null;
    }
    return new GramContactMail({ subject: json.required.title });
  }
}

export class GramContactShop {
  constructor(
    public readonly required: {
      readonly storeName: string;
    },
    public readonly optional: {
      readonly purpose: string;
    }
  ) {}

  public static fromJson(
    json: ApiRes.GramContactAttrsShop
  ): GramContactShop | null {
    if (!json || json === null) {
      return null;
    }
    return new GramContactShop(
      { storeName: json.required.store_name },
      { purpose: json.optional.purpose }
    );
  }
}

export class GramContactTel {
  constructor(
    public readonly required: {
      readonly talkTimeSec: number;
    },
    public readonly optional: {
      readonly content: string;
      readonly response: CONTACT_TEL_RESPONSE_TYPE;
    }
  ) {}

  public static fromJson(
    json: ApiRes.GramContactAttrsTel
  ): GramContactTel | null {
    if (!json || json === null) {
      return null;
    }
    return new GramContactTel(
      { talkTimeSec: json.calculated.talk_time_sec },
      { content: json.optional.content, response: json.optional.response }
    );
  }
}

export class GramContactDm {
  constructor(
    public readonly required: {
      readonly content: string;
    }
  ) {}

  public static fromJson(
    json: ApiRes.GramContactAttrsDm
  ): GramContactDm | null {
    if (!json || json === null) {
      return null;
    }
    return new GramContactDm({ content: json.required.title });
  }
}

export class GramContactVisit {
  constructor(
    readonly optional: {
      readonly employee: string;
      readonly purpose: string;
    }
  ) {}

  public static fromJson(
    json: ApiRes.GramContactAttrsVisit
  ): GramContactVisit | null {
    if (!json || json === null) {
      return null;
    }
    return new GramContactVisit({
      employee: json.optional.employee,
      purpose: json.optional.purpose
    });
  }
}

export class GramContactApp {
  constructor(
    public readonly required: {
      readonly content: string;
    }
  ) {}

  public static fromJson(
    json: ApiRes.GramContactAttrsApp
  ): GramContactApp | null {
    if (!json || json === null) {
      return null;
    }
    return new GramContactApp({ content: json.required.title });
  }
}

export class GramNps {
  constructor(
    public readonly required: {
      readonly score: number;
    },
    public readonly optional: {
      readonly comment: string;
    }
  ) {}

  public static fromJson(json: ApiRes.GramNpsAttrs): GramNps | null {
    if (!json || json === null) {
      return null;
    }
    return new GramNps(
      { score: json.required.score },
      { comment: json.optional.comment }
    );
  }
}
