import { UserTrendNumberData } from "@/models/user-trend/UserTrendNumberData";
import { UserTrendChartPointJson } from "@/api/apis/ApiUserTrend";
import {
  UserTrendScatterXAxisType,
  UserTrendScatterYAxisType
} from "@/const/user-trend";
import { aggregateKey } from "@/models/user-trend/UserTrendRowData";

export class UserTrendChartPoint {
  public x!: number;
  public y!: number;

  constructor(
    public readonly userIds: string[],
    public readonly aggregateKeys: aggregateKey[],
    public readonly data: UserTrendNumberData
  ) {
    this.x = this.data[
      UserTrendScatterXAxisType[UserTrendScatterXAxisType.visit]
    ];
    this.y = this.data[
      UserTrendScatterYAxisType[UserTrendScatterYAxisType.cvInVisit]
    ];
  }

  public updateXAxisValue(xType: UserTrendScatterXAxisType) {
    if (xType === UserTrendScatterXAxisType.count) {
      this.x = this.data.count;
    } else if (xType === UserTrendScatterXAxisType.visit) {
      this.x = this.data.visit;
    } else if (xType === UserTrendScatterXAxisType.uu) {
      this.x = this.data.uniqueUsers;
    }
  }

  public updateYAxisValue(yType: UserTrendScatterYAxisType) {
    this.y = this.data[UserTrendScatterYAxisType[yType]];
  }

  public static fromJson(json: UserTrendChartPointJson): UserTrendChartPoint {
    return new UserTrendChartPoint(
      json.cv_user_ids,
      json.aggregate_keys.map(aggregate_key => {
        return {
          key: aggregate_key.key,
          keyForDisplay: aggregate_key.key.replace(/^http(s?):\/\//i, ""),
          subKey: aggregate_key.sub_key || null
        };
      }),
      UserTrendNumberData.fromJson(json.number_data)
    );
  }
}
