<template>
  <div class="chart-contact-icons">
    <template v-for="contact in sortedContactOverviews">
      <div
        v-if="isVisible(contact)"
        :key="contact.gramId"
        class="chart-contact-icon-wrapper"
        :style="{ left: getX(contact.date.getTime()) + 'px', top: topY + 'px' }"
      >
        <ChartContactIcon
          :contact-icon-type="getContactType(contact)"
          :color="getContactIconColor(contact)"
          @click="onClick(contact)"
          @mouse-over="onMouseOver(contact)"
          @mouse-leave="onMouseLeave"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { ContactOverview } from "@/models/overview/ContactOverview";
import ChartContactIcon from "@/components/chart/ChartContactIcon.vue";
import { groupBy, GroupingResult } from "@/util/list-util";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import {
  CONTACT_ICON_SIZE,
  CONTACT_ICON_Y,
  convertTimeToX,
  getXAdjustWidth,
  isInList,
  getOverviewsMatchedBehind,
  getOverviewsFunnelMatchedBehind
} from "@/components/chart/chart-util";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";

@Component({
  components: {
    ChartContactIcon
  }
})
export default class ChartContactIcons extends Vue {
  @Prop({ type: Date, required: true })
  lastScaleDate!: Date;

  @Prop({ type: Number, required: true })
  lastScaleX!: number;

  @Prop({ type: Date, required: true })
  firstScaleDate!: Date;

  @Prop({ type: Number, required: true })
  baselineLength!: number;

  @Prop({ type: Array, required: true })
  contactOverviews!: ContactOverview[];

  @Prop({ type: Array, required: true })
  filterMatchedIds!: string[];

  @Prop({ type: Number, required: false, default: 0 })
  scrollX!: number;

  @Prop({ type: Number, required: false })
  scrollWidth!: number;

  @Prop({ type: Boolean, required: false, default: true })
  isChartScrollable!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  isInUserDetail!: boolean;

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  onClick(contactOverview: ContactOverview) {
    this.$emit("click", contactOverview);
  }

  onMouseOver(contactOverview: ContactOverview) {
    this.$emit("mouse-over", contactOverview);
  }

  @Emit("mouse-leave")
  onMouseLeave() {}

  topY: number = CONTACT_ICON_Y;

  /**
   * Rearrange ContactOverviews.
   * Put filter matched ContactOverview last
   * to draw filter matched ContactOverview in front.
   */
  get sortedContactOverviews(): ContactOverview[] {
    let nextOverviews: ContactOverview[] = [];
    if (
      this.funnelMatchedSelectedGramIds ||
      (this.funnelMatchedGramIds && this.funnelMatchedGramIds.length > 0)
    ) {
      nextOverviews = getOverviewsFunnelMatchedBehind(
        this.contactOverviews,
        this.funnelMatchedSelectedGramIds,
        this.funnelMatchedGramIds
      );
    }

    nextOverviews =
      nextOverviews.length > 0 ? nextOverviews : this.contactOverviews;

    if (this.filterMatchedIds && this.filterMatchedIds.length > 0) {
      return getOverviewsMatchedBehind(nextOverviews, this.filterMatchedIds);
    } else {
      return nextOverviews;
    }
  }

  getX(unixtime: number): number {
    return getXAdjustWidth(
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        unixtime
      ),
      CONTACT_ICON_SIZE
    );
  }

  get listsGroupedByType(): GroupingResult<ContactOverview> {
    return groupBy(this.contactOverviews, this.getContactType);
  }

  getContactType(contact: ContactOverview): Icons {
    if (contact.type === ContactDefinitionType.TEL) {
      return Icons.Tel;
    }
    if (contact.type === ContactDefinitionType.MAIL) {
      return Icons.Mail;
    }
    return Icons.Shop;
  }

  getContactIconColor(contact: ContactOverview): string {
    if (isInList(contact, this.filterMatchedIds)) {
      return Colors.Base900;
    } else if (isInList(contact, this.funnelMatchedSelectedGramIds)) {
      return Colors.HighlightedBar;
    } else if (isInList(contact, this.funnelMatchedGramIds)) {
      return Colors.SemiHighlightedBar;
    }
    return Colors.Base600;
  }

  isVisible(contact: ContactOverview): boolean {
    if (!this.isChartScrollable || this.isInUserDetail) return true;
    return (
      this.scrollWidth !== null &&
      this.getX(contact.date.getTime()) > this.scrollX - CONTACT_ICON_SIZE &&
      this.getX(contact.date.getTime()) < this.scrollX + this.scrollWidth
    );
  }
}
</script>

<style scoped lang="scss">
.chart-contact-icon-wrapper {
  position: absolute;
  cursor: pointer;
}
</style>
