<i18n src="@/i18n/views/search-condition-balloon.json"></i18n>
<template>
  <div class="searchConditionBalloon">
    <Overlay class="searchConditionBalloon__overlay" @click="onClickClose" />
    <Balloon
      ref="searchFormBalloon"
      class="searchConditionBalloon__form"
      :adjust="arrowPosition"
    >
      <div
        class="searchConditionBalloon__container"
        :class="{ 'searchConditionBalloon__container--hasButton': showButtons }"
        data-cy="search-panel-container"
      >
        <div class="searchConditionBalloon__inner customScrollbar">
          <div v-if="isSelectedTabConditions" data-cy="search-select-forms">
            <div
              v-if="hasManySelectConditionType"
              class="searchConditionBalloon__typeSelect"
            >
              <SelectBox
                data-cy="search-condition_select-box_pulldown"
                :value="selectedFormType"
                :options="formTypeOptions"
                @input="onInputFormType"
              />
              <SelectBox
                v-if="selectedFormType === formTypes.Nps"
                v-model="selectedNpsType"
                class="searchConditionBalloon__npsSelect"
                data-cy="nps-type-select"
                :options="NpsTypeOptions"
                width="260px"
              />
            </div>
            <SelectByConversionForm
              v-if="selectedFormType === formTypes.Conversion"
              :condition="conversionCondition"
              :conversion-definitions="conversionDefinitions"
              @input="onInputSelectCondition"
            />
            <SelectByBusinessEventForm
              v-if="selectedFormType === formTypes.BusinessEvent"
              :condition="businessEventCondition"
              :business-event-definitions="businessEventDefinitions"
              @input="onInputSelectCondition"
            />
            <SelectByContactForm
              v-if="selectedFormType === formTypes.Contact"
              :condition="contactCondition"
              :contact-definitions="contactDefinitions"
              @input="onInputSelectCondition"
            />
            <SelectByNpsForm
              v-if="
                selectedFormType === formTypes.Nps &&
                  selectedNpsType === npsTypes.Score
              "
              :condition="npsCondition"
              :nps-definitions="npsDefinitions"
              @input="onInputSelectCondition"
            />
            <SelectByNpsChangeForm
              v-if="
                selectedFormType === formTypes.Nps &&
                  selectedNpsType === npsTypes.Change
              "
              :condition="npsChangeCondition"
              :nps-definitions="npsDefinitions"
              @input="onInputSelectCondition"
            />
            <SelectByAppForm
              v-if="selectedFormType === formTypes.App"
              :condition="appCondition"
              :conversion-definitions="globalConversionDefinitions"
              @input="onInputSelectCondition"
            />
            <AdditionalConditionEditor
              v-if="hasAdditionalCondition && !isAdditionalConditionDisabled"
              :condition="conditionForAdditionalConditionEditor"
              :conversion-attribute-definitions="conversionAttributeDefinitions"
              :nps-definitions="npsDefinitions"
              :enquete-definitions="enqueteDefinitions"
              :business-index-definitions="businessIndexDefinitions"
              :user-attribute-definitions="userAttributeDefinitions"
              :business-event-definitions="businessEventDefinitions"
              :contact-definitions="contactDefinitions"
              @input="onInputSelectCondition"
            />
          </div>

          <ServiceIdForm
            v-else-if="isSelectedTabServiceId"
            v-model="serviceIdCondition"
          />

          <div v-else-if="isSelectedTabRepeat">
            <SelectByEngagementForm
              v-model="engagementCondition"
              :conversion-definitions="conversionDefinitions"
              :event-definitions="eventDefinitions"
              :site-list="measurementTargetSites"
              :field-type="engagementFormFieldType"
              @input="onInputSelectCondition"
            />
            <AdditionalConditionEditor
              v-if="conversionAttributeDefinitions.length > 0"
              :condition="engagementCondition"
              :conversion-attribute-definitions="conversionAttributeDefinitions"
              :nps-definitions="npsDefinitions"
              :enquete-definitions="enqueteDefinitions"
              :business-index-definitions="businessIndexDefinitions"
              :user-attribute-definitions="userAttributeDefinitions"
              :business-event-definitions="businessEventDefinitions"
              :contact-definitions="contactDefinitions"
              :is-repeat-tab="isSelectedTabRepeat"
              @input="onInputSelectCondition"
            />
          </div>

          <SearchFeaturedUsers
            v-else-if="isSelectedTabFeaturedUsers"
            @change="onChangeFeaturedUsersUrl"
          />

          <SearchHistoryEntry
            v-for="searchHistory in searchHistories"
            v-else-if="isSelectedTabHistory"
            :key="searchHistory.id"
            :history="searchHistory"
            @click="onClickHistory"
          >
            <SelectConditionLabel
              :select-condition="searchHistory.selectCondition"
            />
          </SearchHistoryEntry>
          <div class="searchConditionBalloon__header">
            <SearchHeaderTab
              v-model="selectedTab"
              class="searchConditionBalloon__tab"
              :options="tabOptions"
            />
          </div>
        </div>
      </div>
      <div v-show="showButtons" class="searchConditionBalloon__buttonContainer">
        <Button
          v-t="'search'"
          class="searchConditionBalloon__button searchConditionBalloon__searchButton"
          data-cy="button-search"
          :disabled="isSubmitButtonDisabled"
          @click="search"
        />
        <Button
          v-t="'cancel'"
          class="searchConditionBalloon__button searchConditionBalloon__cancelButton"
          data-cy="button-search-cancel"
          type="light"
          @click="onClickClose"
        />
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import {
  overwriteDatesIfOutdated,
  SelectCondition,
  validationCheckForSelectionHistory
} from "@/models/search/select-condition/SelectCondition";
import {
  SelectByServiceIdCondition,
  maxServiceIdNumber,
  MAX_CHARACTERS_SERVICEID
} from "@/models/search/select-condition/SelectByServiceIdCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import { SelectByNpsChangeCondition } from "@/models/search/select-condition/SelectByNpsChangeCondition";
import {
  containsAppFirstOpenConversion,
  SelectByAppCondition
} from "@/models/search/select-condition/SelectByAppCondition";
import {
  SelectByEngagementCondition,
  FieldType
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";
import Button from "@/components/Button.vue";
import Balloon from "@/components/Balloon.vue";
import Overlay from "@/components/Overlay.vue";
import SearchHeaderTab from "@/components/search/SearchHeaderTab.vue";
import ServiceIdForm from "@/components/search/ServiceIdForm.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import AdditionalConditionEditor from "@/components/search/AdditionalConditionEditor.vue";
import SelectByConversionForm from "@/components/search/SelectByConversionForm.vue";
import SelectByEngagementForm from "@/components/search/SelectByEngagementForm.vue";
import SelectByBusinessEventForm from "@/components/search/SelectByBusinessEventForm.vue";
import SearchHistoryEntry from "@/components/search/SearchHistoryEntry.vue";
import SelectByContactForm from "@/components/search/SelectByContactForm.vue";
import SelectByNpsForm from "@/components/search/SelectByNpsForm.vue";
import SelectByNpsChangeForm from "@/components/search/SelectByNpsChangeForm.vue";
import SelectByAppForm from "@/components/search/SelectByAppForm.vue";

import SearchFeaturedUsers from "@/views/SearchFeaturedUsers.vue";

import { TabType, FormType, NpsFormType } from "@/store/modules/searchForm";

import { TabOption } from "@/components/search/TabOption";
import { SearchHistory } from "@/models/search/SearchHistory";
import {
  ConversionDefinition,
  GlobalConversionDefinition
} from "@/models/client-settings/ConversionDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { handleNoQueryCacheError } from "@/util/error-util";
import { showAlert } from "@/util/modal-util";
import {
  HistoryDateValidationResult,
  ValidationResult
} from "@/models/search/ValidationResult";
import SelectConditionLabel from "@/views/SelectConditionLabel.vue";
import { UgTag, UgEventTag, UgAttributeTag } from "@/store/modules/ugTag";
import { addHidToUsersUrl } from "@/router";

const SEARCH_NAVIGATION_MIN_WIDTH: number = 540;

export interface SearchNavigationStyle {
  width: number;
  left: number;
}

@Component({
  components: {
    Button,
    Balloon,
    Overlay,
    SearchHeaderTab,
    ServiceIdForm,
    SelectBox,
    AdditionalConditionEditor,
    SelectByConversionForm,
    SelectByBusinessEventForm,
    SelectByContactForm,
    SelectByNpsForm,
    SelectByNpsChangeForm,
    SelectByAppForm,
    SelectByEngagementForm,
    SearchHistoryEntry,
    SelectConditionLabel,
    SearchFeaturedUsers
  }
})
export default class SearchConditionBalloon extends Vue {
  @Emit("click-close")
  onClickClose() {}

  @Prop({ type: Boolean, default: false })
  showSelectCondition!: boolean;

  @Prop({ type: Boolean, default: false })
  showSearchForm!: boolean;

  @Prop({ type: Object, required: true })
  searchNavigation!: SearchNavigationStyle;

  formTypes = FormType;
  npsTypes = NpsFormType;

  conversionDefinitions: ConversionDefinition[] = this.$store.state
    .clientSettings.activeConversionDefinitions;
  conversionAttributeDefinitions: ConversionAttributeDefinition[] = this.$store
    .state.clientSettings.activeConversionAttributeDefinitions;
  npsDefinitions: NpsDefinition[] = this.$store.state.clientSettings
    .activeNpsDefinitions;
  enqueteDefinitions: EnqueteDefinition[] = this.$store.state.clientSettings
    .activeEnqueteDefinitions;
  businessIndexDefinitions: BusinessIndexDefinition[] = this.$store.state
    .clientSettings.activeBusinessIndexDefinitions;
  userAttributeDefinitions: UserAttributeDefinition[] = this.$store.state
    .clientSettings.activeUserAttributeDefinitions;
  eventDefinitions: EventDefinition[] = this.$store.state.clientSettings
    .activeEventDefinitions;
  businessEventDefinitions: BusinessEventDefinition[] = this.$store.state
    .clientSettings.activeBusinessEventDefinitions;
  contactDefinitions: ContactDefinition[] = this.$store.state.clientSettings
    .activeContactDefinitions;
  globalConversionDefinitions: GlobalConversionDefinition[] = this.$store
    .getters["system/activeGlobalConversionDefinitions"];

  featuredUsersUrl = "";

  // Adjust return result with `-5` because the arrow is not exactly centered
  get arrowPosition(): number {
    if (
      this.searchNavigation.width > SEARCH_NAVIGATION_MIN_WIDTH &&
      !this.showSelectCondition
    ) {
      return -5;
    }

    if (this.showSearchForm) {
      const searchFormBalloon = (this.$refs
        .searchFormBalloon as Vue).$el.getBoundingClientRect();

      let searchNavigationCenter = this.searchNavigation.width / 2;

      if (this.showSelectCondition) {
        searchNavigationCenter = this.searchNavigation.width / 4;
      }

      const leftDifference =
        this.searchNavigation.left - searchFormBalloon.left;
      const searchFormBalloonCenter = searchFormBalloon.width / 2;

      return (
        searchNavigationCenter + leftDifference - searchFormBalloonCenter - 5
      );
    }

    return -5;
  }

  // タブの種類
  get tabOptions(): TabOption[] {
    return [
      {
        id: TabType.Conditions,
        label: this.hasManySelectConditionType
          ? (this.$t("searchCondition") as string)
          : (this.$t("conversion") as string),
        isNew: false
      },
      {
        id: TabType.ServiceId,
        label: this.$t("serviceId") as string,
        isNew: false
      },
      { id: TabType.Repeat, label: this.$t("repeat") as string, isNew: false },
      {
        id: TabType.FeaturedUsers,
        label: this.$t("featuredUsers") as string,
        isNew: false
      },
      {
        id: TabType.History,
        label: this.$t("searchHistory") as string,
        isNew: false
      }
    ];
  }

  // 選択中のタブ（条件/サービスID/行動増減ユーザ/履歴）
  get selectedTab(): TabOption {
    const filteredOption = this.tabOptions.filter(
      opt => opt.id === this.$store.state.searchForm.currentTabType
    );
    if (filteredOption.length > 0) {
      return filteredOption[0];
    }
    return this.tabOptions[0];
  }
  set selectedTab(option: TabOption) {
    this.$store.commit("searchForm/setCurrentTabType", option.id);
  }

  // Formのタイプ（コンバージョン、ビジスネイベント等）
  get selectedFormType(): FormType {
    return this.$store.state.searchForm.currentFormType;
  }
  set selectedFormType(formType: FormType) {
    this.$store.commit("searchForm/setCurrentFormType", formType);
  }

  // NPSのタイプ（スコアか変化）
  get selectedNpsType(): NpsFormType {
    return this.$store.state.searchForm.currentNpsFormType;
  }
  set selectedNpsType(npsType: NpsFormType) {
    this.$store.commit("searchForm/setCurrentNpsFormType", npsType);
  }

  // 各選定フォームの検索条件
  get conversionCondition(): SelectByConversionCondition {
    return this.$store.state.searchForm.conversionCondition;
  }
  set conversionCondition(condition: SelectByConversionCondition) {
    this.$store.commit("searchForm/setSelectByConversionCondition", condition);
  }

  get businessEventCondition(): SelectByBusinessEventCondition {
    return this.$store.state.searchForm.businessEventCondition;
  }
  set businessEventCondition(condition: SelectByBusinessEventCondition) {
    this.$store.commit(
      "searchForm/setSelectByBusinessEventCondition",
      condition
    );
  }

  get contactCondition(): SelectByContactCondition {
    return this.$store.state.searchForm.contactCondition;
  }
  set contactCondition(condition: SelectByContactCondition) {
    this.$store.commit("searchForm/setSelectByContactCondition", condition);
  }

  get npsCondition(): SelectByNpsCondition {
    return this.$store.state.searchForm.npsCondition;
  }
  set npsCondition(condition: SelectByNpsCondition) {
    this.$store.commit("searchForm/setSelectByNpsCondition", condition);
  }

  get npsChangeCondition(): SelectByNpsChangeCondition {
    return this.$store.state.searchForm.npsChangeCondition;
  }
  set npsChangeCondition(condition: SelectByNpsChangeCondition) {
    this.$store.commit("searchForm/setSelectByNpsChangeCondition", condition);
  }

  get engagementCondition(): SelectByEngagementCondition {
    return this.$store.state.searchForm.engagementCondition;
  }
  set engagementCondition(condition: SelectByEngagementCondition) {
    this.$store.commit("searchForm/setSelectByEngagementCondition", condition);
  }

  get serviceIdCondition(): SelectByServiceIdCondition {
    return this.$store.state.searchForm.serviceIdCondition;
  }
  set serviceIdCondition(condition: SelectByServiceIdCondition) {
    this.$store.commit("searchForm/setSelectByServiceIdCondition", condition);
  }

  get appCondition(): SelectByAppCondition {
    return this.$store.state.searchForm.appCondition;
  }
  set appCondition(condition: SelectByAppCondition) {
    this.$store.commit("searchForm/setSelectByAppCondition", condition);
  }

  get isSelectedTabConditions(): boolean {
    return this.selectedTab.id === TabType.Conditions;
  }

  get isSelectedTabServiceId(): boolean {
    return this.selectedTab.id === TabType.ServiceId;
  }

  get isSelectedTabRepeat(): boolean {
    return this.selectedTab.id === TabType.Repeat;
  }

  get isSelectedTabFeaturedUsers(): boolean {
    return this.selectedTab.id === TabType.FeaturedUsers;
  }

  get isSelectedTabHistory(): boolean {
    return this.selectedTab.id === TabType.History;
  }

  get hasAdditionalCondition(): boolean {
    return (
      this.conversionAttributeDefinitions.length > 0 ||
      this.npsDefinitions.length > 0 ||
      this.enqueteDefinitions.length > 0 ||
      this.businessIndexDefinitions.length > 0 ||
      this.userAttributeDefinitions.length > 0 ||
      this.businessEventDefinitions.length > 0 ||
      this.contactDefinitions.length > 0
    );
  }

  get selectByAppForm(): SelectByAppForm {
    return this.$refs.selectByAppForm as SelectByAppForm;
  }

  get isAdditionalConditionDisabled(): boolean {
    return (
      this.selectedFormType === this.formTypes.App &&
      containsAppFirstOpenConversion(
        this.appCondition.conversionDefinitionIds,
        this.globalConversionDefinitions
      )
    );
  }

  get showButtons(): boolean {
    return (
      this.isSelectedTabConditions ||
      this.isSelectedTabServiceId ||
      this.isSelectedTabRepeat ||
      this.isSelectedTabFeaturedUsers
    );
  }

  get isBusinessEventSelectable(): boolean {
    return (
      this.$store.state.auth.user.permission.isAvailableNaviBusinessEvent &&
      this.businessEventDefinitions.length > 0
    );
  }

  get isContactSelectable(): boolean {
    return (
      this.$store.state.auth.user.permission.isAvailableNaviActHistory &&
      this.contactDefinitions.length > 0
    );
  }

  get isNpsSelectable(): boolean {
    return (
      this.$store.state.auth.user.permission.isAvailableNaviLoyalty &&
      this.npsDefinitions.length > 0
    );
  }

  get hasManySelectConditionType(): boolean {
    return (
      this.isBusinessEventSelectable ||
      this.isContactSelectable ||
      this.isNpsSelectable ||
      this.$store.state.client.client.isContractApp
    );
  }

  get formTypeOptions(): SelectOption[] {
    const options: SelectOption[] = [];
    if (this.$store.state.auth.user.permission.isAvailableNaviCv) {
      options.push({
        label: this.$t("conversion") as string,
        value: FormType.Conversion,
        disabled: false
      });
    }
    if (this.$store.state.client.client.isContractApp) {
      options.push({
        label: this.$t("app") as string,
        value: FormType.App,
        disabled: false
      });
    }
    if (this.isBusinessEventSelectable) {
      options.push({
        label: this.$t("businessEvent") as string,
        value: FormType.BusinessEvent,
        disabled: false
      });
    }
    if (this.isContactSelectable) {
      options.push({
        label: this.$t("activityHistory") as string,
        value: FormType.Contact,
        disabled: false
      });
    }
    if (this.isNpsSelectable) {
      options.push({
        label: this.$t("customerLoyalty") as string,
        value: FormType.Nps,
        disabled: false
      });
    }
    return options;
  }

  get NpsTypeOptions(): SelectOption[] {
    return [
      {
        label: this.$t("usersOfSpecificScore") as string,
        value: NpsFormType.Score,
        disabled: false
      },
      {
        label: this.$t("userScoreHasChanged") as string,
        value: NpsFormType.Change,
        disabled: false
      }
    ];
  }

  get conditionForAdditionalConditionEditor():
    | SelectByConversionCondition
    | SelectByAppCondition
    | SelectByBusinessEventCondition
    | SelectByContactCondition
    | SelectByNpsCondition
    | SelectByNpsChangeCondition {
    if (this.selectedFormType === FormType.BusinessEvent) {
      return this.businessEventCondition;
    } else if (this.selectedFormType === FormType.Contact) {
      return this.contactCondition;
    } else if (
      this.selectedFormType === FormType.Nps &&
      this.selectedNpsType === NpsFormType.Score
    ) {
      return this.npsCondition;
    } else if (
      this.selectedFormType === FormType.Nps &&
      this.selectedNpsType === NpsFormType.Change
    ) {
      return this.npsChangeCondition;
    } else if (this.selectedFormType === FormType.App) {
      return this.appCondition;
    } else {
      return this.conversionCondition;
    }
  }

  get searchHistories(): SearchHistory[] {
    return this.$store.getters["searchHistory/searchHistories"];
  }

  get measurementTargetSites(): MeasurementTargetSite[] {
    return this.$store.getters["clientSettings/activeMeasurementSites"];
  }

  get canUseWebdataFeaturesAndIsContractApp(): boolean {
    return (
      this.$store.state.app.canUseWebdataFeatures &&
      this.$store.state.client.client.isContractApp
    );
  }

  get isContractApp(): boolean {
    return this.$store.state.client.client.isContractApp;
  }

  get isMaxCharactersServiceId(): boolean {
    return this.serviceIdCondition.serviceIds.some(
      serviceId => serviceId.length > MAX_CHARACTERS_SERVICEID
    );
  }

  get disableServiceIdSearch(): boolean {
    const max: number = maxServiceIdNumber;
    const serviceIds = this.serviceIdCondition.serviceIds.length;

    if (
      (this.isSelectedTabServiceId && (serviceIds > max || serviceIds === 0)) ||
      this.isMaxCharactersServiceId
    ) {
      return true;
    }

    return false;
  }

  get isSubmitButtonDisabled(): boolean {
    return (
      this.disableServiceIdSearch ||
      this.disableFeaturedUsers ||
      this.disableConditionSearch
    );
  }

  get disableConditionSearch(): boolean {
    if (!this.isSelectedTabConditions) {
      return false;
    }

    const searchCondition = this.conditionForAdditionalConditionEditor;

    if (searchCondition instanceof SelectByNpsCondition) {
      if (this.npsDefinitions.length <= 0) {
        return false;
      } else {
        return !searchCondition.validate(this.npsDefinitions[0]).isValid;
      }
    } else {
      return !searchCondition.validate.isValid;
    }
  }

  get engagementFormFieldType(): FieldType {
    if (this.canUseWebdataFeaturesAndIsContractApp) {
      return FieldType.All;
    } else if (this.isContractApp) {
      return FieldType.AppOnly;
    }

    return FieldType.WebOnly;
  }

  get disableFeaturedUsers(): boolean {
    return this.isSelectedTabFeaturedUsers && this.featuredUsersUrl === "";
  }

  onInputFormType(type: FormType) {
    this.selectedFormType = type;
  }

  onInputSelectCondition(condition: SelectCondition) {
    this.$store.dispatch("searchForm/setCondition", condition);
  }

  onClickHistory(condition: SelectCondition) {
    /**
     * To validate if the date from search history is invlaid
     * - startDate and endDate will update automatically
     * - User will see alert message of updated date
     */
    if (
      condition instanceof SelectByConversionCondition ||
      condition instanceof SelectByAppCondition ||
      condition instanceof SelectByBusinessEventCondition ||
      condition instanceof SelectByContactCondition
    ) {
      if (condition.startDate !== null && condition.endDate !== null) {
        const validationResult: HistoryDateValidationResult = validationCheckForSelectionHistory(
          condition.startDate,
          condition.endDate
        );

        if (!validationResult.isValid) {
          condition = overwriteDatesIfOutdated(
            validationResult.startDate,
            validationResult.endDate,
            condition
          );
          showAlert(validationResult.errorMessage as string);
        }
      }
    }
    this.$store.dispatch("searchForm/setCondition", condition);
    this.$store.dispatch("searchForm/setDisplayFormFromCondition", condition);
  }

  search() {
    let condition: SelectCondition;

    // 検索ボタンを押したタイミングで、検索のオンボーディングはもう表示させない
    this.$store.commit("preference/setNoMoreShowSearchOnboarding", true);

    if (this.isSelectedTabConditions) {
      if (this.selectedFormType === FormType.BusinessEvent) {
        condition = this.businessEventCondition;
      } else if (this.selectedFormType === FormType.Contact) {
        condition = this.contactCondition;
      } else if (
        this.selectedFormType === FormType.Nps &&
        this.selectedNpsType === NpsFormType.Score
      ) {
        condition = this.npsCondition;
      } else if (
        this.selectedFormType === FormType.Nps &&
        this.selectedNpsType === NpsFormType.Change
      ) {
        condition = this.npsChangeCondition;
      } else if (this.selectedFormType === FormType.App) {
        condition = this.appCondition;
      } else {
        condition = this.conversionCondition;
      }
    } else if (this.isSelectedTabServiceId) {
      condition = this.serviceIdCondition;
    } else if (this.isSelectedTabFeaturedUsers) {
      this.moveToFeaturedUsersResult();
      return;
    } else {
      condition = this.engagementCondition;
    }

    // バリデーション
    let validationResult: ValidationResult;
    // SelectByNpsCondtionだけは引数としてnpsDefinitions必要なので、関数を呼ぶ
    if (condition instanceof SelectByNpsCondition) {
      if (this.npsDefinitions.length <= 0) {
        showAlert(this.$t("notFoundCustomerLoyaltySetting") as string);
        return;
      }
      validationResult = condition.validate(this.npsDefinitions[0]);
    } else {
      validationResult = condition.validate;
    }

    if (
      !validationResult.isValid &&
      validationResult.errorMessage !== undefined
    ) {
      showAlert(validationResult.errorMessage);
      return;
    }

    if (this.isSelectedTabConditions || this.isSelectedTabServiceId) {
      UgTag.pushEvent(UgEventTag.Search, {
        [UgAttributeTag.SearchType]: this.searchType,
        [UgAttributeTag.Condition]: this.getLabelStringFromSelectConditionLabel(
          condition
        )
      });
    }

    if (condition instanceof SelectByEngagementCondition) {
      UgTag.pushEvent(UgEventTag.RepeatSearch);
    }

    this.$store
      .dispatch("search/executeSelect", {
        selectCondition: condition,
        setHistoryIdToUrl: addHidToUsersUrl(this.$router)
      })
      .catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });

    let query: { [key: string]: string } = {
      view: this.$store.state.preference.searchResultView
    };
    this.$router.push({ name: "users", query });
    this.onClickClose();
  }

  getLabelStringFromSelectConditionLabel(condition: SelectCondition) {
    const SelectConditionLabelComponentClass = Vue.extend(SelectConditionLabel);
    const instance = new SelectConditionLabelComponentClass({
      propsData: { selectCondition: condition },
      parent: this
    });
    instance.$mount();
    return (instance as SelectConditionLabel).displayLabelInString;
  }

  get searchType(): string {
    if (this.isSelectedTabServiceId) {
      return "ServiceId";
    }
    return FormType[this.selectedFormType];
  }

  onChangeFeaturedUsersUrl(url: string) {
    this.featuredUsersUrl = url;
  }

  moveToFeaturedUsersResult() {
    this.pushFeaturedUsersUgTags(this.featuredUsersUrl);
    if (
      this.featuredUsersUrl.startsWith("/almost-cv") ||
      this.featuredUsersUrl.startsWith("/lagcv")
    ) {
      const origin = window.location.origin;
      window.open(origin + this.featuredUsersUrl, "_self");
    } else {
      this.$router.push(this.featuredUsersUrl);
    }
    this.onClickClose();
  }

  pushFeaturedUsersUgTags(url: string) {
    if (/^\/almost-cv/.test(url)) {
      UgTag.pushEvent(UgEventTag.AlmostCvSearchPanel);
    } else if (/^\/lagcv\//.test(url)) {
      UgTag.pushEvent(UgEventTag.LagCvSearchPanel);
    }
  }
}
</script>

<style lang="scss" scoped>
.searchConditionBalloon__form {
  position: fixed;
  top: calc(#{$headerHeight} + 15px);
  right: 0;
  left: 0;
  margin: auto;
  max-height: $headerSearchPanelHeight;
  width: $headerSearchPanelWidth;
  height: 100%;
}

.searchConditionBalloon__container {
  padding: 85px 15px 15px;
  height: 100%;
}

.searchConditionBalloon__container--hasButton {
  padding-bottom: $headerSearchPanelButtonsHeight;
}

.searchConditionBalloon__inner {
  overflow-y: auto;
  padding: 10px 15px 0;
  height: 100%;
}

.searchConditionBalloon__typeSelect {
  margin-bottom: 30px;
}

.searchConditionBalloon__npsSelect {
  margin-left: 20px;
}

.searchConditionBalloon__buttonContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 30px;
  width: 100%;
  height: $headerSearchPanelButtonsHeight;
  border-radius: 0 0 $sizeRadius $sizeRadius;
  background-color: $colorWhite;
}

.searchConditionBalloon__button {
  flex: 1;
}
.searchConditionBalloon__searchButton {
  margin-right: 15px;
}
.searchConditionBalloon__cancelButton {
  margin-left: 15px;
}
.searchConditionBalloon__header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 30px 0;
  width: 100%;
}
</style>
