import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";

/**
 * アンケート Overview
 *
 * アンケートとしてOMOインポートしたグラムを表す。
 * アンケートとは、個別の商品やサービスに対するロイヤルティのスコアのこと
 */
export class EnqueteOverview {
  public constructor(
    // グラムのID
    public readonly gramId: string,
    // アンケートを実施した時間
    public readonly date: Date,
    // アンケートのスコア
    public readonly score: number,
    // アンケートのコメント
    public readonly comment: string,
    // アンケートの定義
    public readonly definition: EnqueteDefinition
  ) {}
}
