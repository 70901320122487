import { GramOverviews } from "@/models/overview/Overviews";
import { ApiRes } from "@/api/api-res";
import { Device } from "./Device";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { BusinessEventDefinition } from "./client-settings/BusinessEventDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { i18n } from "@/i18n";
import { NpsDefinition } from "./client-settings/NpsDefinition";
import { getHashedString, insertCommaDelimiter } from "@/util/string-util";
import {
  BusinessIndexDefinitionType,
  BusinessIndexDefinition
} from "@/models/client-settings/BusinessIndexDefinition";
import { usagePeriod } from "@/util/date-util";
import { UserSummary } from "@/models/user-summary/UserSummary";
import { getCurrentDate } from "@/util/date-util";

export interface UserMemo {
  userId: string;
  memo: Memo;
  isMemoPage: boolean;
}

/**
 * サービスID種別
 */
export enum ServiceIdType {
  NotSpecified = -1,
  Temporary = 0,
  Fixed = 1
}

/**
 * Usergramのユーザのモデル
 */
export class User {
  constructor(
    public readonly id: string,
    public readonly serviceId: string,
    public readonly serviceIdType: ServiceIdType,
    public readonly overviews: GramOverviews,
    public readonly device: Device,
    public readonly memo: Memo,
    public readonly conversion: Conversion,
    public readonly attributes: UserAttribute[],
    public readonly businessIndexes: BusinessIndex[],
    public readonly createdAt: Date,
    public readonly createdAtTimezoneOffset: number,
    public readonly summary?: UserSummary,
    public readonly nps?: Nps,
    public readonly previousNps?: Nps
  ) {}

  public static build(
    json: ApiRes.User,
    conversionDefinitions: ConversionDefinition[],
    npsDefinitions: NpsDefinition[],
    businessEventDefinitions: BusinessEventDefinition[],
    enqueteDefinitions: EnqueteDefinition[],
    businessIndexDefinitions: BusinessIndexDefinition[],
    canUseWebdataFeaturesAndIsContractApp: boolean
  ): User {
    return new User(
      json.user_id,
      json.service_id,
      json.service_id_type,
      GramOverviews.build(
        json,
        conversionDefinitions,
        npsDefinitions,
        businessEventDefinitions,
        enqueteDefinitions
      ),
      Device.build(json.platforms, canUseWebdataFeaturesAndIsContractApp),
      Memo.fromJson(json.memo),
      Conversion.fromJson(json.cv),
      UserAttribute.fromJson(json.user_attrs),
      BusinessIndex.fromJson(json.biz_indexes, businessIndexDefinitions),
      new Date(json.profile_created_time_sec * 1000),
      json.profile_created_timezone,
      UserSummary.build(json.summary),
      json.nps === null || json.nps === undefined
        ? undefined
        : new Nps(
            json.nps.curr_val,
            json.nps.curr_comment,
            new Date(json.nps.curr_usec / 1000)
          ),
      json.nps === null || json.nps === undefined
        ? undefined
        : new Nps(
            json.nps.prev_val,
            json.nps.prev_comment,
            new Date(json.nps.prev_usec / 1000)
          )
    );
  }

  /**
   * 表示用サービスIDを返す
   */
  get serviceIdForDisplay(): string {
    return getDisplayId(this.serviceIdType, this.serviceId, this.id);
  }

  get isFixedServiceId(): boolean {
    return isFixedServiceId(this.serviceIdType);
  }

  public updateMemo(memo: Memo): User {
    return new User(
      this.id,
      this.serviceId,
      this.serviceIdType,
      this.overviews,
      this.device,
      memo,
      this.conversion,
      this.attributes,
      this.businessIndexes,
      this.createdAt,
      this.createdAtTimezoneOffset,
      this.summary,
      this.nps,
      this.previousNps
    );
  }
}

/**
 * 表示用のIDを返す
 * UserInfoでも必要なので関数を切り出してます
 */
export function getDisplayId(
  serviceIdType: ServiceIdType,
  serviceId: string,
  userId: string
): string {
  if (serviceIdType === ServiceIdType.Fixed) {
    return serviceId;
  }

  if (serviceIdType === ServiceIdType.Temporary) {
    // 後方互換のために、serviceIdTypeがTemporaryでも
    // serviceIdに値がある場合は旧表示Id生成ロジックのIdを返す
    if (serviceId !== "") {
      const subSid = Number(
        serviceId
          .split("_")
          .join("")
          .substring(6)
      );
      return subSid.toString(36);
    }
    // ハッシュ化したユーザID表示する
    // 旧ロジックと見た目をあわせるために先頭10文字を表示
    return getHashedString(userId).substr(0, 10);
  }

  return i18n.t("models.getServiceId.analyzing") as string;
}

/**
 * サービスIDが決定済みかを返す
 * UserInfoでも必要なので関数を切り出してます
 */
export function isFixedServiceId(serviceIdType: ServiceIdType): boolean {
  return serviceIdType === ServiceIdType.Fixed;
}

enum CustomAttributeFormat {
  string = 1
}

/**
 * ユーザ属性
 */
export class UserAttribute {
  constructor(
    public readonly id: number,
    public readonly format: CustomAttributeFormat,
    public readonly value: string,
    public readonly updatedAt: Date
  ) {}

  public static fromJson(json: ApiRes.UserAttributes): UserAttribute[] {
    return Object.keys(json).map(key => {
      const attr = json[key];
      return new UserAttribute(
        attr.id,
        attr.format_type,
        attr.val,
        new Date(attr.usec / 1000)
      );
    });
  }
}

/**
 * ビジネス指標のstatus
 */
export enum BusinessIndexUsageStatus {
  endOfUse = 1,
  inUse = 0
}

/**
 * ビジネス指標
 *
 * statusには、利用継続中か利用が終わってるかが入っている
 */
export class BusinessIndex {
  constructor(
    public readonly id: number,
    public readonly type: BusinessIndexDefinitionType,
    public readonly value: number,
    public readonly updatedAt: Date,
    public readonly usageStatus: BusinessIndexUsageStatus,
    public readonly startDate: Date,
    public readonly endDate: Date
  ) {}

  public static fromJson(
    json: ApiRes.BusinessIndexes,
    businessIndexDefinitions: BusinessIndexDefinition[]
  ): BusinessIndex[] {
    return Object.keys(json).map(key => {
      const be = json[key];
      const def = businessIndexDefinitions.find(def => def.id === be.id);
      const type = def?.type || (0 as BusinessIndexDefinitionType);
      return new BusinessIndex(
        be.id,
        type,
        be.val,
        new Date(be.usec / 1000),
        be.status,
        be.start_time_usec !== null
          ? new Date(be.start_time_usec / 1000)
          : getCurrentDate(),
        be.end_time_usec !== null
          ? new Date(be.end_time_usec / 1000)
          : getCurrentDate()
      );
    });
  }

  public static valueForDisplay(businessIndex: BusinessIndex): string {
    if (businessIndex.type === BusinessIndexDefinitionType.TOTAL_PAYMENT) {
      // 金額は3桁カンマ区切り
      return insertCommaDelimiter(businessIndex.value);
    } else if (
      businessIndex.type === BusinessIndexDefinitionType.TOTAL_PURCHASE_COUNT
    ) {
      // 回数はそのままの値を使う
      return String(businessIndex.value);
    } else if (
      businessIndex.type === BusinessIndexDefinitionType.TOTAL_USE_PERIOD
    ) {
      // 期間の場合は期間を算出する
      const isInUse =
        businessIndex.usageStatus === BusinessIndexUsageStatus.inUse;

      // 利用中なら現在の日付、利用が終わっていたらendDateから利用期間を求める
      const endDate = isInUse ? getCurrentDate() : businessIndex.endDate;
      return usagePeriod(businessIndex.startDate, endDate);
    }
    return "";
  }
}

/**
 * Npsのスコア、コメント
 */
export class Nps {
  constructor(
    public readonly value: number,
    public readonly comment: string,
    public readonly date: Date
  ) {}
}

/**
 * ユーザのメモ
 *
 * メモがない場合は、contentに空文字列、timestampとして0が渡される
 */
export class Memo {
  constructor(public content: string, public updatedDate: Date) {}

  public static fromJson(json: ApiRes.Memo): Memo {
    return new Memo(json.content, new Date(json.updated_time_msec));
  }
}

/**
 * コンバージョンのモデル
 */
export class Conversion {
  constructor(
    public readonly name: string,
    public readonly date: Date,
    public readonly timezone: number,
    public readonly conversionAttributes: ConversionAttribute[]
  ) {}

  public static fromJson(json: ApiRes.UserConversion): Conversion {
    return new Conversion(
      json.cv_name,
      new Date(json.cv_time_sec * 1000),
      json.cv_timezone,
      ConversionAttribute.fromJson(json.cv_attributes)
    );
  }
}

/**
 * コンバージョン属性のモデル
 */
export class ConversionAttribute {
  constructor(public id: number, public value: string) {}

  public static fromJson(
    json: ApiRes.UserConversionAttributes
  ): ConversionAttribute[] {
    return Object.keys(json).map(key => {
      return new ConversionAttribute(Number(key), json[key]);
    });
  }
}
