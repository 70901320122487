<i18n src="@/i18n/components/search/select-by-engagement-form.json"></i18n>
<template>
  <div class="selectByEngagementForm">
    <div v-if="hasConversion" class="selectByEngagementForm__row">
      <div class="selectByEngagementForm__labelContainer">
        <RadioButton
          class="selectByEngagementForm__radioButton"
          :value="analysisTypeValue"
          :label="AnalysisType.Cv"
          name="analysisType"
          @change="onAnalysisTypeInput"
        >
          <div v-t="'cv'" class="selectByEngagementForm__label" />
        </RadioButton>
      </div>

      <div class="selectByEngagementForm__content">
        <SelectByEngagementRow
          v-model="conversionCondition"
          :disabled="!isActiveAnalysisType(AnalysisType.Cv)"
          @input="onInput"
        >
          <SelectBox
            v-model="conversionDefinitionId"
            :options="conversionOptions"
            :disabled="!isActiveAnalysisType(AnalysisType.Cv)"
            width="150px"
          />
        </SelectByEngagementRow>
      </div>
    </div>
    <!-- Cv -->

    <div v-if="hasEvent" class="selectByEngagementForm__row">
      <div class="selectByEngagementForm__labelContainer">
        <RadioButton
          class="selectByEngagementForm__radioButton"
          :value="analysisTypeValue"
          :label="AnalysisType.Event"
          name="analysisType"
          @change="onAnalysisTypeInput"
        >
          <div v-t="'event'" class="selectByEngagementForm__label" />
        </RadioButton>
      </div>

      <div class="selectByEngagementForm__content">
        <SelectByEngagementRow
          v-model="eventCondition"
          :disabled="!isActiveAnalysisType(AnalysisType.Event)"
          @input="onInput"
        >
          <SelectBox
            v-model="eventDefinitionId"
            :options="eventOptions"
            :disabled="!isActiveAnalysisType(AnalysisType.Event)"
            width="150px"
          />
        </SelectByEngagementRow>
      </div>
    </div>
    <!-- Event -->

    <div
      v-if="selectedFieldType !== FieldType.AppOnly"
      class="selectByEngagementForm__row"
    >
      <div class="selectByEngagementForm__labelContainer">
        <RadioButton
          class="selectByEngagementForm__radioButton"
          :value="analysisTypeValue"
          :label="AnalysisType.Pv"
          name="analysisType"
          @change="onAnalysisTypeInput"
        >
          <div v-t="'pv'" class="selectByEngagementForm__label" />
        </RadioButton>
      </div>

      <div class="selectByEngagementForm__content">
        <SelectByEngagementRow
          v-model="pvCondition"
          :disabled="!isActiveAnalysisType(AnalysisType.Pv)"
          @input="onInput"
        />
      </div>
    </div>
    <!-- Pv -->

    <div
      v-if="selectedFieldType !== FieldType.WebOnly"
      class="selectByEngagementForm__row"
    >
      <div class="selectByEngagementForm__labelContainer">
        <RadioButton
          class="selectByEngagementForm__radioButton"
          :value="analysisTypeValue"
          :label="AnalysisType.AppLaunch"
          name="analysisType"
          @change="onAnalysisTypeInput"
        >
          <div v-t="'launch'" class="selectByEngagementForm__label" />
        </RadioButton>
      </div>

      <div class="selectByEngagementForm__content">
        <SelectByEngagementRow
          v-model="appLaunchCondition"
          :disabled="!isActiveAnalysisType(AnalysisType.AppLaunch)"
          @input="onInput"
        />
      </div>
    </div>
    <!-- AppLaunch -->

    <div
      v-if="selectedFieldType !== FieldType.WebOnly"
      class="selectByEngagementForm__row"
    >
      <div class="selectByEngagementForm__labelContainer">
        <RadioButton
          class="selectByEngagementForm__radioButton"
          :value="analysisTypeValue"
          :label="AnalysisType.AppView"
          name="analysisType"
          @change="onAnalysisTypeInput"
        >
          <div v-t="'screen'" class="selectByEngagementForm__label" />
        </RadioButton>
      </div>

      <div class="selectByEngagementForm__content">
        <SelectByEngagementRow
          v-model="appViewCondition"
          :disabled="!isActiveAnalysisType(AnalysisType.AppView)"
          @input="onInput"
        />
      </div>
    </div>
    <!-- AppView -->

    <div v-if="showWebsiteItem" class="selectByEngagementForm__website">
      <div
        v-t="'webSiteSpecify'"
        class="selectByEngagementForm__websiteLabel"
        :class="{
          'selectByEngagementForm__websiteLabel--disabled': disabledWebsite
        }"
      />
      <div class="selectByEngagementForm__websiteForm">
        <SelectCheckBox
          :title="selectCheckBoxTitle"
          :select-check-box-items="selectCheckBoxItems"
          :selected-child-ids="selectedSiteIds"
          :selected-parent-ids="selectedParentIds"
          :disabled="disabledWebsite"
          pulldown-standard-height="100vh"
          @updateParent="updateParent"
          @updateChild="updateSites"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";

/* components */
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectCheckBox from "@/components/form/SelectCheckBox.vue";
import {
  SelectCheckBoxItem,
  SelectCheckBoxItems
} from "@/components/form/SelectCheckBoxItems";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectByEngagementRow from "@/components/search/engagement-form/SelectByEngagementRow.vue";

/* models */
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";
import {
  UNSPECIFIED_CV_EVENT_ID,
  AnalysisType,
  FieldType,
  PeriodType,
  SelectByEngagementCondition
} from "@/models/search/select-condition/SelectByEngagementCondition";

@Component({
  components: {
    SelectBox,
    RadioButton,
    SelectCheckBox,
    SelectByEngagementRow
  }
})
export default class SelectByEngagementForm extends Vue {
  @Model("input", { type: SelectByEngagementCondition, required: true })
  condition!: SelectByEngagementCondition;

  @Prop({ type: Array, required: true, default: () => [] })
  conversionDefinitions!: ConversionDefinition[];

  @Prop({ type: Array, required: true, default: () => [] })
  eventDefinitions!: EventDefinition[];

  @Prop({ type: Array, required: true, default: () => [] })
  siteList!: MeasurementTargetSite[];

  @Prop({ type: Number, required: true, default: FieldType.AppOnly })
  fieldType!: FieldType;

  onInput(condition: SelectByEngagementCondition) {
    this.$emit("input", condition);
  }

  pvCondition = SelectByEngagementCondition.createTypeCondition(
    AnalysisType.Pv
  );
  eventCondition = SelectByEngagementCondition.createTypeCondition(
    AnalysisType.Event
  );
  conversionCondition = SelectByEngagementCondition.createTypeCondition(
    AnalysisType.Cv
  );
  appLaunchCondition = SelectByEngagementCondition.createTypeCondition(
    AnalysisType.AppLaunch
  );
  appViewCondition = SelectByEngagementCondition.createTypeCondition(
    AnalysisType.AppView
  );

  AnalysisType = AnalysisType;
  FieldType = FieldType;
  PeriodType = PeriodType;

  selectedParentIds: number[] = [];
  analysisTypeValue: AnalysisType = this.condition.analysisType;
  disabledWebsite: boolean = false;

  created() {
    if (this.hasEvent) {
      const eventId =
        this.eventDefinitionId === UNSPECIFIED_CV_EVENT_ID
          ? this.eventDefinitions[0].id
          : this.eventDefinitionId;
      const condition = this.eventCondition.updateEventDefinitionId(eventId);
      this.eventCondition = condition;
    }

    if (this.hasConversion) {
      const conversionId =
        this.conversionDefinitionId === UNSPECIFIED_CV_EVENT_ID
          ? this.conversionDefinitions[0].id
          : this.conversionDefinitionId;
      const condition = this.conversionCondition.updateConversionDefinitionId(
        conversionId
      );
      this.conversionCondition = condition;
    }

    this.getInitAnalysisType();
    this.setCondition(this.condition);

    // ウェブサイトドメインの設定
    if (this.condition.sites.length > 0) {
      this.selectedParentIds = [];
      this.setSites(this.condition.sites);
    } else {
      this.selectedParentIds = [FieldType.WebOnly];
      this.setSites(this.siteList);
    }
    this.onDisabledWebsite(this.condition.analysisType);
  }

  get hasConversion(): boolean {
    return this.conversionDefinitions.length > 0;
  }

  get hasEvent(): boolean {
    return this.eventDefinitions.length > 0;
  }

  setCondition(condition: SelectByEngagementCondition) {
    if (condition.analysisType === AnalysisType.Event) {
      this.eventCondition = condition;
    } else if (condition.analysisType === AnalysisType.Cv) {
      this.conversionCondition = condition;
    } else if (condition.analysisType === AnalysisType.AppLaunch) {
      this.appLaunchCondition = condition;
    } else if (condition.analysisType === AnalysisType.AppView) {
      this.appViewCondition = condition;
    } else if (condition.analysisType === AnalysisType.Pv) {
      this.pvCondition = condition;
    }
  }

  // analysisType
  getTypeCondition(type: AnalysisType): SelectByEngagementCondition {
    if (type === AnalysisType.Pv) {
      return this.pvCondition;
    } else if (type === AnalysisType.Event) {
      return this.eventCondition;
    } else if (type === AnalysisType.Cv) {
      return this.conversionCondition;
    } else if (type === AnalysisType.AppLaunch) {
      return this.appLaunchCondition;
    } else if (type === AnalysisType.AppView) {
      return this.appViewCondition;
    }
    return this.pvCondition;
  }

  getInitAnalysisType() {
    if (
      this.fieldType === FieldType.AppOnly &&
      this.condition.analysisType === AnalysisType.Pv
    ) {
      this.analysisTypeValue = AnalysisType.AppLaunch;
    }
  }

  onAnalysisTypeInput(value: AnalysisType) {
    this.analysisTypeValue = value;

    const condition = this.getTypeCondition(value);

    this.onInput(condition);
    this.onDisabledWebsite(value);
  }

  isActiveAnalysisType(value: number) {
    return this.analysisTypeValue === value;
  }

  // Conversion
  get conversionDefinitionId(): number {
    return this.conversionCondition.conversionDefinitionId;
  }
  set conversionDefinitionId(conversionDefinitionId: number) {
    const condition = this.conversionCondition.updateConversionDefinitionId(
      conversionDefinitionId
    );

    this.onInput(condition);
    this.conversionCondition = condition;
  }

  get conversionOptions(): SelectOption[] {
    return this.conversionDefinitions.map(obj => {
      return {
        label: obj.name,
        value: obj.id,
        disabled: false
      };
    });
  }

  // event
  get eventDefinitionId(): number {
    return this.eventCondition.eventDefinitionId;
  }
  set eventDefinitionId(eventDefinitionId: number) {
    const condition = this.eventCondition.updateEventDefinitionId(
      eventDefinitionId
    );
    this.onInput(condition);
    this.eventCondition = condition;
  }

  get eventOptions(): SelectOption[] {
    return this.eventDefinitions.map(obj => {
      return {
        label: obj.name,
        value: obj.id,
        disabled: false
      };
    });
  }

  // Website
  get selectedFieldType(): FieldType {
    if (this.fieldType === FieldType.WebOnly) {
      return FieldType.WebOnly;
    }

    if (this.fieldType === FieldType.AppOnly) {
      return FieldType.AppOnly;
    }

    return FieldType.All;
  }

  get showWebsiteItem(): boolean {
    if (this.selectedFieldType === FieldType.AppOnly) {
      return false;
    }

    if (this.siteList.length === 0) {
      return false;
    }

    return true;
  }

  get selectCheckBoxTitle(): string {
    if (this.isWebAllSelected(this.selectedSiteIds)) {
      return this.$i18n.t("all") as string;
    }

    let title: string = "";
    this.getTypeCondition(this.analysisTypeValue).sites.forEach(site => {
      if (title !== "") {
        title += ", ";
      }
      if (site.description === "") {
        title += site.hostPath;
      } else {
        title += site.description;
      }
    });

    if (title === "") {
      return this.$i18n.t("pleaseSelect") as string;
    }

    return title;
  }

  sitesToChildren(sites: MeasurementTargetSite[]): SelectCheckBoxItem[] {
    return sites.map(site => {
      let name!: string;
      if (site.description === "") {
        name = site.hostPath;
      } else {
        name = site.description;
      }

      return {
        name: name,
        id: site.id
      };
    });
  }

  get selectCheckBoxItems(): SelectCheckBoxItems[] {
    if (this.fieldType !== FieldType.AppOnly) {
      return [
        {
          parent: {
            name: this.$i18n.t("all") as string,
            id: FieldType.WebOnly
          },
          children: this.sitesToChildren(this.siteList)
        }
      ];
    } else {
      return [];
    }
  }

  get selectedSiteIds(): number[] {
    return this.getTypeCondition(this.analysisTypeValue).sites.map(
      site => site.id
    );
  }

  isWebAllSelected(ids: number[]): boolean {
    return this.siteList.length === ids.length;
  }

  selectedSites(ids: number[]): MeasurementTargetSite[] {
    return this.siteList.filter(site => {
      return ids.indexOf(site.id) >= 0;
    });
  }

  updateSites(ids: number[]) {
    let condition = this.condition;

    if (this.isWebAllSelected(ids)) {
      this.selectedParentIds = [FieldType.WebOnly];
    } else {
      this.selectedParentIds = [];
    }

    condition = condition.updateFieldType(FieldType.WebOnly);
    condition = condition.updateSites(this.selectedSites(ids));

    this.setSites(condition.sites);
    this.onInput(condition);
  }

  updateParent(ids: number[]) {
    let condition = this.condition;

    condition = condition.updateFieldType(FieldType.WebOnly);
    this.selectedParentIds = ids;

    if (ids.length > 0) {
      condition = condition.updateSites(this.siteList);
    } else {
      condition = condition.updateSites([]);
    }

    this.setSites(condition.sites);
    this.onInput(condition);
  }

  onDisabledWebsite(analysisType: AnalysisType) {
    if (
      analysisType === AnalysisType.AppLaunch ||
      analysisType === AnalysisType.AppView
    ) {
      this.disabledWebsite = true;
    } else {
      this.disabledWebsite = false;
    }
  }

  setSites(sites: MeasurementTargetSite[]) {
    const updateEventCondition = this.eventCondition.updateSites(sites);
    this.eventCondition = updateEventCondition;

    const updateConversionCondition = this.conversionCondition.updateSites(
      sites
    );
    this.conversionCondition = updateConversionCondition;

    const updatePvCondition = this.pvCondition.updateSites(sites);
    this.pvCondition = updatePvCondition;
  }
}
</script>

<style scoped lang="scss">
.selectByEngagementForm__row {
  display: flex;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.selectByEngagementForm__labelContainer {
  padding-top: 5px;
  width: 165px;
}

.selectByEngagementForm__label {
  width: 120px;
}

.selectByEngagementForm__content {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.selectByEngagementForm__text {
  margin-left: 8px;
}

.selectByEngagementForm__period {
  margin-left: 8px;
}

.selectByEngagementForm__tooltip {
  display: flex;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.selectByEngagementForm__tooltipLabel {
  margin-right: 8px;
}

.selectByEngagementForm__directAssignPeriodContainer {
  margin-top: 8px;
}

.selectByEngagementForm__directAssignPeriod {
  display: flex;
  align-items: center;
  padding-left: 180px;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.selectByEngagementForm__directAssignPeriodLabel {
  margin-right: 8px;
}

.selectByEngagementForm__fluctuation {
  margin-left: 8px;
}

.selectByEngagementForm__lastText {
  margin-left: 8px;
}

.selectByEngagementForm__website {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.selectByEngagementForm__websiteLabel--disabled {
  color: $colorBase500;
}

.selectByEngagementForm__websiteForm {
  margin-left: 8px;
}
</style>
