import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import {
  ConversionAttributeTextCondition,
  convertConversionAttributeTextConditionToJson,
  convertJsonToConversionAttributeTextCondition,
  isConversionAttributeTextConditionParams
} from "@/models/search/additional-condition/ConversionAttributeTextCondition";
import {
  ConversionAttributeNumberValueCondition,
  convertConversionAttributeNumberValueConditionToJson,
  convertJsonToConversionAttributeNumberValueCondition,
  isConversionAttributeNumberValueConditionParams
} from "@/models/search/additional-condition/ConversionAttributeNumberValueCondition";
import {
  ConversionAttributeNumberRangeCondition,
  convertConversionAttributeNumberRangeConditionToJson,
  convertJsonToConversionAttributeNumberRangeCondition
} from "@/models/search/additional-condition/ConversionAttributeNumberRangeCondition";
import {
  NpsCondition,
  convertNpsConditionToJson,
  isNpsConditionParams,
  convertJsonToNpsCondition
} from "@/models/search/additional-condition/NpsCondition";
import {
  EnqueteCondition,
  convertEnqueteConditionToJson,
  isEnqueteConditionParams,
  convertJsonToEnqueteCondition
} from "@/models/search/additional-condition/EnqueteCondition";
import {
  BusinessIndexCondition,
  convertBusinessIndexConditionToJson,
  convertJsonToBusinessIndexCondition,
  isBusinessIndexConditionParams
} from "@/models/search/additional-condition/BusinessIndexCondition";
import {
  UserAttributeStringCondition,
  convertUserAttributeStringConditionToJson,
  isUserAttributeStringConditionParams,
  convertJsonToUserAttributeStringCondition
} from "@/models/search/additional-condition/UserAttributeStringCondition";
import {
  UserAttributeNumberCondition,
  convertUserAttributeNumberConditionToJson,
  convertJsonToUserAttributeNumberCondition
} from "@/models/search/additional-condition/UserAttributeNumberCondition";
import {
  BusinessEventAttributeCondition,
  convertBusinessEventAttributeConditionToJson,
  convertJsonToBusinessEventAttributeCondition,
  isBusinessEventAttributeConditionParams
} from "@/models/search/additional-condition/BusinessEventAttributeCondition";
import {
  ContactAttributeCondition,
  convertContactAttributeConditionToJson,
  convertJsonToContactAttributeCondition,
  isContactAttributeConditionParams
} from "@/models/search/additional-condition/ContactAttributeCondition";

import { splitByComma } from "@/util/string-util";
import { MatchMethod, isMatchMethod } from "@/models/search/MatchMethod";
import {
  AdditionalConditionParams,
  ConversionAttributeNumberRangeConditionParams
} from "@/api/apis/ApiSearch";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { i18n } from "@/i18n";
import { isConversionAttributeConditionParams } from "@/models/search/additional-condition/ConversionAttributeCondition";

/**
 * 選定条件に追加で指定できる条件
 * (subCndと呼ばれていたもの)
 */
export type AdditionalSelectCondition =
  | ConversionAttributeTextCondition
  | ConversionAttributeNumberValueCondition
  | ConversionAttributeNumberRangeCondition
  | UserAttributeCondition
  | NpsCondition
  | EnqueteCondition
  | BusinessIndexCondition
  | BusinessEventAttributeCondition
  | ContactAttributeCondition;

/**
 * 選定条件のタイプ
 */
export enum AdditionalSelectConditionType {
  ConversionAttributeCondition,
  UserAttributeStringCondition,
  UserAttributeNumberCondition,
  NpsCondition,
  EnqueteCondition,
  BusinessIndexCondition,
  BusinessEventAttributeCondition,
  ContactAttributeCondition
}

/**
 * ユーザ属性
 * 文字列型と数字型の２種類ある
 */
export type UserAttributeCondition =
  | UserAttributeStringCondition
  | UserAttributeNumberCondition;

/**
 * 選定条件に追加で指定できる条件の種類
 */
export enum AdditionalConditionType {
  CONVERSION_ATTRIBUTE,
  USER_ATTRIBUTE,
  NPS,
  ENQUETE,
  BUSINESS_INDEX,
  BUSINESS_EVENT_ATTRIBUTE,
  CONTACT_ATTRIBUTE
}

export interface AdditionalSelectConditionInterface {
  displayTitle(
    definitions:
      | ConversionAttributeDefinition[]
      | UserAttributeDefinition[]
      | NpsDefinition[]
      | EnqueteDefinition[]
      | BusinessIndexDefinition[]
      | BusinessEventDefinition[]
      | ContactDefinition[]
  ): string;
  displayValue(): string;
  isSameId(id: number): boolean;
}

/**
 * 数値入力の方法
 * 値を１つ入れる値指定と、最小と最大を入れるレンジ指定
 */
export enum ValueInputMethod {
  // 値指定
  Value = 1,

  // レンジ指定
  Range = 2
}

/**
 * ValueInputMethod にある値か
 */
export function isValueInputMethod(val: number) {
  return val === ValueInputMethod.Value || val === ValueInputMethod.Range;
}

/**
 * stringリストとマッチ条件を持つconditionの値を表示用に加工
 */
export function stringListValue(
  strings: string[],
  matchMethod: MatchMethod
): string {
  // 入力されたテキストの最大数表示個数
  const MAX_DISPLAY_VALUES = 5;

  // テキストをカンマ区切りで表示
  const values = strings.slice(0, MAX_DISPLAY_VALUES).join(",");

  // 最大数より多ければ残りの個数を表示する。
  let remaining = "";
  if (MAX_DISPLAY_VALUES < strings.length) {
    const remainingNumber: number = strings.length - MAX_DISPLAY_VALUES;
    remaining += " ";
    remaining += i18n.t("models.search.other", {
      num: String(remainingNumber)
    }) as string;
  }

  // マッチ条件を表示
  let match: String = "";
  switch (matchMethod) {
    case MatchMethod.Partial:
      match = "(" + (i18n.t("models.search.partialMatch") as string) + ")";
      break;
    case MatchMethod.Exact:
      match = "(" + (i18n.t("models.search.perfectMatch") as string) + ")";
      break;
    case MatchMethod.Prefix:
      match = "(" + (i18n.t("models.search.prefixMatch") as string) + ")";
      break;
    case MatchMethod.Postfix:
      match = "(" + (i18n.t("models.search.postfixMatch") as string) + ")";
      break;
  }

  return values + remaining + " " + match;
}

/**
 * 数値conditionの値を表示用に加工
 */
export function numberValue(
  from: number,
  to: number,
  inputMethod: ValueInputMethod
): string {
  // 値指定
  if (inputMethod === ValueInputMethod.Range) {
    return String(from) + " 〜 " + String(to);
  }
  return String(from);
}

/**
 * 文字列パラメータのバリデーション
 * @param params JSON.parseされたパラメータ
 */
export function validateStringsUrlParam(params: {
  [key: string]: string | number | boolean | null;
}): Error | null {
  if (!("id" in params) || !("vals" in params) || !("match" in params)) {
    return new Error("not enough params.");
  }

  const vals = splitByComma(String(params.vals));
  if (vals.length <= 0) {
    return new Error("invalid params.");
  }

  const match = params.match as MatchMethod;
  if (!isMatchMethod(match)) {
    return new Error("invalid params.");
  }

  return null;
}

/**
 * 数値パラメータのバリデーション
 * @param params JSON.parseされたパラメータ
 */
export function validateNumberUrlParam(params: {
  [key: string]: string | number | boolean | null;
}): Error | null {
  if (
    !("id" in params) ||
    !("from" in params) ||
    !("to" in params) ||
    !("range" in params)
  ) {
    return new Error("not enough params.");
  }

  const range = params.range as ValueInputMethod;
  if (!isValueInputMethod(range)) {
    return new Error("invalid params.");
  }

  return null;
}

/**
 * 追加条件 -> JSON
 */
export function convertAdditionalConditionToJson(
  condition: AdditionalSelectCondition
): AdditionalConditionParams {
  if (condition instanceof ConversionAttributeTextCondition) {
    return convertConversionAttributeTextConditionToJson(condition);
  }

  if (condition instanceof ConversionAttributeNumberValueCondition) {
    return convertConversionAttributeNumberValueConditionToJson(condition);
  }

  if (condition instanceof ConversionAttributeNumberRangeCondition) {
    return convertConversionAttributeNumberRangeConditionToJson(condition);
  }

  if (condition instanceof NpsCondition) {
    return convertNpsConditionToJson(condition);
  }

  if (condition instanceof EnqueteCondition) {
    return convertEnqueteConditionToJson(condition);
  }

  if (condition instanceof UserAttributeNumberCondition) {
    return convertUserAttributeNumberConditionToJson(condition);
  }

  if (condition instanceof UserAttributeStringCondition) {
    return convertUserAttributeStringConditionToJson(condition);
  }

  if (condition instanceof BusinessEventAttributeCondition) {
    return convertBusinessEventAttributeConditionToJson(condition);
  }

  if (condition instanceof ContactAttributeCondition) {
    return convertContactAttributeConditionToJson(condition);
  }

  return convertBusinessIndexConditionToJson(condition);
}

/**
 * JSON -> 追加条件
 */
export function convertJsonToAdditionalCondition(
  json: AdditionalConditionParams,
  activeDefinitions: ActiveDefinitions
): AdditionalSelectCondition | null {
  if (isConversionAttributeConditionParams(json)) {
    if (isConversionAttributeTextConditionParams(json)) {
      return convertJsonToConversionAttributeTextCondition(
        json,
        activeDefinitions.conversionAttributeDefinitions
      );
    } else if (isConversionAttributeNumberValueConditionParams(json)) {
      return convertJsonToConversionAttributeNumberValueCondition(
        json,
        activeDefinitions.conversionAttributeDefinitions
      );
    } else {
      return convertJsonToConversionAttributeNumberRangeCondition(
        json as ConversionAttributeNumberRangeConditionParams,
        activeDefinitions.conversionAttributeDefinitions
      );
    }
  }

  if (isNpsConditionParams(json)) {
    return convertJsonToNpsCondition(json);
  }

  if (isEnqueteConditionParams(json)) {
    return convertJsonToEnqueteCondition(
      json,
      activeDefinitions.enqueteDefinitions
    );
  }

  if (isBusinessIndexConditionParams(json)) {
    return convertJsonToBusinessIndexCondition(
      json,
      activeDefinitions.businessIndexDefinitions
    );
  }

  if (isUserAttributeStringConditionParams(json)) {
    return convertJsonToUserAttributeStringCondition(
      json,
      activeDefinitions.userAttributeDefinitions
    );
  }

  if (isBusinessEventAttributeConditionParams(json)) {
    return convertJsonToBusinessEventAttributeCondition(
      json,
      activeDefinitions.businessEventDefinitions
    );
  }

  if (isContactAttributeConditionParams(json)) {
    return convertJsonToContactAttributeCondition(
      json,
      activeDefinitions.contactDefinitions
    );
  }

  return convertJsonToUserAttributeNumberCondition(
    json,
    activeDefinitions.userAttributeDefinitions
  );
}
