import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";
import { MatchMethod } from "@/models/search/MatchMethod";

/**
 * コンバージョン・イベント属性による条件
 *
 * コンバージョンの絞り込みに追加条件として付与できる
 */
export class FilterConversionAttributeCondition {
  public readonly conditionType = FilterNodeConditionType.ConversionAttribute;

  constructor(
    // コンバージョン・イベント属性定義のID
    public readonly conversionAttributeDefinitionId: number,
    // 検索対象文字列
    public readonly value: string,

    public readonly matchMethod: MatchMethod
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.value,
      i18n.t("models.search.cvAttribute") as string
    );
  }
}
