<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <ul class="chart-period-menu">
    <li
      v-for="o in chartPeriodOptions"
      :key="o.label"
      class="chart-period-menu__item"
      :class="{ 'chart-period-menu__item--current': o.period === value }"
      @click="onClickPeriod(o.period)"
    >
      {{ o.label }}
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Vue, Model } from "vue-property-decorator";
import { ChartPeriod } from "@/const/chart-period";

@Component
export default class ChartPeriodMenu extends Vue {
  @Model("input", { type: Number, required: true })
  value!: ChartPeriod;

  onClickPeriod(period: ChartPeriod) {
    this.$emit("input", period);
  }

  get chartPeriodOptions() {
    return [
      { label: this.$t("1month"), period: ChartPeriod.OneMonth },
      { label: this.$t("6months"), period: ChartPeriod.SixMonths },
      { label: this.$t("1year"), period: ChartPeriod.OneYear },
      { label: this.$t("2years"), period: ChartPeriod.TwoYears }
    ];
  }
}
</script>

<style lang="scss" scoped>
.chart-period-menu {
  display: flex;
  padding: 0 10px;
  color: $colorBase700;

  & > *:not(:first-child) {
    margin-left: 20px;
  }

  &__item {
    color: inherit;
    cursor: pointer;

    &:not(.chart-period-menu__item--current):hover {
      text-decoration: underline;
    }

    &--current {
      color: $colorBase900;
      text-decoration: none;
      font-weight: bold;
      cursor: default;
    }
  }
}
</style>
