<i18n src="@/i18n/components/search/search-result-helper.json"></i18n>

<template>
  <Dialog
    class="saveSearchConditionModal"
    :visible="true"
    :title="$t('saveConditions')"
    width="600px"
    data-cy="favorite-search-modal"
    @close="onClose"
  >
    <div class="saveSearchConditionModal__head">
      <div v-t="'label'" class="saveSearchConditionModal__label" />
      <div v-t="'description'" class="saveSearchConditionModal__description" />
    </div>

    <InputText
      :value="searchConditionLabel"
      @input="onChangeSearchConditionLabel"
    />

    <div
      v-t="'favoriteSearchDescription'"
      class="saveSearchConditionModal__explanationText"
    />

    <template #footer>
      <div class="saveSearchConditionModal__dialogFooter">
        <Button
          v-t="'ok'"
          class="saveSearchConditionModal__dialogButton"
          width="150px"
          :disabled="!canSave || isSearchConditionLabelEmpty"
          @click="onClickSave"
        />
        <Button
          v-t="'cancel'"
          class="saveSearchConditionModal__dialogButton"
          width="150px"
          :type="cancelButtonType"
          @click="onClose"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Model } from "vue-property-decorator";
import Dialog from "@/components/dialog/Dialog.vue";
import Button from "@/components/Button.vue";
import InputText from "@/components/form/InputText.vue";
import { BUTTON_TYPE } from "@/const/button";

@Component({
  components: {
    Dialog,
    Button,
    InputText
  }
})
export default class SaveSearchConditionModal extends Vue {
  @Prop({ type: Boolean, default: false })
  canSave!: boolean;

  @Model("input", { type: String, required: true })
  searchConditionLabel!: string;

  onChangeSearchConditionLabel(value: string) {
    this.$emit("input", value);
  }

  @Emit("close")
  onClose() {}

  @Emit("save")
  onClickSave() {}

  cancelButtonType: BUTTON_TYPE = BUTTON_TYPE.LIGHT;

  get isSearchConditionLabelEmpty(): boolean {
    return this.searchConditionLabel === "";
  }
}
</script>

<style lang="scss" scoped>
.saveSearchConditionModal__head {
  display: flex;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 10px;
  line-height: 1;
}

.saveSearchConditionModal__label {
  font-weight: bold;
}

.saveSearchConditionModal__description {
  margin-left: 10px;
  font-size: 12px;
}

.saveSearchConditionModal__explanationText {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
}

.saveSearchConditionModal__dialogFooter {
  margin-top: 30px;
}

.saveSearchConditionModal__dialogButton {
  margin: 0 10px;
}
</style>
