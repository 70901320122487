<template>
  <div id="app">
    <div ref="appHeader" class="app__header">
      <Header
        v-if="
          isInitialized &&
            isLoggedInAndAuthenticated &&
            !shouldHideHeaderAndSideBar
        "
        @click-burger="clickBurger"
        @show-search-form="changeSearchFormIsDisplayed"
        @show-filter-form="changeFilterFormIsDisplayed"
      />
    </div>

    <template v-if="!isInitialized">
      <Loading />
    </template>

    <template v-else>
      <div
        v-if="isLoggedInAndAuthenticated && !shouldHideHeaderAndSideBar"
        class="app__globalNaviContainer"
        :class="[
          expandGlobalNav
            ? 'app__globalNaviContainer--expanded'
            : 'app__globalNaviContainer--condensed'
        ]"
      >
        <ScrollWrapper
          :overflow="overflow.ScrollYOnlyEnabled"
          :show-scrollbar-with-mouse-enter="true"
          @update:scroll-y="scrollNavbar($event)"
        >
          <GlobalNav
            class="app__globalNavi"
            :expand-nav="expandGlobalNav"
            :hovered-favorite-search-id="
              hoveredFavoriteSearch !== null ? hoveredFavoriteSearch.id : -1
            "
            @mouse-enter-item="onMouseEnterSavedSearchColumn"
            @mouse-leave-item="onMouseLeaveSavedSearchColumn"
            @mouse-enter-navi="onMouseEnterNavi"
            @mouse-leave-navi="onMouseLeaveNavi"
          />
        </ScrollWrapper>
      </div>

      <div
        class="app_savedSearchBalloon"
        :style="style"
        @mouseenter="onMouseEnterSavedSearchBalloon"
        @mouseleave="onMouseLeaveSavedSearchBalloon"
      >
        <SavedSearchBalloon
          v-if="showBalloon"
          :favorite-search="hoveredFavoriteSearch"
          :adjust="balloonArrowAdjust"
        />
      </div>

      <div
        ref="appContent"
        class="app__content"
        data-cy="ug-main"
        :class="addCotentClass"
        :style="{ top: appContentTop }"
      >
        <RouterView />
      </div>

      <BlogLinkContainer
        class="app__blogLinkContainer"
        :search-form-is-displayed="searchFormIsDisplayed"
        :filter-form-is-displayed="filterFormIsDisplayed"
      />

      <Transition name="fade">
        <div v-if="isDownloading" class="app__csvDownloadProgress">
          <CsvDownloadProgress :file-name="csvFileName" />
        </div>
      </Transition>
    </template>

    <AlertDialog
      data-cy="alert-dialog"
      :message="alertMessage"
      @close="closeAlertModal"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import AlertDialog from "@/components/dialog/AlertDialog.vue";
import Loading from "@/components/Loading.vue";
import IconTextButton from "@/components/IconTextButton.vue";
import { Icons } from "@/const/Icons";
import Header from "@/views/Header.vue";
import GlobalNav from "@/views/GlobalNav.vue";
import SettingNav from "@/views/SettingNav.vue";
import { FavoriteSearch } from "@/models/search/FavoriteSearch";
import SavedSearchBalloon from "@/components/search/SavedSearchBalloon.vue";
import BlogLinkContainer from "@/views/BlogLinkContainer.vue";
import ScrollWrapper, { Overflow } from "@/components/ScrollWrapper.vue";
import { stopWindowScroll, startWindowScroll } from "@/util/window-util";
import { Os } from "@/const/UserAgent";
import { getOs } from "@/util/user-agent-util";
import CsvDownloadProgress from "@/components/CsvDownloadProgress.vue";
import { getCsvFileName } from "@/util/csv-util";

const SHOW_BALLOON_WAIT_DURATION = 300;
const HIDE_BALLOON_WAIT_DURATION = 100;
const ADJUST_BALLOON_POSITION = 70;
const ADJUST_BALLOON_POSITION_WITH_NO_PERIOD = 30;
const HEADER_HEIGHT = 70;

@Component({
  components: {
    Header,
    GlobalNav,
    SettingNav,
    AlertDialog,
    Loading,
    IconTextButton,
    BlogLinkContainer,
    SavedSearchBalloon,
    ScrollWrapper,
    CsvDownloadProgress
  }
})
export default class DefaultLayout extends Vue {
  hoverExpandGlobalNav = false;
  globalNavShowTimer: number | null = null;

  // whether to display balloon or not
  hoveredFavoriteSearch: FavoriteSearch | null = null;
  lastHoveredSearchId: number | null = null;
  balloonDisplayTimer: number | null = null;
  balloonHideTimer: number | null = null;
  isBalloonHover: boolean = false;
  isItemHover: boolean = false;

  // where to display balloon
  balloonArrowAdjust: number = 0;
  balloonPositionX: string = "250px";
  balloonPositionY: String | null = null; // use this for judging displaying
  defaultBalloonTop: number = 0; // 画面がスクロールされる前の、balloonの表示位置。
  navScroll: number = 0;
  homeScroll: number = 0;
  overflow = Overflow;

  icons = Icons;

  appContentTop: string = "";

  searchFormIsDisplayed = false;
  filterFormIsDisplayed = false;

  @Watch("$route")
  pageTransition(to: Route, from: Route) {
    if (to.name !== from.name) {
      this.appContentTop = "";
      this.startWindowScrollWithScrollbarDisplay();
    }
  }

  scrollNavbar(navScroll: number) {
    this.navScroll = navScroll;
    if (this.balloonPositionY !== null) {
      this.updateBalloonPosition();
    }
  }
  updateBalloonPosition() {
    this.balloonPositionY =
      this.defaultBalloonTop - this.navScroll + this.homeScroll + "px";
  }
  hideBalloon() {
    this.balloonPositionY = null;
    this.hoveredFavoriteSearch = null;
  }

  clickBurger() {
    this.$store.commit(
      "preference/setClickExpandGlobalNav",
      !this.clickExpandGlobalNav
    );

    if (!this.expandGlobalNav) {
      this.hideBalloon();
    }
  }

  onMouseEnterSavedSearchBalloon() {
    this.isBalloonHover = true;
    // setTimeout 使わずに表示したいので onMouseEnterNavi を使わない
    this.hoverExpandGlobalNav = true;
    this.stopWindowScrollWithScrollbarDisplay();

    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
    }
  }

  onMouseLeaveSavedSearchBalloon() {
    this.isBalloonHover = false;

    // hide balloon when move out of cell
    this.balloonDisplayTimer = window.setTimeout(() => {
      if (!this.isBalloonHover) {
        this.onMouseLeaveNavi();
        this.hideBalloon();
      }
    }, HIDE_BALLOON_WAIT_DURATION);
  }

  onMouseEnterSavedSearchColumn(event: MouseEvent, search: FavoriteSearch) {
    this.isItemHover = true;

    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
    }

    // バルーンに表示されているセルと違うセルに移動をした際、一旦バルーンを消す(balloon内の情報取得&格納のため)
    if (search.id !== this.lastHoveredSearchId) {
      this.hideBalloon();
    }

    // balloonPositionYの更新をタイマーでセット
    this.balloonDisplayTimer = window.setTimeout(() => {
      this.hoveredFavoriteSearch = search;
      this.lastHoveredSearchId = this.hoveredFavoriteSearch.id;
      const evTarget = event.target;
      const target: HTMLDivElement = evTarget as HTMLDivElement;
      if ("startDate" in search.history.selectCondition!) {
        this.balloonArrowAdjust = 0;
        this.defaultBalloonTop =
          target.getBoundingClientRect().top +
          this.navScroll -
          ADJUST_BALLOON_POSITION;
      } else {
        this.balloonArrowAdjust = -5;
        this.defaultBalloonTop =
          target.getBoundingClientRect().top +
          this.navScroll -
          ADJUST_BALLOON_POSITION_WITH_NO_PERIOD;
      }
      this.updateBalloonPosition();
    }, SHOW_BALLOON_WAIT_DURATION);
  }
  onMouseLeaveSavedSearchColumn() {
    this.isItemHover = false;

    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
    }

    // balloonPositionYの更新をタイマーでセット
    this.balloonHideTimer = window.setTimeout(() => {
      if (!this.isBalloonHover) {
        this.hideBalloon();
      }
      //  delete timer after use
      if (this.balloonHideTimer !== null) {
        clearTimeout(this.balloonHideTimer);
        this.balloonHideTimer = null;
      }
    }, HIDE_BALLOON_WAIT_DURATION);
  }

  onMouseEnterNavi() {
    this.globalNavShowTimer = window.setTimeout(() => {
      this.hoverExpandGlobalNav = true;
      this.stopWindowScrollWithScrollbarDisplay();
    }, SHOW_BALLOON_WAIT_DURATION);
  }

  onMouseLeaveNavi() {
    this.hoverExpandGlobalNav = false;
    this.startWindowScrollWithScrollbarDisplay();

    if (this.globalNavShowTimer !== null) {
      clearTimeout(this.globalNavShowTimer);
    }
  }

  changeSearchFormIsDisplayed(isDisplayed: boolean) {
    this.searchFormIsDisplayed = isDisplayed;
  }

  changeFilterFormIsDisplayed(isDisplayed: boolean) {
    this.filterFormIsDisplayed = isDisplayed;
  }

  get clickExpandGlobalNav(): boolean {
    return this.$store.state.preference.clickExpandGlobalNav;
  }

  get addCotentClass(): string[] {
    const result: string[] = [];

    if (!this.isLoggedInAndAuthenticated || this.shouldHideHeaderAndSideBar) {
      return result;
    }

    if (this.clickExpandGlobalNav) {
      result.push("app__content--expanded");
    } else {
      result.push("app__content--condensed");
    }

    if (this.hoverExpandGlobalNav && this.isOsWindow) {
      result.push("app__content--hoverExpanded");
    }

    return result;
  }

  get expandGlobalNav(): boolean {
    return this.clickExpandGlobalNav || this.hoverExpandGlobalNav;
  }

  get style() {
    return {
      left: this.balloonPositionX,
      top: this.balloonPositionY
    };
  }

  get isInitialized(): boolean {
    return (
      this.$store.state.app.isInitialized || this.$route.meta?.allowPublicAccess
    );
  }

  get isAuthenticated(): boolean {
    return this.$store.state.auth.isAuthenticated;
  }

  get isLoggedIn(): boolean {
    return this.$route.path !== "/login";
  }

  get isLoggedInAndAuthenticated(): boolean {
    return this.isLoggedIn && this.isAuthenticated;
  }

  get shouldHideHeaderAndSideBar(): boolean {
    return !!(this.$route.meta && this.$route.meta.shouldHideHeaderAndSideBar);
  }

  get alertMessage(): string {
    const message = this.$store.state.alert.message;
    return message === null ? "" : message;
  }

  get showBalloon(): boolean {
    return this.balloonPositionY !== null;
  }

  get isOsWindow(): boolean {
    return getOs() === Os.Win;
  }

  closeAlertModal() {
    this.$store.commit("alert/initialize");
  }

  stopWindowScrollWithScrollbarDisplay() {
    if (this.isOsWindow) {
      this.appContentTop = `-${window.scrollY - HEADER_HEIGHT}px`;
    } else {
      stopWindowScroll();
    }
  }

  startWindowScrollWithScrollbarDisplay() {
    if (this.isOsWindow) {
      this.$nextTick(() => {
        // app_savedSearchBalloon をマウスオーバーした際に appContentTop は初期化されているため、
        // Javascriptを使用し直接取得
        const appContent = this.$refs.appContent as HTMLElement;
        const appContentTop = appContent.style.top;
        const scrollY = parseInt(appContentTop || "0");

        this.appContentTop = "";
        if (0 > scrollY) {
          window.scrollTo(0, -(scrollY - HEADER_HEIGHT));
        }
      });
    } else {
      startWindowScroll();
    }
  }

  get isDownloading(): boolean {
    return this.$store.state.search.isCsvDownloading;
  }

  get csvFileName(): string {
    return getCsvFileName();
  }
}
</script>

<style lang="scss" scoped>
.app__header {
  position: sticky;
  top: 0;
  width: 100%;
}

.app_savedSearchBalloon {
  position: fixed;
}

.app__globalNaviContainer {
  position: sticky;
  float: left;
  top: $headerHeight;
  left: 0;
  overflow: hidden;
  flex-shrink: 0;
  height: calc(100% - #{$headerHeight});
  background-color: $colorWhite;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.15);
  transition: width $globalNaviTransition ease-out,
    margin-left $globalNaviTransition ease-out;
}

.app__globalNaviContainer--expanded {
  width: $globalNaviExpandedWidth;
  margin-left: calc($globalNaviCondensedWidth - $globalNaviExpandedWidth);
}

.app__globalNaviContainer--condensed {
  width: $globalNaviCondensedWidth;
}

.app__globalNavi {
  min-height: calc(100vh - #{$headerHeight});
}

.app__content {
  transition: padding-left $globalNaviTransition ease-out;
}

.app__content--expanded {
  padding-left: $globalNaviExpandedWidth;
}

.app__content--condensed {
  padding-left: $globalNaviCondensedWidth;
}

.app__content--hoverExpanded {
  position: fixed;
  left: 0;
  min-width: $browserMinWidth;
  width: 100%;
}

@media print {
  .app__header {
    display: none;
  }
  .app__blogLinkContainer {
    display: none;
  }
}

.app__csvDownloadProgress {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 23;
}
</style>
