import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";

/**
 * get conversion name from definitions by conversion id.
 *
 * @param conversionId
 * @param conversionDefinitions list of conversion definitions
 * @param globalConversionDefinitions list of global conversion definitions
 *
 * @returns string conversion name
 */
export function getConversionNameFromConversionId(
  conversionId: number,
  conversionDefinitions: ConversionDefinition[],
  globalConversionDefinitions: ConversionDefinition[]
): string {
  let cv = conversionDefinitions.find(cv => cv.id === conversionId);
  if (cv !== undefined) {
    return cv.name;
  }
  // If conversion does not exist in conversionDefinitions, Check globalConversionDefinitions.
  cv = globalConversionDefinitions.find(cv => cv.id === conversionId);
  return cv?.name || "";
}
