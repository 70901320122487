<template>
  <div id="app" class="settings">
    <template v-if="!isInitialized">
      <Loading />
    </template>

    <template v-else>
      <Header />

      <div class="settings__main">
        <div class="settings__navContainer">
          <ClientSettingsNav class="settings__nav" />
        </div>
        <div class="settings__contents">
          <RouterView />
        </div>
      </div>
      <ClientSettingsFooter />
    </template>

    <AlertDialog
      data-cy="alert-dialog"
      :message="alertMessage"
      @close="closeAlertModal"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import ClientSettingsFooter from "@/components/client-settings/Footer.vue";
import AlertDialog from "@/components/dialog/AlertDialog.vue";
import Loading from "@/components/Loading.vue";
import Header from "@/views/Header.vue";
import ClientSettingsNav from "@/views/client-settings/ClientSettingsNav.vue";

@Component({
  components: {
    AlertDialog,
    Loading,
    Header,
    ClientSettingsNav,
    ClientSettingsFooter
  }
})
export default class SettingsLayout extends Vue {
  get isInitialized(): boolean {
    return this.$store.state.app.isSettingsInitialized;
  }

  get alertMessage(): string {
    const message = this.$store.state.alert.message;
    return message === null ? "" : message;
  }

  closeAlertModal() {
    this.$store.commit("alert/initialize");
  }
}
</script>

<style lang="scss" scoped>
.settings__main {
  display: flex;
  padding: 70px 0 80px 0;
  min-height: calc(100vh - 100px);
}

.settings__navContainer {
  padding: 0 60px 0 20px;
}

.settings__contents {
  width: 100%;
}
</style>
