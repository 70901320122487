import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { filterInputCheck } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { i18n } from "@/i18n";

/**
 * 行動履歴による条件
 *
 * 絞り込み条件に追加で、電話のとき、内容（部分一致）を条件として付与できる
 */
export class FilterContactContentCondition {
  public readonly conditionType = FilterNodeConditionType.ContactContent;
  constructor(public readonly content: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.content,
      i18n.t("models.contactAttribute.content") as string
    );
  }
}
