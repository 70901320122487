import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * 検索エンジンの種類による条件
 *
 * 流入のサーチエンジン絞り込みに追加で付与できる
 */
export class FilterSearchEngineCondition {
  public readonly conditionType = FilterNodeConditionType.SearchEngine;

  constructor(
    // 検索エンジン定義のID
    public readonly searchEngineId: number
  ) {}

  // validateがfalseになる条件がない
  get validate(): ValidationResult {
    return { isValid: true };
  }
}
