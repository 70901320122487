import { ApiRes } from "@/api/api-res";
import {
  getDateFromYearMonthDayString,
  formatDate,
  DateFormat,
  isSameDate
} from "@/util/date-util";
import { UgEventTag } from "@/store/modules/ugTag";

export enum LagCvPeriodType {
  OneDay = "one-day",
  OneWeek = "one-week",
  FourWeeks = "four-weeks"
}

export type LagCvConversion = ApiRes.LagCvConversion;

export default class LagCvUserCount {
  constructor(
    public readonly periodType: LagCvPeriodType,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly numberOfUsers: number,
    public readonly conversions: LagCvConversion[]
  ) {}

  public static fromJson(json: ApiRes.LagCvUserCount): LagCvUserCount {
    return new LagCvUserCount(
      LagCvUserCount.getPeriodTypeFromRawPeriod(json.period),
      getDateFromYearMonthDayString(json.start_date),
      getDateFromYearMonthDayString(json.end_date),
      json.number_of_users,
      json.conversions
    );
  }

  private static getPeriodTypeFromRawPeriod(
    rawPeriod: ApiRes.LagCvPeriod
  ): LagCvPeriodType {
    switch (rawPeriod) {
      case "one-day":
        return LagCvPeriodType.OneDay;
      case "one-week":
        return LagCvPeriodType.OneWeek;
      case "four-weeks":
        return LagCvPeriodType.FourWeeks;
      default:
        throw new Error("Invalid LagCV period");
    }
  }

  get periodRange(): string {
    const startDateString = formatDate(DateFormat.yyyysMMsdd, this.startDate);
    const endDateString = formatDate(DateFormat.yyyysMMsdd, this.endDate);

    if (isSameDate(this.startDate, this.endDate)) {
      return startDateString;
    }
    return `${startDateString} - ${endDateString}`;
  }

  get ugEventTag(): UgEventTag | null {
    switch (this.periodType) {
      case LagCvPeriodType.OneDay:
        return UgEventTag.LagCvYesterday;
      case LagCvPeriodType.OneWeek:
        return UgEventTag.LagCvOneWeek;
      case LagCvPeriodType.FourWeeks:
        return UgEventTag.LagCvFourWeeks;
      default:
        // set default for linter
        return null;
    }
  }

  public static isValidPeriod(period: string): boolean {
    const validPeriods = ["one-day", "one-week", "four-weeks"];
    return validPeriods.includes(period);
  }
}
