/**
 * マッチ条件の一致の判定方法
 * 文字列型の属性に対する検索条件として指定可能
 */
export enum MatchMethod {
  Wildcard = 5,
  // 後方一致
  Postfix = 4,

  // 前方一致
  Prefix = 3,

  // 完全一致
  Exact = 2,

  // 部分一致
  Partial = 1
}

/**
 * MatchMethod にある値か
 */
export function isMatchMethod(val: number) {
  return (
    val === MatchMethod.Partial ||
    val === MatchMethod.Exact ||
    val === MatchMethod.Prefix ||
    val === MatchMethod.Postfix
  );
}

export interface WordForHighlight {
  word: string;
  matchMethod?: MatchMethod;
  isInflowParam?: boolean;
}
