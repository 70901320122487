<template>
  <input
    type="text"
    class="datePickerReadonlyInput"
    readonly
    :placeholder="placeholder"
    :value="value"
    :class="[
      { 'datePickerReadonlyInput--focus': focus },
      'datePickerReadonlyInput--' + align
    ]"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { InputTextAlign } from "@/models/date-picker/DatePicker";

@Component
export default class DatePickerReadonlyInput extends Vue {
  @Prop({ type: String, default: "" })
  value!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: Boolean, default: false })
  focus!: boolean;

  @Prop({ type: String, default: InputTextAlign.Left })
  align!: InputTextAlign;
}
</script>

<style scoped lang="scss">
.datePickerReadonlyInput {
  padding: 0 8px;
  width: 100%;
  height: $formPartsHeight - 6;
  border: 1px solid $colorClear;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorText;
  font-size: $basicFontSize;
  line-height: $formPartsHeight - 6;

  &:disabled {
    background-color: $colorGray200;
    color: $colorBase600;
  }

  &:not(:disabled):hover {
    background-color: $colorBlue600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  &::placeholder {
    color: $colorBase700;
  }
}

.datePickerReadonlyInput--left {
  text-align-last: left;
}

.datePickerReadonlyInput--center {
  text-align: center;
}

.datePickerReadonlyInput--focus {
  background-color: $colorBlue600;
  /* IE11でplaceholderを表示させ、キャスターを消すために必要 */
  pointer-events: none;
}
</style>
