<template>
  <g class="chartOmoIcons">
    <ChartOmoIcon
      v-for="o in npsOverviews"
      :key="o.gramId"
      class="chartOmoIcons__icon chartOmoIcons__npsIcon"
      :width="getWidth(o)"
      :x="getX(o)"
      :fill="colors.Base900"
      :content="getContent(o)"
      @click="onClick(o)"
      @mouseenter="onMouseEnter(o)"
      @mouseleave="onMouseLeave"
    />

    <ChartOmoIcon
      v-for="o in enqueteOverviews"
      :key="o.gramId"
      class="chartOmoIcons__icon chartOmoIcons__enqueteIcon"
      :width="getWidth(o)"
      :x="getX(o)"
      :fill="colors.Base700"
      :content="getContent(o)"
      @click="onClick(o)"
      @mouseenter="onMouseEnter(o)"
      @mouseleave="onMouseLeave"
    />
  </g>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { Colors } from "@/const/Colors";
import { convertTimeToX, getXAdjustWidth } from "@/components/chart/chart-util";
import ChartOmoIcon from "@/components/chart/ChartOmoIcon.vue";

@Component({
  components: { ChartOmoIcon }
})
export default class ChartOmoIcons extends Vue {
  @Prop({ type: Array, required: true })
  npsOverviews!: NpsOverview[];

  @Prop({ type: Array, required: true })
  enqueteOverviews!: EnqueteOverview[];

  @Prop({ type: Number, required: true })
  baselineLength!: number;

  @Prop({ type: Date, required: true })
  firstScaleDate!: Date;

  @Prop({ type: Date, required: true })
  lastScaleDate!: Date;

  @Prop({ type: Number, required: true })
  lastScaleX!: number;

  @Prop({ type: Boolean, required: true })
  isYear!: boolean;

  onClick(overview: NpsOverview | EnqueteOverview) {
    this.$emit("click", overview);
  }

  onMouseEnter(overview: NpsOverview | EnqueteOverview) {
    this.$emit("mouseenter", overview);
  }

  @Emit("mouseleave")
  onMouseLeave() {}

  colors = Colors;

  getX(overview: NpsOverview | EnqueteOverview): number {
    const width: number = this.getWidth(overview);

    return getXAdjustWidth(
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        overview.date.getTime()
      ),
      width
    );
  }

  getContent(overview: NpsOverview | EnqueteOverview): string {
    const title: string = overview.definition.shortName;
    const score: number = overview.score;
    const content: string = this.isYear
      ? score.toString()
      : title + " " + score;
    return content;
  }

  getWidth(overview: NpsOverview | EnqueteOverview): number {
    const text = this.getContent(overview);
    const widthPerCharacter: number = 5.5;
    const margin: number = 18;
    return widthPerCharacter * text.length + margin;
  }
}
</script>
