<i18n src="@/i18n/components/user-trend/user-trend-scatter.json"></i18n>
<template>
  <div class="userTrendScatter" @click="onClickChart">
    <slot></slot>
    <div
      ref="scatterArea"
      class="scatter-area"
      data-cy="user-trend-scatter--area"
    />
    <UserTrendTooltip
      v-if="showTooltip"
      class="userTrendScatter__tooltip"
      data-cy="user-trend-scatter-tooltip"
      :type="scatterType"
      :point="tooltipPoint"
      :page-type="pageType"
      :style="tooltipStyle"
      @click="onClickUserIcon"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import * as Highcharts from "highcharts";
import { UserTrendChartPoint } from "@/models/user-trend/UserTrendChartPoint";
import { UserTrendScatterType } from "@/models/user-trend/UserTrendScatterType";
import { AnalysisType, UserTrendScatterXAxisType } from "@/const/user-trend";
import UserTrendTooltip from "@/components/user-trend/scatter/UserTrendTooltip.vue";
import { TOOLTIP_WIDTH_250, TOOLTIP_HEIGHT } from "@/const/user-trend";
import { LIMIT_CHART_POINT_NUMBER } from "@/const/user-trend";

// 散布図の軸の目盛りの文字サイズ
const CHART_FONT_SIZE = "14px";

// 散布図の軸のタイトルの文字サイズ
const CHART_TITLE_FONT_SIZE = "16px";

// top, left(CSS)の原点とtooltip自身の原点との差分オフセット
const TOOLTIP_SELF_OFFSET_LEFT = 78;
const TOOLTIP_SELF_OFFSET_TOP = 12;

// 散布図の点とtooltipが重ならないためのオフセット
const OFFSET_FOR_OVERLAP = 10;

interface TooltipStyle {
  left: string;
  top: string;
}

@Component({
  components: {
    UserTrendTooltip
  }
})
export default class UserTrendScatter extends Vue {
  // ToDo
  // - CSSの適正化

  // tooltip用の変数
  showTooltip: boolean = false;
  dotClicked: boolean = false;
  tooltipFixed: boolean = false;
  tooltipStyle: TooltipStyle = { left: "0px", top: "0px" };
  tooltipPoint!: UserTrendChartPoint;

  // 散布図で表示したいユーザートレンドのデータ
  @Prop({ type: Array, required: true })
  chartPoints!: UserTrendChartPoint[];

  @Prop({ type: String, required: true })
  pageType!: AnalysisType;

  @Prop({ type: UserTrendScatterType, required: true })
  scatterType!: UserTrendScatterType;

  // tooltipからユーザ一覧への導線
  onClickUserIcon(userIds: string[]) {
    this.$emit("click-user-icon", userIds);
  }

  @Watch("pageType")
  onChangePageType() {
    this.makeScatterData();
    this.drawHighchart();
  }

  @Watch("scatterType")
  onChangeScatterType() {
    this.makeScatterData();
    this.drawHighchart();
  }

  mounted() {
    this.makeScatterData();
    this.drawHighchart();
  }

  // tooltip固定時に散布図内のクリックで非表示に
  onClickChart() {
    if (!this.dotClicked) {
      this.tooltipFixed = false;
      this.showTooltip = false;
    }
    this.dotClicked = false;
  }

  // Propのデータを散布図用に加工する
  makeScatterData() {
    for (const data of this.chartPoints) {
      data.updateXAxisValue(this.scatterType.xAxisType);
      data.updateYAxisValue(this.scatterType.yAxisType);
    }
  }

  get XAxisName(): string {
    if (this.scatterType.xAxisType === UserTrendScatterXAxisType.count) {
      return this.pageType === AnalysisType.AppView
        ? this.$i18n.t("viewCount").toString()
        : this.$i18n.t("pv").toString();
    }

    if (this.scatterType.xAxisType === UserTrendScatterXAxisType.visit) {
      return this.$i18n.t("visit").toString();
    }

    return this.$i18n.t("uu").toString();
  }

  get YAxisName(): string {
    return this.$i18n.t(this.scatterType.yAxisType) as string;
  }

  // inputの型には、 EventTarget または Highcharts.Point が入る
  // mouseOverの場合 EventTarget で、clickの場合 Highcharts.Point となる
  private drawTooltip(input: any) {
    if (this.tooltipFixed) {
      return;
    }

    const x: number = input.plotX;
    const y: number = input.plotY;
    const point = new UserTrendChartPoint(
      input.userIds,
      input.aggregateKeys,
      input.data
    );

    this.showTooltip = false;
    this.tooltipPoint = point;

    const parent = this.$refs.scatterArea as Element;
    let offsetX = 0;
    let offsetY = 0;

    // tooltipが画面端で見切れないようにする処置
    // 点が画面の左側にある時は右側に、右の時は左に表示
    if (x < parent.clientWidth / 2.0) {
      offsetX = OFFSET_FOR_OVERLAP;
    } else {
      offsetX = -TOOLTIP_WIDTH_250 - OFFSET_FOR_OVERLAP;
    }
    // 点が散布図の上側にある時は下側に、下の時は上に表示
    if (y < parent.clientHeight / 2.0) {
      offsetY = OFFSET_FOR_OVERLAP;
    } else {
      offsetY = -TOOLTIP_HEIGHT - OFFSET_FOR_OVERLAP;
    }

    // 散布図内の点の位置に応じてtooltipを表示
    this.tooltipStyle = {
      left: x + offsetX + TOOLTIP_SELF_OFFSET_LEFT + "px",
      top: y - parent.clientHeight + offsetY + TOOLTIP_SELF_OFFSET_TOP + "px"
    };
    this.showTooltip = true;
  }

  // マウント時や、データ期間・軸設定の変更時に散布図を更新
  private drawHighchart() {
    this.showTooltip = false;
    Highcharts.chart({
      chart: {
        renderTo: this.$refs.scatterArea as Highcharts.HTMLDOMElement,
        height: 650,
        type: "scatter",
        zoomType: "xy",
        marginRight: 50
      },
      title: {
        text: ""
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        min: 0,
        title: {
          text: this.XAxisName,
          style: {
            fontSize: CHART_TITLE_FONT_SIZE,
            fontWeight: "bold"
          }
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        labels: {
          style: {
            fontSize: CHART_FONT_SIZE
          }
        }
      },
      yAxis: [
        {
          // Y1軸
          endOnTick: false,
          title: {
            text: this.YAxisName,
            style: {
              fontSize: CHART_TITLE_FONT_SIZE,
              fontWeight: "bold"
            }
          },
          labels: {
            style: {
              fontSize: CHART_FONT_SIZE
            }
          }
        }
      ],
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          point: {
            events: {
              mouseOver: e => {
                this.drawTooltip(e.target);
              },
              click: e => {
                this.dotClicked = true;

                // クリックした点の情報を描画させるために tooltipFixed を解除し描画したのちに固定化させるために tooltipFixed をtrueにする
                this.tooltipFixed = false;
                this.drawTooltip(e.point);
                this.tooltipFixed = true;
              }
            }
          },
          marker: {
            states: {
              hover: { enabled: false }
            }
          },
          turboThreshold: LIMIT_CHART_POINT_NUMBER
        },

        scatter: {
          marker: {
            radius: 5,
            symbol: "square",
            states: {
              hover: {
                enabled: true,
                lineColor: Colors.Base800
              }
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false,
        snap: 1,
        positioner: (w, h, p) => {
          const { x, y } = p as Highcharts.Point;
          return {
            x: x as number,
            y: y as number
          };
        }
      },
      series: [
        {
          type: "scatter",
          name: "Others",
          yAxis: 0,
          color: Colors.UserTrendScatter,
          data: this.chartPoints
        }
      ]
    });
  }
}
</script>

<style scoped lang="scss">
.userTrendScatter {
  width: 100%;
  height: 650px;
}
.userTrendScatter__tooltip {
  position: relative;
}
</style>
