import Vue from "vue";
import { Store } from "vuex";
import Router, {
  RawLocation,
  Route,
  NavigationGuard,
  NavigationFailureType
} from "vue-router";
import {
  ErrorHandler,
  RouteConfigMultipleViews,
  RouteConfigSingleView
} from "vue-router/types/router";
import Login from "@/views/Login.vue";
import UserSearchResult from "@/views/UserSearchResult.vue";
import store, { RootState } from "@/store";
import UserTrend from "@/views/UserTrend.vue";
import MemoUser from "@/views/MemoUser.vue";
import ForgetPassword from "@/views/ForgetPassword.vue";
import { showAlert } from "@/util/modal-util";
import { UgTag } from "@/store/modules/ugTag";
import { i18n } from "@/i18n";
import { LoginUser } from "@/models/auth/UgUser";
import { Client } from "@/models/Client";
import { AnalysisType } from "@/const/user-trend";
import { LagCvPeriodType } from "@/models/lag-cv/LagCvUserCount";
import ClientSettingsTop from "@/views/client-settings/ClientSettingsTop.vue";
import TourIndex from "@/views/TourIndex.vue";
import TourDetail from "@/views/TourDetail.vue";
import { validateTourPermission } from "@/util/tour-util";
import ConversionSettings from "@/views/client-settings/conversion/ConversionSettings.vue";
import ConversionSettingsIndex from "@/views/client-settings/conversion/ConversionSettingsIndex.vue";
import ConversionSettingsMeasurementState from "@/views/client-settings/conversion/ConversionSettingsMeasurementState.vue";
import ConversionSettingsCreate from "@/views/client-settings/conversion/ConversionSettingsCreate.vue";
import ConversionSettingsEdit from "@/views/client-settings/conversion/ConversionSettingsEdit.vue";
import TdWebImportConfigDetails from "@/views/client-settings/TdWebImportConfigDetails.vue";
import TdConversionSettings from "@/views/client-settings/TdConversionSettings.vue";
import TdConversionCreateAndUpdate from "@/views/client-settings/TdConversionCreateAndUpdate.vue";
import TdConversionAttributeSettings from "@/views/client-settings/TdConversionAttributeSettings.vue";
import TdConversionUpdateMeasurementState from "@/views/client-settings/TdConversionUpdateMeasurementState.vue";
import TdConversionAttributeCreateAndUpdate from "@/views/client-settings/TdConversionAttributeCreateAndUpdate.vue";
import TdConversionAttributeUpdateMeasurementState from "@/views/client-settings/TdConversionAttributeUpdateMeasurementState.vue";
import CoordinationConversionSettingsCreate from "@/views/client-settings/coordination/conversion/CoordinationConversionSettingsCreate.vue";
import MfaSetup from "@/views/MfaSetup.vue";
import TotpInput from "@/views/TotpInput.vue";
import CoordinationEventSettingsCreate from "@/views/client-settings/coordination/event/CoordinationEventSettingsCreate.vue";
import CoordinationConversionAttributeSettingsCreate from "@/views/client-settings/coordination/conversion-attribute/CoordinationConversionAttributeSettingsCreate.vue";

interface RouteMeta {
  allowPublicAccess?: boolean;
  shouldHideHeaderAndSideBar?: boolean;
  layout?: string;
  additionalGuard?: (store: Store<RootState>) => boolean;
}

// This is a workaround to enforce typecheck for RouteMeta.
// vue-router@v3 doesn't support extending RouteMeta interface.
// https://next.router.vuejs.org/guide/advanced/meta.html#typescript
interface ExtendedRouteConfigSingleView extends RouteConfigSingleView {
  meta?: RouteMeta;
}
interface ExtendedRouteConfigMultipleViews extends RouteConfigMultipleViews {
  meta?: RouteMeta;
}
type ExtendedRouteConfig =
  | ExtendedRouteConfigSingleView
  | ExtendedRouteConfigMultipleViews;

Vue.use(Router);

// Save original push and replace methods
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// @ts-ignore
Router.prototype.push = function push(
  location: RawLocation,
  onComplete?: ((route: Route) => void) | undefined,
  onAbort?: ErrorHandler
): Promise<Route> | void {
  if (onComplete || onAbort) {
    return originalPush.call(this, location, onComplete, onAbort);
  }
  const push: (to: RawLocation) => Promise<Route> = originalPush;
  return push.call(this, location).catch(error => {
    if (!Router.isNavigationFailure(error, NavigationFailureType.duplicated)) {
      return Promise.reject(error);
    }
    return Promise.resolve(this.currentRoute);
  });
};

// @ts-ignore
Router.prototype.replace = function replace(
  location: RawLocation,
  onComplete?: ((route: Route) => void) | undefined,
  onAbort?: ErrorHandler
): Promise<Route> | void {
  if (onComplete || onAbort) {
    return originalReplace.call(this, location, onComplete, onAbort);
  }
  const replace: (to: RawLocation) => Promise<Route> = originalReplace;
  return replace.call(this, location).catch(error => {
    if (!Router.isNavigationFailure(error, NavigationFailureType.duplicated)) {
      return Promise.reject(error);
    }
    return Promise.resolve(this.currentRoute);
  });
};

export const isTdImportSettingsAvaliable = (store: Store<RootState>) =>
  !!store.state.client.client && store.state.client.client.isContractTdImport;

const routes: ExtendedRouteConfig[] = [
  {
    path: "/users",
    name: "users",
    component: UserSearchResult
  },
  {
    path: "/memo-users",
    name: "memo-users",
    component: MemoUser
  },
  {
    path: "/users/:userId",
    name: "user-detail",
    component: UserSearchResult
  },
  {
    path: "/link/:link",
    name: "before-framework-permalink",
    component: UserSearchResult
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { allowPublicAccess: true }
  },
  {
    path: "/forget-password/:encoded_login_name?/:token?",
    name: "forget-password",
    component: ForgetPassword,
    meta: { allowPublicAccess: true, shouldHideHeaderAndSideBar: true }
  },
  // analysisは削除されたページですが、user-trendへのリダイレクトを設定しているため削除不要
  {
    path: "/analysis",
    children: [
      {
        path: "",
        redirect: "/user-trend/web-views"
      },
      {
        path: "pv",
        redirect: "/user-trend/web-views"
      },
      {
        path: "landing",
        redirect: "/user-trend/landings"
      },
      {
        path: "inflow",
        redirect: "/user-trend/inflows"
      },
      {
        path: "inflow-detail",
        redirect: "/user-trend/inflow-details"
      }
    ]
  },
  {
    path: "/user-trend",
    component: UserTrend,
    children: [
      {
        path: "web-views",
        name: AnalysisType.WebView,
        component: UserTrend
      },
      {
        path: "landings",
        name: AnalysisType.Landing,
        component: UserTrend
      },
      {
        path: "inflows",
        name: AnalysisType.Inflow,
        component: UserTrend
      },
      {
        path: "inflow-details",
        name: AnalysisType.InflowDetail,
        component: UserTrend
      },
      {
        path: "app-views",
        name: AnalysisType.AppView,
        component: UserTrend
      }
    ]
  },
  {
    path: "/mfa",
    name: "mfa-setup",
    component: MfaSetup,
    meta: {
      layout: "Mfa"
    }
  },
  {
    path: "/totp",
    name: "totp-input",
    component: TotpInput,
    meta: {
      layout: "Mfa"
    }
  },
  {
    path: "/tours",
    name: "tours",
    component: TourIndex,
    beforeEnter: validateTourPermission
  },
  {
    path: "/tours/:id",
    name: "tour-detail",
    component: TourDetail,
    beforeEnter: validateTourPermission
  },
  {
    path: "/client-settings/",
    name: "client-settings-top",
    component: ClientSettingsTop,
    meta: {
      layout: "Settings"
    },
    pathToRegexpOptions: { strict: true }
  },
  {
    path: "/client-settings/conversion",
    name: "conversion-settings",
    component: ConversionSettings,
    redirect: { name: "conversion-settings-index" },
    children: [
      {
        path: "index",
        name: "conversion-settings-index",
        component: ConversionSettingsIndex,
        meta: {
          layout: "Settings"
        }
      },
      {
        path: ":type(enable|disable)/:id(\\d+)",
        name: "conversion-settings-measurement-state",
        component: ConversionSettingsMeasurementState,
        meta: {
          layout: "Settings"
        }
      },
      {
        path: "create",
        name: "conversion-settings-create",
        component: ConversionSettingsCreate,
        meta: {
          layout: "Settings"
        }
      },
      {
        path: "update/:id(\\d+)",
        name: "conversion-settings-update",
        component: ConversionSettingsEdit,
        meta: {
          layout: "Settings"
        }
      }
    ]
  },
  {
    path:
      "/client-settings/coordination/:coordinationId(\\d+)/conversion/create",
    name: "coordination-conversion-settings-create",
    component: CoordinationConversionSettingsCreate,
    meta: {
      layout: "Settings"
    }
  },
  {
    path: "/client-settings/coordination/:coordinationId(\\d+)/event/create",
    name: "coordination-event-settings-create",
    component: CoordinationEventSettingsCreate,
    meta: {
      layout: "Settings"
    }
  },
  {
    path:
      "/client-settings/coordination/:coordinationId(\\d+)/cvAttribute/create",
    name: "coordination-conversion-attribute-settings-create",
    component: CoordinationConversionAttributeSettingsCreate,
    meta: {
      layout: "Settings"
    }
  },
  {
    path: "/client-settings/treasure-data/td-web-import-config",
    name: "td-web-import-config-details",
    component: TdWebImportConfigDetails,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path: "/client-settings/treasure-data/td-conversion",
    name: "td-conversion-settings",
    component: TdConversionSettings,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path: "/client-settings/treasure-data/td-conversion/create",
    name: "td-conversion-create",
    component: TdConversionCreateAndUpdate,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path: "/client-settings/treasure-data/td-conversion/update/:id(\\d+)",
    name: "td-conversion-update",
    component: TdConversionCreateAndUpdate,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path:
      "/client-settings/treasure-data/td-conversion/measurement-state/:id(\\d+)",
    name: "td-conversion-update-measurement-state",
    component: TdConversionUpdateMeasurementState,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },

  {
    path: "/client-settings/treasure-data/td-conversion-attr",
    name: "td-conversion-attribute-settings",
    component: TdConversionAttributeSettings,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path: "/client-settings/treasure-data/td-conversion-attr/create",
    name: "td-conversion-attribute-create",
    component: TdConversionAttributeCreateAndUpdate,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path: "/client-settings/treasure-data/td-conversion-attr/update/:id(\\d+)",
    name: "td-conversion-attribute-update",
    component: TdConversionAttributeCreateAndUpdate,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path:
      "/client-settings/treasure-data/td-conversion-attr/measurement-state/:id(\\d+)",
    name: "td-conversion-attribute-update-measurement-state",
    component: TdConversionAttributeUpdateMeasurementState,
    meta: {
      layout: "Settings",
      additionalGuard: isTdImportSettingsAvaliable
    }
  },
  {
    path: "*",
    beforeEnter: () => {
      window.location.assign("/"); // Redirects to home page with full reload
    }
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // Always reset to 0 in client-settings page
      if (to.path.startsWith("/client-settings")) {
        return { x: 0, y: 0 };
      }
      // 検索結果 & 分析ツールなどタブを使用したページ移動ではリセットしない
      if (
        from.matched.length > 0 &&
        to.matched[0].path !== from.matched[0].path
      ) {
        return { x: 0, y: 0 };
      }
    }
  }
});

export const beforeEachClientSettings = async (
  store: Store<RootState>,
  to: Route,
  _: Route,
  next: (to?: RawLocation | false | void) => void
) => {
  if (!store.state.auth.isInitialized) {
    await store.dispatch("auth/initialize");
  }

  if (store.state.auth.isAuthenticated) {
    await verifySessionWhenMfaIsEnabled();
    if (isMfaRequired() && !isMfaEnabled()) {
      router.push({
        name: "mfa-setup"
      });
      return;
    }

    if (isMfaEnabled() && !isMfaVerified()) {
      location.assign("/totp");
      return;
    }

    if (!store.state.app.isSettingsInitialized) {
      await store.dispatch("app/initializeSettings");
    }

    if (to.meta && to.meta.additionalGuard && !to.meta.additionalGuard(store)) {
      location.assign("/");
      return;
    }

    const loginUser: LoginUser | null = store.state.auth.user;
    if (loginUser && loginUser.permission.isAvailableClientSettings) {
      next();
    } else {
      location.assign("/");
    }
    return;
  }

  next({ name: "login", query: { redirect: to.fullPath } });
  return;
};

export const beforeEach: NavigationGuard<Vue> = async (to, from, next) => {
  await mfaPageNavigationGuard(to, from, next);

  if (to.path.startsWith("/client-settings")) {
    try {
      await beforeEachClientSettings(store, to, from, next);
    } catch (e) {
      showAlert(i18n.t("util.errorUtil.common-error-message") as string);
    }
    return;
  }

  // Refresh search navigation not to keep previous conditions
  clearSelectCondition(to, from);

  // ブラウザのバックボタン対応、観察画面からユーザ一覧に行った場合は観察画面を消す
  if (
    from.name === "user" &&
    (to.name === "attribute" || to.name === "overview" || to.name === "memo")
  ) {
    store.commit("user/setShowUserDetail", false);
  }

  if (store.state.auth.isAuthenticated) {
    if (from.name) {
      handleBrowserBackOnMfaPages(from.name);
    }

    if (isSuperUser()) {
      await redirectOwnerUser(to.path);
    }

    if (!store.state.app.isInitialized) {
      if (wontCheckMfa()) {
        store.dispatch("app/initialize");
      }
    }

    // If an already logged-in user wants to open login-page, redirect to home
    if (to.name === "login") {
      location.assign("/");
      return;
    }

    next();
    return;
  }

  if (store.state.auth.isInitialized) {
    if (to.matched.some(record => record.meta.allowPublicAccess)) {
      next();
      return;
    }
    next({ name: "login" });
    return;
  }

  try {
    await store.dispatch("auth/initialize");

    if (isSuperUser()) {
      await redirectOwnerUser(to.path);
    }

    const loginUser: LoginUser | null = store.state.auth.user;
    if (loginUser) {
      if (
        to.path.startsWith("/user-trend") &&
        !loginUser.permission.isAvailableUserTrend
      ) {
        location.assign("/");
        return;
      }

      if (to.name === "users" && !loginUser.permission.isAvailableUserList) {
        location.assign("/");
        return;
      }
    }

    if (store.state.auth.isAuthenticated) {
      await verifySessionWhenMfaIsEnabled();
      if (isMfaRequired() && !isMfaEnabled()) {
        router.push({
          name: "mfa-setup"
        });
        return;
      }

      if (isMfaEnabled() && !isMfaVerified()) {
        location.assign("/totp");
        return;
      }

      if (wontCheckMfa()) {
        await store.dispatch("app/initialize");
      }

      if (to.name && to.name.startsWith("funnel-analysis")) {
        const loginClient: Client | null = store.state.client.client;
        if (
          !(
            loginUser != null &&
            loginClient != null &&
            loginUser.permission.isAvailableUserList &&
            loginClient.hasFunnelAnalysisContract
          )
        ) {
          location.assign("/");
          return;
        }
      }

      if (to.name === "login") {
        location.assign("/");
        return;
      }

      next();
      return;
    } else if (to.meta && to.meta.allowPublicAccess) {
      next();
      return;
    } else {
      if (to.name !== "login" && to.name !== "home") {
        next({ name: "login", query: { redirect: to.fullPath } });
      } else {
        next({ name: "login" });
      }
      return;
    }
  } catch (e) {
    showAlert(i18n.t("util.errorUtil.common-error-message") as string);
  }
};

router.beforeEach(beforeEach);

router.afterEach((to, from) => {
  const pageTitle = i18n.t("pageTitle." + to.name) as string;
  const usergram = i18n.t("pageTitle.usergram") as string;
  if (to.path.startsWith("/client-settings")) {
    const settings = i18n.t("pageTitle.settings").toString();
    document.title = pageTitle
      ? `${usergram} | ${settings} ( ${pageTitle} )`
      : usergram;
  } else {
    document.title = pageTitle ? `${pageTitle} | ${usergram}` : usergram;
  }

  if (from.name === null || to.path !== from.path) {
    Vue.nextTick(() => {
      UgTag.pushPv();
    });
  }
});

// historyIdを取った後にuesrsのURLを更新するための関数
// storeにrouterを持たせなくなので、外から関数を渡す
export function addHidToUsersUrl(
  router: Router
): (historyId: number, filterId?: number) => void {
  return (historyId: number, filterId: number = 0) => {
    const query = { ...router.currentRoute.query };
    if (filterId && router.currentRoute.name === "funnel-analysis-detail") {
      query.fid = String(filterId);
    } else {
      query.hid = String(historyId);
    }
    router.replace({ query });
    UgTag.pushPv();
  };
}

// queryIdを取った後にuser-trendのURLを更新するための関数
// storeにrouterを持たせなくないので、外から関数を渡す
export function addHidToUserTrendUrl(
  router: Router
): (queryId: string) => void {
  return (queryId: string) => {
    if (!router.currentRoute.path.startsWith("/user-trend")) {
      return;
    }
    const query = { ...router.currentRoute.query };
    query.hid = queryId;
    router.replace({ query });
    UgTag.pushPv();
  };
}

export const redirectOwnerUser = async (targetUrl: string) => {
  await verifySessionWhenMfaIsEnabled();

  if (wontCheckMfa() && targetUrl.indexOf("client-settings") === -1) {
    location.assign("/client-settings/");
    return;
  }
};

export const clearSelectCondition = (to: Route, from: Route) => {
  const pagesToKeepSelectCondition = [
    "users",
    "user-detail",
    "before-framework-permalink",
    "almost-cv",
    LagCvPeriodType.OneDay,
    LagCvPeriodType.OneWeek,
    LagCvPeriodType.FourWeeks
  ];

  if (
    to.name &&
    to.name !== from.name &&
    !pagesToKeepSelectCondition.includes(to.name)
  ) {
    store.dispatch("search/clearSelectCondition");
  }
};

export const verifySessionWhenMfaIsEnabled = async () => {
  if (isMfaRequired() && isMfaEnabled()) {
    await store.dispatch("mfa/verify", "");
  }
};

export const mfaPageNavigationGuard: NavigationGuard = async (to, {}) => {
  if (
    (to.name === "mfa-setup" || to.name === "totp-input") &&
    !store.state.auth.isInitialized
  ) {
    await store.dispatch("auth/initialize");
  }

  if (to.name === "mfa-setup") {
    if (!isMfaRequired() || isMfaEnabled()) {
      location.assign("/");
      return;
    }
  }

  if (to.name === "totp-input") {
    if (!isMfaRequired() || !isMfaEnabled()) {
      location.assign("/");
      return;
    }
  }
};

export const wontCheckMfa = (): boolean => !isMfaRequired() || isMfaVerified();

export const isMfaRequired = (): boolean => store.getters["auth/isMfaRequired"];

export const isMfaEnabled = (): boolean => store.getters["auth/isMfaEnabled"];

export const isMfaVerified = (): boolean => store.state.mfa.verified;

export const isSuperUser = (): boolean => {
  const { user } = store.state.auth;
  return !!user && user.isSuperUser;
};

export const handleBrowserBackOnMfaPages = async (fromName: string) => {
  const mfaRouteNames = ["mfa-setup", "totp-input"];

  if (!mfaRouteNames.includes(fromName)) return;

  if (isMfaRequired() && !isMfaEnabled()) {
    location.assign("/mfa");
    return;
  }

  if (isMfaEnabled() && !isMfaVerified()) {
    location.assign("/totp");
    return;
  }
};

export default router;
