import { MutationTree, GetterTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { GlobalConversion } from "@/api/apis/ApiSystem";
import { SearchEngine } from "@/models/system/SearchEngine";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { GlobalConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { i18n } from "@/i18n";

export const enum GlobalConversionQueryType {
  FirstOpen = "__first_open",
  AppRemove = "__app_remove"
}

export class SystemState {
  searchEngines: SearchEngine[] = [];
  clientPvStats: ClientPvStats | null = null;
  globalConversionJson: GlobalConversion[] = [];
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[] = [];
}

const mutations: MutationTree<SystemState> = {
  setSearchEngines(state: SystemState, searchEngines: SearchEngine[]) {
    state.searchEngines = searchEngines;
  },
  setClientPvStats(state: SystemState, clientPvStats: ClientPvStats) {
    state.clientPvStats = clientPvStats;
  },
  setGlobalConversionJson(
    state: SystemState,
    globalConversionJson: GlobalConversion[]
  ) {
    state.globalConversionJson = globalConversionJson;
  },
  setGlobalConversionAttributeDefinitions(
    state: SystemState,
    globalConversionAttribute: GlobalConversionAttributeDefinition[]
  ) {
    state.globalConversionAttributeDefinitions = globalConversionAttribute;
  },
  initialize(state: SystemState) {
    state.searchEngines = [];
    state.clientPvStats = null;
    state.globalConversionJson = [];
  }
};

const getters: GetterTree<SystemState, RootState> = {
  activeGlobalConversionDefinitions(state): GlobalConversionDefinition[] {
    // 表示する順番とCV名の翻訳を行う
    const globalConversions: GlobalConversionDefinition[] = [];

    // 初回起動
    const firstOpen = state.globalConversionJson.find(
      cv =>
        cv.path.length > 0 && cv.path[0] === GlobalConversionQueryType.FirstOpen
    );
    if (firstOpen !== undefined) {
      firstOpen.name = i18n.t("models.system.initialLaunch") as string;
      globalConversions.push(GlobalConversionDefinition.fromJson(firstOpen));
    }

    // アプリ削除
    const appRemove = state.globalConversionJson.find(
      cv =>
        cv.path.length > 0 && cv.path[0] === GlobalConversionQueryType.AppRemove
    );
    if (appRemove !== undefined) {
      appRemove.name = i18n.t("models.system.DeleteApp") as string;
      globalConversions.push(GlobalConversionDefinition.fromJson(appRemove));
    }
    return globalConversions;
  }
};

const actions: ActionTree<SystemState, RootState> = {
  async fetchSearchEngines({ commit, rootState }) {
    const body = await rootState.api.system.getSearchEngineList();
    const searchEngines: SearchEngine[] = body.search_engines.map(
      SearchEngine.fromJson
    );
    commit("setSearchEngines", searchEngines);
  },
  async fetchPvStats({ commit, rootState }) {
    const body = await rootState.api.clientPv.getClientPvStats();
    const clientPvStats: ClientPvStats = ClientPvStats.fromJson(body);
    commit("setClientPvStats", clientPvStats);
  },
  async fetchConversionDefinitions({ commit, rootState }) {
    const body = await rootState.api.system.getGlobalConversionList();

    // ここでの翻訳は行わず、受けとったものをstoreにセットする
    commit("setGlobalConversionJson", body.conversions);
  },
  async fetchConversionAttributeDefinitions({ commit, rootState }) {
    const body = await rootState.api.system.getGlobalConversionAttributeList();
    const globalConversionAttributes: GlobalConversionAttributeDefinition[] = body.conversion_attributes.map(
      GlobalConversionAttributeDefinition.fromJson
    );
    commit(
      "setGlobalConversionAttributeDefinitions",
      globalConversionAttributes
    );
  }
};

export const system = {
  namespaced: true,
  state: new SystemState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
