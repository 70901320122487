export interface SelectCheckBoxItem {
  id: number;
  name: string;
}

export interface SelectCheckBoxItems {
  parent: SelectCheckBoxItem;
  children: SelectCheckBoxItem[];
}

export enum FormPartsSize {
  Medium = "medium",
  Small = "small"
}

export enum PulldownAlign {
  Left = "left",
  Right = "right"
}
