<i18n src="@/i18n/components/observation/visit/gram-inflow.json"></i18n>

<template>
  <div
    class="ud-Visit_Gram ud-Visit_Gram_Inflow"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_Circle">
        <Icon :icon="inflowRight" :size="17" :color="iconColor" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_InflowTitle">{{ title }}</div>

      <div class="ud-Visit_InflowAttrs">
        <div v-if="locationReferrer" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("referrer") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ locationReferrer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import Icon from "@/components/Icon.vue";
import { Gram } from "@/models/Gram";
import { Colors } from "@/const/Colors";

@Component({
  components: { Icon }
})
export default class GramInflowAd extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  inflowRight = Icons.InflowRight;
  iconColor = Colors.Blue900;

  get title(): string {
    return this.$i18n.t("adInflow") as string;
  }

  get locationReferrer(): string {
    return this.gram.pvLocationReferrer;
  }
}
</script>
