import HttpClient from "@/api/HttpClient";
import { ValueInputMethod } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ApiRes } from "@/api/api-res";
import { ApiUrl } from "@/api/api-url";
import { Sec, postDate } from "@/util/date-util";

import { FilterEdgeType } from "@/models/search/filter-node/FilterEdge";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  NpsInputMethod,
  NpsScoreCategory
} from "@/models/search/additional-condition/NpsCondition";
import {
  SelectCondition,
  convertSelectConditionToJson
} from "@/models/search/select-condition/SelectCondition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { ClusteringPattern } from "@/models/search/ClusteringPattern";
import { DeviceType, FilterPeriodDays } from "@/const/filter";

export const labelDataTypes = [
  "pv",
  "cv",
  "pv_week_cnt",
  "cv_week_cnt",
  "leadtime"
] as const;
export type ClusteringLabelDataType = typeof labelDataTypes[number];

export const labelDataValues = [1, 2, 3];
export type ClusteringLabelDataValue = typeof labelDataValues[number];

export interface ClusteringLabelData {
  type: ClusteringLabelDataType;
  value: ClusteringLabelDataValue;
}

export interface ClusteringPatternData {
  label: ClusteringLabelData[];
  num_user: number;
  cached_cluster_id: string;
  is_calculated: boolean;
}
export interface ClusteringListData {
  clusters: ClusteringPatternData[];
}

export default class ApiSearch {
  constructor(private readonly httpClient: HttpClient) {}

  async selectUser(
    selectCondition: SelectCondition,
    npsDefinition: NpsDefinition | null
  ): Promise<number | null> {
    const params: SelectUserApiRequestParams | null = convertSelectConditionToJson(
      selectCondition,
      npsDefinition
    );
    if (params === null) {
      return null;
    }

    const res = await this.httpClient.post<{ history_id: number }>(
      ApiUrl.SELECT_USER,
      params
    );

    return res.history_id;
  }

  async filterUser(
    historyId: number,
    filterApiParams: FilterApiParams
  ): Promise<{ hid: number; fid: number }> {
    const res = await this.httpClient.post<{
      history_id: number;
      filter_id: number;
    }>(ApiUrl.FILTER_USER, {
      filter_condition: filterApiParams,
      history_id: historyId
    });

    return { hid: res.history_id, fid: res.filter_id };
  }

  /**
   * historyIdで指定した検索にマッチするレコードの件数を問い合わせる。
   * 検索が終わっていれば件数を、そうでなければnullを返す。
   */
  async *getSelectCount(
    historyId: number
  ): AsyncIterableIterator<number | null> {
    const getResponse = () =>
      this.httpClient.post<ApiRes.SelectCountResult>(ApiUrl.NUM_FOUND_USERS, {
        history_id: historyId
      });

    let countResult: ApiRes.SelectCountResult = await getResponse();

    yield countResult.search_finished ? countResult.num_found_users : null;

    while (!countResult.search_finished) {
      countResult = await getResponse();
      yield countResult.search_finished ? countResult.num_found_users : null;
    }
  }

  async *getFilterCount(
    historyId: number
  ): AsyncIterableIterator<ApiRes.FilterCountResult> {
    const getResponse = () =>
      this.httpClient.post<ApiRes.FilterCountResult>(ApiUrl.NUM_FOUND_USERS, {
        history_id: historyId
      });

    let countResult: ApiRes.FilterCountResult = await getResponse();

    yield countResult;

    while (!countResult.search_finished) {
      countResult = await getResponse();
      yield countResult;
    }
  }

  async *getSelectUserResult(
    historyId: number,
    chunkSize: number = 50
  ): AsyncIterableIterator<ApiRes.SelectSearchResult> {
    const getResponse = (offset: number) =>
      this.httpClient.post<ApiRes.SelectSearchResult>(ApiUrl.USER, {
        offset_limit: {
          user_number_offset: offset,
          user_number_limit: chunkSize
        },
        history_id: historyId
      });

    let offset = 0;
    let res = await getResponse(offset);
    offset += res.users.length;
    yield res;

    while (!res.search_finished || res.num_found_users > offset) {
      res = await getResponse(offset);
      offset += res.users.length;
      yield res;
    }
  }

  async *getFilterUserResult(
    historyId: number,
    chunkSize: number = 10
  ): AsyncIterableIterator<ApiRes.FilterSearchResult> {
    const getResponse = (offset: number) =>
      this.httpClient.post<ApiRes.FilterSearchResult>(ApiUrl.USER, {
        offset_limit: {
          user_number_offset: offset,
          user_number_limit: chunkSize
        },
        history_id: historyId
      });

    let offset = 0;
    let res = await getResponse(offset);
    offset += res.users.length;
    yield res;

    while (!res.search_finished || res.num_filtered_users > offset) {
      res = await getResponse(offset);
      offset += res.users.length;
      yield res;
    }
  }

  async *getClusteringUserResult(
    historyId: number,
    pattern: ClusteringPattern
  ): AsyncIterableIterator<{ users: ApiRes.User[] }> {
    const chunkSize: number = 10;
    const getResponse = (offset: number) =>
      this.httpClient.post<{ users: ApiRes.User[] }>(ApiUrl.CLUSTER_USER, {
        cached_cluster_id: pattern.cachedId,
        offset_limit: {
          user_number_offset: offset,
          user_number_limit: chunkSize
        },
        history_id: historyId
      });

    let offset = 0;
    let res = await getResponse(offset);
    offset += res.users.length;
    yield res;

    while (offset < pattern.userCount) {
      res = await getResponse(offset);
      offset += res.users.length;
      yield res;
    }
  }

  async getClusteringList(
    endDate: Date,
    historyId: number
  ): Promise<ClusteringListData> {
    return this.httpClient.post<ClusteringListData>(ApiUrl.CLUSTER_LIST, {
      end_date: postDate(endDate),
      history_id: historyId
    });
  }

  // isFilter: 絞り込み後の時 true
  // appUserTimezone: コンバージョン日時などを適切に表示するためにタイムゾーンを渡す
  public getCsv(historyId: number, appUserTimezone: number): Promise<string> {
    return this.httpClient.get<string>(ApiUrl.USER_LIST_CSV_URL, {
      history_id: historyId,
      app_user_timezone: appUserTimezone
    });
  }
}

export type SelectUserApiRequestParams = {
  select_method: string;
  selection: SelectQuery;
};

export type SelectQuery =
  | SelectByConversionQuery
  | SelectByServiceIdQuery
  | SelectByContactQuery
  | SelectByBusinessEventQuery
  | SelectByNpsQuery
  | SelectByNpsChangeQuery
  | SelectByUserIdQuery
  | SelectByEngagementQuery
  | SelectByUserJourneyQuery;

export interface SelectByServiceIdQuery {
  start_time_sec: Sec;
  end_time_sec: Sec;
  include_no_cv_users: boolean;
  service_ids: string[];
}

export interface SelectByConversionQuery {
  conversion_ids: number[];
  start_time_sec: Sec;
  end_time_sec: Sec;
  utc_time_period: number;
  sub_cnds: AdditionalConditionParams[];
}

/**
 * 顧客ロイヤルティによるユーザ選定時のパラメータ(OMO版のみ)
 */
export interface SelectByNpsQuery {
  change: null;
  score_time: {
    end_time_sec: Sec;
    category: number | null;
    val: number | null;
    val_from: number;
    val_to: number;
  };
  sub_cnds: AdditionalConditionParams[];
}

export interface SelectByNpsChangeQuery {
  change: {
    curr: {
      category: number;
      val_from: number;
      val_to: number;
    };
    prev_list: {
      category: number;
      val_from: number;
      val_to: number;
    }[];
  };
  score_time: null;
  sub_cnds: AdditionalConditionParams[];
}

/**
 * 行動履歴によるユーザ選定時のパラメータ(OMO版のみ)
 */
export interface SelectByContactQuery {
  contact_def_ids: number[];
  end_time_sec: Sec;
  start_time_sec: Sec;
  utc_time_period: number;
  sub_cnds: AdditionalConditionParams[];
}

/**
 * ビジネスイベントによるユーザ選定時のパラメータ(OMO版のみ)
 */
export interface SelectByBusinessEventQuery {
  be_def_ids: number[];
  end_time_sec: Sec;
  start_time_sec: Sec;
  utc_time_period: number;
  sub_cnds: AdditionalConditionParams[];
}

/**
 * ユーザIDによるユーザ選定時のパラメータ
 */
export interface SelectByUserIdQuery {
  users: {
    user_id: string;
    select_method_type: number;
    start_time_sec: Sec;
    end_time_sec: Sec;
    utc_time_period: number;
    conversion_ids: number[];
    sub_cnds: AdditionalConditionParams[];
  }[];
}

export type SelectByUserJourneyQuery = {
  user_visit_info_query_id: string;
  select_method_type: number;
  path_id: number;
  step_index: number;
  journey_type: JourneyType;
  user_list_type: UserListType;
  start_time_sec: number;
  end_time_sec: number;
  utc_time_period: number;
};

export type JourneyType = "MAIN" | "INTERMEDIATE";

export type UserListType =
  | "REMAIN_USERS"
  | "PATH_EXIT_CV_USERS"
  | "PATH_EXIT_NON_CV_USERS";

/**
 * エンゲージ検索時のパラメータ
 */
export interface SelectByEngagementQuery {
  analysis_type: number;
  region: number;
  hosts: string[];
  base_date: {
    start_date: string;
    end_date: string;
  };
  comparison_date: {
    start_date: string;
    end_date: string;
  };
  conversion_id: number | null;
  min_percent: number | null;
  max_percent: number | null;
  increase_decrease: number;
  sub_cnds: AdditionalConditionParams[];
}

/**
 * ========================================================================
 * AttributeCondition 関連
 * ========================================================================
 */

/**
 * Type of additionalCondition set at POST.
 */
export enum AttributeConditionParamType {
  ConversionAttribute = 1,
  Loyalty = 2,
  BusinessIndex = 3,
  UserAttribute = 4,
  ContactAttribute = 5,
  BusinessEventAttribute = 6
}

export enum LoyaltyType {
  Nps = 1,
  Enquete = 2
}

/**
 * 追加検索条件パラメータ
 */
export type AdditionalConditionParams =
  | ConversionAttributeConditionParams
  | ConversionAttributeTextConditionParams
  | ConversionAttributeNumberValueConditionParams
  | ConversionAttributeNumberRangeConditionParams
  | NpsConditionParams
  | EnqueteConditionParams
  | BusinessIndexConditionParams
  | UserAttributeConditionParams
  | BusinessEventAttributeConditionParams
  | ContactAttributeConditionParams;

/**
 * Base interface for an additional search condition parameters
 * for conversion & event attributes.
 */
export interface ConversionAttributeConditionParams {
  type: AttributeConditionParamType.ConversionAttribute;
  id: number;
  attributeType: AttributeType;
}

export interface ConversionAttributeTextConditionParams
  extends ConversionAttributeConditionParams {
  values: string[];
  word_match_method: MatchMethod;
}

export interface ConversionAttributeNumberConditionParams
  extends ConversionAttributeConditionParams {
  numberMatchMethod: NumberMatchMethod;
}
export interface ConversionAttributeNumberValueConditionParams
  extends ConversionAttributeNumberConditionParams {
  val: number;
}

export interface ConversionAttributeNumberRangeConditionParams
  extends ConversionAttributeNumberConditionParams {
  valFrom: number;
  valTo: number;
}

export enum AttributeType {
  TEXT = 1,
  NUMBER = 2
}

export enum NumberMatchMethod {
  EXACT = 1,
  LESS_THAN_OR_EQUAL_TO = 2,
  GREATER_THAN_OR_EQUAL_TO = 3,
  BETWEEN = 4
}

export interface NpsConditionParams {
  type: AttributeConditionParamType.Loyalty;
  id: number;
  npsType: LoyaltyType.Nps;
  totalCat: NpsScoreCategory;
  totalCatScoreType: NpsInputMethod;
  valRangeType: ValueInputMethod;
  valFrom: number;
  valTo: number;
}

export interface EnqueteConditionParams {
  type: AttributeConditionParamType.Loyalty;
  id: number;
  npsType: LoyaltyType.Enquete;
  totalCat: -1;
  totalCatScoreType: -1;
  valRangeType: ValueInputMethod;
  valFrom: number;
  valTo: number;
}

/**
 * ビジネス指標の追加検索条件パラメータ
 */
export interface BusinessIndexConditionParams {
  type: AttributeConditionParamType.BusinessIndex;
  id: number;
  valRangeType: ValueInputMethod;
  valFrom: number;
  valTo: number;
}

/**
 * ユーザ属性の追加検索条件パラメータ
 * word_match_methodが正しいので、word_match_methodを使うようにしてください。
 * ただし以前はwordMatchMethodを返しており、historyがwordMatchMethodを持っている場合あるので、
 * どちらが来ても大丈夫なようにしています。
 *
 * correct value is word_match_method and we should use word_match_method.
 * However some histories from API has only wordMatchMethod.
 * So this interface word_match_method? wordMatchMethod?
 */
export interface UserAttributeConditionParams {
  type: AttributeConditionParamType.UserAttribute;
  id: number;
  formatType: AttributeType;
  strValues: string[];
  valRangeType: ValueInputMethod;
  valFrom: number;
  valTo: number;
  word_match_method?: MatchMethod;
  wordMatchMethod?: MatchMethod;
}

/**
 * Additional search condition parameters for Business event
 */
export interface BusinessEventAttributeConditionParams {
  type: AttributeConditionParamType.BusinessEventAttribute;
  id: number;
  values: string[];
  word_match_method: MatchMethod;
}

/*
 *  Additional search condition parametrs for Contact Attribute
 */
export interface ContactAttributeConditionParams {
  type: AttributeConditionParamType.ContactAttribute;
  id: number;
  field_id: number;
  values: string[];
  word_match_method: MatchMethod;
}

export interface FilterEdgeParam {
  edge_type: FilterEdgeType;
}

export type EdgeParam =
  | FilterEdgeParam
  | FilterEdgeParamForIntervalTime
  | FilterEdgeParamForSameVisit
  | FilterEdgeParamForTransitionStep;

export interface FilterEdgeParamForIntervalTime extends FilterEdgeParam {
  interval_time_sec: number;
}

export interface FilterEdgeParamForSameVisit extends FilterEdgeParam {
  transition_type: number;
}

export interface FilterEdgeParamForTransitionStep
  extends FilterEdgeParamForSameVisit {
  transition_steps: number;
}

export interface FilterNodeParam {
  activity_edge: FilterEdgeParam | null;
  activity_type: number;
  activity_excluded?: boolean;
  dates?: {
    end_time_sec: number;
    start_time_sec: number;
  };
  is_in_first_visit?: boolean;
  stay_time_in_sec_gte?: number;
  stay_time_in_sec_lt?: number;
}

export interface FilterApiParams {
  device_types: DeviceType[];
  period_days: FilterPeriodDays;
  activity_nodes: FilterNodeParam[];
}

export type FilterNodeParamForInflow =
  | ChildFilterNodeParamForReferrer
  | ChildFilterNodeParamForInflowParameter
  | ChildFilterNodeParamForSearchEngine
  | ChildFilterNodeParamForAd;

export interface ChildFilterNodeParamForReferrer extends FilterNodeParam {
  entrance_title?: string | PageTitleWithWordMatchMethodParam;
  entrance_url?: {
    url: string;
    word_match_method: MatchMethod;
  };
  external_site_inflow_url?: {
    url: string;
    word_match_method: MatchMethod;
  };
}

export interface ChildFilterNodeParamForInflowParameter
  extends FilterNodeParam {
  inflow_param: string;
}

export interface ChildFilterNodeParamForSearchEngine extends FilterNodeParam {
  entrance_title?: string | PageTitleWithWordMatchMethodParam;
  entrance_url?: {
    url: string;
    word_match_method: MatchMethod;
  };
  search_engine_id?: number;
}

export interface ChildFilterNodeParamForAd extends FilterNodeParam {
  ad_type?: number;
  media?: string;
  menu?: string;
  advertise?: string;
  advertise_group?: string;
  entrance_url?: {
    url: string;
    word_match_method: MatchMethod;
  };
  entrance_title?: string | PageTitleWithWordMatchMethodParam;
}

export interface FilterNodeParamForConversion extends FilterNodeParam {
  cv: {
    id: number;
    attributes: {
      id: number;
      value: string;
      word_match_method: MatchMethod;
    }[];
  };
}

export interface FilterNodeParamForBrowseSite extends FilterNodeParam {
  cruise_title?: string | PageTitleWithWordMatchMethodParam;
  cruise_url?: {
    url: string;
    word_match_method: MatchMethod;
  };
}

export interface PageTitleWithWordMatchMethodParam {
  title: string;
  word_match_method: MatchMethod;
}

export interface FilterNodeParamForBusinessEvent extends FilterNodeParam {
  be_def_id: number;
  content?: string;
}

export interface FilterNodeParamForOrNode extends FilterNodeParam {
  or_activity_nodes: FilterNodeParam[];
}

export interface FilterNodeParamForEventWithoutAttribute
  extends FilterNodeParam {
  event_id: number;
}

export interface FilterNodeParamForEvent extends FilterNodeParam {
  event: {
    id: number;
    attributes: {
      id: number;
      value: string;
      word_match_method: MatchMethod;
    }[];
  };
}

export interface FilterNodeParamForContact extends FilterNodeParam {
  contact_def_id: number;
  talk_gt_checked: boolean;
  talk_gt_time_min: number;
  title?: string;
  purpose?: string;
  employee?: string;
  store_name?: string;
  content?: string;
  subject_type?: number;
}

export interface FilterNodeParamForBrowseApp extends FilterNodeParam {
  app_cruise_title: {
    value: string;
    word_match_method: MatchMethod;
  };
}

export interface FilterNodeParamForLaunchAppWithoutAttribute
  extends FilterNodeParam {
  launch_type: number;
}

export interface FilterNodeParamForLaunchApp extends FilterNodeParam {
  launch_type: number;
  launch_attributes: {
    id: number;
    value: string;
    word_match_method: MatchMethod;
  }[];
}
