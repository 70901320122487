import { MutationTree, ActionTree } from "vuex";
import { SegmentedTrendsTab } from "@/const/segmented-trends";
import { RootState } from "@/store/";
import {
  webView,
  landing,
  inflow,
  inflowDetail,
  appView,
  event,
  conversion
} from "@/store/segmented-trends/base";

export class SegmentedTrendsState {
  currentTab: SegmentedTrendsTab = SegmentedTrendsTab.conversion;
}

const mutations = <MutationTree<SegmentedTrendsState>>{
  setCurrentTab(state: SegmentedTrendsState, tab: SegmentedTrendsTab) {
    state.currentTab = tab;
  }
};

const actions = <ActionTree<SegmentedTrendsState, RootState>>{
  async executeAnalysisOfActiveTab({ state, dispatch }) {
    switch (state.currentTab) {
      case SegmentedTrendsTab.webView:
        await dispatch("webView/executeAnalysis");
        break;
      case SegmentedTrendsTab.landing:
        await dispatch("landing/executeAnalysis");
        break;
      case SegmentedTrendsTab.inflow:
        await dispatch("inflow/executeAnalysis");
        break;
      case SegmentedTrendsTab.appView:
        await dispatch("appView/executeAnalysis");
        break;
      case SegmentedTrendsTab.event:
        await dispatch("event/executeAnalysis");
        break;
      case SegmentedTrendsTab.conversion:
        await dispatch("conversion/executeAnalysis");
        break;
      default:
        await dispatch("conversion/executeAnalysis");
    }
  },
  resetSegmentedTrendsData({ commit }) {
    commit("webView/resetData");
    commit("landing/resetData");
    commit("inflow/resetData");
    commit("inflowDetail/resetData");
    commit("appView/resetData");
    commit("event/resetData");
    commit("conversion/resetData");
  }
};

export const segmentedTrends = {
  namespaced: true,
  state: new SegmentedTrendsState(),
  mutations,
  actions,
  modules: {
    webView,
    landing,
    inflow,
    inflowDetail,
    appView,
    event,
    conversion
  }
};
