import { AdditionalSelectConditionInterface } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  AttributeConditionParamType,
  AttributeType,
  ConversionAttributeNumberRangeConditionParams,
  NumberMatchMethod
} from "@/api/apis/ApiSearch";
import { ConversionAttributeNumberCondition } from "@/models/search/additional-condition/ConversionAttributeNumberCondition";

export class ConversionAttributeNumberRangeCondition
  extends ConversionAttributeNumberCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    id: number,
    public readonly valFrom: number,
    public readonly valTo: number
  ) {
    super(id, NumberMatchMethod.BETWEEN);
  }
  /**
   * 表示用の設定された値を返す。
   */
  displayValue(): string {
    return String(this.valFrom) + " 〜 " + String(this.valTo);
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertConversionAttributeNumberRangeConditionToJson(
  condition: ConversionAttributeNumberRangeCondition
): ConversionAttributeNumberRangeConditionParams {
  return {
    type: AttributeConditionParamType.ConversionAttribute,
    id: condition.id,
    attributeType: AttributeType.NUMBER,
    numberMatchMethod: NumberMatchMethod.BETWEEN,
    valFrom: condition.valFrom,
    valTo: condition.valTo
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToConversionAttributeNumberRangeCondition(
  json: ConversionAttributeNumberRangeConditionParams,
  activeConversionAttributeDefinitions: ConversionAttributeDefinition[]
): ConversionAttributeNumberRangeCondition | null {
  // 利用可能なidリストになければnull
  if (!activeConversionAttributeDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  return new ConversionAttributeNumberRangeCondition(
    json.id,
    json.valFrom,
    json.valTo
  );
}
