<template>
  <tr class="analysisTableHeaderRow" data-cy="analysis-table-header-row">
    <slot></slot>
  </tr>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AnalysisTableHeaderRow extends Vue {}
</script>
