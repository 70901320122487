<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <span
    class="selectConditionLabel"
    data-cy="select-condition-tag"
    :class="{ 'selectConditionLabel--nowrap': !wrap }"
  >
    <span
      data-cy="select-type-label"
      class="selectConditionLabel__selectTypeLabel"
      >{{ selectTypeLabel }}</span
    >

    <span
      v-for="(label, index) in displayLabels"
      :key="index"
      class="selectConditionLabel__label"
      :data-cy="label.dataCy"
    >
      {{ label.text }}
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import {
  SelectByNpsChangeCondition,
  NpsLabel
} from "@/models/search/select-condition/SelectByNpsChangeCondition";
import { SelectByServiceIdCondition } from "@/models/search/select-condition/SelectByServiceIdCondition";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { AdditionalSelectCondition } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { DateFormat, formatDate } from "@/util/date-util";
import { ConversionAttributeTextCondition } from "@/models/search/additional-condition/ConversionAttributeTextCondition";
import { UserAttributeStringCondition } from "@/models/search/additional-condition/UserAttributeStringCondition";
import { UserAttributeNumberCondition } from "@/models/search/additional-condition/UserAttributeNumberCondition";
import { NpsCondition } from "@/models/search/additional-condition/NpsCondition";

import { EnqueteCondition } from "@/models/search/additional-condition/EnqueteCondition";
import { BusinessIndexCondition } from "@/models/search/additional-condition/BusinessIndexCondition";
import { BusinessEventAttributeCondition } from "@/models/search/additional-condition/BusinessEventAttributeCondition";
import { ContactAttributeCondition } from "@/models/search/additional-condition/ContactAttributeCondition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { hourlyIntervalsSelectOpitions } from "@/util/date-util";
import { SelectByEngagementCondition } from "@/models/search/select-condition/SelectByEngagementCondition";
import { SelectByUserJourneyCondition } from "@/models/search/select-condition/SelectByUserJourneyCondition";
import {
  getStartDateOfSearchConditionPeriod,
  getEndDateOfSearchConditionPeriod,
  getEngagementSearchConditionDetailText
} from "@/views/select-condition-label-util";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";

interface DisplayLabel {
  text: string;
  dataCy?: string;
}

@Component
export default class SelectConditionLabel extends Vue {
  conversionAttributeNames: { [key: number]: string } = this.nameMap(
    this.$store.state.clientSettings.activeConversionAttributeDefinitions
  );
  npsNames: { [key: number]: string } = this.shortNameMap(
    this.$store.state.clientSettings.activeNpsDefinitions
  );
  enqueteNames: { [key: number]: string } = this.shortNameMap(
    this.$store.state.clientSettings.activeEnqueteDefinitions
  );
  businessIndexNames: { [key: number]: string } = this.nameMap(
    this.$store.state.clientSettings.activeBusinessIndexDefinitions
  );
  userAttributeNames: { [key: number]: string } = this.nameMap(
    this.$store.state.clientSettings.activeUserAttributeDefinitions
  );
  businessEventNames: { [key: number]: string } = this.nameMap(
    this.$store.state.clientSettings.activeBusinessEventDefinitions
  );
  contactNames: { [key: number]: string } = this.contactNameMap(
    this.$store.state.clientSettings.activeContactDefinitions
  );

  @Prop({ type: Object, required: true })
  selectCondition!: SelectCondition;

  @Prop({ type: Boolean, default: true })
  wrap!: boolean;

  get isConversion(): boolean {
    return this.selectCondition instanceof SelectByConversionCondition;
  }
  get isApp(): boolean {
    return this.selectCondition instanceof SelectByAppCondition;
  }
  get isBusinessEvent(): boolean {
    return this.selectCondition instanceof SelectByBusinessEventCondition;
  }
  get isContact(): boolean {
    return this.selectCondition instanceof SelectByContactCondition;
  }
  get isNps(): boolean {
    return this.selectCondition instanceof SelectByNpsCondition;
  }
  get isNpsChange(): boolean {
    return this.selectCondition instanceof SelectByNpsChangeCondition;
  }
  get isServiceId(): boolean {
    return this.selectCondition instanceof SelectByServiceIdCondition;
  }
  get isUserId(): boolean {
    return this.selectCondition instanceof SelectByUserIdCondition;
  }
  get isEngagementSearch(): boolean {
    return this.selectCondition instanceof SelectByEngagementCondition;
  }
  get isUserJourney(): boolean {
    return this.selectCondition instanceof SelectByUserJourneyCondition;
  }

  get selectTypeLabel(): string {
    if (this.isConversion) {
      return this.$t("conversion") as string;
    }
    if (this.isApp) {
      return this.$t("app") as string;
    }
    if (this.isBusinessEvent) {
      return this.$t("businessEvent") as string;
    }
    if (this.isContact) {
      return this.$t("contact") as string;
    }
    if (this.isNps || this.isNpsChange) {
      return this.$t("customerLoyalty") as string;
    }
    if (this.isServiceId) {
      return this.$t("serviceId") as string;
    }
    if (this.isUserId) {
      return this.$t("userSearch") as string;
    }
    if (this.isEngagementSearch) {
      return this.$t("repeat") as string;
    }
    if (this.isUserJourney) {
      return this.$t("userJourney") as string;
    }
    return "";
  }

  get pathIndex(): string {
    return this.$route.query.pathIndex as string;
  }

  get shouldDisplayDateLabel(): boolean {
    return (
      this.isConversion ||
      this.isBusinessEvent ||
      this.isContact ||
      this.isApp ||
      this.isEngagementSearch
    );
  }
  get dateLabel(): string {
    const startDate: Date | null = getStartDateOfSearchConditionPeriod(
      this.selectCondition
    );
    const endDate: Date | null = getEndDateOfSearchConditionPeriod(
      this.selectCondition
    );
    if (startDate === null || endDate === null) {
      return "";
    }

    return this.makeLabelDate(startDate, endDate);
  }
  get conversionLabel(): string {
    const conversionIds: number[] = (this
      .selectCondition as SelectByConversionCondition).conversionDefinitionIds;
    const conversionDefinitions: ConversionDefinition[] = this.$store.state
      .clientSettings.activeConversionDefinitions;

    return conversionDefinitions
      .filter(def => conversionIds.indexOf(def.id) !== -1)
      .map(def => def.name)
      .join(", ");
  }
  get businessEventLabel(): string {
    const businessEventIds: number[] = (this
      .selectCondition as SelectByBusinessEventCondition)
      .businessEventDefinitionIds;
    const businessEventDefinitions: BusinessEventDefinition[] = this.$store
      .state.clientSettings.activeBusinessEventDefinitions;

    return businessEventDefinitions
      .filter(def => businessEventIds.indexOf(def.id) !== -1)
      .map(def => def.name)
      .join(", ");
  }
  get contactLabel(): string {
    const contactIds: number[] = (this
      .selectCondition as SelectByContactCondition).contactDefinitionIds;
    const contactDefinitions: ContactDefinition[] = this.$store.state
      .clientSettings.activeContactDefinitions;

    return contactDefinitions
      .filter(def => contactIds.indexOf(def.id) !== -1)
      .map(def => def.translatedName)
      .join(", ");
  }
  get serviceIdLabel(): string {
    const serviceIds: string[] = (this
      .selectCondition as SelectByServiceIdCondition).serviceIds;
    const truncatedIds = serviceIds.map(id => {
      return id.length <= 10 ? id : id.substring(0, 10) + "...";
    });
    const sidMaxLength = 7;
    if (truncatedIds.length > sidMaxLength) {
      return (
        truncatedIds.slice(0, sidMaxLength).join(", ") +
        " +" +
        String(truncatedIds.length - sidMaxLength)
      );
    }

    return truncatedIds.join(", ");
  }
  get npsDateLabel(): string {
    const date: Date = (this.selectCondition as SelectByNpsCondition).date;
    return formatDate(DateFormat.yyyysMsd, date);
  }
  get npsLabel(): string {
    let label: string = (this.$t("score") as string) + ": ";
    const from: number = (this.selectCondition as SelectByNpsCondition).from;
    const to: number = (this.selectCondition as SelectByNpsCondition).to;
    if (from === to) {
      label += from;
    } else {
      label += from + " - " + to;
    }
    return label;
  }
  get npsChangeLabel(): string {
    const condition = this.selectCondition as SelectByNpsChangeCondition;
    const previousLabels = condition.previousNpsLabels
      .slice()
      .sort()
      .map(this.npsCategory);
    const currentLabel = this.npsCategory(condition.npsLabel);
    return previousLabels.join(",") + " > " + currentLabel;
  }
  get appLabel(): string {
    const conversionIds: number[] = (this
      .selectCondition as SelectByAppCondition).conversionDefinitionIds;
    const conversionDefinitions: ConversionDefinition[] = this.$store.getters[
      "system/activeGlobalConversionDefinitions"
    ];

    return conversionDefinitions
      .filter(def => conversionIds.indexOf(def.id) !== -1)
      .map(def => def.name)
      .join(", ");
  }

  get measurementTargetSites(): MeasurementTargetSite[] {
    return this.$store.getters["clientSettings/activeMeasurementSites"];
  }

  get engagementSearchLabel(): string {
    return getEngagementSearchConditionDetailText(
      this.selectCondition as SelectByEngagementCondition,
      this.$store.state.clientSettings.activeConversionDefinitions,
      this.$store.state.clientSettings.activeEventDefinitions,
      this.measurementTargetSites.length
    );
  }

  get additionalConditions(): AdditionalSelectCondition[] {
    const additionalConditions =
      "additionalConditions" in this.selectCondition
        ? this.selectCondition.additionalConditions
        : [];
    return additionalConditions;
  }
  get additionalConditionLabels(): string[] {
    const additionalLabels: string[] = [];
    this.additionalConditions.forEach(ac => {
      additionalLabels.push(this.makeLabels(ac));
    });
    return additionalLabels;
  }

  get displayLabels(): DisplayLabel[] {
    const displayLabels: DisplayLabel[] = [];

    if (this.dateLabel && !this.isEngagementSearch) {
      displayLabels.push({
        text: this.dateLabel,
        dataCy: "search-condition-date-label"
      });
    }

    if (this.isConversion) {
      displayLabels.push({
        text: this.conversionLabel,
        dataCy: "conversion-label-item"
      });
    }

    if (this.isBusinessEvent) {
      displayLabels.push({
        text: this.businessEventLabel
      });
    }

    if (this.isServiceId) {
      displayLabels.push({
        text: this.serviceIdLabel,
        dataCy: "search-condition-service-id-label"
      });
    }

    if (this.isNps) {
      displayLabels.push({
        text: this.npsDateLabel
      });
    }

    if (this.isNps) {
      displayLabels.push({
        text: this.npsLabel
      });
    }

    if (this.isNpsChange) {
      displayLabels.push({
        text: this.npsChangeLabel
      });
    }

    if (this.isContact) {
      displayLabels.push({
        text: this.contactLabel
      });
    }

    if (this.isApp) {
      displayLabels.push({
        text: this.appLabel
      });
    }

    if (this.isEngagementSearch) {
      displayLabels.push({
        text: this.engagementSearchLabel
      });
    }

    if (this.isUserJourney) {
      displayLabels.push({
        text: this.$t("path", { path: this.pathIndex }) as string
      });
    }

    this.additionalConditionLabels.forEach((label: string) => {
      displayLabels.push({
        text: label,
        dataCy: "additional-label-item"
      });
    });

    return displayLabels;
  }

  get displayLabelInString() {
    return (
      this.selectTypeLabel +
      " " +
      this.displayLabels
        .map(function(label) {
          return label.text;
        })
        .join(",")
    );
  }

  nameMap(
    definitions:
      | ConversionDefinition[]
      | ConversionAttributeDefinition[]
      | UserAttributeDefinition[]
      | BusinessIndexDefinition[]
  ): { [key: number]: string } {
    const names: { [key: number]: string } = {};
    (definitions as any).forEach((d: any) => {
      names[d.id] = d.name;
    });
    return names;
  }

  contactNameMap(
    contactDefinitions: ContactDefinition[]
  ): { [key: number]: string } {
    const names: { [key: number]: string } = {};
    contactDefinitions.forEach(def => {
      names[def.id] = def.translatedName;
    });
    return names;
  }

  shortNameMap(
    definitions: NpsDefinition[] | EnqueteDefinition[]
  ): { [key: number]: string } {
    const names: { [key: number]: string } = {};
    (definitions as any).forEach((d: any) => {
      names[d.id] = d.shortName;
    });
    return names;
  }
  npsCategory(npsLabel: number): string {
    switch (npsLabel) {
      case NpsLabel.Detractor:
        return this.$i18n.t("npsLow") as string;
      case NpsLabel.Passive:
        return this.$i18n.t("npsMiddle") as string;
      case NpsLabel.Promoter:
        return this.$i18n.t("npsHigh") as string;
      default:
        return "";
    }
  }

  makeLabels(condition: AdditionalSelectCondition): string {
    if (condition instanceof ConversionAttributeTextCondition) {
      return this.makeLabelConversionAttributeCondition(condition);
    }

    if (condition instanceof UserAttributeStringCondition) {
      return this.makeLabelUserAttributeStringCondition(condition);
    }

    if (condition instanceof UserAttributeNumberCondition) {
      return this.makeLabelUserAttributeNumberCondition(condition);
    }

    if (condition instanceof BusinessIndexCondition) {
      return this.makeLabelBusinessIndexCondition(condition);
    }

    if (condition instanceof NpsCondition) {
      return this.makeLabelNpsCondition(condition);
    }

    if (condition instanceof EnqueteCondition) {
      return this.makeLabelEnqueteCondition(condition);
    }

    if (condition instanceof BusinessEventAttributeCondition) {
      return this.makeLabelBusinessEventAttributeCondition(condition);
    }

    if (condition instanceof ContactAttributeCondition) {
      return this.makeLabelContactAttributeCondition(condition);
    }

    return "";
  }

  makeLabelConversionAttributeCondition(
    condition: ConversionAttributeTextCondition
  ): string {
    let label: string = this.conversionAttributeNames[condition.id];
    label += ": ";
    const longLabel = condition.values.join(", ");
    label +=
      longLabel.length <= 10 ? longLabel : longLabel.substring(0, 10) + "...";
    return label;
  }

  makeLabelUserAttributeStringCondition(
    condition: UserAttributeStringCondition
  ): string {
    let label = this.userAttributeNames[condition.id];
    label += ": ";
    const longLabel = condition.values.join(", ");
    label +=
      longLabel.length <= 10 ? longLabel : longLabel.substring(0, 10) + "...";
    return label;
  }

  makeLabelUserAttributeNumberCondition(
    condition: UserAttributeNumberCondition
  ): string {
    let label = this.userAttributeNames[condition.id];
    label += ": " + condition.displayValue();
    return label;
  }

  makeLabelBusinessIndexCondition(condition: BusinessIndexCondition): string {
    let label = this.businessIndexNames[condition.id];
    label += ": " + condition.displayValue();
    return label;
  }

  makeLabelNpsCondition(condition: NpsCondition): string {
    let label = this.npsNames[condition.id];
    label += ": " + condition.displayValue();
    return label;
  }

  makeLabelEnqueteCondition(condition: EnqueteCondition): string {
    let label = this.enqueteNames[condition.id];
    label += ": " + condition.displayValue();
    return label;
  }

  makeLabelDate(startDate: Date, endDate: Date): string {
    let label: string =
      formatDate(DateFormat.yyyysMsd, startDate) +
      " - " +
      formatDate(DateFormat.yyyysMsd, endDate);

    if ("hour" in this.selectCondition) {
      const hour = this.selectCondition.hour;
      hourlyIntervalsSelectOpitions(false).forEach(opt => {
        if (opt.value === hour) {
          label += " " + opt.label;
        }
      });
    }

    return label;
  }

  makeLabelBusinessEventAttributeCondition(
    condition: BusinessEventAttributeCondition
  ): string {
    let label: string = this.businessEventNames[condition.id];
    label += ": ";
    const longLabel = condition.values.join(", ");
    label +=
      longLabel.length <= 10 ? longLabel : longLabel.substring(0, 10) + "...";
    return label;
  }

  makeLabelContactAttributeCondition(
    condition: ContactAttributeCondition
  ): string {
    let label: string = this.contactNames[condition.id];
    label += ": ";
    const longLabel = condition.values.join(", ");
    label +=
      longLabel.length <= 10 ? longLabel : longLabel.substring(0, 10) + "...";
    return label;
  }
}
</script>
<style lang="scss" scoped>
.selectConditionLabel {
  line-height: 1.5;
}

.selectConditionLabel--nowrap {
  white-space: nowrap;
}

.selectConditionLabel__selectTypeLabel {
  font-weight: bold;
  font-size: 14px;
}

.selectConditionLabel__label {
  margin-left: 5px;
  color: $colorBase700;
  font-size: 14px;
}
</style>
