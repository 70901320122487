import { FilterNodeParam, FilterApiParams } from "@/api/apis/ApiSearch";
import {
  convertFilterNodeToJson,
  FilterNode,
  convertJsonToFilterNode,
  convertJsonToFilterNodeForDeepCopy
} from "@/models/search/filter-node/FilterNode";
import { ValidationResult } from "@/models/search/ValidationResult";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { i18n } from "@/i18n";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { DeviceType, FilterPeriodDays } from "@/const/filter";

/**
 * 絞り込み条件
 *
 * 最大5個まで絞り込みノードを保持できる
 */
export class FilterCondition {
  constructor(
    public readonly filterNodes: FilterNode[],
    public readonly deviceTypes: DeviceType[] = [],
    public readonly periodDays: FilterPeriodDays = FilterPeriodDays.TWO_YEARS
  ) {}

  get validate(): ValidationResult {
    if (this.filterNodes.length <= 0 && this.deviceTypes.length <= 0) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.specifyFilterCondition") as string
      };
    }

    const errorMessages: string[] = [];
    this.filterNodes.forEach(node => {
      const v = node.validate;
      if (!v.isValid && v.errorMessage) {
        errorMessages.push(v.errorMessage);
      }
    });

    if (errorMessages.length === 0) {
      return { isValid: true };
    }

    return {
      isValid: false,
      errorMessage: errorMessages.join("\n")
    };
  }

  get exclusionNodeIndex(): number {
    return this.filterNodes.findIndex(node => node.isExcluded);
  }

  get hasExclusionNode(): boolean {
    return this.exclusionNodeIndex >= 0;
  }

  get hasMiddleExclusionNode(): boolean {
    return (
      this.filterNodes.length > 2 &&
      this.exclusionNodeIndex > 0 &&
      this.exclusionNodeIndex < this.filterNodes.length - 1
    );
  }
}

export function deepCopyFilterCondition(
  condition: FilterCondition,
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionDefinitions: ConversionDefinition[], // TODO: This is exist in activeDefinitions, it is possible to use from activeDefinitions
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[] // TODO: Maybe we can consider to move this to activeDefinitions
): FilterCondition | null {
  const json = condition.filterNodes.map(node =>
    convertFilterNodeToJson(node, globalConversionDefinitions)
  );
  const nodes: FilterNode[] = [];
  json.forEach(nd => {
    const node = convertJsonToFilterNodeForDeepCopy(
      nd,
      canUseWebdataFeatures,
      isContractApp,
      activeDefinitions,
      globalConversionDefinitions,
      globalConversionAttributeDefinitions
    );
    if (node !== null) {
      nodes.push(node);
    }
  });

  if (
    (nodes.length === 0 && condition.deviceTypes.length === 0) ||
    nodes.length !== json.length ||
    !isIncludedInFilterPeriodDaysValues(condition.periodDays)
  ) {
    return null;
  }

  return new FilterCondition(
    nodes,
    condition.deviceTypes,
    condition.periodDays
  );
}

/**
 * 絞り込み条件 -> JSON
 */
export function convertFilterConditionToJson(
  condition: FilterCondition,
  globalConversionDefinitions: ConversionDefinition[]
): FilterApiParams {
  const activityNodes: FilterNodeParam[] = condition.filterNodes.map(node =>
    convertFilterNodeToJson(node, globalConversionDefinitions)
  );

  const deviceTypes: DeviceType[] = condition.deviceTypes;
  const periodDays: FilterPeriodDays = condition.periodDays;
  return {
    device_types: deviceTypes,
    period_days: periodDays,
    activity_nodes: activityNodes
  };
}

/**
 * JSON -> 絞り込み条件
 */
export function convertJsonToFilterCondition(
  json: FilterApiParams | FilterNodeParam[],
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterCondition | null {
  try {
    let nodes: FilterNode[] = [];

    const deviceTypes: DeviceType[] = isFilterApiParams(json)
      ? json.device_types
      : [];
    const periodDays: FilterPeriodDays =
      isFilterApiParams(json) && json.hasOwnProperty("period_days")
        ? json.period_days
        : FilterPeriodDays.TWO_YEARS;
    const activityNodes: FilterNodeParam[] = isFilterApiParams(json)
      ? json.activity_nodes
      : json;

    activityNodes.forEach(nd => {
      const node = convertJsonToFilterNode(
        nd,
        canUseWebdataFeatures,
        isContractApp,
        activeDefinitions,
        globalConversionAttributeDefinitions
      );
      if (node !== null) {
        nodes.push(node);
      }
    });
    if (
      (nodes.length === 0 && deviceTypes.length === 0) ||
      nodes.length !== activityNodes.length ||
      !isIncludedInFilterPeriodDaysValues(periodDays)
    ) {
      return null;
    }

    if (isInvalidNodeEdge(nodes)) {
      nodes = updateFilterNodes(nodes);
    }
    return new FilterCondition(nodes, deviceTypes, periodDays);
  } catch (error) {
    return null;
  }
}

function isFilterApiParams(
  json: FilterApiParams | FilterNodeParam[]
): json is FilterApiParams {
  return "device_types" in json;
}

function isIncludedInFilterPeriodDaysValues(periodDays: FilterPeriodDays) {
  return Object.values(FilterPeriodDays).indexOf(periodDays) != -1;
}

export function isInvalidNodeEdge(nodes: FilterNode[]): boolean {
  return nodes.some((node, index) => {
    const isLast: boolean = nodes.length - 1 === index;
    return (node.edge === null && !isLast) || (isLast && node.edge !== null);
  });
}

export function updateFilterNodes(nodes: FilterNode[]): FilterNode[] {
  return nodes.map((node, index) => {
    const isFirst: boolean = index === 0;
    const isLast: boolean = nodes.length - 1 === index;

    // 先頭以外のnodeに初回が入っていたら削除する
    if (!isFirst) {
      node = node.removeFirstTimeCondition();
    }
    // Remove edge when last node or middle exclusion node
    if (isLast || (!isFirst && node.isExcluded)) {
      node = node.removeEdge();
    } else {
      node = node.insertEdge();
    }

    return node;
  });
}
