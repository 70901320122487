<i18n
  src="@/i18n/components/segmented-trends/segmented-trends-tabs.json"
></i18n>
<template>
  <div class="segmented-trends-tabs" data-cy="segmented-trends-tabs">
    <UgTab>
      <UgTabItem
        v-if="canUseWebdataFeatures"
        :class="{ 'segmented-trends-tabs__wrapper': displayLabel }"
        :active="selectedTab === SegmentedTrendsTab.webView"
        data-cy="pageAnalysis-tab"
        @click="onClickTab(SegmentedTrendsTab.webView)"
      >
        {{ $t("pageAnalysis") }}
        <div v-if="displayLabel" class="segmented-trends-tabs__label">
          (Web)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="canUseWebdataFeatures"
        :class="{ 'segmented-trends-tabs__wrapper': displayLabel }"
        :active="selectedTab === SegmentedTrendsTab.landing"
        data-cy="landingPageAnalysis-tab"
        @click="onClickTab(SegmentedTrendsTab.landing)"
      >
        {{ $t("landingPageAnalysis") }}
        <div v-if="displayLabel" class="segmented-trends-tabs__label">
          (Web)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="canUseWebdataFeatures"
        :class="{ 'segmented-trends-tabs__wrapper': displayLabel }"
        :active="selectedTab === SegmentedTrendsTab.inflow"
        data-cy="inflowBaseAnalysis-tab"
        @click="onClickTab(SegmentedTrendsTab.inflow)"
      >
        {{ $t("inflowBaseAnalysis") }}
        <div v-if="displayLabel" class="segmented-trends-tabs__label">
          (Web)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="isContractApp"
        :class="{ 'segmented-trends-tabs__wrapper': displayLabel }"
        :active="selectedTab === SegmentedTrendsTab.appView"
        data-cy="appViewAnalysis-tab"
        @click="onClickTab(SegmentedTrendsTab.appView)"
      >
        {{ $t("appViewAnalysis") }}
        <div v-if="displayLabel" class="segmented-trends-tabs__label">
          (App)
        </div>
      </UgTabItem>
      <UgTabItem
        v-if="hasEventDefinitions"
        :class="{ 'segmented-trends-tabs__wrapper': displayLabel }"
        :active="selectedTab === SegmentedTrendsTab.event"
        data-cy="eventAnalysis-tab"
        @click="onClickTab(SegmentedTrendsTab.event)"
      >
        {{ $t("eventAnalysis") }}
        <div v-if="displayLabel" class="segmented-trends-tabs__label">
          (Web, App)
        </div>
      </UgTabItem>
      <UgTabItem
        :class="{ 'segmented-trends-tabs__wrapper': displayLabel }"
        data-cy="conversionAnalysis-tab"
        :active="selectedTab === SegmentedTrendsTab.conversion"
        @click="onClickTab(SegmentedTrendsTab.conversion)"
      >
        {{ $t("conversionAnalysis") }}
        <div v-if="displayLabel" class="segmented-trends-tabs__label">
          (Web, App)
        </div>
      </UgTabItem>
    </UgTab>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import UgTab from "@/components/tab/UgTab.vue";
import UgTabItem from "@/components/tab/UgTabItem.vue";
import { SegmentedTrendsTab } from "@/const/segmented-trends";

@Component({
  components: {
    UgTab,
    UgTabItem
  }
})
export default class SegmentedTrendsTabs extends Vue {
  @Prop({ type: Boolean, required: true })
  canUseWebdataFeatures!: boolean;
  @Prop({ type: Boolean, required: true })
  isContractApp!: boolean;
  @Prop({ type: Boolean, required: true })
  hasEventDefinitions!: boolean;
  @Prop({ type: String, required: true })
  selectedTab!: SegmentedTrendsTab;

  onClickTab(tab: SegmentedTrendsTab) {
    this.$emit("changeTab", tab);
  }

  SegmentedTrendsTab = SegmentedTrendsTab;

  get displayLabel(): boolean {
    return this.canUseWebdataFeatures && this.isContractApp;
  }
}
</script>

<style lang="scss" scoped>
.segmented-trends-tabs__wrapper {
  display: block;
}
.segmented-trends-tabs__label {
  display: block;
  padding-top: 2px;
  font-size: 12px;
}
</style>
