export enum DeviceType {
  IPHONE = 1,
  IPAD = 2,
  ANDROID = 3,
  PC = 4
}

export const FilterPeriodDays = {
  ONE_MONTH: 30,
  THREE_MONTHS: 90,
  SIX_MONTHS: 180,
  ONE_YEAR: 366,
  TWO_YEARS: 731
} as const;

export type FilterPeriodDays = typeof FilterPeriodDays[keyof typeof FilterPeriodDays];
