import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { AttributeType } from "@/api/apis/ApiSearch";
import {
  FunnelType,
  FunnelStatus,
  SearchField,
  InflowSearchField,
  InflowOrganicSearchField
} from "@/const/funnel";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  FunnelEdgeType,
  SameVisitTransitionType
} from "@/models/funnel/FunnelCondition";
import { ComparisonType } from "@/models/funnel/FunnelConditionActivity";

export interface FunnelCreateParam {
  name: string;
  start_date: string;
  end_date: string;
  app_user_timezone: number;
  conditions: FunnelConditionJson[];
  user_attributes: FunnelAttributeJson[];
  user_activities: FunnelConditionActivityJson[];
}

export interface FunnelJson {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  status: FunnelStatus;
  funnel_data: FunnelDataJson;
  created_at: string;
}

export interface FunnelDataJson {
  data: FunnelDataNodeJson[];
  user_attributes: FunnelAttributeJson[];
  user_activities: FunnelConditionActivityJson[];
  num_filtered_users?: number;
}

export interface FunnelApiResponse {
  funnel: FunnelJson;
  failed_reason?: string;
}

export interface FunnelConditionJson {
  type: FunnelType;
  value?: string | number;
  not_condition: boolean;
  start_date: string;
  end_date: string;
  word_match_method?: MatchMethod;
  search_field?: SearchField;
  attributes?: FunnelAttributeJson[];
  inflow_param?: string;
  search_engine_id?: number;
  match_conditions?: FunnelInflowMatchConditionJson[];
  edge?: FunnelEdgeJson;
}

export interface FunnelDataNodeJson {
  order: number;
  condition: FunnelConditionJson;
  matched_users?: FunnelUserList;
  unmatched_users?: FunnelUserList;
}

export interface FunnelUserList {
  total: number;
  ids?: string[];
  grams_per_user?: GramListOfUser;
}

export interface GramListOfUser {
  [userId: string]: string[];
}

export interface FunnelAttributeJson {
  type: AttributeType;
  id: number;
  value?: string;
  word_match_method?: MatchMethod;
  min?: number;
  max?: number;
}

export interface FunnelConditionActivityJson {
  times: number;
  comparison: ComparisonType;
  condition: Omit<FunnelConditionJson, "edge">;
}

export interface FunnelEdgeJson {
  type: FunnelEdgeType;
  transition_type?: SameVisitTransitionType;
}

export interface FunnelInflowMatchConditionJson {
  value: string;
  word_match_method: MatchMethod;
  search_field: InflowSearchField | InflowOrganicSearchField;
}

export default class FunnelAnalysis {
  constructor(private readonly httpClient: HttpClient) {}

  public getHistory(): Promise<FunnelJson[]> {
    return this.httpClient.get<FunnelJson[]>(ApiUrl.FUNNEL);
  }

  public createNew(params: FunnelCreateParam): Promise<FunnelApiResponse> {
    const url: string = ApiUrl.FUNNEL;

    return this.httpClient.post<FunnelApiResponse>(url, params);
  }

  public getResult(id: string): Promise<FunnelApiResponse> {
    const url: string = `${ApiUrl.FUNNEL}${id}/`;

    return this.httpClient.get<FunnelApiResponse>(url);
  }
}
