import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { filterInputCheck } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { i18n } from "@/i18n";

/**
 * 行動履歴による条件
 *
 * 絞り込み条件に追加で、来店のとき、店舗名（部分一致）を条件として付与できる
 */
export class FilterContactShopNameCondition {
  public readonly conditionType = FilterNodeConditionType.ContactShopName;
  constructor(public readonly shopName: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.shopName,
      i18n.t("models.contactAttribute.nameOfStore") as string
    );
  }
}
