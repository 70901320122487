<template>
  <AnalysisTableRow class="settingsTableRow">
    <slot></slot>
  </AnalysisTableRow>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AnalysisTableRow from "@/components/table/AnalysisTableRow.vue";

@Component({
  components: {
    AnalysisTableRow
  }
})
export default class SettingsTableRow extends Vue {}
</script>

<style lang="scss" scoped>
.settingsTableRow {
  display: table-row;
}
</style>
