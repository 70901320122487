<template>
  <button :class="[type, { disabled }]" :style="style" @click="onClick">
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BUTTON_TYPE, ButtonTextSize, getFontSize } from "@/const/button";
import { Properties } from "csstype";

@Component
export default class Button extends Vue {
  @Prop({ type: String, default: BUTTON_TYPE.DARK })
  type!: BUTTON_TYPE;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: String, default: "40px" })
  height!: string;

  @Prop({ type: String, default: "0 12px" })
  padding!: string;

  @Prop({ type: String, default: ButtonTextSize.Medium })
  textSize!: ButtonTextSize;

  get style(): any {
    let properties: Properties = {
      width: this.width,
      height: this.height,
      padding: this.padding
    };

    if (this.textSize !== ButtonTextSize.Medium) {
      properties.fontSize = getFontSize(this.textSize);
    }

    return properties;
  }

  static type(): any {
    return BUTTON_TYPE;
  }

  onClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.$emit("click", event);
    }
  }
}
</script>

<style scoped lang="scss">
button {
  display: inline-block;
  outline: none;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  appearance: none;
}

.dark:not(.disabled) {
  border: 1px solid $colorDark;
  background-color: $colorDark;
  color: $colorWhite;
  font-weight: bold;

  &:hover {
    background-color: $colorHoverDarkForDark;
  }
}

.light:not(.disabled) {
  border: 1px solid $colorDark;
  background-color: $colorWhite;
  color: $colorText;
  &:hover {
    background-color: $colorHoverLightForWhite;
  }
}

.lightBlue:not(.disabled) {
  border: 1px solid $colorBlue600;
  background-color: $colorBlue600;
  color: $colorBase800;
  font-weight: bold;
  &:hover {
    border-color: $colorBlue700;
    background-color: $colorBlue700;
  }
}

.disabled {
  border: 1px solid $bgColorDisabled;
  background-color: $bgColorDisabled;
  color: $colorDisabled;
  cursor: default;
}
</style>
