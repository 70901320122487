import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";
import { LagCvPeriodType } from "@/models/lag-cv/LagCvUserCount";

export default class ApiLagCv {
  constructor(private readonly httpClient: HttpClient) {}

  public getUserListByPeriod = (
    period: LagCvPeriodType
  ): Promise<ApiRes.LagCvUserList> => {
    return this.httpClient.get<ApiRes.LagCvUserList>(
      ApiUrl.LAG_CV_BY_PERIOD(period)
    );
  };

  public fetchLagCvUserCounts = (): Promise<ApiRes.LagCvUserCount[]> => {
    return this.httpClient.get<ApiRes.LagCvUserCount[]>(
      ApiUrl.LAG_CV_USER_COUNT
    );
  };

  public fetchUserListByPeriod = (
    period: LagCvPeriodType
  ): Promise<ApiRes.LagCvUserList> => {
    return this.httpClient.get<ApiRes.LagCvUserList>(
      ApiUrl.LAG_CV_USER_LIST_BY_PERIOD(period)
    );
  };

  public fetchUserListByConversionId = (
    period: LagCvPeriodType,
    conversionId: number
  ): Promise<ApiRes.LagCvUserList> => {
    return this.httpClient.get<ApiRes.LagCvUserList>(
      ApiUrl.LAG_CV_USER_LIST_BY_CONVERSION_ID(period, conversionId)
    );
  };
}
