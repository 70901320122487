import { TdConversionResponse } from "@/api/apis/client-settings/ApiTdConversion";
import { MatchMethod } from "@/models/search/MatchMethod";

export default class TdConversionDefinition {
  constructor(
    public readonly id: number,
    public readonly cvId: number,
    public readonly cvParamColumn: string,
    public readonly cvCond: string,
    public readonly matchMethod: MatchMethod,
    public readonly isDisabled: boolean
  ) {}

  public static fromJson(json: TdConversionResponse): TdConversionDefinition {
    return new TdConversionDefinition(
      json.id,
      json.cv_id,
      json.cv_param_column,
      json.cv_cond,
      json.match_method,
      json.is_disabled
    );
  }
}
