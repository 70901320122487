import { ConversionTrendResultJson } from "@/api/apis/ApiConversionTrend";
import { ConversionTrendDailyCount } from "@/models/conversion-trend/ConversionTrendDailyCount";

/**
 * CV毎の結果
 */

export class ConversionTrendResult {
  constructor(
    public readonly conversionId: number,
    public readonly conversionName: string,
    public readonly data: ConversionTrendDailyCount[]
  ) {}

  static build(json: ConversionTrendResultJson): ConversionTrendResult {
    return new ConversionTrendResult(
      json.conversion_id,
      json.conversion_name,
      json.data.map(d => new ConversionTrendDailyCount(d.date, d.count))
    );
  }
}
