<template>
  <div class="slider-range-ui">
    <vue-slider
      v-model="value"
      class="slider-range-ui-form"
      :data="rangeStepData"
      :enable-cross="enableCross"
      :marks="marks"
      :hide-label="hideLabel"
      :step-style="stepStyle"
      :dot-options="dotOptions"
      :rail-style="railStyle"
      :process-style="processStyle"
      :min-range="minRange"
      :max-range="maxRange"
      :disabled="disabled"
      :duration="animationDuration"
      :adsorb="adsorb"
    >
      <template #tooltip="{ value }">
        <slot name="tooltip" v-bind="{ value }"></slot>
      </template>
    </vue-slider>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Tooltip from "@/components/Tooltip.vue";
import { RangeUiStyleType } from "@/const/slider-range-ui";

@Component({
  components: {
    VueSlider,
    Tooltip
  }
})
export default class SliderRangeUi extends Vue {
  @Model("input", { type: Array, required: true })
  rangeValue!: number[];

  @Prop({ type: Array, required: true })
  rangeStepData!: number[];

  @Prop({ type: Number, required: true })
  minRange!: number;

  @Prop({ type: Number, required: true })
  maxRange!: number;

  @Prop({ type: Boolean, default: false })
  enableCross!: boolean;

  @Prop({ type: Boolean, default: true })
  marks!: boolean;

  @Prop({ type: Boolean, default: true })
  hideLabel!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Number, default: 0 })
  animationDuration!: number;

  @Prop({ type: Boolean, default: true })
  adsorb!: boolean;

  @Prop({ type: Object, default: () => RangeUiStyleType.DotOptions })
  dotOptions!: object;

  @Prop({ type: Object, default: () => RangeUiStyleType.StepStyle })
  stepStyle!: object;

  @Prop({ type: Object, default: () => RangeUiStyleType.RailStyle })
  railStyle!: object;

  @Prop({ type: Object, default: () => RangeUiStyleType.ProcessStyle })
  processStyle!: object;

  onInput(value: number[]) {
    this.$emit("input", value);
  }

  get value(): number[] {
    return this.rangeValue;
  }
  set value(rangeValue: number[]) {
    this.onInput(rangeValue);
  }
}
</script>
