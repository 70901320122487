<template>
  <div class="filter-base-input">
    <div v-if="hasPrefix" class="filter-base-input__prefix">{{ prefix }}</div>
    <slot />
    <div v-if="hasSuffix" class="filter-base-input__suffix">{{ suffix }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FilterBaseInput extends Vue {
  @Prop({ type: String, default: "" })
  prefix!: string;

  @Prop({ type: String, default: "" })
  suffix!: string;

  get hasPrefix(): boolean {
    return this.prefix !== "";
  }

  get hasSuffix(): boolean {
    return this.suffix !== "";
  }
}
</script>
<style lang="scss" scoped>
.filter-base-input {
  display: flex;
  align-items: center;
}

.filter-base-input__prefix,
.filter-base-input__suffix {
  color: $colorBase700;
  font-size: 14px;
}
.filter-base-input__prefix {
  margin-right: 7px;
}

.filter-base-input__suffix {
  margin-left: 7px;
}
</style>
