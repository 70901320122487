<template>
  <div class="userTrendFilterDefaultForm">
    <UserTrendFilterFormContainer
      :show-deselect-filter="showDeselectFilter"
      :is-disabled-filter="isDisabledFilter"
      @filter="onFilterClick"
      @deselect-filter="onDeselectFilterClick"
    >
      <UserTrendFormRow :label="label">
        <UserTrendFilterInput :condition="condition" @enter="onEnter" />
      </UserTrendFormRow>
    </UserTrendFilterFormContainer>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import UserTrendFilterFormContainer from "@/components/user-trend/form/UserTrendFilterFormContainer.vue";
import UserTrendFormRow from "@/components/user-trend/form/UserTrendFormRow.vue";
import { UserTrendFilterCondition } from "@/models/user-trend/UserTrendFilterCondition";
import UserTrendFilterInput from "@/components/user-trend/form/UserTrendFilterInput.vue";

@Component({
  components: {
    UserTrendFilterFormContainer,
    UserTrendFormRow,
    UserTrendFilterInput
  }
})
export default class UserTrendFilterDefaultForm extends Vue {
  @Model("input", { type: UserTrendFilterCondition, required: true })
  condition!: UserTrendFilterCondition;

  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: Boolean, required: true })
  isDisabled!: boolean;

  @Prop({ type: Boolean, required: true })
  showDeselectFilter!: boolean;

  onFilter(filter: UserTrendFilterCondition) {
    this.$emit("filter", filter);
  }

  onDeselectFilter(filter: UserTrendFilterCondition) {
    this.$emit("deselect-filter", filter);
  }

  get isDisabledFilter(): boolean {
    return this.condition.value.length === 0 || this.isDisabled;
  }

  onFilterClick() {
    if (this.condition.value.length !== 0 && !this.isDisabled) {
      this.onFilter(this.condition);
    }
  }

  onDeselectFilterClick() {
    this.condition.setValue(UserTrendFilterCondition.defaultFilter.value);
    this.condition.setMethod(
      UserTrendFilterCondition.defaultFilter.matchMethod
    );
    this.onDeselectFilter(this.condition);
  }

  onEnter() {
    if (this.condition.value.length !== 0 && !this.isDisabled) {
      this.onFilter(this.condition);
    }
  }
}
</script>
