<template>
  <div class="ug-tab" :class="[columnClass]" :style="style">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UgTab extends Vue {
  @Prop({ type: String, default: "auto" })
  maxWidth!: string;

  @Prop({ type: Number, required: false })
  column!: number;

  get style() {
    return {
      maxWidth: this.maxWidth
    };
  }

  get columnClass(): string {
    if (this.column) {
      return "ug-tab__column" + this.column;
    }
    return "";
  }
}
</script>

<style lang="scss" scoped>
.ug-tab__column3 {
  > * {
    max-width: calc(100% / 3);
  }
}

.ug-tab__column4 {
  > * {
    max-width: calc(100% / 4);
  }
}

.ug-tab__column5 {
  > * {
    max-width: calc(100% / 5);
  }
}

.ug-tab {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > * {
    display: block;
    flex: 1;
    max-width: 240px;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 10px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
