<template>
  <AnalysisTableCell
    class="settingsTableCell"
    :class="{ 'settingsTableCell--disabled': disabled }"
    :style="style"
    :width="width"
    :colspan="colspan"
    :rowspan="rowspan"
    @mouse-enter="onMouseEnter"
    @mouse-leave="onMouseLeave"
    @click="!disabled && onClick($event)"
  >
    <slot></slot>
  </AnalysisTableCell>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import AnalysisTableCell from "@/components/table/AnalysisTableCell.vue";

@Component({
  components: {
    AnalysisTableCell
  }
})
export default class SettingsTableCell extends Vue {
  @Prop({ type: String, default: "left" })
  textAlign!: string;

  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Number, default: 0 })
  colspan!: number;

  @Prop({ type: Number, default: 0 })
  rowspan!: number;

  onMouseEnter(event: MouseEvent) {
    this.$emit("mouse-enter", event);
  }

  onMouseLeave(event: MouseEvent) {
    this.$emit("mouse-leave", event);
  }

  onClick(event: MouseEvent) {
    this.$emit("click", event);
  }

  get style() {
    return {
      textAlign: this.textAlign
    };
  }
}
</script>

<style lang="scss" scoped>
.settingsTableCell {
  padding: 15px;
  box-sizing: border-box;
  border: solid 1px $colorBase500;
  background: $colorWhite;
  font-size: 12px;
  line-height: 1.6;
}

.settingsTableCell--disabled {
  background: $colorBase300;
  color: $colorBase600;
}
</style>
