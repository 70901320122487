import { FilterNodeParam } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import {
  FilterDateHourCondition,
  isDateHourCondition
} from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { FilterConversionAttributeCondition } from "@/models/search/filter-node-condition/FilterConversionAttributeCondition";
import { FilterLandingPageTitleCondition } from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import { FilterPageTitleCondition } from "@/models/search/filter-node-condition/FilterPageTitleCondition";
import { FilterContactPurposeCondition } from "@/models/search/filter-node-condition/FilterContactPurposeCondition";
import { FilterContactEmployeeCondition } from "@/models/search/filter-node-condition/FilterContactEmployeeCondition";
import { FilterContactShopNameCondition } from "@/models/search/filter-node-condition/FilterContactShopNameCondition";
import { FilterReferrerUrlCondition } from "@/models/search/filter-node-condition/FilterReferrerUrlCondition";
import { FilterSearchEngineCondition } from "@/models/search/filter-node-condition/FilterSearchEngineCondition";
import { FilterSiteUrlCondition } from "@/models/search/filter-node-condition/FilterSiteUrlCondition";
import { FilterContactTitleCondition } from "@/models/search/filter-node-condition/FilterContactTitleCondition";
import { FilterContactContentCondition } from "@/models/search/filter-node-condition/FilterContactContentCondition";

import { FilterBusinessEventAttributeCondition } from "@/models/search/filter-node-condition/FilterBusinessEventAttributeCondition";
import { secToMsec } from "@/util/date-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";
import { FilterLaunchAppAdditionalCondition } from "@/models/search/filter-node-condition/FilterLaunchAppAdditionalCondition";

export type FilterAdditionalTimingCondition =
  | FilterFirstTimeCondition
  | FilterPeriodCondition
  | FilterDateHourCondition;

export type FilterAdditionalCondition =
  | FilterAdditionalTimingCondition
  | FilterBusinessEventAttributeCondition
  | FilterConversionAttributeCondition
  | FilterLandingPageTitleCondition
  | FilterLandingPageUrlCondition
  | FilterPageTitleCondition
  | FilterContactContentCondition
  | FilterReferrerUrlCondition
  | FilterSearchEngineCondition
  | FilterSiteUrlCondition
  | FilterLaunchAppAdditionalCondition
  | FilterContactTitleCondition
  | FilterContactPurposeCondition
  | FilterContactEmployeeCondition
  | FilterContactShopNameCondition;

// Noneは選択されないが、selectのリストに存在する用(Ex: イベント名
export enum FilterNodeConditionType {
  ReferrerUrl,
  LandingPageUrl,
  LandingPageTitle,
  AdType,
  AdMedia,
  AdInverntory,
  AdCampaign,
  AdGroup,
  ConversionAttribute,
  SiteUrl,
  PageTitle,
  SearchEngine,
  Contact,
  ContactTitle,
  ContactContent,
  ContactPurpose,
  ContactEmployee,
  ContactShopName,
  FirstTime,
  Period,
  DateHour,
  PushLabel,
  LinkCampaign,
  LinkSource,
  LinkMedium,
  LinkLinkName,
  LinkLinkValue,
  BusinessEventAttribute,
  None
}

export function getAdditionalTimingConditions(
  json: FilterNodeParam
): FilterAdditionalTimingCondition[] {
  const additionalConditions: FilterAdditionalTimingCondition[] = [];

  // 初回 のキーがあれば条件に追加
  if (json.is_in_first_visit) {
    additionalConditions.push(new FilterFirstTimeCondition());
  }

  // 日付をチェックして2種類の条件を復元
  if (json.dates) {
    // ○時台の指定の場合
    if (
      isDateHourCondition(json.dates.start_time_sec, json.dates.end_time_sec)
    ) {
      const date: Date = new Date(secToMsec(json.dates.start_time_sec));
      additionalConditions.push(
        new FilterDateHourCondition(date, date.getHours())
      );
    } else {
      additionalConditions.push(
        new FilterPeriodCondition(
          new Date(secToMsec(json.dates.start_time_sec)),
          new Date(secToMsec(json.dates.end_time_sec))
        )
      );
    }
  }
  return additionalConditions;
}

export function filterInputCheck(
  text: string,
  fieldName: string
): ValidationResult {
  if (text === "") {
    return {
      isValid: false,
      errorMessage: i18n.t("models.search.enterFieldName", {
        fieldName
      }) as string
    };
  }
  if (text.length > 256) {
    return {
      isValid: false,
      errorMessage: i18n.t("models.search.lessCharactorsFieldName", {
        fieldName
      }) as string
    };
  }
  return { isValid: true };
}
