export enum GtmTagEvent {
  virtualPageView = "virtualPageView"
}

export class GtmTag {
  public static pushPv(replacedTitle?: string) {
    const title = replacedTitle ? replacedTitle : document.title;
    const url = document.URL;

    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({
      event: GtmTagEvent.virtualPageView,
      pageUrl: url,
      pageTitle: title
    });
  }
}
