<i18n src="@/i18n/components/user-trend/table/user-trend-user-icon.json"></i18n>
<template>
  <div class="userTrendUserIcon">
    <Tooltip :text="$t('cvUserHelp')">
      <div
        class="userTrendUserIcon__iconContainer"
        data-cy="user-trend-user-icon-cell-icon-container"
        @click="onClick"
      >
        <UserIconDefault class="userTrendUserIcon__icon" />
      </div>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import UserIconDefault from "@/assets/svg/user/face.svg";

@Component({
  components: { Tooltip, UserIconDefault }
})
export default class UserTrendUserIcon extends Vue {
  @Emit("click")
  onClick() {}
}
</script>

<style scoped lang="scss">
.userTrendUserIcon__iconContainer {
  display: inline-flex;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.userTrendUserIcon__icon {
  width: 20px;
  height: 20px;
}
</style>
