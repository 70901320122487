import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterSearchEngineCondition } from "@/models/search/filter-node-condition/FilterSearchEngineCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import {
  FilterLandingPageTitleCondition,
  convertJsonToFilterLandingPageTitleCondition
} from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import { ChildFilterNodeParamForSearchEngine } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import {
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * サーチエンジンによる絞り込み条件
 *
 * サーチエンジンから流入したユーザを絞り込む
 * FilterByInflowConditionに子になるので、edgeは持たない
 * （edgeはFilterByInflowConditionが保持する）
 *
 * 検索エンジンの種類、入口ページURL、入口ページタイトル、初回、期間、日付と時間帯
 * を条件として追加できる
 */
export class ChildFilterNodeForSearchEngine {
  constructor(
    /**
     * 追加の検索条件
     *
     * 検索エンジンの種類、入口ページURL、入口ページタイトル、初回、期間、日付と時間帯を 3つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterSearchEngineCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterAdditionalTimingCondition
    )[],
    public readonly parentDepth: number | null = 0
  ) {}

  get validate(): ValidationResult {
    return validateConditions(this.additionalConditions);
  }

  get isConditionAddable(): boolean {
    return this.additionalConditions.length < 3;
  }

  get hasSearchEngineCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterSearchEngineCondition
    );
  }

  get hasLandingPageUrlCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterLandingPageUrlCondition
    );
  }

  get hasLandingPageTitleCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterLandingPageTitleCondition
    );
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.SearchEngine,
            label: i18n.t("models.search.searchEngine") as string,
            disabled:
              this.hasSearchEngineCondition &&
              !(currentCondition instanceof FilterSearchEngineCondition)
          },
          {
            value: FilterNodeConditionType.LandingPageUrl,
            label: i18n.t("models.search.entryPageUrl") as string,
            disabled:
              this.hasLandingPageUrlCondition &&
              !(currentCondition instanceof FilterLandingPageUrlCondition)
          },
          {
            value: FilterNodeConditionType.LandingPageTitle,
            label: i18n.t("models.search.entryPageTitle") as string,
            disabled:
              this.hasLandingPageTitleCondition &&
              !(currentCondition instanceof FilterLandingPageTitleCondition)
          }
        ]
      }
    ];

    if (this.parentDepth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }
}

export function convertChildFilterNodeForSearchEngineToJson(
  node: ChildFilterNodeForSearchEngine
): ChildFilterNodeParamForSearchEngine {
  // edgeは別の場所でつける
  const result: ChildFilterNodeParamForSearchEngine = {
    activity_edge: null,
    activity_type: ActivityEdgeType.SearchEngine
  };

  const conditions: (
    | FilterAdditionalTimingCondition
    | FilterSearchEngineCondition
    | FilterLandingPageUrlCondition
    | FilterLandingPageTitleCondition
  )[] = node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterSearchEngineCondition) {
      result.search_engine_id = condition.searchEngineId;
    } else if (condition instanceof FilterLandingPageUrlCondition) {
      result.entrance_url = {
        url: condition.pageUrl,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterLandingPageTitleCondition) {
      result.entrance_title = {
        title: condition.pageTitle,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForSearchEngine(
  json: ChildFilterNodeParamForSearchEngine
) {
  const additionalConditions: (
    | FilterSearchEngineCondition
    | FilterLandingPageUrlCondition
    | FilterLandingPageTitleCondition
    | FilterFirstTimeCondition
    | FilterPeriodCondition
    | FilterDateHourCondition
  )[] = getAdditionalTimingConditions(json);

  if (json.search_engine_id) {
    additionalConditions.push(
      new FilterSearchEngineCondition(json.search_engine_id)
    );
  }
  if (json.entrance_url) {
    additionalConditions.push(
      new FilterLandingPageUrlCondition(
        json.entrance_url.url,
        json.entrance_url.word_match_method
      )
    );
  }
  if (json.entrance_title) {
    additionalConditions.push(
      convertJsonToFilterLandingPageTitleCondition(json.entrance_title)
    );
  }
  return new ChildFilterNodeForSearchEngine(additionalConditions);
}
