<i18n src="@/i18n/views/client-settings/td-conversion-attribute.json"></i18n>
<template>
  <div class="tdConversionAttributeSetting">
    <div
      v-t="'tdConversionAttributeSetting'"
      class="tdConversionAttributeSetting__title"
    />
    <div class="tdConversionAttributeSetting__description">
      <div v-t="'description1'" />
      <br />
      <div v-html="$t('description2')" />
    </div>
    <div v-if="!isLoading">
      <SettingsAlert
        v-if="!tdWebImportConfig.isDefined()"
        class="tdConversionAttributeSetting__configErrorMessage tdConversionAttributeSetting__errorMessage"
      >
        <span v-html="$t('notConfiguredImportConfigError')"></span>
      </SettingsAlert>
      <SettingsAlert
        v-if="conversionAttributeList.length === 0"
        class="tdConversionAttributeSetting__errorMessage"
      >
        <span v-html="$t('noConversionAttributeCreatedError')"></span>
      </SettingsAlert>
      <SettingsAlert
        v-if="
          conversionAttributeList.length > 0 &&
            !hasEnabledConversionAttributeDefinitions
        "
        class="tdConversionAttributeSetting__errorMessage"
      >
        <span v-html="$t('noEnabledConversionAttributeError')"></span>
      </SettingsAlert>
    </div>
    <div v-if="successMessage" class="setting-success-message">
      {{ successMessage }}
    </div>
    <div class="tdConversionAttributeSetting__buttonContainer">
      <template v-if="isReadyToCreateTdConversionAttribute">
        <RouterLink
          :to="{ name: 'td-conversion-attribute-create' }"
          class="tdConversionAttributeSetting__button"
        >
          <Button
            v-t="'registerNewConversionAttributeCondition'"
            data-cy="create-td-cv-attribute-condition"
            padding="0 20px"
            :text-size="buttonTextSize"
          />
        </RouterLink>
      </template>
      <template v-else>
        <Button
          v-t="'registerNewConversionAttributeCondition'"
          data-cy="create-td-cv-attribute-condition"
          padding="0 20px"
          :disabled="true"
          :text-size="buttonTextSize"
        />
      </template>
    </div>
    <div v-if="isLoading" class="tdConversionAttributeSetting__loading">
      <Loading height="80px" />
    </div>
    <div v-else class="tdConversionAttributeSetting__table">
      <SettingsTable :min-width="'680px'">
        <template #header>
          <SettingsTableRow>
            <SettingsTableHeaderCell
              v-t="'conversionAttributeName'"
              text-align="center"
            />
            <SettingsTableHeaderCell
              v-t="'attributeType'"
              text-align="center"
            />
            <SettingsTableHeaderCell
              v-t="'conversionAttributeColumn'"
              text-align="center"
            />
            <SettingsTableHeaderCell v-t="'edit'" text-align="center" />
            <SettingsTableHeaderCell
              v-t="'updateMeasurementStatus'"
              text-align="center"
            />
          </SettingsTableRow>
        </template>

        <template v-if="hasTdConversionAttribute">
          <SettingsTableRow
            v-for="tdConversionAttribute in sortedTdConversionAttributes"
            :key="tdConversionAttribute.id"
            class="tdConversionAttributeSetting__row"
          >
            <SettingsTableCell
              :disabled="tdConversionAttribute.isDisabled"
              text-align="center"
            >
              {{
                getConversionAttributeNameFromConversionAttributeId(
                  tdConversionAttribute.cvAttributeId
                )
              }}
            </SettingsTableCell>
            <SettingsTableCell
              :disabled="tdConversionAttribute.isDisabled"
              text-align="center"
            >
              {{ getAttributeTypeText(tdConversionAttribute.cvAttributeId) }}
            </SettingsTableCell>
            <SettingsTableCell
              class="tdConversionAttributeSettings__cvAttributeColumnCell"
              :disabled="tdConversionAttribute.isDisabled"
              text-align="center"
            >
              {{ tdConversionAttribute.cvAttributeColumn }}
            </SettingsTableCell>

            <SettingsTableCell
              :disabled="tdConversionAttribute.isDisabled"
              text-align="center"
            >
              <span v-if="tdConversionAttribute.isDisabled" v-t="'edit'" />
              <RouterLink
                v-else
                v-t="'edit'"
                class="tdConversionAttributeSettings__link"
                :to="{
                  name: 'td-conversion-attribute-update',
                  params: { id: tdConversionAttribute.id }
                }"
              />
            </SettingsTableCell>

            <SettingsTableCell
              :disabled="tdConversionAttribute.isDisabled"
              text-align="center"
            >
              <RouterLink
                class="tdConversionAttributeSettings__link"
                :to="{
                  name: 'td-conversion-attribute-update-measurement-state',
                  params: { id: tdConversionAttribute.id }
                }"
              >
                {{
                  getTextMeasurementStatusAction(
                    tdConversionAttribute.isDisabled
                  )
                }}
              </RouterLink>
            </SettingsTableCell>
          </SettingsTableRow>
        </template>
        <template v-else>
          <SettingsTableRow class="tdConversionAttributeSetting__emptyRow">
            <SettingsTableCell
              v-t="'notSet'"
              text-align="center"
              :colspan="5"
            />
          </SettingsTableRow>
        </template>
      </SettingsTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { AttributeType } from "@/api/apis/ApiSearch";
import Button from "@/components/Button.vue";
import SettingsAlert from "@/components/client-settings/SettingsAlert.vue";
import Loading from "@/components/Loading.vue";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableHeaderCell from "@/components/table/SettingsTableHeaderCell.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import { ButtonTextSize } from "@/const/button";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { TdConversionAttributeDefinition } from "@/models/client-settings/TdConversionAttributeDefinition";
import { TdWebImportConfigDefinition } from "@/models/client-settings/TdWebImportConfigDefinition";
import { handleError } from "@/util/error-util";

@Component({
  components: {
    Button,
    SettingsAlert,
    SettingsTable,
    SettingsTableRow,
    SettingsTableHeaderCell,
    SettingsTableCell,
    Loading
  }
})
export default class TdConversionAttributeSettings extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isLoading = true;

  get tdWebImportConfig(): TdWebImportConfigDefinition | null {
    return this.$store.state.tdWebImportConfig.tdWebImportConfigDetails;
  }

  get hasTdConversionAttribute(): boolean {
    return this.tdConversionAttributes.length > 0;
  }

  get tdConversionAttributes(): TdConversionAttributeDefinition[] {
    return this.$store.state.tdConversionAttribute
      .tdConversionAttributeDefinitions;
  }

  get sortedTdConversionAttributes(): TdConversionAttributeDefinition[] {
    return [...this.tdConversionAttributes].sort((a, b) => {
      if (a.isDisabled === b.isDisabled) {
        return a.id - b.id;
      }
      return b.isDisabled ? -1 : 1;
    });
  }

  get conversionAttributeList(): ConversionAttributeDefinition[] {
    return this.$store.state.clientSettings.conversionAttributeDefinitions;
  }

  get hasEnabledConversionAttributeDefinitions(): boolean {
    const enabledCvAttrs = this.conversionAttributeList.filter(
      cvAttr => !cvAttr.isDisabled
    );
    return enabledCvAttrs.length > 0;
  }

  get successMessage(): string {
    return this.$store.state.tdConversionAttribute.successMessage;
  }

  get isReadyToCreateTdConversionAttribute(): boolean {
    return (
      !!this.tdWebImportConfig &&
      this.tdWebImportConfig.isDefined() &&
      this.hasEnabledConversionAttributeDefinitions
    );
  }

  getTextMeasurementStatusAction(isDisabled: boolean): string {
    const translationKey = isDisabled ? "startMeasuring" : "stopMeasuring";
    return this.$i18n.t(translationKey) as string;
  }

  getConversionAttributeNameFromConversionAttributeId(id: number): string {
    if (this.conversionAttributeList.length === 0) return "";

    return this.conversionAttributeList.filter(
      conversionAttributeDefinition => conversionAttributeDefinition.id == id
    )[0].name;
  }

  getAttributeTypeText(cvAttributeId: number): string {
    const cvAttribute = this.conversionAttributeList.find(
      cvAttribute => cvAttribute.id === cvAttributeId
    );
    if (cvAttribute) {
      const attributeType: string =
        cvAttribute.type == AttributeType.TEXT ? "text" : "number";
      return this.$t(attributeType) as string;
    }
    return "";
  }

  async created() {
    const requests = [
      this.$store.dispatch(
        "clientSettings/fetchConversionAttributeDefinitions"
      ),
      this.$store.dispatch("tdWebImportConfig/fetchTdWebImportConfigDetail"),
      this.$store.dispatch(
        "tdConversionAttribute/fetchTdConversionAttributeDefinitions"
      )
    ];
    Promise.all(requests)
      .then(() => {
        this.isLoading = false;
      })
      .catch(handleError);
  }

  destroyed() {
    this.$store.commit("tdConversionAttribute/setSuccessMessage", null);
  }
}
</script>

<style lang="scss" scoped>
.tdConversionAttributeSetting {
  margin-right: 20px;
  min-width: 704px;
}
.tdConversionAttributeSetting__title {
  padding: 0 0 46px 0;
  font-size: 20px;
  font-weight: 700;
}
.tdConversionAttributeSetting__description {
  padding-bottom: 40px;
  white-space: pre-line;
  font-size: 12px;
  line-height: 24px;
}
::v-deep .tdConversionAttributeSettings__link {
  color: $colorBase700;
  text-decoration: underline;
}
.tdConversionAttributeSetting__errorMessage {
  margin-bottom: 10px;
}
.tdConversionAttributeSetting__errorMessage:last-of-type {
  margin-bottom: 30px;
}
.setting-success-message {
  color: $colorBlue900;
  margin-bottom: 30px;
  font-size: 14px;
}
.tdConversionAttributeSetting__buttonContainer {
  margin-bottom: 30px;
}
.tdConversionAttributeSetting__button {
  display: block;
  outline: none;
}
.tdConversionAttributeSetting__table {
  width: auto;
}
.tdConversionAttributeSetting__noContent {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  outline: 1px solid #cad1e0;
}
</style>
