import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";
import { MatchMethod } from "@/models/search/MatchMethod";
import { PageTitleWithWordMatchMethodParam } from "@/api/apis/ApiSearch";
/**
 * ページタイトルによる条件
 *
 * 絞り込み条件に追加で、ページタイトル（部分一致）を条件として付与できる
 */
export class FilterPageTitleCondition {
  public readonly conditionType = FilterNodeConditionType.PageTitle;

  constructor(
    public readonly pageTitle: string,
    public readonly matchMethod: MatchMethod
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.pageTitle,
      i18n.t("models.search.pageTitle") as string
    );
  }
}

export function convertJsonToFilterPageTitleCondition(
  jsonContent: string | PageTitleWithWordMatchMethodParam
): FilterPageTitleCondition {
  if (typeof jsonContent === "string") {
    return new FilterPageTitleCondition(jsonContent, MatchMethod.Partial);
  }

  return new FilterPageTitleCondition(
    jsonContent.title,
    jsonContent.word_match_method
  );
}
