<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <LightUgTable data-cy="featuredUsersLagCvTable" sticky-top="-10px">
    <template #header>
      <FeaturedUsersLagCvTableHeader :counts-by-period="userCountsByPeriod" />
    </template>
    <FeaturedUsersLagCvTableBody
      :counts-by-conversion-id="userCountsByConversionId"
      :counts-by-period="userCountsByPeriod"
      @select="emitSelect"
    />
  </LightUgTable>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LagCvUserCount from "@/models/lag-cv/LagCvUserCount";
import LightUgTable from "@/components/table/LightUgTable.vue";
import FeaturedUsersLagCvTableHeader from "@/components/search/FeaturedUsersLagCvTableHeader.vue";
import FeaturedUsersLagCvTableBody from "@/components/search/FeaturedUsersLagCvTableBody.vue";

export interface UserCountsByConversionId {
  id: number;
  name: string;
  number_of_users: number[];
}

export interface UserCountsByPeriod {
  periodType: string;
  startDate: Date;
  endDate: Date;
  numberOfUsers: number;
}

@Component({
  components: {
    LightUgTable,
    FeaturedUsersLagCvTableHeader,
    FeaturedUsersLagCvTableBody
  }
})
export default class FeaturedUsersLagCvTable extends Vue {
  @Prop({ type: Array, required: true })
  lagCvUserCounts!: LagCvUserCount[];

  emitSelect(url: string) {
    this.$emit("select", url);
  }

  get userCountsByConversionId(): UserCountsByConversionId[] {
    const dataByConversion: UserCountsByConversionId[] = [];
    this.lagCvUserCounts[0].conversions.forEach(conversion => {
      const { id, name } = conversion;
      dataByConversion.push({
        id,
        name,
        number_of_users: []
      });
    });

    this.lagCvUserCounts.forEach(userCount => {
      userCount.conversions.forEach((conversion, index) => {
        dataByConversion[index].number_of_users.push(
          conversion.number_of_users
        );
      });
    });
    return dataByConversion;
  }

  get userCountsByPeriod(): UserCountsByPeriod[] {
    const dataByPeriod = this.lagCvUserCounts.map(userCount => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { conversions, ...others } = userCount;
      return others;
    });

    return dataByPeriod;
  }
}
</script>
