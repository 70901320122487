import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import LagCvUserCount, {
  LagCvPeriodType
} from "@/models/lag-cv/LagCvUserCount";

export interface ParamsFetchUserIdsByConversionId {
  period: LagCvPeriodType;
  conversionId: number;
}

export class LagCvState {
  lagCvUserIds: string[] = [];
  lagCvUserCounts: LagCvUserCount[] = [];
  isLagCvsFetchError: boolean = false;
  isLagCvsFetched: boolean = false;
}

const mutations = <MutationTree<LagCvState>>{
  setLagCvUserIds(state, userIds: string[] | undefined) {
    state.lagCvUserIds = userIds !== undefined ? userIds : [];
  },
  setLagCvUserCounts(state, payload: LagCvUserCount[]) {
    state.lagCvUserCounts = payload;
  },
  setIsLagCvsFetchError(state, isError) {
    state.isLagCvsFetchError = isError;
  },
  setIsLagCvsFetched(state, isLagCvsFetched: boolean) {
    state.isLagCvsFetched = isLagCvsFetched;
  }
};

const actions = <ActionTree<LagCvState, RootState>>{
  async fetchUserIds({ commit, rootState }, periodType: LagCvPeriodType) {
    const body = await rootState.api.lagCv.getUserListByPeriod(periodType);
    commit("setLagCvUserIds", body.user_ids);
  },
  async fetchLagCvUserCounts({ commit, rootState }) {
    commit("setIsLagCvsFetchError", false);
    commit("setIsLagCvsFetched", false);
    try {
      const body = await rootState.api.lagCv.fetchLagCvUserCounts();
      const lagCvUserCounts: LagCvUserCount[] = body.map(
        LagCvUserCount.fromJson
      );
      commit("setLagCvUserCounts", lagCvUserCounts);
    } catch (e) {
      commit("setIsLagCvsFetchError", true);
    }
    commit("setIsLagCvsFetched", true);
  },
  async fetchUserListByPeriod({ commit, rootState }, period: LagCvPeriodType) {
    const body = await rootState.api.lagCv.fetchUserListByPeriod(period);
    commit("setLagCvUserIds", body.user_ids);
  },
  async fetchUserListByConversionId(
    { commit, rootState },
    { period, conversionId }: ParamsFetchUserIdsByConversionId
  ) {
    const body = await rootState.api.lagCv.fetchUserListByConversionId(
      period,
      conversionId
    );
    commit("setLagCvUserIds", body.user_ids);
  }
};

export const lagCv = {
  namespaced: true,
  state: new LagCvState(),
  mutations: mutations,
  actions: actions
};
