<i18n
  src="@/i18n/views/client-settings/td-conversion-create-and-update.json"
></i18n>
<template>
  <div class="tdConversionCreateAndUpdate">
    <div
      v-t="isCreatePage ? 'createTitle' : 'updateTitle'"
      class="tdConversionCreateAndUpdate__title"
    />
    <div v-if="!isEditing" class="tdConversionCreateAndUpdate__confirmText">
      <div v-if="isCreatePage" v-t="'confirmAdd'" />
      <div v-else v-t="'confirmUpdate'" />
    </div>
    <div v-if="isLoading" class="tdConversionCreateAndUpdate__loading">
      <Loading height="80px" />
    </div>

    <div v-else class="tdConversionCreateAndUpdate__form">
      <SettingsTable class="tdConversionCreateAndUpdate__formTable">
        <SettingsTableRow>
          <SettingsTableCell
            v-t="'conversion'"
            width="200px"
            text-align="center"
          />
          <SettingsTableCell
            class="tdConversionCreateAndUpdate__conversionCell"
          >
            <template v-if="isEditing">
              <SelectBox
                v-if="hasActiveConversion"
                v-model="conversion"
                class="tdConversionCreateAndUpdate__selectBox"
                width="450px"
                :options="conversionOptions"
              />
              <div
                class="tdConversionCreateAndUpdate__formCellDescription"
                v-html="$t('conversionFormCellDescription')"
              />
              <div
                v-if="conversionErrorMessage"
                class="tdConversionCreateAndUpdate__errorMessage"
              >
                {{ conversionErrorMessage }}
              </div>
            </template>
            <template v-else>{{ selectedConversionText }}</template>
          </SettingsTableCell>
        </SettingsTableRow>

        <SettingsTableRow>
          <SettingsTableCell v-t="'targetData'" text-align="center" />
          <SettingsTableCell
            class="tdConversionCreateAndUpdate__targetDataCell"
          >
            <template v-if="isEditing">
              <RadioButton
                v-model="targetColumn"
                class="tdConversionCreateAndUpdate__radioButton"
                :label="pageUrlRadioLabel"
              >
                {{ $t("pageUrlText") }}
              </RadioButton>
              <RadioButton
                v-model="targetColumn"
                class="tdConversionCreateAndUpdate__radioButton"
                :label="pageTitleRadioLabel"
              >
                {{ $t("pageTitleText") }}
              </RadioButton>
            </template>
            <template v-else>{{ targetDataText }}</template>
          </SettingsTableCell>
        </SettingsTableRow>

        <SettingsTableRow>
          <SettingsTableCell v-t="'matchCondition'" text-align="center" />
          <SettingsTableCell
            class="tdConversionCreateAndUpdate__matchConditionCell"
          >
            <template v-if="isEditing">
              <InputText v-model="matchCondition" :max-length="300" />
              <SelectBox
                v-model="matchMethod"
                class="tdConversionCreateAndUpdate__selectBox"
                width="130px"
                :options="matchMethodOptions"
              />
              <div
                v-if="matchConditionErrorMessage"
                class="tdConversionCreateAndUpdate__errorMessage"
              >
                {{ matchConditionErrorMessage }}
              </div>
            </template>
            <template v-else>
              {{ `${matchCondition} (${matchMethodText})` }}
            </template>
          </SettingsTableCell>
        </SettingsTableRow>
      </SettingsTable>

      <div class="tdConversionCreateAndUpdate__formButtons">
        <Button
          class="tdConversionCreateAndUpdate__submitButton tdConversionCreateAndUpdate__button"
          padding="0 20px"
          :text-size="buttonTextSize"
          :disabled="isSubmitting"
          @click="isEditing ? onClickConfirm() : onClickSubmit()"
          >{{ submitText }}</Button
        >
        <Button
          v-t="'cancel'"
          class="tdConversionCreateAndUpdate__cancelButton tdConversionCreateAndUpdate__button"
          padding="0 20px"
          :text-size="buttonTextSize"
          :type="buttonTypeCancel"
          @click="onClickCancel"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { TdConversionRequest } from "@/api/apis/client-settings/ApiTdConversion";
import Loading from "@/components/Loading.vue";
import Button from "@/components/Button.vue";
import InputText from "@/components/form/InputText.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import { BUTTON_TYPE, ButtonTextSize } from "@/const/button";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import TdConversionDefinition from "@/models/client-settings/TdConversionDefinition";
import { TdWebImportConfigDefinition } from "@/models/client-settings/TdWebImportConfigDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";
import { handleError } from "@/util/error-util";
import { matchMethodOptions } from "@/util/select-util";

export const EMPTY_CONVERSION_VALUE = 0;
export const DEFAULT_PAGE_URL_RADIO_LABEL = "location_url";
export const DEFAULT_PAGE_TITLE_RADIO_LABEL = "location_name";

@Component({
  components: {
    Button,
    InputText,
    Loading,
    RadioButton,
    SelectBox,
    SettingsTable,
    SettingsTableCell,
    SettingsTableRow
  }
})
export default class TdConversionCreateAndUpdate extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  buttonTypeCancel = BUTTON_TYPE.LIGHT;
  buttonType = BUTTON_TYPE;
  matchMethodOptions = matchMethodOptions();

  isEditing = true;
  isLoading = true;
  isSubmitting = false;

  conversion: number = EMPTY_CONVERSION_VALUE;
  targetColumn = "";
  matchCondition = "";
  matchMethod = MatchMethod.Partial;

  conversionErrorMessage = "";
  matchConditionErrorMessage = "";

  async created() {
    let requests = [
      this.$store.dispatch("clientSettings/fetchConversionDefinitions"),
      this.$store.dispatch("tdWebImportConfig/fetchTdWebImportConfigDetail")
    ];

    if (this.isUpdatePage) {
      requests = [
        ...requests,
        this.$store.dispatch(
          "tdConversion/fetchTdConversionById",
          Number(this.$route.params.id)
        )
      ];
    }

    try {
      await Promise.all(requests);

      if (!this.tdWebImportConfig || !this.tdWebImportConfig.isDefined()) {
        this.$router.push({ name: "td-conversion-settings" });
        return;
      }

      if (!this.hasActiveConversion) {
        this.$router.push({ name: "td-conversion-settings" });
        return;
      }

      if (this.isUpdatePage && !this.tdConversionDefinition) {
        this.$router.push({ name: "td-conversion-settings" });
        return;
      }

      this.setOptions(this.tdWebImportConfig, this.tdConversionDefinition);
      this.isLoading = false;
    } catch (e) {
      handleError(e);
    }
  }

  destroyed() {
    this.$store.commit("tdConversion/setSelectedTdConversionDefinition", null);
  }

  get isCreatePage(): boolean {
    return this.$route.name === "td-conversion-create";
  }

  get isUpdatePage(): boolean {
    return this.$route.name === "td-conversion-update";
  }

  get tdConversionDefinition(): TdConversionDefinition | null {
    return this.$store.state.tdConversion.selectedTdConversionDefinition;
  }

  get activeConversionList(): ConversionDefinition[] {
    return this.$store.state.clientSettings.activeConversionDefinitions;
  }

  get hasActiveConversion(): boolean {
    return this.activeConversionList.length > 0;
  }

  get tdWebImportConfig(): TdWebImportConfigDefinition | null {
    return this.$store.state.tdWebImportConfig.tdWebImportConfigDetails;
  }

  get conversionOptions(): SelectOption[] {
    return [
      {
        label: "-----------",
        value: EMPTY_CONVERSION_VALUE,
        disabled: false
      },
      ...this.activeConversionList.map(cv => ({
        label: cv.name,
        value: cv.id,
        disabled: false
      }))
    ];
  }

  get pageUrlRadioLabel(): string {
    if (this.tdWebImportConfig && this.tdWebImportConfig.locationUriColumn) {
      return this.tdWebImportConfig.locationUriColumn;
    }

    return DEFAULT_PAGE_URL_RADIO_LABEL;
  }

  get pageTitleRadioLabel(): string {
    if (this.tdWebImportConfig && this.tdWebImportConfig.locationNameColumn) {
      return this.tdWebImportConfig.locationNameColumn;
    }

    return DEFAULT_PAGE_TITLE_RADIO_LABEL;
  }

  get selectedConversionText(): string {
    const cv = this.activeConversionList.find(cv => cv.id === this.conversion);
    return cv ? cv.name : "";
  }

  get targetDataText(): string {
    if (this.tdWebImportConfig) {
      return this.tdWebImportConfig.locationUriColumn === this.targetColumn
        ? this.$t("pageUrlText").toString()
        : this.$t("pageTitleText").toString();
    }

    return "";
  }

  get matchMethodText(): string {
    return this.matchMethodOptions.filter(
      op => op.value === this.matchMethod
    )[0].label;
  }

  get submitText(): string {
    if (this.isEditing) {
      return this.$t("confirm").toString();
    }

    if (this.isCreatePage) {
      return this.$t("register").toString();
    }

    return this.$t("update").toString();
  }

  setOptions(
    cfg: TdWebImportConfigDefinition,
    def: TdConversionDefinition | null
  ) {
    // Conversion
    if (this.hasActiveConversion) {
      this.conversion = def ? def.cvId : EMPTY_CONVERSION_VALUE;
    }

    // Target Data
    this.targetColumn = cfg.locationUriColumn;
    if (def && def.cvParamColumn === cfg.locationNameColumn) {
      this.targetColumn = cfg.locationNameColumn;
    }

    // Match Condition
    this.matchCondition = def ? def.cvCond : "";
    this.matchMethod = def ? def.matchMethod : MatchMethod.Partial;
  }

  onClickConfirm() {
    let hasError = false;
    this.conversionErrorMessage = "";
    this.matchConditionErrorMessage = "";

    if (this.conversion === EMPTY_CONVERSION_VALUE) {
      this.conversionErrorMessage = this.$t("noConversionError").toString();
      hasError = true;
    }

    if (this.matchCondition.trim() === "") {
      this.matchConditionErrorMessage = this.$t("emptyError").toString();
      hasError = true;
    }

    if (!hasError) {
      this.isEditing = false;
    }
  }

  async onClickSubmit() {
    this.isSubmitting = true;

    try {
      this.isCreatePage
        ? await this.createTdConversion()
        : await this.updateTdConversion();
      this.$router.push({ name: "td-conversion-settings" });
    } catch (e) {
      handleError(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  async createTdConversion() {
    const payload: TdConversionRequest = {
      cv_id: this.conversion,
      cv_param_column: this.targetColumn,
      cv_cond: this.matchCondition,
      match_method: this.matchMethod,
      is_disabled: false
    };
    await this.$store.dispatch("tdConversion/createTdConversion", payload);
    this.$store.commit(
      "tdConversion/setSuccessMessage",
      this.$t("createSuccessMessage").toString()
    );
  }

  async updateTdConversion() {
    const payload = {
      id: Number(this.$route.params.id),
      params: {
        cv_id: this.conversion,
        cv_param_column: this.targetColumn,
        cv_cond: this.matchCondition,
        match_method: this.matchMethod,
        is_disabled: false
      }
    };
    await this.$store.dispatch("tdConversion/updateTdConversion", payload);
    this.$store.commit(
      "tdConversion/setSuccessMessage",
      this.$t("updateSuccessMessage").toString()
    );
  }

  onClickCancel() {
    if (this.isEditing) {
      this.$router.push({ name: "td-conversion-settings" });
    } else {
      this.isEditing = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.tdConversionCreateAndUpdate {
  min-width: 704px;
}

.tdConversionCreateAndUpdate__title {
  font-size: 20px;
  font-weight: 700;
  line-height: inherit;
  padding: 0 0 46px 0;
}

.tdConversionCreateAndUpdate__formTable {
  margin-bottom: 16px;
  width: 680px;
}

.tdConversionCreateAndUpdate__formCellDescription {
  white-space: pre-line;

  * + & {
    margin-top: 12px;
  }
}

::v-deep .tdConversionCreateAndUpdate__linkText {
  color: $colorBase700;
  text-decoration: underline;
}

::v-deep .tdConversionCreateAndUpdate__errorLinkText {
  color: $colorError;
  text-decoration: underline;
}

.tdConversionCreateAndUpdate__createConversionLink {
  margin-top: 12px;
}

.tdConversionCreateAndUpdate__radioButton {
  display: flex;

  & + & {
    margin-top: 12px;
  }
}

.tdConversionCreateAndUpdate__selectBox {
  * + & {
    margin-top: 12px;
  }
}

.tdConversionCreateAndUpdate__button {
  min-width: 120px;
}

.tdConversionCreateAndUpdate__cancelButton {
  margin-left: 15px;
}

.tdConversionCreateAndUpdate__errorMessage {
  color: $colorError;
  margin-top: 12px;
}
.tdConversionCreateAndUpdate__configErrorMessage {
  color: $colorError;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
  line-height: 1.6;
}
.tdConversionCreateAndUpdate__confirmText {
  padding-bottom: 30px;
  white-space: pre-line;
  font-size: 14px;
  line-height: 1;
}
</style>
