<template>
  <div class="user-icon-container">
    <a
      :href="`/users/${user.id}?vt=${vt}`"
      class="detail-clickable"
      @click.exact.prevent="onClickUser"
    >
      <UserIconWithCalculateStats
        :overviews="user.overviews"
        :select-condition="selectCondition"
        :client-pv-stats="clientPvStats"
        :is-checked="isChecked(user.id)"
      />
      <UserServiceIdText
        :service-id-text="user.serviceIdForDisplay"
        :is-fixed-service-id="user.isFixedServiceId"
        :ellipsis="true"
      />
    </a>
    <div class="favorite">
      <FavoriteButton
        :user="user"
        data-cy="favorite-button-in-user-icon-container"
        @show-form="onShowFavoriteForm"
        @close-form="onCloseFavoriteForm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { User } from "@/models/User";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import UserIconWithCalculateStats from "@/components/users/UserIconWithCalculateStats.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";
import FavoriteButton from "@/views/FavoriteButton.vue";
import { getConditionMaxDate, msecToSec, usecToMsec } from "@/util/date-util";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";

@Component({
  components: {
    UserIconWithCalculateStats,
    UserServiceIdText,
    FavoriteButton
  }
})
export default class UserIconContainer extends Vue {
  @Prop({ type: User, required: true })
  user!: User;

  @Emit("click-user")
  onClickUser() {}

  @Emit("show-favorite-form")
  onShowFavoriteForm() {}

  @Emit("close-favorite-form")
  onCloseFavoriteForm() {}

  get selectCondition(): SelectCondition {
    return this.$store.state.search.selectCondition;
  }

  get clientPvStats(): ClientPvStats {
    return this.$store.state.system.clientPvStats;
  }

  get checkedUserIds(): string[] {
    return this.$store.state.user.checkedUserIds;
  }

  get isAlmostCv(): boolean {
    return this.$route.name === "almost-cv";
  }

  get almostCvUserList(): AlmostCvUserList {
    return this.$store.state.almostCv.almostCvUserList;
  }

  get vt(): string {
    if (this.isAlmostCv) {
      const firstGramIdUsec = this.almostCvUserList.getUsecOfFirstGramIdByUserId(
        this.user.id
      );

      if (firstGramIdUsec > 0) {
        return String(msecToSec(usecToMsec(firstGramIdUsec)));
      }
    }

    const endDate = getConditionMaxDate(this.selectCondition);
    return String(msecToSec(endDate.getTime()));
  }

  isChecked(userId: string): boolean {
    return this.checkedUserIds.some(id => id === userId);
  }
}
</script>

<style scoped lang="scss">
.user-icon-container {
  position: relative;
}
.detail-clickable {
  width: 105px;
}
.favorite {
  position: absolute;
  top: -5px;
  right: -8px;
}
</style>
