<i18n src="@/i18n/views/wordcloud.json"></i18n>
<template>
  <div ref="wordcloud" :style="areaSize"></div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";

// HighchartsでWordcloudを使用するための宣言
import * as Highcharts from "highcharts";
require("highcharts/modules/wordcloud")(Highcharts);

const DEFAULT_FONT_MIN: number = 10;
const DEFAULT_FONT_MAX: number = 50;

const DEFAULT_WIDTH: string = "450px";
const DEFAULT_HEIGHT: string = "360px";

@Component
export default class Wordcloud extends Vue {
  @Prop({ type: Array, required: true })
  wordcloudData!: Highcharts.PointOptionsObject[];

  @Prop({ type: Number, default: DEFAULT_FONT_MIN })
  fontSizeMin?: number;

  @Prop({ type: Number, default: DEFAULT_FONT_MAX })
  fontSizeMax?: number;

  @Prop({ type: String, default: DEFAULT_WIDTH })
  areaWidth?: string;

  @Prop({ type: String, default: DEFAULT_HEIGHT })
  areaHeight?: string;

  get areaSize() {
    return { width: this.areaWidth, height: this.areaHeight };
  }

  mounted() {
    // 現状、ボタンクリック時に1つ表示するのみなのでmountedのタイミングで描画する
    this.showWordCloud();
  }

  showWordCloud() {
    const container = this.$refs.wordcloud as HTMLElement;
    Highcharts.chart({
      chart: {
        renderTo: container
      },
      plotOptions: {
        series: {
          enableMouseTracking: false
        }
      },
      series: [
        {
          type: "wordcloud",
          data: this.wordcloudData,
          name: this.$i18n.t("browsingTime") as string,
          colors: [Colors.Base600, Colors.Blue800, Colors.Blue900],
          maxFontSize: this.fontSizeMax,
          minFontSize: this.fontSizeMin,
          rotation: {
            from: 0,
            to: 0,
            orientations: 1
          }
        }
      ],
      title: {
        text: undefined
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    });
  }
}
</script>
