import { ApiRes } from "@/api/api-res";

/**
 * ビジネスイベント
 *
 * ビジネスイベントは、OMOインポートしたグラムの中でビジネス上の重要な行動となるもの。
 * 例えば解約や、返品など。
 *
 * クライアントが任意に定義できる。
 */

export class BusinessEventDefinition {
  constructor(
    // ID
    public readonly id: number,
    // 名前
    public readonly name: string,
    // 無効ならtrue
    public readonly isDisabled: boolean
  ) {}

  public static fromJson(json: ApiRes.BeDef): BusinessEventDefinition {
    return new BusinessEventDefinition(json.id, json.name, json.is_disabled);
  }
}
