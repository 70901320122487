<template>
  <div class="textarea" :class="{ 'textarea--error': hasError }" :style="style">
    <textarea
      ref="textarea"
      class="textarea__input"
      data-cy="textarea"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="onInput"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";

@Component
export default class TextArea extends Vue {
  @Model("input", { type: String, required: true })
  value!: string;

  @Prop({ type: String, default: "100%" })
  width!: string;

  @Prop({ type: String, default: "290px" })
  height!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  // IE11ブラウザのバグ
  // placeholderに値が無いと最初に文字を入力した時にinput eventが発生しない
  // defaultでnullを設定し回避
  @Prop({ type: String, default: null })
  placeholder!: string | null;

  @Prop({ type: Boolean, default: false })
  hasError!: boolean;

  get style() {
    return {
      width: this.width,
      height: this.height
    };
  }

  focus() {
    (this.$refs.textarea as HTMLTextAreaElement).focus();
  }

  onInput(ev: KeyboardEvent) {
    if (ev.target as HTMLInputElement) {
      this.$emit("input", (ev.target as HTMLInputElement).value);
    }
  }
}
</script>

<style scoped lang="scss">
.textarea {
  padding: 5px;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.textarea--error {
  border-color: $colorError;
  color: $colorError;
}

.textarea__input {
  padding: 0 5px 0 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  line-height: 1.3;
  resize: none;
  &:disabled {
    border: 1px solid $colorBase500;
    background-color: $colorBase300;
    color: $colorBase600;
    cursor: default;
  }
}
</style>
