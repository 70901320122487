<template>
  <div class="cm-Overlay" @click="onClick">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Overlay extends Vue {
  onClick(event: MouseEvent): void {
    this.$emit("click", event);
  }
}
</script>

<style scoped lang="scss">
.cm-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $colorOverlay;
}
</style>
