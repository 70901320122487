var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FilterNode',{staticClass:"filter-node-contact",attrs:{"index":_vm.index,"depth":_vm.condition.depth,"show-or-drop-area":_vm.condition.depth === 0 && _vm.isDragging,"title":_vm.$t('nodeTitleContact'),"show-add-condition":_vm.condition.isConditionAddable(),"exclusion":_vm.exclusion,"has-all-additional-conditions":_vm.checkAdditionalConditions,"select-exclusion-type-value":_vm.exclutionTypeValue},on:{"on-select-exclusion-type":_vm.onSelectExclusionType,"add-condition":_vm.onClickAddCondition,"add-or-node":function($event){return _vm.$emit('add-or-node', $event)},"remove-node":function($event){return _vm.$emit('remove-node')}}},[_c('FilterContactField',{attrs:{"contact-definitions":_vm.contactDefinitions},model:{value:(_vm.contactCondition),callback:function ($$v) {_vm.contactCondition=$$v},expression:"contactCondition"}}),_vm._l((_vm.condition.additionalConditions),function(additionalCondition,index){return _c('FilterNodeItem',{key:index,attrs:{"options":_vm.condition.selectOption(
        additionalCondition,
        _vm.contactDefinitionType,
        false,
        _vm.isFirstNode
      ),"condition-type":additionalCondition.conditionType},on:{"input":type => {
        _vm.onInputItem(type, index);
      },"remove":function($event){return _vm.onRemoveAdditionalCondition(index)}}},[(additionalCondition.conditionType === _vm.contactPurpose)?_c('FilterContactPurposeField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e(),(additionalCondition.conditionType === _vm.contactEmployee)?_c('FilterContactEmployeeField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e(),(additionalCondition.conditionType === _vm.contactTitle)?_c('FilterContactTitleField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e(),(additionalCondition.conditionType === _vm.contactShopName)?_c('FilterContactShopNameField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e(),(additionalCondition.conditionType === _vm.contactContent)?_c('FilterContactContentField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e(),(additionalCondition.conditionType === _vm.dateHour)?_c('FilterDateHourField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e(),(additionalCondition.conditionType === _vm.period)?_c('FilterPeriodField',{attrs:{"condition":additionalCondition},on:{"input":cnd => _vm.onInputAdditionalCondition(cnd, index)}}):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }