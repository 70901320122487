<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div class="user-search-result-attribute">
    <UserAttributeTable
      :users="users"
      :fixed-columns="fixedColumns"
      :selected-columns="attributeTableColumns"
      :all-columns="tableColumnOptions"
      :current-user="currentUser"
      :current-column-page="isOmo ? currentColumnPage : 0"
      sticky-top="120px"
      @changeColumnSettings="onChangeColumnSettings"
      @mouse-enter="onMouseEnterUserColumn"
      @mouse-leave="onMouseLeaveUserColumn"
    >
      <template #default="{ user }">
        <UserIconContainer
          :user="user"
          @show-favorite-form="onShowFavoriteForm"
          @close-favorite-form="onCloseFavoriteForm"
          @click-user="onClickUser(user)"
        />
        <UserAttributeBalloon
          :user="user"
          :show="showUserBalloon(user.id)"
          :direction="balloonDirection"
          :y="balloonPositionY"
          :x="balloonPositionX"
          :adjust="balloonArrowAdjust"
        >
          <template #wordcloud>
            <WordcloudContainer :user-id="user.id" />
          </template>
        </UserAttributeBalloon>
      </template>
    </UserAttributeTable>

    <div
      v-if="showArrowPrev"
      class="user-search-result-attribute__arrow user-search-result-attribute__arrow-prev"
    >
      <Tooltip
        :text="$t('previousItem')"
        :placement="tooltipPlacement.TopStart"
      >
        <IconButton
          :icon="icons.ArrowLeft"
          :size="45"
          :icon-scale="0.4"
          :button-type="iconButtonType"
          :background="true"
          @click="onClick(-1)"
        />
      </Tooltip>
    </div>

    <div
      v-if="showArrowNext"
      class="user-search-result-attribute__arrow user-search-result-attribute__arrow-next"
    >
      <Tooltip :text="$t('nextItem')" :placement="tooltipPlacement.TopEnd">
        <IconButton
          :icon="icons.ArrowRight"
          :size="45"
          :icon-scale="0.4"
          :button-type="iconButtonType"
          :background="true"
          @click="onClick(1)"
        />
      </Tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import UserAttributeTable from "@/components/users/UserAttributeTable.vue";
import UserAttributeBalloon from "@/components/users/UserAttributeBalloon.vue";
import { Component, Emit, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import { handleError } from "@/util/error-util";
import UserIconContainer from "@/views/UserIconContainer.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { TooltipPlacement } from "@/const/tooltip";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import { UserBalloonDirection } from "@/components/users/UserBaseBalloon.vue";
import WordcloudContainer from "@/views/WordcloudContainer.vue";
import { UgAttributeTag, UgEventTag, UgTag } from "@/store/modules/ugTag";
import { SearchResultViews } from "@/const/SearchResultViews";

const BALLOON_ARROW_ADJUST: number = 26; // balloonのtopの位置調整(Start postion = 20 + Arrow Size / 2 = 6)
const USER_COLUMN_WIDTH: number = 135;
const COLUMN_BREAK_NUMBER: number = 5;
const SHOW_BALLOON_WAIT_DURATION = 300;
const PUSH_EVENT_DELAY: number = 1000;

// word cloudを開いた状態での高さ
const MAX_BALLOON_HEIGHT = 461;
// word cloudを開かない状態の高さ
const MIN_BALLOON_HEIGHT = 164;
// table cellの最上端の高さ(スクロール時)
const TABLE_CELL_TOP = 170;

@Component({
  components: {
    UserAttributeTable,
    UserAttributeBalloon,
    UserIconContainer,
    IconButton,
    Tooltip,
    WordcloudContainer
  }
})
export default class UserSearchResultAttribute extends Vue {
  onClickUser(user: User) {
    this.$emit("click-user", user);
  }

  @Emit("on-show-favorite-form")
  onShowFavoriteForm() {
    this.showingFavoriteForm = true;
    this.onMouseLeaveUserColumn();
  }

  @Emit("on-close-favorite-form")
  onCloseFavoriteForm() {
    this.showingFavoriteForm = false;
  }

  icons = Icons;
  colors = Colors;
  iconButtonType = IconButtonType.UserSearchArrow;
  tooltipPlacement = TooltipPlacement;

  currentColumnPage: number = 1;
  mouseEnterUserId: string = "";

  balloonDisplayTimer: number | null = null;
  balloonDirection: UserBalloonDirection = UserBalloonDirection.Top;
  balloonPositionY: string = "auto";
  balloonPositionX: string = "auto";
  balloonArrowAdjust: number = 0;

  showingFavoriteForm: boolean = false;
  fixedColumns!: TableColumn[];
  pushEventTimer: number | undefined = undefined;

  created() {
    this.$store.dispatch("clientSettings/fetchAttributeTableColumns");
    this.fixedColumns = [
      { id: 1, name: this.$t("user") as string },
      { id: 2, name: this.$t("terminalUsed") as string },
      { id: 3, name: this.$t("conversionDate") as string },
      { id: 4, name: this.$t("conversionName") as string }
    ];
  }

  get users(): User[] {
    if (this.$store.state.clustering.isClusteringMode) {
      return this.$store.state.clustering.users;
    } else if (this.isTourDetail) {
      return this.$store.getters["filter/tourUsers"];
    } else if (this.$store.state.filter.isFilterMode) {
      return this.$store.state.filter.users;
    } else {
      return this.$store.state.search.users;
    }
  }

  get isOmo(): boolean {
    return this.$store.getters["clientSettings/hasActiveOmoDefinitions"];
  }

  get attributeTableColumns(): TableColumn[] {
    return this.$store.state.clientSettings.attributeTableColumns;
  }

  get maxColumnPage(): number {
    return Math.ceil(
      (this.fixedColumns.length + this.attributeTableColumns.length - 1) /
        COLUMN_BREAK_NUMBER
    );
  }

  get tableColumnOptions(): TableColumn[] {
    return this.$store.getters["clientSettings/tableColumnOptions"];
  }

  get currentUser(): User | null {
    return this.$store.state.user.currentUser;
  }

  get showArrowPrev(): boolean {
    if (this.isOmo) {
      return this.currentColumnPage !== 1;
    }

    return false;
  }

  get showArrowNext(): boolean {
    if (this.isOmo) {
      return this.currentColumnPage !== this.maxColumnPage;
    }
    return false;
  }

  get isTourDetail(): boolean {
    return this.$route.name === "tour-detail";
  }

  onChangeColumnSettings(columns: TableColumn[]) {
    this.currentColumnPage = 1;
    this.$store
      .dispatch("clientSettings/saveAttributeTableColumns", columns)
      .catch(error => {
        handleError(error);
        throw new Error(error);
      });
  }

  onClick(page: number) {
    this.currentColumnPage = this.currentColumnPage + page;
    if (this.maxColumnPage === 0) {
      this.currentColumnPage = 1;
    }
  }

  onMouseEnterUserColumn(event: MouseEvent, userId: string) {
    if (this.showingFavoriteForm) {
      return;
    }
    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
    }
    this.balloonDisplayTimer = window.setTimeout(() => {
      const evTarget = event.target;
      const target: HTMLDivElement = evTarget as HTMLDivElement;
      const cellTop: number = target.getBoundingClientRect().top;
      const cellHeight: number = target.clientHeight;
      const windowHeight: number = window.innerHeight;
      this.mouseEnterUserId = userId;

      this.balloonPositionX = USER_COLUMN_WIDTH + "px";

      // cellの大きさで場合わけ
      if (cellHeight > MIN_BALLOON_HEIGHT) {
        const balloonMaxTop = cellTop + cellHeight / 2 - MAX_BALLOON_HEIGHT / 2;
        const balloonMaxBottom =
          cellTop + cellHeight / 2 + MAX_BALLOON_HEIGHT / 2;

        // Middle : WordCloudを上下に開いても上下とも画面内に収まる
        if (balloonMaxBottom < windowHeight && balloonMaxTop > TABLE_CELL_TOP) {
          this.balloonDirection = UserBalloonDirection.Middle; // WordCloudがどっち側に開くか指定
          this.balloonPositionY = "calc(50%)"; // WordCloud・arrowの位置を移動
          this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2; // arrowの位置を移動
          return;
        }
        // Bottom : Middle WordCloudを上下に開いたら、下側が画面外に出てしまう場合
        if (balloonMaxBottom > windowHeight) {
          this.balloonDirection = UserBalloonDirection.Bottom;
          this.balloonPositionY =
            cellHeight / 2 - BALLOON_ARROW_ADJUST / 2 + "px";
          this.balloonArrowAdjust = 0;
          return;
        }

        // Top : Middle WordCloudを上下に開いたら、上側が画面外に出てしまう場合
        if (balloonMaxTop < TABLE_CELL_TOP) {
          this.balloonDirection = UserBalloonDirection.Top;
          this.balloonPositionY =
            cellHeight / 2 - (BALLOON_ARROW_ADJUST * 3) / 2 + "px";
          this.balloonArrowAdjust = 0;
          return;
        }

        // 上記で全ての場合を捉えられているので、デフォルト設定は不要
      } else {
        // Top : WordCloudを下に開いても収まる
        if (cellTop + MAX_BALLOON_HEIGHT < windowHeight) {
          this.balloonDirection = UserBalloonDirection.Top;
          this.balloonPositionY = BALLOON_ARROW_ADJUST * -1 + "px";
          this.balloonArrowAdjust = cellHeight / 2 - BALLOON_ARROW_ADJUST / 2;
          return;
        }

        // Middle : WordCloudを開いても下が収まる
        if (cellTop + MAX_BALLOON_HEIGHT / 2 < windowHeight) {
          this.balloonDirection = UserBalloonDirection.Middle;
          this.balloonPositionY = "calc(50%)";
          this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2;
          return;
        }

        // Bottom : 下からWordCloudを開いても上に突き抜けない
        if (
          cellTop + cellHeight - MAX_BALLOON_HEIGHT - BALLOON_ARROW_ADJUST / 2 >
          TABLE_CELL_TOP
        ) {
          this.balloonDirection = UserBalloonDirection.Bottom;
          this.balloonPositionY = BALLOON_ARROW_ADJUST / 2 + "px";
          this.balloonArrowAdjust = -(cellHeight / 2 - BALLOON_ARROW_ADJUST);
          return;
        }

        // どれにも当てはまらない時は中央寄せにしておく
        this.balloonDirection = UserBalloonDirection.Middle;
        this.balloonPositionY = "calc(50%)";
        this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2;
      }
    }, SHOW_BALLOON_WAIT_DURATION);
  }

  onMouseLeaveUserColumn() {
    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
      this.balloonDisplayTimer = null;
    }
    clearTimeout(this.pushEventTimer);

    this.mouseEnterUserId = "";
    this.balloonPositionY = "auto";
    this.balloonPositionX = "auto";
  }

  showUserBalloon(userId: string): boolean {
    if (userId !== this.mouseEnterUserId) return false;

    clearTimeout(this.pushEventTimer);
    this.pushEventTimer = window.setTimeout(() => {
      UgTag.pushEvent(UgEventTag.UserListBalloon, {
        [UgAttributeTag.UserListDisplayMode]: SearchResultViews.Attribute
      });
    }, PUSH_EVENT_DELAY);

    return true;
  }
}
</script>

<style scoped lang="scss">
.user-search-result-attribute {
  position: relative;
}

.user-search-result-attribute__arrow {
  position: fixed;
  top: 50%;
  transform: translateY(65%);
}

.user-search-result-attribute__arrow-prev {
  transform: translate(-10px, 65%);
}

.user-search-result-attribute__arrow-next {
  right: 10px;
}

@media print {
  .user-search-result-attribute__arrow {
    display: none;
  }
}
</style>
