import {
  SelectConditionInterface,
  MAX_PERIOD_DAYS,
  convertStartAndEndTimeSecFromCondition
} from "@/models/search/select-condition/SelectCondition";
import {
  AdditionalSelectCondition,
  convertAdditionalConditionToJson,
  convertJsonToAdditionalCondition
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { MAX_HOLD_ADDITIONAL_CONDITION_SIZE } from "./SelectCondition";
import { SelectByContactQuery, SelectQuery } from "@/api/apis/ApiSearch";
import {
  HOURLY_INTERVALS_DEFAULT_VALUE,
  secToMsec,
  MINUTES_IN_HOUR,
  HOURS_IN_DAY,
  toLocalHour
} from "@/util/date-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { Colors } from "@/const/Colors";
import { i18n } from "@/i18n";
import { getCurrentDate } from "@/util/date-util";

/**
 * 行動履歴によるユーザ選定条件(OMO版のみ)
 *
 * 行動履歴定義と、期間を指定して
 * その期間内に指定したビジネスイベントが起こったユーザを選定する
 *
 * 行動履歴定義は複数選択可能
 */
export class SelectByContactCondition implements SelectConditionInterface {
  constructor(
    // 行動履歴定義を指定
    public readonly contactDefinitionIds: number[],
    // 選定対象期間の始め
    public readonly startDate: Date | null,
    // 選定対象期間の終わり
    public readonly endDate: Date | null,
    /**
     * 選定対象期間の時間
     * 例えば1が指定されていれば
     * 午前1時〜2時までを取得する（2時ちょうどは含めない）
     *
     * nullの場合は指定しない
     */
    public readonly hour: number | null,
    /**
     * 追加の検索条件
     *
     * 顧客ロイヤルティ指標、ビジネス指標、ユーザ属性に対する条件を5つまで指定できる
     */
    public readonly additionalConditions: AdditionalSelectCondition[]
  ) {}

  static emptyCondition(): SelectByContactCondition {
    return new SelectByContactCondition(
      [],
      getCurrentDate(),
      getCurrentDate(),
      null,
      []
    );
  }

  get coloredPeriods(): ColoredPeriod[] {
    if (this.startDate !== null && this.endDate !== null) {
      return [
        new ColoredPeriod(
          i18n.t("models.search.designatedPeriod") as String,
          this.startDate,
          this.endDate,
          Colors.Blue730,
          Colors.Blue800
        )
      ];
    }
    return [];
  }

  // AdditionalSelectConditionを追加できるか
  get isAdditionalConditionAppendable(): boolean {
    return (
      this.additionalConditions.length < MAX_HOLD_ADDITIONAL_CONDITION_SIZE
    );
  }

  get validate(): ValidationResult {
    const errorMessages: string[] = [];
    if (this.endDate === null && this.startDate === null) {
      errorMessages.push(i18n.t("models.search.enterTargetPeriod") as string);
    }
    /**
     * 期間のチェック（MAX_PERIOD_DAYS日以内か）
     * 日付の妥当性endがstartより後ろの日付か等はdate pickerがチェックする
     */
    if (this.endDate !== null && this.startDate !== null) {
      const diffMsec = this.endDate.getTime() - this.startDate.getTime();
      if (diffMsec / (1000 * 60 * 60 * 24) > MAX_PERIOD_DAYS) {
        errorMessages.push(i18n.t("models.search.tooLongPeriod") as string);
      }
    }

    // いずれかの行動履歴が選択されているか
    if (this.contactDefinitionIds.length <= 0) {
      errorMessages.push(
        i18n.t("models.search.selectActivityHistory") as string
      );
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectByContactConditionToJson(
  condition: SelectByContactCondition
): SelectByContactQuery {
  // 時間帯が設定されていなければ、時間帯の初期値（任意の時間帯）をセットする
  const offsetHour = getCurrentDate().getTimezoneOffset() / MINUTES_IN_HOUR;
  const timePeriod =
    condition.hour === null
      ? HOURLY_INTERVALS_DEFAULT_VALUE
      : (condition.hour + offsetHour + HOURS_IN_DAY) % 24;
  const timeSec = convertStartAndEndTimeSecFromCondition(condition);

  return {
    contact_def_ids: condition.contactDefinitionIds,
    start_time_sec: timeSec.start_time_sec,
    end_time_sec: timeSec.end_time_sec,
    utc_time_period: timePeriod,
    sub_cnds: condition.additionalConditions.map(
      convertAdditionalConditionToJson
    )
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByContactCondition(
  query: SelectByContactQuery,
  activeDefinitions: ActiveDefinitions
): SelectByContactCondition | null {
  // 利用可能な行動履歴のみ抽出
  const ids: number[] = query.contact_def_ids.filter(id =>
    activeDefinitions.contactDefinitions.some(def => def.id === id)
  );

  // 利用可能な行動履歴がなければnull
  if (ids.length === 0) {
    return null;
  }

  const additionalConditions: AdditionalSelectCondition[] = [];
  query.sub_cnds.forEach(cnd => {
    const addCnd = convertJsonToAdditionalCondition(cnd, activeDefinitions);
    if (addCnd !== null) {
      additionalConditions.push(addCnd);
    }
  });

  return new SelectByContactCondition(
    query.contact_def_ids,
    new Date(secToMsec(query.start_time_sec)),
    new Date(secToMsec(query.end_time_sec)),
    toLocalHour(query.utc_time_period),
    additionalConditions
  );
}

/**
 * JSONがコンバージョン検索のJSONかどうかを判定する
 */
export function isSelectByContactQuery(
  query: SelectQuery
): query is SelectByContactQuery {
  return "contact_def_ids" in query;
}
