import { ApiRes } from "@/api/api-res";

/**
 * 選定画面のナビゲーション
 */
const SELECT_NAVIGATIN_KEY = {
  CV: "by_cvs",
  ACT_HISTORY: "by_contacts",
  BUSINESS_EVENT: "by_bes",
  SERVICE_ID: "by_sids",
  FAV_MEMO_HIST: "by_uids",
  LOYALTY: "by_loyalty"
};

export class UgPermission {
  constructor(
    private readonly config: string[],
    private readonly filtering: string[],
    private readonly selection: string[],
    private readonly userTrend: string[]
  ) {}

  public static fromJson(dict: ApiRes.Permissions): UgPermission {
    const config: string[] = dict.config ? dict.config : [];
    const filtering: string[] = dict.filtering ? dict.filtering : [];
    const selection: string[] = dict.selection ? dict.selection : [];
    // databaseを更新するまでは、volumeZoneの名前で、userTrendの利用権限の有無を確認する
    const userTrend: string[] = dict.volumezone ? dict.volumezone : [];

    const permission: UgPermission = new UgPermission(
      config,
      filtering,
      selection,
      userTrend
    );
    return permission;
  }

  // 管理用設定を表示していいか
  public get isAvailableClientSettings(): boolean {
    return 0 < this.config.length;
  }

  // ユーザートレンドを表示していいか
  public get isAvailableUserTrend(): boolean {
    return 0 < this.userTrend.length;
  }

  // ユーザ選定のお気に入りから選択時複数選択からのリスト表示にいってもよいか
  public get isAvailableUserList(): boolean {
    return 0 < this.filtering.length;
  }

  // ============================================
  // ユーザ選定
  // ============================================

  // コンバージョン選定ナビを表示していいか
  public get isAvailableNaviCv(): boolean {
    return this.selection.indexOf(SELECT_NAVIGATIN_KEY.CV) !== -1;
  }

  // ビジネスイベント選定ナビを表示していいか
  public get isAvailableNaviBusinessEvent(): boolean {
    return this.selection.indexOf(SELECT_NAVIGATIN_KEY.BUSINESS_EVENT) !== -1;
  }

  // 行動履歴選定ナビを表示していいか
  public get isAvailableNaviActHistory(): boolean {
    return this.selection.indexOf(SELECT_NAVIGATIN_KEY.ACT_HISTORY) !== -1;
  }

  // サービスID選定ナビを表示していいか
  public get isAvailableNaviServiceId(): boolean {
    return this.selection.indexOf(SELECT_NAVIGATIN_KEY.SERVICE_ID) !== -1;
  }

  // お気に入り/メモ/履歴選定ナビを表示していいか
  public get isAvailableNaviFavariteMemoHistory(): boolean {
    return this.selection.indexOf(SELECT_NAVIGATIN_KEY.FAV_MEMO_HIST) !== -1;
  }

  // ロイヤルティ選定ナビを表示していいか
  public get isAvailableNaviLoyalty(): boolean {
    return this.selection.indexOf(SELECT_NAVIGATIN_KEY.LOYALTY) !== -1;
  }
}
