import { MutationTree, GetterTree, ActionTree } from "vuex";
import { RootState } from "@/store/";

import { User, UserMemo } from "@/models/User";
import { ApiRes } from "@/api/api-res";
import {
  SelectCondition,
  overwriteDates,
  validationCheckForSelectionHistory,
  overwriteDatesIfOutdated
} from "@/models/search/select-condition/SelectCondition";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";

import { sleep } from "@/util/common-util";
import { Msec } from "@/util/date-util";
import {
  FilterCondition,
  deepCopyFilterCondition
} from "@/models/search/filter-condition/FilterCondition";
import {
  SearchHistory,
  buildSearchHistory
} from "@/models/search/SearchHistory";
import {
  SelectByEngagementCondition,
  AnalysisType
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { SearchResultViews } from "@/const/SearchResultViews";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { HistoryDateValidationResult } from "@/models/search/ValidationResult";
import { getCsvFileName } from "@/util/csv-util";
import { getCurrentDate } from "@/util/date-util";

export interface FetchSearchResultsArgs {
  historyId: number;
  chunkSize: number;
}

export interface ExecuteSelectArgs {
  selectCondition: SelectCondition;
  historyId?: number;
  setHistoryIdToUrl?: (historyId: number) => void;
  favoriteSearchLabel?: string;
}

// 初期取得時の最大件数
export const DEFAULT_USER_FETCH_COUNT: number = 50;
export const EACH_REQUEST_USER_FETCH_COUNT: number = 10;

// 件数取得のインターバル
const FETCH_COUNT_INTERVAL_MSEC: Msec = 2000;

// 検索結果で表示可能な人数
export const MAX_SEARCH_RESULT_COUNT = 2000;

// 履歴IDが変わった際に返すエラー
const ERROR_HISTORY_ID_IS_CHANGED: string = "ERROR_HISTORY_ID_IS_CHANGED";

// 履歴IDから検索・絞り込み条件を戻す際のエラー
export const ERROR_CREATE_CONDITION_FROM_HISTORY_ID: string =
  "ERROR_CREATE_CONDITION_FROM_HISTORY_ID";

export class SearchState {
  // 検索結果
  users: User[] = [];

  // 検索にマッチした件数
  userCount: number = 0;

  // 検索結果が取得されたかどうか
  isFetched: boolean = false;

  // 件数が取得されたかどうか
  isCountFetched: boolean = false;

  // 選定条件
  selectCondition: SelectCondition | null = null;

  // 検索条件の履歴ID
  historyId: number | null = null;
  favoriteSearchLabel: string = "";

  // 選定結果を返すイテレーター
  selectResultIterator: AsyncIterableIterator<
    ApiRes.SelectSearchResult
  > | null = null;

  // 件数を返すイテレーター、件数が取得できない場合はnullを返す
  countIterator: AsyncIterableIterator<number | null> | null = null;

  // CSVダウンロード中かどうかの状態
  isCsvDownloading: boolean = false;

  // 選択されている Conversion の id
  selectedConversionIds: number[] = [];

  //To check if time emphasis button clicked. By default CV emphasis button is clicked.
  toggleTimeOfDayEnabled: boolean = false;

  //To check if date is invalid when search from hid url
  invalidSearchHistoryDateText: string = "";
}

export const mutations: MutationTree<SearchState> = {
  setSelectCondition(state: SearchState, selectCondition: SelectCondition) {
    state.selectCondition = selectCondition;
  },
  clearSelectCondition(state: SearchState) {
    state.selectCondition = null;
  },
  setHistoryId(state: SearchState, historyId: number | null) {
    state.historyId = historyId;
  },
  setFavoriteSearchLabel(state: SearchState, favoriteSearchLabel: string) {
    state.favoriteSearchLabel = favoriteSearchLabel;
  },
  setIsFetched(state: SearchState, isFetched: boolean) {
    state.isFetched = isFetched;
  },
  setSelectResultIterator(
    state: SearchState,
    iterator: AsyncIterableIterator<ApiRes.SelectSearchResult>
  ) {
    state.selectResultIterator = iterator;
  },
  setCountIterator(
    state: SearchState,
    iterator: AsyncIterableIterator<number | null> | null
  ) {
    state.countIterator = iterator;
  },
  initializeSearchResults(state: SearchState) {
    state.users = [];
    state.userCount = 0;
    state.isFetched = false;
    state.isCountFetched = false;
  },
  addSelectResult(state: SearchState, users: User[]) {
    state.users.push(...users);
  },
  setUserCount(state: SearchState, userCount: number) {
    state.userCount = userCount;
    state.isCountFetched = true;
  },
  initializeAll(state: SearchState) {
    state.users = [];
    state.userCount = 0;
    state.isFetched = false;
    state.isCountFetched = false;
    state.selectCondition = new SelectByConversionCondition(
      [],
      getCurrentDate(),
      getCurrentDate(),
      null,
      []
    );
    state.historyId = null;
    state.selectResultIterator = null;
  },
  setIsCsvDownloading(state: SearchState, isCsvDownloading: boolean) {
    state.isCsvDownloading = isCsvDownloading;
  },
  updateMemo(state: SearchState, { userId, memo, isMemoPage }: UserMemo) {
    const memoUser = state.users.find(u => u.id === userId);
    if (memoUser !== undefined) {
      let users: User[] = [];
      if (isMemoPage) {
        users = state.users.filter(u => u.id !== userId);
        users.unshift(memoUser.updateMemo(memo));
      } else {
        users = [...state.users];
        const index = users.findIndex(u => u.id === userId);
        users[index] = users[index].updateMemo(memo);
      }
      state.users = users;
    }
  },
  removeUser(state: SearchState, userId: string) {
    const users: User[] = state.users.filter(u => u.id !== userId);
    state.users = users;
    state.userCount -= 1;
  },
  setSelectedConversionIds(
    state: SearchState,
    selectedConversionIds: number[]
  ) {
    state.selectedConversionIds = selectedConversionIds;
  },
  setToggleTimeOfDayEnabled(
    state: SearchState,
    toggleTimeOfDayEnabled: boolean
  ) {
    state.toggleTimeOfDayEnabled = toggleTimeOfDayEnabled;
  },
  setInvalidSearchHistoryDateText(state: SearchState, errorText: string) {
    state.invalidSearchHistoryDateText = errorText;
  }
};

const getters: GetterTree<SearchState, RootState> = {
  initSelectedConversionIds(state: SearchState): number[] {
    const conversionDefinitionIds: number[] = [];

    if (
      state.selectCondition instanceof SelectByConversionCondition ||
      state.selectCondition instanceof SelectByAppCondition
    ) {
      conversionDefinitionIds.push(
        ...state.selectCondition.conversionDefinitionIds
      );
    }

    if (
      state.selectCondition instanceof SelectByEngagementCondition &&
      state.selectCondition.analysisType === AnalysisType.Cv
    ) {
      conversionDefinitionIds.push(
        state.selectCondition.conversionDefinitionId
      );
    }

    return conversionDefinitionIds;
  }
};

const actions: ActionTree<SearchState, RootState> = {
  /**
   * Select検索を実行する
   * 検索結果は少しずつ返ってくる。
   * 最初の検索結果が返ってきたら、ユーザ状態を更新する
   */
  async executeSelect(
    { commit, state, dispatch, rootState },
    args: ExecuteSelectArgs
  ) {
    let historyId = args.historyId || null;
    const { selectCondition, setHistoryIdToUrl, favoriteSearchLabel } = args;

    // 現状の状態/データをクリア
    dispatch("initializeBeforeSearch");

    // 検索条件を保存しておく
    commit("setSelectCondition", selectCondition);

    // セグメント別分析の結果をリセット
    dispatch("segmentedTrends/resetSegmentedTrendsData", null, { root: true });

    // Wordcloudのデータをリセット
    commit("wordcloud/resetWordcloudData", null, { root: true });

    dispatch("fetchSelectedConversionIds");

    // お気に入り検索タイトルがあれば、表示ためにstoreに保存
    if (favoriteSearchLabel) {
      commit("setFavoriteSearchLabel", favoriteSearchLabel);
    }

    // historyIdがあればstateに保存
    commit("setHistoryId", historyId);

    // 検索条件を投げてhistoryIdを取得
    historyId = historyId
      ? historyId
      : await dispatch("fetchHistoryId", selectCondition);

    // historyIdが取得できなければ処理を中止
    if (!historyId) {
      return;
    }

    // Redirect User to v3 as soon as historyId is aquired if URL path is /users
    // Do not redirect for /bookmark-users ,/memo or /watch-history
    if (window.location.pathname == "/users") {
      const origin = window.location.origin;
      // Replace current /users history entry to allow user to navigate back to top-page.
      window.location.replace(`${origin}/users?hid=${historyId}`);
    }

    // 検索条件リストアのためにhistoryIdをURLにセットする
    if (setHistoryIdToUrl) {
      setHistoryIdToUrl(historyId);
    }

    // historyIdを使って件数、検索結果を取得
    const fetchUserResultsParams: FetchSearchResultsArgs = {
      historyId,
      chunkSize: EACH_REQUEST_USER_FETCH_COUNT
    };
    await dispatch("fetchSearchResults", fetchUserResultsParams);

    // 履歴IDが更新された（違う検索が実行された）場合はキャンセル
    if (state.historyId !== historyId) {
      return;
    }

    // 実行するdispatchType
    const dispatches: string[] = [];

    /**
     * 検索終了前に絞り込みをしている場合、完了していない絞り込みのhistoryIdで
     * クラスタリングリストを取得してしまいエラーになるため、ここでは取得せずに絞り込み後に、
     * 絞り込み用のクラスタリングリストを取得する。
     *
     * 絞り込み解除後に、検索用のクラスタリングリストを検索のhistoryId使用して再度取得するので
     * 絞り込みしている場合は、検索用クラスタリングリストは無くても問題ない
     *
     * ユーザ数が0場合はクラスタリングリストとセグメント別分析の結果は取得しない
     */
    if (!rootState.filter.isFilterMode && state.userCount > 0) {
      dispatches.push("clustering/fetchClusteringList");
      // クラスタリングリスト取得と並行して、セグメント分析の結果を取得する
      dispatches.push("segmentedTrends/executeAnalysisOfActiveTab");
    }

    // 検索条件が追加されているので、historyを更新する
    dispatches.push("searchHistory/updateSearchHistories");

    await Promise.all(
      dispatches.map(type => dispatch(type, null, { root: true }))
    );
  },
  /**
   * 絞り込みとSelect検索を実行する
   * 絞り込みクリアで検索結果を表示する必要があるのと、
   * 絞り込みのために historyId が必要なので、ここで一度に実行する
   */
  async executeSelectAndFilter(
    { commit, state, rootState, dispatch, rootGetters },
    {
      filterCondition,
      historyId,
      setHistoryIdToUrl
    }: {
      filterCondition: FilterCondition;
      historyId: number;
      setHistoryIdToUrl?: (id: number) => void;
    }
  ) {
    if (state.selectCondition === null) {
      return;
    }

    // 現状の状態/データをクリア
    dispatch("initializeBeforeSearch");

    // 絞り込みモードにする
    commit("filter/setIsFilterMode", true, { root: true });

    const client = rootState.client.client;

    const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
    const isContractApp = client !== null ? client.isContractApp : false;
    // Create deepCopy of history filterCondition to avoid mutating history.filterCondition during search
    // original passed to executeFilter, deepCopy passed to form
    const filterConditionFromHistoryImmutable = deepCopyFilterCondition(
      filterCondition,
      canUseWebdataFeatures,
      isContractApp,
      rootGetters["clientSettings/activeDefinitions"],
      rootGetters["system/activeGlobalConversionDefinitions"],
      rootState.system.globalConversionAttributeDefinitions
    );
    commit(
      "filter/setFilterConditionForForm",
      filterConditionFromHistoryImmutable,
      {
        root: true
      }
    );

    // クラスタリングを解除
    dispatch("clustering/resetClustering", null, { root: true });

    // セグメント別分析の結果をリセット
    dispatch("segmentedTrends/resetSegmentedTrendsData", null, { root: true });

    // Wordcloudのデータをリセット
    commit("wordcloud/resetWordcloudData", null, { root: true });

    dispatch("fetchSelectedConversionIds");

    // historyIdがあればstateに保存
    commit("setHistoryId", historyId);

    // 検索条件を投げてhistoryIdを取得
    historyId = historyId
      ? historyId
      : await dispatch("fetchHistoryId", state.selectCondition);

    // historyIdが取得できなければ処理を中止
    if (historyId === null) {
      return;
    }

    // 履歴IDが更新された（違う検索が実行された）場合はキャンセル
    if (state.historyId !== historyId) {
      return;
    }

    // 絞り込みクリアしたときに検索結果を表示する必要があるので、historyIdを使って件数、検索結果を取得
    await dispatch("fetchSearchResults", {
      historyId,
      chunkSize: EACH_REQUEST_USER_FETCH_COUNT
    });

    // 絞り込み実行
    await dispatch(
      "filter/executeFilter",
      {
        filterConditionArg: filterCondition,
        setHistoryIdToUrl
      },
      {
        root: true
      }
    );

    // 履歴IDが更新された（違う検索が実行された）場合はキャンセル
    if (state.historyId !== historyId) {
      return;
    }

    // 検索条件が追加されているので、historyを更新する
    await dispatch("searchHistory/updateSearchHistories", null, { root: true });
  },
  /**
   * SearchHistoryから検索を実行する
   * もし絞り込み条件を持っていれば絞り込みも実行する
   */
  async executeSelectFromHistory(
    { commit, dispatch },
    {
      history,
      setHistoryIdToUrl,
      favoriteSearchLabel,
      favoriteSearchPeriod
    }: {
      history: SearchHistory;
      setHistoryIdToUrl?: (historyId: number) => void;
      favoriteSearchLabel?: string;
      favoriteSearchPeriod?: number;
    }
  ) {
    if (history.selectCondition === null) {
      return;
    }
    let condition =
      favoriteSearchPeriod === undefined || favoriteSearchPeriod === -1
        ? history.selectCondition
        : overwriteDates(history.selectCondition, favoriteSearchPeriod);

    /**
     * To validate if the date from hid url is invlaid
     * - startDate and endDate will update automatically
     * - User will see alert message of updated date
     */
    if (
      condition instanceof SelectByConversionCondition ||
      condition instanceof SelectByAppCondition ||
      condition instanceof SelectByBusinessEventCondition ||
      condition instanceof SelectByContactCondition
    ) {
      if (condition.startDate !== null && condition.endDate !== null) {
        const validationResult: HistoryDateValidationResult = validationCheckForSelectionHistory(
          condition.startDate,
          condition.endDate
        );

        if (!validationResult.isValid) {
          condition = overwriteDatesIfOutdated(
            validationResult.startDate,
            validationResult.endDate,
            condition
          );
          commit(
            "setInvalidSearchHistoryDateText",
            validationResult.errorMessage
          );
        }
      }
    }

    dispatch("searchForm/setCondition", condition, {
      root: true
    });
    dispatch("searchForm/setDisplayFormFromCondition", condition, {
      root: true
    });

    const hasFilter = history.filterCondition !== null;
    if (!hasFilter) {
      await dispatch("executeSelect", {
        selectCondition: condition,
        setHistoryIdToUrl,
        favoriteSearchLabel: favoriteSearchLabel
      });
      return;
    }

    commit("setSelectCondition", condition);
    commit("setFavoriteSearchLabel", favoriteSearchLabel || "");
    await dispatch("executeSelectAndFilter", {
      filterCondition: history.filterCondition,
      setHistoryIdToUrl
    });
  },
  /**
   * HistoryIdから検索を取得して実行する
   */
  async executeSelectFromHistoryId(
    { dispatch, rootState, rootGetters },
    historyId: number
  ) {
    let history!: SearchHistory | null;

    try {
      const historyRes: ApiRes.SelectionHistory = await rootState.api.searchHistory.getSelectionHistoryById(
        historyId
      );
      const client = rootState.client.client;
      const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
      const isContractApp = client !== null ? client.isContractApp : false;
      history = buildSearchHistory(
        historyRes,
        [],
        canUseWebdataFeatures,
        isContractApp,
        rootGetters["clientSettings/activeDefinitions"],
        rootState.system.globalConversionAttributeDefinitions,
        rootGetters["clientSettings/activeMeasurementSites"]
      );

      if (history === null) {
        throw new Error();
      }
    } catch (e) {
      throw new Error(ERROR_CREATE_CONDITION_FROM_HISTORY_ID);
    }

    // historyを使って検索
    await dispatch("executeSelectFromHistory", { history });
  },
  /**
   * 現在指定されている検索条件にマッチするユーザの件数を取得してセットする
   *
   * 件数が取得できていない場合は数秒(FETCH_COUNT_INTERVAL_MSEC)待機したのち
   * もう一度問い合わせる。
   *
   * ユーザ取得のレスポンスでも件数は取得される可能性があり
   * そちらでセットされている場合は、問い合わせを終了する。
   */
  async fetchUserCount({ commit, state }) {
    if (state.historyId !== null && state.countIterator !== null) {
      // 取得する前に履歴IDを保存する
      const historyId = state.historyId;

      for await (const count of state.countIterator) {
        // 取得後に検索が変更される可能性があるので、変更後に変わっていないか確認する
        if (count !== null && state.historyId === historyId) {
          commit("setUserCount", count);
        }

        // すでに件数がセットされているなら抜ける
        if (state.isCountFetched) {
          break;
        }

        // 違う検索ならエラーを返す
        if (state.historyId !== historyId) {
          throw new Error(ERROR_HISTORY_ID_IS_CHANGED);
        }

        await sleep(FETCH_COUNT_INTERVAL_MSEC);
      }
    }

    return;
  },
  /**
   * マッチしたユーザ数が一定数(DEFAULT_USER_FETCH_COUNT)に達するか
   * ユーザを全部取得できるまで、検索結果を取得する
   */
  async fetchInitialSelectResult({ commit, state, dispatch }) {
    commit("setIsFetched", false);

    // DEFAULT_USER_FETCH_COUNT 分ユーザを取得するか、ユーザを全取得するまで問い合わせる
    while (
      state.users.length < DEFAULT_USER_FETCH_COUNT &&
      (!state.isCountFetched || state.users.length < state.userCount)
    ) {
      const result = await dispatch("fetchSelectResultOnce").catch(e => {
        throw e;
      });
      // falseが返ってくるのは取得が完了している場合なのでループを抜ける
      if (result === false) {
        break;
      }
    }
    commit("setIsFetched", true);

    await dispatch("showUserDetail");
  },
  /**
   * 最後に実行したSelect検索の次の結果を取得する
   */
  async fetchNextSelectResult({ commit, dispatch }) {
    commit("setIsFetched", false);
    await dispatch("fetchSelectResultOnce").catch(e => {
      if (e.message !== ERROR_HISTORY_ID_IS_CHANGED) {
        throw e;
      }
    });
    commit("setIsFetched", true);
  },
  /**
   * 現在指定している検索の結果を一度だけ問い合わせる
   */
  async fetchSelectResultOnce({
    commit,
    state,
    rootState,
    rootGetters
  }): Promise<boolean> {
    // 検索の情報がない場合は無視
    if (state.historyId === null || state.selectResultIterator === null) {
      return false;
    }

    // 取得する前に履歴IDを保存する
    const historyId = state.historyId;
    const iteratorResult: IteratorResult<ApiRes.SelectSearchResult> = await state.selectResultIterator.next();

    // 取得がおわっているか確認する
    if (iteratorResult.done) {
      return false;
    }

    // 違う検索ならエラーを返す
    if (state.historyId !== historyId) {
      throw new Error(ERROR_HISTORY_ID_IS_CHANGED);
    }

    // レスポンスを取得
    const userSelectResult: ApiRes.SelectSearchResult = iteratorResult.value;

    // もし検索が終わってる、かつカウントがセットされていないなら、カウントをセット
    if (userSelectResult.search_finished && !state.isCountFetched) {
      commit("setUserCount", userSelectResult.num_found_users);
    }

    // ウェブとアプリ両方の契約があるか
    const canUseWebdataFeaturesAndIsContractApp = rootState.client.client
      ? rootState.app.canUseWebdataFeatures &&
        rootState.client.client.isContractApp
      : false;

    // ユーザモデルのビルド
    const users: User[] = userSelectResult.users.map(user =>
      User.build(
        user,
        rootGetters["clientSettings/allConversionDefinitions"],
        rootState.clientSettings.npsDefinitions,
        rootState.clientSettings.businessEventDefinitions,
        rootState.clientSettings.enqueteDefinitions,
        rootState.clientSettings.businessIndexDefinitions,
        canUseWebdataFeaturesAndIsContractApp
      )
    );

    // 取得したユーザ情報を保存
    commit("addSelectResult", users);

    return true;
  },
  async downloadCsv({ commit, state, rootState }) {
    commit("setIsCsvDownloading", true);

    const historyId: number | null = rootState.filter.isFilterMode
      ? rootState.filter.filterHistoryId
      : state.historyId;
    if (historyId === null) {
      return;
    }

    const response: string = await rootState.api.search.getCsv(
      historyId,
      getCurrentDate().getTimezoneOffset()
    );

    const filename = getCsvFileName();

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(
      new Blob(["\ufeff", response], { type: "text/csv;" })
    );

    link.download = filename;

    document.body.appendChild(link); // for Firefox etc.
    link.click();
    document.body.removeChild(link); // for Firefox etc.

    commit("setIsCsvDownloading", false);
  },
  showUserDetail({ commit, state, dispatch, rootState }) {
    // ユーザ取得後にtargetUserIdが存在すれば（users/xxxxxxでアクセスされていれば）、ユーザ詳細を表示する
    // ユーザ指定アクセスであれば取得されるユーザは1名なのでそれも確認する
    if (rootState.user.targetUserId !== null && state.users.length === 1) {
      const user = state.users[0];
      if (user.id == rootState.user.targetUserId) {
        dispatch(
          "user/showUserDetail",
          {
            user: user,
            startBaseTimeUsec: rootState.user.targetStartBaseTimeUsec
          },
          { root: true }
        );
      }
    }
    // 次にユーザ詳細を開かないようにtargetUserIdをクリアする
    commit("user/setTartgeUserId", null, { root: true });
  },
  goToUserList({ dispatch }, userIds: string[]) {
    const condition = new SelectByUserIdCondition(userIds);
    dispatch("executeSelect", { selectCondition: condition });
  },
  clearForEmptyState({ commit }) {
    commit("initializeAll");
    commit("setSelectCondition", null);
    commit("setUserCount", 0);

    // ローディングを止めるためにisFetchedをtrueにする
    commit("setIsFetched", true);

    // HOTFIX 絞り込みとクラスタリングもリセットする
    commit("filter/initializeFilterResults", null, { root: true });
    commit("filter/setIsFilterMode", false, { root: true });
    commit("clustering/initializeClusteringResults", null, { root: true });
    commit("clustering/setIsClusteringMode", false, { root: true });
  },
  // 検索前の初期化処理
  initializeBeforeSearch({ commit, dispatch }) {
    // 履歴IDをリセット
    commit("setHistoryId", null);
    // 検索条件ラベルをクリア
    commit("setFavoriteSearchLabel", "");
    // 検索結果・ローディング状態をクリア
    commit("initializeSearchResults");
    // 絞り込みの状態/条件/結果をクリア
    dispatch("filter/resetFilter", null, { root: true });
    // クラスタリングを解除
    dispatch("clustering/resetClustering", null, { root: true });
    // WordCloudのデータをリセット
    commit("wordcloud/resetWordcloudData", null, { root: true });
  },
  // historyIdの取得
  async fetchHistoryId(
    { commit, rootState, dispatch },
    selectCondition: SelectCondition
  ): Promise<number | null> {
    let historyId: number | null = null;

    const npsDefinition =
      rootState.clientSettings.activeNpsDefinitions.length > 0
        ? rootState.clientSettings.activeNpsDefinitions[0]
        : null;

    /**
     * エンゲージメント検索の場合は、historyIdを取得するために、Athenaを利用したAPIを使用する。
     * そのため問い合わせ結果が出るまでループする必要があるので、切り出した別関数からhistoryIdを取得する。
     */
    if (selectCondition instanceof SelectByEngagementCondition) {
      commit("preference/setSearchResultView", SearchResultViews.Overview, {
        root: true
      });

      historyId = await dispatch(
        "engagementSearch/fetchHistoryId",
        selectCondition,
        { root: true }
      );
    } else if (rootState.tour.isTourMode) {
      historyId = await rootState.api.tour.selectUser(
        selectCondition,
        npsDefinition
      );
    } else {
      /**
       * サーバーに検索を投げて、historyIdを取得
       * 検索条件がロイヤルティの場合用にNpsDefinitionがあればNpsDefinitionも渡す
       */
      historyId = await rootState.api.search.selectUser(
        selectCondition,
        npsDefinition
      );
    }

    // historyIdがあればstateに保存
    commit("setHistoryId", historyId);

    return historyId;
  },
  // 検索結果の取得
  async fetchSearchResults(
    { commit, dispatch, state, rootState },
    { historyId, chunkSize }: FetchSearchResultsArgs
  ) {
    commit("initializeSearchResults");

    // 検索結果を返すIteratorを取得
    const userIterator: AsyncIterableIterator<ApiRes.SelectSearchResult> = rootState.api.search.getSelectUserResult(
      historyId,
      chunkSize
    );

    // 件数を返すIteratorを取得
    const countIterator: AsyncIterableIterator<
      number | null
    > = rootState.api.search.getSelectCount(historyId);

    commit("setSelectResultIterator", userIterator);
    commit("setCountIterator", countIterator);

    await Promise.all([
      // 件数を取得する
      dispatch("fetchUserCount"),
      // 最初の結果を取得する
      dispatch("fetchInitialSelectResult")
    ]).catch((e: any) => {
      // 履歴IDが変わった時はエラーではないので、以下の処理をキャンセル
      if (
        e.message === ERROR_HISTORY_ID_IS_CHANGED ||
        historyId !== state.historyId
      ) {
        return;
      }
      throw e;
    });
  },

  clearSelectCondition({ commit }) {
    commit("clearSelectCondition");
  },

  fetchSelectedConversionIds({ commit, getters }) {
    const result: number[] = getters.initSelectedConversionIds;

    commit("setSelectedConversionIds", result);
  }
};

export const search = {
  namespaced: true,
  state: new SearchState(),
  mutations,
  getters,
  actions
};
