<template>
  <div
    :id="gramId"
    class="ud-Visit_Gram"
    :class="{
      'ud-Visit_Gram_Omo': isOmo,
      'ud-Visit_Gram_CompanyActivity': isCompany
    }"
  >
    <div class="ud-Visit_GramIcon">
      <div :class="iconClass">
        <Icon :icon="iconTel" :size="16" :color="iconColor" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_GramTime">{{ time }}</div>
      <div :class="actionClass">{{ title }}</div>
      <div v-if="description" class="ud-Visit_GramActionDetail">
        <div class="ud-Visit_GramActionDetailText">{{ description }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import { Gram } from "@/models/Gram";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";

@Component({
  components: { Icon }
})
export default class GramContactTel extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  iconTel = Icons.Tel;

  get iconColor(): Colors {
    return this.isCompany ? Colors.Base900 : Colors.Blue900;
  }

  get gramId(): string {
    return this.gram.id;
  }

  get time(): string {
    return this.gram.hasTimeOfDay ? this.gram.timeTx : "";
  }

  get isCompany(): boolean {
    return this.gram.isCompany;
  }

  get title(): string {
    return this.gram.contactTelTitle;
  }

  get description(): string {
    return this.gram.contactTelDescription;
  }

  get iconClass(): string {
    return this.isCompany
      ? "ud-Visit_GramIcon_CompanyActivity"
      : "ud-Visit_GramIcon_Circle";
  }

  get actionClass(): string {
    return this.isCompany
      ? "ud-Visit_GramCompanyActivity"
      : "ud-Visit_GramAction";
  }
}
</script>
