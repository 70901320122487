import { SelectConditionInterface } from "@/models/search/select-condition/SelectCondition";
import {
  AdditionalSelectCondition,
  convertAdditionalConditionToJson,
  convertJsonToAdditionalCondition
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { MAX_HOLD_ADDITIONAL_CONDITION_SIZE } from "@/models/search/select-condition/SelectCondition";
import { SelectByNpsQuery, SelectQuery } from "@/api/apis/ApiSearch";
import { msecToSec, secToMsec } from "@/util/date-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { i18n } from "@/i18n";
import { getCurrentDate } from "@/util/date-util";

/**
 * 顧客ロイヤルティによるユーザ選定条件(OMO版のみ)
 *
 * Relational Net Promoter Score
 * @see https://www.bell24.co.jp/ja/ccwiki/cat1/npsnet-promoter-score.html
 */
export class SelectByNpsCondition implements SelectConditionInterface {
  constructor(
    // スコア下限
    public readonly from: number,
    // スコア上限
    public readonly to: number,
    /**
     * 探索を始める日付
     *
     * この日付から過去に遡って、スコアが指定された範囲に収まるユーザを選定する
     * 最大で2年間遡り、2000人見つかればその時点で検索を打ち切る。
     */
    public readonly date: Date,
    /**
     * 追加の検索条件
     *
     * 顧客ロイヤルティ指標(個別のみ)、ビジネス指標、ユーザ属性に対する条件を5つまで指定できる
     */
    public readonly additionalConditions: AdditionalSelectCondition[]
  ) {}

  static emptyCondition(): SelectByNpsCondition {
    return new SelectByNpsCondition(0, 0, getCurrentDate(), []);
  }

  // AdditionalSelectConditionを追加できるか
  get isAdditionalConditionAppendable(): boolean {
    return (
      this.additionalConditions.length < MAX_HOLD_ADDITIONAL_CONDITION_SIZE
    );
  }

  validate(npsDefinition: NpsDefinition): ValidationResult {
    if (this.from < npsDefinition.min || this.from > npsDefinition.max) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.inputValueWithinRange") as string
      };
    }
    if (this.to < npsDefinition.min || this.to > npsDefinition.max) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.inputValueWithinRange") as string
      };
    }
    if (this.from > this.to) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.enterSizeReguration") as string
      };
    }

    return { isValid: true };
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectByNpsConditionToJson(
  condition: SelectByNpsCondition
): SelectByNpsQuery {
  return {
    change: null,
    score_time: {
      end_time_sec: msecToSec(condition.date.getTime()),
      category: null,
      val: null,
      val_from: condition.from,
      val_to: condition.to
    },
    sub_cnds: condition.additionalConditions.map(
      convertAdditionalConditionToJson
    )
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByNpsCondition(
  query: SelectByNpsQuery,
  activeDefinitions: ActiveDefinitions
): SelectByNpsCondition {
  const additionalConditions: AdditionalSelectCondition[] = [];
  query.sub_cnds.forEach(cnd => {
    const addCnd = convertJsonToAdditionalCondition(cnd, activeDefinitions);
    if (addCnd !== null) {
      additionalConditions.push(addCnd);
    }
  });

  return new SelectByNpsCondition(
    query.score_time.val_from,
    query.score_time.val_to,
    new Date(secToMsec(query.score_time.end_time_sec)),
    additionalConditions
  );
}

/**
 * JSONがコンバージョン検索のJSONかどうかを判定する
 */
export function isSelectByNpsQuery(
  query: SelectQuery
): query is SelectByNpsQuery {
  return "change" in query && query["change"] === null;
}
