<i18n
  src="@/i18n/components/client-settings/conversion/conversion-confirm-table.json"
></i18n>
<template>
  <div class="conversionConfirmTable">
    <SettingsTable>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'conversionName'"
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell width="480px"> {{ name }} </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'conversionParameter'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell> {{ path.join(" → ") }} </SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";

@Component({
  components: {
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell
  }
})
export default class ConversionConfirmTable extends Vue {
  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: Array, required: true })
  path!: string[];
}
</script>

<style lang="scss" scoped>
.conversionConfirmTable {
  margin-bottom: 16px;
  min-width: 680px;
}
</style>
