import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";

export interface NewsForLoginPageList {
  messages: NewsForLoginPage[];
}
export interface NewsForLoginPage {
  id: number;
  message: string;
  expiration_date: string;
  created_at: string;
}

// CV一覧（クライアント共通）
export interface GlobalConversionList {
  conversions: GlobalConversion[];
}
export interface GlobalConversion {
  id: number;
  conversion_type: number;
  path: string[];
  name: string;
}

// CV・イベント属性リスト（クライアント共通）
export interface GlobalConversionAttributeList {
  conversion_attributes: GlobalConversionAttribute[];
}
export interface GlobalConversionAttribute {
  id: number;
  query: string;
  name: string;
  attribute_type: number;
}

// 検索エンジンリスト
export interface SearchEngineList {
  search_engines: SearchEngine[];
}
export interface SearchEngine {
  id: number;
  name: string;
}

export default class ApiSystem {
  constructor(private readonly httpClient: HttpClient) {}
  public getNewsForLoginPageList(): Promise<NewsForLoginPageList> {
    return this.httpClient.get<NewsForLoginPageList>(ApiUrl.INFOMATION_LIST);
  }

  public getGlobalConversionList(): Promise<GlobalConversionList> {
    return this.httpClient.get<GlobalConversionList>(ApiUrl.GLOBAL_CV_LIST);
  }

  public getGlobalConversionAttributeList(): Promise<
    GlobalConversionAttributeList
  > {
    return this.httpClient.get<GlobalConversionAttributeList>(
      ApiUrl.GLOBAL_CV_ATTR_LIST
    );
  }

  public getSearchEngineList(): Promise<SearchEngineList> {
    return this.httpClient.get<SearchEngineList>(ApiUrl.SEARCH_ENGINE_LIST);
  }
}
