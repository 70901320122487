import {
  MapConversionAndCvRequest,
  MapConversionRequest
} from "@/api/apis/client-settings/ApiAppConversion";
import {
  AppConversionDefinition,
  MapConversionDefinitionListWithCv
} from "@/models/client-settings/AppConversionDefinition";
import { CreationMethod } from "@/models/client-settings/Coordination";
import { RootState } from "@/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";

export class AppConversionState {
  appConversionList: AppConversionDefinition[] = [];
  newRegisteredMapConversions: AppConversionDefinition[] = [];
  isFetched: boolean = false;
}

const mutations: MutationTree<AppConversionState> = {
  setAppConversions(
    state: AppConversionState,
    appConversionList: AppConversionDefinition[]
  ) {
    state.appConversionList = appConversionList;
  },

  setIsFetched(state: AppConversionState, isFetched: boolean) {
    state.isFetched = isFetched;
  },

  setCreatedMapConversion(
    state: AppConversionState,
    mapConversions: AppConversionDefinition[]
  ) {
    state.newRegisteredMapConversions = mapConversions;
  }
};

const getters = <GetterTree<AppConversionState, RootState>>{
  activeAppConversions(state): AppConversionDefinition[] {
    return state.appConversionList.filter(cv => !cv.isDisabled);
  },
  disabledAppConversions(state): AppConversionDefinition[] {
    return state.appConversionList.filter(cv => cv.isDisabled);
  }
};

const actions: ActionTree<AppConversionState, RootState> = {
  async fetchAppConversionList({ commit, rootState }) {
    commit("setIsFetched", false);

    const response = await rootState.api.appConversion.getList();
    commit("setAppConversions", response);
    commit("setIsFetched", true);
  },
  async registerNewCvAndMapConversion(
    { commit, rootState },
    def: MapConversionDefinitionListWithCv
  ) {
    let response;
    if (def.creationMethod == CreationMethod.NEW) {
      const params: MapConversionAndCvRequest = {
        conversion_name: def.conversionName,
        parameters: def.parameters
      };
      response = await rootState.api.appConversion.registerCvAndMapConversion(
        params,
        def.coordinationId
      );
    } else {
      const params: MapConversionRequest = {
        parameters: def.parameters
      };
      response = await rootState.api.appConversion.registerMapConversion(
        params,
        def.coordinationId,
        def.conversionId
      );
    }

    commit("setCreatedMapConversion", response);
  }
};

export const appConversion = {
  namespaced: true,
  state: new AppConversionState(),
  mutations,
  actions,
  getters
};
