<i18n
  src="@/i18n/components/client-settings/conversion/conversion-confirm-table.json"
></i18n>
<template>
  <div class="bulkConversionConfirmTable">
    <SettingsTable>
      <template slot="header">
        <SettingsTableRow>
          <SettingsTableHeaderCell
            v-t="'conversionName'"
            width="200px"
            :style="{ padding: '20px' }"
          />
          <SettingsTableHeaderCell
            v-t="'conversionParameter'"
            width="200px"
            :style="{ padding: '20px' }"
          />
        </SettingsTableRow>
      </template>
      <SettingsTableRow v-for="(row, idx) in bulkData" :key="`bulk-${idx}`">
        <SettingsTableCell>{{ row.name }}</SettingsTableCell>
        <SettingsTableCell>{{ row.path.join(" → ") }}</SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import SettingsTableHeaderCell from "@/components/table/SettingsTableHeaderCell.vue";
import { ConversionEditableData } from "@/models/client-settings/ConversionDefinition";

@Component({
  components: {
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell,
    SettingsTableHeaderCell
  }
})
export default class BulkConversionConfirmTable extends Vue {
  @Prop({ type: Array, required: true })
  bulkData!: ConversionEditableData[];
}
</script>

<style lang="scss" scoped>
.bulkConversionConfirmTable {
  margin-bottom: 16px;
}
</style>
