import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";

export class AlertState {
  message: string | null = null;
}

const mutations: MutationTree<AlertState> = {
  updateMessage(state: AlertState, message: string) {
    state.message = message;
  },
  initialize(state: AlertState) {
    state.message = null;
  }
};

const actions: ActionTree<AlertState, RootState> = {
  showAlert({ commit }, message: string) {
    commit("updateMessage", message);
  }
};

export const alert = {
  namespaced: true,
  state: new AlertState(),
  mutations: mutations,
  actions: actions
};
