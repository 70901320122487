<template>
  <div class="filter-base-select-box">
    <div v-if="hasPrefix" class="prefix">{{ prefix }}</div>
    <SelectBox
      :value="value"
      :options="options"
      :width="width"
      @input="onInput"
    />
    <div v-if="hasSuffix" class="suffix">{{ suffix }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";

@Component({
  components: {
    SelectBox
  }
})
export default class FilterBaseSelectBox extends Vue {
  @Model("input", { type: [String, Number], required: true })
  value!: string | number;

  @Prop({ type: Array, required: true })
  options!: SelectOption[];

  @Prop({ type: String, default: "" })
  prefix!: string;

  @Prop({ type: String, default: "" })
  suffix!: string;

  @Prop({ type: String, default: "240px" })
  width!: string;

  onInput(value: string) {
    this.$emit("input", value);
  }

  get hasPrefix(): boolean {
    return this.prefix !== "";
  }

  get hasSuffix(): boolean {
    return this.suffix !== "";
  }
}
</script>
<style lang="scss" scoped>
.filter-base-select-box {
  display: flex;
  align-items: center;
}

.prefix,
.suffix {
  color: $colorBase700;
  font-size: 14px;
}
.prefix {
  margin-right: 7px;
}

.suffix {
  margin-left: 7px;
}
</style>
