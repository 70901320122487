import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";

export const INQUIRY_TOUR_ID = "5192c126-f25a-2065-27e7-0678ecd70960";
export const ERROR_TOUR_ID = "2438f927-e28e-8236-92h3-2837sjb47261";
export const MULTI_INFLOW_TOUR_ID = "96b7f86f-6f04-4d3e-b664-813e03455b4d";

export type TourCondition =
  | TourConditionBaseModel
  | TourConditionSearchAndFilterModel;

export interface TourConditionBase {
  id: string;
  title: string;
  suggestion: string;
  conversions: string[];
  reason: string;
  description: string;
  eyecatchImageFileName: string;
  panelImageFileName: string;
}

export class TourConditionBaseModel {
  constructor(public readonly tourCondition: TourConditionBase) {}

  get id(): string {
    return this.tourCondition.id;
  }

  get title(): string {
    return this.tourCondition.title;
  }

  get suggestion(): string {
    return this.tourCondition.suggestion;
  }

  get conversions(): string[] {
    return this.tourCondition.conversions;
  }

  get reason(): string {
    return this.tourCondition.reason;
  }

  get description(): string {
    return this.tourCondition.description;
  }

  get eyecatchImageUrl() {
    return require(`@/assets/img/tours/${this.tourCondition.eyecatchImageFileName}`);
  }

  get panelImageUrl() {
    return require(`@/assets/img/tours/${this.tourCondition.panelImageFileName}`);
  }
}

export interface TourConditionSearchAndFilter extends TourConditionBase {
  selectCondition: SelectCondition;
  filterCondition: FilterCondition;
}

export class TourConditionSearchAndFilterModel extends TourConditionBaseModel {
  constructor(public readonly tourCondition: TourConditionSearchAndFilter) {
    super(tourCondition);
  }

  get filterCondition(): FilterCondition {
    return this.tourCondition.filterCondition;
  }

  get selectCondition(): SelectCondition {
    return this.tourCondition.selectCondition;
  }
}
