<i18n src="@/i18n/components/csv-download-button.json"></i18n>
<template>
  <div class="csvDownloadButton">
    <Tooltip
      :text="tooltipText"
      :placement="tooltipPlacement"
      :active="!disabled"
    >
      <div class="csvDownloadButton__container">
        <div
          v-if="isDownloading"
          class="csvDownloadButton__icon csvDownloadButton__icon--downloading"
        >
          <Icon
            :icon="Icons.Download"
            :color="Colors.Base900"
            :size="iconSize"
          />
        </div>

        <IconButton
          v-else
          class="csvDownloadButton__icon csvDownloadButton__button"
          data-cy="csvDownloadButton__button"
          :icon="Icons.Download"
          :size="iconSize"
          :button-type="IconButtonType.Weak"
          :disabled="disabled"
          @click="onClick"
        />
      </div>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { TooltipPlacement } from "@/const/tooltip";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {
    Tooltip,
    Icon,
    IconButton
  }
})
export default class CsvDownloadButton extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  isDownloading!: boolean;

  @Prop({ type: String, default: TooltipPlacement.TopEnd })
  tooltipPlacement!: TooltipPlacement;

  @Prop({ type: Number, default: 20 })
  iconSize!: number;

  @Emit("click")
  onClick() {}

  Colors = Colors;
  Icons = Icons;
  IconButtonType = IconButtonType;

  get tooltipText(): string {
    return this.isDownloading
      ? (this.$i18n.t("downloading") as string)
      : (this.$i18n.t("csvDownload") as string);
  }
}
</script>

<style scoped lang="scss">
.csvDownloadButton {
  display: inline-block;
}

.csvDownloadButton__container {
  position: relative;
  width: 20px;
  height: 20px;
}

.csvDownloadButton__icon {
  position: absolute;
}

.csvDownloadButton__icon--downloading {
  display: inline-block;
  vertical-align: top;
  animation: downloading 1.8s infinite;
}

@keyframes downloading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
