<template>
  <FilterBaseInputTextMatchType
    :value="pageTitle"
    :match-method="matchMethod"
    :input-form-suggestion-type="inputFormSuggestionType"
    class="filter-landing-page-title-form"
    data-cy="filter-landing-page-title-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterLandingPageTitleCondition } from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterLandingPageTitleField extends Vue {
  @Model("input", { type: FilterLandingPageTitleCondition, required: true })
  condition!: FilterLandingPageTitleCondition;

  inputFormSuggestionType = InputFormSuggestionType.WEB_TITLE;

  onInput(condition: FilterLandingPageTitleCondition) {
    this.$emit("input", condition);
  }

  get matchMethod(): MatchMethod {
    return this.condition.matchMethod;
  }

  get pageTitle(): string {
    return this.condition.pageTitle;
  }
  onInputText(pageTitle: string) {
    this.onInput(
      new FilterLandingPageTitleCondition(pageTitle, this.condition.matchMethod)
    );
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(
      new FilterLandingPageTitleCondition(this.condition.pageTitle, matchMethod)
    );
  }
}
</script>
