import { render, staticRenderFns } from "./BulkConversionConfirmTable.vue?vue&type=template&id=2c72f958&scoped=true"
import script from "./BulkConversionConfirmTable.vue?vue&type=script&lang=ts"
export * from "./BulkConversionConfirmTable.vue?vue&type=script&lang=ts"
import style0 from "./BulkConversionConfirmTable.vue?vue&type=style&index=0&id=2c72f958&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c72f958",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/client-settings/conversion/conversion-confirm-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fclient-settings%2Fconversion%2FBulkConversionConfirmTable.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports