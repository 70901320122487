<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <UgTable :sticky-top="stickyTop">
    <template #header>
      <UgTableHeaderRow>
        <UgTableHeaderCell :width="userColumnWidth">{{
          $t("user")
        }}</UgTableHeaderCell>
        <UgTableHeaderCell :width="updateColumnWidth">{{
          $t("favoriteUpdate")
        }}</UgTableHeaderCell>
        <UgTableHeaderCell>{{ $t("favoriteTitle") }}</UgTableHeaderCell>
        <UgTableHeaderCell :width="updateColumnWidth">{{
          $t("memoUpdate")
        }}</UgTableHeaderCell>
        <UgTableHeaderCell>{{ $t("memoContent") }}</UgTableHeaderCell>
      </UgTableHeaderRow>
    </template>
    <UgTableRow v-for="user in users" :key="user.id">
      <UgTableCell
        class="user-memo-table__user-cell"
        :class="{ 'current-user': isCurrentUser(user.id) }"
        :width="userColumnWidth"
        @mouse-enter="onMouseEnter($event, user.id)"
        @mouse-leave="onMouseLeave"
      >
        <slot :user="user" />
      </UgTableCell>
      <UgTableCell
        class="user-search-result__cell"
        :width="updateColumnWidth"
        >{{ registerDate(user.id) }}</UgTableCell
      >
      <UgTableCell
        class="user-search-result__cell user-memo-table_content"
        data-cy="user-search-result__cell"
        >{{ favoriteTitle(user.id) }}</UgTableCell
      >
      <UgTableCell
        class="user-search-result__cell"
        :width="updateColumnWidth"
        >{{ formatDate(user.memo.updatedDate) }}</UgTableCell
      >
      <UgTableCell class="user-search-result__cell user-memo-table_content">{{
        user.memo.content
      }}</UgTableCell>
    </UgTableRow>
  </UgTable>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDate, DateFormat } from "@/util/date-util";
import { User } from "@/models/User";
import { Favorite } from "@/models/userdata/Favorite";
import UgTable from "@/components/table/UgTable.vue";
import UgTableRow from "@/components/table/UgTableRow.vue";
import UgTableCell from "@/components/table/UgTableCell.vue";
import UgTableHeaderRow from "@/components/table/UgTableHeaderRow.vue";
import UgTableHeaderCell from "@/components/table/UgTableHeaderCell.vue";

const WIDTH_USER_COLUMN: number = 135;
const WIDTH_UPDATE_COLUMN: number = 210;

@Component({
  components: {
    UgTable,
    UgTableRow,
    UgTableCell,
    UgTableHeaderRow,
    UgTableHeaderCell
  }
})
export default class UserMemoTable extends Vue {
  @Prop({ type: Array, required: true })
  users!: User[];

  @Prop({ type: Array, default: [] })
  favorites!: Favorite[];

  @Prop({ type: String, default: "0" })
  stickyTop!: string;

  @Prop({ type: User, default: null })
  currentUser!: User;

  onMouseEnter(event: MouseEvent, id: string): void {
    this.$emit("mouse-enter", event, id);
  }

  onMouseLeave(event: MouseEvent): void {
    this.$emit("mouse-leave", event);
  }

  userColumnWidth: string = WIDTH_USER_COLUMN + "px";
  updateColumnWidth: string = WIDTH_UPDATE_COLUMN + "px";

  formatDate(date: Date): string {
    return formatDate(DateFormat.yyyy年M月d日E_HH時mm分_TZONEDIFF, date);
  }

  isCurrentUser(userId: string): boolean {
    if (this.currentUser) {
      return this.currentUser.id === userId;
    }
    return false;
  }

  favoriteTitle(userId: string): string {
    const favorite = this.favorites.find(f => f.userId === userId);

    return favorite?.description || "";
  }

  registerDate(userId: string): string {
    const favorite = this.favorites.find(f => f.userId === userId);
    if (favorite) {
      return formatDate(
        DateFormat.yyyy年M月d日E_HH時mm分_TZONEDIFF,
        favorite.updatedDate
      );
    }
    return "";
  }
}
</script>

<style scoped lang="scss">
.user-memo-table__user-cell {
  position: relative;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}

.user-memo-table_content {
  text-align: left;
  line-height: 1.5;
}

.current-user {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: $colorBlue900;
    content: "";
  }
}

.user-search-result__cell {
  max-width: 500px;
  line-height: 1.5;

  @include all-break-word();
}
</style>
