<template>
  <div
    :id="gramId"
    class="ud-Visit_Gram ud-Visit_Gram_Inflow ud-Visit_Gram_Launch"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_Circle">
        <Icon :icon="inflowRight" :size="17" :color="iconColor" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_InflowTitle">{{ title }}</div>

      <div v-if="hasConversionAttributes" class="ud-Visit_InflowAttrs">
        <div
          v-for="cvAttr in conversionAttributes"
          :key="cvAttr.id"
          class="ud-Visit_InflowAttr"
        >
          <div class="ud-Visit_InflowAttrTitle">{{ cvAttr.name }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ cvAttr.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import { Gram } from "@/models/Gram";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";

@Component({
  components: { Icon }
})
export default class GramLaunch extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  inflowRight = Icons.InflowRight;
  iconColor = Colors.Blue900;

  get gramId(): string {
    return this.gram.id;
  }

  get time(): string {
    return this.gram.timeTx;
  }

  get title(): string {
    return this.gram.launchTitle;
  }

  get conversionAttributes(): {
    id: number;
    name: string;
    value: string;
  }[] {
    return this.gram.conversionAttributes;
  }

  get hasConversionAttributes(): boolean {
    return 0 < this.conversionAttributes.length;
  }
}
</script>
