<template>
  <div class="calendarBase">
    <div class="calendarBase__title">
      <span class="calendarBase__text" v-text="title" />
    </div>
    <table class="calendarBase__body">
      <thead>
        <tr>
          <th v-for="day in dayOfWeek" :key="day.id" class="calendarBase__cell">
            {{ day.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(week, index) in table" :key="index">
          <td
            v-for="date in week"
            :key="date.getDate()"
            class="calendarBase__cell calendarBase__bodyCell"
            @click="onSelectDate(date)"
          >
            <slot :date="date" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Calendar, CalendarTable, Week } from "@/models/date-picker/Calendar";

@Component
export default class CalendarBase extends Vue {
  @Prop({ type: Calendar, required: true })
  calendar!: Calendar;

  onSelectDate(date: Date) {
    this.$emit("select-date", date);
  }

  get title(): string {
    return this.calendar.title;
  }

  get dayOfWeek(): Week[] {
    return this.calendar.weekTexts;
  }

  get table(): CalendarTable {
    const date = new Date(this.calendar.year, this.calendar.month, 0);
    return Calendar.buildCalendarTable(date);
  }
}
</script>

<style scoped lang="scss">
.calendarBase__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $datePickerCellHeight;
  border-bottom: 1px solid $colorBase400;
  text-align: center;
}

.calendarBase__text {
  display: inline-block;
  padding: 5px 10px 0;
  height: $datePickerCellHeight;
}

.calendarBase__body {
  margin-top: 5px;
  width: 100%;
  table-layout: fixed;
}

.calendarBase__cell {
  height: $datePickerCellHeight;
  color: $colorElmentUIGray600;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
}

.calendarBase__bodyCell {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
