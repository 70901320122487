import { ApiRes } from "@/api/api-res";
import { UgPermission } from "@/models/auth/UgPermission";
import { getInitalLanguage } from "@/i18n/index";
import { Mfa } from "@/models/auth/Mfa";

export class UgUser {
  public constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly loginName: string,
    public readonly isDisabled: boolean
  ) {}

  public static fromJson(json: ApiRes.UgUser): UgUser {
    return new UgUser(json.id, json.name, json.login_name, json.is_disabled);
  }

  public static fromJsonArray(list: ApiRes.UgUser[]): UgUser[] {
    return list.map(UgUser.fromJson);
  }
}

export class LoginUser implements UgUser {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly loginName: string,
    public readonly isDisabled: boolean,
    public readonly client: string,
    public readonly mailAddress: string,
    public readonly language: string,
    public readonly createdAt: string,
    public readonly isStaff: boolean,
    public readonly permission: UgPermission,
    public readonly mfa: Mfa
  ) {
    if (!["ja", "en", "zh-hant", "zh-hans"].includes(language)) {
      this.language = getInitalLanguage();
    }
  }

  public static fromJson(json: ApiRes.LoginUser): LoginUser {
    const user: LoginUser = new LoginUser(
      json.id,
      json.name,
      json.login_name,
      json.is_disabled,
      json.client,
      json.mail_address,
      json.language,
      json.created_at,
      json.is_staff,
      UgPermission.fromJson(json.permissions),
      Mfa.fromJson(json.mfa)
    );

    return user;
  }

  /**
   * User has owner permission or not
   * @returns boolean
   */
  get isSuperUser(): boolean {
    return (
      !this.permission.isAvailableUserTrend &&
      this.permission.isAvailableClientSettings
    );
  }

  get isGuest(): boolean {
    return !this.permission.isAvailableUserList;
  }
}
