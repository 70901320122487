export const PLATFORM_DEVICE_CATEGORY = {
  IPHONE: 1,
  IPAD: 2,
  ANDROID: 3,
  OTHERS: 4
};

export const PLATFORM_BUSINESS_INDEX_CATEGORY = {
  MONEY: 1, // 総利用金額
  TIMES: 2, // 総利用回数
  YEARS: 3 // 総利用年数
};

export const PLATFORM_CATEGORY = {
  WEB: {
    ID: 1,
    SUB_CATEGORY: PLATFORM_DEVICE_CATEGORY
  },
  APP: {
    ID: 2,
    SUB_CATEGORY: PLATFORM_DEVICE_CATEGORY
  },
  // ビジネスイベント
  BE: {
    ID: 3
  },
  //
  CLDB: {
    ID: 4,
    SUB_CATEGORY: {
      NPS: 1,
      ENQT: 2,
      UDATA: 3 // ユーザ属性
    }
  },
  // 行動履歴
  CONTACT: {
    ID: 5,
    SUB_CATEGORY: {
      TEL: 1,
      MAIL: 2,
      SHOP: 3, // 来店
      DM: 4, // 書類・DM
      VISIT: 5, // 訪問
      APP: 6 // アプリ通知
    }
  },
  // ビジネス指標
  BIZ_INDEX: {
    ID: 6,
    SUB_CATEGORY: PLATFORM_BUSINESS_INDEX_CATEGORY
  }
};

export enum GRAM_TYPE {
  CV = 1,
  PV = 2,
  NCV = 3,
  EVENT = 4,
  LAUNCH = 5,
  NPS = 6,
  UDATA = 7
}

export enum GRAM_VIEW_TYPE {
  CV,
  PV,
  EVENT,
  LAUNCH,
  BUSINESS_EVENT,
  NPS_ENQT,
  USER_ATTR,
  TEL,
  MAIL,
  SHOP,
  DM,
  VISIT,
  APP
}

// 行動主体
export enum SUBJECT_TYPE {
  CUSTOMER = 1,
  COMPANY = 2
}

// 日付けフォーマット種別
export enum DATE_FORMAT_TYPE {
  DEFAULT = 0, // web data measured by ug tag which has time before releasing new processor
  DATE_TIME = 1, // imported data having time or web data measured by ug tag which has time after releasing new processor
  DATE_ONLY = 2 // imported data not having time
}
