import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { msecToSec } from "@/util/date-util";
import { i18n } from "@/i18n";

/**
 * 期間による条件
 *
 * 絞り込み条件に追加で、期間を条件として付与できる
 */
export class FilterPeriodCondition {
  public readonly conditionType = FilterNodeConditionType.Period;

  constructor(
    // 期間の始め
    public readonly startDate: Date | null,
    // 期間の終わり
    public readonly endDate: Date | null
  ) {}

  public static buildDefaultData() {
    return new FilterPeriodCondition(null, null);
  }

  public static buildSecTimes(condition: FilterPeriodCondition) {
    return {
      start_time_sec:
        condition.startDate !== null
          ? msecToSec(condition.startDate.getTime())
          : 0,
      end_time_sec:
        condition.endDate !== null ? msecToSec(condition.endDate.getTime()) : 0
    };
  }

  get validate(): ValidationResult {
    const errorMessages: string[] = [];

    if (this.endDate === null && this.startDate === null) {
      errorMessages.push(i18n.t("models.search.enterTargetPeriod") as string);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}
