import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";
import { ApiParam } from "@/api/api-param";

export default class ApiForgetPassword {
  constructor(private readonly httpClient: HttpClient) {}

  public async requestToken(
    param: ApiParam.ResetPasswordRequestToken
  ): Promise<ApiRes.ResetPasswordRequestToken> {
    return this.httpClient.post<ApiRes.ResetPasswordRequestToken>(
      ApiUrl.RESET_PASSWORD_REQUEST_TOKEN,
      param
    );
  }

  public async validateToken(
    param: ApiParam.ResetPasswordValidateToken
  ): Promise<ApiRes.ResetPasswordValidateToken> {
    return this.httpClient.post<ApiRes.ResetPasswordValidateToken>(
      ApiUrl.RESET_PASSWORD_VALIDATE_TOKEN,
      param
    );
  }

  public async updatePassword(
    param: ApiParam.ResetPasswordUpdatePassword
  ): Promise<ApiRes.ResetPasswordUpdatePassword> {
    return this.httpClient.post<ApiRes.ResetPasswordUpdatePassword>(
      ApiUrl.RESET_PASSWORD_UPDATE_PASSWORD,
      param
    );
  }
}
