<template>
  <div
    class="ug-table-cell"
    data-cy="ug-table-cell"
    :style="style"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="onClick"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UgTableCell extends Vue {
  @Prop({ type: String, default: "auto" })
  width!: string;

  onMouseEnter(event: MouseEvent): void {
    this.$emit("mouse-enter", event);
  }

  onMouseLeave(event: MouseEvent): void {
    this.$emit("mouse-leave", event);
  }

  onClick(event: MouseEvent) {
    this.$emit("click", event);
  }

  get style() {
    return {
      width: this.width
    };
  }
}
</script>

<style lang="scss" scoped>
.ug-table-cell {
  display: table-cell;
  padding: 10px 15px 8px;
  vertical-align: middle;
  text-align: center;
}
</style>
