<template>
  <span :class="[displayClass, { ellipsis: ellipsis }]">{{
    serviceIdText
  }}</span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UserServiceIdText extends Vue {
  @Prop({ type: String, required: true })
  serviceIdText!: string;

  @Prop({ type: Boolean, required: true })
  isFixedServiceId!: boolean;

  // ユーザIDが長い場合は折り返さずにはみ出し文は...で省略するか
  @Prop({ type: Boolean, default: false })
  ellipsis!: boolean;

  get displayClass(): string {
    return this.isFixedServiceId ? "fixed" : "temporary";
  }
}
</script>

<style scoped lang="scss">
.fixed {
  padding: 2px 0;
  color: $colorBase900;
}

.temporary {
  padding: 2px 0;
  color: $colorBase700;
}

.ellipsis {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
