import { i18n } from "@/i18n";
import { getSearchPeriodDates, postDate } from "@/util/date-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  MAX_PERIOD_DAYS,
  SIX_MONTHS_DAYS
} from "@/models/search/select-condition/SelectCondition";
import { FunnelCreateParam, FunnelJson } from "@/api/apis/ApiFunnel";
import { FunnelCondition } from "@/models/funnel/FunnelCondition";
import { FunnelData } from "@/models/funnel/FunnelData";
import {
  FunnelConditionAttributeType,
  FunnelConditionTextAttribute,
  FunnelConditionNumberAttribute
} from "@/models/funnel/FunnelConditionAttribute";
import { FunnelConditionActivity } from "@/models/funnel/FunnelConditionActivity";
import { AttributeType } from "@/api/apis/ApiSearch";
import { getDisplayPeriod } from "@/util/funnel-util";
import { FunnelStatus } from "@/const/funnel";
import { SearchEngine } from "@/models/system/SearchEngine";
import { getCurrentDate } from "@/util/date-util";

export const MIN_TITLE_CHAR = 1;
export const MAX_TITLE_CHAR = 40;
export const MIN_CONDITION_NUM = 2;
export const MAX_CONDITION_NUM = 6;
export const MAX_USER_ATTRIBUTES_NUM = 5;

/**
 * Class for funnel analysis condition
 */

export class FunnelAnalysisCondition {
  constructor(
    public readonly funnelTitle: string,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly conditions: FunnelCondition[],
    public readonly userAttributes: FunnelConditionAttributeType[],
    public readonly userActivities: FunnelConditionActivity[]
  ) {}

  get displayPeriod(): string {
    return getDisplayPeriod(this.startDate, this.endDate);
  }

  static defaultCondition() {
    // 2日前までの1ヶ月間
    const { startDate, endDate } = getSearchPeriodDates(1, 0, 2);

    return new FunnelAnalysisCondition(
      localI18n("newFunnel"),
      startDate,
      endDate,
      [],
      [],
      []
    );
  }

  update(
    params: {
      [P in keyof FunnelAnalysisCondition]?: FunnelAnalysisCondition[P];
    }
  ): this {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), {
      ...this,
      ...params
    });
  }

  deleteCondition(index: number) {
    const conditions = this.conditions.filter((_, idx) => idx !== index);

    // notCondition is not allowed in first condtion
    const firstCondition: FunnelCondition | undefined = conditions[0];
    if (firstCondition?.notCondition) {
      conditions[0] = firstCondition.update({ notCondition: false });
    }

    return this.update({ conditions });
  }

  toJson(): FunnelCreateParam {
    return {
      name: this.funnelTitle,
      start_date: postDate(this.startDate),
      end_date: postDate(this.endDate),
      conditions: this.conditions.map(c => c.toJson()),
      app_user_timezone: getCurrentDate().getTimezoneOffset(),
      user_attributes: this.userAttributes.map(a => a.toJson()),
      user_activities: this.userActivities.map(a => a.toJson())
    };
  }

  validate(canUseLongTerm?: boolean): ValidationResult {
    const errorMessages: string[] = [];

    if (this.funnelTitle.length < MIN_TITLE_CHAR) {
      errorMessages.push(localI18n("validateMinTitle"));
    }

    if (this.funnelTitle.length > MAX_TITLE_CHAR) {
      errorMessages.push(localI18n("validateMaxTitle"));
    }

    if (this.endDate === null && this.startDate === null) {
      errorMessages.push(localI18n("validateNoDate"));
    }

    const periodDays =
      (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
    const maxPeriodDays = canUseLongTerm ? SIX_MONTHS_DAYS : MAX_PERIOD_DAYS;
    if (periodDays >= maxPeriodDays) {
      errorMessages.push(
        localI18n("validateMaxPeriod", {
          dateRange: Math.floor(maxPeriodDays / 30)
        })
      );
    }

    if (this.conditions.length < MIN_CONDITION_NUM) {
      errorMessages.push(localI18n("validateMinCondition"));
    }

    if (this.conditions.length > MAX_CONDITION_NUM) {
      errorMessages.push(localI18n("validateMaxCondition"));
    }

    if (
      this.conditions.length > 0 &&
      this.conditions[0].notCondition === true
    ) {
      errorMessages.push(localI18n("validateFirstNotCondition"));
    }

    if (this.userAttributes.length > MAX_USER_ATTRIBUTES_NUM) {
      errorMessages.push(localI18n("validateMaxUserAttributes"));
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    const childErrorMessages: string[] = [];
    this.conditions.forEach(c => {
      const result = c.validate(canUseLongTerm);
      if (result.isValid === false) {
        childErrorMessages.push(result.errorMessage);
      }
    });
    if (childErrorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: childErrorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

/**
 * Class for whole funnel analysis condition used to display and store funnel history items.
 */

export class FunnelAnalysisHistory {
  constructor(
    public readonly id: string,
    public readonly funnelTitle: string,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly funnelData: FunnelData[],
    public readonly userAttributes: FunnelConditionAttributeType[],
    public readonly userActivities: FunnelConditionActivity[],
    public readonly numFilteredUsers: number | null,
    public readonly createdAt: Date,
    public readonly isCreated = true
  ) {}

  get displayPeriod(): string {
    return getDisplayPeriod(this.startDate, this.endDate);
  }

  static fromJson(
    json: FunnelJson,
    searchEngines: SearchEngine[]
  ): FunnelAnalysisHistory {
    const startDate = new Date(json.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(json.end_date);
    endDate.setHours(23, 59, 59, 999);

    const funnelData = json.funnel_data.data
      .sort((a, b) => a.order - b.order)
      .map(json => FunnelData.fromJson(json, searchEngines));

    const userAttributes: FunnelConditionAttributeType[] = [];
    json.funnel_data.user_attributes.forEach(a => {
      if (a.type === AttributeType.TEXT) {
        userAttributes.push(FunnelConditionTextAttribute.fromJson(a));
      } else {
        userAttributes.push(FunnelConditionNumberAttribute.fromJson(a));
      }
    });

    const userActivities = json.funnel_data.user_activities.map(uaJson =>
      FunnelConditionActivity.fromJson(uaJson, searchEngines)
    );

    return new FunnelAnalysisHistory(
      json.id,
      json.name,
      startDate,
      endDate,
      funnelData,
      userAttributes,
      userActivities,
      json.funnel_data.num_filtered_users ?? null,
      new Date(json.created_at),
      json.status === FunnelStatus.CREATED
    );
  }

  toFunnelCondition(): FunnelAnalysisCondition {
    return new FunnelAnalysisCondition(
      this.funnelTitle,
      this.startDate,
      this.endDate,
      this.funnelData.map(data => data.condition),
      this.userAttributes,
      this.userActivities
    );
  }
}

function localI18n(key: string, param = {}): string {
  return i18n.t(
    `models.funnel.funnelAnalysisCondition.${key}`,
    param
  ) as string;
}
