import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store";
import { ApiRes } from "@/api/api-res";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";
import { AlmostCvPeriod } from "@/models/almost-cv/AlmostCvPeriod";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";
import { getCurrentDate } from "@/util/date-util";

/**
 * Vuex state for almostCV
 */
export class AlmostCvState {
  // almost cv period and conversions
  almostCvInfo: AlmostCvInfo = new AlmostCvInfo(
    new AlmostCvPeriod(getCurrentDate(), getCurrentDate(), false),
    []
  );
  /**
   * user list for a specific Almost CV transaction
   */
  almostCvUserList: AlmostCvUserList = new AlmostCvUserList([]);
  isAlmostCvInfoFetched: boolean = false;
  isLoadingAlmostCvInfo: boolean = false;
  isAlmostCvFetchError: boolean = false;
}

const mutations: MutationTree<AlmostCvState> = {
  setAlmostCvInfo(state, almostCvInfo: AlmostCvInfo) {
    state.almostCvInfo = almostCvInfo;
  },
  setAlmostCvUserList(state, almostCvUserList: AlmostCvUserList) {
    state.almostCvUserList = almostCvUserList;
  },
  setIsAlmostCvInfoFetched(state, isAlmostCvInfoFetched: boolean) {
    state.isAlmostCvInfoFetched = isAlmostCvInfoFetched;
  },
  setIsLoadingAlmostCvInfo(state, isLoadingAlmostCvInfo: boolean) {
    state.isLoadingAlmostCvInfo = isLoadingAlmostCvInfo;
  },
  setIsAlmostCvFetchError(state, isError) {
    state.isAlmostCvFetchError = isError;
  }
};

/**
 * Vuex actions for almostCV
 */
const actions: ActionTree<AlmostCvState, RootState> = {
  /**
   * Fetch almostCVInfo  from API.
   * After that, set `almostCvInfo` in [[AlmostCvState]]
   */
  async fetchAlmostCvInfo({ commit, rootState }) {
    commit("setIsAlmostCvFetchError", false);
    commit("setIsLoadingAlmostCvInfo", true);
    try {
      const json: ApiRes.AlmostCvInfo = await rootState.api.almostCv.getAlmostCvInfo();

      const almostCvInfo = AlmostCvInfo.fromJson(json);
      commit("setAlmostCvInfo", almostCvInfo);
    } catch (e) {
      commit("setIsAlmostCvFetchError", true);
    }

    commit("setIsAlmostCvInfoFetched", true);
    commit("setIsLoadingAlmostCvInfo", false);
  },
  /**
   * Fetch list of users by Almost CV ID from API.
   * After that, set `userList` in [[AlmostCvState]]
   *
   * @param cvId ID of the Almost CV transaction
   */
  async fetchUserList({ commit, rootState }, cvId: number) {
    const json: ApiRes.AlmostCvUser[] = await rootState.api.almostCv.getAlmostCvUsers(
      cvId
    );

    const almostCvUserList: AlmostCvUserList = AlmostCvUserList.fromJson(json);
    commit("setAlmostCvUserList", almostCvUserList);
  }
};

export const almostCv = {
  namespaced: true,
  state: new AlmostCvState(),
  mutations,
  actions
};
