<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filterNodeEdge">
    <div class="filter-edge edge" data-cy="filter-edge">
      <SelectBox
        v-model="filterEdgeType"
        class="edge-type"
        data-cy="edge-type"
        :options="edgeTypeOptions"
        width="190px"
      />
      <SelectBox
        v-show="showDaySelect"
        v-model="filterEdgeDayType"
        class="day-type"
        data-cy="day-type"
        :options="edgeDayTypeOptions"
        width="120px"
      />
      <SelectBox
        v-show="showSameVisitTransitionTypeSelect"
        v-model="sameVisitTransitionType"
        class="transition-type"
        data-cy="transition-type"
        :options="sameVisitTransitionTypeOptions"
        width="190px"
      />
      <div v-if="showTransitionSteps" class="transition-step-text">{{
        $te("withinStepPrefix") ? $t("withinStepPrefix") : ""
      }}</div>
      <SelectBoxWithOptionalInput
        v-show="showTransitionSteps"
        :options="transitionStepsOptions"
        :show-input-box="showInputBox"
        :input-value="transitionStep.steps"
        :select-value="transitionStep.type"
        :max="99"
        :min="2"
        :placeholder="placeholder"
        :select-box-width="selectBoxWidth"
        :input-field-width="inputFieldWidth"
        class="transition-steps"
        data-cy="transition-steps"
        @inputNumber="onInputNumber"
        @onSelect="onSelect"
      />
      <div v-if="showTransitionSteps" class="transition-step-text">{{
        $te("withinStepSuffix") ? $t("withinStepSuffix") : ""
      }}</div>

      <Tooltip
        v-show="showTransitionStepHelpText"
        :placement="tooltipPlacement"
        class="transitionStep__help"
      >
        <Icon :icon="Icons.HelpInvert" :color="Colors.Base700" :size="10" />
        <template #content>
          <div class="transitionStep__helpContent">
            {{ $t("transitionStepHelpText1") }}
            <br /><br />
            {{ $t("transitionStepHelpText2") }}
          </div>
        </template>
      </Tooltip>

      <div v-if="dayText" class="day-text">{{ dayText }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-property-decorator";
import SelectOption from "@/components/form/SelectOption";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectBoxWithOptionalInput from "@/components/form/SelectBoxWithOptionalInput.vue";
import {
  FilterEdge,
  FilterEdgeType,
  FilterEdgeDayType,
  SameVisitTransitionType,
  WithInTransitionStepType,
  TransitionStep
} from "@/models/search/filter-node/FilterEdge";
import { i18n } from "@/i18n";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement } from "@/const/tooltip";

@Component({
  name: "FilterNodeEdge",
  components: {
    Icon,
    SelectBox,
    SelectBoxWithOptionalInput,
    Tooltip
  }
})
export default class FilterNodeEdge extends Vue {
  Icons = Icons;
  Colors = Colors;
  tooltipPlacement = TooltipPlacement.Top;
  placeholder: string = "2~99";
  inputFieldWidth: string = "52px";
  selectBoxWidth: string = "80px";

  @Model("input", { type: FilterEdge })
  edge!: FilterEdge;

  @Prop({ type: Boolean, required: true })
  isExclusionCheckboxEnabled!: boolean;

  onInput(edge: FilterEdge) {
    this.$emit("input", edge);
  }

  onInputNumber(transitionSteps: number) {
    this.edge.transitionSteps = new TransitionStep(
      WithInTransitionStepType.stepAny,
      transitionSteps
    );
  }

  onSelect(transitionStepType: WithInTransitionStepType | number) {
    const steps =
      transitionStepType === WithInTransitionStepType.stepAny
        ? this.edge.transitionSteps.steps
        : transitionStepType;

    const edge = new FilterEdge(
      this.edge.edgeType,
      this.edge.dayType,
      this.edge.transitionType,
      new TransitionStep(transitionStepType, steps)
    );

    this.onInput(edge);
  }

  get edgeTypeOptions(): SelectOption[] {
    return [
      {
        value: FilterEdgeType.allVisit,
        label: i18n.t("components.search.filter.anyVisits") as string,
        disabled: false
      },
      {
        value: FilterEdgeType.sameVisit,
        label: i18n.t("components.search.filter.withinSameVisit") as string,
        disabled: false
      },
      {
        value: FilterEdgeType.otherVisit,
        label: i18n.t("components.search.filter.anotherVisit") as string,
        disabled: false
      },
      {
        value: FilterEdgeType.withinXDays,
        label: i18n.t(
          "components.search.filter.withinFromPreviousVisit"
        ) as string,
        disabled: this.isExclusionCheckboxEnabled
      },
      {
        value: FilterEdgeType.moreThanXDays,
        label: i18n.t(
          "components.search.filter.moreFromPreviousVisit"
        ) as string,
        disabled: this.isExclusionCheckboxEnabled
      }
    ];
  }

  get edgeDayTypeOptions(): SelectOption[] {
    return [
      {
        value: FilterEdgeDayType.day1,
        label: i18n.t("components.search.filter.day", { num: 1 }) as string,
        disabled: false
      },
      {
        value: FilterEdgeDayType.day7,
        label: i18n.t("components.search.filter.days", { num: 7 }) as string,
        disabled: false
      },
      {
        value: FilterEdgeDayType.day30,
        label: i18n.t("components.search.filter.days", { num: 30 }) as string,
        disabled: false
      }
    ];
  }

  get sameVisitTransitionTypeOptions(): SelectOption[] {
    return [
      {
        value: SameVisitTransitionType.immediatelyAfter,
        label: i18n.t("components.search.filter.immediatelyAfter") as string,
        disabled: this.isExclusionCheckboxEnabled
      },
      {
        value: SameVisitTransitionType.withInTransitionStep,
        label: i18n.t(
          "components.search.filter.withInTransitionStep"
        ) as string,
        disabled: this.isExclusionCheckboxEnabled
      },
      {
        value: SameVisitTransitionType.allAfter,
        label: i18n.t("components.search.filter.allAfter") as string,
        disabled: false
      }
    ];
  }

  get transitionStepsOptions(): SelectOption[] {
    return [
      {
        value: WithInTransitionStepType.stepThree,
        label: "3",
        disabled: false
      },
      {
        value: WithInTransitionStepType.stepFive,
        label: "5",
        disabled: false
      },
      {
        value: WithInTransitionStepType.stepTen,
        label: "10",
        disabled: false
      },
      {
        value: WithInTransitionStepType.stepFifteen,
        label: "15",
        disabled: false
      },
      {
        value: WithInTransitionStepType.stepAny,
        label: i18n.t("components.search.filter.anyStep") as string,
        disabled: false
      }
    ];
  }

  public get showInputBox(): boolean {
    return this.transitionStep.type === WithInTransitionStepType.stepAny;
  }

  public get filterEdgeType(): FilterEdgeType {
    if (this.edge !== null) {
      return this.edge.edgeType;
    }
    return FilterEdgeType.allVisit;
  }

  public set filterEdgeType(type: FilterEdgeType) {
    this.onInput(new FilterEdge(type, this.filterEdgeDayType));
  }

  public get filterEdgeDayType(): FilterEdgeDayType {
    return this.edge.dayType;
  }

  public set filterEdgeDayType(dayType: FilterEdgeDayType) {
    this.onInput(new FilterEdge(this.filterEdgeType, dayType));
  }

  public get sameVisitTransitionType(): SameVisitTransitionType {
    return this.edge.transitionType;
  }

  public set sameVisitTransitionType(transitionType: SameVisitTransitionType) {
    this.onInput(
      new FilterEdge(
        this.filterEdgeType,
        this.filterEdgeDayType,
        transitionType
      )
    );
  }

  public get transitionStep(): TransitionStep {
    return this.edge.transitionSteps;
  }

  public get showSameVisitTransitionTypeSelect(): boolean {
    return this.filterEdgeType === FilterEdgeType.sameVisit;
  }

  public get showDaySelect(): boolean {
    return (
      this.filterEdgeType === FilterEdgeType.withinXDays ||
      this.filterEdgeType === FilterEdgeType.moreThanXDays
    );
  }

  public get showTransitionSteps(): boolean {
    return (
      this.filterEdgeType === FilterEdgeType.sameVisit &&
      this.sameVisitTransitionType ===
        SameVisitTransitionType.withInTransitionStep
    );
  }

  public get showTransitionStepHelpText(): boolean {
    return this.filterEdgeType === FilterEdgeType.sameVisit;
  }

  public get dayText(): string {
    if (this.filterEdgeType === FilterEdgeType.withinXDays) {
      return i18n.t("components.search.filter.within") as string;
    }
    if (this.filterEdgeType === FilterEdgeType.moreThanXDays) {
      return i18n.t("components.search.filter.moreThan") as string;
    }
    return "";
  }
}
</script>
<style lang="scss" scoped>
.filterNodeEdge {
  display: inline-flex;
}

.edge {
  display: inline-flex;
  align-items: center;
}

.edge-icon {
  margin: 5px 10px 5px 5px;
}

.day-type,
.transition-type {
  margin-left: 7px;
}

.day-text,
.transition-step-text {
  margin-left: 5px;
  color: $colorBase700;
  font-size: 12px;
}

.transition-steps {
  margin-left: 5px;
}

.transitionStep__helpContent {
  width: 400px;
  white-space: normal;
  line-height: 1.6;
  font-weight: 500;
}
.transitionStep__help {
  margin-left: 7px;
}
</style>
