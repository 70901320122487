<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-contact-content-field">
    <FilterBaseInputText
      v-model="value"
      :prefix="$t('containsPrefix')"
      :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
    />
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterContactContentCondition } from "@/models/search/filter-node-condition/FilterContactContentCondition";

import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";

@Component({
  components: {
    FilterBaseInputText
  }
})
export default class FilterContactContentField extends Vue {
  @Model("input", {
    type: FilterContactContentCondition,
    required: true
  })
  condition!: FilterContactContentCondition;

  onInput(condition: FilterContactContentCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.content;
  }
  set value(value: string) {
    this.onInput(new FilterContactContentCondition(value));
  }
}
</script>
<style lang="scss" scoped>
.filter-contact-content-field {
  display: flex;
  align-items: center;
}

.select-box {
  margin-right: 7px;
}
</style>
