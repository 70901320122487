<template>
  <Dialog :visible="showModal" top="30vh" width="820px" @close="onClose">
    <div class="md-Message">{{ message }}</div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import Dialog from "@/components/dialog/Dialog.vue";

@Component({
  components: {
    Dialog
  }
})
export default class AlertDialog extends Vue {
  @Prop({ type: String, required: true })
  message!: string;

  @Emit("close")
  onClose() {}

  get showModal(): boolean {
    return this.message !== "";
  }
}
</script>

<style scoped lang="scss">
.md-Message {
  padding: 30px;
  text-align: center;
  white-space: pre;
  line-height: 1.5;
}
</style>
