<template>
  <table class="property-list">
    <slot></slot>
  </table>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PropertyList extends Vue {}
</script>

<style lang="scss" scoped>
.property-list {
  border-collapse: collapse;
  font-size: 12px;
  line-height: 1.4;

  th {
    width: 110px;
    color: $colorBase700;
    text-align: right;
    font-weight: normal;
  }

  td {
    padding-left: 10px;
    text-align: left;
  }

  th,
  td {
    padding-bottom: 10px;

    @include default-break-word();
  }

  tr:last-child {
    th,
    td {
      padding-bottom: 0;
    }
  }
}
</style>
