import { CreationMethod } from "@/models/client-settings/Coordination";

export class AppConversionDefinition {
  constructor(
    public readonly id: number,
    public readonly coordinationId: number,
    public readonly conversionId: number,
    public readonly parameter: string,
    public readonly isDisabled: boolean
  ) {}
}

export class MapConversionDefinitionList {
  constructor(
    public readonly coordinationId: number,
    public readonly parameters: string[]
  ) {}
}

export class MapConversionDefinitionListWithCv {
  constructor(
    public readonly creationMethod: CreationMethod,
    public readonly coordinationId: number,
    public readonly conversionName: string,
    public readonly conversionId: number,
    public readonly parameters: string[]
  ) {}
}
