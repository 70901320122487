import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

/**
 * This class holds API calling methods for AlmostCv
 */
export default class ApiAlmostCv {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Making API call to `/api/almostcv/`
   * @returns Promise<ApiRes.AlmostCvInfo>
   */
  public getAlmostCvInfo = (): Promise<ApiRes.AlmostCvInfo> => {
    return this.httpClient.get<ApiRes.AlmostCvInfo>(ApiUrl.ALMOST_CV);
  };

  /**
   * Making API call to `/api/almostcv/<cv_id>/`
   * @param conversionId ID of the almost CV transaction
   * @returns Promise<Array<ApiRes.AlmostCvUser>>
   */
  public getAlmostCvUsers = (
    conversionId: number
  ): Promise<Array<ApiRes.AlmostCvUser>> => {
    return this.httpClient.get<Array<ApiRes.AlmostCvUser>>(
      ApiUrl.ALMOST_CV_USER_LIST_BY_CV_ID(conversionId)
    );
  };
}
