import { CreationMethod } from "@/models/client-settings/Coordination";

export class MapEventDefinition {
  constructor(
    public readonly id: number,
    public readonly coordinationId: number,
    public readonly eventId: number,
    public readonly parameter: string,
    public readonly isDisabled: boolean
  ) {}
}

export class MapEventDefinitionList {
  constructor(
    public readonly coordinationId: number,
    public readonly parameters: string[]
  ) {}
}

export class MapEventDefinitionListWithEvent {
  constructor(
    public readonly creationMethod: CreationMethod,
    public readonly coordinationId: number,
    public readonly eventName: string,
    public readonly eventId: number,
    public readonly parameters: string[]
  ) {}
}
