<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div class="display-menu">
    <Tooltip :text="$t('userAttr')" placement="bottom">
      <IconButton
        :icon="Icons.Table"
        :size="iconSize"
        :selected="isAttribute"
        :button-type="iconButtonType"
        @click="onViewChange(SearchResultViews.Attribute)"
      />
    </Tooltip>
    <Tooltip :text="$t('actOverview')" placement="bottom">
      <IconButton
        :icon="Icons.Overview"
        :size="iconSize"
        :selected="isOverview"
        :button-type="iconButtonType"
        @click="onViewChange(SearchResultViews.Overview)"
      />
    </Tooltip>
    <Tooltip :text="$t('memo')" placement="bottom-end">
      <IconButton
        :icon="Icons.Memo"
        :size="iconSize"
        :selected="isMemo"
        :button-type="iconButtonType"
        @click="onViewChange(SearchResultViews.Memo)"
      />
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Model } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import { SearchResultViews } from "@/const/SearchResultViews";

@Component({
  components: {
    Icon,
    IconButton,
    Tooltip
  }
})
export default class DisplayMenu extends Vue {
  Icons = Icons;
  iconButtonType = IconButtonType.Weak;
  SearchResultViews = SearchResultViews;

  iconSize = 20;

  @Model("view-change", { type: String, required: true })
  view!: SearchResultViews;

  onViewChange(view: SearchResultViews) {
    this.$emit("view-change", view);
  }

  get isAttribute(): boolean {
    return this.view === SearchResultViews.Attribute;
  }

  get isOverview(): boolean {
    return this.view === SearchResultViews.Overview;
  }

  get isMemo(): boolean {
    return this.view === SearchResultViews.Memo;
  }
}
</script>

<style lang="scss" scoped>
.display-menu {
  display: flex;
  padding: 0 10px;

  &__mode {
    color: $colorBase700;
    font-size: 14px;
  }

  & > *:not(:first-child) {
    margin-left: 12px;
  }
}
</style>
