<template>
  <DatePickerPopupContainer
    :date-picker="datePicker"
    @move-year-month="onMoveCalendarButtonClick"
  >
    <CalendarDateTable
      v-for="calendar in calendars"
      :key="calendar.month"
      class="datePickerPopup__calendar"
      :calendar="calendar"
      :selected-date="selectedDate"
      :enabled-period="enabledPeriod"
      @select-date="onSelectDate"
      @mouse-enter-date="onMouseEnterDate"
    />
  </DatePickerPopupContainer>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DatePicker } from "@/models/date-picker/DatePicker";
import { Calendar } from "@/models/date-picker/Calendar";
import { DateRange } from "@/components/date-picker/DateRange";
import DatePickerPopupContainer from "@/components/date-picker/popup/DatePickerPopupContainer.vue";
import CalendarDateTable from "@/components/date-picker/calendar/CalendarDateTable.vue";

@Component({
  components: { DatePickerPopupContainer, CalendarDateTable }
})
export default class DatePickerPopup extends Vue {
  @Prop({ type: DatePicker, required: true })
  datePicker!: DatePicker;

  @Prop({ type: Date, default: null })
  selectedDate!: Date;

  @Prop({ type: Object, default: null })
  enabledPeriod!: DateRange;

  onSelectDate(date: Date) {
    this.$emit("select-date", date);
  }

  onMouseEnterDate(date: Date) {
    this.$emit("mouse-enter-date", date);
  }

  onMoveCalendarButtonClick(calender: Calendar) {
    this.$emit("move-year-month", calender);
  }

  get calendars(): Calendar[] {
    return this.datePicker.calendars;
  }
}
</script>

<style scoped lang="scss">
.datePickerPopup__calendar {
  width: 33.3%;

  &:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
