<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div class="featuredUsersLagCvTableBody">
    <LightUgTableRow v-if="hasMultipleConversions">
      <LightUgTableCell :width="FIRST_CELL_WIDTH">
        <div class="featuredUsersLagCvTableBody__firstCell">
          <span>{{ $t("allConversions") }}</span>
          <Tooltip class="featuredUsersLagCvTableBody__tooltip">
            <Icon :icon="Icons.HelpInvert" :color="Colors.Base700" :size="12" />
            <template #content>
              {{ $t("allConversionsNote1") }}<br />
              {{ $t("allConversionsNote2") }}
            </template>
          </Tooltip>
        </div>
      </LightUgTableCell>
      <LightUgTableCell
        v-for="(userCount, index) in countsByPeriod"
        :key="`lagcv-period-${userCount.periodType}`"
        :disabled="userCount.numberOfUsers === 0"
      >
        <RadioButton
          name="lagCVResult"
          :value="selectedUserCounts"
          :label="`${index}-0`"
          :disabled="userCount.numberOfUsers === 0"
          :size="RadioButtonSize.Small"
          @change="
            label => {
              userCount.numberOfUsers > 0 && onChange(label);
            }
          "
        >
          {{
            $tc("userCount", userCount.numberOfUsers, {
              userCount: userCount.numberOfUsers
            })
          }}
        </RadioButton>
      </LightUgTableCell>
    </LightUgTableRow>

    <LightUgTableRow
      v-for="userCount in countsByConversionId"
      :key="`lagcv-table-row-${userCount.id}`"
    >
      <LightUgTableCell :width="FIRST_CELL_WIDTH">
        {{ userCount.name }}
      </LightUgTableCell>

      <LightUgTableCell
        v-for="(count, index) in userCount.number_of_users"
        :key="`lagcv-table-cell-${index}-${userCount.id}`"
        :disabled="count === 0"
      >
        <RadioButton
          name="lagCVResult"
          :value="selectedUserCounts"
          :label="`${index}-${userCount.id}`"
          :disabled="count === 0"
          :size="RadioButtonSize.Small"
          @change="
            label => {
              count > 0 && onChange(label);
            }
          "
        >
          {{
            $tc("userCount", count, {
              userCount: count
            })
          }}
        </RadioButton>
      </LightUgTableCell>
    </LightUgTableRow>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";
import RadioButton from "@/components/form/RadioButton.vue";
import { RadioButtonSize } from "@/const/RadioButtonSize";
import LightUgTableRow from "@/components/table/LightUgTableRow.vue";
import LightUgTableCell from "@/components/table/LightUgTableCell.vue";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import {
  UserCountsByPeriod,
  UserCountsByConversionId
} from "@/components/search/FeaturedUsersLagCvTable.vue";

@Component({
  components: {
    RadioButton,
    LightUgTableRow,
    LightUgTableCell,
    Icon,
    Tooltip
  }
})
export default class FeaturedUsersLagCvTableBody extends Vue {
  Icons = Icons;
  Colors = Colors;
  FIRST_CELL_WIDTH = "359px";
  RadioButtonSize = RadioButtonSize;
  selectedUserCounts = "";

  @Prop({ type: Array, required: true })
  countsByConversionId!: UserCountsByConversionId[];

  @Prop({ type: Array, required: true })
  countsByPeriod!: UserCountsByPeriod[];

  @Watch("selectedUserCounts")
  onChangeResult() {
    this.emitSelect();
  }

  @Emit("select")
  emitSelect() {
    return this.searchPageUrl;
  }

  mounted() {
    this.selectedUserCounts = this.defaultSelectedUserCount;
    this.emitSelect();
  }

  onChange(value: string) {
    this.selectedUserCounts = value;
  }

  get defaultSelectedUserCount() {
    let defaultItem = "";

    if (this.hasMultipleConversions) {
      this.countsByPeriod.some((userCount, index) => {
        if (userCount.numberOfUsers > 0) {
          defaultItem = `${index}-0`;
          return true;
        }
      });
    }

    if (defaultItem.length === 0) {
      this.countsByConversionId.some(userCount => {
        const period = userCount.number_of_users.findIndex(
          (count: number) => count > 0
        );
        if (period !== -1) {
          defaultItem = `${period}-${userCount.id}`;
          return true;
        }
      });
    }

    return defaultItem;
  }

  get searchPageUrl(): string {
    if (this.selectedUserCounts.length === 0) return this.selectedUserCounts;
    const urlStrings = ["/lagcv"];
    const stringArray = this.selectedUserCounts.split("-");

    urlStrings.push(this.countsByPeriod[parseInt(stringArray[0])].periodType);
    const conversionId = stringArray[1];
    if (conversionId !== "0") {
      urlStrings.push(conversionId);
    }
    return urlStrings.join("/");
  }

  get hasMultipleConversions(): boolean {
    return this.countsByConversionId.length > 1;
  }
}
</script>
<style lang="scss" scoped>
.featuredUsersLagCvTableBody__firstCell {
  display: flex;
  align-items: center;
}
.featuredUsersLagCvTableBody__tooltip {
  padding-left: 5px;
}
</style>
