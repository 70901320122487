import {
  CONVERSION_ATTRIBUTE_ID_PREFIX,
  USER_ATTRIBUTE_ID_PREFIX,
  BUSINESS_INDEX_ID_PREFIX
} from "@/store/modules/clientSettings";
import { User, BusinessIndex } from "@/models/User";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { AttributeType } from "@/api/apis/ApiSearch";

//Npsのidの型
const enum NPS_ID_TYPE {
  // 直近顧客ロイヤルティスコア
  CURRENT_VAL = 1,
  // 直近スコアコメント
  CURRENT_COMMENT = 2,
  // 前回顧客ロイヤルティスコア
  PREV_VAL = 3,
  // 前回スコアコメント
  PREV_COMMENT = 4
}

/**
 * columnId から該当する定義ファイルを取得し返す
 *
 * @param {string} columnId
 * @returns {ConversionAttributeDefinition | UserAttributeDefinition | BusinessIndexDefinition}
 */
function getDefinitionByColumnId(
  columnId: string
):
  | ConversionAttributeDefinition
  | UserAttributeDefinition
  | BusinessIndexDefinition
  | undefined {
  const columnIdArray: string[] = columnId.split("_");
  const id: number = Number(columnIdArray[1]);

  if (
    columnIdArray.some(
      (c: string) => CONVERSION_ATTRIBUTE_ID_PREFIX.indexOf(c) >= 0
    )
  ) {
    return new ConversionAttributeDefinition(
      id,
      "",
      false,
      AttributeType.NUMBER
    );
  } else if (
    columnIdArray.some((c: string) => USER_ATTRIBUTE_ID_PREFIX.indexOf(c) >= 0)
  ) {
    return new UserAttributeDefinition(id, "", false, 2);
  } else if (
    columnIdArray.some((c: string) => BUSINESS_INDEX_ID_PREFIX.indexOf(c) >= 0)
  ) {
    return new BusinessIndexDefinition(id, "", false, id);
  }
}

/**
 * Npsデータがあるときは取得した columnId から該当する id を取得し返す
 *
 * @param {string} columnId
 * @returns {number}
 */
function getNpsIdByColumnId(columnId: string): number {
  const columnIdArray: { [key: number]: string } = columnId.split("_");

  return Number(columnIdArray[1]);
}

// columnIdから定義された定数を取得し判定
// 該当データと定義された定数のidを使用し、表示内容を取得
export function getColumnValue(columnId: string, user: User): string {
  const definition:
    | ConversionAttributeDefinition
    | UserAttributeDefinition
    | BusinessIndexDefinition
    | undefined = getDefinitionByColumnId(columnId);

  if (definition instanceof ConversionAttributeDefinition) {
    const attributes = user.conversion.conversionAttributes.find(
      a => a.id === definition.id
    );

    return attributes?.value || "";
  } else if (definition instanceof UserAttributeDefinition) {
    const attributes = user.attributes.find(a => a.id === definition.id);

    return attributes?.value || "";
  } else if (definition instanceof BusinessIndexDefinition) {
    const businessIndex = user.businessIndexes.find(
      b => b.id === definition.id
    );

    if (businessIndex === undefined) {
      return "";
    }

    return BusinessIndex.valueForDisplay(businessIndex);
  } else {
    if (user.nps && user.previousNps) {
      const NpsId: number = getNpsIdByColumnId(columnId);

      if (NpsId === NPS_ID_TYPE.CURRENT_VAL) {
        return String(user.nps.value);
      } else if (NpsId === NPS_ID_TYPE.CURRENT_COMMENT) {
        return user.nps.comment;
      } else if (NpsId === NPS_ID_TYPE.PREV_VAL) {
        return String(user.previousNps.value);
      } else if (NpsId === NPS_ID_TYPE.PREV_COMMENT) {
        return user.previousNps.comment;
      }
    }

    return "";
  }
}
