import { UserTrendNumberJsonData } from "@/api/apis/ApiUserTrend";

/**
 * UserTrend数値データ
 *
 * UserTrend検索結果の、
 * PV数・CV数・CV率といった数値データを格納するデータクラス
 */

export class UserTrendNumberData {
  constructor(
    // ページビュー数
    public readonly count: number,
    // ページビューの訪問数
    public readonly visit: number,
    public readonly cvInVisit: number,
    public readonly cvRatioInVisit: number,
    public readonly uniqueUsers: number = 0
  ) {}

  public static fromJson(json: UserTrendNumberJsonData): UserTrendNumberData {
    return new UserTrendNumberData(
      json.count,
      json.visit,
      json.cv_in_visit,
      json.cv_ratio_in_visit,
      json.unique_users
    );
  }
}
