<i18n src="@/i18n/components/users/conversions-select-menu.json"></i18n>
<template>
  <div class="conversionsSelectMenu" :style="{ width: width }">
    <div class="conversionsSelectMenu__control">
      <div
        class="conversionsSelectMenu__controlItem conversionsSelectMenu__allEmphasize"
        @click="onAllEmphasize"
        >{{ $t("allEmphasize") }}</div
      >
      <div
        class="conversionsSelectMenu__controlItem conversionsSelectMenu__allDeselect"
        @click="onAllDeselect"
        >{{ $t("allDeselect") }}</div
      >
      <div
        class="conversionsSelectMenu__controlItem conversionsSelectMenu__initReturn"
        @click="onInit"
        >{{ $t("init") }}</div
      >
    </div>

    <div
      ref="conversionsSelectMenuContainer"
      class="conversionsSelectMenu__container"
      :style="style"
    >
      <ScrollWrapper :overflow="overflow">
        <div
          ref="conversionsSelectMenuList"
          class="conversionsSelectMenu__list"
        >
          <ConversionSelectCheckbox
            v-for="(c, i) in conversions"
            :key="i"
            :checked="isChecked(c.id)"
            @input="onCheckboxInput($event, c.id)"
            >{{ c.name }}</ConversionSelectCheckbox
          >
        </div>
      </ScrollWrapper>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { VisitConversion } from "@/models/overview/VisitOverview";
import { Icons } from "@/const/Icons";
import { APP_BOTTOM_BUTTON_SPACE } from "@/const/users";
import Icon from "@/components/Icon.vue";
import ScrollWrapper, { Overflow } from "@/components/ScrollWrapper.vue";
import ConversionSelectCheckbox from "@/components/users/ConversionSelectCheckbox.vue";

@Component({
  components: {
    Icon,
    ScrollWrapper,
    ConversionSelectCheckbox
  }
})
export default class ConversionSelectMenu extends Vue {
  @Prop({ type: Array, required: true })
  conversions!: VisitConversion[];

  @Prop({ type: Array, required: true })
  selectedConversionIds!: number[];

  @Prop({ type: Array, required: true })
  initSelectedConversionIds!: number[];

  @Prop({ type: String, default: "300px" })
  width!: string;

  onInput(selectedConversionIds: number[]) {
    this.$emit("input", selectedConversionIds);
  }

  icons = Icons;
  overflow = Overflow.ScrollYOnlyEnabled;
  conversionsSelectMenuContainerHeight: string = "auto";
  conversionsSelectMenuContainerPaddingBottom: number = 15;
  conversionsSelectMenuListHeight: string = "auto";

  get conversionsSelectMenuContainer(): HTMLElement {
    return this.$refs.conversionsSelectMenuContainer as HTMLElement;
  }

  get conversionsSelectMenuList(): HTMLElement {
    return this.$refs.conversionsSelectMenuList as HTMLElement;
  }

  get style() {
    return {
      height: this.conversionsSelectMenuContainerHeight,
      maxHeight: this.conversionsSelectMenuListHeight,
      paddingBottom: this.conversionsSelectMenuContainerPaddingBottom + "px"
    };
  }

  mounted() {
    const container = this.conversionsSelectMenuContainer.getBoundingClientRect();
    const list = this.conversionsSelectMenuList.getBoundingClientRect();

    this.conversionsSelectMenuContainerHeight =
      "calc(100vh - " +
      Math.floor(container.top + APP_BOTTOM_BUTTON_SPACE) +
      "px)";
    this.conversionsSelectMenuListHeight =
      list.height + this.conversionsSelectMenuContainerPaddingBottom + "px";
  }

  isChecked(value: number): boolean {
    return this.selectedConversionIds.indexOf(value) !== -1;
  }

  onCheckboxInput(checked: boolean, value: number) {
    if (checked) {
      this.onInput([...this.selectedConversionIds, value]);
    } else {
      this.onInput(this.selectedConversionIds.filter(n => n !== value));
    }

    UgTag.pushEvent(UgEventTag.CvEmphasis);
  }

  onAllEmphasize() {
    const ids = this.conversions.map(c => c.id);
    this.onInput(ids);
  }

  onAllDeselect() {
    this.onInput([]);
  }

  onInit() {
    this.onInput(this.initSelectedConversionIds);
  }
}
</script>

<style scoped lang="scss">
.conversionsSelectMenu {
  border: 1px solid $colorBase600;
  border-radius: 5px;
  background-color: $colorWhite;
}

.conversionsSelectMenu__container {
  padding-bottom: 15px;
}

.conversionsSelectMenu__control {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 50px;
}

.conversionsSelectMenu__controlItem {
  color: $colorBase700;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:not(:first-child) {
    margin-left: 10px;
  }
}
</style>
