import {
  MapEventRequest,
  MapEventWithEventRequest
} from "@/api/apis/client-settings/ApiMapEvent";
import {
  MapEventDefinition,
  MapEventDefinitionListWithEvent
} from "@/models/client-settings/MapEventDefinition";
import { CreationMethod } from "@/models/client-settings/Coordination";
import { RootState } from "@/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";

export class MapEventState {
  mapEventList: MapEventDefinition[] = [];
  newRegisteredMapEvent: MapEventDefinition[] = [];
  isFetched: boolean = false;
}

const mutations: MutationTree<MapEventState> = {
  setMapEvents(state: MapEventState, mapEventList: MapEventDefinition[]) {
    state.mapEventList = mapEventList;
  },

  setIsFetched(state: MapEventState, isFetched: boolean) {
    state.isFetched = isFetched;
  },

  setCreatedMapEvent(state: MapEventState, mapEvents: MapEventDefinition[]) {
    state.newRegisteredMapEvent = mapEvents;
  }
};

const getters = <GetterTree<MapEventState, RootState>>{
  activeMapEvents(state): MapEventDefinition[] {
    return state.mapEventList.filter(events => !events.isDisabled);
  },
  disabledMapEvents(state): MapEventDefinition[] {
    return state.mapEventList.filter(events => events.isDisabled);
  }
};

const actions: ActionTree<MapEventState, RootState> = {
  async registerNewEventAndMapEvent(
    { commit, rootState },
    def: MapEventDefinitionListWithEvent
  ) {
    let response;
    if (def.creationMethod == CreationMethod.NEW) {
      const params: MapEventWithEventRequest = {
        event_name: def.eventName,
        parameters: def.parameters
      };
      response = await rootState.api.mapEvent.registerMapEventWithEvent(
        params,
        def.coordinationId
      );
    } else {
      const params: MapEventRequest = {
        parameters: def.parameters
      };
      response = await rootState.api.mapEvent.registerMapEvent(
        params,
        def.coordinationId,
        def.eventId
      );
    }

    commit("setCreatedMapEvent", response);
  }
};

export const mapEvent = {
  namespaced: true,
  state: new MapEventState(),
  mutations,
  actions,
  getters
};
