<i18n src="@/i18n/components/observation/visit/visit-head.json"></i18n>

<template>
  <div class="visitHead" :class="{ 'visitHead--showSummary': showSummary }">
    <div class="ud-Visit_HeadDate" data-cy="ud-Visit_HeadDate">
      <Icon :icon="icons.Calendar" :size="23" class="visitHead__calendarIcon" />
      {{ visitDateString }}
    </div>
    <div class="visitHead__relativeItemContainer">
      <div v-if="showTimeContainer" class="visitHead__timeContainer">
        <Icon
          :icon="visit.timeFrameIconType"
          :size="22"
          :color="visit.timeFrameIconColor"
          class="visitHead__timeFrameIcon"
        />
        {{ startTime }} - {{ endTime }}
        <div
          v-if="supplementaryInformation"
          class="visitHead__supplementaryInformationContainer"
        >
          {{ supplementaryInformation }}
        </div>
      </div>
    </div>
    <div class="visitHead__notPrintedItems">
      <Tooltip
        :placement="shareButtonTooltipPlacement"
        :text="shareTooltipText"
      >
        <IconButton
          class="ud-Visit_HeadShareButton"
          :icon="icons.Link"
          :size="36"
          :icon-scale="0.5"
          :button-type="iconButtonType"
          :background="true"
          @click="onClickShare"
          @mouseleave="onMouseLeave"
        />
      </Tooltip>
    </div>
    <div class="visitHead__summaryContainer">
      <VisitSummaryContainer
        :summary="visit.summary"
        @show-summary="updateShowSummary"
    /></div>
    <div
      class="visitHead__scrollButtonContainer visitHead__notPrintedItems"
      :class="{ 'visitHead__scrollButtonContainer--omo': isOmo }"
    >
      <Tooltip
        :placement="shareButtonTooltipPlacement"
        :text="$t('moveToPreviousVisit')"
        :active="!isFirstVisit"
      >
        <IconButton
          class="visitHead__scrollButtonPrevious"
          :icon="icons.ArrowTop"
          :disabled="isFirstVisit"
          :size="36"
          :icon-scale="0.5"
          :button-type="iconButtonType"
          :background="true"
          @click="scrollToPreviousVisit(visit.key)"
        />
      </Tooltip>
      <Tooltip
        :placement="shareButtonTooltipPlacement"
        :text="$t('moveToNextVisit')"
        :active="!isLastVisit"
      >
        <IconButton
          class="visitHead__scrollButtonNext"
          :icon="icons.ArrowBottom"
          :disabled="isLastVisit"
          :size="36"
          :icon-scale="0.5"
          :button-type="iconButtonType"
          :background="true"
          @click="scrollToNextVisit(visit.key)"
        />
      </Tooltip>
    </div>
  </div>
</template>

<script scoped lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Visit } from "@/models/Visit";
import Tooltip from "@/components/Tooltip.vue";
import IconButton from "@/components/IconButton.vue";
import { msecToSec, formatDate, DateFormat, secToMsec } from "@/util/date-util";

import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { copyToClipboard } from "@/util/string-util";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { TooltipPlacement } from "@/const/tooltip";

import Button from "@/components/Button.vue";
import VisitSummaryContainer from "@/components/observation/visit/visit-summary/VisitSummaryContainer.vue";

@Component({
  components: {
    Icon,
    Tooltip,
    IconButton,
    Button,
    VisitSummaryContainer
  }
})
export default class VisitHead extends Vue {
  icons = Icons;
  iconButtonType = IconButtonType.Weak;
  shareButtonTooltipPlacement = TooltipPlacement.Bottom;

  isCopiedShareLink: boolean = false;
  showSummary: boolean = false;

  @Prop({ type: Visit, required: true })
  visit!: Visit;

  @Prop({ type: Boolean, default: false })
  isFirstVisit!: boolean;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  get shareTooltipText(): string {
    return this.isCopiedShareLink
      ? (this.$i18n.t("copied") as string)
      : (this.$i18n.t("tooltip") as string);
  }

  scrollToNextVisit(gramId: string) {
    this.$emit("scroll-to-next", gramId);
  }

  scrollToPreviousVisit(gramId: string) {
    this.$emit("scroll-to-previous", gramId);
  }

  onClickShare() {
    const url =
      location.protocol +
      "//" +
      location.host +
      location.pathname +
      "?vt=" +
      msecToSec(this.visit.startTimeDate.getTime());
    if (copyToClipboard(url)) {
      this.isCopiedShareLink = true;
    }
    UgTag.pushEvent(UgEventTag.CopyPermalink);
  }

  onMouseLeave() {
    this.isCopiedShareLink = false;
  }

  get isLastVisit(): boolean {
    return this.visit.isLast;
  }

  get showTimeContainer(): boolean {
    return this.visit.hasTimeOfDay;
  }

  get visitDateString(): string {
    return formatDate(
      DateFormat.yyyy年M月d日E,
      this.visit.startTimeDate,
      this.visit.timeZone
    );
  }

  get startTime(): string {
    return formatDate(
      DateFormat.HHcmm,
      this.visit.startTimeDate,
      this.visit.timeZone
    );
  }

  get endTime(): string {
    return formatDate(
      DateFormat.HHcmm_TZONEDIFF,
      new Date(secToMsec(this.visit.endTimeIncludingAdditionalTimeSec)),
      this.visit.timeZone
    );
  }

  get minutesInVisit(): number {
    const MINUTE_LOWER_LIMIT: number = 1;
    if (this.visit.stayTimeSeconds < 60 * MINUTE_LOWER_LIMIT) {
      return MINUTE_LOWER_LIMIT;
    }

    return Math.floor(this.visit.stayTimeSeconds / 60);
  }

  /**
   * falseになるのは、visitのgramが1つのときかつ電話でないとき
   * @return boolean
   */
  get showStayTime(): boolean {
    return this.visit.stayTimeSeconds !== 0;
  }

  get stayTime(): string {
    // over 10 hours
    const HOUR_UPPER_LIMIT: number = 10;
    if (this.minutesInVisit >= 60 * HOUR_UPPER_LIMIT) {
      return this.$i18n.tc("longerThanHours", HOUR_UPPER_LIMIT, {
        num: HOUR_UPPER_LIMIT
      });
    }

    return this.calcuratedStayTime;
  }

  get calcuratedStayTime(): string {
    const stayTimeHours: number = Math.floor(this.minutesInVisit / 60);
    const stayTimeMinutes: number = this.minutesInVisit % 60;

    if (stayTimeHours) {
      return (
        (this.$i18n.t("about") as string) +
        this.$i18n.t("hourMinute", {
          hour: stayTimeHours,
          minute: stayTimeMinutes
        })
      );
    }

    return (
      (this.$i18n.t("about") as string) +
      this.$i18n.t("minute", { minute: stayTimeMinutes })
    );
  }

  get showPvCount(): boolean {
    return this.visit.pageViewCount >= 1;
  }

  get pvCount(): string {
    return this.$i18n.tc("pageView", this.visit.pageViewCount, {
      pv: this.visit.pageViewCount
    });
  }

  get supplementaryInformation(): string {
    if (!this.showStayTime && !this.showPvCount) {
      return "";
    }

    if (this.showStayTime && !this.showPvCount) {
      return "(" + this.stayTime + ")";
    }

    if (!this.showStayTime && this.showPvCount) {
      return "(" + this.pvCount + ")";
    }

    return "(" + this.stayTime + ", " + this.pvCount + ")";
  }

  updateShowSummary(showSummary: boolean): void {
    this.showSummary = showSummary;
  }
}
</script>

<style scoped lang="scss">
.visitHead {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding-top: 2px;
  height: 44px;
  background-color: $colorWhiteAlpha09;
}

.ud-Visit_HeadDate,
.visitHead__relativeItemContainer {
  font-weight: bold;
  font-size: 18px;
}

.ud-Visit_HeadDate {
  display: flex;
  align-items: center;
  margin-right: 16px;
  width: 200px;

  .visitHead__calendarIcon {
    margin-top: -1px;
    margin-right: 11px;
  }
}

.visitHead__relativeItemContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.visitHead__timeContainer {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.visitHead__timeFrameIcon {
  margin-top: -2px;
  margin-right: 11px;
}

.visitHead__supplementaryInformationContainer {
  margin-left: 0.5em;
}

.visitHead__summaryContainer {
  flex: 1;
  padding-left: 13px;
}

.visitHead__scrollButtonContainer {
  text-align: right;
}

.visitHead__scrollButtonContainer:not(.visitHead__scrollButtonContainer--omo) {
  margin-right: -8px;
}

.visitHead__scrollButtonContainer--omo {
  padding-right: 40px;
}

.ud-Visit_HeadShareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  width: 36px;
  height: 36px;
  border: 0;
  border-radius: 50%;
  &:hover {
    background: $colorHoverLightForWhite;
  }
}

.visitHead__scrollButtonPrevious {
  margin-right: 2px;
}

@media print {
  .visitHead__notPrintedItems {
    display: none;
  }
}
</style>
