<template>
  <GramInflowAd v-if="isAd" :gram="gram" :is-omo="isOmo" />
  <GramInflowSearch v-else-if="isSearch" :gram="gram" :is-omo="isOmo" />
  <GramInflowOther v-else-if="isOther" :gram="gram" :is-omo="isOmo" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Gram } from "@/models/Gram";
import GramInflowAd from "@/components/observation/visit/gram/GramInflowAd.vue";
import GramInflowSearch from "@/components/observation/visit/gram/GramInflowSearch.vue";
import GramInflowOther from "@/components/observation/visit/gram/GramInflowOther.vue";

@Component({
  components: { GramInflowAd, GramInflowSearch, GramInflowOther }
})
export default class VisitInflow extends Vue {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  get isAd(): boolean {
    return this.gram.isAdInflow;
  }
  get isSearch(): boolean {
    return this.gram.isSearchInflow;
  }
  get isOther(): boolean {
    return this.gram.isOtherInflow;
  }
}
</script>
