import { ApiRes } from "@/api/api-res";

/**
 * ビジネス指標
 *
 * ビジネス指標は、クライアントごとに定義できるビジネスの目標数値です。
 */

export enum BusinessIndexDefinitionType {
  // 総購入金額
  TOTAL_PAYMENT = 1,
  // 総購入回数
  TOTAL_PURCHASE_COUNT = 2,
  // 総利用期間
  TOTAL_USE_PERIOD = 3
}

export class BusinessIndexDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    // true = インポート中, false = インポート停止
    public readonly isDisabled: boolean,
    // 目標数値の値の型
    public readonly type: BusinessIndexDefinitionType
  ) {}

  public static fromJson(json: ApiRes.BizIndexDef): BusinessIndexDefinition {
    return new BusinessIndexDefinition(
      json.id,
      json.name,
      json.is_disabled,
      json.data_type
    );
  }
}
