<i18n
  src="@/i18n/views/client-settings/conversion/conversion-settings-create.json"
></i18n>
<template>
  <div class="conversionCreationTabs">
    <UgTab>
      <UgTabItem
        class="conversionCreationTabs_wrapper"
        :active="isSingleCvCreation"
        @click="onClickTab(ConversionCreationType.Single)"
      >
        {{ $t("singleRegistration") }}
      </UgTabItem>
      <UgTabItem
        class="conversionCreationTabs_wrapper"
        :active="isBulkCvCreation"
        :disabled="disableBulk"
        @click="onClickTab(ConversionCreationType.Bulk)"
      >
        {{ $t("bulkRegistration") }}
      </UgTabItem>
    </UgTab>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import UgTab from "@/components/tab/UgTab.vue";
import UgTabItem from "@/components/tab/UgTabItem.vue";

export enum ConversionCreationType {
  Single,
  Bulk
}

@Component({
  components: {
    UgTab,
    UgTabItem
  }
})
export default class ConversionSettingsCreateTab extends Vue {
  @Prop({ type: Number, required: true })
  selectedTab!: ConversionCreationType;

  @Prop({ type: Boolean, default: false })
  disableBulk!: boolean;

  onClickTab(selectedTab: ConversionCreationType) {
    this.$emit("changeTab", selectedTab);
  }

  ConversionCreationType = ConversionCreationType;

  get isSingleCvCreation(): boolean {
    return this.selectedTab === ConversionCreationType.Single;
  }

  get isBulkCvCreation(): boolean {
    return this.selectedTab === ConversionCreationType.Bulk;
  }
}
</script>
