<template>
  <div class="searchResultContent">
    <div ref="searchResultNavigation" class="searchResultContent__navigation">
      <SearchResultNavigation :view="view" @view-change="onViewChange" />
    </div>

    <UserSearchResultAttribute
      v-show="showAttribute"
      @click-user="onClickUser"
      @on-show-favorite-form="onShowFavoriteForm"
      @on-close-favorite-form="onCloseFavoriteForm"
    />
    <UserSearchResultOverview
      v-show="showOverview"
      :colored-periods="coloredPeriods"
      :almost-cv-user-list="almostCvUserList"
      @click-user="onClickUser"
      @on-show-favorite-form="onShowFavoriteForm"
      @on-close-favorite-form="onCloseFavoriteForm"
    />
    <UserSearchResultMemo
      v-show="showMemo"
      @click-user="onClickUser"
      @on-show-favorite-form="onShowFavoriteForm"
      @on-close-favorite-form="onCloseFavoriteForm"
    />

    <Loading v-if="loading" height="100px" />

    <div
      v-else-if="showMoreButton"
      class="searchResultContent__buttonContainer"
    >
      <Button width="120px" height="56px" @click="onClickMore">More</Button>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit, Model } from "vue-property-decorator";
import UserSearchResultAttribute from "@/views/UserSearchResultAttribute.vue";
import UserSearchResultOverview from "@/views/UserSearchResultOverview.vue";
import UserSearchResultMemo from "@/views/UserSearchResultMemo.vue";
import Loading from "@/components/Loading.vue";
import Button from "@/components/Button.vue";
import SearchResultNavigation from "@/views/SearchResultNavigation.vue";
import { SearchResultViews } from "@/const/SearchResultViews";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";

@Component({
  components: {
    SearchResultNavigation,
    UserSearchResultAttribute,
    UserSearchResultOverview,
    UserSearchResultMemo,
    Loading,
    Button
  }
})
export default class SearchResultContent extends Vue {
  @Model("view-change", { type: String, required: true })
  view!: SearchResultViews;

  onViewChange(view: SearchResultViews) {
    this.$emit("view-change", view);
  }

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Prop({ type: Boolean, default: false })
  showMoreButton!: boolean;

  @Prop({ type: Array, default: () => [] })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Object, default: null })
  almostCvUserList!: AlmostCvUserList;

  @Emit("click-user")
  onClickUser() {}

  @Emit("show-favorite-form")
  onShowFavoriteForm() {}

  @Emit("close-favorite-form")
  onCloseFavoriteForm() {}

  @Emit("click-more")
  onClickMore() {}

  get showAttribute(): boolean {
    return this.view === SearchResultViews.Attribute;
  }

  get showOverview(): boolean {
    return this.view === SearchResultViews.Overview;
  }

  get showMemo(): boolean {
    return this.view === SearchResultViews.Memo;
  }
}
</script>

<style lang="scss" scoped>
.searchResultContent__navigation {
  position: sticky;
  top: $headerHeight;
  width: 100%;
  background-color: $colorWhite;
}

.searchResultContent__buttonContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}
@media print {
  .searchResultContent__navigation {
    position: relative;
    top: 0 !important;
  }

  .searchResultContent__buttonContainer {
    display: none !important;
  }
}
</style>
