<i18n
  src="@/i18n/components/client-settings/coordination/event/coordination-event-confirm-table.json"
></i18n>
<template>
  <div class="eventConfirmTable">
    <SettingsTable>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'targetData'"
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell width="480px">
          {{ coordinationEvent.targetData }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'event'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell>
          {{ eventName }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'coordinationParameter'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell>
          <ul style="list-style-type: disc; margin-left: 15px;">
            <li v-for="param in coordinationEvent.path" :key="param">
              {{ param }}
            </li>
          </ul>
        </SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import { CreationMethod } from "@/models/client-settings/Coordination";
import {
  EventDefinition,
  CoordinationEventEditableData
} from "@/models/client-settings/EventDefinition";

@Component({
  components: {
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell
  }
})
export default class CoordinationEventConfirmTable extends Vue {
  @Prop({ type: Object, required: true })
  coordinationEvent!: CoordinationEventEditableData;

  get activeEvents(): EventDefinition[] {
    return this.$store.state.clientSettings.activeEventDefinitions;
  }

  get eventName() {
    if (this.coordinationEvent.creationMethod === CreationMethod.NEW)
      return this.coordinationEvent.eventName;

    return this.activeEvents.find(
      event => event.id === Number(this.coordinationEvent.eventId)
    )?.name;
  }
}
</script>

<style lang="scss" scoped>
.eventConfirmTable {
  margin-bottom: 16px;
  min-width: 680px;
}
</style>
