<template>
  <div
    class="icon-button"
    data-cy="icon-button"
    :class="{
      'cursor-default': disabled
    }"
    :style="divstyle"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
    @click="onClick"
  >
    <component
      :is="burgerIcon"
      v-if="isHamburgerSvgIcon"
      class="icon-button__icon"
      :width="iconSize"
      :height="iconSize"
    />
    <Icon
      v-else
      class="icon-button__icon"
      :icon="icon"
      :color="iconColor"
      :size="iconSize"
    />
  </div>
</template>

<script lang="ts">
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { IconButtonType, IconButtonColorType } from "@/const/IconButtons";
import Icon from "@/components/Icon.vue";
import IconBurger from "@/components/v3-icons/IconBurger.vue";

@Component({
  components: {
    Icon,
    IconBurger
  }
})
export default class IconButton extends Vue {
  @Prop({ type: String, required: true })
  icon!: Icons;

  @Prop({ type: Number, default: 14 })
  size!: number;

  //"iconScale" option is the ratio of icon for background div
  @Prop({ type: Number, default: 1.0 })
  iconScale!: number;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: Boolean, default: false })
  background!: boolean;

  @Prop({
    type: Object,
    default() {
      return IconButtonType.Strong;
    }
  })
  buttonType!: IconButtonColorType;

  @Prop({ type: Boolean, default: false })
  isHamburgerSvgIcon?: boolean;

  @Emit("mouseenter")
  onMouseenter() {
    this.hovered = true;
  }

  @Emit("mouseleave")
  onMouseleave() {
    this.hovered = false;
  }

  onClick(e: MouseEvent) {
    if (!this.disabled) {
      this.$emit("click", e);
    }
  }

  hovered: boolean = false;

  get iconSize() {
    return this.size * this.iconScale;
  }
  get iconColor(): Colors {
    if (this.disabled) return this.buttonType.disabledIconColor;
    if (this.selected) return this.buttonType.selectedIconColor;
    if (this.hovered) return this.buttonType.hoveredIconColor;
    return this.buttonType.unhoverIconColor;
  }
  get backgroundColor(): Colors {
    if (!this.background) return Colors.Clear;
    if (this.disabled) return this.buttonType.disabledBackgroundColor;
    if (this.hovered) return this.buttonType.hoveredBackgroundColor;
    return this.buttonType.unhoverBackgroundColor;
  }
  get divstyle() {
    return {
      width: this.size + "px",
      height: this.size + "px",
      background: this.backgroundColor
    };
  }
  get burgerIcon() {
    return IconBurger;
  }
}
</script>

<style lang="scss" scoped>
.icon-button {
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}
.icon-button.cursor-default {
  cursor: default;
}
.icon-button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
