<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :title="$t('nodeTitleLaunchApp')"
    class="filter-node-launch-app"
    :show-add-condition="condition.isConditionAddable(isFirstNode)"
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    :select-exclusion-type-value="exclutionTypeValue"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-condition="onClickAddCondition"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <FilterAppLaunchField v-model="launchType" />

    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, false, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterLaunchAppAdditionalConditionField
        v-if="
          isShowFilterLaunchAppAdditionalConditionField(additionalCondition)
        "
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      >
        <Tooltip
          v-if="isLinkCondition(additionalCondition.conditionType)"
          class="tooltip"
          :placement="tooltipPlacement.Bottom"
          :wrap="true"
        >
          <Icon :icon="Icons.HelpInvert" :color="Colors.Base700" :size="12" />
          <template #content>
            <div class="description__wrapper">
              <div class="description__title">{{
                $t("appLaunchLinkDescription")
              }}</div>
              <dl class="description__detail">
                <dt>{{ $t("appLaunchLinkDescriptionForWithDynamicLink") }}</dt>
                <dd>
                  <ul>
                    <li>campaign</li>
                    <li>source</li>
                    <li>medium</li>
                  </ul>
                </dd>
                <dt>{{
                  $t("appLaunchLinkDescriptionForWithoutDynamicLink")
                }}</dt>
                <dd>
                  <ul>
                    <li>link_name</li>
                    <li>link_value</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </template>
        </Tooltip>
      </FilterLaunchAppAdditionalConditionField>
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import {
  FilterNodeForLaunchApp,
  AppLaunchType
} from "@/models/search/filter-node/FilterNodeForLaunchApp";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { ExclusionProps } from "@/models/search/filter-node/FilterExclusion";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import {
  FilterLaunchAppAdditionalCondition,
  FilterLaunchAppAdditionalConditionType,
  filterNodeConditionTypeToGlobalConversionAttributeIdId
} from "@/models/search/filter-node-condition/FilterLaunchAppAdditionalCondition";
import FilterNode from "@/components/filter/FilterNode.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import FilterAppLaunchField from "@/components/filter/form/FilterAppLaunchField.vue";
import FilterLaunchAppAdditionalConditionField from "@/components/filter/form/FilterLaunchAppAdditionalConditionField.vue";
import { TooltipPlacement } from "@/const/tooltip";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import {
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

@Component({
  name: "FilterNodeLaunchApp",
  components: {
    FilterNode,
    FilterNodeItem,
    FilterDateHourField,
    FilterPeriodField,
    FilterAppLaunchField,
    FilterLaunchAppAdditionalConditionField,
    Icon,
    Tooltip
  }
})
export default class FilterNodeLaunchApp extends Vue {
  Colors = Colors;
  dateHour = FilterNodeConditionType.DateHour;
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[] = this
    .$store.state.system.globalConversionAttributeDefinitions;
  Icons = Icons;
  period = FilterNodeConditionType.Period;
  tooltipPlacement = TooltipPlacement;

  @Model("input", { type: FilterNodeForLaunchApp, required: true })
  condition!: FilterNodeForLaunchApp;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  onInput(condition: FilterNodeForLaunchApp) {
    this.$emit("input", condition);
  }

  get launchType(): AppLaunchType {
    return this.condition.launchType;
  }
  set launchType(launchType: AppLaunchType) {
    // 起動タイプを変えた場合は、FilterLaunchAppAdditionalConditionの追加条件を消す
    const conditions = this.condition.additionalConditions.filter(cnd => {
      return !(cnd instanceof FilterLaunchAppAdditionalCondition);
    });
    this.onInput(this.createCondition(launchType, conditions));
  }

  isShowFilterLaunchAppAdditionalConditionField(
    condition:
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
  ): boolean {
    return this.isFilterLaunchAppAdditionalCondition(condition.conditionType);
  }

  // 条件を追加
  onClickAddCondition() {
    const cnds = this.condition.additionalConditions;
    let cnd!:
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition;
    if (this.condition.launchType === AppLaunchType.PushNotification) {
      cnd = this.getDefaultPushCondition(cnds, this.isFirstNode);
    } else if (this.condition.launchType === AppLaunchType.Link) {
      cnd = this.getDefaultLinkCondition(cnds);
    } else {
      cnd = this.getDefaultTimingCondition(cnds, this.isFirstNode);
    }
    cnds.push(cnd);
    this.onInput(this.createCondition(null, cnds));
  }

  getDefaultTimingCondition(
    conditions: (
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
    )[],
    isFirstNode: boolean
  ): FilterFirstTimeCondition | FilterPeriodCondition {
    const hasFirstTime = conditions.some(
      cnd => cnd instanceof FilterFirstTimeCondition
    );
    if (isFirstNode && !hasFirstTime) {
      return FilterFirstTimeCondition.buildDefaultData();
    }
    return FilterPeriodCondition.buildDefaultData();
  }

  // Push通知起動の場合のデフォルト追加条件
  getDefaultPushCondition(
    conditions: (
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
    )[],
    isFirstNode: boolean
  ):
    | FilterFirstTimeCondition
    | FilterPeriodCondition
    | FilterLaunchAppAdditionalCondition {
    const typePush = FilterNodeConditionType.PushLabel;
    // ラベルが条件になければ、ラベル条件を返す
    if (!conditions.some(cnd => cnd.conditionType === typePush)) {
      return FilterLaunchAppAdditionalCondition.buildDefaultData(
        typePush,
        filterNodeConditionTypeToGlobalConversionAttributeIdId(
          typePush,
          this.globalConversionAttributeDefinitions
        )
      );
    }
    return this.getDefaultTimingCondition(conditions, isFirstNode);
  }

  // Link起動の場合のデフォルト追加条件
  getDefaultLinkCondition(
    conditions: (
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition
    )[]
  ): FilterLaunchAppAdditionalCondition {
    const typeCampaign = FilterNodeConditionType.LinkCampaign;
    // campaignが条件になければ、campaign条件を返す
    if (!conditions.some(cnd => cnd.conditionType === typeCampaign)) {
      return FilterLaunchAppAdditionalCondition.buildDefaultData(
        typeCampaign,
        filterNodeConditionTypeToGlobalConversionAttributeIdId(
          typeCampaign,
          this.globalConversionAttributeDefinitions
        )
      );
    }
    // campaingが条件に追加ずみならSourceの条件を返す
    const typeSource = FilterNodeConditionType.LinkSource;
    return FilterLaunchAppAdditionalCondition.buildDefaultData(
      typeSource,
      filterNodeConditionTypeToGlobalConversionAttributeIdId(
        typeSource,
        this.globalConversionAttributeDefinitions
      )
    );
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let additionalCondition!:
      | FilterAdditionalTimingCondition
      | FilterLaunchAppAdditionalCondition;

    if (this.isFilterLaunchAppAdditionalCondition(type)) {
      additionalCondition = FilterLaunchAppAdditionalCondition.buildDefaultData(
        type,
        filterNodeConditionTypeToGlobalConversionAttributeIdId(
          type,
          this.globalConversionAttributeDefinitions
        )
      );
    } else if (type === FilterNodeConditionType.FirstTime) {
      additionalCondition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      additionalCondition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      additionalCondition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? additionalCondition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputAdditionalCondition(
    condition:
      | FilterLaunchAppAdditionalCondition
      | FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  createCondition(
    launchType: AppLaunchType | null,
    conditions:
      | (FilterLaunchAppAdditionalCondition | FilterAdditionalTimingCondition)[]
      | null
  ): FilterNodeForLaunchApp {
    const setLaunchType =
      launchType === null ? this.condition.launchType : launchType;
    const setConditions =
      conditions === null ? this.condition.additionalConditions : conditions;
    return new FilterNodeForLaunchApp(
      setLaunchType,
      setConditions,
      this.condition.childIndex,
      this.condition.depth,
      this.condition.edge,
      this.condition.filterExclusion
    );
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForLaunchApp(
        this.condition.launchType,
        this.condition.additionalConditions,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue)
      )
    );
  }
  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get checkAdditionalConditions(): boolean {
    const conditionsMax = 2;
    const conditions = this.$props.condition;

    return (
      conditions.additionalConditions &&
      conditions.additionalConditions.length === conditionsMax
    );
  }

  isFilterLaunchAppAdditionalCondition(
    conditionType: FilterNodeConditionType
  ): conditionType is FilterLaunchAppAdditionalConditionType {
    return (
      conditionType === FilterNodeConditionType.PushLabel ||
      conditionType === FilterNodeConditionType.LinkCampaign ||
      conditionType === FilterNodeConditionType.LinkSource ||
      conditionType === FilterNodeConditionType.LinkMedium ||
      conditionType === FilterNodeConditionType.LinkLinkName ||
      conditionType === FilterNodeConditionType.LinkLinkValue
    );
  }

  isLinkCondition(conditionType: FilterNodeConditionType): boolean {
    return (
      conditionType === FilterNodeConditionType.LinkCampaign ||
      conditionType === FilterNodeConditionType.LinkSource ||
      conditionType === FilterNodeConditionType.LinkMedium ||
      conditionType === FilterNodeConditionType.LinkLinkName ||
      conditionType === FilterNodeConditionType.LinkLinkValue
    );
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  margin-left: 4px;
}

.description__wrapper {
  width: 420px;
}

.description__title {
  margin-bottom: 10px;
}

.description__detail {
  dt {
    margin: 10px 0 2px 0;
  }
  li::before {
    content: "- ";
  }
}
</style>
