<i18n
  src="@/i18n/views/client-settings/conversion/conversion-settings-index.json"
></i18n>
<template>
  <div class="conversionSettingsIndex">
    <div v-t="'title'" class="conversionSettingsIndex__title" />
    <div class="conversionSettingsIndex__description" v-html="description" />
    <div
      v-if="successMessage !== ''"
      class="conversionSettingsIndex__message"
      v-html="successMessage"
    />
    <Button
      v-if="canCreate"
      v-t="'register'"
      class="conversionSettingsIndex__button"
      padding="0 20px"
      :text-size="buttonTextSize"
      @click="onClickCreate"
    />
    <div v-else class="conversionSettingsIndex__limitMessage">{{
      $t("limitMessage", { maximum: resourceLimitation })
    }}</div>
    <Loading v-if="isLoading" height="80px" />
    <ConversionListTable
      v-else
      :active-conversions="activeConversions"
      :disabled-conversions="disabledConversions"
      :can-enable="canCreate"
      @click-edit="onClickUpdate"
      @click-update-state="onClickUpdateState"
    />
  </div>
</template>
<script lang="ts">
import orderBy from "lodash/orderBy";
import { Vue, Component } from "vue-property-decorator";
import { Route } from "vue-router";
import ConversionListTable from "@/components/client-settings/conversion/ConversionListTable.vue";
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import { ButtonTextSize } from "@/const/button";
import {
  ConversionDefinition,
  ConversionEditableData
} from "@/models/client-settings/ConversionDefinition";
import { handleError } from "@/util/error-util";

@Component({
  components: {
    Button,
    ConversionListTable,
    Loading
  }
})
export default class ConversionSettingsIndex extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  successMessage: string = "";
  prevRouteName = "";

  async mounted() {
    await this.$store
      .dispatch("conversion/fetchConversionList")
      .catch(handleError);

    this.setBulkSuccessMessage();
    this.setIndividualSuccessMessage();
  }

  setBulkSuccessMessage() {
    if (this.bulkCreatedConversion.length > 0) {
      this.successMessage = this.bulkSuccessMessage;
      this.$store.commit("conversion/setBulkCreatedConversion", []);
    }
  }

  setIndividualSuccessMessage() {
    if (this.previouslyCreatedOrUpdatedConversion !== null) {
      switch (this.prevRouteName) {
        case "conversion-settings-create":
          this.successMessage = this.$t("created", {
            name: this.previouslyCreatedOrUpdatedConversion.name
          }).toString();
          break;
        case "conversion-settings-update":
        case "conversion-settings-measurement-state":
          this.successMessage = this.$t("updated", {
            name: this.previouslyCreatedOrUpdatedConversion.name
          }).toString();
          break;
        default:
          this.successMessage = "";
      }
      this.$store.commit(
        "conversion/setPreviouslyCreatedOrUpdatedConversion",
        null
      );
    }
  }

  get bulkSuccessMessage(): string {
    const conversionList = this.bulkCreatedConversion
      .map(conversion => `<li> - ${conversion.name}</li>`)
      .join("");

    return `${this.$t("bulkCreationSuccessMessage")}<ul>${conversionList}</ul>`;
  }

  beforeRouteEnter(
    to: Route,
    from: Route,
    next: (to?: string | false | ((vm: Vue) => any) | void) => void
  ) {
    next(vm => {
      vm.$data.prevRouteName = from.name;
    });
  }

  get isLoading(): boolean {
    return !this.$store.state.conversion.isConversionsFetched;
  }

  get activeConversions(): ConversionDefinition[] {
    return orderBy(this.$store.getters["conversion/activeConversions"], [
      "name",
      "id"
    ]);
  }

  get disabledConversions(): ConversionDefinition[] {
    return orderBy(this.$store.getters["conversion/disabledConversions"], [
      "name",
      "id"
    ]);
  }

  get previouslyCreatedOrUpdatedConversion(): ConversionDefinition | null {
    return this.$store.state.conversion.previouslyCreatedOrUpdatedConversion;
  }

  get bulkCreatedConversion(): ConversionEditableData[] {
    return this.$store.state.conversion.bulkCreatedConversion;
  }

  get resourceLimitation(): number {
    return this.$store.state.client.client.resourceLimitation.conversion;
  }

  get canCreate(): boolean {
    return this.resourceLimitation > this.activeConversions.length;
  }

  get description(): string {
    return this.$t("description") as string;
  }

  onClickCreate() {
    this.$router.push({ name: "conversion-settings-create" });
  }

  onClickUpdate(id: number) {
    this.$router.push({
      name: "conversion-settings-update",
      params: { id: `${id}` }
    });
  }

  onClickUpdateState(id: number, measurementStatus: "enable" | "disable") {
    this.$router.push({
      name: "conversion-settings-measurement-state",
      params: { id: `${id}`, type: measurementStatus }
    });
  }
}
</script>
<style lang="scss" scoped>
.conversionSettingsIndex {
  margin-right: 20px;
  min-width: 704px;
}
.conversionSettingsIndex__title {
  padding: 0 0 46px 0;
  font-size: 20px;
  font-weight: 700;
}
.conversionSettingsIndex__description {
  padding-bottom: 40px;
  white-space: pre-line;
  font-size: 12px;
  line-height: 24px;

  ::v-deep a {
    color: $colorBase700;
    text-decoration: underline;

    &:hover {
      color: $colorBase800;
    }
  }
}
.conversionSettingsIndex__message {
  color: $colorBlue900;
  margin-bottom: 30px;
  font-size: 14px;
}
.conversionSettingsIndex__button {
  min-width: 120px;
  margin-bottom: 30px;
}
.conversionSettingsIndex__limitMessage {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid $colorBlue900;
  color: $colorBlue900;
  font-size: 12px;
  line-height: 1.5;
}
</style>
