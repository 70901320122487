<template>
  <div class="select-filter-condition-label">
    <SelectConditionLabel :select-condition="selectCondition" />

    <div
      v-if="showFilter"
      class="select-filter-condition-label__filter"
      data-cy="filter-condition-label-result"
    >
      <Icon
        :icon="iconFilter"
        :size="12"
        :color="iconFilterColor"
        class="select-filter-condition-label__filter-icon"
      />
      <FilterConditionLabel :filter-condition="filterCondition" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import SelectConditionLabel from "@/views/SelectConditionLabel.vue";
import FilterConditionLabel from "@/views/FilterConditionLabel.vue";

@Component({
  components: {
    Icon,
    SelectConditionLabel,
    FilterConditionLabel
  }
})
export default class SelectFilterConditionLabel extends Vue {
  @Prop({ type: Object, required: true })
  selectCondition!: SelectCondition;

  @Prop({ type: FilterCondition, default: null })
  filterCondition!: FilterCondition | null;

  iconFilter: Icons = Icons.Filter;
  iconFilterColor: Colors = Colors.Base500;

  get showFilter(): boolean {
    return (
      this.filterCondition !== null &&
      (this.filterCondition.filterNodes.length > 0 ||
        this.filterCondition.deviceTypes.length > 0)
    );
  }
}
</script>

<style lang="scss" scoped>
.select-filter-condition-label__filter {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid $colorBase500;
}
.select-filter-condition-label__filter-icon {
  margin-right: 4px;
}
.select-filter-condition-label__period {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid $colorBase500;
}
</style>
