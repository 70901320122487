<template>
  <div
    v-if="hasConversion"
    :id="gramId"
    class="ud-Visit_Gram ud-Visit_Gram_Action"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_Circle ud-Visit_GramIcon_Circle-Cv">
        <Icon :icon="icon" :size="iconSize" :color="iconColor" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_GramTime">{{ time }}</div>
      <div class="ud-Visit_GramAction ud-Visit_GramAction-Cv">{{ title }}</div>
      <div v-if="hasConversionAttributes" class="ud-Visit_GramActionDetail">
        <div
          v-for="cvAttr in conversionAttributes"
          :key="cvAttr.id"
          class="ud-Visit_GramActionDetailRow"
        >
          <div class="ud-Visit_GramActionDetailKey">{{ cvAttr.name }}</div>
          <div class="ud-Visit_GramActionDetailValue">{{ cvAttr.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import { Gram, CvType } from "@/models/Gram";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";

@Component({
  components: { Icon }
})
export default class GramCv extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  iconColor = Colors.White;

  get conversionAttributes(): {
    id: number;
    name: string;
    value: string;
  }[] {
    return this.gram.conversionAttributes;
  }

  get hasConversion(): boolean {
    return this.gram.hasConversion;
  }

  get hasConversionAttributes(): boolean {
    return 0 < this.conversionAttributes.length;
  }

  get gramId(): string {
    return this.gram.id;
  }

  get time(): string {
    return this.gram.timeTx;
  }

  get title(): string {
    return this.gram.conversionName;
  }

  get icon(): string {
    if (this.gram.cvType === CvType.FirstOpen) {
      return Icons.FirstOpen;
    }
    return Icons.Conversion;
  }

  get iconSize(): number {
    if (this.gram.cvType === CvType.FirstOpen) {
      return 20;
    }
    return 14;
  }
}
</script>
