<i18n
  src="@/i18n/components/user-trend/form/user-trend-filter-form-container.json"
></i18n>
<template>
  <div class="userTrendFilterFormContainer">
    <div class="userTrendFilterFormContainer__body">
      <div class="userTrendFilterFormContainer__form">
        <slot />
      </div>

      <div class="userTrendFilterFormContainer__buttonContainer">
        <Button
          class="userTrendFilterFormContainer__filterButton"
          height="35px"
          :disabled="isDisabledFilter"
          @click="onFilterClick"
        >
          <span
            v-t="'filter'"
            class="userTrendFilterFormContainer__buttonText"
          />
        </Button>

        <div
          v-if="showDeselectFilter"
          class="userTrendFilterFormContainer__deselectFilterLink"
          @click="onDeselectFilterClick"
          >{{ $t("deselectFilter") }}</div
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import Button from "@/components/Button.vue";

@Component({
  components: { Button }
})
export default class UserTrendFilterFormContainer extends Vue {
  @Prop({ type: Boolean, required: true })
  showDeselectFilter!: boolean;

  @Prop({ type: Boolean, required: true })
  isDisabledFilter!: boolean;

  @Emit("filter")
  onFilterClick() {}

  @Emit("deselect-filter")
  onDeselectFilterClick() {}
}
</script>

<style lang="scss" scoped>
.userTrendFilterFormContainer__body {
  display: flex;
  align-items: flex-end;
}

.userTrendFilterFormContainer__buttonContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: $formPartsHeight;
}

.userTrendFilterFormContainer__buttonText {
  font-size: 14px;
}

.userTrendFilterFormContainer__deselectFilterLink {
  margin-left: 10px;
  color: $colorBlue900;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
</style>
