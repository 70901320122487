import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * 初回による条件
 *
 * 絞り込み条件に追加で、初回を条件として付与できる
 */
export class FilterFirstTimeCondition {
  public readonly conditionType = FilterNodeConditionType.FirstTime;

  // validateがfalseになる条件がない
  get validate(): ValidationResult {
    return { isValid: true };
  }

  public static buildDefaultData(): FilterFirstTimeCondition {
    return new FilterFirstTimeCondition();
  }
}
