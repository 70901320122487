<template>
  <label class="check-box" :class="{ checked: checked, disabled: disabled }">
    <input
      type="checkbox"
      data-cy="additional-condition-button-checkbox"
      :checked="checked"
      @change="onChange($event.target.checked)"
    />
    <div class="check-box_icon" data-cy="check-box_icon">
      <Icon
        v-if="checked"
        :icon="iconChecked"
        :size="11"
        :color="iconColor"
        class="check-box_icon-checked"
        data-cy="check-box_icon-checked"
      />
    </div>

    <div v-if="hasSlot" class="check-box_wrapper">
      <slot></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    Icon
  }
})
export default class CheckBox extends Vue {
  @Model("input", { type: Boolean, required: true })
  checked!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  iconChecked = Icons.Checked;

  get iconColor(): Colors {
    if (this.disabled) {
      return Colors.Base500;
    }
    return Colors.Base900;
  }

  // チェックボックス単体仕様の場合、不要な余白を削除
  get hasSlot(): boolean {
    return Object.keys(this.$slots).length > 0;
  }

  onChange(checked: boolean) {
    if (!this.disabled) {
      this.$emit("input", checked);
    }
  }
}
</script>

<style scoped lang="scss">
.check-box_icon {
  position: relative;
  min-width: $selectFormPartsSize;
  width: $selectFormPartsSize;
  height: $selectFormPartsSize;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorDark;
}

.check-box_wrapper {
  margin-left: 10px;
  padding-top: 4px;
  color: $colorBase800;
  line-height: 1.2;

  @include default-break-word();
}

.check-box {
  display: inline-flex;
  padding-right: 10px;
  cursor: pointer;

  & [type="checkbox"] {
    display: none;
  }

  &.checked {
    & .check-box_wrapper {
      color: $colorBase900;
    }
    & .check-box_icon {
      border-color: $colorBase700;
    }
  }

  &.disabled {
    cursor: default;

    & .check-box_wrapper {
      color: $colorBase500;
    }

    & .check-box_icon {
      border: 1px solid $colorBase500;
      background-color: $colorBase300;
      content: "";
    }
  }
}

.check-box_icon-checked {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
