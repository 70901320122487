import { GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { PanelType } from "@/const/home";
import notificationData from "@/assets/json/notification.json";
import { getCurrentDate } from "@/util/date-util";

// Please add notification item to where you want to show it in
// `assets/json/notification.json`.
// And you need to update `i18n/views/notification.json` to define the title.
// In addition, you don't need to remove expired items. It's not shown.
// It will be helpful to prevent from using same id which is used before.

export interface NotificationItem {
  // Please take care not to be same as other item's id.
  id: string;
  // Release date of the feature
  startDate: string;
  // Date to hide this notification item
  endDate: string;
  pathName?: string;
  panelType?: keyof typeof PanelType;
  helpSiteUrl?: string;
  isRead?: boolean;
}

export class NotificationState {
  // item's id will be pushed when the notification item is clicked
  markAsRead: string[] = [];
  items: NotificationItem[] = notificationData;
}

export const getters: GetterTree<NotificationState, RootState> = {
  // Return not expired items
  items(state): NotificationItem[] {
    // Extract items which can be shown
    const activeItems = state.items.filter(activeItemFilter);

    // Add isRead status
    activeItems.forEach(item => {
      item.isRead = state.markAsRead.indexOf(item.id) !== -1;
    });

    return activeItems;
  },
  // Return number of unread notification items
  unreadNotificationCount(state): number {
    const activeItems = state.items.filter(activeItemFilter);
    return activeItems.length - state.markAsRead.length;
  }
};

export const mutations: MutationTree<NotificationState> = {
  setMarkAsRead(state, markAsRead: string[]) {
    state.markAsRead = markAsRead;
  },
  addMarkAsRead(state, notificationId: string) {
    state.markAsRead.push(notificationId);
  }
};

export const actions: ActionTree<NotificationState, RootState> = {
  // Remove the items which are expired or removed from markAsRead when NotificationNav.vue is initialized
  setMarkAsRead({ commit, state }) {
    const { markAsRead } = state;
    const activeIds = state.items.filter(activeItemFilter).map(item => item.id);

    commit(
      "setMarkAsRead",
      markAsRead.filter(id => {
        return activeIds.indexOf(id) !== -1;
      })
    );
  },
  // Added item's id to markAsRead when the notification item is clicked
  addMarkAsRead({ commit, state }, notificationId: string) {
    if (state.markAsRead.indexOf(notificationId) !== -1) return;
    commit("addMarkAsRead", notificationId);
  }
};

export const notification = {
  namespaced: true,
  state: new NotificationState(),
  getters,
  actions,
  mutations
};

/**
 * Callback function for filter to extract items which are not expired
 * @param item NotificationItem
 * @returns boolean
 */
export const activeItemFilter = (item: NotificationItem): boolean => {
  const today = getCurrentDate();
  const startDate: Date = new Date(item.startDate);
  const endDate: Date = new Date(item.endDate);

  return (
    startDate.getTime() <= today.getTime() &&
    endDate.getTime() > today.getTime()
  );
};
