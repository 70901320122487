<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div>
    <LightUgTableRow
      v-for="conversion in conversions"
      :key="conversion.conversionId"
    >
      <LightUgTableCell :width="CELL_COLUMN_WIDTH">
        {{ conversion.conversionName }}
      </LightUgTableCell>
      <LightUgTableCell v-if="conversion.count < 1" :disabled="true">
        <RadioButton
          name="conversionId"
          :label="conversion.conversionId"
          :value="selectedConversionId"
          :disabled="true"
          :size="RadioButtonSize.Small"
        >
          {{
            conversion.count === -1
              ? "N/A"
              : $tc("userCount", conversion.count, {
                  userCount: conversion.count
                })
          }}
        </RadioButton>
      </LightUgTableCell>
      <LightUgTableCell v-else>
        <RadioButton
          name="conversionId"
          :label="conversion.conversionId"
          :value="selectedConversionId"
          :size="RadioButtonSize.Small"
          @change="onSelectConversionName"
        >
          {{
            $tc("userCount", conversion.count, {
              userCount: conversion.count
            })
          }}
        </RadioButton>
      </LightUgTableCell>
    </LightUgTableRow>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import RadioButton from "@/components/form/RadioButton.vue";
import { RadioButtonSize } from "@/const/RadioButtonSize";
import LightUgTableRow from "@/components/table/LightUgTableRow.vue";
import LightUgTableCell from "@/components/table/LightUgTableCell.vue";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";

@Component({
  components: {
    RadioButton,
    LightUgTableRow,
    LightUgTableCell
  }
})
export default class FeaturedUsersAlmostCvTableBody extends Vue {
  CELL_COLUMN_WIDTH = "458px";
  RadioButtonSize = RadioButtonSize;
  selectedConversionId = 0;

  @Prop({ type: Object, required: true })
  almostCvInfo!: AlmostCvInfo;

  @Emit("select")
  emitSelect(): string {
    if (this.selectedConversionId === 0) return "";
    return `/almost-cv/${this.selectedConversionId}`;
  }

  get conversions() {
    return this.almostCvInfo.conversions;
  }

  get periodRange(): string {
    return this.almostCvInfo.period.periodRange;
  }

  mounted() {
    const defaultConversion = this.conversions.find(
      conversion => conversion.count > 0
    );

    if (defaultConversion) {
      this.selectedConversionId = defaultConversion.conversionId;
    }

    this.emitSelect();
  }

  onSelectConversionName(value: number) {
    this.selectedConversionId = value;
    this.emitSelect();
  }
}
</script>
