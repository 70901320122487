<template>
  <div v-if="currentTour" class="tourDetail">
    <TourDetailHeader :tour="currentTour" />
    <SearchResultContainer
      class="tourDetail__searchResult"
      :view="searchResultView"
      :icon="''"
      :colored-periods="coloredPeriods"
      @view-change="onViewChange"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { SearchResultViews } from "@/const/SearchResultViews";
import { handleNoQueryCacheError } from "@/util/error-util";
import TourDetailTitle from "@/components/tours/TourDetailTitle.vue";
import TourDetailHeader from "@/components/tours/TourDetailHeader.vue";
import Button from "@/components/Button.vue";
import {
  TourCondition,
  TourConditionSearchAndFilterModel
} from "@/models/tour/TourCondition";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { getColoredPeriods } from "@/components/chart/chart-util";

@Component({
  components: {
    SearchResultContainer,
    TourDetailTitle,
    TourDetailHeader,
    Button
  }
})
export default class TourDetail extends Vue {
  searchResultView = SearchResultViews.Overview;

  async created() {
    await this.$store.dispatch("tour/findSelectedTourById", this.currentTourId);

    if (this.currentTour) {
      this.$store.commit("tour/setIsTourMode", true);
      this.fetchUsers();
    } else {
      this.$router.push({ name: "tours" });
    }
  }

  destroyed() {
    this.$store.commit("tour/setIsTourMode", false);
    this.$store.commit("tour/setCurrentTour", null);
  }

  get currentTourId() {
    return this.$route.params.id;
  }

  get currentTour(): TourCondition {
    return this.$store.state.tour.currentTour;
  }

  get coloredPeriods(): ColoredPeriod[] {
    if (this.currentTour instanceof TourConditionSearchAndFilterModel) {
      return getColoredPeriods(this.currentTour.selectCondition);
    }
    return [];
  }

  fetchUsers() {
    this.$store
      .dispatch("tour/executeSelectAndFilterForTour", this.currentTour)
      .catch(handleNoQueryCacheError);
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
  }
}
</script>

<style scoped lang="scss">
.tourDetail__searchResult {
  padding-top: 27px;
}
</style>
