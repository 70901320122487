import { render, staticRenderFns } from "./SettingNav.vue?vue&type=template&id=5f934654&scoped=true"
import script from "./SettingNav.vue?vue&type=script&lang=ts"
export * from "./SettingNav.vue?vue&type=script&lang=ts"
import style0 from "./SettingNav.vue?vue&type=style&index=0&id=5f934654&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f934654",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/setting-nav.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2FSettingNav.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports