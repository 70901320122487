<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="child-filter-node-inflow-parameter">
    <FilterBaseInputText
      v-model="parameter"
      :prefix="$t('inflowParameterPrefix')"
      :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
    />

    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
    <FilterAddConditionButton
      v-if="condition.isConditionAddable(isFirstNode)"
      @click="onClickAddCondition"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterAddConditionButton from "@/components/filter/FilterAddConditionButton.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import { ChildFilterNodeForInflowParameter } from "@/models/search/filter-node/ChildFilterNodeForInflowParameter";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";

@Component({
  components: {
    FilterNodeItem,
    FilterBaseInputText,
    FilterDateHourField,
    FilterPeriodField,
    FilterAddConditionButton
  }
})
export default class ChildFilterNodeInflowParameter extends Vue {
  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: ChildFilterNodeForInflowParameter, required: true })
  condition!: ChildFilterNodeForInflowParameter;

  @Prop({ type: Number, required: true })
  depth!: number;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  onInput(condition: ChildFilterNodeForInflowParameter) {
    this.$emit("input", condition);
  }

  get parameter(): string {
    return this.condition.parameter;
  }

  set parameter(parameter: string) {
    this.onInput(
      new ChildFilterNodeForInflowParameter(
        parameter,
        this.condition.additionalConditions,
        this.depth
      )
    );
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let condition!: FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.FirstTime) {
      condition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      condition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      condition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(
      new ChildFilterNodeForInflowParameter(
        this.condition.parameter,
        additionalConditions,
        this.depth
      )
    );
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(
      new ChildFilterNodeForInflowParameter(
        this.condition.parameter,
        additionalConditions,
        this.depth
      )
    );
  }

  onInputAdditionalCondition(
    condition: FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(
      new ChildFilterNodeForInflowParameter(
        this.condition.parameter,
        additionalConditions,
        this.depth
      )
    );
  }

  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    let condition!: FilterAdditionalTimingCondition;

    const hasFirstTime = additionalConditions.some(
      cnd => cnd instanceof FilterFirstTimeCondition
    );

    if (this.isFirstNode && !hasFirstTime) {
      condition = FilterFirstTimeCondition.buildDefaultData();
    } else {
      condition = FilterPeriodCondition.buildDefaultData();
    }

    additionalConditions.push(condition);
    this.onInput(
      new ChildFilterNodeForInflowParameter(
        this.condition.parameter,
        additionalConditions,
        this.depth
      )
    );
  }
}
</script>
<style lang="scss" scoped>
.child-filter-node-inflow-parameter {
  margin-top: 10px;
}
</style>
