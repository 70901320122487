export enum ExclusionType {
  Include = 1, // jsonValue will be false
  Exclude = 2
}

export interface ExclusionProps {
  isEnabled: boolean;
  isExcluded: boolean;
  isShown: boolean;
}

export class FilterExclusion {
  constructor(public exclusionType: ExclusionType = ExclusionType.Include) {}
}

export function convertFilterExclusionToJsonValue(
  filterExclusion: FilterExclusion
): boolean {
  return filterExclusion.exclusionType === ExclusionType.Exclude;
}

export function convertJsonValueToFilterExclusion(
  jsonValue: boolean
): FilterExclusion {
  if (jsonValue && jsonValue === true) {
    return new FilterExclusion(ExclusionType.Exclude);
  }
  return new FilterExclusion(ExclusionType.Include);
}
