var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:{
      'side-bar-item-expandable': true,
      'side-bar-item-expandable--disabled': _setup.isDisabled
    },attrs:{"data-cy":"expandable-item"},on:{"click":function($event){return _setup.emits('toggle-subitems')}}},[_c(_setup.V3SideBarItemNew,{attrs:{"show-minimized":_vm.showMinimized,"icon":_vm.icon,"text":_vm.text,"active":_setup.isActive,"is-expandable":"","disabled":_setup.isDisabled}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMinimized),expression:"!showMinimized"}],class:{
        'side-bar-item-expandable__arrow': true,
        'side-bar-item-expandable__arrow--disabled': _setup.isDisabled,
        'side-bar-item-expandable__arrow--active': _setup.isActive
      }},[(_vm.isExpanded)?_c(_setup.V3Icon,{staticClass:"side-bar-item-expandable__arrow-up-icon",attrs:{"icon":"side-bar-arrow-up","width":"10","height":"6.59"}}):_c(_setup.V3Icon,{staticClass:"side-bar-item-expandable__arrow-down-icon",attrs:{"icon":"side-bar-arrow-down","width":"10","height":"6.59"}})],1)],1),_c(_setup.V3SlideDownTransition,[(_vm.isExpanded)?_c('div',{attrs:{"data-cy":"side-bar-sub-items"}},_vm._l((_vm.subitems),function(item,index){return _c('div',{key:index},[(item.show)?_c(_setup.V3SideBarItemNew,{attrs:{"small":"","show-minimized":_vm.showMinimized,"icon":item.icon,"text":item.text,"data-cy":item.dataCy,"active":_setup.isItemActive(_setup.route, item)},nativeOn:{"click":function($event){return (() => _setup.emits('click-subitem', item)).apply(null, arguments)},"mouseenter":function($event){return (event =>
              item.favoriteSearch &&
              _setup.showSearchBalloon(event, item.favoriteSearch)).apply(null, arguments)},"mouseleave":function($event){item.favoriteSearch && _setup.hideSearchBalloon()}}}):_vm._e()],1)}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }