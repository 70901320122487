<template>
  <g id="visitBars" class="chartVisitBars">
    <g id="normalVisitBars" class="chartVisitBars__group">
      <template v-for="visit in normalVisitOverviews">
        <ChartVisitBar
          v-if="isVisible(visit)"
          :key="visit.gramId"
          :width="width"
          :height="getBarHeight(visit.pvStats.allPv)"
          :y="getBarY(visit.pvStats.allPv)"
          :x="getBarX(visit.date)"
          :fill="normalVisitBarColor(visit.timeOfDay)"
          :show-stroke="showStroke"
          @click="onClick(visit)"
          @mouseenter="onMouseEnter(visit)"
          @mouseleave="onMouseLeave"
        />
      </template>
    </g>
    <g id="cvVisitBars" class="chartVisitBars__group">
      <template v-for="visit in cvVisitOverviews">
        <ChartVisitBar
          v-if="isVisible(visit)"
          :key="visit.gramId"
          :width="width"
          :height="getBarHeight(visit.pvStats.allPv)"
          :y="getBarY(visit.pvStats.allPv)"
          :x="getBarX(visit.date)"
          :fill="cvVisitBarColor(visit.timeOfDay)"
          :show-stroke="showStroke"
          @click="onClick(visit)"
          @mouseenter="onMouseEnter(visit)"
          @mouseleave="onMouseLeave"
        />
      </template>
    </g>
    <g
      v-if="!isToggleTimeOfDayEnabled"
      id="funnelMatchedVisitBars"
      class="chartVisitBars__group"
    >
      <template v-for="visit in funnelMatchedVisitOverviews">
        <ChartVisitBar
          v-if="isVisible(visit)"
          :key="visit.gramId"
          :width="width"
          :height="getBarHeight(visit.pvStats.allPv)"
          :y="getBarY(visit.pvStats.allPv)"
          :x="getBarX(visit.date)"
          :fill="colors.SemiHighlightedBar"
          :show-stroke="showStroke"
          @click="onClick(visit)"
          @mouseenter="onMouseEnter(visit)"
          @mouseleave="onMouseLeave"
        />
      </template>
    </g>
    <g
      v-if="!isToggleTimeOfDayEnabled"
      id="funnelMatchedSelectedVisitBars"
      class="chartVisitBars__group"
    >
      <template v-for="visit in funnelMatchedSelectedVisitOverviews">
        <ChartVisitBar
          v-if="isVisible(visit)"
          :key="visit.gramId"
          :width="width"
          :height="getBarHeight(visit.pvStats.allPv)"
          :y="getBarY(visit.pvStats.allPv)"
          :x="getBarX(visit.date)"
          :fill="colors.HighlightedBar"
          :show-stroke="showStroke"
          @click="onClick(visit)"
          @mouseenter="onMouseEnter(visit)"
          @mouseleave="onMouseLeave"
        />
      </template>
    </g>
    <g
      v-if="!isToggleTimeOfDayEnabled"
      id="selectedCvVisitBars"
      class="chartVisitBars__group"
    >
      <template v-for="visit in selectedCvVisitOverviews">
        <ChartVisitBar
          v-if="isVisible(visit)"
          :key="visit.gramId"
          :width="width"
          :height="getBarHeight(visit.pvStats.allPv)"
          :y="getBarY(visit.pvStats.allPv)"
          :x="getBarX(visit.date)"
          :fill="colors.HighlightedBar"
          :show-stroke="showStroke"
          @click="onSelectedCvVisitOverviewClick(visit)"
          @mouseenter="onMouseEnter(visit)"
          @mouseleave="onMouseLeave"
        />
      </template>
    </g>
    <g id="filterMatchVisitBars" class="chartVisitBars__group">
      <template v-for="visit in filterMatchVisitOverviews">
        <ChartVisitBar
          v-if="isVisible(visit)"
          :key="visit.gramId"
          :width="width"
          :height="getBarHeight(visit.pvStats.allPv)"
          :y="getBarY(visit.pvStats.allPv)"
          :x="getBarX(visit.date)"
          :fill="colors.Base900"
          :show-stroke="showStroke"
          @click="onClick(visit)"
          @mouseenter="onMouseEnter(visit)"
          @mouseleave="onMouseLeave"
        />
      </template>
    </g>
  </g>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import {
  VisitOverview,
  VisitConversion
} from "@/models/overview/VisitOverview";
import { Colors } from "@/const/Colors";
import {
  isInList,
  getBarHeight,
  getXAdjustWidth,
  convertTimeToX
} from "@/components/chart/chart-util";
import ChartVisitBar from "@/components/chart/ChartVisitBar.vue";
import { VisitTimeFrame } from "@/api/apis/ApiVisits";

@Component({
  components: { ChartVisitBar }
})
export default class ChartVisitBars extends Vue {
  @Prop({ type: Array, required: true })
  visitOverviews!: VisitOverview[];

  @Prop({ type: Array, required: true })
  filterMatchedVisitIds!: string[];

  @Prop({ type: Array, required: true })
  almostMatchedVisitIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  @Prop({ type: Array, required: true })
  selectedConversionIds!: number[];

  @Prop({ type: Number, required: true })
  baselineY!: number;

  @Prop({ type: Number, required: true })
  baselineLength!: number;

  @Prop({ type: Date, required: true })
  firstScaleDate!: Date;

  @Prop({ type: Date, required: true })
  lastScaleDate!: Date;

  @Prop({ type: Number, required: true })
  lastScaleX!: number;

  @Prop({ type: Number, required: true })
  width!: number;

  @Prop({ type: Boolean, required: true })
  showStroke!: boolean;

  @Prop({ type: Boolean, required: true })
  isToggleTimeOfDayEnabled!: boolean;

  @Prop({ type: Number, required: false, default: 0 })
  scrollX!: number;

  @Prop({ type: Number, required: false })
  scrollWidth!: number;

  @Prop({ type: Boolean, required: false, default: true })
  isChartScrollable!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  isInUserDetail!: boolean;

  onClick(overview: VisitOverview) {
    this.$emit("click", overview);
  }

  onMouseEnter(overview: VisitOverview) {
    this.$emit("mouseenter", overview);
  }

  @Emit("mouseleave")
  onMouseLeave() {}

  colors = Colors;

  normalVisitOverviews: VisitOverview[] = [];
  cvVisitOverviews: VisitOverview[] = [];
  filterMatchVisitOverviews: VisitOverview[] = [];
  funnelMatchedVisitOverviews: VisitOverview[] = [];
  funnelMatchedSelectedVisitOverviews: VisitOverview[] = [];

  get selectedCvVisitOverviews(): VisitOverview[] {
    const overviews = this.cvVisitOverviews
      .concat(this.funnelMatchedVisitOverviews)
      .concat(this.funnelMatchedSelectedVisitOverviews);
    return overviews.filter(o => this.isInSelectedConversion(o.conversions));
  }

  get isFunnelUsed(): boolean {
    if (this.funnelMatchedGramIds.length > 0) return true;

    return this.funnelMatchedSelectedGramIds.length > 0;
  }

  created() {
    this.fetchVisitBarData();
  }

  // We want to display the data in a fixed order, so we store the visitOverview in each data and draw it using
  // Use <g> to draw it.
  fetchVisitBarData() {
    for (const visitOverview of this.visitOverviews) {
      if (
        isInList(visitOverview, this.filterMatchedVisitIds) ||
        isInList(visitOverview, this.almostMatchedVisitIds)
      ) {
        this.filterMatchVisitOverviews.push(visitOverview);
      } else if (visitOverview.hasConversion) {
        this.cvVisitOverviews.push(visitOverview);
      } else {
        this.normalVisitOverviews.push(visitOverview);
      }
      if (this.isFunnelUsed) {
        if (isInList(visitOverview, this.funnelMatchedSelectedGramIds)) {
          this.funnelMatchedSelectedVisitOverviews.push(visitOverview);
        } else if (isInList(visitOverview, this.funnelMatchedGramIds)) {
          this.funnelMatchedVisitOverviews.push(visitOverview);
        }
      }
    }
  }

  getBarHeight(allPv: number): number {
    return getBarHeight(allPv);
  }

  getBarY(allPv: number): number {
    return this.baselineY - this.getBarHeight(allPv);
  }

  getBarX(date: Date): number {
    return getXAdjustWidth(
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        date.getTime()
      ),
      this.width
    );
  }

  isInSelectedConversion(conversions: VisitConversion[]): boolean {
    return conversions.some(c =>
      this.selectedConversionIds.some(id => id === c.id)
    );
  }

  onSelectedCvVisitOverviewClick(overview: VisitOverview) {
    this.onClick(overview);
    UgTag.pushEvent(UgEventTag.CvEmphasisDetail);
  }

  normalVisitBarColor(timeofDay: VisitTimeFrame): Colors {
    if (this.isToggleTimeOfDayEnabled) {
      switch (timeofDay) {
        case VisitTimeFrame.Morning:
          return this.colors.MorningVisitWithOutCv;
        case VisitTimeFrame.Day:
          return this.colors.DayVisitWithOutCv;
        case VisitTimeFrame.Night:
          return this.colors.NightVisitWithOutCv;
      }
    }

    return this.colors.Base500;
  }

  cvVisitBarColor(timeofDay: VisitTimeFrame): Colors {
    if (this.isToggleTimeOfDayEnabled) {
      switch (timeofDay) {
        case VisitTimeFrame.Morning:
          return this.colors.MorningVisitWithCv;
        case VisitTimeFrame.Day:
          return this.colors.DayVisitWithCv;
        case VisitTimeFrame.Night:
          return this.colors.NightVisitWithCv;
      }
    }

    return this.colors.Cv;
  }

  isVisible(visitOverview: VisitOverview): boolean {
    if (!this.isChartScrollable || this.isInUserDetail) return true;
    return (
      this.scrollWidth !== null &&
      this.getBarX(visitOverview.date) > this.scrollX - this.width &&
      this.getBarX(visitOverview.date) < this.scrollX + this.scrollWidth
    );
  }
}
</script>
