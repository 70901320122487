import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { MfaConfig } from "@/models/mfa/MfaConfig";
import Axios from "axios";

export enum ErrorType {
  Unverified,
  Other
}

export class MfaState {
  config: MfaConfig | undefined = undefined;
  verified: boolean = false;
  errorType?: ErrorType = undefined;
}

export const mutations = <MutationTree<MfaState>>{
  setConfig(state, config: MfaConfig | undefined = undefined) {
    state.config = config;
  },
  setVerified(state, isVerified: boolean) {
    state.verified = isVerified;
  },
  setErrorType(state, errorType: ErrorType) {
    state.errorType = errorType;
  }
};

export const actions = <ActionTree<MfaState, RootState>>{
  async configure({ commit, rootState }) {
    const response = await rootState.api.mfa.configure();
    const config: MfaConfig = MfaConfig.build(response);
    commit("setConfig", config);
  },
  async enable({ commit, rootState }, otpCode: string) {
    try {
      const response = await rootState.api.mfa.enable(
        rootState.mfa.config!.secretKey,
        otpCode
      );
      if ("verified" in response && response.verified === true) {
        commit("setVerified", true);
        commit("setErrorType", undefined);
      } else {
        throw new Error();
      }
    } catch (error) {
      if (
        Axios.isAxiosError(error) &&
        error.response &&
        error.response.status === 400
      ) {
        commit("setErrorType", ErrorType.Unverified);
      } else {
        commit("setErrorType", ErrorType.Other);
      }
    }
  },

  async verify({ commit, rootState }, otpCode: string) {
    try {
      const response = await rootState.api.mfa.verify(otpCode);
      if (response.verified) {
        commit("setVerified", true);
        commit("setErrorType", undefined);
      } else {
        throw new Error();
      }
    } catch (error) {
      if (Axios.isAxiosError(error) && error.response) {
        commit("setVerified", false);
        switch (error.response.status) {
          case 400:
            break;
          case 401:
            commit("setErrorType", ErrorType.Unverified);
            break;
          default:
            commit("setErrorType", ErrorType.Other);
        }
      }
    }
  }
};

export const mfa = {
  namespaced: true,
  state: new MfaState(),
  mutations: mutations,
  actions: actions
};
