<template>
  <div
    class="chart-contact-icon"
    data-cy="chart-contact-icon"
    @click="onClick"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <Icon :icon="contactIconType" :size="iconSize" :color="color" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { CONTACT_ICON_SIZE } from "@/components/chart/chart-util";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class ChartContactIcon extends Vue {
  @Prop({ type: String, required: true })
  contactIconType!: Extract<Icons, "tel" | "shop" | "mail">;

  @Prop({ type: String, required: true })
  color!: Colors;

  @Emit("click")
  onClick() {}

  @Emit("mouse-over")
  onMouseOver() {}

  @Emit("mouse-leave")
  onMouseLeave() {}

  iconSize = CONTACT_ICON_SIZE;
}
</script>

<style scoped lang="scss">
.chart-contact-icon {
  display: flex;
  align-items: center;
  transition: transform 0.05s ease-in-out;
}

.chart-contact-icon:hover {
  transform: scale(1.2);
}
</style>
