<template>
  <FilterBaseInputTextMatchType
    :value="value"
    :match-method="matchMethod"
    class="filter-referrer-url-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterReferrerUrlCondition } from "@/models/search/filter-node-condition/FilterReferrerUrlCondition";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterReferrerUrlField extends Vue {
  @Model("input", { type: FilterReferrerUrlCondition, required: true })
  condition!: FilterReferrerUrlCondition;

  onInput(condition: FilterReferrerUrlCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.referrerUrl;
  }

  get matchMethod(): MatchMethod {
    return this.condition.matchMethod;
  }

  onInputText(value: string) {
    this.onInput(
      new FilterReferrerUrlCondition(value, this.condition.matchMethod)
    );
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(
      new FilterReferrerUrlCondition(this.condition.referrerUrl, matchMethod)
    );
  }
}
</script>
