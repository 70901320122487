<template>
  <component :is="layout()" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import MfaLayout from "@/layouts/MfaLayout.vue";

@Component({
  components: {
    DefaultLayout,
    SettingsLayout,
    MfaLayout
  }
})
export default class App extends Vue {
  layout() {
    return this.$route.meta?.layout
      ? `${this.$route.meta.layout}Layout`
      : "DefaultLayout";
  }
}
</script>
