import { auth } from "@/store/modules/auth";
import { GtmTag } from "@/store/modules/gtmTag";

export enum UgConversionTag {
  Login = "login",
  ObservationOpen = "observation_open",
  ObservationClosed = "observation_closed"
}

export enum UgEventTag {
  // Almost CV
  AlmostCv = "almost_cv",
  AlmostCvSearchPanel = "almost_cv_search_panel",

  // Clustering
  SelectClustering = "pattern_analytics",

  // Filter
  Filter = "filter",
  FilterSelectSuggestion = "filter_select_suggestion",

  // Home
  SortHomePanels = "sort_home_panels",

  // Lag CV
  LagCvYesterday = "lag_cv_yesterday",
  LagCvOneWeek = "lag_cv_one_week",
  LagCvFourWeeks = "lag_cv_four_weeks",
  LagCvSearchPanel = "lag_cv_search_panel",

  // Search
  Search = "select",
  RepeatSearch = "repeat_search",
  ClickFavoriteSearchPanel = "click_favorite_search_panel",

  // Search result page buttons
  DownloadCsv = "csv_download",
  AddFavoriteUser = "favorite",
  UpdateMemo = "memo",
  ChangeAttributeColumn = "col_order",
  CopyPermalink = "link_copy",
  CvEmphasis = "cv_emphasis",
  CvEmphasisDetail = "cv_emphasis_detail",
  TimeModeEmphasis = "time_mode_emphasis",
  // Segmented Trend
  SegmentedTrendDisplay = "segmented_trend_display",
  SegmentedTrendCsv = "segmented_trend_csv",

  // User Details
  ShowWordcloud = "wordcloud_show",
  WebPreview = "web_preview",
  VisitSummary = "visit_summary",
  UserListBalloon = "user_list_balloon", // ユーザ属性ツールチップ

  // User Trend
  UserTrendCondition = "user_trend_condition",
  UserTrendCsv = "user_trend_csv",
  UserTrendFilter = "user_trend_filter",
  UserTrendToUserList = "user_trend_to_user_list",

  // Conversion Trend
  ConversionTrend = "conversion_trend",

  // Funnel Analysis
  FunnelToUserList = "funnel_user_list",
  FunnelUrlShare = "funnel_url_share",
  FunnelSelectSuggestion = "funnel_select_suggestion",

  // Others
  AlertDisplayed = "alert_displayed",
  BlogLinkButton = "blog_dynamic_link",

  // Tour
  TourCompleteButton = "tour_complete_button_clicked",
  TourObservation = "observation_from_tour",
  TourMoreThan5Users = "tour_more_than_5_users"
}

export enum UgAttributeTag {
  UserListType = "prop11",
  FunnelOrder = "prop12",
  SearchType = "prop13", // 検索方式
  UserListDisplayMode = "prop14", // ユーザ一覧表示形式
  Condition = "prop15",
  NumberOfNodes = "prop16",
  StayTimeObservationOpenCloseSec = "prop17",
  UrlOfTourDetail = "prop22",
  UserObservedViaTour = "prop23",
  TourTitle = "prop24",
  FunnelUrlParams = "prop26",
  SuggestionDetail = "prop27"
}

type AdditionalAttributes = {
  [attributeQuery in UgAttributeTag]?: string | number;
};

export class UgTag {
  public static pushPv(replacedTitle?: string) {
    try {
      // クライアントパラメータを取得
      const masterId: string = getMasterId();

      const ugAttr: { [key: string]: string } = {};
      // 変更するタイトルがあればセットする
      if (replacedTitle) {
        ugAttr["documentTitle"] = replacedTitle;
      }
      // ログイン済みの場合、サービスIDをセットする
      if (auth.state.isAuthenticated && auth.state.user) {
        ugAttr["serviceId"] = auth.state.user.loginName;
      }

      // PVデータを送信する
      const usergram = (window as any).usergram || [];
      usergram.push(["send", masterId, "pv", ugAttr]);

      // Send PV to GTM for GA
      GtmTag.pushPv(replacedTitle);
    } catch (e) {
      // エラーは無視する
    }
  }

  public static pushEvent(
    eventParam: UgEventTag,
    additionalAttributes: AdditionalAttributes = {}
  ) {
    try {
      // クライアントパラメータを取得
      const masterId: string = getMasterId();

      // ログイン済みの場合、サービスIDをセットする
      const ugAttr: { [key: string]: string | number | undefined } = {
        ...additionalAttributes
      };
      if (auth.state.isAuthenticated && auth.state.user) {
        ugAttr["serviceId"] = auth.state.user.loginName;
      }

      // Eventデータを送信する
      const usergram = (window as any).usergram || [];
      usergram.push(["send", masterId, "event", eventParam, ugAttr]);
    } catch (e) {
      // エラーは無視する
    }
  }

  public static pushCv(
    cvParam: UgConversionTag,
    additionalAttributes: AdditionalAttributes = {}
  ) {
    try {
      // クライアントパラメータを取得
      const masterId: string = getMasterId();

      // ログイン済みの場合、サービスIDをセットする
      const ugAttr: { [key: string]: string | number | undefined } = {
        ...additionalAttributes
      };
      if (auth.state.isAuthenticated && auth.state.user) {
        ugAttr["serviceId"] = auth.state.user.loginName;
      }

      // CVデータを送信する
      const usergram = (window as any).usergram || [];
      usergram.push(["send", masterId, "cv", cvParam, ugAttr]);
    } catch (e) {
      // エラーは無視する
    }
  }
}

function getMasterId() {
  return process.env.VUE_APP_UG_MASTER_ID === undefined
    ? "undifined"
    : process.env.VUE_APP_UG_MASTER_ID;
}
