import { createGlobalState, useLocalStorage } from "@vueuse/core";
import { computed, ComputedRef } from "vue";

export const sideBarStore = createGlobalState(() => {
  const isMinimizedStorage = useLocalStorage<boolean>(
    "IS_SIDE_BAR_MINIMIZED",
    true
  );
  const isAnalysisExpandedStorage = useLocalStorage<boolean>(
    "IS_SIDE_BAR_ANALYSIS_EXPANDED_V2",
    true
  );
  const isPinExpandedStorage = useLocalStorage<boolean>(
    "IS_SIDE_BAR_PIN_EXPANDED",
    false
  );

  const isSideBarMinimized: ComputedRef<boolean> = computed(
    () => isMinimizedStorage.value
  );

  const toggleIsSideBarMinimized = () => {
    isMinimizedStorage.value = !isSideBarMinimized.value;
  };

  const isAnalysisExpanded: ComputedRef<boolean> = computed(
    () => isAnalysisExpandedStorage.value
  );

  const setIsAnalysisExpanded = (isExpanded: boolean) => {
    isAnalysisExpandedStorage.value = isExpanded;
  };

  const isPinExpanded: ComputedRef<boolean> = computed(
    () => isPinExpandedStorage.value
  );

  const setIsPinExpanded = (isExpanded: boolean) => {
    isPinExpandedStorage.value = isExpanded;
  };

  return {
    isSideBarMinimized,
    toggleIsSideBarMinimized,
    isAnalysisExpanded,
    setIsAnalysisExpanded,
    isPinExpanded,
    setIsPinExpanded
  };
});
