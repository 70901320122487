import { Item } from "@/types/components/V3SideBar.type";
import { Route } from "vue-router";

export function isItemActive(route: Route, item: Item): boolean {
  return (
    route.name === item.routeName ||
    route.path === item.url ||
    (item.url ? route.path.startsWith(item.url) : false)
  );
}
