<i18n src="@/i18n/views/mfa-setup"></i18n>

<template>
  <div class="mfaSetup" data-cy="mfa-setup">
    <h1 class="mfaSetup__title">{{ $t("title") }}</h1>
    <p class="mfaSetup__subtitle">{{ $t("subtitle") }}</p>
    <SetupStep :step-number="1" :title="$t('stepTitle1')" :show-arrow="true">
      <div class="mfaSetup__step1">
        {{ $t("googleAuthenticator") }}
        (<a
          class="mfaSetup__link"
          :href="iosLink"
          target="_blank"
          rel="noopener"
          >IOS</a
        >
        |
        <a
          class="mfaSetup__link"
          :href="androidLink"
          target="_blank"
          rel="noopener"
          >Android</a
        >) [{{ $t("recommendation") }}]
        <br />
        {{ $t("otherApps") }}
      </div>
    </SetupStep>
    <SetupStep :step-number="2" :title="$t('stepTitle2')" :show-arrow="true">
      <div v-if="mfaConfig" class="mfaSetup__step2">
        <div class="mfaSetup__step2Codes">
          <QRCode data-cy="mfa-setup-qr-code" :uri="mfaConfig.otpAuthUri" />
          <span class="mfaSetup__step2Or">OR</span>
          <span class="mfaSetup__step2KeyLabel">{{ $t("keyLabel") }}</span>
          <span class="mfaSetup__step2KeyValue" data-cy="mfa-setup-secret-key">
            {{ mfaConfig.secretKey }}
          </span>
        </div>
        <span class="mfaSetup__step2Note">{{ $t("step2SubTitle") }}</span>
      </div>
    </SetupStep>
    <SetupStep :step-number="3" :title="$t('stepTitle3')">
      <div class="mfaSetup__step3">
        <OtpForm v-model="otpCode" @submit="verifyOtpCode" />
      </div>
    </SetupStep>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { MfaConfig } from "@/models/mfa/MfaConfig";
import QRCode from "@/components/QRCode.vue";
import SetupStep from "@/components/mfa/SetupStep.vue";
import OtpForm from "@/components/mfa/OtpForm.vue";
import UsergramLogo from "@/assets/svg/logo/usergram-logo.svg";
import InputText from "@/components/form/InputText.vue";
import Button from "@/components/Button.vue";
import { ErrorType } from "@/store/modules/mfa";
import { handleError, handleMfaVerificationError } from "@/util/error-util";

@Component({
  components: {
    QRCode,
    UsergramLogo,
    SetupStep,
    InputText,
    Button,
    OtpForm
  }
})
export default class MfaSetup extends Vue {
  iosLink = "https://apps.apple.com/jp/app/id388497605";
  androidLink =
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";
  submitButtonWidth = "354px";
  submitButtonHeight = "35px";

  otpCode: string = "";

  async created() {
    await this.configureMfa();
  }

  get mfaConfig(): MfaConfig {
    return this.$store.state.mfa.config;
  }

  get isMfaVerified(): boolean {
    return this.$store.state.mfa.verified;
  }

  get errorType(): ErrorType | undefined {
    return this.$store.state.mfa.errorType;
  }

  async configureMfa() {
    await this.$store.dispatch("mfa/configure");
  }

  async verifyOtpCode(otpCode: string) {
    this.otpCode = otpCode;
    await this.$store.dispatch("mfa/enable", this.otpCode);
    if (this.isMfaVerified) {
      await this.$store.dispatch("app/initializePostLogin");
      if (this.$route.query.redirect) {
        location.assign(this.$route.query.redirect as string);
      } else {
        location.assign("/");
      }
      return;
    }

    switch (this.errorType) {
      case ErrorType.Unverified:
        handleMfaVerificationError();
        this.otpCode = "";
        break;
      case ErrorType.Other:
        handleError({});
    }
  }
}
</script>

<style lang="scss" scoped>
.mfaSetup {
  padding-top: 60px;
  padding-bottom: 80px;
  max-width: 980px;
  margin: 0 auto;
}

.mfaSetup__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: $colorBase900;
  text-align: center;
}

.mfaSetup__subtitle {
  margin: 40px 0;
  font-size: 14px;
  color: $colorBase900;
  text-align: center;
  line-height: 1.4;
}

.mfaSetup__step1 {
  margin-left: 122px;
  font-size: 14px;
  line-height: 1.5;
}

.mfaSetup__link {
  color: $colorBlue1000;
}

.mfaSetup__step2 {
  display: flex;
  flex-direction: column;
  margin-left: 117px;
}

.mfaSetup__step2Codes {
  display: flex;
  align-items: center;
}

.mfaSetup__step2Or {
  margin: 0 40px;
  color: $colorBase900;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

.mfaSetup__step2KeyLabel {
  color: $colorBase900;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.mfaSetup__step2KeyValue {
  color: $colorBase900;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.mfaSetup__step2Note {
  color: $colorBase900;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.mfaSetup__step3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
