<template>
  <div class="tourIndexNotificationItem">
    <div class="tourIndexNotificationItem__number">
      {{ number }}
    </div>
    <div class="tourIndexNotificationItem__text">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TourIndexNotificationItem extends Vue {
  @Prop({ type: String, default: "1" })
  number!: string;

  @Prop({ type: String, required: true })
  text!: string;
}
</script>

<style lang="scss" scoped>
.tourIndexNotificationItem {
  display: flex;
  align-items: center;
}

.tourIndexNotificationItem__number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $colorBlue800;
  color: $colorWhite;
  font-weight: 700;
  font-size: 11px;
  line-height: 1;
}

.tourIndexNotificationItem__text {
  margin-left: 10px;
  width: 230px;
  color: $colorBase700;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
}
</style>
