import { FilterReferrerUrlCondition } from "@/models/search/filter-node-condition/FilterReferrerUrlCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import {
  FilterLandingPageTitleCondition,
  convertJsonToFilterLandingPageTitleCondition
} from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ChildFilterNodeParamForReferrer } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import {
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * リファラURL、入口ページURL、入口ページタイトルによる絞り込み条件
 *
 * リファラURL、入口ページURL、入口ページタイトルいずれかの入力が必須で、条件にあうユーザを絞り込む
 * FilterByInflowConditionに子になるので、edgeは持たない
 * （edgeはFilterByInflowConditionが保持する）
 *
 * 条件として3つすべてを追加することもできる
 * リファラURL、入口ページURLは完全一致or部分一致で、入口ページタイトルは部分一致を指定できる
 *
 * 初回、期間、日付と時間帯 を条件として追加できる
 */
export class ChildFilterNodeForReferrer {
  constructor(
    /**
     * 少なくとも、リファラURLか、ページURLか、ページタイトルの条件いずれかの保持が必須
     */
    public readonly condition:
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition,
    /**
     * 追加の検索条件
     *
     * リファラURL、入口ページURL、入口ページタイトル、初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterAdditionalTimingCondition
    )[],
    public readonly parentDepth: number | null = 0
  ) {}

  get validate(): ValidationResult {
    return validateConditions(
      this.additionalConditions,
      this.condition.validate
    );
  }

  get isConditionAddable(): boolean {
    return this.additionalConditions.length < 2;
  }

  get hasReferrerUrlCondition(): boolean {
    return (
      this.condition instanceof FilterReferrerUrlCondition ||
      this.additionalConditions.some(
        cnd => cnd instanceof FilterReferrerUrlCondition
      )
    );
  }

  get hasLandingPageUrlCondition(): boolean {
    return (
      this.condition instanceof FilterLandingPageUrlCondition ||
      this.additionalConditions.some(
        cnd => cnd instanceof FilterLandingPageUrlCondition
      )
    );
  }

  get hasLandingPageTitleCondition(): boolean {
    return (
      this.condition instanceof FilterLandingPageTitleCondition ||
      this.additionalConditions.some(
        cnd => cnd instanceof FilterLandingPageTitleCondition
      )
    );
  }

  /**
   * isConditionがtrueならadditionalConditionsではなく、
   * conditionで表示される方のなので、日付系の選択肢はdisable
   */
  selectOption(
    currentCondition: FilterAdditionalCondition,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.ReferrerUrl,
            label: i18n.t("models.search.referrerUrl") as string,
            disabled:
              this.hasReferrerUrlCondition &&
              !(currentCondition instanceof FilterReferrerUrlCondition)
          },
          {
            value: FilterNodeConditionType.LandingPageUrl,
            label: i18n.t("models.search.entryPageUrl") as string,
            disabled:
              this.hasLandingPageUrlCondition &&
              !(currentCondition instanceof FilterLandingPageUrlCondition)
          },
          {
            value: FilterNodeConditionType.LandingPageTitle,
            label: i18n.t("models.search.entryPageTitle") as string,
            disabled:
              this.hasLandingPageTitleCondition &&
              !(currentCondition instanceof FilterLandingPageTitleCondition)
          }
        ]
      }
    ];

    if (this.parentDepth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }
}

export function convertChildFilterNodeForReferrerToJson(
  node: ChildFilterNodeForReferrer
): ChildFilterNodeParamForReferrer {
  // edgeは別の場所でつける
  const result: ChildFilterNodeParamForReferrer = {
    activity_edge: null,
    activity_type: ActivityEdgeType.Referrer
  };

  const conditions: (
    | FilterReferrerUrlCondition
    | FilterLandingPageUrlCondition
    | FilterLandingPageTitleCondition
    | FilterAdditionalTimingCondition
  )[] = node.additionalConditions.concat([node.condition]);

  for (const condition of conditions) {
    if (condition instanceof FilterReferrerUrlCondition) {
      result.external_site_inflow_url = {
        url: condition.referrerUrl,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterLandingPageUrlCondition) {
      result.entrance_url = {
        url: condition.pageUrl,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterLandingPageTitleCondition) {
      result.entrance_title = {
        title: condition.pageTitle,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForReferrer(
  json: ChildFilterNodeParamForReferrer
) {
  let condition!:
    | FilterReferrerUrlCondition
    | FilterLandingPageUrlCondition
    | FilterLandingPageTitleCondition;
  const additionalCondition: (
    | FilterReferrerUrlCondition
    | FilterLandingPageUrlCondition
    | FilterLandingPageTitleCondition
    | FilterFirstTimeCondition
    | FilterPeriodCondition
    | FilterDateHourCondition
  )[] = getAdditionalTimingConditions(json);

  // 複数の条件がある場合は、entrance_title, entrance_url, external_site_inflow_urlの順で主条件とする
  if (json.entrance_title) {
    condition = convertJsonToFilterLandingPageTitleCondition(
      json.entrance_title
    );
    if (json.entrance_url) {
      additionalCondition.push(
        new FilterLandingPageUrlCondition(
          json.entrance_url.url,
          json.entrance_url.word_match_method
        )
      );
    }
    if (json.external_site_inflow_url) {
      additionalCondition.push(
        new FilterReferrerUrlCondition(
          json.external_site_inflow_url.url,
          json.external_site_inflow_url.word_match_method
        )
      );
    }
  } else if (json.entrance_url) {
    condition = new FilterLandingPageUrlCondition(
      json.entrance_url.url,
      json.entrance_url.word_match_method
    );
    if (json.external_site_inflow_url) {
      additionalCondition.push(
        new FilterReferrerUrlCondition(
          json.external_site_inflow_url.url,
          json.external_site_inflow_url.word_match_method
        )
      );
    }
  } else if (json.external_site_inflow_url) {
    condition = new FilterReferrerUrlCondition(
      json.external_site_inflow_url.url,
      json.external_site_inflow_url.word_match_method
    );
  }
  return new ChildFilterNodeForReferrer(condition, additionalCondition);
}
