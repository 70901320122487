<i18n
  src="@/i18n/components/user-trend/form/user-trend-filter-input.json"
></i18n>
<template>
  <div class="userTrendFilterInput">
    <InputText
      :value="condition.value"
      :style="style"
      :placeholder="$t('requiredInput')"
      @input="onInputText"
      @keydown-enter="onEnter"
    />

    <SelectBox
      :value="condition.matchMethod"
      :options="selectOptions"
      width="140px"
      class="userTrendFilterInput__select"
      @input="onInputMatchMethod"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { MatchMethod } from "@/models/search/MatchMethod";
import { UserTrendFilterCondition } from "@/models/user-trend/UserTrendFilterCondition";
import InputText from "@/components/form/InputText.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";

@Component({
  components: {
    InputText,
    SelectBox
  }
})
export default class UserTrendFilterInput extends Vue {
  @Prop({ type: UserTrendFilterCondition, required: true })
  condition!: UserTrendFilterCondition;

  @Prop({ type: String, default: "395px" })
  inputWidth!: string;

  onInput(condition: UserTrendFilterCondition) {
    this.$emit("input", condition);
  }

  @Emit("enter")
  onEnter() {}

  get style() {
    return {
      width: this.inputWidth
    };
  }

  get selectOptions(): SelectOption[] {
    return [
      {
        label: this.$i18n.t("partialMatch") as string,
        value: MatchMethod.Partial,
        disabled: false
      },
      {
        label: this.$i18n.t("perfectMatch") as string,
        value: MatchMethod.Exact,
        disabled: false
      },
      {
        label: this.$i18n.t("prefixMatch") as string,
        value: MatchMethod.Prefix,
        disabled: false
      },
      {
        label: this.$i18n.t("postfixMatch") as string,
        value: MatchMethod.Postfix,
        disabled: false
      }
    ];
  }

  onInputText(value: string) {
    this.condition.setValue(value);
    this.onInput(this.condition);
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.condition.setMethod(matchMethod);
    this.onInput(this.condition);
  }
}
</script>

<style scoped lang="scss">
.userTrendFilterInput {
  display: flex;
}

.userTrendFilterInput__select {
  margin-left: 7px;
}
</style>
