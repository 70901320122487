import { ApiRes } from "@/api/api-res";

/**
 * ユーザ属性
 *
 * ユーザ属性は、年齢や性別などクライアントごとに定義できるユーザ属性です。
 * クライアントがUGタグで収集。
 */

export enum UserAttributeDefinitionType {
  // 入力される値がテキスト
  TEXT = 1,
  // 入力される値が数字
  NUMBER = 2
}

export class UserAttributeDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    // true = インポート中, false = インポート停止
    public readonly isDisabled: boolean,
    // ユーザ属性の値の型
    public readonly type: UserAttributeDefinitionType
  ) {}

  public static fromJson(json: ApiRes.UserAttrDef): UserAttributeDefinition {
    return new UserAttributeDefinition(
      json.id,
      json.name,
      json.is_disabled,
      json.format_type
    );
  }
}
