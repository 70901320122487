<template>
  <FilterBaseSelectBox
    v-model="value"
    :options="options"
    class="filter-search-engine-form"
  />
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import { SearchEngine } from "@/models/system/SearchEngine";
import { FilterSearchEngineCondition } from "@/models/search/filter-node-condition/FilterSearchEngineCondition";

import SelectOption from "@/components/form/SelectOption";
import FilterBaseSelectBox from "@/components/filter/form/FilterBaseSelectBox.vue";

@Component({
  components: {
    FilterBaseSelectBox
  }
})
export default class FilterSearchEngineField extends Vue {
  @Model("input", { type: FilterSearchEngineCondition, required: true })
  condition!: FilterSearchEngineCondition;

  @Prop({ type: Array, required: true })
  searchEngines!: SearchEngine[];

  onInput(condition: FilterSearchEngineCondition) {
    this.$emit("input", condition);
  }

  get options(): SelectOption[] {
    return this.searchEngines.map(engine => {
      return { label: engine.name, value: engine.id, disabled: false };
    });
  }

  get value(): number {
    return this.condition.searchEngineId;
  }
  set value(searchEngineId: number) {
    this.onInput(new FilterSearchEngineCondition(Number(searchEngineId)));
  }
}
</script>
