import { AttributeType, NumberMatchMethod } from "@/api/apis/ApiSearch";
import { ConversionAttributeCondition } from "@/models/search/additional-condition/ConversionAttributeCondition";

export abstract class ConversionAttributeNumberCondition extends ConversionAttributeCondition {
  constructor(
    id: number,
    public readonly numberMatchMethod: NumberMatchMethod
  ) {
    super(id, AttributeType.NUMBER);
  }
}
