// 表示の切り替え
export enum UserTrendViewType {
  Table = "table",
  Scatter = "scatter"
}

// tab type
export enum AnalysisType {
  WebView = "web-views",
  Landing = "landings",
  Inflow = "inflows",
  InflowDetail = "inflow-details",
  AppView = "app-views"
}

// メインのデータか比較用データかを区別する
export enum UserTrendDataType {
  base = "base",
  comparison = "comparison"
}

export enum UserTrendColumnType {
  count = "count",
  visit = "visit",
  uniqueUsers = "uniqueUsers",
  cvInVisit = "cvInVisit",
  cvRatioInVisit = "cvRatioInVisit"
}

// Table の表示列数
export enum UserTrendSelectType {
  row20 = 20,
  row50 = 50,
  row100 = 100,
  row200 = 200,
  row500 = 500
}

// Scatter の X軸項目
export enum UserTrendScatterXAxisType {
  count = "count",
  visit = "visit",
  uu = "uu"
}

// Scatter の Y軸項目
export enum UserTrendScatterYAxisType {
  cvInVisit = "cvInVisit",
  cvRatioInVisit = "cvRatioInVisit"
}

// 散布図のtooltipの固定サイズ
export const TOOLTIP_WIDTH = 340;
export const TOOLTIP_WIDTH_250 = 250;
export const TOOLTIP_HEIGHT = 130;

// 散布図のデータ数の上限
// データ数上限は、バックエンドと合わせて設定している https://bebit-sw.atlassian.net/wiki/spaces/UG/pages/664240148/release+step+plan+for+User+trend+analysis
export const LIMIT_CHART_POINT_NUMBER = 10000;

// 表の行数
// デフォルトでは1ページで 20行 表示させる
export const DEFAULT_PER_PAGE = 20;
