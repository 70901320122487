<i18n src="@/i18n/components/tours/upcoming-tour.json"></i18n>
<template>
  <div class="upcomingTour">
    <div class="upcomingTour__header">
      <img
        class="upcomingTour__image"
        :src="require('@/assets/img/tours/upcoming-tour.png')"
        alt="upcoming tour"
      />
    </div>
    <div class="upcomingTour__body">
      <div v-t="'title'" class="upcomingTour__title" />
      <div v-t="'subTitle'" class="upcomingTour__subTitle" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class UpcomingTour extends Vue {}
</script>

<style scoped lang="scss">
.upcomingTour {
  overflow: hidden; // to apply border radius to top image
  width: 296px;
  height: 426px;
  border-radius: $sizeRadius;
  background: $colorWhite;
}

.upcomingTour__header {
  display: block;
  height: 148px;
}

.upcomingTour__image {
  width: 100%;
  height: 100%;
}

.upcomingTour__body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 262px;
}

.upcomingTour__title {
  color: $colorBase700;
  font-weight: 700;
  font-size: 16px;
}

.upcomingTour__subTitle {
  margin-top: 10px;
  color: $colorBase700;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
}
</style>
