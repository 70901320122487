<template>
  <div class="wrapper" :style="style"></div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

const PC_SCALE = 0.25; // 1/4
const SMARTPHONE_SCALE = 0.5; // 1/2

/**
 * vue-friendly-iframe (https://github.com/officert/vue-friendly-iframe) をベースに作成
 * 普通にsrcにurlセットしていないのは、iframe自体を消さないと、前の画面が残った状態で表示されるため
 * 以下を実現するためにライブラリをそのまま使わず独自実装しています。
 * - scrolling noのセット
 * - PCかスマートフォンかで縮小率を変える
 * - 表示にscaleをセットする関係上、外側(wrapper)と中側(iframe)同時に計算したサイズをセット
 */
@Component
export default class WebPreview extends Vue {
  iFrameElement: HTMLIFrameElement | null = null;
  timer = -1;
  width: string = "25vw";
  height: string = "calc(100vh - 20px)";

  @Prop({ type: String, required: true })
  url!: string;

  @Prop({ type: Boolean, default: true })
  isSmartphone!: boolean;

  @Watch("url")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onUrlChange(newUrl: string, oldUrl: string) {
    // non-blockingのためと、
    // 連続でURLが渡された時に最後の物だけロードするように
    // setTimeoutとclearTimeoutを使う
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.removeIframe();
      this.initIframe();
    }, 200);
  }

  get style() {
    return {
      width: this.width,
      height: this.height
    };
  }

  mounted() {
    this.initIframe();
  }

  removeIframe() {
    while (this.$el.firstChild) {
      this.$el.removeChild(this.$el.firstChild);
    }
  }

  initIframe() {
    // iFrameをそのまま表示させると大きすぎてページ全体が見えないので、縮小して表示する。
    // PCとSmartphoneで縮小率を変える。
    const scale = this.isSmartphone ? SMARTPHONE_SCALE : PC_SCALE;
    const magnification = 1 / scale;
    let style = "transform-origin: 0 0;pointer-events: none;overflow:hidden;";
    style += "transform: scale(" + scale + ");";
    style += "height: calc(" + this.height + " * " + magnification + ");";
    style += "width: calc(" + this.width + " * " + magnification + ");";

    this.iFrameElement = document.createElement("iframe");
    this.iFrameElement.setAttribute("crossorigin", "anonymous");
    this.iFrameElement.setAttribute("target", "_parent");
    this.iFrameElement.setAttribute("style", style);
    this.iFrameElement.setAttribute("scrolling", "no");
    this.iFrameElement.setAttribute("src", this.url);

    this.$el.appendChild(this.iFrameElement);
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  overflow: hidden;
}
</style>
