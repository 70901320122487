import { SegmentedTrendResultJsonData } from "@/api/apis/ApiSegmentedTrends";
import { i18n } from "@/i18n";
import { insertCommaDelimiter } from "@/util/string-util";

interface SegmentedTrendResultPageElement {
  forDisplay(): void;
}

export class SegmentedTrendResultAggregateKey
  implements SegmentedTrendResultPageElement {
  constructor(public readonly key: string) {}

  public forDisplay(): string {
    return this.key.replace(/^http(s?):\/\//i, "");
  }
}

export class SegmentedTrendResultPageTitle
  implements SegmentedTrendResultPageElement {
  DEFAULT_TITLE_FOR_DISPLAY = i18n.t(
    "models.analysisTools.segmentedTrends.segmentedTrendResult.noPageTitle"
  ) as string;

  constructor(public readonly title: string) {}

  public forDisplay(): string {
    if (this.title === "" || this.title === null) {
      return this.DEFAULT_TITLE_FOR_DISPLAY;
    }
    return this.title;
  }
}

export class SegmentedTrendResult {
  constructor(
    public readonly key: SegmentedTrendResultAggregateKey,
    public readonly title: SegmentedTrendResultPageTitle,
    public readonly count: string,
    public readonly pvVisit: string,
    public readonly uniqueUsers: string
  ) {}

  static fromJson(json: SegmentedTrendResultJsonData): SegmentedTrendResult {
    const key = json.aggregate_key === null ? "--" : json.aggregate_key;
    return new SegmentedTrendResult(
      new SegmentedTrendResultAggregateKey(key),
      new SegmentedTrendResultPageTitle(json.page_title),
      insertCommaDelimiter(json.count),
      insertCommaDelimiter(json.pv_visit),
      insertCommaDelimiter(json.unique_users)
    );
  }
}
