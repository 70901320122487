import { SelectConditionInterface } from "@/models/search/select-condition/SelectCondition";
import { SelectByUserIdQuery, SelectQuery } from "@/api/apis/ApiSearch";
import {
  HOURLY_INTERVALS_DEFAULT_VALUE,
  msecToSec,
  Sec,
  SECONDS_IN_DAY
} from "@/util/date-util";
import { MAX_DISPLAY_SPAN_DAYS } from "@/const/app";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";
import { now } from "@/util/date-util";

/**
 * ユーザIDによるユーザ選定条件
 */
export class SelectByUserIdCondition implements SelectConditionInterface {
  constructor(
    // 取得したいユーザのユーザIDの配列
    public readonly userIds: string[],
    // 選定対象期間の終わり
    public readonly endDate: Date | null = null,
    // 選定対象期間の始まり
    public readonly startDate: Date | null = null
  ) {}

  // AdditionalSelectConditionを追加できるか
  public readonly isAdditionalConditionAppendable: boolean = false;

  get validate(): ValidationResult {
    if (this.userIds.length <= 0) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.enterUserId") as string
      };
    }

    return { isValid: true };
  }
}

/**
 * user idで選定する際にselect_method_typeにセットする数字
 * user id選定以外ではselect_method_typeは使用されていない
 */
const SELECT_METHOD_TYPE_USER_ID = 2;

/**
 * ユーザIDによるユーザ選定時のパラメータ作成
 */
export function convertSelectByUserIdConditionToJson(
  condition: SelectByUserIdCondition
): SelectByUserIdQuery {
  // end time は現在日時
  let endTimeSec: Sec = msecToSec(now());
  // start time は現在から表示最大期間日前
  let startTimeSec: Sec = endTimeSec - MAX_DISPLAY_SPAN_DAYS * SECONDS_IN_DAY;

  if (condition.startDate !== null && condition.endDate !== null) {
    startTimeSec = msecToSec(condition.startDate.getTime());
    endTimeSec = msecToSec(condition.endDate.getTime());
  }

  const users = condition.userIds.map(userId => ({
    user_id: userId,
    select_method_type: SELECT_METHOD_TYPE_USER_ID,
    start_time_sec: startTimeSec,
    end_time_sec: endTimeSec,
    utc_time_period: HOURLY_INTERVALS_DEFAULT_VALUE,
    conversion_ids: [],
    sub_cnds: []
  }));

  return { users };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByUserIdCondition(
  query: SelectByUserIdQuery
): SelectByUserIdCondition {
  return new SelectByUserIdCondition(query.users.map(u => u.user_id));
}

/**
 * JSONがユーザID検索のものかどうかを判定する
 */
export function isSelectByUserIdQuery(
  query: SelectQuery
): query is SelectByUserIdQuery {
  return "users" in query;
}
