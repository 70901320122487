<template>
  <div class="linkText">
    <a
      :class="['linkText__link', { 'linkText__link--disabled': disabled }]"
      :href="url"
      target="_blank"
      rel="noopener"
      @mouseover="hover"
      @mouseleave="unhover"
      @click="onClick"
    >
      <Icon
        v-if="showLeftIcon"
        :class="['linkText__leftIcon', 'linkText__leftIcon--' + size]"
        :icon="leftIcon"
        :color="helpLinkColor"
        :size="fontSize"
      />
      <span
        :class="[
          'linkText__text',
          'linkText__text--' + size,
          { 'linkText__text--hovered': hovered && !disabled },
          { 'linkText__text--disabled': disabled }
        ]"
        >{{ linkText }}</span
      >
      <Icon
        v-if="showRightIcon"
        :class="['linkText__rightIcon', 'linkText__rightIcon--' + size]"
        :icon="icons.ExternalLink"
        :color="helpLinkColor"
        :size="fontSize"
      />
    </a>

    <!-- This empty div is needed to to prevent the 'a' tag from spreading widely because of 'display: flex;' css -->
    <div />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import Icon from "@/components/Icon.vue";
import {
  LinkTextSize,
  LINK_TEXT_SMALL_FONT,
  LINK_TEXT_MEDIUM_FONT
} from "@/const/LinkText";

@Component({
  components: {
    Icon
  }
})
export default class LinkText extends Vue {
  @Prop({ type: String, default: LinkTextSize.Medium })
  size!: LinkTextSize;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: String, required: true })
  url!: string;

  @Prop({ type: String, required: true })
  linkText!: string;

  @Prop({ type: String, default: Icons.Help })
  leftIcon!: Icons;

  @Prop({ type: Boolean, default: true })
  showLeftIcon!: boolean;

  @Prop({ type: Boolean, default: true })
  showRightIcon!: boolean;

  @Emit("click")
  onClick() {}

  icons = Icons;
  hovered = false;

  get fontSize(): number {
    return this.size === LinkTextSize.Medium
      ? LINK_TEXT_MEDIUM_FONT
      : LINK_TEXT_SMALL_FONT;
  }

  get helpLinkColor(): String {
    return this.disabled ? Colors.Base500 : Colors.Blue1000;
  }

  hover() {
    this.hovered = true;
  }

  unhover() {
    this.hovered = false;
  }
}
</script>

<style scoped lang="scss">
.linkText {
  display: flex;
}
.linkText__link {
  text-decoration: none;
}

.linkText__link--disabled {
  pointer-events: none;
}

.linkText__text {
  color: $colorTextLink;
  text-decoration: none;
}

.linkText__text--hovered {
  text-decoration: underline;
}

.linkText__text--disabled {
  color: $colorBase500;
}

.linkText__text--small {
  font-size: 12px;
}

.linkText__text--medium {
  font-size: 14px;
}

.linkText__leftIcon--small {
  margin-right: 4px;
}

.linkText__leftIcon--medium {
  margin-right: 6px;
}

.linkText__rightIcon--small {
  margin-left: 6px;
}

.linkText__rightIcon--medium {
  margin-left: 8px;
}
</style>
