<template>
  <UserIcon
    class="user-icon-helper"
    :is-pc="isPc"
    :is-application="isApplication"
    :gaze-level="gazeLevel"
    :is-checked="isChecked"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import UserIcon from "@/components/users/UserIcon.vue";
import { GramOverviews } from "@/models/overview/Overviews";
import { GazeLevel } from "@/const/user-icon";
import { UserPvStats } from "@/models/UserPvStats";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { aggregatePvStats } from "@/models/overview/aggregatePvStats";

@Component({
  components: {
    UserIcon
  }
})
export default class UserIconWithCalculateStats extends Vue {
  @Prop({ type: GramOverviews, required: true })
  overviews!: GramOverviews;

  @Prop({ type: Object, required: true })
  selectCondition!: SelectCondition;

  @Prop({ type: ClientPvStats, required: true })
  clientPvStats!: ClientPvStats;

  @Prop({ type: Boolean, default: false })
  isChecked!: boolean;

  userPvStats: UserPvStats = aggregatePvStats(
    this.overviews,
    this.selectCondition
  );

  get isPc(): boolean {
    return this.userPvStats.isPc;
  }

  get isApplication(): boolean {
    return this.userPvStats.isApplication;
  }

  get gazeLevel(): GazeLevel {
    return this.userPvStats.gazeLevelFromPv(this.clientPvStats);
  }
}
</script>
