<i18n src="@/i18n/components/client-settings/footer.json"></i18n>
<template>
  <div class="cm-Footer">
    <div class="cm-Footer_Logo">
      <UsergramLogo class="footer__logoImg" />
    </div>
    <div class="cm-Footer_Links">
      <div class="cm-Footer_LinksItem">
        <a
          v-t="'footerLinkHelpText'"
          href="https://help.usergram.info/"
          target="_blank"
          rel="noopener"
        />
      </div>
      <div class="cm-Footer_LinksItem">
        <a
          v-t="'footerLinkBlogText'"
          href="https://blog.usergram.info/"
          target="_blank"
          rel="noopener"
        />
      </div>
    </div>
    <div class="cm-Footer_Copyright">
      {{ $t("footerCopyrightText") }}
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import UsergramLogo from "@/assets/svg/logo/footer-logo.svg";

@Component({
  components: {
    UsergramLogo
  }
})
export default class ClientSettingsFooter extends Vue {}
</script>

<style lang="scss" scoped>
.cm-Footer {
  position: absolute;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-align: center;
  width: 100%;
  height: 100px;
  background-color: #323442;
  color: #fff;
}

.cm-Footer_Logo {
  padding: 0 0 0 20px;
  cursor: pointer;
}

.cm-Footer_Logo .footer__logoImg {
  width: 65px;
  height: 65px;
}

.cm-Footer_Links {
  margin-left: 30px;
  font-size: 14px;
}

.cm-Footer_LinksItem {
  margin-top: 16px;
}

.cm-Footer_LinksItem:first-child {
  margin-top: 0;
}

.cm-Footer_LinksItem a,
.cm-Footer_LinksItem a:visited {
  color: #fff;
}

.cm-Footer_Copyright {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: inline-block;
  font-size: 11px;
}
</style>
