<template>
  <FilterBaseInputTextMatchType
    :value="value"
    :match-method="matchMethod"
    :is-wild-card-option-enable="true"
    :placeholder="placeholder"
    :input-form-suggestion-type="inputFormSuggestionType"
    class="filter-site-url-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterSiteUrlCondition } from "@/models/search/filter-node-condition/FilterSiteUrlCondition";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";
import { i18n } from "@/i18n";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterSiteUrlField extends Vue {
  @Model("input", { type: FilterSiteUrlCondition, required: true })
  condition!: FilterSiteUrlCondition;

  inputFormSuggestionType = InputFormSuggestionType.WEB_URL;

  onInput(condition: FilterSiteUrlCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.siteUrl;
  }

  get matchMethod(): MatchMethod {
    return this.condition.matchMethod;
  }

  get placeholder(): string {
    if (this.condition.matchMethod == MatchMethod.Wildcard) {
      return i18n.t(
        "components.search.filter.siteUrlWildcardPlaceholderText"
      ) as string;
    }
    return "";
  }

  onInputText(value: string) {
    this.onInput(new FilterSiteUrlCondition(value, this.condition.matchMethod));
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(
      new FilterSiteUrlCondition(this.condition.siteUrl, matchMethod)
    );
  }
}
</script>
