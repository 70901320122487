import { render, staticRenderFns } from "./SettingsLayout.vue?vue&type=template&id=7e7c9b6d&scoped=true"
import script from "./SettingsLayout.vue?vue&type=script&lang=ts"
export * from "./SettingsLayout.vue?vue&type=script&lang=ts"
import style0 from "./SettingsLayout.vue?vue&type=style&index=0&id=7e7c9b6d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e7c9b6d",
  null
  
)

export default component.exports