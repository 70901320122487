import { MutationTree, GetterTree, ActionTree } from "vuex";
import { i18n } from "@/i18n";
import { RootState } from "@/store/";
import { sleep } from "@/util/common-util";
import { TimeoutError } from "@/util/error-util";
import {
  FunnelDataDefinitionType,
  getFunnelDataDefinitionLabel,
  getFunnelDataAttributeDefinitionLabel
} from "@/util/funnel-util";

import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { MatchMethod } from "@/models/search/MatchMethod";

import {
  FunnelFailedReason,
  FunnelStatus,
  FunnelType,
  SearchField
} from "@/const/funnel";
import {
  FunnelCreateParam,
  FunnelJson,
  FunnelApiResponse,
  FunnelDataJson,
  GramListOfUser
} from "@/api/apis/ApiFunnel";
import {
  FunnelAnalysisCondition,
  FunnelAnalysisHistory
} from "@/models/funnel/FunnelAnalysisCondition";
import { FunnelData } from "@/models/funnel/FunnelData";
import {
  FunnelCondition,
  FunnelConditionDefault,
  FunnelConditionPageTitleOrURL,
  FunnelEdge
} from "@/models/funnel/FunnelCondition";
import { DateRange } from "@/components/date-picker/DateRange";
import {
  FunnelConditionAttributeType,
  FunnelConditionNumberAttribute,
  FunnelConditionTextAttribute
} from "@/models/funnel/FunnelConditionAttribute";
import { FunnelConditionActivity } from "@/models/funnel/FunnelConditionActivity";
import { AttributeType } from "@/api/apis/ApiSearch";
import { SearchEngine } from "@/models/system/SearchEngine";

// 再度APIを叩くまでの待機時間を4秒とする
const FETCH_INTERVAL_MSEC = 4000;
// 240秒(4秒 x 60回)で結果が返らない場合はタイムアウトとする
export const TIMEOUT_COUNT = 60;

export class FunnelState {
  condition: FunnelAnalysisCondition = FunnelAnalysisCondition.defaultCondition();
  userAttributes: FunnelConditionAttributeType[] = [];
  userActivities: FunnelConditionActivity[] = [];
  numFilteredUsers: number | null = null;
  currentId: string = "";
  status: FunnelStatus = FunnelStatus.NOT_STARTED;
  failedReason: FunnelFailedReason | null = null;
  funnelDataJson: FunnelDataJson | null = null;
  funnelHistory: FunnelAnalysisHistory[] = [];
  fetchCount = 0;
  canUseLongTerm: boolean = false;
  selectedOrder: number = 0;
  isMatchedSelected: boolean | null = null;
}

const mutations = <MutationTree<FunnelState>>{
  setCondition(state: FunnelState, condition: FunnelAnalysisCondition) {
    state.condition = condition;
  },
  setUserAttributes(
    state: FunnelState,
    attributes: FunnelConditionAttributeType[]
  ) {
    state.userAttributes = attributes;
  },
  setUserActivities(state: FunnelState, activities: FunnelConditionActivity[]) {
    state.userActivities = activities;
  },
  setNumFilteredUsers(state: FunnelState, numFilteredUsers: number | null) {
    state.numFilteredUsers = numFilteredUsers;
  },
  setCurrentId(state: FunnelState, id: string) {
    state.currentId = id;
  },
  setStatus(state: FunnelState, status: FunnelStatus) {
    state.status = status;
  },
  setFailedReason(state: FunnelState, failedReason: FunnelFailedReason | null) {
    state.failedReason = failedReason;
  },
  setResult(state: FunnelState, dataJson: FunnelDataJson | null) {
    state.funnelDataJson = dataJson;
  },
  setHistory(state: FunnelState, history: FunnelAnalysisHistory[]) {
    state.funnelHistory = history;
  },
  setFetchCount(state: FunnelState, fetchCount: number) {
    state.fetchCount = fetchCount;
  },
  setCanUseLongTermFunnel(state: FunnelState, canUseLongTerm: boolean) {
    state.canUseLongTerm = canUseLongTerm;
  },
  setSelectedOrder(state: FunnelState, order: number) {
    state.selectedOrder = order;
  },
  setIsMatchedSelected(state: FunnelState, isMatchedSelected: boolean | null) {
    state.isMatchedSelected = isMatchedSelected;
  }
};

const getters = <GetterTree<FunnelState, RootState>>{
  funnelData(state, _, rootState): FunnelData[] {
    if (!state.funnelDataJson) {
      return [];
    }

    const searchEngines: SearchEngine[] = rootState.system.searchEngines;

    return [...state.funnelDataJson.data]
      .sort((a, b) => a.order - b.order)
      .map(json => FunnelData.fromJson(json, searchEngines));
  },
  funnelUserAttributes(state): FunnelConditionAttributeType[] {
    if (!state.funnelDataJson || !state.funnelDataJson.user_attributes) {
      return [];
    }

    const userAttrs: FunnelConditionAttributeType[] = [];
    state.funnelDataJson.user_attributes.forEach(ua => {
      if (ua.type === AttributeType.TEXT) {
        userAttrs.push(FunnelConditionTextAttribute.fromJson(ua));
      } else {
        userAttrs.push(FunnelConditionNumberAttribute.fromJson(ua));
      }
    });

    return userAttrs;
  },
  funnelDataDefinitions(
    state,
    getters,
    rootState,
    rootGetters
  ): FunnelDataDefinitionType[] {
    const definitions: FunnelDataDefinitionType[] = rootGetters[
      "clientSettings/allActiveConversionDefinitions"
    ]
      .concat(rootState.clientSettings.eventDefinitions)
      .concat(rootState.clientSettings.businessEventDefinitions)
      .concat(rootState.clientSettings.contactDefinitions);

    return definitions;
  },
  funnelDataDefinitionLabels(state, getters): string[] {
    const definitions: FunnelDataDefinitionType[] =
      getters.funnelDataDefinitions;

    return getters.funnelData.map((data: FunnelData) => {
      const { displayValueForResult, conditionType } = data.condition;

      return displayValueForResult.map(value => {
        return getFunnelDataDefinitionLabel(
          definitions,
          [value] as string[] | number[],
          conditionType
        );
      });
    });
  },
  funnelDataAttributeDefinitionLabels(state, getters, rootState): string[] {
    const definitions: ConversionAttributeDefinition[] =
      rootState.clientSettings.conversionAttributeDefinitions;

    return getters.funnelData.map((data: FunnelData) => {
      const condition = data.condition;

      if (!(condition instanceof FunnelConditionDefault)) return "";

      return getFunnelDataAttributeDefinitionLabel(
        definitions,
        condition.attributes,
        condition.conditionType
      );
    });
  },
  funnelProgress(state): number {
    return (state.fetchCount / TIMEOUT_COUNT) * 100;
  },
  funnelGramIdsPerUser(state, getters): GramListOfUser {
    const isMatchedSelected: boolean | null = state.isMatchedSelected;
    const selectedIndex: number = state.selectedOrder - 1;
    const funnelData: FunnelData = [...getters.funnelData][selectedIndex];

    if (isMatchedSelected === true) {
      if ("grams_per_user" in funnelData.matchedUsers) {
        return { ...funnelData.matchedUsers.grams_per_user };
      }
    }
    if (isMatchedSelected === false) {
      if ("grams_per_user" in funnelData.unmatchedUsers) {
        return { ...funnelData.unmatchedUsers.grams_per_user };
      }
    }

    return {};
  }
};

const actions = <ActionTree<FunnelState, RootState>>{
  async create({ state, commit, rootState }): Promise<string> {
    commit("setStatus", FunnelStatus.NOT_STARTED);

    const conditions = state.condition.conditions.map(conditionItem => {
      return conditionItem.update({ ...conditionItem });
    });

    commit(
      "setCondition",
      new FunnelAnalysisCondition(
        state.condition.funnelTitle,
        state.condition.startDate,
        state.condition.endDate,
        conditions,
        state.userAttributes,
        state.userActivities
      )
    );

    const params: FunnelCreateParam = state.condition.toJson();
    const response: FunnelApiResponse = await rootState.api.funnel.createNew(
      params
    );

    commit("setCurrentId", response.funnel.id);
    commit("setStatus", response.funnel.status);

    return response.funnel.id;
  },
  async fetchFunnelData({ state, commit, rootState }, id: string) {
    if (state.currentId === "") {
      return;
    }
    commit("setFetchCount", 0);
    commit("setSelectedOrder", 0);
    commit("setIsMatchedSelected", null);

    let response: FunnelApiResponse = await rootState.api.funnel.getResult(id);

    let count = 0;
    while (response.funnel.status === FunnelStatus.CREATION_IN_PROGRESS) {
      // タイムアウト処理
      if (count > TIMEOUT_COUNT) {
        throw new TimeoutError(i18n.t("store.modules.apiTimeout") as string);
      }

      await sleep(FETCH_INTERVAL_MSEC);
      count++;
      commit("setFetchCount", count);

      response = await rootState.api.funnel.getResult(id);

      // 新しい解析が実行された場合はキャンセル
      if (id !== state.currentId && state.currentId.length > 0) {
        return;
      }
    }

    // 新しい解析が実行された場合はキャンセル
    if (id !== state.currentId && state.currentId.length > 0) {
      return;
    }

    if (
      response.funnel.status === FunnelStatus.CREATION_FAILED &&
      "failed_reason" in response
    ) {
      commit("setFailedReason", response.failed_reason);
    } else {
      commit("setFetchCount", TIMEOUT_COUNT);
      commit("setResult", response.funnel.funnel_data);

      const funnelHistory = FunnelAnalysisHistory.fromJson(
        response.funnel,
        rootState.system.searchEngines
      );
      const condition = funnelHistory.toFunnelCondition();
      commit("setCondition", condition);
      commit("setUserAttributes", condition.userAttributes);
      commit("setUserActivities", condition.userActivities);
      commit("setNumFilteredUsers", funnelHistory.numFilteredUsers);
    }

    return response.funnel.status;
  },
  addFunnelCondition({ state, commit, rootState, rootGetters }) {
    let addedCondition: FunnelCondition | null = null;

    const allActiveConversions =
      rootGetters["clientSettings/allActiveConversionDefinitions"];
    if (allActiveConversions.length !== 0) {
      addedCondition = new FunnelConditionDefault(
        FunnelType.CV,
        state.condition.startDate,
        state.condition.endDate,
        allActiveConversions[0].id,
        false,
        [],
        FunnelEdge.defaultCondition()
      );
    } else if (rootState.app.canUseWebdataFeatures!) {
      addedCondition = new FunnelConditionPageTitleOrURL(
        FunnelType.PV,
        state.condition.startDate,
        state.condition.endDate,
        "",
        false,
        MatchMethod.Partial,
        SearchField.URL,
        FunnelEdge.defaultCondition()
      );
    }

    if (addedCondition !== null) {
      const condition = new FunnelAnalysisCondition(
        state.condition.funnelTitle,
        state.condition.startDate,
        state.condition.endDate,
        [...state.condition.conditions, addedCondition],
        state.userAttributes,
        state.userActivities
      );
      commit("setCondition", condition);
    }

    return;
  },
  resetFunnel({ commit }) {
    commit("setCondition", FunnelAnalysisCondition.defaultCondition());
    commit("setUserAttributes", []);
    commit("setUserActivities", []);
    commit("setCurrentId", "");
    commit("setStatus", FunnelStatus.NOT_STARTED);
    commit("setFailedReason", null);
    commit("setResult", null);
    commit("setFetchCount", 0);
    commit("setSelectedOrder", 0);
    commit("setIsMatchedSelected", null);
  },
  updateFunnelCondition(
    { state, commit },
    condition: {
      funnelCondition: FunnelCondition;
      order: number;
    }
  ) {
    const { funnelCondition, order } = condition;
    state.condition.conditions.splice(order, 1, funnelCondition);
    commit("setCondition", state.condition);
  },
  deleteFunnelCondition({ state, commit }, index: number) {
    commit("setCondition", state.condition.deleteCondition(index));
  },
  updateFunnelTitle({ state, commit }, title: string) {
    const {
      startDate,
      endDate,
      conditions,
      userAttributes,
      userActivities
    } = state.condition;
    const condition = new FunnelAnalysisCondition(
      title,
      startDate,
      endDate,
      conditions,
      userAttributes,
      userActivities
    );
    commit("setCondition", condition);
  },
  updateFunnelPeriod({ state, commit }, dateRange: DateRange) {
    const {
      funnelTitle,
      conditions,
      userAttributes,
      userActivities
    } = state.condition;
    const condition = new FunnelAnalysisCondition(
      funnelTitle,
      dateRange.min,
      dateRange.max,
      conditions,
      userAttributes,
      userActivities
    );
    commit("setCondition", condition);
  },
  async fetchFunnelHistory({ commit, rootState }) {
    const response: FunnelJson[] = await rootState.api.funnel.getHistory();
    commit(
      "setHistory",
      response.map(json =>
        FunnelAnalysisHistory.fromJson(json, rootState.system.searchEngines)
      )
    );
  },
  sortFunnelCondition({ state, commit }, sortedConditions: FunnelCondition[]) {
    const { condition } = state;
    const firstCondition = sortedConditions[0].update({ notCondition: false });
    const updateCondition = new FunnelAnalysisCondition(
      condition.funnelTitle,
      condition.startDate,
      condition.endDate,
      [firstCondition, ...sortedConditions.slice(1)],
      condition.userAttributes,
      condition.userActivities
    );

    commit("setCondition", updateCondition);
  }
};

export const funnel = {
  namespaced: true,
  state: new FunnelState(),
  mutations,
  getters,
  actions
};
