<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :title="$t('nodeTitleBusinessEvent')"
    class="filter-node-business-event"
    :show-add-condition="condition.isConditionAddable()"
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    :select-exclusion-type-value="exclutionTypeValue"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-condition="onClickAddCondition"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <FilterBusinessEventField
      v-model="businessEventDefinitionId"
      :business-event-definitions="businessEventDefinitions"
    />

    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, false, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterBusinessEventAttributeField
        v-if="additionalCondition.conditionType === businessEventAttribute"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />

      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import { FilterNodeForBusinessEvent } from "@/models/search/filter-node/FilterNodeForBusinessEvent";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import {
  ExclusionType,
  ExclusionProps,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { FilterBusinessEventAttributeCondition } from "@/models/search/filter-node-condition/FilterBusinessEventAttributeCondition";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";

import FilterNode from "@/components/filter/FilterNode.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import FilterBusinessEventField from "@/components/filter/form/FilterBusinessEventField.vue";
import FilterBusinessEventAttributeField from "@/components/filter/form/FilterBusinessEventAttributeField.vue";

@Component({
  name: "FilterNodeBusinessEvent",
  components: {
    FilterNode,
    FilterNodeItem,
    FilterDateHourField,
    FilterPeriodField,
    FilterBusinessEventField,
    FilterBusinessEventAttributeField
  }
})
export default class FilterNodeBusinessEvent extends Vue {
  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;
  businessEventAttribute = FilterNodeConditionType.BusinessEventAttribute;
  businessEventDefinitions: BusinessEventDefinition[] = this.$store.state
    .clientSettings.activeBusinessEventDefinitions;
  value = "";

  @Model("input", { type: FilterNodeForBusinessEvent, required: true })
  condition!: FilterNodeForBusinessEvent;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  onInput(condition: FilterNodeForBusinessEvent) {
    this.$emit("input", condition);
  }

  get businessEventDefinitionId(): number {
    return this.condition.businessEventDefinitionId;
  }
  set businessEventDefinitionId(businessEventDefinitionId: number) {
    this.onInput(this.createCondition(businessEventDefinitionId, null));
  }

  // 条件を追加
  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    const addCondition:
      | FilterAdditionalTimingCondition
      | FilterBusinessEventAttributeCondition = this.addedCondition();

    additionalConditions.push(addCondition);
    this.onInput(this.createCondition(null, additionalConditions));
  }

  addedCondition():
    | FilterAdditionalTimingCondition
    | FilterBusinessEventAttributeCondition {
    if (!this.condition.hasBusinessEventAttributeCondition) {
      return new FilterBusinessEventAttributeCondition("");
    }

    if (this.isFirstNode && !this.hasFirstTime) {
      return FilterFirstTimeCondition.buildDefaultData();
    }

    return FilterPeriodCondition.buildDefaultData();
  }

  get hasFirstTime(): boolean {
    return this.condition.additionalConditions.some(
      additionalCondition =>
        additionalCondition instanceof FilterFirstTimeCondition
    );
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    const additionalCondition:
      | FilterAdditionalTimingCondition
      | FilterBusinessEventAttributeCondition = FilterNodeBusinessEvent.updatedCondition(
      type
    );

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? additionalCondition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  static updatedCondition(
    type: FilterNodeConditionType
  ): FilterAdditionalTimingCondition | FilterBusinessEventAttributeCondition {
    if (type === FilterNodeConditionType.BusinessEventAttribute) {
      return new FilterBusinessEventAttributeCondition("");
    }
    if (type === FilterNodeConditionType.FirstTime) {
      return FilterFirstTimeCondition.buildDefaultData();
    }
    if (type === FilterNodeConditionType.DateHour) {
      return FilterDateHourCondition.buildDefaultData();
    }
    if (type === FilterNodeConditionType.Period) {
      return FilterPeriodCondition.buildDefaultData();
    }

    return new FilterBusinessEventAttributeCondition(""); // 形式的なreturn value
  }

  onInputAdditionalCondition(
    condition:
      | FilterAdditionalTimingCondition
      | FilterBusinessEventAttributeCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  createCondition(
    businessEventDefinitionId: number | null,
    conditions:
      | (
          | FilterBusinessEventAttributeCondition
          | FilterAdditionalTimingCondition
        )[]
      | null
  ): FilterNodeForBusinessEvent {
    const setBusinessEventDefinitionId =
      businessEventDefinitionId === null
        ? this.condition.businessEventDefinitionId
        : businessEventDefinitionId;
    const setConditions =
      conditions === null ? this.condition.additionalConditions : conditions;
    return new FilterNodeForBusinessEvent(
      setBusinessEventDefinitionId,
      setConditions,
      this.condition.childIndex,
      this.condition.depth,
      this.condition.edge,
      this.condition.filterExclusion
    );
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForBusinessEvent(
        this.condition.businessEventDefinitionId,
        this.condition.additionalConditions,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue)
      )
    );
  }
  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get checkAdditionalConditions(): boolean {
    const conditionsMax = 2;
    const conditions = this.$props.condition;

    return (
      conditions.additionalConditions &&
      conditions.additionalConditions.length === conditionsMax
    );
  }
}
</script>
