<template>
  <FilterBaseInputTextMatchType
    :value="value"
    :match-method="matchMethod"
    class="filter-launch-app-additional-condition-form"
    @input-text="onInputText"
    @input-match-method="onInputMatchMethod"
  >
    <slot></slot>
  </FilterBaseInputTextMatchType>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterLaunchAppAdditionalCondition } from "@/models/search/filter-node-condition/FilterLaunchAppAdditionalCondition";
import FilterBaseInputTextMatchType from "@/components/filter/form/FilterBaseInputTextMatchType.vue";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    FilterBaseInputTextMatchType
  }
})
export default class FilterLaunchAppAdditionalConditionField extends Vue {
  @Model("input", { type: FilterLaunchAppAdditionalCondition, required: true })
  condition!: FilterLaunchAppAdditionalCondition;

  onInput(condition: FilterLaunchAppAdditionalCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.value;
  }

  get matchMethod(): MatchMethod {
    return this.condition.matchMethod;
  }

  onInputText(value: string) {
    this.onInput(
      new FilterLaunchAppAdditionalCondition(
        this.condition.conditionType,
        this.condition.conversionAttributeId,
        value,
        this.condition.matchMethod
      )
    );
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.onInput(
      new FilterLaunchAppAdditionalCondition(
        this.condition.conditionType,
        this.condition.conversionAttributeId,
        this.condition.value,
        matchMethod
      )
    );
  }
}
</script>
