import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

/**
 *
 * @export
 * @interface SiteUrl
 */
export interface SiteUrl {
  /**
   *
   * @type {number}
   * @memberof SiteUrl
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SiteUrl
   */
  host_path: string;
  /**
   *
   * @type {string}
   * @memberof SiteUrl
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof SiteUrl
   */
  is_disabled: boolean;
}
/**
 *
 * @export
 * @interface SiteUrls
 */
export interface SiteUrls {
  /**
   *
   * @type {Array<SiteUrl>}
   * @memberof SiteUrls
   */
  site_urls: Array<SiteUrl>;
}

export default class ApiClientSettings {
  constructor(private readonly httpClient: HttpClient) {}

  public getConversionList = (): Promise<ApiRes.ConversionList> => {
    return this.httpClient.get<ApiRes.ConversionList>(ApiUrl.CV_LIST);
  };

  public getConversionAttributeList = (): Promise<
    ApiRes.ConversionAttributeList
  > => {
    return this.httpClient.get<ApiRes.ConversionAttributeList>(
      ApiUrl.CV_ATTR_LIST
    );
  };

  public getEventList = (): Promise<ApiRes.EventList> => {
    return this.httpClient.get<ApiRes.EventList>(ApiUrl.EVENT_LIST);
  };

  public getNpsList = (): Promise<ApiRes.NpsDefList> => {
    return this.httpClient.get<ApiRes.NpsDefList>(ApiUrl.NPS_DEF_LIST);
  };

  public getEnqueteList = (): Promise<ApiRes.EnqtDefList> => {
    return this.httpClient.get<ApiRes.EnqtDefList>(ApiUrl.ENQT_DEF_LIST);
  };

  public getBusinessEventList = (): Promise<ApiRes.BeDefList> => {
    return this.httpClient.get<ApiRes.BeDefList>(ApiUrl.BE_DEF_LIST);
  };

  public getBusinessIndexList = (): Promise<ApiRes.BizIndexDefList> => {
    return this.httpClient.get<ApiRes.BizIndexDefList>(
      ApiUrl.BIZ_INDEX_DEF_LIST
    );
  };

  public getContactList = (): Promise<ApiRes.ContactDefList> => {
    return this.httpClient.get<ApiRes.ContactDefList>(ApiUrl.CONTACT_DEF_LIST);
  };

  public getUserAttributeList = (): Promise<ApiRes.UserAttrDefList> => {
    return this.httpClient.get<ApiRes.UserAttrDefList>(
      ApiUrl.USER_ATTR_DEF_LIST
    );
  };

  public getAttributeTableColumnOrderList = (): Promise<
    ApiRes.ProfileOrders
  > => {
    return this.httpClient.get<ApiRes.ProfileOrders>(ApiUrl.PROFILE_ORDER_LIST);
  };

  public saveAttributeTableColumnOrderList = (
    profileOrders: ApiRes.ProfileOrder[]
  ): Promise<ApiRes.SaveProfileOrders> => {
    const data: ApiRes.SaveProfileOrders = {
      version: "1.0.0",
      data: profileOrders
    };

    return this.httpClient.post<ApiRes.SaveProfileOrders>(
      ApiUrl.SAVE_PROFILE_ORDER,
      data
    );
  };

  public getSiteUrlList = (): Promise<SiteUrls> => {
    return this.httpClient.get<SiteUrls>(ApiUrl.SITE_URL_LIST);
  };

  public getCoordinationList = (): Promise<ApiRes.CoordinationList> => {
    return this.httpClient.get<ApiRes.CoordinationList>(
      ApiUrl.COORDINATION_LIST
    );
  };
}
