<i18n src="@/i18n/components/tours/tour-detail-header.json"></i18n>
<template>
  <div class="tourDetailHeader">
    <div class="tourDetailHeader__titleContainer">
      <span class="tourDetailHeader__title">{{ tour.title }}</span>
      <Button
        v-if="canComplete"
        v-t="'complete'"
        class="tourDetailHeader__completeButton"
        height="35px"
        @click="onClickCompleteButton"
      />
      <TourDetailCompleteModal
        v-if="showCompleteModal"
        data-test="complete-modal"
        @close="closeCompleteModal"
      />
    </div>

    <div class="tourDetailHeader__panelContainer">
      <div class="tourDetailHeader__panel">
        <TourDetailHeaderPanel :title="leftPanelTitle">
          <div>
            <img
              class="tourDetailHeader__leftImage"
              :src="tour.panelImageUrl"
            />
            <div class="tourDetailHeader__description">{{
              tour.description
            }}</div>
          </div>
        </TourDetailHeaderPanel>
      </div>

      <div class="tourDetailHeader__panel">
        <TourDetailHeaderPanel
          :title="middlePanelTitle"
          :background-image-url="
            require('@/assets/img/tours/tour-detail-search-icon.png')
          "
        >
          <div class="tourDetailHeader__reasonToObserve">{{ tour.reason }}</div>
        </TourDetailHeaderPanel>
      </div>

      <div class="tourDetailHeader__panel">
        <TourDetailHeaderPanel
          :title="rightPanelTitle"
          :background-image-url="
            require('@/assets/img/tours/tour-detail-message-icon.png')
          "
        >
          <div class="tourDetailHeader__suggestion">{{ tour.suggestion }}</div>
        </TourDetailHeaderPanel>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import TourDetailHeaderPanel from "@/components/tours/TourDetailHeaderPanel.vue";
import TourDetailHeaderPanelTip from "@/components/tours/TourDetailHeaderPanelTip.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { TourCondition } from "@/models/tour/TourCondition";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import Button from "@/components/Button.vue";
import TourDetailCompleteModal from "@/components/tours/TourDetailCompleteModal.vue";

@Component({
  components: {
    TourDetailHeaderPanel,
    TourDetailHeaderPanelTip,
    Icon,
    Button,
    TourDetailCompleteModal
  }
})
export default class TourDetailHeader extends Vue {
  @Prop({ type: Object, required: true })
  tour!: TourCondition;

  iconHelp = Icons.ExternalLink;
  iconColor = Colors.Blue1000;

  showCompleteModal = false;

  get leftPanelTitle(): string {
    return this.$t("extract_user") as string;
  }

  get middlePanelTitle(): string {
    return this.$t("why_observe") as string;
  }

  get rightPanelTitle(): string {
    return this.$t("observation_tips") as string;
  }

  get canComplete(): boolean {
    return this.$store.state.filter.filterHistoryId !== null;
  }

  onClickCompleteButton() {
    this.showCompleteModal = true;
    UgTag.pushEvent(UgEventTag.TourCompleteButton);
  }

  closeCompleteModal() {
    this.showCompleteModal = false;
  }
}
</script>

<style lang="scss" scoped>
.tourDetailHeader {
  position: relative;
  padding: 20px;
  width: 100%;
  background: $colorTourBase;
}

.tourDetailHeader__titleContainer {
  display: flex;
  justify-content: space-between;
  color: $colorWhite;
  font-weight: bold;
  min-height: 35px;
}

.tourDetailHeader__title {
  font-size: 20px;
  line-height: 1.5;
}

.tourDetailHeader__completeButton {
  min-width: 125px;
  font-size: 13px;
  line-height: 1;
}

.tourDetailHeader__panelContainer {
  display: flex;
  flex: 1;
  margin-top: 20px;
}

.tourDetailHeader__panel {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;

  &:not(:first-child) {
    margin-left: 5px;
  }
}

.tourDetailHeader__leftImage {
  display: block;
  margin: auto;
  height: 60px;
}

.tourDetailHeader__description {
  margin-top: 15px;
  color: $colorBase700;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

.tourDetailHeader__reasonToObserve {
  position: absolute;
  top: 50%;
  right: 20px;
  left: 20px;
  color: $colorBase700;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  transform: translate(0, -50%);
}

.tourDetailHeader__suggestion {
  position: absolute;
  top: 50%;
  right: 20px;
  left: 20px;
  color: $colorTourHighlight;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  transform: translate(0, -50%);
}
</style>
