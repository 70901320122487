<i18n src="@/i18n/components/user-trend/user-trend-view-switch.json"></i18n>
<template>
  <div class="userTrendViewSwitch">
    <div
      class="userTrendViewSwitch__button"
      data-cy="button-table-list"
      @click="onClick(userTrendViewType.Table)"
    >
      <IconButton
        :icon="icons.Table"
        :size="22"
        :selected="view === userTrendViewType.Table"
        :button-type="iconButtonType"
      />
      <span
        v-t="'list'"
        class="userTrendViewSwitch__text"
        :class="{
          'userTrendViewSwitch__text--selected':
            view === userTrendViewType.Table
        }"
      />
    </div>

    <div
      class="userTrendViewSwitch__button"
      data-cy="button-scatter"
      @click="onClick(userTrendViewType.Scatter)"
    >
      <IconButton
        :icon="icons.Scatter"
        :size="22"
        :selected="view === userTrendViewType.Scatter"
        :button-type="iconButtonType"
      />
      <span
        v-t="'cvContributionPage'"
        class="userTrendViewSwitch__text"
        :class="{
          'userTrendViewSwitch__text--selected':
            view === userTrendViewType.Scatter
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { UserTrendViewType } from "@/const/user-trend";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {
    IconButton
  }
})
export default class UserTrendViewSwitch extends Vue {
  @Prop({ type: String, default: UserTrendViewType.Table })
  selectedView!: UserTrendViewType;

  @Emit("click")
  onClick(view: UserTrendViewType) {
    this.view = view;
  }

  userTrendViewType = UserTrendViewType;

  view: UserTrendViewType = this.selectedView;
  icons = Icons;
  iconButtonType = IconButtonType.UserTrend;
}
</script>

<style scoped lang="scss">
.userTrendViewSwitch {
  display: flex;
  align-items: center;
  line-height: 1;
}

.userTrendViewSwitch__button {
  line-height: 1;
  display: flex;
  align-items: center;
  &:first-child {
    margin-right: 10px;
  }
  &:hover span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.userTrendViewSwitch__text {
  padding-right: 10px;
  padding-left: 10px;
  color: $colorBase700;
  &--selected {
    color: $colorBase900;
  }
}
</style>
