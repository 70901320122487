<template>
  <div
    class="ug-tab-item"
    :class="{
      'ug-tab-item__active': active,
      'ug-tab-item__disabled': disabled
    }"
    @click="onClick"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class UgTabItem extends Vue {
  @Prop({ type: Boolean, required: true })
  active!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Emit("click")
  onClick() {
    if (this.disabled) {
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
.ug-tab-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 0;
  width: 100%;
  height: $tabHeight;
  border-bottom: 2px solid $colorBase500;
  color: $colorBase700;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: $colorBase600;
    content: "";
    transition: width 0.3s ease;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }

  &:hover {
    color: $colorBase800;

    &::before,
    &::after {
      width: 50%;
    }
  }
}

.ug-tab-item__active {
  border-bottom-color: $colorBase700;
  color: $colorBase900;
  font-weight: bold;
  pointer-events: none;
}

.ug-tab-item__disabled {
  color: $colorBase500;
  cursor: not-allowed;
  pointer-events: none;

  &:hover {
    &::before,
    &::after {
      width: 0;
    }
  }
}
</style>
