<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div class="featuredUsersAlmostCv">
    <div v-if="isAlmostCvLoading" class="featuredUsersAlmostCv__loading">
      <Loading height="80px" />
    </div>
    <div v-else class="featuredUsersAlmostCv__content">
      <FeaturedUsersError
        v-if="isError"
        class="featuredUsersAlmostCv__error"
        @retry-fetch="emitRetryFetch"
      />
      <div v-else>
        <div v-if="hasConversion">
          <FeaturedUsersNote
            :description="$t('almostCvNote')"
            :url="HELP_PAGE_LINK"
          />
          <FeaturedUsersAlmostCvTable
            :almost-cv-info="almostCvInfo"
            @select="emitSelect"
          />
        </div>
        <FeaturedUsersWarning
          v-else
          :title="$t('noConversionTitle')"
          :description="$t('noConversionContent')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import FeaturedUsersAlmostCvTable from "@/components/search/FeaturedUsersAlmostCvTable.vue";
import FeaturedUsersNote from "@/components/search/FeaturedUsersNote.vue";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";
import FeaturedUsersWarning from "@/components/search/FeaturedUsersWarning.vue";
import FeaturedUsersError from "@/components/search/FeaturedUsersError.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    FeaturedUsersAlmostCvTable,
    FeaturedUsersNote,
    FeaturedUsersWarning,
    FeaturedUsersError,
    Loading
  }
})
export default class FeaturedUsersAlmostCv extends Vue {
  HELP_PAGE_LINK = "https://help.usergram.info/article/462-almost-cv";

  @Prop({ type: Object, required: true })
  almostCvInfo!: AlmostCvInfo;

  @Prop({ type: Boolean, required: true })
  isError!: boolean;

  @Prop({ type: Boolean, required: true })
  isAlmostCvLoading!: boolean;

  emitSelect(url: string) {
    this.$emit("select", url);
  }

  @Emit("retry-fetch")
  emitRetryFetch() {}

  get hasConversion(): boolean {
    return this.almostCvInfo.conversions.length > 0;
  }

  mounted() {
    if (!this.hasConversion) {
      this.emitSelect("");
    }
  }
}
</script>
<style lang="scss" scoped>
.featuredUsersAlmostCv {
  height: 100%;
}
.featuredUsersAlmostCv__content {
  height: 100%;
}
.featuredUsersAlmostCv__loading {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -36px;
}
.featuredUsersAlmostCv__error {
  margin-top: -36px;
}
</style>
