import SelectOption from "@/components/form/SelectOption";
import { i18n } from "@/i18n";
import { MatchMethod } from "@/models/search/MatchMethod";

export function matchPatternOptions(): SelectOption[] {
  return [
    {
      label: i18n.t("components.search.search.partialMatch") as string,
      value: MatchMethod.Partial,
      disabled: false
    },
    {
      label: i18n.t("components.search.search.perfectMatch") as string,
      value: MatchMethod.Exact,
      disabled: false
    }
  ];
}
