<template>
  <div class="input-text">
    <input
      v-if="isImeMode"
      v-model="modelValue"
      v-bind="commonProps"
      @blur="onBlur($event.target.value)"
      @focus="onFocus"
      @keyup.enter="onKeyupEnter"
      @keydown.enter="onKeydownEnter"
      @keypress="onKeypress"
      @keydown="onKeydown"
    />
    <input
      v-else
      v-bind="commonProps"
      :value="value"
      @input="onInput($event.target.value)"
      @blur="onBlur($event.target.value)"
      @focus="onFocus"
      @keyup.enter="onKeyupEnter"
      @keydown.enter="onKeydownEnter"
      @keypress="onKeypress"
      @keydown="onKeydown"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";

@Component
export default class InputText extends Vue {
  @Model("input", { type: [String, Number], required: true })
  value!: string | number;

  @Prop({ type: Boolean, default: false })
  isImeMode!: boolean;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Number, default: 0 })
  tabindex!: number;

  @Prop({ type: String, default: "text" })
  type!: string;

  @Prop({ type: Number })
  maxLength?: number;

  @Prop({ type: Boolean, default: false })
  hasError!: boolean;

  @Prop({ type: String, default: "8px" })
  horizontalPadding!: string;

  onInput(value: string | number) {
    this.$emit("input", value);
  }

  onBlur(value: string | number) {
    this.$emit("blur", value);
  }

  @Emit("focus")
  onFocus() {}

  @Emit("keyup-enter")
  onKeyupEnter() {}

  @Emit("keydown-enter")
  onKeydownEnterNotDuringEditing() {}

  onKeypress(event: KeyboardEvent) {
    this.$emit("keypress", event);
  }

  onKeydown(event: KeyboardEvent) {
    this.$emit("keydown", event);
  }

  onKeydownEnter(event: any) {
    // 日本語編集中のenterには反応させないようにする
    if (event.keyCode === 13) {
      this.onKeydownEnterNotDuringEditing();
    }
  }
  get commonProps() {
    return {
      class: {
        "input-text-input": true,
        "input-text-input--error": this.hasError
      },
      type: this.type,
      placeholder: this.placeholder,
      title: this.type !== "password" && this.value,
      disabled: this.disabled,
      tabindex: this.tabindex,
      maxlength: this.maxLength,
      style: this.paddingStyle
    };
  }
  get modelValue(): string | number {
    return this.value;
  }

  set modelValue(newValue: string | number) {
    this.$emit("input", newValue);
  }

  get paddingStyle() {
    return {
      padding: "0 " + this.horizontalPadding
    };
  }
}
</script>

<style scoped lang="scss">
.input-text-input {
  width: 100%;
  height: $formPartsHeight;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorText;
  font-size: $basicFontSize;
  /* IE11で中央にする */
  line-height: $formPartsHeight;

  &::placeholder {
    color: $colorBase700;
  }
}

.input-text-input:disabled {
  border: 1px solid $colorBase500;
  background-color: $colorBase300;
  color: $colorBase600;
  cursor: default;
}

.input-text-input--error {
  border-color: $colorError;
  color: $colorError;
}
</style>
