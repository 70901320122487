<template>
  <label
    class="conversionSelectCheckbox"
    :class="{
      'conversionSelectCheckbox--checked': checked
    }"
  >
    <input
      class="conversionSelectCheckbox__input"
      type="checkbox"
      :checked="checked"
      @change="onInput($event.target.checked)"
    />

    <div
      class="conversionSelectCheckbox__iconContainer"
      :class="{
        'conversionSelectCheckbox__iconContainer--checked': checked
      }"
    >
      <Icon
        v-if="checked"
        :icon="icon"
        :size="11"
        :color="color"
        class="conversionSelectCheckbox__icon"
      />
    </div>

    <div
      class="conversionSelectCheckbox__text"
      :class="{
        'conversionSelectCheckbox__text--checked': checked
      }"
    >
      <slot></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { Component, Vue, Model } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class ConversionSelectCheckbox extends Vue {
  @Model("input", { type: Boolean, required: true })
  checked!: boolean;

  icon = Icons.Eye;
  color = Colors.White;

  onInput(checked: boolean) {
    this.$emit("input", checked);
  }
}
</script>

<style scoped lang="scss">
.conversionSelectCheckbox {
  display: flex;
  padding: 5px 20px;
  padding-right: 10px;
  cursor: pointer;
  &:hover {
    background-color: $colorHighlightedBarBg;
  }
}

.conversionSelectCheckbox--checked {
  background-color: $colorHighlightedBarBg;
  color: $colorHighlightedBar;
}

.conversionSelectCheckbox__input {
  display: none;
}

.conversionSelectCheckbox__iconContainer {
  position: relative;
  min-width: $selectFormPartsSize;
  width: $selectFormPartsSize;
  height: $selectFormPartsSize;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorDark;
}

.conversionSelectCheckbox__iconContainer--checked {
  border-color: $colorHighlightedBar;
  background-color: $colorHighlightedBar;
}

.conversionSelectCheckbox__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.conversionSelectCheckbox__text {
  margin-left: 10px;
  padding-top: 2px;
  color: $colorBase800;
  line-height: 1.3;

  @include default-break-word();
}

.conversionSelectCheckbox__text--checked {
  color: $colorBase900;
}
</style>
