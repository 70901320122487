import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";

export interface TdWebImportConfigDetailResponse {
  timestamp_column: string;
  timestamp_format_type: number;
  service_id_column: string;
  platform_id_column: string;
  referrer_uri_column: string;
  location_uri_column: string;
  location_name_column: string;
  user_agent_column: string;
  screen_resolution_column: string | null;
  ip_address_column: string | null;
  navigation_type_column: string | null;
}

export default class ApiTdWebImportConfig {
  constructor(private readonly httpClient: HttpClient) {}

  public getTdWebImportConfigDetails = (): Promise<
    TdWebImportConfigDetailResponse
  > => {
    return this.httpClient.get<TdWebImportConfigDetailResponse>(
      ApiUrl.TD_WEB_IMPORT_CONFIG_DETAIL
    );
  };

  public updateTdWebImportConfig = (
    params: TdWebImportConfigDetailResponse
  ): Promise<TdWebImportConfigDetailResponse> => {
    return this.httpClient.put<TdWebImportConfigDetailResponse>(
      ApiUrl.TD_WEB_IMPORT_CONFIG_DETAIL,
      params
    );
  };
}
