import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";
import { MatchMethod } from "@/models/search/MatchMethod";
import { PageTitleWithWordMatchMethodParam } from "@/api/apis/ApiSearch";

/**
 * 入口ページタイトルによる条件
 *
 * 絞り込み条件に追加で、入口ページタイトル（部分一致）を条件として付与できる
 */
export class FilterLandingPageTitleCondition {
  public readonly conditionType = FilterNodeConditionType.LandingPageTitle;

  constructor(
    public readonly pageTitle: string,
    public readonly matchMethod: MatchMethod
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.pageTitle,
      i18n.t("models.search.entryPageTitle") as string
    );
  }
}

export function convertJsonToFilterLandingPageTitleCondition(
  jsonContent: string | PageTitleWithWordMatchMethodParam
): FilterLandingPageTitleCondition {
  if (typeof jsonContent === "string") {
    return new FilterLandingPageTitleCondition(
      jsonContent,
      MatchMethod.Partial
    );
  }

  return new FilterLandingPageTitleCondition(
    jsonContent.title,
    jsonContent.word_match_method
  );
}
