<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div class="userHeader" :style="{ height: height + 'px' }">
    <div class="userHeader__user" data-cy="user-info-field">
      <div
        class="userHeader__userContainer"
        @mouseenter="onEnter"
        @mouseleave="onLeave"
      >
        <div class="userHeader__userIcon">
          <UserIconWithCalculateStats
            :overviews="user.overviews"
            :select-condition="selectCondition"
            :client-pv-stats="clientPvStats"
          />
          <slot name="userDetailBalloon" />
        </div>
        <div class="userHeader__displayId" data-cy="user-header-user-name">
          <UserServiceIdText
            :service-id-text="user.serviceIdForDisplay"
            :is-fixed-service-id="user.isFixedServiceId"
            :ellipsis="true"
          />
        </div>
      </div>
      <slot name="actions" />
    </div>
    <div class="userHeader__overview">
      <GramChart
        :chart-period="chartPeriod"
        :is-omo="isOmo"
        :visit-overviews="user.overviews.visit"
        :nps-overviews="user.overviews.nps"
        :enquete-overviews="user.overviews.enquete"
        :contact-overviews="user.overviews.contact"
        :business-event-overviews="user.overviews.business"
        :colored-periods="coloredPeriods"
        :is-user-detail="true"
        :end-date="endDate"
        :filter-matched-ids="user.overviews.filterMatchedIds"
        :highlight-msec="highlightMsec"
        :almost-cv-gram-ids="almostCvGramIds"
        :funnel-matched-gram-ids="funnelMatchedGramIds"
        :funnel-matched-selected-gram-ids="funnelMatchedSelectedGramIds"
        :selected-conversion-ids="selectedConversionIds"
        :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
        @click="onClick"
      />
    </div>
    <div
      v-click-outside="onCloseChartPeriodMenu"
      class="userHeader__chartPeriod"
      :class="{ 'userHeader__chartPeriod--showMenu': showChartPeriodMenu }"
    >
      <div class="userHeader__chartPeriodInner">
        <Transition name="chartPeriodMenu">
          <div v-if="showChartPeriodMenu" class="userHeader__chartPeriodMenu">
            <div class="userHeader__chartPeriodMenuText">{{
              $t("displayMode")
            }}</div>
            <ChartPeriodMenu
              v-model="chartPeriod"
              @input="onChartPeriodClick"
            />
          </div>
        </Transition>
      </div>

      <Transition name="chartPeriodButton">
        <div
          class="userHeader__chartPeriodButton"
          @click="showChartPeriodMenu = true"
        >
          <Tooltip
            v-if="!showChartPeriodMenu"
            :text="$t('displayMode')"
            placement="bottom"
          >
            <div class="userHeader__chartPeriodButtonInner">
              {{ chartPeriodLabel }}
            </div>
          </Tooltip>
        </div>
      </Transition>
    </div>
    <div class="userHeader__setting">
      <slot name="companyActivityDisplaySetting" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue, Model } from "vue-property-decorator";
import { User } from "@/models/User";
import UserIconWithCalculateStats from "@/components/users/UserIconWithCalculateStats.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";
import GramChart from "@/components/chart/GramChart.vue";
import ChartPeriodMenu from "@/components/chart/ChartPeriodMenu.vue";
import Tooltip from "@/components/Tooltip.vue";
import Button from "@/components/Button.vue";
import { BUTTON_TYPE } from "@/const/button";
import { getPaperHeight, getEndDate } from "@/components/chart/chart-util";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { Msec } from "@/util/date-util";
import { ChartPeriod } from "@/const/chart-period";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import vClickOutside from "v-click-outside";

const SPACE_SCROLLBAR_CHART: number = 10;
@Component({
  components: {
    UserIconWithCalculateStats,
    UserServiceIdText,
    ChartPeriodMenu,
    GramChart,
    Tooltip,
    Button
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
})
export default class UserHeader extends Vue {
  buttonType = BUTTON_TYPE;
  showChartPeriodMenu = false;

  @Model("input", { type: Number, required: true })
  balloonChartPeriod!: ChartPeriod;

  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: Number, default: null })
  highlightMsec!: Msec | null;

  @Prop({ type: Object, required: true })
  selectCondition!: SelectCondition;

  @Prop({ type: ClientPvStats, required: true })
  clientPvStats!: ClientPvStats;

  @Prop({ type: Array, required: true })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Array, required: true })
  almostCvGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  @Prop({ type: Array, required: true })
  selectedConversionIds!: number[];

  @Prop({ type: Boolean, required: true })
  isToggleTimeOfDayEnabled!: boolean;

  @Emit("show-balloon")
  onEnter() {}

  @Emit("hide-balloon")
  onLeave() {}

  onClick(
    overview:
      | VisitOverview
      | NpsOverview
      | BusinessEventOverview
      | EnqueteOverview
      | ContactOverview
  ) {
    this.$emit("click", overview);
  }

  onChartPeriodClick(chartPeriod: ChartPeriod) {
    this.onCloseChartPeriodMenu();
    this.$emit("chart-period-click", chartPeriod);
  }

  chartPeriod: ChartPeriod = this.balloonChartPeriod;

  onCloseChartPeriodMenu() {
    this.showChartPeriodMenu = false;
  }

  get chartPeriodLabel(): string {
    if (this.chartPeriod === ChartPeriod.TwoYears) {
      return this.$t("2years") as string;
    }

    if (this.chartPeriod === ChartPeriod.OneYear) {
      return this.$t("1year") as string;
    }

    if (this.chartPeriod === ChartPeriod.OneMonth) {
      return this.$t("1month") as string;
    }

    return this.$t("6months") as string;
  }

  get height() {
    return getPaperHeight(this.isOmo) + SPACE_SCROLLBAR_CHART;
  }

  get endDate(): Date {
    return getEndDate(this.selectCondition);
  }
}
</script>

<style scoped lang="scss">
.userHeader {
  position: fixed;
  top: 0;
  left: 100px;
  display: flex;
  min-height: 104px;
  width: calc(100% - 200px);
  border-bottom: 1px solid $colorLine;
}

.userHeader__user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 158px;
  text-align: center;
}

.userHeader__userContainer {
  display: inline;
}

.userHeader__userIcon {
  position: relative;
  display: inline-block;
}

.userHeader__displayId {
  margin: 0 auto;
  width: 120px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.userHeader__overview {
  position: relative;
  padding-right: 35px;
  width: 100%;
  transition: opacity 0.3s ease;
}

.userHeader__setting {
  position: absolute;
  right: 37px;
  bottom: -18px;
  display: inline-block;
}

.userHeader__chartPeriod {
  position: absolute;
  top: 8px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  min-width: 48px;
  width: 48px;
  height: 26px;
  border: 1px solid $colorBase500;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  transition: min-width 0.2s ease-out;
}

.userHeader__chartPeriod--showMenu {
  min-width: 235px;
  width: auto;
}

.userHeader__chartPeriodInner {
  overflow: hidden;
}

.userHeader__chartPeriodMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2px 0 10px;
  height: 24px;
  white-space: nowrap;
}

.userHeader__chartPeriodMenuText {
  font-weight: bold;
}

.userHeader__chartPeriodButton {
  position: absolute;
  top: 0;
  right: 0;
}

.userHeader__chartPeriodButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 24px;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: $colorHoverLightForWhite;
  }
}

.chartPeriodButton-enter-active,
.chartPeriodButton-leave-active {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.chartPeriodButton-enter,
.chartPeriodButton-leave-to {
  opacity: 0;
}

.chartPeriodMenu-enter-active,
.chartPeriodMenu-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.1s, transform 0.2s ease-out;
  transform: translateX(0);
}
.chartPeriodMenu-enter,
.chartPeriodMenu-leave-to {
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out 0.2s;
  transform: translateX(100%);
}

@media print {
  .userHeader {
    position: relative;
    left: 0;
    width: 100%;
  }
}
</style>
