var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Tooltip',{staticClass:"chartColoredPerioodTooltip",style:({
    position: 'absolute',
    left: _vm.x + 'px',
    top: _vm.y + 'px'
  }),attrs:{"value":_vm.show,"placement":_vm.tooltipPlacement,"manual":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"chartColoredPerioodTooltip__colorIndicator",style:({
        'background-color': _vm.colorIndicator
      })}),_c('span',{staticClass:"chartColoredPerioodTooltip__label",domProps:{"textContent":_vm._s(_vm.label)}}),_c('span',{staticClass:"chartColoredPerioodTooltip__period",attrs:{"data-cy":"gram-chart-tooltip-period"},domProps:{"textContent":_vm._s(_vm.period)}})]},proxy:true}])},[_c('div',{staticClass:"chartColoredPerioodTooltip__empty"})])
}
var staticRenderFns = []

export { render, staticRenderFns }