<template>
  <div class="search-header-tab">
    <UgTab data-cy="search-header-ug-tab">
      <UgTabItem
        v-for="option in options"
        :key="option.id"
        :label="option.label"
        :active="option.label === seletedTab.label"
        @click="selectTab(option)"
      >
        <div :class="{ 'ug-tag-item__new': option.isNew }">
          {{ option.label }}
        </div>
        <TagNew v-if="option.isNew" class="ug-tag-item__new__icon" />
      </UgTabItem>
    </UgTab>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import { TabOption } from "@/components/search/TabOption";
import UgTab from "@/components/tab/UgTab.vue";
import UgTabItem from "@/components/tab/UgTabItem.vue";
import TagNew from "@/components/TagNew.vue";

@Component({
  components: {
    UgTab,
    UgTabItem,
    TagNew
  }
})
export default class SearchHeaderTab extends Vue {
  @Model("select", { type: Object, required: true })
  seletedTab!: TabOption;

  @Prop({ type: Array, required: true })
  options!: TabOption[];

  selectTab(seletedTab: TabOption) {
    this.$emit("select", seletedTab);
  }
}
</script>
<style lang="scss" scoped>
.ug-tag-item__new {
  position: relative;
}

.ug-tag-item__new__icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
