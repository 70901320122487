<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="select-by-nps-form">
    <div class="select-by-nps-form__title">
      <TitleTextWithIcon :title="$t('date')" :icon="iconHistoy" />
    </div>
    <div class="select-by-nps-form__date" data-cy="nps-date">
      <DatePickerInput
        width="250px"
        :value="condition.date"
        :enabled-period="enabledPeriod"
        @input="onInputDate"
      />
      <span v-t="'atTheTime'" class="select-by-nps-form__date-suffix" />
    </div>
    <div class="select-by-nps-form__title">
      <TitleTextWithIcon
        :title="$t('categoryOrScore')"
        :icon="iconConversion"
      />
    </div>

    <div class="select-by-nps-form__input-type-wrapper">
      <label
        class="select-by-nps-form__input-type-label"
        data-cy="nps-category-radio"
      >
        <RadioButton
          :value="selectedFormType"
          :label="formTypes.Category"
          @change="onChageFormType(formTypes.Category)"
        />
        <div
          v-t="'category'"
          class="select-by-nps-form__input-type-title"
          :class="{
            'select-by-nps-form__input-type-title__selected':
              selectedFormType === formTypes.Category
          }"
        />
      </label>
      <div
        class="select-by-nps-form__category_item"
        :class="{
          'select-by-nps-form__category_item__selected':
            selectedCategoryType === categoryTypes.Low
        }"
        data-cy="nps-category-low"
        @click="onClickCateogyType(categoryTypes.Low)"
      >
        {{ $t("low") }}
        <div class="select-by-nps-form__score">{{ loyaltyLowScore }}</div>
      </div>
      <div
        class="select-by-nps-form__category_item"
        :class="{
          'select-by-nps-form__category_item__selected':
            selectedCategoryType === categoryTypes.Medium
        }"
        @click="onClickCateogyType(categoryTypes.Medium)"
      >
        {{ $t("middle") }}
        <div class="select-by-nps-form__score">{{ loyaltyMediumScore }}</div>
      </div>
      <div
        class="select-by-nps-form__category_item"
        :class="{
          'select-by-nps-form__category_item__selected':
            selectedCategoryType === categoryTypes.High
        }"
        @click="onClickCateogyType(categoryTypes.High)"
      >
        {{ $t("high") }}
        <div class="select-by-nps-form__score">{{ loyaltyHighScore }}</div>
      </div>
    </div>

    <div class="select-by-nps-form__input-type-wrapper">
      <label class="select-by-nps-form__input-type-label">
        <RadioButton
          :value="selectedFormType"
          :label="formTypes.Score"
          @change="onChageFormType(formTypes.Score)"
        />

        <div
          class="select-by-nps-form__input-type-title"
          :class="{
            'select-by-nps-form__input-type-title__selected':
              selectedFormType === formTypes.Score
          }"
        >
          {{ $t("score") }}
          <div class="select-by-nps-form__input-type-title-socre"
            >({{ loyaltyScoreRange }})</div
          >
        </div>
      </label>
      <InputNumber
        class="select-by-nps-form__input-text"
        :max="npsDefinitions[0].max"
        :min="npsDefinitions[0].min"
        :value="scoreValue"
        @focus="onChageFormType(formTypes.Score)"
        @input="onInputScore"
      />
    </div>

    <div class="select-by-nps-form__input-type-wrapper">
      <label class="select-by-nps-form__input-type-label">
        <RadioButton
          :value="selectedFormType"
          :label="formTypes.Range"
          @change="onChageFormType(formTypes.Range)"
        />
        <div
          class="select-by-nps-form__input-type-title"
          :class="{
            'select-by-nps-form__input-type-title__selected':
              selectedFormType === formTypes.Range
          }"
        >
          {{ $t("scoreRange") }}
          <div class="select-by-nps-form__input-type-title-socre"
            >({{ loyaltyScoreRange }})</div
          >
        </div>
      </label>
      <InputNumber
        class="select-by-nps-form__input-text"
        :max="npsDefinitions[0].max"
        :min="npsDefinitions[0].min"
        :value="rangeFromValue"
        @focus="onChageFormType(formTypes.Range)"
        @input="onInputRangeFrom"
      />
      <div class="select-by-nps-form__range-separator">〜</div>
      <InputNumber
        class="select-by-nps-form__input-text"
        :max="npsDefinitions[0].max"
        :min="npsDefinitions[0].min"
        :value="rangeToValue"
        @focus="onChageFormType(formTypes.Range)"
        @input="onInputRangeTo"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import InputNumber from "@/components/form/InputNumber.vue";
import { Icons } from "@/const/Icons";
import { enabledPeriodRange } from "@/util/date-range-picker-util";
import { DateRange } from "@/components/date-picker/DateRange";
import DatePickerInput from "@/components/date-picker/DatePickerInput.vue";

enum NpsFormType {
  Category,
  Score,
  Range
}

enum NpsCategoryType {
  Low,
  Medium,
  High
}

@Component({
  components: {
    TitleTextWithIcon,
    RadioButton,
    InputNumber,
    DatePickerInput
  }
})
export default class SelectByNpsForm extends Vue {
  @Prop({ type: Array, required: true })
  npsDefinitions!: NpsDefinition[];

  @Model("input", { type: SelectByNpsCondition, required: true })
  condition!: SelectByNpsCondition;

  onUpdate(condition: SelectByNpsCondition) {
    this.$emit("input", condition);
  }

  // どの入力タイプ、NPSカテゴリかはconditionから判断する
  selectedFormType!: NpsFormType;
  selectedCategoryType!: NpsCategoryType;

  scoreValue: number = this.condition.from;
  rangeFromValue: number = this.condition.from;
  rangeToValue: number = this.condition.to;

  iconConversion = Icons.Conversion;
  iconHistoy = Icons.History;

  categoryTypes = NpsCategoryType;
  formTypes = NpsFormType;

  created() {
    // fromとtoが同じなら暫定でスコア指定とする
    if (this.condition.from === this.condition.to) {
      this.selectedFormType = NpsFormType.Score;
    }
    // 違う場合は暫定でスコア範囲指定とする
    else {
      this.selectedFormType = NpsFormType.Range;
    }

    // fromとtoの値がカテゴリにマッチする場合、カテゴリ指定とする
    if (this.npsDefinitions.length > 0) {
      const range = { from: this.condition.from, to: this.condition.to };
      const npsDefinition = this.npsDefinitions[0];

      if (this.isSameRange(range, npsDefinition.lowRange)) {
        this.selectedCategoryType = NpsCategoryType.Low;
        this.selectedFormType = NpsFormType.Category;
      } else if (this.isSameRange(range, npsDefinition.mediumRange)) {
        this.selectedCategoryType = NpsCategoryType.Medium;
        this.selectedFormType = NpsFormType.Category;
      } else if (this.isSameRange(range, npsDefinition.highRange)) {
        this.selectedCategoryType = NpsCategoryType.High;
        this.selectedFormType = NpsFormType.Category;
      }
    }
    // カテゴリの種類がセットされてなければ、デフォルトとしてLowをセット
    if (this.selectedCategoryType === undefined) {
      this.selectedCategoryType = NpsCategoryType.Low;
    }
  }

  get enabledPeriod(): DateRange {
    return enabledPeriodRange();
  }

  /**
   * NSP低評価者のスコア
   */
  get loyaltyLowScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].lowScoreRange;
    }
    return "";
  }

  /**
   * NSP中評価者のスコア
   */
  get loyaltyMediumScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].mediumScoreRange;
    }
    return "";
  }

  /**
   * NSP高評価者のスコア
   */
  get loyaltyHighScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].highScoreRange;
    }
    return "";
  }

  /**
   * NSPスコア範囲
   */
  get loyaltyScoreRange(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].min + " 〜 " + this.npsDefinitions[0].max;
    }
    return "";
  }

  onChageFormType(type: NpsFormType) {
    this.selectedFormType = type;
    if (type === NpsFormType.Category) {
      this.setScoreFromCategory();
      return;
    }
    const isScore = type === NpsFormType.Score;
    const from = isScore ? this.scoreValue : this.rangeFromValue;
    const to = isScore ? this.scoreValue : this.rangeToValue;
    const condition = new SelectByNpsCondition(
      from,
      to,
      this.condition.date,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  onInputDate(date: Date) {
    const condition = new SelectByNpsCondition(
      this.condition.from,
      this.condition.to,
      date,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  onClickCateogyType(categoryType: NpsCategoryType) {
    this.selectedFormType = NpsFormType.Category;
    this.selectedCategoryType = categoryType;
    this.setScoreFromCategory();
  }

  // 選択中のカテゴリーから、検索条件のfromとtoをセットして更新する
  setScoreFromCategory() {
    let range: { from: number; to: number } = { from: 0, to: 0 };
    if (this.npsDefinitions.length > 0) {
      const npsDefinition = this.npsDefinitions[0];
      if (this.selectedCategoryType === NpsCategoryType.Low) {
        range = npsDefinition.lowRange;
      } else if (this.selectedCategoryType === NpsCategoryType.Medium) {
        range = npsDefinition.mediumRange;
      } else if (this.selectedCategoryType === NpsCategoryType.High) {
        range = npsDefinition.highRange;
      }
    }

    const condition = new SelectByNpsCondition(
      range.from,
      range.to,
      this.condition.date,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  onInputScore(score: string) {
    // TODO 数値以外が来たらエラー（できればレンジ範囲かも）
    this.scoreValue = Number(score);
    const condition = new SelectByNpsCondition(
      Number(score),
      Number(score),
      this.condition.date,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  onInputRangeFrom(score: string) {
    // TODO 数値以外が来たらエラー（できればレンジ範囲かも）
    this.rangeFromValue = Number(score);
    const condition = new SelectByNpsCondition(
      Number(score),
      this.condition.to,
      this.condition.date,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  onInputRangeTo(score: string) {
    this.rangeToValue = Number(score);
    const condition = new SelectByNpsCondition(
      this.condition.from,
      Number(score),
      this.condition.date,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }

  isSameRange(
    range: { from: number; to: number },
    otherRange: { from: number; to: number }
  ): boolean {
    return range.from === otherRange.from && range.to === otherRange.to;
  }
}
</script>

<style scoped lang="scss">
.select-by-nps-form__title {
  margin: 30px 0 20px 0;
  &:first-child {
    margin: 0 0 10px 0;
  }
}
.select-by-nps-form__date {
  position: relative;
  display: flex;
  align-items: center;
  height: $formPartsHeight;
}
.select-by-nps-form__date-suffix {
  margin-left: 10px;
}

.select-by-nps-form__input-type-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.select-by-nps-form__input-type-label {
  display: flex;
  align-items: center;
}

.select-by-nps-form__score {
  margin-top: 5px;
  color: $colorTextLight;
  text-align: center;
  font-size: 12px;
}

.select-by-nps-form__category_item {
  margin-right: 16px;
  padding: 6px 10px;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: $colorBlue700;
  }

  &__selected {
    border: 1px solid $colorDark;
    background-color: $colorDark;
    color: $colorWhite;

    &:hover {
      background-color: $colorDark;
    }
    .select-by-nps-form__score {
      color: $colorWhite;
    }
  }
}

.select-by-nps-form__input-type-title {
  width: 100px;
  color: $colorTextLight;
}

.select-by-nps-form__input-type-title-socre {
  margin-top: 3px;
  font-size: 12px;
}

.select-by-nps-form__input-type-title__selected {
  color: $colorText;
  font-weight: bold;
}

.select-by-nps-form__input-text {
  width: 70px;
}

.select-by-nps-form__range-separator {
  margin: 0 10px;
}
</style>
