import {
  convertFilterNodeForBrowseAppToJson,
  convertJsonToFilterNodeForBrowseApp,
  FilterNodeForBrowseApp
} from "@/models/search/filter-node/FilterNodeForBrowseApp";
import {
  convertFilterNodeForBrowseSiteToJson,
  convertJsonToFilterNodeForBrowseSite,
  FilterNodeForBrowseSite
} from "@/models/search/filter-node/FilterNodeForBrowseSite";
import {
  convertFilterNodeForBusinessEventToJson,
  convertJsonToFilterNodeForBusinessEvent,
  FilterNodeForBusinessEvent
} from "@/models/search/filter-node/FilterNodeForBusinessEvent";
import {
  convertFilterNodeForContactToJson,
  convertJsonToFilterNodeForContact,
  FilterNodeForContact
} from "@/models/search/filter-node/FilterNodeForContact";
import {
  convertFilterNodeForConversionToJson,
  convertJsonToFilterNodeForConversion,
  FilterNodeForConversion
} from "@/models/search/filter-node/FilterNodeForConversion";
import {
  convertFilterNodeForEventToJson,
  convertJsonToFilterNodeForEvent,
  FilterNodeForEvent
} from "@/models/search/filter-node/FilterNodeForEvent";
import {
  convertFilterNodeForInflowToJson,
  convertJsonToFilterNodeForInflow,
  FilterNodeForInflow
} from "@/models/search/filter-node/FilterNodeForInflow";
import {
  convertFilterNodeForLaunchAppToJson,
  convertJsonToFilterNodeForLaunchApp,
  FilterNodeForLaunchApp,
  convertConversionJsonToFilterNodeForLaunchApp
} from "@/models/search/filter-node/FilterNodeForLaunchApp";
import {
  convertFilterNodeForOrNodeToJson,
  convertJsonToFilterNodeForOrNode,
  FilterNodeForOrNode
} from "@/models/search/filter-node/FilterNodeForOrNode";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import {
  FilterAdditionalCondition,
  FilterNodeConditionType
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import {
  FilterNodeParam,
  FilterNodeParamForInflow,
  FilterNodeParamForBrowseSite,
  FilterNodeParamForBusinessEvent,
  FilterNodeParamForLaunchApp,
  FilterNodeParamForBrowseApp,
  FilterNodeParamForEvent,
  FilterNodeParamForConversion,
  FilterNodeParamForContact,
  FilterNodeParamForEventWithoutAttribute,
  FilterNodeParamForLaunchAppWithoutAttribute,
  FilterNodeParamForOrNode
} from "@/api/apis/ApiSearch";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { FilterConversionAttributeCondition } from "@/models/search/filter-node-condition/FilterConversionAttributeCondition";
import SelectOption from "@/components/form/SelectOption";
import { MatchMethod } from "@/models/search/MatchMethod";

export type FilterNode =
  | FilterNodeForOrNode
  | FilterNodeForInflow
  | FilterNodeForConversion
  | FilterNodeForBrowseSite
  | FilterNodeForEvent
  | FilterNodeForLaunchApp
  | FilterNodeForBrowseApp
  | FilterNodeForContact
  | FilterNodeForBusinessEvent;

export enum FilterNodeType {
  Inflow,
  BrowseSite,
  LaunchApp,
  BrowseApp,
  Event,
  Conversion,
  Contact,
  BusinessEvent,
  OrNode
}

export enum ActivityEdgeType {
  Ad = 1,
  SearchEngine = 2,
  Referrer = 3,
  BrowseSite = 4,
  LaunchApp = 5,
  BrowseApp = 6,
  EventWithoutAttribute = 7,
  Conversion = 8,
  Contact = 9,
  BusinessEvent = 10,
  InflowParameter = 11,
  Event = 12,
  OrNode = 13
}

export function convertFilterNodeToJson(
  node: FilterNode,
  globalConversionDefinitions: ConversionDefinition[]
): FilterNodeParam {
  if (node instanceof FilterNodeForOrNode) {
    return convertFilterNodeForOrNodeToJson(node, globalConversionDefinitions);
  }

  if (node instanceof FilterNodeForInflow) {
    return convertFilterNodeForInflowToJson(node);
  }

  if (node instanceof FilterNodeForConversion) {
    return convertFilterNodeForConversionToJson(node);
  }

  if (node instanceof FilterNodeForBrowseSite) {
    return convertFilterNodeForBrowseSiteToJson(node);
  }

  if (node instanceof FilterNodeForEvent) {
    return convertFilterNodeForEventToJson(node);
  }

  if (node instanceof FilterNodeForLaunchApp) {
    return convertFilterNodeForLaunchAppToJson(
      node,
      globalConversionDefinitions
    );
  }

  if (node instanceof FilterNodeForBrowseApp) {
    return convertFilterNodeForBrowseAppToJson(node);
  }

  if (node instanceof FilterNodeForContact) {
    return convertFilterNodeForContactToJson(node);
  }

  return convertFilterNodeForBusinessEventToJson(node);
}

export function convertJsonToFilterNodeForDeepCopy(
  json: FilterNodeParam,
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionDefinitions: ConversionDefinition[],
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterNode | null {
  if (json.activity_type === ActivityEdgeType.OrNode) {
    return convertJsonToFilterNodeForOrNode(
      json as FilterNodeParamForOrNode,
      canUseWebdataFeatures,
      isContractApp,
      activeDefinitions,
      globalConversionAttributeDefinitions
    );
  }
  if (
    json.activity_type === ActivityEdgeType.Referrer ||
    json.activity_type === ActivityEdgeType.InflowParameter ||
    json.activity_type === ActivityEdgeType.SearchEngine ||
    json.activity_type === ActivityEdgeType.Ad
  ) {
    return convertJsonToFilterNodeForInflow(json as FilterNodeParamForInflow);
  }
  if (json.activity_type === ActivityEdgeType.BrowseSite) {
    return convertJsonToFilterNodeForBrowseSite(
      json as FilterNodeParamForBrowseSite
    );
  }
  if (json.activity_type === ActivityEdgeType.LaunchApp) {
    return convertJsonToFilterNodeForLaunchApp(
      json as FilterNodeParamForLaunchApp,
      globalConversionAttributeDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.BrowseApp) {
    return convertJsonToFilterNodeForBrowseApp(
      json as FilterNodeParamForBrowseApp
    );
  }
  if (json.activity_type === ActivityEdgeType.Event) {
    return convertJsonToFilterNodeForEvent(
      json as FilterNodeParamForEvent,
      [],
      [],
      false
    );
  }
  if (json.activity_type === ActivityEdgeType.Conversion) {
    const cvJson = json as FilterNodeParamForConversion;
    // 条件のconversion idが、global conversionだった場合は、FilterNodeForLaunchAppを返す
    if (globalConversionDefinitions.some(def => def.id === cvJson.cv.id)) {
      return convertConversionJsonToFilterNodeForLaunchApp(
        cvJson,
        globalConversionDefinitions
      );
    }

    return convertJsonToFilterNodeForConversion(cvJson, [], [], false);
  }
  if (json.activity_type === ActivityEdgeType.Contact) {
    return convertJsonToFilterNodeForContact(
      json as FilterNodeParamForContact,
      [],
      false
    );
  }
  if (json.activity_type === ActivityEdgeType.BusinessEvent) {
    return convertJsonToFilterNodeForBusinessEvent(
      json as FilterNodeParamForBusinessEvent,
      [],
      false
    );
  }
  return null;
}

export function convertJsonToFilterNode(
  json: FilterNodeParam,
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterNode | null {
  if (
    json.activity_type === ActivityEdgeType.Referrer ||
    json.activity_type === ActivityEdgeType.InflowParameter ||
    json.activity_type === ActivityEdgeType.SearchEngine ||
    json.activity_type === ActivityEdgeType.Ad
  ) {
    if (!canUseWebdataFeatures) {
      return null;
    }
    return convertJsonToFilterNodeForInflow(json as FilterNodeParamForInflow);
  }

  if (json.activity_type === ActivityEdgeType.BrowseSite) {
    if (!canUseWebdataFeatures) {
      return null;
    }
    return convertJsonToFilterNodeForBrowseSite(
      json as FilterNodeParamForBrowseSite
    );
  }
  if (json.activity_type === ActivityEdgeType.LaunchApp) {
    if (!isContractApp) {
      return null;
    }

    return convertJsonToFilterNodeForLaunchApp(
      convertJsonToFilterNodeParamForLaunchApp(
        json as
          | FilterNodeParamForLaunchApp
          | FilterNodeParamForLaunchAppWithoutAttribute
      ),
      globalConversionAttributeDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.BrowseApp) {
    if (!isContractApp) {
      return null;
    }
    return convertJsonToFilterNodeForBrowseApp(
      json as FilterNodeParamForBrowseApp
    );
  }
  if (json.activity_type === ActivityEdgeType.EventWithoutAttribute) {
    const convertedJson: FilterNodeParamForEvent = convertToEventFromEventWithoutAttribute(
      json as FilterNodeParamForEventWithoutAttribute
    );
    return convertJsonToFilterNodeForEvent(
      convertedJson,
      activeDefinitions.eventDefinitions,
      activeDefinitions.conversionAttributeDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.Conversion) {
    const cvJson = json as FilterNodeParamForConversion;
    // 条件のconversion idが、global conversionだった場合は、FilterNodeForLaunchAppを返す
    if (
      activeDefinitions.globalConversionDefinitions.some(
        def => def.id === cvJson.cv.id
      )
    ) {
      if (!isContractApp) {
        return null;
      }
      return convertConversionJsonToFilterNodeForLaunchApp(
        cvJson,
        activeDefinitions.globalConversionDefinitions
      );
    }

    return convertJsonToFilterNodeForConversion(
      cvJson,
      activeDefinitions.conversionDefinitions,
      activeDefinitions.conversionAttributeDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.Contact) {
    return convertJsonToFilterNodeForContact(
      json as FilterNodeParamForContact,
      activeDefinitions.contactDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.BusinessEvent) {
    return convertJsonToFilterNodeForBusinessEvent(
      json as FilterNodeParamForBusinessEvent,
      activeDefinitions.businessEventDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.Event) {
    return convertJsonToFilterNodeForEvent(
      json as FilterNodeParamForEvent,
      activeDefinitions.eventDefinitions,
      activeDefinitions.conversionAttributeDefinitions
    );
  }
  if (json.activity_type === ActivityEdgeType.OrNode) {
    return convertJsonToFilterNodeForOrNode(
      json as FilterNodeParamForOrNode,
      canUseWebdataFeatures,
      isContractApp,
      activeDefinitions,
      globalConversionAttributeDefinitions
    );
  }
  return null;
}

export function firstTimePeriodSelectOption(
  currentCondition: FilterAdditionalCondition,
  isFirstNode: boolean,
  conditions: FilterAdditionalCondition[],
  disabled = false
): SelectOptionGroup {
  const hasFirstTime =
    conditions.filter(c => c instanceof FilterFirstTimeCondition).length > 0;

  const hasPeriod =
    conditions.filter(c => c instanceof FilterPeriodCondition).length > 0;

  const hasDateHour =
    conditions.filter(c => c instanceof FilterDateHourCondition).length > 0;

  const currentIsPeriodOrDateHour =
    currentCondition instanceof FilterPeriodCondition ||
    currentCondition instanceof FilterDateHourCondition;

  let disabledFirstTime = true;
  let disabledPeriodOrDateHour = true;

  if (!disabled) {
    // 初回は、既に初回が選択されている、1番先頭のノードじゃない場合disable
    disabledFirstTime = hasFirstTime || !isFirstNode;

    /**
     * 期間、日付は、どちらか片方が選択されていたらdisabledになる
     * ただし、現在選択中ものが期間か日付なら、期間と日付を切り替えれる必要がるのでdisableにしない
     */
    disabledPeriodOrDateHour =
      !currentIsPeriodOrDateHour && (hasPeriod || hasDateHour);
  }

  return {
    label: i18n.t("models.search.startPointPeriod") as string,
    options: [
      {
        value: FilterNodeConditionType.FirstTime,
        label: i18n.t("models.search.firstPoint") as string,
        disabled: disabledFirstTime
      },
      {
        value: FilterNodeConditionType.Period,
        label: i18n.t("models.search.period") as string,
        disabled: disabledPeriodOrDateHour
      },
      {
        value: FilterNodeConditionType.DateHour,
        label: i18n.t("models.search.dateTime") as string,
        disabled: disabledPeriodOrDateHour
      }
    ]
  };
}

export function validateConditions(
  additionalConditions: FilterAdditionalCondition[],
  requiredParamValidationResult: ValidationResult | null = null
): ValidationResult {
  const errorMessages: string[] = [];

  if (
    requiredParamValidationResult !== null &&
    !requiredParamValidationResult.isValid &&
    requiredParamValidationResult.errorMessage
  ) {
    errorMessages.push(requiredParamValidationResult.errorMessage);
  }

  additionalConditions.forEach(cnd => {
    const v = cnd.validate;
    if (!v.isValid && v.errorMessage) {
      errorMessages.push(v.errorMessage);
    }
  });

  if (errorMessages.length === 0) {
    return { isValid: true };
  }

  return {
    isValid: false,
    errorMessage: errorMessages.join("\n")
  };
}

export function convertToEventFromEventWithoutAttribute(
  json: FilterNodeParamForEventWithoutAttribute
): FilterNodeParamForEvent {
  const convertedJson: FilterNodeParamForEvent = {
    activity_edge: json.activity_edge,
    activity_type: ActivityEdgeType.Event,
    event: {
      id: json.event_id,
      attributes: []
    }
  };

  if (json.dates) {
    convertedJson.dates = json.dates;
  }
  if (json.is_in_first_visit) {
    convertedJson.is_in_first_visit = json.is_in_first_visit;
  }

  return convertedJson;
}

export function convertJsonToFilterNodeParamForLaunchApp(
  json:
    | FilterNodeParamForLaunchApp
    | FilterNodeParamForLaunchAppWithoutAttribute
): FilterNodeParamForLaunchApp {
  if ("launch_attributes" in json) {
    return json as FilterNodeParamForLaunchApp;
  }

  // launch_attributesを追加してFilterNodeParamForLaunchAppにする
  (json as FilterNodeParamForLaunchApp).launch_attributes = [];
  return json as FilterNodeParamForLaunchApp;
}

export function convertAttributeDefinitionsToSelectOptions(
  definitions: ConversionAttributeDefinition[],
  condition: FilterNodeForConversion | FilterNodeForEvent
): SelectOption[] {
  return definitions.map(definition => {
    const conversionAttributeConditions = condition.additionalConditions
      .filter(con => con instanceof FilterConversionAttributeCondition)
      .map(con => con as FilterConversionAttributeCondition);

    const isOptionSelected = conversionAttributeConditions.some(
      con => con.conversionAttributeDefinitionId === definition.id
    );

    return {
      label: definition.name,
      value: definition.id,
      disabled: isOptionSelected
    };
  });
}

export function initSelectedConversionAttributeCondition(
  definitions: ConversionAttributeDefinition[],
  condition: FilterNodeForConversion | FilterNodeForEvent
): FilterConversionAttributeCondition {
  const conversionAttributeConditions = condition.additionalConditions
    .filter(con => con instanceof FilterConversionAttributeCondition)
    .map(con => con as FilterConversionAttributeCondition);

  const conversionAttributeDefinitionIds = conversionAttributeConditions.map(
    con => con.conversionAttributeDefinitionId
  );

  const unselectedDefinitions = definitions.filter(definition => {
    return conversionAttributeDefinitionIds.indexOf(definition.id) == -1;
  });

  const selectedId =
    unselectedDefinitions.length > 0 ? unselectedDefinitions[0].id : -1;

  return new FilterConversionAttributeCondition(
    selectedId,
    "",
    MatchMethod.Partial
  );
}
