<template>
  <SelectBox
    v-model="language"
    class="login__languageSelect"
    :options="availableLanguages"
    @input="changeLanguage"
  />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { AvailableLanguages } from "@/i18n/";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";

@Component({
  components: {
    SelectBox
  }
})
export default class LanguageSelect extends Vue {
  language: string = "0";
  availableLanguages: SelectOption[] = [
    { value: "0", label: "Language", disabled: false },
    { value: AvailableLanguages.ja, label: "日本語", disabled: false },
    {
      value: AvailableLanguages["zh-hant"],
      label: "繁体中文",
      disabled: false
    },
    {
      value: AvailableLanguages["zh-hans"],
      label: "简体中文",
      disabled: false
    },
    { value: AvailableLanguages.en, label: "English", disabled: false }
  ];

  async created() {
    // サーバから返るエラー文言が更新されるように
    this.postLanguage(this.$i18n.locale);
  }

  public changeLanguage() {
    if (this.language !== this.availableLanguages[0].value) {
      this.postLanguage(this.language);
      this.$i18n.locale = this.language;
    }
  }

  async postLanguage(language: string) {
    try {
      await this.$store.state.api.auth.getToken();
      this.$store.state.api.auth.updateLanguage(language);
    } catch (e) {
      // ハンドリング不要
    }
  }
}
</script>
