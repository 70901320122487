<template>
  <div class="iconTextButton">
    <Tooltip
      :active="activeTooltip"
      :color="tooltipColor"
      :text="tooltipText"
      :placement="tooltipPlacement"
    >
      <div
        class="iconTextButton__tooltipContainer"
        :class="[
          !showText
            ? 'iconTextButton__tooltipContainer--withoutLabel'
            : 'iconTextButton__tooltipContainer--withLabel'
        ]"
        @click="onClick"
      >
        <div v-if="showText" class="iconTextButton__label">{{ text }}</div>
        <Icon :icon="icon" :color="Colors.White" :size="28" />
      </div>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement, TooltipColor } from "@/const/tooltip";

@Component({
  components: {
    Icon,
    Tooltip
  }
})
/**
 * This component is intened to be used
 * to display text and an icon with a single button.
 * And you can also display tooltip.
 */
export default class IconTextButton extends Vue {
  Colors = Colors;
  tooltipPlacement = TooltipPlacement.Left;

  @Prop({ type: Boolean, default: true })
  activeTooltip!: boolean;

  @Prop({ type: String, required: true })
  icon!: Icons;

  @Prop({ type: String, default: "" })
  text!: String;

  @Prop({ type: String, default: TooltipColor.Dark })
  tooltipColor!: TooltipColor;

  @Prop({ type: String, default: "" })
  tooltipText!: String;

  @Emit("click")
  onClick() {}

  get showText(): boolean {
    return !this.activeTooltip && this.text !== "";
  }
}
</script>

<style lang="scss" scoped>
.iconTextButton__tooltipContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 28px;
  background: $colorBase800;
  border: 2px solid $colorWhite;
  color: $colorWhite;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: $colorBase900;
  }
}

.iconTextButton__tooltipContainer--withLabel {
  padding: 0 14px;
}

.iconTextButton__tooltipContainer--withoutLabel {
  width: 56px;
}

.iconTextButton__label {
  margin-right: 8px;
  line-height: 1.5;
  white-space: pre-wrap;
}
</style>
