<i18n
  src="@/i18n/components/client-settings/conversion/bulk-conversion-edit-table.json"
></i18n>
<template>
  <div class="bulkConversionCreateTable">
    <div class="bulkConversionCreateTable__errors">
      <div
        v-for="error in groupedErrors"
        :key="error.message"
        class="bulkConversionCreateTable__errorText"
      >
        <span
          >{{ $t("Row") }} {{ error.rows.join(", ") }}:
          {{ error.message }}</span
        >
      </div>
    </div>
    <SpreadSheetForm
      ref="spreadsheetForm"
      :columns="columns"
      :number-of-columns="columns.length"
      :number-of-rows="numberOfRowsToDisplay"
      :initial-data="bulkConversionData"
      @data-changed="onBulkDataChanged"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Model, Prop } from "vue-property-decorator";
import { Column } from "jspreadsheet-ce";
import SpreadSheetForm from "@/components/SpreadSheetForm.vue";
import {
  isInValidCvParam,
  MAX_PARAM_LENGTH,
  MAX_NAME_LENGTH
} from "@/models/client-settings/ConversionDefinition";

export interface BulkConversion {
  name: string;
  path_1: string;
  path_2: string;
  path_3: string;
  path_4: string;
  path_5: string;
}

type GroupedError = {
  message: string;
  rows: number[];
};

@Component({
  components: {
    SpreadSheetForm
  }
})
export default class BulkConversionCreateTable extends Vue {
  @Model("bulkDataChanged", { type: Array, required: true })
  bulkConversionData!: BulkConversion[];

  @Prop({ type: Number, required: true })
  numberOfRows!: number;

  columns = [
    {
      title: this.$t("conversionName") as string,
      width: 145,
      type: "text",
      name: "name",
      wordWrap: true
    },
    {
      title: this.$t("conversionParameter", { number: 1 }) as string,
      width: 145,
      type: "text",
      name: "path_1",
      wordWrap: true
    },
    {
      title: this.$t("conversionParameter", { number: 2 }) as string,
      width: 145,
      type: "text",
      name: "path_2",
      wordWrap: true
    },
    {
      title: this.$t("conversionParameter", { number: 3 }) as string,
      width: 145,
      type: "text",
      name: "path_3",
      wordWrap: true
    },
    {
      title: this.$t("conversionParameter", { number: 4 }) as string,
      width: 145,
      type: "text",
      name: "path_4",
      wordWrap: true
    },
    {
      title: this.$t("conversionParameter", { number: 5 }) as string,
      width: 145,
      type: "text",
      name: "path_5",
      wordWrap: true
    }
  ] as Column[];

  errors: { col: number; row: number; message: string }[] = [];
  validBulkConversions: BulkConversion[] = [];

  mounted() {
    this.onBulkDataChanged(this.bulkConversionData);
  }

  onBulkDataChanged(conversions: BulkConversion[]) {
    this.errors = [];
    this.validBulkConversions = [];
    conversions.forEach((conversion, i) => {
      const hasAnyValue = this.hasAnyValue(conversion);

      if (!hasAnyValue) {
        return;
      }

      this.validateConversion(conversion, i);
    });

    this.highlightErrors();
    this.$emit("bulkDataChanged", this.validBulkConversions);
  }

  hasAnyValue(conversion: BulkConversion): boolean {
    return Object.values(conversion).some(value => value !== "");
  }

  validateConversion(conversion: BulkConversion, index: number): void {
    const conversionName = this.normalizeConversionName(conversion.name);
    const conversionParameters = Object.keys(conversion).filter(key =>
      key.startsWith("path_")
    );

    const hasAtLeastOneParameter = conversionParameters.some(
      key => conversion[key as keyof BulkConversion] !== ""
    );

    if (!conversionName && hasAtLeastOneParameter) {
      this.errors.push({
        col: 0,
        row: index,
        message: this.$t("conversionNameEmptyError") as string
      });
    } else if (conversionName && conversionName.length > MAX_NAME_LENGTH) {
      this.errors.push({
        col: 0,
        row: index,
        message: this.$t("conversionNameCharacterLimitError") as string
      });
    }

    if (conversionName && !hasAtLeastOneParameter) {
      this.errors.push({
        col: 1,
        row: index,
        message: this.$t("conversionParameterEmptyError") as string
      });
    }
    this.validateConversionParameters(conversion, conversionParameters, index);
  }

  validateConversionParameters(
    conversion: BulkConversion,
    conversionParameters: string[],
    index: number
  ): void {
    const hasInvalidParameter = conversionParameters.some((parameterKey, j) => {
      const parameter = conversion[parameterKey as keyof BulkConversion];
      if (typeof parameter === "string" && isInValidCvParam(parameter)) {
        this.errors.push({
          col: j + 1,
          row: index,
          message: this.$t("conversionParameterError") as string
        });
      } else if (
        typeof parameter === "string" &&
        parameter.length > MAX_PARAM_LENGTH
      ) {
        this.errors.push({
          col: j + 1,
          row: index,
          message: this.$t("conversionParameterCharacterLimitError") as string
        });
      }
      return false;
    });

    if (!hasInvalidParameter) {
      this.validBulkConversions.push(conversion);
    }
  }

  normalizeConversionName(name: string): string {
    return name.replace(/\s+/g, " ").trim();
  }

  highlightErrors(): void {
    const spreadsheetForm = this.$refs.spreadsheetForm as Vue & SpreadSheetForm;
    spreadsheetForm.clearErrorHighlights();
    this.errors.forEach(error => {
      spreadsheetForm.highlightErrorCell(error.col, error.row);
    });
  }

  get groupedErrors(): GroupedError[] {
    const errorMap: { [key: string]: GroupedError } = {};

    this.errors.forEach(error => {
      const key = error.message;
      if (errorMap[key]) {
        if (!errorMap[key].rows.includes(error.row + 1)) {
          errorMap[key].rows.push(error.row + 1);
        }
      } else {
        errorMap[key] = {
          message: error.message,
          rows: [error.row + 1]
        };
      }
    });

    return Object.values(errorMap).map(error => ({
      ...error,
      rows: error.rows.sort((a, b) => a - b)
    }));
  }

  get numberOfRowsToDisplay(): number {
    return this.numberOfRows > 50 ? 50 : this.numberOfRows;
  }
}
</script>
<style lang="scss" scoped>
.bulkConversionCreateTable__errors {
  margin-bottom: 16px;
  color: $colorError;
}
.bulkConversionCreateTable__errorText {
  margin-bottom: 5px;
}
</style>
