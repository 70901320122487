const isBrowser = typeof window !== "undefined";

const stopScrollClass = "stop-scroll";

export function stopWindowScroll() {
  if (isBrowser) {
    document.body.classList.add(stopScrollClass);
  }
}

export function startWindowScroll(): void {
  if (isBrowser) {
    document.body.classList.remove(stopScrollClass);
  }
}
