<i18n
  src="@/i18n/views/client-settings/td-web-import-config-details.json"
></i18n>
<template>
  <div class="tdWebImportConfigDetails">
    <div
      ref="pageTitle"
      v-t="'tdWebImportConfigSetting'"
      class="tdWebImportConfigDetails__title"
    />
    <div
      v-if="!isEditingConfig || isConfirm"
      class="tdWebImportConfigDetails__description"
      :class="{ tdWebImportConfigDetails__confrimDescription: isConfirm }"
    >
      <div v-if="!isEditingConfig">
        <div v-t="'description'" />
      </div>
      <div v-else-if="isConfirm">
        <div
          v-t="
            isTdWebImportConfigDefined
              ? 'confirmUpdateDescription'
              : 'confirmRegisterDescription'
          "
        />
      </div>
    </div>

    <div v-if="successMessage" class="setting-success-message">
      {{ successMessage }}
    </div>
    <div
      v-if="!isEditingConfig"
      class="tdWebImportConfigDetails__buttonContainer"
    >
      <Button
        v-t="isTdWebImportConfigDefined ? 'editTdWeb' : 'configureTdWeb'"
        class="tdWebImportConfigDetails__configureButton"
        data-cy="configure-td-web-button"
        :text-size="buttonTextSize"
        @click="onClickConfigureTdWeb"
      />
    </div>

    <div v-if="isLoading" class="tdWebImportConfigDetails__loading">
      <Loading height="80px" />
    </div>
    <div v-else class="tdWebImportConfigDetails__table">
      <SettingsTable>
        <template #header>
          <SettingsTableRow>
            <SettingsTableHeaderCell v-t="'itemName'" text-align="center" />
            <SettingsTableHeaderCell
              v-t="isEditingConfig ? 'csvColumnName' : 'columnDescription'"
              text-align="center"
            />
            <SettingsTableHeaderCell
              v-t="isEditingConfig ? 'columnDescription' : 'csvColumnName'"
              text-align="center"
            />
          </SettingsTableRow>
        </template>
        <template v-if="!isConfirm">
          <SettingsTableRow
            v-for="(val, key) in tdWebImportConfig"
            :key="key"
            class="tdWebImportConfigDetails__row"
          >
            <SettingsTableCell text-align="left">
              {{ $t(key + ".name") }}
              <div
                v-if="shouldDisplayRequireText(key) && isEditingConfig"
                v-t="'required'"
                class="tdWebImportConfigDetails__cell--required"
              />
              <div
                v-if="!shouldDisplayRequireText(key) && isEditingConfig"
                v-t="'optional'"
                class="tdWebImportConfigDetails__cell--optional"
              />
            </SettingsTableCell>

            <SettingsTableCell
              v-if="isEditingConfig"
              class="tdWebImportConfigDetails__cell--form-input"
              :width="'240px'"
            >
              <SelectBox
                v-if="key === 'timestampFormatType'"
                class="tdWebImportConfigDetails__timeStampSelect"
                :has-error="
                  isContainInvalidColumnName(val, key) && isValidationError
                "
                :options="timestampFormatOptions"
                :value="tdWebImportConfig[key]"
                :width="'100%'"
                @input="updateTdWebImportConfig(key, $event)"
              />
              <InputText
                v-else
                :value="tdWebImportConfig[key]"
                :type="'text'"
                :has-error="
                  isContainInvalidColumnName(val, key) && isValidationError
                "
                class="tdWebImportConfigDetails__formInput"
                @input="updateTdWebImportConfig(key, $event)"
              />
              <div
                v-if="
                  isInvalidColumnName(tdWebImportConfig[key]) &&
                    shouldDisplayRequireText(key) &&
                    isValidationError
                "
                v-t="'emptyError'"
                class="tdWebImportConfigDetails__errorMessage"
              />
              <div
                v-if="isTextContainsInvalidChracter(val) && isValidationError"
                v-t="'invalidCharactersError'"
                class="tdWebImportConfigDetails__errorMessage"
              />
              <div
                v-if="
                  isColumnNameExceedFortyCharacters(val) && isValidationError
                "
                v-t="'colmunNameMaxLengthError'"
                class="tdWebImportConfigDetails__errorMessage"
              />
            </SettingsTableCell>

            <SettingsTableCell
              text-align="left"
              class="tdWebImportConfigDetails__descriptionText"
            >
              <span>{{ descriptionText(key) }}</span>
            </SettingsTableCell>

            <SettingsTableCell v-if="!isEditingConfig" text-align="center"
              >{{ getCsvColumnName(key, val) }}
            </SettingsTableCell>
          </SettingsTableRow>
        </template>
        <template v-else>
          <SettingsTableRow
            v-for="(val, key) in tdWebImportConfig"
            :key="key"
            class="tdWebImportConfigDetails__row--confirm"
          >
            <SettingsTableCell
              v-if="tdWebImportConfig[key].length != 0"
              text-align="left"
              class="tdWebImportConfigDetails__nameCol--confirm"
            >
              {{ $t(key + ".name") }}
            </SettingsTableCell>
            <SettingsTableCell
              v-if="tdWebImportConfig[key].length != 0"
              text-align="center"
              class="tdWebImportConfigDetails__valCol--confirm"
              >{{ getCsvColumnName(key, val) }}
            </SettingsTableCell>

            <SettingsTableCell
              v-if="tdWebImportConfig[key].length != 0"
              text-align="left"
              class="tdWebImportConfigDetails__descriptionText"
            >
              <span>{{ descriptionText(key) }}</span>
            </SettingsTableCell>
          </SettingsTableRow>
        </template>
      </SettingsTable>
    </div>
    <div v-if="isEditingConfig">
      <Button
        v-if="isConfirm"
        v-t="isTdWebImportConfigDefined ? 'update' : 'submit'"
        class="
          tdWebImportConfigDetails__submitButton
          tdWebImportConfigDetails__button
        "
        :disabled="isSubmitting"
        :text-size="buttonTextSize"
        @click="submitTdWebConfig"
      />
      <Button
        v-else
        v-t="'confirm'"
        class="
          tdWebImportConfigDetails__confirmButton
          tdWebImportConfigDetails__button
        "
        :text-size="buttonTextSize"
        @click="onclickConfirm"
      />
      <Button
        v-t="'cancel'"
        class="
          tdWebImportConfigDetails__cancelButton
          tdWebImportConfigDetails__button
        "
        :text-size="buttonTextSize"
        :type="buttonTypeLight"
        @click="cancelTdWebConfig"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableHeaderCell from "@/components/table/SettingsTableHeaderCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import Button from "@/components/Button.vue";
import { ButtonTextSize } from "@/const/button";
import { BUTTON_TYPE } from "@/const/button";
import InputText from "@/components/form/InputText.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import { handleError } from "@/util/error-util";
import Loading from "@/components/Loading.vue";
import {
  TdWebImportConfigDefinition,
  TimestampFormatType
} from "@/models/client-settings/TdWebImportConfigDefinition";
import { isEnglishText } from "@/util/common-util";

export const EMPTY_TIMESTAMP_FORMAT_VALUE = 0;

@Component({
  components: {
    SettingsTable,
    SettingsTableRow,
    SettingsTableHeaderCell,
    SettingsTableCell,
    Button,
    InputText,
    SelectBox,
    Loading
  }
})
export default class TdWebImportConfigDetails extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isLoading: boolean = true;
  isEditingConfig: boolean = false;
  isConfirm: boolean = false;
  isSubmitting = false;
  isTdWebImportConfigDefined: boolean = false;
  successMessage: string = "";
  buttonTypeLight = BUTTON_TYPE.LIGHT;
  defaultScrollOptions = {
    duration: 300,
    easing: "ease"
  };
  isValidationError: boolean = false;
  originalTdWebImportConfig: TdWebImportConfigDefinition | null = null;

  get tdWebImportConfig(): TdWebImportConfigDefinition {
    return this.$store.state.tdWebImportConfig.tdWebImportConfigDetails;
  }

  descriptionText(key: string): string {
    return this.$t(key + ".description") as string;
  }

  getCsvColumnName(key: string, value: string): string {
    let translationKey;
    if (value === null || value === "") {
      translationKey = "notSet";
    } else if (key === "timestampFormatType") {
      translationKey = key + "." + value;
    } else {
      return value;
    }
    return this.$t(translationKey) as string;
  }

  isTextContainsInvalidChracter(value: string): boolean {
    return !isEnglishText(value);
  }

  isColumnNameExceedFortyCharacters(value: string): boolean {
    return value.length > 40;
  }

  isContainInvalidColumnName(val: string, key: string): boolean {
    return (
      (this.isInvalidColumnName(val) && this.shouldDisplayRequireText(key)) ||
      this.isTextContainsInvalidChracter(val) ||
      this.isColumnNameExceedFortyCharacters(val)
    );
  }
  updateTdWebImportConfig(key: keyof TdWebImportConfigDefinition, value: any) {
    this.isValidationError = false;

    this.$store.commit(
      "tdWebImportConfig/setTdWebImportConfigDetails",
      this.tdWebImportConfig.update(key, value)
    );
  }

  async created() {
    await this.$store
      .dispatch("tdWebImportConfig/fetchTdWebImportConfigDetail")
      .then(() => {
        this.isLoading = false;
        const tdWebImportConfig = this.$store.state.tdWebImportConfig
          .tdWebImportConfigDetails;
        this.originalTdWebImportConfig = tdWebImportConfig.copy();
      })
      .catch(handleError);
    this.isTdWebImportConfigDefined =
      !!this.tdWebImportConfig && this.tdWebImportConfig.isDefined();
  }

  onclickConfirm() {
    Object.entries(this.tdWebImportConfig).forEach(([key, value]) => {
      if (this.isContainInvalidColumnName(value, key)) {
        this.isValidationError = true;
      }
    });

    if (!this.isValidationError) {
      this.isConfirm = true;
      this.scrollToPageTitle();
    } else {
      this.scrollToTable();
    }
  }

  onClickConfigureTdWeb() {
    this.isEditingConfig = true;
    this.successMessage = "";
    this.scrollToTable();
  }

  async submitTdWebConfig() {
    this.isSubmitting = true;
    try {
      await this.$store.dispatch(
        "tdWebImportConfig/updateTdWebImportConfig",
        this.tdWebImportConfig
      );
      this.successMessage = this.isTdWebImportConfigDefined
        ? this.$t("updateSuccessMessage").toString()
        : this.$t("createSuccessMessage").toString();
      this.isTdWebImportConfigDefined = true;
      this.scrollToTop();
    } catch (e) {
      this.successMessage = "";
      handleError(e);
    } finally {
      this.isEditingConfig = false;
      this.isConfirm = false;
      this.isSubmitting = false;
    }
  }

  cancelTdWebConfig() {
    if (this.isConfirm) {
      this.isConfirm = false;
    } else {
      this.isEditingConfig = false;
      this.$store.commit(
        "tdWebImportConfig/setTdWebImportConfigDetails",
        this.originalTdWebImportConfig
      );
    }

    this.scrollToPageTitle();
  }

  isInvalidColumnName(
    columnName: string | TimestampFormatType | number
  ): boolean {
    return columnName === "" || columnName === EMPTY_TIMESTAMP_FORMAT_VALUE;
  }

  timestampFormatOptions: SelectOption[] = [
    {
      value: EMPTY_TIMESTAMP_FORMAT_VALUE,
      label: "-----------",
      disabled: false
    },
    {
      value: TimestampFormatType.UnixTimeSecond,
      label: this.$i18n.t("unixTimeSeconds") as string,
      disabled: false
    },
    {
      value: TimestampFormatType.UnixTimeMiliSecond,
      label: this.$i18n.t("unixTimeMilliseconds") as string,
      disabled: false
    },
    {
      value: TimestampFormatType.DateAndTime,
      label: this.$i18n.t("dateTime") as string,
      disabled: false
    },
    {
      value: TimestampFormatType.DateTimeWithTimezone,
      label: this.$i18n.t("dateTimeWithTimezone") as string,
      disabled: false
    }
  ];

  shouldDisplayRequireText(key: string): boolean {
    if (
      key == "navigationTypeColumn" ||
      key == "screenResolutionColumn" ||
      key == "ipAddressColumn"
    ) {
      return false;
    }

    return true;
  }

  scrollToTop() {
    this.$scrollTo("body", this.defaultScrollOptions);
  }

  scrollToPageTitle() {
    this.$scrollTo(".tdWebImportConfigDetails__title", {
      ...this.defaultScrollOptions,
      offset: -20
    });
  }

  scrollToTable() {
    this.$scrollTo(".tdWebImportConfigDetails__title", {
      ...this.defaultScrollOptions,
      offset: (this.$refs.pageTitle as Element).clientHeight - 20
    });
  }
}
</script>

<style lang="scss" scoped>
.tdWebImportConfigDetails {
  margin-right: 20px;
  min-width: 704px;
}
.tdWebImportConfigDetails__title {
  padding: 0 0 46px 0;
  font-size: 20px;
  font-weight: 700;
}
.tdWebImportConfigDetails__description {
  padding-bottom: 40px;
  white-space: pre-line;
  font-size: 12px;
  line-height: 24px;
}

.tdWebImportConfigDetails__description
  ::v-deep
  .tdWebImportConfigDetails__link {
  color: $colorBase700;
  text-decoration: underline;
}

.tdWebImportConfigDetails__confrimDescription {
  font-size: 14px;
  line-height: 1;
  padding-bottom: 30px;
}

.setting-success-message {
  color: $colorBlue900;
  margin-bottom: 30px;
  font-size: 14px;
}
.tdWebImportConfigDetails__buttonContainer {
  margin-bottom: 30px;
}
.tdWebImportConfigDetails__configureButton {
  min-width: 120px;
  padding: 0 20px;
}
.tdWebImportConfigDetails__table {
  margin-bottom: 16px;
  width: 680px;
}
.tdWebImportConfigDetails__timeStampSelect {
  width: 100%;
}
.tdWebImportConfigDetails__cell--required,
.tdWebImportConfigDetails__cell--optional {
  display: inline;
  float: right;
  margin-left: 10px;
}
.tdWebImportConfigDetails__cell--required {
  color: $colorRedForNote;
}
.tdWebImportConfigDetails__cell--optional {
  color: $colorBase900;
}
.tdWebImportConfigDetails__button {
  min-width: 120px;
}
.tdWebImportConfigDetails__cancelButton {
  margin-left: 15px;
}
.tdWebImportConfigDetails__errorMessage {
  color: $colorError;
}
.tdWebImportConfigDetails__cell--form-input {
  max-width: 250px;
}

.tdWebImportConfigDetails__descriptionText {
  white-space: pre-line;
}
</style>
