<i18n src="@/i18n/views/wordcloud.json"></i18n>
<template>
  <div class="wordcloud-container">
    <div class="title">
      {{ $t("wordcloudName") }} (BETA)
      <Tooltip :text="$t('help')">
        <Icon :icon="iconHelp" :color="iconHelpColor" :size="12" />
      </Tooltip>
    </div>
    <div class="wordcloud-area" :class="{ active: isLoading | !showButton }">
      <Loading v-if="isLoading" class="loading" height="80px"></Loading>
      <div v-else-if="showButton" class="button">
        <Button
          data-cy="wordcloud-button"
          width="220px"
          height="60px"
          :type="buttonType"
          @click.capture.stop="onClick"
          >{{ $t("display") }}</Button
        >
      </div>
      <div v-else-if="wordcloudData.length > 0">
        <Wordcloud :wordcloud-data="wordcloudData" />
        <div class="data-span">{{ dataSpanLavel }}</div>
      </div>
      <div v-else>
        <div class="no-data">{{ $t("noData") }}</div>
        <div class="data-span">{{ dataSpanLavel }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import { BUTTON_TYPE } from "@/const/button";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import Button from "@/components/Button.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import * as Highcharts from "highcharts";
import Wordcloud from "@/components/Wordcloud.vue";
import { DateFormat, formatDate } from "@/util/date-util";
import { handleError } from "@/util/error-util";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";

interface WordcloudUserResult {
  data: Highcharts.PointOptionsObject[];
  beginDate: Date;
  endDate: Date;
}

export interface WordcloudResult {
  [userId: string]: WordcloudUserResult;
}

@Component({
  components: {
    Loading,
    Button,
    Tooltip,
    Icon,
    Wordcloud
  }
})
export default class WordcloudContainer extends Vue {
  iconHelp = Icons.HelpInvert;
  iconHelpColor = Colors.Base700;
  buttonType: string = BUTTON_TYPE.LIGHT_BLUE;

  @Prop({ type: String, required: true })
  userId!: string;

  onClick() {
    UgTag.pushEvent(UgEventTag.ShowWordcloud);
    this.$store
      .dispatch("wordcloud/fetchWordcloudData", this.userId)
      .catch(error => {
        handleError(error);
        throw new Error(error);
      });
  }

  get showButton(): boolean {
    const results: WordcloudResult = this.$store.state.wordcloud
      .wordcloudResult;
    return !(this.userId in results);
  }

  get isLoading(): boolean {
    return !!this.$store.state.wordcloud.wordcloudLoading[this.userId];
  }

  get userData(): WordcloudUserResult | undefined {
    return this.$store.state.wordcloud.wordcloudResult[this.userId];
  }

  get wordcloudData(): Highcharts.PointOptionsObject[] {
    if (this.userData) {
      return this.userData.data;
    }
    return [];
  }

  get dataSpanLavel(): string {
    if (this.userData) {
      return (
        "( " +
        this.$i18n.t("dataTimeSpan") +
        ": " +
        formatDate(DateFormat.yyyysMsd, this.userData.beginDate) +
        " - " +
        formatDate(DateFormat.yyyysMsd, this.userData.endDate) +
        " )"
      );
    }
    return "";
  }
}
</script>

<style scoped lang="scss">
.wordcloud-container {
  min-width: 360px;
}

.wordcloud-area {
  margin: 0 auto;
  width: 360px;
  transition: 0.5s ease;
  transition-property: width, height;

  &.active {
    width: $wordCloudWidth;
    height: 360px;
  }
}

.title {
  margin: 0 auto;
  padding-bottom: 10px;
  color: $colorBase700;
  text-align: center;
  font-size: 14px;
}

.button {
  margin: 0 auto;
  max-width: 250px;
  > * {
    width: 100%;
  }
}

.loading {
  width: $wordCloudWidth;
}

.data-span {
  color: $colorBase700;
  text-align: right;
  font-size: 12px;
}

.no-data {
  padding-top: 160px;
  width: $wordCloudWidth;
  height: 360px;
  color: $colorBase900;
  text-align: center;
  font-size: 18px;
}
</style>
