<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseSelectBox
    :value="eventDefinitionId"
    :options="options"
    :prefix="$t('eventPrefix')"
    class="filter-event-form"
    @input="onInput"
  />
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import { EventDefinition } from "@/models/client-settings/EventDefinition";

import SelectOption from "@/components/form/SelectOption";
import FilterBaseSelectBox from "@/components/filter/form/FilterBaseSelectBox.vue";

@Component({
  components: {
    FilterBaseSelectBox
  }
})
export default class FilterEventField extends Vue {
  @Model("input", { type: Number, required: true })
  eventDefinitionId!: number;

  @Prop({ type: Array, required: true })
  eventDefinitions!: EventDefinition[];

  onInput(eventDefinitionId: number) {
    this.$emit("input", eventDefinitionId);
  }

  get options(): SelectOption[] {
    return this.eventDefinitions.map(def => {
      return { label: def.name, value: def.id, disabled: false };
    });
  }
}
</script>
