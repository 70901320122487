<template>
  <rect
    class="chartVisitBar"
    :class="{ 'chartVisitBar--showStroke': showStroke }"
    :width="barWidth"
    :height="barHeight"
    :x="barX"
    :y="barY"
    :fill="fill"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { MAGNIFICATION_HOVER_EFFECT } from "@/components/chart/chart-util";

@Component
export default class ChartVisitBar extends Vue {
  @Prop({ type: Number, required: true })
  width!: number;

  @Prop({ type: Number, required: true })
  height!: number;

  @Prop({ type: Number, required: true })
  y!: number;

  @Prop({ type: Number, required: true })
  x!: number;

  @Prop({ type: String, required: true })
  fill!: Colors;

  @Prop({ type: Boolean, required: true })
  showStroke!: boolean;

  @Emit("click")
  onClick() {}

  @Emit("mouseenter")
  onMouseEnter() {
    this.isHovered = true;
  }

  @Emit("mouseleave")
  onMouseLeave() {
    this.isHovered = false;
  }

  isHovered: boolean = false;

  get barWidth(): number {
    return this.isHovered
      ? this.width * MAGNIFICATION_HOVER_EFFECT
      : this.width;
  }

  get barHeight(): number {
    return this.isHovered
      ? this.height * MAGNIFICATION_HOVER_EFFECT
      : this.height;
  }

  get barX(): number {
    return this.isHovered
      ? this.x - (this.width * (MAGNIFICATION_HOVER_EFFECT - 1)) / 2
      : this.x;
  }

  get barY(): number {
    return this.isHovered
      ? this.y - this.height * (MAGNIFICATION_HOVER_EFFECT - 1)
      : this.y;
  }
}
</script>

<style lang="scss" scoped>
.chartVisitBar {
  cursor: pointer;
  transition: 0.15s ease;
  transition-property: width, height, x, y;
}

.chartVisitBar--showStroke {
  stroke-width: 1;
  stroke: $colorWhite;
}
</style>
