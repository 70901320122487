<template>
  <div class="savedSearchBalloon">
    <Balloon :direction="balloonDirection" :adjust="adjust">
      <div class="savedSearchBalloon__inner">
        <PeriodConditionLabel
          :favorite-search="favoriteSearch"
          :editing-label="false"
        />
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Balloon from "@/components/Balloon.vue";
import { BalloonDirection } from "@/const/balloon";
import PeriodConditionLabel from "@/views/PeriodConditionLabel.vue";
import { FavoriteSearch } from "@/models/search/FavoriteSearch";

@Component({
  components: {
    Balloon,
    PeriodConditionLabel
  }
})
export default class SavedSearchBalloon extends Vue {
  balloonDirection: BalloonDirection = BalloonDirection.LeftEnd;

  @Prop({ type: Number, default: 0 })
  adjust!: number;

  @Prop({ type: FavoriteSearch, required: true })
  favoriteSearch!: FavoriteSearch;
}
</script>

<style scoped lang="scss">
.savedSearchBalloon__inner {
  padding: 10px;
  min-width: 259px;
}
</style>
