import { ApiRes } from "@/api/api-res";

/**
 * 顧客ロイヤルティ指標（個別）
 *
 * 顧客ロイヤルティ指標（個別）は、Webサイト満足度などの特定のタッチポイントや商品・サービスに対するユーザの評価を定義した指標です。
 */

export class EnqueteDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    // nameの略称
    public readonly shortName: string,
    // true = インポート中, false = インポート停止
    public readonly isDisabled: boolean
  ) {}

  public static fromJson(json: ApiRes.EnqtDef): EnqueteDefinition {
    return new EnqueteDefinition(
      json.id,
      json.name,
      json.short_name,
      json.is_disabled
    );
  }
}
