<i18n
  src="@/i18n/components/search/engagement-form/rate-of-increase-dialog.json"
></i18n>
<template>
  <Dialog
    class="rateOfIncreaseDialog"
    :visible="visible"
    :show-close-button="false"
    :title="title"
    width="600px"
    top="15vh"
    @opened="onOpened"
    @close="onClose"
  >
    <div class="rateOfIncreaseDialog__container">
      <div class="rateOfIncreaseDialog__row">
        <div class="rateOfIncreaseDialog__head">
          <RadioButton
            v-model="specificationValue"
            :label="specificationLabels[0]"
            name="specification"
          >
            <span>{{ $t("valueSpecification") }}</span>
          </RadioButton>
        </div>

        <div class="rateOfIncreaseDialog__body">
          <div
            v-if="showText(timesOrMoreItemPrefix)"
            class="rateOfIncreaseDialog__prefix"
          >
            {{ timesOrMoreItemPrefix }}
          </div>
          <InputText
            v-model="valueSpecificationValue"
            class="rateOfIncreaseDialog__input"
            @blur="onValueSpecificationValueBlur"
          />
          <div class="rateOfIncreaseDialog__suffix">
            {{ timesOrMoreItemSuffix }}
          </div>
        </div>
      </div>

      <div class="rateOfIncreaseDialog__row">
        <div class="rateOfIncreaseDialog__head">
          <RadioButton
            v-model="specificationValue"
            :label="specificationLabels[1]"
            name="specification"
          >
            <span v-t="'rangeSpecification'" />
          </RadioButton>
        </div>

        <div class="rateOfIncreaseDialog__body">
          <div
            v-if="showText(timesItemPrefix)"
            class="rateOfIncreaseDialog__prefix"
          >
            {{ timesItemPrefix }}
          </div>
          <InputText
            v-model="rangeSpecificationMinValue"
            class="rateOfIncreaseDialog__input"
            @blur="onRangeSpecificationMinValueBlur"
          />
          <div class="rateOfIncreaseDialog__text" v-text="'-'" />
          <InputText
            v-model="rangeSpecificationMaxValue"
            class="rateOfIncreaseDialog__input"
            @blur="onRangeSpecificationMaxValueBlur"
          />
          <div class="rateOfIncreaseDialog__suffix">
            {{ timesItemSuffix }}
          </div>

          <Tooltip class="rateOfIncreaseDialog__tooltip">
            <Icon :icon="icon" :color="color" :size="12" />

            <template #content>
              <div class="rateOfIncreaseDialog__tooltipContent">
                {{ $t("tooltipTitle") }}<br />
                {{ $t("tooltipContent1") }}

                <div class="rateOfIncreaseDialog__example">
                  {{ $t("tooltipContent2") }}<br />
                  {{ $t("tooltipContent3") }}
                </div>
              </div>
            </template>
          </Tooltip>
        </div>
      </div>

      <div v-t="'note'" class="rateOfIncreaseDialog__note" />
    </div>

    <div class="rateOfIncreaseDialog__buttonContaineer">
      <Button v-t="'submit'" width="250px" @click="onSubmit" />
      <Button
        v-t="'cancel'"
        width="250px"
        class="rateOfIncreaseDialog__canselButton"
        type="light"
        @click="onClose"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import {
  FluctuationType,
  FluctuationValue,
  FluctuationValues,
  FLUCTUATION_MAX_VALUES,
  SPECIFICATION_LABELS,
  UNSPECIFIED_FLUCTUATION_VALUE,
  DIRECT_FLUCTUATION_VALUE,
  DIRECT_FLUCTUATION_MIN_VALUE,
  DIRECT_FLUCTUATION_MAX_VALUE
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import {
  getInitialValue,
  getConvertPercentageToTimes,
  getConvertTimesToPercentage
} from "@/util/select-by-engagement-util";
import { showAlert } from "@/util/modal-util";
import RadioButton from "@/components/form/RadioButton.vue";
import InputText from "@/components/form/InputText.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import Button from "@/components/Button.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    RadioButton,
    InputText,
    Dialog,
    Button,
    Tooltip,
    Icon
  }
})
export default class RateOfIncreaseDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  visible!: boolean;

  @Prop({ type: Object, required: true })
  value!: FluctuationValues;

  @Emit("opened")
  onOpened() {
    this.updateValue = this.value;

    const type = this.value.type;

    if (type === FluctuationType.Increase) {
      const { min, max } = getInitialValue(
        this.value.min,
        this.value.max,
        FLUCTUATION_MAX_VALUES
      );

      if (max === null) {
        this.specificationValue = this.specificationLabels[0];
        this.valueSpecificationValue = getConvertPercentageToTimes(min);
      } else {
        this.specificationValue = this.specificationLabels[1];
        this.rangeSpecificationMinValue = getConvertPercentageToTimes(min);
        this.rangeSpecificationMaxValue = getConvertPercentageToTimes(max);
      }
    }
  }

  @Emit("close")
  onClose() {}

  onInput(values: FluctuationValues) {
    this.$emit("input", values);
  }

  updateValue = this.value;

  valueSpecificationValue = DIRECT_FLUCTUATION_MIN_VALUE;
  rangeSpecificationMaxValue = DIRECT_FLUCTUATION_MAX_VALUE;
  rangeSpecificationMinValue = DIRECT_FLUCTUATION_MIN_VALUE;

  specificationLabels = SPECIFICATION_LABELS;
  specificationValue = this.specificationLabels[0];

  icon = Icons.HelpInvertWhite;
  color = Colors.Base700;

  get title(): string {
    return this.$t("title") as string;
  }

  get timesOrMoreItemPrefix(): string {
    return this.$te("timesOrMoreItemPrefix")
      ? (this.$t("timesOrMoreItemPrefix") as string)
      : "";
  }

  get timesOrMoreItemSuffix(): string {
    return this.$t("timesOrMoreItemSuffix") as string;
  }

  get timesItemPrefix(): string {
    return this.$te("timesItemPrefix")
      ? (this.$t("timesItemPrefix") as string)
      : "";
  }

  get timesItemSuffix(): string {
    return this.$t("timesItemSuffix") as string;
  }

  showText(value: string): boolean {
    return value.length > 0;
  }

  getInputValue(value: string): number {
    let result = Number(value);

    if (isNaN(result)) {
      result = DIRECT_FLUCTUATION_MIN_VALUE;
    } else if (value.length !== 0 && result < DIRECT_FLUCTUATION_MIN_VALUE) {
      result = DIRECT_FLUCTUATION_MIN_VALUE;
    } else if (result > DIRECT_FLUCTUATION_MAX_VALUE) {
      result = DIRECT_FLUCTUATION_MAX_VALUE;
    } else if (result === 0) {
      result = DIRECT_FLUCTUATION_MIN_VALUE;
    }

    return Math.floor(result * 100) / 100;
  }

  onValueSpecificationValueBlur(value: string) {
    this.valueSpecificationValue = this.getInputValue(value);
  }

  onRangeSpecificationMaxValueBlur(value: string) {
    this.rangeSpecificationMaxValue = this.getInputValue(value);
  }

  onRangeSpecificationMinValueBlur(value: string) {
    this.rangeSpecificationMinValue = this.getInputValue(value);
  }

  onSubmit() {
    if (this.specificationValue === this.specificationLabels[0]) {
      const min = new FluctuationValue(
        DIRECT_FLUCTUATION_VALUE,
        getConvertTimesToPercentage(this.valueSpecificationValue)
      );

      const max = new FluctuationValue(UNSPECIFIED_FLUCTUATION_VALUE, 0);
      const result: FluctuationValues = {
        min,
        max,
        type: FluctuationType.Increase
      };

      this.onInput(result);
    } else {
      const minPercent = getConvertTimesToPercentage(
        this.rangeSpecificationMinValue
      );
      const maxPercent = getConvertTimesToPercentage(
        this.rangeSpecificationMaxValue
      );

      if (this.rangeSpecificationMinValue >= this.rangeSpecificationMaxValue) {
        showAlert(this.$t("errorLowerHigherUpper") as string);
        return;
      }

      const min = new FluctuationValue(DIRECT_FLUCTUATION_VALUE, minPercent);
      const max = new FluctuationValue(DIRECT_FLUCTUATION_VALUE, maxPercent);

      const result: FluctuationValues = {
        min,
        max,
        type: FluctuationType.Increase
      };

      this.onInput(result);
    }
  }
}
</script>

<style scoped lang="scss">
.rateOfIncreaseDialog__row {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.rateOfIncreaseDialog__head {
  min-width: 135px;
}

.rateOfIncreaseDialog__body {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 8px;
}

.rateOfIncreaseDialog__input {
  width: 60px;
}

.rateOfIncreaseDialog__prefix {
  margin-right: 8px;
}

.rateOfIncreaseDialog__suffix {
  margin-left: 8px;
}

.rateOfIncreaseDialog__text {
  margin: 0 8px;
}

.rateOfIncreaseDialog__tooltip {
  margin-left: 8px;
}

.rateOfIncreaseDialog__tooltipContent {
  line-height: 1.5;
}

.rateOfIncreaseDialog__example {
  margin-top: 10px;
}

.rateOfIncreaseDialog__note {
  margin-top: 25px;
  text-align: center;
}

.rateOfIncreaseDialog__buttonContaineer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.rateOfIncreaseDialog__canselButton {
  margin-left: 15px;
}
</style>
