import {
  FilterCondition,
  convertJsonToFilterCondition
} from "@/models/search/filter-condition/FilterCondition";
import { ApiRes } from "@/api/api-res";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";

export class FilterHistory {
  constructor(
    public readonly id: number,
    public readonly updatedAt: Date,
    public readonly filterCondition: FilterCondition
  ) {}
}
export function buildFilterHistory(
  history: ApiRes.FilterHistory,
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterHistory | null {
  const filterCondition: FilterCondition | null = convertJsonToFilterCondition(
    history.query,
    canUseWebdataFeatures,
    isContractApp,
    activeDefinitions,
    globalConversionAttributeDefinitions
  );

  if (filterCondition === null) {
    return null;
  }

  return new FilterHistory(
    history.id,
    new Date(history.updated_time_msec),
    filterCondition
  );
}
