import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * ビジネスイベントの内容よる条件
 *
 * ビシネスイベントの絞り込みに追加条件として付与できる
 */
export class FilterBusinessEventAttributeCondition {
  public readonly conditionType =
    FilterNodeConditionType.BusinessEventAttribute;

  constructor(
    // 検索対象文字列
    public readonly content: string
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.content,
      i18n.t("models.businessEventAttribute.content") as string
    );
  }
}
