<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="search-history-entry">
    <div class="history-item" @click="onClick(history.selectCondition)">
      <Icon :icon="iconSearch" :size="18" class="search-icon" />
      <div>
        <div class="searchHistoryEntry__head">
          <div class="search-history-entry__item-elapsed">{{
            elapsed(history)
          }}</div>
          <div class="searchHistoryEntry__count">{{
            previousSearchResultCount
          }}</div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import { SearchHistory } from "@/models/search/SearchHistory";
import { getElapsedTxFromNow } from "@/util/date-util";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { MAX_SEARCH_RESULT_COUNT } from "@/store/modules/search";

@Component({ components: { Icon } })
export default class SearchHistoryEntry extends Vue {
  iconSearch = Icons.Search;
  @Prop({ type: SearchHistory, required: true })
  history!: SearchHistory;

  onClick(condition: SelectCondition) {
    this.$emit("click", condition);
  }

  elapsed(searchHistory: SearchHistory): string {
    return getElapsedTxFromNow(searchHistory.updatedAt.getTime());
  }

  get previousSearchResultCount(): string {
    let beforeText = this.$i18n.t("previousSearchResult") as string;

    if (this.history.count > MAX_SEARCH_RESULT_COUNT) {
      return (beforeText +
        this.$i18n.t("moreThanLimit", {
          count: MAX_SEARCH_RESULT_COUNT
        })) as string;
    }

    return (beforeText +
      this.$i18n.tc("userCount", this.history.count, {
        count: this.history.count
      })) as string;
  }
}
</script>
<style lang="scss" scoped>
.history-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 8px 4px 8px;
  width: 100%;
  border-radius: $sizeRadius;
  background-color: $colorGray100;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $colorBlue600;
  }
}

.search-icon {
  margin-right: 10px;
}

.searchHistoryEntry__head {
  display: flex;
  margin-bottom: 2px;
  color: $colorBase600;
  font-size: 12px;
}

.searchHistoryEntry__count {
  margin-left: 10px;
}
</style>
