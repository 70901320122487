<i18n src="@/i18n/components/observation/visit/visit-withdrawal.json"></i18n>

<template>
  <div
    class="ud-Visit_Gram ud-Visit_Gram_Exit"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_Circle ud-Visit_GramIcon_Circle-Exit">
        <Tooltip :text="tooltipText" class="ud-Visit_GramIcon_Tooltip">
          <Icon
            :icon="inflowRight"
            :size="18"
            class="icon"
            :color="iconColor"
          />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";

@Component({
  components: { Tooltip, Icon }
})
export default class GramWithdrawal extends GramBase {
  iconColor = Colors.Base600;
  inflowRight = Icons.InflowRight;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  get tooltipText(): string {
    return this.$i18n.t("tooltip") as string;
  }
}
</script>
