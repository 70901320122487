import { SelectCondition } from "@/models/search/select-condition/SelectCondition";

import {
  SelectByEngagementCondition,
  FieldType,
  AnalysisType,
  FLUCTUATION_MIN_VALUES,
  FLUCTUATION_MAX_VALUES,
  FluctuationValue,
  FluctuationType
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { DateFormat, formatDate } from "@/util/date-util";
import {
  getInitialValue,
  getPercentOfDecrease,
  getConvertPercentageToTimes
} from "@/util/select-by-engagement-util";
import { i18n } from "@/i18n";

function localI18n(key: string): string {
  return i18n.t(`views.selectConditionLabelUtil.${key}`) as string;
}

/**
 * 最終的には、SelectConditionLabel.vueをcomponentsに入れて、
 * storeにアクセスせず必要な物だけを渡すようにしたいが、変更箇所がおおくなりすぎるので
 * 追加機能分だけでもテストできるように、追加関数はこのファイルに書き出す。
 */

/**
 * get start date of the search condition period.
 * if condition does not have date to show return new Date()
 *
 * @param selectCondition SelectCondition
 * @returns date from condition or new Date
 */
export function getStartDateOfSearchConditionPeriod(
  selectCondition: SelectCondition
): Date | null {
  if (selectCondition instanceof SelectByEngagementCondition) {
    return selectCondition.period.min;
  }
  if ("startDate" in selectCondition) {
    return selectCondition.startDate;
  }
  return null;
}
/**
 * get end date of the search condition period.
 * if condition does not have date to show return new Date()
 *
 * @param selectCondition SelectCondition
 * @returns date from condition or new Date
 */
export function getEndDateOfSearchConditionPeriod(
  selectCondition: SelectCondition
): Date | null {
  if (selectCondition instanceof SelectByEngagementCondition) {
    return selectCondition.period.max;
  }
  if ("endDate" in selectCondition) {
    return selectCondition.endDate;
  }
  return null;
}

/**
 * get field type text to display.
 *
 * @param fieldType AllFieldType
 * @param sites MeasurementTargetSite
 * @param maxAvailableSites number
 * @returns string for field type to display
 */
export function getFieldTypeText(
  fieldType: FieldType,
  sites: MeasurementTargetSite[],
  maxAvailableSites: number
): string {
  // アプリの場合を最初に判定する
  if (fieldType === FieldType.AppOnly) {
    return localI18n("allApp");
  }
  // アプリ以外で、ドメイン指定がない場合はウェブすべて
  if (sites.length === 0) {
    return localI18n("allWeb");
  }
  // サイトが選択されているが、全てではない場合は各ドメイン
  if (sites.length < maxAvailableSites) {
    return getTargetSiteListText(sites);
  }
  // 上のケースに該当しない場合はウェブすべて
  return localI18n("allWeb");
}

/**
 * get target site list text to display.
 *
 * @param sites MeasurementTargetSite[]
 * @returns string list of sites to display
 */
export function getTargetSiteListText(sites: MeasurementTargetSite[]): string {
  return sites
    .map(site => (site.description !== "" ? site.description : site.hostPath))
    .join(", ");
}

/**
 * get analysis type text to display.
 *
 * @param analysisType AnalysisType
 * @param cvId: number | null,
 * @param eventId: number | null,
 * @param conversionDefinitions: ConversionDefinition[],
 * @param eventDefinitions: EventDefinition[]
 * @returns string for analysis type to display
 */
export function getAnalysisTypeText(
  analysisType: AnalysisType,
  cvId: number | null,
  eventId: number | null,
  conversionDefinitions: ConversionDefinition[],
  eventDefinitions: EventDefinition[]
): string {
  if (analysisType === AnalysisType.Pv) {
    return localI18n("pv");
  }
  if (analysisType === AnalysisType.AppLaunch) {
    return localI18n("launch");
  }
  if (analysisType === AnalysisType.AppView) {
    return localI18n("screen");
  }
  if (analysisType === AnalysisType.VisitCount) {
    return localI18n("sessionCount");
  }
  if (analysisType === AnalysisType.StayTime) {
    return localI18n("sessionDuration");
  }
  if (analysisType === AnalysisType.Cv) {
    const def = conversionDefinitions.find(def => def.id === cvId);
    if (def !== undefined) {
      return def.name;
    }
  }
  if (analysisType === AnalysisType.Event) {
    const def = eventDefinitions.find(def => def.id === eventId);
    if (def !== undefined) {
      return def.name;
    }
  }
  return "";
}

/**
 * get period range text.
 *
 * @param minDate Date
 * @param maxDate Date
 * @returns string period range text
 */
export function getPeriodRangeText(minDate: Date, maxDate: Date): string {
  const min = formatDate(DateFormat.yyyysMsd, minDate);
  const max = formatDate(DateFormat.yyyysMsd, maxDate);

  return `${min} - ${max}`;
}

/**
 * get text to display increase fluctuation type
 *
 * @param minFluctuationValue FluctuationValue
 * @param maxFluctuationValue FluctuationValue
 * @returns string
 */
export function getIncreaseFluctuationValueText(
  minFluctuationValue: FluctuationValue,
  maxFluctuationValue: FluctuationValue
): string {
  const { min, max } = getInitialValue(
    minFluctuationValue,
    maxFluctuationValue,
    FLUCTUATION_MAX_VALUES
  );

  const suffix =
    max === null
      ? localI18n("increaseTimesOrMoreSuffix")
      : localI18n("increaseTimesSuffix");

  const value =
    max === null
      ? `${getConvertPercentageToTimes(min)}`
      : `${getConvertPercentageToTimes(min)}-${getConvertPercentageToTimes(
          max
        )}`;

  return `${value}${suffix}`;
}

/**
 * get text to display decrease fluctuation type
 *
 * @param minFluctuationValue FluctuationValue
 * @param maxFluctuationValue FluctuationValue
 * @returns string
 */
export function getDecreaseFluctuationValueText(
  minFluctuationValue: FluctuationValue,
  maxFluctuationValue: FluctuationValue
): string {
  const { min, max } = getInitialValue(
    minFluctuationValue,
    maxFluctuationValue,
    FLUCTUATION_MIN_VALUES
  );

  const suffix =
    max === null
      ? localI18n("decreasePercentageOrLessSuffix")
      : localI18n("decreasePercentageSuffix");

  let value = `${getPercentOfDecrease(min)}${suffix}`;

  if (max !== null) {
    value = `${getPercentOfDecrease(min)}-${getPercentOfDecrease(
      max
    )}${suffix}`;
  } else if (min === FLUCTUATION_MIN_VALUES[0]) {
    value = localI18n("10PercentDecrease");
  } else if (min === FLUCTUATION_MIN_VALUES[1]) {
    value = localI18n("50PercentDecrease");
  }

  return `${value}`;
}

/**
 * get text to display fluctuation range
 *
 * @param minFluctuationValue: FluctuationValue
 * @param maxFluctuationValue: FluctuationValue
 * @returns string
 */
export function getFluctuationText(
  minFluctuationValue: FluctuationValue,
  maxFluctuationValue: FluctuationValue,
  fluctuationType: FluctuationType
): string {
  return fluctuationType === FluctuationType.Increase
    ? getIncreaseFluctuationValueText(minFluctuationValue, maxFluctuationValue)
    : getDecreaseFluctuationValueText(minFluctuationValue, maxFluctuationValue);
}

/**
 * get text to display engagement search condition detail
 *
 * @param condition: SelectByEngagementCondition
 * @param conversionDefinitions: ConversionDefinition[]
 * @param eventDefinitions: EventDefinition[]
 * @param maxAvailableSites: number
 * @returns string
 */
export function getEngagementSearchConditionDetailText(
  condition: SelectByEngagementCondition,
  conversionDefinitions: ConversionDefinition[],
  eventDefinitions: EventDefinition[],
  maxAvailableSites: number
): string {
  const field = getFieldTypeText(
    condition.fieldType,
    condition.sites,
    maxAvailableSites
  );
  const analysis = getAnalysisTypeText(
    condition.analysisType,
    condition.conversionDefinitionId,
    condition.eventDefinitionId,
    conversionDefinitions,
    eventDefinitions
  );
  const comparisonPeriod = condition.comparisonPeriod;
  const comparisonPeriodText = getPeriodRangeText(
    comparisonPeriod.min,
    comparisonPeriod.max
  );
  const period = condition.period;
  const periodText = getPeriodRangeText(period.min, period.max);
  const fluctuation = getFluctuationText(
    condition.minFluctuationValue,
    condition.maxFluctuationValue,
    condition.fluctuationType
  );

  return `${analysis} ${fluctuation}, ${comparisonPeriodText}, ${periodText}, ${field}`;
}
