<template>
  <div class="filterNodeInterElement">
    <div class="filterNodeInterElement__arrow arrowIcon">
      <div class="arrowIcon_shaft"></div>
      <div class="arrowIcon_head"></div>
    </div>

    <div class="filterNodeInterElement__right">
      <FilterNodeEdge
        ref="nodeEdge"
        v-model="nodeEdge"
        :is-exclusion-checkbox-enabled="isExclusionCheckboxEnabled"
      />

      <FilterMiddleExclusionNode
        :edge="edge"
        :index="index"
        :left="edgeWidth"
        @add-exclusion-node="onAddExclusionNode"
        @remove-exclusion-node="onRemoveExclusionNode"
      >
        <template #middleExclusionNode>
          <slot name="middleExclusionNode" />
        </template>
      </FilterMiddleExclusionNode>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FilterMiddleExclusionNode from "@/components/filter/FilterMiddleExclusionNode.vue";
import FilterNodeEdge from "@/components/filter/FilterNodeEdge.vue";
import { FilterEdge } from "@/models/search/filter-node/FilterEdge";
import { FilterNodeType } from "@/models/search/filter-node/FilterNode";

@Component({
  name: "FilterNodeInterElement",
  components: {
    FilterNodeEdge,
    FilterMiddleExclusionNode
  }
})
export default class FilterNodeInterElement extends Vue {
  @Prop({ type: Object, required: true })
  edge!: FilterEdge;

  @Prop({ type: Number, required: true })
  index!: number;

  @Prop({ type: Boolean, required: true })
  isExclusionCheckboxEnabled!: boolean;

  edgeWidth: string = "200px";

  $refs!: {
    nodeEdge: FilterNodeEdge;
  };

  get nodeEdge(): FilterEdge {
    return this.edge;
  }
  set nodeEdge(edge: FilterEdge) {
    this.$emit("update-edge", edge);
  }

  onAddExclusionNode(nodeType: FilterNodeType) {
    this.$emit("add-exclusion-node", nodeType);
  }

  onRemoveExclusionNode() {
    this.$emit("remove-exclusion-node");
  }

  mounted() {
    const resizeObserver = new ResizeObserver(entries => {
      this.edgeWidth = `${entries[0].contentRect.width}px`;
    });

    resizeObserver.observe(this.$refs.nodeEdge.$el);
  }
}
</script>

<style lang="scss" scoped>
.filterNodeInterElement {
  display: flex;
  padding: 20px 0;
  width: 100%;
}

.filterNodeInterElement__right {
  flex: 1;
}

.arrowIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  float: left;
}

.arrowIcon_shaft {
  flex-grow: 1;
  width: 10px;
  background-color: rgb(163, 172, 191);
}

.arrowIcon_head {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid rgb(163, 172, 191);
}
</style>
