import { ApiRes } from "@/api/api-res";

/**
 * アイコンの注視度の閾値
 */
export class ClientPvStats {
  constructor(public readonly q1: number, public readonly q3: number) {}

  public get hasStats(): boolean {
    return this.q1 !== undefined && this.q3 !== undefined;
  }

  public static fromJson(json: ApiRes.ClientPvStats): ClientPvStats {
    return new ClientPvStats(json.q1, json.q3);
  }
}
