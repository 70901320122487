import { MutationTree, ActionTree } from "vuex";
import { AnalysisType, DEFAULT_PER_PAGE } from "@/const/user-trend";
import { RootState } from "@/store/";
import {
  webView,
  landing,
  inflow,
  inflowDetail,
  appView
} from "@/store/user-trends/base";
import { UserTrendSearchCondition } from "@/models/user-trend/UserTrendSearchCondition";
import { UserTrendQueryHistory } from "@/api/apis/ApiUserTrend";

export class UserTrendsState {
  condition = UserTrendSearchCondition.defaultCondition();
}

const mutations = <MutationTree<UserTrendsState>>{
  setCondition(state: UserTrendsState, condition: UserTrendSearchCondition) {
    state.condition = condition;
  }
};

const actions = <ActionTree<UserTrendsState, RootState>>{
  resetUserTrendsPerPage({ commit }) {
    commit("web-views/setPerPage", DEFAULT_PER_PAGE);
    commit("landings/setPerPage", DEFAULT_PER_PAGE);
    commit("inflows/setPerPage", DEFAULT_PER_PAGE);
    commit("inflow-details/setPerPage", DEFAULT_PER_PAGE);
    commit("app-views/setPerPage", DEFAULT_PER_PAGE);
  },

  resetUserTrendsData({ commit }) {
    commit("web-views/resetData");
    commit("landings/resetData");
    commit("inflows/resetData");
    commit("inflow-details/resetData");
    commit("app-views/resetData");
  },

  async fetchLatestCondition({ commit, rootState, rootGetters }) {
    const conditions: UserTrendQueryHistory[] = await rootState.api.userTrends.fetchSearchHistory();

    const activeConversions =
      rootGetters["clientSettings/allActiveConversionDefinitions"];

    let condition = UserTrendSearchCondition.defaultCondition(
      activeConversions
    );
    if (conditions && conditions.length > 0) {
      condition = UserTrendSearchCondition.fromJson(
        conditions[0].query,
        activeConversions
      );
    }

    commit("setCondition", condition);

    if (conditions.length > 0) {
      const domain = conditions[0].query.domain;
      commit("inflow-details/setDomain", domain ? domain : null);
    }
  },

  async fetchSearchConditionById(
    { commit, rootState, rootGetters },
    id: string
  ) {
    const response: UserTrendQueryHistory = await rootState.api.userTrends.fetchSearchHistoryById(
      id
    );
    const activeConversions =
      rootGetters["clientSettings/allActiveConversionDefinitions"];

    let condition = UserTrendSearchCondition.defaultCondition(
      activeConversions
    );
    if (response) {
      condition = UserTrendSearchCondition.fromJson(
        response.query,
        activeConversions
      );
    }

    commit("setCondition", condition);

    if (response && response.query) {
      const domain = response.query.domain;
      commit("inflow-details/setDomain", domain ? domain : null);
    }
  }
};

export const userTrends = {
  namespaced: true,
  state: new UserTrendsState(),
  mutations,
  actions,
  modules: {
    [AnalysisType.WebView]: webView,
    [AnalysisType.Landing]: landing,
    [AnalysisType.Inflow]: inflow,
    [AnalysisType.InflowDetail]: inflowDetail,
    [AnalysisType.AppView]: appView
  }
};
