import { NpsDefinition } from "@/models/client-settings/NpsDefinition";

/**
 * NPS Overview
 *
 * NPSとしてOMOインポートしたグラムを表す。
 * NPSとは、企業に対するロイヤルティのスコアのこと
 */
export class NpsOverview {
  public constructor(
    // グラムのID
    public readonly gramId: string,
    // NPSの調査を実施した時間
    public readonly date: Date,
    // NPSのスコア
    public readonly score: number,
    // NPSのコメント
    public readonly comment: string,
    // NPSの定義
    public readonly definition: NpsDefinition
  ) {}
}
