import HttpClient from "@/api/HttpClient";
import { ApiRes } from "@/api/api-res";
import { ApiUrl } from "@/api/api-url";
import { ApiParam } from "@/api/api-param";

export default class ApiMemo {
  constructor(private readonly httpClient: HttpClient) {}

  public getMemoList = (): Promise<ApiRes.MemoList> => {
    return this.httpClient.get<ApiRes.MemoList>(ApiUrl.MEMO_LIST);
  };

  public updateMemo = (
    memo: ApiParam.UpdateMemo
  ): Promise<ApiRes.UpdateMemo> => {
    return this.httpClient.post<ApiRes.UpdateMemo>(ApiUrl.SAVE_MEMO, memo);
  };
}
