<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="select-by-nps-change-form">
    <div class="select-by-nps-change-form__title">
      <TitleTextWithIcon :title="$t('scoreChange')" :icon="iconConversion" />
    </div>

    <div class="select-by-nps-change-form__label-type-wrapper">
      <div
        class="select-by-nps-change-form__label-type-items"
        data-cy="nps-previous-items"
      >
        <div class="select-by-nps-change-form__label-type-title">
          {{ $t("previous") }}
          <div
            v-t="'multipleSelect'"
            class="select-by-nps-change-form__label-type-note"
          />
        </div>
        <div
          class="select-by-nps-change-form__label-type-item"
          :class="{
            'select-by-nps-change-form__label-type-item__selected':
              condition.previousNpsLabels.indexOf(npsLabel.Detractor) >= 0
          }"
          @click="onClickPreviousLabel(npsLabel.Detractor)"
        >
          {{ $t("low") }}
          <div class="select-by-nps-change-form__score">{{
            loyaltyLowScore
          }}</div>
        </div>
        <div
          class="select-by-nps-change-form__label-type-item"
          :class="{
            'select-by-nps-change-form__label-type-item__selected':
              condition.previousNpsLabels.indexOf(npsLabel.Passive) >= 0
          }"
          data-cy="nps-previous-item"
          @click="onClickPreviousLabel(npsLabel.Passive)"
        >
          {{ $t("middle") }}
          <div class="select-by-nps-change-form__score">{{
            loyaltyMediumScore
          }}</div>
        </div>
        <div
          class="select-by-nps-change-form__label-type-item"
          :class="{
            'select-by-nps-change-form__label-type-item__selected':
              condition.previousNpsLabels.indexOf(npsLabel.Promoter) >= 0
          }"
          @click="onClickPreviousLabel(npsLabel.Promoter)"
        >
          {{ $t("high") }}
          <div class="select-by-nps-change-form__score">{{
            loyaltyHighScore
          }}</div>
        </div>
      </div>
    </div>
    <div class="select-by-nps-change-form__down-arrow">
      <Icon :icon="iconDownArrow" :size="30" :color="downArrowColor" />
    </div>
    <div class="select-by-nps-change-form__label-type-wrapper">
      <div
        class="select-by-nps-change-form__label-type-items"
        data-cy="nps-recent-items"
      >
        <div
          v-t="'recent'"
          class="select-by-nps-change-form__label-type-title"
        />
        <div
          class="select-by-nps-change-form__label-type-item"
          :class="{
            'select-by-nps-change-form__label-type-item__selected':
              condition.npsLabel === npsLabel.Detractor
          }"
          data-cy="nps-recent-item"
          @click="onClickLabel(npsLabel.Detractor)"
        >
          {{ $t("low") }}
          <div class="select-by-nps-change-form__score">{{
            loyaltyLowScore
          }}</div>
        </div>
        <div
          class="select-by-nps-change-form__label-type-item"
          :class="{
            'select-by-nps-change-form__label-type-item__selected':
              condition.npsLabel === npsLabel.Passive
          }"
          @click="onClickLabel(npsLabel.Passive)"
        >
          {{ $t("middle") }}
          <div class="select-by-nps-change-form__score">{{
            loyaltyMediumScore
          }}</div>
        </div>
        <div
          class="select-by-nps-change-form__label-type-item"
          :class="{
            'select-by-nps-change-form__label-type-item__selected':
              condition.npsLabel === npsLabel.Promoter
          }"
          @click="onClickLabel(npsLabel.Promoter)"
        >
          {{ $t("high") }}
          <div class="select-by-nps-change-form__score">{{
            loyaltyHighScore
          }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import {
  SelectByNpsChangeCondition,
  NpsLabel
} from "@/models/search/select-condition/SelectByNpsChangeCondition";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import Icon from "@/components/Icon.vue";

import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    TitleTextWithIcon,
    Icon
  }
})
export default class SelectByNpsChangeForm extends Vue {
  @Prop({ type: Array, required: true })
  npsDefinitions!: NpsDefinition[];

  @Model("input", { type: SelectByNpsChangeCondition, required: true })
  condition!: SelectByNpsChangeCondition;

  onUpdate(condition: SelectByNpsChangeCondition) {
    this.$emit("input", condition);
  }

  npsLabel = NpsLabel;
  iconConversion = Icons.Conversion;
  iconDownArrow = Icons.CaretBottom;
  downArrowColor = Colors.Base600;

  /**
   * NSP低評価者のスコア
   */
  get loyaltyLowScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].lowScoreRange;
    }
    return "";
  }

  /**
   * NSP中評価者のスコア
   */
  get loyaltyMediumScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].mediumScoreRange;
    }
    return "";
  }

  /**
   * NSP高評価者のスコア
   */
  get loyaltyHighScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].highScoreRange;
    }
    return "";
  }

  onClickPreviousLabel(label: NpsLabel) {
    let labels: NpsLabel[] = this.condition.previousNpsLabels;
    if (labels.indexOf(label) >= 0) {
      labels = labels.filter(l => l !== label);
    } else {
      labels.push(label);
    }
    this.onUpdate(
      new SelectByNpsChangeCondition(
        labels,
        this.condition.npsLabel,
        this.condition.additionalConditions
      )
    );
  }

  onClickLabel(label: NpsLabel) {
    this.onUpdate(
      new SelectByNpsChangeCondition(
        this.condition.previousNpsLabels,
        label,
        this.condition.additionalConditions
      )
    );
  }
}
</script>

<style scoped lang="scss">
.select-by-nps-change-form__title {
  margin: 30px 0 10px 0;
  &:first-child {
    margin-top: 0;
  }
}

.select-by-nps-change-form__label-type-wrapper {
  display: inline-block;
  padding: 10px;
  border-radius: $sizeRadius;
  background: $colorBase300;
}

.select-by-nps-change-form__label-type-items {
  display: inline-flex;
  align-items: center;
}

.select-by-nps-change-form__score {
  margin-top: 5px;
  color: $colorTextLight;
  text-align: center;
  font-size: 12px;
}

.select-by-nps-change-form__label-type-item {
  margin-right: 16px;
  padding: 6px 10px;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: $colorBlue700;
  }

  &__selected {
    border: 1px solid $colorDark;
    background-color: $colorDark;
    color: $colorWhite;

    &:hover {
      background-color: $colorDark;
    }
    .select-by-nps-change-form__score {
      color: $colorWhite;
    }
  }
}

.select-by-nps-change-form__label-type-title {
  width: 70px;
}

.select-by-nps-change-form__label-type-note {
  margin-top: 6px;
  color: $colorTextLight;
  font-size: 11px;
}

.select-by-nps-change-form__down-arrow {
  padding: 6px 0 6px 22px;
}
</style>
