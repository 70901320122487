<i18n src="@/i18n/components/search/period.json"></i18n>

<template>
  <span class="periodConditionLabel">
    <span
      class="periodConditionLabel__periodConditionContainer"
      :class="{
        'periodConditionLabel__periodConditionContainer--nowrap': !wrap
      }"
    >
      <div v-t="'searchPeriod'" class="periodConditionLabel__title"></div>
      <div class="periodConditionLabel__buttons">
        <div class="periodConditionLabel__buttons__allPeriod">
          <Button
            data-cy="startFavoriteSearchButton"
            width="356px"
            height="32px"
            class="savedPeriodButton periodButton"
            @click="executeFavoriteSearch(-1)"
            >{{ $t("savedPeriod") }}：{{ dateLabel }}
          </Button>
        </div>
        <div
          v-if="haveStartAndEndDate"
          class="periodConditionLabel__buttonContainer"
        >
          <Button
            v-t="'today'"
            data-cy="startFavoriteSearchButton"
            width="77px"
            height="32px"
            class="periodButton"
            @click="executeFavoriteSearch(1)"
          /><Button
            v-t="'yesterday'"
            data-cy="startFavoriteSearchButton"
            width="77px"
            height="32px"
            padding="0px"
            class="periodButton"
            @click="executeFavoriteSearch(2)"
          /><Button
            v-t="'lastSevenDays'"
            data-cy="startFavoriteSearchButton"
            width="95px"
            height="32px"
            class="periodButton"
            @click="executeFavoriteSearch(7)"
          /><Button
            v-t="'lastTwentyEightDays'"
            data-cy="startFavoriteSearchButton"
            width="95px"
            height="32px"
            padding="0px"
            class="periodButton"
            @click="executeFavoriteSearch(28)"
          />
        </div>
      </div>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormat, formatDate } from "@/util/date-util";
import { hourlyIntervalsSelectOpitions } from "@/util/date-util";
import { addHidToUsersUrl } from "@/router";
import { handleError } from "@/util/error-util";
import Button from "@/components/Button.vue";

import { FavoriteSearch } from "@/models/search/FavoriteSearch";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";

import { SelectCondition } from "@/models/search/select-condition/SelectCondition";

@Component({
  components: {
    Button
  }
})
export default class PeriodConditionLabel extends Vue {
  @Prop({ type: Boolean, default: true })
  wrap!: boolean;
  @Prop({ type: FavoriteSearch, required: true })
  favoriteSearch!: FavoriteSearch;
  @Prop({ type: Boolean, required: true })
  editingLabel!: Boolean;

  selectCondition = this.favoriteSearch.history
    .selectCondition as SelectCondition;

  get haveStartAndEndDate(): boolean {
    return (
      "startDate" in this.selectCondition &&
      this.selectCondition.startDate !== null &&
      "endDate" in this.selectCondition &&
      this.selectCondition.endDate !== null
    );
  }

  get dateLabel(): string {
    if (
      !("startDate" in this.selectCondition) ||
      this.selectCondition.startDate === null ||
      !("endDate" in this.selectCondition) ||
      this.selectCondition.endDate === null
    ) {
      // 行動増減ユーザ検索の場合
      if ("period" in this.selectCondition) {
        return this.makeLabelDate(
          this.selectCondition.period.min,
          this.selectCondition.period.max
        );
      }
      // 顧客ロイヤルティ(Loyalty)検索の場合
      if ("date" in this.selectCondition) {
        return formatDate(DateFormat.yyyysMsd, this.selectCondition.date);
      }
      // Cases for ServiceId, UserId, ChangesInNps etc.
      return this.$t("allPeriod") as string;
    }

    const startDate: Date = this.selectCondition.startDate as Date;
    const endDate: Date = this.selectCondition.endDate as Date;

    return this.makeLabelDate(startDate, endDate);
  }

  makeLabelDate(startDate: Date, endDate: Date): string {
    let label: string =
      formatDate(DateFormat.yyyysMsd, startDate) +
      " - " +
      formatDate(DateFormat.yyyysMsd, endDate);

    if ("hour" in this.selectCondition) {
      const hour = this.selectCondition.hour;
      hourlyIntervalsSelectOpitions(false).forEach(opt => {
        if (opt.value === hour) {
          label += " " + opt.label;
        }
      });
    }
    return label;
  }

  executeFavoriteSearch(period: number) {
    if (!this.editingLabel) {
      UgTag.pushEvent(UgEventTag.ClickFavoriteSearchPanel);

      this.$store
        .dispatch("search/executeSelectFromHistory", {
          history: this.favoriteSearch.history,
          setHistoryIdToUrl: addHidToUsersUrl(this.$router),
          favoriteSearchLabel: this.favoriteSearch.label,
          favoriteSearchPeriod: period
        })
        .catch(error => {
          handleError(error);
          throw new Error(error);
        });

      const query: { [key: string]: string } = {
        view: this.$store.state.preference.searchResultView
      };
      this.$router.push({ name: "users", query });
    }
  }
}
</script>
<style lang="scss" scoped>
.periodConditionLabel__title {
  font-weight: bold;
  font-size: 14px;
}

.periodConditionLabel__periodConditionContainer {
  position: relative;
  line-height: 1.5;
}
.periodConditionLabel__periodConditionContainer--nowrap {
  white-space: nowrap;
}

.periodButton {
  margin: 2px;
  font-size: 12px;
}

.savedPeriodButton {
  margin-top: 5px;
}
</style>
