import { DateFormat, formatDate } from "@/util/date-util";
/**
 * 日毎のCV数
 */

export class ConversionTrendDailyCount {
  readonly date: Date;
  readonly count: number;

  constructor(date: string, count: number) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    this.date = d;
    this.count = count;
  }

  get displayDate(): string {
    return formatDate(DateFormat.Md, this.date);
  }
}
