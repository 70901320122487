import { MutationTree } from "vuex";

export class OnboardingState {
  showHomeOnboarding: boolean = false;
}

const mutations: MutationTree<OnboardingState> = {
  setShowHomeOnboarding(state: OnboardingState, showHomeOnboarding: boolean) {
    state.showHomeOnboarding = showHomeOnboarding;
  }
};

export const onboarding = {
  namespaced: true,
  state: new OnboardingState(),
  mutations
};
