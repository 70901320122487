<i18n src="@/i18n/components/user/user-list.json"></i18n>
<template>
  <div class="ptc-Form">
    <div class="ptc-Form_Box">
      <div v-t="'hiddenItems'" class="ptc-Form_Title" />
      <div class="ptc-Form_Body">
        <ul class="ptc-Form_List" @dragover="deselect" @drop="onDragEnd">
          <li
            v-for="column in unselectedColumns"
            :key="column.id"
            class="ptc-Form_Item"
            :class="{ 'ptc-Form_Item__dragging': isDragging(column) }"
            draggable="true"
            @dragstart="onDragStart($event.dataTransfer, column, -1)"
            @dragend="onDragEnd"
          >
            {{ column.name }}
          </li>
        </ul>
      </div>
    </div>

    <div class="ptc-Form_Arrow">
      <Icon :icon="icons.InflowRight" :color="inflowBasicColor" :size="35" />
    </div>

    <div class="ptc-Form_Box">
      <div v-t="'displayedItems'" class="ptc-Form_Title" />
      <div class="ptc-Form_Body">
        <ul class="ptc-Form_List" @dragover="select" @drop="onDragEnd">
          <li
            v-for="column in fixedColumns"
            :key="column.id"
            class="ptc-Form_Item is-fixed"
          >
            {{ column.name }} {{ $t("fixed") }}
          </li>
          <li
            v-for="(column, index) in selectColumns"
            :key="column.id"
            class="ptc-Form_Item"
            draggable="true"
            :class="{ 'ptc-Form_Item__dragging': isDragging(column) }"
            @dragstart="onDragStart($event.dataTransfer, column, index)"
            @dragenter="onDragEnter(index)"
            @dragend="onDragEnd"
          >
            {{ column.name }}
            <IconButton
              class="ptc-Form_Remove"
              :icon="icons.ButtonClose"
              :size="16"
              @click.native="removeColumn(column)"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { Browser } from "@/const/UserAgent";
import { getBrowser } from "@/util/user-agent-util";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {
    Icon,
    IconButton
  }
})
/**
 * カラムの表示と順番を制御する
 * ユーザがカラムの順番を変えたり、表示・非表示を切り替えると
 * inputイベントを発火する。
 */
export default class AttributeTableColumn extends Vue {
  //固定カラム
  @Prop({ type: Array, required: true })
  fixedColumns!: TableColumn[];

  //現在選択されているカラム
  @Prop({ type: Array, required: true })
  selectedColumns!: TableColumn[];

  //すべてのカラム
  @Prop({ type: Array, required: true })
  allColumns!: TableColumn[];

  update(columns: TableColumn[]) {
    this.$emit("input", columns);
  }

  isDrugging: boolean = false;
  current: TableColumn | null = null;

  // -1 = データが無い状態
  startPosition: number = -1;

  //prop データは加工できないので保存
  selectColumns: TableColumn[] = this.selectedColumns;

  inflowBasicColor = Colors.Base700;

  icons = Icons;

  get unselectedColumns(): TableColumn[] {
    return this.allColumns.filter(option => !this.isSelected(option));
  }

  onDragStart(
    dataTransfer: DataTransfer,
    column: TableColumn,
    position: number
  ) {
    // Firefoxでdrag and dropするのに必要
    if (getBrowser() === Browser.Firefox) {
      dataTransfer.setData("Number", position.toString());
    }
    this.isDrugging = true;
    this.current = column;
    this.startPosition = position;
  }

  // 表示中のカラムの順番を入れ替えるときに実行
  // 最初の位置と現在の位置が違う場合、順番を入れ替える
  onDragEnter(position: number) {
    if (this.isDrugging && this.current !== null) {
      const current: TableColumn = this.current;

      if (position !== this.startPosition) {
        const newColumns = this.selectColumns.filter(c => c.id !== current.id);

        newColumns.splice(position, 0, current);
        this.selectColumns = newColumns;
        this.update(newColumns);
      }
    }
  }

  // "onDragStart" で取得した状態を初期化
  onDragEnd() {
    this.isDrugging = false;
    this.current = null;
    this.startPosition = -1;
  }

  isDragging(column: TableColumn): boolean {
    if (this.current !== null) {
      return this.current.id === column.id;
    }

    return false;
  }

  // "onDragEnter" 以外で select の範囲の場合追加する
  select(event: MouseEvent) {
    if (this.isDrugging && this.current !== null) {
      // Safariの挙動制御に使用
      event.preventDefault();

      const current: TableColumn = this.current;

      if (!this.isSelected(current)) {
        const newColumns = this.selectColumns.concat([current]);

        this.selectColumns = newColumns;
        this.update(newColumns);
      }
    }
  }

  deselect(event: MouseEvent) {
    if (this.isDrugging && this.current !== null) {
      // Safariの挙動制御に使用
      event.preventDefault();

      const current: TableColumn = this.current;

      if (this.isSelected(current)) {
        this.removeColumn(current);
      }
    }
  }

  removeColumn(column: TableColumn) {
    const newColumns = this.selectColumns.filter(c => c.id !== column.id);
    this.selectColumns = newColumns;
    this.update(newColumns);
  }

  isSelected(option: TableColumn): boolean {
    return this.selectColumns.some(c => c.id === option.id);
  }
}
</script>

<style lang="scss" scoped>
.ptc-Form {
  display: flex;
  justify-content: center;

  &_Arrow {
    display: flex;
    align-items: center;
    margin-right: 45px;
    margin-left: 45px;
    width: 35px;
  }

  &_Box {
    width: 305px;
    text-align: left;
  }

  &_Title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
  }

  &_Body {
    overflow-y: auto;
    max-height: calc(85vh - 130px - 60px - 25px);
  }

  &_List {
    min-height: 50vh;
    height: 100%;
  }

  &_Item {
    position: relative;
    padding: 12px 45px 10px 15px;
    border: 1px solid;
    border-radius: 5px;
    background-color: $colorWhite;
    font-size: 12px;
    /* drag中角丸にするための対応 */
    transform: translate(0, 0);

    &:not(:first-child) {
      margin-top: 10px;
    }

    &.is-fixed {
      border-color: $colorBase500;
      color: $colorBase700;

      user-select: none;
    }

    &:not(.is-fixed) {
      border-color: $colorBase600;
      color: $colorBase800;
    }
  }

  &_Remove {
    position: absolute;
    top: 50%;
    right: 15px;
    cursor: pointer;
    transform: translateY(-50%);
  }
}

.ptc-Form_Item__dragging {
  opacity: 0.5;
}
</style>
