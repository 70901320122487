<i18n
  src="@/i18n/components/user-trend/scatter/user-trend-scatter-form.json"
></i18n>
<template>
  <div class="userTrendScatterForm">
    <div v-if="isCompared" class="userTrendScatterForm__timeSpan">
      <div
        v-for="option in timeSpanOptions"
        :key="option.value"
        class="userTrendScatterForm__radio"
      >
        <RadioButton
          v-model="value.type"
          :label="option.value"
          @change="onRadioInput"
          >{{ option.label }}</RadioButton
        >
      </div>
    </div>
    <div class="userTrendScatterForm__axis" data-cy="scatter-axis-x">
      <div class="userTrendScatterForm__text">{{ $t("xAxis") }}:</div>
      <div class="userTrendScatterForm__selectBox">
        <SelectBox
          :options="xAxisOptions"
          :value="xAxisType"
          @input="onXAxisInput"
        />
      </div>
    </div>
    <div class="userTrendScatterForm__axis" data-cy="scatter-axis-y">
      <div class="userTrendScatterForm__text">{{ $t("yAxis") }}:</div>
      <div class="userTrendScatterForm__selectBox">
        <SelectBox
          :options="yAxisOptions"
          :value="yAxisType"
          @input="onYAxisInput"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  AnalysisType,
  UserTrendDataType,
  UserTrendScatterXAxisType,
  UserTrendScatterYAxisType
} from "@/const/user-trend";
import { UserTrendScatterType } from "@/models/user-trend/UserTrendScatterType";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import RadioButton from "@/components/form/RadioButton.vue";

@Component({
  components: {
    SelectBox,
    RadioButton
  }
})
export default class UserTrendScatterForm extends Vue {
  @Prop({ type: UserTrendScatterType, required: true })
  value!: UserTrendScatterType;

  @Prop({ type: String, required: true })
  pageType!: AnalysisType;

  // 検索条件で設定したデータ期間（ラジオボタン用）
  @Prop({ type: Array, required: true })
  timeSpanOptions!: SelectOption[];

  // 比較用のデータを表示するか判定
  @Prop({ type: Boolean, required: true })
  isCompared!: boolean;

  onInput(value: UserTrendScatterType) {
    this.$emit("input", value);
  }

  get xAxisType(): string {
    return this.value.xAxisType;
  }

  get yAxisType(): string {
    return this.value.yAxisType;
  }

  // X軸表示用（セレクトボックス用）
  get xAxisOptions(): SelectOption[] {
    const pvOption = {
      value: UserTrendScatterXAxisType.count,
      label: this.$i18n.t("pv") as string,
      disabled: false
    };
    const screenViewOption = {
      value: UserTrendScatterXAxisType.count,
      label: this.$i18n.t("viewCount") as string,
      disabled: false
    };
    const visitOption = {
      value: UserTrendScatterXAxisType.visit,
      label: this.$i18n.t("visit") as string,
      disabled: false
    };
    const uuOption = {
      value: UserTrendScatterXAxisType.uu,
      label: this.$i18n.t("uu") as string,
      disabled: false
    };

    if (this.pageType === AnalysisType.WebView) {
      return [pvOption, visitOption, uuOption];
    } else if (this.pageType === AnalysisType.AppView) {
      return [screenViewOption, visitOption, uuOption];
    }

    return [visitOption, uuOption];
  }

  // Y軸表示用（セレクトボックス用）
  get yAxisOptions(): SelectOption[] {
    return [
      {
        value: "cvInVisit",
        label: this.$i18n.t("cvInVisit") as string,
        disabled: false
      },
      {
        value: "cvRatioInVisit",
        label: this.$i18n.t("cvRatioInVisit") as string,
        disabled: false
      }
    ];
  }

  onRadioInput(value: UserTrendDataType) {
    this.onInput(
      new UserTrendScatterType(
        value,
        this.value.xAxisType,
        this.value.yAxisType
      )
    );
  }

  onXAxisInput(value: UserTrendScatterXAxisType) {
    this.onInput(
      new UserTrendScatterType(this.value.type, value, this.value.yAxisType)
    );
  }

  onYAxisInput(value: UserTrendScatterYAxisType) {
    this.onInput(
      new UserTrendScatterType(this.value.type, this.value.xAxisType, value)
    );
  }
}
</script>

<style scoped lang="scss">
.userTrendScatterForm {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}

.userTrendScatterForm__timeSpan {
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding: 2px 5px 0 10px;
  height: $formPartsHeight;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.userTrendScatterForm__axis {
  display: flex;
  align-items: center;

  &:last-child {
    margin-left: 15px;
  }
}

.userTrendScatterForm__text {
  margin-right: 5px;
  font-weight: bold;
}

.userTrendScatterForm__selectBox {
  min-width: 100px;
}
</style>
