<i18n
  src="@/i18n/views/client-settings/td-conversion-attribute-create-and-update.json"
></i18n>
<template>
  <div class="tdConversionAttributeCreateAndUpdate">
    <div
      v-t="isCreatePage ? 'createTitle' : 'updateTitle'"
      class="tdConversionAttributeCreateAndUpdate__title"
    />

    <div
      v-if="!isEditing"
      class="tdConversionAttributeCreateAndUpdate__confirmText"
    >
      <div v-if="isCreatePage" v-t="'confirmAdd'" />
      <div v-else v-t="'confirmUpdate'" />
    </div>

    <div v-if="isLoading" class="tdConversionAttributeCreateAndUpdate__loading">
      <Loading height="80px" />
    </div>

    <div v-else class="tdConversionAttributeCreateAndUpdate__form">
      <SettingsTable class="tdConversionAttributeCreateAndUpdate__formTable">
        <SettingsTableRow>
          <SettingsTableCell
            v-t="'attributeName'"
            width="200px"
            text-align="center"
          />
          <SettingsTableCell
            class="tdConversionAttributeCreateAndUpdate__conversionAttributeCell"
          >
            <template v-if="isEditing">
              <SelectBox
                v-if="hasActiveConversionAttribute"
                v-model="conversionAttribute"
                class="tdConversionAttributeCreateAndUpdate__selectBox"
                width="450px"
                :options="conversionAttributeOptions"
              />
              <div
                class="tdConversionAttributeCreateAndUpdate__formCellDescription"
                v-html="$t('conversionAttributeFormCellDescription')"
              />
              <div
                v-if="conversionAttributeErrorMessage"
                class="tdConversionAttributeCreateAndUpdate__errorMessage"
              >
                {{ conversionAttributeErrorMessage }}
              </div>
            </template>
            <template v-else>{{ selectedConversionAttributeText }}</template>
          </SettingsTableCell>
        </SettingsTableRow>

        <SettingsTableRow>
          <SettingsTableCell v-t="'attributeType'" text-align="center" />
          <SettingsTableCell
            class="tdConversionAttributeCreateAndUpdate__attributeTypeCell"
          >
            {{ attributeType }}
          </SettingsTableCell>
        </SettingsTableRow>

        <SettingsTableRow>
          <SettingsTableCell v-t="'attributeColumnName'" text-align="center" />
          <SettingsTableCell
            class="tdConversionAttributeCreateAndUpdate__attributeColumnNameCell"
          >
            <template v-if="isEditing">
              <InputText
                v-model="attributeColumnName"
                :max-length="40"
                class="tdConversionAttributeCreateAndUpdate__attributeColumnNameInput"
              />
              <div
                class="tdConversionAttributeCreateAndUpdate__formCellDescription"
                v-html="$t('conversionAttributeColumnNameDescription')"
              />
              <div
                v-if="attributeColumnNameErrorMessage"
                class="tdConversionAttributeCreateAndUpdate__errorMessage"
              >
                {{ attributeColumnNameErrorMessage }}
              </div>
            </template>
            <template v-else>
              {{ attributeColumnName }}
            </template>
          </SettingsTableCell>
        </SettingsTableRow>
      </SettingsTable>

      <div class="tdConversionAttributeCreateAndUpdate__formButtons">
        <Button
          class="tdConversionAttributeCreateAndUpdate__submitButton tdConversionAttributeCreateAndUpdate__button"
          padding="0 20px"
          :text-size="buttonTextSize"
          :disabled="isSubmitting"
          @click="isEditing ? onClickConfirm() : onClickSubmit()"
          >{{ submitText }}</Button
        >
        <Button
          v-t="'cancel'"
          class="tdConversionAttributeCreateAndUpdate__cancelButton tdConversionAttributeCreateAndUpdate__button"
          padding="0 20px"
          :text-size="buttonTextSize"
          :type="buttonTypeCancel"
          @click="onClickCancel"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { AttributeType } from "@/api/apis/ApiSearch";
import { TdConversionAttributeRequest } from "@/api/apis/client-settings/ApiTdConversionAttribute";
import Loading from "@/components/Loading.vue";
import Button from "@/components/Button.vue";
import InputText from "@/components/form/InputText.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import { BUTTON_TYPE, ButtonTextSize } from "@/const/button";
import { handleError } from "@/util/error-util";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { TdConversionAttributeDefinition } from "@/models/client-settings/TdConversionAttributeDefinition";
import { TdWebImportConfigDefinition } from "@/models/client-settings/TdWebImportConfigDefinition";
import { isEnglishText } from "@/util/common-util";

export const EMPTY_CONVERSION_ATTRIBUTE_VALUE = 0;

@Component({
  components: {
    Button,
    InputText,
    Loading,
    RadioButton,
    SelectBox,
    SettingsTable,
    SettingsTableCell,
    SettingsTableRow
  }
})
export default class TdConversionAttributeCreateAndUpdate extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  buttonTypeCancel = BUTTON_TYPE.LIGHT;
  buttonType = BUTTON_TYPE;

  isEditing = true;
  isLoading = true;
  isSubmitting = false;

  conversionAttribute: number = EMPTY_CONVERSION_ATTRIBUTE_VALUE;

  conversionAttributeErrorMessage = "";
  attributeColumnName = "";
  attributeColumnNameErrorMessage = "";

  async created() {
    let requests = [
      this.$store.dispatch(
        "clientSettings/fetchConversionAttributeDefinitions"
      ),
      this.$store.dispatch("tdWebImportConfig/fetchTdWebImportConfigDetail"),
      this.$store.dispatch(
        "tdConversionAttribute/fetchTdConversionAttributeDefinitions"
      )
    ];

    if (this.isUpdatePage) {
      requests = [
        ...requests,
        this.$store.dispatch(
          "tdConversionAttribute/fetchTdConversionAttributeById",
          Number(this.$route.params.id)
        )
      ];
    }

    try {
      await Promise.all(requests);

      if (!this.tdWebImportConfig || !this.tdWebImportConfig.isDefined()) {
        this.$router.push({ name: "td-conversion-attribute-settings" });
        return;
      }

      if (!this.hasAvailableConversionAttribute) {
        this.$router.push({ name: "td-conversion-attribute-settings" });
        return;
      }

      if (this.isUpdatePage && !this.tdConversionAttributeDefinition) {
        this.$router.push({ name: "td-conversion-attribute-settings" });
        return;
      }

      this.setOptions(this.tdConversionAttributeDefinition);
      this.isLoading = false;
    } catch (e) {
      handleError(e);
    }
  }

  destroyed() {
    this.$store.commit(
      "tdConversionAttribute/setSelectedTdConversionAttributeDefinition",
      null
    );
  }

  get isCreatePage(): boolean {
    return this.$route.name === "td-conversion-attribute-create";
  }

  get isUpdatePage(): boolean {
    return this.$route.name === "td-conversion-attribute-update";
  }

  get tdWebImportConfig(): TdWebImportConfigDefinition | null {
    return this.$store.state.tdWebImportConfig.tdWebImportConfigDetails;
  }

  get tdConversionAttributeDefinition(): TdConversionAttributeDefinition | null {
    return this.$store.state.tdConversionAttribute
      .selectedTdConversionAttributeDefinition;
  }

  get activeConversionAttributeList(): ConversionAttributeDefinition[] {
    return this.$store.state.clientSettings
      .activeConversionAttributeDefinitions;
  }

  get tdConversionAttributeList(): TdConversionAttributeDefinition[] {
    return this.$store.state.tdConversionAttribute
      .tdConversionAttributeDefinitions;
  }

  get conversionAttributeDropDownList(): ConversionAttributeDefinition[] {
    let configuredConversionAttributeIds: number[] = this.tdConversionAttributeList.map(
      tdConversionAttribute => tdConversionAttribute.cvAttributeId
    );
    if (this.isUpdatePage) {
      if (this.tdConversionAttributeDefinition) {
        const selectedId: number = this.tdConversionAttributeDefinition
          .cvAttributeId;
        configuredConversionAttributeIds = configuredConversionAttributeIds.filter(
          cvAttrId => cvAttrId != selectedId
        );
      }
    }
    return this.activeConversionAttributeList.filter(activeCvAttr => {
      return !configuredConversionAttributeIds.includes(activeCvAttr.id);
    });
  }

  get hasActiveConversionAttribute(): boolean {
    return this.activeConversionAttributeList.length > 0;
  }

  get hasAvailableConversionAttribute(): boolean {
    return this.activeConversionAttributeList.length > 0;
  }

  get conversionAttributeOptions(): SelectOption[] {
    return [
      {
        label: "-----------",
        value: EMPTY_CONVERSION_ATTRIBUTE_VALUE,
        disabled: false
      },
      ...this.conversionAttributeDropDownList.map(cvAttribute => ({
        label: cvAttribute.name,
        value: cvAttribute.id,
        disabled: false
      }))
    ];
  }

  get attributeType(): string {
    const cvAttribute = this.activeConversionAttributeList.find(
      cvAttribute => cvAttribute.id === this.conversionAttribute
    );
    if (cvAttribute) {
      const attributeType: string =
        cvAttribute.type == AttributeType.TEXT ? "text" : "number";
      return this.$t(attributeType) as string;
    }
    return "";
  }

  get selectedConversionAttributeText(): string {
    const cvAttribute = this.activeConversionAttributeList.find(
      cvAttribute => cvAttribute.id === this.conversionAttribute
    );
    return cvAttribute ? cvAttribute.name : "";
  }

  get submitText(): string {
    if (this.isEditing) {
      return this.$t("confirm").toString();
    }

    if (this.isCreatePage) {
      return this.$t("register").toString();
    }

    return this.$t("update").toString();
  }

  setOptions(def: TdConversionAttributeDefinition | null) {
    if (this.hasActiveConversionAttribute) {
      this.conversionAttribute = def
        ? def.cvAttributeId
        : EMPTY_CONVERSION_ATTRIBUTE_VALUE;
    }

    this.attributeColumnName = def ? def.cvAttributeColumn : "";
  }

  isAttributeColumnNameValid(): boolean {
    if (this.attributeColumnName === "") {
      this.attributeColumnNameErrorMessage = this.$t("emptyError").toString();
      return false;
    }
    if (!isEnglishText(this.attributeColumnName)) {
      this.attributeColumnNameErrorMessage = this.$t(
        "invalidCharactersError"
      ) as string;
      return false;
    }
    return true;
  }

  onClickConfirm() {
    let hasError = false;
    this.conversionAttributeErrorMessage = "";
    this.attributeColumnNameErrorMessage = "";
    if (this.conversionAttribute === EMPTY_CONVERSION_ATTRIBUTE_VALUE) {
      this.conversionAttributeErrorMessage = this.$t(
        "noConversionAttributeError"
      ).toString();
      hasError = true;
    }
    if (!this.isAttributeColumnNameValid()) {
      hasError = true;
    }
    if (!hasError) {
      this.isEditing = false;
    }
  }
  async onClickSubmit() {
    this.isSubmitting = true;
    try {
      this.isCreatePage
        ? await this.createTdConversionAttribute()
        : await this.updateTdConversionAttribute();
      this.$router.push({ name: "td-conversion-attribute-settings" });
    } catch (e) {
      handleError(e);
    } finally {
      this.isSubmitting = false;
    }
  }
  async createTdConversionAttribute() {
    const payload: TdConversionAttributeRequest = {
      cv_attr_id: this.conversionAttribute,
      cv_attr_column: this.attributeColumnName,
      is_disabled: false
    };
    await this.$store.dispatch(
      "tdConversionAttribute/createTdConversionAttribute",
      payload
    );
    this.$store.commit(
      "tdConversionAttribute/setSuccessMessage",
      this.$t("createSuccessMessage", {
        name: this.selectedConversionAttributeText
      }).toString()
    );
  }

  async updateTdConversionAttribute() {
    const params = {
      id: Number(this.$route.params.id),
      payload: {
        cv_attr_id: this.conversionAttribute,
        cv_attr_column: this.attributeColumnName,
        is_disabled: false
      }
    };
    await this.$store.dispatch(
      "tdConversionAttribute/updateTdConversionAttribute",
      params
    );
    this.$store.commit(
      "tdConversionAttribute/setSuccessMessage",
      this.$t("updateSuccessMessage", {
        name: this.selectedConversionAttributeText
      }).toString()
    );
  }

  onClickCancel() {
    if (this.isEditing) {
      this.$router.push({ name: "td-conversion-attribute-settings" });
    } else {
      this.isEditing = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.tdConversionAttributeCreateAndUpdate {
  min-width: 704px;
}

.tdConversionAttributeCreateAndUpdate__title {
  font-size: 20px;
  font-weight: 700;
  line-height: inherit;
  padding: 0 0 46px 0;
}

.tdConversionAttributeCreateAndUpdate__formTable {
  margin-bottom: 16px;
  width: 680px;
}

.tdConversionAttributeCreateAndUpdate__formCellDescription {
  white-space: pre-line;
  margin-top: 12px;
}

::v-deep .tdConversionAttributeCreateAndUpdate__linkText {
  color: $colorBase700;
  text-decoration: underline;
}

::v-deep .tdConversionCreateAndUpdate__errorLinkText {
  color: $colorError;
  text-decoration: underline;
}

.tdConversionAttributeCreateAndUpdate__createConversionAttributeLink {
  margin-top: 12px;
}

.tdConversionAttributeCreateAndUpdate__selectBox {
  margin-top: 12px;
}

.tdConversionAttributeCreateAndUpdate__button {
  min-width: 120px;
}

.tdConversionAttributeCreateAndUpdate__cancelButton {
  margin-left: 15px;
}

.tdConversionAttributeCreateAndUpdate__errorMessage {
  color: $colorError;
  margin-top: 12px;
}

.tdConversionAttributeCreateAndUpdate__confirmText {
  padding-bottom: 30px;
  white-space: pre-line;
  font-size: 14px;
  line-height: 1;
}
</style>
