<i18n src="@/i18n/views/user-search-result-overview.json"></i18n>
<template>
  <div class="user-search-result-overview">
    <div
      ref="userSearchResultOverviewMenus"
      class="userSearchResultOverview__menus"
    >
      <div v-if="showChartMenu" class="userSearchResultOverview__definitions">
        <ul
          v-if="hasBusinessEventDefinitions"
          class="userSearchResultOverview__list"
        >
          <li
            v-for="definition in businessEventDefinitions"
            :key="definition.id"
            class="userSearchResultOverview__item userSearchResultOverview__bussinessItem"
            :class="{
              'userSearchResultOverview__item--disabled': isDisabledBusinessEventDefinition(
                definition
              )
            }"
            @click="toggleDisplayBusinessEventDefinition(definition)"
            v-text="businessEventName(definition.name)"
          />
        </ul>
        <ul
          v-if="hasChartContactDefinitions"
          class="userSearchResultOverview__list"
        >
          <li
            v-for="i in contactIcons"
            :key="i.contactType"
            class="userSearchResultOverview__item"
            :class="{
              'userSearchResultOverview__item--disabled': isDisabledContact(
                i.contactType
              )
            }"
            @click="toggleDisplayContact(i.contactType)"
          >
            <Tooltip :text="i.text" :placement="TooltipPlacement.Bottom">
              <IconButton
                :icon="i.icon"
                :size="14"
                :button-type="IconButtonType.UserSearchEvent"
                :disabled="isDisabledContact(i.contactType)"
              />
            </Tooltip>
          </li>
        </ul>
      </div>

      <div class="userSearchResultOverview__emphasisToggle">
        <div
          v-click-outside="hideEmphasisCvBalloon"
          class="userSearchResultOverview__emphasisButtonList"
        >
          <div
            class="userSearchResultOverview__emphasisCvButton userSearchResultOverview__emphasisButtonItem"
            :class="{
              'userSearchResultOverview__emphasisCvButton--current': showBalloon,
              'userSearchResultOverview__emphasisButtonItem--disabled': isToggleTimeOfDayEnabled
            }"
            @click="onEmphasisCvButtonClick"
          >
            <div class="userSearchResultOverview__emphasisCvButtonText">{{
              emphasisCvButtonText
            }}</div>
            <div class="userSearchResultOverview__emphasisCvIcon">
              <Icon
                :icon="Icons.ArrowBottom"
                :size="10"
                :color="emphasisCvIconColor"
              />
            </div>
          </div>
          <div
            class="userSearchResultOverview__emphasisTimeButton userSearchResultOverview__emphasisButtonItem"
            :class="{
              'userSearchResultOverview__emphasisButtonItem--disabled': !isToggleTimeOfDayEnabled
            }"
            @click="onEmphasisTimeButtonClick"
          >
            <div class="userSearchResultOverview__emphasisTimeButtonText">{{
              emphasisTimeButtonText
            }}</div>
          </div>
          <ConversionSelectMenu
            v-if="showBalloon"
            class="userSearchResultOverview__emphasisCvBalloon"
            :conversions="conversions"
            :selected-conversion-ids="selectedConversionIds"
            :init-selected-conversion-ids="initSelectedConversionIds"
            @input="onEmphasisCvInput"
          />
        </div>

        <div class="userSearchResultOverview__tooltip">
          <Tooltip :placement="TooltipPlacement.BottomEnd">
            <Icon :icon="iconHelp" :color="iconHelpColor" :size="12" />

            <template #content>
              <div class="userSearchResultOverview__tooltipContent">
                [{{ $t("emphasisCvButtonText") }}]
                <div
                  class="userSearchResultOverview__explanation"
                  v-html="cvEmphasisTooltip1"
                />
                <div class="userSearchResultOverview__explanation">{{
                  cvEmphasisTooltip2
                }}</div>

                <div class="userSearchResultOverview__notes">
                  {{ cvEmphasisTooltipNoteTitle }}

                  <div class="userSearchResultOverview__noteItem">
                    {{ cvEmphasisTooltipNoteList1 }}
                    <div class="userSearchResultOverview__noteItemChild">{{
                      cvEmphasisTooltipNoteList1Child1
                    }}</div>
                    <div class="userSearchResultOverview__noteItemChild">{{
                      cvEmphasisTooltipNoteList1Child2
                    }}</div></div
                  >
                  <div class="userSearchResultOverview__noteItem">{{
                    cvEmphasisTooltipNoteList2
                  }}</div>
                </div>

                <div class="userSearchResultOverview__timeModeHelpContent">
                  [{{ $t("emphasisTimeButtonText") }}]
                  <div class="userSearchResultOverview__timeModeExplanation">
                    {{ $t("emphasisTimeModeNote1") }} <br />
                    {{ $t("emphasisTimeModeNote2") }}
                    <div class="userSearchResultOverview__timeModeNotes">
                      <div
                        class="userSearchResultOverview__noteItem"
                        v-html="emphasisTimeModeNoteList1"
                      />
                      <div
                        class="userSearchResultOverview__noteItem"
                        v-html="emphasisTimeModeNoteList2"
                      />
                      <div
                        class="userSearchResultOverview__noteItem"
                        v-html="emphasisTimeModeNoteList3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Tooltip>
        </div>
      </div>
    </div>

    <UserGramChartTable
      sticky-top="155px"
      :users="users"
      :almost-cv-user-list="almostCvUserList"
      :end-date="endDate"
      :chart-period="chartPeriod"
      :colored-periods="coloredPeriods"
      :is-omo="isOmo"
      :current-user="currentUser"
      :display-contact-definition-types="displayContactDefinitionTypes"
      :display-business-event-definition-ids="displayBusinessEventDefinitionIds"
      :is-funnel-result="isFunnelResult"
      :funnel-selected-order="funnelSelectedOrder"
      :funnel-gram-ids-per-user="funnelGramIdsPerUser"
      :selected-conversion-ids="selectedConversionIds"
      :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
      @mouse-enter="onMouseEnterUserColumn"
      @mouse-leave="onMouseLeaveUserColumn"
    >
      <template #default="{ user }">
        <UserIconContainer
          :user="user"
          @show-favorite-form="onShowFavoriteForm"
          @close-favorite-form="onCloseFavoriteForm"
          @click-user="onClickUser(user)"
        />
        <UserOtherBalloon
          :user="user"
          :columns="conversionAttributeColumns"
          :show="showUserBalloon(user.id)"
          :direction="balloonDirection"
          :y="balloonPositionY"
          :x="balloonPositionX"
          :adjust="balloonArrowAdjust"
        >
          <template #wordcloud>
            <WordcloudContainer :user-id="user.id" />
          </template>
        </UserOtherBalloon>
      </template>
    </UserGramChartTable>
  </div>
</template>

<script lang="ts">
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";
import UserGramChartTable from "@/components/users/UserGramChartTable.vue";
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { User } from "@/models/User";
import { VisitConversion } from "@/models/overview/VisitOverview";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { ChartPeriod } from "@/const/chart-period";
import { getEndDate } from "@/components/chart/chart-util";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { IconButtonType } from "@/const/IconButtons";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { TooltipPlacement } from "@/const/tooltip";
import UserOtherBalloon from "@/components/users/UserOtherBalloon.vue";
import ChartPeriodMenu from "@/components/chart/ChartPeriodMenu.vue";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";
import UserIconContainer from "@/views/UserIconContainer.vue";
import { TranslateResult } from "vue-i18n";
import { i18n } from "@/i18n";
import { UserBalloonDirection } from "@/components/users/UserBaseBalloon.vue";
import WordcloudContainer from "@/views/WordcloudContainer.vue";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";
import ConversionSelectMenu from "@/components/users/ConversionSelectMenu.vue";
import vClickOutside from "v-click-outside";
import { UgTag, UgEventTag, UgAttributeTag } from "@/store/modules/ugTag";
import { SearchResultViews } from "@/const/SearchResultViews";
import { GramListOfUser } from "@/api/apis/ApiFunnel";

// balloonのtopの位置調整(Start postion = 20 + Arrow Size / 2 = 6)
const BALLOON_ARROW_ADJUST: number = 26;
const USER_COLUMN_WIDTH: number = 135;
const SHOW_BALLOON_WAIT_DURATION = 300;
const PUSH_EVENT_DELAY: number = 1000;

// バルーンのmax-heightでセットされている高さ
const MAX_BALLOON_HEIGHT = 500;

@Component({
  components: {
    UserGramChartTable,
    Icon,
    IconButton,
    ChartPeriodMenu,
    Tooltip,
    UserOtherBalloon,
    UserIconContainer,
    WordcloudContainer,
    ConversionSelectMenu
  },
  directives: { clickOutside: vClickOutside.directive }
})
export default class UserSearchResultOverview extends Vue {
  onClickUser(user: User) {
    this.$emit("click-user", user);
  }

  @Emit("on-show-favorite-form")
  onShowFavoriteForm() {
    this.showingFavoriteForm = true;
    this.onMouseLeaveUserColumn();
  }

  @Prop({ type: Array, default: () => [] })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Object, default: null })
  almostCvUserList!: AlmostCvUserList;

  @Emit("on-close-favorite-form")
  onCloseFavoriteForm() {
    this.showingFavoriteForm = false;
  }

  Colors = Colors;
  IconButtonType = IconButtonType;
  iconHelp = Icons.HelpInvert;
  iconHelpColor = Colors.Base700;

  displayContactDefinitionTypes: number[] = this.chartContactDefinitions.map(
    def => def.type
  );

  displayBusinessEventDefinitionIds: number[] = this.businessEventDefinitions.map(
    def => def.id
  );

  Icons = Icons;
  TooltipPlacement = TooltipPlacement;

  mouseEnterUserId: string = "";
  balloonDisplayTimer: number | null = null;
  balloonDirection: UserBalloonDirection = UserBalloonDirection.Top;
  balloonPositionY: string = "auto";
  balloonPositionX: string = "auto";
  balloonArrowAdjust: number = 0;
  showingFavoriteForm: boolean = false;
  showBalloon: boolean = false;
  isToggleTimeOfDayEnabled: boolean = false;
  pushEventTimer: number | undefined = undefined;

  get isContractApp(): boolean {
    return this.$store.state.client.client.isContractApp;
  }

  get contactIcons(): {
    icon: Icons;
    text: string;
    contactType: ContactDefinitionType;
  }[] {
    return this.chartContactDefinitions.map((def: ContactDefinition) => {
      if (def.type === ContactDefinitionType.TEL) {
        return {
          icon: Icons.Tel,
          text: i18n.t("models.gram.tel") as string,
          contactType: ContactDefinitionType.TEL
        };
      }

      if (def.type === ContactDefinitionType.MAIL) {
        return {
          icon: Icons.Mail,
          text: i18n.t("models.gram.mail") as string,
          contactType: ContactDefinitionType.MAIL
        };
      }

      return {
        icon: Icons.Shop,
        text: i18n.t("models.gram.shop") as string,
        contactType: ContactDefinitionType.SHOP
      };
    });
  }

  get chartPeriod(): ChartPeriod {
    return this.$store.state.preference.chartPeriod;
  }

  get showChartMenu(): boolean {
    return this.hasBusinessEventDefinitions || this.hasChartContactDefinitions;
  }

  get hasChartContactDefinitions(): boolean {
    return this.chartContactDefinitions.length > 0;
  }

  get hasBusinessEventDefinitions(): boolean {
    return this.businessEventDefinitions.length > 0;
  }

  get chartContactDefinitions(): ContactDefinition[] {
    return this.$store.state.clientSettings.activeContactDefinitions.filter(
      (d: ContactDefinition) =>
        d.type === ContactDefinitionType.TEL ||
        d.type === ContactDefinitionType.MAIL ||
        d.type === ContactDefinitionType.SHOP
    );
  }

  get businessEventDefinitions(): BusinessEventDefinition[] {
    return this.$store.state.clientSettings.activeBusinessEventDefinitions;
  }

  get isOmo(): boolean {
    return this.$store.getters["clientSettings/hasActiveOmoDefinitions"];
  }

  get users(): User[] {
    if (this.$store.state.clustering.isClusteringMode) {
      return this.$store.state.clustering.users;
    } else if (this.isTourDetail) {
      return this.$store.getters["filter/tourUsers"];
    } else if (this.$store.state.filter.isFilterMode) {
      return this.$store.state.filter.users;
    } else {
      return this.$store.state.search.users;
    }
  }

  get conversionAttributeColumns(): TableColumn[] {
    return this.$store.getters["clientSettings/conversionAttributeColumns"];
  }

  get selectCondition(): SelectCondition {
    return this.$store.state.search.selectCondition;
  }

  get endDate(): Date {
    return getEndDate(this.selectCondition);
  }

  get currentUser(): User | null {
    return this.$store.state.user.currentUser;
  }

  get cvEmphasisTooltip1(): TranslateResult {
    const element =
      '<span class="userSearchResultOverview__emphasisCvColor"></span>';
    return this.$i18n.t("cvEmphasisTooltip1", {
      element
    }) as TranslateResult;
  }

  get emphasisTimeModeNoteList1(): TranslateResult {
    const colorMorningVisitWithCv = '<span class="morningVisitWithCv"></span>';
    const colorMorningVisitWithOutCv =
      '<span class="morningVisitWithOutCv"></span>';

    return this.$i18n.t("emphasisTimeModeNoteList1", {
      colorMorningVisitWithCv: colorMorningVisitWithCv,
      colorMorningVisitWithOutCv: colorMorningVisitWithOutCv
    }) as TranslateResult;
  }

  get emphasisTimeModeNoteList2(): TranslateResult {
    const colorDayVisitWithCv = '<span class="dayVisitWithCv"></span>';
    const colorDayVisitWithOutCv = '<span class="dayVisitWithOutCv"></span>';

    return this.$i18n.t("emphasisTimeModeNoteList2", {
      colorDayVisitWithCv: colorDayVisitWithCv,
      colorDayVisitWithOutCv: colorDayVisitWithOutCv
    }) as TranslateResult;
  }

  get emphasisTimeModeNoteList3(): TranslateResult {
    const colorNightVisitWithCv = '<span class="nightVisitWithCv"></span>';
    const colorNightVisitWithOutCv =
      '<span class="nightVisitWithOutCv"></span>';

    return this.$i18n.t("emphasisTimeModeNoteList3", {
      colorNightVisitWithCv: colorNightVisitWithCv,
      colorNightVisitWithOutCv: colorNightVisitWithOutCv
    }) as TranslateResult;
  }

  get conversions(): VisitConversion[] {
    const conversionDefinitions: ConversionDefinition[] =
      this.isContractApp && this.selectCondition instanceof SelectByAppCondition
        ? this.$store.getters["system/activeGlobalConversionDefinitions"]
        : this.$store.state.clientSettings.activeConversionDefinitions;

    return conversionDefinitions.map(d => ({ id: d.id, name: d.name }));
  }

  get isFunnelResult(): boolean {
    return (
      this.$route.name === "funnel-analysis-detail" ||
      !!this.$route.query.funnelId
    );
  }
  get funnelSelectedOrder(): number {
    return this.$store.state.funnel.selectedOrder;
  }
  get funnelGramIdsPerUser(): GramListOfUser {
    return this.$store.getters["funnel/funnelGramIdsPerUser"];
  }
  get selectedConversionIds(): SelectCondition {
    return this.$store.state.search.selectedConversionIds;
  }

  get initSelectedConversionIds(): string[] {
    return this.$store.getters["search/initSelectedConversionIds"];
  }

  get emphasisCvButtonText(): string {
    return this.$i18n.t("emphasisCvButtonText") as string;
  }

  get emphasisTimeButtonText(): string {
    return this.$i18n.t("emphasisTimeButtonText") as string;
  }

  get cvEmphasisTooltip2(): string {
    return this.$i18n.t("cvEmphasisTooltip2") as string;
  }

  get cvEmphasisTooltipNoteTitle(): string {
    return this.$i18n.t("cvEmphasisTooltipNoteTitle") as string;
  }

  get cvEmphasisTooltipNoteList1(): string {
    return this.$i18n.t("cvEmphasisTooltipNoteList1") as string;
  }

  get cvEmphasisTooltipNoteList1Child1(): string {
    return this.$i18n.t("cvEmphasisTooltipNoteList1Child1") as string;
  }

  get cvEmphasisTooltipNoteList1Child2(): string {
    return this.$i18n.t("cvEmphasisTooltipNoteList1Child2") as string;
  }

  get cvEmphasisTooltipNoteList2(): string {
    return this.$i18n.t("cvEmphasisTooltipNoteList2") as string;
  }

  get emphasisCvIconColor(): Colors {
    return this.isToggleTimeOfDayEnabled ? Colors.Base700 : Colors.Base900;
  }

  get isTourDetail(): boolean {
    return this.$route.name === "tour-detail";
  }

  toggleDisplayContact(type: ContactDefinitionType) {
    if (this.displayContactDefinitionTypes.indexOf(type) > -1) {
      this.displayContactDefinitionTypes = this.displayContactDefinitionTypes.filter(
        t => t !== type
      );
    } else {
      this.displayContactDefinitionTypes.push(type);
    }
  }

  isDisabledContact(type: ContactDefinitionType): boolean {
    return this.displayContactDefinitionTypes.indexOf(type) === -1;
  }

  toggleDisplayBusinessEventDefinition(definition: BusinessEventDefinition) {
    if (this.displayBusinessEventDefinitionIds.indexOf(definition.id) > -1) {
      this.displayBusinessEventDefinitionIds = this.displayBusinessEventDefinitionIds.filter(
        id => id !== definition.id
      );
    } else {
      this.displayBusinessEventDefinitionIds.push(definition.id);
    }
  }

  isDisabledBusinessEventDefinition(
    definition: BusinessEventDefinition
  ): boolean {
    return this.displayBusinessEventDefinitionIds.indexOf(definition.id) === -1;
  }

  onMouseEnterUserColumn(event: MouseEvent, userId: string) {
    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
    }
    this.balloonDisplayTimer = window.setTimeout(() => {
      const evTarget = event.target;
      const target: HTMLDivElement = evTarget as HTMLDivElement;
      const top: number = target.getBoundingClientRect().top;
      const height: number = target.clientHeight;
      const windowHeight: number = window.innerHeight;
      this.mouseEnterUserId = userId;

      this.balloonPositionX = USER_COLUMN_WIDTH + "px";

      // Top WordCloudを下に開いても収まる
      if (top + MAX_BALLOON_HEIGHT < windowHeight) {
        this.balloonDirection = UserBalloonDirection.Top;
        this.balloonPositionY = BALLOON_ARROW_ADJUST * -1 + "px";
        this.balloonArrowAdjust = height / 2 - BALLOON_ARROW_ADJUST / 2;
        return;
      }

      // Middle WordCloudを開いても下が収まる
      if (top + MAX_BALLOON_HEIGHT / 2 < windowHeight) {
        this.balloonDirection = UserBalloonDirection.Middle;
        this.balloonPositionY = "calc(50%)";
        this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2;
        return;
      }

      // Bottom 下からWordCloudを開いても上に突き抜けない
      if (top + height - MAX_BALLOON_HEIGHT - BALLOON_ARROW_ADJUST / 2 > 0) {
        this.balloonDirection = UserBalloonDirection.Bottom;
        this.balloonPositionY = BALLOON_ARROW_ADJUST / 2 + "px";
        this.balloonArrowAdjust = -(height / 2 - BALLOON_ARROW_ADJUST);
        return;
      }

      // どれにも当てはまらない時は中央寄せにしておく
      this.balloonDirection = UserBalloonDirection.Middle;
      this.balloonPositionY = "calc(50%)";
      this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2;
    }, SHOW_BALLOON_WAIT_DURATION);
  }

  onMouseLeaveUserColumn() {
    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
      this.balloonDisplayTimer = null;
    }
    clearTimeout(this.pushEventTimer);
    this.mouseEnterUserId = "";
    this.balloonPositionY = "auto";
    this.balloonPositionX = "auto";
  }

  showUserBalloon(userId: string): boolean {
    if (userId !== this.mouseEnterUserId) return false;

    clearTimeout(this.pushEventTimer);
    this.pushEventTimer = window.setTimeout(() => {
      UgTag.pushEvent(UgEventTag.UserListBalloon, {
        [UgAttributeTag.UserListDisplayMode]: SearchResultViews.Overview
      });
    }, PUSH_EVENT_DELAY);

    return true;
  }

  businessEventName(name: string): string {
    // 一行は3文字までなので、3文字で改行
    const nameArray = name.match(/(.{1,3})$|.{3}/g);

    if (nameArray === null) {
      return "";
    }
    return nameArray.join("\n");
  }

  onEmphasisCvButtonClick() {
    if (this.isToggleTimeOfDayEnabled === false) {
      this.showBalloon = !this.showBalloon;
    } else {
      this.isToggleTimeOfDayEnabled = false;
      this.$store.commit("search/setToggleTimeOfDayEnabled", false);
    }
  }

  onEmphasisTimeButtonClick() {
    this.isToggleTimeOfDayEnabled = true;
    this.showBalloon = false;
    this.$store.commit("search/setToggleTimeOfDayEnabled", true);
    UgTag.pushEvent(UgEventTag.TimeModeEmphasis);
  }

  hideEmphasisCvBalloon() {
    this.showBalloon = false;
  }

  onEmphasisCvInput(value: number[]) {
    this.$store.commit("search/setSelectedConversionIds", value);
  }
}
</script>

<style lang="scss" scoped>
.userSearchResultOverview__menus {
  position: sticky;
  top: $headerHeight + 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  width: 100%;
  height: 36px;
  background-color: $colorWhite;
}

.userSearchResultOverview__definitions {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-right: 10px;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: $colorBase500;
    content: "";
  }
}

.userSearchResultOverview__list,
.userSearchResultOverview__emphasisButtonList {
  display: flex;

  &:not(:first-child) {
    margin-left: 12px;
  }
}

.userSearchResultOverview__item,
.userSearchResultOverview__emphasisButtonItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 44px;
  height: 26px;
  border-top: 1px solid $colorBase500;
  border-bottom: 1px solid $colorBase500;
  border-left: 1px solid $colorBase500;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: $sizeRadius;
    border-bottom-left-radius: $sizeRadius;
  }

  &:last-child {
    border-right: 1px solid $colorBase500;
    border-top-right-radius: $sizeRadius;
    border-bottom-right-radius: $sizeRadius;
  }
}

.userSearchResultOverview__emphasisCvButton,
.userSearchResultOverview__emphasisTimeButton {
  width: 86px;
  font-weight: 700;
}

.userSearchResultOverview__emphasisTimeButton {
  cursor: default;
  border-right: 1px solid $colorBase500;
  border-top-right-radius: $sizeRadius;
  border-bottom-right-radius: $sizeRadius;
}

.userSearchResultOverview__item--disabled,
.userSearchResultOverview__emphasisButtonItem--disabled {
  background-color: $colorGray100;
  color: $colorBase700;
  font-weight: normal;
}

.userSearchResultOverview__emphasisCvButton:hover,
.userSearchResultOverview__emphasisButtonItem--disabled:hover {
  background-color: $colorHoverLightForWhite;
}

.userSearchResultOverview__emphasisTimeButton.userSearchResultOverview__emphasisButtonItem--disabled {
  cursor: pointer;
}

.userSearchResultOverview__bussinessItem {
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 10px;
}

.userSearchResultOverview__emphasisToggle {
  display: flex;
  align-items: center;
  height: 26px;
}

.userSearchResultOverview__tooltip {
  margin-left: 5px;
}

.userSearchResultOverview__tooltipContent {
  line-height: 1.5;
}

/* v-html で表示するため `::v-deep` を使用 */
.userSearchResultOverview__tooltipContent
  ::v-deep
  .userSearchResultOverview__emphasisCvColor {
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
  width: 10px;
  height: 10px;
  border: 1px solid $colorHighlightedBarBg;
  background-color: $colorHighlightedBar;
}

/* v-html で表示するため `::v-deep` を使用 */
.userSearchResultOverview__timeModeNotes ::v-deep span {
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
  width: 10px;
  height: 10px;
  border: 1px solid $colorHighlightedBarBg;
}

.userSearchResultOverview__timeModeNotes ::v-deep .morningVisitWithCv {
  background-color: $colorMorningVisitWithCv;
}
.userSearchResultOverview__timeModeNotes ::v-deep .morningVisitWithOutCv {
  background-color: $colorMorningVisitWithOutCv;
}

.userSearchResultOverview__timeModeNotes ::v-deep .dayVisitWithCv {
  background-color: $colorDayVisitWithCv;
}
.userSearchResultOverview__timeModeNotes ::v-deep .dayVisitWithOutCv {
  background-color: $colorDayVisitWithOutCv;
}

.userSearchResultOverview__timeModeNotes ::v-deep .nightVisitWithCv {
  background-color: $colorNightVisitWithCv;
}
.userSearchResultOverview__timeModeNotes ::v-deep .nightVisitWithOutCv {
  background-color: $colorNightVisitWithOutCv;
}

.userSearchResultOverview__notes,
.userSearchResultOverview__timeModeHelpContent {
  margin-top: 10px;
}

.userSearchResultOverview__noteItem {
  position: relative;
  margin-top: 3px;
  padding-left: 13px;

  &:before {
    position: absolute;
    top: 1px;
    left: 4px;
    content: "-";
  }
}

.userSearchResultOverview__noteItemChild {
  position: relative;
  padding-left: 13px;

  &:before {
    position: absolute;
    top: 6px;
    left: 4px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $colorWhite;
    content: "";
  }
}

.userSearchResultOverview__emphasisCvButton--current {
  background-color: $colorBlue500;
}

.userSearchResultOverview__emphasisCvButtonText,
.userSearchResultOverview__emphasisTimeButtonText,
.userSearchResultOverview__emphasisCvIcon {
  display: flex;
  align-items: center;
  height: 24px;
}

.userSearchResultOverview__emphasisCvButtonText,
.userSearchResultOverview__emphasisTimeButtonText {
  font-size: 12px;
}

.userSearchResultOverview__emphasisCvIcon {
  margin-left: 5px;
}

.userSearchResultOverview__emphasisCvBalloon {
  position: absolute;
  top: 32px;
  right: 0;
}
</style>
