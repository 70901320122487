<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <SearchResultContainer
    :view="searchResultView"
    :label="label"
    :icon="searchIcon"
    :can-save-condition="isFunnelResult ? false : isAvailableUserList"
    :colored-periods="coloredPeriods"
    :is-show-empty-description="true"
    @view-change="onViewChange"
  >
    <template v-if="showConditionDetail" #conditions>
      <div class="user-search-result__search-condition-title">
        <span
          class="user-search-result__search-condition-title__clickable"
          data-cy="user-search-result__search-condition-title__clickable"
          @click="toggleShowSearchCondtion"
        >
          <span
            v-t="'currentSearchCondition'"
            class="user-search-result__search-condition-title__text"
          />
          <span class="user-search-result__search-condition-title__icon">
            <Icon
              :icon="showSearchConditionTitleIcon"
              :size="14"
              :color="showSearchConditionTitleIconColor"
            />
          </span>
        </span>
      </div>
    </template>
    <template v-else-if="showIntermediateJourneyCondition" #conditions>
      <div
        v-t="{
          path: 'intermediateJourneyCondition',
          args: { path: pathIndex }
        }"
        class="user-search-result__conditionLabel"
      />
    </template>

    <template v-if="showConditionDetail" #detailedConditions>
      <SelectFilterConditionLabel
        v-if="selectCondition"
        v-show="showSearchCondition"
        class="user-search-result__search-condition-detail"
        data-cy="result-header-panel-conditions"
        :select-condition="selectCondition"
        :filter-condition="filterCondition"
      />
    </template>
  </SearchResultContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { LoginUser } from "@/models/auth/UgUser";
import { isNumberText } from "@/util/string-util";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { handleError, handleNoQueryCacheError } from "@/util/error-util";
import { secToMsec, msecToUsec } from "@/util/date-util";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";
import SelectFilterConditionLabel from "@/views/SelectFilterConditionLabel.vue";
import { showAlert } from "@/util/modal-util";
import { SearchResultViews } from "@/const/SearchResultViews";
import { SearchHistory } from "@/models/search/SearchHistory";
import { addHidToUsersUrl } from "@/router";
import { ERROR_CREATE_CONDITION_FROM_HISTORY_ID } from "@/store/modules/search";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { getColoredPeriods } from "@/components/chart/chart-util";
import * as Sentry from "@sentry/browser";
import { FunnelStatus } from "@/const/funnel";
import { FunnelData } from "@/models/funnel/FunnelData";
import { SelectByUserJourneyCondition } from "@/models/search/select-condition/SelectByUserJourneyCondition";
import { now } from "@/util/date-util";

// ユーザID指定で観察画面を開く際の訪問取得開始日
const DEFAULT_START_VISIT_RANGE_DAY = 180;

@Component({
  components: {
    SearchResultContainer,
    SelectFilterConditionLabel,
    Icon
  }
})
export default class UserSearchResult extends Vue {
  searchIcon = Icons.Search;

  showSearchCondition = false;
  showSearchConditionTitleIconColor = Colors.Base700;

  async created() {
    const view = this.$route.query.view;
    switch (view) {
      case SearchResultViews.Memo:
        this.searchResultView = SearchResultViews.Memo;
        break;
      case SearchResultViews.Overview:
        this.searchResultView = SearchResultViews.Overview;
        break;
      default:
        this.searchResultView = SearchResultViews.Attribute;
    }

    // 観察画面をダイレクト開く場合
    if (this.$route.name === "user-detail") {
      this.openUserDetailFromParams();
    }
    // 旧フレームワークのパーマリンクで開いた場合
    if (this.$route.name === "before-framework-permalink") {
      this.openUserDetailFromOldPermalink();
    }

    if (this.isFunnelResult) {
      const { funnelId, funnelConditionIndex, type } = this.$route.query;

      await this.$store.commit("funnel/setCurrentId", funnelId);
      await this.$store
        .dispatch("funnel/fetchFunnelData", funnelId)
        .then((data: FunnelStatus) => {
          this.$store.commit("funnel/setStatus", data);
          this.$store.commit(
            "funnel/setSelectedOrder",
            Number(funnelConditionIndex) + 1
          );
          this.$store.commit(
            "funnel/setIsMatchedSelected",
            type === "START_END"
          );
        });
    }

    // historyIdがある、かつ検索条件がセットされていないなら、historyIdから条件を取得して検索する
    if (this.$route.query.hid && this.selectCondition === null) {
      await this.executeSearchByHistoryId(Number(this.$route.query.hid));
    }

    if (this.invalidSearchHistoryDateText !== "") {
      showAlert(this.invalidSearchHistoryDateText);
      this.$store.commit("search/setInvalidSearchHistoryDateText", "");
    }

    // 検索条件がまだセットされていなければ、最後に実行した検索条件で検索する
    if (this.selectCondition === null) {
      // URLにJSON化した検索条件つけてアクセスすることにより、条件を戻す旧方式の場合は
      // URLにcnd=xxxとcndをパラメータとしてつけていた。
      // 現在では、cndを付ける方式はスルーして最後にやった検索条件で検索するので、
      // cndがURLについていれば、URLが無効な旨をアラートで表示する
      if (this.$route.query.cnd) {
        showAlert(this.$i18n.t("invalidUrl") as string);
      }
      this.executeSearchWithLastExecutedCondition();
    }
  }

  get label(): string {
    return this.$i18n.t("defaultLabel") as string;
  }

  get selectCondition(): SelectCondition | null {
    return this.$store.state.search.selectCondition;
  }

  get filterCondition(): FilterCondition {
    return this.$store.state.filter.filterCondition;
  }

  get invalidSearchHistoryDateText(): string {
    return this.$store.state.search.invalidSearchHistoryDateText;
  }

  get showSearchConditionTitleIcon(): string {
    return this.showSearchCondition ? Icons.ArrowTop : Icons.ArrowBottom;
  }

  get searchResultView(): SearchResultViews {
    return this.$store.state.preference.searchResultView;
  }

  set searchResultView(searchResultView: SearchResultViews) {
    this.$store.commit("preference/setSearchResultView", searchResultView);
  }

  get loginUser(): LoginUser {
    return this.$store.state.auth.user;
  }

  get isAvailableUserList(): boolean {
    return this.loginUser.permission.isAvailableUserList;
  }

  get pathIndex(): string {
    return this.$route.query.pathIndex as string;
  }

  get showIntermediateJourneyCondition(): boolean {
    return (
      this.isFunnelResult &&
      isNumberText(this.$route.query.funnelConditionIndex as string)
    );
  }

  get isFunnelResult(): boolean {
    return (
      !!this.$route.query.funnelId && !!this.$route.query.funnelConditionIndex
    );
  }

  get funnelData(): FunnelData[] {
    return this.$store.getters["funnel/funnelData"];
  }

  get coloredPeriods(): ColoredPeriod[] {
    if (this.isFunnelResult && this.funnelData && this.funnelData.length > 0) {
      const funnelConditionIndex = Number(
        this.$route.query.funnelConditionIndex
      );
      return [
        new ColoredPeriod(
          this.$i18n.t("designatedPeriod") as String,
          this.funnelData[funnelConditionIndex - 1].condition.startDate,
          this.funnelData[funnelConditionIndex].condition.endDate,
          Colors.Blue730,
          Colors.Blue800
        )
      ];
    }
    if (this.selectCondition) {
      return getColoredPeriods(this.selectCondition);
    }
    return [];
  }
  /**
   * Search by SelectByUserIdCondition when opening a user directly.
   * /users/:userId
   */
  get showConditionDetail(): boolean {
    return (
      !(this.selectCondition instanceof SelectByUserIdCondition) &&
      !(this.selectCondition instanceof SelectByUserJourneyCondition)
    );
  }

  toggleShowSearchCondtion() {
    this.showSearchCondition = !this.showSearchCondition;
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
    let query: { [key: string]: string } = { ...this.$route.query, view };
    this.$router.replace({ query });
  }

  openUserDetailFromParams() {
    const userId = this.$route.params.userId;

    // 観察画面を直接開く際にどの時間の訪問から表示するか
    const visitTime = this.$route.query.vt as string;

    // 初期値としてDEFAULT_START_VISIT_DAY_RANGE日前をセット
    let startBaseTimeUsec = msecToUsec(
      now() - secToMsec(DEFAULT_START_VISIT_RANGE_DAY * 24 * 60 * 60)
    );

    // クエリのパラメータに訪問時間が指定されていればその時間を使う
    if (visitTime && isNumberText(visitTime)) {
      startBaseTimeUsec = msecToUsec(secToMsec(Number(this.$route.query.vt)));
    }
    this.executeSelectWithUserIdAndStartBaseTime(userId, startBaseTimeUsec);
  }

  openUserDetailFromOldPermalink() {
    try {
      const decodedStr = decodeURIComponent(
        escape(atob(this.$route.params.link))
      );
      const decoded = JSON.parse(decodedStr);
      const userId = decoded.userId;
      if (!userId) {
        throw new Error();
      }

      // 初期値としてDEFAULT_START_VISIT_DAY_RANGE日前をセット
      let startBaseTimeUsec = msecToUsec(
        now() - secToMsec(DEFAULT_START_VISIT_RANGE_DAY * 24 * 60 * 60)
      );

      // デコードしたパラメータに時間が入っていればその時間を使う
      let visitTime = decoded.visitStartTimeSec;
      if (visitTime && isNumberText(String(visitTime))) {
        startBaseTimeUsec = msecToUsec(secToMsec(visitTime));
      }
      this.executeSelectWithUserIdAndStartBaseTime(userId, startBaseTimeUsec);
    } catch (e) {
      this.executeSearchWithLastExecutedCondition();
    }
  }

  executeSelectWithUserIdAndStartBaseTime(
    userId: string,
    startBaseTimeUsec: number
  ) {
    // 検索後に開くユーザ情報をセット
    this.$store.commit("user/setTartgeUserId", userId);
    this.$store.commit("user/setTargetStartBaseTimeUsec", startBaseTimeUsec);

    // 検索条件をセット
    const selectCondition = new SelectByUserIdCondition([userId]);
    this.$store
      .dispatch("search/executeSelect", {
        selectCondition,
        setHistoryIdToUrl: addHidToUsersUrl(this.$router)
      })
      .catch(error => {
        handleNoQueryCacheError(error);
        Sentry.captureException(error);
      });
  }

  async executeSearchByHistoryId(historyId: number) {
    await this.$store
      .dispatch("search/executeSelectFromHistoryId", historyId)
      .catch((error: any) => {
        // 条件がサーバから取得出来なかった場合はエラーを表示して、一番最後に実行した検索条件で検索
        if (
          error.message &&
          error.message === ERROR_CREATE_CONDITION_FROM_HISTORY_ID
        ) {
          showAlert(this.$i18n.t("invalidUrl") as string);
          this.executeSearchWithLastExecutedCondition();
          return;
        }
        handleNoQueryCacheError(error);
        Sentry.captureException(error);
      });
  }

  executeSearchWithLastExecutedCondition() {
    const searchHistories: SearchHistory[] = this.$store.getters[
      "searchHistory/searchHistories"
    ];
    const history = searchHistories.length > 0 ? searchHistories[0] : null;

    // 実行した検索条件が1件もなければホームに飛ばす
    if (history === null) {
      this.$router.replace("/");
    } else {
      this.$store
        .dispatch("search/executeSelectFromHistory", {
          history,
          setHistoryIdToUrl: addHidToUsersUrl(this.$router)
        })
        .catch(error => {
          handleError(error);
          Sentry.captureException(error);
        });

      let query: { [key: string]: string } = {};

      this.$router.replace({ name: "users", query });
    }
  }
}
</script>
<style lang="scss" scoped>
.user-search-result__search-condition-title {
  margin-top: 4px;
  text-align: center;
}

.user-search-result__search-condition-title__text {
  margin-right: 8px;
  font-size: 14px;
}

.user-search-result__search-condition-title__clickable {
  display: inline-flex;
  align-items: center;
  padding: 4px 0 2px 0;
  color: $colorBase700;
  cursor: pointer;
  &:hover {
    .user-search-result__search-condition-title__text {
      text-decoration: underline;
    }
  }
}

.user-search-result__search-condition-detail {
  margin: 10px 0 5px 0;
}

@media print {
  .user-search-result__search-condition-detail {
    display: block !important;
  }
}

.user-search-result__conditionLabel {
  margin-top: 10px;
  color: $colorBase700;
  font-size: 14px;
  word-break: break-all;
  line-height: 1.5;
}
</style>
