import { TdConversionAttributeResponse } from "@/api/apis/client-settings/ApiTdConversionAttribute";

export class TdConversionAttributeDefinition {
  constructor(
    public readonly id: number,
    public readonly cvAttributeId: number,
    public readonly cvAttributeColumn: string,
    public readonly isDisabled: boolean
  ) {}
  public static fromJson(
    json: TdConversionAttributeResponse
  ): TdConversionAttributeDefinition {
    return new TdConversionAttributeDefinition(
      json.id,
      json.cv_attr_id,
      json.cv_attr_column,
      json.is_disabled
    );
  }
}
