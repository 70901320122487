import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

export default class ApiAuth {
  constructor(private readonly httpClient: HttpClient) {}

  async getToken(): Promise<string> {
    const res = await this.httpClient.get<{
      success: boolean;
      csrf_token?: string;
    }>(ApiUrl.GET_TOKEN);
    if (res.success !== true || !res.csrf_token) {
      throw new Error("cannot get csrf token");
    }
    this.httpClient.updateCsrfToken(res.csrf_token);
    return res.csrf_token;
  }

  public getLoginUser(): Promise<ApiRes.GetLoginUser> {
    return this.httpClient.get<ApiRes.GetLoginUser>(ApiUrl.GET_UG_USER);
  }

  public login(id: string, password: string): Promise<ApiRes.Login> {
    return this.httpClient.post<ApiRes.Login>(ApiUrl.LOGIN, {
      username: id,
      password
    });
  }

  public logout(): Promise<void> {
    return this.httpClient.get<void>(ApiUrl.LOGOUT);
  }

  public updateLanguage(language: string): Promise<ApiRes.UpdateLanguage> {
    return this.httpClient.post<ApiRes.UpdateLanguage>(ApiUrl.SET_LANG, {
      language
    });
  }
}
