<template>
  <div class="user-detail">
    <div class="ud-Main" :tabindex="-1" @keydown.esc="onKeyDownEsc">
      <UserHeader
        ref="header"
        :balloon-chart-period="balloonChartPeriod"
        :user="currentUser"
        :highlight-msec="highlightMsec"
        :is-omo="isOmo"
        :select-condition="selectCondition"
        :client-pv-stats="clientPvStats"
        :colored-periods="coloredPeriods"
        :almost-cv-gram-ids="almostCvGramIds"
        :funnel-matched-gram-ids="funnelMatchedGramIds"
        :funnel-matched-selected-gram-ids="funnelMatchedSelectedGramIds"
        :selected-conversion-ids="selectedConversionIds"
        :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
        @show-balloon="showUserDetailBalloon"
        @hide-balloon="hideUserDetailBalloon"
        @click="onClickChartIcon"
        @chart-period-click="onChartPeriodClick"
      >
        <template #actions>
          <div class="ud-UserHeader_UserActions">
            <FavoriteButton
              class="ud-UserHeader_UserActionButton"
              data-cy="favorite-button-in-UserHeader"
              :user="currentUser"
            />
            <MemoButton class="_UserActionButton" :user="currentUser" />
          </div>
        </template>
        <template #companyActivityDisplaySetting>
          <CompanyActivityDisplaySetting
            v-if="0 < companyActivityDisplaySettings.length"
            :company-activity-display-settings="companyActivityDisplaySettings"
            @update="updateCompanyActivityDisplaySetting"
          />
        </template>
        <template #userDetailBalloon>
          <div v-if="isShowUserDetailBalloon" class="userDetailBalloon">
            <UserDetailBalloon
              :user="currentUser"
              :conversion-attribute-definitions="conversionAttributeDefinitions"
              :business-index-definitions="businessIndexDefinitions"
              :user-attribute-definitions="userAttributeDefinitions"
              :opened-word-cloud="openedWordCloud"
            >
              <WordcloudContainer :user-id="currentUser.id" />
            </UserDetailBalloon>
          </div>
        </template>
      </UserHeader>
      <div
        ref="visitScroll"
        class="ud-Visits"
        :tabindex="0"
        :style="{
          'margin-top': headerHeight + 'px',
          height: 'calc(100% - ' + headerHeight + 'px)'
        }"
        @scroll="onScroll"
      >
        <div v-if="showEmptyLoading" class="ud-Loading_Empty">
          <img src="../assets/img/loading_circle.gif" />
        </div>

        <UserHasNoActivity v-if="showTextWhenNoActivity" />

        <div v-if="showPastLoading" class="ud-Loading_Past">
          <img src="../assets/img/loading_circle.gif" />
        </div>
        <div ref="visitList">
          <div
            v-for="(visit, index) in visits"
            :key="'wrap' + visit.key"
            :style="addLastVisitHeight(visit.isLast)"
          >
            <VisitWrapper
              :key="visit.key"
              class="userDetail__visitWrapper"
              :is-omo="isOmo"
              :visit="visit"
              :is-first-visit="index === 0"
              :previewed-gram-id="webPreviewGramId"
              :filter-titles="filterTitles"
              :filter-full-urls="filterFullUrls"
              :filter-part-urls="filterPartUrls"
              @click-pv="onClickPv"
              @scroll-to-next="scrollToNextVisit"
              @scroll-to-previous="scrollToPreviousVisit"
            />
            <div v-if="!visit.isLast" :key="index" class="ud-Visit_Elapsed">
              {{ visit.elapsedTimeTx }}
            </div>
          </div>
        </div>
        <div v-if="showFutureLoading" class="ud-Loading_Future">
          <img src="../assets/img/loading_circle.gif" />
        </div>
      </div>
    </div>
    <Transition name="preview">
      <div
        v-show="isDisplayingWebPreview"
        class="ud-WebPreview"
        @click="hideWebPreview"
        @wheel.prevent="hideWebPreview"
      >
        <WebPreview
          :url="webPreviewUrl"
          :is-smartphone="webPreviewIsSmartphone"
        />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop, Emit } from "vue-property-decorator";
import { Favorite } from "@/models/userdata/Favorite";
import { Visit } from "@/models/Visit";
import { User } from "@/models/User";
import { CompanyActivity } from "@/models/CompanyActivity";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { Msec, msecToSec } from "@/util/date-util";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";

import MemoButton from "@/views/MemoButton.vue";
import FavoriteButton from "@/views/FavoriteButton.vue";
import UserHeader from "@/components/observation/user-header/UserHeader.vue";
import VisitWrapper from "@/components/observation/visit/VisitWrapper.vue";
import CompanyActivityDisplaySetting from "@/components/observation/user-header/CompanyActivityDisplaySetting.vue";
import UserDetailBalloon from "@/components/observation/user-header/UserDetailBalloon.vue";
import WebPreview from "@/components/observation/WebPreview.vue";
import UserHasNoActivity from "@/components/users/UserHasNoActivity.vue";

import { FirstVisitsParams } from "@/store/modules/visit";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { ClientSettingsState } from "@/store/modules/clientSettings";
import { getPaperHeight } from "@/components/chart/chart-util";
import { handleError } from "@/util/error-util";
import { ChartPeriod } from "@/const/chart-period";

import { FilterNode } from "@/models/search/filter-node/FilterNode";
import { FilterNodeForInflow } from "@/models/search/filter-node/FilterNodeForInflow";
import { FilterNodeForBrowseSite } from "@/models/search/filter-node/FilterNodeForBrowseSite";
import { WordForHighlight } from "@/models/search/MatchMethod";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import WordcloudContainer, {
  WordcloudResult
} from "@/views/WordcloudContainer.vue";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";
import {
  UgTag,
  UgEventTag,
  UgAttributeTag,
  UgConversionTag
} from "@/store/modules/ugTag";
import { TourCondition } from "@/models/tour/TourCondition";
import { FilterNodeForOrNode } from "@/models/search/filter-node/FilterNodeForOrNode";
import { GramListOfUser } from "@/api/apis/ApiFunnel";
import { now, getCurrentDate } from "@/util/date-util";

const LOADING_AREA_HEIGHT = 128;
const DELAY_SHOW_BALLOON_TIME = 300;
const DELAY_HIDE_BALLOON_TIME = 300;
const WEB_PREVIEW_REVERSE_PROXY_URL = "https://proxy.usergram.info";
const SCROLL_UPDATE_DELAY = 400;
const SPACE_SCROLLBAR_CHART: number = 10;
const INTERVAL_MSEC_CHECK_POSITION: number = 500;

interface OffsetDateForHighlight {
  offsetTop: number;
  date: Date;
}

@Component({
  components: {
    UserHeader,
    VisitWrapper,
    FavoriteButton,
    MemoButton,
    CompanyActivityDisplaySetting,
    UserDetailBalloon,
    WebPreview,
    WordcloudContainer,
    UserHasNoActivity
  }
})
export default class UserDetail extends Vue {
  scrollPosition = 0;
  visitListHeight = 0;
  topVisit: Visit | null = null;

  isShowUserDetailBalloonFlag = false;
  userDetailBalloonDisplayTimer: number | null = null;
  userDetailBalloonHideTimer: number | null = null;

  isManualScroll = true;
  isDisplayingWebPreview = false;
  webPreviewIsSmartphone = false;
  webPreviewUrl = "";
  webPreviewGramId = "";

  highlightMsec: Msec | null = null;
  scrollTimerId: number = 0;
  scrollLastExecTime: Msec = 0;
  // オートスクロール中は動きがおかしくなるのでhightlightを更新させないためのフラグ
  stopUpdateScrollHighlight = false;
  throttleLastTime: number = now();

  offsetDates: OffsetDateForHighlight[] = [];

  clientPvStats: ClientPvStats = this.$store.state.system.clientPvStats;

  currentHighlight: Msec | null = null;

  balloonChartPeriod: ChartPeriod | null = null;
  // Flag to check if fetch visit complete and user clicked on chart icon
  // This two flag require to determine showTextWhenNoActivity,
  isFetchedVisits = false;
  isClickedChartIcon = false;

  observationOpenTime: Date = getCurrentDate();

  @Prop({ type: Array, default: () => [] })
  coloredPeriods!: ColoredPeriod[];

  @Emit("keydown-esc")
  onKeyDownEsc() {}

  @Watch("highlightMsec")
  onHighlightMsecChanged() {
    if (this.highlightMsec === null) {
      return;
    }
    const url = location.pathname + "?vt=" + msecToSec(this.highlightMsec);
    history.replaceState("", "", url);
  }
  // To highlight the last icon by showing the last visit in the top of screen
  addLastVisitHeight(isLast: boolean) {
    if (isLast) {
      return { "min-height": "calc(100vh - " + this.headerHeight + "px)" };
    }
  }

  get currentUser(): User {
    return this.$store.state.user.currentUser;
  }

  // 訪問に表示されるOMOの設定があるか
  get isOmo(): boolean {
    return this.$store.getters["clientSettings/hasActiveOmoDefinitions"];
  }

  get headerHeight() {
    return getPaperHeight(this.isOmo) + SPACE_SCROLLBAR_CHART;
  }

  get companyActivityDisplaySettings(): CompanyActivity[] {
    return this.$store.state.companyActivityDisplaySetting.displaySettings;
  }

  get showPastLoading(): boolean {
    return this.$store.state.visit.hasNextPast && this.hasVisit;
  }

  get showFutureLoading(): boolean {
    return this.$store.state.visit.hasNextFuture && this.hasVisit;
  }

  get showEmptyLoading(): boolean {
    return (!this.isFetchedVisits || this.isClickedChartIcon) && !this.hasVisit;
  }

  get showTextWhenNoActivity(): boolean {
    return this.isFetchedVisits && !this.hasVisit && !this.isClickedChartIcon;
  }

  get hasVisit(): boolean {
    return 0 < this.visits.length;
  }

  get visits(): Visit[] {
    return this.$store.getters["visit/displayableVisits"];
  }

  get conversionAttributeDefinitions(): ConversionAttributeDefinition[] {
    return (this.$store.state.clientSettings as ClientSettingsState)
      .activeConversionAttributeDefinitions;
  }

  get businessIndexDefinitions(): BusinessIndexDefinition[] {
    return (this.$store.state.clientSettings as ClientSettingsState)
      .activeBusinessIndexDefinitions;
  }

  get userAttributeDefinitions(): UserAttributeDefinition[] {
    return (this.$store.state.clientSettings as ClientSettingsState)
      .activeUserAttributeDefinitions;
  }

  get isShowUserDetailBalloon(): boolean {
    return this.isShowUserDetailBalloonFlag;
  }

  get almostCvGramIds(): string[] {
    return this.almostCvUserList.getGramIdsByUserId(this.currentUser.id);
  }

  get almostCvUserList(): AlmostCvUserList {
    return this.$store.state.almostCv.almostCvUserList;
  }

  get isFunnelResult(): boolean {
    return (
      this.$route.name === "funnel-analysis-detail" ||
      !!this.$route.query.funnelId
    );
  }
  get funnelSelectedOrder(): number {
    return this.$store.state.funnel.selectedOrder;
  }
  get funnelGramIdsPerUser(): GramListOfUser {
    return this.$store.getters["funnel/funnelGramIdsPerUser"];
  }
  get funnelUserGrams(): string[] {
    return this.funnelGramIdsPerUser[this.currentUser.id];
  }
  get funnelMatchedGramIds(): string[] {
    if (!this.isFunnelResult) return [];
    if (!this.funnelUserGrams) return [];

    return this.funnelUserGrams.slice(0, this.funnelSelectedOrder - 1);
  }
  get isIntermediateResult() {
    return this.$route.path.includes("users") && !!this.$route.query.funnelId;
  }
  get funnelMatchedSelectedGramIds(): string[] {
    if (!this.isFunnelResult) return [];
    if (!this.funnelUserGrams) return [];

    return this.isIntermediateResult // For intermediate result we select the gram for the next condition as well
      ? [
          this.funnelUserGrams[this.funnelSelectedOrder - 2],
          this.funnelUserGrams[this.funnelSelectedOrder - 1]
        ]
      : [this.funnelUserGrams[this.funnelSelectedOrder - 1]];
  }

  // 絞り込み「サイト内のページを閲覧」にセットされたタイトルをハイライトするために渡す
  get filterTitles(): WordForHighlight[] {
    let titles: WordForHighlight[] = [];
    const nodes: FilterNode[] = this.$store.state.filter.filterCondition
      .filterNodes;
    nodes.forEach(node => {
      if (
        node instanceof FilterNodeForOrNode ||
        node instanceof FilterNodeForBrowseSite
      ) {
        titles = titles.concat(node.titlesForHighlight);
      }
    });
    return titles;
  }

  // 絞り込み「サイト内のページを閲覧」にセットされたURL完全一致をハイライトするために渡す
  get filterFullUrls(): WordForHighlight[] {
    let urls: WordForHighlight[] = [];
    const nodes: FilterNode[] = this.$store.state.filter.filterCondition
      .filterNodes;
    nodes.forEach(node => {
      if (
        node instanceof FilterNodeForOrNode ||
        node instanceof FilterNodeForBrowseSite
      ) {
        urls = urls.concat(node.fullUrlsForHighlight);
      }
    });
    return urls;
  }

  // 絞り込み「サイト内のページを閲覧」と「流入パラメータ」にセットされたURL部分一致をハイライトするために渡す
  get filterPartUrls(): WordForHighlight[] {
    let urls: WordForHighlight[] = [];
    const nodes: FilterNode[] = this.$store.state.filter.filterCondition
      .filterNodes;
    nodes.forEach(node => {
      if (
        node instanceof FilterNodeForOrNode ||
        node instanceof FilterNodeForBrowseSite ||
        node instanceof FilterNodeForInflow
      ) {
        urls = urls.concat(node.partUrlsForHighlight);
      }
    });
    return urls;
  }

  get selectCondition(): SelectCondition {
    return this.$store.state.search.selectCondition;
  }

  get chartPeriod(): ChartPeriod {
    return this.$store.state.preference.chartPeriod;
  }

  get openedWordCloud(): boolean {
    const isLoading = !!this.$store.state.wordcloud.wordcloudLoading[
      this.currentUser.id
    ];
    const results: WordcloudResult = this.$store.state.wordcloud
      .wordcloudResult;

    return this.currentUser.id in results || isLoading;
  }

  get selectedConversionIds(): number[] {
    return this.$store.state.search.selectedConversionIds;
  }

  get isToggleTimeOfDayEnabled(): boolean {
    return this.$store.state.search.toggleTimeOfDayEnabled;
  }

  get currentTour(): TourCondition | null {
    return this.$store.state.tour.currentTour;
  }

  async onClickChartIcon(
    overview:
      | VisitOverview
      | NpsOverview
      | BusinessEventOverview
      | EnqueteOverview
      | ContactOverview
  ) {
    // We need this flag, so that we can ignore checking
    this.isClickedChartIcon = true;

    const el = document.getElementById(overview.gramId);
    // クリックされた訪問があればgramまでスクロールさせる
    if (el !== null) {
      this.scrollToVisitByGramId(overview.gramId, true);
      return;
    }
    // クリックされた訪問はないので、現行の訪問を破棄して訪問を取得する
    const params: FirstVisitsParams = {
      user: this.currentUser,
      baseTimeUsec: overview.date.getTime() * 1000
    };
    this.stopUpdateScrollHighlight = true;
    this.topVisit = null;
    this.scrollPosition = 0;
    this.visitListHeight = 0;
    this.highlightMsec = overview.date.getTime();
    this.currentHighlight = this.highlightMsec;
    await this.$store.dispatch("visit/fetchFirst", params).catch(error => {
      throw new Error(error);
    });
    this.stopUpdateScrollHighlight = false;
  }

  onChartPeriodClick(chartPeriod: ChartPeriod) {
    this.highlightMsec = null;
    this.balloonChartPeriod = chartPeriod;

    window.setTimeout(() => {
      this.highlightMsec = this.currentHighlight;
    }, 300);
  }

  async mounted() {
    const params: FirstVisitsParams = {
      user: this.currentUser,
      baseTimeUsec: this.$store.state.user.startBaseTimeUsec
    };
    this.adjustPastLoading();
    (this.$refs.visitScroll as HTMLInputElement).focus();

    await this.$store.dispatch("visit/fetchFirst", params).catch(error => {
      throw new Error(error);
    });
    this.isFetchedVisits = true;
    UgTag.pushCv(UgConversionTag.ObservationOpen);

    if (this.currentTour !== null) {
      const tourUrl = location.origin + "/tours/" + this.currentTour.id;

      UgTag.pushEvent(UgEventTag.TourObservation, {
        [UgAttributeTag.UrlOfTourDetail]: tourUrl,
        [UgAttributeTag.UserObservedViaTour]: this.currentUser.id,
        [UgAttributeTag.TourTitle]: this.currentTour.title
      });
    }
    this.observationOpenTime = getCurrentDate();
  }

  created() {
    this.balloonChartPeriod = this.chartPeriod;
  }

  updated() {
    this.adjustPastLoading();
    const el = document.querySelector(".ud-Visits");
    if (el) {
      // 保存しているスクロールできる高さと実際のスクロールできる高さが違う
      // (訪問がリセットもしくは追加で読み込まれた)
      if (this.visitListHeight !== el.scrollHeight) {
        // 過去方向に追加された場合は追加分スクロールをずらす
        if (
          this.topVisit &&
          this.visits.length > 0 &&
          this.visits[0].grams[0].timeSec < this.topVisit.grams[0].timeSec
        ) {
          // To prevent the web preview from closing automatically when scrolling by adding a visit.
          this.isManualScroll = false;
          el.scrollTop =
            el.scrollHeight - this.visitListHeight + this.scrollPosition;
        }
      }
      this.visitListHeight = el.scrollHeight;
    }

    // 追加されたデータが過去分かチェックするために先頭の訪問を保持しておく
    if (this.visits && 0 < this.visits.length) {
      const topVisit = this.visits[0];
      // topVisitが無いのは初めのfetchなので、先頭の訪問時間でハイライトする
      if (!this.topVisit) {
        this.highlightMsec = topVisit.startTimeDate.getTime();
        this.currentHighlight = this.highlightMsec;
      }
      this.topVisit = topVisit;
    }

    // Overviewの現在地表示ハイライトに使用する訪問の場所 x 時間を保存しておく
    const elements = this.$refs.visitList as HTMLElement;
    this.offsetDates = [];
    const headerHeight = (this.$refs.header as Vue).$el.clientHeight;

    if (!elements) return;

    for (let i = 0; i < elements.children.length; i++) {
      const visit = this.visits[i];
      const element = elements.children[i] as HTMLElement;
      if (visit && element) {
        // -1で調整しないと一つ前の訪問にハイライトが付く
        this.offsetDates.push({
          offsetTop: element.offsetTop - headerHeight - 1,
          date: visit.startTimeDate
        });
      }
    }
  }

  destroyed() {
    // 保持している訪問のクリア
    this.$store.dispatch("visit/clearVisit");
    this.$store.state.user.showUserDetail = false;

    const observationCloseTime = getCurrentDate();
    const msecObservationOpenClose =
      observationCloseTime.getTime() - this.observationOpenTime.getTime();
    UgTag.pushCv(UgConversionTag.ObservationClosed, {
      [UgAttributeTag.StayTimeObservationOpenCloseSec]: msecToSec(
        msecObservationOpenClose
      )
    });
  }

  // はじめに訪問を表示するときに頭にあるpast loading分スクロールさせる
  adjustPastLoading() {
    if (!this.hasVisit) {
      return;
    }

    const el = document.querySelector(".ud-Visits");
    if (el) {
      if (this.topVisit === null && this.$store.state.visit.hasNextPast) {
        el.scrollTop = LOADING_AREA_HEIGHT;
      }
    }
    const gramId = this.$store.state.user.shouldScrollAndDisplayGramId;
    if (gramId !== null) {
      this.scrollToVisitByGramId(gramId, false);
      this.$store.commit("user/setShouldScrollAndDisplayGramId", null);
    }
  }

  closeFavoriteDiaglog() {
    this.$store.dispatch("user/closeFavoriteDialog");
  }

  showFavoriteDiaglog() {
    this.$store.dispatch("user/showFavoriteDialog");
  }

  addFavorite(favorite: Favorite) {
    this.$store.dispatch("user/addFavorite", favorite);
  }

  removeFavorite(userId: string) {
    this.$store.dispatch("user/removeFavorite", userId);
  }

  updateCompanyActivityDisplaySetting(settings: CompanyActivity[]) {
    this.$store.dispatch("companyActivityDisplaySetting/update", settings);
  }

  /**
   * Judge whether to fetch by scroll position.
   * to prevent users from waiting for extra time.
   * @returns  true when displaying below the second visit from the bottom of the page
   * @param eventTarget  top dom displayed in the viewport
   */
  isPositionToFetchFuture(eventTarget: HTMLDivElement): boolean {
    const visitElements = document.querySelectorAll(
      ".userDetail__visitWrapper"
    );

    if (visitElements.length <= 2) {
      return false;
    }

    let totalHeightsOfLastTwoVisits: number = 0;
    for (let i: number = 1; i <= 2; i++) {
      const element = visitElements[visitElements.length - i];
      totalHeightsOfLastTwoVisits += element.clientHeight;
    }

    const visitScrollHeight: number = (this.$refs.visitScroll as HTMLElement)
      .scrollHeight;
    // The value of positionToLoad is an approximate value, ignoring the distance between visits.
    const positionToLoad: number =
      visitScrollHeight - totalHeightsOfLastTwoVisits;

    return eventTarget.scrollTop + eventTarget.offsetHeight >= positionToLoad;
  }

  /**
   * To reduce the load, call function every given miliseconds later.
   * @return void
   * @param fn  function you do not want to call many times
   * @param interval  miliseconds
   */
  throttle(fn: () => void, interval: number) {
    if (this.throttleLastTime + interval < now()) {
      fn();
      this.throttleLastTime = now();
    }
  }

  onScroll(event: UIEvent) {
    // スクロールしたらWebPreviewは閉じる、自動
    if (this.isDisplayingWebPreview && this.isManualScroll) {
      this.hideWebPreview();
    }
    this.isManualScroll = true;
    const evTarget = event.target;
    if (evTarget !== null) {
      const target: HTMLDivElement = evTarget as HTMLDivElement;
      this.scrollPosition = target.scrollTop;

      // isPositionToFetchFuture()の処理が重いので、throttleでcall回数を減らす。
      this.throttle(() => {
        // 未来方向のロードを開始するか
        if (
          this.$store.state.visit.hasNextFuture &&
          !this.$store.state.visit.isLoadingFuture &&
          this.isPositionToFetchFuture(target)
        ) {
          this.$store
            .dispatch("visit/fetchNextFuture", this.currentUser)
            .catch(error => {
              handleError(error);
              throw new Error(error);
            });
        }
      }, INTERVAL_MSEC_CHECK_POSITION);
      // 過去方向のロードを開始するか
      if (
        target.scrollTop < LOADING_AREA_HEIGHT &&
        this.$store.state.visit.hasNextPast &&
        !this.$store.state.visit.isLoadingPast
      ) {
        this.$store
          .dispatch("visit/fetchNextPast", this.currentUser)
          .catch(error => {
            handleError(error);
            throw new Error(error);
          });
      }

      // overviewのハイライト更新（スクロール度に処理すると重くなるので処理を間引いている）
      let elapsedTime = performance.now() - this.scrollLastExecTime;
      // ハイライトするGramを自然にするためにチェックする時の調整を入れる
      const adjustmentCheckPosition = -200;
      const updateHighLight = () => {
        this.offsetDates.forEach((now, index) => {
          // 次の訪問がある場合
          if (index < this.offsetDates.length - 2) {
            const next = this.offsetDates[index + 1];
            if (
              this.scrollPosition >= now.offsetTop + adjustmentCheckPosition &&
              this.scrollPosition < next.offsetTop + adjustmentCheckPosition
            ) {
              this.highlightMsec = now.date.getTime();
              this.currentHighlight = this.highlightMsec;
            }
          }
          // 次の訪問がない
          else {
            if (now.offsetTop <= this.scrollPosition) {
              this.highlightMsec = now.date.getTime();
              this.currentHighlight = this.highlightMsec;
            }
          }
        });

        this.scrollLastExecTime = performance.now();
      };
      if (this.scrollTimerId === 0 && !this.stopUpdateScrollHighlight) {
        updateHighLight();
      }
      if (this.scrollTimerId) {
        clearTimeout(this.scrollTimerId);
      }
      if (!this.stopUpdateScrollHighlight) {
        if (elapsedTime > SCROLL_UPDATE_DELAY) {
          updateHighLight();
        } else {
          this.scrollTimerId = window.setTimeout(
            updateHighLight,
            SCROLL_UPDATE_DELAY
          );
        }
      }
    }
  }

  scrollToVisitByGramId(gramId: string, shouldShowVistTop: boolean) {
    const el = document.getElementById(gramId);
    if (el === null) {
      this.stopUpdateScrollHighlight = false;
      return;
    }

    const options = {
      container: ".ud-Visits",
      y: true,
      offset: 0,
      onDone: () => {
        this.stopUpdateScrollHighlight = false;
      }
    };

    // 訪問トップではなく直接そのgramにスクロールする
    if (!shouldShowVistTop) {
      options.offset -= 20;
      this.$scrollTo(el, options);
    } else {
      const body = el.parentElement;
      if (body) {
        // 訪問トップ
        const visit = body.parentElement;
        if (visit) {
          this.$scrollTo(visit, options);
        }
      }
    }
  }

  /**
   * UserDetailBalloonの表示
   * すぐに表示してしまうとマウスが上を通っただけでも表示してしまうので、
   * DELAY_SHOW_BALLOON_TIME （ミリ秒）後に表示させる
   * 遅延非表示timerの停止も同時に行う
   */
  showUserDetailBalloon() {
    if (this.userDetailBalloonDisplayTimer !== null) {
      clearTimeout(this.userDetailBalloonDisplayTimer);
      this.userDetailBalloonDisplayTimer = null;
    }

    this.userDetailBalloonDisplayTimer = window.setTimeout(() => {
      this.isShowUserDetailBalloonFlag = true;
    }, DELAY_SHOW_BALLOON_TIME);

    if (this.userDetailBalloonHideTimer !== null) {
      clearTimeout(this.userDetailBalloonHideTimer);
      this.userDetailBalloonHideTimer = null;
    }
  }

  /**
   * UserDetailBalloonの非表示
   * すぐに非表示にしてしまうとバルーン内をクリックしようと
   * 顔アイコンからバルーンへマウスを動かすと
   * バルーンが消えてクリックできないので
   * DELAY_HIDE_BALLOON_TIME （ミリ秒）後に消す
   * 遅延表示timerの停止も同時に行う
   */
  hideUserDetailBalloon() {
    if (this.userDetailBalloonDisplayTimer !== null) {
      clearTimeout(this.userDetailBalloonDisplayTimer);
      this.userDetailBalloonDisplayTimer = null;
    }

    if (this.userDetailBalloonHideTimer !== null) {
      clearTimeout(this.userDetailBalloonHideTimer);
      this.userDetailBalloonHideTimer = null;
    }
    this.userDetailBalloonHideTimer = window.setTimeout(() => {
      this.isShowUserDetailBalloonFlag = false;
    }, DELAY_HIDE_BALLOON_TIME);
  }

  /**
   * URLを持つPVグラムがクリックされた
   */
  onClickPv(url: string, isSmartphone: boolean, gramId: string) {
    if (this.isDisplayingWebPreview && this.webPreviewGramId === gramId) {
      this.hideWebPreview();
    } else {
      this.showWebPreview(url, isSmartphone, gramId);
    }
  }

  showWebPreview(url: string, isSmartphone: boolean, gramId: string) {
    const pramURL = "?url=" + encodeURIComponent(decodeURIComponent(url));
    let openUrl = WEB_PREVIEW_REVERSE_PROXY_URL + pramURL;
    openUrl += "&is_smp=" + (isSmartphone ? 1 : 0);

    this.webPreviewGramId = gramId;
    this.webPreviewUrl = openUrl;
    this.isDisplayingWebPreview = true;
    UgTag.pushEvent(UgEventTag.WebPreview);
  }

  hideWebPreview() {
    this.webPreviewGramId = "";
    this.webPreviewUrl = "";
    this.isDisplayingWebPreview = false;
  }

  /**
   * Scrolls so that the next visit of the visit with the received gramId is first.
   * @param gramId  gramId as visit.key which is the first gramId in the visit
   */
  scrollToNextVisit(gramId: string) {
    const nextVisitIndex: number = this.getVisitIndex(gramId) + 1;
    const nextVisit: Visit = this.visits[nextVisitIndex];
    this.scrollToVisitByGramId(nextVisit.key, true);
  }
  /**
   * Scrolls so that the previous visit of the visit with the received gramId is first.
   * @param gramId  gramId as visit.key which is the first gramId in the visit
   */
  scrollToPreviousVisit(gramId: string) {
    const previousVisitIndex: number = this.getVisitIndex(gramId) - 1;
    const previousVisit: Visit = this.visits[previousVisitIndex];
    this.scrollToVisitByGramId(previousVisit.key, true);
  }

  getVisitIndex(gramId: string): number {
    return this.visits.findIndex(visit => visit.key === gramId);
  }
}
</script>

<style scoped lang="scss">
.user-detail {
  background-color: $colorWhite;
}

.ud-Main {
  width: 100%;
  height: 100%;
}

.ud-Visit_Elapsed {
  padding: 32px 0 32px 48px;
  background: $colorBase300;
  font-weight: bold;
  font-size: 14px;
}

.ud-Loading_Empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;

  & img {
    width: 58px;
    height: 58px;
  }
}

.ud-Loading_Past,
.ud-Loading_Future {
  padding-top: 44px;
  width: 100%;
  height: 128px;
  text-align: center;

  & img {
    width: 40px;
    height: 40px;
  }
}

.ud-UserHeader_UserActions {
  display: flex;
  justify-content: center;
}

.ud-UserHeader_UserActionButton {
  margin: 0 3px;
}

.userDetailBalloon {
  position: absolute;
  top: 52px;
  left: -120px;
}

.ud-Visits {
  overflow-x: hidden;
  overflow-y: scroll;
}

.ud-WebPreview {
  position: absolute;
  top: 10px;
  right: 16px;
  display: inline-block;
  border: 1px solid $colorBase500;
  background: $colorWhite;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.preview-enter-active,
.preview-leave-active {
  transition: right 0.24s linear;
}

.preview-enter,
.preview-leave-to {
  right: calc(-25vw - 16px);
}

@media print {
  .ud-Visits {
    overflow: visible;
    margin-top: 0 !important;
  }
}
</style>
