export enum Browser {
  Ie = "ie",
  Edge = "edge",
  Chrome = "chrome",
  Safari = "safari",
  Firefox = "firefox",
  Unknown = "unknown"
}

export enum Os {
  Win = "windows",
  Mac = "mac",
  Unknown = "unknown"
}
