<template>
  <th
    class="analysisTableHeaderCell"
    :style="style"
    data-cy="analysis-table-header-cell"
    @click="onClick"
  >
    <slot></slot>
  </th>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class AnalysisTableHeaderCell extends Vue {
  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: String, default: "52px" })
  height!: string;

  @Emit("click")
  onClick() {}

  get style() {
    return {
      width: this.width,
      height: this.height
    };
  }
}
</script>

<style lang="scss" scoped>
.analysisTableHeaderCell {
  padding: 10px;
  height: 52px;
  background-color: $colorBlue800;
  color: $colorWhite;
  vertical-align: middle;
  text-align: center;
  font-weight: bold;

  @include all-break-word();

  &:first-child {
    border-top-left-radius: $sizeRadius;
  }
  &:last-child {
    border-top-right-radius: $sizeRadius;
  }
}
</style>
