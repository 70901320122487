<template>
  <div class="ud-Visit" data-cy="ud-visit">
    <VisitHead
      :visit="visit"
      :is-first-visit="isFirstVisit"
      :is-omo="isOmo"
      @scroll-to-next="scrollToNextVisit"
      @scroll-to-previous="scrollToPreviousVisit"
    />
    <div :class="bodyClass">
      <template v-for="(gram, index) in visit.grams">
        <VisitInflow
          v-if="gram.hasInflow"
          :key="index"
          :gram="gram"
          :is-omo="isOmo"
        />
        <VisitGram
          :key="gram.id"
          :gram="gram"
          :is-omo="isOmo"
          :previewed-gram-id="previewedGramId"
          :filter-titles="filterTitles"
          :filter-full-urls="filterFullUrls"
          :filter-part-urls="filterPartUrls"
          @click-pv="onClick"
        />
        <GramWithdrawal
          v-if="gram.hasWithdrawal"
          :key="gram.id + index"
          :is-omo="isOmo"
        />
      </template>
      <GramWithdrawal v-if="visit.isShowableWithdrawal" :is-omo="isOmo" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Visit } from "@/models/Visit";

import VisitHead from "@/components/observation/visit/VisitHead.vue";
import VisitInflow from "@/components/observation/visit/VisitInflow.vue";
import VisitGram from "@/components/observation/visit/VisitGram.vue";
import GramWithdrawal from "@/components/observation/visit/gram/GramWithdrawal.vue";
import { WordForHighlight } from "@/models/search/MatchMethod";

@Component({
  components: { VisitHead, VisitInflow, VisitGram, GramWithdrawal }
})
export default class VisitWrapper extends Vue {
  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: Visit, required: true })
  visit!: Visit;

  @Prop({ type: String, required: true })
  previewedGramId!: string;

  @Prop({ type: Array, required: true })
  filterTitles!: WordForHighlight[];

  @Prop({ type: Array, required: true })
  filterFullUrls!: WordForHighlight[];

  @Prop({ type: Array, required: true })
  filterPartUrls!: WordForHighlight[];

  @Prop({ type: Boolean, default: false })
  isFirstVisit!: boolean;

  onClick(url: string, isSmartphone: boolean, gramId: string) {
    this.$emit("click-pv", url, isSmartphone, gramId);
  }

  scrollToNextVisit(gramId: string) {
    this.$emit("scroll-to-next", gramId);
  }

  scrollToPreviousVisit(gramId: string) {
    this.$emit("scroll-to-previous", gramId);
  }

  get bodyClass(): string {
    return this.isOmo ? "ud-Visit_Body_Omo" : "ud-Visit_Body";
  }
}
</script>

<style scoped lang="scss">
.ud-Visit {
  padding: 38px 26px 4px 40px;
  background-color: $colorWhite;
}
.ud-Visit_Body,
.ud-Visit_Body_Omo {
  position: relative;
  margin: 44px 0 34px 0;
  padding: 24px 0 26px 0;
}
.ud-Visit_Body {
  background-image: $edge-dot, $edge-dot;
  background-position: 23px top, 23px 100%;
  background-size: 3px 18px, 3px 18px;
  background-repeat: no-repeat, no-repeat;
}
.ud-Visit_Body_Omo {
  background-image: $edge-dot, $edge-dot, $omo-company-line-dot;
  background-position: 23px top, 23px 100%, right 23px top;
  background-size: 3px 18px, 3px 18px, 3px 18px;
  background-repeat: no-repeat, no-repeat, repeat-y;
}
</style>
