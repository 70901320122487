import { MatchMethod } from "@/models/search/MatchMethod";
import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * サイト内URLによる条件
 *
 * 絞り込み条件に追加で、サイト内URLを条件として付与できる
 */
export class FilterSiteUrlCondition {
  public readonly conditionType = FilterNodeConditionType.SiteUrl;

  constructor(
    public readonly siteUrl: string,
    // 文字列のマッチ条件（部分一致 or 完全一致）
    public readonly matchMethod: MatchMethod
  ) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.siteUrl,
      i18n.t("models.search.pageUrl") as string
    );
  }
}
