import { Colors } from "@/const/Colors";

/**
 * GramChartに期間を示すrectを図示するために使用する。
 */
export class ColoredPeriod {
  public constructor(
    // ツールチップに表示するラベル
    public readonly periodName: String,
    public readonly startDate: Date,
    public readonly endDate: Date,
    // chartで表示する時のcolor. chartでは不透明度が下がる処理がある.
    public readonly color: Colors,
    public readonly strokeColor: Colors
  ) {}
}
