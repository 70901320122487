<template>
  <div class="tourDetailHeaderPanel">
    <img
      class="tourDetailHeaderPanel__backgroundImage"
      :src="backgroundImageUrl"
    />
    <h4 class="tourDetailHeaderPanel__title">{{ title }}</h4>

    <div class="tourDetailHeaderPanel__content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TourDetailHeaderPanel extends Vue {
  @Prop({ type: String, required: false, default: "" })
  title!: string;

  @Prop({ type: String, required: false, default: "" })
  backgroundImageUrl!: string;
}
</script>

<style lang="scss" scoped>
.tourDetailHeaderPanel {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: $sizeRadius;
  background: $colorWhite;
}

.tourDetailHeaderPanel__backgroundImage {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 96px;
  opacity: 0.15;
}

.tourDetailHeaderPanel__title {
  font-weight: bold;
  font-size: 14px;
}

.tourDetailHeaderPanel__content {
  margin-top: 20px;
}
</style>
