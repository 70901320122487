<template>
  <component :is="icon" v-if="icon" width="32px" height="32px" />
  <div v-else>Invalid icon</div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { iconList } from "@/const/v3-icon-list";

const props = defineProps<{
  icon: string;
}>();

const icon = computed(() => iconList[props.icon]);
</script>
