import { DateRange } from "@/components/date-picker/DateRange";
import { getCurrentDate } from "@/util/date-util";

/**
 * 有効な期間を返す
 */
export function enabledPeriodRange(): DateRange {
  const today = getCurrentDate();
  today.setHours(0, 0, 0, 0);

  const twoYearsBefore = getCurrentDate();
  twoYearsBefore.setHours(0, 0, 0, 0);
  twoYearsBefore.setFullYear(twoYearsBefore.getFullYear() - 2);

  return {
    min: twoYearsBefore,
    max: today
  };
}

/**
 * 分析ツールとengagement searchの、enabledPeriodRange
 * 2018/09/01以前と直近2日は集計開始前なので、選択出来ないようにする
 */
export function usergramEnabledPeriodRange(): DateRange {
  const twoDaysAgo = getCurrentDate();
  twoDaysAgo.setHours(0, 0, 0, 0);
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  const startDay = new Date(2018, 8, 1);

  const twoYearsBefore = getCurrentDate();
  twoYearsBefore.setHours(0, 0, 0, 0);
  twoYearsBefore.setFullYear(twoYearsBefore.getFullYear() - 2);

  return {
    min: startDay > twoYearsBefore ? startDay : twoYearsBefore,
    max: twoDaysAgo
  };
}
