<template>
  <AnalysisTableHeaderCell
    class="settingsTableHeaderCell"
    :width="width"
    :style="style"
    @click="onClick"
  >
    <slot></slot>
  </AnalysisTableHeaderCell>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import AnalysisTableHeaderCell from "@/components/table/AnalysisTableHeaderCell.vue";

@Component({
  components: {
    AnalysisTableHeaderCell
  }
})
export default class SettingsTableHeaderCell extends Vue {
  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: String, default: "left" })
  textAlign!: string;

  @Emit("clicks")
  onClick() {}

  get style() {
    return {
      textAlign: this.textAlign
    };
  }
}
</script>

<style lang="scss" scoped>
.settingsTableHeaderCell {
  padding: 15px;
  box-sizing: border-box;
  border: solid 1px $colorBase500;
  background: $colorBase300;
  color: $colorBase900;
  white-space: nowrap;
  word-break: keep-all;
  font-weight: normal;
}
</style>
