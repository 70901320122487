<template>
  <div class="filter-base-input-text-match-type">
    <FilterBaseInputText
      :prefix="prefix"
      :value="value"
      :width="width"
      :placeholder="placeholder"
      :input-form-suggestion-type="inputFormSuggestionType"
      class="input"
      @input="onInputText"
    />
    <SelectBox
      :value="matchMethod"
      :options="matchOptions"
      class="select-box"
      width="140px"
      @input="onInputMatchMethod"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import { matchPatternOptions } from "@/components/search/matchPatternOptions";
import { MatchMethod } from "@/models/search/MatchMethod";
import { i18n } from "@/i18n";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";

@Component({
  components: {
    FilterBaseInputText,
    SelectBox
  }
})
export default class FilterBaseInputTextMatchType extends Vue {
  @Prop({ type: String, required: true })
  value!: string;

  @Prop({ type: Number, required: true })
  matchMethod!: MatchMethod;

  @Prop({ type: String, default: "" })
  prefix!: string;

  @Prop({ type: String, default: "260px" })
  width!: string;

  @Prop({ type: Boolean, default: false })
  isWildCardOptionEnable!: boolean;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: String, default: undefined })
  inputFormSuggestionType!: InputFormSuggestionType;

  onInputText(value: string) {
    this.$emit("input-text", value);
  }

  onInputMatchMethod(matchMethod: MatchMethod) {
    this.$emit("input-match-method", matchMethod);
  }

  get matchOptions(): SelectOption[] {
    const matchOptions = matchPatternOptions();

    if (this.isWildCardOptionEnable) {
      const additionalOptionWildcard = [
        {
          label: i18n.t("components.search.search.wildcardMatch") as string,
          value: MatchMethod.Wildcard,
          disabled: false
        }
      ];
      return matchOptions.concat(additionalOptionWildcard);
    }

    return matchOptions;
  }

  get hasPrefix(): boolean {
    return this.prefix !== "";
  }
}
</script>
<style lang="scss" scoped>
.filter-base-input-text-match-type {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.select-box {
  margin-left: 7px;
}
::v-deep .input-text-input {
  &::placeholder {
    color: $colorBase600;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
</style>
