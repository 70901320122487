<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseSelectBox
    :value="conversionDefinitionId"
    :options="options"
    :prefix="$t('conversionPrefix')"
    class="filter-conversion-form"
    @input="onInput"
  />
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";

import SelectOption from "@/components/form/SelectOption";
import FilterBaseSelectBox from "@/components/filter/form/FilterBaseSelectBox.vue";

@Component({
  components: {
    FilterBaseSelectBox
  }
})
export default class FilterConversionField extends Vue {
  @Model("input", { type: Number, required: true })
  conversionDefinitionId!: number;

  @Prop({ type: Array, required: true })
  conversionDefinitions!: ConversionDefinition[];

  onInput(conversionDefinitionId: number) {
    this.$emit("input", conversionDefinitionId);
  }

  get options(): SelectOption[] {
    return this.conversionDefinitions.map(def => {
      return { label: def.name, value: def.id, disabled: false };
    });
  }
}
</script>
