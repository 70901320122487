import { ApiRes } from "@/api/api-res";
import { AlmostCvConversion } from "@/models/almost-cv/AlmostCvConversion";
import { AlmostCvPeriod } from "@/models/almost-cv/AlmostCvPeriod";

/**
 * Model holding AlmostCvPeriod and AlmostCvConversions data.
 */
export class AlmostCvInfo {
  public constructor(
    public readonly period: AlmostCvPeriod,
    public readonly conversions: AlmostCvConversion[]
  ) {}

  /**
   * Convert data from API into an instance of AlmostCvInfo
   * @param json an element from API // ApiRes.AlmostCvInfo
   * @returns an instance of this class
   */
  public static fromJson(json: ApiRes.AlmostCvInfo): AlmostCvInfo {
    const period: AlmostCvPeriod = AlmostCvPeriod.fromJson(json);
    const conversions: AlmostCvConversion[] = json.conversions.map(
      AlmostCvConversion.fromJson
    );

    return new AlmostCvInfo(period, conversions);
  }
}
