<template>
  <lazy-component
    :key="chartPeriod + user.id"
    :style="{ height: cellHeight + 'px' }"
  >
    <GramChart
      class="user-gram-chart__gram-chart"
      :chart-period="chartPeriod"
      :is-omo="isOmo"
      :visit-overviews="user.overviews.visit"
      :nps-overviews="user.overviews.nps"
      :enquete-overviews="user.overviews.enquete"
      :contact-overviews="filterContactOverviews"
      :business-event-overviews="filterBusinessEventOverviews"
      :is-user-detail="false"
      :end-date="endDate"
      :filter-matched-ids="user.overviews.filterMatchedIds"
      :almost-cv-gram-ids="almostCvGramIds"
      :funnel-matched-gram-ids="funnelMatchedGramIds"
      :funnel-matched-selected-gram-ids="funnelMatchedSelectedGramIds"
      :colored-periods="coloredPeriods"
      :selected-conversion-ids="selectedConversionIds"
      :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
      @click="overview => onClickChartIcon(overview, user)"
    />
  </lazy-component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { ChartPeriod } from "@/const/chart-period";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import GramChart from "@/components/chart/GramChart.vue";
import { getPaperHeight } from "@/components/chart/chart-util";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";

@Component({
  components: {
    GramChart
  }
})
export default class GramChartWrapperForList extends Vue {
  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: Array, required: true })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Date, required: true })
  endDate!: Date;

  @Prop({ type: Number, required: true })
  chartPeriod!: ChartPeriod;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: Array, default: [] })
  almostCvGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  @Prop({ type: Array })
  displayContactDefinitionTypes?: ContactDefinitionType[];

  @Prop({ type: Array })
  displayBusinessEventDefinitionIds?: number[];

  @Prop({ type: Array, required: true })
  selectedConversionIds!: number[];

  @Prop({ type: Boolean, required: true })
  isToggleTimeOfDayEnabled!: boolean;

  onClickChartIcon(
    overview:
      | VisitOverview
      | NpsOverview
      | BusinessEventOverview
      | EnqueteOverview
      | ContactOverview,
    user: User
  ) {
    this.$emit("click-chart-icon", overview, user);
  }

  get filterContactOverviews(): ContactOverview[] {
    const contactOverviews: ContactOverview[] = this.user.overviews.contact;
    const displayContactTypes: ContactDefinitionType[] | undefined = this
      .displayContactDefinitionTypes;
    if (displayContactTypes) {
      return contactOverviews.filter(
        c => displayContactTypes.indexOf(c.type) > -1
      );
    }
    return contactOverviews;
  }

  get filterBusinessEventOverviews(): BusinessEventOverview[] {
    const businessEventOverviews: BusinessEventOverview[] = this.user.overviews
      .business;
    const displayBusinessEventDefinitionIds: number[] | undefined = this
      .displayBusinessEventDefinitionIds;
    if (displayBusinessEventDefinitionIds) {
      return businessEventOverviews.filter(
        b => displayBusinessEventDefinitionIds.indexOf(b.definition.id) > -1
      );
    }
    return businessEventOverviews;
  }

  get cellHeight() {
    return getPaperHeight(this.isOmo);
  }
}
</script>

<style scoped lang="scss">
.user-gram-chart__gram-chart {
  text-align: left;
}
</style>
