import { Colors } from "@/const/Colors";

export enum FunnelType {
  CV = 1,
  PV = 2,
  Event = 3,
  Screen = 4,
  BusinessEvent = 5,
  Contact = 6,
  Inflow = 7,
  InflowParam = 8,
  InflowOrganic = 9
}

export enum FunnelStatus {
  CREATION_IN_PROGRESS = "CREATION_IN_PROGRESS",
  CREATED = "CREATED",
  CREATION_FAILED = "CREATION_FAILED",
  NOT_STARTED = "NOT_STARTED"
}

export enum FunnelFailedReason {
  TABLE_NOT_READY = "TABLE_NOT_READY"
}

export enum SearchField {
  URL = 1,
  PAGE_TITLE = 2
}

export enum InflowSearchField {
  LOCATION_REFERRER = 1,
  LOCATION_URI = 2,
  LOCATION_NAME = 3
}

export enum InflowOrganicSearchField {
  LOCATION_URI = 1,
  LOCATION_NAME = 2,
  SEARCH_ENGINE = 3
}

export enum FunnelLablelType {
  DEFAULT = "default",
  NOT = "not"
}

export enum FunnelEditLinkType {
  DEFAULT = Colors.Base700,
  EMPHASIS = Colors.Base900
}

export enum FunnelEditLinkSize {
  REGULAR = "14px",
  SMALL = "12px"
}
