<i18n
  src="@/i18n/components/observation/visit/visit-summary/visit-summary-tooltip.json"
></i18n>
<template>
  <div class="visitSummaryTooltip">
    <div class="visitSummaryTooltip__inner">
      <div class="visitSummaryTooltip__head">
        <div v-t="'title'" class="visitSummaryTooltip__headTitle" />
        <div v-t="'note'" class="visitSummaryTooltip__headNote" />
      </div>
      <div class="visitSummaryTooltip__body">
        <VisitSummaryTable :data="data" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import VisitSummaryTable from "@/components/observation/visit/visit-summary/VisitSummaryTable.vue";
import { VisitSummary } from "@/models/visit-summary/VisitSummary";

@Component({ components: { VisitSummaryTable } })
export default class VisitSummaryTooltip extends Vue {
  @Prop({ type: Object, required: true })
  data!: VisitSummary;
}
</script>
<style scoped lang="scss">
.visitSummaryTooltip {
  width: 860px;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background: $colorWhite;
}
.visitSummaryTooltip__inner {
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 20px 15px 30px 30px;
  padding-right: 15px;
  max-height: 480px;
}
.visitSummaryTooltip__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: flex-end;
}
.visitSummaryTooltip__headTitle {
  font-weight: bold;
  font-size: 14px;
}
.visitSummaryTooltip__headNote {
  color: $colorBase700;
  font-size: 12px;
}
</style>
