import { i18n } from "@/i18n";
import { FilterNodeParamForOrNode } from "@/api/apis/ApiSearch";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import {
  convertJsonToFilterNode,
  FilterNode,
  FilterNodeType,
  ActivityEdgeType,
  convertFilterNodeToJson
} from "@/models/search/filter-node/FilterNode";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { WordForHighlight } from "@/models/search/MatchMethod";
import { FilterNodeForBrowseSite } from "@/models/search/filter-node/FilterNodeForBrowseSite";
import { FilterNodeForInflow } from "@/models/search/filter-node/FilterNodeForInflow";

export class FilterNodeForOrNode {
  public readonly nodeType = FilterNodeType.OrNode;

  constructor(
    public childIndex: number | null, // theoretical, in current specification orNode can't contain other orNode
    public depth: number | null,
    public edge: FilterEdge | null,
    public filterExclusion: FilterExclusion = new FilterExclusion(),
    public orActivityNodes: FilterNode[]
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate() {
    const result = {
      isValid: true,
      errorMessage: `${i18n.t("models.search.orNodeError") as string}\n`
    };

    if (this.orActivityNodes.length > 0) {
      this.orActivityNodes.forEach(node => {
        const validation = node.validate;

        if (validation.isValid === false) {
          result.isValid = false;
          result.errorMessage += `\n${validation.errorMessage}`;
        }
      });
    }

    return result;
  }

  get titlesForHighlight(): WordForHighlight[] {
    let titles: WordForHighlight[] = [];
    this.orActivityNodes.filter(orNode => {
      if (orNode instanceof FilterNodeForBrowseSite) {
        titles = titles.concat(orNode.titlesForHighlight);
      }
    });
    return titles;
  }

  get fullUrlsForHighlight(): WordForHighlight[] {
    let urls: WordForHighlight[] = [];
    this.orActivityNodes.forEach(orNode => {
      if (orNode instanceof FilterNodeForBrowseSite) {
        urls = urls.concat(orNode.fullUrlsForHighlight);
      }
    });
    return urls;
  }

  get partUrlsForHighlight(): WordForHighlight[] {
    let urls: WordForHighlight[] = [];
    this.orActivityNodes.forEach(orNode => {
      if (
        orNode instanceof FilterNodeForBrowseSite ||
        orNode instanceof FilterNodeForInflow
      ) {
        urls = urls.concat(orNode.partUrlsForHighlight);
      }
    });
    return urls;
  }

  insertEdge(): FilterNodeForOrNode {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();

    return new FilterNodeForOrNode(
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion,
      this.orActivityNodes
    );
  }

  removeEdge(): FilterNodeForOrNode {
    return new FilterNodeForOrNode(
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion,
      this.orActivityNodes
    );
  }

  removeFirstTimeCondition(): FilterNodeForOrNode {
    return new FilterNodeForOrNode(
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion,
      this.orActivityNodes
    );
  }
}

export function convertFilterNodeForOrNodeToJson(
  node: FilterNodeForOrNode,
  globalConversionDefinitions: ConversionDefinition[]
): FilterNodeParamForOrNode {
  const result: FilterNodeParamForOrNode = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.OrNode,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    or_activity_nodes: node.orActivityNodes.map(n =>
      convertFilterNodeToJson(n, globalConversionDefinitions)
    )
  };

  return result;
}

export function convertJsonToFilterNodeForOrNode(
  json: FilterNodeParamForOrNode,
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterNodeForOrNode {
  const innerNodes: FilterNode[] = json.or_activity_nodes
    .map(n =>
      convertJsonToFilterNode(
        n,
        canUseWebdataFeatures,
        isContractApp,
        activeDefinitions,
        globalConversionAttributeDefinitions
      )
    )
    .filter(n => n !== null)
    .map(n => n as FilterNode);
  innerNodes.forEach(n => (n.depth = 1));
  innerNodes.forEach(n => {
    // @ts-ignore
    if (n.condition) {
      // @ts-ignore
      n.condition.parentDepth = 1;
    }
  });
  for (let idx = 0; idx < innerNodes.length; idx++) {
    innerNodes[idx].childIndex = idx;
  }

  return new FilterNodeForOrNode(
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!),
    innerNodes
  );
}
