<i18n
  src="@/i18n/components/segmented-trends/segmented-trends-button.json"
></i18n>
<template>
  <div class="segmented-trends-button">
    <button
      class="segmented-trends-button__button"
      :class="{ 'segmented-trends-button__button--disabled': isDisabled }"
      data-cy="segmented-trends-button"
      :disabled="isDisabled"
      @click="onClick"
    >
      <span
        class="segmented-trends-button__icon"
        :class="{ 'segmented-trends-button__icon--disabled': isDisabled }"
      >
        <Icon :icon="Icons.Analysis" size="16" color="inherit" />
      </span>
      <span
        class="segmented-trends-button__text"
        :class="{ 'segmented-trends-button__text--disabled': isDisabled }"
        >{{ $t("checkUserTrends") }}</span
      >
    </button>
    <div class="segmented-trends-button__tooltip-wrapper">
      <Tooltip :placement="TooltipPlacement">
        <Icon :icon="Icons.HelpInvert" :color="Colors.Base700" :size="12" />
        <template #content>
          <div class="segmented-trends-button__tooltip-header">
            {{ $t("SegmentedTrendsNoteHeader") }}
            <br />
            <div class="segmented-trends-button__tooltip-notes">
              {{ $t("SegmentedTrendsNote01") }}
              <br />
              {{ $t("SegmentedTrendsNote02") }}
            </div>
            <br />
            {{ $t("SegmentedTrendsNoteFooter") }}
          </div>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { TooltipPlacement } from "@/const/tooltip";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: {
    Icon,
    Tooltip
  }
})
export default class SegmentedTrendsButton extends Vue {
  Icons = Icons;
  Colors = Colors;
  TooltipPlacement = TooltipPlacement.Bottom;

  @Prop({ type: Boolean, default: false })
  isDisabled!: boolean;

  @Emit("click")
  onClick() {}
}
</script>

<style lang="scss" scoped>
.segmented-trends-button {
  display: flex;
  align-items: center;
}
.segmented-trends-button__button {
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 6px;
  min-width: 140px;
  height: 28px;
  border: 1px solid $colorBase600;
  border-radius: 5px;
  background-color: $colorWhite;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  color: $colorBase900;
  &:hover {
    background-color: $colorHoverLightForWhite;
  }
}
.segmented-trends-button__button--disabled {
  border: 1px solid $bgColorDisabled;
  background-color: $bgColorDisabled;
  pointer-events: none;
}
.segmented-trends-button__text {
  color: inherit;
}
.segmented-trends-button__text--disabled {
  color: $colorDisabled;
}
.segmented-trends-button__tooltip-wrapper {
  margin-left: 6px;
}
.segmented-trends-button__tooltip-notes {
  padding-left: 10px;
}
.segmented-trends-button__icon {
  margin-right: 1px;
}
.segmented-trends-button__icon--disabled {
  color: $colorElmentUIGray300;
}
</style>
