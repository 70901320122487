import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

export default class ApiClients {
  constructor(private readonly httpClient: HttpClient) {}

  public getClient = (): Promise<ApiRes.WrapClient> => {
    return this.httpClient.get<ApiRes.WrapClient>(ApiUrl.GET_CLIENT);
  };
}
