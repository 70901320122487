import {
  AnalysisType,
  UserTrendColumnType,
  UserTrendDataType
} from "@/const/user-trend";
import { i18n } from "@/i18n";

export interface columnData {
  type: UserTrendColumnType;
  label: string;
  isDesc: boolean;
}

export interface sortColumnData {
  type: UserTrendColumnType;
  key: UserTrendDataType;
  isDesc: boolean;
}

export function defaultSelectColumn(page: AnalysisType): sortColumnData {
  let type = UserTrendColumnType.count;

  if (
    page === AnalysisType.Landing ||
    page === AnalysisType.Inflow ||
    page === AnalysisType.InflowDetail
  ) {
    type = UserTrendColumnType.visit;
  }

  return {
    type,
    key: UserTrendDataType.base,
    isDesc: true
  };
}

export function pageColumnTypes(page: AnalysisType): UserTrendColumnType[] {
  const types: UserTrendColumnType[] = [];
  const rejectColumns: UserTrendColumnType[] = [];

  switch (page) {
    case AnalysisType.Landing:
    case AnalysisType.Inflow:
    case AnalysisType.InflowDetail:
      rejectColumns.push(UserTrendColumnType.count);
      break;
  }

  Object.entries(UserTrendColumnType).forEach(([_, value]) => {
    if (!rejectColumns.includes(value)) {
      types.push(value);
    }
  });

  return types;
}

export function urlTootltipText(key: string | null) {
  if (key === null || key.length === 0) {
    return i18n.t("components.userTrend.table.noPageTitle") as string;
  }

  return key;
}
