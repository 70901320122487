import { render, staticRenderFns } from "./UserAttributeBalloon.vue?vue&type=template&id=3d6cf274&scoped=true"
import script from "./UserAttributeBalloon.vue?vue&type=script&lang=ts"
export * from "./UserAttributeBalloon.vue?vue&type=script&lang=ts"
import style0 from "./UserAttributeBalloon.vue?vue&type=style&index=0&id=3d6cf274&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d6cf274",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/user-search-result.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fusers%2FUserAttributeBalloon.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports