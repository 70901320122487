export enum Icons {
  Analysis = "icon-analysis",
  App = "icon-app",
  ArrowBottom = "icon-arrow-bottom",
  ArrowLeft = "icon-arrow-left",
  ArrowRight = "icon-arrow-right",
  ArrowTop = "icon-arrow-top",
  ArrowTopBar = "icon-arrow-top-bar",
  At = "icon-at",
  Bell = "icon-bell",
  Beta = "icon-beta",
  ButtonClose = "icon-button-close",
  Burger = "icon-burger",
  BurgerCheck = "icon-burger-check",
  BusinessEvent = "icon-business-event",
  Calendar = "icon-calendar",
  CalendarDay = "icon-calendar-day",
  CalendarWeek = "icon-calendar-week",
  CalendarMonth = "icon-calendar-month",
  CaretBottom = "icon-caret-bottom",
  CaretLeft = "icon-caret-left",
  CaretRight = "icon-caret-right",
  CaretTop = "icon-caret-top",
  Checked = "icon-checked",
  Clip = "icon-clip",
  Close = "icon-close",
  CloseBold = "icon-close-bold",
  Conditions = "icon-conditions",
  Conversion = "icon-conversion",
  Cylinder = "icon-cylinder",
  Daytime = "icon-daytime",
  Delete = "icon-delete",
  Document = "icon-document",
  Dots = "icon-dots",
  Download = "icon-download",
  Event = "icon-event",
  ExternalLink = "icon-external-link",
  Eye = "icon-eye",
  Filter = "icon-filter",
  FirstOpen = "icon-first-open",
  Gear = "icon-gear",
  Heart = "icon-heart",
  HeartPlus = "icon-heart-plus",
  Help = "icon-help",
  HelpInvert = "icon-help-invert",
  HelpInvertWhite = "icon-help-invert-white",
  Hint = "icon-hint",
  History = "icon-history",
  Home = "icon-home",
  InflowBottom = "icon-inflow-bottom",
  InflowLeft = "icon-inflow-left",
  InflowRight = "icon-inflow-right",
  InflowTop = "icon-inflow-top",
  MailSend = "icon-mail-send",
  Mail = "icon-mail",
  Memo = "icon-memo",
  Message = "icon-message",
  Morning = "icon-morning",
  Night = "icon-night",
  LineChart = "icon-line-chart",
  Link = "icon-link",
  Like = "icon-like",
  Logout = "icon-logout",
  Loyalty = "icon-loyalty",
  Overview = "icon-overview",
  Plus = "icon-plus",
  PlusCircle = "icon-plus-circle",
  RoundSquare = "icon-round-square",
  Save = "icon-save",
  Scatter = "icon-scatter",
  Search = "icon-search",
  Setting = "icon-setting",
  Shop = "icon-shop",
  Table = "icon-table",
  Tel = "icon-tel",
  User = "icon-user",
  Users = "icon-users",
  Visit = "icon-visit",
  Mortarboard = "icon-mortarboard",
  Toggle = "icon-toggle",
  HorizontalBars = "icon-horizontal-bars",
  Tag = "icon-tag",
  CylinderNew = "icon-cylinder-new",
  TreasureData = "icon-td",
  Binocular = "icon-binocular",
  SearchPlus = "icon-search-plus",
  SearchUser = "icon-search-user",
  List = "icon-list",
  SpeechBalloon = "icon-speech-balloon"
}
