import { FunnelDataNodeJson, FunnelUserList } from "@/api/apis/ApiFunnel";
import {
  convertFunnelConditionJsonToModel,
  FunnelCondition
} from "@/models/funnel/FunnelCondition";
import { DateFormat, formatDate } from "@/util/date-util";
import { SearchEngine } from "@/models/system/SearchEngine";

/**
 * Funnel分析の結果を格納するクラス
 */

export class FunnelData {
  constructor(
    public readonly condition: FunnelCondition,
    public readonly matchedUsers: FunnelUserList,
    public readonly unmatchedUsers: FunnelUserList
  ) {}

  get periodsForDisplay(): string {
    const { startDate, endDate } = this.condition;

    return `${formatDate(DateFormat.yyyysMMsdd, startDate)} - ${formatDate(
      DateFormat.yyyysMMsdd,
      endDate
    )}`;
  }

  static fromJson(
    json: FunnelDataNodeJson,
    searchEngines: SearchEngine[]
  ): FunnelData {
    const condition = convertFunnelConditionJsonToModel(
      json.condition,
      searchEngines
    );

    const matched_users =
      json.matched_users !== undefined
        ? json.matched_users
        : { total: 0, ids: [], grams_per_user: {} };

    const unmatched_users =
      json.unmatched_users !== undefined
        ? json.unmatched_users
        : { total: 0, ids: [], grams_per_user: {} };

    return new FunnelData(condition, matched_users, unmatched_users);
  }
}
