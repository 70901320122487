<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div class="search-result-navigation">
    <SearchStatus />
    <ClusteringMenu v-if="showClusteringMenu" />
    <SegmentedTrends v-if="showSegmentedTrend" />
    <div class="search-result-navigation__aside">
      <div class="search-result-navigation__aside__label">
        {{ $t("displayMode") }}
      </div>
      <ChartPeriodMenu v-if="showChartPeriodMenu" v-model="chartPeriod" />
      <DisplayMenu :view="view" @view-change="onViewChange" />
      <div
        v-if="showDownloadButton"
        class="search-result-navigation__aside__csv-download"
      >
        <CsvDownloadButton
          :tooltip-placement="TooltipPlacement.BottomEnd"
          :disabled="disabledCsvDownload"
          :is-downloading="isDownloading"
          @click="onDownloadCsvClick"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Model } from "vue-property-decorator";
import { ChartPeriod } from "@/const/chart-period";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByServiceIdCondition } from "@/models/search/select-condition/SelectByServiceIdCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { handleNoQueryCacheError } from "@/util/error-util";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { TooltipPlacement } from "@/const/tooltip";

import ChartPeriodMenu from "@/components/chart/ChartPeriodMenu.vue";
import SearchStatus from "@/views/SearchStatus.vue";
import DisplayMenu from "@/views/DisplayMenu.vue";
import ClusteringMenu from "@/views/ClusteringMenu.vue";
import SegmentedTrends from "@/views/SegmentedTrends.vue";
import CsvDownloadButton from "@/components/CsvDownloadButton.vue";
import { SearchResultViews } from "@/const/SearchResultViews";

@Component({
  components: {
    ChartPeriodMenu,
    SearchStatus,
    DisplayMenu,
    ClusteringMenu,
    CsvDownloadButton,
    SegmentedTrends
  }
})
export default class SearchResultNavigation extends Vue {
  @Model("view-change", { type: String, required: true })
  view!: SearchResultViews;

  onViewChange(view: SearchResultViews) {
    this.$emit("view-change", view);
  }

  TooltipPlacement = TooltipPlacement;

  get chartPeriod(): ChartPeriod {
    return this.$store.state.preference.chartPeriod;
  }

  set chartPeriod(chartPeriod: ChartPeriod) {
    this.$store.commit("preference/setChartPeriod", chartPeriod);
  }

  get isFilterMode(): boolean {
    return this.$store.state.filter.isFilterMode;
  }

  get isDownloading(): boolean {
    return this.$store.state.search.isCsvDownloading;
  }

  // 検索終了後かつダウンロード中でない場合にダウンロードできる
  get disabledCsvDownload(): boolean {
    return !this.isCountFetched || this.isDownloading;
  }

  get isCountFetched(): boolean {
    return this.isFilterMode
      ? this.$store.state.filter.isCountFetched
      : this.$store.state.search.isCountFetched;
  }

  get noUsers(): boolean {
    return this.isFilterMode
      ? this.$store.state.filter.users.length === 0
      : this.$store.state.search.users.length === 0;
  }

  get hasPermission(): boolean {
    const loginUser = this.$store.state.auth.user;
    return loginUser && loginUser.permission.isAvailableUserList;
  }

  get showChartPeriodMenu(): boolean {
    return this.view === SearchResultViews.Overview;
  }

  // CSVダウンロードリンクは「CVから選定」「サービスIDから選定」の時のみ表示される
  get showDownloadButton(): boolean {
    if (this.isFunnelResult) {
      return true;
    }
    if (this.isTourDetail) {
      return false;
    }
    const condition: SelectCondition = this.$store.state.search.selectCondition;
    return (
      condition instanceof SelectByServiceIdCondition ||
      condition instanceof SelectByConversionCondition ||
      condition instanceof SelectByAppCondition
    );
  }

  get isFunnelResult(): boolean {
    return this.$route.name === "funnel-analysis-detail";
  }

  get isTourDetail(): boolean {
    return this.$route.name === "tour-detail";
  }

  get showClusteringMenu(): boolean {
    if (this.isTourDetail) {
      return false;
    }
    return this.isCountFetched && !this.noUsers;
  }

  get showSegmentedTrend(): boolean {
    if (this.isTourDetail) {
      return false;
    }
    return this.isCountFetched && !this.noUsers && this.hasPermission;
  }

  onDownloadCsvClick() {
    this.$store.dispatch("search/downloadCsv").catch(error => {
      handleNoQueryCacheError(error);
      throw new Error(error);
    });
    UgTag.pushEvent(UgEventTag.DownloadCsv);
  }
}
</script>

<style lang="scss" scoped>
.search-result-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $searchResultNavigationHeight;
  background-color: $colorWhite;

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  &__aside {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;

    &__csv-download {
      display: inline-flex;

      &:before {
        display: inline-block;
        margin-right: 12px;
        width: 1px;
        background-color: $colorBase500;
        content: "";
      }
    }

    &__label {
      font-weight: bold;
    }
  }
}
</style>
