<i18n src="@/i18n/views/client-settings/client-settings-nav.json"></i18n>
<template>
  <div class="clientSettingsNav">
    <!-- link to client-settings top paga -->
    <RouterLink
      class="clientSettingsNav__link"
      :to="{ name: 'client-settings-top' }"
    >
      <div
        class="clientSettingsNav__item"
        :class="{ 'clientSettingsNav__item--current': isSettingsTop }"
      >
        <Icon
          class="clientSettingsNav__icon"
          :icon="icons.Gear"
          :color="isSettingsTop ? iconColorCurrent : iconColor"
          :size="iconSize"
        />

        <div v-t="'clientSettingsTop'" />
      </div>
    </RouterLink>

    <!-- web setting -->
    <div
      v-if="client.hasWebOrGA4Contract"
      class="clientSettingsNav__item clientSettingsNav__measurementWebSettings"
      :class="{ 'clientSettingsNav__item--selected': isWebSettingPages }"
      @click="onClickWeb"
    >
      <Icon
        class="clientSettingsNav__icon"
        :icon="icons.BurgerCheck"
        :color="iconColor"
        :size="iconSize"
      />

      <div v-t="'measurementWebSettings'" />

      <Icon
        class="clientSettingsNav__suffixIcon"
        :class="[{ 'clientSettingsNav__suffixIcon--opened': showWebRows }]"
        :icon="icons.CaretRight"
        :color="suffixIconColor"
        :size="suffixIconSize"
      />
    </div>
    <transition name="float">
      <div v-if="showWebRows" class="clientSettingsNav__rows">
        <a
          class="clientSettingsNav__link clientSettingsNav__measurementTargetDataSettings"
          href="/client-settings/site-url/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'measurementSitesSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractWeb"
          class="clientSettingsNav__link"
          href="/client-settings/branded-domain-tracking/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'measurementMethodsSettings'" />
          </div>
        </a>

        <RouterLink
          v-if="client.isContractWeb"
          class="clientSettingsNav__link clientSettingsNav__conversionSettings"
          :to="{ name: 'conversion-settings' }"
        >
          <div
            class="clientSettingsNav__row"
            :class="{
              'clientSettingsNav__row--current': isConversionSettings
            }"
          >
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="isConversionSettings ? iconColorCurrent : rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'conversionSettings'" />
          </div>
        </RouterLink>

        <a
          v-if="client.isContractWeb"
          class="clientSettingsNav__link"
          href="/client-settings/event/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'eventSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractWeb"
          class="clientSettingsNav__link"
          href="/client-settings/conversion-attribute/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'cvEventAttributeSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractWeb"
          class="clientSettingsNav__link"
          href="/client-settings/measurement-tag/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'measurementTagSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractWeb"
          class="clientSettingsNav__link"
          href="/client-settings/exclusion-ip/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'excludeIpsSettings'" />
          </div>
        </a>
      </div>
    </transition>

    <!-- coordination setting -->
    <div
      v-if="client.hasAppOrGA4Contract"
      class="clientSettingsNav__item clientSettingsNav__measurementAppGa4Settings"
      :class="{
        'clientSettingsNav__item--selected': isCoordinationSettings()
      }"
      @click="onClickApp"
    >
      <Icon
        class="clientSettingsNav__icon"
        :icon="icons.RoundSquare"
        :color="iconColor"
        :size="iconSize"
      />

      <div v-t="'measurementAppGA4Settings'" />

      <Icon
        class="clientSettingsNav__suffixIcon"
        :class="[{ 'clientSettingsNav__suffixIcon--opened': showAppRows }]"
        :icon="icons.CaretRight"
        :color="suffixIconColor"
        :size="suffixIconSize"
      />
    </div>
    <transition name="float">
      <div v-if="showAppRows" class="clientSettingsNav__rows">
        <a
          class="clientSettingsNav__link"
          href="/client-settings/coordination/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'coordinationSettings'" />
          </div>
        </a>

        <div v-for="coordination in coordinations" :key="coordination.id">
          <a
            class="clientSettingsNav__link"
            :href="
              `/client-settings/coordination/${coordination.id}/detail/index/`
            "
          >
            <div
              class="clientSettingsNav__row clientSettingsNav__targetApp"
              :class="{
                'clientSettingsNav__row--current': isCoordinationSettings(
                  coordination.id
                )
              }"
            >
              <Icon
                class="clientSettingsNav__rowIcon"
                :icon="icons.ArrowRight"
                :color="
                  isCoordinationSettings(coordination.id)
                    ? iconColorCurrent
                    : rowIconColor
                "
                :size="rowIconSize"
              />
              {{ coordination.name }}
            </div>
          </a>
          <div
            v-if="isCoordinationSettings(coordination.id)"
            class="clientSettingsNav__rowsSubs"
          >
            <a
              :href="
                `/client-settings/coordination/${coordination.id}/conversion/index/`
              "
            >
              <div
                v-t="'conversionSettings'"
                class="clientSettingsNav__rowsSub"
                :class="{
                  'current-page': isCoordinationConversionSettings
                }"
              />
            </a>
            <a
              :href="
                `/client-settings/coordination/${coordination.id}/event/index/`
              "
            >
              <div
                v-t="'eventSettings'"
                class="clientSettingsNav__rowsSub"
                :class="{
                  'current-page': isCoordinationEventSettings
                }"
              />
            </a>
            <a
              :href="
                `/client-settings/coordination/${coordination.id}/cvattribute/index/`
              "
            >
              <div
                v-t="'cvEventAttributeSettings'"
                class="clientSettingsNav__rowsSub"
                :class="{
                  'current-page': isCoordinationConversionAttributeSettings
                }"
              />
            </a>
          </div>
        </div>
      </div>
    </transition>

    <!-- account setting -->
    <div
      class="clientSettingsNav__item clientSettingsNav__accountManagement"
      @click="onClickAccount"
    >
      <Icon
        class="clientSettingsNav__icon"
        :icon="icons.At"
        :color="iconColor"
        :size="iconSize"
      />

      <div v-t="'accountManagement'" />

      <Icon
        class="clientSettingsNav__suffixIcon"
        :class="[{ 'clientSettingsNav__suffixIcon--opened': showAccountRows }]"
        :icon="icons.CaretRight"
        :color="suffixIconColor"
        :size="suffixIconSize"
      />
    </div>
    <transition name="float">
      <div v-if="showAccountRows" class="clientSettingsNav__rows">
        <a
          class="clientSettingsNav__link"
          href="/client-settings/id-management/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'accountManagement'" />
          </div>
        </a>

        <a
          class="clientSettingsNav__link"
          href="/client-settings/user-usage/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'idUsage'" />
          </div>
        </a>

        <a
          class="clientSettingsNav__link"
          href="/client-settings/login-restriction/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'loginRestriction'" />
          </div>
        </a>
      </div>
    </transition>

    <!-- import data setting -->
    <div
      v-if="client.hasAnyOfImportContract"
      class="clientSettingsNav__item clientSettingsNav__importDataSettings"
      @click="onClickImportData"
    >
      <Icon
        class="clientSettingsNav__icon"
        :icon="icons.CylinderNew"
        :color="iconColor"
        :size="iconSize"
      />

      <div v-t="'importDataSettings'" />

      <Icon
        class="clientSettingsNav__suffixIcon"
        :class="[
          { 'clientSettingsNav__suffixIcon--opened': showImportDataRows }
        ]"
        :icon="icons.CaretRight"
        :color="suffixIconColor"
        :size="suffixIconSize"
      />
    </div>
    <transition name="float">
      <div v-if="showImportDataRows" class="clientSettingsNav__rows">
        <a
          v-if="client.isContractNps"
          class="clientSettingsNav__link"
          href="/client-settings/loyalty/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'loyaltySettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractBusinessIndex"
          class="clientSettingsNav__link"
          href="/client-settings/biz-index/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'businessIndexSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractBusinessEvent"
          class="clientSettingsNav__link"
          href="/client-settings/be/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'businessEventSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractUserAttribute"
          class="clientSettingsNav__link"
          href="/client-settings/user_attr/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'userAttributeSettings'" />
          </div>
        </a>

        <a
          v-if="client.isContractContact"
          class="clientSettingsNav__link"
          href="/client-settings/contact/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'contactSettings'" />
          </div>
        </a>

        <a
          class="clientSettingsNav__link"
          href="/client-settings/csv_format/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'getCsvTemplate'" />
          </div>
        </a>

        <a
          v-if="isImportValidationEnabled"
          class="clientSettingsNav__link"
          href="/client-settings/import_history/index/"
        >
          <div class="clientSettingsNav__row">
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'importHistory'" />
          </div>
        </a>
      </div>
    </transition>

    <!-- TD Import setting -->
    <div
      v-if="client.isContractTdImport"
      class="clientSettingsNav__item clientSettingsNav__tdImportSettings"
      :class="{ 'clientSettingsNav__item--selected': isTdSettingsPages }"
      @click="onClickTdImport"
    >
      <Icon
        class="clientSettingsNav__icon"
        :icon="icons.TreasureData"
        :color="iconColor"
        :size="iconSize"
      />

      <div v-t="'tdImportSettings'" />

      <Icon
        class="clientSettingsNav__suffixIcon"
        :class="[{ 'clientSettingsNav__suffixIcon--opened': showTdImportRows }]"
        :icon="icons.CaretRight"
        :color="suffixIconColor"
        :size="suffixIconSize"
      />
    </div>
    <transition name="float">
      <div v-if="showTdImportRows" class="clientSettingsNav__rows">
        <RouterLink
          class="clientSettingsNav__link"
          :to="{ name: 'td-web-import-config-details' }"
        >
          <div
            class="clientSettingsNav__row"
            :class="{ 'clientSettingsNav__row--current': isTdImportSettings }"
          >
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="isTdImportSettings ? iconColorCurrent : rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'tdWebImportConfigSetting'" />
          </div>
        </RouterLink>
        <RouterLink
          class="clientSettingsNav__link"
          :to="{ name: 'td-conversion-settings' }"
        >
          <div
            class="clientSettingsNav__row"
            :class="{
              'clientSettingsNav__row--current': isTdConversionSettings
            }"
          >
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="isTdConversionSettings ? iconColorCurrent : rowIconColor"
              :size="rowIconSize"
            />
            <div v-t="'tdConversion'" />
          </div>
        </RouterLink>

        <RouterLink
          class="clientSettingsNav__link"
          :to="{ name: 'td-conversion-attribute-settings' }"
        >
          <div
            class="clientSettingsNav__row"
            :class="{
              'clientSettingsNav__row--current': isTdConversionAttributeSettings
            }"
          >
            <Icon
              class="clientSettingsNav__rowIcon"
              :icon="icons.ArrowRight"
              :color="
                isTdConversionAttributeSettings
                  ? iconColorCurrent
                  : rowIconColor
              "
              :size="rowIconSize"
            />
            <div v-t="'tdConversionAttribute'" />
          </div>
        </RouterLink>
      </div>
    </transition>

    <!-- link to measurement history -->
    <a
      class="clientSettingsNav__link"
      href="/client-settings/use-status/index/"
    >
      <div class="clientSettingsNav__item clientSettingsNav__usageStatus">
        <Icon
          class="clientSettingsNav__icon"
          :icon="icons.LineChart"
          :color="iconColor"
          :size="iconSize"
        />

        <div v-t="'measurementHistory'" />
      </div>
    </a>

    <!-- link to csv takeout -->
    <a
      v-if="client.isContractWeb"
      class="clientSettingsNav__link"
      href="/client-settings/csv-takeout/"
    >
      <div
        class="clientSettingsNav__item clientSettingsNav__measurementDataOutput"
      >
        <Icon
          class="clientSettingsNav__icon"
          :icon="icons.Download"
          :color="iconColor"
          :size="iconSize"
        />

        <div v-t="'csvTakeout'" />
      </div>
    </a>

    <!-- link to help site -->
    <a
      href="https://help.usergram.info/"
      target="_blank"
      rel="noopener"
      class="clientSettingsNav__link"
    >
      <div class="clientSettingsNav__item clientSettingsNav__help">
        <Icon
          class="clientSettingsNav__icon"
          :icon="icons.Help"
          :color="iconColor"
          :size="iconSize"
        />

        <div v-t="'helpSite'" />
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { Client } from "@/models/Client";
import { Coordination } from "@/models/client-settings/Coordination";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";

@Component({
  components: {
    Icon
  }
})
export default class ClientSettingsNav extends Vue {
  icons = Icons;
  iconColor = Colors.Base800;
  iconColorCurrent = Colors.White;
  rowIconColor = Colors.Base700;
  suffixIconColor = Colors.Base580;
  iconSize = "19px";
  rowIconSize = "6px";
  suffixIconSize = "10px";

  showWebRows = false;
  showAppRows = false;
  showAccountRows = false;
  showImportDataRows = false;
  showTdImportRows = false;

  get client(): Client {
    return this.$store.state.client.client;
  }

  get coordinations(): Coordination[] {
    return this.$store.state.clientSettings.coordinations;
  }

  get isImportValidationEnabled(): boolean {
    return this.$store.state.app.isImportValidationEnabled;
  }

  get routeName(): string {
    return this.$route.name || "";
  }

  get isSettingsTop(): boolean {
    return this.$route.name == "client-settings-top";
  }

  get isWebSettingPages(): boolean {
    // Will be updated in the future like `this.isConversionSettings || this.isEventSettings
    return this.isConversionSettings;
  }

  get isConversionSettings(): boolean {
    return this.routeName.startsWith("conversion-settings");
  }

  get isCvEventAttributeSettings(): boolean {
    return this.$route.path === "/client-settings/conversion-attribute/index/";
  }

  get isTdSettingsPages(): boolean {
    return this.$route.path.startsWith("/client-settings/treasure-data/");
  }

  get isTdImportSettings(): boolean {
    return this.routeName.startsWith("td-web-import-config-details");
  }

  isCoordinationSettings(coordinationId?: number): boolean {
    if (coordinationId) {
      return coordinationId === Number(this.$route.params.coordinationId);
    } else {
      return /coordination-[\w-]+-settings[\w-]+/.test(this.routeName);
    }
  }

  get isCoordinationConversionSettings(): boolean {
    return this.routeName.startsWith("coordination-conversion-settings");
  }

  get isCoordinationEventSettings(): boolean {
    return this.routeName.startsWith("coordination-event-settings");
  }

  get isCoordinationConversionAttributeSettings(): boolean {
    return this.routeName.startsWith(
      "coordination-conversion-attribute-settings"
    );
  }

  get isTdConversionSettings(): boolean {
    return [
      "td-conversion-settings",
      "td-conversion-create",
      "td-conversion-update",
      "td-conversion-update-measurement-state"
    ].includes(this.routeName);
  }

  get isTdConversionAttributeSettings(): boolean {
    return this.routeName.startsWith("td-conversion-attribute-");
  }

  get importValidationEnabled(): boolean {
    return !!process.env.VUE_APP_IMPORT_VALIDATION_ENABLED;
  }

  @Watch("routeName")
  updateNavStatus() {
    this.showWebRows = this.isWebSettingPages;
    this.showTdImportRows = this.isTdSettingsPages;
    this.showAppRows = this.isCoordinationSettings();
  }

  created() {
    this.updateNavStatus();
  }

  onClickWeb() {
    this.showWebRows = !this.showWebRows;
  }

  onClickApp() {
    this.showAppRows = !this.showAppRows;
  }

  onClickAccount() {
    this.showAccountRows = !this.showAccountRows;
  }

  onClickImportData() {
    this.showImportDataRows = !this.showImportDataRows;
  }

  onClickTdImport() {
    this.showTdImportRows = !this.showTdImportRows;
  }
}
</script>

<style lang="scss" scoped>
.clientSettingsNav {
  width: 244px;
  min-width: 244px;
}

.clientSettingsNav__link:hover {
  text-decoration: none;
}

.clientSettingsNav__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 54px;
  padding-left: 12px;
  border-top: 1px solid $colorBase500;
  color: $colorBase800;
  font-size: 13px;
  position: relative;

  &:hover {
    background: $colorBlue500;
  }
}

.clientSettingsNav__item--current {
  background-color: $colorBase900;
  color: $colorWhite;
  font-weight: bold;
  font-size: 12px;

  &:hover {
    background: $colorBase900;
  }
}

.clientSettingsNav__icon {
  margin-right: 9px;
}

.clientSettingsNav__suffixIcon {
  position: absolute;
  right: 10px;
  transition: 0.3s;
  transform: rotate(0);
}

.clientSettingsNav__suffixIcon--opened {
  transform: rotate(90deg);
}

.clientSettingsNav__item--selected {
  font-weight: bold;
}

.clientSettingsNav__rows {
  transition: 0.2s;
}

.clientSettingsNav__row {
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 34px;
  border-top: 1px dashed $colorBase500;
  color: $colorBase800;
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    background: $colorBlue500;
  }
}

.clientSettingsNav__row--current {
  background-color: $colorBase900;
  color: $colorWhite;
  font-weight: bold;
  font-size: 11px;

  &:hover {
    background: $colorBase900;
  }
}

.clientSettingsNav__rowIcon {
  margin-right: 6px;
}

.float-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}
.float-leave-active {
  opacity: 1;
  transition: opacity 0s;
}
.float-enter,
.float-leave-to {
  opacity: 0;
}

.clientSettingsNav__rowsSubs {
  padding-bottom: 10px;

  & a {
    text-decoration: none;
  }
}

.clientSettingsNav__rowsSub {
  padding: 6px 10px 6px 45px;
  color: #818599;
  font-size: 12px;
  line-height: 1.5;

  &::before {
    content: "- ";
  }

  &.current-page {
    color: #323442;
    font-weight: bold;
  }
}
</style>
