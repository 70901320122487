import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

export enum SearchHistoryType {
  Conversion = 1,
  ServiceId = 2,
  Filter = 3,
  BusinessEvent = 4,
  Contact = 5,
  Loyalty = 6,
  App = 8,
  Engagement = 9,
  UserJourney = 11
}

export default class ApiSearchHistory {
  constructor(private readonly httpClient: HttpClient) {}

  async getFavoriteSearches(): Promise<ApiRes.FavoriteSearch[]> {
    const response = await this.httpClient.get<{
      favorite_searches: ApiRes.FavoriteSearch[];
    }>(ApiUrl.FAVORITE_SEARCH_LIST);

    return response.favorite_searches;
  }

  async createFavoriteSearch(
    historyId: number,
    label: string
  ): Promise<ApiRes.FavoriteSearch> {
    const response = await this.httpClient.post<{
      favorite_search: ApiRes.FavoriteSearch;
    }>(ApiUrl.FAVORITE_SEARCH_SAVE, {
      selection_history_id: historyId,
      label
    });

    return response.favorite_search;
  }

  async updateFavoriteSearch(
    id: number,
    historyId: number,
    label: string
  ): Promise<ApiRes.FavoriteSearch> {
    const response = await this.httpClient.post<{
      favorite_search: ApiRes.FavoriteSearch;
    }>(ApiUrl.FAVORITE_SEARCH_SAVE, {
      id,
      selection_history_id: historyId,
      label
    });

    return response.favorite_search;
  }

  async deleteFavoriteSearch(id: number): Promise<void> {
    await this.httpClient.post<any>(ApiUrl.FAVORITE_SEARCH_REMOVE(id), {});

    return;
  }

  async getSelectionHistories(): Promise<ApiRes.SelectionHistory[]> {
    const response = await this.httpClient.get<{
      histories: ApiRes.SelectionHistory[];
    }>(ApiUrl.SERVER_CALL_HISTORY);

    return response.histories;
  }

  async getFilterHistories(): Promise<ApiRes.FilterHistory[]> {
    const response = await this.httpClient.get<{
      histories: ApiRes.FilterHistory[];
    }>(ApiUrl.SERVER_CALL_FILTER_HISTORY, { limit: 50 });

    return response.histories;
  }

  async getSelectionHistory(
    type: SearchHistoryType
  ): Promise<ApiRes.SelectionHistory[]> {
    const response = await this.httpClient.get<{
      histories: ApiRes.SelectionHistory[];
    }>(ApiUrl.SERVER_CALL_HISTORY, {
      type,
      limit: 1
    });

    return response.histories;
  }

  async getSelectionHistoryById(id: number): Promise<ApiRes.SelectionHistory> {
    return await this.httpClient.get<ApiRes.SelectionHistory>(
      `${ApiUrl.SERVER_CALL_HISTORY}${id}`
    );
  }

  async getFilterHistoryById(id: number): Promise<ApiRes.FilterHistory> {
    return await this.httpClient.get<ApiRes.FilterHistory>(
      `${ApiUrl.SERVER_CALL_FILTER_HISTORY}${id}`
    );
  }
}
