import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";

export interface ConversionResponse {
  id: number;
  name: string;
  path: string[];
  is_disabled: boolean;
}

export interface CreateConversionParam {
  name: string;
  path: string[];
}

export interface UpdateConversionParam {
  name?: string;
  path?: string[];
  is_disabled?: boolean;
}

export default class ApiConversion {
  constructor(private readonly httpClient: HttpClient) {}

  public getConversionList = async (): Promise<ConversionDefinition[]> => {
    const url = ApiUrl.CONVERSION;

    const response = await this.httpClient.get<ConversionResponse[]>(url);

    return response.map(this.toModel);
  };

  public getConversion = async (id: number): Promise<ConversionDefinition> => {
    const url = `${ApiUrl.CONVERSION}${id}/`;

    const response = await this.httpClient.get<ConversionResponse>(url);
    return this.toModel(response);
  };

  public createConversion = async (
    params: CreateConversionParam
  ): Promise<ConversionDefinition> => {
    const url = ApiUrl.CONVERSION;

    const response = await this.httpClient.post<ConversionResponse>(
      url,
      params
    );
    return this.toModel(response);
  };

  public createManyConversions = async (
    params: CreateConversionParam[]
  ): Promise<ConversionDefinition[]> => {
    const url = `${ApiUrl.CONVERSION}create-many/`;

    const response = await this.httpClient.post<ConversionResponse[]>(
      url,
      params
    );
    return response.map(this.toModel);
  };

  public updateConversion = async (
    id: number,
    apiParam: UpdateConversionParam
  ): Promise<ConversionDefinition> => {
    const url = `${ApiUrl.CONVERSION}${id}/`;

    const response = await this.httpClient.patch<ConversionResponse>(
      url,
      apiParam
    );
    return this.toModel(response);
  };

  private toModel(json: ConversionResponse) {
    return new ConversionDefinition(
      json.id,
      json.name,
      json.is_disabled,
      json.path
    );
  }
}
