<i18n src="@/i18n/components/tours/tour-detail-title.json"></i18n>
<template>
  <div class="tourDetailTitle">
    <div class="tourDetailTitle__title">
      <Icon class="tourDetailTitle__icon" :icon="iconTour" :size="iconSize" />
      <div v-t="'title'" />
    </div>
    <div class="tourDetailTitle__suggestion">
      {{ suggestion }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    Icon
  }
})
export default class TourDetailTitle extends Vue {
  iconTour = Icons.Binocular;
  iconSize = 18;

  @Prop({ type: String, required: true })
  suggestion!: string;
}
</script>

<style scoped lang="scss">
.tourDetailTitle {
  display: flex;
  color: $colorBase900;
  font-size: 14px;
  line-height: 1.5;
}
.tourDetailTitle__title {
  display: flex;
  font-weight: bold;
  &::after {
    display: block;
    margin: 3px 15px 0;
    width: 1px;
    height: 14px;
    background: $colorBase500;
    content: "";
  }
}
.tourDetailTitle__icon {
  display: flex;
  margin-top: 1px;
  margin-right: 6px;
}
.tourDetailTitle__suggestion {
  width: 490px;
}
</style>
