import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

export default class ApiUserHistory {
  constructor(private readonly httpClient: HttpClient) {}

  public getUserHistoryList = (): Promise<ApiRes.UserHistories> => {
    return this.httpClient.get<ApiRes.UserHistories>(
      ApiUrl.OBSERVE_HISTORY_LIST
    );
  };
}
