<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <UgTable class="user-gram-chart" :sticky-top="stickyTop">
    <template #header>
      <UgTableHeaderRow>
        <UgTableHeaderCell v-t="'user'" :width="userColumnWidth" />
        <UgTableHeaderCell v-t="'actOverview'" />
      </UgTableHeaderRow>
    </template>
    <UgTableRow v-for="user in users" :key="createRowKey(user)">
      <UgTableCell
        class="upt-user-icon-cell"
        :class="{ 'current-user': isCurrentUser(user.id) }"
        :width="userColumnWidth"
        @mouse-enter="onMouseEnter($event, user.id)"
        @mouse-leave="onMouseLeave"
      >
        <slot :user="user" />
      </UgTableCell>
      <UgTableCell>
        <GramChartWrapperForList
          :user="user"
          :almost-cv-gram-ids="getAlmostCvGramIds(user.id)"
          :end-date="endDate"
          :chart-period="chartPeriod"
          :colored-periods="coloredPeriods"
          :is-omo="isOmo"
          :display-contact-definition-types="displayContactDefinitionTypes"
          :display-business-event-definition-ids="
            displayBusinessEventDefinitionIds
          "
          :funnel-matched-gram-ids="getFunnelMatchedGramIds(user.id)"
          :funnel-matched-selected-gram-ids="
            getFunnelMatchedSelectedGramIds(user.id)
          "
          :selected-conversion-ids="selectedConversionIds"
          :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
          @click-chart-icon="onClickChartIcon"
        />
      </UgTableCell>
    </UgTableRow>
  </UgTable>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { ChartPeriod } from "@/const/chart-period";
import GramChartWrapperForList from "@/components/chart/GramChartWrapperForList.vue";
import UgTable from "@/components/table/UgTable.vue";
import UgTableRow from "@/components/table/UgTableRow.vue";
import UgTableCell from "@/components/table/UgTableCell.vue";
import UgTableHeaderRow from "@/components/table/UgTableHeaderRow.vue";
import UgTableHeaderCell from "@/components/table/UgTableHeaderCell.vue";

import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";
import { msecToUsec } from "@/util/date-util";
import { stopWindowScroll } from "@/util/window-util";
import { UserInfo } from "@/models/UserInfo";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { GramListOfUser } from "@/api/apis/ApiFunnel";

const USER_COLUMN_WIDTH: number = 135;

@Component({
  components: {
    UgTable,
    UgTableRow,
    UgTableCell,
    UgTableHeaderRow,
    UgTableHeaderCell,
    GramChartWrapperForList
  }
})
export default class UserGramChartTable extends Vue {
  @Prop({ type: Array, required: true })
  users!: User[];

  @Prop({ type: Date, required: true })
  endDate!: Date;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: String, default: "0" })
  stickyTop!: string;

  @Prop({ type: Number, required: true })
  chartPeriod!: ChartPeriod;

  @Prop({ type: Array })
  displayContactDefinitionTypes?: ContactDefinitionType[];

  @Prop({ type: Array })
  displayBusinessEventDefinitionIds?: number[];

  @Prop({ type: User, default: null })
  currentUser!: User;

  @Prop({ type: Array, default: () => [] })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Object, default: null })
  almostCvUserList!: AlmostCvUserList;

  @Prop({ type: Boolean, default: false })
  isFunnelResult!: boolean;

  @Prop({ type: Number, required: true })
  funnelSelectedOrder!: number;

  @Prop({ type: Object, required: true })
  funnelGramIdsPerUser!: GramListOfUser;

  @Prop({ type: Array, required: true })
  selectedConversionIds!: string[];

  @Prop({ type: Boolean, required: true })
  isToggleTimeOfDayEnabled!: boolean;

  onMouseEnter(event: MouseEvent, id: string): void {
    this.$emit("mouse-enter", event, id);
  }

  onMouseLeave(event: MouseEvent): void {
    this.$emit("mouse-leave", event);
  }

  userColumnWidth: string = USER_COLUMN_WIDTH + "px";

  onClickChartIcon(
    overview:
      | VisitOverview
      | NpsOverview
      | BusinessEventOverview
      | EnqueteOverview
      | ContactOverview,
    user: User
  ) {
    stopWindowScroll();

    // ユーザ詳細を閉じたときのURLとタイトルを保存しておく
    this.$store.commit(
      "user/setPathBeforeShowUserDetail",
      this.$route.fullPath
    );
    this.$store.commit("user/setTitleBeforeShowUserDetail", document.title);

    // this.$router.push で移動するとtableがAttributeになってしまうので、URLのみだけ書き換える
    history.pushState(null, "", "/users/" + user.id);

    this.$store.dispatch("user/showUserDetail", {
      user: user,
      startBaseTimeUsec: msecToUsec(overview.date.getTime())
    });

    // 閲覧履歴にユーザを追加
    this.$store.commit("history/addUserHistory", user);
    this.$store.commit("userInfo/addUserInfo", UserInfo.fromUser(user));
  }

  isCurrentUser(userId: string): boolean {
    if (this.currentUser) {
      return this.currentUser.id === userId;
    }
    return false;
  }

  getAlmostCvGramIds(userId: string): string[] {
    if (this.almostCvUserList === null) {
      return [];
    }
    return this.almostCvUserList.getGramIdsByUserId(userId);
  }

  /**
   * Create key for UgTableRow.
   * We have to change key user has filterMatchedIds or not.
   * Because if we use the same key, the filtered bar color remains.
   * TODO
   * 本来であれば、user.overviews.filterMatchedIdsの変更を検知して、更新されて欲しいが、
   * 更新されないので暫定対応してkeyを更新する
   * @param user User displayed in the table row
   * @return key for UgTableRow's v-for
   */
  createRowKey(user: User): string {
    return `${user.id}-${user.overviews.filterMatchedIds.length}`;
  }

  get isIntermediateResult() {
    return this.$route.path.includes("users") && !!this.$route.query.funnelId;
  }

  getFunnelMatchedGramIds(userId: string): string[] {
    if (!this.isFunnelResult) return [];

    const userGrams = this.funnelGramIdsPerUser[userId];
    if (!userGrams || userGrams.length === 0) return [];

    return userGrams.slice(0, this.funnelSelectedOrder - 1);
  }
  getFunnelMatchedSelectedGramIds(userId: string): string[] {
    if (!this.isFunnelResult) return [];
    const userGrams = this.funnelGramIdsPerUser[userId];
    if (!userGrams || userGrams.length === 0) return [];

    return this.isIntermediateResult // For intermediate result we select the gram for the next condition as well
      ? [
          userGrams[this.funnelSelectedOrder - 2],
          userGrams[this.funnelSelectedOrder - 1]
        ]
      : [userGrams[this.funnelSelectedOrder - 1]];
  }
}
</script>

<style scoped lang="scss">
.upt-user-icon-cell {
  position: relative;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.current-user {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: $colorBlue900;
    content: "";
  }
}
</style>
