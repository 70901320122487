import { ApiRes } from "@/api/api-res";
import { AttributeType } from "@/api/apis/ApiSearch";
import { CreationMethod } from "@/models/client-settings/Coordination";
/**
 * コンバージョン・イベント属性
 *
 * コンバージョン・イベント属性は、ユーザがフォームに入力した情報やWebサイト側で発行された情報など、
 * クライアントが任意に定義できるコンバージョンに紐づく値です。
 */

export class ConversionAttributeDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    // true = 計測中, false = 計測停止
    public readonly isDisabled: boolean,

    public readonly type: AttributeType
  ) {}

  public static fromJson(
    json: ApiRes.ConversionAttribute
  ): ConversionAttributeDefinition {
    return new ConversionAttributeDefinition(
      json.id,
      json.name,
      json.is_disabled,
      json.attribute_type
    );
  }
}

export type ConversionAttributeType = "TEXT" | "NUMBER";

export interface CoordinationConversionAttributeEditableData {
  targetData: string;
  creationMethod: CreationMethod;
  conversionAttributeName: string;
  conversionAttributeType: ConversionAttributeType;
  conversionAttributeId: number;
  path: string[];
}

export const MAX_NAME_LENGTH = 40;
export const MAX_PARAM_LENGTH = 40;

export const isValidCoordinationConversionAttributeData = (
  data: CoordinationConversionAttributeEditableData
) => {
  if (data.targetData === "") return false;
  if (data.targetData.length > MAX_NAME_LENGTH) return false;

  if (
    data.creationMethod === CreationMethod.NEW &&
    (data.conversionAttributeName === "" ||
      data.conversionAttributeName.length > MAX_NAME_LENGTH)
  )
    return false;
  if (
    data.creationMethod === CreationMethod.EXISTING &&
    data.conversionAttributeId <= 0
  )
    return false;

  if (data.path.length < 1) return false;
  if (data.path.length > 5) return false;
  if (data.path.some(p => p === "")) return false;
  if (data.path.some(p => p.length > MAX_PARAM_LENGTH)) return false;
  if (data.path.some(p => isInValidConversionAttributeParam(p))) return false;

  return true;
};

export function isInValidConversionAttributeParam(path: string): boolean {
  return !path.match(/^[0-9A-Za-z-\._]*$/);
}
