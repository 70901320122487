import { ApiRes } from "@/api/api-res";

/**
 * Model holding userId and gramIds
 * gramIds are for colored gram chart visit bar
 */
export class AlmostCvUser {
  public constructor(
    public readonly userId: string,
    public readonly gramIds: string[]
  ) {}

  /**
   * Convert data from API into an instance of AlmostCvUser
   * @param json an element from API // ApiRes.AlmostCvUser
   * @returns an instance of this class
   */
  public static fromJson(json: ApiRes.AlmostCvUser): AlmostCvUser {
    return new AlmostCvUser(json.user_id, json.gram_ids);
  }
}
