<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-node" data-cy="filter-node" @dragover.prevent>
    <div class="node">
      <div class="node-head">
        <div class="title">
          {{ title }}
        </div>
        <template v-if="depth === 0 && exclusionVisibility">
          <div
            v-for="option in exclusionTypeOptions"
            :key="option.value"
            class="node-exclusion__type-form"
          >
            <RadioButton
              :value="selectExclusionTypeValue"
              :label="option.value"
              name="node-exclusion"
              :disabled="isExcludeButtonDisabled(option.value)"
              @change="onSelectExclusionType"
              >{{ option.label }}
            </RadioButton>
          </div>
          <span
            v-if="displayExclusionDescriptionText"
            class="node-exclusion__description"
            >{{ $t("filterExclusionDescription") }}</span
          >
        </template>
        <IconButton
          class="remove"
          :icon="icons.Close"
          :size="40"
          :icon-scale="0.4"
          :background="true"
          @click="handleNodeRemoval"
        />
      </div>
      <div class="node-body">
        <slot />
        <FilterAddConditionButton
          v-if="showAddCondition"
          @click="onClickAddCondition"
        />
        <FilterConditionFormDropArea
          v-if="showOrDropArea"
          :class="additionalContitionClass"
          height="auto"
          type="orNode"
          @drop="handleDrop"
        >
          <span v-html="$te('dragHereOrNode') ? $t('dragHereOrNode') : ''" />
        </FilterConditionFormDropArea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { i18n } from "@/i18n";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

import FilterAddConditionButton from "@/components/filter/FilterAddConditionButton.vue";
import FilterConditionFormDropArea from "@/components/filter/FilterConditionFormDropArea.vue";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectOption from "@/components/form/SelectOption";
import { Icons } from "@/const/Icons";
import {
  ExclusionProps,
  ExclusionType
} from "@/models/search/filter-node/FilterExclusion";

@Component({
  name: "FilterNode",
  components: {
    IconButton,
    Icon,
    FilterAddConditionButton,
    FilterConditionFormDropArea,
    RadioButton
  }
})
export default class FilterNode extends Vue {
  icons = Icons;

  @Prop({ type: String, required: true })
  title!: String;

  @Prop({ type: Number, required: true })
  depth!: number;

  @Prop({ type: Number, required: false })
  index!: number;

  @Prop({ type: Boolean, required: true })
  showAddCondition!: boolean;

  @Prop({ type: Number, default: ExclusionType.Include })
  selectExclusionTypeValue!: ExclusionType;

  @Prop({ type: Object, required: false })
  exclusion!: ExclusionProps | undefined;

  @Prop({ type: Boolean, required: true })
  hasAllAdditionalConditions!: boolean | undefined;

  @Prop({ type: Boolean, required: true })
  showOrDropArea!: boolean;

  @Emit("add-condition")
  onClickAddCondition() {}

  onSelectExclusionType(exclusionType: ExclusionType) {
    this.$emit("on-select-exclusion-type", exclusionType);
  }

  get draggingNodeType() {
    return this.$store.state.filter.draggingNodeType;
  }

  get filterConditionNodes() {
    return this.$store.state.filter.filterConditionForForm.filterNodes;
  }

  get exclusionTypeOptions(): SelectOption<ExclusionType>[] {
    return [
      {
        value: ExclusionType.Include,
        label: i18n.t("components.search.filter.include") as string,
        disabled: false
      },
      {
        value: ExclusionType.Exclude,
        label: i18n.t("components.search.filter.exclude") as string,
        disabled: false
      }
    ];
  }

  get indexOfEnableExcludeCondition(): number | null {
    return this.$store.state.filter.indexOfEnableExcludeCondition;
  }

  isExcludeButtonDisabled(exclusionType: ExclusionType): boolean {
    if (exclusionType === ExclusionType.Include) return false;

    // should be disabled when other node is excluded
    if (!!this.exclusion && !this.exclusion.isEnabled) return true;

    // should be disabled when middle exclusion checkbox is checked
    return this.indexOfEnableExcludeCondition !== null;
  }

  handleDrop() {
    this.$emit("add-or-node", this.draggingNodeType);
  }

  handleNodeRemoval() {
    this.$emit("remove-node");
  }

  get displayExclusionDescriptionText(): boolean {
    // check for exclusion active in other nodes
    return (
      (!!this.exclusion && !this.exclusion.isEnabled) ||
      this.indexOfEnableExcludeCondition !== null
    );
  }

  get exclusionVisibility(): boolean {
    return !!this.exclusion && this.exclusion.isShown;
  }

  get additionalContitionClass(): string {
    if (this.hasAllAdditionalConditions) {
      return "filterConditionForm__emptyOrDragArea-under";
    }
    return "filterConditionForm__emptyOrDragArea";
  }
}
</script>

<style lang="scss" scoped>
.node {
  background: $colorWhite;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
}

.title {
  font-weight: bold;
  font-size: 16px;
  margin-right: 15px;
  white-space: nowrap;
}

.node-head {
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
}
.node-exclusion__type-form {
  white-space: nowrap;
}
::v-deep .radioButton__wrapper--disabled {
  color: $colorBase500;
}
::v-deep .radioButton--disabled .radioButton__icon {
  border-color: $colorElmentUIGray300;
}
.node-exclusion__description {
  font-size: 12px;
  color: $colorBase700;
  margin-left: 10px;
}
.remove {
  position: absolute;
  top: 8px;
  right: 8px;
}

.node-body {
  padding: 0px 15px 15px 20px;
}

.add-condition {
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
}

.exclusion-type {
  margin-left: 10px;
}

.filterConditionForm__emptyOrDragArea {
  margin-top: -35px;
  padding: 6px 0;
  position: relative;
}

.filterConditionForm__emptyOrDragArea-under {
  margin-top: 12px;
  padding: 6px 0;
}
</style>
