import { i18n } from "@/i18n";

import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";

import { FunnelType } from "@/const/funnel";
import { ContactFieldType } from "@/models/search/additional-condition/ContactAttributeCondition";
import { ComparisonType } from "@/models/funnel/FunnelConditionActivity";
import { FunnelAnalysisCondition } from "@/models/funnel/FunnelAnalysisCondition";
import { FunnelAnalysisHistory } from "@/models/funnel/FunnelAnalysisCondition";
import { DateFormat, formatDate } from "@/util/date-util";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";

export type FunnelDataDefinitionType =
  | ConversionDefinition
  | EventDefinition
  | BusinessEventDefinition
  | ContactDefinition;

export function getFunnelDataDefinitionLabel(
  definitions: FunnelDataDefinitionType[],
  value: string[] | number[],
  conditionType: FunnelType
): string {
  if ([FunnelType.Inflow, FunnelType.InflowOrganic].includes(conditionType)) {
    return value.join(", ");
  }

  const filterDefinitions = definitions.filter(d => {
    if (conditionType === FunnelType.CV && d instanceof ConversionDefinition) {
      return d;
    }

    if (conditionType === FunnelType.Event && d instanceof EventDefinition) {
      return d;
    }

    if (
      conditionType === FunnelType.BusinessEvent &&
      d instanceof BusinessEventDefinition
    ) {
      return d;
    }

    if (
      conditionType === FunnelType.Contact &&
      d instanceof ContactDefinition
    ) {
      return d;
    }
  });

  const definition = filterDefinitions.find(d => d.id === value[0]);

  if (definition !== undefined) {
    if (definition instanceof ContactDefinition) {
      return definition.translatedName;
    }
    return definition.name;
  }

  return String(value[0]);
}

export function getFunnelDataAttributeDefinitionLabel(
  conversionAttributeDefinitions: ConversionAttributeDefinition[],
  attributes: FunnelConditionAttributeType[],
  conditionType: FunnelType,
  useValue: boolean = true
): string {
  if (attributes.length === 0) return "";

  const attribute = attributes[0];

  if (conditionType === FunnelType.Contact) {
    let contactAttributeType = "";
    switch (attribute.attributeId) {
      case ContactFieldType.Content:
        contactAttributeType = i18n.t("util.funnelUtil.content") as string;
        break;
      case ContactFieldType.Title:
        contactAttributeType = i18n.t("util.funnelUtil.title") as string;
        break;
      case ContactFieldType.StoreName:
        contactAttributeType = i18n.t("util.funnelUtil.nameOfStore") as string;
        break;
      case ContactFieldType.Purpose:
        contactAttributeType = i18n.t(
          "util.funnelUtil.storeVisitPurpose"
        ) as string;
        break;
      default:
        contactAttributeType = "";
    }

    return useValue
      ? `${contactAttributeType}: ${attribute.displayValue}`
      : `${i18n
          .t("util.funnelUtil.contactAttribute")
          .toString()}: ${contactAttributeType}`;
  } else {
    const definition = conversionAttributeDefinitions.find(
      d => d.id === attribute.attributeId
    );

    if (definition === undefined) return "";

    return useValue
      ? `${definition.name}: ${attribute.displayValue}`
      : `${i18n.t("util.funnelUtil.conversionAttribute").toString()}: ${
          definition.name
        }`;
  }
}

export function isValidQuery(query: any, maxLength: number) {
  if (!query.s || !query.m) return false;
  if (Array.isArray(query.s) || Array.isArray(query.m)) return false;
  if (!query.s.match(/^[1-6]$/)) return false;
  if (!query.m.match(/^[0-1]$/)) return false;
  if (query.fid !== undefined) {
    if (query.fid === null) return false;
    if (Array.isArray(query.fid)) return false;
    if (!query.fid.match(/^[0-9]+$/)) return false;
  }

  const order = Number(query.s);
  const isMatched = Number(query.m);
  if (order > maxLength) return false;
  if (order === 1 && isMatched === 0) return false;

  return true;
}

export function getActivityString(
  condition: FunnelAnalysisCondition | FunnelAnalysisHistory,
  allDefinitions: FunnelDataDefinitionType[],
  conversionAttributeDefinition: ConversionAttributeDefinition[]
): string {
  if (condition.userActivities.length === 0) return "";

  const userActivity = condition.userActivities[0];
  const activityTypeName = userActivity.condition.title;
  const activityName = getFunnelDataDefinitionLabel(
    allDefinitions,
    userActivity.condition.displayValueForResult,
    userActivity.condition.conditionType
  );

  let comparisonTypeJsonPath = "";

  switch (userActivity.comparison) {
    case ComparisonType.greaterThanOrEqualTo:
      comparisonTypeJsonPath =
        "components.funnel.funnelUserActivityCondition.greaterThanOrEqualTo";
      break;
    case ComparisonType.lessThanOrEqualTo:
      comparisonTypeJsonPath =
        "components.funnel.funnelUserActivityCondition.lessThanOrEqualTo";
      break;
    default:
      comparisonTypeJsonPath =
        "components.funnel.funnelUserActivityCondition.equalTo";
  }

  const numberOfActivity = i18n.tc(comparisonTypeJsonPath, userActivity.times, {
    count: userActivity.times
  }) as string;

  const conditionOverview = [
    `${activityTypeName}${
      activityName ? ` "${activityName}"` : ""
    } (${numberOfActivity})`
  ];

  if (
    "attributes" in userActivity.condition &&
    userActivity.condition.attributes.length > 0
  ) {
    const attributeText = getFunnelDataAttributeDefinitionLabel(
      conversionAttributeDefinition,
      userActivity.condition.attributes,
      userActivity.condition.conditionType,
      true
    );
    conditionOverview.push(attributeText);
  }

  conditionOverview.push(userActivity.condition.displayPeriod);
  return conditionOverview.join(", ");
}

export function getDisplayPeriod(startDate: Date, endDate: Date): string {
  return `${formatDate(DateFormat.yyyysMMsdd, startDate)} - ${formatDate(
    DateFormat.yyyysMMsdd,
    endDate
  )}`;
}
