var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',{staticClass:"userTrendTableHeader"},[_c('AnalysisTableHeaderRow',[_vm._l((_vm.pageColumns),function(column,index){return _c('th',{key:index,staticClass:"userTrendTableHeader__cell userTrendTableHeader__cell--default userTrendTableHeader__cell--pageColumn",class:{ 'userTrendTableHeader__cell--noBoder': index === 0 },style:(_vm.style),attrs:{"rowspan":_vm.spanIndex}},[_vm._v(" "+_vm._s(column)+" "),_c('Tooltip',{attrs:{"placement":_vm.tooltipPlacement.BottomStart,"wrap":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"userTrendTableHeader__tooltipContent",domProps:{"textContent":_vm._s(_vm.pageColumnTooltipText)}})]},proxy:true}],null,true)},[_c('Icon',{attrs:{"icon":_vm.iconHelp,"size":_vm.iconSize,"color":_vm.iconHelpColor}})],1)],1)}),_vm._l((_vm.pageComparisonColumns),function(column){return _c('th',{key:column.type,staticClass:"userTrendTableHeader__cell userTrendTableHeader__cell--default userTrendTableHeader__comparisonHeadCell",class:[
        {
          'userTrendTableHeader__cell--enableHover': !_vm.isCompared
        }
      ],attrs:{"colspan":_vm.spanIndex},on:{"click":function($event){return _vm.onDefaultClick(_vm.userTrendDataType.base, column)}}},[_c('span',{class:[
          { userTrendTableHeader__sortCell: !_vm.isCompared },
          {
            'userTrendTableHeader__sortCell--showIcon':
              _vm.showSortIcon(_vm.userTrendDataType.base, column.type) && !_vm.isCompared
          }
        ]},[_vm._v(" "+_vm._s(column.label)+" "),(
            _vm.showSortIcon(_vm.userTrendDataType.base, column.type) && !_vm.isCompared
          )?_c('Icon',{staticClass:"userTrendTableHeader__sortIcon",attrs:{"icon":_vm.sortIcon(column.isDesc),"size":10,"color":_vm.iconColor}}):_vm._e()],1),(_vm.showPageComparisonColumnTooltip(column.type))?_c('Tooltip',{attrs:{"placement":_vm.pageComparisonColumnTooltipPlacement(column.type),"wrap":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"userTrendTableHeader__tooltipContent"},[(column.type === _vm.userTrendColumnType.cvRatioInVisit)?[_vm._v(" "+_vm._s(_vm.cvRatioInVisitHelpTooltipLabel)+" ")]:[_vm._v(_vm._s(_vm.pageComparisonColumnTooltipText(column.type)))]],2)]},proxy:true}],null,true)},[_c('Icon',{attrs:{"icon":_vm.iconHelp,"size":_vm.iconSize,"color":_vm.iconHelpColor}})],1):_vm._e()],1)})],2),(_vm.isCompared)?_c('AnalysisTableHeaderRow',[_vm._l((_vm.pageComparisonColumns),function(column,index){return [_c('td',{key:column.type + index,staticClass:"userTrendTableHeader__cell userTrendTableHeader__cell--target1 userTrendTableHeader__cell--enableHover",on:{"click":function($event){return _vm.onClick(_vm.userTrendDataType.base, column)}}},[_c('span',{staticClass:"userTrendTableHeader__sortCell",class:{
            'userTrendTableHeader__sortCell--showIcon': _vm.showSortIcon(
              _vm.userTrendDataType.base,
              column.type
            )
          }},[_vm._v(" "+_vm._s(_vm.baseLabel)+" "),(_vm.showSortIcon(_vm.userTrendDataType.base, column.type))?_c('Icon',{staticClass:"userTrendTableHeader__sortIcon",attrs:{"icon":_vm.sortIcon(column.isDesc),"size":_vm.iconSize,"color":_vm.iconColor}}):_vm._e()],1)]),_c('td',{key:column.type + (index + 1),staticClass:"userTrendTableHeader__cell userTrendTableHeader__cell--target2 userTrendTableHeader__cell--enableHover",on:{"click":function($event){return _vm.onClick(_vm.userTrendDataType.comparison, column)}}},[_c('span',{staticClass:"userTrendTableHeader__sortCell",class:{
            'userTrendTableHeader__sortCell--showIcon': _vm.showSortIcon(
              _vm.userTrendDataType.comparison,
              column.type
            )
          }},[_vm._v(" "+_vm._s(_vm.comparisonLabel)+" "),(_vm.showSortIcon(_vm.userTrendDataType.comparison, column.type))?_c('Icon',{staticClass:"userTrendTableHeader__sortIcon",attrs:{"icon":_vm.sortIcon(column.isDesc),"size":_vm.iconSize,"color":_vm.iconColor}}):_vm._e()],1)])]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }