<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <LightUgTableHeader class="featuredUsersLagCvTableHeader">
    <LightUgTableHeaderRow>
      <LightUgTableHeaderCell :width="FIRST_CELL_WIDTH">
        {{ $t("cv") }}
      </LightUgTableHeaderCell>
      <LightUgTableHeaderCell no-padding>
        <LightUgTableHeaderRow>
          <LightUgTableHeaderCell text-align="center">
            {{ $t("lagCvTableHeader") }}
          </LightUgTableHeaderCell>
        </LightUgTableHeaderRow>
        <LightUgTableHeaderRow>
          <LightUgTableHeaderCell
            v-for="userCount in countsByPeriod"
            :key="`lagcv-search-panel-${userCount.periodType}`"
            is-thin
          >
            <div class="featuredUsersLagCvTableHeader__periodCell">
              <Tooltip
                class="featuredUsersLagCvTableHeader__tooltip"
                :text="getPeriodText(userCount.startDate, userCount.endDate)"
              >
                <Icon :icon="Icons.History" />
              </Tooltip>
              <span class="featuredUsersLagCvTableHeader__period">{{
                $t(userCount.periodType)
              }}</span>
            </div>
          </LightUgTableHeaderCell>
        </LightUgTableHeaderRow>
      </LightUgTableHeaderCell>
    </LightUgTableHeaderRow>
  </LightUgTableHeader>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LightUgTableHeaderRow from "@/components/table/LightUgTableHeaderRow.vue";
import LightUgTableHeaderCell from "@/components/table/LightUgTableHeaderCell.vue";
import LightUgTableHeader from "@/components/table/LightUgTableHeader.vue";
import { UserCountsByPeriod } from "@/components/search/FeaturedUsersLagCvTable.vue";
import Icon from "@/components/Icon.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Icons } from "@/const/Icons";
import { DateFormat, formatDate, isSameDate } from "@/util/date-util";

@Component({
  components: {
    LightUgTableHeaderRow,
    LightUgTableHeaderCell,
    LightUgTableHeader,
    Icon,
    Tooltip
  }
})
export default class FeaturedUsersLagCvTableHeader extends Vue {
  FIRST_CELL_WIDTH = "358px";
  Icons = Icons;

  @Prop({ type: Array, required: true })
  countsByPeriod!: UserCountsByPeriod[];

  getPeriodText(startDate: string, endDate: string): string {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const formattedStartDate = formatDate(DateFormat.yyyysMMsdd, startDateObj);
    const formattedEndDate = formatDate(DateFormat.yyyysMMsdd, endDateObj);
    if (isSameDate(startDateObj, endDateObj)) return formattedStartDate;
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
}
</script>
<style lang="scss" scoped>
.featuredUsersLagCvTableHeader__periodCell {
  display: inline-flex;
  align-items: center;
}
.featuredUsersLagCvTableHeader__period {
  padding-bottom: 1px;
}
.featuredUsersLagCvTableHeader__tooltip {
  margin-right: 5px;
}
</style>
