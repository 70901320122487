<template>
  <UgTableCell
    class="lightUgTableCell"
    :class="{ 'lightUgTableCell--disabled': disabled }"
    :width="width"
    @mouse-enter="onMouseEnter"
    @mouse-leave="onMouseLeave"
    @click="event => !disabled && onClick(event)"
  >
    <slot></slot>
  </UgTableCell>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import UgTableCell from "@/components/table/UgTableCell.vue";

@Component({
  components: {
    UgTableCell
  }
})
export default class LightUgTableCell extends Vue {
  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  onMouseEnter(event: MouseEvent) {
    this.$emit("mouse-enter", event);
  }

  onMouseLeave(event: MouseEvent) {
    this.$emit("mouse-leave", event);
  }

  onClick(event: MouseEvent) {
    this.$emit("click", event);
  }
}
</script>

<style lang="scss" scoped>
.lightUgTableCell {
  text-align: left;
  padding: 13px 15px 12px;
  border-right: solid 1px $colorBase400;
  border-bottom: solid 1px $colorBase400;
  box-sizing: border-box;
}
.lightUgTableCell--disabled {
  background: $colorBase300;
}
</style>
