export enum Colors {
  Base1000 = "#231815",
  Base900 = "#323442",
  Base800 = "#56596B",
  Base700 = "#818599",
  Base600 = "#A3ACBF",
  Base580 = "#b5bdcc",
  Base500 = "#CAD1E0",
  Base400 = "#E1E5EE",
  Base300 = "#F5F5F7",

  Blue1000 = "#0077cc",
  Blue900 = "#51A5E0",
  Blue820 = "#6da9d4",
  Blue800 = "#7EBAE6",
  Blue780 = "#98c8eb",
  Blue750 = "#a0d1f5",
  Blue730 = "#ABD6FE",
  Blue700 = "#D9EDFF",
  Blue600 = "#E6F3FF",
  Blue500 = "#F3F9FE",

  White = "#FFF",
  Gray100 = "#F7F7F7",
  Gray200 = "#eeeeee",

  Orange700 = "#eeb466",
  Orange500 = "#e6bb7e",
  Orange300 = "#FEE1AB",

  ChartColoredPeriodPast = "#FEE1AB",

  Purple = "#c888cc",

  Clear = "rgba(0, 0, 0, 0)",
  RedForFavorite = "#fc9c95",
  RedForNote = "#C93636",
  RedForTimeFrame = "#ee7066",
  WhiteAlpha09 = "rgba(255, 255, 255, .9)",

  UserTrendScatter = "rgba(163, 172, 191, .8)", // Base600 + 0.8

  ElmentUIGray300 = "#c0c4cc",

  Cv = "#90c8f0",
  HighlightedBar = "#5874fc",
  HighlightedBarBg = "#e5eaff",
  SemiHighlightedBar = "#90A0F0",

  MorningVisitWithCv = "#ee7066",
  MorningVisitWithOutCv = "#f5b9b5",

  DayVisitWithCv = "#eeb466",
  DayVisitWithOutCv = "#fcdbae",

  NightVisitWithCv = "#c888cc",
  NightVisitWithOutCv = "#f1b5f5",

  TextLink = Blue1000,
  TextLinkGray = Base700,

  Error = "#de1818",
  TourBase = "#7ec8d6",
  TourHoverBgColor = "#9edae5"
}
