<template>
  <span class="tagNew">NEW</span>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TagNew extends Vue {}
</script>

<style lang="scss" scoped>
.tagNew {
  display: inline-block;
  text-align: center;
  width: 40px;
  height: 16px;
  line-height: 16px;
  background-color: $colorNewIcon;
  color: $colorWhite;
  font-size: 11px;
}
</style>
