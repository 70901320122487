<template>
  <span class="wrapper">
    <span class="inner">
      <Icon class="icon" :icon="icon" :size="iconSize" />
      <span class="title" :style="{ fontSize }">{{ title }}</span>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";

@Component({
  components: { Icon }
})
export default class TitleTextWithIcon extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: true })
  icon!: Icons;

  @Prop({ type: String, default: "16px" })
  fontSize!: string;

  @Prop({ type: Number, default: 15 })
  iconSize!: number;
}
</script>

<style scoped lang="scss">
.wrapper {
  display: inline-block;
}

.inner {
  display: flex;
  align-items: center;
  width: auto;
}

.icon {
  padding-bottom: 1px;
  display: block;
}

.title {
  padding-left: 10px;
  font-weight: bold;
}
</style>
