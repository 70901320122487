import { MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/store/";
import { ApiRes } from "@/api/api-res";
import { UgUser, LoginUser } from "@/models/auth/UgUser";
import { i18n } from "@/i18n";

export class AuthState {
  user: LoginUser | null = null;
  isInitialized: boolean = false;
  isAuthenticated: boolean = false;
}

const mutations: MutationTree<AuthState> = {
  setUser(state: AuthState, user: LoginUser) {
    state.user = user;
    if (user !== null) {
      i18n.locale = user.language;
    }
  },
  setIsInitialized(state: AuthState, isInitialized: boolean) {
    state.isInitialized = isInitialized;
  },
  setIsAuthenticated(state: AuthState, isAuthenticated: boolean) {
    state.isAuthenticated = isAuthenticated;
  }
};

const actions: ActionTree<AuthState, RootState> = {
  async initialize({ commit, rootState }) {
    let result: ApiRes.GetLoginUser;
    let user: LoginUser;
    try {
      await rootState.api.auth.getToken();
      result = await rootState.api.auth.getLoginUser();
      user = LoginUser.fromJson(result.user);
    } catch (e) {
      commit("setIsAuthenticated", false);
      commit("setIsInitialized", true);
      return;
    }
    if (!result.success) {
      commit("setIsAuthenticated", false);
      commit("setIsInitialized", true);
      return;
    }

    commit("setUser", user);
    commit("setIsAuthenticated", true);
    commit("setIsInitialized", true);
  },
  async login({ commit, rootState }, data: { id: string; password: string }) {
    let result: ApiRes.Login;
    let user: UgUser;
    try {
      result = await rootState.api.auth.login(data.id, data.password);
      user = LoginUser.fromJson(result.user);
      // CSRFToken is rotated after login, so get valid token from endpoint.
      await rootState.api.auth.getToken();
    } catch (e) {
      commit("setIsAuthenticated", false);
      throw e;
    }
    if (result.success) {
      commit("setUser", user);
      commit("setIsAuthenticated", true);
    } else {
      commit("setIsAuthenticated", false);
    }
  },
  async logout({ commit, rootState }) {
    await rootState.api.auth.logout();
    commit("setUser", null);
    commit("setIsAuthenticated", false);
    commit("setIsInitialized", false);
    commit("app/setIsInitialized", false, { root: true });
    commit("app/setIsSettingsInitialized", false, { root: true });
    commit("client/initialize", null, { root: true });
    commit("alert/initialize", null, { root: true });
    commit("clientSettings/initialize", null, { root: true });
    commit("companyActivityDisplaySetting/initialize", null, {
      root: true
    });
    commit("favorite/initialize", null, { root: true });
    commit("preference/initialize", null, { root: true });
    commit("search/initializeAll", null, { root: true });
    commit("searchForm/initialize", null, { root: true });
    commit("system/initialize", null, { root: true });
    commit("user/initialize", null, { root: true });
    commit("visit/initialize", null, { root: true });
    commit("wordcloud/resetWordcloudData", null, { root: true });
  }
};

const getters = <GetterTree<AuthState, RootState>>{
  isMfaRequired(state): boolean {
    return !!state.user && state.user.mfa.required;
  },
  isMfaEnabled(state): boolean {
    return !!state.user && state.user.mfa.enabled;
  }
};

export const auth = {
  namespaced: true,
  state: new AuthState(),
  mutations,
  actions,
  getters
};
