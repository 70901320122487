<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filterConditionForm">
    <div class="filterConditionForm__dragArea">
      <FilterPeriod
        :period-days="filterCondition.periodDays"
        class="filterConditionForm__filterPeriod"
        @input="onFilterPeriod"
      />
      <FilterDevice
        :values="filterCondition.deviceTypes"
        class="filterConditionForm__filterDevice"
        @input="onFilterDevice"
      />

      <FilterConditionFormDropArea
        v-if="filterCondition.filterNodes.length === 0"
        height="400px"
        class="filterConditionForm__emptyDragArea"
        @drop="handleDrop(0, draggingNodeType)"
      >
        {{ $t("userActivity") }}
        <br />
        {{ $te("dragHere") ? $t("dragHere") : "" }}
        <br />
        {{ $te("dragHere") ? $t("upToFive") : "" }}
        <img
          class="tips-drag-node"
          src="@/assets/img/home/tips-drag-nodes.gif"
        />
      </FilterConditionFormDropArea>

      <div class="filterConditionForm__activeDragArea">
        <FilterConditionFormDropArea
          v-if="
            getNodeDragAreaVisibility(
              filterCondition.filterNodes.length,
              0,
              isDragging,
              true
            )
          "
          class="filterConditionForm__topDragArea"
          @drop="handleDrop(0, draggingNodeType)"
        >
          <div>
            <span v-html="$t('userActivity_html')" />{{
              $te("dragHere") ? $t("dragHere") : ""
            }}
          </div>
        </FilterConditionFormDropArea>

        <div
          v-for="(filterNode, index) in filterCondition.filterNodes"
          :key="index"
          class="filterConditionForm__dragAreaNode"
        >
          <FilterNodeFactory
            v-if="
              !isNodeExcluded(index) ||
                index === 0 ||
                index === filterCondition.filterNodes.length - 1
            "
            :filter-node="filterNode"
            :index="index"
            :input-handler="node => onUpdateNode(node, index)"
            :is-dragging="isDragging"
            :exclusion="
              getExclusionProperties(filterCondition.filterNodes, index)
            "
            @add-or-node="handleDrop(index, $event, 'orNode')"
            @remove-node="handleNodeRemoval(index, 0, 0)"
            @remove-child-node="handleNodeRemoval(index, $event, 1)"
          />
          <FilterNodeInterElement
            v-if="filterNode.edge"
            :edge="filterNode.edge"
            :index="index"
            :is-exclusion-checkbox-enabled="
              indexOfEnableExcludeCondition === index
            "
            @update-edge="onUpdateEdge(index, $event)"
            @add-exclusion-node="handleDrop(index + 1, $event, 'excludeNode')"
            @remove-exclusion-node="handleNodeRemoval(index + 1, 0, 0)"
          >
            <template v-if="isNodeExcluded(index + 1)" #middleExclusionNode>
              <FilterNodeFactory
                :filter-node="filterCondition.filterNodes[index + 1]"
                :index="index + 1"
                :input-handler="node => onUpdateNode(node, index + 1)"
                :is-dragging="isDragging"
                :exclusion="
                  getExclusionProperties(filterCondition.filterNodes, index + 1)
                "
                @add-or-node="handleDrop(index + 1, $event, 'orNode')"
                @remove-node="handleNodeRemoval(index + 1, 0, 0)"
                @remove-child-node="handleNodeRemoval(index + 1, $event, 1)"
              />
            </template>
          </FilterNodeInterElement>

          <FilterConditionFormDropArea
            v-if="
              getNodeDragAreaVisibility(
                filterCondition.filterNodes.length,
                index,
                isDragging
              ) && !isNodeExcluded(index + 1)
            "
            class="filterConditionForm__bottomDragArea"
            :class="{
              'filterConditionForm__bottomDragArea--afterMiddleExclusion': isAfterMiddleExclusion(
                index
              ),
              'filterConditionForm__bottomDragArea--last':
                filterCondition.filterNodes.length - 1 === index
            }"
            @drop="handleDrop(index + 1, draggingNodeType)"
          >
            <div>
              <span v-html="$t('userActivity_html')" />{{
                $te("dragHere") ? $t("dragHere") : ""
              }}
            </div>
          </FilterConditionFormDropArea>
        </div>
      </div>
    </div>

    <div
      class="filterConditionForm__typeContainer customScrollbar"
      data-cy="filter-condition-form__type-list"
    >
      <template v-for="(filter, index) in filterList">
        <TitleTextWithIcon
          v-if="isTitle(filter.type)"
          :key="index"
          :title="filter.title"
          :icon="iconConversion"
          class="filterConditionForm__name"
        />
        <div
          v-else
          :key="index"
          draggable="true"
          data-cy="filter-condition-form__type-list-item"
          class="filterConditionForm__type"
          :class="{
            'filterConditionForm__type--dragging':
              draggingNodeType === filter.type
          }"
          @dragstart="onDragStart($event.dataTransfer, filter.type)"
          @dragend="onDragEnd"
        >
          {{ filter.title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import {
  FilterNodeType,
  FilterNode
} from "@/models/search/filter-node/FilterNode";
import {
  FilterCondition,
  updateFilterNodes
} from "@/models/search/filter-condition/FilterCondition";
import { FilterEdge } from "@/models/search/filter-node/FilterEdge";
import { ExclusionProps } from "@/models/search/filter-node/FilterExclusion";

import FilterConditionFormDropArea from "@/components/filter/FilterConditionFormDropArea.vue";
import FilterNodeFactory from "@/components/filter/FilterNodeFactory.vue";
import FilterNodeInterElement from "@/components/filter/FilterNodeInterElement.vue";
import FilterDevice from "@/components/filter/FilterDevice.vue";
import FilterPeriod from "@/components/filter/FilterPeriod.vue";

import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import { Icons } from "@/const/Icons";
import { i18n } from "@/i18n";
import { Browser } from "@/const/UserAgent";
import {
  DropType,
  createExclusionProperties,
  updateNodesWithNewDroppedNode
} from "@/util/filter-util";
import { getBrowser } from "@/util/user-agent-util";

import { DeviceType, FilterPeriodDays } from "@/const/filter";

const TYPE_TITLE = -1;

@Component({
  components: {
    FilterConditionFormDropArea,
    FilterNodeFactory,
    FilterNodeInterElement,
    TitleTextWithIcon,
    FilterDevice,
    FilterPeriod
  }
})
export default class FilterConditionForm extends Vue {
  businessEventDefinitions: BusinessEventDefinition[] = this.$store.state
    .clientSettings.activeBusinessEventDefinitions;
  iconConversion = Icons.Conversion;

  nodeType = FilterNodeType;

  @Model("input", { type: FilterCondition, required: true })
  filterCondition!: FilterCondition;

  onInput(filterCondition: FilterCondition) {
    this.$emit("input", filterCondition);
  }

  get filterNodes() {
    return this.filterCondition.filterNodes;
  }

  get draggingNodeType(): FilterNodeType | null {
    return this.$store.state.filter.draggingNodeType;
  }

  get filterList(): { title: string; type: number }[] {
    const list: { title: string; type: number }[] = [];

    // Web契約あり
    if (this.canUseWebdataFeatures) {
      // Web以外も表示するならタイトルをつける
      if (this.isContractApp || this.hasImport) {
        list.push({ title: "Web", type: TYPE_TITLE });
      }
      list.push({
        title: i18n.t("components.search.filter.nodeTitleInflow") as string,
        type: FilterNodeType.Inflow
      });
      list.push({
        title: i18n.t("components.search.filter.nodeTitleBrowseSite") as string,
        type: FilterNodeType.BrowseSite
      });
    }
    // App契約あり
    if (this.isContractApp) {
      // App以外も表示するならタイトルをつける
      if (this.canUseWebdataFeatures || this.hasImport) {
        list.push({ title: "App", type: TYPE_TITLE });
      }
      list.push({
        title: i18n.t("components.search.filter.nodeTitleLaunchApp") as string,
        type: FilterNodeType.LaunchApp
      });
      list.push({
        title: i18n.t("components.search.filter.nodeTitleBrowseApp") as string,
        type: FilterNodeType.BrowseApp
      });
    }
    // WebもAppも契約ありなら共通タイトルを表示
    if (this.canUseWebdataFeatures && this.isContractApp) {
      list.push({
        title: i18n.t(
          "components.search.filter.nodeTitleWebAppCommon"
        ) as string,
        type: TYPE_TITLE
      });
    }
    if (this.hasEvent) {
      list.push({
        title: i18n.t("components.search.filter.nodeTitleEvent") as string,
        type: FilterNodeType.Event
      });
    }
    list.push({
      title: i18n.t("components.search.filter.nodeTitleConverison") as string,
      type: FilterNodeType.Conversion
    });
    // インボートデータあり
    if (this.hasImport) {
      list.push({
        title: i18n.t("components.search.filter.nodeTitleImportData") as string,
        type: TYPE_TITLE
      });
      if (this.hasContact) {
        list.push({
          title: i18n.t("components.search.filter.nodeTitleContact") as string,
          type: FilterNodeType.Contact
        });
      }
      if (this.hasBusinessEvent) {
        list.push({
          title: i18n.t(
            "components.search.filter.nodeTitleBusinessEvent"
          ) as string,
          type: FilterNodeType.BusinessEvent
        });
      }
    }
    return list;
  }

  get hasEvent(): boolean {
    return this.$store.state.clientSettings.activeEventDefinitions.length > 0;
  }

  get hasImport(): boolean {
    return this.hasContact || this.hasBusinessEvent;
  }

  get hasContact(): boolean {
    return this.$store.state.clientSettings.activeContactDefinitions.length > 0;
  }

  get hasBusinessEvent(): boolean {
    return this.businessEventDefinitions.length > 0;
  }

  get canUseWebdataFeatures(): boolean {
    return this.$store.state.app.canUseWebdataFeatures;
  }

  get isContractApp(): boolean {
    return this.$store.state.client.client.isContractApp;
  }

  get isDragging(): boolean {
    return this.$store.state.filter.isDragging;
  }

  get indexOfEnableExcludeCondition(): number | null {
    return this.$store.state.filter.indexOfEnableExcludeCondition;
  }

  isNodeExcluded(index: number): boolean {
    if (index === this.filterNodes.length - 1) return false;
    const filterNode = this.filterNodes[index];
    return !!filterNode?.isExcluded;
  }

  getExclusionProperties(nodes: FilterNode[], index: number): ExclusionProps {
    return createExclusionProperties(nodes, index);
  }

  getNodeDragAreaVisibility(
    nodes: number,
    index: number,
    isDragging: boolean,
    isStartingDropArea: boolean = false
  ): boolean {
    // If there are 5 nodes already, hide last drop area
    if (nodes === 5 && nodes - 1 === index) return false;
    // First and middle drag areas should show up only when dragging
    if (nodes !== 0 && isDragging) return true;
    // Drag area always shows on last node
    if (nodes !== 0 && nodes - 1 === index && !isStartingDropArea) return true;

    return false;
  }

  handleDrop(
    index: number,
    nodeType: FilterNodeType | null,
    dropType: DropType = "addNode"
  ) {
    if (nodeType === null) return;

    const newNodes = updateNodesWithNewDroppedNode(
      this.filterNodes,
      index,
      nodeType,
      dropType
    );

    if (newNodes) {
      this.onInput(
        new FilterCondition(
          updateFilterNodes(newNodes),
          this.filterCondition.deviceTypes,
          this.filterCondition.periodDays
        )
      );
    }
  }

  handleNodeRemoval(index: number, childIndex: number, depth: number) {
    const nodeToRemoveProps = { childIndex, depth, index };
    this.$store.commit("filter/removeNode", nodeToRemoveProps);
  }

  isTitle(type: number): boolean {
    return type === TYPE_TITLE;
  }

  isAfterMiddleExclusion(index: number): boolean {
    // When exclusion checkbox is on and there is a middle exclusion node
    if (this.filterCondition.hasMiddleExclusionNode) {
      return index === this.filterCondition.exclusionNodeIndex;
    }

    // When only exclusion checkbox is on
    if (!this.filterCondition.hasExclusionNode) {
      return index === this.indexOfEnableExcludeCondition;
    }

    return false;
  }

  onDragStart(dataTransfer: DataTransfer, nodeType: FilterNodeType) {
    // Necessary for drag and drop on Firefox
    if (getBrowser() === Browser.Firefox) {
      dataTransfer.setData("Number", nodeType.toString());
    }

    this.$store.commit("filter/setDraggingNodeType", nodeType);
    this.$store.commit("filter/toggleDragging");
  }

  onDragEnd() {
    this.$store.commit("filter/setDraggingNodeType", null);
    this.$store.commit("filter/toggleDragging");
  }

  onUpdateEdge(index: number, edge: FilterEdge) {
    const node = this.filterNodes[index];
    // TODO: Make it immutable
    node.edge = edge;
    this.onUpdateNode(node, index);
  }

  onUpdateNode(node: FilterNode, index: number) {
    const nodes = this.filterNodes;

    if (node.depth === 0) {
      nodes[index] = node;
    } else if (nodes[index].hasOwnProperty("orActivityNodes")) {
      // Update the corresponding node inside the parent or node
      // @ts-ignore
      nodes[index].orActivityNodes[node.childIndex] = node;
    }

    this.onInput(
      new FilterCondition(
        updateFilterNodes(nodes),
        this.filterCondition.deviceTypes,
        this.filterCondition.periodDays
      )
    );
  }

  onFilterDevice(deviceTypes: DeviceType[]) {
    this.onInput(
      new FilterCondition(
        this.filterNodes,
        deviceTypes,
        this.filterCondition.periodDays
      )
    );
  }

  onFilterPeriod(periodDays: FilterPeriodDays) {
    this.onInput(
      new FilterCondition(
        this.filterNodes,
        this.filterCondition.deviceTypes,
        periodDays
      )
    );
  }
}
</script>

<style lang="scss" scoped>
.filterConditionForm__filterDevice {
  margin: 13px 0 10px 0;
}

.filterConditionForm__dragArea {
  position: relative;
  width: 900px;
}

.filterConditionForm__dragAreaNode {
  position: relative;
}

.filterConditionForm__emptyDragArea {
  font-size: 20px;
  justify-content: flex-start;
  padding-top: 20px;
}

.filterConditionForm__activeDragArea {
  position: relative;
}

.filterConditionForm__topDragArea {
  position: absolute;
  top: -60px;
}

.filterConditionForm__bottomDragArea {
  position: absolute;
  bottom: 14px;
}

.filterConditionForm__bottomDragArea--afterMiddleExclusion {
  position: relative;
  margin-top: 10px;
}

.filterConditionForm__bottomDragArea--last {
  bottom: -60px;
}

.filterConditionForm__typeContainer {
  position: absolute;
  top: 96px;
  left: 948px;
  overflow-y: auto;
  padding-right: 10px;
  width: 200px;
  height: calc(100% - 200px);

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $colorClear;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $colorScrollbar;
  }
}

.filterConditionForm__name {
  margin: 10px 0 16px 0;

  &:first-child {
    margin-top: 0;
  }
}

.filterConditionForm__type {
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 10px;
  width: 100%;
  height: 40px;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorBase900;
  /* drag中角丸にするための対応 */
  transform: translate(0, 0);
}

.filterConditionForm__type--dragging {
  opacity: 0.5;
}

.tips-drag-node {
  margin-top: 30px;
}
</style>
