<template>
  <div class="analysisToolsPagerNav">
    <div class="analysisToolsPagerNav__prevButton">
      <IconButton
        data-cy="analysisToolsPagerNav__prevButton"
        :icon="icons.ArrowLeft"
        :size="32"
        :icon-scale="0.625"
        :button-type="iconButtonType"
        :background="true"
        :disabled="currentPage <= 1"
        @click="onClick(-1)"
      />
    </div>
    <div class="analysisToolsPagerNav__page">{{ currentPage }}</div>
    <div class="analysisToolsPagerNav__break">/</div>
    <div class="analysisToolsPagerNav__lastPage">{{ lastPage }}</div>
    <div class="analysisToolsPagerNav__nextButton">
      <IconButton
        data-cy="analysisToolsPagerNav__nextButton"
        :icon="icons.ArrowRight"
        :size="32"
        :icon-scale="0.625"
        :button-type="iconButtonType"
        :background="true"
        :disabled="currentPage >= lastPage"
        @click="onClick(1)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {
    IconButton
  }
})
export default class AnalysisToolsPagerNav extends Vue {
  @Prop({ type: Number, required: true })
  currentPage!: number;

  @Prop({ type: Number, required: true })
  lastPage!: number;

  onClick(move: number) {
    this.$emit("click", move);
  }

  icons = Icons;
  iconButtonType = IconButtonType.Weak;
}
</script>

<style scoped lang="scss">
.analysisToolsPagerNav {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.analysisToolsPagerNav__prevButton {
  margin-right: 7px;
}

.analysisToolsPagerNav__page {
  font-size: 14px;
}

.analysisToolsPagerNav__break {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 14px;
}

.analysisToolsPagerNav__lastPage {
  font-weight: bold;
  font-size: 14px;
}

.analysisToolsPagerNav__nextButton {
  margin-left: 7px;
}
</style>
