import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import {
  InputFormSuggestionStatus,
  InputFormSuggestionType
} from "@/const/input-form-suggestion";

export interface SuggestionResultResponse {
  status: InputFormSuggestionStatus;
  result?: string[];
}

export default class InputFormSuggestion {
  constructor(private readonly httpClient: HttpClient) {}

  public prepare(): Promise<void> {
    const url: string = ApiUrl.SUGGESTION_PREPARE;
    const params = {};

    return this.httpClient.post<void>(url, params);
  }

  public getResult(
    type: InputFormSuggestionType,
    input_str: string
  ): Promise<SuggestionResultResponse> {
    const url: string = ApiUrl.SUGGESTION_GET_RESULT;
    const params = { input_str, type };

    return this.httpClient.get<SuggestionResultResponse>(url, params);
  }
}
