<template>
  <canvas ref="canvas" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import QRCode from "qrcode";

@Component
export default class Loading extends Vue {
  @Prop({ type: Number, default: 155 })
  size!: number;

  @Prop({ type: String, required: true })
  uri!: string;

  @Watch("uri")
  updateQRcode() {
    this.generateQRCode();
  }

  generateQRCode() {
    window.setTimeout(() => {
      QRCode.toCanvas(this.$refs.canvas, this.uri, {
        width: this.size,
        margin: 2.7
      });
    }, 10);
  }

  async mounted() {
    this.generateQRCode();
  }
}
</script>
