import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";

export default class ApiClientPv {
  constructor(private readonly httpClient: HttpClient) {}

  public getClientPvStats = (): Promise<ApiRes.ClientPvStats> => {
    return this.httpClient.get<ApiRes.ClientPvStats>(ApiUrl.CLIENT_PV_STATS);
  };
}
