<template>
  <div
    ref="chart"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: false
    }"
    class="chartBase"
    :style="style"
  >
    <ScrollWrapper
      class="chartBase__scrollWrapper"
      :overflow="overflow"
      :arrow-show="true"
      :destination-x="scrollDestinationX"
      @update:scroll-x="updateScrollX"
      @update:scroll-width="updateScrollWidth"
      @update:is-scrollable="updateIsScrollable"
    >
      <div class="chartBase__overview" :style="style">
        <slot
          :props="{
            scrollX,
            scrollWidth,
            isVisible,
            isScrollable
          }"
        />

        <div id="canvas">
          <svg
            id="paper"
            :width="paperWidth"
            :height="paperHeight"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs"
          >
            <ChartHighlight
              v-if="isUserDetail"
              :width="barWidth"
              :height="paperHeight"
              :x="highlightX"
            />

            <g v-if="coloredPeriods.length > 0" id="coloredPeriods">
              <ChartColoredPeriod
                v-for="(coloredPeriod, i) in coloredPeriods"
                :key="'coloredPeriod' + i"
                :width="getChartColoredPeriodWidth(coloredPeriod)"
                :height="baselineY"
                :x="getChartUnitX(coloredPeriod.startDate)"
                :fill="coloredPeriod.color"
                :stroke="coloredPeriod.strokeColor"
                :data-cy="[
                  coloredPeriod.color === colors.Blue730
                    ? 'colored-period-base'
                    : 'colored-period-comp'
                ]"
                @mouseenter="
                  onMouseEnterColoredPeriod(
                    getChartColoredPeriodMousePositionX($event),
                    coloredPeriod
                  )
                "
                @mouseleave="onMouseLeaveColoredPeriod"
              />
            </g>

            <ChartGridLine
              :y="baselineY"
              :baseline-length="baselineLength"
              :first-scale-date="firstScaleDate"
              :last-scale-date="lastScaleDate"
              :is-one-month="isOneMonth"
              :is-six-month="isSixMonth"
            />

            <ChartVisitBars
              v-if="isVisible"
              :visit-overviews="visitOverviews"
              :filter-matched-visit-ids="filterMatchedVisitIds"
              :almost-matched-visit-ids="almostMatchedVisitIds"
              :funnel-matched-gram-ids="funnelMatchedGramIds"
              :funnel-matched-selected-gram-ids="funnelMatchedSelectedGramIds"
              :baseline-y="baselineY"
              :baseline-length="baselineLength"
              :first-scale-date="firstScaleDate"
              :last-scale-date="lastScaleDate"
              :last-scale-x="lastScaleX"
              :width="barWidth"
              :show-stroke="!isTwoYear"
              :selected-conversion-ids="selectedConversionIds"
              :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
              :scroll-x="scrollX"
              :scroll-width="scrollWidth"
              :is-chart-scrollable="isScrollable"
              :is-in-user-detail="isUserDetail"
              @click="onClick"
              @mouseenter="onMouseEnterOverview"
              @mouseleave="onMouseLeaveOverview"
            />

            <ChartOmoIcons
              v-if="isOmo"
              :nps-overviews="npsOverviews"
              :enquete-overviews="enqueteOverviews"
              :baseline-length="baselineLength"
              :first-scale-date="firstScaleDate"
              :last-scale-date="lastScaleDate"
              :last-scale-x="lastScaleX"
              :is-year="isYear"
              @click="onClick"
              @mouseenter="onMouseEnterOverview"
              @mouseleave="onMouseLeaveOverview"
            />

            <path :d="baselinePath" class="chartBase__baseline" />

            <path
              v-if="showPeriodLinePath"
              :d="periodLinePath"
              class="chartBase__periodLine"
            />
          </svg>
        </div>
      </div>
    </ScrollWrapper>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";

import { Msec } from "@/util/date-util";

import { ChartPeriod } from "@/const/chart-period";
import { Colors } from "@/const/Colors";

import {
  SCROLLBAR_CHART_SPACE,
  START_LINE_X,
  BASELINE_EDGE_LENGTH,
  SIX_MONTH_BASELINE_EDGE_LENGTH,
  DAYS_IN_SHOWN_BASELINE_LENHTH,
  convertTimeToX,
  getXAdjustWidth,
  getDiffDays,
  getBaselineY,
  getPaperHeight,
  getBarWidth,
  getMaxUnixtimeInOverviews,
  getMinUnixtimeInOverviews,
  getNextMonthFirst
} from "@/components/chart/chart-util";
import ScrollWrapper, {
  Overflow,
  ARROW_SPACE
} from "@/components/ScrollWrapper.vue";
import ChartHighlight from "@/components/chart/ChartHighlight.vue";
import ChartColoredPeriod from "@/components/chart/ChartColoredPeriod.vue";
import ChartGridLine from "@/components/chart/ChartGridLine.vue";
import ChartVisitBars from "@/components/chart/ChartVisitBars.vue";
import ChartOmoIcons from "@/components/chart/ChartOmoIcons.vue";

@Component({
  components: {
    ScrollWrapper,
    ChartHighlight,
    ChartColoredPeriod,
    ChartGridLine,
    ChartVisitBars,
    ChartOmoIcons
  }
})
export default class ChartBase extends Vue {
  @Prop({ type: Date, required: true })
  endDate!: Date;

  @Prop({ type: Number, required: true })
  chartPeriod!: ChartPeriod;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: Number, default: 0 })
  omoMinActionUnixtime!: Msec;

  @Prop({ type: Number, default: 0 })
  omoMaxActionUnixtime!: Msec;

  @Prop({ type: Array, required: true })
  visitOverviews!: VisitOverview[];

  @Prop({ type: Array, required: true })
  npsOverviews!: NpsOverview[];

  @Prop({ type: Array, required: true })
  enqueteOverviews!: EnqueteOverview[];

  @Prop({ type: Array, required: true })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Number, required: true })
  baselineLength!: number;

  @Prop({ type: Date, required: true })
  firstScaleDate!: Date;

  @Prop({ type: Date, required: true })
  lastScaleDate!: Date;

  @Prop({ type: Number, required: true })
  lastScaleX!: number;

  @Prop({ type: Number, default: null })
  highlightMsec!: Msec;

  @Prop({ type: Boolean, default: true })
  isUserDetail!: boolean;

  @Prop({ type: Array, required: true })
  filterMatchedVisitIds!: string[];

  @Prop({ type: Array, required: true })
  almostMatchedVisitIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  @Prop({ type: Array, required: true })
  selectedConversionIds!: number[];

  @Prop({ type: Boolean, required: true })
  isToggleTimeOfDayEnabled!: boolean;

  onClick(overview: VisitOverview | NpsOverview | EnqueteOverview) {
    this.$emit("click", overview);
  }

  onMouseEnterOverview(
    overview: VisitOverview | NpsOverview | EnqueteOverview
  ) {
    this.$emit("mouseenter-overview", overview);
  }

  @Emit("mouseleave-overview")
  onMouseLeaveOverview() {}

  onMouseEnterColoredPeriod(mouseX: number, coloredPeriod: ColoredPeriod) {
    this.$emit("mouseenter-colored-period", mouseX, coloredPeriod);
  }

  @Emit("mouseleave-colored-period")
  onMouseLeaveColoredPeriod() {}

  @Watch("highlightMsec")
  onHighlightMsecChanged() {
    this.scrollDestinationX = this.getHighlightScrollDestinationX();
  }

  overflow = Overflow.ScrollXOnlyEnabled;
  colors = Colors;

  scrollDestinationX: number = 0;
  scrollX: number | null = null;
  scrollWidth: number | null = null;
  isVisible: boolean = false;
  isScrollable: boolean = true;

  get chart(): Element {
    return this.$refs.chart as HTMLElement;
  }

  get style() {
    return { height: this.paperHeight + SCROLLBAR_CHART_SPACE + "px" };
  }

  get paperWidth(): number {
    if (this.isSixMonth) {
      return (
        SIX_MONTH_BASELINE_EDGE_LENGTH * 2 + this.baselineLength + START_LINE_X
      );
    }
    return BASELINE_EDGE_LENGTH * 2 + this.baselineLength + START_LINE_X;
  }

  get paperHeight(): number {
    return getPaperHeight(this.isOmo);
  }

  get barWidth(): number {
    return getBarWidth(
      this.chartPeriod === ChartPeriod.OneYear ||
        this.chartPeriod === ChartPeriod.TwoYears
    );
  }

  get baselinePath(): string {
    return `M ${START_LINE_X} ${this.baselineY} H ${this.paperWidth}`;
  }

  get periodLinePath(): string {
    const startX: number = getXAdjustWidth(
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        this.minActionUnixtime
      ),
      this.barWidth
    );

    const endX: number =
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        this.maxActionUnixtime
      ) +
      this.barWidth / 2;

    return `M ${startX} ${this.baselineY} H ${endX}`;
  }

  get showPeriodLinePath(): boolean {
    return this.periodLinePath.indexOf("Infinity") === -1;
  }

  get maxActionUnixtime(): Msec {
    const visitMax: Msec = getMaxUnixtimeInOverviews(this.visitOverviews);

    if (this.isOmo) {
      // these args of Math.max() contain more than 1 number except -Infinity.
      return Math.max(visitMax, this.omoMaxActionUnixtime);
    }

    return visitMax;
  }

  get minActionUnixtime(): Msec {
    const visitMin: Msec = getMinUnixtimeInOverviews(this.visitOverviews);

    if (this.isOmo) {
      // these args of Math.min() contain more than 1 number except Infinity.
      return Math.min(visitMin, this.omoMinActionUnixtime);
    }

    return visitMin;
  }

  get highlightX(): number {
    return getXAdjustWidth(
      convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        this.highlightMsec
      ),
      this.barWidth
    );
  }

  get isOneMonth(): boolean {
    return this.chartPeriod === ChartPeriod.OneMonth;
  }

  get isSixMonth(): boolean {
    return this.chartPeriod === ChartPeriod.SixMonths;
  }

  get isTwoYear(): boolean {
    return this.chartPeriod === ChartPeriod.TwoYears;
  }

  get isYear(): boolean {
    return (
      this.chartPeriod === ChartPeriod.OneYear ||
      this.chartPeriod === ChartPeriod.TwoYears
    );
  }

  get daysInPeriod(): number {
    return getDiffDays(this.firstScaleDate, this.lastScaleDate);
  }

  get baselineY(): number {
    return getBaselineY(this.isOmo);
  }

  get baselineWidth(): number {
    return -START_LINE_X;
  }

  /**
   * if chartPeriod is OneMonth, return x where 7 days after endDate shows on right edge in scalebar.
   * else return x where next month of endDate shows on right edge in scalebar.
   */
  get firstScrollX(): number {
    const nextMonthFirstOfEndDate: Date = getNextMonthFirst(this.endDate);
    let leftEdgeDate: Date = new Date(
      nextMonthFirstOfEndDate.getFullYear(),
      nextMonthFirstOfEndDate.getMonth() - this.chartPeriod,
      1,
      0,
      0,
      0
    );

    if (this.isOneMonth) {
      leftEdgeDate = new Date(
        nextMonthFirstOfEndDate.getFullYear(),
        nextMonthFirstOfEndDate.getMonth(),
        nextMonthFirstOfEndDate.getDate() - DAYS_IN_SHOWN_BASELINE_LENHTH + 6,
        0,
        0,
        0
      );
    }

    if (this.funnelMatchedSelectedGramIds[0]) {
      const selectedGram = this.visitOverviews.find(
        overview => overview.gramId === this.funnelMatchedSelectedGramIds[0]
      );
      if (selectedGram)
        return this.getCenteredScrollDestinationX(
          convertTimeToX(
            this.firstScaleDate,
            this.lastScaleDate,
            this.lastScaleX,
            this.baselineLength,
            selectedGram.date.getTime()
          )
        );
    }

    return convertTimeToX(
      this.firstScaleDate,
      this.lastScaleDate,
      this.lastScaleX,
      this.baselineLength,
      leftEdgeDate.getTime()
    );
  }

  mounted() {
    if (!this.isUserDetail) {
      this.scrollDestinationX = this.firstScrollX;
    }
  }

  getHighlightScrollDestinationX(): number {
    return this.getCenteredScrollDestinationX(this.highlightX);
  }

  getCenteredScrollDestinationX(leftEdgeDestinationX: number) {
    const x: number =
      leftEdgeDestinationX - (this.chart.clientWidth - ARROW_SPACE * 2) / 2;
    return x < 0 ? 0 : x;
  }

  getChartUnitX(date: Date): number {
    return convertTimeToX(
      this.firstScaleDate,
      this.lastScaleDate,
      this.lastScaleX,
      this.baselineLength,
      date.getTime()
    );
  }

  getChartColoredPeriodWidth(coloredPeriod: ColoredPeriod): number {
    const startX: number = this.getChartUnitX(coloredPeriod.startDate);
    const endX: number = this.getChartUnitX(coloredPeriod.endDate);
    return endX - startX;
  }

  getChartColoredPeriodMousePositionX(event: MouseEvent): number {
    const chartX = -this.chart.getBoundingClientRect().left;
    return event.clientX + chartX;
  }

  updateScrollX(scrollX: number) {
    this.scrollX = scrollX;
  }

  updateScrollWidth(scrollWidth: number) {
    this.scrollWidth = scrollWidth;
  }

  updateIsScrollable(isScrollable: boolean) {
    this.isScrollable = isScrollable;
  }

  visibilityChanged(isVisible: boolean) {
    this.isVisible = isVisible;
  }
}
</script>

<style scoped lang="scss">
.chartBase {
  position: relative;
  width: 100%;
}

.chartBase__scrollWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.chartBase__baseline {
  stroke: $colorBase700;
  stroke-width: 1;
}

.chartBase__periodLine {
  stroke: $colorBlue900;
  stroke-width: 2;
}
</style>
