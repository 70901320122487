var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"userTrendViewSwitch"},[_c('div',{staticClass:"userTrendViewSwitch__button",attrs:{"data-cy":"button-table-list"},on:{"click":function($event){return _vm.onClick(_vm.userTrendViewType.Table)}}},[_c('IconButton',{attrs:{"icon":_vm.icons.Table,"size":22,"selected":_vm.view === _vm.userTrendViewType.Table,"button-type":_vm.iconButtonType}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:('list'),expression:"'list'"}],staticClass:"userTrendViewSwitch__text",class:{
        'userTrendViewSwitch__text--selected':
          _vm.view === _vm.userTrendViewType.Table
      }})],1),_c('div',{staticClass:"userTrendViewSwitch__button",attrs:{"data-cy":"button-scatter"},on:{"click":function($event){return _vm.onClick(_vm.userTrendViewType.Scatter)}}},[_c('IconButton',{attrs:{"icon":_vm.icons.Scatter,"size":22,"selected":_vm.view === _vm.userTrendViewType.Scatter,"button-type":_vm.iconButtonType}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:('cvContributionPage'),expression:"'cvContributionPage'"}],staticClass:"userTrendViewSwitch__text",class:{
        'userTrendViewSwitch__text--selected':
          _vm.view === _vm.userTrendViewType.Scatter
      }})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }