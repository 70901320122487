import { AdditionalSelectConditionInterface } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  AdditionalConditionParams,
  AttributeConditionParamType,
  AttributeType,
  ConversionAttributeNumberConditionParams,
  ConversionAttributeNumberValueConditionParams,
  NumberMatchMethod
} from "@/api/apis/ApiSearch";
import { ConversionAttributeNumberCondition } from "@/models/search/additional-condition/ConversionAttributeNumberCondition";
import { i18n } from "@/i18n";

export class ConversionAttributeNumberValueCondition
  extends ConversionAttributeNumberCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    id: number,
    numberMatchMethod: NumberMatchMethod,
    public readonly val: number
  ) {
    super(id, numberMatchMethod);
    this.val = val;
  }

  displayValue(): string {
    const value: string = String(this.val);
    let match: String = "";
    switch (this.numberMatchMethod) {
      case NumberMatchMethod.EXACT:
        match =
          "(" + (i18n.t("components.search.search.exactMatch") as string) + ")";
        break;
      case NumberMatchMethod.LESS_THAN_OR_EQUAL_TO:
        match =
          "(" +
          (i18n.t("components.search.search.lessThanOrEqualMatch") as string) +
          ")";
        break;
      case NumberMatchMethod.GREATER_THAN_OR_EQUAL_TO:
        match =
          "(" +
          (i18n.t(
            "components.search.search.greaterThanOrEqualMatch"
          ) as string) +
          ")";
        break;
    }
    return value + " " + match;
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertConversionAttributeNumberValueConditionToJson(
  condition: ConversionAttributeNumberValueCondition
): ConversionAttributeNumberValueConditionParams {
  return {
    type: AttributeConditionParamType.ConversionAttribute,
    id: condition.id,
    attributeType: AttributeType.NUMBER,
    numberMatchMethod: condition.numberMatchMethod,
    val: condition.val
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToConversionAttributeNumberValueCondition(
  json: ConversionAttributeNumberValueConditionParams,
  activeConversionAttributeDefinitions: ConversionAttributeDefinition[]
): ConversionAttributeNumberValueCondition | null {
  // 利用可能なidリストになければnull
  if (!activeConversionAttributeDefinitions.some(def => def.id === json.id)) {
    return null;
  }
  return new ConversionAttributeNumberValueCondition(
    json.id,
    json.numberMatchMethod,
    json.val
  );
}

export function isConversionAttributeNumberValueConditionParams(
  params: AdditionalConditionParams
): params is ConversionAttributeNumberValueConditionParams {
  return (
    (params as ConversionAttributeNumberConditionParams).numberMatchMethod !==
    NumberMatchMethod.BETWEEN
  );
}
