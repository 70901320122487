import { render, staticRenderFns } from "./LightUgTableRow.vue?vue&type=template&id=60d87bdb&scoped=true"
import script from "./LightUgTableRow.vue?vue&type=script&lang=ts"
export * from "./LightUgTableRow.vue?vue&type=script&lang=ts"
import style0 from "./LightUgTableRow.vue?vue&type=style&index=0&id=60d87bdb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d87bdb",
  null
  
)

export default component.exports