import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  GlobalConversionAttributeDefinition,
  GlobalConversionAttributeQuery,
  GlobalConversionAttributeQueryType,
  getGlobalConversionAttributeNameByQuery
} from "@/models/system/GlobalConversionAttributeDefinition";

/**
 * 絞り込み条件のアプリ起動に追加できる条件
 *
 * FireBaseから取得した情報で絞り込みできるようになる
 * Push通知 ラベル
 * リンク起動 campaign, source, medium, link-name, link-value
 *
 */
export class FilterLaunchAppAdditionalCondition {
  constructor(
    public readonly conditionType: FilterLaunchAppAdditionalConditionType,
    public readonly conversionAttributeId: number,
    public readonly value: string,
    // 文字列のマッチ条件（部分一致 or 完全一致）
    public readonly matchMethod: MatchMethod
  ) {}

  public static buildDefaultData(
    conditionType: FilterLaunchAppAdditionalConditionType,
    conversionAttributeId: number
  ): FilterLaunchAppAdditionalCondition {
    return new FilterLaunchAppAdditionalCondition(
      conditionType,
      conversionAttributeId,
      "",
      MatchMethod.Partial
    );
  }

  get validate(): ValidationResult {
    return filterInputCheck(this.value, conditionName(this.conditionType));
  }
}

export type FilterLaunchAppAdditionalConditionType =
  | FilterNodeConditionType.PushLabel
  | FilterNodeConditionType.LinkCampaign
  | FilterNodeConditionType.LinkSource
  | FilterNodeConditionType.LinkMedium
  | FilterNodeConditionType.LinkLinkName
  | FilterNodeConditionType.LinkLinkValue;

export function conditionName(
  conditionType: FilterLaunchAppAdditionalConditionType
): string {
  return getGlobalConversionAttributeNameByQuery(
    filterNodeConditionTypeToQuery(conditionType)
  );
}

export function globalConversionAttributeIdToFilterNodeConditionType(
  conversionAttributeId: number,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): FilterLaunchAppAdditionalConditionType {
  const def = globalConversionAttributeDefinitions.find(
    def => def.id === conversionAttributeId
  );

  // undefinedはありえないので、PushLabelリターンで可能性を潰しておく
  if (def === undefined) {
    return FilterNodeConditionType.PushLabel;
  }

  const query = def.query as GlobalConversionAttributeQuery;
  switch (query) {
    case GlobalConversionAttributeQueryType.LinkCampaign:
      return FilterNodeConditionType.LinkCampaign;
    case GlobalConversionAttributeQueryType.LinkSource:
      return FilterNodeConditionType.LinkSource;
    case GlobalConversionAttributeQueryType.LinkMedium:
      return FilterNodeConditionType.LinkMedium;
    case GlobalConversionAttributeQueryType.LinkLinkName:
      return FilterNodeConditionType.LinkLinkName;
    case GlobalConversionAttributeQueryType.LinkLinkValue:
      return FilterNodeConditionType.LinkLinkValue;
    case GlobalConversionAttributeQueryType.PushLabel:
      return FilterNodeConditionType.PushLabel;
  }
}

export function filterNodeConditionTypeToGlobalConversionAttributeIdId(
  type: FilterLaunchAppAdditionalConditionType,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[]
): number {
  const query: string = filterNodeConditionTypeToQuery(type);
  const def = globalConversionAttributeDefinitions.find(
    def => def.query === query
  );

  // undefinedはありえないので、undefinedの場合は0を返しておく
  if (def === undefined) {
    return 0;
  }

  return def.id;
}

function filterNodeConditionTypeToQuery(
  type: FilterLaunchAppAdditionalConditionType
): GlobalConversionAttributeQuery {
  switch (type) {
    case FilterNodeConditionType.LinkCampaign:
      return GlobalConversionAttributeQueryType.LinkCampaign;
    case FilterNodeConditionType.LinkSource:
      return GlobalConversionAttributeQueryType.LinkSource;
    case FilterNodeConditionType.LinkMedium:
      return GlobalConversionAttributeQueryType.LinkMedium;
    case FilterNodeConditionType.LinkLinkName:
      return GlobalConversionAttributeQueryType.LinkLinkName;
    case FilterNodeConditionType.LinkLinkValue:
      return GlobalConversionAttributeQueryType.LinkLinkValue;
    case FilterNodeConditionType.PushLabel:
      return GlobalConversionAttributeQueryType.PushLabel;
  }
}
