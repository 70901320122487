import {
  SelectCondition,
  convertJsonToSelectCondition
} from "@/models/search/select-condition/SelectCondition";
import {
  FilterCondition,
  convertJsonToFilterCondition
} from "@/models/search/filter-condition/FilterCondition";
import { UserInfo } from "@/models/UserInfo";
import { ApiRes } from "@/api/api-res";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";

export class SearchHistory {
  constructor(
    public readonly id: number,
    public readonly results: UserInfo[],
    public readonly count: number,
    public readonly updatedAt: Date,
    public readonly selectCondition: SelectCondition | null,
    public readonly filterCondition: FilterCondition | null
  ) {}
}

/**
 * お気に入りで検索条件をJSONから生成する際に、期間があるものに対して保存時の期間ではなく
 * デフォルト期間（30日前〜本日）をセットしたいので、その時にtrueを渡す。
 *
 * 将来的にデフォルト期間固定でなく、保存時に3ヶ月や1ヶ月を保存時の期間などを選べる&DBに保存するにして
 * 指定の期間で検索条件を生成するようにする
 */
export function buildSearchHistory(
  history: ApiRes.SelectionHistory,
  userInfoList: UserInfo[],
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[],
  activeMeasurementSites: MeasurementTargetSite[]
): SearchHistory | null {
  const userInfo = userInfoList.filter(
    info => history.results.indexOf(info.userId) > -1
  );

  const selectCondition: SelectCondition | null = convertJsonToSelectCondition(
    history.type,
    history.query,
    activeDefinitions,
    activeMeasurementSites
  );
  // 検索条件が作れない場合はnullを返す
  if (selectCondition === null) {
    return null;
  }

  let filterCondition: FilterCondition | null = null;
  if (history.filter_query !== null) {
    filterCondition = convertJsonToFilterCondition(
      history.filter_query,
      canUseWebdataFeatures,
      isContractApp,
      activeDefinitions,
      globalConversionAttributeDefinitions
    );
  }

  return new SearchHistory(
    history.id,
    userInfo,
    history.found_num,
    new Date(history.updated_time_msec),
    selectCondition,
    filterCondition
  );
}
