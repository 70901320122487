<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <div class="featuredUsersLagCv">
    <div v-if="isLagCvLoading" class="featuredUsersLagCv__loading">
      <Loading height="80px" />
    </div>
    <div v-else class="featuredUsersLagCv__content">
      <FeaturedUsersError
        v-if="isError"
        class="featuredUsersLagCv__error"
        @retry-fetch="emitRetryFetch"
      />
      <div v-else>
        <div v-if="hasConversion">
          <FeaturedUsersNote
            :description="$t('lagCvNote')"
            :url="HELP_PAGE_LINK"
          />
          <FeaturedUsersLagCvTable
            :lag-cv-user-counts="lagCvUserCounts"
            @select="emitSelect"
          />
        </div>
        <FeaturedUsersWarning
          v-else
          :title="$t('noConversionTitle')"
          :description="$t('noConversionContent')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import LagCvUserCount from "@/models/lag-cv/LagCvUserCount";
import FeaturedUsersNote from "@/components/search/FeaturedUsersNote.vue";
import FeaturedUsersLagCvTable from "@/components/search/FeaturedUsersLagCvTable.vue";
import FeaturedUsersWarning from "@/components/search/FeaturedUsersWarning.vue";
import FeaturedUsersError from "@/components/search/FeaturedUsersError.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    FeaturedUsersNote,
    FeaturedUsersLagCvTable,
    FeaturedUsersWarning,
    FeaturedUsersError,
    Loading
  }
})
export default class FeaturedUsersLagCv extends Vue {
  HELP_PAGE_LINK = "https://help.usergram.info/article/457-lag-cv";

  @Prop({ type: Array, required: true })
  lagCvUserCounts!: LagCvUserCount[];

  @Prop({ type: Boolean, required: true })
  isError!: boolean;

  @Prop({ type: Boolean, required: true })
  isLagCvLoading!: boolean;

  emitSelect(url: string) {
    this.$emit("select", url);
  }

  @Emit("retry-fetch")
  emitRetryFetch() {}

  get hasConversion(): boolean {
    return this.lagCvUserCounts.some(
      userCount =>
        userCount.numberOfUsers > 0 || userCount.conversions.length > 0
    );
  }

  mounted() {
    if (!this.hasConversion) {
      this.emitSelect("");
    }
  }
}
</script>
<style lang="scss" scoped>
.featuredUsersLagCv {
  height: 100%;
}
.featuredUsersLagCv__content {
  height: 100%;
}
.featuredUsersLagCv__loading {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -36px;
}
.featuredUsersLagCv__error {
  margin-top: -36px;
}
</style>
