<i18n src="@/i18n/components/user/user-list.json"></i18n>
<template>
  <UgTable :sticky-top="stickyTop">
    <template #header>
      <UgTableHeaderRow>
        <UgTableHeaderCell :width="userColumnWidth">{{
          fixedColumns[0].name
        }}</UgTableHeaderCell>
        <UgTableHeaderCell v-show="showColumn(1)">{{
          fixedColumns[1].name
        }}</UgTableHeaderCell>
        <UgTableHeaderCell v-show="showColumn(1)">{{
          fixedColumns[2].name
        }}</UgTableHeaderCell>
        <UgTableHeaderCell v-show="showColumn(1)">{{
          fixedColumns[3].name
        }}</UgTableHeaderCell>
        <UgTableHeaderCell
          v-for="(column, index) in selectedColumns"
          v-show="showColumn(showColumnPage(index))"
          :key="column.key"
          >{{ column.name }}</UgTableHeaderCell
        >
        <UgTableHeaderCell :width="menuButtonColumnWidth">
          <Tooltip
            :text="$t('displaySetting')"
            :placement="tooltipPlacement.BottomEnd"
          >
            <UgTableMenuButton
              class="user-attribute-table__menu-button"
              @click="openDialog"
            />
          </Tooltip>

          <Dialog
            :visible="showAttributeTableColumnDialog"
            :title="$t('selectColumnAndOrder')"
            top="5vh"
            width="800px"
            @close="closeDialog"
          >
            <div class="upt-Modal_Body">
              <AttributeTableColumnForm
                :fixed-columns="fixedColumns"
                :selected-columns="selectedColumns"
                :all-columns="allColumns"
                @input="onInput"
              />
            </div>

            <template #footer>
              <div class="upt-Modal_List">
                <Button
                  v-t="'set'"
                  class="upt-Modal_Button"
                  width="100%"
                  @click="onChangeColumnSettings"
                />
                <Button
                  v-t="'cancel'"
                  class="upt-Modal_Button"
                  width="100%"
                  :type="cancelButtonColor"
                  @click="closeDialog"
                />
              </div>
            </template>
          </Dialog>
        </UgTableHeaderCell>
      </UgTableHeaderRow>
    </template>
    <UgTableRow
      v-for="user in users"
      :key="user.id"
      data-cy="ug-table-row-users"
    >
      <UgTableCell
        class="upt-UserColumn user-attribute-table__user-column"
        :class="{ 'current-user': isCurrentUser(user.id) }"
        :width="userColumnWidth"
        @mouse-enter="onMouseEnter($event, user.id)"
        @mouse-leave="onMouseLeave"
      >
        <slot :user="user" />
      </UgTableCell>
      <UgTableCell v-show="showColumn(1)" class="user-search-result__cell">{{
        user.device.toString
      }}</UgTableCell>
      <UgTableCell v-show="showColumn(1)" class="user-search-result__cell">{{
        formatDate(user.conversion.date, user.conversion.timezone)
      }}</UgTableCell>
      <UgTableCell
        v-show="showColumn(1)"
        class="user-search-result__cell"
        data-cy="user-search-result__cell-conversion_name"
        >{{ user.conversion.name }}</UgTableCell
      >
      <UgTableCell
        v-for="(column, index) in selectedColumns"
        v-show="showColumn(showColumnPage(index))"
        :key="column.key"
        class="user-search-result__cell"
        :class="{ 'upt-ArticleColumn': isArticleColumn(column.id) }"
        >{{ getColumnValue(column.id, user) }}</UgTableCell
      >
      <UgTableCell :width="menuButtonColumnWidth"></UgTableCell>
    </UgTableRow>
  </UgTable>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import { TooltipPlacement } from "@/const/tooltip";
import { BUTTON_TYPE } from "@/const/button";
import { formatDate, DateFormat } from "@/util/date-util";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import { getColumnValue } from "@/components/users/getUserAttributeTableColumn";
import Tooltip from "@/components/Tooltip.vue";
import Button from "@/components/Button.vue";
import UgTable from "@/components/table/UgTable.vue";
import UgTableRow from "@/components/table/UgTableRow.vue";
import UgTableCell from "@/components/table/UgTableCell.vue";
import UgTableHeaderRow from "@/components/table/UgTableHeaderRow.vue";
import UgTableHeaderCell from "@/components/table/UgTableHeaderCell.vue";
import UgTableMenuButton from "@/components/table/UgTableMenuButton.vue";
import AttributeTableColumnForm from "@/components/users/AttributeTableColumnForm.vue";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import Dialog from "@/components/dialog/Dialog.vue";

const USER_COLUMN_WIDTH: number = 135;
const MENU_BUTTON_COLUMN_WIDTH: number = 60;
const COLUMN_BREAK_NUMBER: number = 5;
const ARTICLE_COLUMNS: string[] = ["nps_2", "nps_4"];

@Component({
  components: {
    Tooltip,
    Button,
    UgTable,
    UgTableRow,
    UgTableCell,
    UgTableHeaderRow,
    UgTableHeaderCell,
    UgTableMenuButton,
    AttributeTableColumnForm,
    Dialog
  }
})
export default class UserAttributeTable extends Vue {
  @Prop({ type: Array, required: true })
  users!: User[];

  //固定カラム情報
  @Prop({ type: Array, required: true })
  fixedColumns!: TableColumn[];

  //選択されたカラム情報
  @Prop({ type: Array, required: true })
  selectedColumns!: TableColumn[];

  //全てのカラム情報
  @Prop({ type: Array, required: true })
  allColumns!: TableColumn[];

  @Prop({ type: Number, required: true })
  currentColumnPage!: number;

  @Prop({ type: String, default: "0" })
  stickyTop!: string;

  @Prop({ type: User, default: null })
  currentUser!: User;

  update(columns: TableColumn[]) {
    this.$emit("changeColumnSettings", columns);
  }

  onMouseEnter(event: MouseEvent, id: string): void {
    this.$emit("mouse-enter", event, id);
  }

  onMouseLeave(event: MouseEvent): void {
    this.$emit("mouse-leave", event);
  }

  columnPage: number = 1;
  tooltipPlacement = TooltipPlacement;
  cancelButtonColor: string = BUTTON_TYPE.LIGHT;
  userColumnWidth: string = USER_COLUMN_WIDTH + "px";
  menuButtonColumnWidth: string = MENU_BUTTON_COLUMN_WIDTH + "px";
  isChangeColumn: boolean = false;
  inputColumns: TableColumn[] = [];
  showAttributeTableColumnDialog = false;

  showColumn(page: number): boolean {
    if (this.currentColumnPage === 0) {
      return true;
    }
    return page === this.currentColumnPage;
  }

  showColumnPage(index: number): number {
    const columnIndex = index + this.fixedColumns.length;
    const num = columnIndex / COLUMN_BREAK_NUMBER;
    return Math.ceil(num);
  }

  formatDate(date: Date, timezoneOffset: number): string {
    return formatDate(DateFormat.yyyy年M月d日E_HH時mm分, date, timezoneOffset);
  }

  getColumnValue(columnId: string, user: User) {
    return getColumnValue(columnId, user);
  }

  isArticleColumn(columnId: string): boolean {
    return ARTICLE_COLUMNS.some((c: string) => c === columnId);
  }

  openDialog() {
    this.showAttributeTableColumnDialog = true;
  }

  closeDialog() {
    this.showAttributeTableColumnDialog = false;
  }

  onChangeColumnSettings() {
    UgTag.pushEvent(UgEventTag.ChangeAttributeColumn);

    this.closeDialog();
    // onInputが動作したときに返す
    // 初期情報から変更なしに「設定」をクリックすると空のinputColumnsを返すため
    if (this.isChangeColumn) {
      this.update(this.inputColumns);
      this.isChangeColumn = false;
    }
  }

  onInput(columns: TableColumn[]) {
    this.isChangeColumn = true;
    this.inputColumns = columns;
  }

  isCurrentUser(userId: string): boolean {
    if (this.currentUser) {
      return this.currentUser.id === userId;
    }

    return false;
  }
}
</script>

<style scoped lang="scss">
.upt-Modal {
  &_List {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  &_Button {
    margin: 0 30px;
    max-width: 300px;
    width: 100%;
  }
}

.upt-Menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: $colorBlue800;
  cursor: pointer;

  &_Dot {
    margin-right: 2px;
    margin-left: 2px;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: $colorWhite;
  }

  &:hover {
    background-color: $colorBlue900;
  }
}

.upt-ArticleColumn {
  text-align: left;
  line-height: 1.5;
}

.upt-UserColumn {
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}

.current-user {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: $colorBlue900;
    content: "";
  }
}

.user-search-result__cell {
  max-width: 500px;
  line-height: 1.5;

  @include all-break-word();
}

.user-attribute-table__user-column {
  position: relative;
}
</style>
