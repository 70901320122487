import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { filterInputCheck } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { i18n } from "@/i18n";

/**
 * 行動履歴による条件
 *
 * 絞り込み条件に追加で、メール, DM, アプリ通知のとき、タイトル（部分一致）を条件として付与できる
 */
export class FilterContactTitleCondition {
  public readonly conditionType = FilterNodeConditionType.ContactTitle;
  constructor(public readonly title: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.title,
      i18n.t("models.contactAttribute.title") as string
    );
  }
}
