<i18n
  src="@/i18n/components/analysis-tools/analysis-tools-pager-select.json"
></i18n>
<template>
  <div class="analysisToolsPagerSelect">
    <div class="analysisToolsPagerSelect__text"> {{ $t("rowNumber") }} : </div>
    <SelectBox
      class="analysisToolsPagerSelect__select"
      data-cy="analysisToolsPagerSelect__select"
      width="90px"
      :options="selectOptions"
      :value="value"
      @input="onInput"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";

@Component({
  components: {
    SelectBox
  }
})
export default class AnalysisToolsPagerSelect extends Vue {
  @Prop({ type: Number, required: true })
  value!: number;

  @Prop({ type: Array, required: true })
  selectOptions!: SelectOption[];

  onInput(page: number) {
    this.$emit("input", page);
  }
}
</script>

<style scoped lang="scss">
.analysisToolsPagerSelect {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.analysisToolsPagerSelect__select {
  margin-left: 5px;
}
</style>
