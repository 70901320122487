import { render, staticRenderFns } from "./UserAttributeTable.vue?vue&type=template&id=47f1a0ee&scoped=true"
import script from "./UserAttributeTable.vue?vue&type=script&lang=ts"
export * from "./UserAttributeTable.vue?vue&type=script&lang=ts"
import style0 from "./UserAttributeTable.vue?vue&type=style&index=0&id=47f1a0ee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f1a0ee",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/user/user-list.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fusers%2FUserAttributeTable.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports