<i18n src="@/i18n/components/filter/filter-device.json"></i18n>
<template>
  <div class="filterDevice__container">
    <div class="filterDevice__title">
      {{ $t("devices") }}
      <Tooltip :text="$t('tooltip')" :placement="tooltipPlacement">
        <Icon :icon="Icons.HelpInvert" :color="Colors.Base700" :size="10" />
      </Tooltip>
      :
    </div>

    <CheckBoxList
      :values="values"
      class="filterDevice__checkboxContainer"
      :options="checkBoxOptions"
      :column="0"
      @input="onChange"
    />
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";

import CheckBox from "@/components/form/CheckBox.vue";
import CheckBoxList from "@/components/form/CheckBoxList.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";

import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { DeviceType } from "@/const/filter";
import { TooltipPlacement } from "@/const/tooltip";
import SelectOption from "@/components/form/SelectOption";

@Component({
  components: {
    CheckBox,
    Tooltip,
    Icon,
    CheckBoxList
  }
})
export default class FilterDevice extends Vue {
  Colors = Colors;
  Icons = Icons;
  tooltipPlacement = TooltipPlacement.BottomStart;

  @Model("input", { type: Array, required: true })
  values!: DeviceType[];

  get checkBoxOptions(): SelectOption[] {
    return [
      {
        label: "PC",
        value: DeviceType.PC,
        disabled: false
      },

      {
        label: "iPhone",
        value: DeviceType.IPHONE,
        disabled: false
      },

      {
        label: "Android",
        value: DeviceType.ANDROID,
        disabled: false
      },

      {
        label: "iPad",
        value: DeviceType.IPAD,
        disabled: false
      }
    ];
  }

  onChange(values: DeviceType[]) {
    this.$emit("input", values);
  }
}
</script>

<style lang="scss" scoped>
.filterDevice__container {
  display: flex;

  .filterDevice__title {
    margin-top: 5px;
  }

  .filterDevice__checkboxContainer {
    margin-left: 5px;
  }
}
</style>
