export enum PanelType {
  FavSearch = 1,
  Bookmark = 2,
  History = 3,
  Discussion = 4,
  PrevSearch = 5,
  LagCv = 6,
  AlmostCv = 7,
  ConversionTrend = 8,
  Tour = 9
}
