<i18n
  src="@/i18n/components/observation/user-header/user-detail-balloon.json"
></i18n>
<template>
  <Balloon :direction="balloonDirection.TopStart" :adjust="118">
    <div class="balloonWrapper">
      <div class="serviceId">
        <UserServiceIdText
          :service-id-text="user.serviceIdForDisplay"
          :is-fixed-service-id="user.isFixedServiceId"
        />
      </div>
      <div
        class="userDetailBalloon__container"
        :class="{ 'userDetailBalloon__container--active': openedWordCloud }"
      >
        <div
          class="userDetailBalloon__userInfo"
          :class="{
            'userDetailBalloon__userInfo--active': openedWordCloud,
            'userDetailBalloon__userInfo--ja': $i18n.locale === 'ja'
          }"
        >
          <ScrollWrapper
            :arrow-show="true"
            class="userDetailBalloon__scrollWrapper"
          >
            <div
              class="userDetailBalloon__userInfoContainer"
              :style="{ minWidth: userInfoContainerWidth }"
            >
              <div
                v-if="0 < bizIndexes.length"
                class="userInfo"
                data-cy="userInfo-businessIndex"
              >
                <div
                  class="userInfoTitle"
                  data-cy="userInfo-businessIndex-title"
                  >{{ $t("businessIndex") }}</div
                >
                <dl>
                  <template v-for="item in bizIndexes">
                    <dt
                      :key="'dt' + item.title"
                      class="title"
                      data-cy="userInfo-businessIndex-item-title"
                      >{{ item.title }}</dt
                    >
                    <dd
                      :key="'dd' + item.title"
                      class="value"
                      data-cy="userInfo-businessIndex-item-value"
                      >{{ item.value }}</dd
                    >
                  </template>
                </dl>
              </div>
              <div
                v-if="0 < userAttrs.length"
                class="userInfo"
                data-cy="userInfo-userAttributes"
              >
                <div
                  class="userInfoTitle"
                  data-cy="userInfo-userAttributes-title"
                  >{{ $t("userAttributes") }}</div
                >
                <dl>
                  <template v-for="item in userAttrs">
                    <dt
                      :key="'dt' + item.title"
                      class="title"
                      data-cy="userInfo-userAttributes-item-title"
                      >{{ item.title }}</dt
                    >
                    <dd
                      :key="'dd' + item.title"
                      class="value"
                      data-cy="userInfo-userAttributes-item-value"
                      >{{ item.value }}</dd
                    >
                  </template>
                </dl>
              </div>
              <div class="userInfo" data-cy="userInfo-conversionAttributes">
                <div
                  class="userInfoTitle"
                  data-cy="userInfo-conversionAttributes-title"
                  >{{ $t("conversionAttributes") }}</div
                >
                <dl>
                  <template v-for="item in cvAttrs">
                    <dt
                      :key="'dt' + item.title"
                      class="title"
                      data-cy="userInfo-conversionAttributes-item-title"
                      >{{ item.title }}</dt
                    >
                    <dd
                      :key="'dd' + item.title"
                      class="value"
                      data-cy="userInfo-conversionAttributes-item-value"
                      >{{ item.value }}</dd
                    >
                  </template>
                </dl>
              </div>
            </div>
          </ScrollWrapper>
        </div>
        <div
          v-if="$i18n.locale === 'ja'"
          class="userDetailBalloon__wordcloud"
          :class="{ 'userDetailBalloon__wordcloud--active': openedWordCloud }"
        >
          <slot />
        </div>
      </div>
    </div>
  </Balloon>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BalloonDirection } from "@/const/balloon";
import { User, BusinessIndex } from "@/models/User";
import Balloon from "@/components/Balloon.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";
import { formatDate, DateFormat } from "@/util/date-util";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import ScrollWrapper from "@/components/ScrollWrapper.vue";

class UserInfoKeyValue {
  constructor(public readonly title: string, public readonly value: string) {}
}

@Component({
  components: {
    Balloon,
    UserServiceIdText,
    ScrollWrapper
  }
})
export default class UserDetailBalloon extends Vue {
  balloonDirection = BalloonDirection;

  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  businessIndexDefinitions!: BusinessIndexDefinition[];

  @Prop({ type: Array, required: true })
  userAttributeDefinitions!: UserAttributeDefinition[];

  @Prop({ type: Boolean, required: true })
  openedWordCloud!: boolean;

  get displayId(): string {
    return this.user.serviceIdForDisplay;
  }

  /**
   * コンバージョン属性を表示するための配列を作る
   * コンバージョン以外にも、初回訪問日、利用端末も表示情報として入れる
   */
  get cvAttrs(): UserInfoKeyValue[] {
    const cvAttrs: UserInfoKeyValue[] = [];
    if (this.user.createdAt) {
      cvAttrs.push(
        new UserInfoKeyValue(
          this.$i18n.t("firstVisitDate") as string,
          formatDate(
            DateFormat.yyyy年M月d日E,
            this.user.createdAt,
            this.user.createdAtTimezoneOffset
          )
        )
      );
    }
    cvAttrs.push(
      new UserInfoKeyValue(
        this.$i18n.t("userDevice") as string,
        this.user.device.toString
      )
    );
    cvAttrs.push(
      new UserInfoKeyValue(
        this.$i18n.t("conversionName") as string,
        this.user.conversion.name
      )
    );
    cvAttrs.push(
      new UserInfoKeyValue(
        this.$i18n.t("conversionDate") as string,
        formatDate(
          DateFormat.yyyy年M月d日E_HH時mm分_TZONEDIFF,
          this.user.conversion.date,
          this.user.conversion.timezone
        )
      )
    );
    for (const attr of this.user.conversion.conversionAttributes) {
      const ca = this.conversionAttributeDefinitions.find(
        ca => ca.id === attr.id
      );
      if (ca !== undefined) {
        cvAttrs.push(new UserInfoKeyValue(ca.name, attr.value));
      }
    }

    return cvAttrs;
  }

  /**
   * ビジネス指標を表示するための配列を作る
   */
  get bizIndexes(): UserInfoKeyValue[] {
    const bizIndexes: UserInfoKeyValue[] = [];
    for (const userBizIndex of this.user.businessIndexes) {
      const bizIndex = this.businessIndexDefinitions.find(
        bi => bi.id === userBizIndex.id
      );
      if (bizIndex !== undefined) {
        const value = BusinessIndex.valueForDisplay(userBizIndex);
        const insertDate = formatDate(
          DateFormat.yyyysMMsdd,
          userBizIndex.updatedAt
        );
        const title = bizIndex.name + "(" + insertDate + ")";
        bizIndexes.push(new UserInfoKeyValue(title, value));
      }
    }

    return bizIndexes;
  }

  /**
   * ユーザ属性を表示するための配列を作る
   */
  get userAttrs(): UserInfoKeyValue[] {
    const userAttrs: UserInfoKeyValue[] = [];

    for (const userAttr of this.user.attributes) {
      const attr = this.userAttributeDefinitions.find(
        ua => ua.id === userAttr.id
      );
      if (attr !== undefined) {
        const insertDate = formatDate(
          DateFormat.yyyysMMsdd,
          userAttr.updatedAt
        );
        const title = attr.name + "(" + insertDate + ")";
        userAttrs.push(new UserInfoKeyValue(title, userAttr.value));
      }
    }
    return userAttrs;
  }

  // 固定しないと最小値までBallonnが小さくなる
  get userInfoContainerWidth(): string {
    const margin: number = 20;
    const width: number = 180;
    let value: number = 180;

    if (0 < this.bizIndexes.length) {
      value = value + width + margin;
    }

    if (0 < this.userAttrs.length) {
      value = value + width + margin;
    }

    return value + "px";
  }
}
</script>

<style scoped lang="scss">
.balloonWrapper {
  padding: 20px 20px 20px 0;
}

.serviceId {
  margin-bottom: 16px;
  padding-left: 35px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
}

.userDetailBalloon__container {
  display: flex;
  min-width: 620px;
  max-width: calc(100vw - 80px);
}

.userDetailBalloon__container--active {
  max-height: calc(100vh - 260px);
}

.userDetailBalloon__userInfo {
  position: relative;
  min-height: 100%;
  max-width: calc(100%);
  max-height: calc(100vh - 260px);

  &--ja {
    max-width: calc(100% - 360px);
  }
}

.userDetailBalloon__userInfo--active {
  max-width: calc(100% - 450px);
}

.userDetailBalloon__userInfoContainer {
  display: flex;
  width: 100%;
}

.userInfo {
  padding-bottom: 10px;
  width: 180px;
  &:not(:first-child) {
    margin-left: 10px;
  }
}

.userInfoTitle {
  padding: 4px;
  background: $colorBase600;
  color: $colorWhite;
  text-align: center;
  font-size: 14px;
}

.title {
  margin-top: 16px;
  color: $colorTextLight;
  text-align: left;
  font-weight: normal;
  font-size: 11px;

  @include default-break-word();
}

.value {
  margin-top: 12px;
  text-align: left;
  font-size: 14px;

  @include default-break-word();
}

.userDetailBalloon__wordcloud {
  width: 360px;
  height: 85px;
  transition: width 0.3s ease;
}

.userDetailBalloon__wordcloud--active {
  width: 450px;
  height: 400px;
}
</style>
