<i18n src="@/i18n/components/tours/tour-no-users.json"></i18n>
<template>
  <div class="tourSorryNoUser">
    <div class="tourSorryNoUser__balloon">
      <h4 v-t="'text1'" class="tourSorryNoUser__balloonTitle" />
      <p v-t="'text2'" class="tourSorryNoUser__balloonText" />
      <RouterLink
        v-t="'text3'"
        class="tourSorryNoUser__balloonLink"
        :to="{ name: 'tours' }"
      />
    </div>
    <NoResultImage />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import NoResultImage from "@/assets/svg/no-result.svg";

@Component({
  components: {
    NoResultImage
  }
})
export default class TourSorryNoUser extends Vue {}
</script>

<style lang="scss" scoped>
.tourSorryNoUser {
  text-align: center;
}

.tourSorryNoUser__balloon {
  position: relative;
  margin: 60px auto 35px;
  padding: 20px 0;
  max-width: 812px;
  border: 1px solid $colorBase500;
  border-radius: $sizeRadius;

  /* Balloon arrow styling */
  &::before,
  &::after {
    position: absolute;
    left: 50%;
    border-width: 17px 12px;
    border-style: solid;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    content: "";
    transform: translateX(-50%);
  }

  &::after {
    bottom: -32px;
    border-top-color: $colorWhite;
  }

  &::before {
    bottom: -34px;
    border-top-color: $colorBase500;
  }
  /* End of Balloon arrow styling */
}

.tourSorryNoUser__balloonTitle {
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}

.tourSorryNoUser__balloonText {
  margin-bottom: 17px;
  font-size: 12px;
  line-height: 1.5;
}

.tourSorryNoUser__balloonLink,
.tourSorryNoUser__balloonLink:active,
.tourSorryNoUser__balloonLink:focus {
  color: $colorBlue1000;
  font-size: 14px;
  line-height: 1;
}
</style>
