import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ConversionAttributeType } from "@/models/client-settings/ConversionAttributeDefinition";
import { MapConversionAttributeDefinition } from "@/models/client-settings/MapConversionAttributeDefinition";

export interface MapCvAttributeWithCvAttributeRequest {
  conversion_attribute_name: string;
  conversion_attribute_type: ConversionAttributeType;
  parameters: string[];
}
export interface MapConversionAttributeResponse {
  id: number;
  coordination_id: number;
  conversion_attribute_id: number;
  parameter: string;
  is_disabled: boolean;
}
export interface MapCvAttributeRequest {
  parameters: string[];
}

export default class ApiMapConversionAttribute {
  constructor(private readonly httpClient: HttpClient) {}

  public registerMapCvAttributeWithCvAttribute = async (
    params: MapCvAttributeWithCvAttributeRequest,
    coordinationId: number
  ): Promise<MapConversionAttributeDefinition[]> => {
    const url = ApiUrl.MAP_CV_ATTRIBUTE_WITH_CV_ATTRIBUTE_REGISTER(
      coordinationId
    );

    const response = await this.httpClient.post<
      MapConversionAttributeResponse[]
    >(url, params);

    return response.map(this.toModel);
  };

  public registerMapConversionAttribute = async (
    params: MapCvAttributeRequest,
    coordinationId: number,
    conversionAttributeId: number
  ): Promise<MapConversionAttributeDefinition[]> => {
    const url = ApiUrl.MAP_CV_ATTRIBUTE_REGISTER(
      coordinationId,
      conversionAttributeId
    );

    const response = await this.httpClient.post<
      MapConversionAttributeResponse[]
    >(url, params);

    return response.map(this.toModel);
  };

  private toModel(json: MapConversionAttributeResponse) {
    return new MapConversionAttributeDefinition(
      json.id,
      json.coordination_id,
      json.conversion_attribute_id,
      json.parameter,
      json.is_disabled
    );
  }
}
