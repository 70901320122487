<template>
  <div
    ref="tooltip"
    class="tooltip"
    :class="{ tooltip__show: show }"
    @mouseenter="onMouseEnter"
  >
    <slot></slot>
    <div
      v-if="active"
      class="tooltip__popup"
      :class="[placementClass, colorClass, { 'tooltip__mode--float': float }]"
    >
      <div class="tooltip__arrow tooltip__arrow--front" :style="style"></div>
      <div
        ref="tooltip-container"
        class="tooltip__container"
        :class="{ tooltip__container__nowrap: !wrap }"
      >
        <template v-if="hasText">{{ text }}</template>
        <slot v-else name="content"></slot>
      </div>
      <div class="tooltip__arrow tooltip__arrow--back" :style="style"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { TooltipPlacement, TooltipColor } from "@/const/tooltip";

const OVER_ARROW_POSITION = 10;
const ARROW_SIZE = 5;

@Component
export default class Tooltip extends Vue {
  @Prop({ type: String, default: TooltipPlacement.Top })
  placement!: TooltipPlacement;

  @Prop({ type: String, default: TooltipColor.Dark })
  color!: TooltipColor;

  @Prop({ type: Boolean, default: false })
  float!: boolean;

  @Prop({ type: String, default: "" })
  text!: string;

  @Prop({ type: Boolean, default: true })
  active!: boolean;

  @Prop({ type: Boolean, default: false })
  wrap!: boolean;

  @Prop({ type: Boolean, default: false })
  fixedArrowPosition!: boolean;

  // hoverではなく、propで強制的に表示する用
  @Prop({ type: Boolean, default: false })
  show!: boolean;

  arrowPosion: string = "0px";

  get placementClass(): string {
    return "tooltip__placement--" + this.placement;
  }

  get colorClass(): string {
    return "tooltip__mode--" + this.color;
  }

  get hasText(): boolean {
    return this.text.length > 0;
  }

  get style() {
    if (
      this.placement.indexOf(TooltipPlacement.TopStart) !== -1 ||
      this.placement.indexOf(TooltipPlacement.BottomStart) !== -1
    ) {
      return {
        left: this.arrowPosion
      };
    }

    if (
      this.placement.indexOf(TooltipPlacement.TopEnd) !== -1 ||
      this.placement.indexOf(TooltipPlacement.BottomEnd) !== -1
    ) {
      return {
        right: this.arrowPosion
      };
    }

    if (
      this.placement.indexOf(TooltipPlacement.LeftStart) !== -1 ||
      this.placement.indexOf(TooltipPlacement.RightStart) !== -1
    ) {
      return {
        top: this.arrowPosion
      };
    }

    if (
      this.placement.indexOf(TooltipPlacement.LeftEnd) !== -1 ||
      this.placement.indexOf(TooltipPlacement.RightEnd) !== -1
    ) {
      return {
        bottom: this.arrowPosion
      };
    }

    return {
      display: "block"
    };
  }

  onMouseEnter() {
    if (!this.active) {
      return;
    }
    const tooltip = this.$refs["tooltip"] as HTMLElement;
    const tooltipContainer = this.$refs["tooltip-container"] as HTMLElement;

    if (
      this.placement.indexOf("top-") !== -1 ||
      this.placement.indexOf("bottom-") !== -1
    ) {
      let arrowPostion = Math.floor(tooltip.clientWidth / 2);

      if (
        arrowPostion >= tooltipContainer.clientWidth - ARROW_SIZE ||
        this.fixedArrowPosition
      ) {
        arrowPostion = OVER_ARROW_POSITION;
      }

      this.arrowPosion = arrowPostion + "px";
    }

    if (
      this.placement.indexOf("left-") !== -1 ||
      this.placement.indexOf("right-") !== -1
    ) {
      let arrowPostion = Math.floor(tooltip.clientHeight / 2);

      if (arrowPostion >= tooltipContainer.clientHeight - ARROW_SIZE) {
        arrowPostion = OVER_ARROW_POSITION;
      }

      this.arrowPosion = arrowPostion + "px";
    }
  }
}
</script>

<style scoped lang="scss">
.tooltip__popup {
  position: absolute;
  display: none;
  border-radius: $sizeRadius;
}

.tooltip__show {
  & .tooltip__popup {
    display: block;
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  &:hover .tooltip__popup {
    display: block;
  }
}

.tooltip__arrow {
  position: absolute;
  transform: rotate(45deg);
}

.tooltip__arrow--front {
  width: 8px;
  height: 8px;
}

.tooltip__arrow--back {
  width: 6px;
  height: 6px;
}

.tooltip__container {
  position: relative;
  padding: 8px;
  border-radius: $sizeRadius;
  text-align: left;
  text-indent: 0;
  font-size: 11px;
  line-height: 1.3;
  font-weight: normal;

  &.tooltip__container__nowrap {
    white-space: nowrap;
  }
}

.tooltip__placement--top,
.tooltip__placement--top-start,
.tooltip__placement--top-end {
  bottom: calc(100% + 10px);

  .tooltip__arrow--front {
    bottom: -3px;
  }

  .tooltip__arrow--back {
    bottom: -2px;
  }
}

.tooltip__placement--bottom,
.tooltip__placement--bottom-start,
.tooltip__placement--bottom-end {
  top: calc(100% + 10px);

  .tooltip__arrow--front {
    top: -3px;
  }

  .tooltip__arrow--back {
    top: -2px;
  }
}

.tooltip__placement--top,
.tooltip__placement--bottom {
  left: 50%;
  transform: translateX(-50%);

  .tooltip__arrow {
    left: 50%;
  }

  .tooltip__arrow--front {
    margin-left: -3px;
  }

  .tooltip__arrow--back {
    margin-left: -2px;
  }
}
.tooltip__placement--top-start,
.tooltip__placement--bottom-start {
  left: -3px;

  .tooltip__arrow--back {
    margin-left: 1px;
  }
}
.tooltip__placement--top-end,
.tooltip__placement--bottom-end {
  right: -3px;

  .tooltip__arrow--back {
    margin-right: 1px;
  }
}

.tooltip__placement--right,
.tooltip__placement--right-start,
.tooltip__placement--right-end {
  left: calc(100% + 10px);

  .tooltip__arrow--front {
    left: -3px;
  }

  .tooltip__arrow--back {
    left: -2px;
  }
}

.tooltip__placement--left,
.tooltip__placement--left-start,
.tooltip__placement--left-end {
  right: calc(100% + 10px);

  .tooltip__arrow--front {
    right: -3px;
  }

  .tooltip__arrow--back {
    right: -2px;
  }
}

.tooltip__placement--right,
.tooltip__placement--left {
  top: 50%;
  transform: translateY(-50%);

  .tooltip__arrow {
    top: 50%;
  }

  .tooltip__arrow--front {
    margin-top: -3px;
  }

  .tooltip__arrow--back {
    margin-top: -2px;
  }
}
.tooltip__placement--right-start,
.tooltip__placement--left-start {
  top: -3px;

  .tooltip__arrow--back {
    margin-top: 1px;
  }
}
.tooltip__placement--right-end,
.tooltip__placement--left-end {
  bottom: -3px;

  .tooltip__arrow--back {
    margin-bottom: 1px;
  }
}

.tooltip__mode--dark {
  .tooltip__arrow--front {
    background-color: $colorDark;
  }
  .tooltip__arrow--back {
    display: none;
  }
  .tooltip__container {
    background-color: $colorDark;
    color: $colorWhite;
  }
}

.tooltip__mode--light {
  .tooltip__arrow--front {
    background-color: $colorBase500;
  }
  .tooltip__arrow--back {
    background-color: $colorWhite;
  }
  .tooltip__container {
    border: 1px solid $colorBase500;
    background-color: $colorWhite;
    color: $colorText;
  }
}

.tooltip__mode--float {
  box-shadow: 5px 5px 5px $colorShadow100;
}
</style>
