import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import { FilterNodeParamForContact } from "@/api/apis/ApiSearch";
import { FilterContactTitleCondition } from "@/models/search/filter-node-condition/FilterContactTitleCondition";
import { FilterContactContentCondition } from "@/models/search/filter-node-condition/FilterContactContentCondition";
import { FilterContactPurposeCondition } from "@/models/search/filter-node-condition/FilterContactPurposeCondition";
import { FilterContactEmployeeCondition } from "@/models/search/filter-node-condition/FilterContactEmployeeCondition";
import { FilterContactShopNameCondition } from "@/models/search/filter-node-condition/FilterContactShopNameCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import {
  FilterNodeType,
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import { FilterContactCondition } from "@/models/search/filter-node-condition/FilterContactCondition";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import SelectOption from "@/components/form/SelectOption";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

export enum ContactSubjectType {
  All = 0,
  InBound = 1,
  OutBound = 2
}

/**
 * 行動履歴による絞り込み条件
 *
 * 選択した種類の行動履歴があるユーザを絞り込む
 *
 * 初回、期間、日付と時間帯 を条件として追加できる
 */
export class FilterNodeForContact {
  public readonly nodeType = FilterNodeType.Contact;

  constructor(
    public readonly condition: FilterContactCondition,
    /**
     * 追加の検索条件
     *
     * 初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterContactTitleCondition
      | FilterContactContentCondition
      | FilterContactPurposeCondition
      | FilterContactEmployeeCondition
      | FilterContactShopNameCondition
      | FilterAdditionalTimingCondition
    )[],
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion()
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate(): ValidationResult {
    return validateConditions(
      this.additionalConditions,
      this.condition.validate
    );
  }

  isConditionAddable(): boolean {
    if (this.depth === 0) {
      return this.additionalConditions.length < 2;
    } else {
      return this.additionalConditions.length < 1;
    }
  }

  insertEdge(): FilterNodeForContact {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForContact(
      this.condition,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion
    );
  }

  removeEdge(): FilterNodeForContact {
    return new FilterNodeForContact(
      this.condition,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion
    );
  }

  removeFirstTimeCondition(): FilterNodeForContact {
    return new FilterNodeForContact(
      this.condition,
      this.additionalConditions.filter(
        cnd => !(cnd instanceof FilterFirstTimeCondition)
      ),
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion
    );
  }
  get hasContactTitleCondition(): boolean {
    return this.additionalConditions.some(
      addtionalCondition =>
        addtionalCondition instanceof FilterContactTitleCondition
    );
  }

  get hasContactContentCondition(): boolean {
    return this.additionalConditions.some(
      addtionalCondition =>
        addtionalCondition instanceof FilterContactContentCondition
    );
  }

  get hasContactPurposeCondition(): boolean {
    return this.additionalConditions.some(
      addtionalCondition =>
        addtionalCondition instanceof FilterContactPurposeCondition
    );
  }

  get hasContactEmployeeCondition(): boolean {
    return this.additionalConditions.some(
      addtionalCondition =>
        addtionalCondition instanceof FilterContactEmployeeCondition
    );
  }

  get hasContactShopNameCondition(): boolean {
    return this.additionalConditions.some(
      addtionalCondition =>
        addtionalCondition instanceof FilterContactShopNameCondition
    );
  }

  get hasFirstTime(): boolean {
    return this.additionalConditions.some(
      additionalCondition =>
        additionalCondition instanceof FilterFirstTimeCondition
    );
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    data_type: ContactDefinitionType,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: this.getOptionsForContactAttribute(data_type, currentCondition)
      }
    ];

    if (this.depth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }

  getOptionsForContactAttribute(
    data_type: ContactDefinitionType,
    currentCondition: FilterAdditionalCondition
  ): SelectOption[] {
    if (
      data_type === ContactDefinitionType.MAIL ||
      data_type === ContactDefinitionType.DM ||
      data_type === ContactDefinitionType.APP
    ) {
      return [
        {
          value: FilterNodeConditionType.ContactTitle,
          label: i18n.t("models.contactAttribute.title") as string,
          disabled:
            this.hasContactTitleCondition &&
            !(currentCondition instanceof FilterContactTitleCondition)
        }
      ];
    }

    if (data_type === ContactDefinitionType.TEL) {
      return [
        {
          value: FilterNodeConditionType.ContactContent,
          label: i18n.t("models.contactAttribute.content") as string,
          disabled:
            this.hasContactContentCondition &&
            !(currentCondition instanceof FilterContactContentCondition)
        }
      ];
    }

    if (data_type === ContactDefinitionType.VISIT) {
      return [
        {
          value: FilterNodeConditionType.ContactPurpose,
          label: i18n.t("models.contactAttribute.visitPurpose") as string,
          disabled:
            this.hasContactPurposeCondition &&
            !(currentCondition instanceof FilterContactPurposeCondition)
        },
        {
          value: FilterNodeConditionType.ContactEmployee,
          label: i18n.t("models.contactAttribute.personInCharge") as string,
          disabled:
            this.hasContactEmployeeCondition &&
            !(currentCondition instanceof FilterContactEmployeeCondition)
        }
      ];
    }

    if (data_type === ContactDefinitionType.SHOP) {
      return [
        {
          value: FilterNodeConditionType.ContactShopName,
          label: i18n.t("models.contactAttribute.nameOfStore") as string,
          disabled:
            this.hasContactShopNameCondition &&
            !(currentCondition instanceof FilterContactShopNameCondition)
        },
        {
          value: FilterNodeConditionType.ContactPurpose,
          label: i18n.t("models.contactAttribute.storeVisitPurpose") as string,
          disabled:
            this.hasContactPurposeCondition &&
            !(currentCondition instanceof FilterContactPurposeCondition)
        }
      ];
    }

    return [
      {
        value: FilterNodeConditionType.None,
        label: i18n.t("models.search.activityHistory") as string,
        disabled: true
      }
    ];
  }
}

export function convertFilterNodeForContactToJson(
  node: FilterNodeForContact
): FilterNodeParamForContact {
  const result: FilterNodeParamForContact = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.Contact,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    contact_def_id: node.condition.contactDefinitionId,
    talk_gt_checked: node.condition.isCheckTalkTime,
    talk_gt_time_min: node.condition.talkMin,
    subject_type: node.condition.subjectType
  };

  const conditions: (
    | FilterContactTitleCondition
    | FilterContactContentCondition
    | FilterContactPurposeCondition
    | FilterContactEmployeeCondition
    | FilterContactShopNameCondition
    | FilterAdditionalTimingCondition
  )[] = node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterContactTitleCondition) {
      result.title = condition.title;
    } else if (condition instanceof FilterContactContentCondition) {
      result.content = condition.content;
    } else if (condition instanceof FilterContactPurposeCondition) {
      result.purpose = condition.purpose;
    } else if (condition instanceof FilterContactEmployeeCondition) {
      result.employee = condition.employee;
    } else if (condition instanceof FilterContactShopNameCondition) {
      result.store_name = condition.shopName;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }
  return result;
}

export function convertJsonToFilterNodeForContact(
  json: FilterNodeParamForContact,
  activeContactDefinitions: ContactDefinition[],
  isCheckDefinition = true
): FilterNodeForContact | null {
  // チェックが必要かつ利用可能なidリストになければnull
  if (
    isCheckDefinition &&
    !activeContactDefinitions.some(def => def.id === json.contact_def_id)
  ) {
    return null;
  }
  const additionalConditions: (
    | FilterContactTitleCondition
    | FilterContactContentCondition
    | FilterContactPurposeCondition
    | FilterContactEmployeeCondition
    | FilterContactShopNameCondition
    | FilterAdditionalTimingCondition
  )[] = getAdditionalTimingConditions(json);

  if (json.title) {
    additionalConditions.push(new FilterContactTitleCondition(json.title));
  }

  if (json.content) {
    additionalConditions.push(new FilterContactContentCondition(json.content));
  }

  if (json.purpose) {
    additionalConditions.push(new FilterContactPurposeCondition(json.purpose));
  }

  if (json.employee) {
    additionalConditions.push(
      new FilterContactEmployeeCondition(json.employee)
    );
  }

  if (json.store_name) {
    additionalConditions.push(
      new FilterContactShopNameCondition(json.store_name)
    );
  }

  return new FilterNodeForContact(
    new FilterContactCondition(
      json.contact_def_id,
      json.talk_gt_checked,
      json.talk_gt_time_min,
      json.subject_type
    ),
    additionalConditions,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!)
  );
}
