import { Browser, Os } from "@/const/UserAgent";

export function getBrowser(): Browser {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("msie") !== -1 || userAgent.indexOf("trident") !== -1) {
    return Browser.Ie;
  } else if (userAgent.indexOf("edge") !== -1) {
    return Browser.Edge;
  } else if (userAgent.indexOf("chrome") !== -1) {
    return Browser.Chrome;
  } else if (userAgent.indexOf("safari") !== -1) {
    return Browser.Safari;
  } else if (userAgent.indexOf("firefox") !== -1) {
    return Browser.Firefox;
  }

  return Browser.Unknown;
}

export function getOs(): Os {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("windows") !== -1) {
    return Os.Win;
  } else if (userAgent.indexOf("mac") !== -1) {
    return Os.Mac;
  }

  return Os.Unknown;
}
