<template>
  <div class="ug-table-header-row" data-cy="ug-table-header-row">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UgTableHeaderRow extends Vue {}
</script>

<style lang="scss" scoped>
.ug-table-header-row {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
</style>
