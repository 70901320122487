<i18n
  src="@/i18n/components/input-form-suggestion/input-form-suggetion-tooltip.json"
></i18n>
<template>
  <Tooltip :placement="tooltipPlacement.Bottom" :wrap="true">
    <Icon :icon="icons.HelpInvertWhite" :color="colors.Base700" :size="12" />
    <div slot="content" class="funnelAnalysisSearchInput__toolTipContent">
      <span v-html="$t('inputFormSuggetionNote1')" />
      <ul>
        <li v-t="'inputFormSuggetionNote2-1'" />
        <li v-t="'inputFormSuggetionNote2-2'" />
        <li v-t="'inputFormSuggetionNote2-3'" />
      </ul>
      <span v-t="'inputFormSuggetionNote3'" />
    </div>
  </Tooltip>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement } from "@/const/tooltip";
import { Colors } from "@/const/Colors";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    Tooltip,
    Icon
  }
})
export default class InputFormSuggestionTooltip extends Vue {
  colors = Colors;
  tooltipPlacement = TooltipPlacement;
  icons = Icons;
}
</script>

<style lang="scss" scoped>
.funnelAnalysisSearchInput__toolTipContent {
  width: 324px;
}
.funnelAnalysisSearchInput__toolTipContent ul {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 20px;
  list-style: disc;
}
</style>
