<template>
  <nav
    :class="{
      'side-bar': true,
      'side-bar--minimized': isSideBarMinimized,
      'side-bar--hovered': isHovered || isFavoriteSearchBalloonShown
    }"
    data-cy="global-nav"
  >
    <div
      class="side-bar__items"
      @mouseenter="setHoverState(true)"
      @mouseleave="setHoverState(false)"
    >
      <div class="side-bar__home-item">
        <V3SideBarItemNew
          :show-minimized="showMinimized"
          icon="shop"
          :text="$t('home').toString()"
          :active="false"
          @click.native="goToPage({ url: '/' })"
        />
      </div>

      <V3SideBarGroup
        v-for="{ category, items } in categories"
        :key="category"
        :show-minimized="showMinimized"
        :title="category"
      >
        <template v-for="(item, index) in items">
          <V3SideBarItemExpandableNew
            v-if="item.subitems && item.show"
            :key="index"
            :is-expanded="getIsExpanded(item.storageKey)"
            :show-minimized="showMinimized"
            :subitems="item.subitems"
            :icon="item.icon"
            :text="item.text"
            :data-cy="item.dataCy"
            @click-subitem="
              subitem =>
                goToPage({ routeName: subitem.routeName, url: subitem.url })
            "
            @toggle-subitems="() => onToggleSubItems(item.storageKey)"
          />
          <V3SideBarItemNew
            v-else-if="item.show"
            :key="index"
            :show-minimized="showMinimized"
            :icon="item.icon"
            :text="item.text"
            :disabled="item.disabled"
            :data-cy="item.dataCy"
            :is-icon-branches="item.icon === 'branches'"
            :active="isItemActive(route, item)"
            @click.native="
              goToPage({ routeName: item.routeName, url: item.url })
            "
          />
        </template>
      </V3SideBarGroup>
    </div>

    <div class="side-bar__footer">
      <div v-if="!showMinimized">© COPYRIGHT beBit, Inc.</div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, computed, ComputedRef, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { Category, Item, StorageKey } from "@/types/components/V3SideBar.type";
import V3SideBarGroup from "@/components/v3-side-bar/V3SideBarGroup.vue";
import V3SideBarItemNew from "@/components/v3-side-bar/V3SideBarItemNew.vue";
import V3SideBarItemExpandableNew from "@/components/v3-side-bar/V3SideBarItemExpandableNew.vue";
import { isItemActive } from "@/components/v3-side-bar/v3-side-bar-util";
import { sideBarStore } from "@/store/v3-side-bar";
import { FavoriteSearch } from "@/models/search/FavoriteSearch";
import store from "@/store";
import { AnalysisType } from "@/const/user-trend";
import { LoginUser } from "@/models/auth/UgUser";
import { Client } from "@/models/Client";
import { useI18n } from "@/composables/useI18n";
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const {
  isSideBarMinimized,
  isAnalysisExpanded,
  isPinExpanded,
  setIsAnalysisExpanded,
  setIsPinExpanded
} = sideBarStore();

const isHovered = ref(false);
const isFavoriteSearchBalloonShown: ComputedRef<boolean> = computed(
  () => store.state.preference.favoriteSearchBalloonPositionY !== ""
);
const favoriteSearches: ComputedRef<FavoriteSearch[]> = computed(
  () => store.getters["searchHistory/favoriteSearches"]
);

const loginUser: ComputedRef<LoginUser | null> = computed(
  () => store.state.auth.user
);
const loginClient: ComputedRef<Client | null> = computed(
  () => store.state.client.client
);
const isAvailableUserList: ComputedRef<boolean> = computed(() => {
  if (loginUser.value === null) {
    return false;
  }
  return loginUser.value.permission.isAvailableUserList;
});
const isFunnelAvailable: ComputedRef<boolean> = computed(() => {
  if (loginClient.value == null) {
    return false;
  }
  return (
    isAvailableUserList.value && loginClient.value.hasFunnelAnalysisContract
  );
});
const isUserTrendAvailable: ComputedRef<boolean> = computed(() => {
  if (loginUser.value == null) {
    return false;
  }
  return loginUser.value.permission.isAvailableUserTrend;
});
const canUseWebdataFeatures: ComputedRef<boolean> = computed(() => {
  return store.state.app.canUseWebdataFeatures;
});
const isContractApp: ComputedRef<boolean> = computed(() => {
  if (loginClient.value == null) {
    return false;
  }
  return loginClient.value.isContractApp;
});
const isUserTrendAppAvailable: ComputedRef<boolean> = computed(
  () => isUserTrendAvailable.value && isContractApp.value
);
const isUserTrendWebAvailable: ComputedRef<boolean> = computed(
  () => isUserTrendAvailable.value && canUseWebdataFeatures.value
);

function isGuestUser(): boolean {
  if (loginUser.value == null) return false;
  return loginUser.value.isGuest;
}
const isToursAvailable: ComputedRef<boolean> = computed(() => {
  if (loginClient.value == null) {
    return false;
  }
  return !isGuestUser() && store.state.app.canUseTourFeatures;
});
const behaviorAnalysisCategory: Category = {
  category: t("behaviorAnalysis") as string,
  items: [
    {
      icon: "horizontal-bars",
      text: t("funnelAnalysis") as string,
      url: "/funnel",
      show: isFunnelAvailable.value,
      dataCy: "global_nav_funnel_analysis"
    },
    {
      icon: "branches",
      text: t("journeyAnalysis") as string,
      url: "/journey",
      show: true
    },
    {
      icon: "analysis",
      text: t("accessAnalysis") as string,
      storageKey: "expandable_analysis",
      dataCy: "toggle_analysis_side_bar",
      show: true,
      subitems: [
        {
          icon: "analysis",
          text: t("pageAnalysis") as string,
          routeName: AnalysisType.WebView,
          show: isUserTrendWebAvailable.value,
          dataCy: "global_nav_user-trend_web_view"
        },
        {
          icon: "analysis",
          text: t("landingPageAnalysis") as string,
          routeName: AnalysisType.Landing,
          show: isUserTrendWebAvailable.value,
          dataCy: "global_nav_user-trend_landing"
        },
        {
          icon: "analysis",
          text: t("inflowSourceAnalysis") as string,
          routeName: AnalysisType.Inflow,
          show: isUserTrendWebAvailable.value,
          dataCy: "global_nav_user-trend_inflow"
        },
        {
          icon: "analysis",
          text: t("appPageAnalysis") as string,
          routeName: AnalysisType.AppView,
          show: isUserTrendAppAvailable.value,
          dataCy: "global_nav_user-trend_app_view"
        },
        {
          icon: "analysis",
          text: t("accessOverview") as string,
          url: "/stats",
          show: true
        }
      ]
    }
  ]
};

const thematicAnalysisCategory: Category = {
  category: t("thematicAnalysis") as string,
  items: [
    {
      icon: "thematic-analysis",
      text: t("issueDiscoveryTour") as string,
      routeName: "tours",
      url: "/tours/",
      show: isToursAvailable.value,
      dataCy: "global_nav_tours"
    }
  ]
};
const favoriteSearchItems: ComputedRef<Item[]> = computed(() =>
  favoriteSearches.value.map((search: FavoriteSearch) => ({
    icon: "pin",
    text: search.label,
    favoriteSearch: search,
    show: true
  }))
);
const myDataCategory = computed(() => {
  return {
    category: t("myData") as string,
    items: [
      {
        icon: "heart",
        text: t("bookmarkedUsers") as string,
        url: "/bookmark-users",
        show: true,
        dataCy: "global_nav_favorite_users"
      },
      {
        icon: "pin",
        text: t("savedSearchConditions") as string,
        storageKey: "expandable_pin",
        subitems: favoriteSearchItems.value,
        show: isAvailableUserList.value,
        dataCy: "toggle_favorite_search"
      },
      {
        icon: "history",
        text: t("watchHistory") as string,
        url: "/watch-history",
        show: true,
        dataCy: "global_nav_watch_history"
      },
      {
        icon: "memo",
        text: t("memoUsers") as string,
        routeName: "memo-users",
        show: true
      }
    ]
  };
});

const categories = computed(() => {
  if (isGuestUser()) {
    return [myDataCategory.value];
  }
  // If tour is not available, there is no meaning to show the thematic category.
  if (!isToursAvailable.value) {
    return [behaviorAnalysisCategory, myDataCategory.value];
  }

  return [
    behaviorAnalysisCategory,
    thematicAnalysisCategory,
    myDataCategory.value
  ];
});

const showMinimized = computed(
  () =>
    isSideBarMinimized.value &&
    !isHovered.value &&
    !isFavoriteSearchBalloonShown.value
);

function setHoverState(hovered: boolean) {
  if (!isSideBarMinimized.value) return;
  isHovered.value = hovered;
}

interface RouteUrl {
  routeName?: string;
  url?: string;
}

function goToPage({ routeName, url }: RouteUrl) {
  if (routeName) router.push({ name: routeName });
  else if (url) {
    const origin = window.location.origin;
    window.open(origin + url, "_self");
  }
}

function getIsExpanded(storageKey: StorageKey | undefined): boolean {
  switch (storageKey) {
    case "expandable_analysis":
      return isAnalysisExpanded.value;
    case "expandable_pin":
      return isPinExpanded.value;
    default:
      return false;
  }
}

function onToggleSubItems(storageKey: StorageKey | undefined) {
  if (storageKey === "expandable_analysis") {
    setIsAnalysisExpanded(!isAnalysisExpanded.value);
  } else if (storageKey === "expandable_pin") {
    setIsPinExpanded(!isPinExpanded.value);
  }
}

watch(
  () => route.name,
  () => {
    if (Object.values(AnalysisType).includes(route.name as AnalysisType)) {
      setIsAnalysisExpanded(true);
    }
  }
);
</script>

<style lang="scss" scoped>
.side-bar {
  background: $colorElmentUIBlue100;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  left: 0;
  position: sticky;
  top: 70px;
  height: calc(100vh - 70px);
  transition: width 0.15s ease-out, margin-left 0.15s ease-out;
  width: $sideNavigationExpandedWidth;

  &--minimized {
    transition: width 0.1s ease-out, margin-left 0.1s ease-out;
    width: $sideNavigationMinimizedWidth;
  }

  &--hovered {
    box-shadow: 1px 0px 8px rgba($colorGray1000, 0.12);
    width: $sideNavigationExpandedWidth;
  }
}

.side-bar__items {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.side-bar__home-item {
  padding-top: 10px;
}

.side-bar__footer {
  align-items: start;
  border-top: 1px solid $colorBase500;
  color: $colorBase700;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 73px;
  padding: 22px 0 40px 22px;
  white-space: nowrap;
}
</style>

<i18n lang="yml">
en:
  home: "Home"
  behaviorAnalysis: "Behavior Analysis"
  funnelAnalysis: "Funnel analysis"
  journeyAnalysis: "Journey analysis(beta)"
  accessAnalysis: "Access analysis"
  pageAnalysis: "Page view analysis"
  landingPageAnalysis: "Landing page analysis"
  inflowSourceAnalysis: "Inflow source analysis"
  appPageAnalysis: "Screen analysis"
  accessOverview: "Access overview(beta)"
  thematicAnalysis: "Thematic Analysis"
  issueDiscoveryTour: "Issue Discovery Tour"
  myData: "My Data"
  bookmarkedUsers: "Bookmarked users"
  savedSearchConditions: "Saved search conditions"
  watchHistory: "Browse history"
  memoUsers: "Notes list"
ja:
  home: "ホーム"
  behaviorAnalysis: "行動分析"
  funnelAnalysis: "行動ファネル分析"
  journeyAnalysis: "ジャーニー分析 (β版)"
  accessAnalysis: "アクセス・CV貢献分析"
  pageAnalysis: "ページ分析"
  landingPageAnalysis: "入口ページ分析"
  inflowSourceAnalysis: "流入元分析"
  appPageAnalysis: "スクリーン分析"
  accessOverview: "アクセス概要 (β版)"
  thematicAnalysis: "テーマ別分析"
  issueDiscoveryTour : "課題発見ツアー"
  myData: "マイデータ"
  bookmarkedUsers: "お気に入りユーザ"
  savedSearchConditions: "お気に入り検索条件"
  watchHistory: "ユーザ閲覧履歴"
  memoUsers: "メモ履歴"
zh-hant:
  home: "主屏幕"
  behaviorAnalysis: "行為分析"
  funnelAnalysis: "程序分析"
  journeyAnalysis: "旅程分析(beta)"
  accessAnalysis: "訪問分析"
  pageAnalysis: "頁面視圖分析"
  landingPageAnalysis: "登陸頁面視圖分析"
  inflowSourceAnalysis: "流入源分析"
  appPageAnalysis: "手機頁面分析"
  accessOverview: "訪問概覽(beta)"
  thematicAnalysis: "專題分析"
  issueDiscoveryTour: "尋找問題之旅"
  myData: "我的數據"
  bookmarkedUsers: "關注的使用者"
  savedSearchConditions: "已保存的搜索條件"
  watchHistory: "用戶瀏覽歷史"
  memoUsers: "備忘錄紀錄"
zh-hans:
  home: "主屏幕"
  behaviorAnalysis: "行为分析"
  funnelAnalysis: "漏斗分析"
  journeyAnalysis: "旅程分析(beta)"
  accessAnalysis: "访问分析"
  pageAnalysis: "页面视图分析"
  landingPageAnalysis: "登陆页面视图分析"
  inflowSourceAnalysis: "流入源分析"
  appPageAnalysis: "手机页面分析"
  accessOverview: "访问概览(beta)"
  thematicAnalysis: "专题分析"
  issueDiscoveryTour: "问题发现之旅"
  myData: "我的数据"
  bookmarkedUsers: "关注的人"
  savedSearchConditions: "保存的搜索条件"
  watchHistory: "用户浏览历史"
  memoUsers: "备忘录记录"
</i18n>
