import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";

export interface TdConversionAttributeRequest {
  cv_attr_id: number;
  cv_attr_column: string;
  is_disabled: boolean;
}
export interface TdConversionAttributeResponse
  extends TdConversionAttributeRequest {
  id: number;
}

export default class ApiTdConversionAttribute {
  constructor(private readonly httpClient: HttpClient) {}
  public getTdConversionAttributeList = (): Promise<
    TdConversionAttributeResponse[]
  > => {
    return this.httpClient.get<TdConversionAttributeResponse[]>(
      ApiUrl.TD_CONVERSION_ATTRIBUTE_LIST
    );
  };

  public getTdConversionAttributeById = (
    id: number
  ): Promise<TdConversionAttributeResponse> => {
    return this.httpClient.get<TdConversionAttributeResponse>(
      ApiUrl.TD_CONVERSION_ATTRIBUTE_DETAIL(id)
    );
  };

  public createTdConversionAttribute = (
    payload: TdConversionAttributeRequest
  ): Promise<TdConversionAttributeResponse> => {
    return this.httpClient.post<TdConversionAttributeResponse>(
      ApiUrl.TD_CONVERSION_ATTRIBUTE_LIST,
      payload
    );
  };

  public updateTdConversionAttribute = (
    id: number,
    payload: TdConversionAttributeRequest
  ): Promise<TdConversionAttributeResponse> => {
    return this.httpClient.put<TdConversionAttributeResponse>(
      ApiUrl.TD_CONVERSION_ATTRIBUTE_DETAIL(id),
      payload
    );
  };
}
