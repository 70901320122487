var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c('div',{ref:"appHeader",staticClass:"app__header"},[(
        _vm.isInitialized &&
          _vm.isLoggedInAndAuthenticated &&
          !_vm.shouldHideHeaderAndSideBar
      )?_c('Header',{on:{"click-burger":_vm.clickBurger,"show-search-form":_vm.changeSearchFormIsDisplayed,"show-filter-form":_vm.changeFilterFormIsDisplayed}}):_vm._e()],1),(!_vm.isInitialized)?[_c('Loading')]:[(_vm.isLoggedInAndAuthenticated && !_vm.shouldHideHeaderAndSideBar)?_c('div',{staticClass:"app__globalNaviContainer",class:[
        _vm.expandGlobalNav
          ? 'app__globalNaviContainer--expanded'
          : 'app__globalNaviContainer--condensed'
      ]},[_c('ScrollWrapper',{attrs:{"overflow":_vm.overflow.ScrollYOnlyEnabled,"show-scrollbar-with-mouse-enter":true},on:{"update:scroll-y":function($event){return _vm.scrollNavbar($event)}}},[_c('GlobalNav',{staticClass:"app__globalNavi",attrs:{"expand-nav":_vm.expandGlobalNav,"hovered-favorite-search-id":_vm.hoveredFavoriteSearch !== null ? _vm.hoveredFavoriteSearch.id : -1},on:{"mouse-enter-item":_vm.onMouseEnterSavedSearchColumn,"mouse-leave-item":_vm.onMouseLeaveSavedSearchColumn,"mouse-enter-navi":_vm.onMouseEnterNavi,"mouse-leave-navi":_vm.onMouseLeaveNavi}})],1)],1):_vm._e(),_c('div',{staticClass:"app_savedSearchBalloon",style:(_vm.style),on:{"mouseenter":_vm.onMouseEnterSavedSearchBalloon,"mouseleave":_vm.onMouseLeaveSavedSearchBalloon}},[(_vm.showBalloon)?_c('SavedSearchBalloon',{attrs:{"favorite-search":_vm.hoveredFavoriteSearch,"adjust":_vm.balloonArrowAdjust}}):_vm._e()],1),_c('div',{ref:"appContent",staticClass:"app__content",class:_vm.addCotentClass,style:({ top: _vm.appContentTop }),attrs:{"data-cy":"ug-main"}},[_c('RouterView')],1),_c('BlogLinkContainer',{staticClass:"app__blogLinkContainer",attrs:{"search-form-is-displayed":_vm.searchFormIsDisplayed,"filter-form-is-displayed":_vm.filterFormIsDisplayed}}),_c('Transition',{attrs:{"name":"fade"}},[(_vm.isDownloading)?_c('div',{staticClass:"app__csvDownloadProgress"},[_c('CsvDownloadProgress',{attrs:{"file-name":_vm.csvFileName}})],1):_vm._e()])],_c('AlertDialog',{attrs:{"data-cy":"alert-dialog","message":_vm.alertMessage},on:{"close":_vm.closeAlertModal}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }