import { render, staticRenderFns } from "./FilterPeriod.vue?vue&type=template&id=d38f7076&scoped=true"
import script from "./FilterPeriod.vue?vue&type=script&lang=ts"
export * from "./FilterPeriod.vue?vue&type=script&lang=ts"
import style0 from "./FilterPeriod.vue?vue&type=style&index=0&id=d38f7076&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d38f7076",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/filter/filter-period.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ffilter%2FFilterPeriod.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports