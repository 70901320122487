import { render, staticRenderFns } from "./TdConversionAttributeSettings.vue?vue&type=template&id=97ad7118&scoped=true"
import script from "./TdConversionAttributeSettings.vue?vue&type=script&lang=ts"
export * from "./TdConversionAttributeSettings.vue?vue&type=script&lang=ts"
import style0 from "./TdConversionAttributeSettings.vue?vue&type=style&index=0&id=97ad7118&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ad7118",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/client-settings/td-conversion-attribute.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2Fclient-settings%2FTdConversionAttributeSettings.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports