import { MutationTree, ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store/";
import { CompanyActivity } from "@/models/CompanyActivity";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";

export class CompanyActivityDisplaySettingState {
  displaySettings: CompanyActivity[] = [];
}

// 企業側の活動がある行動履歴のdata type
const hasCompanyActivityContactDataTypes = [
  ContactDefinitionType.TEL,
  ContactDefinitionType.MAIL,
  ContactDefinitionType.DM,
  ContactDefinitionType.VISIT,
  ContactDefinitionType.APP
];

const mutations: MutationTree<CompanyActivityDisplaySettingState> = {
  setDisplaySettings(
    state: CompanyActivityDisplaySettingState,
    CompanyActivitys: CompanyActivity[]
  ) {
    state.displaySettings = CompanyActivitys;
  },
  initialize(state: CompanyActivityDisplaySettingState) {
    state.displaySettings = [];
  }
};

const actions: ActionTree<CompanyActivityDisplaySettingState, RootState> = {
  setUp(
    store: ActionContext<CompanyActivityDisplaySettingState, any>,
    ContactDefinitions: ContactDefinition[]
  ) {
    // 行動履歴情報があるかつ、企業側のアクションがありえる物を抽出する
    const CompanyActivitys: CompanyActivity[] = [];
    ContactDefinitions.forEach(contact => {
      if (
        !contact.isDisabled &&
        hasCompanyActivityContactDataTypes.indexOf(contact.type) !== -1
      ) {
        CompanyActivitys.push(new CompanyActivity(contact));
      }
    });
    store.commit("setDisplaySettings", CompanyActivitys);
  },
  update(
    store: ActionContext<CompanyActivityDisplaySettingState, any>,
    settings: CompanyActivity[]
  ) {
    store.commit("setDisplaySettings", settings);
  }
};

export const companyActivityDisplaySetting = {
  namespaced: true,
  state: new CompanyActivityDisplaySettingState(),
  mutations: mutations,
  actions: actions
};
