<i18n
  src="@/i18n/views/client-settings/conversion/conversion-settings-change-measurement-state.json"
></i18n>
<template>
  <div class="changeMeasurementState">
    <div v-t="'title'" class="changeMeasurementState__title" />
    <template v-if="isLoading">
      <Loading height="80px" />
    </template>
    <template
      v-else-if="
        isEnableMeasuring || (!isAppUseConversion && !isTdUseConversion)
      "
    >
      <div
        v-t="isEnableMeasuring ? 'enableDescription' : 'disableDescription'"
        class="changeMeasurementState__description"
      />
      <ConversionConfirmTable :name="formData.name" :path="formData.path" />
      <div class="changeMeasurementState__formButtons">
        <Button
          v-t="isEnableMeasuring ? 'beginMeasuring' : 'stopMeasuring'"
          class="changeMeasurementState__submitButton"
          :disabled="isSubmitting"
          padding="0 20px"
          :text-size="buttonTextSize"
          @click="onClickSubmit"
        />
        <Button
          v-t="'cancel'"
          class="changeMeasurementState__cancelButton"
          padding="0 20px"
          width="120px"
          :text-size="buttonTextSize"
          :type="buttonTypeCancel"
          @click="onClickCancel"
        />
      </div>
    </template>
    <template v-else>
      <div v-if="isAppUseConversion" class="changeMeasurementStateApp">
        <div
          v-t="'mapConversionUsageWarning'"
          class="changeMeasurementState__description"
        />
        <div class="changeMeasurementState_formWarning">
          <SettingsTable>
            <SettingsTableRow>
              <SettingsTableCell width="680px" :style="{ padding: '15px' }">
                <a :href="coordinationLink">
                  {{ coordinationName }}
                </a>
              </SettingsTableCell>
            </SettingsTableRow>
          </SettingsTable>
        </div>
      </div>
      <div v-if="isTdUseConversion" class="changeMeasurementStateTd">
        <div
          v-t="'tdUsageConversionWarning'"
          class="changeMeasurementState__description"
        />
        <div class="changeMeasurementState_formWarning">
          <SettingsTable>
            <SettingsTableRow>
              <SettingsTableCell width="680px" :style="{ padding: '15px' }">
                <RouterLink :to="{ name: 'td-conversion-settings' }">
                  {{ $t("tdConversionSetting") }}
                </RouterLink>
              </SettingsTableCell>
            </SettingsTableRow>
          </SettingsTable>
        </div>
      </div>
      <div class="changeMeasurementState__formButtons">
        <Button
          v-t="'cancel'"
          class="changeMeasurementState__cancelButton"
          padding="0 20px"
          width="120px"
          :text-size="buttonTextSize"
          :type="buttonTypeCancel"
          @click="onClickCancel"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { ButtonTextSize, BUTTON_TYPE } from "@/const/button";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import Button from "@/components/Button.vue";
import ConversionConfirmTable from "@/components/client-settings/conversion/ConversionConfirmTable.vue";
import Loading from "@/components/Loading.vue";
import {
  ConversionDefinition,
  ConversionEditableData
} from "@/models/client-settings/ConversionDefinition";
import TdConversionDefinition from "@/models/client-settings/TdConversionDefinition";
import { AppConversionDefinition } from "@/models/client-settings/AppConversionDefinition";
import { Coordination } from "@/models/client-settings/Coordination";
import { handleError, handleNotFoundError } from "@/util/error-util";

@Component({
  components: {
    SettingsTable,
    SettingsTableCell,
    SettingsTableRow,
    Button,
    ConversionConfirmTable,
    Loading
  }
})
export default class ConversionSettingsMeasurementState extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  buttonTypeCancel = BUTTON_TYPE.LIGHT;
  isLoading = true;
  isSubmitting = false;

  formData: ConversionEditableData = { name: "", path: [""] };

  async beforeMount() {
    if (!this.isEnableMeasuring) return;

    if (!this.isConversionsFetched) {
      await this.$store
        .dispatch("conversion/fetchConversionList")
        .catch(handleError);
    }
    if (this.resourceLimitation <= this.activeConversions.length) {
      this.$router.push({ name: "conversion-settings-index" });
    }
  }

  async mounted() {
    await this.$store
      .dispatch("conversion/fetchConversionById", this.cvId)
      .catch(e =>
        handleNotFoundError(
          e,
          "conversion-settings-index",
          this.$t("404error").toString()
        )
      );

    this.formData = {
      name: this.currentConversion?.name || this.formData.name,
      path: this.currentConversion?.path || this.formData.path
    };
    if (this.isEnableMeasuring && !this.currentConversion?.isDisabled) {
      // Enabling 'enabled' conversion
      this.$router.push({ name: "conversion-settings-index" });
      return;
    }
    if (!this.isEnableMeasuring && this.currentConversion?.isDisabled) {
      // Disabling 'disabled' conversion
      this.$router.push({ name: "conversion-settings-index" });
      return;
    }

    await Promise.all([
      this.$store.dispatch("appConversion/fetchAppConversionList"),
      this.$store.dispatch("tdConversion/fetchTdConversions")
    ]).catch(handleError);

    this.isLoading = false;
  }

  get isConversionsFetched(): boolean {
    return this.$store.state.conversion.isConversionsFetched;
  }

  get activeConversions(): ConversionDefinition[] {
    return this.$store.getters["conversion/activeConversions"];
  }

  get activeAppConversions(): AppConversionDefinition[] {
    return this.$store.getters["appConversion/activeAppConversions"];
  }

  get coordinations(): Coordination[] {
    return this.$store.state.clientSettings.coordinations;
  }

  get resourceLimitation(): number {
    return this.$store.state.client.client.resourceLimitation.conversion;
  }

  get currentConversion(): ConversionDefinition | null {
    return this.$store.state.conversion.currentConversion;
  }

  get isEnableMeasuring() {
    return this.$route.params.type === "enable";
  }

  get cvId(): number {
    return Number(this.$route.params.id);
  }

  get isAppUseConversion(): boolean {
    return !!this.usedCoordination;
  }

  get isTdUseConversion(): boolean {
    return (
      this.activeTdConversions.filter(tc => tc.cvId === this.cvId).length > 0
    );
  }

  get activeTdConversions(): TdConversionDefinition[] {
    const allTdConversions: TdConversionDefinition[] = this.$store.state
      .tdConversion.tdConversionDefinitions;

    return allTdConversions.filter(tc => !tc.isDisabled);
  }

  get usedAppConversion(): AppConversionDefinition | undefined {
    return this.activeAppConversions.find(ac => ac.conversionId === this.cvId);
  }

  get usedCoordination(): Coordination | undefined {
    const coordinationId = this.usedAppConversion?.coordinationId;
    return this.coordinations.find(c => c.id === coordinationId);
  }

  get coordinationLink(): string {
    if (this.usedCoordination) {
      return `/client-settings/coordination/${this.usedCoordination.id}/conversion/index/`;
    }

    return "/client-settings/coordination/index/";
  }

  get coordinationName(): string {
    return this.usedCoordination?.name || "";
  }

  async onClickSubmit() {
    if (!this.currentConversion) return;
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const payload = {
      id: this.currentConversion.id,
      param: {
        is_disabled: !this.isEnableMeasuring
      }
    };
    await this.$store
      .dispatch("conversion/updateConversion", payload)
      .then(() => {
        this.$router.push({ name: "conversion-settings-index" });
      })
      .catch(handleError)
      .finally(() => (this.isSubmitting = false));
  }

  onClickCancel() {
    this.$router.push({ name: "conversion-settings-index" });
  }

  destroyed() {
    this.$store.commit("conversion/setCurrentConversion", null);
  }
}
</script>

<style lang="scss" scoped>
.changeMeasurementState {
  margin-right: 20px;
  min-width: 704px;
}

.changeMeasurementState__title {
  padding: 0 0 46px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: inherit;
}

.changeMeasurementState__description {
  margin-bottom: 30px;
  white-space: pre-line;
  line-height: 2;
}

.changeMeasurementState_formWarning {
  margin-bottom: 30px;

  a {
    color: $colorBase700;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $colorBase800;
    }
  }
}

.changeMeasurementState__submitButton {
  margin-right: 20px;
}
</style>
