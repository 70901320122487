<template>
  <div class="user-icon">
    <div class="userIcon__icon">
      <div v-show="isChecked" class="user-checked-icon">
        <Icon :icon="iconChecked" :size="10" />
      </div>
      <UserIconPc1
        v-if="isPc && gazeLevel === 1"
        class="user-icon__pc user-icon__pc--level-1"
      />
      <UserIconPc2
        v-else-if="isPc && gazeLevel === 2"
        class="user-icon__pc user-icon__pc--level-2"
      />
      <UserIconPc3
        v-else-if="isPc && gazeLevel === 3"
        class="user-icon__pc user-icon__pc--level-3"
      />
      <UserIconSp1
        v-else-if="!isPc && gazeLevel === 1"
        class="user-icon__sp user-icon__sp--level-1"
      />
      <UserIconSp2
        v-else-if="!isPc && gazeLevel === 2"
        class="user-icon__sp user-icon__sp--level-2"
      />
      <UserIconSp3
        v-else-if="!isPc && gazeLevel === 3"
        class="user-icon__sp user-icon__sp--level-3"
      />
      <UserIconDefault v-else class="user-icon__default" />
    </div>
    <div v-if="isApplication" class="user-icon__subtext">App</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import UserIconPc1 from "@/assets/svg/user/pc-1.svg";
import UserIconPc2 from "@/assets/svg/user/pc-2.svg";
import UserIconPc3 from "@/assets/svg/user/pc-3.svg";
import UserIconSp1 from "@/assets/svg/user/smp-1.svg";
import UserIconSp2 from "@/assets/svg/user/smp-2.svg";
import UserIconSp3 from "@/assets/svg/user/smp-3.svg";
import UserIconDefault from "@/assets/svg/user/face.svg";

@Component({
  components: {
    Icon,
    UserIconPc1,
    UserIconPc2,
    UserIconPc3,
    UserIconSp1,
    UserIconSp2,
    UserIconSp3,
    UserIconDefault
  }
})
export default class UserIcon extends Vue {
  iconChecked = Icons.Checked;

  @Prop({ type: Boolean, required: true })
  isPc!: boolean;

  @Prop({ type: Boolean, required: false })
  isApplication!: boolean;

  @Prop({ type: Number, required: true })
  gazeLevel!: number;

  @Prop({ type: Boolean, default: false })
  isChecked!: boolean;
}
</script>

<style scoped lang="scss">
.user-icon {
  position: relative;
  display: table;
  margin-right: auto;
  margin-left: auto;
  width: 48px;
  text-align: center;
}

.userIcon__icon {
  display: inline-flex;
  align-items: center;
  height: 44px;
  vertical-align: top;
}

.user-icon__pc {
  width: 48px;
  height: 42px;
}

.user-icon__sp {
  width: 40px;
  height: 38px;
}

.user-icon__default {
  width: 40px;
  height: 40px;
}

.user-checked-icon {
  position: absolute;
  left: -18px;
}

.user-icon__subtext {
  position: absolute;
  right: -10px;
  bottom: -1px;
  color: $colorBase700;
  font-weight: bold;
  font-size: 12px;
  transform: translate(100%, -50%);
}
</style>
