<template>
  <div v-if="show" class="user-base-balloon" :style="style">
    <Balloon :direction="balloonDirection" :adjust="adjust">
      <div class="user-base-balloon__inner">
        <slot></slot>
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BalloonDirection } from "@/const/balloon";

import Balloon from "@/components/Balloon.vue";

export enum UserBalloonDirection {
  Top,
  Middle,
  Bottom
}

@Component({
  components: {
    Balloon
  }
})
export default class UserBaseBalloon extends Vue {
  @Prop({ type: Boolean, default: false })
  show!: boolean;

  @Prop({ type: Number, required: true })
  direction!: UserBalloonDirection;

  @Prop({ type: String, default: "auto" })
  y!: string;

  @Prop({ type: String, default: "auto" })
  x!: string;

  @Prop({ type: Number, default: 0 })
  adjust!: number;

  get balloonDirection(): BalloonDirection {
    if (this.direction === UserBalloonDirection.Top) {
      return BalloonDirection.LeftStart;
    }
    if (this.direction === UserBalloonDirection.Middle) {
      return BalloonDirection.Left;
    }
    if (this.direction === UserBalloonDirection.Bottom) {
      return BalloonDirection.LeftEnd;
    }
    return BalloonDirection.LeftStart;
  }

  get style() {
    if (this.direction === UserBalloonDirection.Middle) {
      return {
        left: this.x,
        top: this.y,
        transform: "translateY(-50%)"
      };
    }
    if (this.direction === UserBalloonDirection.Bottom) {
      return {
        left: this.x,
        bottom: this.y
      };
    }
    return {
      left: this.x,
      top: this.y
    };
  }
}
</script>

<style scoped lang="scss">
.user-base-balloon {
  position: absolute;

  &__inner {
    padding: 20px;
    min-width: 450px;
  }
}
</style>
