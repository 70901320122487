<template>
  <rect :width="width" :height="height" :x="x" y="0" class="chartHighlight" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ChartHighlight extends Vue {
  @Prop({ type: Number, required: true })
  width!: number;

  @Prop({ type: Number, required: true })
  height!: number;

  @Prop({ type: Number, required: true })
  x!: number;
}
</script>

<style scoped lang="scss">
.chartHighlight {
  fill: $colorBlue600;
}
</style>
