<template>
  <g class="chartOmoIcon" :transform="chartOmoIconTransform">
    <rect
      class="chartOmoIcon__bg"
      ry="3"
      rx="3"
      :width="width"
      :height="height"
      :fill="fill"
    />

    <text
      class="chartOmoIcon__text"
      :dx="textX"
      :dy="textY"
      :font-size="fontSize"
    >
      {{ content }}
    </text>

    <rect
      class="chartOmoIcon__overlap"
      data-cy="chart-omo-icon"
      :width="width"
      :height="height"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    />
  </g>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import {
  SCOREBOX_Y,
  SCOREBOX_HEIGHT,
  SCOREBOX_FONT_SIZE,
  SCOREBOX_DEFAULT_SCALE,
  MAGNIFICATION_HOVER_EFFECT
} from "@/components/chart/chart-util";

@Component
export default class ChartOmoIcon extends Vue {
  @Prop({ type: Number, required: true })
  width!: number;

  @Prop({ type: Number, required: true })
  x!: number;

  @Prop({ type: String, required: true })
  fill!: Colors;

  @Prop({ type: String, required: true })
  content!: string;

  @Emit("click")
  onClick() {}

  @Emit("mouseenter")
  onMouseEnter() {
    this.isHovered = true;
  }

  @Emit("mouseleave")
  onMouseLeave() {
    this.isHovered = false;
  }

  height = SCOREBOX_HEIGHT;
  fontSize = SCOREBOX_FONT_SIZE;
  textY: number = this.fontSize - this.height / 2 + this.fontSize;
  isHovered = false;

  get chartOmoIconTransform(): string {
    const scale = this.isHovered
      ? MAGNIFICATION_HOVER_EFFECT
      : SCOREBOX_DEFAULT_SCALE;
    const scaleWidth = this.width * MAGNIFICATION_HOVER_EFFECT;
    const scaleHeight = this.height * MAGNIFICATION_HOVER_EFFECT;
    const hoveredX = this.x - (scaleWidth - this.width) / 2;
    const hoveredY = SCOREBOX_Y - (scaleHeight - this.height) / 2;
    const x: number = this.isHovered ? hoveredX : this.x;
    const y: number = this.isHovered ? hoveredY : SCOREBOX_Y;

    return "translate(" + x + "," + y + ") scale(" + scale + ")";
  }

  get textX(): number {
    return this.width / 2;
  }
}
</script>

<style lang="scss" scoped>
.chartOmoIcon {
  transition: transform 0.2s ease-out;
}

.chartOmoIcon__bg {
  stroke: $colorWhite;
}

.chartOmoIcon__text {
  text-anchor: middle;
  fill: $colorWhite;
}

.chartOmoIcon__overlap {
  cursor: pointer;

  fill: $colorWhite;
  fill-opacity: 0;
  stroke-opacity: 0;
}
</style>
