import {
  ConversionDefinition,
  ConversionEditableData
} from "@/models/client-settings/ConversionDefinition";
import { RootState } from "@/store";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { UpdateConversionParam } from "@/api/apis/client-settings/ApiConversion";
export class ConversionState {
  conversionList: ConversionDefinition[] = [];
  currentConversion: ConversionDefinition | null = null;
  previouslyCreatedOrUpdatedConversion: ConversionDefinition | null = null;
  isConversionsFetched: boolean = false;
  bulkCreatedConversion: ConversionEditableData[] = [];
}

const mutations: MutationTree<ConversionState> = {
  setConversions(
    state: ConversionState,
    conversionList: ConversionDefinition[]
  ) {
    state.conversionList = conversionList;
  },

  setCurrentConversion(
    state: ConversionState,
    conversion: ConversionDefinition | null
  ) {
    state.currentConversion = conversion;
  },

  setPreviouslyCreatedOrUpdatedConversion(
    state: ConversionState,
    conversion: ConversionDefinition | null
  ) {
    state.previouslyCreatedOrUpdatedConversion = conversion;
  },

  setIsConversionsFetched(state: ConversionState, isFetched: boolean) {
    state.isConversionsFetched = isFetched;
  },

  setBulkCreatedConversion(
    state: ConversionState,
    conversion: ConversionEditableData[]
  ) {
    state.bulkCreatedConversion = conversion;
  }
};

const getters = <GetterTree<ConversionState, RootState>>{
  activeConversions(state): ConversionDefinition[] {
    return state.conversionList.filter(cv => !cv.isDisabled);
  },
  disabledConversions(state): ConversionDefinition[] {
    return state.conversionList.filter(cv => cv.isDisabled);
  }
};

const actions: ActionTree<ConversionState, RootState> = {
  async fetchConversionList({ commit, rootState }) {
    commit("setIsConversionsFetched", false);

    const response = await rootState.api.conversion.getConversionList();
    commit("setConversions", response);
    commit("setIsConversionsFetched", true);
  },

  async fetchConversionById({ commit, rootState }, id: number) {
    const response = await rootState.api.conversion.getConversion(id);
    commit("setCurrentConversion", response);
  },

  async createNewConversion(
    { commit, rootState },
    conversion: ConversionEditableData
  ) {
    const params = conversion;
    const response = await rootState.api.conversion.createConversion(params);

    commit("setPreviouslyCreatedOrUpdatedConversion", response);
  },

  async createManyConversions(
    { commit, rootState },
    conversions: ConversionEditableData[]
  ) {
    const params = conversions;
    const response = await rootState.api.conversion.createManyConversions(
      params
    );

    commit("setBulkCreatedConversion", response);
  },

  async updateConversion(
    { commit, rootState },
    payload: { id: number; param: UpdateConversionParam }
  ) {
    const response = await rootState.api.conversion.updateConversion(
      payload.id,
      payload.param
    );

    commit("setPreviouslyCreatedOrUpdatedConversion", response);
  }
};

export const conversion = {
  namespaced: true,
  state: new ConversionState(),
  mutations,
  actions,
  getters
};
