<i18n
  src="@/i18n/views/client-settings/conversion/conversion-settings-create.json"
></i18n>
<template>
  <div class="conversionSettingsCreate">
    <div v-t="'title'" class="conversionSettingsCreate__title" />
    <ConversionSettingsCreateTab
      class="conversionSettingsCreate__tab"
      :selected-tab="selectedTab"
      :disable-bulk="isBulkTabDisabled"
      @changeTab="onSelectTab"
    />
    <div class="conversionSettingsCreate_tabContent">
      <template v-if="isSingleCvCreation">
        <div
          v-if="isConfirm"
          v-t="'message'"
          class="conversionSettingsCreate__message"
        />
        <ConversionConfirmTable
          v-if="isConfirm"
          :name="formData.name"
          :path="formData.path"
        />
        <ConversionEditTable
          v-else
          :name="formData.name"
          :path="formData.path"
          @update="onUpdate"
        />
        <div class="conversionSettingsCreate__buttonContainer">
          <Button
            v-t="isConfirm ? 'register' : 'confirm'"
            class="conversionSettingsCreate__confirmButton"
            :disabled="isSubmitButtonDisabled"
            width="120px"
            :text-size="buttonTextSize"
            @click="onClickConfirm"
          />
          <Button
            v-t="'cancel'"
            class="conversionSettingsCreate__cancelButton"
            type="light"
            width="120px"
            :text-size="buttonTextSize"
            @click="onClickCancel"
          />
        </div>
      </template>
      <template v-if="isBulkCvCreation">
        <div class="conversionSettingsBulkCreate__notice">
          <div
            v-t="'bulkRegistrationNoticeLead'"
            class="conversionSettingsBulkCreate__noticeLead"
          />
          <div
            v-t="'bulkRegistrationNoticeCaution'"
            class="conversionSettingsBulkCreate__noticeCaution"
          />
          <div class="conversionSettingsBulkCreate__noticeDetail">
            <ul>
              <li v-t="'bulkRegistrationNoticeDetail01'" />
              <li>
                {{
                  $t("bulkRegistrationNoticeDetail02", {
                    limit: conversionCreationLimit
                  })
                }}
              </li>
              <li v-t="'bulkRegistrationNoticeDetail03'" />
            </ul>
          </div>
        </div>
        <BulkConversionConfirmTable
          v-if="isConfirm"
          :bulk-data="prepareBulkConversionData"
        />
        <BulkConversionCreateTable
          v-else
          v-model="bulkConversionData"
          :number-of-rows="conversionCreationLimit"
          @bulkDataChanged="onBulkDataChanged"
        />
        <div class="conversionSettingsBulkCreate__buttonContainer">
          <Button
            v-t="isConfirm ? 'register' : 'confirm'"
            class="conversionSettingsBulkCreate__confirmButton"
            :disabled="isBulkSubmitButtonDisabled"
            width="120px"
            :text-size="buttonTextSize"
            @click="onBulkClickConfirm"
          />
          <Button
            v-t="'cancel'"
            class="conversionSettingsBulkCreate__cancelButton"
            type="light"
            width="120px"
            :text-size="buttonTextSize"
            @click="onClickCancel"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import ConversionConfirmTable from "@/components/client-settings/conversion/ConversionConfirmTable.vue";
import ConversionEditTable from "@/components/client-settings/conversion/ConversionEditTable.vue";
import Loading from "@/components/Loading.vue";
import { ButtonTextSize } from "@/const/button";
import {
  ConversionEditableData,
  isValidConversionData
} from "@/models/client-settings/ConversionDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import ConversionSettingsCreateTab, {
  ConversionCreationType
} from "@/components/client-settings/conversion/ConversionSettingsCreateTab.vue";
import BulkConversionCreateTable, {
  BulkConversion
} from "@/components/client-settings/conversion/BulkConversionCreateTable.vue";
import { handleError } from "@/util/error-util";
import BulkConversionConfirmTable from "@/components/client-settings/conversion/BulkConversionConfirmTable.vue";

@Component({
  components: {
    Button,
    ConversionConfirmTable,
    ConversionEditTable,
    Loading,
    ConversionSettingsCreateTab,
    BulkConversionCreateTable,
    BulkConversionConfirmTable
  }
})
export default class ConversionSettingsCreate extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isConfirm: boolean = false;
  isSubmitting: boolean = false;
  selectedTab = ConversionCreationType.Single;
  bulkConversionData: BulkConversion[] = [];

  formData: ConversionEditableData = { name: "", path: [""] };

  onUpdate(data: ConversionEditableData) {
    this.formData = data;
  }

  get isSingleCvCreation(): boolean {
    return this.selectedTab === ConversionCreationType.Single;
  }

  get isBulkCvCreation(): boolean {
    return this.selectedTab === ConversionCreationType.Bulk;
  }

  onSelectTab(selectedTab: ConversionCreationType) {
    this.isConfirm = false;
    this.selectedTab = selectedTab;
  }

  async onClickConfirm() {
    if (this.isConfirm) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      await this.$store
        .dispatch("conversion/createNewConversion", this.formData)
        .then(() => {
          this.$router.push({ name: "conversion-settings-index" });
        })
        .catch(handleError)
        .finally(() => (this.isSubmitting = false));
    } else {
      this.scrollTop();
      this.isConfirm = true;
    }
  }

  onBulkDataChanged(conversions: BulkConversion[]) {
    this.bulkConversionData = conversions;
  }

  get prepareBulkConversionData(): ConversionEditableData[] {
    return this.bulkConversionData.map(conversion => {
      return {
        name: conversion.name,
        path: [
          conversion.path_1,
          conversion.path_2,
          conversion.path_3,
          conversion.path_4,
          conversion.path_5
        ].filter(path => path !== "")
      };
    });
  }

  get isBulkSubmitButtonDisabled(): boolean {
    return (
      this.prepareBulkConversionData.length === 0 ||
      this.prepareBulkConversionData.some(data => !isValidConversionData(data))
    );
  }

  async onBulkClickConfirm() {
    if (this.isConfirm) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      await this.$store
        .dispatch(
          "conversion/createManyConversions",
          this.prepareBulkConversionData
        )
        .then(() => {
          this.$router.push({ name: "conversion-settings-index" });
        })
        .catch(handleError)
        .finally(() => (this.isSubmitting = false));
    } else {
      this.scrollTop();
      this.isConfirm = true;
    }
  }

  onClickCancel() {
    if (this.isConfirm) {
      this.scrollTop();
      this.isConfirm = false;
    } else {
      this.$router.push({ name: "conversion-settings-index" });
    }
  }

  get isConversionsFetched(): boolean {
    return this.$store.state.conversion.isConversionsFetched;
  }

  get activeConversions(): ConversionDefinition[] {
    return this.$store.getters["conversion/activeConversions"];
  }

  get resourceLimitation(): number {
    return this.$store.state.client.client.resourceLimitation.conversion;
  }

  get conversionCreationLimit(): number {
    return this.resourceLimitation - this.activeConversions.length;
  }

  get isBulkTabDisabled(): boolean {
    return this.conversionCreationLimit <= 1;
  }

  get isSubmitButtonDisabled(): boolean {
    if (this.isSubmitting) return true;

    return !isValidConversionData(this.formData);
  }

  async beforeMount() {
    if (!this.isConversionsFetched) {
      await this.$store
        .dispatch("conversion/fetchConversionList")
        .catch(handleError);
    }

    if (this.conversionCreationLimit <= 0) {
      this.$router.push({ name: "conversion-settings-index" });
    }
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
</script>
<style lang="scss" scoped>
.conversionSettingsCreate {
  margin-right: 20px;
  min-width: 704px;
}
.conversionSettingsCreate__title {
  padding: 0 0 46px 0;
  font-size: 20px;
  font-weight: 700;
}
.conversionSettingsCreate__message {
  margin-bottom: 30px;
  font-size: 14px;
}
.conversionSettingsCreate__buttonContainer,
.conversionSettingsBulkCreate__buttonContainer {
  margin-top: 16px;
}
.conversionSettingsCreate__confirmButton,
.conversionSettingsBulkCreate__confirmButton {
  margin-right: 20px;
}

.conversionSettingsCreate_tabContent {
  margin-top: 30px;
}
.conversionSettingsBulkCreate__notice {
  font-size: 12px;
  margin-bottom: 40px;
}
.conversionSettingsBulkCreate__noticeLead {
  padding-bottom: 46px;
  line-height: 2;
}
.conversionSettingsBulkCreate__noticeCaution {
  color: $colorRedForNote;
  font-weight: bold;
  margin-bottom: 12px;
}
.conversionSettingsBulkCreate__noticeDetail {
  ul {
    padding-left: 20px;
  }
  li {
    margin-bottom: 12px;
    list-style: disc;
  }
}
</style>
