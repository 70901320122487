<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :title="$t('nodeTitleInflow')"
    class="filter-node-inflow"
    :show-add-condition="false"
    :select-exclusion-type-value="exclutionTypeValue"
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <SelectBox
      v-model="selectValue"
      width="280px"
      :options="condition.selectOption()"
    />

    <ChildFilterNodeReferrer
      v-if="isReferrer"
      v-model="childCondition"
      :depth="condition.depth"
      :is-first-node="isFirstNode"
    />
    <ChildFilterNodeInflowParameter
      v-if="isInflowParameter"
      v-model="childCondition"
      :depth="condition.depth"
      :is-first-node="isFirstNode"
    />
    <ChildFilterNodeSearchEngine
      v-if="isSearchEngine"
      v-model="childCondition"
      :depth="condition.depth"
      :is-first-node="isFirstNode"
      :search-engines="searchEngines"
    />
    <ChildFilterNodeAd
      v-if="isAd"
      v-model="childCondition"
      :depth="condition.depth"
      :is-first-node="isFirstNode"
    />
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import { SearchEngine } from "@/models/system/SearchEngine";
import { ExclusionProps } from "@/models/search/filter-node/FilterExclusion";
import {
  FilterNodeForInflow,
  InflowConditionType
} from "@/models/search/filter-node/FilterNodeForInflow";
import { ChildFilterNodeForReferrer } from "@/models/search/filter-node/ChildFilterNodeForReferrer";
import { ChildFilterNodeForInflowParameter } from "@/models/search/filter-node/ChildFilterNodeForInflowParameter";
import { ChildFilterNodeForSearchEngine } from "@/models/search/filter-node/ChildFilterNodeForSearchEngine";
import { ChildFilterNodeForAd } from "@/models/search/filter-node/ChildFilterNodeForAd";
import {
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import { FilterReferrerUrlCondition } from "@/models/search/filter-node-condition/FilterReferrerUrlCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

import ChildFilterNodeReferrer from "@/components/filter/ChildFilterNodeReferrer.vue";
import ChildFilterNodeInflowParameter from "@/components/filter/ChildFilterNodeInflowParameter.vue";
import ChildFilterNodeSearchEngine from "@/components/filter/ChildFilterNodeSearchEngine.vue";
import ChildFilterNodeAd from "@/components/filter/ChildFilterNodeAd.vue";
import FilterNode from "@/components/filter/FilterNode.vue";
import SelectBox from "@/components/form/SelectBox.vue";

@Component({
  name: "FilterNodeInflow",
  components: {
    FilterNode,
    SelectBox,
    ChildFilterNodeReferrer,
    ChildFilterNodeInflowParameter,
    ChildFilterNodeSearchEngine,
    ChildFilterNodeAd
  }
})
export default class FilterNodeInflow extends Vue {
  searchEngines: SearchEngine[] = this.$store.state.system.searchEngines;

  @Model("input", { type: FilterNodeForInflow, required: true })
  condition!: FilterNodeForInflow;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  onInput(condition: FilterNodeForInflow) {
    this.$emit("input", condition);
  }

  get selectValue(): InflowConditionType {
    if (this.condition.condition instanceof ChildFilterNodeForReferrer) {
      return InflowConditionType.Referrer;
    }
    if (this.condition.condition instanceof ChildFilterNodeForInflowParameter) {
      return InflowConditionType.InflowParameter;
    }
    if (this.condition.condition instanceof ChildFilterNodeForSearchEngine) {
      return InflowConditionType.SearchEngine;
    }
    if (this.condition.condition instanceof ChildFilterNodeForAd) {
      return InflowConditionType.Ad;
    }
    return InflowConditionType.None;
  }

  set selectValue(value: InflowConditionType) {
    let condition!:
      | ChildFilterNodeForReferrer
      | ChildFilterNodeForInflowParameter
      | ChildFilterNodeForSearchEngine
      | ChildFilterNodeForAd;
    if (value === InflowConditionType.Referrer) {
      const referrerUrlCondition = new FilterReferrerUrlCondition(
        "",
        MatchMethod.Partial
      );
      condition = new ChildFilterNodeForReferrer(
        referrerUrlCondition,
        [],
        this.condition.depth
      );
    }
    if (value === InflowConditionType.InflowParameter) {
      condition = new ChildFilterNodeForInflowParameter(
        "",
        [],
        this.condition.depth
      );
    }
    if (value === InflowConditionType.SearchEngine) {
      condition = new ChildFilterNodeForSearchEngine([], this.condition.depth);
    }
    if (value === InflowConditionType.Ad) {
      condition = new ChildFilterNodeForAd([], this.condition.depth);
    }
    this.onInput(
      new FilterNodeForInflow(
        condition,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        this.condition.filterExclusion
      )
    );
  }

  get childCondition():
    | ChildFilterNodeForReferrer
    | ChildFilterNodeForInflowParameter
    | ChildFilterNodeForSearchEngine
    | ChildFilterNodeForAd
    | null {
    return this.condition.condition;
  }
  set childCondition(
    condition:
      | ChildFilterNodeForReferrer
      | ChildFilterNodeForInflowParameter
      | ChildFilterNodeForSearchEngine
      | ChildFilterNodeForAd
      | null
  ) {
    this.onInput(
      new FilterNodeForInflow(
        condition,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        this.condition.filterExclusion
      )
    );
  }

  get isReferrer(): boolean {
    return this.condition.condition instanceof ChildFilterNodeForReferrer;
  }
  get isInflowParameter(): boolean {
    return (
      this.condition.condition instanceof ChildFilterNodeForInflowParameter
    );
  }
  get isSearchEngine(): boolean {
    return this.condition.condition instanceof ChildFilterNodeForSearchEngine;
  }
  get isAd(): boolean {
    return this.condition.condition instanceof ChildFilterNodeForAd;
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForInflow(
        this.condition.condition,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue)
      )
    );
  }

  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get checkAdditionalConditions(): boolean | null {
    const conditionsMax = 2;
    const conditions = this.$props.condition;
    const hasAllConditions =
      conditions.condition &&
      conditions.condition.additionalConditions &&
      conditions.condition.additionalConditions.length === conditionsMax;

    return !!hasAllConditions;
  }
}
</script>
