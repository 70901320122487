import jssha from "jssha";

/**
 * 数値 or 数値文字列をカンマ区切りにする
 *
 * @param val 数値を3桁ずつのカンマ区切りにする
 *
 * @returns カンマ区切りの数値文字列
 */
export function insertCommaDelimiter(val: number | string): string {
  return String(val).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * テキストを改行で分割し配列にする、空行、配列内のテキストの前後スペースもカットする
 *
 * @param val 改行を持つテキスト
 *
 * @returns stringの配列
 */
export function splitByNewLine(val: string): string[] {
  const vals = val.replace("\r", "").split("\n");
  return vals.map(val => val.trim()).filter(val => val.length > 0);
}

/**
 * テキストをカンマで分割し配列にする、空行、配列内のテキストの前後スペースもカットする
 *
 * @param val カンマを持つテキスト
 *
 * @returns stringの配列
 */
export function splitByComma(val: string): string[] {
  const vals = val.split(",");
  return vals.map(val => val.trim()).filter(val => val.length > 0);
}

/**
 * テキストが数値のみかをチェックする
 *
 * @param val テキスト
 *
 * @returns boolean
 */
export function isNumberText(val: string): boolean {
  return val.match(/[^0-9]+/) === null;
}

/**
 * オブジェクトからURLにつけるパラメータ文字列を生成する
 */
export function objectToQueryString(params: {
  [key: string]: string | number | boolean | null;
}): string {
  return JSON.stringify(params);
}

/**
 * パラメータ文字列からオブジェクトを復元
 * @param paramsString
 */
export function queryStringToObject(
  queryString: string
): { [key: string]: string | number | boolean | null } {
  return JSON.parse(queryString);
}

/**
 * テキストをクリップボードにコピーする
 * @param paramsString
 */
export function copyToClipboard(string: string): boolean {
  const textarea = document.createElement("textarea");

  textarea.value = string;
  textarea.selectionStart = 0;
  textarea.selectionEnd = textarea.value.length;

  // 表示されてしまわないように
  const s = textarea.style;
  s.position = "fixed";
  s.left = "-100%";

  document.body.appendChild(textarea);
  textarea.focus();
  //IE11 & Edgeではselectしないとfocusされない
  textarea.select();
  const result = document.execCommand("copy");
  textarea.blur();
  document.body.removeChild(textarea);

  return result;
}

/**
 * テキストをハッシュ化して返す
 *
 * @param val String
 *
 * @return ハッシュ化したテキスト
 */
export function getHashedString(val: string): string {
  const shaObj = new jssha("SHA-1", "TEXT");
  shaObj.update(val);
  return shaObj.getHash("HEX");
}

/**
 * Generate id string for home panels in Home.vue
 *
 * @param panelType number
 *
 * @returns string
 */
export function panelId(panelType: number): string {
  return `menu-panel-${panelType}`;
}
