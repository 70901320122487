<template>
  <div class="inputTextWithSuggestion">
    <div class="inputTextWithSuggestion__container">
      <InputText
        v-model="_value"
        is-ime-mode
        class="inputTextWithSuggestion__input"
        :placeholder="placeholder"
        :style="{ width }"
        @blur="closeInputFormSuggestions"
        @keydown.up.prevent="onKeydownUp"
        @keydown.down.prevent="onKeydownDown"
        @keydown-enter="onKeydownEnter"
        @keydown.esc="onKeydownEsc"
      />
      <div class="inputTextWithSuggestion__toolTipBox">
        <InputFormSuggestionTooltip class="inputTextWithSuggestion__toolTip" />
      </div>
    </div>
    <SuggestionList
      v-if="showInputFormSuggestions"
      class="inputTextWithSuggestion__suggestion"
      :suggestion-results="suggestionResults"
      :highlight-text="_value"
      :is-loading="isLoading"
      :is-error="isError"
      :selected-index="selectedIndex"
      @click="onSuggestionItemClicked"
    />
  </div>
</template>

<script lang="ts">
import debounce from "lodash/debounce";
import { Component, Vue, Prop } from "vue-property-decorator";
import SuggestionList from "@/components/input-form-suggestion/SuggestionList.vue";
import InputText from "@/components/form/InputText.vue";
import { UgTag, UgEventTag, UgAttributeTag } from "@/store/modules/ugTag";
import InputFormSuggestionTooltip from "@/components/input-form-suggestion/InputFormSuggestionTooltip.vue";

import {
  InputFormSuggestionStatus,
  InputFormSuggestionType
} from "@/const/input-form-suggestion";

@Component({
  components: {
    SuggestionList,
    InputText,
    InputFormSuggestionTooltip
  }
})
export default class InputTextWithSuggestion extends Vue {
  @Prop({ type: String, required: true })
  value!: string;

  @Prop({ type: String, required: true })
  inputFormSuggestionType!: InputFormSuggestionType;

  @Prop({ type: String })
  width?: string;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: String, default: () => UgEventTag.FunnelSelectSuggestion })
  eventName!: UgEventTag;

  onInput(value: string) {
    this.$emit("input", value);
  }

  showInputFormSuggestions = false;
  selectedIndex = 0;
  textFiledType = {
    "web-url": "WEB_URL",
    "web-title": "WEB_TITLE",
    "app-url": "APP_URL",
    "app-title": "APP_TITLE"
  };

  debounceSetTextChange = debounce((value: string) => {
    const params = { type: this.inputFormSuggestionType, input_str: value };
    this.$store.dispatch("inputFormSuggestion/fetchResult", params);
  }, 500);

  get _value(): string {
    return this.value;
  }
  set _value(value: string) {
    this.onInput(value);
    this.onSearchTextChanged(value);
  }

  get suggestionStatus(): InputFormSuggestionStatus {
    return this.$store.state.inputFormSuggestion.status;
  }

  get suggestionResults(): string[] {
    return this.$store.state.inputFormSuggestion.result;
  }

  get isLoading(): boolean {
    return this.suggestionStatus === InputFormSuggestionStatus.RUNNING;
  }

  get isError(): boolean {
    return this.suggestionStatus === InputFormSuggestionStatus.FAILED;
  }

  showSuggestionResult(value: string) {
    this.hideInputFormSuggestions();
    this.selectedIndex = 0;
    this.$store.dispatch("inputFormSuggestion/resetResult");

    if (value.length === 0) {
      return;
    }

    this.showInputFormSuggestions = true;
    this.debounceSetTextChange(value);
  }

  onSearchTextChanged(value: string) {
    this.showSuggestionResult(value);
  }

  closeInputFormSuggestions() {
    setTimeout(() => {
      this.hideInputFormSuggestions();
    }, 200);
  }

  onSuggestionItemClicked(value: string) {
    this.dispatchUgEvent(value);
    this.onInput(value);
    this.hideInputFormSuggestions();
  }

  dispatchUgEvent(value: string) {
    const eventAttribute = {
      type: this.textFiledType[this.inputFormSuggestionType],
      typedText: this._value,
      value
    };
    UgTag.pushEvent(this.eventName, {
      [UgAttributeTag.SuggestionDetail]: JSON.stringify(eventAttribute)
    });
  }

  hideInputFormSuggestions() {
    this.showInputFormSuggestions = false;
  }

  onKeydownUp() {
    if (!this.showInputFormSuggestions) return;

    this.selectedIndex--;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.suggestionResults.length - 1;
    }
  }

  onKeydownDown() {
    if (!this.showInputFormSuggestions) {
      this.showSuggestionResult(this.value);
      return;
    }
    this.selectedIndex++;
    if (this.selectedIndex > this.suggestionResults.length - 1) {
      this.selectedIndex = 0;
    }
  }
  onKeydownEnter() {
    if (this.suggestionResults.length === 0 || this._value === "") return;

    const suggestionResult = this.suggestionResults[this.selectedIndex];
    this.dispatchUgEvent(suggestionResult);
    this.onInput(suggestionResult);
    this.hideInputFormSuggestions();
  }
  onKeydownEsc() {
    this.hideInputFormSuggestions();
  }
}
</script>

<style lang="scss" scoped>
.inputTextWithSuggestion__container {
  display: inline-flex;
}
.inputTextWithSuggestion__input ::v-deep(input) {
  padding-right: 34px !important;
}
.inputTextWithSuggestion__toolTipBox {
  background-color: $colorWhite;
  margin-top: 1px;
  margin-left: $formPartsHeight * (-1);
  width: $formPartsHeight - 1;
  height: $formPartsHeight - 2;
  border-radius: $sizeRadius;

  position: relative;
  z-index: 0;

  &:hover {
    z-index: 1000;
  }
}
.inputTextWithSuggestion__toolTip {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inputTextWithSuggestion__suggestion {
  position: absolute;
}
</style>
