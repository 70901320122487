<template>
  <div class="check-box-list" data-cy="check-box-list">
    <div
      v-for="option in options"
      :key="option.value"
      class="check-box-list_item"
      :style="{ width: width }"
    >
      <CheckBox
        :checked="isChecked(option.value)"
        :disabled="disabled || option.disabled"
        @input="onInput($event, option.value)"
        >{{ option.label }}</CheckBox
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import CheckBox from "@/components/form/CheckBox.vue";
import SelectOption from "@/components/form/SelectOption";

@Component({
  components: {
    CheckBox
  }
})
export default class CheckBoxList extends Vue {
  // requiredを設定すると、v-modelで渡した時にWarningを出すので代わりにdefaultを設定
  @Model("input", { type: Array, default: () => [] })
  values!: (string | number)[];

  @Prop({ type: Array, required: true })
  options!: SelectOption[];

  @Prop({ type: Number, default: 4 })
  column!: number;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  onInput(checked: boolean, value: string | number) {
    if (checked) {
      this.$emit("input", this.values.concat([value]));
    } else {
      this.$emit(
        "input",
        this.values.filter(v => v !== value)
      );
    }
  }

  isChecked(value: string | number): boolean {
    return this.values.indexOf(value) !== -1;
  }

  get width(): string {
    if (this.column === 0) {
      return "auto";
    } else {
      return Math.floor(100 / this.column) + "%";
    }
  }
}
</script>

<style scoped lang="scss">
.check-box-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.check-box-list_item {
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
