<i18n src="@/i18n/components/user-trend/table/user-trend-cell.json"></i18n>
<template>
  <AnalysisTableCell
    class="userTrendCell"
    :width="width"
    @mouse-enter="onMouseEnter"
    @mouse-leave="onMouseLeave"
  >
    <div class="userTrendCell__container">
      <a
        v-if="showLink"
        class="userTrendCell__link"
        :href="url"
        target="_blank"
        rel="noopener"
      >
        <IconButton :icon="icons.ExternalLink" :size="24" :icon-scale="0.5" />
      </a>

      <Tooltip
        class="userTrendCell__labelContainer"
        :class="{ 'userTrendCell__labelContainer--noLink': !showLink }"
        :fixed-arrow-position="true"
        :text="tooltipText"
        :placement="tooltipPlacement.TopStart"
        :active="activeTooltip"
      >
        <div>
          <span class="userTrendCell__label">{{ label }}</span>
          <span
            v-if="activeLabel"
            class="userTrendCell__activeLabel"
            data-cy="userTrendCell__activeLabel"
            @click="onClick()"
          >
            {{ linkLabel }}
          </span>
        </div>
      </Tooltip>

      <div v-if="$slots.default" class="userTrendCell__userIcon">
        <slot />
      </div>
    </div>
  </AnalysisTableCell>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";

import AnalysisTableCell from "@/components/table/AnalysisTableCell.vue";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: {
    AnalysisTableCell,
    IconButton,
    Tooltip
  }
})
export default class UserTrendCell extends Vue {
  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: String, default: "" })
  url!: string;

  @Prop({ type: String, default: "" })
  tooltipText!: string;

  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: Boolean, default: false })
  activeLabel!: boolean;

  @Prop({ type: Boolean, default: true })
  activeTooltip!: boolean;

  @Emit("mouse-enter")
  onMouseEnter() {}

  @Emit("mouse-leave")
  onMouseLeave() {}

  @Emit("click")
  onClick() {}

  tooltipPlacement = TooltipPlacement;
  icons = Icons;

  get showLink(): boolean {
    return this.url.length > 0;
  }

  get linkLabel(): string {
    return "(" + this.$i18n.t("detail") + ")";
  }
}
</script>

<style scoped lang="scss">
.userTrendCell {
  padding-right: 0;
  padding-left: 0;
  text-align: left;

  @include all-break-word();
}

.userTrendCell__container {
  display: table;
  min-width: 100px;
  width: 100%;
}

.userTrendCell__link {
  display: table-cell;
  padding-right: 5px;
  padding-left: 10px;
  width: 40px;
  vertical-align: middle;

  &:hover {
    text-decoration: none;
  }
}

.userTrendCell__labelContainer {
  display: table-cell;
  padding-right: 10px;
  vertical-align: middle;
}

.userTrendCell__labelContainer--noLink {
  padding-left: 10px;
}

.userTrendCell__label {
  padding-right: 5px;
}

.userTrendCell__activeLabel {
  color: $colorBlue820;
  font-weight: bold;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $colorBlue900;
    text-decoration: underline;
  }
}

.userTrendCell__userIcon {
  display: table-cell;
  padding-right: 10px;
  width: 30px;
  vertical-align: middle;
}
</style>
