<i18n src="@/i18n/components/search/featured-users.json"></i18n>
<template>
  <LightUgTable data-cy="featuredUsersAlmostCvTable" sticky-top="-10px">
    <template #header>
      <FeaturedUsersAlmostCvTableHeader :almost-cv-info="almostCvInfo" />
    </template>
    <FeaturedUsersAlmostCvTableBody
      :almost-cv-info="almostCvInfo"
      @select="emitSelect"
    />
  </LightUgTable>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LightUgTable from "@/components/table/LightUgTable.vue";
import FeaturedUsersAlmostCvTableHeader from "@/components/search/FeaturedUsersAlmostCvTableHeader.vue";
import FeaturedUsersAlmostCvTableBody from "@/components/search/FeaturedUsersAlmostCvTableBody.vue";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";

@Component({
  components: {
    LightUgTable,
    FeaturedUsersAlmostCvTableHeader,
    FeaturedUsersAlmostCvTableBody
  }
})
export default class FeaturedUsersAlmostCvTable extends Vue {
  @Prop({ type: Object, required: true })
  almostCvInfo!: AlmostCvInfo;

  emitSelect(url: string) {
    this.$emit("select", url);
  }
}
</script>
