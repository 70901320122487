<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <div class="select-by-conversion-form">
    <div class="title">
      <TitleTextWithIcon :title="$t('period')" :icon="iconHistoy" />
    </div>
    <div class="period">
      <DateRangePickerInput
        v-model="dateValue"
        :enabled-period="enabledPeriod"
      />
      <SelectBox
        v-model="hourValue"
        data-cy="select-box-hour"
        class="hour"
        :options="hourOptions"
      />
    </div>
    <div class="title">
      <TitleTextWithIcon :title="$t('condition')" :icon="iconConversion" />
    </div>
    <div class="selectByAppForm__radioButtonList" data-cy="radio-button-list">
      <div
        v-for="option in conversionOptions"
        :key="option.value"
        class="selectByAppForm__radioButtonList-item"
      >
        <RadioButton v-model="conversionValue" :label="option.value">{{
          option.label
        }}</RadioButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import {
  hourlyIntervalsSelectOpitions,
  HOURLY_INTERVALS_DEFAULT_VALUE
} from "@/util/date-util";
import { GlobalConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import {
  containsAppFirstOpenConversion,
  SelectByAppCondition
} from "@/models/search/select-condition/SelectByAppCondition";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import { DateRange } from "@/components/date-picker/DateRange";
import { enabledPeriodRange } from "@/util/date-range-picker-util";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import { Icons } from "@/const/Icons";
import RadioButton from "@/components/form/RadioButton.vue";

@Component({
  components: {
    DateRangePickerInput,
    TitleTextWithIcon,
    SelectBox,
    RadioButton
  }
})
export default class SelectByAppForm extends Vue {
  @Prop({ type: Array, required: true })
  conversionDefinitions!: GlobalConversionDefinition[];

  @Model("input", { type: SelectByAppCondition, required: true })
  condition!: SelectByAppCondition;

  onUpdate(condition: SelectByAppCondition) {
    this.$emit("input", condition);
  }

  iconConversion = Icons.Conversion;
  iconHistoy = Icons.History;

  get enabledPeriod(): DateRange {
    return enabledPeriodRange();
  }

  // 時間帯Selectのオプション
  get hourOptions(): SelectOption[] {
    return hourlyIntervalsSelectOpitions();
  }

  // コンバージョンのcheckboxListのオプション
  get conversionOptions(): SelectOption[] {
    return this.conversionDefinitions.map(cd => {
      return { value: cd.id, label: cd.name, disabled: false };
    });
  }

  // 日付の値
  get dateValue(): DateRange | null {
    if (this.condition.endDate !== null && this.condition.startDate !== null) {
      return {
        min: this.condition.startDate,
        max: this.condition.endDate
      };
    }

    return null;
  }
  set dateValue(dateRange: DateRange | null) {
    if (dateRange !== null) {
      const condition = new SelectByAppCondition(
        this.condition.conversionDefinitionIds,
        dateRange.min,
        dateRange.max,
        this.condition.hour,
        this.condition.additionalConditions
      );
      this.onUpdate(condition);
    }
  }

  // 時間帯の値
  get hourValue(): number {
    // selectのvalueにnullは渡せないのでnullの場合はHOURLY_INTERVALS_DEFAULT_VALUEを渡す
    return this.condition.hour === null
      ? HOURLY_INTERVALS_DEFAULT_VALUE
      : this.condition.hour;
  }
  set hourValue(hour: number) {
    // HOURLY_INTERVALS_DEFAULT_VALUEだった場合はnullに戻す
    const setHour = hour === HOURLY_INTERVALS_DEFAULT_VALUE ? null : hour;
    const condition = new SelectByAppCondition(
      this.condition.conversionDefinitionIds,
      this.condition.startDate,
      this.condition.endDate,
      setHour,
      this.condition.additionalConditions
    );
    this.onUpdate(condition);
  }
  // コンバージョンの値
  get conversionValue(): number {
    return this.condition.conversionDefinitionIds[0];
  }
  set conversionValue(conversionDefinitionId: number) {
    const condition = new SelectByAppCondition(
      [conversionDefinitionId],
      this.condition.startDate,
      this.condition.endDate,
      this.condition.hour,
      containsAppFirstOpenConversion(
        [conversionDefinitionId],
        this.conversionDefinitions
      )
        ? []
        : this.condition.additionalConditions
    );

    this.onUpdate(condition);
  }
}
</script>

<style scoped lang="scss">
.title {
  margin: 30px 0 10px 0;
  &:first-child {
    margin-top: 0;
  }
}
.period {
  position: relative;
  display: flex;
  height: $formPartsHeight;
}
.hour {
  margin-left: 20px;
}
.selectByAppForm__radioButtonList {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.selectByAppForm__radioButtonList-item {
  width: 33%;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
