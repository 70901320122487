import { SelectByEngagementQuery, SelectQuery } from "@/api/apis/ApiSearch";
import { DateRange } from "@/components/date-picker/DateRange";
import { Colors } from "@/const/Colors";
import { i18n } from "@/i18n";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import {
  AdditionalSelectCondition,
  convertAdditionalConditionToJson,
  convertJsonToAdditionalCondition
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { MAX_HOLD_ADDITIONAL_CONDITION_SIZE } from "@/models/search/select-condition/SelectCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { postDate } from "@/util/date-util";
import { now, getCurrentDate } from "@/util/date-util";

const ALLOWED_COMPARE_MIN_VALUE = 0;
const ALLOWED_COMPARE_MAX_VALUE = 9999;

export const SPECIFICATION_LABELS: string[] = [
  "valueSpecification",
  "rangeSpecification"
];

// 増減値指定なしの場合はselectの値
export const UNSPECIFIED_FLUCTUATION_VALUE = -1;

// 増減値直接入力の場合のselectの値
export const DIRECT_FLUCTUATION_VALUE = 9999;

export const DIRECT_FLUCTUATION_MAX_VALUE = 100;
export const DIRECT_FLUCTUATION_MIN_VALUE = 1;

// 増減値の上限で選択できる数値 (10倍, 5倍, 2倍)
export const FLUCTUATION_MAX_VALUES = [900, 400, 100];

// 増減値の下限で選択できる数値 (10分の1, 2分の1)
export const FLUCTUATION_MIN_VALUES = [90, 50];

export const FLUCTUATION_VALUES = FLUCTUATION_MAX_VALUES.concat(
  FLUCTUATION_MIN_VALUES
);

// 無指定時のconversionDefinitionIdとeventDefinitionIdの値
export const UNSPECIFIED_CV_EVENT_ID = -1;

export enum AnalysisType {
  Pv = 1,
  Event = 2,
  Cv = 3,
  AppLaunch = 4,
  AppView = 5,
  VisitCount = 6,
  StayTime = 7
}

export enum FieldType {
  All,
  WebOnly,
  AppOnly
}

export enum FluctuationType {
  Increase,
  Decrease
}

export enum PeriodType {
  "1week" = 7,
  "2weeks" = 14,
  "30days" = 30,
  "90days" = 90,
  directly = -1
}

// 増減のデータ
export class FluctuationValue {
  constructor(public readonly select: number, public readonly value: number) {}

  static build(value: number | null, allowValues: number[]): FluctuationValue {
    // valueがnullの場合は指定なし
    if (value === null) {
      return new FluctuationValue(UNSPECIFIED_FLUCTUATION_VALUE, 0);
    }

    // リスト内にある数値であれば、直接入力ではなく選択された数値
    if (allowValues.indexOf(value) !== -1) {
      return new FluctuationValue(value, 0);
    }

    // 上記以外は直接入力
    return new FluctuationValue(DIRECT_FLUCTUATION_VALUE, value);
  }

  // APIにPOSTする際にセットするvalueを返す
  valueForQuery(): number | null {
    // 指定なしならnullを返す
    if (this.select === UNSPECIFIED_FLUCTUATION_VALUE) {
      return null;
    }
    // 直接入力ならvalueを返す
    if (this.select === DIRECT_FLUCTUATION_VALUE) {
      return this.value;
    }
    // それ以外なら選択された数値を返す
    return this.select;
  }

  validate(): string[] {
    const errorMessages: string[] = [];

    // Error if select value is DIRECT_FLUCTUATION_VALUE and value is less than 0.
    // 値が0より小さければエラー
    if (
      this.select === DIRECT_FLUCTUATION_VALUE &&
      this.value < ALLOWED_COMPARE_MIN_VALUE
    ) {
      errorMessages.push(localI18n("errorLowerThan0"));
    }

    // Error if select value is DIRECT_FLUCTUATION_VALUE and value is more than 9999.
    // 値が9999より大さければエラー
    if (
      this.select === DIRECT_FLUCTUATION_VALUE &&
      this.value > ALLOWED_COMPARE_MAX_VALUE
    ) {
      errorMessages.push(localI18n("errorHigherThan9999"));
    }

    return errorMessages;
  }
}

function localI18n(key: string): string {
  return i18n.t(`models.selectByEngagementCondition.${key}`) as string;
}

export interface FluctuationValues {
  max: FluctuationValue;
  min: FluctuationValue;
  type: FluctuationType;
}

/**
 * エンゲージメント検索によるユーザ選定条件
 *
 * エンゲージメント検索は対象間で、領域の項目が、指定された分増減したユーザを検索する機能
 * 対象: 対象期間（時間帯含む）と過去の比較期間（時間帯含む）
 * 領域: webかappか、webの場合はドメインを選択できる
 * 項目: PV、イベント、CV、起動、スクリーンビュー（イベントやCVはどのイベント・CVかを選択する）
 * 増減: 何%もしくは何%〜何%、増えた減った
 */
export class SelectByEngagementCondition {
  constructor(
    // 選定対象期間
    public readonly period: DateRange,
    // 過去の比較期間
    public readonly comparisonPeriod: DateRange,
    // 領域（ウェブサイト or アプリ）
    public readonly fieldType: FieldType,
    // 選択したドメイン
    public readonly sites: MeasurementTargetSite[],
    // どの項目で増減を見るか
    public readonly analysisType: AnalysisType,
    // 選択したコンバージョン定義
    public readonly conversionDefinitionId: number,
    // 選択したイベント定義
    public readonly eventDefinitionId: number,
    // 増減指定の下限
    public readonly minFluctuationValue: FluctuationValue,
    // 増減指定の上限
    public readonly maxFluctuationValue: FluctuationValue,
    // 増えた or 減ったの選択
    public readonly fluctuationType: FluctuationType,
    /**
     * 追加の検索条件
     *
     * コンバージョン属性、デバイスに対する条件を5つまで指定できる
     */
    public readonly additionalConditions: AdditionalSelectCondition[]
  ) {}

  get coloredPeriods(): ColoredPeriod[] {
    return [
      new ColoredPeriod(
        localI18n("designatedPeriodTarget"),
        this.period.min,
        this.period.max,
        Colors.Blue730,
        Colors.Blue800
      ),
      new ColoredPeriod(
        localI18n("designatedPeriodPast"),
        this.comparisonPeriod.min,
        this.comparisonPeriod.max,
        Colors.Orange300,
        Colors.Orange500
      )
    ];
  }

  // AdditionalSelectConditionを追加できるか
  get isAdditionalConditionAppendable(): boolean {
    return (
      this.additionalConditions.length < MAX_HOLD_ADDITIONAL_CONDITION_SIZE
    );
  }

  // 追加のコンバージョン・イベント属性は、CVかイベントの場合のみ使える
  get isAdditionalConditionAvailable(): boolean {
    return this.isAdditionalConditionAvailableAnalysisType(this.analysisType);
  }

  get validate(): ValidationResult {
    const day92Msec = 92 * 24 * 60 * 60 * 1000;
    const day549Msec = 549 * 24 * 60 * 60 * 1000;
    const day731Msec = 731 * 24 * 60 * 60 * 1000; // 731日は、外部公開しているUGの仕様より

    let errorMessages: string[] = [];

    // Error if the period min is before the min of comparisonPeriod
    // 対象の頭が、過去の頭より前だとエラー
    if (this.period.min.getTime() < this.comparisonPeriod.min.getTime()) {
      errorMessages.push(localI18n("errorPastStartDay"));
    }

    // Error if the period max is before the max of comparisonPeriod
    // 対象期間のお尻が、過去のお尻より前
    if (this.period.max.getTime() < this.comparisonPeriod.max.getTime()) {
      errorMessages.push(localI18n("errorPastEndDay"));
    }

    // Error if period and comparisonPeriod has same min and max
    // 対象期間と過去と比較が同じ
    if (
      this.period.min.getTime() === this.comparisonPeriod.min.getTime() &&
      this.period.max.getTime() === this.comparisonPeriod.max.getTime()
    ) {
      errorMessages.push(localI18n("errorSameDay"));
    }

    // Error when period range exceeds 3 months(92 days)
    // 対象の日付の範囲が3ヶ月(92日)を超えた場合にエラー
    if (this.period.max.getTime() - this.period.min.getTime() > day92Msec) {
      errorMessages.push(localI18n("errorOver92Days"));
    }

    // Error when comparisonPeriod range exceeds 3 months(92 days)
    // 過去の日付の範囲が3ヶ月(92日)を超えた場合にエラー
    if (
      this.comparisonPeriod.max.getTime() -
        this.comparisonPeriod.min.getTime() >
      day92Msec
    ) {
      errorMessages.push(localI18n("errorPastOver92Days"));
    }

    // Error if period of min comparison comparisonPeriod of max exceeds 18 months(549 days)
    // 対象期間の頭と過去と比較のお尻が期間が18ヶ月 (549日)を超えたらエラー
    if (
      this.period.min.getTime() - this.comparisonPeriod.max.getTime() >
      day549Msec
    ) {
      errorMessages.push(localI18n("errorOver18MonthsInterval"));
    }

    // Error if min of comparisonPeriod is before 2 years(731 days)
    // 過去の日付の開始日がが2年(731日)より前ならエラー
    if (now() - this.comparisonPeriod.min.getTime() > day731Msec) {
      errorMessages.push(localI18n("errorOver2YearsRange"));
    }

    // Error when analysisType is Event and no eventDefinitionId.
    // 項目がイベントなのにイベントIDがない場合はエラー
    if (
      this.analysisType === AnalysisType.Event &&
      this.eventDefinitionId === UNSPECIFIED_CV_EVENT_ID
    ) {
      errorMessages.push(localI18n("errorNoSelectEvent"));
    }

    // Error when analysisType is Cv and no conversionDefinitionId.
    // 項目がコンバージョンなのにコンバージョンIDがない場合はエラー
    if (
      this.analysisType === AnalysisType.Cv &&
      this.conversionDefinitionId === UNSPECIFIED_CV_EVENT_ID
    ) {
      errorMessages.push(localI18n("errorNoSelectCV"));
    }

    // 下限の数値チェック
    errorMessages = errorMessages.concat(this.minFluctuationValue.validate());

    // 上限の数値チェック
    errorMessages = errorMessages.concat(this.maxFluctuationValue.validate());

    const minValue = this.minFluctuationValue.valueForQuery();
    const maxValue = this.maxFluctuationValue.valueForQuery();

    // Error when max fluctuatin value is equal to zero
    // 上限の数値が0となっている場合
    if (maxValue === 0) {
      errorMessages.push(localI18n("errorUpperEqualZero"));
    }

    // Error when min and max fluctuation value is not null and min value is bigger than max value
    // セットする値が両方nullではなく、下限の数値が上限の数値より大きい場合
    if (minValue !== null && maxValue !== null && minValue >= maxValue) {
      errorMessages.push(localI18n("errorLowerHigherUpper"));
    }

    // Error when selected 'decrease' as fluctuationType and max fluctuatin value is bigger than 100%
    // 選ばれたのがdecreaseであり、上限の値がnullでなくかつ100を超えている場合
    if (
      this.fluctuationType === FluctuationType.Decrease &&
      maxValue !== null &&
      maxValue > 100
    ) {
      errorMessages.push(localI18n("errorUpperOver100WhenDecrease"));
    }

    // Error when selected 'decrease' as fluctuationType and min fluctuatin value is 100% or more.
    // 選ばれたのがdecreaseであり、下限の値がnullでなくかつ100以上の場合
    if (
      this.fluctuationType === FluctuationType.Decrease &&
      minValue !== null &&
      minValue >= 100
    ) {
      errorMessages.push(localI18n("errorLowerOver99WhenDecrease"));
    }

    // 重複するエラーメッセージを消す
    errorMessages = errorMessages.filter(
      (mes, idx, array) => array.indexOf(mes) === idx
    );

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }

  static defaultPeriods(): { period: DateRange; comparisonPeriod: DateRange } {
    // periodは直近2日前から1週間
    const endDate = getCurrentDate();
    // YYYY-MM-DD の型式で送るため (23, 59, 59, 999) は不要
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(endDate.getDate() - 2);
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 6);

    // comparisonPeriodはperiod開始前日から1週間前まで
    // periodが2019/7/5 - 2019/7/11だとすると、2019/6/28 - 2019/7/4
    const compEndDate = new Date(startDate);
    compEndDate.setDate(compEndDate.getDate() - 1);
    const compStartDate = new Date(compEndDate);
    compStartDate.setDate(compStartDate.getDate() - 6);

    return {
      period: { min: startDate, max: endDate },
      comparisonPeriod: { min: compStartDate, max: compEndDate }
    };
  }

  static defaultCondition(): SelectByEngagementCondition {
    const {
      period,
      comparisonPeriod
    } = SelectByEngagementCondition.defaultPeriods();

    return new SelectByEngagementCondition(
      period,
      comparisonPeriod,
      FieldType.AppOnly,
      [],
      AnalysisType.Pv,
      UNSPECIFIED_CV_EVENT_ID,
      UNSPECIFIED_CV_EVENT_ID,
      new FluctuationValue(FLUCTUATION_MAX_VALUES[2], 0),
      new FluctuationValue(UNSPECIFIED_FLUCTUATION_VALUE, 0),
      FluctuationType.Increase,
      []
    );
  }

  static createTypeCondition(
    analysisType: AnalysisType
  ): SelectByEngagementCondition {
    const {
      period,
      comparisonPeriod
    } = SelectByEngagementCondition.defaultPeriods();

    return new SelectByEngagementCondition(
      period,
      comparisonPeriod,
      FieldType.AppOnly,
      [],
      analysisType,
      UNSPECIFIED_CV_EVENT_ID,
      UNSPECIFIED_CV_EVENT_ID,
      new FluctuationValue(FLUCTUATION_MAX_VALUES[2], 0),
      new FluctuationValue(UNSPECIFIED_FLUCTUATION_VALUE, 0),
      FluctuationType.Increase,
      []
    );
  }

  updatePeriod(period: DateRange): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateComparisonPeriod(
    comparisonPeriod: DateRange
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateFieldType(fieldType: FieldType): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateSites(sites: MeasurementTargetSite[]): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateAnalysisType(analysisType: AnalysisType): SelectByEngagementCondition {
    // 追加のコンバージョン・イベント属性は、CVかイベントの場合のみ使える
    const additionalConditions = this.isAdditionalConditionAvailableAnalysisType(
      analysisType
    )
      ? this.additionalConditions
      : [];

    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      additionalConditions
    );
  }
  updateConversionDefinitionId(
    conversionDefinitionId: number
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateEventDefinitionId(
    eventDefinitionId: number
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateFluctuations(
    fluctuationValues: FluctuationValues
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      fluctuationValues.min,
      fluctuationValues.max,
      fluctuationValues.type,
      this.additionalConditions
    );
  }
  updateMinFluctuationValue(
    minFluctuationValue: FluctuationValue
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateMaxFluctuationValue(
    maxFluctuationValue: FluctuationValue
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      maxFluctuationValue,
      this.fluctuationType,
      this.additionalConditions
    );
  }
  updateFluctuationType(
    fluctuationType: FluctuationType
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      fluctuationType,
      this.additionalConditions
    );
  }
  updateAdditionalConditions(
    additionalConditions: AdditionalSelectCondition[]
  ): SelectByEngagementCondition {
    return new SelectByEngagementCondition(
      this.period,
      this.comparisonPeriod,
      this.fieldType,
      this.sites,
      this.analysisType,
      this.conversionDefinitionId,
      this.eventDefinitionId,
      this.minFluctuationValue,
      this.maxFluctuationValue,
      this.fluctuationType,
      additionalConditions
    );
  }

  isAdditionalConditionAvailableAnalysisType(
    analysisType: AnalysisType
  ): boolean {
    return (
      analysisType === AnalysisType.Cv || analysisType === AnalysisType.Event
    );
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectByEngagementConditionToJson(
  condition: SelectByEngagementCondition
): SelectByEngagementQuery {
  const region = condition.fieldType;
  const hosts = condition.sites.map(site => site.hostPath);
  const base_date = {
    start_date: postDate(condition.period.min),
    end_date: postDate(condition.period.max)
  };
  const comparison_date = {
    start_date: postDate(condition.comparisonPeriod.min),
    end_date: postDate(condition.comparisonPeriod.max)
  };
  let cvEventId: number | null = null;
  if (condition.analysisType === AnalysisType.Event) {
    cvEventId = condition.eventDefinitionId;
  }
  if (condition.analysisType === AnalysisType.Cv) {
    cvEventId = condition.conversionDefinitionId;
  }

  return {
    analysis_type: condition.analysisType,
    region,
    hosts,
    base_date,
    comparison_date,
    conversion_id: cvEventId,
    min_percent: condition.minFluctuationValue.valueForQuery(),
    max_percent: condition.maxFluctuationValue.valueForQuery(),
    increase_decrease: condition.fluctuationType,
    sub_cnds: condition.additionalConditions.map(
      convertAdditionalConditionToJson
    )
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByEngagementCondition(
  query: SelectByEngagementQuery,
  activeDefinitions: ActiveDefinitions,
  activeMeasurementSites: MeasurementTargetSite[]
): SelectByEngagementCondition | null {
  let conversionDefinitionId =
    activeDefinitions.conversionDefinitions.length > 0
      ? activeDefinitions.conversionDefinitions[0].id
      : UNSPECIFIED_CV_EVENT_ID;
  // AnalysisTypeがCVの場合は、セットされているConversion Idが有効なIdかチェックする
  if (query.analysis_type === AnalysisType.Cv) {
    if (
      !activeDefinitions.conversionDefinitions.some(
        def => def.id === query.conversion_id
      )
    ) {
      return null;
    }
    if (query.conversion_id !== null) {
      conversionDefinitionId = query.conversion_id;
    }
  }

  let eventDefinitionId =
    activeDefinitions.eventDefinitions.length > 0
      ? activeDefinitions.eventDefinitions[0].id
      : UNSPECIFIED_CV_EVENT_ID;
  /**
   * AnalysisTypeがEventの場合は、セットされているEvent Idが有効なIdかチェックする
   * Eventの場合はconversion_idにイベントIDが入っている
   */
  if (query.analysis_type === AnalysisType.Event) {
    if (
      !activeDefinitions.eventDefinitions.some(
        def => def.id === query.conversion_id
      )
    ) {
      return null;
    }
    if (query.conversion_id !== null) {
      eventDefinitionId = query.conversion_id;
    }
  }

  const sites = activeMeasurementSites.filter(site => {
    return query.hosts.some((host: string) => host === site.hostPath);
  });
  /**
   * queryが保持ているhostsの数と、activeMeasurementSitesから取り出した数が違う場合は、
   * 前回の条件は復活できないのでnullを返す
   */
  if (sites.length !== query.hosts.length) {
    return null;
  }

  const additionalConditions: AdditionalSelectCondition[] = [];
  query.sub_cnds.forEach(cnd => {
    const addCnd = convertJsonToAdditionalCondition(cnd, activeDefinitions);
    if (addCnd !== null) {
      additionalConditions.push(addCnd);
    }
  });

  const fieldType = query.region === -1 ? FieldType.AppOnly : query.region;

  const startDate = new Date(query.base_date.start_date);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(query.base_date.end_date);
  endDate.setHours(23, 59, 59, 999);
  const conparisonStartDate = new Date(query.comparison_date.start_date);
  conparisonStartDate.setHours(0, 0, 0, 0);
  const conparisonEndDate = new Date(query.comparison_date.end_date);
  conparisonEndDate.setHours(23, 59, 59, 999);

  return new SelectByEngagementCondition(
    { min: startDate, max: endDate },
    {
      min: conparisonStartDate,
      max: conparisonEndDate
    },
    fieldType,
    sites,
    query.analysis_type,
    conversionDefinitionId,
    eventDefinitionId,
    FluctuationValue.build(query.min_percent, FLUCTUATION_VALUES),
    FluctuationValue.build(query.max_percent, []),
    query.increase_decrease,
    additionalConditions
  );
}

/**
 * JSONがエンゲージメント検索のJSONかどうかを判定する
 */
export function isSelectByEngagementQuery(
  query: SelectQuery
): query is SelectByEngagementQuery {
  return "analysis_type" in query && "region" in query && "hosts" in query;
}
