import store from "@/store";
import { Route } from "vue-router";
import { isGuestUser } from "@/util/common-util";

export function validateTourPermission(
  _to: Route,
  _from: Route,
  next: Function
) {
  if (store.state.client.client && !store.state.app.canUseTourFeatures) {
    location.assign("/");
  } else if (isGuestUser(store.state.auth.user)) {
    location.assign("/");
  } else {
    next();
  }
}
