import {
  AnalysisType,
  UserTrendDataType,
  UserTrendScatterXAxisType,
  UserTrendScatterYAxisType
} from "@/const/user-trend";

export class UserTrendScatterType {
  constructor(
    public type: UserTrendDataType,
    public xAxisType: UserTrendScatterXAxisType,
    public yAxisType: UserTrendScatterYAxisType
  ) {}

  static defaultType(page: AnalysisType): UserTrendScatterType {
    let xAxisType = UserTrendScatterXAxisType.count;

    if (
      page === AnalysisType.Landing ||
      page === AnalysisType.Inflow ||
      page === AnalysisType.InflowDetail
    ) {
      xAxisType = UserTrendScatterXAxisType.visit;
    }

    return new UserTrendScatterType(
      UserTrendDataType.base,
      xAxisType,
      UserTrendScatterYAxisType.cvInVisit
    );
  }
}
