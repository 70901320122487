<template>
  <div class="settingsAlert">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SettingsAlert extends Vue {}
</script>

<style lang="scss" scoped>
.settingsAlert {
  border: 1px solid $colorBlue900;
  color: $colorBlue900;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}

::v-deep a {
  color: $colorBlue900;
  text-decoration: underline;
}
</style>
