<template>
  <div>
    <FilterNodeBrowseApp
      v-if="filterNode.nodeType === nodeType.BrowseApp"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeBrowseSite
      v-if="filterNode.nodeType === nodeType.BrowseSite"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeBusinessEvent
      v-if="filterNode.nodeType === nodeType.BusinessEvent"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeContact
      v-if="filterNode.nodeType === nodeType.Contact"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeConversion
      v-if="filterNode.nodeType === nodeType.Conversion"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeEvent
      v-if="filterNode.nodeType === nodeType.Event"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeInflow
      v-if="filterNode.nodeType === nodeType.Inflow"
      :condition="filterNode"
      :exclusion="exclusion"
      :index="index"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterNodeLaunchApp
      v-if="filterNode.nodeType === nodeType.LaunchApp"
      :condition="filterNode"
      :exclusion="exclusion"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      @input="inputHandler"
      @add-or-node="$emit('add-or-node', $event)"
      @remove-node="$emit('remove-node')"
    />
    <FilterOrNode
      v-if="filterNode.nodeType === nodeType.OrNode"
      :condition="filterNode"
      :is-dragging="isDragging"
      :is-first-node="index === 0"
      :index="index"
      :input-handler="inputHandler"
      :exclusion="exclusion"
      @remove-node="$emit('remove-node')"
      @remove-child-node="$emit('remove-child-node', $event)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import FilterNodeConversion from "@/components/filter/FilterNodeConversion.vue";
import FilterNodeBrowseApp from "@/components/filter/FilterNodeBrowseApp.vue";
import FilterNodeBrowseSite from "@/components/filter/FilterNodeBrowseSite.vue";
import FilterNodeBusinessEvent from "@/components/filter/FilterNodeBusinessEvent.vue";
import FilterNodeContact from "@/components/filter/FilterNodeContact.vue";
import FilterNodeEvent from "@/components/filter/FilterNodeEvent.vue";
import FilterNodeInflow from "@/components/filter/FilterNodeInflow.vue";
import FilterNodeLaunchApp from "@/components/filter/FilterNodeLaunchApp.vue";

import { ExclusionProps } from "@/models/search/filter-node/FilterExclusion";
import { FilterNodeType } from "@/models/search/filter-node/FilterNode";

@Component({
  name: "FilterNodeFactory",
  components: {
    FilterNodeBrowseApp,
    FilterNodeBrowseSite,
    FilterNodeBusinessEvent,
    FilterNodeContact,
    FilterNodeConversion,
    FilterNodeEvent,
    FilterNodeInflow,
    FilterNodeLaunchApp,
    FilterOrNode: () => import("@/components/filter/FilterOrNode.vue")
  }
})
export default class FilterNodeFactory extends Vue {
  nodeType = FilterNodeType;

  @Prop({ type: Object, required: true })
  filterNode: any;

  @Prop({ type: Number, required: true })
  index!: number;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  @Prop({ type: Function, required: true })
  inputHandler!: Function;
}
</script>
