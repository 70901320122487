import { render, staticRenderFns } from "./UserHeader.vue?vue&type=template&id=32530b41&scoped=true"
import script from "./UserHeader.vue?vue&type=script&lang=ts"
export * from "./UserHeader.vue?vue&type=script&lang=ts"
import style0 from "./UserHeader.vue?vue&type=style&index=0&id=32530b41&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32530b41",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/user-search-result.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fobservation%2Fuser-header%2FUserHeader.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports