<template>
  <div class="featuredUsersWarning">
    <p class="featuredUsersWarning__title">{{ title }}</p>
    <p class="featuredUsersWarning__description">{{ description }}</p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FeaturedUsersWarning extends Vue {
  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: true })
  description!: string;
}
</script>
<style lang="scss" scoped>
.featuredUsersWarning {
  line-height: 1.5;
  font-size: 12px;
}
.featuredUsersWarning__title {
  font-weight: bold;
  margin-bottom: 4px;
}
</style>
