import { ApiRes } from "@/api/api-res";
import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";

export default class ApiConfig {
  constructor(private readonly httpClient: HttpClient) {}

  public getConfig(): Promise<ApiRes.Config> {
    return this.httpClient.get<ApiRes.Config>(ApiUrl.GET_CONFIG);
  }
}
