<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-staytime-form">
    <CheckBox
      v-model="isStayTimeChecked"
      class="filter-staytime-form-check-box"
    >
      <p class="staytime-text-label-prefix">{{ $t("stayTime") }} </p>
    </CheckBox>
    <div class="filter-staytime-form-slider">
      <SliderRangeUi
        v-model="filterStayTimeRangeValue"
        :range-step-data="timeRangeStepData"
        :min-range="1"
        :max-range="timeRangeStepData.length"
        :disabled="enableSliderUi"
      >
        <template #tooltip="{ value }">
          <Tooltip
            class="filter-staytime-form-slider-tooltip"
            :text="getTimeString(value)"
            :show="true"
          />
        </template>
      </SliderRangeUi>
    </div>
    <p
      class="staytime-text-label-point"
      :class="{ 'staytime-text-label-point--disabled': enableSliderUi }"
    >
      {{ getStartPoint() }} ~ {{ getEndPoint() }}
      <span
        class="staytime-text-label-point-suffix"
        :class="{
          'staytime-text-label-point-suffix--disabled': enableSliderUi
        }"
        >{{ $t("stayTimeSuffix") }}</span
      ></p
    >
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import CheckBox from "@/components/form/CheckBox.vue";
import SliderRangeUi from "@/components/form/SliderRangeUi.vue";
import Tooltip from "@/components/Tooltip.vue";

import {
  FilterStayTimeCondition,
  stayTimeRangeStepInSec,
  endPoint
} from "@/models/search/filter-node-condition/FilterStayTimeCondition";

@Component({
  components: {
    CheckBox,
    SliderRangeUi,
    Tooltip
  }
})
export default class FilterStayTimeField extends Vue {
  @Model("input", { type: FilterStayTimeCondition, required: true })
  condition!: FilterStayTimeCondition;

  onInput(condition: FilterStayTimeCondition) {
    this.$emit("input", condition);
  }

  timeRangeStepData = stayTimeRangeStepInSec;

  getStartPoint(): string {
    const startPointValue = this.condition.stayTimeValueGreaterThanEqual;
    const suffixText = this.$te("greaterThanEqual")
      ? (this.$t("greaterThanEqual") as string)
      : "";
    return this.getTimeString(startPointValue) + suffixText;
  }

  getEndPoint(): string {
    const endPointValue = this.condition.stayTimeValueLessThan;
    const locale = this.$i18n.locale;
    const prefixText =
      !this.isUnlimited && locale === "en"
        ? (this.$t("lessThan") as string)
        : "";
    const suffixText =
      !this.isUnlimited && locale !== "en"
        ? (this.$t("lessThan") as string)
        : "";
    return prefixText + this.getTimeString(endPointValue) + suffixText;
  }

  getTimeString(timeValue: number): string {
    if (timeValue === endPoint) {
      return this.$t("unlimited") as string;
    }

    return timeValue < 60
      ? (this.$t("sec", { value: timeValue }) as string)
      : (this.$t("min", { value: timeValue / 60 }) as string);
  }

  get isUnlimited(): boolean {
    return this.condition.stayTimeValueLessThan === endPoint;
  }

  get enableSliderUi(): boolean {
    return !this.condition.isStayTimeChecked;
  }

  get filterStayTimeRangeValue(): number[] {
    return [
      this.condition.stayTimeValueGreaterThanEqual,
      this.condition.stayTimeValueLessThan
    ];
  }
  set filterStayTimeRangeValue(value: number[]) {
    this.onInput(
      new FilterStayTimeCondition(
        this.condition.isStayTimeChecked,
        value[0],
        value[1]
      )
    );
  }

  get isStayTimeChecked(): boolean {
    return this.condition.isStayTimeChecked;
  }
  set isStayTimeChecked(isStayTimeChecked: boolean) {
    this.onInput(
      new FilterStayTimeCondition(
        isStayTimeChecked,
        this.condition.stayTimeValueGreaterThanEqual,
        this.condition.stayTimeValueLessThan
      )
    );
  }
}
</script>

<style lang="scss" scoped>
.filter-staytime-form {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.filter-staytime-form-check-box {
  margin-right: 15px;
}

.staytime-text-label-point {
  color: $colorBase900;
  margin-left: 10px;
  font-weight: bold;

  &--disabled {
    color: $colorBase500;
  }

  .staytime-text-label-point-suffix {
    color: $colorBase800;
    font-weight: normal;
    &--disabled {
      color: $colorBase500;
    }
  }
}

.filter-staytime-form-slider {
  width: 249px;
  margin-right: 10px;
  margin-top: -3px;
}

::v-deep .filter-staytime-form-slider-tooltip .tooltip__container {
  font-weight: 700;
}
</style>
