import {
  AdditionalSelectConditionInterface,
  ValueInputMethod,
  numberValue
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import {
  LoyaltyType,
  AttributeConditionParamType,
  EnqueteConditionParams,
  AdditionalConditionParams
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * 顧客ロイヤルティ指標(個別)による検索条件
 *
 * 内部的にはアンケートと呼ばれており
 * 概念的にはTransactional NPSを指している
 * @see https://www.bell24.co.jp/ja/ccwiki/cat1/npsnet-promoter-score.html
 *
 * OMO版限定の検索条件
 * 通常の検索条件に加えて、OMOでインポートしたT-NPSのスコアに対する選定条件を付与できる
 *
 * 範囲を指定して、指定したT-NPSのスコアがその範囲内にあるユーザのみを選定する
 */
export class EnqueteCondition implements AdditionalSelectConditionInterface {
  constructor(
    // ユーザ属性定義のID
    public readonly id: number,
    // 指定範囲の開始
    public readonly from: number,
    // 指定範囲の終了
    public readonly to: number,
    // 値指定 or レンジ指定
    public readonly rangeType: ValueInputMethod
  ) {}

  /**
   * 表示用のタイトル
   * @param definitions
   */
  displayTitle(definitions: EnqueteDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? "(" + def.name + ")" : "";

    return (
      (((i18n.t("models.search.customerLoyaltyIndex") as string) +
        "：" +
        i18n.t("models.search.individualScore")) as string) + defName
    );
  }

  /**
   * 設定された値を表示用に加工して返す。
   */
  displayValue(): string {
    return (
      (i18n.t("models.search.score") as string) +
      " " +
      numberValue(this.from, this.to, this.rangeType)
    );
  }

  /**
   * 指定のIDがenqueteDefinitionIdと同じか返す
   * Conversionごとにid名が違うので共通の関数もたせる
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertEnqueteConditionToJson(
  condition: EnqueteCondition
): EnqueteConditionParams {
  return {
    type: AttributeConditionParamType.Loyalty,
    id: condition.id,
    npsType: LoyaltyType.Enquete,
    totalCat: -1,
    totalCatScoreType: -1,
    valRangeType: condition.rangeType,
    valFrom: condition.from,
    valTo: condition.to
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToEnqueteCondition(
  json: EnqueteConditionParams,
  activeEnqueteDefinitions: EnqueteDefinition[]
): EnqueteCondition | null {
  // 利用可能なidリストになければnull
  if (!activeEnqueteDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  return new EnqueteCondition(
    json.id,
    json.valFrom,
    json.valTo,
    json.valRangeType
  );
}

/**
 * 追加条件のJSONがアンケートの条件かどうかを判定する
 */
export function isEnqueteConditionParams(
  params: AdditionalConditionParams
): params is EnqueteConditionParams {
  return (
    params.type === AttributeConditionParamType.Loyalty &&
    params.npsType === LoyaltyType.Enquete
  );
}
