import {
  FilterAdditionalTimingCondition,
  FilterNodeConditionType,
  FilterAdditionalCondition,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterConversionAttributeCondition } from "@/models/search/filter-node-condition/FilterConversionAttributeCondition";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import { FilterNodeParamForConversion } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import {
  firstTimePeriodSelectOption,
  FilterNodeType,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

/**
 * コンバージョンによる絞り込み条件
 *
 * 選択した種類のコンバージョンをしたユーザを絞り込む
 *
 * コンバージョン・イベント属性（複数追加可能）、初回、期間、日付と時間帯 を条件として追加できる
 */
export class FilterNodeForConversion {
  public readonly nodeType = FilterNodeType.Conversion;

  constructor(
    // コンバージョン定義を指定
    public readonly conversionDefinitionId: number,
    /**
     * 追加の検索条件
     *
     * コンバージョン・イベント属性（複数追加可能）、初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterConversionAttributeCondition
      | FilterAdditionalTimingCondition
    )[],
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion()
  ) {}

  get validate(): ValidationResult {
    return validateConditions(this.additionalConditions);
  }

  get hasFirstTime(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterFirstTimeCondition
    );
  }

  isConditionAddable(
    isFirstNode: boolean,
    conversionAttributeDefinitions: ConversionAttributeDefinition[]
  ): boolean {
    const numConditions = this.additionalConditions.length;

    if (conversionAttributeDefinitions.length === 0) {
      if (this.depth !== 0) return false;
      if (isFirstNode) return numConditions < 2;

      return numConditions < 1;
    }
    if (
      conversionAttributeDefinitions.length === 1 &&
      this.additionalConditions.some(
        cnd => cnd instanceof FilterConversionAttributeCondition
      ) &&
      this.depth !== 0
    ) {
      return numConditions < 1;
    }
    return numConditions < 2;
  }

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  insertEdge(): FilterNodeForConversion {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForConversion(
      this.conversionDefinitionId,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion
    );
  }
  removeEdge(): FilterNodeForConversion {
    return new FilterNodeForConversion(
      this.conversionDefinitionId,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion
    );
  }

  removeFirstTimeCondition(): FilterNodeForConversion {
    return new FilterNodeForConversion(
      this.conversionDefinitionId,
      this.additionalConditions.filter(
        cnd => !(cnd instanceof FilterFirstTimeCondition)
      ),
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion
    );
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isFirstNode: boolean,
    isAttributeAvailable: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.ConversionAttribute,
            label: i18n.t("models.search.cvAttribute") as string,
            disabled: !isAttributeAvailable
          }
        ]
      }
    ];

    if (this.depth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions
        )
      );
    }

    return options;
  }
}

export function convertFilterNodeForConversionToJson(
  node: FilterNodeForConversion
): FilterNodeParamForConversion {
  const result: FilterNodeParamForConversion = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.Conversion,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    cv: {
      id: node.conversionDefinitionId,
      attributes: []
    }
  };

  const conditions: (
    | FilterConversionAttributeCondition
    | FilterAdditionalTimingCondition
  )[] = node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterConversionAttributeCondition) {
      result.cv.attributes.push({
        id: condition.conversionAttributeDefinitionId,
        value: condition.value,
        word_match_method: condition.matchMethod
      });
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForConversion(
  json: FilterNodeParamForConversion,
  activeConversionDefinitions: ConversionDefinition[],
  activeConversionAttributeDefinitions: ConversionAttributeDefinition[],
  isCheckDefinition = true
): FilterNodeForConversion | null {
  // チェックが必要かつ利用可能なidリストになければnull
  if (
    isCheckDefinition &&
    !activeConversionDefinitions.some(def => def.id === json.cv.id)
  ) {
    return null;
  }

  const additionalConditions: (
    | FilterConversionAttributeCondition
    | FilterAdditionalTimingCondition
  )[] = getAdditionalTimingConditions(json);
  if (json.cv.attributes) {
    for (const cvAttribute of json.cv.attributes) {
      // チェックが必要がないまたは、利用可能なCV・イベント属性なら追加
      if (
        !isCheckDefinition ||
        activeConversionAttributeDefinitions.some(
          def => def.id === cvAttribute.id
        )
      ) {
        additionalConditions.push(
          new FilterConversionAttributeCondition(
            cvAttribute.id,
            cvAttribute.value,
            cvAttribute.word_match_method
          )
        );
      }
    }
  }
  return new FilterNodeForConversion(
    json.cv.id,
    additionalConditions,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!)
  );
}
