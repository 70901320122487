<template>
  <UgTableHeaderCell :class="className" :width="width" @click="onClick">
    <slot></slot>
  </UgTableHeaderCell>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import UgTableHeaderCell from "@/components/table/UgTableHeaderCell.vue";

@Component({
  components: {
    UgTableHeaderCell
  }
})
export default class LightUgTableHeaderCell extends Vue {
  @Prop({ type: String, default: "auto" })
  width!: string;

  @Prop({ type: String, default: "left" })
  textAlign!: "center" | "right" | "left";

  @Prop({ type: Boolean, default: false })
  noPadding!: boolean;

  @Prop({ type: Boolean, default: false })
  isThin!: boolean;

  @Emit("clicks")
  onClick() {}

  get className() {
    const classes = ["lightUgTableHeaderCell"];
    if (this.textAlign !== "left") {
      classes.push(`lightUgTableHeaderCell--${this.textAlign}`);
    }
    if (this.noPadding) {
      classes.push("lightUgTableHeaderCell--noPadding");
    }
    if (this.isThin) {
      classes.push("lightUgTableHeaderCell--thin");
    }
    return classes.join(" ");
  }
}
</script>

<style lang="scss" scoped>
.lightUgTableHeaderCell {
  height: auto;
  text-align: left;
  padding: 15px;
  background: $colorBase300;
  color: $colorBase900;
  box-sizing: border-box;
  border-right: solid 1px $colorBase400;

  &:last-child {
    border-right: none;
  }
}
.lightUgTableHeaderCell--right {
  text-align: right;
}
.lightUgTableHeaderCell--center {
  text-align: center;
}
.lightUgTableHeaderCell--noPadding {
  padding: 0;
}
.lightUgTableHeaderCell--thin {
  padding: 8px 15px 7px;
}
</style>
