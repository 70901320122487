<i18n
  src="@/i18n/components/observation/visit/visit-summary/visit-summary-table.json"
></i18n>

<template>
  <AnalysisTable class="visitSummaryTable" data-cy="visitSummaryTable">
    <template #header>
      <AnalysisTableHeaderRow class="visitSummaryTable__header">
        <AnalysisTableHeaderCell v-t="'action'" width="70px" height="auto" />
        <AnalysisTableHeaderCell v-t="'url'" width="285px" height="auto" />
        <AnalysisTableHeaderCell
          v-t="'pageTitle'"
          width="285px"
          height="auto"
        />
        <AnalysisTableHeaderCell v-t="'count'" width="61px" height="auto" />
        <AnalysisTableHeaderCell v-t="'stayTime'" width="82px" height="auto" />
      </AnalysisTableHeaderRow>
    </template>
    <template v-for="(actionData, actionDataIndex) in allActionData">
      <tr
        v-for="(row, rowIndex) in actionData.data"
        :key="getKey(actionDataIndex, rowIndex)"
      >
        <AnalysisTableCell
          v-if="rowIndex === 0"
          v-t="actionData.titleKey"
          width="70px"
          :rowspan="actionData.data.length"
          class="visitSummaryTable__cellAction"
        />
        <AnalysisTableCell
          v-if="row.url"
          width="285px"
          :class="{
            visitSummaryTable__cellHover:
              hoverKey === getKey(actionDataIndex, rowIndex)
          }"
          @mouse-enter="onEnter(getKey(actionDataIndex, rowIndex))"
          @mouse-leave="onLeave"
        >
          <Tooltip
            class="visitSummaryTable__cellTooltip"
            :placement="tooltipPlacement.TopStart"
            :fixed-arrow-position="true"
            :wrap="true"
          >
            <template #content>
              <div class="visitSummaryTable__cellTooltipContent">{{
                row.url
              }}</div>
            </template>
            <div class="visitSummaryTable__cellInnerUrl">{{ row.url }}</div>
          </Tooltip>
        </AnalysisTableCell>
        <AnalysisTableCell
          class="visitSummaryTable__cellPageTitle"
          :class="{
            visitSummaryTable__cellHover:
              hoverKey === getKey(actionDataIndex, rowIndex)
          }"
          :width="row.url ? '285px' : '570px'"
          v-bind="colspanForName(row.url)"
          @mouse-enter="onEnter(getKey(actionDataIndex, rowIndex))"
          @mouse-leave="onLeave"
        >
          <Tooltip
            class="visitSummaryTable__cellTooltip"
            :placement="tooltipPlacement.TopStart"
            :fixed-arrow-position="true"
            :wrap="true"
          >
            <template #content>
              <div class="visitSummaryTable__cellTooltipContent">{{
                row.name
              }}</div>
            </template>
            <div
              :class="
                row.url
                  ? 'visitSummaryTable__cellInnerPageTitle'
                  : 'visitSummaryTable__cellInnerPageTitle--wide'
              "
              >{{ row.name }}</div
            >
          </Tooltip>
        </AnalysisTableCell>
        <AnalysisTableCell
          width="61px"
          class="visitSummaryTable__cellCount"
          :class="{
            visitSummaryTable__cellHover:
              hoverKey === getKey(actionDataIndex, rowIndex)
          }"
          @mouse-enter="onEnter(getKey(actionDataIndex, rowIndex))"
          @mouse-leave="onLeave"
          >{{ row.count }}</AnalysisTableCell
        >
        <AnalysisTableCell
          width="82px"
          class="visitSummaryTable__cellStayTime"
          :class="{
            visitSummaryTable__cellHover:
              hoverKey === getKey(actionDataIndex, rowIndex)
          }"
          @mouse-enter="onEnter(getKey(actionDataIndex, rowIndex))"
          @mouse-leave="onLeave"
          >{{ row.stayTime }}</AnalysisTableCell
        >
      </tr>
    </template>
  </AnalysisTable>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { TooltipPlacement } from "@/const/tooltip";
import Tooltip from "@/components/Tooltip.vue";
import AnalysisTable from "@/components/table/AnalysisTable.vue";
import AnalysisTableCell from "@/components/table/AnalysisTableCell.vue";
import AnalysisTableHeaderRow from "@/components/table/AnalysisTableHeaderRow.vue";
import AnalysisTableHeaderCell from "@/components/table/AnalysisTableHeaderCell.vue";
import { VisitSummary } from "@/models/visit-summary/VisitSummary";
import { VisitSummaryRowData } from "@/models/visit-summary/VisitSummaryRowData";

@Component({
  components: {
    Tooltip,
    AnalysisTable,
    AnalysisTableCell,
    AnalysisTableHeaderRow,
    AnalysisTableHeaderCell
  }
})
export default class VisitSummaryTable extends Vue {
  tooltipPlacement = TooltipPlacement;
  hoverKey: string = "";

  @Prop({ type: Object, required: true })
  data!: VisitSummary;

  get allActionData(): { titleKey: string; data: VisitSummaryRowData[] }[] {
    return [
      { titleKey: "cv", data: this.data.getCvList },
      { titleKey: "event", data: this.data.getEventList },
      { titleKey: "inflow", data: this.data.getInflowList },
      { titleKey: "leave", data: this.data.getWithdrawalList },
      { titleKey: "pv", data: this.data.getPvList }
    ];
  }

  getKey(actionDataIndex: number, index: number) {
    return actionDataIndex + "-" + index;
  }

  colspanForName(url: string) {
    if (url === "") {
      return { colspan: 2 };
    }
    return {};
  }

  onEnter(key: string): void {
    this.hoverKey = key;
  }

  onLeave(): void {
    this.hoverKey = "";
  }
}
</script>

<style scoped lang="scss">
.visitSummaryTable {
  width: 783px;
}
.visitSummaryTable__header th {
  border-left: 1px solid $colorBlue820;
}
.visitSummaryTable__header:first-child {
  border-left: 0;
}
.visitSummaryTable__cellHover {
  background-color: $colorBlue500;
}
.visitSummaryTable__cellAction {
  background: $colorBase300;
  vertical-align: top;
  font-weight: bold;
}
.visitSummaryTable__cellTooltip {
  display: table-cell;
  vertical-align: middle;
}
.visitSummaryTable__cellTooltipContent {
  width: 410px;
}
.visitSummaryTable__cellInnerUrl {
  overflow: hidden;
  max-width: 265px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.visitSummaryTable__cellInnerPageTitle,
.visitSummaryTable__cellInnerPageTitle--wide {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.visitSummaryTable__cellInnerPageTitle {
  max-width: 265px;
}
.visitSummaryTable__cellInnerPageTitle--wide {
  max-width: 554px;
}
</style>
