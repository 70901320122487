import HttpClient from "@/api/HttpClient";
import { ApiParam } from "@/api/api-param";
import { ApiUrl } from "@/api/api-url";
import * as Highcharts from "highcharts";

export default class ApiWordcloud {
  constructor(private readonly httpClient: HttpClient) {}

  public fetchWordcloudData = (
    condition: ApiParam.FetchWordcloudData
  ): Promise<Highcharts.PointOptionsObject[]> => {
    return this.httpClient.post<Highcharts.PointOptionsObject[]>(
      ApiUrl.WORDCLOUD_DATA,
      condition
    );
  };
}
