import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { User, Memo } from "@/models/User";
import { i18n } from "@/i18n";
import { UgTag } from "@/store/modules/ugTag";
import { Usec } from "@/util/date-util";

// ユーザ詳細を表示するユーザと、どの時間の訪問から表示するかの情報
export interface ShowDetailTargetUser {
  user: User;
  startBaseTimeUsec: Usec;
}

export class UserState {
  currentUser: User | null = null;
  // 観察画面を開いた時にこの時間の訪問から取得する
  startBaseTimeUsec: Usec = 0;
  targetUserId: string | null = null;
  targetStartBaseTimeUsec: Usec = 0;
  showUserDetail = false;
  pathBeforeShowUserDetail = "";
  titleBeforeShowUserDetail = "";
  shouldScrollAndDisplayGramId: string | null = null;
  checkedUserIds: string[] = [];
}

const mutations: MutationTree<UserState> = {
  /**
   * Set current user
   */
  setCurrentUser(state: UserState, user: User) {
    state.currentUser = user;
  },

  /**
   * Set start base time sec
   */
  setStartBaseTimeUsec(state: UserState, startBaseTimeUsec: Usec) {
    state.startBaseTimeUsec = startBaseTimeUsec;
  },

  /**
   * Set should scrol land display gram id
   */
  setShouldScrollAndDisplayGramId(state: UserState, gramId: string) {
    state.shouldScrollAndDisplayGramId = gramId;
  },

  /**
   * Set show user detail
   */
  setShowUserDetail(state: UserState, showUserDetail: boolean) {
    state.showUserDetail = showUserDetail;
  },

  /**
   * Set show target user id
   */
  setTartgeUserId(state: UserState, userId: string) {
    state.targetUserId = userId;
  },

  /**
   * Set target start base time sec
   */
  setTargetStartBaseTimeUsec(state: UserState, targetStartBaseTimeUsec: Usec) {
    state.targetStartBaseTimeUsec = targetStartBaseTimeUsec;
  },
  setPathBeforeShowUserDetail(
    state: UserState,
    pathBeforeShowUserDetail: string
  ) {
    state.pathBeforeShowUserDetail = pathBeforeShowUserDetail;
  },
  setTitleBeforeShowUserDetail(
    state: UserState,
    titleBeforeShowUserDetail: string
  ) {
    state.titleBeforeShowUserDetail = titleBeforeShowUserDetail;
  },
  addCheckedUser(state: UserState, userId: string) {
    state.checkedUserIds.push(userId);
  },

  initialize(state: UserState) {
    state.currentUser = null;
    state.startBaseTimeUsec = 0;
    state.targetUserId = null;
    state.targetStartBaseTimeUsec = 0;
    state.showUserDetail = false;
    state.pathBeforeShowUserDetail = "";
    state.titleBeforeShowUserDetail = "";
    state.shouldScrollAndDisplayGramId = null;
    state.checkedUserIds = [];
  },

  updateMemo(
    state: UserState,
    { userId, memo }: { userId: string; memo: Memo }
  ) {
    if (state.currentUser === null) {
      return;
    }
    if (state.currentUser.id === userId) {
      state.currentUser = state.currentUser.updateMemo(memo);
    }
  }
};

const actions: ActionTree<UserState, RootState> = {
  /**
   * Show user detail
   */
  showUserDetail({ commit }, target: ShowDetailTargetUser) {
    commit("setCurrentUser", target.user);
    commit("setStartBaseTimeUsec", target.startBaseTimeUsec);
    commit("setShowUserDetail", true);
    commit("addCheckedUser", target.user.id);

    const pageTitle = i18n.t("pageTitle.user-detail") as string;
    const usergram = i18n.t("pageTitle.usergram") as string;
    document.title = pageTitle + " | " + usergram;
    UgTag.pushPv();
  },

  /**
   * Set should scroll and display gram id
   */
  setShouldScrollAndDisplayGramId({ commit }, gramId: string) {
    commit("setShouldScrollAndDisplayGramId", gramId);
  }
};

export const user = {
  namespaced: true,
  state: new UserState(),
  mutations: mutations,
  actions: actions
};
