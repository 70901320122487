<template>
  <div class="notificationIconButton">
    <slot></slot>
    <span v-if="count > 0" class="notificationIconButton__badge">{{
      count
    }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NotificationIconButton extends Vue {
  @Prop({ type: Number, default: 0 })
  count!: number;
}
</script>

<style lang="scss" scoped>
.notificationIconButton {
  position: relative;
}
.notificationIconButton__badge {
  position: absolute;
  top: 2px;
  right: 3px;
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 12px;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
  color: $colorWhite;
  background: $colorBadge;
  border: solid 2px $colorWhite;
  pointer-events: none;
}
</style>
