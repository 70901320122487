import { SearchEngine as SearchEngineJson } from "@/api/apis/ApiSystem";

/**
 * サーチエンジン id/name
 *
 * 絞り込みの 流入経路 > 自然検索からの流入 > 条件を追加 検索エンジンリストに表示されるデータ
 */
export class SearchEngine {
  constructor(public readonly id: number, public readonly name: string) {}

  public static fromJson(json: SearchEngineJson): SearchEngine {
    return new SearchEngine(json.id, json.name);
  }
}
