<i18n
  src="@/i18n/components/client-settings/coordination/conversion/coordination-conversion-edit-table.json"
></i18n>
<template>
  <div class="conversionEditTable">
    <SettingsTable>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'targetData'"
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell width="480px">
          {{ coordinationConversion.targetData }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        >
          {{ $t("conversion") }}
          <span class="conversionEditTableCell__required">{{
            $t("required")
          }}</span>
        </SettingsTableCell>
        <SettingsTableCell width="480px">
          <template v-if="!conversionLimitReached">
            <RadioButton
              :value="coordinationConversion.creationMethod"
              :label="CreationMethod.NEW"
              @change="onSelectCreationMethod"
              >{{ $t("newConversion") }}
            </RadioButton>
            <InputText
              :value="coordinationConversion.conversionName"
              class="conversionEditTable__conversionMethodContent"
              :placeholder="$t('conversionPlaceholder')"
              :max-length="40"
              @input="onInputConversionName"
            />
            <br />
          </template>
          <RadioButton
            :value="coordinationConversion.creationMethod"
            :label="CreationMethod.EXISTING"
            @change="onSelectCreationMethod"
            >{{ $t("existingConversion") }}
          </RadioButton>
          <br />
          <SelectBox
            :value="coordinationConversion.conversionId"
            :options="selectConversionOptions"
            class="conversionEditTable__conversionMethodContent"
            @input="onSelectConversion"
          />
          <div
            v-if="conversionLimitReached"
            class="conversionEditTable__conversionLimitText"
            v-html="conversionLimitText"
          />
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          class="conversionEditTable__inputParameterHeader"
          :style="{ padding: '20px 10px 20px 20px' }"
        >
          <span v-t="'coordinationParameter'" />
          <Tooltip :placement="tooltipPlacement.Bottom">
            <Icon
              :icon="icons.Help"
              :color="color.Base600"
              style="margin-left: 4px"
            />
            <div
              slot="content"
              v-t="'conversionParameterTooltipText'"
              class="conversionEditTable__tooltipContent"
            />
          </Tooltip>
          <span class="conversionEditTableCell__required">{{
            $t("required")
          }}</span>
        </SettingsTableCell>
        <SettingsTableCell>
          <div
            v-for="(param, index) in coordinationConversion.path"
            :key="index"
          >
            <div class="conversionEditTable__inputParameter">
              <InputText
                :value="param"
                class="conversionEditTable__inputParameterForm"
                :placeholder="$t('conversionParameterPlaceholder')"
                :max-length="40"
                :has-error="isValidCvParam(param)"
                @input="onInputParam($event, index)"
              />
              <IconButton
                v-show="numForm > 1"
                :icon="icons.ButtonClose"
                :size="18"
                @click="onClickDelete(index)"
              />
            </div>
            <div
              v-show="isValidCvParam(param)"
              v-t="'errorText'"
              class="conversionEditTable__errorText"
            />
          </div>

          <div
            v-if="canAddParameter"
            class="conversionEditTable__addParameter"
            @click="onClickNext"
          >
            <IconButton
              :size="18"
              :icon="icons.PlusCircle"
              class="conversionEditTable__addParameterIcon"
            />
            <span v-t="'addConversionParameter'" />
          </div>
          <div
            v-t="'parameterDescription'"
            class="conversionEditTable__parameterDescription"
          />
        </SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import InputText from "@/components/form/InputText.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import {
  ConversionDefinition,
  CoordinationConversionEditableData
} from "@/models/client-settings/ConversionDefinition";
import { isInValidCvParam as importedIsInValidCvParam } from "@/models/client-settings/ConversionDefinition";
import { CreationMethod } from "@/models/client-settings/Coordination";

const EMPTY_CONVERSION_VALUE = 0;

@Component({
  components: {
    Icon,
    IconButton,
    RadioButton,
    SelectBox,
    InputText,
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell,
    Tooltip
  }
})
export default class CoordinationConversionEditTable extends Vue {
  CreationMethod = CreationMethod;
  color = Colors;
  icons = Icons;
  tooltipPlacement = TooltipPlacement;

  @Prop({ type: Object, required: true })
  coordinationConversion!: CoordinationConversionEditableData;

  @Prop({ type: Boolean, default: false })
  conversionLimitReached!: boolean;

  @Prop({ type: Boolean, required: true })
  isContractWeb!: boolean;

  @Emit("update")
  updateCoordinationConversion(data: CoordinationConversionEditableData) {
    return data;
  }

  get conversionLimitText() {
    return this.isContractWeb
      ? this.$t("conversionLimit")
      : this.$t("conversionLimitAppOnly");
  }

  get activeConversions(): ConversionDefinition[] {
    return this.$store.getters["conversion/activeConversions"];
  }

  get selectConversionOptions(): SelectOption[] {
    return [
      {
        label: "-----------",
        value: EMPTY_CONVERSION_VALUE,
        disabled: false
      },
      ...this.activeConversions.map(conversion => ({
        label: conversion.name,
        value: conversion.id,
        disabled: false
      }))
    ];
  }

  get numForm(): number {
    return this.coordinationConversion.path.length;
  }

  get canAddParameter() {
    return this.numForm < 5;
  }

  onInputConversionName(value: string) {
    this.updateCoordinationConversion({
      ...this.coordinationConversion,
      conversionName: value.trim()
    });
  }

  onSelectCreationMethod(value: CreationMethod) {
    this.updateCoordinationConversion({
      ...this.coordinationConversion,
      creationMethod: value
    });
  }

  onSelectConversion(value: string) {
    this.updateCoordinationConversion({
      ...this.coordinationConversion,
      conversionId: Number(value)
    });
  }

  onInputParam(value: string, index: number) {
    const newParams = [];
    const currentParams = [...this.coordinationConversion.path];
    for (let i = 0; i < this.numForm; i++) {
      const newParam = i === index ? value.trim() : currentParams[i] || "";
      newParams.push(newParam);
    }

    this.updateCoordinationConversion({
      ...this.coordinationConversion,
      path: newParams
    });
  }

  onClickDelete(index: number) {
    const newParams = [...this.coordinationConversion.path];
    newParams.splice(index, 1);

    this.updateCoordinationConversion({
      ...this.coordinationConversion,
      path: newParams
    });
  }

  onClickNext() {
    this.updateCoordinationConversion({
      ...this.coordinationConversion,
      path: [...this.coordinationConversion.path, ""]
    });
  }

  isValidCvParam(path: string): boolean {
    return importedIsInValidCvParam(path);
  }
}
</script>

<style lang="scss" scoped>
.conversionEditTable {
  margin-bottom: 16px;
  width: 680px;
}
.conversionEditTableCell__required {
  color: #c93636;
  display: inline;
  float: right;
  margin-left: 10px;
}
.conversionEditTable__conversionMethodContent {
  margin-left: 35px;
}
.conversionEditTable__conversionLimitText {
  margin-top: 12px;

  ::v-deep a {
    color: $colorBase700;
    text-decoration: underline;
    &:hover {
      color: $colorBase800;
    }
  }
}
.conversionEditTable__tooltipContent {
  width: 226px;
  white-space: pre-line;
  word-wrap: break-word;
  line-height: 1.5;
}
.conversionEditTable__inputParameterHeader > * {
  display: inline-flex;
  vertical-align: middle;
}
.conversionEditTable__inputParameter {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.conversionEditTable__inputParameterForm {
  width: 400px;
  margin-right: 8px;
}
.conversionEditTable__errorText {
  margin-bottom: 10px;
  color: $colorError;
}
.conversionEditTable__addParameter {
  display: inline-flex;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
}
.conversionEditTable__addParameterIcon {
  margin-right: 5px;
}
.conversionEditTable__parameterDescription {
  margin-top: 6px;
  color: $colorBase700;
}
</style>
