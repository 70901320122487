import { SelectConditionInterface } from "@/models/search/select-condition/SelectCondition";
import {
  AdditionalSelectCondition,
  convertAdditionalConditionToJson,
  convertJsonToAdditionalCondition
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { MAX_HOLD_ADDITIONAL_CONDITION_SIZE } from "./SelectCondition";
import { SelectByNpsChangeQuery, SelectQuery } from "@/api/apis/ApiSearch";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { i18n } from "@/i18n";

/**
 * NPSのスコアによる、高評価、中評価、低評価の分類
 *
 * @see https://en.wikipedia.org/wiki/Net_Promoter
 */
export enum NpsLabel {
  Promoter,
  Passive,
  Detractor
}

/**
 * 顧客ロイヤルティの変化によるユーザ選定条件(OMO版のみ)
 *
 * RNpsのスコアの変化を指定して、それに該当するユーザを選定する。
 *
 * スコアは低評価、中評価、高評価の３区分に分けられる。
 * 以前のスコア区分、最新のスコア区分を指定することで
 * そのようにスコアが変化したユーザだけを選定することができる。
 *
 * 以前のスコア区分は複数指定できる。
 *
 * Relational Net Promoter Score
 * @see https://www.bell24.co.jp/ja/ccwiki/cat1/npsnet-promoter-score.html
 */
export class SelectByNpsChangeCondition implements SelectConditionInterface {
  constructor(
    // 以前のNPSの結果（複数選択可能）
    public readonly previousNpsLabels: NpsLabel[],
    // 最近のNPSの結果
    public readonly npsLabel: NpsLabel,
    /**
     * 追加の検索条件
     *
     * 顧客ロイヤルティ指標(個別のみ)、ビジネス指標、ユーザ属性に対する条件を5つまで指定できる
     */
    public readonly additionalConditions: AdditionalSelectCondition[]
  ) {}

  static emptyCondition(): SelectByNpsChangeCondition {
    return new SelectByNpsChangeCondition(
      [NpsLabel.Detractor],
      NpsLabel.Passive,
      []
    );
  }

  // AdditionalSelectConditionを追加できるか
  get isAdditionalConditionAppendable(): boolean {
    return (
      this.additionalConditions.length < MAX_HOLD_ADDITIONAL_CONDITION_SIZE
    );
  }

  get validate(): ValidationResult {
    if (this.previousNpsLabels.length <= 0) {
      return {
        isValid: false,
        errorMessage: i18n.t(
          "models.search.specifyConditionFromPrevious"
        ) as string
      };
    }

    return { isValid: true };
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectByNpsChangeConditionToJson(
  condition: SelectByNpsChangeCondition,
  npsDefinition: NpsDefinition
): SelectByNpsChangeQuery {
  function getNpsQuery(label: NpsLabel) {
    if (label === NpsLabel.Promoter) {
      return {
        category: NpsLabel.Promoter,
        val_from: npsDefinition.highThreshold,
        val_to: npsDefinition.max
      };
    }

    if (label === NpsLabel.Passive) {
      return {
        category: NpsLabel.Passive,
        val_from: npsDefinition.lowThreshold + 1,
        val_to: npsDefinition.highThreshold - 1
      };
    }

    return {
      category: NpsLabel.Detractor,
      val_from: npsDefinition.min,
      val_to: npsDefinition.lowThreshold
    };
  }

  return {
    change: {
      curr: getNpsQuery(condition.npsLabel),
      prev_list: condition.previousNpsLabels.map(getNpsQuery)
    },
    score_time: null,
    sub_cnds: condition.additionalConditions.map(
      convertAdditionalConditionToJson
    )
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByNpsChangeCondition(
  query: SelectByNpsChangeQuery,
  activeDefinitions: ActiveDefinitions
): SelectByNpsChangeCondition {
  const additionalConditions: AdditionalSelectCondition[] = [];
  query.sub_cnds.forEach(cnd => {
    const addCnd = convertJsonToAdditionalCondition(cnd, activeDefinitions);
    if (addCnd !== null) {
      additionalConditions.push(addCnd);
    }
  });

  return new SelectByNpsChangeCondition(
    query.change.prev_list.map(p => p.category),
    query.change.curr.category,
    additionalConditions
  );
}

/**
 * JSONがコンバージョン検索のJSONかどうかを判定する
 */
export function isSelectByNpsChangeQuery(
  query: SelectQuery
): query is SelectByNpsChangeQuery {
  return "change" in query && "score_time" in query && query["change"] !== null;
}
