export enum InputFormSuggestionStatus {
  SUCCEEDED = "OK",
  RUNNING = "RUNNING",
  FAILED = "FAILED"
}

export enum InputFormSuggestionType {
  WEB_URL = "web-url",
  WEB_TITLE = "web-title",
  APP_URL = "app-url",
  APP_TITLE = "app-title"
}
