<template>
  <div
    class="scroll-wrapper"
    data-cy="scroll-wrapper"
    :style="style"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <VueScroll
      ref="vs"
      :ops="option"
      @handle-scroll="updateCurrentPosition"
      @handle-resize="onResize"
    >
      <div ref="scrollContent" class="scroll-wrapper__inner">
        <slot></slot>
      </div>
    </VueScroll>

    <div
      v-if="arrowShow"
      class="scroll-wrapper__arrow scroll-wrapper__arrow-prev"
      data-cy="arrow-left"
    >
      <IconButton
        v-show="showArrowLeft"
        :icon="icons.ArrowLeft"
        :size="arrowSize"
        :button-type="iconButtonType"
        @click="onClickArrowLeft"
      />
    </div>

    <div
      v-if="arrowShow"
      class="scroll-wrapper__arrow scroll-wrapper__arrow-next"
      data-cy="arrow-right"
    >
      <IconButton
        v-show="showArrowRight"
        :icon="icons.ArrowRight"
        :size="arrowSize"
        :button-type="iconButtonType"
        @click="onClickArrowRight"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import debounce from "lodash/debounce";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { Browser, Os } from "@/const/UserAgent";
import { getBrowser, getOs } from "@/util/user-agent-util";
import VueScroll, { Config } from "vuescroll";
import IconButton from "@/components/IconButton.vue";

const ARROW_SIZE: number = 25;
export const ARROW_SPACE: number = 10 + ARROW_SIZE;

const SHOW_SCROLLBAR_OPACITY = 0.5;
const HIDE_SCROLLBAR_OPACITY = 0;

export enum Overflow {
  AllEnabled = 1,
  ScrollXOnlyEnabled = 2,
  ScrollYOnlyEnabled = 3,
  AllDisabled = 4
}

interface Scroll {
  type: string;
  process: number;
  barSize: number;
}

export interface ScrollVertical extends Scroll {
  scrollTop: number;
}

export interface ScrollHorizontal extends Scroll {
  scrollLeft: number;
}

interface position {
  x: number;
  y: number;
  dx: number;
  dy: number;
}

@Component({
  components: {
    VueScroll,
    IconButton
  }
})
export default class ScrollWrapper extends Vue {
  @Prop({ type: Number, default: Overflow.AllEnabled })
  overflow!: Overflow;

  @Prop({ type: Number, default: 0 })
  destinationY!: number;

  @Prop({ type: Number, default: 0 })
  destinationX!: number;

  @Prop({ type: Boolean, default: false })
  arrowShow!: boolean;

  @Prop({ type: Number, default: ARROW_SPACE })
  arrowPadding!: number;

  @Prop({ type: Boolean, default: false })
  showScrollbarWithMouseEnter!: boolean;

  onScrollY(scrollTop: number) {
    this.$emit("update:scroll-y", scrollTop);
  }

  onScrollX(scrollLeft: number) {
    this.$emit("update:scroll-x", scrollLeft);
  }

  updateScrollWidth(scrollWidth: number) {
    this.$emit("update:scroll-width", scrollWidth);
  }

  updateIsScrollable(isScrollable: boolean) {
    this.$emit("update:is-scrollable", isScrollable);
  }

  @Watch("destinationX")
  autoScrollX() {
    this.scrollTo(this.destinationY, this.destinationX);
  }

  arrowSize: number = ARROW_SIZE;
  icons = Icons;
  colors = Colors;
  iconButtonType = IconButtonType.Weak;

  scrollingX: boolean = true;
  scrollingY: boolean = true;

  currentPositionX: number = 0;
  currentPositionY: number = 0;

  scrollContentWidth: number = 0;
  vueScrollWidth: number = 0;

  baselineEdgeLength: number = 20;
  startLineX: number = 10;

  debounceSetScrollWidth = debounce(this.setScrollWidth, 600);

  hideScrollBar: boolean = true;

  option: Config = {
    scrollPanel: {
      initialScrollY: false,
      initialScrollX: false,
      scrollingX: true,
      scrollingY: true,
      speed: 300,
      easing: undefined,
      verticalNativeBarPos: "right"
    },
    rail: {
      background: "#01a99a",
      opacity: 0,
      size: "6px",
      specifyBorderRadius: false,
      gutterOfSide: "0px",
      keepShow: false
    },
    bar: {
      verticalNativeBarPos: "right",
      showDelay: 500,
      onlyShowBarOnScroll: true,
      keepShow: true,
      specifyBorderRadius: false,
      minSize: 0,
      disable: false,
      background: Colors.Base500,
      opacity: SHOW_SCROLLBAR_OPACITY
    }
  };

  mounted() {
    this.setOptipn();

    this.$nextTick(() => {
      this.setScrollWidth();
      this.setScrollContentWidth();
      this.updateIsScrollable(this.isScrollable);
    });

    window.addEventListener("resize", this.debounceSetScrollWidth);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.debounceSetScrollWidth);
  }

  updateCurrentPosition(
    vertical: ScrollVertical,
    horizontal: ScrollHorizontal
  ) {
    this.currentPositionX = horizontal.scrollLeft;
    this.currentPositionY = vertical.scrollTop;
    this.onScrollY(vertical.scrollTop);
    this.onScrollX(horizontal.scrollLeft);
  }

  setScrollWidth() {
    this.vueScrollWidth = this.vs.$el.scrollWidth;
    this.updateScrollWidth(this.vueScrollWidth);
  }

  get style() {
    if (this.arrowShow) {
      return {
        paddingLeft: this.arrowPadding + "px",
        paddingRight: this.arrowPadding + "px"
      };
    }

    return {};
  }

  get scrollContent(): Element {
    return this.$refs.scrollContent as HTMLElement;
  }

  get vs(): VueScroll {
    return this.$refs.vs as VueScroll;
  }

  get showArrowLeft(): boolean {
    return this.currentPositionX !== 0;
  }

  get showArrowRight(): boolean {
    const adjuster: number = 2;
    return (
      this.currentPositionX + adjuster <
      this.scrollContentWidth - this.vueScrollWidth
    );
  }

  get shownBaselineLength(): number {
    return this.vueScrollWidth - this.startLineX - this.baselineEdgeLength * 2;
  }

  // MacのFirefoxではライブラリのScrollbarとブラウザのScrollbarが両方表示されてしまう。
  // ブラウザのを消すことは不可能のため、このmethodを使用し制御する
  get isMacFirefox(): boolean {
    return getBrowser() === Browser.Firefox && getOs() === Os.Mac;
  }

  get isScrollable(): boolean {
    return this.scrollContentWidth !== this.vueScrollWidth;
  }

  scrollControl() {
    switch (this.overflow) {
      case Overflow.AllEnabled:
        this.scrollingX = true;
        this.scrollingY = true;
        break;
      case Overflow.ScrollXOnlyEnabled:
        this.scrollingY = false;
        break;
      case Overflow.ScrollYOnlyEnabled:
        this.scrollingX = false;
        break;
      case Overflow.AllDisabled:
        this.scrollingX = false;
        this.scrollingY = false;
        break;
    }
  }

  setOptipn() {
    this.scrollControl();

    const ops = this.option;
    const scrollPanel = ops.scrollPanel;
    const bar = ops.bar;

    if (
      bar !== undefined &&
      (this.isMacFirefox || this.showScrollbarWithMouseEnter)
    ) {
      bar.opacity = HIDE_SCROLLBAR_OPACITY;
    }

    if (scrollPanel !== undefined) {
      scrollPanel.scrollingX = this.scrollingX;
      scrollPanel.scrollingY = this.scrollingY;
    }

    this.option = ops;
  }

  onClickArrowLeft() {
    this.scrollXBy(false);
  }

  onClickArrowRight() {
    this.scrollXBy(true);
  }

  scrollXBy(direction: boolean) {
    const sign: number = direction ? 1 : -1;
    const Pos: position = {
      x: 0,
      y: 0,
      dx: sign * this.shownBaselineLength,
      dy: 0
    };

    this.vs.scrollBy(Pos);
  }

  scrollBy(dy: number, dx: number) {
    const Pos: position = {
      x: 0,
      y: 0,
      dx: dx,
      dy: dy
    };

    this.vs.scrollBy(Pos);
  }

  scrollTo(y: number, x: number) {
    const Pos: position = {
      x: x,
      y: y,
      dx: 0,
      dy: 0
    };

    this.vs.scrollTo(Pos, 0);
  }

  onResize() {
    this.debounceSetScrollWidth();
    this.setScrollContentWidth();
  }

  setScrollContentWidth() {
    this.scrollContentWidth = this.scrollContent.scrollWidth;
  }

  onMouseEnter() {
    if (
      this.option.bar !== undefined &&
      this.showScrollbarWithMouseEnter &&
      !this.isMacFirefox
    ) {
      this.option.bar.opacity = SHOW_SCROLLBAR_OPACITY;
    }
  }

  onMouseLeave() {
    if (
      this.option.bar !== undefined &&
      this.showScrollbarWithMouseEnter &&
      !this.isMacFirefox
    ) {
      this.option.bar.opacity = HIDE_SCROLLBAR_OPACITY;
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.scroll-wrapper__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &-prev {
    left: 6px;
  }

  &-next {
    right: 6px;
  }
}

/* To fix bug of vuescroll ( UK-12696 ) */
::v-deep .__view {
  width: fit-content;
}
</style>
