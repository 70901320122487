<i18n src="@/i18n/components/search/search.json"></i18n>
<template>
  <Dialog
    :visible="visible"
    :show-close-button="false"
    width="600px"
    top="15vh"
    @open="onOpen"
    @close="onClickCancel"
  >
    <template #title>
      <div
        class="additional-condition-form-title"
        data-cy="additional-condition-form-title"
      >
        {{ title }}
      </div>
    </template>
    <div class="additional-condition-form">
      <!-- ロイヤルティの種類選択・選択でいない場合は表示 -->
      <div v-if="isLoyalty" class="additional-condition-form-loyalty-select">
        <div v-t="'latest'" class="latest" />
        <SelectBox
          v-if="showLoyaltySelect"
          v-model="loyaltyTypeSelectValue"
          :options="loyaltyTypeOptions"
          width="100%"
        />
        <div v-else-if="isEnqueteForm" v-t="'individualScore'" />
        <div v-else>{{ firstNpsDefinitionName }}</div>
        <div v-t="'filter'" class="is" />
      </div>

      <!-- NPSの入力方法選択 -->
      <div
        v-if="showNpsInputMethodSelect"
        class="additional-condition-form-definition-select"
      >
        <SelectBox
          v-model="npsInputMethodValue"
          :options="npsInputMethodOptions"
          width="100%"
        />
        <div v-t="'is'" class="is" />
      </div>

      <!-- 各属性、指標の選択 -->
      <div v-else class="additional-condition-form-definition-select">
        <div v-show="showLatest" v-t="'latest'" class="latest" />
        <SelectBox
          v-model="definitionSelectValue"
          :options="definitionOptions"
          width="100%"
        />

        <template v-if="isBusinessEventForm || isContactForm">
          <div class="is of">
            {{ $te("of") ? $t("of") : "" }}
          </div>
          <div v-show="!isContactShop"> {{ attributeName }}</div>
        </template>

        <!-- Shop options -->
        <SelectBox
          v-if="isContactForm && isContactShop"
          v-model="contactShopSelectValue"
          :options="contactShopOptions"
          width="100%"
        />
        <div v-t="'is'" class="is" />
      </div>

      <!-- 文字列入力フォーム -->
      <div v-if="showStringForm" class="additional-condition-form-string">
        <div
          v-t="'additionalConditionDialogLabel'"
          class="additional-condition-dialog__label"
        />
        <TextArea v-model="stringValue" class="textarea" height="100px" />
        <SelectBox
          v-model="matchMethodSelectValue"
          :options="matchMethodOptions"
          width="160px"
          class="matchMethod"
        />
      </div>

      <!-- NPSの評価者選択フォーム -->
      <div
        v-else-if="showNpsScoreCategoryForm"
        class="additional-condition-form-npsScoreCategory"
      >
        <div class="additional-condition-form-npsScoreCategory-input">
          <RadioButton v-model="npsScoreCategory" :label="valueNpsScoreLow"
            >{{ $t("low") }}({{ loyaltyLowScore }})</RadioButton
          >
        </div>
        <div class="additional-condition-form-npsScoreCategory-input">
          <RadioButton v-model="npsScoreCategory" :label="valueNpsScoreMedium"
            >{{ $t("middle") }}({{ loyaltyMediumScore }})</RadioButton
          >
        </div>
        <div class="additional-condition-form-npsScoreCategory-input">
          <RadioButton v-model="npsScoreCategory" :label="valueNpsScoreHigh"
            >{{ $t("high") }}({{ loyaltyHighScore }})</RadioButton
          >
        </div>
      </div>

      <!-- NPS用の数値入力フォーム -->
      <div
        v-else-if="showNpsNumberForm"
        class="additional-condition-form-number"
      >
        <div class="additional-condition-form-number-input">
          <RadioButton
            v-model="npsNumberMethodValue"
            :label="valueInputMethodValue"
            >{{ $t("valueSpecification") }}</RadioButton
          >
          <InputText
            v-model="npsNumberValue"
            class="input"
            @focus="onFocusNpsNumberValue"
          />
        </div>
        <div class="additional-condition-form-number-input">
          <RadioButton
            v-model="npsNumberMethodValue"
            :label="valueInputMethodRange"
            >{{ $t("rangeSpecification") }}</RadioButton
          >
          <div class="additional-condition-form-number-range">
            <InputText
              v-model="npsNumberRangeFromValue"
              class="input"
              @focus="onFocusNpsNumberRange"
            />
            <div class="additional-condition-form-number-range-from-to">〜</div>
            <InputText
              v-model="npsNumberRangeToValue"
              class="input"
              @focus="onFocusNpsNumberRange"
            />
          </div>
        </div>
      </div>

      <!-- 数値入力フォーム（上記フォームのどれでもない場合は数値フォーム） -->
      <div v-else class="additional-condition-form-number">
        <div class="additional-condition-form-number-input">
          <RadioButton
            v-model="numberMethodValue"
            :label="valueInputMethodValue"
            >{{ $t("valueSpecification") }}</RadioButton
          >

          <InputText
            v-model="numberValue"
            class="input"
            @focus="onFocusNumberValue"
          />
          <div
            v-if="showNumberMatchMethodSelectBox"
            class="additional-condition-form__number-match-method"
            data-cy="additional-condition-form__number-match-method"
          >
            <SelectBox
              v-model="selectedNumberMatchMethod"
              :options="numberMatchMethodOptions"
              width="160px"
              @focus="onFocusNumberMatchMethod"
            />
          </div>
          <div v-show="showMonthUnit" v-t="'month'" class="month-unit" />
        </div>
        <div class="additional-condition-form-number-input">
          <RadioButton
            v-model="numberMethodValue"
            :label="valueInputMethodRange"
            >{{ $t("rangeSpecification") }}</RadioButton
          >

          <div class="additional-condition-form-number-range">
            <InputText
              v-model="numberRangeFromValue"
              class="input"
              @focus="onFocusNumberRange"
            />
            <div class="additional-condition-form-number-range-from-to">〜</div>
            <InputText
              v-model="numberRangeToValue"
              class="input"
              @focus="onFocusNumberRange"
            />
            <div v-show="showMonthUnit" v-t="'month'" class="month-unit" />
          </div>
        </div>
      </div>

      <div class="additional-condition-form-bottons">
        <Button
          class="add-button"
          :disabled="shouldDisableSubmitButton"
          @click="onClickSubmit"
          >{{ submitButtonTitle }}</Button
        >
        <Button
          v-t="'cancel'"
          class="cancel-button"
          type="light"
          @click="onClickCancel"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import {
  AdditionalSelectCondition,
  UserAttributeCondition,
  ValueInputMethod,
  AdditionalConditionType
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ConversionAttributeTextCondition } from "@/models/search/additional-condition/ConversionAttributeTextCondition";
import { UserAttributeStringCondition } from "@/models/search/additional-condition/UserAttributeStringCondition";
import { UserAttributeNumberCondition } from "@/models/search/additional-condition/UserAttributeNumberCondition";
import {
  NpsCondition,
  NpsInputMethod,
  NpsScoreCategory
} from "@/models/search/additional-condition/NpsCondition";
import { EnqueteCondition } from "@/models/search/additional-condition/EnqueteCondition";
import { BusinessIndexCondition } from "@/models/search/additional-condition/BusinessIndexCondition";
import { BusinessEventAttributeCondition } from "@/models/search/additional-condition/BusinessEventAttributeCondition";
import {
  ContactAttributeCondition,
  ContactFieldType
} from "@/models/search/additional-condition/ContactAttributeCondition";

import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";

import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import TextArea from "@/components/form/TextArea.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import InputText from "@/components/form/InputText.vue";
import Button from "@/components/Button.vue";
import {
  UserAttributeDefinition,
  UserAttributeDefinitionType
} from "@/models/client-settings/UserAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import {
  BusinessIndexDefinition,
  BusinessIndexDefinitionType
} from "@/models/client-settings/BusinessIndexDefinition";
import { splitByNewLine, isNumberText } from "@/util/string-util";
import { MatchMethod } from "@/models/search/MatchMethod";
import { showAlert } from "@/util/modal-util";
import Dialog from "@/components/dialog/Dialog.vue";
import { AttributeType, NumberMatchMethod } from "@/api/apis/ApiSearch";
import { ConversionAttributeNumberRangeCondition } from "@/models/search/additional-condition/ConversionAttributeNumberRangeCondition";
import { ConversionAttributeNumberValueCondition } from "@/models/search/additional-condition/ConversionAttributeNumberValueCondition";
import { MAX_LINE, MAX_LENGTH } from "@/const/additional-condition";

/**
 * ロイヤルティのタイプ
 */
const enum LoyaltyType {
  NPS,
  ENQUETE
}

/**
 * AdditionalConditionで使用されるDefinition
 */
type AdditionalConditionDefinition =
  | ConversionAttributeDefinition
  | NpsDefinition
  | EnqueteDefinition
  | BusinessIndexDefinition
  | UserAttributeDefinition
  | BusinessEventDefinition
  | ContactDefinition;

@Component({
  components: {
    TextArea,
    SelectBox,
    Button,
    RadioButton,
    InputText,
    Dialog
  }
})
export default class AdditionalConditionDialog extends Vue {
  /**
   * 現在のtabが行動増減ユーザ検索かどうか。行動増減ユーザ検索の場合、true
   */
  @Prop({ type: Boolean, required: false })
  isRepeatTab!: Boolean | false;

  /**
   * 検索条件の種類
   */
  @Prop({ type: Number, required: true })
  conditionType!: AdditionalConditionType;

  /**
   * 既に追加済みの検索条件
   */
  @Prop({ type: Array, required: true })
  currentConditions!: AdditionalSelectCondition[];

  /**
   * 新規追加ではなく、編集の場合に対象となる検索条件
   */
  @Prop({ type: Object, default: null })
  editCondition!: AdditionalSelectCondition | null;

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  npsDefinitions!: NpsDefinition[];

  @Prop({ type: Array, required: true })
  enqueteDefinitions!: EnqueteDefinition[];

  @Prop({ type: Array, required: true })
  businessIndexDefinitions!: BusinessIndexDefinition[];

  @Prop({ type: Array, required: true })
  userAttributeDefinitions!: UserAttributeDefinition[];

  @Prop({ type: Array, required: true })
  businessEventDefinitions!: BusinessEventDefinition[];

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  @Prop({ type: Boolean, default: false })
  visible!: boolean;

  onSubmit(additionalSelectCondition: AdditionalSelectCondition) {
    this.$emit("submit", additionalSelectCondition);
  }

  @Emit("cancel")
  onClickCancel() {}

  /**
   * 選択中の各属性、指標のId
   */
  definitionSelectValue: number = 0;

  /**
   * 文字列入力の値
   */
  stringValue: string = "";

  /**
   * 文字列入力の場合のマッチ条件（部分一致 or 完全一致 or 前方一致 or 後方一致）
   */
  matchMethodSelectValue: number = MatchMethod.Partial;

  /**
   * 数値入力の場合の入力方法（１つの値 or レンジ）
   */
  numberMethodValue: ValueInputMethod = ValueInputMethod.Value;

  /**
   * 数値入力の値（１つの値の場合）
   */
  numberValue: string = "";

  /**
   * レンジ入力の最小値
   */
  numberRangeFromValue: string = "";

  /**
   * レンジ入力の最大値
   */
  numberRangeToValue: string = "";

  /**
   * If the selection type is single value type,
   * this field will be used.
   */
  selectedNumberMatchMethod: NumberMatchMethod = NumberMatchMethod.EXACT;

  /**
   * ロイヤルティの種類の値（NPS or アンケート）
   */
  loyaltyTypeSelectValue: LoyaltyType = LoyaltyType.NPS;

  // NPSに関する変数
  // ロイヤルティの検索条件は、NPSとアンケートをselectで切り替えることによって
  // どっちの条件も入力できるので、selectで切り替えた時に、入れていた条件が
  // クリアされてしまわいようにNPSの値は別の変数で保持するようにする

  /**
   * 選択されたNPSのデータ入力方法（高中低評価から選択する方法と数値を入力する方法がある）
   */
  npsInputMethodValue: number = NpsInputMethod.Select;

  /**
   * NPSのデータ入力方法の選択肢
   */
  npsInputMethodOptions!: SelectOption[];

  /**
   * NSP入力を選択式にした場合の値（低中高評価者のどれが選択されたかが入っている）
   */
  npsScoreCategory: NpsScoreCategory = NpsScoreCategory.Low;

  /**
   * NPSの数値入力の場合の入力方法（１つの値 or レンジ）
   */
  npsNumberMethodValue: ValueInputMethod = ValueInputMethod.Value;

  /**
   * NPSの数値入力の値（１つの値の場合）
   */
  npsNumberValue: string = "";

  /**
   * NPSのレンジ入力の最小値
   */
  npsNumberRangeFromValue: string = "";

  /**
   * NPSのレンジ入力の最大値
   */
  npsNumberRangeToValue: string = "";

  /**
   * Radioボタンのlabelにセットする値
   */
  valueInputMethodValue: ValueInputMethod = ValueInputMethod.Value;
  valueInputMethodRange: ValueInputMethod = ValueInputMethod.Range;
  valueNpsScoreLow: NpsScoreCategory = NpsScoreCategory.Low;
  valueNpsScoreMedium: NpsScoreCategory = NpsScoreCategory.Medium;
  valueNpsScoreHigh: NpsScoreCategory = NpsScoreCategory.High;
  /**
   * Selected value of shop options (Purpose or StoreName)
   */
  contactShopSelectValue: ContactFieldType = ContactFieldType.StoreName;

  created() {
    this.npsInputMethodOptions = [
      {
        value: NpsInputMethod.Select,
        label: this.$t("loyaltyClassification") as string,
        disabled: false
      },
      {
        value: NpsInputMethod.Input,
        label: this.$t("loyaltyScore") as string,
        disabled: false
      }
    ];
  }

  mounted() {
    // TODO FIXME
    // デフォルト値をセット mountedではconditionTypeが古いままなので、setTimeoutをかませる
    window.setTimeout(() => {
      this.setDefaultValues();
    }, 0);
  }

  /**
   * 編集状態か（編集状態じゃない場合は新規追加）
   */
  get isEdit(): boolean {
    return this.editCondition !== null;
  }

  /**
   * 追加、編集しようとしているのは、ロイヤルティ条件か
   */
  get isLoyalty(): boolean {
    return this.isNpsForm || this.isEnqueteForm;
  }

  /**
   * フォームのタイトル
   */
  get title(): string {
    switch (this.conditionType) {
      case AdditionalConditionType.CONVERSION_ATTRIBUTE:
        return this.isEdit
          ? (this.$t("editConversionAttribute") as string)
          : (this.$t("addConversionAttribute") as string);
      case AdditionalConditionType.NPS:
      case AdditionalConditionType.ENQUETE:
        return this.isEdit
          ? (this.$t("editLoyaltyIndex") as string)
          : (this.$t("addLoyaltyIndex") as string);
      case AdditionalConditionType.USER_ATTRIBUTE:
        return this.isEdit
          ? (this.$t("editUserAttribute") as string)
          : (this.$t("addUserAttribute") as string);
      case AdditionalConditionType.BUSINESS_INDEX:
        return this.isEdit
          ? (this.$t("editBusinessIndex") as string)
          : (this.$t("addBusinessIndex") as string);
      case AdditionalConditionType.BUSINESS_EVENT_ATTRIBUTE:
        return this.isEdit
          ? (this.$t("editBusinessEvent") as string)
          : (this.$t("addBusinessEvent") as string);
      case AdditionalConditionType.CONTACT_ATTRIBUTE:
        return this.isEdit
          ? (this.$t("editContactAttribute") as string)
          : (this.$t("addContactAttribute") as string);
    }
    return "";
  }

  get isBusinessEventForm(): boolean {
    return (
      this.conditionType === AdditionalConditionType.BUSINESS_EVENT_ATTRIBUTE
    );
  }

  get isBusinessIndexForm(): boolean {
    return this.conditionType === AdditionalConditionType.BUSINESS_INDEX;
  }

  get isContactForm(): boolean {
    return this.conditionType === AdditionalConditionType.CONTACT_ATTRIBUTE;
  }

  get isConversionAttributeForm(): boolean {
    return this.conditionType === AdditionalConditionType.CONVERSION_ATTRIBUTE;
  }

  get isEnqueteForm(): boolean {
    return this.conditionType === AdditionalConditionType.ENQUETE;
  }

  get isNpsForm(): boolean {
    return this.conditionType === AdditionalConditionType.NPS;
  }

  get isUserAttributeForm(): boolean {
    return this.conditionType === AdditionalConditionType.USER_ATTRIBUTE;
  }

  /**
   * submitボタンのタイトル
   */
  get submitButtonTitle(): string {
    return this.isEdit
      ? (this.$t("editCondition") as string)
      : (this.$t("addCondition") as string);
  }

  /**
   * "最新の"を表示していいか
   */
  get showLatest(): boolean {
    return this.isUserAttributeForm || this.isBusinessIndexForm;
  }

  /**
   * Get different title text base on match condition
   */
  get attributeName(): string {
    /**
     * Get the selected contactDefintions so that we can compare with ContactDefinitionType
     * To output correct text
     */
    if (
      this.selectedContactDefinitionType === ContactDefinitionType.TEL ||
      this.isBusinessEventForm
    ) {
      return this.$t("content") as string;
    }
    if (this.selectedContactDefinitionType === ContactDefinitionType.MAIL) {
      return this.$t("title") as string;
    }

    return "";
  }

  /**
   * Get selected type of contact definition
   */
  get selectedContactDefinitionType(): number {
    const def = this.contactDefinitions.find(
      def => def.id === this.definitionSelectValue
    );

    if (this.isContactForm && def !== undefined) {
      return def.type;
    }
    return 0;
  }

  /**
   * 各属性、指標の選択肢
   */
  get definitionOptions(): SelectOption[] {
    // Returns options without conversion event attributes that have already been added
    // and are not in the edit conditions.
    if (this.isConversionAttributeForm) {
      return this.getUnusedSelectOptions(
        this.conversionAttributeDefinitions,
        (
          cnd
        ): cnd is
          | ConversionAttributeTextCondition
          | ConversionAttributeNumberRangeCondition
          | ConversionAttributeNumberValueCondition =>
          cnd instanceof ConversionAttributeTextCondition ||
          cnd instanceof ConversionAttributeNumberRangeCondition ||
          cnd instanceof ConversionAttributeNumberValueCondition
      );
    }
    if (this.isLoyalty) {
      // 既に追加されているかつ編集のconditionにないロイヤルティ指標(個別)を抜いた選択肢を返す
      return this.getUnusedSelectOptions(
        this.enqueteDefinitions,
        (cnd): cnd is EnqueteCondition => cnd instanceof EnqueteCondition
      );
    }
    if (this.isBusinessIndexForm) {
      // 既に追加されているかつ編集のconditionにないビジネス指標を抜いた選択肢を返す
      return this.getUnusedSelectOptions(
        this.businessIndexDefinitions,
        (cnd): cnd is BusinessIndexCondition =>
          cnd instanceof BusinessIndexCondition
      );
    }
    if (this.isUserAttributeForm) {
      // 既に追加されているかつ編集のconditionにないユーザ属性を抜いた選択肢を返す
      return this.getUnusedSelectOptions(
        this.userAttributeDefinitions,
        (
          cnd
        ): cnd is UserAttributeStringCondition | UserAttributeNumberCondition =>
          cnd instanceof UserAttributeStringCondition ||
          cnd instanceof UserAttributeNumberCondition
      );
    }
    if (this.isBusinessEventForm) {
      // Returns the options which not added and are not in the edit condition
      return this.getUnusedSelectOptions(
        this.businessEventDefinitions,
        (cnd): cnd is BusinessEventAttributeCondition =>
          cnd instanceof BusinessEventAttributeCondition
      );
    }
    if (this.isContactForm) {
      return this.getUnusedContactDefinitionSelectOptions();
    }

    return [];
  }

  /**
   * 文字列入力のマッチ条件選択肢
   */
  get matchMethodOptions(): SelectOption[] {
    // 現在のタブが何かで場合わけ。もしrepeatタブなら、四つ返す。それ以外なら、2つだけ返す。
    if (this.isRepeatTab) {
      return [
        {
          value: MatchMethod.Partial,
          label: this.$t("partialMatch") as string,
          disabled: false
        },
        {
          value: MatchMethod.Exact,
          label: this.$t("perfectMatch") as string,
          disabled: false
        },
        {
          value: MatchMethod.Prefix,
          label: this.$t("prefixMatch") as string,
          disabled: false
        },
        {
          value: MatchMethod.Postfix,
          label: this.$t("postfixMatch") as string,
          disabled: false
        }
      ];
    } else {
      return [
        {
          value: MatchMethod.Partial,
          label: this.$t("partialMatch") as string,
          disabled: false
        },
        {
          value: MatchMethod.Exact,
          label: this.$t("perfectMatch") as string,
          disabled: false
        }
      ];
    }
  }

  get numberMatchMethodOptions(): SelectOption[] {
    return [
      {
        value: NumberMatchMethod.EXACT,
        label: this.$t("exactMatch") as string,
        disabled: false
      },
      {
        value: NumberMatchMethod.LESS_THAN_OR_EQUAL_TO,
        label: this.$t("lessThanOrEqualMatch") as string,
        disabled: false
      },
      {
        value: NumberMatchMethod.GREATER_THAN_OR_EQUAL_TO,
        label: this.$t("greaterThanOrEqualMatch") as string,
        disabled: false
      }
    ];
  }
  /**
   * get available shop options
   */
  get contactShopOptions(): SelectOption[] {
    const shopOptions = [
      {
        value: ContactFieldType.StoreName,
        label: this.$t("nameOfStore") as string,
        disabled: false
      },
      {
        value: ContactFieldType.Purpose,
        label: this.$t("storeVisitPurpose") as string,
        disabled: false
      }
    ];
    /**
     * filter shopOption and return if it is not already used
     */
    const filterShopOptions = shopOptions.filter(def => {
      return !this.currentConditions.some(
        cnd =>
          !(
            this.editCondition instanceof ContactAttributeCondition &&
            this.editCondition !== null &&
            this.editCondition.fieldType === def.value
          ) &&
          cnd instanceof ContactAttributeCondition &&
          def.value === cnd.fieldType
      );
    });

    return filterShopOptions;
  }

  /**
   * When user choose ContactDefinitionType SHOP, return true
   */
  get isContactShop(): boolean {
    return this.selectedContactDefinitionType === ContactDefinitionType.SHOP;
  }

  /**
   * Check if ContactFieldType purpose already added
   */
  get isPurposeAlreadyAdded(): boolean {
    return this.currentConditions.some(
      cnd =>
        cnd instanceof ContactAttributeCondition &&
        cnd.fieldType == ContactFieldType.Purpose
    );
  }

  /**
   * Check if ContactFieldType store name already added
   */
  get isStoreNameAlreadyAdded(): boolean {
    return this.currentConditions.some(
      cnd =>
        cnd instanceof ContactAttributeCondition &&
        cnd.fieldType == ContactFieldType.StoreName
    );
  }

  /**
   * 文字列入力フォームを表示するか
   */
  get showStringForm(): boolean {
    if (this.isBusinessEventForm || this.isContactForm) {
      return true;
    }

    if (this.isUserAttributeForm) {
      return this.isSelectedUserAttributeDefinitionHavingTypeText;
    }
    if (this.isConversionAttributeForm) {
      return this.isSelectedConversionAttributeDefinitionHavingTypeText;
    }
    return false;
  }

  get isSelectedUserAttributeDefinitionHavingTypeText(): boolean {
    const def = this.userAttributeDefinitions.find(
      def => def.id === this.definitionSelectValue
    );
    return def?.type === UserAttributeDefinitionType.TEXT;
  }

  get isSelectedConversionAttributeDefinitionHavingTypeText(): boolean {
    const def = this.conversionAttributeDefinitions.find(
      def => def.id === this.definitionSelectValue
    );
    return def?.type === AttributeType.TEXT;
  }

  /**
   * 数値入力の"ヶ月"を表示するか
   */
  get showMonthUnit(): boolean {
    // ビジネス指標かつ、typeがTOTAL_USE_PERIODなら表示
    if (this.isBusinessIndexForm) {
      const def = this.businessIndexDefinitions.find(
        def => def.id === this.definitionSelectValue
      );
      return def?.type === BusinessIndexDefinitionType.TOTAL_USE_PERIOD;
    }
    return false;
  }

  /**
   * ロイヤルティのタイプ選択肢（NSPかENQUETEか）を表示していいか
   */
  get showLoyaltySelect(): boolean {
    // 検索条件がNPSでなければ表示しない
    if (!this.isNpsForm) {
      return false;
    }

    // 既に アンケートの条件が全ての種類使われていたら表示しない
    if (this.definitionOptions.length === 0) {
      return false;
    }
    return true;
  }

  get firstNpsDefinitionName(): string {
    return this.npsDefinitions.length > 0 ? this.npsDefinitions[0].name : "";
  }

  /**
   * 既にNPSが条件として追加されているか
   */
  get isAlreadyAddedNpsConditon(): boolean {
    return this.currentConditions.some(cnd => cnd instanceof NpsCondition);
  }

  /**
   * ロイヤルティのタイプ選択肢（NSPかENQUETEか）
   */
  get loyaltyTypeOptions(): SelectOption[] {
    let npsName = "";
    if (this.npsDefinitions.length > 0) {
      npsName = this.npsDefinitions[0].name;
    }
    return [
      { value: LoyaltyType.NPS, label: npsName, disabled: false },
      {
        value: LoyaltyType.ENQUETE,
        label: this.$t("individualScore") as string,
        disabled: false
      }
    ];
  }

  /**
   * NSP低評価者のスコア
   */
  get loyaltyLowScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].lowScoreRange;
    }
    return "";
  }

  /**
   * NSP中評価者のスコア
   */
  get loyaltyMediumScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].mediumScoreRange;
    }
    return "";
  }

  /**
   * NSP高評価者のスコア
   */
  get loyaltyHighScore(): string {
    if (this.npsDefinitions.length > 0) {
      return this.npsDefinitions[0].highScoreRange;
    }
    return "";
  }

  /**
   * NPSの入力方法Selectを表示するか（低中高評価者選択 or 数値入力）
   */
  get showNpsInputMethodSelect(): boolean {
    return this.isNpsForm && this.loyaltyTypeSelectValue === LoyaltyType.NPS;
  }

  /**
   * NSPの評価者選択を表示するか
   */
  get showNpsScoreCategoryForm(): boolean {
    return (
      this.showNpsInputMethodSelect &&
      this.npsInputMethodValue === NpsInputMethod.Select
    );
  }

  get showNumberMatchMethodSelectBox(): boolean {
    return this.isConversionAttributeForm && !this.showStringForm;
  }

  /**
   * NSP用の数値入力を表示するか
   */
  get showNpsNumberForm(): boolean {
    return (
      this.showNpsInputMethodSelect &&
      this.npsInputMethodValue === NpsInputMethod.Input
    );
  }

  get showNumberForm(): boolean {
    return (
      !this.showStringForm &&
      !this.showNpsScoreCategoryForm &&
      !this.showNpsNumberForm
    );
  }

  get shouldDisableSubmitButton(): boolean {
    if (this.showNpsScoreCategoryForm) return false;

    if (this.showNpsInputMethodSelect) {
      if (this.npsNumberMethodValue === ValueInputMethod.Value) {
        return this.npsNumberValue === "";
      } else {
        return this.isEmptyNpsNumberRange;
      }
    }

    if (this.showNumberForm) {
      if (this.numberMethodValue === ValueInputMethod.Value) {
        return this.numberValue === "";
      } else {
        return this.isEmptyNumberRange;
      }
    }

    return this.stringValue === "";
  }

  get isEmptyNumberRange(): boolean {
    return this.numberRangeFromValue === "" || this.numberRangeToValue === "";
  }

  get isEmptyNpsNumberRange(): boolean {
    return (
      this.npsNumberRangeFromValue === "" || this.npsNumberRangeToValue === ""
    );
  }

  /**
   * 追加、編集ボタンを押した
   */
  onClickSubmit() {
    const condition = this.createAdditionalSelectCondition();
    if (condition !== null) {
      this.onSubmit(condition);
    }
  }

  /**
   * 入力されたデータからAdditionalSelectConditionを作る
   */
  createAdditionalSelectCondition(): AdditionalSelectCondition | null {
    switch (this.conditionType) {
      case AdditionalConditionType.CONVERSION_ATTRIBUTE:
        return this._createAdditionSelectConditionForConversionAttribute();
      case AdditionalConditionType.BUSINESS_INDEX:
        return this._createAdditionSelectConditionForBusinessIndex();
      case AdditionalConditionType.USER_ATTRIBUTE:
        return this._createAdditionSelectConditionForUserAttribute();
      case AdditionalConditionType.ENQUETE:
        return this._createAdditionSelectConditionForEnquete();
      case AdditionalConditionType.NPS:
        return this._createAdditionSelectConditionForNps();
      case AdditionalConditionType.BUSINESS_EVENT_ATTRIBUTE:
        return this._createAdditionSelectConditionForBusinessEvent();
      case AdditionalConditionType.CONTACT_ATTRIBUTE:
        return this._createAdditionSelectConditionForContactAttribute();
      default:
        return null;
    }
  }

  _createAdditionSelectConditionForConversionAttribute(): AdditionalSelectCondition | null {
    if (this.showStringForm) {
      if (!this.validateStringValue()) {
        return null;
      }
      return new ConversionAttributeTextCondition(
        this.definitionSelectValue,
        splitByNewLine(this.stringValue),
        this.matchMethodSelectValue
      );
    } else {
      if (!this.validateNumberValue()) {
        return null;
      }
      if (this.numberMethodValue === ValueInputMethod.Value) {
        return new ConversionAttributeNumberValueCondition(
          this.definitionSelectValue,
          this.selectedNumberMatchMethod,
          this.getFromToNumberValue().from
        );
      }
      return new ConversionAttributeNumberRangeCondition(
        this.definitionSelectValue,
        this.getFromToNumberValue().from,
        this.getFromToNumberValue().to
      );
    }
  }

  _createAdditionSelectConditionForBusinessIndex(): AdditionalSelectCondition | null {
    if (!this.validateNumberValue()) {
      return null;
    }
    return new BusinessIndexCondition(
      this.definitionSelectValue,
      this.getFromToNumberValue().from,
      this.getFromToNumberValue().to,
      this.numberMethodValue
    );
  }

  _createAdditionSelectConditionForUserAttribute(): AdditionalSelectCondition | null {
    if (this.showStringForm) {
      if (!this.validateStringValue()) {
        return null;
      }
      return new UserAttributeStringCondition(
        this.definitionSelectValue,
        splitByNewLine(this.stringValue),
        this.matchMethodSelectValue
      );
    }
    // 数値入力のフォームが表示されている
    else {
      if (!this.validateNumberValue()) {
        return null;
      }
      return new UserAttributeNumberCondition(
        this.definitionSelectValue,
        this.getFromToNumberValue().from,
        this.getFromToNumberValue().to,
        this.numberMethodValue
      );
    }
  }

  _createAdditionSelectConditionForEnquete(): AdditionalSelectCondition | null {
    if (!this.validateLoyaltyValue()) {
      return null;
    }
    return new EnqueteCondition(
      this.definitionSelectValue,
      this.getFromToNumberValue().from,
      this.getFromToNumberValue().to,
      this.numberMethodValue
    );
  }

  _createAdditionSelectConditionForNps(): AdditionalSelectCondition | null {
    // NPSの場合はENQUETEも選択できるので、ENQUETEが選択されていれば、EnqueteConditionを返す
    if (this.loyaltyTypeSelectValue === LoyaltyType.ENQUETE) {
      if (!this.validateLoyaltyValue()) {
        return null;
      }
      return new EnqueteCondition(
        this.definitionSelectValue,
        this.getFromToNumberValue().from,
        this.getFromToNumberValue().to,
        this.numberMethodValue
      );
    }
    let from = 0;
    let to = 0;
    const nps = this.npsDefinitions[0];

    // 評価者選択式
    if (this.npsInputMethodValue === NpsInputMethod.Select) {
      if (this.npsScoreCategory === NpsScoreCategory.Low) {
        from = nps.min;
        to = nps.lowThreshold;
      } else if (this.npsScoreCategory === NpsScoreCategory.Medium) {
        from = nps.lowThreshold + 1;
        to = nps.highThreshold - 1;
      } else if (this.npsScoreCategory === NpsScoreCategory.High) {
        from = nps.highThreshold;
        to = nps.max;
      }
    }
    // 値入力式な
    else {
      if (
        !this.validateLoyaltyValue(
          this.npsNumberMethodValue,
          this.npsNumberValue,
          this.npsNumberRangeFromValue,
          this.npsNumberRangeToValue,
          nps.min,
          nps.max
        )
      ) {
        return null;
      }
      if (this.npsNumberMethodValue === ValueInputMethod.Value) {
        from = Number(this.npsNumberValue);
        to = Number(this.npsNumberValue);
      } else {
        from = Number(this.npsNumberRangeFromValue);
        to = Number(this.npsNumberRangeToValue);
      }
    }

    return new NpsCondition(
      nps.id,
      from,
      to,
      this.npsInputMethodValue,
      this.npsScoreCategory,
      this.npsNumberMethodValue
    );
  }

  _createAdditionSelectConditionForBusinessEvent(): AdditionalSelectCondition | null {
    if (!this.validateStringValue()) {
      return null;
    }
    return new BusinessEventAttributeCondition(
      this.definitionSelectValue,
      splitByNewLine(this.stringValue),
      this.matchMethodSelectValue
    );
  }

  _createAdditionSelectConditionForContactAttribute(): AdditionalSelectCondition | null {
    if (!this.validateStringValue()) {
      return null;
    }

    let fieldType = 0;

    /**
     * Get the selected contactDefintions so that we can compare with ContactDefinitionType
     */
    if (this.selectedContactDefinitionType === ContactDefinitionType.TEL) {
      fieldType = ContactFieldType.Content;
    } else if (
      this.selectedContactDefinitionType === ContactDefinitionType.MAIL
    ) {
      fieldType = ContactFieldType.Title;
    } else if (
      this.selectedContactDefinitionType === ContactDefinitionType.SHOP
    ) {
      if (this.contactShopSelectValue === ContactFieldType.Purpose) {
        fieldType = ContactFieldType.Purpose;
      } else if (this.contactShopSelectValue === ContactFieldType.StoreName) {
        fieldType = ContactFieldType.StoreName;
      }
    }

    return new ContactAttributeCondition(
      this.definitionSelectValue,
      fieldType,
      splitByNewLine(this.stringValue),
      this.matchMethodSelectValue
    );
  }
  /**
   * 入力された数値から検索条件のfromとtoにセットする数値を返す
   */
  getFromToNumberValue(): { from: number; to: number } {
    if (this.numberMethodValue === ValueInputMethod.Value) {
      return { from: Number(this.numberValue), to: Number(this.numberValue) };
    } else {
      return {
        from: Number(this.numberRangeFromValue),
        to: Number(this.numberRangeToValue)
      };
    }
  }

  /**
   * １つの数値入力のinputにフォーカスがあたったら、入力方法は１つの数値にする
   */
  onFocusNumberValue() {
    this.numberMethodValue = ValueInputMethod.Value;
  }

  /**
   * レンジ入力のinputにフォーカスがあたったら、入力方法はレンジにする
   */
  onFocusNumberRange() {
    this.numberMethodValue = ValueInputMethod.Range;
  }

  onFocusNumberMatchMethod() {
    this.numberMethodValue = ValueInputMethod.Value;
  }

  /**
   * NPSの１つの数値入力のinputにフォーカスがあたったら、NPSの数値入力方法は１つの数値にする
   */
  onFocusNpsNumberValue() {
    this.npsNumberMethodValue = ValueInputMethod.Value;
  }

  /**
   * NSPのレンジ入力のinputにフォーカスがあたったら、NPSの数値入力方法はレンジにする
   */
  onFocusNpsNumberRange() {
    this.npsNumberMethodValue = ValueInputMethod.Range;
  }

  /**
   * 既に追加されているDefinitionを削ったSelectOptionを作成する
   * ただし、編集対象のDefinitionは削らない
   */
  getUnusedSelectOptions(
    definitions: AdditionalConditionDefinition[],
    checkInstance: (
      condition: AdditionalSelectCondition
    ) => condition is
      | ConversionAttributeTextCondition
      | ConversionAttributeNumberRangeCondition
      | ConversionAttributeNumberValueCondition
      | UserAttributeCondition
      | EnqueteCondition
      | BusinessIndexCondition
      | BusinessEventAttributeCondition
      | ContactAttributeCondition
  ): SelectOption[] {
    const filteredDefinitions = this.filterMethod(definitions, checkInstance);
    return filteredDefinitions.map(def => {
      return {
        value: def.id,
        label: def.name,
        disabled: false
      };
    });
  }

  /**
   * Method  to get unused contact defintions
   */
  filterMethod(
    definitions: AdditionalConditionDefinition[],
    checkInstance: (condition: AdditionalSelectCondition) => boolean
  ) {
    return definitions.filter(def => {
      return !this.currentConditions.some(
        cnd =>
          // 編集にconditionと被っていたらマッチさせない
          !(
            this.editCondition !== null &&
            checkInstance(this.editCondition) &&
            this.editCondition.isSameId(def.id)
          ) &&
          // 既に追加されているconditionとマッチしているか
          checkInstance(cnd) &&
          cnd.isSameId(def.id)
      );
    });
  }

  /**
   * Return contact definitions if not yet added
   * If purpose and store name not added, displayu SHOP for selection
   * If purpose and store name already added, do not display Shop for selection
   * This is only applicable when AdditionalConditionType is CONTACT_ATTRIBUTE
   */
  getUnusedContactDefinitionSelectOptions(): SelectOption[] {
    const filterContactDefinitions = this.contactDefinitions.filter(
      d =>
        d.type === ContactDefinitionType.TEL ||
        d.type === ContactDefinitionType.MAIL ||
        d.type === ContactDefinitionType.SHOP
    );
    const sortedContactDefinitions = filterContactDefinitions
      .slice()
      .sort((a, b) => a.type - b.type);

    if (this.isPurposeAlreadyAdded && this.isStoreNameAlreadyAdded) {
      const unUsedContactDefintions = this.filterMethod(
        sortedContactDefinitions,
        (cnd): cnd is ContactAttributeCondition =>
          cnd instanceof ContactAttributeCondition
      ) as ContactDefinition[];

      return unUsedContactDefintions.map(def => {
        return {
          value: def.id,
          label: def.translatedName,
          disabled: false
        };
      });
    }
    const selectableContactDefinitions = sortedContactDefinitions.filter(
      def => {
        return !this.currentConditions.some(
          cnd =>
            !(
              this.editCondition !== null &&
              this.editCondition instanceof ContactAttributeCondition &&
              this.editCondition.isSameId(def.id)
            ) &&
            cnd.isSameId(def.id) &&
            cnd instanceof ContactAttributeCondition &&
            def.type !== ContactDefinitionType.SHOP
        );
      }
    );

    return selectableContactDefinitions.map(def => {
      return {
        value: def.id,
        label: def.translatedName,
        disabled: false
      };
    });
  }

  /**
   * デフォルト値としてセットする
   */
  setDefaultValues() {
    // キャンセルしても値が残るため一旦data属性をリセット
    this.definitionSelectValue =
      this.definitionOptions.length > 0
        ? Number(this.definitionOptions[0].value)
        : 0;
    this.stringValue = "";
    this.matchMethodSelectValue = MatchMethod.Partial;
    this.numberMethodValue = ValueInputMethod.Value;
    this.numberValue = "";
    this.numberRangeFromValue = "";
    this.numberRangeToValue = "";
    this.loyaltyTypeSelectValue = LoyaltyType.NPS;
    this.npsInputMethodValue = NpsInputMethod.Select;
    this.npsScoreCategory = NpsScoreCategory.Low;
    this.npsNumberMethodValue = ValueInputMethod.Value;
    this.npsNumberValue = "";
    this.npsNumberRangeFromValue = "";
    this.npsNumberRangeToValue = "";
    this.valueInputMethodValue = ValueInputMethod.Value;
    this.valueInputMethodRange = ValueInputMethod.Range;
    this.valueNpsScoreLow = NpsScoreCategory.Low;
    this.valueNpsScoreMedium = NpsScoreCategory.Medium;
    this.valueNpsScoreHigh = NpsScoreCategory.High;
    this.contactShopSelectValue = ContactFieldType.StoreName;

    // 編集でない場合は一番始めの選択肢をデフォルト値してセットする
    if (!this.isEdit && this.definitionOptions.length > 0) {
      // ロイヤルティの条件追加で既にNPSが条件に追加済みなら、初期値はEnqueteにする
      if (this.isLoyalty && this.isAlreadyAddedNpsConditon) {
        this.loyaltyTypeSelectValue = LoyaltyType.ENQUETE;
      }
    }

    if (!this.isEdit && this.contactShopOptions.length > 0) {
      if (this.isPurposeAlreadyAdded) {
        this.contactShopSelectValue = ContactFieldType.StoreName;
      }
      if (this.isStoreNameAlreadyAdded) {
        this.contactShopSelectValue = ContactFieldType.Purpose;
      }
    }

    /**
     * 編集の場合は、現状の条件から値を取り出して、デフォルト値としてセットする
     */
    // コンバージョン・イベント属性の初期値をセット
    if (this.editCondition instanceof ConversionAttributeTextCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setStringDefaultValue(this.editCondition);
      return;
    }

    /**
     * Set default values for the range type.
     */
    if (this.editCondition instanceof ConversionAttributeNumberRangeCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setDefaultNumberRange(this.editCondition);
      return;
    }

    /**
     * Set default values for the value type.
     */
    if (this.editCondition instanceof ConversionAttributeNumberValueCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setDefaultNumberValue(this.editCondition);
      return;
    }

    // ビジネス指標の初期値をセット
    if (this.editCondition instanceof BusinessIndexCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setNumberDefaultValue(this.editCondition);
      return;
    }
    // ユーザ属性(文字列)の初期値をセット
    if (this.editCondition instanceof UserAttributeStringCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setStringDefaultValue(this.editCondition);
      return;
    }
    // ユーザ属性(数字)の初期値をセット
    if (this.editCondition instanceof UserAttributeNumberCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setNumberDefaultValue(this.editCondition);
      return;
    }
    // ロイヤルティ指標(個別)の初期値をセット
    if (this.editCondition instanceof EnqueteCondition) {
      // ロイヤルティのタイプをアンケートにする
      this.loyaltyTypeSelectValue = LoyaltyType.ENQUETE;
      this.definitionSelectValue = this.editCondition.id;
      this.setNumberDefaultValue(this.editCondition);
      return;
    }
    // ロイヤルティ指標の初期値をセット
    if (this.editCondition instanceof NpsCondition) {
      this.npsInputMethodValue = this.editCondition.inputMethod;

      // 低中高評価者を選択式で入力した場合
      if (this.editCondition.inputMethod === NpsInputMethod.Select) {
        this.npsScoreCategory = this.editCondition.scoreType;
        return;
      }

      // 数値入力した場合 nps用の変数にデータを入れるのでsetNumberDefaultValueは使えない
      this.npsNumberMethodValue = this.editCondition.rangeType;
      // 値直接入力
      if (this.editCondition.rangeType === ValueInputMethod.Value) {
        this.npsNumberValue = String(this.editCondition.from);
      }
      // レンジ入力
      else {
        this.npsNumberRangeFromValue = String(this.editCondition.from);
        this.npsNumberRangeToValue = String(this.editCondition.to);
      }
    }
    if (this.editCondition instanceof BusinessEventAttributeCondition) {
      this.definitionSelectValue = this.editCondition.id;
      this.setStringDefaultValue(this.editCondition);
      return;
    }
    if (this.editCondition instanceof ContactAttributeCondition) {
      /**
       * To check if contactShopOptions available to add/update
       */
      const isContactShopOptionsAvailable =
        this.editCondition.fieldType !== ContactFieldType.Purpose &&
        this.editCondition.fieldType !== ContactFieldType.StoreName &&
        this.contactShopOptions.length > 0;

      if (isContactShopOptionsAvailable && this.isPurposeAlreadyAdded) {
        this.contactShopSelectValue = ContactFieldType.StoreName;
      }

      if (isContactShopOptionsAvailable && this.isStoreNameAlreadyAdded) {
        this.contactShopSelectValue = ContactFieldType.Purpose;
      }

      if (!isContactShopOptionsAvailable) {
        this.contactShopSelectValue = this.editCondition.fieldType;
      }

      this.definitionSelectValue = this.editCondition.id;
      this.setStringDefaultValue(this.editCondition);
      return;
    }
  }

  /**
   * 数値入力の検索条件からデフォルト値をセット
   */
  setNumberDefaultValue(
    condition:
      | BusinessIndexCondition
      | EnqueteCondition
      | UserAttributeNumberCondition
  ) {
    this.numberMethodValue = condition.rangeType;
    // 値直接入力
    if (condition.rangeType === ValueInputMethod.Value) {
      this.numberValue = String(condition.from);
    }
    // レンジ入力
    else {
      this.numberRangeFromValue = String(condition.from);
      this.numberRangeToValue = String(condition.to);
    }
  }

  /**
   * Set the default value for the Range. Eventhough NumberMatchMethod is Between in case of Range,
   * We put selectedNumberMatchMethod is Exact as the default value, because Between is not provided.
   */
  setDefaultNumberRange(condition: ConversionAttributeNumberRangeCondition) {
    this.selectedNumberMatchMethod = NumberMatchMethod.EXACT;
    this.numberRangeFromValue = String(condition.valFrom);
    this.numberRangeToValue = String(condition.valTo);
    this.numberMethodValue = ValueInputMethod.Range;
  }

  setDefaultNumberValue(condition: ConversionAttributeNumberValueCondition) {
    this.numberValue = String(condition.val);
    this.numberMethodValue = ValueInputMethod.Value;
  }

  /**
   * 文字列入力の検索条件からデフォルト値をセット
   */
  setStringDefaultValue(
    condition:
      | ConversionAttributeTextCondition
      | UserAttributeStringCondition
      | BusinessEventAttributeCondition
      | ContactAttributeCondition
  ) {
    this.matchMethodSelectValue = condition.matchPattern;
    // 元データは配列なので、改行で連結させる
    this.stringValue = condition.values.join("\r\n");
  }

  /**
   * 数値入力のバリデーション
   */
  validateNumberValue(
    method: ValueInputMethod = this.numberMethodValue,
    value: string = this.numberValue,
    from: string = this.numberRangeFromValue,
    to: string = this.numberRangeToValue
  ): boolean {
    if (method === ValueInputMethod.Value) {
      if (value === "" || !isNumberText(value)) {
        showAlert(this.$t("enterCorrectValue") as string);
        return false;
      }
    } else {
      if (
        from === "" ||
        to === "" ||
        !isNumberText(from) ||
        !isNumberText(to) ||
        Number(from) > Number(to)
      ) {
        showAlert(this.$t("enterCorrectValue") as string);
        return false;
      }
    }
    return true;
  }

  /**
   * ロイヤルティ指標のバリデーション
   */
  validateLoyaltyValue(
    method: ValueInputMethod = this.numberMethodValue,
    value: string = this.numberValue,
    from: string = this.numberRangeFromValue,
    to: string = this.numberRangeToValue,
    min: number = 0,
    max: number = 999
  ): boolean {
    if (method === ValueInputMethod.Value) {
      const numValue = Number(value);
      if (
        value === "" ||
        !isNumberText(value) ||
        numValue < min ||
        numValue > max
      ) {
        showAlert(this.$t("enterCorrectScore") as string);
        return false;
      }
    } else {
      const numFrom = Number(from);
      const numTo = Number(to);

      if (
        from === "" ||
        to === "" ||
        !isNumberText(from) ||
        !isNumberText(to) ||
        Number(from) > Number(to) ||
        numFrom < min ||
        numFrom > max ||
        numTo < min ||
        numTo > max
      ) {
        showAlert(this.$t("enterCorrectScore") as string);
        return false;
      }
    }

    return true;
  }

  /**
   * 文字列入力のバリデーション
   */
  validateStringValue(): boolean {
    const attrName = this._getAttributeName();

    // 入力された文字列を改行で区切って配列にする
    const vals: string[] = splitByNewLine(this.stringValue);

    if (vals.length <= 0) {
      showAlert(this.$t("enterAttrName", { attrName }) as string);
      return false;
    }
    let errorMessage = "";
    // MAX_LINE行以上の入力がある
    if (vals.length > MAX_LINE) {
      errorMessage = this.$t("specifyUp", {
        attrName,
        maxLine: String(MAX_LINE)
      }) as string;
    }
    // 文字数がMAX_LENGTHを超える行がある場合
    if (vals.filter(v => v.length > MAX_LENGTH).length > 0) {
      if (errorMessage !== "") {
        errorMessage += "\n";
      }
      errorMessage += this.$t("maxCharactersPerLine", {
        maxLength: String(MAX_LENGTH)
      }) as string;
    }
    if (errorMessage !== "") {
      showAlert(errorMessage);
      return false;
    }

    return true;
  }

  _getAttributeName(): string {
    switch (this.conditionType) {
      case AdditionalConditionType.CONVERSION_ATTRIBUTE:
        return this.$t("conversionAttribute") as string;
      case AdditionalConditionType.USER_ATTRIBUTE:
        return this.$t("userAttribute") as string;
      case AdditionalConditionType.BUSINESS_EVENT_ATTRIBUTE:
        return this.$t("businessEvent") as string;
      case AdditionalConditionType.CONTACT_ATTRIBUTE:
        return this.$t("contact") as string;
    }

    return "";
  }

  onOpen() {
    this.setDefaultValues();
  }
}
</script>
<style lang="scss" scoped>
.additional-condition-form-title {
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.additional-condition-form-loyalty-select,
.additional-condition-form-definition-select {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .latest {
    margin-right: 20px;
  }
  .is {
    margin: 0 20px;
  }
}

.additional-condition-dialog__label {
  margin-bottom: 10px;
  font-size: 12px;
}

.additional-condition-form-string {
  .textarea {
    margin-bottom: 10px;
  }

  .matchMethod {
    margin-bottom: 30px;
  }
}

.additional-condition-form-number {
  margin-bottom: 40px;
}

.additional-condition-form-number-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .radio-button {
    width: 140px;
  }

  .input {
    width: 90px;
  }

  .month-unit {
    margin-left: 10px;
  }
}
.additional-condition-form__number-match-method {
  padding-left: 10px;
}

.additional-condition-form-npsScoreCategory {
  margin-bottom: 30px;
}

.additional-condition-form-npsScoreCategory-input {
  margin-bottom: 20px;
}

.additional-condition-form-number-range-from-to {
  margin: 0 16px;
}

.additional-condition-form-number-range {
  display: flex;
  align-items: center;
}

.additional-condition-form-bottons {
  display: flex;

  .add-button {
    flex: 1;
    margin-right: 10px;
  }

  .cancel-button {
    flex: 1;
    margin-left: 10px;
  }
}
</style>
