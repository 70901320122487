import {
  SearchHistory,
  buildSearchHistory
} from "@/models/search/SearchHistory";
import { ApiRes } from "@/api/api-res";
import { UserInfo } from "@/models/UserInfo";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { GlobalConversionAttributeDefinition } from "@/models/system/GlobalConversionAttributeDefinition";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";

/**
 * お気に入り検索
 *
 * お気に入り検索はselect + filter 検索でお気に入りとして
 * 追加した検索結果
 */

export class FavoriteSearch {
  constructor(
    // お気に入り検索結果id
    public readonly id: number,
    // お気に入りとして追加したラベル
    public label: string,
    // 履歴情報（PV数、デバイス情報など）
    public readonly history: SearchHistory
  ) {}
}

export function buildFavoriteSearch(
  favoriteSearch: ApiRes.FavoriteSearch,
  userInfoList: UserInfo[],
  canUseWebdataFeatures: boolean,
  isContractApp: boolean,
  activeDefinitions: ActiveDefinitions,
  globalConversionAttributeDefinitions: GlobalConversionAttributeDefinition[],
  activeMeasurementSites: MeasurementTargetSite[]
): FavoriteSearch | null {
  const history = buildSearchHistory(
    favoriteSearch.selection_history,
    userInfoList,
    canUseWebdataFeatures,
    isContractApp,
    activeDefinitions,
    globalConversionAttributeDefinitions,
    activeMeasurementSites
  );
  if (history === null) {
    return null;
  }
  return new FavoriteSearch(favoriteSearch.id, favoriteSearch.label, history);
}
