<template>
  <div class="select-box-with-optional-input">
    <SelectBox
      :value="selectValue"
      :options="options"
      :width="selectBoxWidth"
      @input="onSelect"
    />
    <InputNumber
      v-if="showInputBox"
      :value="inputValue"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :style="style"
      class="input-number"
      data-cy="input-number"
      @input="onInput"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import InputNumber from "@/components/form/InputNumber.vue";

@Component({
  components: {
    SelectBox,
    InputNumber
  }
})
export default class SelectBoxWithOptionalInput extends Vue {
  @Prop({ type: Number, required: true })
  selectValue!: number;

  @Prop({ type: Number, required: true })
  inputValue!: number;

  @Prop({ type: Array, required: true })
  options!: SelectOption[];

  @Prop({ type: Boolean, default: false })
  showInputBox!: boolean;

  @Prop({ type: Number, default: -Infinity })
  min!: number;

  @Prop({ type: Number, default: Number.MAX_SAFE_INTEGER })
  max!: number;

  @Prop({ type: String, default: "" })
  placeholder!: string;

  @Prop({ type: String, default: "160px" })
  inputFieldWidth!: string;

  @Prop({ type: String, default: "80px" })
  selectBoxWidth!: string;

  onInput(value: number) {
    this.$emit("inputNumber", value);
  }

  onSelect(selectType: number) {
    this.$emit("onSelect", selectType);
  }

  get style() {
    return {
      width: this.inputFieldWidth
    };
  }
}
</script>

<style scoped lang="scss">
.input-number {
  display: inline-block;
  margin-left: 7px;
}
</style>
