<i18n
  src="@/i18n/components/user-trend/table/user-trend-table-header.json"
></i18n>
<template>
  <thead class="userTrendTableHeader">
    <AnalysisTableHeaderRow>
      <th
        v-for="(column, index) in pageColumns"
        :key="index"
        :style="style"
        :rowspan="spanIndex"
        class="userTrendTableHeader__cell userTrendTableHeader__cell--default  userTrendTableHeader__cell--pageColumn"
        :class="{ 'userTrendTableHeader__cell--noBoder': index === 0 }"
      >
        {{ column }}

        <Tooltip :placement="tooltipPlacement.BottomStart" :wrap="true">
          <Icon :icon="iconHelp" :size="iconSize" :color="iconHelpColor" />

          <template #content>
            <div
              class="userTrendTableHeader__tooltipContent"
              v-text="pageColumnTooltipText"
            />
          </template>
        </Tooltip>
      </th>
      <th
        v-for="column in pageComparisonColumns"
        :key="column.type"
        :colspan="spanIndex"
        class="userTrendTableHeader__cell userTrendTableHeader__cell--default userTrendTableHeader__comparisonHeadCell"
        :class="[
          {
            'userTrendTableHeader__cell--enableHover': !isCompared
          }
        ]"
        @click="onDefaultClick(userTrendDataType.base, column)"
      >
        <span
          :class="[
            { userTrendTableHeader__sortCell: !isCompared },
            {
              'userTrendTableHeader__sortCell--showIcon':
                showSortIcon(userTrendDataType.base, column.type) && !isCompared
            }
          ]"
        >
          {{ column.label }}
          <Icon
            v-if="
              showSortIcon(userTrendDataType.base, column.type) && !isCompared
            "
            class="userTrendTableHeader__sortIcon"
            :icon="sortIcon(column.isDesc)"
            :size="10"
            :color="iconColor"
          />
        </span>

        <Tooltip
          v-if="showPageComparisonColumnTooltip(column.type)"
          :placement="pageComparisonColumnTooltipPlacement(column.type)"
          :wrap="true"
        >
          <Icon :icon="iconHelp" :size="iconSize" :color="iconHelpColor" />

          <template #content>
            <div class="userTrendTableHeader__tooltipContent">
              <template
                v-if="column.type === userTrendColumnType.cvRatioInVisit"
              >
                {{ cvRatioInVisitHelpTooltipLabel }}
              </template>
              <template v-else>{{
                pageComparisonColumnTooltipText(column.type)
              }}</template>
            </div>
          </template>
        </Tooltip>
      </th>
    </AnalysisTableHeaderRow>

    <!-- 比較用 row -->
    <AnalysisTableHeaderRow v-if="isCompared">
      <template v-for="(column, index) in pageComparisonColumns">
        <td
          :key="column.type + index"
          class="userTrendTableHeader__cell userTrendTableHeader__cell--target1 userTrendTableHeader__cell--enableHover"
          @click="onClick(userTrendDataType.base, column)"
        >
          <span
            class="userTrendTableHeader__sortCell"
            :class="{
              'userTrendTableHeader__sortCell--showIcon': showSortIcon(
                userTrendDataType.base,
                column.type
              )
            }"
          >
            {{ baseLabel }}
            <Icon
              v-if="showSortIcon(userTrendDataType.base, column.type)"
              class="userTrendTableHeader__sortIcon"
              :icon="sortIcon(column.isDesc)"
              :size="iconSize"
              :color="iconColor"
            />
          </span>
        </td>
        <td
          :key="column.type + (index + 1)"
          class="userTrendTableHeader__cell userTrendTableHeader__cell--target2 userTrendTableHeader__cell--enableHover"
          @click="onClick(userTrendDataType.comparison, column)"
        >
          <span
            class="userTrendTableHeader__sortCell"
            :class="{
              'userTrendTableHeader__sortCell--showIcon': showSortIcon(
                userTrendDataType.comparison,
                column.type
              )
            }"
          >
            {{ comparisonLabel }}
            <Icon
              v-if="showSortIcon(userTrendDataType.comparison, column.type)"
              class="userTrendTableHeader__sortIcon"
              :icon="sortIcon(column.isDesc)"
              :size="iconSize"
              :color="iconColor"
            />
          </span>
        </td>
      </template>
    </AnalysisTableHeaderRow>
  </thead>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import {
  AnalysisType,
  UserTrendScatterYAxisType,
  UserTrendDataType,
  UserTrendColumnType
} from "@/const/user-trend";
import {
  columnData,
  sortColumnData,
  pageColumnTypes
} from "@/components/user-trend/table/table-util";
import AnalysisTableHeaderRow from "@/components/table/AnalysisTableHeaderRow.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";

@Component({
  components: { AnalysisTableHeaderRow, Tooltip, Icon }
})
export default class UserTrendTableHeader extends Vue {
  iconColor = Colors.Base300;
  iconSize = 10;
  iconHelp = Icons.HelpInvertWhite;
  iconHelpColor = Colors.Base700;
  userTrendDataType = UserTrendDataType;
  tooltipPlacement = TooltipPlacement;
  userTrendColumnType = UserTrendColumnType;

  @Prop({ type: String, required: true })
  pageType!: AnalysisType;

  @Prop({ type: String, default: "auto" })
  pageColumnWidth!: string;

  @Prop({ type: Object, required: true })
  sortColumn!: sortColumnData;

  @Prop({ type: Boolean, required: true })
  isCompared!: boolean;

  fetchData(column: sortColumnData) {
    this.$emit("sort", column);
  }

  get spanIndex(): number {
    return this.isCompared ? 2 : 1;
  }

  get style() {
    return {
      width: this.pageColumnWidth
    };
  }

  get pageColumns(): string[] {
    let results: string[] = [];

    if (
      this.pageType === AnalysisType.WebView ||
      this.pageType === AnalysisType.Landing ||
      this.pageType === AnalysisType.Inflow ||
      this.pageType === AnalysisType.InflowDetail
    ) {
      results = [this.$i18n.t("url") as string];
    }

    if (this.pageType === AnalysisType.AppView) {
      results = [this.$i18n.t("screen") as string];
    }

    return results;
  }

  get pageColumnTooltipText(): string {
    return this.pageType === AnalysisType.Inflow
      ? (this.$i18n.t("inflowUrlHelp") as string)
      : (this.$i18n.t("defaultUrlHelp") as string);
  }

  // 全てのtableで使用されるColumn
  get fixedColumns(): columnData[] {
    const yAxisTypekeys = Object.keys(UserTrendScatterYAxisType);

    return yAxisTypekeys.map(k => {
      return {
        type: k,
        label: this.$i18n.t(k) as string,
        isDesc: false
      } as columnData;
    });
  }

  get pageComparisonColumns(): columnData[] {
    return pageColumnTypes(this.pageType).map(type => {
      let labelType = type as string;

      if (type === UserTrendColumnType.count) {
        if (this.pageType === AnalysisType.WebView) {
          labelType = "pv";
        }

        if (this.pageType === AnalysisType.AppView) {
          labelType = "appView";
        }
      }

      return {
        type,
        label: this.$i18n.t(labelType) as string,
        isDesc: this.sortColumn.type === type ? this.sortColumn.isDesc : false
      };
    });
  }

  get baseLabel(): string {
    return this.$i18n.t("base") as string;
  }

  get comparisonLabel(): string {
    return this.$i18n.t("comparison") as string;
  }

  get cvRatioInVisitHelpTooltipLabel(): string {
    return this.$i18n.t("cvRatioInVisitHelp") as string;
  }

  showSortIcon(key: UserTrendDataType, type: UserTrendColumnType): boolean {
    return this.sortColumn.key === key && this.sortColumn.type === type;
  }

  sortIcon(isDesc: boolean): Icons {
    return isDesc ? Icons.CaretBottom : Icons.CaretTop;
  }

  onClick(key: UserTrendDataType, column: columnData) {
    const data = {
      type: column.type,
      key,
      isDesc:
        this.sortColumn.key === key && this.sortColumn ? !column.isDesc : true
    };

    this.fetchData(data);
  }

  onDefaultClick(key: UserTrendDataType, column: columnData) {
    if (!this.isCompared) {
      this.onClick(key, column);
    }
  }

  pageComparisonColumnTooltipPlacement(
    type: UserTrendColumnType
  ): TooltipPlacement {
    return type === UserTrendColumnType.cvRatioInVisit
      ? this.tooltipPlacement.BottomEnd
      : this.tooltipPlacement.Bottom;
  }

  // cvRatioInVisitは各タブで共通のため含めない
  pageComparisonColumnTooltipText(type: UserTrendColumnType): string {
    let result: string = "";

    switch (this.pageType) {
      case AnalysisType.WebView:
        if (type === UserTrendColumnType.cvInVisit) {
          result = this.$i18n.t("webViewCvInVisitHelp") as string;
        }

        break;
      case AnalysisType.Landing:
        if (type === UserTrendColumnType.visit) {
          result = this.$i18n.t("landingVisitHelp") as string;
        }

        if (type === UserTrendColumnType.cvInVisit) {
          result = this.$i18n.t("landingCvInVisitHelp") as string;
        }

        break;
      case AnalysisType.Inflow:
      case AnalysisType.InflowDetail:
        if (type === UserTrendColumnType.visit) {
          result = this.$i18n.t("inflowVisitHelp") as string;
        }

        if (type === UserTrendColumnType.cvInVisit) {
          result = this.$i18n.t("inflowCvInVisitHelp") as string;
        }

        break;
      case AnalysisType.AppView:
        if (type === UserTrendColumnType.cvInVisit) {
          result = this.$i18n.t("appViewCvInVisitHelp") as string;
        }

        break;
    }

    return result;
  }

  showPageComparisonColumnTooltip(type: UserTrendColumnType): boolean {
    if (type === UserTrendColumnType.cvRatioInVisit) return true;

    switch (this.pageType) {
      case AnalysisType.WebView:
        return type === UserTrendColumnType.cvInVisit;

      case AnalysisType.Landing:
        return (
          type === UserTrendColumnType.visit ||
          type === UserTrendColumnType.cvInVisit
        );

      case AnalysisType.Inflow:
      case AnalysisType.InflowDetail:
        return (
          type === UserTrendColumnType.visit ||
          type === UserTrendColumnType.cvInVisit
        );

      case AnalysisType.AppView:
        return type === UserTrendColumnType.cvInVisit;

      default:
        return false;
    }
  }
}
</script>

<style scoped lang="scss">
.userTrendTableHeader__cell {
  padding: 5px;
  border-left: 1px solid $colorBlue820;
  color: $colorWhite;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  line-height: 1.2;

  @include default-break-word();
}

.userTrendTableHeader__cell--noBoder {
  border-left: none;
}

.userTrendTableHeader__cell--default {
  background-color: $colorBlue800;
  font-weight: bold;

  &:first-child {
    border-top-left-radius: $sizeRadius;
  }
  &:last-child {
    border-top-right-radius: $sizeRadius;
  }
}

.userTrendTableHeader__cell--default.userTrendTableHeader__cell--enableHover {
  &:hover {
    background-color: $colorBlue900;
  }
}

.userTrendTableHeader__cell--pageColumn {
  height: 52px;
}

.userTrendTableHeader__cell--target1 {
  border-top: 1px solid $colorBlue820;
  background-color: $colorBlue780;
}

.userTrendTableHeader__cell--target1.userTrendTableHeader__cell--enableHover {
  &:hover {
    background-color: $colorBlue900;
  }
}

.userTrendTableHeader__cell--target2 {
  border-top: 1px solid $colorBlue820;
  background-color: $colorBase580;
}

.userTrendTableHeader__cell--target2.userTrendTableHeader__cell--enableHover {
  &:hover {
    background-color: $colorBase700;
  }
}

.userTrendTableHeader__cell--enableHover {
  cursor: pointer;
}
.userTrendTableHeader__sortCell {
  position: relative;
  display: inline-block;
}

.userTrendTableHeader__sortCell--showIcon {
  padding-right: 11px;
}

.userTrendTableHeader__sortIcon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -5px;
}

.userTrendTableHeader__tooltipContent {
  width: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.3;
}
</style>
