import { ApiRes } from "@/api/api-res";

// 契約しているサービス
export enum CONSTRACT_SERVICE {
  // Web計測
  WEB = 1,
  // アプリ計測
  APP = 2,
  // ユーザ属性のインポート
  USER_ATTR = 4,
  // 顧客ロイヤルティ指標（総合）のインポート
  NPS_TOTAL = 8,
  // 顧客ロイヤルティ指標（個別）のインポート
  NPS_INDIV = 16,
  // ビジネス指標のインポート
  BIZ_INDEX = 32,
  // ビジネスイベントのインポート
  BE = 64,
  // 行動履歴のインポート
  CONTACT = 128,
  // AIサジェストの利用設定
  AI_SUGGEST = 256,
  // ファネル分析
  FUNNEL_ANALYSIS = 2048,
  // Treasure data import
  TD_IMPORT = 4096,
  // GA4
  GA4 = 8192,
  // GA4 data filtering, sampling limitation
  GA4_DATA_FILTERING = 16384
}

/**
 * UGと契約している顧客情報
 */
export class Client {
  constructor(
    public readonly id: number,
    // 顧客社名
    public readonly name: string,
    // 契約中のサービス
    public readonly contractServices: number[],

    public readonly resourceLimitation: {
      readonly conversion: number;
      readonly conversion_attribute: number;
      readonly event: number;
    }
  ) {}

  // WEB計測を契約しているか
  public get isContractWeb(): boolean {
    return this.contractServices.indexOf(CONSTRACT_SERVICE.WEB) >= 0;
  }

  // アプリ計測を契約しているか
  public get isContractApp(): boolean {
    return this.contractServices.indexOf(CONSTRACT_SERVICE.APP) >= 0;
  }

  public get isContractTdImport(): boolean {
    return this.contractServices.indexOf(CONSTRACT_SERVICE.TD_IMPORT) >= 0;
  }

  public get hasAnyOfImportContract(): boolean {
    return (
      this.isContractNps ||
      this.isContractBusinessIndex ||
      this.isContractBusinessEvent ||
      this.isContractUserAttribute ||
      this.isContractUserAttribute ||
      this.isContractContact
    );
  }

  public get isContractNps(): boolean {
    return (
      this.contractServices.includes(CONSTRACT_SERVICE.NPS_TOTAL) ||
      this.contractServices.includes(CONSTRACT_SERVICE.NPS_INDIV)
    );
  }

  public get isContractBusinessIndex(): boolean {
    return this.contractServices.includes(CONSTRACT_SERVICE.BIZ_INDEX);
  }

  public get isContractBusinessEvent(): boolean {
    return this.contractServices.includes(CONSTRACT_SERVICE.BE);
  }

  public get isContractUserAttribute(): boolean {
    return this.contractServices.includes(CONSTRACT_SERVICE.USER_ATTR);
  }

  public get isContractContact(): boolean {
    return this.contractServices.includes(CONSTRACT_SERVICE.CONTACT);
  }

  // ファネル分析を契約しているか
  public get hasFunnelAnalysisContract(): boolean {
    return (
      this.contractServices.indexOf(CONSTRACT_SERVICE.FUNNEL_ANALYSIS) >= 0
    );
  }

  public get hasGA4Contract(): boolean {
    return this.contractServices.indexOf(CONSTRACT_SERVICE.GA4) >= 0;
  }

  public get hasWebOrGA4Contract(): boolean {
    return this.isContractWeb || this.hasGA4Contract;
  }

  public get hasAppOrGA4Contract(): boolean {
    return this.isContractApp || this.hasGA4Contract;
  }

  public static fromJson(json: ApiRes.Client): Client {
    return new Client(
      json.id,
      json.name,
      json.contract_services,
      json.resource_limitation
    );
  }
}
