import { GetterTree, MutationTree, ActionTree } from "vuex";
import { ApiRes } from "@/api/api-res";
import { RootState } from "@/store";
import {
  SearchHistory,
  buildSearchHistory
} from "@/models/search/SearchHistory";
import { UserInfo } from "@/models/UserInfo";
import {
  FavoriteSearch,
  buildFavoriteSearch
} from "@/models/search/FavoriteSearch";
import {
  FilterHistory,
  buildFilterHistory
} from "@/models/search/FilterHistory";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { getCurrentDate } from "@/util/date-util";

export class SearchHistoryState {
  userInfoList: UserInfo[] = [];
  searchHistories: ApiRes.SelectionHistory[] = [];
  filterHistories: FilterHistory[] = [];
  favoriteSearches: ApiRes.FavoriteSearch[] = [];
}

const getters: GetterTree<SearchHistoryState, RootState> = {
  searchHistories(state, _, rootState, rootGetters): SearchHistory[] {
    const histories: SearchHistory[] = [];

    const client = rootState.client.client;
    const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
    const isApp = client !== null ? client.isContractApp : false;

    state.searchHistories.forEach(sh => {
      const history = buildSearchHistory(
        sh,
        state.userInfoList,
        canUseWebdataFeatures,
        isApp,
        rootGetters["clientSettings/activeDefinitions"],
        rootState.system.globalConversionAttributeDefinitions,
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (history !== null) {
        histories.push(history);
      }
    });
    return histories;
  },
  favoriteSearches(state, _, rootState, rootGetters): FavoriteSearch[] {
    const searches: FavoriteSearch[] = [];
    const client = rootState.client.client;
    const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
    const isApp = client !== null ? client.isContractApp : false;

    state.favoriteSearches.forEach(fs => {
      const search = buildFavoriteSearch(
        fs,
        state.userInfoList,
        canUseWebdataFeatures,
        isApp,
        rootGetters["clientSettings/activeDefinitions"],
        rootState.system.globalConversionAttributeDefinitions,
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (search !== null) {
        searches.push(search);
      }
    });
    return searches;
  }
};

const mutations: MutationTree<SearchHistoryState> = {
  setSearchHistories(
    state: SearchHistoryState,
    searchHistories: ApiRes.SelectionHistory[]
  ) {
    state.searchHistories = searchHistories;
  },
  setFilterHistories(
    state: SearchHistoryState,
    filterHistories: FilterHistory[]
  ) {
    state.filterHistories = filterHistories;
  },
  addFilterHistory(state: SearchHistoryState, filterHistory: FilterHistory) {
    state.filterHistories.unshift(filterHistory);
  },
  setFavoriteSearches(
    state: SearchHistoryState,
    favoriteSearches: ApiRes.FavoriteSearch[]
  ) {
    state.favoriteSearches = favoriteSearches;
  },
  addFavoriteSearch(
    state: SearchHistoryState,
    favoriteSearch: ApiRes.FavoriteSearch
  ) {
    state.favoriteSearches.push(favoriteSearch);
  }
};

const actions: ActionTree<SearchHistoryState, RootState> = {
  async fetchSearchHistories({ commit, rootState }) {
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistories();
    commit("setSearchHistories", searchHistories);
  },
  async fetchFavoriteSearches({ commit, rootState }) {
    const favoriteSearches: ApiRes.FavoriteSearch[] = await rootState.api.searchHistory.getFavoriteSearches();
    commit("setFavoriteSearches", favoriteSearches);
  },
  async fetchFilterHistories({ commit, rootState, rootGetters }) {
    const filterHistories: ApiRes.FilterHistory[] = await rootState.api.searchHistory.getFilterHistories();
    const histories: FilterHistory[] = [];

    const client = rootState.client.client;
    const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
    const isApp = client !== null ? client.isContractApp : false;

    filterHistories.forEach(fh => {
      const history = buildFilterHistory(
        fh,
        canUseWebdataFeatures,
        isApp,
        rootGetters["clientSettings/activeDefinitions"],
        rootState.system.globalConversionAttributeDefinitions
      );
      if (history !== null) {
        histories.push(history);
      }
    });
    commit("setFilterHistories", histories);
  },
  async updateSearchHistories({ dispatch }) {
    await dispatch("fetchSearchHistories");
  },
  async updateFavoriteSearch(
    { commit, state, rootState },
    { id, label, historyId }
  ) {
    const updatedFavoriteSearch = await rootState.api.searchHistory.updateFavoriteSearch(
      id,
      historyId,
      label
    );

    const result: ApiRes.FavoriteSearch[] = [];
    for (const fs of state.favoriteSearches) {
      if (fs.id === updatedFavoriteSearch.id) {
        result.push(updatedFavoriteSearch);
      } else {
        result.push(fs);
      }
    }

    commit("setFavoriteSearches", result);
  },
  async createFavoriteSearch({ commit, rootState }, label: string) {
    if (
      rootState.filter.isFilterMode &&
      rootState.filter.filterHistoryId !== null
    ) {
      const favoriteSearchResponse = await rootState.api.searchHistory.createFavoriteSearch(
        rootState.filter.filterHistoryId,
        label
      );
      commit("addFavoriteSearch", favoriteSearchResponse);
      return;
    }

    if (!rootState.filter.isFilterMode && rootState.search.historyId !== null) {
      const favoriteSearchResponse = await rootState.api.searchHistory.createFavoriteSearch(
        rootState.search.historyId,
        label
      );
      commit("addFavoriteSearch", favoriteSearchResponse);
    }
  },
  async deleteFavoriteSearch(
    { commit, state, rootState },
    favoriteSearchId: number
  ) {
    await rootState.api.searchHistory.deleteFavoriteSearch(favoriteSearchId);
    commit(
      "setFavoriteSearches",
      state.favoriteSearches.filter(fs => fs.id !== favoriteSearchId)
    );
  },
  addFilterHistoryFromFilterCondition(
    { commit, state },
    filterCondition: FilterCondition
  ) {
    let id = 1;
    if (state.filterHistories.length > 0) {
      id = state.filterHistories[0].id + 1;
    }
    commit(
      "addFilterHistory",
      new FilterHistory(id, getCurrentDate(), filterCondition)
    );
  },
  async fetchSearchHistoryById(
    { rootState },
    id: number
  ): Promise<ApiRes.SelectionHistory> {
    return await rootState.api.searchHistory.getSelectionHistoryById(id);
  }
};

export const searchHistory = {
  namespaced: true,
  state: new SearchHistoryState(),
  getters,
  mutations,
  actions
};
