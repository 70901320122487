<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    class="filter-node-event"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :show-add-condition="
      condition.isConditionAddable(isFirstNode, conversionAttributeDefinitions)
    "
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    :select-exclusion-type-value="exclutionTypeValue"
    :title="$t('nodeTitleEvent')"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-condition="onClickAddCondition"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <FilterEventField
      v-model="eventDefinitionId"
      :event-definitions="eventDefinitions"
    />

    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="
        condition.selectOption(
          additionalCondition,
          isFirstNode,
          hasConversionAttribute
        )
      "
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterConversionAttributeField
        v-if="additionalCondition.conditionType === conversionAttribute"
        :condition="additionalCondition"
        :options="attributeOptions"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import { FilterNodeForEvent } from "@/models/search/filter-node/FilterNodeForEvent";

import SelectOption from "@/components/form/SelectOption";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { FilterConversionAttributeCondition } from "@/models/search/filter-node-condition/FilterConversionAttributeCondition";
import FilterNode from "@/components/filter/FilterNode.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import FilterEventField from "@/components/filter/form/FilterEventField.vue";
import FilterConversionAttributeField from "@/components/filter/form/FilterConversionAttributeField.vue";

import { ExclusionProps } from "@/models/search/filter-node/FilterExclusion";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  convertAttributeDefinitionsToSelectOptions,
  initSelectedConversionAttributeCondition
} from "@/models/search/filter-node/FilterNode";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

@Component({
  name: "FilterNodeEvent",
  components: {
    FilterNode,
    FilterNodeItem,
    FilterDateHourField,
    FilterPeriodField,
    FilterEventField,
    FilterConversionAttributeField
  }
})
export default class FilterNodeEvent extends Vue {
  conversionAttribute = FilterNodeConditionType.ConversionAttribute;
  dateHour = FilterNodeConditionType.DateHour;
  conversionAttributeDefinitions: ConversionAttributeDefinition[] = this.$store
    .state.clientSettings.activeConversionAttributeDefinitions;
  eventDefinitions: EventDefinition[] = this.$store.state.clientSettings
    .activeEventDefinitions;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: FilterNodeForEvent, required: true })
  condition!: FilterNodeForEvent;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  onInput(condition: FilterNodeForEvent) {
    this.$emit("input", condition);
  }

  get eventDefinitionId(): number {
    return this.condition.eventDefinitionId;
  }
  set eventDefinitionId(eventDefinitionId: number) {
    this.onInput(this.createCondition(eventDefinitionId, null));
  }

  get attributeOptions(): SelectOption[] {
    return convertAttributeDefinitionsToSelectOptions(
      this.conversionAttributeDefinitions,
      this.condition
    );
  }

  get hasConversionAttribute(): boolean {
    return this.conversionAttributeDefinitions.length > 0;
  }

  get isAttributeAvailable(): boolean {
    if (!this.hasConversionAttribute) return false;
    if (this.conversionAttributeDefinitions.length === 1) {
      return !this.condition.additionalConditions.some(
        cnd => cnd instanceof FilterConversionAttributeCondition
      );
    }
    return true;
  }

  // 条件を追加
  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;

    let addCondition!:
      | FilterConversionAttributeCondition
      | FilterAdditionalTimingCondition;

    if (this.isAttributeAvailable) {
      addCondition = initSelectedConversionAttributeCondition(
        this.conversionAttributeDefinitions,
        this.condition
      );
    } else {
      if (this.isFirstNode && !this.condition.hasFirstTime) {
        addCondition = FilterFirstTimeCondition.buildDefaultData();
      } else {
        addCondition = FilterPeriodCondition.buildDefaultData();
      }
    }

    additionalConditions.push(addCondition);
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let additionalCondition!:
      | FilterConversionAttributeCondition
      | FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.ConversionAttribute) {
      additionalCondition = new FilterConversionAttributeCondition(
        this.conversionAttributeDefinitions[0].id,
        "",
        MatchMethod.Partial
      );
    } else if (type === FilterNodeConditionType.FirstTime) {
      additionalCondition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      additionalCondition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      additionalCondition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? additionalCondition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputAdditionalCondition(
    condition: FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  createCondition(
    eventDefinitionId: number | null,
    conditions:
      | (FilterConversionAttributeCondition | FilterAdditionalTimingCondition)[]
      | null
  ): FilterNodeForEvent {
    const setEventDefinitionId =
      eventDefinitionId === null
        ? this.condition.eventDefinitionId
        : eventDefinitionId;
    const setConditions =
      conditions === null ? this.condition.additionalConditions : conditions;
    return new FilterNodeForEvent(
      setEventDefinitionId,
      setConditions,
      this.condition.childIndex,
      this.condition.depth,
      this.condition.edge,
      this.condition.filterExclusion
    );
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForEvent(
        this.condition.eventDefinitionId,
        this.condition.additionalConditions,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue)
      )
    );
  }
  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get checkAdditionalConditions(): boolean {
    const conditionsMax = 2;
    const conditions = this.$props.condition;

    return (
      conditions.additionalConditions &&
      conditions.additionalConditions.length === conditionsMax
    );
  }
}
</script>
