import { ApiRes } from "@/api/api-res";
import { GlobalConversion as GlobalConversionJson } from "@/api/apis/ApiSystem";
import { CreationMethod } from "@/models/client-settings/Coordination";

export const MAX_NAME_LENGTH = 40;
export const MAX_PARAM_LENGTH = 40;
export interface ConversionEditableData {
  name: string;
  path: string[];
}

export function isInValidCvParam(path: string): boolean {
  return !path.match(/^[0-9A-Za-z-\._]*$/);
}

export const isValidConversionData = (data: ConversionEditableData) => {
  if (data.name === "") return false;
  if (data.name.length > MAX_NAME_LENGTH) return false;

  if (data.path.length < 1) return false;
  if (data.path.length > 5) return false;
  if (data.path.some(p => p === "")) return false;
  if (data.path.some(p => p.length > MAX_PARAM_LENGTH)) return false;
  if (data.path.some(p => isInValidCvParam(p))) return false;

  return true;
};

export interface CoordinationConversionEditableData {
  targetData: string;
  creationMethod: CreationMethod;
  conversionName: string;
  conversionId: number;
  path: string[];
}

export const isValidCoordinationConversionData = (
  data: CoordinationConversionEditableData
) => {
  if (data.targetData === "") return false;
  if (data.targetData.length > MAX_NAME_LENGTH) return false;

  if (
    data.creationMethod === CreationMethod.NEW &&
    (data.conversionName === "" || data.conversionName.length > MAX_NAME_LENGTH)
  )
    return false;
  if (data.creationMethod === CreationMethod.EXISTING && data.conversionId <= 0)
    return false;

  if (data.path.length < 1) return false;
  if (data.path.length > 5) return false;
  if (data.path.some(p => p === "")) return false;
  if (data.path.some(p => p.length > MAX_PARAM_LENGTH)) return false;
  if (data.path.some(p => isInValidCvParam(p))) return false;

  return true;
};

/**
 * コンバージョン
 *
 * 会員登録や商品購入など、Webサイト上での「成果」となる地点を表します
 */
export class ConversionDefinition {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly isDisabled: boolean,
    public readonly path: string[]
  ) {}

  public static fromJson(json: ApiRes.Conversion): ConversionDefinition {
    return new ConversionDefinition(
      json.id,
      json.name,
      json.is_disabled,
      json.path
    );
  }

  get isValid(): boolean {
    return isValidConversionData({ name: this.name, path: this.path });
  }

  update(
    params: {
      [P in keyof Pick<
        ConversionDefinition,
        "name" | "isDisabled" | "path"
      >]?: ConversionDefinition[P];
    }
  ): ConversionDefinition {
    return Object.assign(Object.create(ConversionDefinition.prototype), {
      ...this,
      ...params
    });
  }
}

export class GlobalConversionDefinition extends ConversionDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    public readonly path: string[]
  ) {
    super(id, name, false, []);
  }

  public static fromJson(
    json: GlobalConversionJson
  ): GlobalConversionDefinition {
    return new GlobalConversionDefinition(json.id, json.name, json.path);
  }
}
