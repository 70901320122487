var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onOutSideClick),expression:"onOutSideClick"}],staticClass:"dateRangePickerInput",style:({ width: _vm.width }),attrs:{"data-cy":"date-range-picker"}},[_c('div',{ref:"dateRangePickerInputContainer",staticClass:"dateRangePickerInput__container",class:[
      {
        'dateRangePickerInput__container--foucs':
          _vm.startInputFocus || _vm.endInputFocus
      },
      {
        'dateRangePickerInput__container--disabled': _vm.disabled
      }
    ]},[_c('div',{staticClass:"dateRangePickerInput__icon"},[_c('Icon',{attrs:{"icon":_vm.icons.Calendar,"color":_vm.inputIconColor,"size":14}})],1),_c('div',{staticClass:"dateRangePickerInput__field",on:{"click":_vm.onStartInputClick}},[_c('DatePickerReadonlyInput',{ref:"startInput",attrs:{"value":_vm.dispalyStartInputValue,"placeholder":_vm.$t('startOfTargetPeriod'),"disabled":_vm.disabled,"focus":_vm.startInputFocus,"align":_vm.inputTextAlign.Center}})],1),_c('div',{staticClass:"dateRangePickerInput__hyphen",class:[
        {
          'dateRangePickerInput__hyphen--disabled': _vm.disabled
        }
      ]},[_vm._v("-")]),_c('div',{staticClass:"dateRangePickerInput__field",on:{"click":_vm.onEndInputClick}},[_c('DatePickerReadonlyInput',{ref:"endInput",attrs:{"value":_vm.dispalyEndInputValue,"placeholder":_vm.$t('endOfTargetPeriod'),"disabled":_vm.disabled,"focus":_vm.endInputFocus,"align":_vm.inputTextAlign.Center}})],1),_c('div',{staticClass:"dateRangePickerInput__deleteButton"},[_c('Tooltip',{attrs:{"active":!_vm.disabled,"text":_vm.$t('reselectEntirePeriod')}},[_c('IconButton',{attrs:{"icon":_vm.icons.CloseBold,"size":18,"icon-scale":0.5,"disabled":_vm.disabled},on:{"click":_vm.onDeleteButtonClick}})],1)],1)]),_c('Transition',{attrs:{"name":"datePickerPopup"}},[(_vm.showDatePickerPopup)?_c('div',{staticClass:"dateRangePickerInput__popup",style:(_vm.popupStyle)},[(_vm.showPeriodChoices)?_c('div',{staticClass:"dateRangePickerInput__header"},[_c('PeriodChoices',{attrs:{"current":_vm.periodChoiceValue},on:{"click":_vm.onPeriodChoiceClick}})],1):_vm._e(),_c('div',{staticClass:"dateRangePickerInput__body"},[_c('DateRangePickerPopup',{attrs:{"date-picker":_vm.datePicker,"enabled-period":_vm.enabledPeriod,"selected-period":_vm.popupSelectedPeriod,"disabled-month-click":true},on:{"select-date":_vm.onSelectDate,"move-year-month":_vm.onMoveCalendarButtonClick,"mouse-enter-date":_vm.onMouseEnterDate}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }