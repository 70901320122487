<i18n
  src="@/i18n/components/search/engagement-form/fluctuation-form.json"
></i18n>
<template>
  <div class="fluctuationForm">
    <SelectBox
      :value="fluctuationSelectValue"
      :options="fluctuationSelectOptionGroup"
      :disabled="disabled"
      width="auto"
      @input="onSelectBoxInput"
    />
    <Tooltip
      v-if="showIconButton"
      :text="tooltipText"
      :placement="tooltipPlacement"
      :active="!disabled"
    >
      <IconButton
        class="fluctuationForm__button"
        :icon="icon"
        :icon-size="20"
        :disabled="disabled"
        @click="onClick"
      />
    </Tooltip>

    <RateOfIncreaseDialog
      :value="value"
      :visible="showRateOfIncreaseDialog"
      @input="onDialogInput"
      @close="onClose"
    />

    <RateOfDecreaseDialog
      :value="value"
      :visible="showRateOfDecreaseDialog"
      @input="onDialogInput"
      @close="onClose"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import {
  FluctuationType,
  FluctuationValue,
  FluctuationValues,
  FLUCTUATION_MIN_VALUES,
  FLUCTUATION_MAX_VALUES,
  UNSPECIFIED_FLUCTUATION_VALUE
} from "@/models/search/select-condition/SelectByEngagementCondition";
import {
  getInitialValue,
  getPercentOfDecrease,
  getConvertPercentageToTimes
} from "@/util/select-by-engagement-util";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import RateOfIncreaseDialog from "@/components/search/engagement-form/RateOfIncreaseDialog.vue";
import RateOfDecreaseDialog from "@/components/search/engagement-form/RateOfDecreaseDialog.vue";
import Tooltip from "@/components/Tooltip.vue";
import IconButton from "@/components/IconButton.vue";

export const maxFluctuationSelectOptions: SelectOption[] = [
  {
    label: "1000PercentIncrease",
    value: FLUCTUATION_MAX_VALUES[0],
    disabled: false
  },
  {
    label: "500PercentIncrease",
    value: FLUCTUATION_MAX_VALUES[1],
    disabled: false
  },
  {
    label: "200PercentIncrease",
    value: FLUCTUATION_MAX_VALUES[2],
    disabled: false
  },
  {
    label: "100PercentIncrease",
    value: UNSPECIFIED_FLUCTUATION_VALUE,
    disabled: false
  },
  {
    label: "directly",
    value: FluctuationType.Increase,
    disabled: false
  }
];

export const minFluctuationSelectOptions: SelectOption[] = [
  {
    label: "10PercentDecrease",
    value: FLUCTUATION_MIN_VALUES[0],
    disabled: false
  },
  {
    label: "50PercentDecrease",
    value: FLUCTUATION_MIN_VALUES[1],
    disabled: false
  },
  {
    label: "directly",
    value: FluctuationType.Decrease,
    disabled: false
  }
];

@Component({
  components: {
    SelectBox,
    RateOfIncreaseDialog,
    RateOfDecreaseDialog,
    Tooltip,
    IconButton
  }
})
export default class FluctuationForm extends Vue {
  @Prop({ type: Object, required: true })
  value!: FluctuationValues;

  @Prop({ type: Boolean, required: true })
  disabled!: boolean;

  onInput(value: FluctuationValues) {
    this.$emit("input", value);
  }

  icon = Icons.Memo;
  tooltipPlacement = TooltipPlacement.Bottom;

  showRateOfIncreaseDialog = false;
  showRateOfDecreaseDialog = false;

  fluctuationSelectValue: number = 0;

  get showIconButton(): boolean {
    return (
      this.fluctuationSelectValue === FluctuationType.Increase ||
      this.fluctuationSelectValue === FluctuationType.Decrease
    );
  }

  get tooltipText(): string {
    return this.$t("edit") as string;
  }

  get maxDirectLabel(): string {
    const prefix = this.$t("directly") as string;
    const { min, max } = getInitialValue(
      this.value.min,
      this.value.max,
      FLUCTUATION_MAX_VALUES
    );

    const suffix =
      max === null
        ? (this.$t("increaseTimesOrMoreSuffix") as string)
        : (this.$t("increaseTimesSuffix") as string);

    const value =
      max === null
        ? getConvertPercentageToTimes(min)
        : `${getConvertPercentageToTimes(min)}-${getConvertPercentageToTimes(
            max
          )}`;

    return this.fluctuationSelectValue === FluctuationType.Increase
      ? `${prefix}: ${value}${suffix}`
      : prefix;
  }

  get minDirectLabel(): string {
    const prefix = this.$t("directly") as string;
    const { min, max } = getInitialValue(
      this.value.min,
      this.value.max,
      FLUCTUATION_MAX_VALUES
    );

    const suffix =
      max === null
        ? (this.$t("decreasePercentageOrLessSuffix") as string)
        : (this.$t("decreasePercentageSuffix") as string);

    const value =
      max === null
        ? getPercentOfDecrease(min)
        : `${getPercentOfDecrease(min)}-${getPercentOfDecrease(max)}`;

    return this.fluctuationSelectValue === FluctuationType.Decrease
      ? `${prefix}: ${value}${suffix}`
      : prefix;
  }

  get fluctuationSelectOptionGroup(): SelectOptionGroup[] {
    const max = maxFluctuationSelectOptions.map(o => {
      const label =
        o.label === "directly" &&
        this.fluctuationSelectValue === FluctuationType.Increase
          ? this.maxDirectLabel
          : (this.$t(o.label) as string);

      return {
        label,
        value: o.value,
        disabled: o.disabled
      } as SelectOption;
    });

    const min = minFluctuationSelectOptions.map(o => {
      const label =
        o.label === "directly" &&
        this.fluctuationSelectValue === FluctuationType.Decrease
          ? this.minDirectLabel
          : (this.$t(o.label) as string);

      return {
        label,
        value: o.value,
        disabled: o.disabled
      } as SelectOption;
    });

    return [
      {
        label: this.$t("increase") as string,
        options: max
      },
      {
        label: this.$t("decrease") as string,
        options: min
      }
    ];
  }

  setFluctuationSelectValue() {
    const min = this.value.min;
    const max = this.value.max;
    const type = this.value.type;

    // 増加率直接入力
    if (type === FluctuationType.Increase) {
      this.fluctuationSelectValue = FluctuationType.Increase;
    }

    // 減少率直接入力
    if (type === FluctuationType.Decrease) {
      this.fluctuationSelectValue = FluctuationType.Decrease;
    }

    // 減少率選択
    if (
      FLUCTUATION_MIN_VALUES.includes(min.select) &&
      max.select === UNSPECIFIED_FLUCTUATION_VALUE
    ) {
      this.fluctuationSelectValue = min.select;
    }

    // 増加率選択
    if (
      FLUCTUATION_MAX_VALUES.includes(min.select) &&
      max.select === UNSPECIFIED_FLUCTUATION_VALUE
    ) {
      this.fluctuationSelectValue = min.select;
    }
  }

  onSelectBoxInput(value: number) {
    this.fluctuationSelectValue = value;

    switch (value) {
      case FluctuationType.Increase:
        this.showRateOfIncreaseDialog = true;
        this.showRateOfDecreaseDialog = false;

        break;

      case FluctuationType.Decrease:
        this.showRateOfIncreaseDialog = false;
        this.showRateOfDecreaseDialog = true;

        break;

      default:
        this.showRateOfIncreaseDialog = false;
        this.showRateOfDecreaseDialog = false;

        const max = new FluctuationValue(
          UNSPECIFIED_FLUCTUATION_VALUE,
          this.value.max.value
        );
        const min = new FluctuationValue(value, this.value.min.value);
        const maxValues = FLUCTUATION_MAX_VALUES.concat(
          UNSPECIFIED_FLUCTUATION_VALUE
        );

        if (maxValues.includes(value)) {
          const result: FluctuationValues = {
            min,
            max,
            type: FluctuationType.Increase
          };

          this.onInput(result);
        }

        if (FLUCTUATION_MIN_VALUES.includes(value)) {
          const result: FluctuationValues = {
            min,
            max,
            type: FluctuationType.Decrease
          };

          this.onInput(result);
        }
    }
  }

  onDialogInput(value: FluctuationValues) {
    this.showRateOfIncreaseDialog = false;
    this.showRateOfDecreaseDialog = false;
    this.fluctuationSelectValue = value.type;

    this.onInput(value);
  }

  onClick() {
    if (this.fluctuationSelectValue === FluctuationType.Increase) {
      this.showRateOfIncreaseDialog = true;
      this.showRateOfDecreaseDialog = false;
    } else if (this.fluctuationSelectValue === FluctuationType.Decrease) {
      this.showRateOfIncreaseDialog = false;
      this.showRateOfDecreaseDialog = true;
    }
  }

  onClose() {
    this.showRateOfIncreaseDialog = false;
    this.showRateOfDecreaseDialog = false;

    this.setFluctuationSelectValue();
  }

  created() {
    this.setFluctuationSelectValue();
  }
}
</script>

<style scoped lang="scss">
.fluctuationForm {
  display: flex;
  align-items: center;
}

.fluctuationForm__button {
  margin-left: 8px;
}
</style>
