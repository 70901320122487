<i18n
  src="@/i18n/components/client-settings/conversion/conversion-edit-table.json"
></i18n>
<template>
  <div class="conversionEditTable">
    <SettingsTable>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'conversionName'"
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell width="480px">
          <InputText
            :value="name"
            :placeholder="placeholder"
            :max-length="40"
            @input="onInputName"
          />
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          class="conversionEditTable__inputParameterHeader"
          :style="{ padding: '20px 10px 20px 20px' }"
        >
          <span v-t="'conversionParameter'" />
          <Tooltip :placement="tooltipPlacement.Bottom">
            <Icon
              :icon="icons.Help"
              :color="color.Base600"
              style="margin-left: 4px"
            />
            <div
              slot="content"
              v-t="'conversionParameterTooltipText'"
              class="conversionEditTable__tooltipContent"
            />
          </Tooltip>
        </SettingsTableCell>
        <SettingsTableCell>
          <div v-for="(param, index) in path" :key="index">
            <div class="conversionEditTable__inputParameter">
              <InputText
                :value="param"
                class="conversionEditTable__inputParameterForm"
                :max-length="40"
                :has-error="isValidCvParam(param)"
                @input="onInputParam($event, index)"
              />
              <IconButton
                v-show="numForm > 1"
                :icon="icons.ButtonClose"
                :size="18"
                @click="onClickDelete(index)"
              />
            </div>
            <div
              v-show="isValidCvParam(param)"
              v-t="'errorText'"
              class="conversionEditTable__errorText"
            />
          </div>

          <div
            v-if="canAddParameter"
            class="conversionEditTable__addParameter"
            @click="onClickNext"
          >
            <IconButton
              :size="18"
              :icon="icons.PlusCircle"
              class="conversionEditTable__addParameterIcon"
            />
            <span v-t="'addConversionParameter'" />
          </div>
          <div
            v-t="'parameterDescription'"
            class="conversionEditTable__prameterDescription"
          />
        </SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import Icon from "@/components/Icon.vue";
import IconButton from "@/components/IconButton.vue";
import InputText from "@/components/form/InputText.vue";
import Tooltip from "@/components/Tooltip.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { TooltipPlacement } from "@/const/tooltip";
import { ConversionEditableData } from "@/models/client-settings/ConversionDefinition";
import { isInValidCvParam as importedIsInValidCvParam } from "@/models/client-settings/ConversionDefinition";

@Component({
  components: {
    Icon,
    IconButton,
    InputText,
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell,
    Tooltip
  }
})
export default class ConversionEditTable extends Vue {
  color = Colors;
  icons = Icons;
  tooltipPlacement = TooltipPlacement;

  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: Array, required: true })
  path!: string[];

  @Emit("update")
  updateConversion(data: ConversionEditableData) {
    return data;
  }

  get numForm(): number {
    return this.path.length;
  }

  get placeholder(): string {
    return this.$t("placeholder") as string;
  }

  get canAddParameter() {
    return this.numForm < 5;
  }

  onInputName(value: string) {
    this.$emit("update", { name: value.trim(), path: this.path });
  }

  onInputParam(value: string, index: number) {
    const newParams = [];
    const currentParams = [...this.path];
    for (let i = 0; i < this.numForm; i++) {
      const newParam = i === index ? value.trim() : currentParams[i] || "";
      newParams.push(newParam);
    }

    this.$emit("update", { name: this.name, path: newParams });
  }

  onClickDelete(index: number) {
    const newParams = [...this.path];
    newParams.splice(index, 1);

    this.$emit("update", { name: this.name, path: newParams });
  }

  onClickNext() {
    this.$emit("update", { name: this.name, path: [...this.path, ""] });
  }

  isValidCvParam(path: string): boolean {
    return importedIsInValidCvParam(path);
  }
}
</script>

<style lang="scss" scoped>
.conversionEditTable {
  margin-bottom: 16px;
  width: 680px;
}
.conversionEditTable__tooltipContent {
  width: 226px;
  white-space: pre-line;
  word-wrap: break-word;
  line-height: 1.5;
}
.conversionEditTable__inputParameterHeader > * {
  display: inline-flex;
  vertical-align: middle;
}
.conversionEditTable__inputParameter {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.conversionEditTable__inputParameterForm {
  width: 400px;
  margin-right: 8px;
}
.conversionEditTable__errorText {
  margin-bottom: 10px;
  color: $colorError;
}
.conversionEditTable__addParameter {
  display: inline-flex;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
}
.conversionEditTable__addParameterIcon {
  margin-right: 5px;
}
.conversionEditTable__prameterDescription {
  margin-top: 6px;
  color: $colorBase700;
}
</style>
