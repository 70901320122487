import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import {
  ChildFilterNodeForReferrer,
  convertChildFilterNodeForReferrerToJson,
  convertJsonToFilterNodeForReferrer
} from "@/models/search/filter-node/ChildFilterNodeForReferrer";
import {
  ChildFilterNodeForInflowParameter,
  convertChildFilterNodeForInflowParameterToJson,
  convertJsonToFilterNodeForInflowParameter
} from "@/models/search/filter-node/ChildFilterNodeForInflowParameter";
import {
  ChildFilterNodeForSearchEngine,
  convertChildFilterNodeForSearchEngineToJson,
  convertJsonToFilterNodeForSearchEngine
} from "@/models/search/filter-node/ChildFilterNodeForSearchEngine";
import {
  ChildFilterNodeForAd,
  convertChildFilterNodeForAdToJson,
  convertJsonToFilterNodeForAd
} from "@/models/search/filter-node/ChildFilterNodeForAd";
import {
  FilterNodeParamForInflow,
  ChildFilterNodeParamForInflowParameter,
  ChildFilterNodeParamForSearchEngine,
  ChildFilterNodeParamForAd,
  ChildFilterNodeParamForReferrer
} from "@/api/apis/ApiSearch";
import {
  FilterNodeType,
  ActivityEdgeType
} from "@/models/search/filter-node/FilterNode";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import SelectOption from "@/components/form/SelectOption";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  FilterExclusion,
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType
} from "@/models/search/filter-node/FilterExclusion";
import { WordForHighlight } from "@/models/search/MatchMethod";

export enum InflowConditionType {
  None,
  Referrer,
  InflowParameter,
  SearchEngine,
  Ad
}

/**
 * 流入による絞り込み条件
 *
 * 選択した種類の流入条件があるユーザを絞り込む
 *
 * この条件だけ別の条件と違い条件の詳細はわたされたconditionが保持している
 * 4つの絞り込み条件の中から1つを選択する 流入経路 で仕様される
 */
export class FilterNodeForInflow {
  public readonly nodeType = FilterNodeType.Inflow;

  constructor(
    /**
     * 流入の選定条件
     * どれかの
     */
    public readonly condition:
      | ChildFilterNodeForReferrer
      | ChildFilterNodeForInflowParameter
      | ChildFilterNodeForSearchEngine
      | ChildFilterNodeForAd
      | null,
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion()
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate(): ValidationResult {
    if (this.condition === null) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.chooseInflowRoute") as string
      };
    }

    return this.condition.validate;
  }

  get partUrlsForHighlight(): WordForHighlight[] {
    if (
      this.condition instanceof ChildFilterNodeForInflowParameter &&
      this.filterExclusion.exclusionType !== ExclusionType.Exclude
    ) {
      return [
        {
          word: this.condition.parameter,
          isInflowParam: true
        }
      ];
    }
    return [];
  }

  insertEdge(): FilterNodeForInflow {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForInflow(
      this.condition,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion
    );
  }

  removeEdge(): FilterNodeForInflow {
    return new FilterNodeForInflow(
      this.condition,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion
    );
  }

  removeFirstTimeCondition(): FilterNodeForInflow {
    let condition = this.condition;
    if (condition === null) {
      return this;
    }
    if (condition instanceof ChildFilterNodeForReferrer) {
      condition = new ChildFilterNodeForReferrer(
        condition.condition,
        condition.additionalConditions.filter(
          cnd => !(cnd instanceof FilterFirstTimeCondition)
        ),
        this.depth
      );
    } else if (condition instanceof ChildFilterNodeForInflowParameter) {
      condition = new ChildFilterNodeForInflowParameter(
        condition.parameter,
        condition.additionalConditions.filter(
          cnd => !(cnd instanceof FilterFirstTimeCondition)
        ),
        this.depth
      );
    } else if (condition instanceof ChildFilterNodeForSearchEngine) {
      condition = new ChildFilterNodeForSearchEngine(
        condition.additionalConditions.filter(
          cnd => !(cnd instanceof FilterFirstTimeCondition)
        ),
        this.depth
      );
    } else if (condition instanceof ChildFilterNodeForAd) {
      condition = new ChildFilterNodeForAd(
        condition.additionalConditions.filter(
          cnd => !(cnd instanceof FilterFirstTimeCondition)
        ),
        this.depth
      );
    }
    return new FilterNodeForInflow(
      condition,
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion
    );
  }

  selectOption(): SelectOption[] {
    return [
      {
        value: InflowConditionType.None,
        label: i18n.t("models.search.chooseInflowRoute") as string,
        disabled: this.condition !== null
      },
      {
        value: InflowConditionType.Referrer,
        label: i18n.t("models.search.specifiedFromReferrer") as string,
        disabled: false
      },
      {
        value: InflowConditionType.InflowParameter,
        label: i18n.t("models.search.specifiedFromInflowParameter") as string,
        disabled: false
      },
      {
        value: InflowConditionType.SearchEngine,
        label: i18n.t("models.search.inflowFromOrganicSearch") as string,
        disabled: false
      },
      {
        value: InflowConditionType.Ad,
        label: i18n.t("models.search.inflowFromAd") as string,
        disabled: false
      }
    ];
  }
}

export function convertFilterNodeForInflowToJson(
  node: FilterNodeForInflow
): FilterNodeParamForInflow {
  const edge = convertFilterEdgeToJson(node.edge);
  const childNode = node.condition;
  const filterExclusionJsonValue = convertFilterExclusionToJsonValue(
    node.filterExclusion
  );

  let json: FilterNodeParamForInflow;
  if (childNode instanceof ChildFilterNodeForReferrer) {
    json = convertChildFilterNodeForReferrerToJson(childNode);
  } else if (childNode instanceof ChildFilterNodeForInflowParameter) {
    json = convertChildFilterNodeForInflowParameterToJson(childNode);
  } else if (childNode instanceof ChildFilterNodeForSearchEngine) {
    json = convertChildFilterNodeForSearchEngineToJson(childNode);
  } else {
    json = convertChildFilterNodeForAdToJson(childNode as ChildFilterNodeForAd);
  }

  json.activity_edge = edge;
  json.activity_excluded = filterExclusionJsonValue;

  return json;
}

export function convertJsonToFilterNodeForInflow(
  json: FilterNodeParamForInflow
): FilterNodeForInflow {
  let childCondition!:
    | ChildFilterNodeForReferrer
    | ChildFilterNodeForInflowParameter
    | ChildFilterNodeForSearchEngine
    | ChildFilterNodeForAd;

  if (json.activity_type === ActivityEdgeType.Referrer) {
    childCondition = convertJsonToFilterNodeForReferrer(
      json as ChildFilterNodeParamForReferrer
    );
  }
  if (json.activity_type === ActivityEdgeType.InflowParameter) {
    childCondition = convertJsonToFilterNodeForInflowParameter(
      json as ChildFilterNodeParamForInflowParameter
    );
  }
  if (json.activity_type === ActivityEdgeType.SearchEngine) {
    childCondition = convertJsonToFilterNodeForSearchEngine(
      json as ChildFilterNodeParamForSearchEngine
    );
  }
  if (json.activity_type === ActivityEdgeType.Ad) {
    childCondition = convertJsonToFilterNodeForAd(
      json as ChildFilterNodeParamForAd
    );
  }

  return new FilterNodeForInflow(
    childCondition,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!)
  );
}
