<i18n
  src="@/i18n/components/analysis-tools/analysis-tools-back-button.json"
></i18n>
<template>
  <div class="analysisToolsBackButton" data-cy="analysisToolsBackButton">
    <div
      class="analysisToolsBackButton__button"
      data-cy="analysisToolsBackButton__button"
      @click="onClickButton"
    >
      <Icon
        :icon="arrowleft"
        :color="iconColor"
        :size="8"
        class="analysisToolsBackButton__buttonIcon"
      />
      <span class="analysisToolsBackButton__buttonText">{{ $t("back") }}</span>
    </div>

    <div class="analysisToolsBackButton__contentContainer">
      {{ textBeforeContent }}
      <span class="analysisToolsBackButton__content">
        "{{ contentForDisplay }}"
      </span>
      {{ $t("textAfterContent") }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { AnalysisToolsDetailType } from "@/const/analysis-tools";

@Component({
  components: {
    Icon
  }
})
export default class AnalysisToolsBackButton extends Vue {
  @Prop({ type: String, required: true })
  pageType!: AnalysisToolsDetailType;

  @Prop({ type: String, required: true })
  content!: string;

  @Emit("click")
  onClickButton() {}

  arrowleft = Icons.ArrowLeft;
  iconColor = Colors.White;

  get textBeforeContent(): string {
    return this.$i18n.t("textBefore" + this.pageType + "Content") as string;
  }

  get contentForDisplay() {
    if (this.content.length < 51) {
      return this.content;
    } else {
      return this.content.substr(0, 50) + "...";
    }
  }
}
</script>

<style lang="scss" scoped>
.analysisToolsBackButton {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  height: 40px;
  border-radius: $sizeRadius;
  background-color: $colorBlue600;
}

.analysisToolsBackButton__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 24px;
  border-radius: $sizeRadius;
  background-color: $colorDark;
  color: $colorWhite;
  cursor: pointer;

  &:hover {
    background-color: $colorHoverDarkForDark;
  }
}

.analysisToolsBackButton__buttonIcon {
  margin-right: 5px;
}

.analysisToolsBackButton__contentContainer {
  margin-left: 15px;
}

.analysisToolsBackButton__content {
  font-weight: bold;
}
</style>
