<i18n src="@/i18n/layouts/mfa-layout.json"></i18n>
<template>
  <div id="app" class="mfa">
    <div class="mfa__header">
      <div class="mfa__logo">
        <UsergramLogo />
      </div>
    </div>
    <RouterView />
    <AlertDialog
      data-cy="alert-dialog"
      :message="alertMessage"
      @close="closeAlertModal"
    />
    <div class="mfa__footer">
      © Copyright beBit, Inc.
      <a href="https://help.usergram.info/" target="_blank" rel="noopener">{{
        $t("help")
      }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import AlertDialog from "@/components/dialog/AlertDialog.vue";
import UsergramLogo from "@/assets/svg/logo/usergram-logo.svg";

@Component({
  components: {
    AlertDialog,
    UsergramLogo
  }
})
export default class MfaLayout extends Vue {
  get alertMessage(): string {
    const message = this.$store.state.alert.message;
    return message === null ? "" : message;
  }

  closeAlertModal() {
    this.$store.commit("alert/initialize");
  }
}
</script>

<style lang="scss" scoped>
.mfa__header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 0px 30px;
}

.mfa__logo {
  & svg {
    width: 146.6px;
    height: 30px;
  }
}

.mfa__footer {
  padding-bottom: 40px;
  text-align: center;
  font-size: 14px;

  & a {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $colorLine;
    color: $colorTextLinkGray;
    text-decoration: none;
  }
}
</style>
