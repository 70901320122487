var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AnalysisTable',{staticClass:"visitSummaryTable",attrs:{"data-cy":"visitSummaryTable"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('AnalysisTableHeaderRow',{staticClass:"visitSummaryTable__header"},[_c('AnalysisTableHeaderCell',{directives:[{name:"t",rawName:"v-t",value:('action'),expression:"'action'"}],attrs:{"width":"70px","height":"auto"}}),_c('AnalysisTableHeaderCell',{directives:[{name:"t",rawName:"v-t",value:('url'),expression:"'url'"}],attrs:{"width":"285px","height":"auto"}}),_c('AnalysisTableHeaderCell',{directives:[{name:"t",rawName:"v-t",value:('pageTitle'),expression:"'pageTitle'"}],attrs:{"width":"285px","height":"auto"}}),_c('AnalysisTableHeaderCell',{directives:[{name:"t",rawName:"v-t",value:('count'),expression:"'count'"}],attrs:{"width":"61px","height":"auto"}}),_c('AnalysisTableHeaderCell',{directives:[{name:"t",rawName:"v-t",value:('stayTime'),expression:"'stayTime'"}],attrs:{"width":"82px","height":"auto"}})],1)]},proxy:true}])},[_vm._l((_vm.allActionData),function(actionData,actionDataIndex){return _vm._l((actionData.data),function(row,rowIndex){return _c('tr',{key:_vm.getKey(actionDataIndex, rowIndex)},[(rowIndex === 0)?_c('AnalysisTableCell',{directives:[{name:"t",rawName:"v-t",value:(actionData.titleKey),expression:"actionData.titleKey"}],staticClass:"visitSummaryTable__cellAction",attrs:{"width":"70px","rowspan":actionData.data.length}}):_vm._e(),(row.url)?_c('AnalysisTableCell',{class:{
          visitSummaryTable__cellHover:
            _vm.hoverKey === _vm.getKey(actionDataIndex, rowIndex)
        },attrs:{"width":"285px"},on:{"mouse-enter":function($event){_vm.onEnter(_vm.getKey(actionDataIndex, rowIndex))},"mouse-leave":_vm.onLeave}},[_c('Tooltip',{staticClass:"visitSummaryTable__cellTooltip",attrs:{"placement":_vm.tooltipPlacement.TopStart,"fixed-arrow-position":true,"wrap":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"visitSummaryTable__cellTooltipContent"},[_vm._v(_vm._s(row.url))])]},proxy:true}],null,true)},[_c('div',{staticClass:"visitSummaryTable__cellInnerUrl"},[_vm._v(_vm._s(row.url))])])],1):_vm._e(),_c('AnalysisTableCell',_vm._b({staticClass:"visitSummaryTable__cellPageTitle",class:{
          visitSummaryTable__cellHover:
            _vm.hoverKey === _vm.getKey(actionDataIndex, rowIndex)
        },attrs:{"width":row.url ? '285px' : '570px'},on:{"mouse-enter":function($event){_vm.onEnter(_vm.getKey(actionDataIndex, rowIndex))},"mouse-leave":_vm.onLeave}},'AnalysisTableCell',_vm.colspanForName(row.url),false),[_c('Tooltip',{staticClass:"visitSummaryTable__cellTooltip",attrs:{"placement":_vm.tooltipPlacement.TopStart,"fixed-arrow-position":true,"wrap":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"visitSummaryTable__cellTooltipContent"},[_vm._v(_vm._s(row.name))])]},proxy:true}],null,true)},[_c('div',{class:row.url
                ? 'visitSummaryTable__cellInnerPageTitle'
                : 'visitSummaryTable__cellInnerPageTitle--wide'},[_vm._v(_vm._s(row.name))])])],1),_c('AnalysisTableCell',{staticClass:"visitSummaryTable__cellCount",class:{
          visitSummaryTable__cellHover:
            _vm.hoverKey === _vm.getKey(actionDataIndex, rowIndex)
        },attrs:{"width":"61px"},on:{"mouse-enter":function($event){_vm.onEnter(_vm.getKey(actionDataIndex, rowIndex))},"mouse-leave":_vm.onLeave}},[_vm._v(_vm._s(row.count))]),_c('AnalysisTableCell',{staticClass:"visitSummaryTable__cellStayTime",class:{
          visitSummaryTable__cellHover:
            _vm.hoverKey === _vm.getKey(actionDataIndex, rowIndex)
        },attrs:{"width":"82px"},on:{"mouse-enter":function($event){_vm.onEnter(_vm.getKey(actionDataIndex, rowIndex))},"mouse-leave":_vm.onLeave}},[_vm._v(_vm._s(row.stayTime))])],1)})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }