<template>
  <GramCv v-if="isCv" :gram="gram" :is-omo="isOmo" />
  <GramPv
    v-else-if="isPv"
    :gram="gram"
    :is-omo="isOmo"
    :previewed-gram-id="previewedGramId"
    :filter-titles="filterTitles"
    :filter-full-urls="filterFullUrls"
    :filter-part-urls="filterPartUrls"
    @click-pv="onClick"
  />
  <GramEvent v-else-if="isEvent" :gram="gram" :is-omo="isOmo" />
  <GramLaunch v-else-if="isLaunch" :gram="gram" :is-omo="isOmo" />
  <GramBusinessEvent
    v-else-if="isBussinessEvent"
    :gram="gram"
    :is-omo="isOmo"
  />
  <GramNpsEnqt v-else-if="isNpsEnqt" :gram="gram" :is-omo="isOmo" />
  <GramContactTel v-else-if="isContactTel" :gram="gram" :is-omo="isOmo" />
  <GramContactMail v-else-if="isContactMail" :gram="gram" :is-omo="isOmo" />
  <GramContactShop v-else-if="isContactShop" :gram="gram" :is-omo="isOmo" />
  <GramContactDm v-else-if="isContactDm" :gram="gram" :is-omo="isOmo" />
  <GramContactVisit v-else-if="isContactVisit" :gram="gram" :is-omo="isOmo" />
  <GramContactApp v-else-if="isContactApp" :gram="gram" :is-omo="isOmo" />
  <div
    v-else-if="isUserAttr"
    :id="gramId"
    class="ud-Visit_Gram_NoDisplay"
  ></div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { GRAM_VIEW_TYPE } from "@/const/gram";
import { Gram } from "@/models/Gram";
import GramCv from "@/components/observation/visit/gram/GramCv.vue";
import GramPv from "@/components/observation/visit/gram/GramPv.vue";
import GramEvent from "@/components/observation/visit/gram/GramEvent.vue";
import GramLaunch from "@/components/observation/visit/gram/GramLaunch.vue";
import GramBusinessEvent from "@/components/observation/visit/gram/GramBusinessEvent.vue";
import GramNpsEnqt from "@/components/observation/visit/gram/GramNpsEnqt.vue";
import GramContactTel from "@/components/observation/visit/gram/GramContactTel.vue";
import GramContactMail from "@/components/observation/visit/gram/GramContactMail.vue";
import GramContactShop from "@/components/observation/visit/gram/GramContactShop.vue";
import GramContactDm from "@/components/observation/visit/gram/GramContactDm.vue";
import GramContactVisit from "@/components/observation/visit/gram/GramContactVisit.vue";
import GramContactApp from "@/components/observation/visit/gram/GramContactApp.vue";
import { WordForHighlight } from "@/models/search/MatchMethod";

@Component({
  components: {
    GramCv,
    GramPv,
    GramEvent,
    GramLaunch,
    GramBusinessEvent,
    GramNpsEnqt,
    GramContactTel,
    GramContactMail,
    GramContactShop,
    GramContactDm,
    GramContactVisit,
    GramContactApp
  }
})
export default class VisitGram extends Vue {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: String, required: true })
  previewedGramId!: string;

  @Prop({ type: Array, required: true })
  filterTitles!: WordForHighlight[];

  @Prop({ type: Array, required: true })
  filterFullUrls!: WordForHighlight[];

  @Prop({ type: Array, required: true })
  filterPartUrls!: WordForHighlight[];

  onClick(url: string, isSmartphone: boolean, gramId: string) {
    this.$emit("click-pv", url, isSmartphone, gramId);
  }

  get gramId(): string {
    return this.gram.id;
  }
  get isCv(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.CV;
  }
  get isPv(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.PV;
  }
  get isEvent(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.EVENT;
  }
  get isLaunch(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.LAUNCH;
  }
  get isBussinessEvent(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.BUSINESS_EVENT;
  }
  get isNpsEnqt(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.NPS_ENQT;
  }
  get isUserAttr(): boolean {
    // 表示はされないが、概要からのスクロールのためにidのあるdivをいれる
    return this.gram.viewType === GRAM_VIEW_TYPE.USER_ATTR;
  }
  get isContactTel(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.TEL;
  }
  get isContactMail(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.MAIL;
  }
  get isContactShop(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.SHOP;
  }
  get isContactDm(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.DM;
  }
  get isContactVisit(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.VISIT;
  }
  get isContactApp(): boolean {
    return this.gram.viewType === GRAM_VIEW_TYPE.APP;
  }
}
</script>
