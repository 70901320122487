import {
  AdditionalSelectConditionInterface,
  ValueInputMethod,
  numberValue
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import {
  BusinessIndexDefinition,
  BusinessIndexDefinitionType
} from "@/models/client-settings/BusinessIndexDefinition";
import {
  AttributeConditionParamType,
  BusinessIndexConditionParams,
  AdditionalConditionParams
} from "@/api/apis/ApiSearch";
import { i18n } from "@/i18n";

/**
 * ビジネス指標による条件
 *
 * OMO版限定の検索条件
 * 通常の検索条件に加えて、OMOでインポートしたビジネス指標に対する選定条件を付与できる
 *
 * 範囲を指定して、指定したビジネス指標がその範囲内にあるユーザのみを選定する
 */
export class BusinessIndexCondition
  implements AdditionalSelectConditionInterface {
  constructor(
    // ビジネス指標定義のID
    public readonly id: number,
    // 指定範囲の開始
    public readonly from: number,
    // 指定範囲の終了
    public readonly to: number,
    // 値指定 or レンジ指定
    public readonly rangeType: ValueInputMethod
  ) {}

  /**
   * 表示用のタイトル
   * @param definitions
   */
  displayTitle(definitions: BusinessIndexDefinition[]): string {
    const def = definitions.find(d => d.id === this.id);
    const defName = def !== undefined ? ":" + def.name : "";

    return (
      (i18n.t("components.search.search.businessIndex") as string) + defName
    );
  }

  /**
   * 設定された値を表示用に加工して返す。
   * Condtionの種類によっては値のテキストを作るのにDefinitionsが必要になるので、一律渡せるようにしておく。
   * @param definitions
   */
  displayValue(definitions: BusinessIndexDefinition[] = []): string {
    let val = numberValue(this.from, this.to, this.rangeType);
    const def = definitions.find(d => d.id === this.id);

    // ビジネス指標のタイプが期間の場合はヶ月を追加する
    if (def?.type === BusinessIndexDefinitionType.TOTAL_USE_PERIOD) {
      val += i18n.t("components.search.search.month") as string;
    }
    return val;
  }

  /**
   * 指定のIDがbusinessIndexDefinitionIdと同じか返す
   * Conversionごとにid名が違うので共通の関数もたせる
   */
  isSameId(id: number): boolean {
    return id === this.id;
  }
}

/**
 * 検索条件 -> JSON
 */
export function convertBusinessIndexConditionToJson(
  condition: BusinessIndexCondition
): BusinessIndexConditionParams {
  return {
    type: AttributeConditionParamType.BusinessIndex,
    id: condition.id,
    valRangeType: condition.rangeType,
    valFrom: condition.from,
    valTo: condition.to
  };
}

/**
 * JSON-> 検索条件
 */
export function convertJsonToBusinessIndexCondition(
  json: BusinessIndexConditionParams,
  activeBusinessIndexDefinitions: BusinessIndexDefinition[]
): BusinessIndexCondition | null {
  // 利用可能なidリストになければnull
  if (!activeBusinessIndexDefinitions.some(def => def.id === json.id)) {
    return null;
  }

  return new BusinessIndexCondition(
    json.id,
    json.valFrom,
    json.valTo,
    json.valRangeType
  );
}

/**
 * 追加条件のJSONがビジネス指標の条件かどうかを判定する
 */
export function isBusinessIndexConditionParams(
  params: AdditionalConditionParams
): params is BusinessIndexConditionParams {
  return params.type === AttributeConditionParamType.BusinessIndex;
}
