<template>
  <UgTableRow class="lightUgTableRow">
    <slot></slot>
  </UgTableRow>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import UgTableRow from "@/components/table/UgTableRow.vue";

@Component({
  components: {
    UgTableRow
  }
})
export default class LightUgTableRow extends Vue {}
</script>

<style lang="scss" scoped>
.lightUgTableRow {
  border-left: solid 1px $colorBase400;
  background: $colorWhite !important;
}
</style>
