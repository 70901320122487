/**
 * models/client-settings のモデルに変換
 */

import { MutationTree, GetterTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { ApiRes } from "@/api/api-res";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { Coordination } from "@/models/client-settings/Coordination";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { i18n } from "@/i18n";
import { SiteUrl } from "@/api/apis/ApiClientSettings";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";

// API同士でidが被らないようにprefixを用意
export const CONVERSION_ATTRIBUTE_ID_PREFIX = "conversionAttribute_";
export const USER_ATTRIBUTE_ID_PREFIX = "userAttribute_";
export const BUSINESS_INDEX_ID_PREFIX = "businessIndexDefinition_";
export const NPS_ID_PREFIX = "nps_";

// profile_orderのtypeによるIDの分別
const COLUMN_TYPE_PREFIX_MAP: { [key: number]: string } = {
  1: CONVERSION_ATTRIBUTE_ID_PREFIX,
  2: USER_ATTRIBUTE_ID_PREFIX,
  3: BUSINESS_INDEX_ID_PREFIX,
  4: NPS_ID_PREFIX
};

// NPSのカラムは固定表示
const NPS_COLUMNS: TableColumn[] = [
  {
    id: NPS_ID_PREFIX + 1,
    name: "store.modules.recentCustomerLoyaltyScore"
  },
  {
    id: NPS_ID_PREFIX + 2,
    name: "store.modules.recentScoreComment"
  },
  {
    id: NPS_ID_PREFIX + 3,
    name: "store.modules.previousCustomerLoyaltyScore"
  },
  {
    id: NPS_ID_PREFIX + 4,
    name: "store.modules.previousScoreComment"
  }
];

export interface ActiveDefinitions {
  conversionDefinitions: ConversionDefinition[];
  conversionAttributeDefinitions: ConversionAttributeDefinition[];
  eventDefinitions: EventDefinition[];
  npsDefinitions: NpsDefinition[];
  enqueteDefinitions: EnqueteDefinition[];
  businessEventDefinitions: BusinessEventDefinition[];
  businessIndexDefinitions: BusinessIndexDefinition[];
  contactDefinitions: ContactDefinition[];
  userAttributeDefinitions: UserAttributeDefinition[];
  globalConversionDefinitions: ConversionDefinition[];
}

export class ClientSettingsState {
  /**
   * activeが頭につかないDefinitionsは計測停止中の物を含んだDefinitionで、
   * 観察画面で表示される過去のコンバージョンなどは、現行計測停止中のコンバージョンも
   * 含まれるので、こちらのリストを使用する
   */
  conversionDefinitions: ConversionDefinition[] = [];
  conversionAttributeDefinitions: ConversionAttributeDefinition[] = [];
  eventDefinitions: EventDefinition[] = [];
  npsDefinitions: NpsDefinition[] = [];
  enqueteDefinitions: EnqueteDefinition[] = [];
  businessEventDefinitions: BusinessEventDefinition[] = [];
  businessIndexDefinitions: BusinessIndexDefinition[] = [];
  contactDefinitions: ContactDefinition[] = [];
  userAttributeDefinitions: UserAttributeDefinition[] = [];

  /**
   * activeXXXDefinitionsは現在計測停止中の物を抜いたDefinitionで、
   * 選定や絞り込みのリスト等には基本的にはこちらのリストを使用する
   */
  activeConversionDefinitions: ConversionDefinition[] = [];
  activeConversionAttributeDefinitions: ConversionAttributeDefinition[] = [];
  activeEventDefinitions: EventDefinition[] = [];
  activeNpsDefinitions: NpsDefinition[] = [];
  activeEnqueteDefinitions: EnqueteDefinition[] = [];
  activeBusinessEventDefinitions: BusinessEventDefinition[] = [];
  activeBusinessIndexDefinitions: BusinessIndexDefinition[] = [];
  activeContactDefinitions: ContactDefinition[] = [];
  activeUserAttributeDefinitions: UserAttributeDefinition[] = [];

  /**
   * ユーザ一覧でどのカラムを表示させるかの設定
   */
  attributeTableColumns: TableColumn[] = [];

  /**
   * 計測対象のサイト
   * APIの返り値を保存しておき、getterでモデルにして返す
   * APIから返されるのはsite-urlsなのでAPIのinterfaceはSiteUrlですが、
   * 実際にはスキームがないURLではないものが返ってくるので、アプリ内ではmeasurementTargetSitesとUrlsではな名前にしています。
   */
  measurementTargetSites: SiteUrl[] = [];

  coordinations: Coordination[] = [];
}

const mutations: MutationTree<ClientSettingsState> = {
  setConversionDefinitions(
    state: ClientSettingsState,
    conversionDefinitions: ConversionDefinition[]
  ) {
    state.conversionDefinitions = conversionDefinitions;
    state.activeConversionDefinitions = conversionDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setConversionAttributeDefinitions(
    state: ClientSettingsState,
    conversionAttributeDefinitions: ConversionAttributeDefinition[]
  ) {
    state.conversionAttributeDefinitions = conversionAttributeDefinitions;
    state.activeConversionAttributeDefinitions = conversionAttributeDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setEventDefinitions(
    state: ClientSettingsState,
    eventDefinitions: EventDefinition[]
  ) {
    state.eventDefinitions = eventDefinitions;
    state.activeEventDefinitions = eventDefinitions.filter(d => !d.isDisabled);
  },
  setNpsDefinitions(
    state: ClientSettingsState,
    npsDefinitions: NpsDefinition[]
  ) {
    state.npsDefinitions = npsDefinitions;
    state.activeNpsDefinitions = npsDefinitions.filter(d => !d.isDisabled);
  },
  setEnqueteDefinitions(
    state: ClientSettingsState,
    enqueteDefinitions: EnqueteDefinition[]
  ) {
    state.enqueteDefinitions = enqueteDefinitions;
    state.activeEnqueteDefinitions = enqueteDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setBusinessEventDefinitions(
    state: ClientSettingsState,
    businessEventDefinitions: BusinessEventDefinition[]
  ) {
    state.businessEventDefinitions = businessEventDefinitions;
    state.activeBusinessEventDefinitions = businessEventDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setBusinessIndexDefinitions(
    state: ClientSettingsState,
    businessIndexDefinitions: BusinessIndexDefinition[]
  ) {
    state.businessIndexDefinitions = businessIndexDefinitions;
    state.activeBusinessIndexDefinitions = businessIndexDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setContactDefinitions(
    state: ClientSettingsState,
    contactDefinitions: ContactDefinition[]
  ) {
    state.contactDefinitions = contactDefinitions;
    state.activeContactDefinitions = contactDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setUserAttributeDefinitions(
    state: ClientSettingsState,
    userAttributeDefinitions: UserAttributeDefinition[]
  ) {
    state.userAttributeDefinitions = userAttributeDefinitions;
    state.activeUserAttributeDefinitions = userAttributeDefinitions.filter(
      d => !d.isDisabled
    );
  },
  setActiveConversionDefinitions(
    state: ClientSettingsState,
    activeConversionDefinitions: ConversionDefinition[]
  ) {
    state.activeConversionDefinitions = activeConversionDefinitions;
  },
  setAttributeTableColumns(
    state: ClientSettingsState,
    attributeTableColumns: TableColumn[]
  ) {
    state.attributeTableColumns = attributeTableColumns;
  },
  setMeasurementTargetSites(
    state: ClientSettingsState,
    measurementTargetSites: SiteUrl[]
  ) {
    state.measurementTargetSites = measurementTargetSites;
  },
  setCoordinations(state: ClientSettingsState, coordinations: Coordination[]) {
    state.coordinations = coordinations;
  },
  initialize(state: ClientSettingsState) {
    state.conversionDefinitions = [];
    state.conversionAttributeDefinitions = [];
    state.eventDefinitions = [];
    state.npsDefinitions = [];
    state.enqueteDefinitions = [];
    state.businessEventDefinitions = [];
    state.businessIndexDefinitions = [];
    state.contactDefinitions = [];
    state.userAttributeDefinitions = [];
    state.activeConversionDefinitions = [];
    state.activeConversionAttributeDefinitions = [];
    state.activeEventDefinitions = [];
    state.activeNpsDefinitions = [];
    state.activeEnqueteDefinitions = [];
    state.activeBusinessEventDefinitions = [];
    state.activeBusinessIndexDefinitions = [];
    state.activeContactDefinitions = [];
    state.activeUserAttributeDefinitions = [];
    state.attributeTableColumns = [];
    state.coordinations = [];
  }
};

const getters: GetterTree<ClientSettingsState, RootState> = {
  // businessIndexColumns, userAttributeColumns, conversionAttributeColumns
  // 表示可能なものを取得し、idにprefixを付与し返す
  businessIndexColumns(state: ClientSettingsState): TableColumn[] {
    const businessIndexDefinitions = state.businessIndexDefinitions.filter(
      b => !b.isDisabled
    );

    return businessIndexDefinitions.map(
      (businessIndexDefinition: BusinessIndexDefinition) => {
        const businessIndexDefinitionId =
          BUSINESS_INDEX_ID_PREFIX + businessIndexDefinition.id;

        return {
          id: businessIndexDefinitionId,
          name: businessIndexDefinition.name
        };
      }
    );
  },
  userAttributeColumns(state: ClientSettingsState): TableColumn[] {
    const userAttributeDefinitions = state.userAttributeDefinitions.filter(
      u => !u.isDisabled
    );

    return userAttributeDefinitions.map(
      (userAttributeDefinition: UserAttributeDefinition) => {
        const userAttributeDefinitionId =
          USER_ATTRIBUTE_ID_PREFIX + userAttributeDefinition.id;

        return {
          id: userAttributeDefinitionId,
          name: userAttributeDefinition.name
        };
      }
    );
  },
  conversionAttributeColumns(state: ClientSettingsState): TableColumn[] {
    const conversionAttributeDefinitions = state.conversionAttributeDefinitions.filter(
      c => !c.isDisabled
    );

    return conversionAttributeDefinitions.map(
      (conversionAttributeDefinition: ConversionAttributeDefinition) => {
        const conversionAttributeDefinitionId =
          CONVERSION_ATTRIBUTE_ID_PREFIX + conversionAttributeDefinition.id;

        return {
          id: conversionAttributeDefinitionId,
          name: conversionAttributeDefinition.name
        };
      }
    );
  },
  hasNpsColumns(state: ClientSettingsState): boolean {
    const npsDefinitions: NpsDefinition[] = state.npsDefinitions;

    return npsDefinitions.some(n => !n.isDisabled);
  },
  npsColumns(state: ClientSettingsState, getters): TableColumn[] {
    if (getters.hasNpsColumns) {
      return NPS_COLUMNS.map(nps => {
        return { id: nps.id, name: i18n.t(nps.name) as string };
      });
    }

    return [];
  },
  // 取得した表示カラムと順番を元に、全てのカラムから取得する
  // attributeTableColumnOrders === null の場合は全てのカラムを取得する
  tableColumnOptions(state: ClientSettingsState, getters): TableColumn[] {
    return getters.conversionAttributeColumns.concat(
      getters.userAttributeColumns,
      getters.npsColumns,
      getters.businessIndexColumns
    );
  },
  hasActiveOmoDefinitions(state: ClientSettingsState): boolean {
    return (
      0 < state.activeBusinessEventDefinitions.length ||
      0 < state.activeContactDefinitions.length ||
      0 < state.activeEnqueteDefinitions.length ||
      0 < state.activeNpsDefinitions.length
    );
  },
  /**
   * 履歴やURLから検索・絞り込み条件を復活させときに、
   * 復活してデータ（クライアントが計測停止してないか）をチェックするためのdefinitionsを返す
   */
  activeDefinitions(
    state: ClientSettingsState,
    getters, // don't use but needed to call rootGetters
    rootState, // don't use but needed to call rootGetters
    rootGetters
  ): ActiveDefinitions {
    return {
      conversionDefinitions: state.activeConversionDefinitions,
      conversionAttributeDefinitions:
        state.activeConversionAttributeDefinitions,
      eventDefinitions: state.activeEventDefinitions,
      npsDefinitions: state.activeNpsDefinitions,
      enqueteDefinitions: state.activeEnqueteDefinitions,
      businessEventDefinitions: state.activeBusinessEventDefinitions,
      businessIndexDefinitions: state.activeBusinessIndexDefinitions,
      contactDefinitions: state.activeContactDefinitions,
      userAttributeDefinitions: state.activeUserAttributeDefinitions,
      globalConversionDefinitions:
        rootGetters["system/activeGlobalConversionDefinitions"]
    };
  },
  allActiveConversionDefinitions(
    state: ClientSettingsState,
    getters, // don't use but needed to call rootGetters
    rootState,
    rootGetters
  ): ConversionDefinition[] {
    // アプリ契約している場合は、globalConversionと通常のconversionを混ぜて返す
    // analysis toolでは、混ぜた状態で表示するため
    if (
      rootState.client.client !== null &&
      rootState.client.client.isContractApp
    ) {
      return state.activeConversionDefinitions.concat(
        rootGetters["system/activeGlobalConversionDefinitions"]
      );
    }
    return state.activeConversionDefinitions;
  },
  allConversionDefinitions(
    state: ClientSettingsState,
    getters, // don't use but needed to call rootGetters
    rootState,
    rootGetters
  ): ConversionDefinition[] {
    // CVのIDからCV名を取得するため、停止中も含めたCVの定義を返す
    // アプリ契約している場合は、globalConversionと通常のconversionを混ぜて返す
    if (
      rootState.client.client !== null &&
      rootState.client.client.isContractApp
    ) {
      return state.conversionDefinitions.concat(
        rootGetters["system/activeGlobalConversionDefinitions"]
      );
    }
    return state.conversionDefinitions;
  },
  measurementTargetSites(state: ClientSettingsState): MeasurementTargetSite[] {
    return state.measurementTargetSites.map(MeasurementTargetSite.fromJson);
  },
  activeMeasurementSites(state: ClientSettingsState): MeasurementTargetSite[] {
    const sites = state.measurementTargetSites.map(
      MeasurementTargetSite.fromJson
    );
    return sites.filter(site => !site.isDisabled);
  }
};

const actions: ActionTree<ClientSettingsState, RootState> = {
  async fetchConversionDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getConversionList();
    const conversionDefinitions = body.conversions.map(
      ConversionDefinition.fromJson
    );

    commit("setConversionDefinitions", conversionDefinitions);
  },
  async fetchConversionAttributeDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getConversionAttributeList();
    const conversionAttributeDefinitions: ConversionAttributeDefinition[] = body.conversion_attributes.map(
      ConversionAttributeDefinition.fromJson
    );

    commit("setConversionAttributeDefinitions", conversionAttributeDefinitions);
  },
  async fetchEventDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getEventList();
    const eventDefinitions: EventDefinition[] = body.events.map(
      EventDefinition.fromJson
    );

    commit("setEventDefinitions", eventDefinitions);
  },
  async fetchNpsDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getNpsList();
    const npsDefinitions: NpsDefinition[] = body.nps_defs.map(
      NpsDefinition.fromJson
    );

    commit("setNpsDefinitions", npsDefinitions);
  },
  async fetchEnqueteDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getEnqueteList();
    const enqueteDefinitions: EnqueteDefinition[] = body.enqt_defs.map(
      EnqueteDefinition.fromJson
    );

    commit("setEnqueteDefinitions", enqueteDefinitions);
  },
  async fetchBusinessEventDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getBusinessEventList();
    const businessEventDefinitions: BusinessEventDefinition[] = body.be_defs.map(
      BusinessEventDefinition.fromJson
    );

    commit("setBusinessEventDefinitions", businessEventDefinitions);
  },
  async fetchBusinessIndexDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getBusinessIndexList();
    const businessIndexDefinitions: BusinessIndexDefinition[] = body.biz_index_defs.map(
      BusinessIndexDefinition.fromJson
    );

    commit("setBusinessIndexDefinitions", businessIndexDefinitions);
  },
  async fetchContactDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getContactList();
    const contactDefinitions: ContactDefinition[] = body.contact_defs.map(
      ContactDefinition.fromJson
    );

    commit("setContactDefinitions", contactDefinitions);
  },
  async fetchUserAttributeDefinitions({ commit, rootState }) {
    const body = await rootState.api.clientSettings.getUserAttributeList();
    const userAttributeDefinitions: UserAttributeDefinition[] = body.user_attr_defs.map(
      UserAttributeDefinition.fromJson
    );

    commit("setUserAttributeDefinitions", userAttributeDefinitions);
  },
  // 取得した選択されたカラムと順番を元に、全てのカラムから取得する
  // body.profile_order === null の場合は全てのカラムを取得する
  async fetchAttributeTableColumns({ commit, rootState, getters }) {
    const body = await rootState.api.clientSettings.getAttributeTableColumnOrderList();

    if (body.profile_order === null) {
      commit("setAttributeTableColumns", getters.tableColumnOptions);
    } else {
      const attributeTableColumns: TableColumn[] = [];

      body.profile_order.data.forEach(order => {
        const orderId: string = COLUMN_TYPE_PREFIX_MAP[order.type] + order.id;
        const selectColumn:
          | TableColumn
          | undefined = getters.tableColumnOptions.find(
          (c: TableColumn) => c.id === orderId
        );

        if (selectColumn !== undefined) {
          attributeTableColumns.push(selectColumn);
        }
      });
      commit("setAttributeTableColumns", attributeTableColumns);
    }
  },
  // 変更されたcolumnsを受け取りTableに返す
  // 受け取ったcolumnを元に{ type, id }を生成し、
  // /api/userdata/profile_order/save/に渡す
  async saveAttributeTableColumns(
    { commit, rootState },
    columns: TableColumn[]
  ) {
    const attributeOrders: ApiRes.ProfileOrder[] = [];
    const typeKeys: number[] = Object.keys(
      COLUMN_TYPE_PREFIX_MAP
    ).map((n: string) => Number(n));

    if (columns) {
      commit("setAttributeTableColumns", columns);

      columns.forEach(c => {
        const columnId = String(c.id);

        // idに付与されたprefixをもとにtypeを判定
        const types: number[] = typeKeys.filter(
          (type: number) =>
            columnId.indexOf(COLUMN_TYPE_PREFIX_MAP[type]) !== -1
        );

        // 判定したtypeを使用し、columnIdからprefixを削除しidを生成し返す
        types.forEach((type: number) => {
          const id: number = Number(
            columnId.replace(COLUMN_TYPE_PREFIX_MAP[type], "")
          );

          const attributeOrder: ApiRes.ProfileOrder = {
            type: type,
            id: id
          };

          attributeOrders.push(attributeOrder);
        });
      });

      await rootState.api.clientSettings.saveAttributeTableColumnOrderList(
        attributeOrders
      );
    }
  },
  async fetchMeasurementTargetSites({ commit, rootState }) {
    const response = await rootState.api.clientSettings.getSiteUrlList();
    commit("setMeasurementTargetSites", response.site_urls);
  },

  async fetchCoordinations({ commit, rootState }) {
    const response = await rootState.api.clientSettings.getCoordinationList();
    const coordinations = response.coordinations.map(Coordination.fromJson);

    commit("setCoordinations", coordinations);
  }
};

export const clientSettings = {
  namespaced: true,
  state: new ClientSettingsState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
