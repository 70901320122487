import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";
import { MatchMethod } from "@/models/search/MatchMethod";

export interface TdConversionRequest {
  cv_id: number;
  cv_param_column: string;
  cv_cond: string;
  match_method: MatchMethod;
  is_disabled: boolean;
}

export interface TdConversionResponse extends TdConversionRequest {
  id: number;
}

export default class ApiTdConversion {
  constructor(private readonly httpClient: HttpClient) {}

  public getTdConversionList = (): Promise<TdConversionResponse[]> => {
    return this.httpClient.get<TdConversionResponse[]>(
      ApiUrl.TD_CONVERSION_LIST
    );
  };

  public createTdConversion = (
    params: TdConversionRequest
  ): Promise<TdConversionResponse> => {
    return this.httpClient.post<TdConversionResponse>(
      ApiUrl.TD_CONVERSION_LIST,
      params
    );
  };

  public getTdConversionById = (id: number): Promise<TdConversionResponse> => {
    return this.httpClient.get<TdConversionResponse>(
      ApiUrl.TD_CONVERSION_DETAIL(id)
    );
  };

  public updateTdConversion = (
    id: number,
    params: TdConversionRequest
  ): Promise<TdConversionResponse> => {
    return this.httpClient.put<TdConversionResponse>(
      ApiUrl.TD_CONVERSION_DETAIL(id),
      params
    );
  };
}
