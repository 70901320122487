import { NewsForLoginPage as NewsJson } from "@/api/apis/ApiSystem";

export class NewsForLoginPage {
  public readonly id: number = 0;
  public readonly displayMessage = "";

  constructor(res: NewsJson) {
    this.id = res.id;
    const regex: RegExp = new RegExp(/(https?:\/\/\S+)/g);
    const tmpLines: string[] = res.message.split("\n");

    for (const tmpLine of tmpLines) {
      // リンク(http(s)://xxx....)で分割する
      const tmpBlocks: string[] = tmpLine.split(regex);

      for (const tmpBlock of tmpBlocks) {
        if (regex.test(tmpBlock)) {
          this.displayMessage += `<a href="${tmpBlock}" target="_blank" rel="noopener">${tmpBlock}</a>`;
        } else {
          this.displayMessage += tmpBlock + "<br>";
        }
      }
    }
  }
}
