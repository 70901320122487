import { ApiUrl } from "@/api/api-url";
import HttpClient from "@/api/HttpClient";
import { getCurrentDate } from "@/util/date-util";

// this will be used in future task
export interface SegmentedTrendPostParam {
  history_id: number;
  app_user_timezone: number;
  domain?: string;
}

export interface SegmentedTrendResultJsonData {
  aggregate_key: string;
  page_title: string;
  count: number;
  pv_visit: number;
  unique_users: number;
}

// バックエンドのコードと揃えるため、文字列で扱う
export enum SegmentedTrendStatus {
  OK = "OK",
  RUNNING = "RUNNING",
  FAILED = "FAILED"
}

export enum AnalysisType {
  WebView = "web-view",
  Landing = "landing",
  Inflow = "inflow",
  InflowDetail = "inflow-detail",
  AppView = "app-view",
  Event = "event",
  Conversion = "conversion"
}

const API_URL_MAP = {
  "web-view": ApiUrl.SEGMENTED_TREND_WEB_VIEW,
  landing: ApiUrl.SEGMENTED_TREND_LANDING,
  inflow: ApiUrl.SEGMENTED_TREND_INFLOW,
  "inflow-detail": ApiUrl.SEGMENTED_TREND_INFLOW_DETAIL,
  "app-view": ApiUrl.SEGMENTED_TREND_APP_VIEW,
  event: ApiUrl.SEGMENTED_TREND_EVENT,
  conversion: ApiUrl.SEGMENTED_TREND_CONVERSION
};

export default class ApiSegmentedTrends {
  constructor(private readonly httpClient: HttpClient) {}

  public createNew(
    type: AnalysisType,
    historyId: number,
    domain: string | null
  ): Promise<{ id: number }> {
    const url: string = API_URL_MAP[type];

    const param: SegmentedTrendPostParam = {
      history_id: historyId,
      app_user_timezone: getCurrentDate().getTimezoneOffset()
    };

    if (domain) {
      param["domain"] = domain;
    }

    return this.httpClient.post<{ id: number }>(url, param);
  }

  public getStatus(
    type: AnalysisType,
    id: number
  ): Promise<{ status: SegmentedTrendStatus }> {
    const url: string = `${API_URL_MAP[type]}${String(id)}`;
    return this.httpClient.get<{ status: SegmentedTrendStatus }>(url);
  }

  public getResults(
    type: AnalysisType,
    id: number,
    page: string | null = null,
    perPage: number = 20
  ): Promise<{
    result: SegmentedTrendResultJsonData[];
    resume_token: string;
    total_rows: number;
  }> {
    const url: string = `${API_URL_MAP[type]}${String(id)}/result`;
    const params: { per_page: number; page?: string } = {
      per_page: perPage
    };
    if (page) {
      params["page"] = page;
    }
    return this.httpClient.get<{
      result: SegmentedTrendResultJsonData[];
      resume_token: string;
      total_rows: number;
    }>(url, params);
  }

  public getCsv(type: AnalysisType, id: number): Promise<string> {
    const url: string = `${API_URL_MAP[type]}${String(id)}/csv`;
    return this.httpClient.get<string>(url, {
      app_user_timezone: getCurrentDate().getTimezoneOffset()
    });
  }
}
