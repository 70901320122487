import { ApiRes } from "@/api/api-res";

export class MfaConfig {
  constructor(
    public readonly otpAuthUri: string,
    public readonly secretKey: string
  ) {}

  public static build(json: ApiRes.MfaConfig): MfaConfig {
    return new MfaConfig(json.otpauthuri, json.secret_key);
  }
}
