import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ChildFilterNodeParamForInflowParameter } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import {
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * 流入パラメータによる絞り込み条件
 *
 * 入力した流入パラメータに該当（部分一致）するユーザを絞り込む
 * FilterByInflowConditionに子になるので、edgeは持たない
 * （edgeはFilterByInflowConditionが保持する）
 *
 * 初回、期間、日付と時間帯を条件として追加できる
 */
export class ChildFilterNodeForInflowParameter {
  constructor(
    // パラメータ
    public readonly parameter: string,
    /**
     * 追加の検索条件
     *
     * 初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: FilterAdditionalTimingCondition[],
    public readonly parentDepth: number | null = 0
  ) {}

  get validate(): ValidationResult {
    const validationResult = filterInputCheck(
      this.parameter,
      i18n.t("models.search.inflowParameters") as string
    );

    return validateConditions(this.additionalConditions, validationResult);
  }

  isConditionAddable(isFirstNode: boolean): boolean {
    if (this.parentDepth === 0 && isFirstNode) {
      return this.additionalConditions.length < 2;
    } else if (this.parentDepth === 0) {
      return this.additionalConditions.length < 1;
    }
    return false;
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.None,
            label: i18n.t("models.search.inflowParameters") as string,
            disabled: true
          }
        ]
      }
    ];

    if (this.parentDepth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions
        )
      );
    }

    return options;
  }
}

export function convertChildFilterNodeForInflowParameterToJson(
  node: ChildFilterNodeForInflowParameter
): ChildFilterNodeParamForInflowParameter {
  // edgeは別の場所でつける
  const result: ChildFilterNodeParamForInflowParameter = {
    activity_edge: null,
    activity_type: ActivityEdgeType.InflowParameter,
    inflow_param: node.parameter
  };

  const conditions: FilterAdditionalTimingCondition[] =
    node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForInflowParameter(
  json: ChildFilterNodeParamForInflowParameter
) {
  return new ChildFilterNodeForInflowParameter(
    json.inflow_param,
    getAdditionalTimingConditions(json)
  );
}
