<template>
  <UserMemoTable
    sticky-top="120px"
    :users="users"
    :favorites="favorites"
    :current-user="currentUser"
    @mouse-enter="onMouseEnterUserColumn"
    @mouse-leave="onMouseLeaveUserColumn"
  >
    <template #default="{ user }">
      <UserIconContainer
        :user="user"
        @show-favorite-form="onShowFavoriteForm"
        @close-favorite-form="onCloseFavoriteForm"
        @click-user="onClickUser(user)"
      />
      <UserOtherBalloon
        :user="user"
        :columns="conversionAttributeColumns"
        :show="showUserBalloon(user.id)"
        :direction="balloonDirection"
        :y="balloonPositionY"
        :x="balloonPositionX"
        :adjust="balloonArrowAdjust"
      >
        <template #wordcloud>
          <WordcloudContainer :user-id="user.id" />
        </template>
      </UserOtherBalloon>
    </template>
  </UserMemoTable>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import UserOtherBalloon from "@/components/users/UserOtherBalloon.vue";
import UserMemoTable from "@/components/users/UserMemoTable.vue";
import { Favorite } from "@/models/userdata/Favorite";
import UserIconContainer from "@/views/UserIconContainer.vue";
import { UserBalloonDirection } from "@/components/users/UserBaseBalloon.vue";
import WordcloudContainer from "@/views/WordcloudContainer.vue";
import { UgAttributeTag, UgEventTag, UgTag } from "@/store/modules/ugTag";
import { SearchResultViews } from "@/const/SearchResultViews";

// balloonのtopの位置調整(Start postion = 20 + Arrow Size / 2 = 6)
const BALLOON_ARROW_ADJUST: number = 26;
const USER_COLUMN_WIDTH: number = 135;
const SHOW_BALLOON_WAIT_DURATION = 300;
const PUSH_EVENT_DELAY: number = 1000;

// バルーンのmax-heightでセットされている高さ
const MAX_BALLOON_HEIGHT = 500;
// table cellの最上端の高さ(スクロール時)
const TABLE_CELL_TOP = 170;

@Component({
  components: {
    UserOtherBalloon,
    UserMemoTable,
    UserIconContainer,
    WordcloudContainer
  }
})
export default class UserSearchResultMemo extends Vue {
  onClickUser(user: User) {
    this.$emit("click-user", user);
  }

  @Emit("on-show-favorite-form")
  onShowFavoriteForm() {
    this.showingFavoriteForm = true;
    this.onMouseLeaveUserColumn();
  }

  @Emit("on-close-favorite-form")
  onCloseFavoriteForm() {
    this.showingFavoriteForm = false;
  }

  mouseEnterUserId: string = "";
  balloonDisplayTimer: number | null = null;
  balloonDirection: UserBalloonDirection = UserBalloonDirection.Top;
  balloonPositionY: string = "auto";
  balloonPositionX: string = "auto";
  balloonArrowAdjust: number = 0;
  showingFavoriteForm: boolean = false;
  pushEventTimer: number | undefined = undefined;

  get users(): User[] {
    if (this.$store.state.clustering.isClusteringMode) {
      return this.$store.state.clustering.users;
    } else if (this.isTourDetail) {
      return this.$store.getters["filter/tourUsers"];
    } else if (this.$store.state.filter.isFilterMode) {
      return this.$store.state.filter.users;
    } else {
      return this.$store.state.search.users;
    }
  }

  get currentUser(): User | null {
    return this.$store.state.user.currentUser;
  }

  get conversionAttributeColumns(): TableColumn[] {
    return this.$store.getters["clientSettings/conversionAttributeColumns"];
  }

  get favorites(): Favorite[] {
    return this.$store.state.favorite.favorites;
  }

  get isTourDetail(): boolean {
    return this.$route.name === "tour-detail";
  }

  onMouseEnterUserColumn(event: MouseEvent, userId: string) {
    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
    }
    this.balloonDisplayTimer = window.setTimeout(() => {
      const evTarget = event.target;
      const target: HTMLDivElement = evTarget as HTMLDivElement;
      const cellTop: number = target.getBoundingClientRect().top;
      const cellHeight: number = target.clientHeight;
      const windowHeight: number = window.innerHeight;
      this.mouseEnterUserId = userId;

      this.balloonPositionX = USER_COLUMN_WIDTH + "px";

      // cellの大きさで場合わけ
      if (cellHeight > MAX_BALLOON_HEIGHT) {
        // WordCloudをmiddleで開いた際のバルーンの上端
        const balloonMaxTop = cellTop + cellHeight / 2 - MAX_BALLOON_HEIGHT / 2;
        // WordCloudをmiddleで開いた際のバルーンの下端
        const balloonMaxBottom =
          cellTop + cellHeight / 2 + MAX_BALLOON_HEIGHT / 2;

        // Middle : WordCloudを上下に開いても上下とも画面内に収まる
        if (
          balloonMaxBottom <= windowHeight &&
          balloonMaxTop >= TABLE_CELL_TOP
        ) {
          this.balloonDirection = UserBalloonDirection.Middle; // WordCloudがどっち側に開くか指定
          this.balloonPositionY = "calc(50%)"; // bWordCloud・arrowの位置を移動
          this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2; // arrowの位置を移動
          return;
        }
        // Bottom : Middle WordCloudを上下に開いたら、下側が画面外に出てしまう場合
        if (balloonMaxBottom > windowHeight) {
          this.balloonDirection = UserBalloonDirection.Bottom;
          this.balloonPositionY =
            cellHeight / 2 - BALLOON_ARROW_ADJUST / 2 + "px";
          this.balloonArrowAdjust = 0;
          return;
        }

        // Top : Middle WordCloudを上下に開いたら、上側が画面外に出てしまう場合
        if (balloonMaxTop < TABLE_CELL_TOP) {
          this.balloonDirection = UserBalloonDirection.Top;
          this.balloonPositionY =
            cellHeight / 2 - (BALLOON_ARROW_ADJUST * 3) / 2 + "px";
          this.balloonArrowAdjust = 0;
          return;
        }

        // 上記で全ての場合を捉えられているので、デフォルト設定は不要
      } else {
        // Top WordCloudを下に開いても収まる
        if (cellTop + MAX_BALLOON_HEIGHT < windowHeight) {
          this.balloonDirection = UserBalloonDirection.Top;
          this.balloonPositionY = BALLOON_ARROW_ADJUST * -1 + "px";
          this.balloonArrowAdjust = cellHeight / 2 - BALLOON_ARROW_ADJUST / 2;
          return;
        }

        // Middle WordCloudを開いても下が収まる
        if (cellTop + MAX_BALLOON_HEIGHT / 2 < windowHeight) {
          this.balloonDirection = UserBalloonDirection.Middle;
          this.balloonPositionY = "calc(50%)";
          this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2;
          return;
        }

        // Bottom 下からWordCloudを開いても上に突き抜けない
        if (
          cellTop + cellHeight - MAX_BALLOON_HEIGHT - BALLOON_ARROW_ADJUST / 2 >
          TABLE_CELL_TOP
        ) {
          this.balloonDirection = UserBalloonDirection.Bottom;
          this.balloonPositionY = BALLOON_ARROW_ADJUST / 2 + "px";
          this.balloonArrowAdjust = -(cellHeight / 2 - BALLOON_ARROW_ADJUST);
          return;
        }

        // どれにも当てはまらない時は中央寄せにしておく
        this.balloonDirection = UserBalloonDirection.Middle;
        this.balloonPositionY = "calc(50%)";
        this.balloonArrowAdjust = -BALLOON_ARROW_ADJUST / 2;
      }
    }, SHOW_BALLOON_WAIT_DURATION);
  }

  onMouseLeaveUserColumn() {
    if (this.balloonDisplayTimer !== null) {
      clearTimeout(this.balloonDisplayTimer);
      this.balloonDisplayTimer = null;
    }
    clearTimeout(this.pushEventTimer);

    this.mouseEnterUserId = "";
    this.balloonPositionY = "auto";
    this.balloonPositionX = "auto";
  }

  showUserBalloon(userId: string): boolean {
    if (userId !== this.mouseEnterUserId) return false;

    clearTimeout(this.pushEventTimer);
    this.pushEventTimer = window.setTimeout(() => {
      UgTag.pushEvent(UgEventTag.UserListBalloon, {
        [UgAttributeTag.UserListDisplayMode]: SearchResultViews.Memo
      });
    }, PUSH_EVENT_DELAY);

    return true;
  }
}
</script>
