<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <div class="filter-history-entry">
    <div
      class="filter-history-entry__item"
      @click="onClick(history.filterCondition)"
    >
      <div class="filter-history-entry__item-elapsed">{{
        elapsed(history)
      }}</div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { FilterHistory } from "@/models/search/FilterHistory";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { getElapsedTxFromNow } from "@/util/date-util";

@Component
export default class FilterHistoryEntry extends Vue {
  @Prop({ type: FilterHistory, required: true })
  history!: FilterHistory;

  onClick(condition: FilterCondition) {
    this.$emit("click", condition);
  }

  elapsed(filterHistory: FilterHistory): string {
    return getElapsedTxFromNow(filterHistory.updatedAt.getTime());
  }
}
</script>
<style lang="scss" scoped>
.filter-history-entry__item {
  position: relative;
  margin-bottom: 8px;
  padding: 8px 8px 4px 8px;
  width: 100%;
  border-radius: $sizeRadius;
  background-color: $colorGray100;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $colorBlue600;
  }
}
.filter-history-entry__item-elapsed {
  margin-bottom: 2px;
  color: $colorBase600;
  font-size: 12px;
}
</style>
