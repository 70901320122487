<i18n
  src="@/i18n/views/client-settings/coordination/event/coordination-event-settings-create.json"
></i18n>
<template>
  <div class="coordinationEventSettingsCreate">
    <h1 class="coordinationEventSettingsCreate__title">
      {{ coordinationName }}
    </h1>
    <div
      v-t="'title'"
      class="coordinationEventSettingsCreate__title coordinationEventSettingsCreate__subTitle"
    />
    <div class="EventSettingsCreate_tabContent">
      <div
        v-if="isConfirm"
        v-t="'message'"
        class="coordinationEventSettingsCreate__message"
      />
      <CoordinationEventConfirmTable
        v-if="isConfirm"
        :coordination-event="formData"
      />
      <CoordinationEventEditTable
        v-else
        :coordination-event="formData"
        :is-contract-web="isContractWeb"
        :event-limit-reached="eventLimitReached"
        @update="onUpdate"
      />
      <div class="coordinationEventSettingsCreate__buttonContainer">
        <Button
          v-t="isConfirm ? 'register' : 'confirm'"
          class="coordinationEventSettingsCreate__confirmButton"
          :disabled="isSubmitButtonDisabled"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickConfirm"
        />
        <Button
          v-t="'cancel'"
          class="coordinationEventSettingsCreate__cancelButton"
          type="light"
          width="120px"
          :text-size="buttonTextSize"
          @click="onClickCancel"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import { ButtonTextSize } from "@/const/button";
import {
  EventDefinition,
  CoordinationEventEditableData,
  isValidCoordinationEventData
} from "@/models/client-settings/EventDefinition";
import { handleError } from "@/util/error-util";
import {
  Coordination,
  CreationMethod
} from "@/models/client-settings/Coordination";
import CoordinationEventConfirmTable from "@/components/client-settings/coordination/event/CoordinationEventConfirmTable.vue";
import CoordinationEventEditTable from "@/components/client-settings/coordination/event/CoordinationEventEditTable.vue";
import { MapEventDefinitionListWithEvent } from "@/models/client-settings/MapEventDefinition";

@Component({
  components: {
    Button,
    CoordinationEventConfirmTable,
    CoordinationEventEditTable
  }
})
export default class CoordinationEventSettingsCreate extends Vue {
  buttonTextSize = ButtonTextSize.Small;
  isConfirm: boolean = false;
  isSubmitting: boolean = false;

  formData: CoordinationEventEditableData = {
    targetData: this.coordinationName,
    creationMethod: CreationMethod.NEW,
    eventId: 0,
    eventName: "",
    path: [""]
  };

  get coordinationName(): string {
    return (
      this.coordinations.find(
        coordination => coordination.id === this.coordinationId
      )?.name || ""
    );
  }

  get isContractWeb(): boolean {
    return this.$store.state.client.client.isContractWeb;
  }

  get coordinations(): Coordination[] {
    return this.$store.state.clientSettings.coordinations;
  }

  get coordinationId(): number {
    return Number(this.$route.params.coordinationId);
  }

  onUpdate(data: CoordinationEventEditableData) {
    this.formData = data;
  }

  async onClickConfirm() {
    if (this.isConfirm) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      await this.addMapEvent().then(
        () =>
          (window.location.href = `/client-settings/coordination/${this.coordinationId}/event/index/?created_new=true`)
      );
    } else {
      this.scrollTop();
      this.isConfirm = true;
    }
  }

  async addMapEvent() {
    const mapEventDef = new MapEventDefinitionListWithEvent(
      this.formData.creationMethod,
      this.coordinationId,
      this.formData.eventName,
      this.formData.eventId,
      this.formData.path
    );
    await this.$store
      .dispatch("mapEvent/registerNewEventAndMapEvent", mapEventDef)
      .catch(handleError);
  }

  onClickCancel() {
    if (this.isConfirm) {
      this.scrollTop();
      this.isConfirm = false;
    } else {
      window.location.href = `/client-settings/coordination/${this.coordinationId}/event/index/`;
    }
  }

  get isEventsFetched(): boolean {
    return this.$store.state.clientSettings.activeEventDefinitions.length > 0;
  }

  get activeEvents(): EventDefinition[] {
    return this.$store.state.clientSettings.activeEventDefinitions;
  }

  get resourceLimitation(): number {
    return this.$store.state.client.client.resourceLimitation.event;
  }

  get eventCreationLimit(): number {
    return this.resourceLimitation - this.activeEvents.length;
  }

  get isSubmitButtonDisabled(): boolean {
    if (this.isSubmitting) return true;

    return !isValidCoordinationEventData(this.formData);
  }

  get eventLimitReached(): boolean {
    return this.eventCreationLimit <= 0;
  }

  async beforeMount() {
    if (!this.isEventsFetched) {
      await this.$store
        .dispatch("clientSettings/fetchEventDefinitions")
        .catch(handleError);
    }

    if (this.eventLimitReached) {
      this.formData.creationMethod = CreationMethod.EXISTING;
    }
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
</script>
<style lang="scss" scoped>
.coordinationEventSettingsCreate {
  margin-right: 20px;
  min-width: 704px;
}
.coordinationEventSettingsCreate__title {
  font-size: 20px;
  font-weight: 700;
}

.coordinationEventSettingsCreate__subTitle {
  padding: 20px 0 46px 0;
}
.coordinationEventSettingsCreate__message {
  margin-bottom: 30px;
  font-size: 14px;
}
.coordinationEventSettingsCreate__buttonContainer {
  margin-top: 16px;
}
.coordinationEventSettingsCreate__confirmButton {
  margin-right: 20px;
}
</style>
