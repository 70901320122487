<i18n
  src="@/i18n/components/user-trend/form/user-trend-search-form.json"
></i18n>
<template>
  <div class="userTrendSearchForm">
    <UserTrendSearchFormInput
      v-model="localCondition"
      :client-cvs="clientCvs"
    />

    <Button
      class="userTrendSearchForm__button"
      data-cy="userTrendSearchFormSubmit"
      width="350px"
      @click="onClick(localCondition)"
      >{{ $t("display") }}</Button
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserTrendSearchCondition } from "@/models/user-trend/UserTrendSearchCondition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import Button from "@/components/Button.vue";
import UserTrendSearchFormInput from "@/components/user-trend/form/UserTrendSearchFormInput.vue";
@Component({
  components: {
    Button,
    UserTrendSearchFormInput
  }
})
export default class UserTrendSearchForm extends Vue {
  @Prop({ type: UserTrendSearchCondition, required: true })
  condition!: UserTrendSearchCondition;

  @Prop({ type: Array, required: true })
  clientCvs!: ConversionDefinition[];

  onClick(localCondition: UserTrendSearchCondition) {
    this.$emit("click", localCondition);
  }

  localCondition = this.condition;
}
</script>

<style scoped lang="scss">
.userTrendSearchForm {
  padding: 15px 20px 25px;
  border: 1px solid $colorBase500;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.userTrendSearchForm__button {
  margin-top: 20px;
  margin-left: $userTrendSearchFormTitleWidth;
}
</style>
