<template>
  <UgTableHeaderRow class="lightUgTableHeaderRow">
    <slot></slot>
  </UgTableHeaderRow>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import UgTableHeaderRow from "@/components/table/UgTableHeaderRow.vue";

@Component({
  components: {
    UgTableHeaderRow
  }
})
export default class LightUgTableHeaderRow extends Vue {}
</script>

<style lang="scss" scoped>
.lightUgTableHeaderRow {
  border-bottom: solid 1px $colorBase400;

  &:last-child {
    border-bottom: none;
  }
}
</style>
