<i18n
  src="@/i18n/components/client-settings/coordination/conversion-attribute/coordination-conversion-attribute-confirm-table.json"
></i18n>
<template>
  <div class="conversionAttributeConfirmTable">
    <SettingsTable>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'targetData'"
          width="200px"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell width="480px">
          {{ coordinationConversionAttribute.targetData }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'conversionAttribute'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell>
          {{ conversionAttributeName }}
        </SettingsTableCell>
      </SettingsTableRow>
      <SettingsTableRow>
        <SettingsTableCell
          v-t="'coordinationParameter'"
          :style="{ padding: '20px 10px 20px 20px' }"
        />
        <SettingsTableCell>
          <ul style="list-style-type: disc; margin-left: 15px;">
            <li
              v-for="param in coordinationConversionAttribute.path"
              :key="param"
            >
              {{ param }}
            </li>
          </ul>
        </SettingsTableCell>
      </SettingsTableRow>
    </SettingsTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SettingsTable from "@/components/table/SettingsTable.vue";
import SettingsTableRow from "@/components/table/SettingsTableRow.vue";
import SettingsTableCell from "@/components/table/SettingsTableCell.vue";
import { CreationMethod } from "@/models/client-settings/Coordination";
import {
  ConversionAttributeDefinition,
  CoordinationConversionAttributeEditableData
} from "@/models/client-settings/ConversionAttributeDefinition";

@Component({
  components: {
    SettingsTable,
    SettingsTableRow,
    SettingsTableCell
  }
})
export default class CoordinationConversionAttributeConfirmTable extends Vue {
  @Prop({ type: Object, required: true })
  coordinationConversionAttribute!: CoordinationConversionAttributeEditableData;

  get activeConversionAttributes(): ConversionAttributeDefinition[] {
    return this.$store.state.clientSettings
      .activeConversionAttributeDefinitions;
  }

  get conversionAttributeName() {
    if (
      this.coordinationConversionAttribute.creationMethod === CreationMethod.NEW
    )
      return this.coordinationConversionAttribute.conversionAttributeName;

    return this.activeConversionAttributes.find(
      conversionAttribute =>
        conversionAttribute.id ===
        Number(this.coordinationConversionAttribute.conversionAttributeId)
    )?.name;
  }
}
</script>

<style lang="scss" scoped>
.conversionAttributeConfirmTable {
  margin-bottom: 16px;
  min-width: 680px;
}
</style>
