import { Sec, SECONDS_IN_DAY } from "@/util/date-util";
import {
  EdgeParam,
  FilterEdgeParamForIntervalTime,
  FilterEdgeParamForSameVisit,
  FilterEdgeParamForTransitionStep
} from "@/api/apis/ApiSearch";

/**
 * 絞り込み条件間の連結条件タイプ
 */
export enum FilterEdgeType {
  allVisit = 5,
  sameVisit = 2,
  otherVisit = 1,
  withinXDays = 3,
  moreThanXDays = 4
}

/**
 * 絞り込み条件間の連結条件の日付タイプ
 * allVisitとsameVisitとotherVisitは日付不要なのでnone
 */
export enum FilterEdgeDayType {
  day1 = 1,
  day7 = 7,
  day30 = 30
}

export enum SameVisitTransitionType {
  immediatelyAfter = 1,
  withInTransitionStep = 2,
  allAfter = 3
}

export enum WithInTransitionStepType {
  stepThree = 3,
  stepFive = 5,
  stepTen = 10,
  stepFifteen = 15,
  stepAny = 2
}
/**
 * 絞り込み条件間の連結条件
 */
export class FilterEdge {
  constructor(
    public readonly edgeType: FilterEdgeType,
    public readonly dayType: FilterEdgeDayType = FilterEdgeDayType.day1,
    public readonly transitionType: SameVisitTransitionType = SameVisitTransitionType.allAfter,
    public transitionSteps: TransitionStep = new TransitionStep(
      WithInTransitionStepType.stepThree,
      WithInTransitionStepType.stepThree
    )
  ) {}

  public static getDefaultEdge(): FilterEdge {
    return new FilterEdge(FilterEdgeType.allVisit);
  }
}

export class TransitionStep {
  constructor(public type: WithInTransitionStepType, public steps: number) {}
}

/**
 * エッジをJSONに変換する
 */
export function convertFilterEdgeToJson(
  edge: FilterEdge | null
): EdgeParam | null {
  if (edge === null) {
    return null;
  }

  if (
    edge.edgeType === FilterEdgeType.moreThanXDays ||
    edge.edgeType === FilterEdgeType.withinXDays
  ) {
    const filterEdgetInterval: number = getFilterEdgeInterval(edge);

    return {
      edge_type: edge.edgeType,
      interval_time_sec: filterEdgetInterval
    };
  }

  if (edge.edgeType === FilterEdgeType.sameVisit) {
    if (edge.transitionType == SameVisitTransitionType.withInTransitionStep) {
      return {
        edge_type: edge.edgeType,
        transition_type: edge.transitionType,
        transition_steps: edge.transitionSteps.steps
      };
    }

    return {
      edge_type: edge.edgeType,
      transition_type: edge.transitionType
    };
  }

  return {
    edge_type: edge.edgeType
  };
}

/**
 * JSONからエッジに変換する
 */
export function convertJsonToFilterEdge(
  edgeParam: EdgeParam | null
): FilterEdge | null {
  if (edgeParam === null) {
    return null;
  }

  return new FilterEdge(
    edgeParam.edge_type,
    getFilterEdgeDayType(edgeParam),
    getFilterEdgeTransitionType(edgeParam),
    getFilterEdgeTransitionStep(edgeParam)
  );
}

/**
 * エッジが持つ時間(インターバル)の条件
 * 時間が関係ないエッジの条件の場合、(使わないはずだがなぜか)SECONDS_IN_DAYを返す
 */
function getFilterEdgeInterval(edge: FilterEdge): Sec {
  return edge.dayType * SECONDS_IN_DAY;
}

/**
 * 秒数をFilterEdgeTypeに変換して返す
 */
function getFilterEdgeDayType(edgeParam: EdgeParam): FilterEdgeDayType {
  if (isFilterEdgeParamForIntervalTime(edgeParam)) {
    return Math.round(edgeParam.interval_time_sec / SECONDS_IN_DAY);
  }

  return FilterEdgeDayType.day1;
}

function isFilterEdgeParamForIntervalTime(
  edgeParam: EdgeParam
): edgeParam is FilterEdgeParamForIntervalTime {
  return (
    (edgeParam as FilterEdgeParamForIntervalTime).interval_time_sec !==
    undefined
  );
}

function getFilterEdgeTransitionType(
  edgeParam: EdgeParam
): SameVisitTransitionType {
  if (isFilterEdgeParamForTransitionType(edgeParam)) {
    return edgeParam.transition_type;
  }

  return SameVisitTransitionType.allAfter;
}

function isFilterEdgeParamForTransitionType(
  edgeParam: EdgeParam
): edgeParam is FilterEdgeParamForSameVisit {
  return (
    (edgeParam as FilterEdgeParamForSameVisit).transition_type !== undefined
  );
}

function getFilterEdgeTransitionStep(edgeParam: EdgeParam): TransitionStep {
  if (isFilterEdgeParamForTransitionStep(edgeParam)) {
    if (
      edgeParam.transition_steps === WithInTransitionStepType.stepThree ||
      edgeParam.transition_steps === WithInTransitionStepType.stepFive ||
      edgeParam.transition_steps === WithInTransitionStepType.stepTen ||
      edgeParam.transition_steps === WithInTransitionStepType.stepFifteen
    ) {
      return new TransitionStep(
        edgeParam.transition_steps,
        edgeParam.transition_steps
      );
    } else {
      return new TransitionStep(
        WithInTransitionStepType.stepAny,
        edgeParam.transition_steps
      );
    }
  }
  return new TransitionStep(
    WithInTransitionStepType.stepThree,
    WithInTransitionStepType.stepThree
  );
}

function isFilterEdgeParamForTransitionStep(
  edgeParam: EdgeParam
): edgeParam is FilterEdgeParamForTransitionStep {
  return (
    (edgeParam as FilterEdgeParamForTransitionStep).transition_steps !==
    undefined
  );
}
