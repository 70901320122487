<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class GramBase extends Vue {}
</script>

<style scoped lang="scss">
.ud-Visit_Gram {
  position: relative;
  display: flex;
  padding: 18px 0;

  &::before {
    position: absolute;
    top: 0;
    left: 23px;
    height: calc(100% + 35px);
    border-left: 2px solid $colorLine;
    content: " ";
  }

  &:first-child {
    padding-top: 0;

    &::before {
      top: 20px;
      height: calc(100% - 20px);
    }
  }

  &:last-child {
    padding-bottom: 0;

    &::before {
      top: 0;
      height: 48px;
    }
  }

  &:only-child {
    padding-top: 0;
    padding-bottom: 0;

    &::before {
      height: 0;
      content: "";
    }
  }
}

.ud-Visit_Gram.ud-Visit_Gram_Exit:not(:last-child) {
  padding-bottom: 28px;
  background-image: $edge-dot;
  background-position: 23px 100%;
  background-size: 3px 18px;
  background-repeat: no-repeat;
  &::before {
    border-left: 0;
  }
}

.ud-Visit_Gram.ud-Visit_Gram_Inflow:not(.ud-Visit_Gram_Launch) {
  &::before {
    top: 40px;
    height: calc(100% - 40px);
  }
}

.ud-Visit_Gram.ud-Visit_Gram_Inflow:first-child:not(.ud-Visit_Gram_Launch) {
  &::before {
    top: 10px;
    height: calc(100% - 10px);
  }
}

.ud-Visit_GramBody {
  position: relative;
  width: 100%;
}

.ud-Visit_GramTime {
  position: absolute;
  top: -16px;
  right: 0;
  color: $colorBase500;
  font-size: 12px;
}

.ud-Visit_Gram_Omo .ud-Visit_GramTime {
  right: 40px;
}

.ud-Visit_Gram_Omo .ud-Visit_GramBody {
  padding-right: 40px;
}

.ud-Visit_GramActionDetailText {
  @include all-break-word();
}

.ud-Visit_GramIcon {
  position: relative;
  flex-basis: 100px;
  flex-shrink: 0;
  width: 100px;
}

.ud-Visit_Gram_CompanyActivity {
  flex-direction: row-reverse;

  & .ud-Visit_GramBody {
    padding-right: 0;
    padding-left: 40px;
  }

  & .ud-Visit_GramActionDetailText {
    padding: 40px 0 0 0;
    text-align: right;
  }

  & .ud-Visit_GramIcon {
    flex-basis: 60px;
  }
}

.ud-Visit_GramIcon_CompanyActivity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 11px 0 auto;
  width: 28px;
  height: 28px;
  border: 2px solid $colorLine;
  border-radius: 50%;
  background-color: $colorWhite;
}

.ud-Visit_GramCompanyActivity {
  position: absolute;
  right: 0;
  display: inline-block;
  padding: 0 16px;
  height: 30px;
  border: 1px solid $colorTextLight;
  color: $colorTextLight;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
}

.ud-Visit_Gram_CompanyActivity .ud-Visit_GramTime {
  left: 100px;
}

.ud-Visit_Gram_Action {
  padding: 10px 0 14px 0;
}

.ud-Visit_GramIcon_Circle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  width: 38px;
  height: 38px;
  border: 1px solid $colorBlue850;
  border-radius: 50%;
  background-color: $colorBlue600;
}

.ud-Visit_GramIcon_Circle-Cv {
  border-color: $colorBlue900;
  background-color: $colorBlue800;
}

.ud-Visit_GramIcon_Circle-Exit {
  border-color: $colorBase500;
  background-color: $colorGray200;
}

.ud-Visit_Gram_NoDisplay {
  position: relative;
  padding-bottom: 0px;
  height: 0px;
}

.ud-Visit_GramAction {
  display: inline-block;
  margin: 5px 10px 5px 0;
  padding: 0 16px;
  height: 30px;
  border: 1px solid $colorBlue900;
  background: $colorWhite;
  color: $colorBlue900;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
}

.ud-Visit_GramAction-Cv {
  border-color: $colorBlue900;
  background-color: $colorBlue800;
  color: $colorWhite;
  font-weight: 700;
}

.ud-Visit_GramActionDetail {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
  font-size: 10px;
  line-height: 1.2;
}

.ud-Visit_GramActionDetailRow {
  display: flex;
  margin: 5px;
  font-size: 10px;
}

.ud-Visit_GramActionDetailKey {
  width: 15%;

  @include all-break-word();
}

.ud-Visit_GramActionDetailValue {
  flex: 1;

  @include all-break-word();
}

/* GRAM PV */
.ud-Visit_HighLight {
  display: inline-block;
  background: $colorFilter;
  color: $colorWhite;
}

.ud-Visit_GramTitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.2;
}

.ud-Visit_GramTitleNavigation {
  padding-left: 15px;
  color: $colorTextLight;
  font-size: 14px;
}

.ud-Visit_Gram_Pv {
  min-height: 50px;

  & .showingPreview {
    background: rgba($colorHoverLightForWhite, 0.6);
  }

  & .ud-Visit_GramBody {
    margin-top: -8px;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  & .ud-Visit_GramBody--hasLink {
    &:hover {
      background: rgba($colorHoverLightForWhite, 0.6);
      cursor: pointer;
    }
  }
}

.ud-Visit_GramIconApp {
  position: absolute;
  top: 42px;
  left: 11px;
  padding: 5px 0 5px 0;
  background: $colorWhite;
  color: $colorTextLight;
  font-weight: bold;
  font-size: 12px;
}

.ud-Visit_GramIconTime_Pc,
.ud-Visit_GramIconTime_Smp {
  position: absolute;
  width: 36px;
  height: 36px;
  border: 1px solid $colorDark;
  border-radius: 50%;
  background-color: $colorWhite;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  line-height: 34px;
}

.ud-Visit_GramIconTime_Pc {
  top: -20px;
  left: 35px;
}

.ud-Visit_GramIconTime_Smp {
  top: -21px;
  left: 27px;
}

.ud-Visit_GramIconTime_Unit {
  color: $colorBase800;
  font-weight: normal;
  font-size: 10px;
}

.ud-Visit_GramIcon_UserIcon {
  margin: 3px 0 0 0;
}

.ud-Visit_GramIconTime_LongStay {
  & .ud-Visit_GramIconTime_Pc,
  & .ud-Visit_GramIconTime_Smp {
    background-color: $colorDark;
    color: $colorWhite;
  }
  & .ud-Visit_GramIconTime_Unit {
    color: $colorWhite;
  }
}

.ud-Visit_GramUrl {
  display: inline-block;
  color: $colorTextLight;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;

  @include all-break-word();

  &:hover {
    text-decoration: underline;
  }
}

.ud-Visit_GramUrl_NoLink {
  color: $colorTextLight;
  font-size: 14px;
  line-height: 1.2;
}

/* GRAM Withdrawal */
.ud-Visit_Gram_Exit .icon {
  color: $colorBase600;
  transform: translateX(-1px) rotate(180deg);
}
.ud-Visit_GramIcon_Tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
}

/* Inflow */
.ud-Visit_Gram_Inflow {
  padding: 40px 0 14px 0;
}

.ud-Visit_Gram_Inflow:first-child {
  padding-top: 10px;
}

.ud-Visit_InflowTitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.2;
}

.ud-Visit_InflowAttrs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ud-Visit_InflowAttr {
  display: flex;
  margin-right: 12px;
  font-size: 11px;
}

.ud-Visit_InflowAttrTitle {
  overflow: hidden;
  margin: 5px 5px 5px 0;
  padding: 0 8px;
  min-width: 80px;
  max-width: 400px;
  height: 24px;
  border: 1px solid $colorLine;
  background: $colorWhite;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 22px;
}

.ud-Visit_InflowAttrValue {
  margin: 5px 5px 5px 0;
  padding-top: 6px;
  line-height: 1.2;

  @include all-break-word();
}

.ud-Visit_InflowAttrValueLink {
  color: $colorTextLinkGray;
}

.ud-Visit_InflowReferrerDetailTooltip ::v-deep .tooltip__popup {
  width: 100%;
}
</style>
