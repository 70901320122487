import { MutationTree, GetterTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { i18n } from "@/i18n";

import { FilterPeriodDays } from "@/const/filter";

import {
  TourCondition,
  TourConditionSearchAndFilterModel,
  INQUIRY_TOUR_ID,
  ERROR_TOUR_ID,
  MULTI_INFLOW_TOUR_ID
} from "@/models/tour/TourCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { FilterNodeForBrowseSite } from "@/models/search/filter-node/FilterNodeForBrowseSite";
import { FilterPageTitleCondition } from "@/models/search/filter-node-condition/FilterPageTitleCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import {
  FilterEdge,
  FilterEdgeType,
  SameVisitTransitionType
} from "@/models/search/filter-node/FilterEdge";
import { MatchMethod } from "@/models/search/MatchMethod";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { getLastMonthStartDate, getLastMonthEndDate } from "@/util/date-util";
import { FilterNodeForOrNode } from "@/models/search/filter-node/FilterNodeForOrNode";
import { FilterExclusion } from "@/models/search/filter-node/FilterExclusion";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { FilterNodeForInflow } from "@/models/search/filter-node/FilterNodeForInflow";
import { ChildFilterNodeForSearchEngine } from "@/models/search/filter-node/ChildFilterNodeForSearchEngine";

export const TOUR_DETAIL_MAX_USER_COUNT = 5;

export class TourState {
  isTourMode: boolean = false;
  currentTour: TourCondition | null = null;
}

const mutations = <MutationTree<TourState>>{
  setIsTourMode(state: TourState, isTourMode: boolean) {
    state.isTourMode = isTourMode;
  },
  setCurrentTour(state: TourState, tour: TourCondition | null) {
    state.currentTour = tour;
  }
};

const getters = <GetterTree<TourState, RootState>>{
  tours(state, {}, {}, rootGetters): TourCondition[] {
    const allActiveConversionIds: number[] = rootGetters[
      "clientSettings/allActiveConversionDefinitions"
    ].map((c: ConversionDefinition) => c.id);

    const lastMonthStart = getLastMonthStartDate();
    const lastMonthEnd = getLastMonthEndDate();

    const selectCondition = new SelectByConversionCondition(
      allActiveConversionIds,
      lastMonthStart,
      lastMonthEnd,
      null,
      []
    );

    return [
      getErrorTourCondition(selectCondition),
      getMultiInflowTourCondition(selectCondition)
      // getInquiryTourCondition(selectCondition) // excluded from 1st release of Tour feature
    ];
  }
};

const actions = <ActionTree<TourState, RootState>>{
  async findSelectedTourById({ commit, getters }, tourId: string) {
    const selectedTour = getters.tours.find(
      (t: TourCondition) => t.id === tourId
    );

    if (selectedTour) {
      commit("setCurrentTour", selectedTour);
    }
  },
  async executeSelectAndFilterForTour(
    { commit, dispatch },
    tourCondition: TourConditionSearchAndFilterModel
  ) {
    const { selectCondition, filterCondition } = tourCondition;

    commit("search/setSelectCondition", selectCondition, { root: true });

    await dispatch(
      "search/executeSelectAndFilter",
      { filterCondition },
      { root: true }
    );
  }
};

export const tour = {
  namespaced: true,
  state: new TourState(),
  mutations,
  getters,
  actions
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getInquiryTourCondition(
  selectCondition: SelectCondition
): TourCondition {
  const lastMonthStart = getLastMonthStartDate();
  const lastMonthEnd = getLastMonthEndDate();
  const lastMonthSiteTitleContainsInquiryWithOtherVisitEdge = new FilterNodeForBrowseSite(
    new FilterPageTitleCondition("*問*合*せ*", MatchMethod.Wildcard),
    [new FilterPeriodCondition(lastMonthStart, lastMonthEnd)],
    0,
    0,
    new FilterEdge(FilterEdgeType.otherVisit)
  );
  const lastMonthSiteTitleContainsInquiry = new FilterNodeForBrowseSite(
    new FilterPageTitleCondition("*問*合*せ*", MatchMethod.Wildcard),
    [new FilterPeriodCondition(lastMonthStart, lastMonthEnd)],
    0,
    0,
    null
  );
  const filterCondition = new FilterCondition(
    [
      lastMonthSiteTitleContainsInquiryWithOtherVisitEdge,
      lastMonthSiteTitleContainsInquiry
    ],
    [],
    FilterPeriodDays.THREE_MONTHS
  );
  const i18nDir = `store.tours.${INQUIRY_TOUR_ID}`;

  return new TourConditionSearchAndFilterModel({
    id: INQUIRY_TOUR_ID,
    title: i18n.t(`${i18nDir}.title`) as string,
    suggestion: i18n.t(`${i18nDir}.suggestion`) as string,
    conversions: ["すべて"],
    reason: i18n.t(`${i18nDir}.reason`) as string,
    description: i18n.t(`${i18nDir}.description`) as string,
    eyecatchImageFileName: "eyecatch_inquiry.png",
    panelImageFileName: "tour-detail-header-left-panel_inquiry.png",
    selectCondition,
    filterCondition
  });
}

function getErrorTourCondition(
  selectCondition: SelectCondition
): TourCondition {
  const siteTitleContainsError = new FilterNodeForBrowseSite(
    new FilterPageTitleCondition("エラー", MatchMethod.Partial),
    [],
    0,
    0,
    null
  );
  const siteTitleContainsNotFound = new FilterNodeForBrowseSite(
    new FilterPageTitleCondition("見つかりません", MatchMethod.Partial),
    [],
    0,
    0,
    null
  );
  const siteTitleContainsErrorOrNotFoundWithSameVisitEdge = new FilterNodeForOrNode(
    0,
    0,
    new FilterEdge(FilterEdgeType.sameVisit),
    new FilterExclusion(),
    [siteTitleContainsError, siteTitleContainsNotFound]
  );
  const siteTitleContainsErrorOrNotFound = new FilterNodeForOrNode(
    0,
    0,
    null,
    new FilterExclusion(),
    [siteTitleContainsError, siteTitleContainsNotFound]
  );
  const filterCondition = new FilterCondition(
    [
      siteTitleContainsErrorOrNotFoundWithSameVisitEdge,
      siteTitleContainsErrorOrNotFound
    ],
    [],
    FilterPeriodDays.THREE_MONTHS
  );
  const i18nDir = `store.tours.${ERROR_TOUR_ID}`;

  return new TourConditionSearchAndFilterModel({
    id: ERROR_TOUR_ID,
    title: i18n.t(`${i18nDir}.title`) as string,
    suggestion: i18n.t(`${i18nDir}.suggestion`) as string,
    conversions: ["すべて"],
    reason: i18n.t(`${i18nDir}.reason`) as string,
    description: i18n.t(`${i18nDir}.description`) as string,
    eyecatchImageFileName: "eyecatch_error.png",
    panelImageFileName: "tour-detail-header-left-panel_error.png",
    selectCondition,
    filterCondition
  });
}

function getMultiInflowTourCondition(
  selectCondition: SelectByConversionCondition
): TourCondition {
  const lastMonthStart = getLastMonthStartDate();
  const lastMonthEnd = getLastMonthEndDate();
  const filterCondition = new FilterCondition(
    [
      new FilterNodeForInflow(
        new ChildFilterNodeForSearchEngine([
          new FilterPeriodCondition(lastMonthStart, lastMonthEnd)
        ]),
        0,
        0,
        new FilterEdge(
          FilterEdgeType.sameVisit,
          undefined,
          SameVisitTransitionType.allAfter
        )
      ),
      new FilterNodeForInflow(
        new ChildFilterNodeForSearchEngine([]),
        0,
        0,
        new FilterEdge(
          FilterEdgeType.sameVisit,
          undefined,
          SameVisitTransitionType.allAfter
        )
      ),
      new FilterNodeForInflow(
        new ChildFilterNodeForSearchEngine([]),
        0,
        0,
        null
      )
    ],
    [],
    FilterPeriodDays.THREE_MONTHS
  );
  const i18nDir = `store.tours.${MULTI_INFLOW_TOUR_ID}`;

  return new TourConditionSearchAndFilterModel({
    id: MULTI_INFLOW_TOUR_ID,
    title: i18n.t(`${i18nDir}.title`) as string,
    suggestion: i18n.t(`${i18nDir}.suggestion`) as string,
    conversions: ["すべて"],
    reason: i18n.t(`${i18nDir}.reason`) as string,
    description: i18n.t(`${i18nDir}.description`) as string,
    eyecatchImageFileName: "eyecatch_multi-inflow.png",
    panelImageFileName: "tour-detail-header-left-panel_multi-inflow.png",
    selectCondition,
    filterCondition
  });
}
