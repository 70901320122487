<i18n src="@/i18n/components/filter/filter-period.json"></i18n>
<template>
  <span class="filterPeriodLabel">{{ periodName }}</span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { FilterPeriodDays } from "@/const/filter";

@Component({})
export default class FilterPeriod extends Vue {
  @Prop({ type: Number, required: true })
  periodDays!: FilterPeriodDays;

  get periodName(): string {
    switch (this.periodDays) {
      case FilterPeriodDays.TWO_YEARS:
        return this.$i18n.t("wholePeriod") as string;
      case FilterPeriodDays.ONE_YEAR:
        return this.$i18n.t("oneYear") as string;
      case FilterPeriodDays.SIX_MONTHS:
        return this.$i18n.t("sixMonths") as string;
      case FilterPeriodDays.THREE_MONTHS:
        return this.$i18n.t("threeMonths") as string;
      case FilterPeriodDays.ONE_MONTH:
        return this.$i18n.t("oneMonth") as string;
    }
  }
}
</script>

<style lang="scss" scoped>
.filterPeriodLabel {
  color: $colorBase900;
  font-weight: 700;
  font-size: 14px;
}
</style>
