import { ApiRes } from "@/api/api-res";

/**
 * 顧客ロイヤルティ指標（総合）
 *
 * 顧客ロイヤルティ指標（総合）は、顧客満足度やNPSなど、クライアントが定義した値に基づく指標です。
 *
 * 値の条件: min <= lowThreshold < highThreshold <= max
 */

export class NpsDefinition {
  constructor(
    // 設定されたid
    public readonly id: number,
    // idに対して管理画面で設定された値
    public readonly name: string,
    // nameの略称
    public readonly shortName: string,
    // 値の下限値
    public readonly min: number,
    // 値の上限値
    public readonly max: number,
    // 中評価と高評価の境界値 設定値以上が高評価
    public readonly highThreshold: number,
    // 低評価と中評価の境界値 設定値以下が低評価
    public readonly lowThreshold: number,
    // true = インポート中, false = インポート停止
    public readonly isDisabled: boolean
  ) {}

  // 低評価のスコア範囲
  public get lowRange(): { from: number; to: number } {
    return { from: this.min, to: this.lowThreshold };
  }

  // 中評価のスコア範囲
  public get mediumRange(): { from: number; to: number } {
    return { from: this.lowThreshold + 1, to: this.highThreshold - 1 };
  }

  // 高評価のスコア範囲
  public get highRange(): { from: number; to: number } {
    return { from: this.highThreshold, to: this.max };
  }

  // 表示用低評価のスコア範囲
  public get lowScoreRange(): string {
    const range = this.lowRange;
    return String(range.from) + " 〜 " + String(range.to);
  }

  // 表示用中評価のスコア範囲
  public get mediumScoreRange(): string {
    const range = this.mediumRange;
    return String(range.from) + " 〜 " + String(range.to);
  }

  // 表示用高評価のスコア範囲
  public get highScoreRange(): string {
    const range = this.highRange;
    return String(range.from) + " 〜 " + String(range.to);
  }

  public static fromJson(json: ApiRes.NpsDef): NpsDefinition {
    return new NpsDefinition(
      json.id,
      json.name,
      json.short_name,
      json.min_nps,
      json.max_nps,
      json.min_high_nps,
      json.max_low_nps,
      json.is_disabled
    );
  }
}
