import { FilterBusinessEventAttributeCondition } from "@/models/search/filter-node-condition/FilterBusinessEventAttributeCondition";
import {
  FilterAdditionalTimingCondition,
  FilterAdditionalCondition,
  FilterNodeConditionType,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import {
  FilterEdge,
  convertFilterEdgeToJson,
  convertJsonToFilterEdge
} from "@/models/search/filter-node/FilterEdge";
import { FilterNodeParamForBusinessEvent } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import {
  FilterNodeType,
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";
import {
  convertFilterExclusionToJsonValue,
  convertJsonValueToFilterExclusion,
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

/**
 * ビジネスイベントによる絞り込み条件
 *
 * 選択した種類のビジネスイベントがあるユーザを絞り込む
 *
 * 初回、期間、日付と時間帯 を条件として追加できる
 */
export class FilterNodeForBusinessEvent {
  public readonly nodeType = FilterNodeType.BusinessEvent;

  constructor(
    // ビジネスイベント定義を指定
    public readonly businessEventDefinitionId: number,
    /**
     * 追加の検索条件
     *
     * 初回、期間、日付と時間帯を 2つまで指定できる
     */
    public readonly additionalConditions: (
      | FilterBusinessEventAttributeCondition
      | FilterAdditionalTimingCondition
    )[],
    /**
     * 次の絞り込み条件との連結条件
     * 絞り込み条件が1つまたは末端の場合はnull
     */
    public childIndex: number | null,
    public depth: number | null,
    public edge: FilterEdge | null,
    public readonly filterExclusion: FilterExclusion = new FilterExclusion()
  ) {}

  get isExcluded(): boolean {
    return this.filterExclusion.exclusionType === ExclusionType.Exclude;
  }

  get validate(): ValidationResult {
    return validateConditions(this.additionalConditions);
  }

  isConditionAddable(): boolean {
    if (this.depth === 0) {
      return this.additionalConditions.length < 2;
    } else {
      return this.additionalConditions.length < 1;
    }
  }

  insertEdge(): FilterNodeForBusinessEvent {
    const edge = this.edge !== null ? this.edge : FilterEdge.getDefaultEdge();
    return new FilterNodeForBusinessEvent(
      this.businessEventDefinitionId,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      edge,
      this.filterExclusion
    );
  }
  removeEdge(): FilterNodeForBusinessEvent {
    return new FilterNodeForBusinessEvent(
      this.businessEventDefinitionId,
      this.additionalConditions,
      this.childIndex,
      this.depth,
      null,
      this.filterExclusion
    );
  }

  removeFirstTimeCondition(): FilterNodeForBusinessEvent {
    return new FilterNodeForBusinessEvent(
      this.businessEventDefinitionId,
      this.additionalConditions.filter(
        cnd => !(cnd instanceof FilterFirstTimeCondition)
      ),
      this.childIndex,
      this.depth,
      this.edge,
      this.filterExclusion
    );
  }

  get hasBusinessEventAttributeCondition(): boolean {
    return this.additionalConditions.some(addtionalCondition => {
      return (
        addtionalCondition instanceof FilterBusinessEventAttributeCondition
      );
    });
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isCondition: boolean,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.BusinessEventAttribute,
            label: i18n.t("models.businessEventAttribute.content") as string,
            disabled:
              this.hasBusinessEventAttributeCondition &&
              !(
                currentCondition instanceof
                FilterBusinessEventAttributeCondition
              )
          }
        ]
      }
    ];

    if (this.depth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions,
          isCondition
        )
      );
    }

    return options;
  }
}

export function convertFilterNodeForBusinessEventToJson(
  node: FilterNodeForBusinessEvent
): FilterNodeParamForBusinessEvent {
  const result: FilterNodeParamForBusinessEvent = {
    activity_edge: convertFilterEdgeToJson(node.edge),
    activity_type: ActivityEdgeType.BusinessEvent,
    activity_excluded: convertFilterExclusionToJsonValue(node.filterExclusion),
    be_def_id: node.businessEventDefinitionId
  };

  const conditions: (
    | FilterBusinessEventAttributeCondition
    | FilterAdditionalTimingCondition
  )[] = node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterBusinessEventAttributeCondition) {
      result.content = condition.content;
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForBusinessEvent(
  json: FilterNodeParamForBusinessEvent,
  activetBusinessEventDefinitions: BusinessEventDefinition[],
  isCheckDefinition = true
): FilterNodeForBusinessEvent | null {
  // チェックが必要かつ利用可能なidリストになければnull
  if (
    isCheckDefinition &&
    !activetBusinessEventDefinitions.some(def => def.id === json.be_def_id)
  ) {
    return null;
  }

  const additionalConditions: (
    | FilterBusinessEventAttributeCondition
    | FilterAdditionalTimingCondition
  )[] = getAdditionalTimingConditions(json);

  if (json.content) {
    additionalConditions.push(
      new FilterBusinessEventAttributeCondition(json.content)
    );
  }

  return new FilterNodeForBusinessEvent(
    json.be_def_id,
    additionalConditions,
    0,
    0,
    convertJsonToFilterEdge(json.activity_edge),
    convertJsonValueToFilterExclusion(json.activity_excluded!)
  );
}
