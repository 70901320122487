<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <UserBaseBalloon
    class="user-attribute-balloon"
    :show="show"
    :x="x"
    :y="y"
    :direction="direction"
    :adjust="adjust"
  >
    <div class="user-attribute-balloon__wrapper">
      <PropertyList>
        <tr>
          <th v-t="user.isFixedServiceId ? 'serviceId' : 'userId'" />
          <td>
            <UserServiceIdText
              :service-id-text="user.serviceIdForDisplay"
              :is-fixed-service-id="user.isFixedServiceId"
            />
          </td>
        </tr>
      </PropertyList>

      <div
        v-if="$i18n.locale === 'ja'"
        class="user-attribute-balloon__wordcloud"
      >
        <slot name="wordcloud"></slot>
      </div>
    </div>
  </UserBaseBalloon>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/models/User";

import PropertyList from "@/components/list/PropertyList.vue";
import UserBaseBalloon, {
  UserBalloonDirection
} from "@/components/users/UserBaseBalloon.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";

@Component({
  components: {
    PropertyList,
    UserBaseBalloon,
    UserServiceIdText
  }
})
export default class UserAttributeBalloon extends Vue {
  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: Boolean, default: false })
  show!: boolean;

  @Prop({ type: Number, required: true })
  direction!: UserBalloonDirection;

  @Prop({ type: String, default: "auto" })
  y!: string;

  @Prop({ type: String, default: "auto" })
  x!: string;

  @Prop({ type: Number, default: 0 })
  adjust!: number;
}
</script>

<style scoped lang="scss">
.user-attribute-balloon__wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
}

.user-attribute-balloon__wordcloud {
  margin-top: 15px;
  width: 100%;
}
</style>
